export default [
  'ลูกค้าเดิม',
  'ลูกค้าที่น่ารัก',
  'ลูกค้า VIP',
  'ลูกค้าเดิม',
  'ลูกค้าที่น่ารัก',
  'ลูกค้า VIP',
  'ลูกค้าเดิม',
  'ลูกค้าที่น่ารัก',
  'ลูกค้า VIP',
  'ลูกค้าเดิม',
  'ลูกค้าที่น่ารัก',
  'ลูกค้า VIP',
];
