<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 176.73 63.12">
    <defs></defs>
    <g id="Group_45700">
      <g id="Rectangle_17054">
        <rect class="cls-1" width="34.72" height="63.12" rx="5.31" ry="5.31" />
        <rect class="cls-2" x=".66" y=".66" width="33.39" height="61.79" rx="4.65" ry="4.65" />
      </g>
      <g id="Rectangle_17055">
        <rect class="cls-1" x="44.18" width="132.55" height="63.12" rx="5.31" ry="5.31" />
        <rect class="cls-2" x="44.85" y=".66" width="131.22" height="61.79" rx="4.65" ry="4.65" />
      </g>
    </g>
  </svg>
</template>
