import { ref, Ref } from 'vue';

export default function useToolbar() {
  const fontColor: Ref<string> = ref('');
  const isColorPickerOpen: Ref<boolean> = ref(false);

  const onToggleColorPicker = () => {
    isColorPickerOpen.value = !isColorPickerOpen.value;
  };

  const onChangeFontColor = (color: string) => {
    fontColor.value = color;
  };

  return {
    fontColor,
    isColorPickerOpen,
    onToggleColorPicker,
    onChangeFontColor,
  };
}
