export default {
  myAccount: 'บัญชีของฉัน',
  my_account: {
    my_account_tabs: {
      business: 'ภาพรวมธุรกิจ',
      credit: 'เครดิต',
      storage: 'พื้นที่จัดเก็บข้อมูล',
    },
    ask: 'สอบถามเพิ่มเติม',
  },

  business: {
    title: 'Business',
  },
  credit: {
    title: 'เครดิต',
    detail: 'ภาพรวมการใช้งานเครดิต',
  },

  storage: {
    title: 'พื้นที่จัดเก็บข้อมูล',
    detail: '',
    storage_information: {
      title: 'พื้นที่จัดเก็บข้อมูล',
      current_package: 'แพ็กเกจปัจจุบัน',
    },
    upgrade_storage: {
      title: 'อัปเกรดพื้นที่จัดเก็บข้อมูล',
    },
  },
};
