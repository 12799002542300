import { ref, Ref, watch, getCurrentInstance, onMounted } from 'vue';

interface Props {
  isOpenSorting: boolean;
  selectCurrentValue: any;
  selectSorting: FilterTable.Sorting;
}

export default function useOrdering(props: Props) {
  const isCheckedASC: Ref<boolean> = ref(false);
  const isCheckedDESC: Ref<boolean> = ref(false);
  const isCheckedSort: Ref<boolean> = ref(false);
  const sortedColumn: Ref<string> = ref('');
  const vm = getCurrentInstance()?.proxy;

  const onSelectedOrdering = (key: string) => {
    handleSelectedOrdering(key);
    vm?.$emit('on-force-close-sort', { status: true, selected: props.selectCurrentValue });
  };

  const handleSelectedOrdering = (key: string) => {
    sortedColumn.value = key;
    if (key === 'asc' && !isCheckedASC.value) {
      isCheckedDESC.value = false;
      isCheckedASC.value = true;
      isCheckedSort.value = true;
    } else if (key === 'desc' && !isCheckedDESC.value) {
      isCheckedDESC.value = true;
      isCheckedASC.value = false;
      isCheckedSort.value = true;
    } else {
      isCheckedDESC.value = false;
      isCheckedASC.value = false;
      isCheckedSort.value = false;
    }
    vm?.$emit('on-select-ordering', { key: sortedColumn.value, label: props.selectCurrentValue, status: isCheckedSort.value });
  };

  onMounted(() => {
    if (props.selectCurrentValue == props.selectSorting.Key && props.selectSorting.status) {
      handleSelectedOrdering(String(props.selectSorting.Direction));
    }
  });

  watch(
    () => props.selectCurrentValue,
    (newCurrentKey) => {
      const sort = props.selectSorting.Key == newCurrentKey;
      if (sort) {
        handleSelectedOrdering(String(props.selectSorting.Direction));
      } else {
        isCheckedDESC.value = false;
        isCheckedASC.value = false;
        isCheckedSort.value = false;
      }
    },
    { deep: true },
  );

  return {
    isCheckedASC,
    isCheckedDESC,
    handleSelectedOrdering,
    onSelectedOrdering,
  };
}
