<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24.848" height="24.847" viewBox="0 0 24.848 24.847">
    <path
      d="M12.424,24.847A12.424,12.424,0,0,1,3.639,3.639a12.424,12.424,0,1,1,17.57,17.57A12.343,12.343,0,0,1,12.424,24.847Zm0-22.363A9.939,9.939,0,0,0,5.4,19.452,9.939,9.939,0,0,0,19.452,5.4,9.874,9.874,0,0,0,12.424,2.485Z"
      transform="translate(0)"
    />
    <g transform="translate(9.111 6.626)">
      <path
        d="M4.979,1.17a1,1,0,0,1,1.638,0l3.877,5.539a1,1,0,0,1-.819,1.573H1.921A1,1,0,0,1,1.1,6.709Z"
        transform="translate(8.283 0) rotate(90)"
      />
    </g>
  </svg>
</template>
