<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="79.158" height="73.994" viewBox="0 0 79.158 73.994">
    <path
      id="Subtraction_268"
      data-name="Subtraction 268"
      d="M11.268,73.994A10.948,10.948,0,0,1,7,73.127a11.363,11.363,0,0,1-3.595-2.4A11.487,11.487,0,0,1,0,62.481a70.493,70.493,0,0,0,2.275-6.863A72.663,72.663,0,0,0,4.963,39.872H50.81a1,1,0,0,0,1-1v-3.75a1,1,0,0,0-1-1H4.909a80.15,80.15,0,0,0-2.73-15.655C1.057,14.316.01,11.541,0,11.514A11.487,11.487,0,0,1,3.407,3.271,11.365,11.365,0,0,1,7,.867a10.944,10.944,0,0,1,8.82.124L72.436,26.474A11.255,11.255,0,0,1,77.477,30.9a11.909,11.909,0,0,1,0,12.2,11.255,11.255,0,0,1-5.042,4.423L15.822,73A11.035,11.035,0,0,1,11.268,73.994Z"
      transform="translate(0 0)"
      fill="#1997f4"
    />
  </svg>
</template>
