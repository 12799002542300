import { ref, Ref, getCurrentInstance } from 'vue';

import { STATIC_COLOR_LIST, COLOR_PANELS, ColorPanel, Color } from '@/constants/components/color-picker/base-color-picker';

interface Props {
  selectedColor: string;
  isAdvance?: boolean;
  //Adjust Position
  colorPickerId: string;
  parentElementSelector?: string;
  position?: Position;
  isOpen: boolean;
}

interface Position {
  isTop?: boolean;
  isBottom?: boolean;
  isLeft?: boolean;
  isRight?: boolean;
  isCenter?: boolean;
}

export default function useBaseColorPicker(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const colorsList: Ref<Color[] | []> = ref(STATIC_COLOR_LIST);
  const colorPanels: ColorPanel[] = COLOR_PANELS;
  const currentPanel: Ref<ColorPanel | undefined> = ref(undefined);
  const selectedColor: Ref<string> = ref(props.selectedColor);
  const localColors: Ref<Array<Color>> = ref([]);
  const currentColor: Ref<Color | null> = ref(null);
  const isOpen: Ref<boolean> = ref(props.isOpen);
  const localColorLength = 15 as number;

  const elId: Ref<string> = ref(props.colorPickerId);
  const elRef: Ref<HTMLElement | null> = ref(null);

  function onClickColor(colorItem: Color) {
    currentColor.value = colorItem;
  }

  const onClickChangePanel = (panelSeq: number) => {
    return (currentPanel.value = panelSeq === colorPanels[0].seq ? colorPanels[1] : colorPanels[0]);
  };

  // Emit event from colorSpectrum
  const onClickApply = () => {
    if (currentColor.value) {
      vm?.$emit('on-color-changed', currentColor.value.colorHex);
      onToggle(false);
    } else {
      alert('Please select color');
    }
  };

  // Add new custom color
  const onClickAddSwatch = () => {
    if (currentPanel.value?.seq === colorPanels[0].seq) {
      onClickChangePanel(1);
    } else {
      setLocalColors();
    }
  };

  const orderLocalColorSeq = () => {
    if (localColors.value) {
      return localColors.value.forEach((c, index) => (localColors.value[index] = { seq: index + 1, colorHex: c.colorHex }));
    }
  };

  // Set saved color in local stroage to list
  const setLocalColors = () => {
    if (currentColor.value) {
      if (localColors.value.length > localColorLength - 1) {
        localColors.value = localColors.value.slice(1, localColorLength);
        orderLocalColorSeq();
      }
      localColors.value.push(
        localColors.value.length
          ? { seq: localColors.value.length + 1, colorHex: currentColor.value.colorHex }
          : { seq: 1, colorHex: currentColor.value.colorHex },
      );

      localStorage.setItem('savedColor', JSON.stringify(localColors.value));
    }
  };

  const getLocalColor = () => {
    localColors.value = localStorage.getItem('savedColor') ? JSON.parse(localStorage.getItem('savedColor') || '') : [];
    if (localColors.value) {
      if (localColors.value.length > localColorLength) {
        localColors.value = localColors.value.slice(0, localColorLength);
        orderLocalColorSeq();
      }

      localStorage.setItem('savedColor', JSON.stringify(localColors.value));
    }
  };

  // Add new custom color to swatch list
  const colorSpectrumHandler = (color: string) => {
    try {
      if (color) {
        currentColor.value = { seq: localColors.value.length + 1, colorHex: color };
      }
    } catch (e) {}
  };

  const onClickClearLocalColor = () => {
    localStorage.removeItem('savedColor');
    localColors.value = [];
  };

  // Check exist color to mark selected icon
  const getExistColor = (colorInput: string) => {
    const color = colorsList.value.find((c) => c.colorHex === colorInput) as Color;
    if (color) {
      return color;
    } else {
      const localColor = localColors.value.find((c) => c.colorHex === colorInput) as Color;
      if (localColor) {
        return localColor;
      } else {
        currentColor.value = { seq: localColors.value ? localColors.value.length + 1 : 1, colorHex: selectedColor.value } as Color;
        setLocalColors();
        return currentColor.value;
      }
    }
  };

  const setDefault = () => {
    if (props.isAdvance === true) {
      currentPanel.value = colorPanels[1];
    } else {
      currentPanel.value = colorPanels[0];
    }
  };

  // Initial component
  const init = () => {
    setDefault();
    getLocalColor();

    if (selectedColor.value) {
      if (getExistColor(selectedColor.value)) {
        currentColor.value = getExistColor(selectedColor.value);
      }
    }
  };

  // Toggle action
  const onToggle = (isOpen: boolean) => {
    init();
    const el = initElement(elId.value).el;

    if (el) {
      if (isOpen) {
        el.classList.add('opened');
      } else {
        isOpen.valueOf.bind(false);
        el.classList.remove('opened');
        vm?.$emit('color-picker-close');
      }
    }
  };

  //HTML element declaration
  const initElement = (colorPickerId: string) => {
    const elId = `#color-picker-wrp-${colorPickerId}`;
    const el = document.querySelector(`${elId}`) as HTMLElement;

    return { el, elId };
  };

  return {
    colorsList,
    currentPanel,
    localColors,
    onClickColor,
    currentColor,
    onClickChangePanel,
    onClickApply,
    onClickClearLocalColor,
    onClickAddSwatch,
    colorSpectrumHandler,
    onToggle,
    isOpen,
    colorPanels,
    elRef,
  };
}
