<template>
  <svg id="Group_30081" data-name="Group 30081" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" width="28.755" height="19.959" viewBox="0 0 28.755 19.959">
    <g id="Group_30081-2" data-name="Group 30081">
      <path id="Path_21045" data-name="Path 21045"
        d="M28.5,9.37,19.386.253a.863.863,0,0,0-1.221,0L14.377,4.041,10.59.253a.863.863,0,0,0-1.221,0L.253,9.37a.863.863,0,0,0,0,1.221l9.117,9.117a.863.863,0,0,0,1.221,0l3.788-3.787,3.787,3.787a.863.863,0,0,0,1.221,0L28.5,10.591a.863.863,0,0,0,0-1.221M10.59,16.119a.863.863,0,0,1-1.221,0L3.841,10.591a.863.863,0,0,1,0-1.221L9.369,3.842a.863.863,0,0,1,1.221,0L16.118,9.37a.863.863,0,0,1,0,1.221l-1.741,1.741L12.026,9.98l.657-.657A1.269,1.269,0,0,0,10.89,7.528L9.048,9.37a.863.863,0,0,0,0,1.221l3.535,3.535Zm14.324-5.528-5.528,5.528a.863.863,0,0,1-1.221,0l-1.993-1.993,3.535-3.535a.863.863,0,0,0,0-1.221L16.172,5.835l1.993-1.993a.863.863,0,0,1,1.221,0L24.914,9.37a.863.863,0,0,1,0,1.221"
        transform="translate(0 0)" />
    </g>
  </svg>
</template>
