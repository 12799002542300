<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="11.768" height="10.904" viewBox="0 0 11.768 10.904">
    <g id="Group_962" data-name="Group 962" transform="translate(0)">
      <path
        id="Path_417"
        data-name="Path 417"
        d="M6225.719-5221.722l5.452,5.452-5.452,5.452-1.222-1.222,4.23-4.23-4.23-4.23Z"
        transform="translate(-6219.403 5221.722)"
      />
      <path
        id="Path_418"
        data-name="Path 418"
        d="M6225.719-5221.722l5.452,5.452-5.452,5.452-1.222-1.222,4.23-4.23-4.23-4.23Z"
        transform="translate(-6224.497 5221.722)"
      />
    </g>
  </svg>
</template>
