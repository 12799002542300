import api from '@services/api';
import bangchakModel from '../models/bangchak';

import GraphIcon from '@/assets/icons/modules/communication/line-oa/GraphIcon.vue';
import EditIcon from '@/assets/icons/modules/segment/all-segment/Edit.vue';
import DuplicateIcon from '@/assets/icons/base/DuplicateIcon.vue';
import TrashIcon from '@/assets/icons/base/TrashIcon.vue';

export default function useRedirectBangchak() {
  const { fetchRedirectBangchak } = bangchakModel();
  const redirectBangchak = (campaignId: number, redirect?: RedirectTo.Resopnse) => {
    fetchRedirectBangchak.payload.campaignid = campaignId;
    let urlBangchak: string;
    api
      .apiRequest(fetchRedirectBangchak)
      .then((response) => {
        urlBangchak = response.data.redirect_url;
        if (redirect == 'downloadExcel') {
          const urlDowloadExcel: string = response.data.report_url;
          if (urlDowloadExcel != null && urlDowloadExcel != 'pending') {
            window.open(urlDowloadExcel, '_blank');
          }
        } else {
          window.open(urlBangchak, '_blank');
        }
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const actionListBangChak: ActionList.Action[] = [
    { key: 'dashboard', tooltip: 'Dashboard', icon: GraphIcon, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' },
    { key: 'edit', tooltip: 'Edit', icon: EditIcon, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' },
    { key: 'duplicate', tooltip: 'Duplicate', icon: DuplicateIcon, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' },
    { key: 'delete', tooltip: 'Delete', icon: TrashIcon, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' },
  ];
  const searchParams = new URLSearchParams(window.location.search);
  const brandRef: string = searchParams.get('brandRef')!;
  let brandBangChak: boolean = false;
  if (brandRef == 'BB52U1RLJQJS') {
    brandBangChak = true;
  }
  return { redirectBangchak, brandBangChak, actionListBangChak };
}
