import { DateTime } from 'luxon';
import { onBeforeMount, ref, watch, reactive, Ref, onMounted, toRaw, nextTick, onUpdated } from 'vue';
import { useI18n } from 'vue-i18n';
import useConvertDate from '@/utils/hooks/useConvertDate';

interface ScheduleInfo {
  schedule: {
    scheduleMode: number;
    scheduleDt: string;
    repeatMode: number;
    repeatEvery: number;
    repeatOn: number;
    endMode: number;
    endDt: string;
    endOccurrence: number;
    repeatOnVal: [];
    nextScheduleDt: string;
    nextScheduleDtLabel: string;
    repeatEveryLabel: string;
    repeatOnLabel: string;
    startOnLabel: string;
    endOnLabel: string;
  };
}

export default function useScheduleDescription() {
  const { convertDateLanguage } = useConvertDate();
  const { t } = useI18n();

  const campaignForm: ScheduleInfo = reactive({
    schedule: {
      scheduleMode: 0,
      scheduleDt: '',
      repeatMode: 0,
      repeatEvery: 0,
      repeatOn: 0,
      endMode: 0,
      endDt: '',
      endOccurrence: 0,
      repeatOnVal: [],
      nextScheduleDt: '',
      nextScheduleDtLabel: '',
      repeatEveryLabel: '',
      repeatOnLabel: '',
      startOnLabel: '',
      endOnLabel: '',
    },
  });

  const resolveScheduleDescription = (): string[] => {
    const dateDescription: any = [];
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null');
    const timezone = userInfo.timezone || DateTime.fromISO(new Date().toISOString()).zoneName;
    const scheduleDateTime = campaignForm.schedule.scheduleDt ? DateTime.fromISO(campaignForm.schedule.scheduleDt, { zone: timezone }) : null;

    if (campaignForm.schedule.scheduleMode == 1) {
      dateDescription.push(campaignForm.schedule.startOnLabel);
    } else if (campaignForm.schedule.scheduleMode == 2) {
      dateDescription.push(
        `${
          // scheduleDateTime
          //   ? `${scheduleDateTime.setLocale('th').toFormat('cccc')}ที่ ${scheduleDateTime
          //       .setLocale('th')
          //       .toFormat('dd LLLL yyyy')} เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.`
          //   : ''

          scheduleDateTime ? `${convertDateLanguage(campaignForm.schedule.scheduleDt)}` : ''
        }`,
      );
    } else if (campaignForm.schedule.scheduleMode == 3) {
      if (campaignForm.schedule.repeatMode != 2) {
        dateDescription.push(
          `${
            // scheduleDateTime
            //   ? `${scheduleDateTime.setLocale('th').toFormat('cccc')}ที่ ${scheduleDateTime
            //       .setLocale('th')
            //       .toFormat('dd LLLL yyyy')} เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.`
            //   : ''
            scheduleDateTime ? `${convertDateLanguage(campaignForm.schedule.scheduleDt)}` : ''
          }`,
        );
        dateDescription.push(campaignForm.schedule.repeatEveryLabel, campaignForm.schedule.startOnLabel);
      } else {
        dateDescription.push(
          // scheduleDateTime
          //   ? `${scheduleDateTime.setLocale('th').toFormat('cccc')}ที่ ${scheduleDateTime
          //       .setLocale('th')
          //       .toFormat('dd LLLL yyyy')} เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.`
          //   : ''
          scheduleDateTime ? `${convertDateLanguage(campaignForm.schedule.scheduleDt)}` : '',
        );
        dateDescription.push(campaignForm.schedule.repeatEveryLabel, campaignForm.schedule.startOnLabel);
        campaignForm.schedule.repeatOnVal?.forEach((value) => {
          switch (value) {
            case 1:
              dateDescription.push(
                `${t('communication.sentEvery')}${t('communication.sunday')} ${
                  scheduleDateTime ? ` ${t('communication.time')} ${scheduleDateTime.toFormat('HH:mm:ss')} ${t('communication.minute')}` : ''
                }`,
              );
              break;
            case 2:
              dateDescription.push(
                `${t('communication.sentEvery')}${t('communication.monday')} ${
                  scheduleDateTime ? ` ${t('communication.time')} ${scheduleDateTime.toFormat('HH:mm:ss')} ${t('communication.minute')}` : ''
                }`,
              );
              break;
            case 4:
              dateDescription.push(
                `${t('communication.sentEvery')}${t('communication.tuesday')} ${
                  scheduleDateTime ? ` ${t('communication.time')} ${scheduleDateTime.toFormat('HH:mm:ss')} ${t('communication.minute')}` : ''
                }`,
              );
              break;
            case 8:
              dateDescription.push(
                `${t('communication.sentEvery')}${t('communication.wednesday')} ${
                  scheduleDateTime ? ` ${t('communication.time')} ${scheduleDateTime.toFormat('HH:mm:ss')} ${t('communication.minute')}` : ''
                }`,
              );
              break;
            case 16:
              dateDescription.push(
                `${t('communication.sentEvery')}${t('communication.thursday')} ${
                  scheduleDateTime ? ` ${t('communication.time')} ${scheduleDateTime.toFormat('HH:mm:ss')} ${t('communication.minute')}` : ''
                }`,
              );
              break;
            case 32:
              dateDescription.push(
                `${t('communication.sentEvery')}${t('communication.friday')} ${
                  scheduleDateTime ? ` ${t('communication.time')} ${scheduleDateTime.toFormat('HH:mm:ss')} ${t('communication.minute')}` : ''
                }`,
              );
              break;
            case 64:
              dateDescription.push(
                `${t('communication.sentEvery')}${t('communication.saturday')} ${
                  scheduleDateTime ? ` ${t('communication.time')} ${scheduleDateTime.toFormat('HH:mm:ss')} ${t('communication.minute')}` : ''
                }`,
              );
              break;
          }
        });
      }
    }

    return dateDescription;
  };

  const resolveEndDate = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null');
    const timezone = userInfo.timezone || DateTime.fromISO(new Date().toISOString()).zoneName;
    const endDate = campaignForm.schedule.endDt
      ? // ? `${DateTime.fromISO(campaignForm.schedule.endDt, { zone: timezone }).setLocale('th').toFormat('cccc')}ที่ ${DateTime.fromISO(
        //     campaignForm.schedule.endDt,
        //     { zone: timezone },
        //   )
        //     .setLocale('th')
        //     .toFormat('dd LLLL yyyy')} เวลา ${DateTime.fromISO(campaignForm.schedule.endDt, {
        //     zone: timezone,
        //   }).toFormat('HH:mm:ss')} น. `
        ` ${t('communication.End')} ${convertDateLanguage(campaignForm.schedule.endDt)}`
      : ` ${t('communication.End')} ${campaignForm.schedule.endOnLabel}`;
    return endDate;
  };

  return { campaignForm, resolveScheduleDescription, resolveEndDate };
}
