<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="30px"
    height="30px"
    viewBox="0 0 24 24"
    aria-labelledby="addIconTitle"
    stroke="#606a6c"
    stroke-width="0.8"
    stroke-linecap="round"
    stroke-linejoin="round"
    fill="none"
    color="#606a6c"
  >
    <path d="M17 12L7 12M12 17L12 7" />
    <circle cx="12" cy="12" r="10" />
  </svg>
</template>
