import { acceptHMRUpdate, defineStore } from 'pinia';
import permission from '@/models/permission/permission';
import apiService from '@/services/api';
import ability from '@/libs/acl/ability';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useAuthentication from '@/utils/hooks/useAuthentication';

export const usePermissionStore = defineStore('permission', {
  state: () =>
    <Permission.Key>{
      Info: {},
    },
  getters: {},
  actions: {
    async ASSIGN_PERMISSION() {
      const { fetchPermissionKeyInfo } = permission();
      const { openDefaultErrorModal } = useValidationModal();
      const { logout } = useAuthentication();
      await apiService
        .apiRequest(fetchPermissionKeyInfo)
        .then((resp) => {
          const resp_Key = resp.data.key_lists as Permission.Response.Key[];
          const ability_List = [] as Permission.Ability[];
          resp_Key.forEach((key: Permission.Response.Key) => {
            this.Info[key.permission_key] = key.status;

            if (key.status) {
              ability_List.push({ action: 'portal-cdp', subject: key.permission_key });
            }
          });

          ability.update([...ability_List]);
        })
        .catch((err) => {
          openDefaultErrorModal(err, () => {
            logout();
          }); // logout
        })
        .finally(() => {});
    },
  },
});

if (import.meta.webpackHot) import.meta.webpackHot.accept(acceptHMRUpdate(usePermissionStore, import.meta.webpackHot));
