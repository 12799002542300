export default function datanotiModel() {
  const fetch: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_CDP_CORE!}/Comm/CommuCredit/noti`,
    method: 'GET',
    payload: {},
  };

  const fetchListOverview: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CommuCredit/ListOverview`,
    method: 'POST',
    payload: {},
  };
  const fetchListCampaign: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CommuCredit/ListCampaign`,
    method: 'POST',
    payload: {},
  };
  const fetchListCampaignStat: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CommuCredit/ListCampaignStat`,
    method: 'POST',
    payload: {},
  };
  const notiRead: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_CDP_CORE!}/Noti/Read`,
    method: 'POST',
    payload: {},
  };
  const getAlertNotification: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_CDP_CORE!}/Noti/GetAlertNotification`,
    method: 'POST',
    payload: {},
  };
  const readAlert: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_CDP_CORE!}/Noti/ReadAlert`,
    method: 'POST',
    payload: {},
  };
  const getAlertNotificationCreditTopup: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_CDP_CORE!}/Noti/GetAlertNotificationCreditTopup`,
    method: 'POST',
    payload: {},
  };

  const fetchNotiListBellModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_CDP_CORE!}/Noti/ListBell`,
    method: 'POST',
    payload: {
      Filters: [],
      Ordering: [
        {
          seq: 1,
          key: 'Created_DT',
          direction: 'asc',
        },
      ],
      Page: 1,
      Limit: null,
    },
  };
  return {
    fetchListOverview,
    notiRead,
    fetch,
    fetchNotiListBellModel,
    getAlertNotification,
    readAlert,
    getAlertNotificationCreditTopup,
    fetchListCampaign,
    fetchListCampaignStat,
  };
}
