import { ref, Ref, watch, markRaw, onMounted } from 'vue';
import router from '@/router';
import PieChartIcon from '@assets/icons/modules/segment/preview-segment/PieChart.vue';
import BoxIcon from '@assets/icons/modules/segment/preview-segment/Box.vue';
import SaveIcon from '@assets/icons/modules/segment/preview-segment/Save.vue';
import RemarkIcon from '@assets/icons/modules/segment/preview-segment/Remark.vue';

enum menuValueList {
  SYNC_DATA = 2,
  ASYNC_DATA = 1,
  CUSTOM_DATA = 3,
  SAVE_SELF = 4,
}

interface DropdownListItem {
  title: string;
  value: number;
  icon: object;
  type: number;
}

export default function (
  includeOrExcludeList: Ref<Record<string, any>[]>,
  isSelectedAll: Ref<boolean>,
  selectViewMode: SegmentViewMode.DataViewMode,
) {
  const initDropdownList: DropdownListItem[] = [
    { title: 'บันทึกการเปลี่ยนแปลง', value: menuValueList.SAVE_SELF, icon: markRaw(RemarkIcon), type: 1 },
    { title: 'การจัดกลุ่มเป้าหมายตามเงื่อนไข (ซิงก์ข้อมูล)', value: menuValueList.SYNC_DATA, icon: markRaw(PieChartIcon), type: 1 },
    { title: 'การจัดกลุ่มเป้าหมายตามเงื่อนไข (ไม่ซิงก์ข้อมูล)', value: menuValueList.ASYNC_DATA, icon: markRaw(PieChartIcon), type: 1 },
    { title: 'การจัดกลุ่มเป้าหมายทีบันทึกเอง ', value: menuValueList.CUSTOM_DATA, icon: markRaw(BoxIcon), type: 1 },
  ];

  const dropdownList: Ref<DropdownListItem[]> = ref(initDropdownList);

  const onSetMenu = () => {
    if (!router.currentRoute.value.query.type) {
      dropdownList.value = initDropdownList.filter((el: Record<string, any>) => {
        return el.value === menuValueList.SYNC_DATA || el.value === menuValueList.ASYNC_DATA || el.value === menuValueList.CUSTOM_DATA;
      });
    } else {
      dropdownList.value = initDropdownList.filter((el: Record<string, any>) => el.type === menuValueList.ASYNC_DATA);
    }
  };

  watch(
    () => includeOrExcludeList,
    () => {
      if (includeOrExcludeList.value.length > 0 || (selectViewMode.selectedOption.mode > 0 && selectViewMode.selectedOption.mode < 9)) {
        dropdownList.value = initDropdownList.filter((el) => el.value === menuValueList.CUSTOM_DATA);
      } else {
        onSetMenu();
      }
    },
    { deep: true },
  );

  onMounted(() => {
    onSetMenu();
  });

  return { dropdownList };
}
