<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g id="Group_32746_00000124128044446919806970000013275882168998122379_" transform="translate(7.622 5.892)">
      <path
        id="Path_21372_00000095316729721683132430000006234506171218750388_"
        class="st8"
        d="M104.07,236.61h53.5
		c0.9,13.81-9.56,25.74-23.37,26.64c-0.01,0-0.02,0-0.03,0c-15.17,1.46-26.52-8.09-30.05-26.64"
      />
      <path
        id="Path_21373_00000055671634159244291010000000563094984310550661_"
        class="st8"
        d="M211.19,166.14
		c-0.7-14.44-0.76-28.9-0.98-43.36c-3.04,0.65-6.15,0.98-9.26,0.98c-26.52-0.78-47.41-22.85-46.74-49.37
		c-0.02-12.36,4.48-24.31,12.67-33.57c-3.71-2.39-6.45-6.02-7.74-10.24c-1.74-13.94-14.45-23.84-28.39-22.1
		c-11.42,1.42-20.46,10.34-22.05,21.73c-1.23,4.49-4.07,8.36-7.99,10.88c-26.59,12.8-43.33,39.88-42.89,69.4
		c-0.23,14.54,0,29.1-0.25,43.64c0.82,21.48-6.96,42.41-21.62,58.13c-2.33,3.73-4.03,7.82-5.02,12.11h206.33
		c-1.75-4.78-4.05-9.34-6.86-13.59c-11.41-12.17-18.2-27.94-19.21-44.59"
      />
      <path
        id="Path_21374_00000136404097071680183200000000301000686467012784_"
        class="st8"
        d="M200.95,40.16
		c-18.91-1.52-35.47,12.58-36.99,31.49c-1.52,18.91,12.58,35.47,31.49,36.99c18.91,1.52,35.47-12.58,36.99-31.49
		c0.07-0.92,0.11-1.84,0.11-2.76C233.24,56.22,219.12,40.92,200.95,40.16 M203.6,91.36h-4.46V63.94c-2.57,1.39-5.48,2.02-8.4,1.81
		v-3.52c5.48,0,8.62-2.1,9.42-6.31h3.45L203.6,91.36z"
      />
    </g>
  </svg>
</template>
