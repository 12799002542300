import { getCurrentInstance, ref } from 'vue';
import { isNavLinkActive, navLinkProps } from '@/layouts/utils';

export default function useVerticalNavMenuLink(item: Object, mainIndexMenu: any) {
  const vm = getCurrentInstance()?.proxy;

  const isActive = ref(false);

  const linkProps = navLinkProps(item);

  const updateIsActive = () => {
    isActive.value = isNavLinkActive(item);
  };

  const onClickSubMenu = () => {
    vm?.$emit('on-click-sub-menu', mainIndexMenu);
  };

  return {
    isActive,
    linkProps,
    updateIsActive,
    onClickSubMenu,
  };
}
