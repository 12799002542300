<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="11.884" height="12.958" viewBox="0 0 11.884 12.958">
    <path
      id="Path_24184"
      data-name="Path 24184"
      d="M1063.8,396.858l-11.11,6.516a.387.387,0,0,0,.05.693l2.455.96a.58.58,0,0,0,.6-.11l6.211-5.612a.056.056,0,0,1,.079.079l-4.917,6.183a.844.844,0,0,0-.184.522l-.01,3.513a.158.158,0,0,0,.3.072l1.5-2.944a.284.284,0,0,1,.326-.082l2.867,1.165a.387.387,0,0,0,.526-.289l1.884-10.264A.387.387,0,0,0,1063.8,396.858Z"
      transform="translate(-1052.498 -396.804)"
      fill="#606a6c"
    />
  </svg>
</template>
