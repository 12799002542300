<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10.165" height="15.376" viewBox="0 0 10.165 15.376">
    <path
      id="Union_69"
      data-name="Union 69"
      d="M.431.2C-.2.654-.128,1.752.6,2.651L5.878,9.158a2.678,2.678,0,0,0,1.495.967A2.27,2.27,0,0,0,9.5,9.16l5.276-6.509c.729-.9.81-1.992.183-2.443S13.227.122,12.5,1.02L7.709,6.929,2.9,1A2.494,2.494,0,0,0,1.093,0,1.118,1.118,0,0,0,.431.2Z"
      transform="translate(0 15.376) rotate(-90)"
      fill="#606a6c"
    />
  </svg>
</template>
