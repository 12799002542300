<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="3" viewBox="0 0 100 3">
    <line
      id="Line_115"
      data-name="Line 115"
      x2="112"
      transform="translate(1.5 1.5)"
      fill="none"
      stroke="#707070"
      stroke-linecap="round"
      stroke-width="3"
      stroke-dasharray="10 10"
    />
  </svg>
</template>
