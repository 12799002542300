<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g>
      <path
        class="st8"
        d="M154.09,200.06c-2.17-2.94-5.53-4.77-9.18-5h-0.02c-11.22,1.55-22.58-1.55-31.46-8.58
		c-2.52-3.7-3.51-8.23-2.74-12.64c0.64-4.67,2.73-9.02,6-12.43c5.82-8.85,10.46-18.43,13.79-28.49c2.11-5.39,3.21-11.13,3.23-16.92
		V93.54c0.25-24.27-17.56-44.95-41.6-48.29c-26.06-3.1-49.7,15.52-52.8,41.58c-0.21,1.8-0.32,3.61-0.33,5.42V116
		c-0.01,6.63,1.38,13.18,4.09,19.23c1.22,2.69,2.53,5.37,3.64,8.18c2.5,6.48,6.1,12.48,10.63,17.74c3.65,3.87,5.87,8.86,6.33,14.16
		c0.44,3.95-0.58,7.93-2.87,11.19c-11.66,9.4-21.11,6.99-34.63,7.64c-3.96,0.2-7.58,2.28-9.75,5.59l-0.12,0.17
		c-2.68,3.98-2.82,9.15-0.37,13.27c14.78,24.51,41.3,39.5,69.92,39.55c28.24,0.33,54.61-14.1,69.55-38.07
		c2.61-4.34,2.31-9.83-0.75-13.86L154.09,200.06z M86.27,140.47h-0.8c-2.26,0-4.09-1.83-4.09-4.09s1.83-4.09,4.09-4.09h0.8
		c2.26,0,4.09,1.83,4.09,4.09S88.53,140.47,86.27,140.47z M101.55,103.35c-0.47,1.34-1.11,2.6-1.91,3.78
		c-0.94,1.36-1.96,2.66-3.04,3.92c-1.24,1.39-2.41,2.84-3.5,4.35c-0.94,1.41-1.67,2.95-2.18,4.56c-0.28,0.86-0.47,1.74-0.59,2.64
		c-0.3,2.02-2.04,3.52-4.09,3.5h-0.26c-2.25,0.05-4.12-1.73-4.18-3.99c-0.01-0.32,0.02-0.63,0.09-0.94
		c0.15-0.78,0.35-1.55,0.59-2.31c0.57-1.89,1.39-3.69,2.43-5.37c1.17-1.75,2.42-3.43,3.78-5.05c1.12-1.38,2.05-2.55,2.64-3.5
		c0.66-0.94,1.19-1.96,1.59-3.04c0.41-1.12,0.62-2.3,0.63-3.5c0.13-1.94-0.56-3.84-1.91-5.24c-1.42-1.31-3.32-1.97-5.24-1.84
		c-2.01-0.1-3.95,0.74-5.24,2.27c-1.35,1.89-2.02,4.17-1.91,6.48h-8.58c0-3,0.7-5.97,2.04-8.65c1.21-2.34,3.09-4.28,5.4-5.56
		c2.57-1.27,5.42-1.87,8.29-1.75c2.95-0.06,5.87,0.54,8.56,1.75c2.28,1.04,4.18,2.74,5.45,4.89c1.29,2.37,1.93,5.03,1.85,7.73
		C102.3,100.14,102.06,101.78,101.55,103.35z"
      />
      <path
        class="st8"
        d="M269.22,145.59l0.07,0.02l-50.69-29.28v19.1c-0.22,0.05-0.45,0.09-0.68,0.1c-5.47,0-10.92,0.1-16.4,0
		c-4.52-0.1-8.86-1.8-12.24-4.81c-3.63-3.24-6.76-6.99-9.28-11.15c-1.05-1.64-2.08-3.23-3.13-4.98l-0.33,0.51
		c-3.44,6.33-6.82,12.65-10.31,19.02c-0.15,0.4-0.11,0.85,0.1,1.22c3.84,5.5,8.66,10.24,14.23,13.98c4.9,3.4,10.61,5.46,16.55,5.98
		c5.07,0.28,10.19,0.28,15.28,0.28h6.22v19.23L269.22,145.59z"
      />
      <path
        class="st8"
        d="M176.39,91.98c3.22-6.56,7.67-12.44,13.13-17.3c2.86-2.58,6.49-4.13,10.33-4.4
		c5.49-0.19,10.98-0.19,16.48-0.19h2.18v19.23L269.24,60l-50.71-29.26v19.23h-1.4c-5.24,0-10.49-0.14-15.73,0
		c-2.91,0.04-5.81,0.36-8.65,0.96c-8.32,2.05-15.8,6.62-21.43,13.07c-5.05,5.56-9.37,11.75-12.85,18.41
		c-0.49,0.89-0.93,1.75-1.4,2.69v42.98c2.69-4.12,5.13-8.4,7.31-12.81C168.42,107.52,172.25,99.68,176.39,91.98z"
      />
      <path
        class="st8"
        d="M218.53,202.7h-2.18c-5.51,0-10.99,0-16.48-0.19c-3.84-0.28-7.47-1.83-10.33-4.4
		c-5.45-4.86-9.91-10.74-13.13-17.3c-4.14-7.69-7.97-15.54-12.01-23.28c-2.18-4.41-4.62-8.69-7.31-12.81v43
		c0.47,0.94,0.91,1.8,1.4,2.69c3.48,6.66,7.8,12.84,12.85,18.41c5.63,6.46,13.11,11.02,21.43,13.07c2.85,0.6,5.74,0.92,8.65,0.96
		c5.24,0.14,10.49,0,15.73,0h1.4v19.23l50.69-29.28l-50.71-29.31V202.7z"
      />
    </g>
  </svg>
</template>
