import { max, regex, required } from '@vee-validate/rules';
import { configure, defineRule, ErrorMessage, Field, Form } from 'vee-validate';
import { Plugin } from 'vue';
import { i18n } from '../i18n';

// global i18n injection
configure({
  // pattern: {context.field}_{context.rule.name}
  // - field => field's name or label
  // - rule.name => failed rule name
  //
  // e.g. field/label = "auth.form.email" | failed rule name = "required"
  // => 'auth.form.email_required'
  generateMessage: (context) => {
    return i18n.global.t(`${context.field}_${context.rule!.name}`).toString();
  },
});

// global vee-validate rules
defineRule('required', required);
defineRule('regex', regex);
defineRule('max', max);

// create vue 3 plugin
const plugin = <Plugin>{
  install(app) {
    app.component('vv-field', Field);
    app.component('vv-form', Form);
    app.component('vv-error', ErrorMessage);
  },
};

export { plugin as VeeValidatePlugin };
