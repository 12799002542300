import { DateTime } from 'luxon';

import MessageIcon from '@/assets/icons/modules/segment/customer-insights/MessageIcon.vue';
import LineGuardIcon from '@/assets/icons/modules/segment/customer-insights/LineGuardIcon.vue';
import Mail2Icon from '@/assets/icons/modules/segment/customer-insights/Mail2Icon.vue';

export default function () {
  function resolveBirthdate(datetime: string): string {
    return DateTime.fromISO(new Date(datetime).toISOString()).toFormat('dd LLL yyyy');
  }

  function resolveDateFilter(datetime: string): string | null {
    if (!datetime) return null;
    return DateTime.fromISO(new Date(datetime).toISOString()).toFormat('dd LLL yyyy');
  }

  function resolveDate(datetime: string): string {
    return DateTime.fromISO(new Date(datetime).toISOString()).toFormat('dd LLL yyyy');
  }

  function resolveDateTime(datetime: string): string {
    return DateTime.fromISO(new Date(datetime).toISOString()).toFormat('dd LLL yyyy, HH:mm:ss');
  }

  function isToday(datetime: string): boolean {
    return DateTime.fromISO(new Date(datetime).toISOString()).hasSame(DateTime.local(), 'day');
  }

  function resolveDateTimeDateLabel(datetime: string): string {
    if (DateTime.fromISO(new Date(datetime).toISOString()).hasSame(DateTime.local(), 'day')) {
      return 'Today';
    } else if (DateTime.fromISO(new Date(datetime).toISOString()).hasSame(DateTime.local().minus({ days: 1 }), 'day')) {
      return 'Yesterday';
    } else {
      return DateTime.fromISO(new Date(datetime).toISOString()).toFormat('dd LLL yyyy, HH:mm:ss');
    }
  }

  function resolveDateTimeMinuteLabel(datetime: string): string {
    interface DateDiffObject {
      days?: number;
      months?: number;
      years?: number;
      hours?: number;
      minutes?: number;
      seconds?: number;
    }

    /** use */
    const now = DateTime.now();
    const before = DateTime.fromISO(new Date(datetime).toISOString());

    /** test */
    // const now = DateTime.fromISO(new Date('2022-08-19T00:00:00.000Z').toISOString());
    // const before = DateTime.fromISO(new Date('2022-08-18T00:00:00.000Z').toISOString());

    const dateDiffObject: DateDiffObject = now.diff(before, ['years', 'months', 'days', 'hours', 'minutes', 'seconds']).toObject();
    const isToday = dateDiffObject.days === 0 && dateDiffObject.months === 0 && dateDiffObject.years === 0;

    if (isToday) {
      if (dateDiffObject.hours && dateDiffObject.hours >= 1) {
        return `${dateDiffObject.hours} hour ago`;
      } else {
        if (dateDiffObject.minutes && dateDiffObject.minutes >= 1) {
          return `${dateDiffObject.minutes} minute ago`;
        } else {
          if (dateDiffObject.seconds && dateDiffObject.seconds >= 1) {
            return `${dateDiffObject.seconds} second ago`;
          } else {
            return 'now';
          }
        }
      }
    } else {
      return '';
    }
  }

  function resolveChannelBackground(type: number): string {
    switch (type) {
      case 0:
        return '#FF43DB';
      case 1:
        return '#4FBA00';
      case 2:
        return '#00CEFF';
      default:
        return 'grey';
    }
  }

  function resolveChannelIcon(type: number): object {
    switch (type) {
      case 0:
        return MessageIcon;
      case 1:
        return LineGuardIcon;
      case 2:
        return Mail2Icon;
      default:
        return MessageIcon;
    }
  }

  return {
    resolveChannelBackground,
    resolveChannelIcon,
    resolveBirthdate,
    resolveDateFilter,
    resolveDate,
    resolveDateTime,
    resolveDateTimeDateLabel,
    resolveDateTimeMinuteLabel,
    isToday,
  };
}
