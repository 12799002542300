<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g id="Group_32732_00000080181029046991263690000003610462532243837363_" transform="translate(497.987 564.925)">
      <g id="Group_32713-2_00000013881039487881739050000006394600409063186304_" transform="translate(9.392 18.388)" class="st20">
        <g id="Group_32024-2_00000168797760340385554430000000775313812127521983_">
          <g>
            <g id="Group_32023-2_00000035492105816530202120000005572017253034436536_">
              <path
                id="Path_21469-2_00000159462172187980925040000003894503289138928003_"
                class="st8"
                d="M-458.13-381.25
						c-5.87,7.78-4.31,18.84,3.47,24.71c7.78,5.87,18.84,4.31,24.71-3.47c5.86-7.78,4.32-18.84-3.46-24.71
						C-441.19-390.58-452.25-389.04-458.13-381.25C-458.12-381.26-458.13-381.26-458.13-381.25"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Group_32714-2_00000020389322383214519460000004923777926234320541_" transform="translate(11.829 20.149)" class="st21">
        <g id="Group_32027-2_00000106859602795757481690000017398263014795087790_">
          <g>
            <g id="Group_32026-2_00000011715788820003856570000012292434757743766440_">
              <path
                id="Path_21470-2_00000179634301407796029210000016316911743886587273_"
                class="st8"
                d="M-418.84-353.26
						c-5.27,7-3.87,16.94,3.12,22.21s16.94,3.87,22.21-3.12c5.27-6.99,3.88-16.93-3.11-22.2c-6.99-5.28-16.94-3.89-22.22,3.1
						C-418.83-353.27-418.84-353.26-418.84-353.26"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Group_32715-2_00000183248189864526599160000007979959734642847378_" transform="translate(18.088 8.827)" class="st22">
        <g id="Group_32030-2_00000091695094611379482060000015476675696400263822_">
          <g>
            <g id="Group_32029-2_00000121268365613114257330000009167584077734589568_">
              <path
                id="Path_21471-2_00000064320458409717641410000005143049549150163128_"
                class="st8"
                d="M-319.04-542.2
						c-1.6,2.12-1.17,5.14,0.95,6.74c2.12,1.6,5.14,1.17,6.73-0.95c1.6-2.12,1.17-5.14-0.95-6.73c0,0,0,0-0.01,0
						c-2.11-1.6-5.12-1.18-6.71,0.93C-319.03-542.22-319.04-542.21-319.04-542.2"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Group_32716-2_00000089572847942802404860000013537053410402723969_" transform="translate(20.196 10.587)" class="st23">
        <g id="Group_32033-2_00000131329222503053579480000009917675148551591100_">
          <g>
            <g id="Group_32032-2_00000086679983617283654280000004320013909253091483_">
              <path
                id="Path_21472-2_00000003077511566126913430000011825516968996561845_"
                class="st8"
                d="M-284.32-512.88
						c-2.13,2.83-1.56,6.86,1.27,8.99c2.83,2.13,6.86,1.56,8.99-1.27c2.13-2.83,1.56-6.85-1.26-8.98
						C-278.16-516.27-282.18-515.7-284.32-512.88"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Group_32717-2_00000172404393120909952240000009434471204921034927_" transform="translate(19.906 18.376)" class="st24">
        <g id="Group_32036-2_00000090281873116761988080000017542309607109705114_">
          <g>
            <g id="Group_32035-2_00000060721619570982897480000006684551224832883635_">
              <path
                id="Path_21473-2_00000069374867872646945700000001627855976439649439_"
                class="st8"
                d="M-288.12-383.99
						c-3.74,4.95-2.75,11.99,2.2,15.72c4.95,3.74,11.99,2.75,15.72-2.2c3.74-4.95,2.75-11.99-2.2-15.72
						S-284.38-388.94-288.12-383.99L-288.12-383.99"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        id="Path_21903-2_00000084526127312870937240000001332729556291648173_"
        class="st8"
        d="M-462.97-504.99
		c-7.5,9.9-5.55,24.01,4.35,31.5c9.9,7.5,24.01,5.55,31.5-4.35c7.5-9.9,5.55-24.01-4.35-31.5c-0.02-0.01-0.04-0.03-0.06-0.04
		C-441.43-516.83-455.49-514.87-462.97-504.99"
      />
      <g id="Group_32718-2_00000062191047882138340410000012637247198401012403_" transform="translate(12.681 8.806)" class="st25">
        <g id="Group_32039-2_00000178205367575569678950000000661637914251852714_">
          <g>
            <g id="Group_32038-2_00000122000980756316285180000000489282748598019203_">
              <path
                id="Path_21475-2_00000086672613613518525950000005209124083895632013_"
                class="st8"
                d="M-407.86-543.88
						c0.47-0.62,1.36-0.75,1.99-0.27c0.62,0.47,0.75,1.36,0.27,1.99c-0.47,0.62-1.36,0.75-1.98,0.28
						C-408.21-542.36-408.34-543.25-407.86-543.88C-407.86-543.88-407.86-543.88-407.86-543.88"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Group_32719-2_00000108302939126802891420000006376283295640759483_" transform="translate(7.578 12.858)" class="st26">
        <g id="Group_32042-2_00000157995077206720350610000012475073607268683673_">
          <g>
            <g id="Group_32041-2_00000127017849638548088840000009569774827716329147_">
              <path
                id="Path_21476-2_00000069386863329177247660000016085952031038060709_"
                class="st8"
                d="M-458.13-474.19
						c9.15,6.9,10.98,19.92,4.07,29.07c-6.9,9.15-19.92,10.98-29.07,4.07c-9.15-6.9-10.98-19.92-4.07-29.07
						c0.09-0.13,0.19-0.25,0.29-0.37C-479.95-479.38-467.12-481.03-458.13-474.19"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Group_32720-2_00000170272054332275316270000008469928950708446351_" transform="translate(7.891 15.809)" class="st27">
        <g id="Group_32045-2_00000047743711734350099900000012144508733210911880_">
          <g>
            <g id="Group_32044-2_00000021119812678512424540000005026392426990723988_">
              <path
                id="Path_21477-2_00000174567984498320740590000012524850928146291344_"
                class="st8"
                d="M-455.45-426.28
						c8.29,6.46,9.78,18.41,3.32,26.7c-6.46,8.29-18.41,9.78-26.7,3.32c-8.24-6.42-9.77-18.27-3.43-26.56
						c6.38-8.35,18.32-9.95,26.67-3.57C-455.54-426.35-455.5-426.32-455.45-426.28"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Group_32721-2_00000096753413992753149780000002303756889442312064_" transform="translate(14.742 20.764)" class="st28">
        <g id="Group_32048-2_00000089532694709911595430000007152124227414595719_">
          <g>
            <g id="Group_32047-2_00000022530950470924547690000009112633965150629783_">
              <path
                id="Path_21478-2_00000036946955938810259240000012111753802936059018_"
                class="st8"
                d="M-371.64-343.82
						c4.74-6.32,13.7-7.61,20.03-2.87s7.61,13.7,2.87,20.03c-4.72,6.3-13.65,7.6-19.97,2.91c-6.34-4.66-7.7-13.57-3.05-19.9
						C-371.72-343.72-371.68-343.77-371.64-343.82"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Group_32722-2_00000179619554003259765200000006257335852989361832_" transform="translate(17.609 20.128)" class="st29">
        <g id="Group_32051-2_00000035520047112267796140000003366730453450577300_">
          <g>
            <g id="Group_32050-2_00000127749330088352028960000018190355143484066693_">
              <path
                id="Path_21479-2_00000041287286434195688920000003309295260989098626_"
                class="st8"
                d="M-307.44-357.37
						c5.6,4.23,6.72,12.21,2.48,17.81c-4.23,5.6-12.2,6.72-17.81,2.48c-5.55-4.19-6.7-12.07-2.58-17.68
						c4.21-5.66,12.22-6.84,17.89-2.62C-307.45-357.38-307.45-357.38-307.44-357.37"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Group_32723-2_00000039848871286481237190000007775420332832352690_" transform="translate(21.223 15.872)" class="st30">
        <g id="Group_32054-2_00000028296849388922268970000001063793891042287518_">
          <g>
            <g id="Group_32053-2_00000075844332806555444810000016504290570344003989_">
              <path
                id="Path_21480-2_00000170247689047825213780000001862533964331574916_"
                class="st8"
                d="M-253.64-427.36
						c4.17,3.21,4.95,9.19,1.74,13.37c-3.21,4.17-9.19,4.95-13.37,1.74c-4.17-3.21-4.95-9.19-1.74-13.37c0,0,0.01-0.01,0.01-0.01
						C-263.77-429.78-257.81-430.55-253.64-427.36"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Group_32724-2_00000041974886684508511470000006669424099559605679_" transform="translate(21.316 13.096)" class="st31">
        <g id="Group_32057-2_00000002368846394708336250000000241695028424045228_">
          <g>
            <g id="Group_32056-2_00000106846703179073608050000014777902828337675924_">
              <path
                id="Path_21481-2_00000153669538410116023920000013221144667242287506_"
                class="st8"
                d="M-264.24-460.26
						c-3.5-2.63-4.2-7.6-1.57-11.09c2.63-3.5,7.6-4.2,11.09-1.57c3.44,2.58,4.19,7.44,1.69,10.94c-2.54,3.56-7.49,4.39-11.05,1.85
						C-264.13-460.18-264.18-460.22-264.24-460.26"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Group_32725-2_00000054954927600862099490000003073598383200740530_" transform="translate(15.411 8.194)" class="st32">
        <g id="Group_32060-2_00000009577731942726943850000012569652114364054961_">
          <g>
            <g id="Group_32059-2_00000004513010333308689840000009306719547876128703_">
              <path
                id="Path_21482-2_00000025442247347849011420000017570093293929671081_"
                class="st8"
                d="M-363.07-553.16
						c1.08-1.36,3.02-1.64,4.45-0.66c1.4,1.05,1.69,3.03,0.64,4.43c-1,1.39-2.93,1.71-4.32,0.71c-0.02-0.02-0.05-0.04-0.07-0.05
						C-363.77-549.78-364.08-551.74-363.07-553.16"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Group_32844_00000037674302427779472020000012999758856015998118_" transform="translate(13.07 11.672)">
        <g>
          <g id="Group_32844-2_00000047033613497055486650000001805281501718749628_">
            <path
              id="Path_22312_00000088101435770380286860000003662670013382125704_"
              class="st8"
              d="M-351.74-443.71l19.7-10.97
					c1.7-0.92,2.77-2.7,2.79-4.64v-23.09h7.13c0.83,0,1.5-0.66,1.52-1.49v-12.32c0-0.84-0.68-1.52-1.52-1.52h-83.45
					c-0.84,0-1.52,0.68-1.52,1.52l0,0v12.32c0.02,0.83,0.69,1.49,1.52,1.49h7.13v23.08c0,1.95,1.06,3.74,2.77,4.67l19.72,10.78
					c1.4,0.77,2.38,2.13,2.66,3.7c-0.29,1.57-1.26,2.93-2.66,3.7l-19.72,10.79c-1.71,0.93-2.77,2.73-2.77,4.67v23.06h-7.13
					c-0.84,0-1.52,0.68-1.52,1.52v12.28c0,0.84,0.68,1.52,1.52,1.52h83.45c0.84,0,1.52-0.68,1.52-1.52v-12.28
					c0-0.84-0.68-1.52-1.52-1.52h-7.13v-23.09c0-1.93-1.05-3.71-2.73-4.65l-19.75-10.95c-1.32-0.73-2.26-1.99-2.59-3.46
					c0.33-1.47,1.28-2.73,2.59-3.46 M-355.59-429.62l18.37,10.22v21.43h-53.35v-21.54l18.3-10.05l0.66-0.4
					c2.08-1.32,3.33-3.62,3.32-6.09v-8.29c0.01-2.47-1.24-4.76-3.32-6.09l-0.66-0.4l-18.3-10.05v-21.55h53.42v21.57l-18.37,10.22
					c-0.38,0.21-0.74,0.43-1.09,0.67c-1.89,1.35-3.01,3.54-2.99,5.86v7.77c-0.02,2.33,1.1,4.51,2.99,5.86
					c0.35,0.24,0.71,0.47,1.09,0.67"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
