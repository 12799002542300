<template>
  <svg id="Group_37457" data-name="Group 37457" xmlns="http://www.w3.org/2000/svg" width="12.279" height="14.031" viewBox="0 0 12.279 14.031">
    <path id="Path_80" data-name="Path 80" d="M57.366,439.835h0Z" transform="translate(-51.226 -439.835)" fill="#c3c3c3" />
    <path
      id="Path_81"
      data-name="Path 81"
      d="M56.334,442.462c.487,0,.877-.365.877-1.091s-.548-1.534-.877-1.534c0,1.124-.877.9-.877,1.749v0A.875.875,0,0,0,56.334,442.462Z"
      transform="translate(-50.195 -439.836)"
      fill="#c3c3c3"
    />
    <path
      id="Path_82"
      data-name="Path 82"
      d="M63.966,442.461c.488,0,.877-.365.877-1.091s-.549-1.535-.877-1.535c0,1.124-.877.9-.877,1.755h0A.875.875,0,0,0,63.966,442.461Z"
      transform="translate(-54.32 -439.835)"
      fill="#c3c3c3"
    />
    <path
      id="Path_83"
      data-name="Path 83"
      d="M48.7,442.461c.487,0,.877-.365.877-1.091s-.548-1.535-.877-1.535c0,1.124-.877.9-.877,1.755h0A.876.876,0,0,0,48.7,442.461Z"
      transform="translate(-46.07 -439.835)"
      fill="#c3c3c3"
    />
    <path
      id="Path_84"
      data-name="Path 84"
      d="M54.968,449.585H54.31v-3.07H53v3.07H50.8v-3.07H49.488v3.07H47.3v-3.07H45.98v3.07h-.658a1.316,1.316,0,0,0-1.317,1.315v6.577H56.284V450.9A1.316,1.316,0,0,0,54.968,449.585Zm0,6.578H45.323v-2.217a1.884,1.884,0,0,0,.734.137c1.228,0,1.345-.877,2.047-.877.682,0,.83.877,2.046.877s1.352-.877,2.046-.877.827.877,2.046.877a1.859,1.859,0,0,0,.726-.137Z"
      transform="translate(-44.006 -443.445)"
      fill="#c3c3c3"
    />
  </svg>
</template>
