<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 193.377 156.753">
    <path
      d="M43.286,71.739a26.54,26.54,0,1,0,18.771,7.777,26.574,26.574,0,0,0-18.771-7.777m0,42.924A16.383,16.383,0,1,1,59.669,98.28a16.4,16.4,0,0,1-16.383,16.383"
      transform="translate(80.205 -30.671)"
    />
    <path
      d="M178.257,95.8a70.2,70.2,0,0,0-50.215-21.239l-1.369.056A70.027,70.027,0,0,0,78.7,95.079,35.2,35.2,0,0,0,49.194,79.11l-10.978.231L27.231,68.355a6.073,6.073,0,0,0-8.581,0L6.374,80.646a6.1,6.1,0,0,0,0,8.6l12.283,12.275a6.073,6.073,0,0,0,8.581,0L37.173,91.25H49.2a23.384,23.384,0,0,1,20.116,11.615l1.186,2.062a69.436,69.436,0,0,0-12.02,33.992H37.7V135.79a6.083,6.083,0,0,0-6.074-6.074H14.255a6.074,6.074,0,0,0-6.066,6.066v17.37a6.085,6.085,0,0,0,6.074,6.082H31.641a6.09,6.09,0,0,0,6.074-6.082v-3.359H58.46a69.307,69.307,0,0,0,12.347,34.637l-1.512,2.619a23.391,23.391,0,0,1-20.132,11.622l-11.455.231-10.5-10.492a6.054,6.054,0,0,0-8.581,0L6.342,200.693a6.073,6.073,0,0,0,0,8.581l12.283,12.283a6.073,6.073,0,0,0,8.581,0l10.42-10.747H49.17A35.636,35.636,0,0,0,79.1,194.252l.279.3a73.065,73.065,0,0,0,6.822,5.763,69.48,69.48,0,0,0,83.681,0,71.587,71.587,0,0,0,6.822-5.771A69.821,69.821,0,0,0,178.257,95.8M128.09,174.176A44.964,44.964,0,0,1,163.9,192.063a59.2,59.2,0,0,1-71.708,0,45.062,45.062,0,0,1,35.894-17.888m-.1-10.134a54.994,54.994,0,0,0-43.409,21.279,59.7,59.7,0,1,1,86.9,0,54.573,54.573,0,0,0-43.5-21.279"
      transform="translate(-4.567 -66.58)"
    />
  </svg>
</template>
