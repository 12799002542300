<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250">
    <g id="Group_51452" data-name="Group 51452" transform="translate(-0.233)">
      <g id="Rectangle_17821" data-name="Rectangle 17821" transform="translate(0.233)" fill="rgba(96,106,108,0.02)" stroke="#606a6c" stroke-width="1">
        <rect width="250" height="250" rx="20" stroke="none" />
        <rect x="0.5" y="0.5" width="249" height="249" rx="19.5" fill="none" />
      </g>
      <g id="Group_51445" data-name="Group 51445" transform="translate(0 125)">
        <line id="Line_8" data-name="Line 8" x1="250" transform="translate(0.233)" fill="none" stroke="#606a6c" stroke-width="1" />
      </g>
      <g id="Group_51446" data-name="Group 51446" transform="translate(166.5) rotate(90)">
        <line id="Line_7" data-name="Line 7" x1="250" fill="none" stroke="#606a6c" stroke-width="1" />
        <line id="Line_8-2" data-name="Line 8" x1="250" transform="translate(0 83)" fill="none" stroke="#606a6c" stroke-width="1" />
      </g>
    </g>
  </svg>
</template>
