<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g>
      <path
        class="st8"
        d="M49.54,222.4v-0.04l18.19-27.72c-8.3-1.32-16.04-5.06-22.24-10.73c-8.33-7.5-12.93-18.28-12.59-29.47
		c-0.74-10.44,2.75-20.73,9.68-28.57c4.89-5.77,11.92-9.3,19.47-9.78c10.78,0,23.52,9.75,38.23,29.25l28.3-43
		c-11.54-14.69-29.03-23.47-47.71-23.94c-18.2-0.14-35.51,7.84-47.22,21.77c-24.97,31.11-26.03,75.09-2.59,107.37
		C36.13,213.72,42.42,218.77,49.54,222.4z"
      />
      <polygon class="st8" points="224.87,72.16 224.88,72.16 224.91,72.12 	" />
      <path
        class="st8"
        d="M269.24,152.96c0.37-20.82-5.51-41.28-16.88-58.73c-6.44-10.22-16.11-17.99-27.48-22.07l-20.19,30.66
		c25.78,3.06,45.4,24.59,46.07,50.54c0.12,11.22-3.54,22.16-10.39,31.05c-5.76,8.19-15.15,13.07-25.17,13.08
		c-9.02-0.51-17.55-4.23-24.06-10.49c-8.88-8.42-16.77-17.82-23.51-28.04l-28.3,42.82c7.51,10.2,16.67,19.08,27.09,26.28
		c10.31,6.44,22.21,9.9,34.37,9.99c19.04-0.19,36.89-9.28,48.23-24.56C262.78,196.37,269.95,174.9,269.24,152.96z"
      />
      <path
        class="st8"
        d="M248.91,41.23c6.93,0,12.55-5.62,12.55-12.55c0-6.93-5.62-12.55-12.55-12.55h-40.09L59.71,242.24H32.72
		c-6.93,0-12.55,5.62-12.55,12.55c0,6.93,5.62,12.55,12.55,12.55h39.99L222.04,41.23H248.91z"
      />
    </g>
  </svg>
</template>
