<template>
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M70 0H10C4.47715 0 0 4.47715 0 10V70C0 75.5229 4.47715 80 10 80H70C75.5229 80 80 75.5229 80 70V10C80 4.47715 75.5229 0 70 0Z"
      fill="white"
    />
    <path
      d="M70 0.5H10C4.75329 0.5 0.5 4.75329 0.5 10V70C0.5 75.2467 4.75329 79.5 10 79.5H70C75.2467 79.5 79.5 75.2467 79.5 70V10C79.5 4.75329 75.2467 0.5 70 0.5Z"
      stroke="#0277FF"
    />
    <path d="M54 35.626H26V43.626H54V35.626Z" fill="#0277FF" />
    <path d="M43.292 53.5318V26.4668H35.774V53.5318H43.292Z" fill="#0277FF" />
  </svg>
</template>
