<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18.001" height="18" viewBox="0 0 18.001 18">
    <defs>
      <clipPath id="clip-path">
        <rect width="18.001" height="18" />
      </clipPath>
    </defs>
    <g clip-path="url(#clip-path)">
      <path
        d="M14.722,7.779,4.948,17.552.936,18A.844.844,0,0,1,0,17.062l.446-4.015,9.77-9.769Zm2.784-2.784L15.518,6.983l-4.5-4.5L13.005.5a1.688,1.688,0,0,1,2.388,0l2.113,2.113a1.688,1.688,0,0,1,0,2.387"
        transform="translate(0 0)"
      />
    </g>
  </svg>
</template>
