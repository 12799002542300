<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.517" height="20.211" viewBox="0 0 17.517 20.211">
    <path
      id="Path_21464"
      data-name="Path 21464"
      d="M12.838,42.835a3,3,0,0,0-2.914,3.081v1.232h5.827V45.916a3,3,0,0,0-2.913-3.081m-1.515,2.916a1.61,1.61,0,0,1,1.514-1.517,1.615,1.615,0,0,1,1.513,1.517Z"
      transform="translate(-4.08 -38.933)"
    />
    <path
      id="Path_21465"
      data-name="Path 21465"
      d="M16.209,42.572V42a7.244,7.244,0,0,0-7.45-7,7.244,7.244,0,0,0-7.45,7v.571A3.152,3.152,0,0,0,0,45.106v6.8A3.419,3.419,0,0,0,3.53,55.211h10.5a3.4,3.4,0,0,0,3.482-3.271V45.124a3.165,3.165,0,0,0-1.307-2.552m-.26,9.367A1.914,1.914,0,0,1,13.987,53.8H3.53a1.914,1.914,0,0,1-1.961-1.863V45.106a1.875,1.875,0,0,1,1.307-1.757V42a5.749,5.749,0,0,1,5.883-5.591A5.749,5.749,0,0,1,14.641,42v1.349a1.875,1.875,0,0,1,1.307,1.757Z"
      transform="translate(0 -35)"
    />
    <path
      id="Path_21466"
      data-name="Path 21466"
      d="M14.339,59.085a1.543,1.543,0,0,0,0,3.081,1.543,1.543,0,0,0,0-3.081"
      transform="translate(-5.58 -47.098)"
    />
  </svg>
</template>
