<template>
  <svg
    id="Component_1748_369"
    data-name="Component 1748 – 369"
    xmlns="http://www.w3.org/2000/svg"
    width="16.012"
    height="16.012"
    viewBox="0 0 16.012 16.012"
  >
    <rect id="Rectangle_20306" data-name="Rectangle 20306" width="3" height="16.012" transform="translate(6.506)" fill="#007fff" />
    <rect id="Rectangle_20307" data-name="Rectangle 20307" width="3" height="16.012" transform="translate(16.012 6.506) rotate(90)" fill="#007fff" />
  </svg>
</template>
