import { Directive, DirectiveBinding } from 'vue';

const vPermission: Directive = {
  mounted: (el, binding) => {
    if (!binding.value) {
      el.parentNode.removeChild(el);
    }
  },
};

export default vPermission;
