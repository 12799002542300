export interface Color {
  seq: number;
  colorHex: string;
}

export interface ColorPanel {
  seq: number;
  label: string;
}

export const STATIC_COLOR_LIST: Color[] = [
  { seq: 1, colorHex: '#000000' },
  { seq: 2, colorHex: '#434343' },
  { seq: 3, colorHex: '#666666' },
  { seq: 4, colorHex: '#B7B7B7' },
  { seq: 5, colorHex: '#CCCCCC' },
  { seq: 6, colorHex: '#DBDBDB' },
  { seq: 7, colorHex: '#F3F3F3' },
  { seq: 8, colorHex: '#FFFFFF' },
  { seq: 9, colorHex: '#FF0000' },
  { seq: 10, colorHex: '#FF9D00' },
  { seq: 11, colorHex: '#FFEB00' },
  { seq: 12, colorHex: '#3CFF00' },
  { seq: 13, colorHex: '#00FBFF' },
  { seq: 14, colorHex: '#0031FF' },
  { seq: 15, colorHex: '#8500FF' },
  { seq: 16, colorHex: '#EE00FF' },
  { seq: 17, colorHex: '#FFDEDE' },
  { seq: 18, colorHex: '#FFE4B9' },
  { seq: 19, colorHex: '#FFF9B4' },
  { seq: 20, colorHex: '#CBFFBA' },
  { seq: 21, colorHex: '#9DFDFF' },
  { seq: 22, colorHex: '#AFD7FF' },
  { seq: 23, colorHex: '#E8D0FF' },
  { seq: 24, colorHex: '#FABAFF' },
  { seq: 25, colorHex: '#FFA8A8' },
  { seq: 26, colorHex: '#FDC66E' },
  { seq: 27, colorHex: '#FDF491' },
  { seq: 28, colorHex: '#A0FF81' },
  { seq: 29, colorHex: '#68F7FA' },
  { seq: 30, colorHex: '#72B6FA' },
  { seq: 31, colorHex: '#C489FC' },
  { seq: 32, colorHex: '#F07EF8' },
  { seq: 33, colorHex: '#FF5555' },
  { seq: 34, colorHex: '#F8AB30' },
  { seq: 35, colorHex: '#FCEE57' },
  { seq: 36, colorHex: '#67E240' },
  { seq: 37, colorHex: '#19D5D9' },
  { seq: 38, colorHex: '#188FF5' },
  { seq: 39, colorHex: '#9650D8' },
  { seq: 40, colorHex: '#D059BD' },
  { seq: 41, colorHex: '#CE0000' },
  { seq: 42, colorHex: '#DB9018' },
  { seq: 43, colorHex: '#D8CB3E' },
  { seq: 44, colorHex: '#229000' },
  { seq: 45, colorHex: '#00ADAF' },
  { seq: 46, colorHex: '#0055B7' },
  { seq: 47, colorHex: '#5D1B9A' },
  { seq: 48, colorHex: '#950F9F' },
  { seq: 49, colorHex: '#720000' },
  { seq: 50, colorHex: '#694000' },
  { seq: 51, colorHex: '#4E4800' },
  { seq: 52, colorHex: '#0F4100' },
  { seq: 53, colorHex: '#007172' },
  { seq: 54, colorHex: '#001D4C' },
  { seq: 55, colorHex: '#39006E' },
  { seq: 56, colorHex: '#5D0A6C' },
];

export const COLOR_PANELS: ColorPanel[] = [
  { seq: 1, label: 'Simple' },
  { seq: 2, label: 'Advance' },
  { seq: 3, label: 'Shade' },
  { seq: 4, label: 'Random' },
];
