export default function () {
  const fetchCustomerInsightCInfoListPreview: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSCustomerInfo/GetListPreview`,
    method: 'GET',
    payload: {},
  };

  const fetchCustomerInsightCInfoById: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSCustomerInfo/GetListData`,
    method: 'POST',
    payload: {
      CID: null!,
    },
  };

  const fetchCustomerInsightInfoInitialColumn: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSCustomerInfo/InitialColumnId`,
    method: 'GET',
    payload: {},
  };

  const fetchCustomerInsightInfoListProperties: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSCustomerInfo/GetListProperties`,
    method: 'GET',
    payload: {},
  };

  const fetchCustomerInsightInfoPDPAHistory: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSCustomerInfo/GetListPDPAHistory`,
    method: 'POST',
    payload: { CID: null! },
  };

  /**New Phase */
  const fetchCustomerInsightInfoGetListPropertiesByGroup: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSCustomerInfo/GetListPropertiesByGroup`,
    method: 'POST',
    payload: {
      group: null!,
    },
  };

  const fetchCustomerInsightInfoSavePropertiesListAll: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSCustomerInfo/SavePropertiesListAll`,
    method: 'POST',
    payload: { result: [] },
  };

  const fetchCustomerInsightInfoCancelSettingProperties: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSCustomerInfo/CancelSettingProperties`,
    method: 'GET',
    payload: { result: [] },
  };

  const fetchCustomerInsightInfoEditSection: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSCustomerInfo/EditSection`,
    method: 'POST',
    payload: {
      group: null!,
    },
  };

  const fetchCustomerInsightInfoSavePropertiesByGroup: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSCustomerInfo/SavePropertiesByGroup`,
    method: 'POST',
    payload: [],
  };

  const fetchCustomerInsightInfoAddSection: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSCustomerInfo/AddSection`,
    method: 'POST',
    payload: {},
  };

  const fetchCustomerInsightInfoCopySection: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSCustomerInfo/CopySection`,
    method: 'POST',
    payload: {
      Result: [],
      group: null!,
    },
  };

  const fetchCustomerInsightInfoDeleteSection: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSCustomerInfo/DeleteSection`,
    method: 'POST',
    payload: {
      group: null!,
    },
  };

  const fetchCustomerInsightInfoDuplicationPropertiesListAll: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSCustomerInfo/DuplicatePropertiesListAll`,
    method: 'GET',
    payload: {},
  };

  const fetchCustomerInsightInfoSavePropertiesListSortByGroup: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSCustomerInfo/SavePropertiesListSortByGroup`,
    method: 'POST',
    payload: { Result: [] },
  };

  return {
    fetchCustomerInsightCInfoListPreview,
    fetchCustomerInsightCInfoById,
    fetchCustomerInsightInfoInitialColumn,
    fetchCustomerInsightInfoListProperties,
    fetchCustomerInsightInfoPDPAHistory,
    fetchCustomerInsightInfoSavePropertiesListAll,
    fetchCustomerInsightInfoCancelSettingProperties,
    fetchCustomerInsightInfoGetListPropertiesByGroup,
    fetchCustomerInsightInfoSavePropertiesByGroup,
    fetchCustomerInsightInfoAddSection,
    fetchCustomerInsightInfoCopySection,
    fetchCustomerInsightInfoDeleteSection,
    fetchCustomerInsightInfoEditSection,
    fetchCustomerInsightInfoDuplicationPropertiesListAll,
    fetchCustomerInsightInfoSavePropertiesListSortByGroup,
  };
}
