<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 51.61 45.13">
    <g id="Group_44662">
      <g id="Group_44660">
        <path
          id="Path_28671"
          d="M48.05,0H3.56C1.6,0,0,1.59,0,3.55H0V41.57c0,1.96,1.59,3.56,3.55,3.56H48.05c1.97,0,3.56-1.59,3.56-3.56h0V3.56C51.61,1.59,50.01,0,48.05,0h0Zm0,3.48l.02,8.52H3.5l-.02-8.44,44.57-.08Zm0,38.17H3.56l-.05-26.2H48.07l.06,26.12-.08,.08Z"
        />
        <path id="Path_28672" d="M44.32,20.25H27.27c-.86,0-1.56,.7-1.56,1.56s.7,1.56,1.56,1.56h17.05c.86,0,1.56-.7,1.56-1.56s-.7-1.56-1.56-1.56Z" />
        <path id="Path_28673" d="M44.32,26.99H27.27c-.86,0-1.56,.7-1.56,1.56s.7,1.56,1.56,1.56h17.05c.86,0,1.56-.7,1.56-1.56s-.7-1.56-1.56-1.56Z" />
        <path id="Path_28674" d="M44.32,33.73H27.27c-.86,0-1.56,.7-1.56,1.56s.7,1.56,1.56,1.56h17.05c.86,0,1.56-.7,1.56-1.56s-.7-1.56-1.56-1.56Z" />
        <path
          id="Path_28675"
          d="M6.89,9.22h2.97c.66-.04,1.16-.61,1.11-1.27-.04-.6-.52-1.08-1.11-1.11h-2.97c-.66-.04-1.23,.46-1.27,1.11-.04,.66,.46,1.23,1.11,1.27,.05,0,.11,0,.16,0Z"
        />
        <path
          id="Path_28676"
          d="M14.73,9.22h2.97c.66,.04,1.23-.46,1.27-1.11,.04-.66-.46-1.23-1.11-1.27-.05,0-.11,0-.16,0h-2.97c-.66,.04-1.16,.61-1.11,1.27,.04,.6,.52,1.08,1.11,1.11Z"
        />
        <path
          id="Path_28677"
          d="M22.57,9.22h2.97c.66-.04,1.16-.61,1.11-1.27-.04-.6-.52-1.08-1.11-1.11h-2.97c-.66,.04-1.16,.61-1.11,1.27,.04,.6,.52,1.08,1.11,1.11Z"
        />
        <path
          id="Path_28678"
          d="M13.96,20.58c-4.41,0-7.98,3.56-7.99,7.96s3.56,7.98,7.96,7.99c4.41,0,7.98-3.56,7.99-7.96v-.03c0-4.4-3.57-7.96-7.96-7.96Zm3.59,8.55l-4.64,2.8c-.32,.19-.72,.09-.91-.23-.06-.1-.09-.22-.09-.34v-5.59c0-.37,.3-.67,.66-.67h0c.12,0,.24,.03,.35,.1l4.65,2.8c.31,.19,.41,.6,.22,.92-.05,.09-.13,.16-.22,.22h0Z"
        />
      </g>
    </g>
  </svg>
</template>
