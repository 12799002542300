import { getCurrentInstance, ref, watch, Ref, onBeforeMount } from 'vue';

import router from '@/router';
import emptyImage from '@/assets/images/modules/data-connect/coupon-management/empty-image.png';

interface Props {
  resultData?: Coupon.ValidateCouponResponse;
  resultReference?: string;
  isEdit?: boolean;
  isOpenForm?: boolean;
  detailDataEdit?: any;
  isHaveCouponCode?: boolean;
}

enum StatusCoupon {
  Active = 2,
  Inactive = 3,
}

export default function useFormUploadAndResult(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const inputUploadRef: Ref<HTMLDivElement> = ref(null!);
  const imageFile: Ref<string> = ref('');
  const imageFileName: Ref<string> = ref('');
  const imageAddress: Ref<any> = ref(null);
  const listErrorReport: Ref<number[]> = ref([]);
  const isHaveImage: Ref<boolean> = ref(false);
  const isEditImage: Ref<boolean> = ref(false);
  const couponName: Ref<string> = ref('');
  const couponDescription: Ref<string> = ref('');
  const valueReference: Ref<number> = ref(0);
  const couponStatus: Ref<number> = ref(StatusCoupon.Active);

  const onUploadImage = (file: any) => {
    if (file.target.files.length != 0) {
      const fileUpload = file.target.files[0];
      imageFile.value = URL.createObjectURL(fileUpload);
      isHaveImage.value = true;
      if (!props.isEdit) {
        imageFileName.value = 'CDP_Coupon_Image_' + router.currentRoute.value.query.fileId;
      } else {
        imageFileName.value = 'CDP_Coupon_Image_' + router.currentRoute.value.query.couponId;
        isEditImage.value = true;
      }
      imageAddress.value = fileUpload;
    }
  };

  const confirmFormData = () => {
    if (props.isEdit) {
      const payload_Edit = {
        Image_Name: imageFileName.value,
        Image: imageAddress.value,
        Name: couponName.value,
        Description: couponDescription.value,
        Value_Reference: valueReference.value,
        Status: couponStatus.value,
        Edit_Image: isEditImage.value,
      };
      return payload_Edit;
    } else {
      const payload_Create = {
        Image_Name: !imageFileName.value ? 'CDP_Coupon_Image_Empty_' + router.currentRoute.value.query.fileId : imageFileName.value,
        Image: imageAddress.value,
        Name: couponName.value,
        Description: couponDescription.value,
        Value_Reference: valueReference.value,
        Status: couponStatus.value,
      };
      return payload_Create;
    }
  };

  const resolveToggle = (event: any) => {
    switch (event.target.checked) {
      case false:
        couponStatus.value = StatusCoupon.Inactive;
        break;
      default:
        couponStatus.value = StatusCoupon.Active;
        break;
    }
  };

  const onDelImage = () => {
    isHaveImage.value = false;
    if (!props.isEdit) {
      imageFileName.value = 'CDP_Coupon_Image_Empty_' + router.currentRoute.value.query.fileId;
    } else {
      imageFileName.value = 'CDP_Coupon_Image_Empty_' + router.currentRoute.value.query.couponId;
      isEditImage.value = true;
    }
    notChooseImage();
  };

  const notChooseImage = () => {
    convertFileBase('data:text/plain;base64,aGVsbG8gd29ybGQ=', emptyImage, 'text/plain').then(function (file) {
      imageAddress.value = file;
    });
  };

  const convertFileBase = async (url: string, filename: string, mimeType: any) => {
    const res = await fetch(url);
    const buf = await res.arrayBuffer();
    return new File([buf], filename, { type: mimeType });
  };

  const fetchResultEdit = () => {
    couponName.value = props.detailDataEdit.couponName;
    couponDescription.value = props.detailDataEdit.description;
    valueReference.value = props.detailDataEdit.valueReference;
    couponStatus.value = props.detailDataEdit.status == true ? StatusCoupon.Active : StatusCoupon.Inactive;
    imageFile.value = props.detailDataEdit.photo;
    if (
      props.detailDataEdit.photoName == String('CDP_Coupon_Image_Empty_' + router.currentRoute.value.query.couponId) ||
      props.detailDataEdit.photoName == null
    ) {
      isHaveImage.value = false;
      imageFileName.value = 'CDP_Coupon_Image_Empty_' + router.currentRoute.value.query.couponId;
    } else {
      isHaveImage.value = true;
      imageFileName.value = props.detailDataEdit.photoName;
    }
  };

  const setListErrorReport = () => {
    props.resultData?.error_report.forEach((el: Coupon.ListErrorReport) => {
      listErrorReport.value.push(el.row);
    });
    const unique = listErrorReport.value.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    listErrorReport.value = unique;
  };

  watch(
    () => couponName.value,
    () => {
      if (couponName.value.length == 0 && !props.isEdit) {
        vm?.$emit('on-submit-status', true);
      } else {
        vm?.$emit('on-submit-status', false);
      }
    },
    { deep: true },
  );

  watch(
    () => props.isOpenForm,
    () => {
      isEditImage.value = false;
      if (props.isOpenForm && props.isEdit) {
        fetchResultEdit();
      }
    },
    { deep: true },
  );

  onBeforeMount(() => {
    notChooseImage();
    setListErrorReport();
  });

  return {
    imageFile,
    inputUploadRef,
    isHaveImage,
    couponName,
    couponDescription,
    valueReference,
    couponStatus,
    onUploadImage,
    onDelImage,
    confirmFormData,
    resolveToggle,
    listErrorReport,
  };
}
