<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21" height="23" viewBox="0 0 21 23">
  <defs>
   
    <clipPath id="clip-path">
      <rect id="Rectangle_433" data-name="Rectangle 433" class="cls-1" width="21" height="23"/>
    </clipPath>
  </defs>
  <g id="Group_29275" data-name="Group 29275" transform="translate(-1684 -354)">
    <g id="Group_599" data-name="Group 599" transform="translate(1684 354)">
      <g id="Group_599-2" data-name="Group 599" class="cls-2">
        <path id="Path_302" data-name="Path 302" class="cls-1" d="M20.724,5.129,15.924.581A2.12,2.12,0,0,0,14.466,0H1.257A1.258,1.258,0,0,0,0,1.258V21.41a1.258,1.258,0,0,0,1.257,1.257H20.184a1.257,1.257,0,0,0,1.258-1.257V6.8a2.3,2.3,0,0,0-.717-1.667M5.36,4.892a.612.612,0,0,1,.619-.6h9.189a.612.612,0,0,1,.619.6V9.2a.611.611,0,0,1-.619.6H5.98a.611.611,0,0,1-.619-.6ZM18.724,19.636a.581.581,0,0,1-.581.581H3.3a.58.58,0,0,1-.58-.581v-5.98a.581.581,0,0,1,.58-.581H18.143a.581.581,0,0,1,.581.581Z"/>
        <rect id="Rectangle_432" data-name="Rectangle 432" class="cls-1" width="1.838" height="4.288" transform="translate(12.725 4.901)"/>
      </g>
    </g>
  </g>
</svg>
</template>

<style lang="scss" scoped>
@import '@assets/styles/base/button.scss';
</style>