<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20.049" height="17.091" viewBox="0 0 20.049 17.091">
    <path
      id="Path_21462"
      data-name="Path 21462"
      d="M66.925,0H53.1A3.154,3.154,0,0,0,50,3.184v10.7a3.165,3.165,0,0,0,3.122,3.208H66.951a3.155,3.155,0,0,0,3.1-3.184V3.208A3.166,3.166,0,0,0,66.925,0m1.725,13.906a1.758,1.758,0,0,1-1.725,1.787h-13.8A1.758,1.758,0,0,1,51.4,13.906V3.184A1.758,1.758,0,0,1,53.123,1.4h13.8a1.758,1.758,0,0,1,1.725,1.787Z"
      transform="translate(-50)"
    />
    <path
      id="Path_21463"
      data-name="Path 21463"
      d="M70.063,9.183A.7.7,0,0,0,69.085,9l-5.993,3.825L57.163,9l-.114-.064a.705.705,0,0,0-.881.277.761.761,0,0,0,.207,1.031L62.7,14.335l.125.068a.7.7,0,0,0,.664-.068l6.388-4.094.1-.083a.765.765,0,0,0,.105-.947l-.017-.027"
      transform="translate(-53.068 -4.5)"
    />
  </svg>
</template>
