<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="36.292" height="36.292" viewBox="0 0 36.292 36.292">
    <g id="Group_45604" data-name="Group 45604" transform="translate(4920.256 8069.8)">
      <g id="Group_45600" data-name="Group 45600">
        <rect id="Rectangle_17434" data-name="Rectangle 17434" width="36.292" height="36.292" transform="translate(-4920.256 -8069.8)" />
      </g>
      <g id="Group_46034" data-name="Group 46034" transform="translate(-4913.694 -8058.408)">
        <rect id="Rectangle_17698" data-name="Rectangle 17698" width="1.725" height="13.511" transform="translate(0 -0.001)" fill="#fff" />
        <rect id="Rectangle_17699" data-name="Rectangle 17699" width="1.724" height="13.511" transform="translate(20.582 -0.001)" fill="#fff" />
        <rect id="Rectangle_17700" data-name="Rectangle 17700" width="1.725" height="13.511" transform="translate(9.919 -0.001)" fill="#fff" />
        <rect id="Rectangle_17701" data-name="Rectangle 17701" width="3.683" height="13.511" transform="translate(4.704 -0.001)" fill="#fff" />
        <rect id="Rectangle_17702" data-name="Rectangle 17702" width="0.527" height="13.511" transform="translate(2.396 -0.001)" fill="#fff" />
        <rect id="Rectangle_17703" data-name="Rectangle 17703" width="0.528" height="13.511" transform="translate(11.979 -0.001)" fill="#fff" />
        <rect id="Rectangle_17704" data-name="Rectangle 17704" width="0.527" height="13.511" transform="translate(9.057 -0.001)" fill="#fff" />
        <rect id="Rectangle_17705" data-name="Rectangle 17705" width="0.527" height="13.511" transform="translate(22.642 -0.001)" fill="#fff" />
        <rect id="Rectangle_17706" data-name="Rectangle 17706" width="0.527" height="13.511" transform="translate(19.719 -0.001)" fill="#fff" />
        <rect id="Rectangle_17707" data-name="Rectangle 17707" width="3.174" height="13.511" transform="translate(14.43 -0.001)" fill="#fff" />
        <rect id="Rectangle_17708" data-name="Rectangle 17708" width="0.971" height="13.511" transform="translate(18.221 -0.001)" fill="#fff" />
        <rect id="Rectangle_17709" data-name="Rectangle 17709" width="0.971" height="13.511" transform="translate(12.842 -0.001)" fill="#fff" />
        <rect id="Rectangle_17710" data-name="Rectangle 17710" width="0.527" height="13.511" transform="translate(3.594 -0.001)" fill="#fff" />
      </g>
    </g>
  </svg>
</template>
