<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="679" height="284" viewBox="0 0 679 284">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_50810"
          data-name="Rectangle 50810"
          width="679"
          height="284"
          rx="18"
          transform="translate(560 477)"
          fill="#fff"
          stroke="#707070"
          stroke-width="1"
        />
      </clipPath>
      <filter id="Union_266-1" x="-58.771" y="105.763" width="600.847" height="256.879" filterUnits="userSpaceOnUse">
        <feOffset dy="-15" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="12.5" result="blur" />
        <feFlood flood-color="#335980" flood-opacity="0.051" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_268-3" x="-58.771" y="128.155" width="592.303" height="251.098" filterUnits="userSpaceOnUse">
        <feOffset dy="-15" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="12.5" result="blur-2" />
        <feFlood flood-color="#335980" flood-opacity="0.051" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Mask_Group_98834" data-name="Mask Group 98834" transform="translate(-560 -477)" clip-path="url(#clip-path)">
      <g transform="matrix(1, 0, 0, 1, 560, 477)" filter="url(#Union_266-1)">
        <path
          id="Union_266-2"
          data-name="Union 266"
          d="M282.374,141.285a50.941,50.941,0,0,1-91.8-3.042A53.9,53.9,0,0,1,102.6,126.5a40.13,40.13,0,0,1-53.1-29.6c-.388.009-.778.014-1.167.014A48.454,48.454,0,1,1,96.676,48.453c0,.258,0,.517-.006.774a39.984,39.984,0,0,1,20.008,10.524,53.911,53.911,0,0,1,84.11,21.991A50.875,50.875,0,0,1,269.906,78.3,87.722,87.722,0,0,1,423.118,36.665a54.019,54.019,0,1,1,48.756,77.374,53.605,53.605,0,0,1-28.55-8.184,87.739,87.739,0,0,1-160.95,35.431Z"
          transform="translate(504.57 340.14) rotate(180)"
          fill="#fff"
        />
      </g>
      <g transform="matrix(1, 0, 0, 1, 560, 477)" filter="url(#Union_268-3)">
        <path
          id="Union_268-2"
          data-name="Union 268"
          d="M273.831,135.5a50.941,50.941,0,0,1-91.8-3.042,53.9,53.9,0,0,1-87.97-11.745,40.117,40.117,0,0,1-49.833-20.068A40.25,40.25,0,1,1,76.16,42.879a40.428,40.428,0,0,1,4.095-.207,39.933,39.933,0,0,1,27.878,11.3,53.911,53.911,0,0,1,84.11,21.991,50.875,50.875,0,0,1,69.119-3.447A87.722,87.722,0,0,1,414.574,30.884a54.019,54.019,0,1,1,48.756,77.374,53.605,53.605,0,0,1-28.55-8.184A87.739,87.739,0,0,1,273.831,135.5Z"
          transform="translate(496.03 356.75) rotate(180)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>
