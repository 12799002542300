import { DateTime, Zone } from 'luxon';
import { computed, ref, Ref, unref, watch } from 'vue';
import { isUseDateTimeObject, useDateTime, UseDateTimeObject } from '../date-time';
import { CHOCO_CALENDAR_OBJECT } from '../identifier';

interface CalendarDayItem {
  value: DateTime;
  selectable: boolean;
  outer: boolean;
}

/**
 * Selectable function parameters
 */
export type SelectableFunctionParams = { year: number; month: number; day: number };

/**
 * Selectable function
 *
 * Callback for determine if day item in calendar is selectable or not
 */
export type SelectableFunctionType = (date: SelectableFunctionParams) => boolean;

/**
 * Calendar composition
 *
 * @param init Initial view date, null for default
 * @param timezone view/working timezone, IANA identifier or offset in minutes
 */
function this_useCalendar(
  init: UseDateTimeObject | Ref<Date | string> | Date | string | null = null,
  timezone?: Ref<Zone | string | number | null> | Zone | string | number | null,
) {
  // for calendar navigation
  const navDt = isUseDateTimeObject(init) ? init : useDateTime(init);
  // set timezone
  navDt.setTimezone(unref(timezone) ?? navDt.timezone.value);

  // calendar navigation, watch changes on date model
  const navigation = ref<'increment' | 'decrement' | null>(null);
  watch(
    () => navDt.date.value,
    (newVal, oldVal) => {
      if (newVal.getTime() < oldVal.getTime()) {
        navigation.value = 'decrement';
      } else {
        navigation.value = 'increment';
      }
    },
  );

  // static year list (1940 - 2037)
  const yearList = [...Array(98).keys()].map((v) => 1940 + v);

  // static month list
  const monthList = [...Array(12).keys()];

  // calendar options
  const selectableFunction = ref<SelectableFunctionType | null>(null);
  const setSelectableCallback = (cb: SelectableFunctionType) => (selectableFunction.value = cb);
  const selectableCallback = (value: DateTime) => {
    if (selectableFunction.value) {
      return selectableFunction.value({ year: value.year, month: value.month - 1, day: value.day });
    } else {
      return true;
    }
  };

  // compute utilities for compute day-month list and navigation
  const prevMonthYear = computed(() => {
    let cMonth = navDt.month.value,
      cYear = navDt.year.value;
    if (cMonth - 1 < 0) {
      cMonth = 11;
      cYear -= 1;
    } else {
      cMonth -= 1;
    }
    return { year: cYear, month: cMonth };
  });
  const nextMonthYear = computed(() => {
    let cMonth = navDt.month.value,
      cYear = navDt.year.value;
    if (cMonth + 1 > 11) {
      cMonth = 0;
      cYear += 1;
    } else {
      cMonth += 1;
    }
    return { year: cYear, month: cMonth };
  });

  // compute day-month for calendar rendering
  const dayMonthList = computed(() => {
    let dml: (CalendarDayItem | null)[][] = [];

    let needFillStartWeekAt: number | null = null;
    let needFillLastWeekAt: number | null = null;

    let d = 1;
    let dw = 0;
    let dmlItr: (CalendarDayItem | null)[] = [];
    // infinite loop until finished mapping
    while (true) {
      // if day is not out of daysOfMonth range, map day in week
      if (d <= navDt.daysInMonth.value) {
        // start map first day of month if match the first month day of week
        // (e.g. insert first day to index 4 if first month day of week start at thursday)
        if (d === 1) {
          if (dw !== navDt.firstDayWeekOfMonth.value) {
            dmlItr[dw++] = null;
          } else {
            // if day-week is not at start of the week, need to fill start of week
            if (dw != 0) {
              needFillStartWeekAt = dw - 1;
            }
            const value = DateTime.fromObject({ year: navDt.year.value, month: navDt.month.value + 1, day: d++ }, { zone: navDt.timezone.value });
            dmlItr[dw++] = {
              value,
              selectable: selectableCallback(value),
              outer: false,
            };
          }
        }
        // otherwise, normally map
        else {
          const value = DateTime.fromObject({ year: navDt.year.value, month: navDt.month.value + 1, day: d++ }, { zone: navDt.timezone.value });
          dmlItr[dw++] = {
            value,
            selectable: selectableCallback(value),
            outer: false,
          };
        }
      }
      // otherwise, insert null (for fill the remaining days in week)
      else {
        // set need to fill end of week (if not set to)
        if (needFillLastWeekAt === null) {
          needFillLastWeekAt = dw;
        }
        dmlItr[dw++] = null;
      }

      // if dw (day week) at 7 (out of last day of week range), reset for next week
      if (dw === 7) {
        // push to day-month list
        dml.push(dmlItr);

        // reset dw
        dw = 0;
        dmlItr = [];

        // if we already map the last day of month, break loop (complete)
        if (d > navDt.daysInMonth.value) {
          break;
        }
      }
    }

    // fill start
    if (needFillStartWeekAt !== null) {
      // retrieve days in month of last month
      let lastMonthDays = new Date(prevMonthYear.value.year, prevMonthYear.value.month + 1, 0).getDate();
      // backwards fill on first week of month
      for (let i = needFillStartWeekAt; i >= 0; i--) {
        const value = DateTime.fromObject(
          {
            year: prevMonthYear.value.year,
            month: prevMonthYear.value.month + 1,
            day: lastMonthDays--,
          },
          { zone: navDt.timezone.value },
        );
        dml[0][i] = {
          value,
          selectable: selectableCallback(value),
          outer: true,
        };
      }
    }

    // fill end
    if (needFillLastWeekAt !== null) {
      // forwards fill on first week of month
      d = 1;
      for (let i = needFillLastWeekAt; i <= 6; i++) {
        const value = DateTime.fromObject(
          {
            year: nextMonthYear.value.year,
            month: nextMonthYear.value.month + 1,
            day: d++,
          },
          { zone: navDt.timezone.value },
        );
        dml[dml.length - 1][i] = {
          value,
          selectable: selectableCallback(value),
          outer: true,
        };
      }
    }

    return dml;
  });

  // navigation functions
  const goToPreviousMonth = () => navDt.setDate(prevMonthYear.value.year, prevMonthYear.value.month, 1);
  const goToNextMonth = () => navDt.setDate(nextMonthYear.value.year, nextMonthYear.value.month, 1);
  const goToPreviousYear = () => navDt.setDate(navDt.year.value - 1, navDt.month.value, 1);
  const goToNextYear = () => navDt.setDate(navDt.year.value + 1, navDt.month.value, 1);

  return {
    [CHOCO_CALENDAR_OBJECT]: 1,
    /** current view timezone */
    timezone: navDt.timezone,
    /** set view timezone */
    setTimezone: navDt.setTimezone,
    /** latest navigation state when manipulate date (for update UI states) */
    navigation,
    /** current view year */
    year: navDt.year,
    /** current view month */
    month: navDt.month,
    /** year list, for UI rendering */
    yearList,
    /** month list, for UI rendering */
    monthList,
    /** day-week array dimension of current month, for UI rendering */
    dayMonthList,
    /** set callback to each day item if its able to select */
    setSelectableCallback,
    /** go to specific date */
    goToDate: (year?: number, month?: number, day?: number) => navDt.setDate(year, month, day),
    goToNextMonth,
    goToPreviousMonth,
    goToNextYear,
    goToPreviousYear,
  };
}

/**
 * Calendar composition object
 */
export type UseCalendarObject = Omit<ReturnType<typeof this_useCalendar>, typeof CHOCO_CALENDAR_OBJECT>;

/**
 * Calendar composition
 *
 * Caution: if timezone is provided, for init object useDateTime() or DateTime (luxon object)
 * will use its timezone and then converted to given timezone (with time conversion),
 * for init object Date will treat its date-time as given timezone, otherwise default to useDateTime()
 *
 * @param init Initial view date, null for default
 * @param timezone view timezone, IANA identifier or offset in minutes
 * @param options Use calendar option
 */
export const useCalendar: (...[init, timezone]: Parameters<typeof this_useCalendar>) => UseCalendarObject = this_useCalendar;

/**
 * Type guard for check if given value is a useCalendar() composition object
 * @param value
 */
export function isUseCalendarObject(value: unknown): value is UseCalendarObject {
  return typeof value === 'object' && value !== null && CHOCO_CALENDAR_OBJECT in (value as object);
}
