import { Permission } from '@/types/modules/permission/permission';

export const PERMISSION_USERLIST_HEADER: { label: string; key: string; sortable: boolean }[] = [
  {
    label: 'permission.user_list.number',
    key: 'number',
    sortable: false,
  },
  {
    label: 'permission.user_list.image',
    key: 'image',
    sortable: false,
  },
  {
    label: 'permission.user_list.firstname',
    key: 'firstname',
    sortable: true,
  },
  {
    label: 'permission.user_list.lastname',
    key: 'lastname',
    sortable: true,
  },
  {
    label: 'permission.user_list.email',
    key: 'email',
    sortable: false,
  },
  {
    label: 'permission.user_list.role',
    key: 'role',
    sortable: false,
  },
  {
    label: 'permission.user_list.update',
    key: 'update',
    sortable: false,
  },
  {
    label: 'permission.user_list.status',
    key: 'status',
    sortable: false,
  },
  {
    label: '',
    key: 'Action',
    sortable: false,
  },
];

export const PERMISSION_MANAGE_RIGHTS_HEADER: { label: string; key: string; sortable: boolean }[] = [
  {
    label: 'permission.manage_role.number',
    key: 'number',
    sortable: false,
  },
  {
    label: 'permission.manage_role.role',
    key: 'role',
    sortable: false,
  },
  {
    label: 'permission.manage_role.update_by',
    key: 'update_by',
    sortable: true,
  },
  {
    label: 'permission.manage_role.update',
    key: 'update',
    sortable: true,
  },
  {
    label: 'permission.manage_role.status',
    key: 'status',
    sortable: false,
  },
  {
    label: '',
    key: 'Action',
    sortable: false,
  },
];

export const PERMISSION_ROLE_CONFIG: { key: string; label: string }[] = [
  { key: 'cdp-portal-super-admin', label: 'permission.config.role_super_admin' },
  { key: 'cdp-portal-owner', label: 'permission.config.role_admin' },
  { key: 'cdp-portal-editor', label: 'permission.config.role_editor' },
  { key: 'cdp-portal-staff', label: 'permission.config.role_generaluser' },
  { key: 'cdp-portal-viewer', label: 'permission.config.role_onlysee' },
  { key: 'cdp-portal-choco-employee', label: 'permission.config.role_choco_employee' },
  { key: 'cdp-portal-master', label: 'permission.config.role_master' },
  { key: 'cdp-portal-choco-developer', label: 'permission.config.role_choco_developer' },
];

export const PERMISSION_STATUS_CONFIG: { id: number; label: string }[] = [
  { id: 2, label: 'permission.config.status_active' },
  { id: 3, label: 'permission.config.status_inactive' },
  { id: 1, label: 'permission.config.status_pending' },
  { id: 9, label: 'permission.config.status_archived' },
];

export const PERMISSION_STATUS_ROLE_CONFIG: { id: number | null; label: string }[] = [
  { id: null, label: 'permission.config.status_all' },
  { id: 1, label: 'permission.config.status_active' },
  { id: 9, label: 'permission.config.status_inactive' },
];

export const DROPDOWN_SELECT_ROLE: { key: string; label: string; color: string }[] = [
  { key: '', label: 'permission.config.role_all', color: 'red' },
  { key: 'cdp-portal-owner', label: 'permission.config.role_admin', color: 'red' },
  { key: 'cdp-portal-editor', label: 'permission.config.role_editor', color: 'blue' },
  { key: 'cdp-portal-staff', label: 'permission.config.role_generaluser', color: 'green' },
  { key: 'cdp-portal-viewer', label: 'permission.config.role_onlysee', color: 'yellow' },
];

export const DROPDOWN_SELECT_ROLE_INVITE: { key: string; label: string; color: string }[] = [
  { key: 'cdp-portal-owner', label: 'permission.config.role_admin', color: 'red' },
  { key: 'cdp-portal-editor', label: 'permission.config.role_editor', color: 'blue' },
  { key: 'cdp-portal-staff', label: 'permission.config.role_generaluser', color: 'green' },
  { key: 'cdp-portal-viewer', label: 'permission.config.role_onlysee', color: 'yellow' },
];

export const DROPDOWN_SELECT_ROLE_ACTIVITY: { key: string; label: string; color: string }[] = [
  { key: 'cdp-portal-owner', label: 'Admin/Owner', color: 'red' },
  { key: 'cdp-portal-editor', label: 'Editor', color: 'blue' },
  { key: 'cdp-portal-staff', label: 'Staff', color: 'green' },
  { key: 'cdp-portal-viewer', label: 'Viewer', color: 'yellow' },
];

export const DROPDOWN_SELECT_ROLE_PROFILE_DETAIL: { key: string; label: string }[] = [
  { key: 'cdp-portal-editor', label: 'permission.config.role_editor' },
  { key: 'cdp-portal-staff', label: 'permission.config.role_generaluser' },
  { key: 'cdp-portal-viewer', label: 'permission.config.role_onlysee' },
];

export const DROPDOWN_SELECT_STATUS: { id: number | null; label: string }[] = [
  { id: null, label: 'permission.config.status_all' },
  { id: 2, label: 'permission.config.status_active' },
  { id: 3, label: 'permission.config.status_inactive' },
  { id: 1, label: 'permission.config.status_pending' },
  { id: 9, label: 'permission.config.status_archived' },
];

export const DROPDOWN_SELECT_FUNCTION: { id: number; label: string; color: string }[] = [
  { label: 'permission.config.manage_role', id: 0, color: 'red' },
  { label: 'permission.config.create_audience', id: 1, color: 'blue' },
  { label: 'permission.config.dashboard', id: 2, color: 'green' },
];

export const DROPDOWN_SELECT_ACTION: { id: number; label: string }[] = [
  { label: 'permission.config.edit', id: 0 },
  { label: 'permission.config.delete', id: 1 },
  { label: 'permission.config.build', id: 2 },
];

export const PERMISSION_ROLE: Permission.Permission.Role.PermissionRole = {
  All: 0,
  Admin: 1,
  Editor: 2,
  GeneralUser: 3,
  OnlySee: 4,
};

export const PERMISSION_STATUS: Permission.Permission.Status.PermissionStatus = {
  Active: 2,
  Inactive: 3,
  Pending: 1,
  Archived: 9,
};

export const PERMISSION_STATUS_ROLE: Permission.Permission.Status.PermissionStatusRole = {
  Active: 1,
  Inactive: 9,
};

export const PERMISSION_ROLE_KEY: Permission.Permission.Role.RoleKey = {
  Admin: 'cdp-portal-owner',
  Editor: 'cdp-portal-editor',
  GeneralUser: 'cdp-portal-staff',
  Viewer: 'cdp-portal-viewer',
};

export const PERMISSION_MENU_KEY: Permission.Permission.Menu.PermissionMenuStatus = {
  Dashboard: 'cdp-portal-menu-dashboard',
  Segment: 'cdp-portal-menu-segment',
  DataConnector: 'cdp-portal-menu-dataconnector',
  Communication: 'cdp-portal-menu-communication',
  Mka: 'cdp-portal-menu-mka',
  Setting: 'cdp-portal-menu-setting',
  ResetPassword: 'cdp-portal-password-change',
};

export const PERMISSION_MENU_STATUS: Permission.Permission.Menu.PermissionMenuStatus = {
  Dashboard: 3,
  Segment: 4,
  DataConnector: 5,
  Communication: 6,
  Mka: 7,
  Setting: 1,
  ResetPassword: 2,
};

export const FIRST_PAGE = 1;

export const PERMISSION_TABLE_CONFIG: any = {
  pageNumber: 1,
  pageSize: [10, 50, 100],
  rowsPerPage: 10,
  totalPages: null,
  totalRows: null,
};
