export const charts_key = {
  TOTAL_STATUS: 'Comm_006_OverviewTotalStatus',
  DELIVERY_RANGE: 'Comm_007_HeatmapCommuCampaignDeliveryRange',
  CLICK_RANGE: 'Comm_015_HeatmapCommuClickRange',
  DELIVERY: 'Comm_008_PieDelivery',
  DELIVERY_BY_CAMPAIGN: 'Comm_009_VerticalStackBarDeliveryByCampaign',
  SMS_NEW_SUBSCRIBE: 'Comm_010_LineSmsNewSubscribe',
  SMS_SUBSCRIBE: 'Comm_011_PieSmsSubscribe',
  SMS_CREDIT: 'Comm_012_VerticalOverviewSmsCredit',
};

export const charts_type = {
  OVERVIEW: 'overview',
  HEATMAP: 'heatmap-commu',
  PIE: 'pie',
  VERTICAL_STACK_BAR: 'vertical-stack-bar',
  LINE: 'line',
  VERTICAL_OVERVIEW: 'vertical-overview',
};
