<template>
  <svg width="21.885" height="22.108" viewBox="0 0 21.885 22.108">
    <g id="Group_340" data-name="Group 340">
      <path
        id="Path_150"
        data-name="Path 150"
        d="M24.638.477l-.2-.2a.941.941,0,0,0-1.331,0L20.04,3.363a4.46,4.46,0,0,0-5.357.738L12.634,6.165l-1.011-1a.671.671,0,0,0-.95,0l-.145.146a.672.672,0,0,0,0,.95l1.012,1,0,0L12.7,8.415l0,0,4.019,3.99,0,0,1.157,1.148,0,0,1.012,1a.671.671,0,0,0,.95,0l.145-.146a.671.671,0,0,0,0-.95l-1.011-1,2.049-2.064a4.461,4.461,0,0,0,.6-5.531l3.027-3.049a.941.941,0,0,0,0-1.331M19.858,9.239,17.81,11.3l-4.019-3.99L15.84,5.249a2.831,2.831,0,1,1,4.018,3.99"
        transform="translate(-3.031 0)"
      />
      <path
        id="Path_151"
        data-name="Path 151"
        d="M13.508,18.087l0,0-1.157-1.148,0,0-4.018-3.99v0L7.178,11.8v0l-1.012-1a.672.672,0,0,0-.951,0l-.145.146a.671.671,0,0,0,0,.95l1.011,1L4.035,14.963a4.459,4.459,0,0,0-.7,5.362L.273,23.41a.942.942,0,0,0,0,1.331l.2.2a.941.941,0,0,0,1.331,0L4.841,21.89a4.459,4.459,0,0,0,5.526-.64l2.049-2.064,1.011,1a.672.672,0,0,0,.95,0l.146-.146a.672.672,0,0,0,0-.95ZM9.21,20.1a2.831,2.831,0,1,1-4.018-3.99l2.049-2.064,4.018,3.99Z"
        transform="translate(0 -3.109)"
      />
    </g>
  </svg>
</template>
