<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19.079" height="18.839" viewBox="0 0 19.079 18.839">
    <g id="Group_37845" data-name="Group 37845" transform="translate(-286.639 -394.319)">
      <path
        d="M294.409,404.261h-6.643a1.128,1.128,0,0,0-1.127,1.127v6.643a1.127,1.127,0,0,0,1.127,1.127h6.643a1.126,1.126,0,0,0,1.127-1.127v-6.643A1.127,1.127,0,0,0,294.409,404.261Zm-.573,7.2h-5.5v-5.5h5.5Z"
        fill="#555"
      />
      <path
        d="M304.591,404.261h-6.643a1.127,1.127,0,0,0-1.127,1.127v6.643a1.126,1.126,0,0,0,1.127,1.127h6.643a1.126,1.126,0,0,0,1.127-1.127v-6.643A1.127,1.127,0,0,0,304.591,404.261Zm-.573,7.2h-5.5v-5.5h5.5Z"
        fill="#555"
      />
      <path
        d="M294.409,394.319h-6.643a1.127,1.127,0,0,0-1.127,1.127v6.643a1.128,1.128,0,0,0,1.127,1.127h6.643a1.127,1.127,0,0,0,1.127-1.127v-6.643A1.126,1.126,0,0,0,294.409,394.319Zm-.573,7.2h-5.5v-5.5h5.5Z"
        fill="#555"
      />
      <path
        d="M304.591,394.319h-6.643a1.126,1.126,0,0,0-1.127,1.127v6.643a1.127,1.127,0,0,0,1.127,1.127h6.643a1.127,1.127,0,0,0,1.127-1.127v-6.643A1.126,1.126,0,0,0,304.591,394.319Zm-.573,7.2h-5.5v-5.5h5.5Z"
        fill="#555"
      />
    </g>
  </svg>
</template>
