<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="39.651" height="21.929" viewBox="0 0 39.651 21.929">
    <g id="Group_115581" data-name="Group 115581" transform="translate(-320.747 -137.5)">
      <g id="Group_115411" data-name="Group 115411" transform="translate(320.747 137.5)">
        <g id="Group_115410" data-name="Group 115410" transform="translate(0 0)">
          <path
            id="Path_311054"
            data-name="Path 311054"
            d="M25.963,18.89H7.34a4.246,4.246,0,0,1-4.3-4.075v-.051a4.141,4.141,0,0,1,4.132-4.149v0h.047a.45.45,0,0,0,.45-.406,7.938,7.938,0,0,1,15.685-.822.473.473,0,0,0,.454.376.492.492,0,0,0,.246-.072,5.13,5.13,0,0,1,.74-.384,4.934,4.934,0,0,1,1.8-.368A7.617,7.617,0,0,1,30.43,6.836a7.991,7.991,0,0,0-5.085-.83A10.975,10.975,0,0,0,5.021,7.913,7.165,7.165,0,0,0,0,14.948a7.32,7.32,0,0,0,7.365,6.978l24,0c.111,0,.215-.022.326-.027a7.583,7.583,0,0,1-5.732-3"
            transform="translate(0 0.001)"
            fill="#007fff"
          />
          <path
            id="Path_311055"
            data-name="Path 311055"
            d="M22.82,4.15a7.632,7.632,0,1,0,7.632,7.632A7.632,7.632,0,0,0,22.82,4.15m0,12.059a.951.951,0,1,1,.952-.951.952.952,0,0,1-.952.951m.952-3.618a.952.952,0,0,1-1.9,0V8.307a.952.952,0,0,1,1.9,0Z"
            transform="translate(9.199 2.515)"
            fill="#007fff"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
