<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14.215" height="16.271" viewBox="0 0 14.215 16.271">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_15806" data-name="Rectangle 15806" width="14.215" height="16.271" fill="#676767" />
      </clipPath>
    </defs>
    <g id="Group_36770" data-name="Group 36770" transform="translate(0 0)">
      <g id="Group_36770-2" data-name="Group 36770" transform="translate(0 0)" clip-path="url(#clip-path)">
        <path
          id="Path_23729"
          data-name="Path 23729"
          d="M13.582,0H.633A.633.633,0,0,0,0,.633v15a.633.633,0,0,0,1.039.485L6.7,11.384a.632.632,0,0,1,.812,0l5.662,4.739a.633.633,0,0,0,1.039-.485v-15A.633.633,0,0,0,13.582,0m-.969,13.564L8.542,10.156a2.236,2.236,0,0,0-2.868,0L1.6,13.564V1.6H12.613Z"
          transform="translate(0 0)"
          fill="#676767"
        />
      </g>
    </g>
  </svg>
</template>
