import { markRaw } from 'vue';
//alignment
import AlignStartIcon from '@assets/icons/modules/communication/edm-editor/alignment/AlignStartIcon.vue';
import AlignCenterIcon from '@assets/icons/modules/communication/edm-editor/alignment/AlignCenterIcon.vue';
import AlignEndIcon from '@assets/icons/modules/communication/edm-editor/alignment/AlignEndIcon.vue';

export const alignList = [
  {
    key: 'align-left',
    icon: markRaw(AlignStartIcon),
    value: 'start',
  },
  {
    key: 'align-center',
    icon: markRaw(AlignCenterIcon),
    value: 'center',
  },
  {
    key: 'align-right',
    icon: markRaw(AlignEndIcon),
    value: 'end',
  },
];

export const CARD_MESSAGE = {
  VERTICAL: {
    key: 'vertical',
    size: 'kilo',
    width: '375',
    height: '475',
    preview_height: '575',
    ratio: '0.65:1',
  },
  HORIZONTAL: {
    key: 'horizontal',
    size: 'mega',
    width: '375',
    height: '160',
    preview_height: '187',
    ratio: '2.01:1',
  },
  SMALL_SQUARE: {
    key: 'small-square',
    size: 'mega',
    width: '375',
    height: '250',
    preview_height: '338',
    ratio: '1.11:1',
  },
  LARGE_SQUARE: {
    key: 'large-square',
    size: 'giga',
    width: '400',
    height: '340',
    preview_height: '400',
    ratio: '1:1',
  },
};

export const DEFAULT_CARDMESSAGE_PROPERTIES = {
  layout: {
    key: 'small-square',
    size: 'mega',
    width: '375',
    height: '250',
    preview_height: '338',
    ratio: '1.11:1',
  },
  enableImage: true,
  bannerUrl: '',
  enableLabel: true,
  label: {
    text: 'Label',
    bgColor: '#0056ac',
    fontColor: '#ffffff',
    fontStyleList: null,
    fontWeight: 'regular',
    fontItalic: 'normal',
    textDecoration: 'none',
    align: 'center',
  },
  enableAction: true,
  actionType: 1,
  actionLabel: {
    text: 'Action',
    bgColor: '#007fff',
    fontColor: '#ffffff',
    fontStyleList: null,
    fontWeight: 'regular',
    fontItalic: 'normal',
    textDecoration: 'none',
    borderRadius: 30,
  },
  linkUrl: '',
};

export const DEFAULT_CLOSING_CARD = {
  layout: {
    key: 'small-square',
    size: 'mega',
    width: '375',
    height: '250',
    preview_height: '338',
    ratio: '1.11:1',
  },
  enableImage: true,
  bannerUrl: '',
  enableLabel: false,
  label: {
    text: 'Label',
    bgColor: '#000',
    fontColor: '#000',
    fontStyleList: null,
    fontWeight: 'regular',
    fontItalic: 'normal',
    textDecoration: 'none',
    align: 'center',
  },
  enableAction: true,
  actionType: 1,
  actionLabel: {
    text: 'Lead More',
    bgColor: '#ffffff',
    fontColor: '#006CC9',
    fontStyleList: null,
    fontWeight: 'regular',
    fontItalic: 'normal',
    textDecoration: 'none',
    borderRadius: 30,
  },
  linkUrl: '',
};
