import { onMounted, ref, Ref, watch } from 'vue';
import * as echarts from 'echarts';
import { uid, calculateWidth, calculateFontSizeFromHeight, formatterLabel } from '../utils';

interface Props {
  min: number;
  max: number;
  value: number;
  height: number;
  startLabel?: string;
  endLabel?: string;
  detailColor?: string;
  gradient?: Array<{
    offset: number;
    color: string;
  }>;
}
export default function (props: Props) {
  const chart = ref();
  const fontOffset = 5;
  const id = ref();
  id.value = uid();

  const option: Ref<GaugeOption.Normal> = ref({
    series: [
      {
        type: 'gauge',
        center: ['50%', '75%'],
        radius: '140%',
        startAngle: 180,
        endAngle: 0,
        min: props.min,
        max: props.max,
        splitNumber: 1,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 1, 1, 1, props.gradient),
        },
        progress: {
          show: true,
          roundCap: true,
          width: 18,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          roundCap: true,
          lineStyle: {
            width: 18,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: true,
          distance: -30,
          color: '#999',
          fontSize: calculateFontSizeFromHeight(Number(props.height), 10),
          formatter: (val: number) => formatterLabel(Number(props.min), Number(props.max), val, props.startLabel, props.endLabel),
        },
        title: {
          show: false,
        },
        detail: {
          width: '30%',
          lineHeight: 20,
          height: 20,
          offsetCenter: [0, '-25%'],
          valueAnimation: true,
          formatter: function (value: number) {
            return '{value|' + value.toFixed(0) + '}{unit|%}';
          },
          rich: {
            value: {
              fontSize: calculateFontSizeFromHeight(Number(props.height), fontOffset),
              fontWeight: 'bolder',
              color: props.detailColor,
            },
            unit: {
              fontSize: calculateFontSizeFromHeight(Number(props.height), fontOffset),
              color: props.detailColor,
              padding: [0, 0, 0, 2],
            },
          },
        },
        data: [
          {
            value: props.value,
          },
        ],
      },
    ],
  });

  onMounted(() => {
    const dom = document.getElementById(id.value) as HTMLInputElement;
    chart.value = echarts.init(dom);
    option.value && chart.value.setOption(option.value);
  });

  watch([() => props.min, () => props.max, () => props.value], () => {
    chart.value.setOption({
      series: [
        {
          min: props.min,
          max: props.max,
          data: [{ value: props.value }],
        },
      ],
    });
  });

  watch([() => props.startLabel, () => props.endLabel], () => {
    chart.value.setOption({
      series: [
        {
          axisLabel: {
            formatter: (val: number) => formatterLabel(Number(props.min), Number(props.max), val, props.startLabel, props.endLabel),
          },
        },
      ],
    });
  });

  watch(
    () => props.detailColor,
    (val) => {
      chart.value.setOption({
        series: [
          {
            detail: {
              rich: {
                value: {
                  color: val,
                },
                unit: {
                  color: val,
                },
              },
            },
          },
        ],
      });
    },
  );

  watch(
    () => props.gradient,
    (val) => {
      chart.value.setOption({
        series: [
          {
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 1, 1, val),
            },
          },
        ],
      });
    },
    { deep: true },
  );

  watch([() => props.height], (height) => {
    chart.value.resize({
      height: height,
      width: calculateWidth(Number(height)),
    });
    chart.value.setOption({
      series: [
        {
          axisLabel: {
            fontSize: calculateFontSizeFromHeight(Number(height), 10),
          },
          detail: {
            rich: {
              value: {
                fontSize: calculateFontSizeFromHeight(Number(height), fontOffset),
              },
              unit: {
                fontSize: calculateFontSizeFromHeight(Number(height), fontOffset),
              },
            },
          },
        },
      ],
    });
  });

  return { id, uid, calculateFontSizeFromHeight, formatterLabel, calculateWidth };
}
