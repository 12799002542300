<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="18.216"
    height="22.28"
    viewBox="0 0 18.216 22.28"
  >
    <g id="Group_29067" data-name="Group 29067" transform="translate(-793 -443)">
      <g id="Group_29054" data-name="Group 29054" transform="translate(793 443)">
        <g id="Group_29053" data-name="Group 29053">
          <path
            id="Path_9137"
            data-name="Path 9137"
            d="M11.739,2.72,9.224.071a.225.225,0,0,0-.388.155V2A9.108,9.108,0,0,0,3.444,18.235a.854.854,0,0,0,1.344-.4l.006-.019a.858.858,0,0,0-.285-.938A7.384,7.384,0,0,1,8.836,3.721V5.562a.225.225,0,0,0,.389.154L11.74,3.028a.225.225,0,0,0,0-.308"
            transform="translate(0 0)"
            fill="#b5b5b5"
          />
          <path
            id="Path_9138"
            data-name="Path 9138"
            d="M171.14,99.59a9.091,9.091,0,0,0-3.5-6.978.854.854,0,0,0-1.337.406l-.006.018a.858.858,0,0,0,.291.943,7.383,7.383,0,0,1-4.244,13.193v-1.764a.225.225,0,0,0-.388-.155L159.44,107.9a.225.225,0,0,0,0,.308l2.515,2.688a.225.225,0,0,0,.389-.154v-1.85a9.137,9.137,0,0,0,8.8-9.3"
            transform="translate(-152.926 -88.69)"
            fill="#b5b5b5"
          />
        </g>
      </g>
    </g>
    <rect
      id="Rectangle_13026"
      data-name="Rectangle 13026"
      width="2"
      height="25"
      rx="1"
      transform="matrix(0.788, 0.616, -0.616, 0.788, 16.007, 0.56)"
      fill="#b5b5b5"
    />
  </svg>
</template>
