<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20.511" height="15.414" viewBox="0 0 20.511 15.414">
    <g id="Group_97318" data-name="Group 97318" transform="translate(0.612 1.211)">
      <g id="Group_97321" data-name="Group 97321">
        <path
          id="Subtraction_322"
          data-name="Subtraction 322"
          d="M554.935,14281.615a7.955,7.955,0,0,1-3.035-.633l1.229-1.229a6.14,6.14,0,0,0,1.806.284,8.746,8.746,0,0,0,5.456-2.46,15.966,15.966,0,0,0,2.257-2.456,15.635,15.635,0,0,0-2.348-2.539l1.115-1.115a17.189,17.189,0,0,1,2.528,2.745l.635.906-.635.911C563.535,14276.6,559.814,14281.615,554.935,14281.615Zm-3.994-1.089h0a12.855,12.855,0,0,1-1.71-1.114l1.124-1.124a10.386,10.386,0,0,0,1.758,1.068l-1.17,1.17Zm-2.489-1.752h0a17.216,17.216,0,0,1-2.525-2.744l-.638-.911.638-.906a17.1,17.1,0,0,1,2.68-2.878,9.846,9.846,0,0,1,6.329-2.708,7.973,7.973,0,0,1,3.032.632l-1.228,1.229a6.144,6.144,0,0,0-1.8-.283,8.751,8.751,0,0,0-5.456,2.458,15.978,15.978,0,0,0-2.257,2.454,15.631,15.631,0,0,0,2.347,2.541l-1.116,1.117Zm6.483-.572c-.09,0-.17,0-.245-.01l3.315-3.314c.007.092.009.171.009.241A3.085,3.085,0,0,1,554.935,14278.2Zm-1.356-.313h0a3.1,3.1,0,0,1-1.411-1.414l4.119-4.119a3.1,3.1,0,0,1,1.415,1.412l-.354.354a1.293,1.293,0,1,0-1.6,1.6l-2.167,2.167Zm-1.714-2.527v0c-.006-.081-.009-.159-.009-.24a3.081,3.081,0,0,1,3.079-3.075c.08,0,.16,0,.238.009l-3.307,3.308Zm7.652-3.408h0a10.3,10.3,0,0,0-1.759-1.068l1.169-1.17a12.915,12.915,0,0,1,1.712,1.116l-1.122,1.122Z"
          transform="translate(-545.289 -14268.625)"
          stroke="rgba(0,0,0,0)"
          stroke-width="1"
        />
        <path id="Line_1275" data-name="Line 1275" d="M.707,14.707-.707,13.293l14-14L14.707.707Z" transform="translate(2.646 -0.504)" />
      </g>
    </g>
  </svg>
</template>
