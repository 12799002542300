import { ref, Ref, watch, getCurrentInstance } from 'vue';
import permissionApi from '@/models/permission/permission';
import api from '@services/api';

export default function () {
  const vm = getCurrentInstance()?.proxy;

  const { fetchResetPasswordModel } = permissionApi();

  const showPassword: Ref<boolean> = ref(false);
  const showConfirmPassword: Ref<boolean> = ref(false);
  const btnDisabled: Ref<boolean> = ref(false);

  const isMatch: Ref<boolean> = ref(false);
  const isCharacter: Ref<boolean> = ref(false);
  const isLowercase: Ref<boolean> = ref(false);
  const isUppercase: Ref<boolean> = ref(false);
  const isSpecial: Ref<boolean> = ref(false);
  const isNumber: Ref<boolean> = ref(false);

  const password: Ref<string> = ref('');
  const confirmPassword: Ref<string> = ref('');
  const clearSession: Ref<boolean> = ref(false);

  const onKeyValid = (str: any) => {
    if (str.keyCode == 32) {
      str.returnValue = false;
      return false;
    }
  };

  const onValidationPasswaord = (str: string) => {
    const formatUpper = /[A-Z]/;
    const formatLower = /[a-z]/;
    const formatSpecial = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const formatNumber = /[0-9]/;

    if (str.length >= 8) {
      isCharacter.value = true;
    } else {
      isCharacter.value = false;
    }

    if (formatUpper.test(str)) {
      isUppercase.value = true;
    } else {
      isUppercase.value = false;
    }

    if (formatLower.test(str)) {
      isLowercase.value = true;
    } else {
      isLowercase.value = false;
    }

    if (formatSpecial.test(str)) {
      isSpecial.value = true;
    } else {
      isSpecial.value = false;
    }

    if (formatNumber.test(str)) {
      isNumber.value = true;
    } else {
      isNumber.value = false;
    }
  };

  const fetchResetPassword = async (password: string | null, clearSession: boolean) => {
    fetchResetPasswordModel.payload.Password = password;
    fetchResetPasswordModel.payload.Clear_Session = clearSession;
    try {
      const res = await api.apiRequest(fetchResetPasswordModel);
      return res;
    } catch (error) {}
  };

  const onSubmitForm = () => {
    return vm?.$emit('reset-password-submit', confirmPassword.value);
  };

  watch(
    () => password.value,
    (str) => {
      onValidationPasswaord(str);
    },
  );

  watch(
    () => [isMatch.value, isCharacter.value, isLowercase.value, isUppercase.value, isSpecial.value],
    () => {
      if (isMatch.value && isCharacter.value && isLowercase.value && isUppercase.value && isSpecial.value) {
        btnDisabled.value = true;
      } else {
        btnDisabled.value = false;
      }
    },
  );

  watch(
    () => [confirmPassword.value, password.value],
    () => {
      if (password.value == confirmPassword.value && confirmPassword.value.length > 0) return (isMatch.value = true);
      else return (isMatch.value = false);
    },
  );

  return {
    showPassword,
    showConfirmPassword,
    isMatch,
    isCharacter,
    isLowercase,
    isUppercase,
    isSpecial,
    isNumber,
    password,
    confirmPassword,
    clearSession,
    btnDisabled,
    onSubmitForm,
    onKeyValid,
    fetchResetPassword,
  };
}
