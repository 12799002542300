import { EChartsOption } from 'echarts';

interface RawData {
  value: number;
}

export default function (rawData: RawData): EChartsOption {
  const options: EChartsOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 100,
        splitNumber: 1,
        center: ['50%', '60%'],
        itemStyle: {
          color: '#48AA00',
        },
        progress: {
          show: true,
          roundCap: false,
          width: 40,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          roundCap: false,
          lineStyle: {
            width: 40,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          fontFamily: 'Prompt',
          distance: -50,
          color: '#999',
          fontSize: 15,
        },
        title: {
          show: false,
        },
        detail: {
          width: '60%',
          offsetCenter: [0, '-15%'],
          valueAnimation: true,
          formatter: (value) => '{value|' + String(value) + ' %}',
          rich: {
            value: {
              fontFamily: 'Prompt',
              fontSize: 20,
              color: '#777',
            },
          },
        },
        data: [
          {
            value: rawData.value,
          },
        ],
      },
    ],
  };

  return options;
}
