<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16.768" height="12.198" viewBox="0 0 16.768 12.198">
    <defs>
      <clipPath id="clip-path">
        <rect width="16.768" height="12.198" />
      </clipPath>
    </defs>
    <g transform="translate(0 0)">
      <g transform="translate(0 0)" clip-path="url(#clip-path)">
        <path
          d="M18.384,14.872a3.048,3.048,0,1,1,3.048-3.048,3.048,3.048,0,0,1-3.048,3.048m0-4.064A1.016,1.016,0,1,0,19.4,11.824a1.016,1.016,0,0,0-1.016-1.017h0"
          transform="translate(-10 -5.722)"
        />
        <path
          d="M8.385,12.2C5.058,12.2,2.271,10.273.1,6.482a.757.757,0,0,1,0-.757C2.27,1.927,5.057,0,8.385,0c3.248,0,5.982,1.834,8.127,5.452l.168.289.035.077.022.063.011.042.011.063.008.077v.084l-.01.084c-.006.033-.013.059-.019.084l-.03.084-.028.058-.013.022c-2.116,3.7-4.821,5.625-8.043,5.715Zm0-10.669c-2.63,0-4.8,1.448-6.646,4.425l-.09.148.09.148c1.79,2.9,3.892,4.339,6.424,4.42l.221,0c2.629,0,4.8-1.448,6.651-4.425l.084-.148-.084-.148c-1.792-2.9-3.893-4.34-6.424-4.42Z"
          transform="translate(0 0)"
        />
      </g>
    </g>
  </svg>
</template>
