export default function dashboardByChannelModel() {
  const getChartDataModel: API.RequestModel<Communication.DashboardByChannel.Request.ChartData> = {
    endpoint: `${process.env.BASE_URL_BI!}/Chart/Data`,
    method: 'POST',
    payload: {
      Impl_Key: '',
      Filters: [],
    },
    acceptLanguage: 'en-US',
  };

  const getChartDataInfoModel: API.RequestModel<Communication.DashboardByChannel.Request.ChartDataInfo> = {
    endpoint: `${process.env.BASE_URL_BI!}/Chart/DataInfo`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      ChartSetId: null!,
      ChartId: null!,
      Impl_Type: null!,
      Impl_Key_Ref: '',
      Filters: [],
    },
    acceptLanguage: 'en-US',
  };

  const exportChartDataInfoModel: API.RequestModel<Communication.DashboardAllChannel.Request.ChartDataInfo> = {
    endpoint: `${process.env.BASE_URL_BI!}/Chart/ExportDataInfo`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      ChartSetId: null!,
      ChartId: null!,
      Impl_Type: null!,
      Impl_Key_Ref: '',
      Filters: [],
    },
    responseType: 'blob',
  };

  const getDeliveryRangeCellModel: API.RequestModel<Communication.DashboardByChannel.Request.HeatmapRangeCell> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/ChannelDashboard/DeliveryRangeCell`,
    method: 'POST',
    payload: {
      Channel: [],
      Weekdays: [],
      Filters: [],
      HourRange: '',
    },
  };

  const getOpenRangeCellModel: API.RequestModel<Communication.DashboardByChannel.Request.HeatmapRangeCell> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/ChannelDashboard/OpenRangeCell`,
    method: 'POST',
    payload: {
      Channel: [],
      Weekdays: [],
      Filters: [],
      HourRange: '',
    },
  };

  const getClickRangeCellModel: API.RequestModel<Communication.DashboardByChannel.Request.HeatmapRangeCell> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/ChannelDashboard/ClickRangeCell`,
    method: 'POST',
    payload: {
      Channel: [],
      Weekdays: [],
      Filters: [],
      HourRange: '',
    },
  };

  const getAllCampaignSummaryModel: API.RequestModel<Communication.DashboardByChannel.Request.AllCampaignSummary> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/ChannelDashboard/AllCampaignSummary`,
    method: 'POST',
    payload: {
      Channel: null!,
      Weekdays: [],
      Filters: [],
      HourRange: '',
    },
  };

  const getCampaignListSummaryModel: API.RequestModel<Communication.DashboardByChannel.Request.CampaignListSummary> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/ChannelDashboard/CampaignListSummary`,
    method: 'POST',
    payload: {
      Channel: null!,
      Weekdays: [],
      HourRange: '',
      Filters: [],
      Ordering: [],
      Page: null!,
      Limit: null!,
    },
  };

  const getAllOpenCampaignSummaryModel: API.RequestModel<Communication.DashboardByChannel.Request.AllCampaignSummary> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/ChannelDashboard/AllOpenCampaignSummary`,
    method: 'POST',
    payload: {
      Channel: null!,
      Weekdays: [],
      Filters: [],
      HourRange: '',
    },
  };

  const getOpenCampaignListSummaryModel: API.RequestModel<Communication.DashboardByChannel.Request.CampaignListSummary> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/ChannelDashboard/OpenCampaignListSummary`,
    method: 'POST',
    payload: {
      Channel: null!,
      Weekdays: [],
      HourRange: '',
      Filters: [],
      Ordering: [],
      Page: null!,
      Limit: null!,
    },
  };

  const getAllClickCampaignSummaryModel: API.RequestModel<Communication.DashboardByChannel.Request.AllCampaignSummary> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/ChannelDashboard/AllClickCampaignSummary`,
    method: 'POST',
    payload: {
      Channel: null!,
      Weekdays: [],
      Filters: [],
      HourRange: '',
    },
  };

  const getClickCampaignListSummaryModel: API.RequestModel<Communication.DashboardByChannel.Request.CampaignListSummary> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/ChannelDashboard/ClickCampaignListSummary`,
    method: 'POST',
    payload: {
      Channel: null!,
      Weekdays: [],
      HourRange: '',
      Filters: [],
      Ordering: [],
      Page: null!,
      Limit: null!,
    },
  };

  return {
    getChartDataModel,
    getChartDataInfoModel,
    exportChartDataInfoModel,
    getDeliveryRangeCellModel,
    getOpenRangeCellModel,
    getClickRangeCellModel,
    getAllCampaignSummaryModel,
    getCampaignListSummaryModel,
    getAllOpenCampaignSummaryModel,
    getOpenCampaignListSummaryModel,
    getAllClickCampaignSummaryModel,
    getClickCampaignListSummaryModel,
  };
}
