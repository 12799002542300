export const mockDataSource = [
  {
    tableid: 227,
    brandid: 4,
    databaseid: 2,
    type: 100,
    type_desc: 'Customer',
    table_name: 'B4_Customer',
    table_desc: 'B4_Customer',
    table_label: 'Customer',
    has_joined: false,
    column_type_flags: 1,
    dw_datastore: 1,
    dw_datastore_desc: 'none',
    dw_dist_type: 1,
    dw_dist_type_desc: 'none',
    sourceid: null,
    is_delete: false,
    created_dt: '2022-06-19T00:00:00',
    updated_dt: '2022-06-19T00:00:00',
  },
  {
    tableid: 7,
    brandid: 4,
    databaseid: 1,
    type: 200,
    type_desc: 'Product',
    table_name: 'B4_Product',
    table_desc: 'B4_Product',
    table_label: 'Product',
    has_joined: false,
    column_type_flags: 2,
    dw_datastore: 1,
    dw_datastore_desc: 'none',
    dw_dist_type: 1,
    dw_dist_type_desc: 'none',
    sourceid: null,
    is_delete: false,
    created_dt: '2022-05-31T00:00:00',
    updated_dt: '2022-05-31T00:00:00',
  },
  {
    tableid: 8,
    brandid: 4,
    databaseid: 1,
    type: 300,
    type_desc: 'Transaction',
    table_name: 'B4_Transaction',
    table_desc: 'B4_Transaction',
    table_label: 'Transaction',
    has_joined: false,
    column_type_flags: 3,
    dw_datastore: 1,
    dw_datastore_desc: 'none',
    dw_dist_type: 1,
    dw_dist_type_desc: 'none',
    sourceid: null,
    is_delete: false,
    created_dt: '2022-05-31T00:00:00',
    updated_dt: '2022-05-31T00:00:00',
  },
  {
    tableid: 9,
    brandid: 4,
    databaseid: 1,
    type: 500,
    type_desc: 'Virtual View',
    table_name: 'Mock_CustTran',
    table_desc: 'Customer x Transaction',
    table_label: 'Customer x Transaction',
    has_joined: true,
    column_type_flags: 6,
    dw_datastore: 1,
    dw_datastore_desc: 'none',
    dw_dist_type: 1,
    dw_dist_type_desc: 'none',
    sourceid: null,
    is_delete: false,
    created_dt: '2022-05-31T00:00:00',
    updated_dt: '2022-05-31T00:00:00',
  },
  {
    tableid: 261,
    brandid: 4,
    databaseid: 1,
    type: 500,
    type_desc: 'Virtual View',
    table_name: 'Mock_ProductTran',
    table_desc: 'Product x Transaction',
    table_label: 'Product x Transaction',
    has_joined: true,
    column_type_flags: 7,
    dw_datastore: 1,
    dw_datastore_desc: 'none',
    dw_dist_type: 1,
    dw_dist_type_desc: 'none',
    sourceid: null,
    is_delete: false,
    created_dt: '2022-06-23T00:00:00',
    updated_dt: '2022-06-23T00:00:00',
  },
  {
    tableid: 341,
    brandid: 4,
    databaseid: 1,
    type: 500,
    type_desc: 'Virtual View',
    table_name: 'Mock_TranCustProduct_V2',
    table_desc: 'Transaction x Customer x Product (V2)',
    table_label: 'Transaction x Customer x Product',
    has_joined: true,
    column_type_flags: 4,
    dw_datastore: 1,
    dw_datastore_desc: 'none',
    dw_dist_type: 1,
    dw_dist_type_desc: 'none',
    sourceid: null,
    is_delete: false,
    created_dt: '2022-06-23T00:00:00',
    updated_dt: '2022-06-23T00:00:00',
  },
] as unknown as Segment.Datasource[];
