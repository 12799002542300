import { acceptHMRUpdate, defineStore } from 'pinia';

export interface BrandStoreState {
  brand_list: Array<any>;
}

export const useBrandStore = defineStore('brand', {
  state: () =>
    <BrandStoreState>{
      brand_list: [],
    },
  actions: {
    SET_BRAND_LIST(payload: any) {
      this.brand_list = payload;
    },
  },
});

// to add HMR support with vue-cli/webpack, these code must be added to each store file
if (import.meta.webpackHot) import.meta.webpackHot.accept(acceptHMRUpdate(useBrandStore, import.meta.webpackHot));
