<template>
  <svg id="Group_34740" data-name="Group 34740" xmlns="http://www.w3.org/2000/svg" width="10.207" height="16.566" viewBox="0 0 10.207 16.566">
    <path
      id="Path_22794"
      data-name="Path 22794"
      d="M1071.142,73.507h-.874a.337.337,0,0,1-.337-.337V70.911a.337.337,0,0,1,.337-.337h.874a.337.337,0,0,1,.337.337V73.17a.337.337,0,0,1-.337.337"
      transform="translate(-1065.602 -57.934)"
    />
    <path
      id="Path_22795"
      data-name="Path 22795"
      d="M1060.906,84.625h0a.774.774,0,0,1,.775-.774h3.232a.774.774,0,1,1,0,1.548h-3.232a.774.774,0,0,1-.775-.774"
      transform="translate(-1058.193 -68.833)"
    />
    <path
      id="Path_22796"
      data-name="Path 22796"
      d="M1050.863,46.127a5.109,5.109,0,0,1-5.1-5.1v-.482a.627.627,0,0,1,1.254,0v.482a3.854,3.854,0,0,0,3.85,3.85c.119,0,.239-.006.358-.016a3.919,3.919,0,0,0,3.491-3.914v-.4a.627.627,0,0,1,1.254,0v.482a5.109,5.109,0,0,1-5.1,5.1"
      transform="translate(-1045.759 -32.766)"
    />
    <path
      id="Path_22797"
      data-name="Path 22797"
      d="M1063.345,10.988h0a2.38,2.38,0,0,1-2.38-2.38V2.38a2.38,2.38,0,0,1,4.76,0V8.608a2.38,2.38,0,0,1-2.38,2.38"
      transform="translate(-1058.242 0)"
    />
  </svg>
</template>
