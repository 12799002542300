<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21.524" height="17.234" viewBox="0 0 21.524 17.234">
    <path
      id="Path_28636"
      data-name="Path 28636"
      d="M-6167.18-5577.393a11.375,11.375,0,0,0-2.769-3.089,14.7,14.7,0,0,0-3.011-1.8,22.059,22.059,0,0,0-5.11-1.546v-2.543a.863.863,0,0,0-1.378-.693l-7.392,5.5a.864.864,0,0,0,0,1.387l7.392,5.5a.863.863,0,0,0,1.378-.693v-2.108a16.577,16.577,0,0,1,3.808-.211,10.369,10.369,0,0,1,2.711.536,8.137,8.137,0,0,1,2.456,1.352,9.506,9.506,0,0,1,3.259,5.8A10.763,10.763,0,0,0-6167.18-5577.393Z"
      transform="translate(6187.188 5587.233)"
    />
  </svg>
</template>
