<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20.125" height="20.125" viewBox="0 0 20.125 20.125">
    <g id="Group_48124" data-name="Group 48124" transform="translate(0 0)">
      <path
        id="Path_21306"
        data-name="Path 21306"
        d="M408,1011.546V994.979a1.779,1.779,0,0,1,1.779-1.779h16.566a1.779,1.779,0,0,1,1.779,1.779v16.566a1.779,1.779,0,0,1-1.779,1.779H409.779a1.779,1.779,0,0,1-1.779-1.779"
        transform="translate(-408 -993.2)"
        fill="#fff"
      />
      <path
        id="Path_22315"
        data-name="Path 22315"
        d="M297.5,1002.945c0,.745-.042,1.494.01,2.236a4.574,4.574,0,0,1-2.037,4.186,30.222,30.222,0,0,1-3.25,1.9.871.871,0,0,1-.656.059,10.973,10.973,0,0,1-4.392-2.914,4.307,4.307,0,0,1-1.03-2.9c0-1.8,0-3.595,0-5.392,0-.784.162-1,.928-1.221a16.548,16.548,0,0,1,9.469-.008c.851.248.954.406.955,1.3,0,.918,0,1.836,0,2.754m-7.439,3.669c1.025,0,1.958-.019,2.89.009.429.013.568-.134.558-.558-.009-.405-.117-.592-.559-.567-.582.032-1.166.007-1.79.007v-.715c0-.992-.01-1.984.005-2.977.005-.365-.159-.478-.486-.444-.259.027-.619-.121-.619.358,0,1.6,0,3.2,0,4.886"
        transform="translate(-281.768 -994.719)"
        fill="#2aa700"
      />
    </g>
  </svg>
</template>
