<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <path
      id="Path_21305_00000176740748517938220320000017342380327089988249_"
      class="st8"
      d="M251.59,106.11
	c0,14.44-0.81,28.97,0.18,43.36c2.62,32.23-12.51,63.32-39.48,81.15c-20.23,13.56-41.28,25.84-63.04,36.78
	c-3.92,2.03-8.49,2.45-12.72,1.15c-32.55-11.63-61.77-31.02-85.14-56.49c-13.34-15.63-20.45-35.64-19.97-56.18
	c0-34.83-0.07-69.68,0-104.53c0.07-15.2,3.15-19.34,17.99-23.68c59.89-17.94,123.71-17.99,183.63-0.15
	c16.44,4.81,18.5,7.87,18.51,25.28c0,17.81,0,35.63,0,53.44 M107.36,177.38c19.86,0,37.96-0.37,56.02,0.16
	c8.32,0.25,10.96-2.59,10.81-10.81c-0.16-7.85-2.27-11.47-10.83-10.96c-11.29,0.62-22.6,0.14-34.7,0.14v-13.85
	c0-19.19-0.19-38.47,0.1-57.71c0.1-7.07-3.07-9.28-9.43-8.61c-5.02,0.52-11.99-2.36-11.99,6.95c0,31,0,61.98,0,94.71"
    />
  </svg>
</template>
