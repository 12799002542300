<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20.009" height="14.993" viewBox="0 0 20.009 14.993">
    <g id="Group_91633" data-name="Group 91633" transform="translate(-348.496 -398.485)">
      <path
        id="Path_296965"
        data-name="Path 296965"
        d="M1356.54,975.684v9.183c-.008.01-.019.02-.022.032a1.852,1.852,0,0,1-2,1.563h-15.962c-.065,0-.13,0-.2,0a1.84,1.84,0,0,1-1.825-1.876q-.005-4.376,0-8.753a1.5,1.5,0,0,1,.016-.149c.035.019.053.028.069.038l8.106,5.4a3.014,3.014,0,0,0,3.626,0q3.623-2.413,7.245-4.828C1355.907,976.09,1356.224,975.889,1356.54,975.684Z"
        transform="translate(-988.035 -572.984)"
      />
      <path
        id="Path_296966"
        data-name="Path 296966"
        d="M1356.547,943.837a2.663,2.663,0,0,1-.232.592,2.344,2.344,0,0,1-.578.621q-4.074,2.74-8.168,5.451a1.747,1.747,0,0,1-2.056,0q-4.1-2.718-8.189-5.457a1.739,1.739,0,0,1-.717-1.951,1.843,1.843,0,0,1,1.7-1.353c.058,0,.117,0,.176,0H1354.6A1.854,1.854,0,0,1,1356.45,943c.037.11.065.222.1.334Z"
        transform="translate(-988.043 -543.248)"
      />
    </g>
  </svg>
</template>
