import { ref, Ref, toRaw } from 'vue';
import router from '@/router';
import api from '@services/api';
import segmentResultModel from '@models/segment/result';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useLoading from '@/views/components/loading/hooks/useLoading';

type SaveType = 1 | 2 | 3;
type SelectFlag = 1 | 2;

interface SegmentSavePayload {
  Save_Type: SaveType;
  Type: number;
  Name: string;
  SegmentId: number;
  Apply_SegmentId: number;
  Dest_SegmentId: number;
  Root_TableId: number;
  Root_FolderId: number;
  Dest_FolderId: number;
  saveColumns: AllDataSegment.SaveColumns[];
  select_flag: SelectFlag;
  Datas: AllDataSegment.Datas[];
}

interface Props {
  selectedSource: Segment.Datasource;
}

export default function (props: Props, dataSourceDisplay: Ref<AllDataSegment.DatasourceDisplay>, selectSegmentRow: Ref<Segment.Segment>) {
  const { isLoading } = useLoading();
  const { openSuccessModal, openDefaultErrorModal } = useValidationModal();
  const { fetchSegmentSaveModel } = segmentResultModel();
  const type: Ref<number> = ref(0);
  const idFolder: Ref<number> = ref(0);
  const nameSegmentCopyto: Ref<string> = ref('');
  const nameFolder: Ref<string> = ref('');
  const idSegmentSelectCopyto: Ref<number> = ref(0);
  const nameSegmentSelectCopyto: Ref<string> = ref('');
  const idSegment: Ref<number> = ref(0);
  const isLoadingActiveCopyto: Ref<boolean> = ref(false);

  const onSubmitDialogSegment = (val: BaseModal.SaveSegmentModal) => {
    if (val.segmentSelect.segmentId != 0) {
      type.value = 3;
      nameSegmentCopyto.value = val.segmentName;
      idFolder.value = val.folderSelect.folderId;
      nameFolder.value = val.folderSelect.label;
      idSegmentSelectCopyto.value = val.segmentSelect.segmentId;
      nameSegmentSelectCopyto.value = val.segmentSelect.label;
      onLoadDataSave();
    }
  };

  const onLoadDataSave = () => {
    const mapColumn = (dataSourceDisplay: AllDataSegment.DatasourceDisplay): AllDataSegment.SaveColumns[] => {
      const saveColumns: AllDataSegment.SaveColumns[] = [];
      dataSourceDisplay.columns.forEach((element) => {
        const obj: AllDataSegment.SaveColumns = {
          ColumnId: element.column_id,
          Ref_columnId: element.ref_columnid,
          Col_Type: element.col_type,
        };
        saveColumns.push(obj);
      });
      return saveColumns;
    };

    const sourceDisplay: AllDataSegment.DatasourceDisplay = toRaw(dataSourceDisplay.value);

    const payload: SegmentSavePayload = {
      Save_Type: 3,
      Type: !router.currentRoute.value.query.type ? type.value : Number(router.currentRoute.value.query.type),
      Name: nameSegmentSelectCopyto.value,
      SegmentId: !router.currentRoute.value.query.segmentOldId
        ? selectSegmentRow.value.segmentid
        : Number(router.currentRoute.value.query.segmentOldId),
      Apply_SegmentId: selectSegmentRow.value.segmentid,
      Dest_SegmentId: idSegmentSelectCopyto.value,
      Root_TableId: props.selectedSource.tableid,
      Root_FolderId: idFolder.value,
      Dest_FolderId: idFolder.value,
      saveColumns: mapColumn(sourceDisplay),
      select_flag: 2,
      Datas: [],
    };

    fetchSegmentResultList(payload);
  };

  const fetchSegmentResultList = (payload: SegmentSavePayload) => {
    isLoading(true, 'กำลังบันทึก กรุณารอสักครู่...');
    isLoadingActiveCopyto.value = true;
    fetchSegmentSaveModel.payload = payload;

    api
      .apiRequest(fetchSegmentSaveModel)
      .then((response) => {
        if (response.status === 'success') {
          idSegment.value = response.data.segment_id;
          openSuccessModal(
            'บันทึกรายการสำเร็จ',
            '',
            '',
            () => onDirectSegment(),
            () => onDirectSegment(),
          );
        }
      })
      .catch((error) => {
        if (error.data.error.message == 'Name of Segment is duplicate') {
          isLoadingActiveCopyto.value = false;
          openDefaultErrorModal(error.data.error.locale['th-th'].title, error.data.error.locale['th-th'].message);
        } else {
          openDefaultErrorModal(error.data.error.locale['th-th'].title, error.data.error.locale['th-th'].message);
        }
      })
      .finally(() => {
        isLoadingActiveCopyto.value = false;
        isLoading(false);
      });
  };

  const onDirectSegment = () => {
    router.replace({
      path: '/segment/data-segment/segment-detail',
      query: {
        tab: router.currentRoute.value.query.tab,
        tableId: router.currentRoute.value.query.tableId,
        folderId: idFolder.value,
        folderName: router.currentRoute.value.query.folderName || nameFolder.value,
        segmentId: idSegment.value,
        segmentName: nameSegmentSelectCopyto.value,
        type: !router.currentRoute.value.query.type ? type.value : Number(router.currentRoute.value.query.type),
      },
    });
  };

  return {
    isLoadingActiveCopyto,
    nameSegmentCopyto,
    onSubmitDialogSegment,
  };
}
