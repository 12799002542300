<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30.483" height="30.275" viewBox="0 0 30.483 30.275">
    <g id="Group_34039" data-name="Group 34039" transform="translate(-1145 -804.336)">
      <path
        id="Path_22627"
        data-name="Path 22627"
        d="M14.846,3.462a7.528,7.528,0,1,0,7.529,7.528,7.529,7.529,0,0,0-7.529-7.528m0,11.894a4.366,4.366,0,1,1,4.366-4.366,4.372,4.372,0,0,1-4.366,4.366"
        transform="translate(1145.395 800.873)"
      />
      <path
        id="Path_22628"
        data-name="Path 22628"
        d="M28.185,23.056a22.729,22.729,0,0,0-25.887,0A5.426,5.426,0,0,0,5.462,32.9H25.022a5.426,5.426,0,0,0,3.163-9.843m-.978,5.05a2.235,2.235,0,0,1-2.185,1.631H5.461a2.234,2.234,0,0,1-2.185-1.631,2.088,2.088,0,0,1,.82-2.45,19.57,19.57,0,0,1,22.291,0,2.088,2.088,0,0,1,.82,2.45"
        transform="translate(1145 801.712)"
      />
    </g>
  </svg>
</template>
