<template>
  <svg id="Page-1" xmlns="http://www.w3.org/2000/svg" width="80.319" height="80.319" viewBox="0 0 80.319 80.319">
    <g id="_010---Secure-Password" data-name="010---Secure-Password">
      <path
        id="Shape"
        d="M4.016,65.594h6.693v8.032A6.7,6.7,0,0,0,17.4,80.319H62.917a6.7,6.7,0,0,0,6.693-6.693V65.594H76.3a4.016,4.016,0,0,0,4.016-4.016V48.192A4.016,4.016,0,0,0,76.3,44.176H69.61V36.144a6.7,6.7,0,0,0-6.693-6.693V22.757a22.757,22.757,0,0,0-45.514,0V29.45a6.7,6.7,0,0,0-6.693,6.693v8.032H4.016A4.016,4.016,0,0,0,0,48.192V61.578A4.016,4.016,0,0,0,4.016,65.594Zm62.917,8.032a4.016,4.016,0,0,1-4.016,4.016H17.4a4.016,4.016,0,0,1-4.016-4.016V65.594H66.933ZM20.08,22.757a20.08,20.08,0,0,1,40.16,0V29.45H54.885V22.757a14.725,14.725,0,0,0-29.45,0V29.45H20.08Zm8.032,6.693V22.757a12.048,12.048,0,0,1,24.1,0V29.45ZM13.387,36.144A4.016,4.016,0,0,1,17.4,32.128H62.917a4.016,4.016,0,0,1,4.016,4.016v8.032H13.387ZM2.677,48.192a1.339,1.339,0,0,1,1.339-1.339H76.3a1.339,1.339,0,0,1,1.339,1.339V61.578A1.339,1.339,0,0,1,76.3,62.917H4.016a1.339,1.339,0,0,1-1.339-1.339Z"
        fill="#aaa"
      />
      <path
        id="Shape-2"
        data-name="Shape"
        d="M4.225,45.775a1.339,1.339,0,0,0,1.857.371l1.934-1.29v1.515a1.339,1.339,0,1,0,2.677,0V44.855l1.934,1.29a1.339,1.339,0,1,0,1.486-2.228l-2.349-1.564,2.345-1.564a1.339,1.339,0,1,0-1.486-2.228l-1.93,1.29V38.339a1.339,1.339,0,0,0-2.677,0v1.515l-1.934-1.29A1.339,1.339,0,0,0,4.6,40.791l2.349,1.564L4.6,43.918a1.339,1.339,0,0,0-.371,1.857Z"
        transform="translate(1.355 12.53)"
        fill="#aaa"
      />
      <path
        id="Shape-3"
        data-name="Shape"
        d="M15.225,45.775a1.339,1.339,0,0,0,1.857.371l1.934-1.29v1.515a1.339,1.339,0,1,0,2.677,0V44.855l1.934,1.29a1.339,1.339,0,0,0,1.486-2.228l-2.349-1.564,2.345-1.564a1.339,1.339,0,0,0-1.486-2.228l-1.93,1.29V38.339a1.339,1.339,0,0,0-2.677,0v1.515l-1.934-1.29A1.339,1.339,0,0,0,15.6,40.791l2.349,1.564L15.6,43.918a1.339,1.339,0,0,0-.375,1.857Z"
        transform="translate(5.08 12.53)"
        fill="#aaa"
      />
      <path
        id="Shape-4"
        data-name="Shape"
        d="M37.225,45.775a1.339,1.339,0,0,0,1.857.371l1.934-1.29v1.515a1.339,1.339,0,1,0,2.677,0V44.855l1.934,1.29a1.339,1.339,0,1,0,1.486-2.228l-2.349-1.564,2.345-1.564a1.339,1.339,0,0,0-1.486-2.228l-1.93,1.29V38.339a1.339,1.339,0,0,0-2.677,0v1.515l-1.934-1.29A1.339,1.339,0,1,0,37.6,40.791l2.349,1.564L37.6,43.918a1.339,1.339,0,0,0-.375,1.857Z"
        transform="translate(12.53 12.53)"
        fill="#aaa"
      />
      <path
        id="Shape-5"
        data-name="Shape"
        d="M26.225,45.775a1.339,1.339,0,0,0,1.857.371l1.934-1.29v1.515a1.339,1.339,0,1,0,2.677,0V44.855l1.934,1.29a1.339,1.339,0,0,0,1.486-2.228l-2.349-1.564,2.345-1.564a1.339,1.339,0,0,0-1.486-2.228l-1.93,1.29V38.339a1.339,1.339,0,0,0-2.677,0v1.515l-1.934-1.29A1.339,1.339,0,0,0,26.6,40.791l2.349,1.564L26.6,43.918a1.339,1.339,0,0,0-.375,1.857Z"
        transform="translate(8.805 12.53)"
        fill="#aaa"
      />
      <path
        id="Shape-6"
        data-name="Shape"
        d="M17.355,26H13.339A1.339,1.339,0,0,0,12,27.339v4.016a1.339,1.339,0,0,0,2.677,0V28.677h2.677a1.339,1.339,0,1,0,0-2.677Z"
        transform="translate(4.064 8.805)"
        fill="#aaa"
      />
      <path
        id="Shape-7"
        data-name="Shape"
        d="M49.339,45.677h8.032a1.339,1.339,0,0,0,0-2.677H49.339a1.339,1.339,0,0,0,0,2.677Z"
        transform="translate(16.255 14.562)"
        fill="#aaa"
      />
    </g>
  </svg>
</template>
