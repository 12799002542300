import { useI18n } from 'vue-i18n';

export default function useConvertChannel() {
  const { t } = useI18n();

  function onlyUnique(value: any, index: any, array: string | any[]) {
    return array.indexOf(value) === index;
  }

  const convertListChannelName = (channel: any) => {
    const MockupListChannel = [1, 2, 1, 1, 1, 3];
    let ListChannel = [];
    let resolveValue = '';

    if (channel > -1) {
      // ListChannel = channel;

      // ถ้าทำเป็น List แล้ว ลบข้างล่างนี้
      ListChannel = String(channel).split('').map(Number);
      //
    } else {
      // ListChannel = MockupListChannel;
      ListChannel = channel;
    }
    if (ListChannel == null) {
      ListChannel = [];
    }
    ListChannel = ListChannel.filter(onlyUnique);
    ListChannel.forEach((channelNormal: any, index: number) => {
      if (index + 1 < ListChannel.length) {
        switch (channelNormal) {
          case 1:
            resolveValue = resolveValue + 'SMS, ';
            break;
          case 2:
            resolveValue = resolveValue + 'Email, ';
            break;
          case 3:
            resolveValue = resolveValue + 'Line, ';
            break;
        }
      } else {
        switch (channelNormal) {
          case 1:
            resolveValue = resolveValue + 'SMS';
            break;
          case 2:
            resolveValue = resolveValue + 'Email';
            break;
          case 3:
            resolveValue = resolveValue + 'Line';
            break;
        }
      }
    });
    return resolveValue;
  };

  const convertChannelName = (channel: number) => {
    let resolveValue = '';
    switch (channel) {
      case 1:
        resolveValue = 'SMS';
        break;
      case 2:
        resolveValue = 'EMAIL';
        break;
      case 3:
        resolveValue = 'LINE';
        break;
      default:
        break;
    }
    return resolveValue;
  };

  return {
    convertListChannelName,
    convertChannelName,
  };
}
