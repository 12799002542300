<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21.2" height="23.472" viewBox="0 0 21.2 23.472">
    <g id="Group_33574" data-name="Group 33574" transform="translate(0 0)">
      <g id="Group_640" data-name="Group 640" transform="matrix(0.755, 0.656, -0.656, 0.755, 14.809, 0)">
        <path id="Union_33" data-name="Union 33" d="M0,14.849H5.709L2.855,18.276Zm0,0V0H5.709V14.849Z" transform="translate(0 4.297)" />
        <g id="Group_444" data-name="Group 444" transform="translate(0 0)">
          <rect id="Rectangle_280" data-name="Rectangle 280" width="5.709" height="2.865" rx="1" transform="translate(0)" />
          <rect id="Rectangle_281" data-name="Rectangle 281" width="5.709" height="1.432" transform="translate(0 1.432)" />
        </g>
      </g>
      <rect id="Rectangle_440" data-name="Rectangle 440" width="21.199" height="2.355" rx="1" transform="translate(0 21.116)" />
    </g>
  </svg>
</template>
