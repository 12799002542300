export default function SegmentTemplateModel() {
  const fetchCategoryList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/SegmentTemplate/GetCategoryList`,
    method: 'GET',
    payload: {},
  };

  const fetchSegmentTemplateList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/SegmentTemplate/list`,
    method: 'POST',
    payload: {},
  };

  const fetchSegmentTemplateListCard: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/SegmentTemplate/listCard`,
    method: 'POST',
    payload: {},
  };
  return { fetchCategoryList, fetchSegmentTemplateList, fetchSegmentTemplateListCard };
}
