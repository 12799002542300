<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2145 1.92122L12.6385 4.80722C12.7557 5.04614 12.9293 5.25294 13.1443 5.40974C13.3593 5.56655 13.6092 5.66865 13.8725 5.70722L17.0625 6.17122C17.3659 6.21483 17.651 6.3426 17.8855 6.54001C18.1199 6.73743 18.2944 6.9966 18.389 7.28813C18.4837 7.57966 18.4947 7.89188 18.4209 8.18937C18.3471 8.48686 18.1915 8.75772 17.9715 8.97122L15.6635 11.2212C15.4733 11.4068 15.331 11.6358 15.2489 11.8886C15.1669 12.1414 15.1475 12.4103 15.1925 12.6722L15.7365 15.8492C15.7884 16.1508 15.7548 16.4608 15.6396 16.7443C15.5243 17.0278 15.3321 17.2733 15.0845 17.4531C14.8369 17.6329 14.5439 17.7399 14.2387 17.7618C13.9335 17.7837 13.6283 17.7198 13.3575 17.5772L10.5045 16.0772C10.2693 15.9535 10.0074 15.8888 9.74155 15.8888C9.47569 15.8888 9.21383 15.9535 8.97855 16.0772L6.12555 17.5772C5.85474 17.7197 5.54948 17.7835 5.24428 17.7615C4.93909 17.7396 4.64613 17.6326 4.39852 17.4529C4.15091 17.2731 3.95854 17.0276 3.84315 16.7442C3.72775 16.4608 3.69395 16.1508 3.74555 15.8492L4.29055 12.6722C4.33539 12.4103 4.31595 12.1414 4.23391 11.8887C4.15186 11.6359 4.00967 11.4069 3.81955 11.2212L1.51455 8.96822C1.29427 8.75494 1.13823 8.48416 1.06417 8.18663C0.9901 7.88911 1.00097 7.57677 1.09555 7.28512C1.19013 6.99347 1.36462 6.73419 1.5992 6.53675C1.83378 6.33931 2.11903 6.21163 2.42255 6.16822L5.61455 5.70722C5.87707 5.66788 6.12614 5.56543 6.34037 5.40868C6.5546 5.25192 6.72761 5.04553 6.84455 4.80722L8.27155 1.92122C8.40701 1.64688 8.61651 1.41591 8.87637 1.25441C9.13623 1.09291 9.43609 1.00732 9.74205 1.00732C10.048 1.00732 10.3479 1.09291 10.6077 1.25441C10.8676 1.41591 11.0771 1.64688 11.2125 1.92122"
      fill="white"
    />
    <path
      d="M11.2145 1.92122L12.6385 4.80722C12.7557 5.04614 12.9293 5.25294 13.1443 5.40974C13.3593 5.56655 13.6092 5.66865 13.8725 5.70722L17.0625 6.17122C17.3659 6.21483 17.651 6.3426 17.8855 6.54001C18.1199 6.73743 18.2944 6.9966 18.389 7.28813C18.4837 7.57966 18.4947 7.89188 18.4209 8.18937C18.3471 8.48686 18.1915 8.75772 17.9715 8.97122L15.6635 11.2212C15.4733 11.4068 15.331 11.6358 15.2489 11.8886C15.1669 12.1414 15.1475 12.4103 15.1925 12.6722L15.7365 15.8492C15.7884 16.1508 15.7548 16.4608 15.6396 16.7443C15.5243 17.0278 15.3321 17.2733 15.0845 17.4531C14.8369 17.6329 14.5439 17.7399 14.2387 17.7618C13.9335 17.7837 13.6283 17.7198 13.3575 17.5772L10.5045 16.0772C10.2693 15.9535 10.0074 15.8888 9.74155 15.8888C9.47569 15.8888 9.21383 15.9535 8.97855 16.0772L6.12555 17.5772C5.85474 17.7197 5.54948 17.7835 5.24428 17.7615C4.93909 17.7396 4.64613 17.6326 4.39852 17.4529C4.15091 17.2731 3.95854 17.0276 3.84315 16.7442C3.72775 16.4608 3.69395 16.1508 3.74555 15.8492L4.29055 12.6722C4.33539 12.4103 4.31595 12.1414 4.23391 11.8887C4.15186 11.6359 4.00967 11.4069 3.81955 11.2212L1.51455 8.96822C1.29427 8.75494 1.13823 8.48416 1.06417 8.18663C0.9901 7.88911 1.00097 7.57677 1.09555 7.28512C1.19013 6.99347 1.36462 6.73419 1.5992 6.53675C1.83378 6.33931 2.11903 6.21163 2.42255 6.16822L5.61455 5.70722C5.87707 5.66788 6.12614 5.56543 6.34037 5.40868C6.5546 5.25192 6.72761 5.04553 6.84455 4.80722L8.27155 1.92122C8.40701 1.64688 8.61651 1.41591 8.87637 1.25441C9.13623 1.09291 9.43609 1.00732 9.74205 1.00732C10.048 1.00732 10.3479 1.09291 10.6077 1.25441C10.8676 1.41591 11.0771 1.64688 11.2125 1.92122"
      stroke="#ACACAC"
    />
  </svg>
</template>
