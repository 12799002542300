<template>
  <svg id="Group_34737" data-name="Group 34737" xmlns="http://www.w3.org/2000/svg" width="21.953" height="16.525" viewBox="0 0 21.953 16.525">
    <path
      id="Path_22792"
      data-name="Path 22792"
      d="M447.638,6.947l-15-2.188a1.349,1.349,0,0,0-1.53,1.141L430.7,8.7H428.19a1.35,1.35,0,0,0-1.35,1.35v9.87a1.349,1.349,0,0,0,1.35,1.349h15.159a1.349,1.349,0,0,0,1.349-1.349v-.7l1.125.164a1.35,1.35,0,0,0,1.53-1.141l1.424-9.767a1.349,1.349,0,0,0-1.141-1.53m-4.716,12.535-14.3.011V10.478h14.3Zm2.753-1.837L444.7,17.5V10.05a1.35,1.35,0,0,0-1.349-1.35H432.494l.309-2.121,14.155,2.064Z"
      transform="translate(-426.84 -4.745)"
    />
    <path
      id="Path_22793"
      data-name="Path 22793"
      d="M471.411,50.963l-2.5-1.8a.42.42,0,0,0-.666.34v3.886a.42.42,0,0,0,.689.323l2.5-2.082a.42.42,0,0,0-.023-.663"
      transform="translate(-460.831 -41.139)"
    />
  </svg>
</template>
