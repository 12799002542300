<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.364" height="17.364" viewBox="0 0 17.364 17.364">
    <path d="M8.682,0A8.682,8.682,0,1,1,0,8.682,8.682,8.682,0,0,1,8.682,0Z" fill="#ff7272" />
    <g transform="translate(5.256 5.256)">
      <path
        d="M-9444.442,20.1l6.853,6.852"
        transform="translate(9444.442 -20.099)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-width="1"
      />
      <path
        d="M-9437.589,20.1l-6.852,6.852"
        transform="translate(9444.44 -20.099)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-width="1"
      />
    </g>
  </svg>
</template>
