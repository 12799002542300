import { ref, getCurrentInstance, onBeforeMount, VNode, Ref, provide, watch } from 'vue';
import { BaseTabs } from '@/types/modules/tabs/tabs';

interface Props {
  modelValue?: number;
}

export default function useBaseTabs(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const tabs: Ref<HTMLDivElement> = ref(null!);
  const tabTitles: Ref<Record<string, any>[]> = ref([]);
  const selectedTitle = ref('');
  const selectedIndex = ref(0);

  const onSelectTab = (tabIndex: number, tabTitle: string) => {
    const activateEvent = new CustomEvent('activate-tab', { cancelable: true, detail: { from: selectedIndex.value, to: tabIndex } });

    if (tabs.value.dispatchEvent(activateEvent)) {
      selectedTitle.value = tabTitle;
      selectedIndex.value = tabIndex;
      return vm?.$emit('update:modelValue', tabIndex);
    }
  };

  const initTabs = () => {
    const slots = vm?.$slots;

    const titleList = (<VNode[]>(<any>slots).default())
      .filter((tab) => tab.type.hasOwnProperty('__name'))
      .map((tab) => ({
        title: (<BaseTabs.Tab>tab.props)?.title,
        icon: (<BaseTabs.Tab>tab.props)?.icon,
        iconPosition: tab.props?.['icon-position'],
      }));

    tabTitles.value = titleList;

    if (typeof props.modelValue == 'number') {
      selectedTitle.value = titleList[props.modelValue].title || '';
      selectedIndex.value = props.modelValue;
    } else {
      selectedTitle.value = titleList[0].title || '';
      selectedIndex.value = 0;
    }
  };

  watch(
    () => props.modelValue,
    () => {
      if (typeof props.modelValue == 'number') {
        selectedTitle.value = tabTitles.value[props.modelValue].title || '';
        return (selectedIndex.value = props.modelValue);
      }

      selectedTitle.value = tabTitles.value[0].title || '';
      return (selectedIndex.value = 0);
    },
  );

  watch(
    () => <VNode[]>(<any>vm?.$slots).default(),
    () => {
      initTabs();
    },
  );

  onBeforeMount(() => {
    initTabs();

    provide('selectedTitle', selectedTitle);
  });

  return { tabTitles, tabs, selectedTitle, selectedIndex, onSelectTab };
}
