<template>
  <svg width="187.828" height="171.248" viewBox="0 0 188.649 149.277" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter id="Union_239-7" x="51.302" y="0" width="126.715" height="126.782" filterUnits="userSpaceOnUse">
        <feOffset dx="-5" dy="5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="12.5" result="blur" />
        <feFlood flood-opacity="0.102" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_239-8" x="51.302" y="0" width="126.715" height="126.782" filterUnits="userSpaceOnUse">
        <feOffset dy="5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="2.5" result="blur-2" />
        <feFlood flood-color="#ffa193" result="color" />
        <feComposite operator="out" in="SourceGraphic" in2="blur-2" />
        <feComposite operator="in" in="color" />
        <feComposite operator="in" in2="SourceGraphic" />
      </filter>
      <filter id="Rectangle_48635" x="79.576" y="21.323" width="81.019" height="94.56" filterUnits="userSpaceOnUse">
        <feOffset dy="15" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="12.5" result="blur-3" />
        <feFlood flood-color="#fff" flood-opacity="0.545" />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Ellipse_4105" x="79.576" y="43.291" width="81.019" height="81.019" filterUnits="userSpaceOnUse">
        <feOffset dy="15" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="12.5" result="blur-4" />
        <feFlood flood-color="#fff" flood-opacity="0.545" />
        <feComposite operator="in" in2="blur-4" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_239-6" x="74.93" y="58.35" width="112.898" height="112.898" filterUnits="userSpaceOnUse">
        <feOffset dy="25" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="17.5" result="blur-5" />
        <feFlood flood-opacity="0.051" />
        <feComposite operator="in" in2="blur-5" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_239-7" x="0" y="43.423" width="109.798" height="109.798" filterUnits="userSpaceOnUse">
        <feOffset dy="25" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="17.5" result="blur-6" />
        <feFlood flood-opacity="0.051" />
        <feComposite operator="in" in2="blur-6" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_239-8" x="34.004" y="1.991" width="115.527" height="115.527" filterUnits="userSpaceOnUse">
        <feOffset dy="25" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="17.5" result="blur-7" />
        <feFlood flood-opacity="0.051" />
        <feComposite operator="in" in2="blur-7" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_115487" data-name="Group 115487" transform="matrix(1.951467, 0, 0, 1.951467, 3.016571, 2.588528)" style="">
      <g id="Group_110110" data-name="Group 110110" transform="translate(15.182 3.009)">
        <g id="Group_110106" data-name="Group 110106" transform="translate(0 0)">
          <g id="Group_110104" data-name="Group 110104" transform="translate(0 18.055)">
            <path
              id="Union_239-6"
              data-name="Union 239"
              d="M.91,2.065C4.141,3.7,6.674,5.134,8.388,6.159A23.632,23.632,0,1,0,23.914.344h-.25C22.315.28,16.25,0,10.6,0,3.811,0-2.382.4.91,2.065Z"
              transform="translate(47.545 47.607) rotate(180)"
              fill="#fff"
            />
          </g>
          <g id="Group_110105" data-name="Group 110105" transform="translate(26.12)">
            <g data-type="innerShadowGroup">
              <g transform="matrix(1, 0, 0, 1, -93.8, -32.5)" filter="url(#Union_239-7)">
                <path
                  id="Union_239-7"
                  data-name="Union 239"
                  d="M.99,49.537C4.5,47.763,7.259,46.2,9.124,45.083a25.7,25.7,0,1,1,16.887,6.325l-.272,0c-1.468.072-8.064.376-14.212.376C4.145,51.782-2.591,51.344.99,49.537Z"
                  transform="translate(93.8 32.5)"
                  fill="#e36352"
                />
              </g>
              <g transform="matrix(1, 0, 0, 1, -93.8, -32.5)" filter="url(#Union_239-8)">
                <path
                  id="Union_239-8"
                  data-name="Union 239"
                  d="M.99,49.537C4.5,47.763,7.259,46.2,9.124,45.083a25.7,25.7,0,1,1,16.887,6.325l-.272,0c-1.468.072-8.064.376-14.212.376C4.145,51.782-2.591,51.344.99,49.537Z"
                  transform="translate(93.8 32.5)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Group_110109" data-name="Group 110109" transform="translate(49.394 11.323)">
          <g transform="matrix(1, 0, 0, 1, -117.08, -43.82)" filter="url(#Rectangle_48635)">
            <rect
              id="Rectangle_48635-2"
              data-name="Rectangle 48635"
              width="6.019"
              height="19.56"
              rx="3.009"
              transform="translate(117.08 43.82)"
              fill="#fff"
            />
          </g>
          <g transform="matrix(1, 0, 0, 1, -117.08, -43.82)" filter="url(#Ellipse_4105)">
            <ellipse
              id="Ellipse_4105-2"
              data-name="Ellipse 4105"
              cx="3.009"
              cy="3.009"
              rx="3.009"
              ry="3.009"
              transform="translate(117.08 65.79)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
      <g id="Group_115388" data-name="Group 115388" transform="translate(74.93 56.359)">
        <g transform="matrix(1, 0, 0, 1, -127.43, -85.85)" filter="url(#Union_239-6)">
          <path
            id="Union_239-9"
            data-name="Union 239"
            d="M3.949,0A3.949,3.949,0,1,1,0,3.949,3.949,3.949,0,0,1,3.949,0Z"
            transform="translate(135.33 93.75) rotate(180)"
            fill="#e46453"
          />
        </g>
      </g>
      <g id="Group_115389" data-name="Group 115389" transform="translate(4.798 41.433) rotate(90)">
        <g transform="matrix(0, -1, 1, 0, -70.92, 57.3)" filter="url(#Union_239-7)">
          <path
            id="Union_239-10"
            data-name="Union 239"
            d="M2.4,0A2.4,2.4,0,1,1,0,2.4,2.4,2.4,0,0,1,2.4,0Z"
            transform="translate(52.5 75.72) rotate(-90)"
            fill="#e46453"
          />
        </g>
      </g>
      <g id="Group_115390" data-name="Group 115390" transform="translate(34.004)">
        <g transform="matrix(1, 0, 0, 1, -86.5, -29.49)" filter="url(#Union_239-8)">
          <path
            id="Union_239-11"
            data-name="Union 239"
            d="M5.263,0A5.263,5.263,0,1,1,0,5.263,5.263,5.263,0,0,1,5.263,0Z"
            transform="translate(97.03 40.02) rotate(180)"
            fill="#e46453"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
