<template>
  <svg id="Group_30914" data-name="Group 30914" xmlns="http://www.w3.org/2000/svg" width="19" height="22.001" viewBox="0 0 19 22.001">
    <path
      id="Subtraction_246"
      data-name="Subtraction 246"
      d="M16,19H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H16a3,3,0,0,1,3,3V16A3,3,0,0,1,16,19ZM3.192,6.577a1,1,0,0,0-1,1v8.231a1,1,0,0,0,1,1H15.808a1,1,0,0,0,1-1V7.577a1,1,0,0,0-1-1Z"
      transform="translate(0 3)"
    />
    <g id="Group_30913" data-name="Group 30913" transform="translate(4 11)">
      <rect id="Rectangle_13967" data-name="Rectangle 13967" width="3" height="3" />
      <rect id="Rectangle_13970" data-name="Rectangle 13970" width="3" height="3" transform="translate(4)" />
      <rect id="Rectangle_13972" data-name="Rectangle 13972" width="3" height="3" transform="translate(8)" />
      <rect id="Rectangle_13968" data-name="Rectangle 13968" width="3" height="3" transform="translate(0 4)" />
      <rect id="Rectangle_13969" data-name="Rectangle 13969" width="3" height="3" transform="translate(4 4)" />
      <rect id="Rectangle_13971" data-name="Rectangle 13971" width="3" height="3" transform="translate(8 4)" />
    </g>
    <rect id="Rectangle_13973" data-name="Rectangle 13973" width="3" height="5" rx="1" transform="translate(5)" />
    <rect id="Rectangle_13974" data-name="Rectangle 13974" width="3" height="5" rx="1" transform="translate(12)" />
  </svg>
</template>
