export default function calendarModel() {
  const fetchCalendarListModel: API.RequestModel<Communication.Calendar.Request.CalendarList> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Calendar/List3`,
    method: 'POST',
    payload: {
      Month: null,
      Year: null!,
      Channel: [],
      Campaign_Status: [],
      Note_Status: [],
      Search: '',
      Filters: [],
      Ordering: [],
    },
  };
  return {
    fetchCalendarListModel,
  };
}
