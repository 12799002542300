<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="106.735" height="106.734" viewBox="0 0 106.735 106.734">
    <g>
      <g>
        <path
          d="M74.27,37.97l2.77,2.77c0.46,0.46,0.46,1.2,0,1.66L47.68,71.76c-0.46,0.46-1.2,0.46-1.66,0L32.96,58.7
			c-0.46-0.46-0.46-1.2,0-1.66l2.77-2.77c0.46-0.46,1.2-0.46,1.66,0l9.46,9.46l25.76-25.76C73.07,37.51,73.81,37.51,74.27,37.97z"
        />
      </g>
      <path
        d="M55,5C27.39,5,5,27.39,5,55c0,27.61,22.39,50,50,50c27.61,0,50-22.39,50-50C105,27.39,82.61,5,55,5z
		 M55,100.74C29.78,100.74,9.26,80.22,9.26,55S29.78,9.26,55,9.26S100.74,29.78,100.74,55S80.22,100.74,55,100.74z"
      />
    </g>
  </svg>
</template>
