<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g>
      <g class="circle" stroke="#CECECE" stroke-width="1">
        <circle cx="16" cy="16" r="16" stroke="none" />
        <circle cx="16" cy="16" r="15.5" fill="none" />
      </g>
    </g>
    <g class="icon" transform="translate(8.134 9.992)">
      <path d="M15.621,5.407H0V0H1.894V3.362H13.728V0h1.893V5.406Z" transform="translate(0 6.609)" />
      <rect width="2.02" height="6.464" transform="translate(7.172 1.511)" />
      <path d="M2.7,0l2.7,3H0Z" transform="translate(5.369)" />
    </g>
  </svg>
</template>
