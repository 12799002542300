export default function limitationModel() {
  const createOrUpdateLimitDetailModel: API.RequestModel<Communication.Limitation.Request.Save> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CommuLimit/CreateOrUpdateLimitDetail`,
    method: 'POST',
    payload: {
      channel: null,
      enable_limit_amount: false,
      enable_limit_time: false,
      enable_limit_time_week: false,
      enable_limit_time_date: false,
      handle_limit_time_mode: null,
      detail_limit_amount: {
        enable_limit_per_day: false,
        limit_per_day: null,
        enable_limit_per_month: false,
        limit_per_month: null,
        enable_limit_per_year: false,
        limit_per_year: null,
      },
      list_limit_week: [],
      list_limit_date: [],
    },
  };
  const getLimitDetailByChannelModel: API.RequestModel<{ channel: number | null }> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CommuLimit/GetLimitDetailByChannel`,
    method: 'POST',
    payload: {
      channel: null,
    },
  };
  return {
    createOrUpdateLimitDetailModel,
    getLimitDetailByChannelModel,
  };
}
