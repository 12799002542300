import { ref } from 'vue';

const clearTimeout = window.clearTimeout;
const setTimeout = window.setTimeout;

export function useDebounce<T extends (...args: any[]) => any>(wait: number, callback: T) {
  const waitTime = ref<number>(wait);
  const tid = ref<number | null>(null);

  const setWait = (wait: number) => {
    waitTime.value = wait;

    // debounce again to update wait time
    if (tid.value) {
      debounce();
    }
  };

  const cancel = () => tid.value && clearTimeout(tid.value);
  const debounce = ((...args: any[]) => {
    // if no wait time, immediate trigger
    if (wait <= 0) {
      callback(...args);
      return;
    }

    // otherwise, reset timeout (clear and reset)
    cancel();

    tid.value = setTimeout(() => {
      callback(...args);
      tid.value = null;
    }, waitTime.value);
  }) as T;

  return { cancel, debounce, setWait };
}
