import { ref, Ref, onMounted } from 'vue';
import router from '@/router';
import api from '@services/api';

import dataConnectModel from '@models/dataConnect/dataConnect';

import imgSms from '@assets/images/modules/data-connect/Icon_Sms.png';
import imgEmail from '@assets/images/modules/data-connect/Icon_Email.png';
import imgLine from '@assets/images/modules/data-connect/Icon_Line.png';

import { statusLine } from '../constants/statusLine';
import { directionLine } from '../constants/statusLine';
import { datasourceConnect } from '../constants/statusLine';

interface DataSourceItem {
  datasource_id: number;
  title: string;
  icon_url: string;
  connect_status: number;
  last_updated: string;
  frontend_key: string;
  amount: string;
  directionLineUp: number;
  directionLineDown: number;
  statusLineHorizontal: number;
  statusLineVerticalUp: number;
  statusLineVerticalDown: number;
  customData: any;
}

type CommuListItem = DataSourceItem;

export default function useMainPage() {
  const { fetchDataConnectListModel, fetchCommuListModel } = dataConnectModel();

  const loading: Ref<{ entirePage: boolean; dataConnectedList: boolean; commuList: boolean }> = ref({
    entirePage: false,
    dataConnectedList: false,
    commuList: false,
  });

  const dataSourceList: Ref<DataSourceItem[]> = ref([]);
  const commuList: Ref<CommuListItem[]> = ref([]);
  const cdpSync: Ref<boolean> = ref(false);
  const isNotiCreditModalOpen = ref<boolean>(false);
  const isNotiCreditSuccessOpen = ref<boolean>(false);
  const modalRemoveDataSource: Ref<boolean> = ref(false);
  const actionConnectDataSourceFunc = ref(() => {});
  const modalContinueDataSource: Ref<boolean> = ref(false);
  const actionConnectDataSourceCustomData: any = ref({});

  const newDataConnect = () => {
    router.push({ path: '/dataConnector/addDataSource' }).then(() => {});
  };
  const newCommuConnect = () => {
    router.push({ path: '/communication' }).then(() => {});
  };

  const resolveDateTime = (dateInput: string) => {
    const date = new Date(dateInput);
    let dateStr = '';

    try {
      dateStr = `${date.toLocaleString('default', {
        day: '2-digit',
      })}-${date.toLocaleString('default', {
        month: '2-digit',
      })}-${date.getFullYear()} ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
      }`;
    } catch {
      return 'date.invalid';
    }

    return dateStr;
  };

  const iconMapping: any = {
    sms: imgSms,
    email: imgEmail,
    'line-msg': imgLine,
  };

  // Addition property of datasource list
  const buildDataConnectListData = (dataSourceListInp: any) => {
    return dataSourceListInp.map((o: any) => {
      o.actionOnClick = (customData: any) => {
        const switchCondition: any = {
          dataconnect: () => {},
          Csv: () => {
            router.push({ path: '/dataConnector/excelFileHistory' }).then(() => {});
          },
        };
        switchCondition[customData.type] && switchCondition[customData.type]();
      };

      o.actionOnClickConnect = (customData: any) => {
        //send request change
        switch (o.status) {
          case 0:
            actionConnectDataSourceCustomData.value = customData;
            modalContinueDataSource.value = true;
            break;
          case 1:
            actionConnectDataSourceCustomData.value = customData;
            modalContinueDataSource.value = true;
            break;
          default:
        }
      };

      o.actionOnClickRemove = (customData: any) => {
        actionConnectDataSourceFunc.value = () => {
          return buildDataConnectListData(dataSourceListInp.filter((dataSource: any) => dataSource.id != customData.id));
        };
        modalRemoveDataSource.value = true;
      };

      o.directionLineUp = directionLine.down;
      o.directionLineDown = directionLine.up;
      o.statusLineHorizontal = o.connect_status;
      o.statusLineVerticalUp = o.connect_status;
      o.statusLineVerticalDown = o.connect_status;
      o.last_updated = o.last_updated != null ? resolveDateTime(o.last_updated) : '';
      o.customData = { type: o.frontend_key };
      return o;
    });
  };
  const onCloseNotiCreditModal = () => {
    isNotiCreditModalOpen.value = false;
  };
  const onOpenNotiCreditModal = () => {
    isNotiCreditModalOpen.value = true;
  };
  const onCloseNotiCreditSuccess = () => {
    isNotiCreditSuccessOpen.value = false;
  };
  const onOpenNotiCreditSuccess = () => {
    isNotiCreditSuccessOpen.value = true;
  };
  // Addition property of commu list
  const buildCommuListData = (commuListInp: any) => {
    return commuListInp.map((o: any) => {
      o.actionOnClick = (customData: any) => {
        const switchCondition: any = {
          dataconnect: () => {},
          sms: () => {
            router.push({ path: '/communication', query: { channel: 'sms', tab: '1' } }).then(() => {});
          },
          email: () => {
            router.push({ path: '/communication', query: { channel: 'email', tab: '1' } }).then(() => {});
          },
          linemsg: () => {
            router.push({ path: '/communication', query: { channel: 'line-oa', tab: '1' } }).then(() => {});
          },
        };
        switchCondition[customData.type] && switchCondition[customData.type]();
      };

      o.directionLineUp = directionLine.down;
      o.directionLineDown = directionLine.up;
      o.statusLineHorizontal = o.connect_status;
      o.statusLineVerticalUp = o.connect_status;
      o.statusLineVerticalDown = o.connect_status;
      o.icon_url = iconMapping[o.key] ? iconMapping[o.key] : o.icon_url;
      o.last_updated = o.last_updated != null ? resolveDateTime(o.last_updated) : '';
      o.customData = { type: o.frontend_key };
      return o;
    });
  };

  // Set direction, style of line
  const setLine = (dataSourceListInp: any, datasource: any) => {
    if (dataSourceListInp != null && dataSourceListInp.length != 0) {
      // Set hide of first line up above
      dataSourceListInp[0].statusLineVerticalUp = 0;
      // Set hide of last line below
      dataSourceListInp[dataSourceListInp.length - 1].statusLineVerticalDown = 0;

      // ** priority of line 1, 3, 2
      // divide data 3set.
      // top haft, lower haft, mid
      const evenNumber = dataSourceListInp.length % 2 == 0;
      const split = Math.floor(dataSourceListInp.length / 2);
      const itemTopHaft = dataSourceListInp.splice(0, split);
      const itemLowerHaft = dataSourceListInp.splice(evenNumber ? 0 : 1, split);
      const itemMid = !evenNumber ? dataSourceListInp.splice(0) : null;

      // == top haft ==
      for (let i = 0; i < itemTopHaft.length; i++) {
        // set direction
        if (datasource == datasourceConnect.datasource) {
          itemTopHaft[i].directionLineUp = directionLine.down;
          itemTopHaft[i].directionLineDown = directionLine.down;
        } else if (datasource == datasourceConnect.commu) {
          itemTopHaft[i].directionLineUp = directionLine.up;
          itemTopHaft[i].directionLineDown = directionLine.up;
        }
        // - set line up
        //   check line down of before index
        if (i != 0) {
          itemTopHaft[i].statusLineVerticalUp = itemTopHaft[i - 1].statusLineVerticalDown;
        }
        // - set style of line down
        //   check line up of index own + horizontal line (with priority)
        if (itemTopHaft[i].statusLineHorizontal == statusLine.dotLine || itemTopHaft[i].statusLineVerticalUp == statusLine.dotLine)
          itemTopHaft[i].statusLineVerticalDown = statusLine.dotLine;
        else if (itemTopHaft[i].statusLineHorizontal == statusLine.solidBlueLine || itemTopHaft[i].statusLineVerticalUp == statusLine.solidBlueLine)
          itemTopHaft[i].statusLineVerticalDown = statusLine.solidBlueLine;
        else if (itemTopHaft[i].statusLineHorizontal == statusLine.solidLine || itemTopHaft[i].statusLineVerticalUp == statusLine.solidLine)
          itemTopHaft[i].statusLineVerticalDown = statusLine.solidLine;
      }

      // == lower after ==
      for (let i = itemLowerHaft.length - 1; i >= 0; i--) {
        // set direction
        if (datasource == datasourceConnect.datasource) {
          itemLowerHaft[i].directionLineUp = directionLine.up;
          itemLowerHaft[i].directionLineDown = directionLine.up;
        } else if (datasource == datasourceConnect.commu) {
          itemLowerHaft[i].directionLineUp = directionLine.down;
          itemLowerHaft[i].directionLineDown = directionLine.down;
        }
        // - set line down
        //   check line up of index + 1
        if (i != itemLowerHaft.length - 1) {
          itemLowerHaft[i].statusLineVerticalDown = itemLowerHaft[i + 1].statusLineVerticalUp;
        }
        // - set style of line up
        //   check line down of index own + horizontal line (with priority)
        if (itemLowerHaft[i].statusLineHorizontal == statusLine.dotLine || itemLowerHaft[i].statusLineVerticalDown == statusLine.dotLine)
          itemLowerHaft[i].statusLineVerticalUp = statusLine.dotLine;
        else if (
          itemLowerHaft[i].statusLineHorizontal == statusLine.solidBlueLine ||
          itemLowerHaft[i].statusLineVerticalDown == statusLine.solidBlueLine
        )
          itemLowerHaft[i].statusLineVerticalUp = statusLine.solidBlueLine;
        else if (itemLowerHaft[i].statusLineHorizontal == statusLine.solidLine || itemLowerHaft[i].statusLineVerticalDown == statusLine.solidLine)
          itemLowerHaft[i].statusLineVerticalUp = statusLine.solidLine;
      }

      // == mid ==
      if (itemMid != null) {
        // set direction
        if (datasource == datasourceConnect.datasource) {
          itemMid[0].directionLineUp = directionLine.down;
          itemMid[0].directionLineDown = directionLine.up;
        } else if (datasource == datasourceConnect.commu) {
          itemMid[0].directionLineUp = directionLine.up;
          itemMid[0].directionLineDown = directionLine.down;
        }
        // set style of line
        if (itemTopHaft.length != 0) itemMid[0].statusLineVerticalUp = itemTopHaft[itemTopHaft.length - 1].statusLineVerticalDown;
        if (itemLowerHaft.length != 0) itemMid[0].statusLineVerticalDown = itemLowerHaft[0].statusLineVerticalUp;
      }

      // merge data
      for (const item of itemTopHaft) {
        dataSourceListInp.push(item);
      }
      if (itemMid != null) {
        for (const item of itemMid) {
          dataSourceListInp.push(item);
        }
      }
      for (const item of itemLowerHaft) {
        dataSourceListInp.push(item);
      }
    }

    return dataSourceListInp;
  };

  const fetchDataConnectedList = async () => {
    loading.value.dataConnectedList = true;
    return await api
      .apiRequest(fetchDataConnectListModel)
      .then((response) => {
        dataSourceList.value = buildDataConnectListData(response.data.payload);
        dataSourceList.value = setLine(dataSourceList.value, datasourceConnect.datasource);
        if (response.data.payload.length > 0) {
          cdpSync.value = true;
        }
      })
      .finally(() => {
        loading.value.dataConnectedList = false;
      });
  };

  const fetchCommuList = async () => {
    loading.value.commuList = true;
    return await api
      .apiRequest(fetchCommuListModel)
      .then((response) => {
        commuList.value = buildCommuListData(response.data.payload);
        commuList.value = setLine(commuList.value, datasourceConnect.commu);
        if (response.data.payload.length > 0) {
          cdpSync.value = true;
        }
      })
      .finally(() => {
        loading.value.commuList = false;
      });
  };

  onMounted(async () => {
    loading.value.entirePage = true;
    await fetchDataConnectedList();
    await fetchCommuList();
    loading.value.entirePage = false;
  });

  return {
    loading,
    dataSourceList,
    commuList,
    cdpSync,
    newDataConnect,
    newCommuConnect,
    isNotiCreditModalOpen,
    onCloseNotiCreditModal,
    onOpenNotiCreditModal,
    isNotiCreditSuccessOpen,
    onOpenNotiCreditSuccess,
    onCloseNotiCreditSuccess,
  };
}
