<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="11.925" height="13.203" viewBox="0 0 11.925 13.203">
        <g transform="translate(0 0)">
            <g transform="matrix(0.755, 0.656, -0.656, 0.755, 8.33, 0)">
                <path d="M0,8.352H3.211L1.606,10.28Zm0,0V0H3.211V8.352Z" transform="translate(0 2.417)" />
                <g transform="translate(0 0)">
                    <rect width="3.211" height="1.611" rx="0.806" transform="translate(0 0)" />
                    <rect width="3.211" height="0.806" transform="translate(0 0.806)" />
                </g>
            </g>
            <rect width="11.924" height="1.325" rx="0.662" transform="translate(0 11.878)" />
        </g>
    </svg>
</template>