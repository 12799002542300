import { Ref, ref, reactive, getCurrentInstance, onMounted, watch } from 'vue';
import { DateTime } from 'luxon';

interface Props {
  isOpenModal: boolean;
  returnFileData: any;
  uploadFileComplate: boolean;
  uploadFileError: boolean;
}

enum progress {
  is_Ready = 0,
  in_progress = 1,
  is_success = 2,
  is_Error = 9,
}

export default function useUploadPanel(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const inputUploadRef: Ref<HTMLDivElement> = ref(null!);
  const panelDragOver: Ref<boolean> = ref(false);

  const isProgress: Ref<number> = ref(progress.is_Ready);

  const fileName: Ref<string> = ref('');
  const fileUpload: Ref<string> = ref('');

  const onChooseFiles = (e: any) => {
    e.preventDefault();
    panelDragOver.value = false;
    isProgress.value = progress.in_progress;

    let fileData = null;

    if (e.dataTransfer != null) {
      if (e.dataTransfer.items) {
        if (e.dataTransfer.items.length > 0) {
          if (e.dataTransfer.items[0].kind === 'file') {
            const file = e.dataTransfer.items[0].getAsFile();
            fileData = file;
          }
        }
      } else {
        if (e.dataTransfer.files.length > 0) {
          const file = e.dataTransfer.files[0];
          fileData = file;
        }
      }
    } else {
      if (e.currentTarget.files.length > 0) {
        const file = e.currentTarget.files[0];
        fileData = file;
      }
    }

    if (fileData != null) {
      fileName.value = fileData.name;
      convertDatefileUpdate();
      const func: any = props.returnFileData;
      func(fileData);
      vm?.$emit('on-progress', { current_progress: isProgress.value });
    }
  };

  const convertDatefileUpdate = () => {
    const normalDate = new Date().toISOString();
    fileUpload.value = String(DateTime.fromISO(normalDate, { zone: 'utc+7' }).toFormat("dd'-'LL'-'yy' 'H'.'mm"));
  };

  const onDrop = (e: any, drop: boolean) => {
    e.preventDefault();
    panelDragOver.value = drop;
  };

  watch(
    () => props.isOpenModal,
    () => {
      if (props.isOpenModal == false) {
        isProgress.value = progress.is_Ready;
      }
    },
    { deep: true },
  );

  watch(
    () => [props.uploadFileComplate, props.uploadFileError],
    () => {
      if (props.uploadFileComplate) {
        isProgress.value = progress.is_success;
        vm?.$emit('on-progress', { current_progress: isProgress.value });
      }
      if (props.uploadFileError) {
        isProgress.value = progress.is_Error;
        vm?.$emit('on-progress', { current_progress: isProgress.value });
      }
    },
    { deep: true },
  );

  return {
    inputUploadRef,
    isProgress,
    panelDragOver,
    fileName,
    fileUpload,
    onDrop,
    onChooseFiles,
  };
}
