export default function fileServiceModel() {
  const uploadFileModel: API.RequestModel<{
    File: File;
    Is_Temp: boolean;
    Options: {
      Gen_preview?: boolean;
    };
    Path?: string;
  }> = {
    endpoint: `${process.env.BASE_URL_FILE_SERVICE!}/File/AddAndShare`,
    method: 'POST',
    payload: {
      File: null!,
      Is_Temp: null!,
      Options: {},
      Path: null!,
    },
  };

  const editFileModel: API.RequestModel<{
    Sref: string;
    File: File;
    Is_Temp: boolean;
    Options: {
      Gen_preview?: boolean;
    };
    Path?: string;
  }> = {
    endpoint: `${process.env.BASE_URL_FILE_SERVICE!}/File/Edit`,
    method: 'POST',
    payload: {
      Sref: null!,
      File: null!,
      Is_Temp: null!,
      Options: {},
      Path: null!,
    },
  };

  const deleteFileModel: API.RequestModel<{ Sref: string }> = {
    endpoint: `${process.env.BASE_URL_FILE_SERVICE!}/File/Delete`,
    method: 'POST',
    payload: {
      Sref: null!,
    },
  };

  const getFileModel: API.RequestModel<{ Sref: string }> = {
    endpoint: `${process.env.BASE_URL_FILE_SERVICE!}/File/Get`,
    method: 'POST',
    payload: {
      Sref: null!,
    },
  };

  return {
    uploadFileModel,
    editFileModel,
    deleteFileModel,
    getFileModel,
  };
}
