<template>
  <svg
    id="Group_106"
    data-name="Group 106"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="26.903"
    height="31.348"
    viewBox="0 0 26.903 31.348"
  >
    <g id="Group_115" data-name="Group 115" transform="translate(0 0)">
      <g id="Group_114" data-name="Group 114">
        <g id="Group_114-2" data-name="Group 114">
          <path
            id="Path_52"
            data-name="Path 52"
            d="M26.9,17.672c-.068-2.161-.014-4.325-.014-6.488q0-3.656,0-7.312a1.7,1.7,0,0,0-1.41-2.006A36.669,36.669,0,0,0,14.61.025a39.308,39.308,0,0,0-7.042.4A29.464,29.464,0,0,0,1.195,1.952,1.62,1.62,0,0,0,0,3.679q0,7.288.006,14.575A8.5,8.5,0,0,0,.154,19.79a10.17,10.17,0,0,0,2.791,5.16,26.732,26.732,0,0,0,9.966,6.319,1.985,1.985,0,0,0,1.26-.059,21.064,21.064,0,0,0,2.345-1.024,24.281,24.281,0,0,0,7.758-5.6A9.724,9.724,0,0,0,26.9,17.672m-4.7,5.1A21.258,21.258,0,0,1,15.3,27.715c-.586.289-1.207.585-1.818.815a23.363,23.363,0,0,1-8.535-5.477,7.448,7.448,0,0,1-2.081-3.77,5.716,5.716,0,0,1-.1-1.035q-.014-6.4-.007-12.8V4.316a28.1,28.1,0,0,1,5.2-1.164,37.8,37.8,0,0,1,5.336-.4c.406,0,.814.008,1.213.023A34.271,34.271,0,0,1,24.13,4.319v6.865c0,.658-.005,1.316-.01,1.975-.012,1.5-.024,3.05.025,4.6A6.964,6.964,0,0,1,22.2,22.774"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            id="Path_53"
            data-name="Path 53"
            d="M43.958,39.615c-1.436,0-2.873,0-4.309,0-.634,0-.635,0-.635-.641q0-4.286,0-8.572c0-.561-.157-.719-.712-.727-.468-.007-.936-.006-1.4,0-.584.007-.748.168-.749.747q0,2.833,0,5.666,0,2.785,0,5.569c0,.7.139.835.854.837.662,0,1.323,0,1.985,0,1.663,0,3.325,0,4.987,0,.58,0,.742-.174.746-.753,0-.452,0-.9,0-1.356,0-.646-.115-.764-.762-.766"
            transform="translate(-27.092 -22.237)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
