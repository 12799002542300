export default function AllFolderModel() {
  const fetchAllFolderListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Folder_list`,
    method: 'POST',
    payload: {
      root_tableId: null,
    },
  };
  const createAllFolderListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Create_Folder`,
    method: 'POST',
    payload: {
      group_type: null,
      name: '',
      Root_TableId: null,
    },
  };
  const deleteAllFolderListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Delete`,
    method: 'POST',
    payload: {
      ref_type: 1,
      referenceId: null,
    },
  };

  const renameAllFolderListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Rename`,
    method: 'POST',
    payload: {
      ref_type: null,
      reference: null,
      name: '',
    },
  };

  const fetchSegmentListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/list`,
    method: 'POST',
    payload: {
      ref_type: null,
      reference: null,
    },
  };

  const createSegmentModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Apply_With_Condition`,
    method: 'POST',
    payload: {
      Apply_SegmentId: null,
      root_TableId: null,
      name: '',
      description: null,
      set_Op: 1,
      set_Logical_Equation: null,
      RuleSets: [],
      listGroupBy: [
        {
          ColumnId: null,
          TableId: null,
        },
      ],
    },
  };

  return {
    fetchAllFolderListModel,
    createAllFolderListModel,
    deleteAllFolderListModel,
    renameAllFolderListModel,
    fetchSegmentListModel,
    createSegmentModel,
  };
}
