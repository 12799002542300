<template>
  <svg id="Group_53767" data-name="Group 53767" xmlns="http://www.w3.org/2000/svg" width="21.429" height="21.431" viewBox="0 0 21.429 21.431">
    <path
      id="Path_22760"
      data-name="Path 22760"
      d="M6.457,0H3.229A3.229,3.229,0,0,0,0,3.229V6.457A3.229,3.229,0,0,0,3.229,9.686H6.457A3.229,3.229,0,0,0,9.686,6.457V3.229A3.229,3.229,0,0,0,6.457,0M7.672,6.457A1.216,1.216,0,0,1,6.457,7.672H3.229A1.216,1.216,0,0,1,2.014,6.457V3.227A1.216,1.216,0,0,1,3.229,2.013H6.457A1.216,1.216,0,0,1,7.672,3.227Z"
    />
    <path
      id="Path_22761"
      data-name="Path 22761"
      d="M17.364,0H14.136a3.229,3.229,0,0,0-3.229,3.229V6.457a3.229,3.229,0,0,0,3.229,3.229h3.229a3.229,3.229,0,0,0,3.229-3.229V3.229A3.229,3.229,0,0,0,17.364,0M18.58,6.457a1.217,1.217,0,0,1-1.216,1.215H14.136a1.216,1.216,0,0,1-1.215-1.215V3.227a1.216,1.216,0,0,1,1.215-1.215h3.229A1.217,1.217,0,0,1,18.58,3.227Z"
      transform="translate(0.836)"
    />
    <path
      id="Path_22762"
      data-name="Path 22762"
      d="M6.457,10.907H3.229A3.229,3.229,0,0,0,0,14.136v3.229a3.229,3.229,0,0,0,3.229,3.229H6.457a3.229,3.229,0,0,0,3.229-3.229V14.136a3.229,3.229,0,0,0-3.229-3.229m1.215,6.457a1.216,1.216,0,0,1-1.215,1.215H3.229a1.216,1.216,0,0,1-1.215-1.215V14.136A1.217,1.217,0,0,1,3.229,12.92H6.457a1.217,1.217,0,0,1,1.215,1.216Z"
      transform="translate(0 0.838)"
    />
    <path
      id="Path_22763"
      data-name="Path 22763"
      d="M17.364,10.907H14.136a3.229,3.229,0,0,0-3.229,3.229v3.229a3.229,3.229,0,0,0,3.229,3.229h3.229a3.229,3.229,0,0,0,3.229-3.229V14.136a3.229,3.229,0,0,0-3.229-3.229m1.216,6.457a1.217,1.217,0,0,1-1.216,1.215H14.136a1.216,1.216,0,0,1-1.215-1.215V14.136a1.217,1.217,0,0,1,1.215-1.216h3.229a1.218,1.218,0,0,1,1.216,1.216Z"
      transform="translate(0.836 0.838)"
    />
  </svg>
</template>
