<template>
  <svg
    id="Group_33787"
    data-name="Group 33787"
    xmlns="http://www.w3.org/2000/svg"
    width="12.358"
    height="15.542"
    viewBox="0 0 12.358 15.542"
  >
    <path
      id="Path_22585"
      data-name="Path 22585"
      d="M24.114,463.713a.494.494,0,0,1-.3-.1l-.628-.485-3.219-2.494-.722-.56-.629-.485a.494.494,0,0,1-.086-.692l.07-.091a.492.492,0,0,1,.692-.085l.441.341a.118.118,0,0,0,.165-.021l.85-1.1a2.607,2.607,0,0,1,2.894-.873.115.115,0,0,0,.038.006.118.118,0,0,0,.093-.046l1.383-1.782a.643.643,0,0,1,.9-.112l.107.084.023.015a.644.644,0,0,1,.111.9l-1.358,1.753a.118.118,0,0,0,0,.141,2.589,2.589,0,0,1-.063,3.109l-.85,1.1a.118.118,0,0,0,.021.166l.441.341a.492.492,0,0,1,.086.692l-.07.091a.492.492,0,0,1-.389.19m-1.3-5.64a1.454,1.454,0,0,0-1.157.567l-.85,1.1a.118.118,0,0,0,.021.166l2.126,1.648a.118.118,0,0,0,.165-.021l.85-1.1a1.465,1.465,0,0,0-.26-2.053,1.449,1.449,0,0,0-.895-.307"
      transform="translate(-14.077 -454.996)"
    />
    <path
      id="Path_22586"
      data-name="Path 22586"
      d="M.767,492.537a.638.638,0,0,1-.4-.136l-.124-.1a.645.645,0,0,1-.111-.9l1.38-1.781a.119.119,0,0,0,.006-.137,2.609,2.609,0,0,1,.122-3.02l.848-1.1a.118.118,0,0,0-.021-.165l-.448-.346a.492.492,0,0,1-.079-.687l.071-.092a.493.493,0,0,1,.692-.084l.628.484.671.521a.119.119,0,0,0,.072.025.117.117,0,0,0,.093-.045l.852-1.092a.251.251,0,0,1,.353-.043l.469.366a.251.251,0,0,1,.043.352l-.849,1.088a.117.117,0,0,0,.021.166l.43.333a.118.118,0,0,0,.165-.021l.848-1.086A.251.251,0,0,1,6.855,485l.469.366a.252.252,0,0,1,.043.353L6.522,486.8a.117.117,0,0,0-.024.087.119.119,0,0,0,.045.079l.729.565.631.486a.494.494,0,0,1,.086.693l-.071.091a.493.493,0,0,1-.692.085l-.442-.341a.118.118,0,0,0-.165.021l-.85,1.1a2.607,2.607,0,0,1-2.994.838.113.113,0,0,0-.042-.008.118.118,0,0,0-.093.046L1.274,492.29a.64.64,0,0,1-.507.248m2.711-6.515a.119.119,0,0,0-.078.045l-.85,1.1a1.463,1.463,0,0,0,2.313,1.792l.85-1.1a.118.118,0,0,0-.021-.166l-2.126-1.648a.119.119,0,0,0-.072-.025H3.478"
      transform="translate(0 -476.996)"
    />
  </svg>
</template>
