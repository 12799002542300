import { ref, Ref, getCurrentInstance } from 'vue';

interface Props {
  items: SettingChartModal.ItemProperty;
}

export default function useItemProperties(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const itemsModified: Ref<SettingChartModal.ItemProperty> = ref(props.items);
  const currentItem: Ref<SettingChartModal.ItemProperty | null> = ref(null);

  function onClickColor(colorHex: string) {
    itemsModified.value.color = colorHex;
    vm?.$emit('on-items-changed', itemsModified.value);
  }

  const onTogglePopup = (isOpen: boolean, item: SettingChartModal.ItemProperty) => {
    if (isOpen && item) {
      currentItem.value = item;
    } else {
      currentItem.value = null;
    }
    return currentItem;
  };

  return { itemsModified, onClickColor, onTogglePopup, currentItem };
}
