<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15.257" height="14.588" viewBox="0 0 15.257 14.588">
    <path
      id="Path_24364"
      data-name="Path 24364"
      d="M8.346.446l1.737,3.52a.8.8,0,0,0,.6.438l3.885.564a.8.8,0,0,1,.444,1.365L12.2,9.074a.8.8,0,0,0-.23.709l.663,3.869a.8.8,0,0,1-1.161.844L8,12.668a.8.8,0,0,0-.745,0L3.782,14.5a.8.8,0,0,1-1.161-.844l.663-3.869a.8.8,0,0,0-.23-.709L.243,6.334A.8.8,0,0,1,.686,4.969L4.571,4.4a.8.8,0,0,0,.6-.438L6.911.446a.8.8,0,0,1,1.435,0"
      transform="translate(0 0)"
      fill="#ffce00"
    />
  </svg>
</template>
