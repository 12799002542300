export default function datasourceModel() {
  const fetchSegmentExportReport: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/export`,
    method: 'POST',
    payload: {
      SegmentId: null,
      Select_flag: null,
      Search_Value: null,
      Page: null,
      Limit: null,
      Filters: [],
      ResultType: null,
      View_Select_Main: null,
    },
    responseType: 'blob',
  };

  return { fetchSegmentExportReport };
}
