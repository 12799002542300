<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="currentColor">
    <path
      d="M7.65461 13.653C6.3116 13.6509 4.99929 13.2512 3.88318 12.5042C2.76706 11.7572 1.89712 10.6965 1.38307 9.45577C0.869023 8.21504 0.733884 6.84987 0.994699 5.53243C1.25551 4.21499 1.9006 3.00428 2.84861 2.05299C3.84782 1.05306 5.13344 0.388851 6.52716 0.152504C7.92087 -0.0838435 9.3536 0.119383 10.6266 0.733995C10.7549 0.798537 10.8648 0.894502 10.946 1.01295C11.0272 1.1314 11.0772 1.26848 11.0911 1.41142C11.1051 1.55436 11.0827 1.69852 11.0259 1.83046C10.9692 1.96239 10.88 2.07782 10.7666 2.16599C10.6441 2.26321 10.4968 2.32412 10.3414 2.34179C10.186 2.35947 10.0288 2.33321 9.88761 2.26599C8.8336 1.75499 7.63546 1.6237 6.4958 1.89432C5.35614 2.16494 4.34493 2.82087 3.63317 3.75116C2.92141 4.68146 2.55279 5.82903 2.58966 6.99979C2.62653 8.17056 3.06662 9.29266 3.8355 10.1763C4.60438 11.06 5.65486 11.651 6.80929 11.8494C7.96371 12.0478 9.15122 11.8413 10.171 11.265C11.1908 10.6887 11.9802 9.77791 12.4058 8.68662C12.8314 7.59532 12.867 6.39053 12.5066 5.27599C12.4543 5.11221 12.4536 4.93633 12.5046 4.77214C12.5556 4.60794 12.6558 4.46338 12.7916 4.35799C12.8995 4.27363 13.0262 4.21654 13.1609 4.19155C13.2956 4.16657 13.4343 4.17443 13.5653 4.21446C13.6963 4.2545 13.8157 4.32554 13.9135 4.42155C14.0112 4.51756 14.0843 4.63571 14.1266 4.76599C14.4556 5.78612 14.5387 6.86956 14.3693 7.92794C14.1999 8.98632 13.7826 9.98965 13.1516 10.8561C12.5206 11.7225 11.6938 12.4275 10.7385 12.9136C9.78314 13.3997 8.72646 13.653 7.65461 13.653Z"
      fill="currentColor"
    />
    <path
      d="M10.0938 8.50761H7.23281C7.00738 8.50761 6.79118 8.41806 6.63177 8.25865C6.47237 8.09924 6.38281 7.88304 6.38281 7.65761V3.57461C6.38281 3.34918 6.47237 3.13297 6.63177 2.97357C6.79118 2.81416 7.00738 2.72461 7.23281 2.72461C7.45825 2.72461 7.67445 2.81416 7.83385 2.97357C7.99326 3.13297 8.08281 3.34918 8.08281 3.57461V6.81261H10.0948C10.3202 6.81261 10.5364 6.90216 10.6959 7.06157C10.8553 7.22097 10.9448 7.43718 10.9448 7.66261C10.9448 7.88804 10.8553 8.10424 10.6959 8.26365C10.5364 8.42306 10.3202 8.51261 10.0948 8.51261"
      fill="currentColor"
    />
  </svg>
</template>
