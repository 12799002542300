<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="106.735" height="106.734" viewBox="0 0 106.735 106.734">
    <g>
      <path
        class="st4"
        d="M55,5C27.39,5,5,27.39,5,55c0,27.61,22.39,50,50,50c27.61,0,50-22.39,50-50C105,27.39,82.61,5,55,5z
		 M55,100.74C29.78,100.74,9.26,80.22,9.26,55S29.78,9.26,55,9.26S100.74,29.78,100.74,55S80.22,100.74,55,100.74z"
      />
      <g>
        <path
          class="st4"
          d="M56.74,67.38h-3.22c-0.68,0-1.23-0.54-1.26-1.21l-1.19-32.52c-0.03-0.71,0.55-1.31,1.26-1.31h5.34
			c0.71,0,1.28,0.59,1.26,1.3L58,66.16C57.98,66.84,57.42,67.38,56.74,67.38z"
        />
        <path
          class="st4"
          d="M55.12,71.42c0.45,0,0.88,0.09,1.28,0.26c0.4,0.17,0.74,0.4,1.03,0.69c0.29,0.29,0.52,0.63,0.69,1.03
			c0.17,0.4,0.26,0.82,0.26,1.28s-0.09,0.88-0.26,1.28c-0.17,0.4-0.4,0.75-0.69,1.05c-0.29,0.3-0.63,0.54-1.03,0.71
			c-0.4,0.17-0.82,0.26-1.28,0.26c-0.45,0-0.88-0.09-1.27-0.26c-0.4-0.17-0.75-0.41-1.05-0.71c-0.3-0.3-0.54-0.65-0.71-1.05
			c-0.17-0.4-0.26-0.82-0.26-1.28s0.09-0.88,0.26-1.28c0.17-0.4,0.4-0.74,0.69-1.03c0.29-0.29,0.64-0.52,1.05-0.69
			C54.23,71.51,54.67,71.42,55.12,71.42z"
        />
      </g>
    </g>
  </svg>
</template>
