<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    xml:space="preserve"
  >
    <g id="Group_33346" transform="translate(0)">
      <g id="Group_33336" transform="translate(11.959 16)">
        <g>
          <g id="Group_112">
            <path
              id="Path_51"
              style="fill: #212529"
              d="M69.24,8.94H6.84c-2.97,0.05-5.34,2.5-5.31,5.47V53.6
					c-0.03,2.97,2.34,5.41,5.31,5.46h62.4c2.97-0.05,5.34-2.5,5.31-5.47V14.41C74.58,11.44,72.21,8.99,69.24,8.94 M6.84,53.6V14.8
					l23.5,23.1l-3.71-3.65L6.96,53.6H6.84z M38.26,36.88L15.41,14.41h45.7L38.26,36.88z M31.11,38.66l3.47,3.41
					c2.04,2.04,5.34,2.04,7.38,0l3.47-3.41L60.61,53.6H15.92L31.11,38.66z M49.9,34.25l19.34-19.01v38.02L49.9,34.25z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<style lang="scss" scoped>
@import '@assets/styles/base/button.scss';
</style>
