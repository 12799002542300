<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14.649" height="16.219" viewBox="0 0 14.649 16.219">
    <g id="Group_34626" data-name="Group 34626" transform="translate(0 0)">
      <g id="Group_640" data-name="Group 640" transform="matrix(0.755, 0.656, -0.656, 0.755, 10.234, 0)">
        <path
          id="Union_33"
          data-name="Union 33"
          d="M0,10.261H3.945L1.973,12.629Zm0,0V0H3.945V10.261Z"
          transform="translate(0 2.969)"
        />
        <g id="Group_444" data-name="Group 444" transform="translate(0 0)">
          <rect
            id="Rectangle_280"
            data-name="Rectangle 280"
            width="3.945"
            height="1.98"
            rx="0.99"
            transform="translate(0 0)"
          />
          <rect
            id="Rectangle_281"
            data-name="Rectangle 281"
            width="3.945"
            height="0.99"
            transform="translate(0 0.99)"
          />
        </g>
      </g>
      <rect
        id="Rectangle_440"
        data-name="Rectangle 440"
        width="14.649"
        height="1.628"
        rx="0.814"
        transform="translate(0 14.592)"
      />
      <g id="Group_33574" data-name="Group 33574" transform="translate(0 0)">
        <g id="Group_640-2" data-name="Group 640" transform="matrix(0.755, 0.656, -0.656, 0.755, 10.234, 0)">
          <path
            id="Union_33-2"
            data-name="Union 33"
            d="M0,10.261H3.945L1.973,12.629Zm0,0V0H3.945V10.261Z"
            transform="translate(0 2.969)"
          />
          <g id="Group_444-2" data-name="Group 444" transform="translate(0 0)">
            <rect
              id="Rectangle_280-2"
              data-name="Rectangle 280"
              width="3.945"
              height="1.98"
              rx="0.99"
              transform="translate(0)"
            />
            <rect
              id="Rectangle_281-2"
              data-name="Rectangle 281"
              width="3.945"
              height="0.99"
              transform="translate(0 0.99)"
            />
          </g>
        </g>
        <rect
          id="Rectangle_440-2"
          data-name="Rectangle 440"
          width="14.649"
          height="1.628"
          rx="0.814"
          transform="translate(0 14.592)"
        />
      </g>
    </g>
  </svg>
</template>
