<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="149.238" height="122.003" viewBox="0 0 149.238 122.003">
    <g id="Group_53786" data-name="Group 53786" transform="translate(-260.881 -420.218)">
      <g id="Group_45157" data-name="Group 45157" transform="translate(266.025 425.2)">
        <g id="Group_45155" data-name="Group 45155" transform="translate(27.235 44.785)">
          <circle id="Ellipse_1007" data-name="Ellipse 1007" cx="7.697" cy="7.697" r="7.697" transform="translate(0 0)" fill="#3a83f6" />
          <circle id="Ellipse_1008" data-name="Ellipse 1008" cx="7.697" cy="7.697" r="7.697" transform="translate(22.494 0)" fill="#3a83f6" />
          <circle id="Ellipse_1009" data-name="Ellipse 1009" cx="7.697" cy="7.697" r="7.697" transform="translate(44.988 0)" fill="#3a83f6" />
        </g>
        <g id="Group_45156" data-name="Group 45156" transform="translate(36.678 16.319)">
          <path
            id="Path_28701"
            data-name="Path 28701"
            d="M3106.174,62.682l-8.793-15.093a52.528,52.528,0,0,0-26.622-70.839A52.481,52.481,0,0,1,3032.726,65.4a52.3,52.3,0,0,1-20.752-4.267,52.478,52.478,0,0,0,74.918,1.179l18.051,2.24A1.247,1.247,0,0,0,3106.174,62.682Z"
            transform="translate(-3011.974 23.25)"
            fill="#3a83f6"
          />
        </g>
        <path
          id="Path_28702"
          data-name="Path 28702"
          d="M2865.081-114.76c-12.768-13.424-32.567-20.1-53.494-14.11a50.724,50.724,0,0,0-34.74,34.563,53.377,53.377,0,0,0,2.831,38.329l-10.06,17.268,20.549-2.55A52.571,52.571,0,0,0,2806.3-30.382a52.3,52.3,0,0,0,20.753,4.267A52.482,52.482,0,0,0,2879.531-78.6,52.3,52.3,0,0,0,2865.081-114.76Zm-7.9,66.3A42.316,42.316,0,0,1,2828.36-36c-.436.013-.874.022-1.312.022a42.36,42.36,0,0,1-29.948-12.3l-3.4-3.361-5.528.686,2.662-4.569-2.254-4.712a43.408,43.408,0,0,1-2.257-31.316,40.863,40.863,0,0,1,10.4-17.513,40.971,40.971,0,0,1,17.577-10.319,46.392,46.392,0,0,1,12.749-1.828,42.336,42.336,0,0,1,30.133,12.482,43.009,43.009,0,0,1,5.5,6.74,42.318,42.318,0,0,1,6.981,23.393A42.338,42.338,0,0,1,2857.182-48.463Z"
          transform="translate(-2769.618 131.079)"
          fill="#16294b"
        />
      </g>
      <g id="Group_45160" data-name="Group 45160" transform="translate(379.03 420.218)">
        <g id="Group_45161" data-name="Group 45161">
          <rect
            id="Rectangle_17076"
            data-name="Rectangle 17076"
            width="13.709"
            height="2.142"
            transform="translate(7.925) rotate(90)"
            fill="#3a83f6"
          />
          <rect
            id="Rectangle_17077"
            data-name="Rectangle 17077"
            width="13.709"
            height="2.142"
            transform="translate(13.709 7.925) rotate(180)"
            fill="#3a83f6"
          />
        </g>
      </g>
      <g id="Group_45162" data-name="Group 45162" transform="translate(276.876 525.09) rotate(45)">
        <g id="Group_45161-2" data-name="Group 45161">
          <rect
            id="Rectangle_17076-2"
            data-name="Rectangle 17076"
            width="13.709"
            height="2.142"
            transform="translate(7.925) rotate(90)"
            fill="#3a83f6"
          />
          <rect
            id="Rectangle_17077-2"
            data-name="Rectangle 17077"
            width="13.709"
            height="2.142"
            transform="translate(13.709 7.925) rotate(180)"
            fill="#3a83f6"
          />
        </g>
      </g>
      <circle id="Ellipse_1010" data-name="Ellipse 1010" cx="3.244" cy="3.244" r="3.244" transform="translate(260.881 439.401)" fill="#16294b" />
      <circle id="Ellipse_1011" data-name="Ellipse 1011" cx="5.191" cy="5.191" r="5.191" transform="translate(399.737 500.394)" fill="#16294b" />
      <circle id="Ellipse_1012" data-name="Ellipse 1012" cx="1.947" cy="1.947" r="1.947" transform="translate(406.226 493.905)" fill="#16294b" />
    </g>
  </svg>
</template>
