export const RENDER_TYPE = (type: number) => {
  switch (type) {
    case 1:
      return 'entry-node';
    case 2:
      return 'flow-node';
    case 3:
      return 'action-node';
  }
  return '';
};
