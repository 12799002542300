import { ref, Ref, reactive, onMounted, getCurrentInstance, watch, onBeforeMount, nextTick } from 'vue';
import { addScanUI } from '../../../../constants/constant-edm';
import { PATH } from '@/constants/modules/file-service/path';
import apiService from '@/services/api';
import fileServiceModel from '@/models/file-service/file-service';
import useLoading from '@/views/components/loading/hooks/useLoading';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import JsBarcode from 'jsbarcode';

interface Props {
  selectedElement: Record<string, any>;
  backupElement: Communication.EdmEditor.CoreEdmElement;
  selectedCoupon: Campaign.CouponSet;
  listCouponColumn: Campaign.CouponColumn;
  templateMode?: string;
}

export default function useScanTabPanel(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const { uploadFileModel } = fileServiceModel();
  const { openErrorModal, openDefaultErrorModal } = useValidationModal();
  const { isLoading } = useLoading();
  //custom image
  let imgWidthOriginal = 0;
  let imgHeightOriginal = 0;
  let oldWidth = 0;
  let oldBarcodeBgColor = '';
  let tableDataLength = 0;
  const imgWidth: Ref<number> = ref(0);
  const imgHeight: Ref<number> = ref(0);
  const disabledPadding: Ref<boolean> = ref(true);
  const spacingTop: Ref<number> = ref(0);
  const spacingBottom: Ref<number> = ref(0);
  const indentLeft: Ref<number> = ref(0);
  const indentRight: Ref<number> = ref(0);
  const alignMent: Ref<string> = ref('center');
  const justifyMent: Ref<string> = ref('middle');

  const isChangedRadiusMode: Ref<boolean> = ref(false);
  const borderRadius: Record<string, number> = reactive({
    all: 0,
    topLeft: 0,
    bottomLeft: 0,
    topRight: 0,
    bottomRight: 0,
  });
  const borderType: Ref<string> = ref('solid');
  const borderPx: Ref<string> = ref('0px');
  const isBarcodeColorPickerOpen: Ref<boolean> = ref(false);
  const isBarcodeBgColorPickerOpen: Ref<boolean> = ref(false);
  const selectedCouponType: Ref<string> = ref('barcode');
  const selectedCouponDetail: Ref<Campaign.CouponSet> = ref(null!);
  const couponColumnDetail: Ref<Campaign.CouponColumn> = ref(null!);
  const canvasEle: Ref<HTMLCanvasElement> = ref(null!);
  const barcodeSrc: Ref<string> = ref('');
  const barcodeLineHeight: Ref<number> = ref(0);
  const barcodeDisplayValue: Ref<boolean> = ref(true);
  const barcodeStyle: Ref<number> = ref(2);
  const barcodeColor: Ref<string> = ref('#000000');
  const barcodeBgColor: Ref<string> = ref('#ffffff');

  const buildScannerObjProperties = () => {
    if (!selectedCouponDetail.value && !couponColumnDetail.value) {
      // Case no image selected / upload yet
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Content.Type = 'ScannerBlank';
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Content.Properties = {};
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Style = {};
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.HTMLUI = addScanUI;
    } else {
      //optional prop
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.HTMLUI = '';

      //td prop
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Style = {
        'vertical-align': justifyMent.value,
        'text-align': alignMent.value,
        'padding-top': `${spacingTop.value}px`,
        'padding-bottom': `${spacingBottom.value}px`,
        'padding-left': `${indentLeft.value}px`,
        'padding-right': `${indentRight.value}px`,
      };

      //blockdata prop & custom content type data
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Content.Type = 'Scanner';
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Content.Properties = {
        SummaryPadding: summaryPadding(),
        WidthMemory: imgWidth.value,
        HeightMemory: imgHeight.value,
        Width: Math.min(realColumnSize(), imgWidth.value),
        Url: barcodeSrc.value,
        ClassName: 'scan-coupon-code',
        Style: {
          'border-top-left-radius': isChangedRadiusMode.value === false ? borderRadius.all + 'px' : borderRadius.topLeft + 'px',
          'border-top-right-radius': isChangedRadiusMode.value === false ? borderRadius.all + 'px' : borderRadius.topRight + 'px',
          'border-bottom-left-radius': isChangedRadiusMode.value === false ? borderRadius.all + 'px' : borderRadius.bottomLeft + 'px',
          'border-bottom-right-radius': isChangedRadiusMode.value === false ? borderRadius.all + 'px' : borderRadius.bottomRight + 'px',
        },

        ScanData: {
          WidthOriginal: imgWidthOriginal,
          HeightOriginal: imgHeightOriginal,
          DisablePadding: disabledPadding.value,
          Padding_Top: spacingTop.value,
          Padding_Bottom: spacingBottom.value,
          Padding_Left: indentLeft.value,
          Padding_Right: indentRight.value,
          IsChangedRadiusMode: isChangedRadiusMode.value,
          BorderRadius_All: borderRadius.all,
          BorderRadius_TopLeft: borderRadius.topLeft,
          BorderRadius_BottomLeft: borderRadius.bottomLeft,
          BorderRadius_TopRight: borderRadius.topRight,
          BorderRadius_BottomRight: borderRadius.bottomRight,
          // BorderType: borderType.value,
          // BorderPx: borderPx.value,
          // BarcodeColor: barcodeColor.value,
          SelectedCouponDetail: selectedCouponDetail.value,
          CouponColumnDetail: couponColumnDetail.value,
          BarcodeLineHeight: barcodeLineHeight.value,
          BarcodeDisplayValue: barcodeDisplayValue.value,
          BarcodeColor: barcodeColor.value,
          BarcodeBgColor: barcodeBgColor.value,
          BarcodeStyle: barcodeStyle.value,
        },
      };
    }

    EDMRender('cdp-edm-editor');
    vm?.$emit('on-get-edm-config-json', EDMRenderSave('cdp-edm-editor'));
    vm?.$emit('on-get-edm-content', EDMRenderResult('cdp-edm-editor'));
    nextTick(() => {
      vm?.$emit('set-is-selected');
      vm?.$emit('handle-selected-element');
      vm?.$emit('on-added-coupon');
    });
  };

  const onChangeRadiusMode = () => {
    isChangedRadiusMode.value = !isChangedRadiusMode.value;
  };

  const handleDisablePadding = () => {
    disabledPadding.value = !disabledPadding.value;
    if (disabledPadding.value == true) {
      spacingTop.value = 0;
      spacingBottom.value = 0;
      indentLeft.value = 0;
      indentRight.value = 0;
    } else {
      spacingTop.value = 10;
      spacingBottom.value = 10;
      indentLeft.value = 10;
      indentRight.value = 10;
    }
  };

  const onSelectAlignMent = (align: string) => {
    alignMent.value = align;
  };

  const onSelectJustifyMent = (justify: string) => {
    justifyMent.value = justify;
  };

  const onSelectBarcodeStyle = (style: number) => {
    barcodeStyle.value = style;
    if (barcodeStyle.value !== 3) {
      borderRadius.all = 0;
      borderRadius.topLeft = 0;
      borderRadius.topRight = 0;
      borderRadius.bottomLeft = 0;
      borderRadius.bottomRight = 0;
    }
    if (barcodeStyle.value === 1) {
      barcodeBgColor.value = '#ffffff00';
    } else {
      if (oldBarcodeBgColor) {
        barcodeBgColor.value = oldBarcodeBgColor;
      } else {
        barcodeBgColor.value = '#ffffff';
      }
    }

    barcodeSrc.value = genScanObjImg() as string;
    buildScannerObjProperties();
  };

  const onSelectDisplayStyle = (isShowValue: boolean) => {
    barcodeDisplayValue.value = isShowValue;
    barcodeSrc.value = genScanObjImg() as string;
    buildScannerObjProperties();
  };

  const onToggleColorPicker = () => {
    isBarcodeColorPickerOpen.value = !isBarcodeColorPickerOpen.value;
  };

  const onToggleBgColorPicker = () => {
    isBarcodeBgColorPickerOpen.value = !isBarcodeBgColorPickerOpen.value;
  };

  const onChangeBarcodeColor = (color: string) => {
    barcodeColor.value = color;
    barcodeSrc.value = genScanObjImg() as string;
    buildScannerObjProperties();
  };

  const onChangeBarcodeBgColor = (color: string) => {
    barcodeBgColor.value = color;
    oldBarcodeBgColor = barcodeBgColor.value;
    barcodeSrc.value = genScanObjImg() as string;
    buildScannerObjProperties();
  };

  const columnSize = () => {
    return tableDataLength * props.backupElement.ColObj.Properties.Length;
  };

  const summaryPadding = () => {
    return indentLeft.value + indentRight.value;
  };

  const realColumnSize = () => {
    return columnSize() - summaryPadding();
  };

  const onImgWidthChanged = () => {
    const contentObj = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id];
    const contentElem = document.querySelector(`#${contentObj.Id as string}`);
    const imageElem = contentElem?.closest('table')?.querySelector('img');

    imageElem?.setAttribute('width', String(Math.min(realColumnSize(), imgWidth.value)));
  };

  const onImgHeightChanged = () => {
    if (barcodeLineHeight.value < 50) {
      barcodeLineHeight.value = 50;
    } else if (barcodeLineHeight.value > 150) {
      barcodeLineHeight.value = 150;
    }
    barcodeSrc.value = genScanObjImg() as string;
  };

  function onAdd_ChangeCoupon() {
    if (props.selectedCoupon) {
      selectedCouponDetail.value = props.selectedCoupon;
      couponColumnDetail.value = props.listCouponColumn;
      barcodeLineHeight.value = 100;
      barcodeSrc.value = genScanObjImg() as string;
      getImageSize(barcodeSrc.value);
    }
  }

  const getImageSize = (url: string) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      imgWidthOriginal = img.width;
      imgHeightOriginal = img.height;
      if (img.width > realColumnSize()) {
        imgWidth.value = realColumnSize();
      } else {
        imgWidth.value = img.width;
      }
      imgHeight.value = calImageHeight(imgWidth.value);
      oldWidth = imgWidth.value;
      buildScannerObjProperties();
    };
  };

  const calImageWidth = (h: number): number => {
    const w = Math.ceil((imgWidthOriginal / imgHeightOriginal) * h);
    return w;
  };

  const calImageHeight = (w: number): number => {
    const h = Math.ceil((imgHeightOriginal / imgWidthOriginal) * w);
    return h;
  };

  const checkInputPadding = () => {
    const min = 0;
    const max = 40;
    if (spacingTop.value < min) {
      spacingTop.value = min;
    }
    if (spacingBottom.value < min) {
      spacingBottom.value = min;
    }
    if (indentLeft.value > max) {
      indentLeft.value = max;
    } else if (indentLeft.value < min) {
      indentLeft.value = min;
    }
    if (indentRight.value > max) {
      indentRight.value = max;
    } else if (indentRight.value < min) {
      indentRight.value = min;
    }
  };

  function onDelCoupon() {
    if (selectedCouponDetail.value && couponColumnDetail.value) {
      selectedCouponDetail.value = null!;
      couponColumnDetail.value = null!;
      // barcodeLineHeight.value = 0;
      imgWidth.value = 0;
      imgHeight.value = 0;
    }
  }

  function onChangeCoupon() {
    if (selectedCouponDetail.value && couponColumnDetail.value) {
      vm?.$emit('on-select-coupon');
      vm?.$emit('on-select-coupon-from-scan');
      vm?.$emit('on-change-coupon', couponColumnDetail.value);
    }
  }

  function genScanObjImg() {
    if (selectedCouponType.value === 'barcode') {
      canvasEle.value = document.createElement('canvas');
      JsBarcode(canvasEle.value, 'Example 1234', {
        format: 'CODE128',
        width: 2,
        height: barcodeLineHeight.value,
        background: barcodeBgColor.value,
        displayValue: barcodeDisplayValue.value,
        lineColor: barcodeColor.value,
      });

      return canvasEle.value.toDataURL('image/png');
    } else if (selectedCouponType.value === 'qr') {
      ///to do later
    }
  }

  onMounted(() => {
    const currentNode = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id];
    tableDataLength = EDMGetEditorRunTime('cdp-edm-editor').Config.TableDataLength;
    if (currentNode.Properties.Content.Type == 'Scanner') {
      barcodeSrc.value = currentNode.Properties.Content.Properties.Url;
      imgWidth.value = currentNode.Properties.Content.Properties.WidthMemory;
      imgHeight.value = currentNode.Properties.Content.Properties.HeightMemory ?? 0;
      oldWidth = currentNode.Properties.Content.Properties.WidthMemory;
      imgWidthOriginal = currentNode.Properties.Content.Properties.ScanData.WidthOriginal ?? 0;
      imgHeightOriginal = currentNode.Properties.Content.Properties.ScanData.HeightOriginal ?? 0;
      selectedCouponDetail.value = currentNode.Properties.Content.Properties.ScanData.SelectedCouponDetail;
      couponColumnDetail.value = currentNode.Properties.Content.Properties.ScanData.CouponColumnDetail;
      barcodeLineHeight.value = currentNode.Properties.Content.Properties.ScanData.BarcodeLineHeight;
      barcodeDisplayValue.value = currentNode.Properties.Content.Properties.ScanData.BarcodeDisplayValue;
      barcodeColor.value = currentNode.Properties.Content.Properties.ScanData.BarcodeColor;
      barcodeBgColor.value = currentNode.Properties.Content.Properties.ScanData.BarcodeBgColor;
      barcodeStyle.value = currentNode.Properties.Content.Properties.ScanData.BarcodeStyle;
      if (barcodeBgColor.value !== '#ffffff00') {
        oldBarcodeBgColor = barcodeBgColor.value;
      }

      disabledPadding.value = currentNode.Properties.Content.Properties.ScanData.DisablePadding ?? false;
      spacingTop.value = currentNode.Properties.Content.Properties.ScanData.Padding_Top;
      spacingBottom.value = currentNode.Properties.Content.Properties.ScanData.Padding_Bottom;
      indentLeft.value = currentNode.Properties.Content.Properties.ScanData.Padding_Left;
      indentRight.value = currentNode.Properties.Content.Properties.ScanData.Padding_Right;

      justifyMent.value = currentNode.Properties.Style['vertical-align'];
      alignMent.value = currentNode.Properties.Style['text-align'];

      isChangedRadiusMode.value = currentNode.Properties.Content.Properties.ScanData.IsChangedRadiusMode ?? false;
      borderRadius.all = currentNode.Properties.Content.Properties.ScanData.BorderRadius_All ?? 0;
      borderRadius.topLeft = currentNode.Properties.Content.Properties.ScanData.BorderRadius_TopLeft ?? 0;
      borderRadius.bottomLeft = currentNode.Properties.Content.Properties.ScanData.BorderRadius_BottomLeft ?? 0;
      borderRadius.topRight = currentNode.Properties.Content.Properties.ScanData.BorderRadius_TopRight ?? 0;
      borderRadius.bottomRight = currentNode.Properties.Content.Properties.ScanData.BorderRadius_BottomRight ?? 0;
    }
  });

  watch(
    () => [props.selectedCoupon, props.listCouponColumn],
    () => {
      onAdd_ChangeCoupon();
    },
    { deep: true },
  );

  watch(
    [
      selectedCouponDetail,
      couponColumnDetail,
      isChangedRadiusMode,
      disabledPadding,
      spacingTop,
      spacingBottom,
      indentLeft,
      indentRight,
      alignMent,
      justifyMent,
      borderRadius,
      barcodeColor,
      barcodeBgColor,
      isBarcodeColorPickerOpen,
      isBarcodeBgColorPickerOpen,
    ],
    () => {
      buildScannerObjProperties();
    },
    { deep: true },
  );

  return {
    barcodeBgColor,
    barcodeColor,
    barcodeStyle,
    barcodeDisplayValue,
    barcodeLineHeight,
    couponColumnDetail,
    selectedCouponDetail,
    selectedCouponType,
    isChangedRadiusMode,
    disabledPadding,
    imgWidth,
    spacingTop,
    spacingBottom,
    indentLeft,
    indentRight,
    alignMent,
    justifyMent,
    borderRadius,
    isBarcodeColorPickerOpen,
    isBarcodeBgColorPickerOpen,
    onSelectBarcodeStyle,
    checkInputPadding,
    onChangeRadiusMode,
    handleDisablePadding,
    onSelectAlignMent,
    onSelectJustifyMent,
    onSelectDisplayStyle,
    onChangeBarcodeColor,
    onChangeBarcodeBgColor,
    onToggleColorPicker,
    onToggleBgColorPicker,
    onImgWidthChanged,
    onImgHeightChanged,
    onDelCoupon,
    onChangeCoupon,
    buildScannerObjProperties,
  };
}
