export default function DynamicTableModel() {
  const updateDisplayColumnModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Display/Update`,
    method: 'POST',
    payload: {
      TableId: 0,
      displayTables: [
        {
          ColumnId: 0,
          Seq: 0,
          Type: 0, //1 = Default, 2 = Personal
          IdentityId: 0,
          Column_Th: '',
          Column_En: '',
          Col_Display_Type: null!, //1 = Text, 2 = Image, 3 = Date, 4 = Option, 5 = Action
          Option: {},
          ImageHeight: 0,
          ImageWidth: 0,
          ImageSizeUnit: '',
          ImageShape: null!, // 1 = Square, 2 = Circle
          Font_Size: 0,
          Font_Size_Unit: '',
          Font_Color: '',
          Badge_Color: '',
          BG_Color: '',
          Date_Format: '',
          Date_EditType: null!, //1 = DateAndTime, 2 = Date, 3 = Time
          Action_Type: null!, //1 = Detail, 2 = Edit, 3 = Delete
          Action_Payload: '',
          Is_Display: false,
          Align: null!, //1 = Left, 2 = Center, 3 = Right
          Searchable: false,
          Sortable: false,
        },
      ],
    },
  };

  return { updateDisplayColumnModel };
}
