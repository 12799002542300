import { ref, Ref, reactive, toRaw, watch } from 'vue';
import router from '@/router';
import { RouteLocationRaw } from 'vue-router';
import api from '@services/api';
import segmentResultModel from '@models/segment/result';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useLoading from '@/views/components/loading/hooks/useLoading';

type SaveType = 1 | 2 | 3;
type SelectFlag = 1 | 2;

interface SegmentSavePayload {
  Save_Type: SaveType;
  Type: number;
  Name: string;
  SegmentId: number;
  Apply_SegmentId: number;
  Dest_SegmentId: number;
  Root_TableId: number;
  Root_FolderId: number;
  Dest_FolderId: number;
  saveColumns: AllDataSegment.SaveColumns[];
  select_flag: SelectFlag;
  Datas: AllDataSegment.Datas[];
}

interface Props {
  selectedSource: Segment.Datasource;
}

interface SegmentDialogList {
  folderSelect: {
    folderId: number;
    label: string;
  };
  segmentSelect: {
    segmentId: number;
    label: string;
  };
  viewData: number;
  segmentName: string;
}

interface SegmentPayloadSuccess {
  segment_id: number;
  segment_type: number;
  segment_type_desc: string;
  segment_size: number;
  is_displaycreate: boolean;
}

export default function useSaveSegmentCopyto(
  includeOrExcludeList: Ref<Record<string, any>[]>,
  dataSourceDisplay: Ref<PreviewSegment.DatasourceDisplay>,
  props: Props,
  isSelectedAll: Ref<boolean>,
  totalRecord: Ref<number>,
) {
  const { isLoading } = useLoading();
  const { openSuccessModal, openDefaultErrorModal } = useValidationModal();
  const { fetchSegmentSaveModel } = segmentResultModel();
  const dataDialogList: Ref<BaseModal.SaveSegmentModal> = ref({
    viewData: 0,
    segmentName: '',
    folderSelect: {
      folderId: 0,
      label: '',
    },
    segmentSelect: {
      segmentId: 0,
      label: '',
    },
  });
  const responseDetailSegment: SegmentPayloadSuccess = reactive({
    segment_id: 0,
    segment_type: 0,
    segment_type_desc: '',
    segment_size: 0,
    is_displaycreate: false,
  });
  const isLoadingActiveCopyTo: Ref<boolean> = ref(false);
  const nameDialogCopyTo: Ref<string> = ref('');
  const typeModalAction: Ref<string> = ref('');
  const dataFolder: Ref<Record<string, any>> = ref(router.currentRoute.value.query);

  const urlRouter: Ref<RouteLocationRaw> = ref({
    path: '/segment/data-segment/folder-inside',
    query: {
      tab: router.currentRoute.value.query.tab,
      folderId: router.currentRoute.value.query.folderId,
      folderName: router.currentRoute.value.query.folderName,
      tableId: router.currentRoute.value.query.tableId,
      subtab: router.currentRoute.value.query.subtab,
      page: router.currentRoute.value.query.page,
    },
  });

  const urlRouterCurrent: Ref<RouteLocationRaw> = ref({
    path: '/segment/data-segment/segment-detail',
    query: {
      ...router.currentRoute.value.query,
    },
  });

  const breadcrumbListCopyTo: Ref<BreadCrumbList.BreadCrumb[]> = ref([
    { key: 1, url: urlRouter.value, name: dataFolder.value.folderName },
    { key: 2, url: urlRouterCurrent.value, name: dataFolder.value.segmentName },
  ]);

  const onSubmitDialogSegment = (val: BaseModal.SaveSegmentModal) => {
    if (val.segmentSelect.segmentId != 0) {
      dataDialogList.value = val;
      nameDialogCopyTo.value = val.segmentSelect.label;
      onLoadDataSave();
    }
  };

  const onLoadDataSave = () => {
    const mapColumn = (dataSourceDisplay: PreviewSegment.DatasourceDisplay): PreviewSegment.SaveColumns[] => {
      const saveColumns: PreviewSegment.SaveColumns[] = [];
      dataSourceDisplay.columns.forEach((element) => {
        const obj: PreviewSegment.SaveColumns = {
          ColumnId: element.column_id,
          Ref_columnId: element.ref_columnid,
          Col_Type: element.col_type,
        };
        saveColumns.push(obj);
      });
      return saveColumns;
    };

    const mapData = (includeOrExcludeList: Record<string, any>[]): PreviewSegment.Datas[] => {
      const datas: PreviewSegment.Datas[] = [];
      includeOrExcludeList.forEach((element) => {
        const dataMap: PreviewSegment.Data_Map[] = [];
        sourceDisplay.columns.forEach((el: PreviewSegment.DatasourceDisplayColumns) => {
          const obj: PreviewSegment.Data_Map = {
            ColumnId: el.column_id,
            Value: element[el.key],
          };
          dataMap.push(obj);
        });
        datas.push({ Data_Map: dataMap });
      });
      return datas;
    };

    const dataDialogOrder: SegmentDialogList = toRaw(dataDialogList.value);
    const ListIncludeOrExclude: Record<string, any>[] = toRaw(includeOrExcludeList.value);
    const sourceDisplay: PreviewSegment.DatasourceDisplay = toRaw(dataSourceDisplay.value);

    const payload: SegmentSavePayload = {
      Save_Type: 3,
      Type: 3,
      Name: nameDialogCopyTo.value,
      SegmentId: null!,
      Apply_SegmentId: Number(router.currentRoute.value.query.segmentId),
      Dest_SegmentId: dataDialogOrder.segmentSelect.segmentId,
      Root_TableId: props.selectedSource.tableid,
      Root_FolderId: Number(router.currentRoute.value.query.folderId),
      Dest_FolderId: dataDialogOrder.folderSelect.folderId,
      saveColumns: mapColumn(sourceDisplay),
      select_flag: isSelectedAll.value ? 2 : 1,
      Datas: mapData(ListIncludeOrExclude),
    };
    onSaveDialogSelect(payload);
  };
  const onSaveDialogSelect = (payload: SegmentSavePayload) => {
    isLoading(true, 'กำลังบันทึก กรุณารอสักครู่...');
    isLoadingActiveCopyTo.value = true;
    fetchSegmentSaveModel.payload = payload;

    api
      .apiRequest(fetchSegmentSaveModel)
      .then((response) => {
        if (response.status === 'success') {
          responseDetailSegment.segment_id = response.data.segment_id;
          responseDetailSegment.segment_type = response.data.segment_type;
          openSuccessModal(
            'บันทึกรายการสำเร็จ',
            '',
            '',
            () => onDirectSegment(),
            () => onDirectSegment(),
          );
        }
      })
      .catch((error) => {
        if (error.data.error.message == 'Name of Segment is duplicate') {
          isLoadingActiveCopyTo.value = false;
        } else {
          openDefaultErrorModal(error);
        }
      })
      .finally(() => {
        isLoadingActiveCopyTo.value = false;
        isLoading(false);
      });
  };

  const refreshTableCopyTo: Ref<boolean> = ref(false);

  const onDirectSegment = () => {
    localStorage.setItem('listNewData', JSON.stringify(toRaw(includeOrExcludeList.value)));

    if (typeModalAction.value) {
      refreshTableCopyTo.value = true;
    } else {
      urlRouter.value = {
        path: '/segment/data-segment/folder-inside',
        query: {
          folderId: dataDialogList.value.folderSelect.folderId,
          folderName: dataDialogList.value.folderSelect.label,
          tab: router.currentRoute.value.query.tab,
          subtab: router.currentRoute.value.query.subtab,
          page: router.currentRoute.value.query.page,
        },
      };

      urlRouterCurrent.value = {
        path: '/segment/data-segment/folder-inside',
        query: {
          ...router.currentRoute.value.query,
          segmentId: responseDetailSegment.segment_id,
          segmentName: nameDialogCopyTo.value,
        },
      };

      breadcrumbListCopyTo.value = [
        { key: 1, url: urlRouter.value, name: dataDialogList.value.folderSelect.label },
        { key: 2, url: urlRouterCurrent.value, name: nameDialogCopyTo.value },
      ];

      router.push({
        path: '/segment/data-segment/segment-detail',
        query: {
          tab: router.currentRoute.value.query.tab,
          folderId: dataDialogList.value.folderSelect.folderId,
          folderName: dataDialogList.value.folderSelect.label,
          segmentId: responseDetailSegment.segment_id,
          segmentName: nameDialogCopyTo.value,
          type: responseDetailSegment.segment_type,
        },
      });
    }
  };

  watch(
    () => router.currentRoute.value.query.segmentName,
    (newSegmentName) => {
      if (newSegmentName) {
        if (dataDialogList.value.folderSelect.label) {
          urlRouterCurrent.value = {
            path: '/segment/data-segment/folder-inside',
            query: {
              ...router.currentRoute.value.query,
              segmentName: newSegmentName,
            },
          };

          breadcrumbListCopyTo.value = [
            { key: 1, url: urlRouter.value, name: dataDialogList.value.folderSelect.label },
            { key: 2, url: urlRouterCurrent.value, name: newSegmentName },
          ];
        }
      }
    },
  );

  return {
    breadcrumbListCopyTo,
    nameDialogCopyTo,
    isLoadingActiveCopyTo,
    onSubmitDialogSegment,
    refreshTableCopyTo,
  };
}
