import { getCurrentInstance, onMounted, reactive, ref, computed, nextTick, onUnmounted, onBeforeMount, watch } from 'vue';
// libs
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';
import { saveAs } from 'file-saver';
import router from '@/router';
import api from '@services/api';
// models
import customerInsightModel from '@models/customer-insight';
import { adjustDefaultLayout, deepClone, unMountedDefaultLayout } from '../utils';

import useValidationModal from '@views/components/modal/hooks/useValidationModal';

interface ItemType {
  DEFAULT: number;
  GENERIC: number;
}

export function useCustomerInsight() {
  // modals action
  const { openSuccessModal, openErrorModal, openWarningModal } = useValidationModal();

  const { t } = useI18n();
  const vm = getCurrentInstance()?.proxy;

  const ITEM_TYPE: ItemType = { DEFAULT: 1, GENERIC: 2 };

  const customerInsightCid = ref<number>(0);
  const currentPageId = ref<number>(0);
  const currentActivityTab = ref<INS.INSActivityTab.Item>();
  const filteredActivityKeys = ref<INS.INSActivityTab.Item>();
  //tab-list
  const tabList = ref<INS.INSTab.Item[]>([]);
  const activityTabList = ref<INS.INSActivityTab.Item[]>([]);

  const activityFilterList = ref<INS.INSActivityFilter.Item[]>([]);
  const PDPAHistoryDataList = ref<any>(null);

  // Params
  const paramsCid = ref<number>(0);
  const paramsPageId = ref<number>(0);

  // filters
  const DEFAULT_DATE_FILTER = 'P90D';
  const relativeModel = ref<string>(DEFAULT_DATE_FILTER);
  const startDate = ref<Date | null>(null);
  const endDate = ref<Date | null>(null);

  const sourceList = ref<INS.INSSource.SourceListOptions[]>([]);
  //this state trigger when date range filter changed
  const isMainFilterChanged = ref<boolean>(false);

  // Customer info
  const customerInfoPreview = ref<INS.CustomerInfoItem[]>([]);

  const {
    // Tabs
    fetchCustomerInsightTabsModel,
    fetchCustomerInsightTabsReorderModel,
    fetchCustomerInsightTabsCreateModel,
    fetchCustomerInsightTabsEditModel,
    fetchCustomerInsightTabsDeleteModel,
    // Chart
    fetchChartAddModel,
    // Filters
    fetchCustomerInsightChartGlobalFilterListModel,
    // Config
    fetchCustomerInsightConfigSave,
    fetchCustomerInsightConfigCancel,
    // Activity
    fetchCustomerInsightActivityFiltersModel,
    fetchCustomerInsightTransactionsExportModel,
    // Info
    fetchCustomerInsightCInfoListPreview,
    fetchCustomerInsightCInfoById,
    fetchCustomerInsightInfoPDPAHistory,
    fetchCustomerInsightInfoDuplicationPropertiesListAll,
  } = customerInsightModel();

  const tabItemProps = ref<INS.INSTab.Item>({
    seq: -1,
    pageId: -1,
    name: '',
    path: '',
    isDefault: false,
    configId: 0,
    isDraggable: false,
    isEditable: false,
    isDeletable: false,
    isTypeDefault: false,
  });
  //modal state
  const modalOpen = reactive({
    addChart: false,
    addBi: false,
    addTab: false,
    editTabItem: false,
    configProfile: false,
    pdpaHistory: false,
    editProfileProperty: false,
  });

  const loading = reactive({
    addChatModal: false,
    tabs: false,
    content: false,
    filter: false,
    config: false,
    activityFilter: false,
    exportXlsx: false,
    profile: false,
    pdpa: false,
  });

  const onErrorSetting = () => {
    openErrorModal(
      'เกิดข้อผิดพลาด',
      'มีผู้ Setting Dashboard ก่อนหน้านี้ โปรดลองใหม่อีกครั้ง',
      undefined,
      () => {
        router.push({ path: '/segment/data-segment/main' });
      },
      () => {
        router.push({ path: '/segment/data-segment/main' });
      },
    );
  };

  //fetch data api
  // tab list
  async function fetchCustomerInsightTabList(): Promise<void> {
    function mapBiTabList(input: INS.INSTab.Request.Response.Item[]): INS.INSTab.Item[] {
      return input.map((el: INS.INSTab.Request.Response.Item) => ({
        seq: el.seq,
        pageId: el.pageid,
        name: el.tab_name,
        path: el.tab_name,
        configId: el.configid,
        isDefault: el.seq === 1 || el.seq === 2,
        isDraggable: el.type === ITEM_TYPE.GENERIC,
        isEditable: el.type === ITEM_TYPE.GENERIC,
        isDeletable: el.type === ITEM_TYPE.GENERIC,
        isTypeDefault: el.type === ITEM_TYPE.DEFAULT,
      }));
    }
    loading.tabs = true;
    fetchCustomerInsightTabsModel.payload.CustomerView = paramsCid.value !== 0;

    return api
      .apiRequest(fetchCustomerInsightTabsModel)
      .then(async (response) => {
        tabList.value = [];
        await nextTick();
        tabList.value = mapBiTabList(response.data.payload.data_list as INS.INSTab.Request.Response.Item[]);
        tabList.value.sort((a, b) => a.seq - b.seq);
        return Promise.resolve();
      })
      .catch((error) => {
        onErrorSetting();
        return Promise.reject();
      })
      .finally(() => {
        loading.tabs = false;
      });
  }

  const onChangeTabs = async (value: number) => {
    currentPageId.value = Number(value);
    await router.push({ params: { pageId: currentPageId.value } });
    // Existing filters
    if (activityFilterList.value.length) return;
    // Fetch Activity tabs
    if (tabList.value) {
      const current = tabList.value.find((item) => item.pageId === currentPageId.value);
      if (current?.seq == 2) {
        await fetchActivityFilters();
      }
    }
  };

  const onChangeActivityTab = async (value: INS.INSActivityTab.Item) => {
    loading.activityFilter = true;
    currentActivityTab.value = value;
    filteredActivityKeys.value = value;
    await nextTick();
    loading.activityFilter = false;
  };

  const onChangeActivityFilter = (value: INS.INSActivityTab.Item) => {
    isMainFilterChanged.value = !isMainFilterChanged.value;
    filteredActivityKeys.value = value;
  };

  const onReordered = (val: { oldIndex: number; newIndex: number; oldItem: INS.INSTab.Item; newItem: INS.INSTab.Item }) => {
    if (val.oldIndex !== val.newIndex) {
      loading.tabs = true;
      fetchCustomerInsightTabsReorderModel.payload.PageId = val.oldItem.pageId;
      fetchCustomerInsightTabsReorderModel.payload.From_Seq = val.oldItem.seq;
      fetchCustomerInsightTabsReorderModel.payload.To_Seq = val.newItem.seq;
      api
        .apiRequest(fetchCustomerInsightTabsReorderModel)
        .then(async () => {
          await fetchCustomerInsightTabList();
        })
        .catch((error) => {
          onErrorSetting();
        })
        .finally(() => {
          loading.tabs = false;
        });
    } else {
      /** Reorder same position, do nothing... */
    }
  };

  // global filter
  async function fetchCustomerInsightGlobalFilter(): Promise<void> {
    function mapINSFilter(input: INS.INSSource.SourceListOptions[]): INS.INSSource.SourceListOptions[] {
      return input.map((el) => ({
        label: el.label,
        value: el.value,
      }));
    }

    loading.filter = true;

    return api
      .apiRequest(fetchCustomerInsightChartGlobalFilterListModel)
      .then((response) => {
        sourceList.value = mapINSFilter(response.data.result.global_source as INS.INSSource.SourceListOptions[]);
        relativeModel.value = response.data.result.global_datetime;
        return Promise.resolve();
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
        return Promise.reject();
      })
      .finally(() => {
        loading.filter = false;
      });
  }

  // Config api actions
  const fetchConfigSave = async (): Promise<void> => {
    return api
      .apiRequest(fetchCustomerInsightConfigSave)
      .then(() => Promise.resolve())
      .catch((error) => {
        Promise.reject(error);
      })
      .finally(() => {
        loading.config = false;
      });
  };

  const fetchConfigCancel = async (): Promise<void> => {
    return api
      .apiRequest(fetchCustomerInsightConfigCancel)
      .then(() => Promise.resolve())
      .catch((error) => {
        Promise.reject(error);
      })
      .finally(() => {
        loading.config = false;
      });
  };

  // chart actions
  const addNewChartFromSelection = (chartSetList: INS.INSChartSet.Item[]) => {
    loading.content = true;
    fetchChartAddModel.payload.PageId = currentPageId.value;
    fetchChartAddModel.payload.Charts = chartSetList.map((el) => ({
      ChartSetId: el.chartSetId,
      ChartId: el.chartId,
    }));
    api
      .apiRequest(fetchChartAddModel)
      .then(() => {
        openSuccessModal(t('bi.successful'));
      })
      .catch((error) => {
        onErrorSetting();
      })
      .finally(() => {
        loading.content = false;
        modalOpen.addChart = false;
      });
  };

  const onSubmitEditTabItem = (tabName: string) => {
    loading.tabs = true;
    const oldTabItemName = tabItemProps.value.name;
    fetchCustomerInsightTabsEditModel.payload.PageId = tabItemProps.value.pageId;
    fetchCustomerInsightTabsEditModel.payload.Tab_Name = tabName;
    api
      .apiRequest(fetchCustomerInsightTabsEditModel)
      .then(async (response) => {
        const editedTab = response.data.payload as INS.INSTab.Request.Response.Item;
        openSuccessModal(
          t('bi.successful'),
          `${t('bi.change_tab_name')} ${oldTabItemName} ${t('bi.tab_name_to')} ${editedTab.tab_name} ${t('bi.success')}`,
        );
        await fetchCustomerInsightTabList();
      })
      .catch((error) => {
        onErrorSetting();
      })
      .finally(() => {
        loading.tabs = false;
        modalOpen.editTabItem = false;
      });
  };

  // Config Action

  const onClickApply = async () => {
    loading.config = true;
    await fetchConfigSave();

    router.push({ path: '/segment/data-segment/main' });
  };

  const onClickCancel = async () => {
    loading.config = true;
    await fetchConfigCancel();
    router.push({ path: '/segment/data-segment/main' });
  };

  // Tabs Actions

  const onClickEditTab = (tabItem: INS.INSTab.Item) => {
    modalOpen.editTabItem = true;
    tabItemProps.value = tabItem;
  };

  const onCreateTab = (tabName: string) => {
    loading.tabs = false;
    fetchCustomerInsightTabsCreateModel.payload.Tab_Name = tabName;
    fetchCustomerInsightTabsCreateModel.payload.Tab_label = '';
    fetchCustomerInsightTabsCreateModel.payload.Tab_Icon = '';
    api
      .apiRequest(fetchCustomerInsightTabsCreateModel)
      .then(async (response) => {
        const createdTab = response.data.payload as INS.INSTab.Request.Response.Item;
        openSuccessModal(t('bi.successful'), `${t('bi.create_tab')} ${createdTab.tab_name} ${t('bi.success')}`);
        await fetchCustomerInsightTabList();
        currentPageId.value = createdTab.pageid;
        onChangeTabs(createdTab.pageid);
      })
      .catch((error) => {
        onErrorSetting();
        return Promise.reject(error);
      })
      .finally(() => {
        loading.tabs = false;
        onCloseAddTabModal();
      });
  };

  const onClickDeleteTabsItem = (tab: INS.INSTab.Item) => {
    loading.tabs = true;
    openWarningModal(
      t('bi.confirm_deletion'),
      `${t('bi.want_to_delete_tab')} ${tab.name} ${t('bi.yes_no')}`,
      () => {
        fetchCustomerInsightTabsDeleteModel.payload.PageId = tab.pageId;
        api
          .apiRequest(fetchCustomerInsightTabsDeleteModel)
          .then(async () => {
            await fetchCustomerInsightTabList();
            onChangeTabs(tabList.value[0].pageId);
          })
          .catch((error) => {
            onErrorSetting();
          })
          .finally(() => {
            loading.tabs = false;
          });
      },
      () => {},
      () => {},
    );
  };

  // Profile Panel Actions

  const onClickOpenConfigModal = async () => {
    loading.config = true;

    await fetchDuplicationPropertiesListAll();

    router.push('/customer-profile/manage-property');
    // modalOpen.configProfile = true;
    setTimeout(() => {
      loading.config = false;
    }, 1000);
  };

  function getParams() {
    paramsCid.value = Number(vm?.$route.params.cId) || 0;
    paramsPageId.value = Number(vm?.$route.params.pageId) || 0;
  }

  // Modal actions
  const onCloseAddBiModal = () => {
    modalOpen.addChart = false;
  };

  const onCloseAddTabModal = () => {
    modalOpen.addTab = false;
  };

  const onCloseEditTabItemModal = () => {
    modalOpen.editTabItem = false;
  };

  const onFilterChange = (filter: { startDate: Date; endDate: Date; relativeModel: string }) => {
    startDate.value = filter.startDate || null;
    endDate.value = filter.endDate || null;
    relativeModel.value = filter.relativeModel || '';
    isMainFilterChanged.value = !isMainFilterChanged.value;
  };

  const onExportCsv = async () => {
    await fetchActivityTransactionExportCsv();
  };

  const onExportPreview = () => {
    const tabName = tabList.value.find((el) => el.pageId === currentPageId.value);
    if (sourceList.value.length && relativeModel.value && startDate.value && endDate.value && tabName) {
      const sourceFilter = sourceList.value.map((el) => {
        return el.value;
      });
      const start = startDate.value ? new Date(startDate.value).getTime() : '';
      const end = endDate.value ? new Date(endDate.value).getTime() : '';
      window.open(
        `/customer-profile/export-preview?cid=${customerInsightCid.value}&layoutId=${currentPageId.value}&source=${JSON.stringify(
          sourceFilter,
        )}&startDate=${start}&endDate=${end}&relativeModel=${relativeModel.value}`,
        '_blank',
      );
    }
  };

  const fetchActivityFilters = async (): Promise<void> => {
    // Mapping function
    const mappingActivityTab = (filter: INS.INSActivityFilter.Item[]) => {
      const tmpTabs: Record<string, string[]> = {};
      if (filter.length) {
        filter.forEach((item) => {
          const groupDesc: string = item.filter_group_desc;
          const key: string = item.key;

          if (!tmpTabs[groupDesc]) {
            tmpTabs[groupDesc] = [];
          }

          tmpTabs[groupDesc].push(key);
        });
      }

      const filterGroupsArray: INS.INSActivityTab.Item[] = Object.keys(tmpTabs).map((groupDesc) => ({
        groupName: groupDesc,
        value: tmpTabs[groupDesc],
      }));

      // Insert a new object at the beginning of the array
      filterGroupsArray.unshift({
        groupName: 'Overall',
        value: filter.map((item) => item.key),
      });
      return filterGroupsArray;
    };

    loading.activityFilter = true;
    return api
      .apiRequest(fetchCustomerInsightActivityFiltersModel)
      .then((response) => {
        const data = response.data.result as INS.INSActivityFilter.Item[];
        const activeData = data.filter((item) => item.is_active);
        activityFilterList.value = activeData;
        activityTabList.value = mappingActivityTab(activeData);

        if (!activityTabList.value.length) return;
        currentActivityTab.value = activityTabList.value[0];
        filteredActivityKeys.value = activityTabList.value[0];
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
        return Promise.reject(error);
      })
      .finally(() => {
        loading.activityFilter = false;
      });
  };

  // export xlsx
  const fetchActivityTransactionExportCsv = async (): Promise<void> => {
    const mappingFilters = [
      {
        Key: 'startDate',
        Value: startDate.value ? DateTime.fromISO(startDate.value.toISOString()).toFormat('yyyy-MM-dd HH:mm') : '',
      },
      {
        Key: 'endDate',
        Value: endDate.value ? DateTime.fromISO(endDate.value.toISOString()).toFormat('yyyy-MM-dd HH:mm') : '',
      },
      {
        Key: 'source',
        Value: sourceList.value.map((el) => el.value),
      },
    ];

    fetchCustomerInsightTransactionsExportModel.payload.CID = customerInsightCid.value;
    fetchCustomerInsightTransactionsExportModel.payload.Filters = mappingFilters;
    fetchCustomerInsightTransactionsExportModel.payload.Activities_Key = filteredActivityKeys.value?.value || [];

    loading.exportXlsx = true;
    return api
      .apiRequest(fetchCustomerInsightTransactionsExportModel)
      .then((response) => {
        const datesTime = new Date();
        saveAs(
          response as Blob | string,
          `Report-${
            customerInsightCid.value
          }_${datesTime.getFullYear()}${datesTime.getMonth()}${datesTime.getDate()}_${datesTime.getHours()}${datesTime.getMinutes()}${datesTime.getSeconds()}.xlsx`,
        );

        Promise.resolve();
      })

      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
        return Promise.reject(error);
      })
      .finally(() => {
        loading.exportXlsx = false;
      });
  };

  // Customer Info Api
  // - InfoPreview
  async function fetchCustomerInfoPreview(): Promise<void> {
    loading.profile = true;

    return api
      .apiRequest(fetchCustomerInsightCInfoListPreview)
      .then((response) => {
        const data = response.data.result.result as INS.CustomerInfoItem[];
        customerInfoPreview.value = data;

        return Promise.resolve();
      })
      .catch((error) => {
        onErrorSetting();
        return Promise.reject();
      })
      .finally(() => {
        loading.profile = false;
      });
  }

  // - InfoById
  async function fetchCustomerInfoById(): Promise<void> {
    loading.profile = true;
    fetchCustomerInsightCInfoById.payload.CID = customerInsightCid.value;
    return api
      .apiRequest(fetchCustomerInsightCInfoById)
      .then((response) => {
        const data = response.data.result.result as INS.CustomerInfoItem[];
        customerInfoPreview.value = data;
        return Promise.resolve();
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
        return Promise.reject();
      })
      .finally(() => {
        loading.profile = false;
      });
  }

  const fetchPDPAHistory = async (): Promise<void> => {
    loading.pdpa = true;
    fetchCustomerInsightInfoPDPAHistory.payload.CID = customerInsightCid.value;
    return api
      .apiRequest(fetchCustomerInsightInfoPDPAHistory)
      .then((response) => {
        PDPAHistoryDataList.value = response.data.result;
        Promise.resolve();
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
        Promise.reject(error);
      })
      .finally(() => {
        loading.pdpa = false;
      });
  };

  const fetchDuplicationPropertiesListAll = async (): Promise<void> => {
    return api
      .apiRequest(fetchCustomerInsightInfoDuplicationPropertiesListAll)
      .then(() => Promise.resolve())
      .catch((error) => Promise.reject(error));
  };

  const onClickPDPAOpenModal = async () => {
    if (!PDPAHistoryDataList.value) {
      await fetchPDPAHistory();
    }

    modalOpen.pdpaHistory = true;
  };

  const applyProfileConfig = async () => {
    await fetchCustomerInfoPreview();
    modalOpen.configProfile = false;
  };

  const setDefaultDate = () => {
    endDate.value = new Date();
    startDate.value = new Date(new Date().setDate(endDate.value.getDate() - 90));

    // set default time
    endDate.value.setHours(0);
    endDate.value.setMinutes(0);
    endDate.value.setSeconds(0);
    startDate.value.setHours(0);
    startDate.value.setMinutes(0);
    startDate.value.setSeconds(0);
  };

  watch(
    () => {
      isMainFilterChanged.value;
    },
    () => {},
  );

  onBeforeMount(() => {
    setDefaultDate();
    console.log(setDefaultDate());
    console.log(startDate.value);
    console.log(endDate.value);
  });

  onMounted(async () => {
    // Custom Layout
    adjustDefaultLayout();
    // manage property page
    if (vm?.$route.name !== 'manage-property') {
      getParams();
      // Life cycle
      await fetchCustomerInsightGlobalFilter();
      await fetchCustomerInsightTabList();

      // Set Customer id
      if (paramsCid.value !== 0) customerInsightCid.value = paramsCid.value;
      // Set current page id
      if (paramsPageId.value !== 0 && tabList.value.length) {
        const found = tabList.value.find((x) => x.pageId === paramsPageId.value);
        if (found) {
          currentPageId.value = paramsPageId.value;
        } else {
          currentPageId.value = tabList.value[0].pageId;
        }
      } else if (paramsPageId.value == 0) {
        currentPageId.value = tabList.value[0].pageId;
        router.push({ params: { pageId: currentPageId.value } });
      }

      // Fetch Activity when on mounted teb seq == 2
      if (tabList.value) {
        const current = tabList.value.find((item) => item.pageId === currentPageId.value);
        if (current?.seq == 2) {
          await fetchActivityFilters();
        }
      }
    }
    // Fetch Customer Info
    if (customerInsightCid.value == 0 || vm?.$route.name == 'manage-property') {
      //test
      // return;
      await fetchCustomerInfoPreview();
    } else {
      await fetchCustomerInfoById();
    }
  });

  onUnmounted(() => {
    unMountedDefaultLayout();
  });

  return {
    tabList,
    currentPageId,
    loading,
    onChangeTabs,
    onReordered,
    relativeModel,
    startDate,
    endDate,
    onFilterChange,
    customerInsightCid,
    isMainFilterChanged,
    sourceList,
    modalOpen,
    onClickDeleteTabsItem,
    onClickEditTab,
    onCloseAddBiModal,
    onCloseAddTabModal,
    onCreateTab,
    tabItemProps,
    onCloseEditTabItemModal,
    onSubmitEditTabItem,
    deepClone,
    onClickOpenConfigModal,
    onClickCancel,
    onClickApply,
    addNewChartFromSelection,
    onExportPreview,
    onExportCsv,
    activityTabList,
    activityFilterList,
    currentActivityTab,
    filteredActivityKeys,
    onChangeActivityTab,
    onChangeActivityFilter,
    customerInfoPreview,
    applyProfileConfig,
    PDPAHistoryDataList,
    onClickPDPAOpenModal,
  };
}
