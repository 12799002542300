import { ref, Ref, toRaw, watch } from 'vue';
interface HighlightNew {
  cid: number;
  merge_cid: number;
  sourceid: number;
  dataid: number;
  first_name: string;
  last_name: string;
  mobileno: string;
  cdp_updated_dt: string;
  cdp_created_dt: string;
  cdp_isdelete: boolean;
  cdp_recordstate: number;
  homeno: string;
  faxno: string;
  gender: string;
  email: string;
  age: number;
  birthdate: string;
  nationality: string;
  religion: string;
  educational: string;
  occupation: string;
  maritalstatus: string;
  timestamp: string;
}
export default function useHighlightNewData(list: Ref<Record<string, any>[]>, uniqueKey: Ref<string>) {
  const includeOrExcludeNewData: Ref<Record<string, any>[]> = ref([]);

  const onCloseGetNewData = (includeOrExcludeNewList: HighlightNew[]) => {
    includeOrExcludeNewData.value = includeOrExcludeNewList.map((el: Record<string, any>) => toRaw(el));
    localStorage.removeItem('listNewData');
  };

  function highlightTable() {
    const tds = document.querySelectorAll('.form-check-input[type=checkbox]');
    tds.forEach((element: Element) => {
      includeOrExcludeNewData.value.forEach((o: Record<string, any>) => {
        setTimeout(() => {
          let eleArr1: string;
          if (o[uniqueKey.value] === parseInt((<HTMLInputElement>element).value)) {
            eleArr1 = element.classList[1];
            const selectTableRow = document.querySelectorAll(`.selected-${eleArr1}`);
            selectTableRow.forEach((el: Element) => {
              (<HTMLInputElement>el).style.backgroundColor = '#C7E6FF';
            });
          }
        });
      });
    });
    includeOrExcludeNewData.value = [];
  }

  watch(list, () => {
    setTimeout(() => {
      highlightTable();
    });
  });

  return { onCloseGetNewData };
}
