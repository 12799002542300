<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="60" height="60" viewBox="0 0 60 60">
    <defs>
      <clipPath id="clip-path-13">
        <rect id="Rectangle_51050" data-name="Rectangle 51050" width="60" height="60" transform="translate(-8.473 -14.515)" fill="none" />
      </clipPath>
      <filter id="Union_239-19">
        <feOffset dy="5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur" />
        <feFlood flood-color="#fff" result="color" />
        <feComposite operator="out" in="SourceGraphic" in2="blur" />
        <feComposite operator="in" in="color" />
        <feComposite operator="in" in2="SourceGraphic" />
      </filter>
      <filter id="Union_239-11" x="-15.298" y="-16.621" width="98.451" height="98.482" filterUnits="userSpaceOnUse">
        <feOffset dx="-5" dy="5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="12.5" result="blur-2" />
        <feFlood flood-opacity="0.102" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_239-12" x="-15.298" y="-16.621" width="98.451" height="98.482" filterUnits="userSpaceOnUse">
        <feOffset dy="5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="2.5" result="blur-3" />
        <feFlood flood-color="#3cbdfe" result="color-2" />
        <feComposite operator="out" in="SourceGraphic" in2="blur-3" />
        <feComposite operator="in" in="color-2" />
        <feComposite operator="in" in2="SourceGraphic" />
      </filter>
      <filter id="Union_239-13" x="-10.049" y="12.572" width="108.582" height="108.582" filterUnits="userSpaceOnUse">
        <feOffset dy="25" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="17.5" result="blur-4" />
        <feFlood flood-opacity="0.051" />
        <feComposite operator="in" in2="blur-4" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_239-14" x="-44.027" y="5.803" width="107.176" height="107.176" filterUnits="userSpaceOnUse">
        <feOffset dy="25" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="17.5" result="blur-5" />
        <feFlood flood-opacity="0.051" />
        <feComposite operator="in" in2="blur-5" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_239-15" x="-28.607" y="-12.985" width="109.774" height="109.774" filterUnits="userSpaceOnUse">
        <feOffset dy="25" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="17.5" result="blur-6" />
        <feFlood flood-opacity="0.051" />
        <feComposite operator="in" in2="blur-6" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Mask_Group_98838" data-name="Mask Group 98838" transform="translate(8.473 14.515)" clip-path="url(#clip-path-13)">
      <g id="Group_115637" data-name="Group 115637">
        <g id="Group_110110" data-name="Group 110110" transform="translate(6.885 1.365)">
          <g id="Group_110106" data-name="Group 110106" transform="translate(0 0)">
            <g id="Group_110104" data-name="Group 110104" transform="translate(0 8.187)">
              <g data-type="innerShadowGroup">
                <path
                  id="Union_239-16"
                  data-name="Union 239"
                  d="M.413.936C1.878,1.676,3.026,2.328,3.8,2.793A10.716,10.716,0,1,0,10.844.156h-.113C10.119.127,7.369,0,4.806,0,1.728,0-1.08.183.413.936Z"
                  transform="translate(21.561 21.589) rotate(180)"
                  fill="#fff"
                />
                <g transform="matrix(1, 0, 0, 1, -15.36, -24.07)" filter="url(#Union_239-19)">
                  <path
                    id="Union_239-17"
                    data-name="Union 239"
                    d="M.413.936C1.878,1.676,3.026,2.328,3.8,2.793A10.716,10.716,0,1,0,10.844.156h-.113C10.119.127,7.369,0,4.806,0,1.728,0-1.08.183.413.936Z"
                    transform="translate(36.92 45.66) rotate(180)"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_110105" data-name="Group 110105" transform="translate(11.845 0)">
              <g data-type="innerShadowGroup">
                <g transform="matrix(1, 0, 0, 1, -27.2, -15.88)" filter="url(#Union_239-11)">
                  <path
                    id="Union_239-18"
                    data-name="Union 239"
                    d="M.449,22.463c1.594-.8,2.843-1.514,3.688-2.02A11.656,11.656,0,1,1,11.8,23.312h-.123c-.666.032-3.657.17-6.445.17C1.879,23.482-1.175,23.283.449,22.463Z"
                    transform="translate(27.2 15.88)"
                    fill="#0d90fc"
                  />
                </g>
                <g transform="matrix(1, 0, 0, 1, -27.2, -15.88)" filter="url(#Union_239-12)">
                  <path
                    id="Union_239-19"
                    data-name="Union 239"
                    d="M.449,22.463c1.594-.8,2.843-1.514,3.688-2.02A11.656,11.656,0,1,1,11.8,23.312h-.123c-.666.032-3.657.17-6.445.17C1.879,23.482-1.175,23.283.449,22.463Z"
                    transform="translate(27.2 15.88)"
                    fill="#0d90fc"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="Group_115388" data-name="Group 115388" transform="translate(33.979 25.557)">
          <g transform="matrix(1, 0, 0, 1, -42.45, -40.07)" filter="url(#Union_239-13)">
            <path
              id="Union_239-11"
              data-name="Union 239"
              d="M1.791,0A1.791,1.791,0,1,1,0,1.791,1.791,1.791,0,0,1,1.791,0Z"
              transform="translate(46.03 43.65) rotate(180)"
              fill="#007fff"
            />
          </g>
        </g>
        <g id="Group_115389" data-name="Group 115389" transform="translate(2.176 18.789) rotate(90)">
          <g transform="matrix(0, -1, 1, 0, -33.3, 10.65)" filter="url(#Union_239-14)">
            <path
              id="Union_239-12"
              data-name="Union 239"
              d="M1.088,0A1.088,1.088,0,1,1,0,1.088,1.088,1.088,0,0,1,1.088,0Z"
              transform="translate(8.47 35.48) rotate(-90)"
              fill="#007fff"
            />
          </g>
        </g>
        <g id="Group_115390" data-name="Group 115390" transform="translate(15.42)">
          <g transform="matrix(1, 0, 0, 1, -23.89, -14.51)" filter="url(#Union_239-15)">
            <path
              id="Union_239-13"
              data-name="Union 239"
              d="M2.387,0A2.387,2.387,0,1,1,0,2.387,2.387,2.387,0,0,1,2.387,0Z"
              transform="translate(28.67 19.29) rotate(180)"
              fill="#007fff"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
