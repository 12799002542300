export default function () {
  const fetchCustomerInsightLayoutModel: API.RequestModel<INS.INSLayout.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSLayout/List`,
    method: 'GET',
    payload: {
      Page: 1,
      Limit: 1000,
      PageId: null!,
      CustomerView: false,
    },
  };

  const fetchCustomerInsightAdjustLayoutModel: API.RequestModel<INS.INSLayout.Adjust.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSLayout/Edit`,
    method: 'POST',
    payload: {
      PageId: null!,
      Layout: [],
    },
  };

  const fetchCustomerInsightDeleteLayoutModel: API.RequestModel<INS.INSLayout.Delete.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSLayout/Remove`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      CId: null!,
    },
  };

  return {
    fetchCustomerInsightLayoutModel,
    fetchCustomerInsightAdjustLayoutModel,
    fetchCustomerInsightDeleteLayoutModel,
  };
}
