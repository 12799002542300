// pdf
import { TDocumentDefinitions, Content } from 'pdfmake/interfaces';
import domtoimage from 'dom-to-image';
import { pdfMakeContentMain, styles } from '@/views/modules/analytics/utils/pdf-make-helper';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

export default function useExportPDF() {
  function onExportPdf(element: string, fileName: string) {
    /* pick DOM will transform to canvas */
    const node = document.querySelector(element);
    /* const node = document.querySelector('.dashboard-by-campaign-dashboard-contents-container'); */
    /* dashboard-by-campaign-dashboard-contents-container */
    if (!node) return "element class name 'content-wrapper' not found";

    /* paper size A4 */
    const PAPER_WIDTH = 595;
    const PAPER_HEIGHT = PAPER_WIDTH * 1.4141;

    /* Canvas height is the rest of page cut off the header and subheader */
    /* 1.4141 is A4 ratio -> width : height (1 : 1.4141) */
    const CANVAS_HEIGHT = node.clientWidth * 1.4141;
    /* calculate page count */
    const pageCount = Math.ceil(node.clientHeight / CANVAS_HEIGHT);

    /* do transform DOM to image statement */
    /* the "cacheBust" property using for resolve browser CORS */
    domtoimage.toPng(<Node>node, { cacheBust: true }).then(function (dataUrl: string) {
      /* list store Canvas items */
      const createdCanvasPromises = [];

      /* list store Canvas items include start-end */
      const canvasWillExecuteStartEndPromises = [];

      /* used as start position of Canvas will be crop */
      let start = 0;

      /* start position canvas list */
      const startList: number[] = [];

      /* loop for execute DOM to Canvas theses result is list of promise */
      for (let i = 0; i < pageCount; i++) {
        const promise = (start: number) => {
          return new Promise((resolve, reject) => {
            try {
              const image = new Image();
              image.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = node.clientWidth;
                canvas.height = CANVAS_HEIGHT;
                ctx?.drawImage(image, 0, start, node.clientWidth, CANVAS_HEIGHT, 0, 0, node.clientWidth, CANVAS_HEIGHT);
                resolve(canvas.toDataURL());
              };
              image.src = dataUrl;
            } catch (e) {
              reject(e);
            }
          });
        };
        createdCanvasPromises.push(promise);

        /* store canvas start-end crop position will be a part of execution later */
        startList.push(start);

        /* update canvas crop position */
        start += CANVAS_HEIGHT;
      }

      /* loop execute for defining each canvas has start and end position */
      for (let i = 0; i < createdCanvasPromises.length; i++) {
        canvasWillExecuteStartEndPromises.push(createdCanvasPromises[i](startList[i]));
      }

      /* resolve promise all */
      Promise.all(canvasWillExecuteStartEndPromises)
        .then((imageBase64List) => {
          const content: Content = [];

          /* loop create pdfMake each page */
          for (let i = 0; i < imageBase64List.length; i++) {
            /* push main content (canvas) to page */
            content.push(pdfMakeContentMain(imageBase64List[i], PAPER_WIDTH, i === imageBase64List.length - 1));
          }

          /* docDefinition for pdfMake */
          const docDefinition: TDocumentDefinitions = {
            info: {
              title: 'Dashboard By Campaign',
              author: '-',
              subject: '-',
              keywords: '-',
            },
            pageSize: {
              width: PAPER_WIDTH,
              height: PAPER_HEIGHT,
            },
            pageMargins: 0,
            content: content,
            styles: styles,
          };
          /* create pdf and open new window from doc definition config */

          //เปิดไปหน้าใหม่แล้วค่อยเซฟ
          /* const win = window.open('', '_blank');
          pdfMake.createPdf(docDefinition).open({}, win); */

          //กดแล้วโหลดเลย
          let name = `${fileName}`;
          pdfMake.createPdf(docDefinition).download(`${name}`);
        })
        .catch((e) => {
          throw e;
        })
        .finally(() => {});
    });
  }

  return {
    onExportPdf,
  };
}
