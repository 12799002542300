import { Ref, ref, onUpdated, reactive, watch, onBeforeMount, getCurrentInstance, computed } from 'vue';
import Sortable from 'sortablejs';

interface Props {
  data?: Array<SelectExpandTable.dataTable | Record<string, any>>;
  columns: Array<SelectExpandTable.TableColumn>;
  checkbox?: boolean;
  checkboxExpand?: boolean;
  selectAll?: boolean;
  busy?: boolean;
  hover?: boolean;
  responsive?: boolean;
  stickyHeader?: boolean;
  sortableRow?: boolean;
  sortableCol?: boolean;
  rowColorSelect?: string;
  currentPage?: number;
  perPages?: number;
  // Expand
  dataExpand?: Array<SelectExpandTable.dataExpand>;
  columnsExpand?: Array<SelectExpandTable.expandTableColumn>;
  /**
   * โชว์ expand ได้ row เดียว
   */
  expandOneOnly?: boolean;
  /**
   * show Columns Expand
   */
  showColumnsExpand?: boolean;
  /**
   * แสดง expand
   */
  expand?: boolean;
  /**
   * ปุ่มสำหรับ expand อยู่ column หลังสุด
   */
  expandEndColumn?: boolean;
  columnLabelExpand?: string;
  //
  /**
   * กำหนด บรรทัดแสดง text
   */
  textOverflow?: 'one_line_ellipsis' | 'three_line_ellipsis';
}

export default function useSelectExpandTable(props: Props) {
  type direction = 'ASC' | 'DESC' | '';
  interface Column {
    key: string;
    direction: direction;
    target: HTMLElement | null;
  }

  const vm = getCurrentInstance()?.proxy;
  const tableRef: Ref<HTMLElement | null> = ref(null);
  const tableContainerRef: Ref<HTMLElement | null> = ref(null);
  const tooltipTextRef: Ref<HTMLElement | null> = ref(null);
  const selectAllCheckbox: Ref<HTMLInputElement | null> = ref(null);
  const keyChange: Ref<number> = ref(0);
  const sortedColumn: Column = reactive({ key: '', direction: '', target: null });

  const displayedData: Ref<SelectExpandTable.dataTable[] | Record<string, any>[]> = ref([]);
  const displayedDataExpand: Ref<Record<string, any>[]> = ref([]);
  const displayedColumns: Ref<SelectExpandTable.TableColumn[]> = ref([]);
  const displayedColumnsExpand: Ref<SelectExpandTable.expandTableColumn[]> = ref([]);

  const colspanValue = ref<number>(props.columns?.length + setColSpan());
  const isClickExpand = reactive<boolean[]>([]);
  const isHideExpand = reactive<boolean[]>([]);

  const isSelectAll: Ref<boolean> = ref(false);

  const setSelectAll = () => {
    isSelectAll.value = true;
    onSelectAllRow(isSelectAll.value);
  };

  function onSelectAllRow(params: any, event?: any) {
    let checked = false;
    if (params) {
      checked = true;
      const targetObjects = displayedData.value.filter((item: any) => item.checkbox == false);
      if (targetObjects && targetObjects.length > 0) {
        targetObjects.forEach((targetObject: any) => {
          targetObject.checkbox = true;
        });
      }

      displayedData.value.forEach((item: SelectExpandTable.dataTable | Record<string, any>, index: number) => {
        if (item.expand == true) {
          const targetObjects = displayedDataExpand.value[index].filter((item: any) => item.checkbox == false);
          if (targetObjects && targetObjects.length > 0) {
            targetObjects.forEach((targetObject: any) => {
              targetObject.checkbox = true;
            });
          }
        }
      });
    } else {
      checked = false;
      const targetObjects = displayedData.value.filter((item: any) => item.checkbox == true);

      if (targetObjects && targetObjects.length > 0) {
        targetObjects.forEach((targetObject: any) => {
          targetObject.checkbox = false;
        });
      }

      displayedData.value.forEach((item: SelectExpandTable.dataTable | Record<string, any>, index: number) => {
        if (item.expand == true) {
          const targetObjects = displayedDataExpand.value[index].filter((item: any) => item.checkbox == true);
          if (targetObjects && targetObjects.length > 0) {
            targetObjects.forEach((targetObject: any) => {
              targetObject.checkbox = false;
            });
          }
        }
      });
    }

    const dataRow: Ref<Record<string, any>[]> = ref([]);
    const dataExpand: Ref<Record<string, any>[]> = ref([]);
    if (checked) {
      dataRow.value = displayedData.value.filter((item: Record<string, any>) => item.checkbox == true);
      displayedData.value.forEach((element: Record<string, any>, index: number) => {
        const data = displayedDataExpand.value[index].filter((item: Record<string, any>) => item.checkbox == true);
        data.forEach((item: Record<string, any>) => dataExpand.value.push(item));
      });
    }

    const SelectAll = displayedData.value.every((item: any) => item.checkbox == true);
    if (SelectAll) {
      isSelectAll.value = true;
    } else {
      isSelectAll.value = false;
    }

    vm?.$emit('select-all-row', isSelectAll.value, JSON.parse(JSON.stringify(dataRow.value)), JSON.parse(JSON.stringify(dataExpand.value)));
  }

  const onSelectRow = (params: SelectExpandTable.dataTable, index: number) => {
    const row_select = params;
    const row_expand_select: Ref<Record<string, any>[]> = ref([]);
    if (params.checkbox) {
      if (params.expand == true) {
        const targetObjects = displayedDataExpand.value[index].filter((item: any) => item.parent_id == params.id);
        row_expand_select.value = targetObjects;
        if (targetObjects && targetObjects.length > 0) {
          targetObjects.forEach((targetObject: any) => {
            targetObject.checkbox = true;
          });
        }
        displayedData.value.find((item: Record<string, any>) => {
          if (item.id === params.id) {
            item.isSelectAll = true;
            return true;
          }
        });
      }
    } else {
      if (params.expand == true) {
        const targetObjects = displayedDataExpand.value[index].filter((item: any) => item.parent_id == params.id);
        row_expand_select.value = targetObjects;
        if (targetObjects && targetObjects.length > 0) {
          targetObjects.forEach((targetObject: any) => {
            targetObject.checkbox = false;
          });
        }
        displayedData.value.find((item: Record<string, any>) => {
          if (item.id === params.id) {
            item.isSelectAll = false;
            return true;
          }
        });
      }
    }

    const dataRow: Record<string, any> = ref([]);
    const dataExpand: Ref<Record<string, any>[]> = ref([]);
    dataRow.value = displayedData.value.filter((item: Record<string, any>) => item.checkbox == true);
    displayedData.value.forEach((element: Record<string, any>, index: number) => {
      const data = displayedDataExpand.value[index].filter((item: Record<string, any>) => item.checkbox == true);
      data.forEach((item: Record<string, any>) => dataExpand.value.push(item));
    });

    const SelectAll = displayedData.value.every((item: any) => item.checkbox == true);
    if (SelectAll) {
      isSelectAll.value = true;
    } else {
      isSelectAll.value = false;
    }

    vm?.$emit(
      'row-select',
      isSelectAll.value,
      JSON.parse(JSON.stringify(row_select)),
      JSON.parse(JSON.stringify(row_expand_select.value)),
      JSON.parse(JSON.stringify(dataRow.value)),
      JSON.parse(JSON.stringify(dataExpand.value)),
    );
  };

  function onSomeCheckboxExpand(params: any, index: number) {
    if (!displayedDataExpand.value[index] || displayedDataExpand.value[index].length == 0) {
      return false;
    }

    const allChecked = displayedDataExpand.value[index]
      .filter((item: any) => item.parent_id === params.id)
      .every((item: any) => item.checkbox === true);

    if (!allChecked) {
      const someChecked = displayedDataExpand.value[index]
        .filter((item: any) => item.parent_id === params.id)
        .some((item: any) => item.checkbox === true);

      return someChecked;
    }
    return false;
  }

  function onSelectRowExpand(params: any, index: any) {
    const row_select: Ref<Record<string, any>> = ref({});
    const row_expand_select = params;

    const isAllChecked = displayedDataExpand.value[index]
      ?.filter((item: any) => item.parent_id === params.parent_id)
      .every((item: any) => item.checkbox === true);

    if (isAllChecked) {
      const targetObject = displayedData.value.find((item: any) => item.id === params.parent_id);
      if (targetObject) {
        row_select.value = targetObject;
        targetObject.checkbox = true;
        targetObject.isSelectAll = true;
      }
    } else {
      const targetObject = displayedData.value.find((item: any) => item.id === params.parent_id);
      if (targetObject) {
        row_select.value = targetObject;
        targetObject.checkbox = false;
        targetObject.isSelectAll = false;
      }
    }
    const dataRow: Record<string, any> = ref([]);
    const dataExpand: Ref<Record<string, any>[]> = ref([]);
    dataRow.value = displayedData.value.filter((item: Record<string, any>) => item.checkbox == true);
    displayedData.value.forEach((element: Record<string, any>, index: number) => {
      const data = displayedDataExpand.value[index].filter((item: Record<string, any>) => item.checkbox == true);
      data.forEach((item: Record<string, any>) => dataExpand.value.push(item));
    });

    const SelectAll = displayedData.value.every((item: any) => item.checkbox == true);
    if (SelectAll) {
      isSelectAll.value = true;
    } else {
      isSelectAll.value = false;
    }

    vm?.$emit(
      'expand-select',
      isSelectAll.value,
      JSON.parse(JSON.stringify(row_expand_select)),
      JSON.parse(JSON.stringify(row_select.value)),
      JSON.parse(JSON.stringify(dataRow.value)),
      JSON.parse(JSON.stringify(dataExpand.value)),
    );
  }

  function onClickExpand(index: number) {
    if (props.expandOneOnly) {
      // cses select 1 only
      for (let i = 0; i < isClickExpand.length; i++) {
        if (i != index) {
          isClickExpand[i] = false;
        }
      }
    }

    if (isClickExpand[index] == true) {
      isClickExpand[index] = false;
      isHideExpand[index] = true;
      vm?.$emit('on-expand', false);
    } else {
      isClickExpand[index] = true;
      isHideExpand[index] = false;
      vm?.$emit('on-expand', true);
    }
  }

  const onRowClick = (row: Record<string, any>) => {
    return vm?.$emit('row-click', JSON.parse(JSON.stringify(row)));
  };
  const onRowExpandClick = (row: Record<string, any>) => {
    return vm?.$emit('row-expand-click', JSON.parse(JSON.stringify(row)));
  };

  function setColSpan() {
    if (props.expand == true && props.checkbox == true) {
      return 2;
    } else if (props.expand == true) {
      return 1;
    }
    return 1;
  }

  function mapDataExpand() {
    const map: Ref<Record<string, any>[]> = ref([]);
    displayedData.value.forEach((item: any) => {
      const data = props.dataExpand?.filter((itemExpand: any) => itemExpand.parent_id == item.id);
      const test = data?.forEach((item, index) => {
        item['id'] = index + 1;
      });
      if (data) {
        map.value.push(data);
      } else {
        map.value.push([]);
      }
    });
    return map.value;
  }

  const onSort = (event: Event, col: { key: string; label: string }) => {
    const sortingCheckbox = event.target as HTMLElement;

    if (sortingCheckbox.nodeName !== 'INPUT') {
      sortedColumn.target?.classList.remove('asc');
      sortedColumn.target?.classList.remove('desc');
      sortedColumn.key = '';
      sortedColumn.direction = '';
      sortedColumn.target = null;
      return;
    }

    if (sortedColumn.key !== col.key) {
      sortedColumn.target?.classList.remove('asc');
      sortedColumn.target?.classList.remove('desc');
      sortingCheckbox.classList.add('asc');
      sortedColumn.direction = 'ASC';
      sortedColumn.key = col.key;
      sortedColumn.target = sortingCheckbox;
    } else {
      if (sortingCheckbox.classList.contains('asc')) {
        sortingCheckbox.classList.remove('asc');
        sortedColumn.direction = 'DESC';
        sortingCheckbox.classList.add('desc');
      } else if (sortingCheckbox.classList.contains('desc')) {
        sortingCheckbox.classList.remove('desc');
        sortedColumn.direction = '';
      } else {
        sortedColumn.direction = 'ASC';
        sortingCheckbox.classList.add('asc');
      }
    }

    return vm?.$emit('sorting-change', { key: sortedColumn.key, direction: sortedColumn.direction });
  };

  const initSortColumn = () => {
    if (!sortedColumn.key) {
      return;
    }

    const sortingCheckbox = tableRef.value?.querySelector(`#sorting-${sortedColumn.key}`);

    if (sortedColumn.direction == 'ASC') {
      return sortingCheckbox?.classList.add('asc');
    } else if (sortedColumn.direction == 'DESC') {
      return sortingCheckbox?.classList.add('desc');
    }
  };

  const rowKeyChang: Ref<number> = ref(0);
  const handleRowChange = (event: Sortable.SortableEvent) => {
    const newIndex: number = <number>event.newDraggableIndex;
    const oldIndex: number = <number>event.oldDraggableIndex;
    if (props.data && props.data.length) {
      displayedData.value.splice(newIndex, 0, displayedData.value.splice(oldIndex, 1)[0]);
      displayedDataExpand.value.splice(newIndex, 0, displayedDataExpand.value.splice(oldIndex, 1)[0]);
      isHideExpand.splice(newIndex, 0, isHideExpand.splice(oldIndex, 1)[0]);
      isClickExpand.splice(newIndex, 0, isClickExpand.splice(oldIndex, 1)[0]);
      vm?.$emit('row-change', JSON.parse(JSON.stringify(displayedData.value)));
      rowKeyChang.value++;
    }
  };

  const handleColumnChange = (event: Sortable.SortableEvent) => {
    if (props.columns && props.columns.length) {
      displayedColumns.value.splice(<number>event.newDraggableIndex, 0, displayedColumns.value.splice(<number>event.oldDraggableIndex, 1)[0]);
      vm?.$emit('col-change', JSON.parse(JSON.stringify(displayedColumns.value)));
    }
  };

  const setSortableRow = (sortable: boolean) => {
    sortableRowOptions.disabled = !sortable;
    return keyChange.value++;
  };

  const setSortableCol = (sortable: boolean) => {
    sortableColOptions.disabled = !sortable;
    return keyChange.value++;
  };

  const sortableRowOptions: Sortable.SortableOptions = reactive({
    chosenClass: 'is-selected',
    ghostClass: 'sortable-ghost',
    dragClass: 'sortable-drag',
    draggable: '.dragable-row',
    onEnd: handleRowChange,
    disabled: true,
    animation: 300,
    easing: 'cubic-bezier(0.22, 1, 0.36, 1)',
  });

  const sortableColOptions: Sortable.SortableOptions = reactive({
    chosenClass: 'is-selected',
    ghostClass: 'sortable-ghost',
    dragClass: 'sortable-drag',
    draggable: '.dragable-col',
    handle: '.handle-dragable-col',
    onEnd: handleColumnChange,
    disabled: true,
    animation: 300,
    easing: 'cubic-bezier(0.22, 1, 0.36, 1)',
  });

  const setPageContent = () => {
    const tableContainer = tableContainerRef.value;
    if (props.currentPage && props.perPages) {
      const startIndex = props.perPages * (props.currentPage - 1);
      const endIndex = props.perPages * props.currentPage;

      displayedData.value = props.data?.slice(startIndex, endIndex) || [];
      displayedDataExpand.value = mapDataExpand().slice(startIndex, endIndex) || [];

      displayedColumns.value = props.columns || [];
      displayedColumnsExpand.value = props.columnsExpand || [];
    } else {
      displayedData.value = JSON.parse(JSON.stringify(props.data));
      displayedDataExpand.value = mapDataExpand();

      displayedColumns.value = props.columns || [];
      displayedColumnsExpand.value = props.columnsExpand || [];
    }
    if (tableContainer) {
      tableContainer.scrollTop = 0;
    }

    displayedData.value.forEach((item: any, index: number) => {
      isHideExpand[index] = true;
      isClickExpand[index] = false;
    });
    return keyChange.value++;
  };

  const tooltipData: Ref<string> = ref('');
  const tooltipStyle: Ref<Record<string, any>> = ref({});
  const displayMode: Ref<boolean> = ref(false);

  function onLongText(data: any, mode: string, e: any) {
    let offset = 0;
    let scroll = 0;
    if (mode == 'one_line_ellipsis') {
      offset = e.target.offsetWidth;
      scroll = e.target.scrollWidth;
    } else {
      offset = e.target.offsetHeight;
      scroll = e.target.scrollHeight;
    }
    if (offset < scroll) {
      tooltipData.value = data;
      const targetElement = e.target as HTMLElement;

      if (tableContainerRef.value && targetElement && tableRef.value) {
        const positionMouse = getPositionWithinParent(targetElement, tableContainerRef.value, e);
        const width = tableContainerRef.value.offsetWidth;
        const height = tableContainerRef.value.offsetHeight;

        const leftTableContainer = tableContainerRef.value.getBoundingClientRect().left;
        const leftTable = tableRef.value.getBoundingClientRect().left;
        const topTableContainer = tableContainerRef.value.getBoundingClientRect().top;
        const topTable = tableRef.value.getBoundingClientRect().top;

        // ใช้ requestAnimationFrame(() => {}); เพื่อรอให้เกิดการเปลี่ยนแปลงใน DOM
        // ถ้าของ Vue ใช้ Vue.nextTick(() => {}); เพื่อรอให้ Vue.js อัพเดท DOM
        requestAnimationFrame(() => {
          if (tooltipTextRef.value) {
            const widthTooltip = tooltipTextRef.value.offsetWidth;
            const heightTooltip = tooltipTextRef.value.offsetHeight;
            const position = positionTooltip([positionMouse.x, positionMouse.y], {
              viewSize: [width, height],
              contentSize: [widthTooltip, heightTooltip],
            });
            tooltipStyle.value = {
              top: position.top + -(topTable - topTableContainer),
              left: position.left + -(leftTable - leftTableContainer),
            };
          }
        });
      }
      displayMode.value = true;
    } else {
      displayMode.value = false;
    }
  }

  const getPositionWithinParent = (child: HTMLElement, parent: HTMLElement, e: any): { x: number; y: number } => {
    const parentRect = parent.getBoundingClientRect();
    const childRect = child.getBoundingClientRect();

    const offsetX = childRect.left - parentRect.left;
    const offsetY = childRect.top - parentRect.top;

    return { x: offsetX + e.offsetX, y: offsetY + e.offsetY };
  };

  function positionTooltip(pos: Array<number>, size: Record<string, any>) {
    const obj: Ref<Record<string, number>> = ref({});
    const canvasX: number = pos[0];
    const canvasY: number = pos[1];
    const width_canvas: number = size.viewSize[0];
    const height_canvas: number = size.viewSize[1];
    const width_tooltip: number = size.contentSize[0];
    const height_tooltip: number = size.contentSize[1];
    //distance mouse X &  Tooltip X
    const distanceX = 10;

    //แบบที 1
    const percentHeight_canvas: number = (canvasY * 100) / height_canvas;
    const Y_tooltip: number = (height_tooltip * percentHeight_canvas) / 100;
    obj.value['top'] = canvasY - Y_tooltip;
    if (canvasX < width_canvas / 2) {
      obj.value['left'] = canvasX + distanceX;
    } else {
      obj.value['left'] = canvasX - width_tooltip - distanceX;
    }

    //แบบที่ 2
    // var overSize = 0;
    // if (canvasY < height_canvas / 2) {
    //   if (canvasY + height_tooltip > height_canvas) {
    //     overSize = canvasY + height_tooltip - height_canvas;
    //     obj['top'] = canvasY - overSize;
    //   } else {
    //     obj['top'] = canvasY;
    //   }
    // } else {
    //   if (canvasY - height_tooltip < 0) {
    //     overSize = (canvasY - height_tooltip) * -1;
    //     obj['top'] = canvasY + overSize - height_tooltip;
    //   } else {
    //     obj['top'] = canvasY - height_tooltip;
    //   }
    // }
    // if (canvasX < width_canvas / 2) {
    //   obj['left'] = canvasX + distanceX;
    // } else {
    //   obj['left'] = canvasX - width_tooltip - distanceX;
    // }
    return obj.value;
  }

  function closeLongText(e: any) {
    displayMode.value = false;
  }

  watch(
    () => props.sortableRow,
    () => {
      setSortableRow(props.sortableRow || false);
    },
  );

  watch(
    () => props.sortableCol,
    () => {
      setSortableCol(props.sortableCol || false);
    },
  );

  watch(
    [() => props.currentPage, () => props.perPages, () => props.data],
    () => {
      setPageContent();
    },
    { deep: true },
  );

  watch(
    () => props.columns,
    () => {
      colspanValue.value = props.columns.length + setColSpan();
    },
  );

  onUpdated(() => {
    initSortColumn();
  });

  onBeforeMount(() => {
    if (props.data) {
      setPageContent();
    }

    if (props.selectAll) {
      setSelectAll();
    }

    setSortableRow(props.sortableRow || false);
    setSortableCol(props.sortableCol || false);
  });

  return {
    rowKeyChang,
    tableRef,
    tableContainerRef,
    selectAllCheckbox,
    sortableRowOptions,
    sortableColOptions,
    keyChange,
    displayedData,
    displayedDataExpand,
    displayedColumns,
    displayedColumnsExpand,
    colspanValue,
    isHideExpand,
    isClickExpand,
    isSelectAll,
    tooltipData,
    tooltipStyle,
    displayMode,
    tooltipTextRef,
    onSelectAllRow,
    onSelectRow,
    onSelectRowExpand,
    onSort,
    onRowClick,
    onRowExpandClick,
    onClickExpand,
    setColSpan,
    mapDataExpand,
    onSomeCheckboxExpand,
    onLongText,
    closeLongText,
    getPositionWithinParent,
    positionTooltip,
  };
}
