<template>
  <svg id="Group_37105" data-name="Group 37105" xmlns="http://www.w3.org/2000/svg" width="46.62" height="98.898" viewBox="0 0 46.62 98.898">
    <path id="Path_24092" data-name="Path 24092" d="M191.366,17.013a8.971,8.971,0,1,0,17.1-.6l-8.117,4.234Z" transform="translate(-176.2 -4.521)" />
    <path
      id="Path_24093"
      data-name="Path 24093"
      d="M180.679,15.6a.13.13,0,0,0-.125.094l-.323,1.147a.129.129,0,0,0,.125.164h.18v-.433h.175V17h.433v-.433h.175V17h.456v-.433h.175V17h.18a.129.129,0,0,0,.124-.166l-.344-1.147a.13.13,0,0,0-.124-.092h-.315v-.374h.391a.239.239,0,1,0,0-.479h-.391V8.134l15.822,6.392,15.2-7.924L196.149,0l-15.2,7.925v6.82h-.392a.239.239,0,0,0,0,.479h.392V15.6Z"
      transform="translate(-173.22)"
    />
    <path
      id="Path_24094"
      data-name="Path 24094"
      d="M216.924,60.588,209.93,40.505a4.5,4.5,0,0,0-4.081-3.017v-.007h-1.726A8.083,8.083,0,0,0,199.505,39l-.057.04a9.961,9.961,0,0,0-4.47,8.167V76.4a.612.612,0,0,1-1.224,0v-29.2a9.962,9.962,0,0,0-4.47-8.167l-.058-.04a8.079,8.079,0,0,0-4.617-1.518h-2.615v.006a4.5,4.5,0,0,0-4.194,3.019l-6.993,20.083a4.5,4.5,0,1,0,8.508,2.963l2.679-7.693v50.121a4.62,4.62,0,0,0,3.941,4.641,4.507,4.507,0,0,0,5.069-4.47V98.434a2.918,2.918,0,0,1,5.835,0v7.545a4.619,4.619,0,0,0,3.94,4.641,4.507,4.507,0,0,0,5.069-4.47V56.181l2.567,7.37a4.5,4.5,0,1,0,8.508-2.963"
      transform="translate(-170.555 -11.756)"
    />
  </svg>
</template>
