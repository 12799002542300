<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="61.947" height="73.147" viewBox="0 0 61.947 73.147">
    <defs>
      <clipPath id="clip-path-14">
        <rect id="Rectangle_50806" data-name="Rectangle 50806" width="61.947" height="73.147" fill="none" />
      </clipPath>
    </defs>
    <g id="Group_116454" data-name="Group 116454" transform="translate(0 0)">
      <g id="Group_116453" data-name="Group 116453" transform="translate(0 0)" clip-path="url(#clip-path-14)">
        <path
          id="Path_311309"
          data-name="Path 311309"
          d="M58.487,73.146A3.453,3.453,0,0,1,55.824,71.9L41.592,54.864l-.472.221a28.642,28.642,0,0,1-12.2,2.72,28.938,28.938,0,1,1,19.031-7.181l-.5.434,13.693,16.4a3.468,3.468,0,0,1-2.654,5.693ZM28.9,6.911A21.968,21.968,0,1,0,50.878,28.88,21.823,21.823,0,0,0,28.9,6.911Z"
          transform="translate(0 0)"
          fill="#c0c7d2"
        />
        <path
          id="Path_311310"
          data-name="Path 311310"
          d="M15.706,18.776a3.553,3.553,0,1,1-3.553,3.553,3.554,3.554,0,0,1,3.553-3.553"
          transform="translate(4.292 6.631)"
          fill="#c0c7d2"
        />
        <path
          id="Path_311311"
          data-name="Path 311311"
          d="M22.707,18.776a3.553,3.553,0,1,1-3.553,3.553,3.554,3.554,0,0,1,3.553-3.553"
          transform="translate(6.764 6.631)"
          fill="#c0c7d2"
        />
        <path
          id="Path_311312"
          data-name="Path 311312"
          d="M29.709,18.776a3.553,3.553,0,1,1-3.553,3.553,3.554,3.554,0,0,1,3.553-3.553"
          transform="translate(9.237 6.631)"
          fill="#c0c7d2"
        />
      </g>
    </g>
  </svg>
</template>
