export default {
  image: 'https://icon-library.com/images/twitter-small-icon/twitter-small-icon-17.jpg',
  tierId: 2,
  tierLabel: 'SILBER MEMBER',
  averageLifetimeValue: 7596.01,
  point: 3999,
  firstname: 'Isares',
  lastname: 'Kullaphat',
  mobileNo: '0920481028',
  birthdate: '2022-04-02T17:00:00.000Z',
  genderLabel: 'Male',
  email: 'isares.ku@gmail.com',
  isPDPAConcent: true,
  occupation: 'Officer',
  address: 'Chatujak Bangkok Thailand',
  lastVisit: '2022-08-17T17:00:00.000Z',
};
