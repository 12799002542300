import Edit from '@/assets/icons/modules/segment/all-segment/Edit.vue';
import Delete from '@/assets/icons/modules/segment/all-segment/Delete.vue';
import Duplicate from '@/assets/icons/base/DuplicateIcon.vue';
import DownloadIcon from '@/assets/icons/base/DownloadIcon.vue';
import CancelIcon from '@/assets/icons/base/CancelIcon.vue';
import DashboardIcon from '@/assets/icons/modules/communication/campaign-tab/DashboardIcon.vue';

import { CAMPAIGN_STATUS } from '@/constants/modules/commu/campaign-status';

// permission
import ability from '@/libs/acl/ability';

export function handleActionByStatus(campaignStatus: number) {
  const listStatusShowTooltip = [CAMPAIGN_STATUS.DRAFT, CAMPAIGN_STATUS.SCHEDULED, CAMPAIGN_STATUS.COMPLETE];
  if (!listStatusShowTooltip.some((status) => status == campaignStatus)) {
    return [];
  }

  const getActionList = handleActionList();

  // dashboard, edit, duplicate, delete
  if (campaignStatus == CAMPAIGN_STATUS.DRAFT) {
    return getActionList.filter((action) => action.key !== 'download' && action.key !== 'cancel');
  }

  // cancel, dashboard
  if (campaignStatus == CAMPAIGN_STATUS.SCHEDULED) {
    return getActionList.filter((action) => action.key === 'cancel' || action.key === 'dashboard');
  }

  // export, dashboard, edit, duplicate, delete
  if (campaignStatus == CAMPAIGN_STATUS.COMPLETE) {
    return getActionList.filter((action) => action.key !== 'cancel');
  }
}

export const resolveStatusClass = (status: number) => {
  switch (status) {
    case CAMPAIGN_STATUS.DRAFT:
      return 'status-draft';
    case CAMPAIGN_STATUS.PREPARING:
      return 'status-preparing';
    case CAMPAIGN_STATUS.SCHEDULED:
      return 'status-scheduled';
    case CAMPAIGN_STATUS.DISPATCH:
      return 'status-sending';
    case CAMPAIGN_STATUS.PAUSE:
      return 'status-pause';
    case CAMPAIGN_STATUS.COMPLETE:
      return 'status-complete';
    case CAMPAIGN_STATUS.CANCEL:
      return 'status-cancel';
    default:
      return 'status-unknown';
  }
};

export const resolveStatusLabel = (status: number) => {
  switch (status) {
    case CAMPAIGN_STATUS.DRAFT:
      return 'Draft';
    case CAMPAIGN_STATUS.PREPARING:
      return 'Preparing';
    case CAMPAIGN_STATUS.SCHEDULED:
      return 'Scheduled';
    case CAMPAIGN_STATUS.DISPATCH:
      return 'Sending';
    case CAMPAIGN_STATUS.PAUSE:
      return 'Pause';
    case CAMPAIGN_STATUS.COMPLETE:
      return 'Complete';
    case CAMPAIGN_STATUS.CANCEL:
      return 'Cancel';
    default:
      return 'Unknown';
  }
};

function handleActionList() {
  // download, cancel, dashboard, edit, duplicate, delete
  const actionList = [];

  if (ability.can('portal-cdp', 'cdp-portal-commu-downloadcsv')) {
    actionList.push({ key: 'download', tooltip: 'Export', icon: DownloadIcon, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' });
  }

  if (ability.can('portal-cdp', 'cdp-portal-commu-deletecampaign')) {
    actionList.push({ key: 'cancel', tooltip: 'Cancel', icon: CancelIcon, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' });
  }

  actionList.push({ key: 'dashboard', tooltip: 'Dashboard', icon: DashboardIcon, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' });

  if (ability.can('portal-cdp', 'cdp-portal-commu-createcampaign')) {
    actionList.push({ key: 'edit', tooltip: 'Edit', icon: Edit, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' });
  }

  if (ability.can('portal-cdp', 'cdp-portal-commu-copycampaign')) {
    actionList.push({ key: 'duplicate', tooltip: 'Duplicate', icon: Duplicate, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' });
  }

  if (ability.can('portal-cdp', 'cdp-portal-commu-deletecampaign')) {
    actionList.push({ key: 'delete', tooltip: 'Delete', icon: Delete, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' });
  }

  return actionList;
}
