<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<path id="Path_17292_00000157272501426899190970000002421572029927084176_" class="st3" d="M65.6,10.63L27.76,48.46
	c-0.85,0.84-0.85,2.21-0.01,3.06c0,0,0.01,0.01,0.01,0.01L65.6,89.37c0.85,0.84,2.22,0.84,3.06,0l3.58-3.58
	c0.84-0.85,0.84-2.22,0-3.06L39.59,50l32.65-32.72c0.84-0.85,0.84-2.22,0-3.06l-3.58-3.58C67.82,9.79,66.45,9.79,65.6,10.63"/>
</svg>
</template>

<style type="text/css" scoped>
	.st1{stroke-miterlimit:10;}
	.st4{stroke-linecap:round;stroke-miterlimit:10;}
	.st6{stroke-miterlimit:10;}
	.st7{stroke-width:1.0002;stroke-miterlimit:10.0022;}
	.st9{stroke-miterlimit:10;}
	.st10{stroke-miterlimit:10;}
	.st11{stroke-miterlimit:10;}
	.st12{stroke-miterlimit:10;}
	.st13{stroke-miterlimit:10;}
	.st17{stroke-miterlimit:10;}
	.st18{stroke-miterlimit:10;}
	.st21{stroke-width:1;stroke-miterlimit:9.9997;}
	.st23{stroke-miterlimit:10;}
	.st26{stroke-miterlimit:10;}
	.st27{clip-path:url(#SVGID_00000154415043534907786260000011618962984780119726_);}
	.st30{clip-path:url(#SVGID_00000112604811606467564120000018301370264745989002_);}
	.st31{clip-path:url(#SVGID_00000023259514704339307210000008836737183651957935_);}
	.st32{clip-path:url(#SVGID_00000056423971604721847440000016295336153850290309_);}
	.st33{clip-path:url(#SVGID_00000176044595935002751350000002316002692264643750_);}
	.st34{clip-path:url(#SVGID_00000116234437434633829920000001253764521622957716_);}
	.st35{clip-path:url(#SVGID_00000159446864057683784460000015628235617411002551_);}
	.st36{clip-path:url(#SVGID_00000169532769278332438100000001688692713596350397_);}
	.st37{clip-path:url(#SVGID_00000023261517144865991450000009136421331972489887_);}
	.st38{clip-path:url(#SVGID_00000072967610127513317240000016917524323506175920_);}
</style>