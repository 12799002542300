<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 110 110" style="enable-background:new 0 0 110 110;" xml:space="preserve">
    <g id="Group_31569" transform="translate(-1563 -508.199)" style="background-color: #0064BA;">
      <g id="Group_31561" transform="translate(12551 15057.199)">
        <g>
          <g id="Group_31560" transform="translate(-10975.264 -14415.193)">
            <path id="Path_21243" style="fill:#0064BA;" d="M40.29-45.43c-0.1-0.01-0.2-0.03-0.28-0.08c-3.79-1.85-7.32-4.19-10.51-6.95
					c-8.38-7.33-13.9-17.39-15.58-28.4c-0.39-2.82-0.57-5.66-0.54-8.51c-0.02-4.21-0.02-8.5-0.02-12.64v-0.82c0-0.18,0-0.39,0-0.53
					c0.14,0,0.36-0.02,0.52-0.03c5.1-0.17,10.15-1.13,14.96-2.85c3.78-1.36,7.37-3.19,10.7-5.45c0.11-0.07,0.2-0.15,0.29-0.22
					c0.2-0.16,0.33-0.26,0.43-0.26h0.05c0.06,0.01,0.11,0.02,0.17,0.04c3.72,2.68,7.82,4.77,12.17,6.2c1.2,0.41,2.52,0.79,4.04,1.17
					c0.94,0.23,1.87,0.42,2.77,0.59l-0.25,0.26l-2.87,2.95c-0.17,0.17-0.35,0.35-0.52,0.51c-0.29,0.28-0.59,0.58-0.87,0.89l0,0
					h-0.06c-4.88-1.25-9.57-3.18-13.92-5.73c-0.21-0.13-0.44-0.2-0.68-0.2c-0.25,0.01-0.49,0.08-0.7,0.21
					c-4.82,2.83-10.05,4.88-15.51,6.08c-1.15,0.25-2.34,0.45-3.49,0.62l-0.89,0.15c-0.53,0.04-0.92,0.5-0.88,1.03
					c0,1.26,0,2.56,0,3.8c-0.08,3.29,0.01,6.59,0.27,9.87c0.63,6.63,2.88,13,6.55,18.56c3.53,5.43,8.29,9.95,13.88,13.2
					c0.23,0.15,0.5,0.23,0.77,0.23c0.27,0,0.53-0.08,0.76-0.23c4.52-2.65,8.52-6.12,11.79-10.22c3.22-4.14,5.65-8.84,7.18-13.86
					c0.06-0.18,0.16-0.35,0.29-0.49c1.68-1.7,3.4-3.42,5.06-5.08l1.59-1.59c-0.63,6.07-2.41,11.97-5.24,17.37
					c-2.96,5.47-6.94,10.33-11.74,14.3c-2.89,2.38-6.05,4.41-9.43,6.04C40.45-45.46,40.37-45.44,40.29-45.43" />
            <path id="Path_21244" style="fill:#0064BA;" d="M42.7-69.25c-0.24,0-0.47-0.09-0.64-0.27L28.7-82.88
					c-0.36-0.35-0.36-0.92-0.01-1.28c0,0,0.01-0.01,0.01-0.01l2.78-2.69c0.35-0.35,0.92-0.35,1.27,0l7.64,7.64
					c0.87,0.87,2.29,0.87,3.16,0c0,0,0,0,0,0l22.6-22.6c0.35-0.35,0.93-0.35,1.28,0l3.46,3.46c0.35,0.35,0.35,0.93,0,1.28
					L43.33-69.53c-0.17,0.17-0.4,0.27-0.64,0.27" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
