export default {
  dashboard: 'Dashboard',
  add_chart: 'Add Chart',
  data_type: 'Data Type',
  source: 'Source',
  date: 'Date',
  chart_type: 'Chart Type',
  cancel: 'Cancel',
  close: 'Close',
  add: 'Add',
  data_info: 'Data Info',
  setting: 'Setting',
  delete: 'Delete',
  information: 'Information',
  chart_name: 'Chart Name',
  description: 'Description',
  filter: 'Filter',
  filters: 'Filters',
  add_tab: 'Add tab',
  tab_name: 'Tab Name',
  fill: 'Fill',
  All: 'All',
  'Pie Chart': 'Pie Chart',
  'Line Chart': 'Line Chart',
  'Bar Chart': 'Bar Chart',
  Heatmap: 'Heatmap',
  Infographic: 'Infographic Chart',
  Radar: 'Radar Chart',
  Box: 'Box Chart',
  'Geo-Map': 'GEO/Map',
  Table: 'Table',
  Gauge: 'แผนภูมิวัด (Gauge)',
  TOverview: 'แผนภูมิภาพรวม (Overview)',
  Profile: 'Profile',
  Customer: 'Customer',
  Product: 'Product',
  Transaction: 'Transaction',
  POS: 'POS',
  CRM: 'CRM',
  Csv: 'Csv',
  'CRM/POS': 'CRM/POS',
  BP: 'BCRM Platform',
  FH5: 'Fasthelp5',
  edit_tab: 'Edit Tab',
  save: 'Save',
  no_filter: 'No Filter',
  selected: 'Selected',
  select: 'Select',
  setting_chart: 'Setting Chart',
  color: 'Color',
  no_color: 'No Color',
  apply: 'Apply',
  confirm_deletion: 'Confirm deletion',
  or_not: 'or not',
  confirm: 'Confirm',
  no_item: 'No item',
  search: 'Search',
  reset: 'reset',
  select_source: 'Select source',
  startDate: 'Start Date',
  endDate: 'End Date',
  fillDataSourceAndDate: 'Please fill the correct source and date',
  successful: 'Successful',
  success: 'success',
  want_to_delete_tab: 'Are you sure you want to delete',
  yes_no: '?',
  create_tab: 'Create a new tab',
  change_tab_name: 'Change tab name',
  tab_name_to: 'to',
  tab_list_empty: 'Tab list empty',
  not_found_tab_item: 'Not found tab item',
  overview: 'Overview',
  geomap_extraleft_title: 'Top 5 Provinces',
  total: 'Total',
  no_data: 'No Data',
  dynamicFilter: 'Dynamic Filter',
  seemore: 'See More',
  chart: 'Chart',
};
