<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="106.645" height="122.361" viewBox="0 0 106.645 122.361">
    <g id="Group_53832" data-name="Group 53832" transform="translate(15943.822 17842.5)">
      <path
        id="Union_163"
        data-name="Union 163"
        d="M73.822,0V12.435c.722.648,1.415,1.3,2.086,1.967.031.381.078.8.14,1.247,1.773,12.908,0,58.849,0,58.849L15.973,80.752H9.746V76.22H0v2.552l0,5.939c0,3.2,1.223,4.964,4.217,6.107,6.3,2.4,13.251,4.071,22.539,5.4a143.435,143.435,0,0,0,20.254,1.5c1.553,0,3.118-.029,4.651-.086C66.8,97.075,79.008,95,90.091,91.122c2.125-.745,3.281-1.463,3.992-2.48s.992-2.345.993-4.613c0-6.122,0-12.347,0-18.367V58.169c0-2.345-.018-4.731-.036-7.038-.04-5.216-.081-10.61.086-15.908a33.031,33.031,0,0,0-2.235-13.181,38.733,38.733,0,0,0-7.048-11.286A69.043,69.043,0,0,0,73.823,0h0Z"
        transform="translate(-15848.179 -17744.279) rotate(180)"
        fill="#3a83f6"
        stroke="rgba(0,0,0,0)"
        stroke-width="1"
      />
      <g id="Group_53829" data-name="Group 53829" transform="translate(-15932.325 -17831)">
        <g id="Group_106" data-name="Group 106">
          <g id="Group_115" data-name="Group 115">
            <g id="Group_114" data-name="Group 114">
              <g id="Group_53827" data-name="Group 53827">
                <path
                  id="Path_52"
                  data-name="Path 52"
                  d="M95.13,62.5c-.239-7.642-.049-15.3-.049-22.946q0-12.929,0-25.859c0-4.247-.986-5.691-4.986-7.093C77.658,2.242,64.756.571,51.667.086A139.018,139.018,0,0,0,26.763,1.5,104.2,104.2,0,0,0,4.224,6.9C1.217,8.05.007,9.811.006,13.009Q0,38.783.028,64.556a30.048,30.048,0,0,0,.517,5.432c1.333,7.127,4.961,13.079,9.871,18.25,9.864,10.389,22.05,17.207,35.244,22.346a7.019,7.019,0,0,0,4.458-.209,74.5,74.5,0,0,0,8.294-3.622c10.247-5.06,19.818-11.094,27.437-19.79,6.121-6.985,9.579-14.955,9.282-24.466M78.518,80.542c-5.781,6.6-13.313,11.988-24.422,17.474-2.071,1.023-4.269,2.07-6.43,2.883-13.02-5.245-22.64-11.424-30.185-19.37C13.406,77.237,11,72.875,10.123,68.2a20.216,20.216,0,0,1-.351-3.661q-.05-22.63-.023-45.26V15.264a99.362,99.362,0,0,1,18.394-4.117,133.674,133.674,0,0,1,18.872-1.4c1.434,0,2.877.027,4.291.08,13.418.5,24.286,2.232,34.028,5.448V39.552c0,2.327-.018,4.655-.035,6.983-.041,5.3-.083,10.786.089,16.267.2,6.519-1.98,12.157-6.871,17.74"
                  transform="translate(0 0)"
                />
                <path
                  id="Path_53"
                  data-name="Path 53"
                  d="M63.775,64.846c-5.08-.017-10.16,0-15.241-.008-2.242,0-2.247-.009-2.247-2.265q0-15.158-.005-30.315c0-1.983-.554-2.543-2.517-2.573-1.656-.025-3.311-.02-4.966,0-2.067.023-2.645.594-2.648,2.642q-.013,10.019,0,20.039,0,9.848,0,19.7c0,2.473.491,2.954,3.022,2.961,2.341.008,4.68,0,7.021,0,5.88,0,11.758.012,17.638-.007,2.052-.007,2.624-.614,2.638-2.664.012-1.6.009-3.2,0-4.8-.011-2.284-.406-2.7-2.693-2.71"
                  transform="translate(-4.125 -3.386)"
                  fill="#3a83f6"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
