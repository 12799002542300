<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g>
      <path
        class="st8"
        d="M164.3,104.7c3.77-3.4,8.56-5.45,13.62-5.81c7.24-0.25,14.48-0.25,21.74-0.25h2.87v25.25l66.74-38.53
		l-66.84-38.59v25.4l-1.84,0.04c-6.88,0-13.77-0.19-20.63,0c-3.83,0.06-7.65,0.48-11.4,1.27c-10.96,2.7-20.83,8.72-28.25,17.23
		c-6.65,7.33-12.34,15.49-16.93,24.26c-5.34,9.9-10.26,20.03-15.45,30.02c-4.66,9.85-10.56,19.06-17.56,27.42
		c-5.64,6.83-14.1,10.7-22.95,10.53c-17.02-0.15-34.01,0-51.11,0h-2.05v26.45c0.44,0,0.8,0,1.16,0c18.52,0,37.04,0,55.55,0
		c8.23-0.07,16.31-2.26,23.44-6.37c7.08-4.07,13.33-9.42,18.45-15.79c7.11-9.01,13.24-18.75,18.3-29.05
		c5.32-10.2,10.37-20.56,15.83-30.68C151.23,118.86,157.11,111.11,164.3,104.7z"
      />
      <path
        class="st8"
        d="M202.45,159.61v25.17c-0.29,0.07-0.59,0.11-0.89,0.13c-7.2,0-14.4,0.13-21.6,0
		c-5.93-0.15-11.61-2.4-16.04-6.34c-4.79-4.26-8.91-9.21-12.24-14.69c-1.39-2.15-2.74-4.31-4.12-6.55l-0.44,0.67
		c-4.54,8.34-9.1,16.68-13.6,25.06c-0.2,0.53-0.15,1.12,0.13,1.62c5.05,7.27,11.41,13.54,18.75,18.49
		c6.46,4.47,13.98,7.18,21.81,7.87c6.69,0.38,13.43,0.29,20.14,0.38c2.66,0,5.34,0,8.21,0v25.29l66.69-38.51l0.04,0.02
		L202.45,159.61z"
      />
      <polygon class="st8" points="14.27,100.6 14.27,100.41 14.17,100.41 	" />
      <path
        class="st8"
        d="M68.7,100.41c5.44-0.06,10.77,1.61,15.2,4.77c4.92,3.68,9.13,8.22,12.44,13.39c2.13,3.13,4.08,6.29,6.16,9.5
		l0.44-0.74c4.54-8.28,9.08-16.59,13.55-24.89c0.23-0.58,0.17-1.23-0.15-1.77c-3.86-5.43-8.42-10.34-13.55-14.59
		c-8.49-7.4-19.29-11.63-30.55-11.97C53.41,73.87,34.56,74,15.73,74h-1.46v26.41h18.71H68.7z"
      />
    </g>
  </svg>
</template>
