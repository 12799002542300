<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <path
      class="st8"
      d="M269.29,82.86V23.99h-58.87V41.2H106.37c-15.24,0-27.64,12.4-27.64,27.64v26.3
	c-4.87-1.62-10.08-2.51-15.49-2.51c-27.1,0-49.06,21.97-49.06,49.06c0,27.1,21.97,49.06,49.06,49.06c5.42,0,10.62-0.89,15.49-2.51
	v21.13c0,15.24,12.4,27.64,27.64,27.64h104.05v22.46h58.87v-58.87h-58.87v17.36h-47.54v-67.73h47.54v20.91h58.87v-58.87h-58.87
	v20.91h-47.54V58.5h47.54v24.36H269.29z M145.82,219.96h-39.45c-5.84,0-10.59-4.75-10.59-10.59V178.4
	c8.11-7.2,13.81-17.03,15.76-28.17h34.28V219.96z M111.54,133.17c-1.95-11.13-7.65-20.97-15.76-28.17V68.85
	c0-5.84,4.75-10.59,10.59-10.59h39.45v74.91H111.54z"
    />
  </svg>
</template>
