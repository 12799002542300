<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="13.695" height="10.309" viewBox="0 0 13.695 10.309">
    <g id="Group_48226" data-name="Group 48226" transform="translate(0 -8.846)">
      <g id="list_1_" data-name="list (1)" transform="translate(0 8.846)">
        <path
          id="Path_24358"
          data-name="Path 24358"
          d="M23.761,47.26H14.6a.683.683,0,1,1,0-1.367h9.158a.683.683,0,0,1,0,1.367Z"
          transform="translate(-10.749 -37.133)"
        />
        <path
          id="Path_24359"
          data-name="Path 24359"
          d="M23.761,28.429H14.6a.683.683,0,1,1,0-1.367h9.158a.683.683,0,0,1,0,1.367Z"
          transform="translate(-10.749 -22.591)"
        />
        <path
          id="Path_24360"
          data-name="Path 24360"
          d="M23.761,9.6H14.6a.683.683,0,0,1,0-1.367h9.158a.683.683,0,0,1,0,1.367Z"
          transform="translate(-10.749 -8.049)"
        />
        <ellipse id="Ellipse_87" data-name="Ellipse 87" cx="0.918" cy="0.918" rx="0.918" ry="0.918" transform="translate(0)" />
        <ellipse id="Ellipse_88" data-name="Ellipse 88" cx="0.918" cy="0.918" rx="0.918" ry="0.918" transform="translate(0 4.237)" />
        <ellipse id="Ellipse_89" data-name="Ellipse 89" cx="0.918" cy="0.918" rx="0.918" ry="0.918" transform="translate(0 8.473)" />
      </g>
    </g>
  </svg>
</template>
