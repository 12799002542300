<template>
  <svg id="Group_30079" data-name="Group 30079" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" width="27.129" height="22.307" viewBox="0 0 27.129 22.307">
    <g id="Group_28726" data-name="Group 28726" transform="translate(0 0)">
      <g id="Group_28726-2" data-name="Group 28726">
        <path id="Path_17242" data-name="Path 17242"
          d="M181.951,83.7h-.04a.386.386,0,0,0-.384.384v.7a3.116,3.116,0,0,0-2.09.859,2.987,2.987,0,0,0-.886,2.229,2.718,2.718,0,0,0,.374,1.423,2.676,2.676,0,0,0,.949.961,10.4,10.4,0,0,0,1.653.716v2.389c-.486-.052-.809-.3-1.133-1.043h-1.836a2.84,2.84,0,0,0,2.969,2.852v.81a.386.386,0,0,0,.384.384h.04a.385.385,0,0,0,.384-.384v-.846a3.468,3.468,0,0,0,1.982-.864,3.011,3.011,0,0,0,.983-2.318,2.811,2.811,0,0,0-.348-1.416,2.69,2.69,0,0,0-.912-.965,8.485,8.485,0,0,0-1.551-.717l-.155-.06V86.612a1.223,1.223,0,0,1,1,.665h1.836a3.2,3.2,0,0,0-2.833-2.462v-.728a.385.385,0,0,0-.384-.384m-.424,4.753a2.955,2.955,0,0,1-.461-.263.812.812,0,0,1-.34-.684.871.871,0,0,1,.288-.647.977.977,0,0,1,.513-.251Zm1.262,3.175a1.015,1.015,0,0,1-.108,1.449,1.228,1.228,0,0,1-.345.195V91.313a1.553,1.553,0,0,1,.453.318"
          transform="translate(-168.378 -78.933)" />
        <path id="Path_17243" data-name="Path 17243"
          d="M95.295,11.253,92.348,9.941l.724-1.6,2.352,1.039-.569-1.521a9.506,9.506,0,0,0-15.63-3.434H77.033a11.166,11.166,0,0,1,19.418,2.98l.578,1.609L98.16,6.456l1.613.712-2.24,5.073Z"
          transform="translate(-72.644)" />
        <path id="Path_17244" data-name="Path 17244"
          d="M13.286,204.079a11.153,11.153,0,0,1-10.054-6.321l-.622-1.291-1.015,2.148L0,197.861l2.414-5.107,5.014,2.37-.754,1.595L3.9,195.407l.892,1.773a9.529,9.529,0,0,0,16.454.985h1.923a11.178,11.178,0,0,1-9.883,5.914"
          transform="translate(0 -181.773)" />
      </g>
    </g>
  </svg>
</template>
