import { EChartsOption } from 'echarts';

interface RawData {
  category: string[];
  data: number[];
}

export default function (rawData: RawData) {
  const options: EChartsOption = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      left: 'center',
      bottom: '0%',
    },
    series: [
      {
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 2,
          borderRadius: 1,
          shadowBlur: 5,
          shadowColor: 'rgba(0, 0, 0, 0.1)',
        },
        center: ['50%', '40%'],
        name: 'Access From',
        type: 'pie',
        radius: ['30%', '50%'],
        avoidLabelOverlap: false,
        label: {
          formatter: '{b}\n{d}%',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold',
          },
        },
        data: rawData.category.map((_, index: number) => ({
          value: rawData.data[index],
          name: rawData.category[index],
        })),
      },
    ],
  };

  return options;
}
