<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <path
      class="st8"
      d="M269.28,138.05l-63.76-27.75l1.16,21.74l-24.88,1.32l-31.42-27.57c-12.85-11.53-29.75-17.5-46.99-16.57
	l-24.83,1.32C72.44,72.66,53,63.13,35.13,69.24c-17.87,6.11-27.41,25.55-21.3,43.42c6.11,17.87,25.55,27.41,43.42,21.3
	c11.23-3.84,19.66-13.22,22.29-24.79l17.12-0.91c17.24-0.92,34.13,5.05,46.97,16.59l18.38,16.58l0.17,3.16l-16.52,18.37
	c-11.54,12.83-27.71,20.55-44.95,21.46l-16.2,0.86c-3.58-18.55-21.52-30.69-40.07-27.12c-18.55,3.58-30.69,21.52-27.12,40.07
	c3.58,18.55,21.52,30.69,40.07,27.12c11.59-2.24,21.21-10.28,25.45-21.3l26.5-1.41c17.24-0.91,33.41-8.63,44.96-21.46l26.8-29.04
	l26.49-1.41l1.16,21.74L269.28,138.05z M47.12,118.72c-9.43,0.49-17.47-6.75-17.97-16.18c-0.49-9.43,6.75-17.47,16.18-17.97
	c9.42-0.49,17.46,6.73,17.96,16.15C63.78,110.15,56.55,118.2,47.12,118.72z M51.91,208.79c-9.43,0.49-17.47-6.75-17.97-16.18
	c-0.49-9.43,6.75-17.47,16.18-17.97c9.42-0.49,17.46,6.73,17.96,16.15C68.57,200.22,61.34,208.27,51.91,208.79z"
    />
  </svg>
</template>
