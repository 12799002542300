import { Ref, ref, onMounted, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import ability from '@/libs/acl/ability';

// constants
import {
  MKA_CAMPAIGN_HEADER as mkaCampaignHeader,
  STATUS_RENDER_CONFIG as statusRenderConfig,
  DROPDOWN_SELECT_STATUS as dropDownStatus,
  MKA_TABLE_CONFIG as tableConfig,
  FIRST_PAGE,
} from '@/constants/modules/mka/mka';

// modal
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
// libs
import router from '@/router';
import api from '@services/api';
// model
import mkaModel from '@models/mka/mka';
// icons
import Edit from '@/assets/icons/modules/mka/Edit.vue';
import Delete from '@/assets/icons/modules/mka/Delete.vue';
import Copy from '@/assets/icons/modules/mka/Copy.vue';

export default function useMkaMain() {
  const { t } = useI18n();
  const { fetchCampaignListModel, fetchMkaSummary, createMkaCampaign, deleteMkaCampaign, cloneMkaCampaign, fetchOutOfCreditJourneyStat } = mkaModel();
  const { openWarningModal } = useValidationModal();

  // table map
  const tableCampaignHeader = mkaCampaignHeader.map((item) => {
    item.label = t(item.label);
    return item;
  });

  const tableCampaignStatus = statusRenderConfig.map((item) => {
    item.label = t(item.label);
    return item;
  });

  const tableDropDownStatus = dropDownStatus.map((item) => {
    item.label = t(item.label);
    return item;
  });

  // Noti Paused
  const countPaused: Ref<number> = ref(0);
  const loadingPaused: Ref<boolean> = ref(false);

  // table page config
  const campaignPaginationConfig: any = ref(tableConfig);

  const actionList: ActionList.Action[] = [];

  const actionListActive: ActionList.Action[] = [];

  const campaignCreateLoad = ref<boolean>(false);
  const campaignSummaryData = ref<MKA.MKA_Summary_Stat.Item>();

  const campaignSorting: any = ref(null);
  const campaignStatus: any = ref({ label: t('mka.status_all'), id: 0 });
  const campaignList = ref([]);

  let campaignSearchDelayEvent: any = null;
  const campaignSearch = ref<string>('');

  const isNotiCreditModalOpen = ref<boolean>(false);
  const isNotiCreditSuccessOpen = ref<boolean>(false);
  const loadingSummaryStat = ref<boolean>(false);
  const loadingCampaignTable = ref<boolean>(false);
  const setCampaignStatusSelect = (status: { label: string; id: number }) => {
    campaignStatus.value = status;
    campaignSearch.value = '';
    onCampaignChangePage(FIRST_PAGE);
  };
  const onCloseNotiCreditModal = () => {
    isNotiCreditModalOpen.value = false;
  };
  const onOpenNotiCreditModal = () => {
    isNotiCreditModalOpen.value = true;
  };
  const onCloseNotiCreditSuccess = () => {
    isNotiCreditSuccessOpen.value = false;
  };
  const onOpenNotiCreditSuccess = () => {
    isNotiCreditSuccessOpen.value = true;
  };
  const onCampaignSort = (sortedColumn: { key: string; direction: string }) => {
    campaignSorting.value = sortedColumn;
    fetchMkaCampaignList();
  };

  const setCampaignSearch = (value: any) => {
    campaignSearch.value = value;
    if (campaignSearchDelayEvent != null) {
      clearTimeout(campaignSearchDelayEvent as number);
      campaignSearchDelayEvent = null;
    }
    campaignSearchDelayEvent = setTimeout(() => {
      onCampaignChangePage(FIRST_PAGE);
    }, 300);
  };

  const onCampaignChangePage = (pageNumber: number) => {
    const pagingConfig = campaignPaginationConfig.value;
    pagingConfig.pageNumber = pageNumber;
    campaignPaginationConfig.value = pagingConfig;
    fetchMkaCampaignList();
  };

  async function deleteCampaign(campaignId: any): Promise<void> {
    loadingCampaignTable.value = true;
    deleteMkaCampaign.payload.JourneyId = campaignId;
    return api
      .apiRequest(deleteMkaCampaign)
      .then(() => {
        loadingCampaignTable.value = false;
        fetchMkaSummaryRequest();
        fetchMkaCampaignList();
        Promise.resolve();
      })
      .catch((err) => {
        Promise.reject(err);
      });
  }

  const modalConfirmDeleteCampaign = (campaignId: number) => {
    openWarningModal(
      'ลบข้อมูล Campaign',
      'ต้องการลบ Campaign นี้หรือไม่',
      () => {
        deleteCampaign(campaignId);
      },
      () => {},
    );
  };
  const onCampaignEdit = (campaignData: number) => {
    router.push({ path: '/mka/detail/' + campaignData });
  };
  const onCampaignAction = async (actionEvent: any, campaignData: any) => {
    switch (actionEvent.key) {
      case 'detail':
      case 'edit':
        router.push({ path: '/mka/detail/' + campaignData.id });
        break;

      case 'delete':
        modalConfirmDeleteCampaign(campaignData.id as number);
        break;

      case 'clone':
        await cloneMkaCampaignRequest(campaignData.id as number);
    }
  };

  const resolveDate = (dateInput: any) => {
    if (dateInput) {
      const date = new Date(dateInput as Date);
      let dateStr = '';

      try {
        dateStr = `${date.getFullYear()}-${date.toLocaleString('default', {
          month: '2-digit',
        })}-${date.toLocaleString('default', {
          day: '2-digit',
        })}`;
      } catch {
        return '';
      }

      return dateStr;
    }

    return '';
  };

  const buildCampaignListData = (campaignList: any) => {
    return campaignList.map((campaign: Record<any, any>) => {
      return {
        id: campaign.journeyid,
        name: campaign.name,
        start_dt: resolveDate(campaign.start_dt),
        end_dt: resolveDate(campaign.end_dt),
        last_update: resolveDate(campaign.updated_dt),
        update_by: campaign.update_by,
        status: tableCampaignStatus.find((item) => item.id == campaign.status)?.label,
        status_id: campaign.status,
        _Action_: campaign.journeyid,
      };
    });
  };

  async function fetchMkaCampaignList(): Promise<void> {
    loadingCampaignTable.value = true;
    fetchCampaignListModel.payload.Query = campaignSearch.value;
    fetchCampaignListModel.payload.Status = campaignStatus.value.id;
    fetchCampaignListModel.payload.Filters = [];
    fetchCampaignListModel.payload.Ordering = [];
    fetchCampaignListModel.payload.Page = campaignPaginationConfig.value.pageNumber;
    fetchCampaignListModel.payload.Limit = campaignPaginationConfig.value.rowsPerPage;
    if (campaignSorting.value != null) {
      fetchCampaignListModel.payload.Ordering.push(campaignSorting.value);
    }

    return api
      .apiRequest(fetchCampaignListModel)
      .then((response) => {
        const campaignReturn = response.data;

        campaignList.value = buildCampaignListData(campaignReturn.records);

        const pagingConfig = campaignPaginationConfig.value;
        pagingConfig.totalPages = campaignReturn.total_page;
        pagingConfig.totalRows = campaignReturn.filtered_records;

        campaignPaginationConfig.value = pagingConfig;
        loadingCampaignTable.value = false;

        Promise.resolve();
      })
      .catch((err) => {
        Promise.reject();
      })
      .finally(() => {});
  }

  async function fetchMkaSummaryRequest(): Promise<void> {
    loadingSummaryStat.value = true;
    return api
      .apiRequest(fetchMkaSummary)
      .then((response) => {
        const summaryResult = response.data;

        campaignSummaryData.value = {
          query_dt: summaryResult.query_dt,
          status_all: summaryResult.status_all.currencyString('th-TH', 0),
          status_archived: summaryResult.status_archived.currencyString('th-TH', 0),
          status_draft: summaryResult.status_draft.currencyString('th-TH', 0),
          status_pause: summaryResult.status_pause.currencyString('th-TH', 0),
          status_publish: summaryResult.status_publish.currencyString('th-TH', 0),
        };
        Promise.resolve();
      })
      .catch((err) => {
        Promise.reject();
      })
      .finally(() => {
        loadingSummaryStat.value = false;
      });
  }

  async function createMkaCampaignRequest(): Promise<void> {
    createMkaCampaign.payload.Name = null;
    createMkaCampaign.payload.Description = null;
    createMkaCampaign.payload.Start_DT = null;
    createMkaCampaign.payload.End_DT = null;
    if (campaignCreateLoad.value == false) {
      campaignCreateLoad.value = true;
      return api
        .apiRequest(createMkaCampaign)
        .then((response) => {
          const campaignResult = response.data;
          campaignCreateLoad.value = false;
          localStorage.setItem('journeyid', campaignResult.journeyid); // for show notify when first create
          router.push({ path: '/mka/detail/' + campaignResult.journeyid });
          Promise.resolve();
        })
        .catch((err) => {
          Promise.reject();
        })
        .finally(() => {});
    }
  }

  async function cloneMkaCampaignRequest(campaignId: number): Promise<void> {
    loadingCampaignTable.value = true;
    cloneMkaCampaign.payload.JourneyId = campaignId;
    return api
      .apiRequest(cloneMkaCampaign)
      .then(() => {
        Promise.resolve();
      })
      .catch((err) => {
        Promise.reject(err);
      })
      .finally(() => {
        fetchMkaSummaryRequest();
        fetchMkaCampaignList();
        loadingCampaignTable.value = false;
      });
  }

  function pushResume() {
    router.push({
      path: '/communication/resume-campaign',
      query: { back: '/mka', activetab: '/mka' },
    });
  }

  const fetchNotiJourneyPaused = () => {
    loadingPaused.value = true;
    countPaused.value = 0;
    return api
      .apiRequest(fetchOutOfCreditJourneyStat)
      .then((response) => {
        const data = response.data;
        countPaused.value = data.total_journey || 0;
      })
      .catch((err) => {})
      .finally(() => {
        loadingPaused.value = false;
      });
  };

  function checkPermissionMka() {
    if (ability.can('portal-cdp', 'cdp-portal-mka-create')) {
      actionList.push({ key: 'edit', tooltip: t('mka.edit'), icon: Edit, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' });
    }
    if (ability.can('portal-cdp', 'cdp-portal-mka-copy')) {
      actionList.push({ key: 'clone', tooltip: t('mka.clone'), icon: Copy, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' });
    }
    if (ability.can('portal-cdp', 'cdp-portal-mka-delete')) {
      actionList.push({ key: 'delete', tooltip: t('mka.delete'), icon: Delete, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' });
    }
    // journey status published
    actionListActive.push({ key: 'detail', tooltip: t('mka.detail'), icon: Edit, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' });
    if (ability.can('portal-cdp', 'cdp-portal-mka-copy')) {
      actionListActive.push({ key: 'clone', tooltip: t('mka.clone'), icon: Copy, tooltipColor: '#007FFF', tooltipBackgroundColor: '#BBDBFF' });
    }
  }

  onBeforeMount(() => {
    checkPermissionMka();
  });

  onMounted(async () => {
    // await fetchMkaSummaryRequest();
    // await fetchMkaCampaignList();

    // Parallel fetch campaign summary
    await Promise.all([fetchMkaSummaryRequest(), fetchMkaCampaignList(), fetchNotiJourneyPaused()]);
  });

  return {
    campaignStatus,
    setCampaignStatusSelect,
    dropDownStatus: tableDropDownStatus,
    mkaCampaignHeader: tableCampaignHeader,
    campaignList,
    campaignPaginationConfig,
    setCampaignSearch,
    campaignSearch,
    onCampaignChangePage,
    onCampaignSort,
    actionList,
    actionListActive,
    onCampaignAction,
    onCampaignEdit,
    loadingCampaignTable,
    campaignSummaryData,
    campaignCreateLoad,
    createMkaCampaignRequest,
    loadingSummaryStat,
    isNotiCreditModalOpen,
    onCloseNotiCreditModal,
    onOpenNotiCreditModal,
    loadingPaused,
    countPaused,
    pushResume,
    isNotiCreditSuccessOpen,
    onOpenNotiCreditSuccess,
    onCloseNotiCreditSuccess,
  };
}
