import { ref, onMounted } from 'vue';
import api from '@services/api';
import dataConnectModel from '@models/dataConnect/dataConnect';
import router from '@/router';

export default function useSettingFasthelp5Page() {
  const { fetchGetFH5integrationSetting } = dataConnectModel();

  const dataFh5Setting = ref({
    fastHelp5: { connected_tenant: 'no information', api_key: 'no information', url_login: '' },
    choco: { api_key: 'no information', api_secret: 'no information' },
  });

  const dataTitleInput = ref({
    fastHelp5: { col1: 'Connected Tenant :', col2: 'FastHelp5 API Key :' },
    choco: { col1: 'API Key :', col2: 'API Secret :' },
  });

  const fetchFH5Setting = () => {
    fetchGetFH5integrationSetting.payload.brand_ref = router.currentRoute.value.query.brandRef;
    api.apiRequest(fetchGetFH5integrationSetting).then((response) => {
      dataFh5Setting.value = {
        fastHelp5: {
          connected_tenant: response.data.payload.fasthelp5.conneted_tenant,
          api_key: response.data.payload.fasthelp5.api_key,
          url_login: response.data.payload.fasthelp5.url_login,
        },
        choco: { api_key: response.data.payload.choco.api_key, api_secret: response.data.payload.choco.api_secret },
      };
    });
  };
  function buttonStepGoTo(value: string) {
    window.open(value, '_blank');
  }
  onMounted(() => {
    fetchFH5Setting();
  });
  return {
    buttonStepGoTo,
    dataTitleInput,
    dataFh5Setting,
  };
}
