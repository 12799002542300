<template>
  <svg width="156" height="104" viewBox="0 0 156 104" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.59766 14.265V0L6.36866 0L6.36866 14.265H8.59766Z" fill="black" />
    <path d="M14.6166 6.01758H0.351562V8.24658H14.6166V6.01758Z" fill="black" />
    <path d="M155.435 97.7646L145.348 87.6777L143.772 89.2539L153.858 99.3408L155.435 97.7646Z" fill="black" />
    <path d="M153.86 87.6787L143.773 97.7656L145.35 99.3418L155.436 89.2549L153.86 87.6787Z" fill="black" />
    <path
      d="M3.72756 82.42C5.59208 82.42 7.10356 80.9085 7.10356 79.044C7.10356 77.1795 5.59208 75.668 3.72756 75.668C1.86305 75.668 0.351562 77.1795 0.351562 79.044C0.351562 80.9085 1.86305 82.42 3.72756 82.42Z"
      fill="black"
    />
    <path
      d="M150.241 29.7532C153.224 29.7532 155.642 27.3351 155.642 24.3522C155.642 21.3693 153.224 18.9512 150.241 18.9512C147.258 18.9512 144.84 21.3693 144.84 24.3522C144.84 27.3351 147.258 29.7532 150.241 29.7532Z"
      fill="black"
    />
    <path
      d="M153.62 36.5071C154.739 36.5071 155.646 35.6 155.646 34.4811C155.646 33.3621 154.739 32.4551 153.62 32.4551C152.501 32.4551 151.594 33.3621 151.594 34.4811C151.594 35.6 152.501 36.5071 153.62 36.5071Z"
      fill="black"
    />
    <path d="M135.56 32.2598H19.5859V35.9428H135.56V32.2598Z" fill="black" />
    <path d="M135.56 76.8223H19.5859V80.1573H135.56V76.8223Z" fill="black" />
    <path
      d="M109.99 85.3594H45.1571C44.4015 85.3594 43.7891 85.9718 43.7891 86.7274C43.7891 87.4829 44.4015 88.0954 45.1571 88.0954H109.99C110.746 88.0954 111.358 87.4829 111.358 86.7274C111.358 85.9718 110.746 85.3594 109.99 85.3594Z"
      fill="#007FFF"
    />
    <path
      d="M50.1836 89.0356V84.4176C50.1836 83.667 49.5751 83.0586 48.8246 83.0586C48.074 83.0586 47.4656 83.667 47.4656 84.4176V89.0356C47.4656 89.7861 48.074 90.3946 48.8246 90.3946C49.5751 90.3946 50.1836 89.7861 50.1836 89.0356Z"
      fill="black"
    />
    <path
      d="M36.374 27.9238C38.5826 27.9238 40.373 26.1334 40.373 23.9248C40.373 21.7162 38.5826 19.9258 36.374 19.9258C34.1654 19.9258 32.375 21.7162 32.375 23.9248C32.375 26.1334 34.1654 27.9238 36.374 27.9238Z"
      fill="black"
    />
    <path
      d="M48.7724 27.9238C50.981 27.9238 52.7714 26.1334 52.7714 23.9248C52.7714 21.7162 50.981 19.9258 48.7724 19.9258C46.5639 19.9258 44.7734 21.7162 44.7734 23.9248C44.7734 26.1334 46.5639 27.9238 48.7724 27.9238Z"
      fill="black"
    />
    <path
      d="M61.1748 27.9238C63.3834 27.9238 65.1738 26.1334 65.1738 23.9248C65.1738 21.7162 63.3834 19.9258 61.1748 19.9258C58.9662 19.9258 57.1758 21.7162 57.1758 23.9248C57.1758 26.1334 58.9662 27.9238 61.1748 27.9238Z"
      fill="black"
    />
    <path
      d="M72.5195 61.2488V50.2488C72.5189 50.0875 72.5623 49.9289 72.645 49.7903C72.7277 49.6518 72.8465 49.5384 72.9888 49.4623C73.1311 49.3862 73.2915 49.3504 73.4526 49.3586C73.6138 49.3668 73.7695 49.4187 73.9033 49.5088L82.0327 55.0088C82.1536 55.0908 82.2524 55.201 82.3208 55.33C82.3892 55.459 82.4248 55.6028 82.4248 55.7488C82.4248 55.8948 82.3892 56.0387 82.3208 56.1677C82.2524 56.2966 82.1536 56.4069 82.0327 56.4888L73.9033 61.9888C73.7695 62.079 73.6138 62.1309 73.4526 62.1391C73.2915 62.1473 73.1311 62.1114 72.9888 62.0353C72.8465 61.9592 72.7277 61.8458 72.645 61.7072C72.5623 61.5686 72.5189 61.4102 72.5195 61.2488Z"
      fill="#007FFF"
    />
    <path
      d="M76.4521 73.9221C72.8583 73.9221 69.3451 72.8564 66.3569 70.8598C63.3687 68.8631 61.0399 66.0252 59.6646 62.7049C58.2892 59.3846 57.9292 55.7309 58.6304 52.2061C59.3315 48.6813 61.0623 45.4435 63.6035 42.9023C66.1448 40.361 69.3824 38.6304 72.9072 37.9292C76.4321 37.2281 80.0854 37.588 83.4058 38.9633C86.7261 40.3386 89.5639 42.6676 91.5605 45.6558C93.5572 48.644 94.623 52.1572 94.623 55.7511C94.6178 60.5688 92.7015 65.1876 89.2949 68.5941C85.8883 72.0007 81.2698 73.9169 76.4521 73.9221ZM76.4521 42.2471C73.7821 42.2471 71.1722 43.0388 68.9521 44.5222C66.7321 46.0056 65.0018 48.114 63.98 50.5808C62.9582 53.0476 62.6905 55.762 63.2114 58.3808C63.7323 60.9995 65.0182 63.405 66.9062 65.293C68.7943 67.181 71.1996 68.4668 73.8184 68.9877C76.4371 69.5086 79.1514 69.2413 81.6182 68.2195C84.085 67.1977 86.1934 65.4674 87.6768 63.2473C89.1602 61.0273 89.9521 58.4172 89.9521 55.7471C89.9482 52.1675 88.5249 48.7356 85.9941 46.2041C83.4634 43.6726 80.0317 42.2481 76.4521 42.2431V42.2471Z"
      fill="#007FFF"
    />
    <path
      d="M130.296 7.13672H24.8442C23.8844 7.13659 22.9337 7.32561 22.0469 7.69287C21.16 8.06014 20.3545 8.59849 19.6758 9.27722C18.9971 9.95595 18.4586 10.7617 18.0913 11.6486C17.724 12.5354 17.535 13.4858 17.5352 14.4457V96.2908C17.535 97.2506 17.724 98.2011 18.0913 99.0879C18.4586 99.9747 18.9971 100.781 19.6758 101.459C20.3545 102.138 21.16 102.676 22.0469 103.044C22.9337 103.411 23.8844 103.6 24.8442 103.6H130.296C131.256 103.6 132.207 103.411 133.094 103.044C133.981 102.677 134.786 102.138 135.465 101.46C136.144 100.781 136.682 99.975 137.05 99.0881C137.417 98.2012 137.606 97.2507 137.606 96.2908V14.4368C137.604 12.4997 136.833 10.6427 135.462 9.27393C134.091 7.90514 132.233 7.13645 130.296 7.13672ZM127.749 93.7438H27.3911V16.9927H127.749V93.7438Z"
      fill="black"
    />
  </svg>
</template>
