<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40.608" height="40.608" viewBox="0 0 40.608 40.608">
    <g transform="translate(0 0)">
      <path
        d="M20.3,40.608A20.3,20.3,0,0,1,5.947,5.947,20.3,20.3,0,1,1,34.662,34.661,20.172,20.172,0,0,1,20.3,40.608Zm0-36.547A16.243,16.243,0,0,0,8.819,31.789,16.243,16.243,0,0,0,31.79,8.818,16.136,16.136,0,0,0,20.3,4.06Z"
        fill="#75a3dd"
      />
      <g transform="matrix(0.839, 0.545, -0.545, 0.839, 18.575, 9.04)">
        <path d="M0,13.094H5.035L2.518,16.116Zm0,0V0H5.035V13.094Z" transform="translate(0 3.789)" fill="#75a3dd" />
        <g transform="translate(0 0)"><path d="M0,2.526V1A1,1,0,0,1,1,0H4.034a1,1,0,0,1,1,1V2.526Z" fill="#75a3dd" /></g>
        <ellipse cx="1.828" cy="1.828" rx="1.828" ry="1.828" transform="translate(6.482 12.589)" fill="#75a3dd" />
        <ellipse cx="1.828" cy="1.828" rx="1.828" ry="1.828" transform="translate(10.723 9.835)" fill="#75a3dd" />
        <ellipse cx="1.828" cy="1.828" rx="1.828" ry="1.828" transform="translate(14.965 7.08)" fill="#75a3dd" />
      </g>
    </g>
  </svg>
</template>
