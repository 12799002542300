<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 172.16 64.08">
    <defs></defs>
    <g id="Group_45702">
      <g id="Rectangle_17037">
        <rect class="cls-1" width="78.87" height="64.08" rx="5.31" ry="5.31" />
        <rect class="cls-2" x=".66" y=".66" width="77.55" height="62.76" rx="4.65" ry="4.65" />
      </g>
      <g id="Rectangle_17038">
        <rect class="cls-1" x="93.66" y="0" width="78.5" height="64.08" rx="5.31" ry="5.31" />
        <rect class="cls-2" x="94.33" y=".66" width="77.17" height="62.76" rx="4.65" ry="4.65" />
      </g>
    </g>
  </svg>
</template>
