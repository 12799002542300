import { onMounted, ref, Ref, watch, reactive } from 'vue';

import { useI18n } from 'vue-i18n';

export default function variable() {
  const { t } = useI18n();

  enum Module {
    All = '*',
    Commu = 'COMM',
    Mka = 'MKA',
  }
  enum Channel {
    allChannel = 0,
    SMS = 1,
    Email = 2,
    Line = 3,
  }
  enum ChannelDes {
    allChannel = 'all-channel',
    SMS = 'sms',
    Email = 'email',
    Line = 'line-oa',
  }
  enum Schedule {
    Send_now = 1,
    Schedule_later = 2,
    Repeat = 3,
  }

  const dropDownPagination = ref([5, 10, 15, 20]);

  const column_main = ref<Array<SelectExpandTable.TableColumn>>([
    { key: 'select', label: '', selectCustom: true },
    {
      key: 'no',
      label: t('communication.No'),
      // sortable: true,
    },
    {
      key: 'name',
      label: t('communication.CampaignName'),
      sortable: true,
    },
    {
      key: 'module',
      label: t('communication.module'),
    },
    {
      key: 'channel',
      label: t('communication.channel'),
    },
    {
      key: 'schedule_mode',
      label: t('communication.schedule_mode'),
    },
    {
      key: 'accum_recp_dispatchable',
      label: t('communication.count_dispatchable'),
      sortable: true,
    },
    {
      key: 'accum_recp_success',
      label: t('communication.count_success'),
      sortable: true,
    },
    {
      key: 'accum_recp_pendingcredit',
      label: t('communication.count_pendingcredit'),
      sortable: true,
    },
    {
      key: 'est_credit_use',
      label: t('communication.est_credit_use'),
    },
    {
      key: 'updated_dt',
      label: t('communication.updated'),
      sortable: true,
    },
    {
      key: 'status',
      label: t('communication.status'),
    },
  ]);

  const column_expand = ref<Array<SelectExpandTable.TableColumn>>([
    {
      key: 'id',
      label: t('communication.No'),
      // sortable: true,
    },
    {
      key: 'name',
      label: t('communication.CampaignNameExpand'),
      sortable: true,
    },
    {
      key: 'channel',
      label: t('communication.channel'),
    },
    {
      key: 'accum_recp_dispatchable',
      label: t('communication.count_dispatchable'),
      sortable: true,
    },
    {
      key: 'accum_recp_success',
      label: t('communication.count_success'),
      sortable: true,
    },
    {
      key: 'accum_recp_pendingcredit',
      label: t('communication.count_pendingcredit'),
      sortable: true,
    },
    {
      key: 'est_credit_use',
      label: t('communication.est_credit_use'),
    },
    {
      key: 'updated_dt',
      label: t('communication.updated'),
      sortable: true,
    },
  ]);

  const mockDataTable: SelectExpandTable.dataTable[] = [
    {
      id: 1,
      expand: true,
      checkbox: false,
      isSelectAll: false,
      select: false,
      campaign_name: 'SMS - Campaign - 23/11/2023 แบบ Repeat',
      channel: 'SMS',
      schedule_mode: 3,
      schedule_mode_desc: 'ส่งซ้ำ',
      count_customer: 3000,
      success_sent: 2000,
      wait_send: 1000,
      credit: 1000,
      update_last: '23/11/23 11:23',
      status: 'หยุดขั่วคราว',
    },
    {
      id: 2,
      expand: true,
      checkbox: false,
      isSelectAll: false,
      select: false,
      campaign_name: 'SMS - Campaign - 23/11/2023 แบบ Repeat',
      channel: 'SMS',
      schedule_mode: 3,
      schedule_mode_desc: 'ส่งซ้ำ',
      count_customer: 3000,
      success_sent: 2000,
      wait_send: 1000,
      credit: 1000,
      update_last: '23/11/23 11:23',
      status: 'หยุดขั่วคราว',
    },
    {
      id: 3,
      expand: false,
      checkbox: false,
      isSelectAll: false,
      select: false,
      campaign_name: 'SMS - Campaign - 23/11/2023 แบบ Send Now',
      channel: 'SMS',
      schedule_mode: 1,
      schedule_mode_desc: 'ส่งทันที',
      count_customer: 3000,
      success_sent: 2000,
      wait_send: 1000,
      credit: 1000,
      update_last: '23/11/23 11:23',
      status: 'หยุดขั่วคราว',
    },
  ];

  const mockDataTableExpand: SelectExpandTable.dataExpand[] = [
    {
      expand_id: 1,
      parent_id: 3005,
      checkbox: false,
      campaign_name: 'SMS - Campaign - 23/11/2023 แบบ Repeat',
      channel: 'SMS',
      schedule_mode: 3,
      schedule_mode_desc: 'ส่งซ้ำ',
      count_customer: 3000,
      success_sent: 2000,
      wait_send: 1000,
      credit: 1000,
      update_last: '23/11/23 11:23',
      status: 'หยุดขั่วคราว',
    },
    {
      expand_id: 2,
      parent_id: 3005,
      checkbox: false,
      campaign_name: 'SMS - Campaign - 23/11/2023 แบบ Repeat',
      channel: 'SMS',
      schedule_mode: 3,
      schedule_mode_desc: 'ส่งซ้ำ',
      count_customer: 3000,
      success_sent: 2000,
      wait_send: 1000,
      credit: 1000,
      update_last: '23/11/23 11:23',
      status: 'หยุดขั่วคราว',
    },
    {
      expand_id: 3,
      parent_id: 3008,
      checkbox: false,
      campaign_name: 'SMS - Campaign - 23/11/2023 แบบ Repeat',
      channel: 'SMS',
      schedule_mode: 3,
      schedule_mode_desc: 'ส่งซ้ำ',
      count_customer: 3000,
      success_sent: 2000,
      wait_send: 1000,
      credit: 1000,
      update_last: '23/11/23 11:23',
      status: 'หยุดขั่วคราว',
    },
    {
      expand_id: 4,
      parent_id: 3008,
      checkbox: false,
      campaign_name: 'SMS - Campaign - 23/11/2023 แบบ Repeat',
      channel: 'SMS',
      schedule_mode: 3,
      schedule_mode_desc: 'ส่งซ้ำ',
      count_customer: 3000,
      success_sent: 2000,
      wait_send: 1000,
      credit: 1000,
      update_last: '23/11/23 11:23',
      status: 'หยุดขั่วคราว',
    },
    {
      expand_id: 5,
      parent_id: 3008,
      checkbox: false,
      campaign_name: 'SMS - Campaign - 23/11/2023 แบบ Repeat',
      channel: 'SMS',
      schedule_mode: 3,
      schedule_mode_desc: 'ส่งซ้ำ',
      count_customer: 3000,
      success_sent: 2000,
      wait_send: 1000,
      credit: 1000,
      update_last: '23/11/23 11:23',
      status: 'หยุดขั่วคราว',
    },
  ];

  return {
    Module,
    Channel,
    ChannelDes,
    Schedule,
    dropDownPagination,
    column_main,
    column_expand,
    mockDataTableExpand,
  };
}
