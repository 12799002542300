<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 195.868 194.414">
    <g transform="translate(0 0)">
      <path
        d="M32.835,43.295A27.279,27.279,0,1,0,60.114,70.574,27.31,27.31,0,0,0,32.835,43.295m0,42.833a15.554,15.554,0,0,1,.008-31.109l.008-.783v.783a15.554,15.554,0,0,1-.016,31.109"
        transform="translate(37.963 18.265)"
      />
      <path
        d="M165.69,95.773a30.172,30.172,0,0,0,24.4-47.908A30.172,30.172,0,0,0,138.2,78.041l-14.411,10.08a70.489,70.489,0,1,0-102.1,96.991,73.4,73.4,0,0,0,6.884,5.811,70.741,70.741,0,0,0,90.568-5.067l13.627,11.106a23.072,23.072,0,0,0,10.432,30.4,23.022,23.022,0,0,0,30.959-10.142,23.039,23.039,0,0,0-33.536-29.448l-13.486-11a70.232,70.232,0,0,0,3.8-78.876L145.3,87.831a30.029,30.029,0,0,0,20.394,7.942m-17.9-32.957a18.124,18.124,0,0,1,17.872-15.288,18.108,18.108,0,0,1,10.659,32.746,17.938,17.938,0,0,1-10.636,3.47,18.167,18.167,0,0,1-14.653-7.456,17.963,17.963,0,0,1-3.242-13.471m-3.986,135.415.587.525a12.208,12.208,0,1,1-3.07,8.85,12.242,12.242,0,0,1,3.07-8.85Zm-39.073-15.75a58.357,58.357,0,0,1-67.863,0l-.721-.525.572-.7a43.978,43.978,0,0,1,61.864-6.3,44.524,44.524,0,0,1,6.313,6.313l.556.689Zm10.119-9.07-.642.728-.627-.744a55.691,55.691,0,0,0-85.563,0l-.627.744-.634-.728a58.8,58.8,0,1,1,88.093,0"
        transform="translate(0 -35.435)"
      />
    </g>
  </svg>
</template>
