import { ref, Ref, reactive, Directive, onMounted, getCurrentInstance, watch, nextTick } from 'vue';
import { addSocialUI } from '../../../../constants/constant-edm';
import { PATH } from '@/constants/modules/file-service/path';
import apiService from '@/services/api';
import fileServiceModel from '@/models/file-service/file-service';
import useLoading from '@/views/components/loading/hooks/useLoading';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import Sortable from 'sortablejs';

interface Props {
  selectedElement?: Communication.EdmEditor.EdmElement;
  templateMode?: string;
  tagMp?: number;
}

interface Social {
  key: number;
  label: string;
  link: string;
  replaceText: string;
  selectedSocial: Record<string, any>;
  iconUrl: string | null;
}

interface SocialPayload {
  Text: string;
  Url: string;
  Alt: string;
  Link: string;
  Key: number;
  Value: Campaign.TrackingMapper | null;
}

export default function useSocialTabPanel(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const { uploadFileModel } = fileServiceModel();
  const { openErrorModal, openDefaultErrorModal } = useValidationModal();
  const { isLoading } = useLoading();
  //choose social
  const uploadCustomIcon: any = reactive({ file: null, url: null, sref: null });
  const selectedSocial: Ref<Record<string, any>> = ref(null!);
  const socialList: Ref<Social[]> = ref([]);
  const socialIndex: Ref<number> = ref(0);
  const editMode: Ref<boolean> = ref(false);
  const isEdit: Ref<boolean> = ref(false);
  const keyChange: Ref<number> = ref(1);
  const link: Ref<string> = ref('');
  const replaceText: Ref<string> = ref('');
  ///
  const disabledPadding: Ref<boolean> = ref(true);
  const showDisplay: Ref<string> = ref('icon-and-text');
  const textPosition: Ref<string> = ref('Down');
  const fontFamily: Ref<string> = ref('Arial');
  const fontSize: Ref<number> = ref(14);
  const fontStyle: Ref<any> = ref([]);
  const textDecoration: Ref<string> = ref('none');
  const fontWeight: Ref<string> = ref('normal');
  const fontItalic: Ref<string> = ref('normal');
  const fontColor: Ref<string> = ref('');
  const isColorPickerOpen: Ref<boolean> = ref(false);
  const iconStyle: Ref<number> = ref(5);
  const alignMent: Ref<string> = ref('center');
  const justifyMent: Ref<string> = ref('middle');
  const scale: Ref<number> = ref(25);
  const margin: Ref<number> = ref(5);
  const spacingTop: Ref<number> = ref(10);
  const spacingBottom: Ref<number> = ref(10);
  const indentLeft: Ref<number> = ref(10);
  const indentRight: Ref<number> = ref(10);

  const resolveUrlSocialIcon = (type: string, iconUrl: string) => {
    const storageUrl = process.env.NODE_ENV == 'development' ? 'https://devcdpdata.blob.core.windows.net' : 'https://cdpdatav2.blob.core.windows.net';
    switch (type) {
      case 'web-site':
        if (iconStyle.value == 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-1-112.png`;
        } else if (iconStyle.value > 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-${iconStyle.value - 2}2.png`;
        } else {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value}-${iconStyle.value - 1}2.png`;
        }
      case 'facebook':
        if (iconStyle.value == 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-1-113.png`;
        } else if (iconStyle.value > 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-${iconStyle.value - 2}3.png`;
        } else {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value}-${iconStyle.value - 1}3.png`;
        }
      case 'instagram':
        if (iconStyle.value == 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-1-114.png`;
        } else if (iconStyle.value > 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-${iconStyle.value - 2}4.png`;
        } else {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value}-${iconStyle.value - 1}4.png`;
        }
      case 'tik-tok':
        if (iconStyle.value == 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-1-115.png`;
        } else if (iconStyle.value > 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-${iconStyle.value - 2}5.png`;
        } else {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value}-${iconStyle.value - 1}5.png`;
        }
      case 'message':
        if (iconStyle.value == 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-1-116.png`;
        } else if (iconStyle.value > 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-${iconStyle.value - 2}6.png`;
        } else {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value}-${iconStyle.value - 1}6.png`;
        }
      case 'twitter':
        if (iconStyle.value == 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-1-117.png`;
        } else if (iconStyle.value > 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-${iconStyle.value - 2}7.png`;
        } else {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value}-${iconStyle.value - 1}7.png`;
        }
      case 'youtube':
        if (iconStyle.value == 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-1-118.png`;
        } else if (iconStyle.value > 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-${iconStyle.value - 2}8.png`;
        } else {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value}-${iconStyle.value - 1}8.png`;
        }
      case 'linked-in':
        if (iconStyle.value == 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-1-119.png`;
        } else if (iconStyle.value > 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-${iconStyle.value - 2}9.png`;
        } else {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value}-${iconStyle.value - 1}9.png`;
        }
      case 'google-plus':
        if (iconStyle.value == 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-1-120.png`;
        } else if (iconStyle.value > 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-${iconStyle.value - 1}0.png`;
        } else {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value}-${iconStyle.value}0.png`;
        }
      case 'email':
        if (iconStyle.value == 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-1-121.png`;
        } else if (iconStyle.value > 6) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value - 1}-${iconStyle.value - 1}1.png`;
        } else {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value}-${iconStyle.value}1.png`;
        }
      case 'line':
        if (iconStyle.value == 9) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value}-130.png`;
        } else if (iconStyle.value == 10) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value}-131.png`;
        } else if (iconStyle.value == 11) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value}-132.png`;
        } else if (iconStyle.value == 12) {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value}-133.png`;
        } else {
          return `${storageUrl}/0-public/Commu/EDM/Social/แบบ${iconStyle.value}-12${iconStyle.value + 1}.png`;
        }
      case 'custom':
        return iconUrl;
    }
  };

  const resolveDisplayType = () => {
    if (showDisplay.value == 'icon-only') {
      return '';
    } else if (showDisplay.value == 'text-only') {
      return 'TextOnly';
    } else {
      return textPosition.value;
    }
  };

  const resolveTextStyle = (text: string) => {
    let styleText = '';
    styleText = `<span style='font-family:${fontFamily.value};font-weight:${fontWeight.value};font-style:${fontItalic.value};color:${fontColor.value};'>${text}</span>`;
    return styleText;
  };

  const buildSocialObjProperties = () => {
    const currentNodeSelected = EDMGetObjList('cdp-edm-editor')[props.selectedElement?.id as string];
    let socialListPayload: any = [];
    if (socialList.value.length > 0) {
      currentNodeSelected.Properties.HTMLUI = '';

      //td prop
      currentNodeSelected.Properties.Style = {
        'vertical-align': justifyMent.value,
        'text-align': alignMent.value,
        'padding-top': `${spacingTop.value}px`,
        'padding-bottom': `${spacingBottom.value}px`,
        'padding-left': `${indentLeft.value}px`,
        'padding-right': `${indentRight.value}px`,
      };
      //blockdata prop
      socialListPayload = socialList.value.map((social: Social, index: number) => ({
        Text: social.replaceText !== '' ? resolveTextStyle(social.replaceText) : resolveTextStyle(social.label),
        Url: resolveUrlSocialIcon(social.selectedSocial.key as string, social.iconUrl as string),
        Alt: 'image',
        Link: social.link,
        Key: social.key,
        Value: setMapperValue(social.link, currentNodeSelected.Properties.Content.Properties.SocialList as SocialPayload[], social.key),
      }));

      currentNodeSelected.Properties.Content.Type = 'Social';
      currentNodeSelected.Properties.Content.Properties = {
        SocialList: socialListPayload,
        InnerPadding: margin.value,
        TextSize: fontSize.value,
        LinkTextDecoration: textDecoration.value,
        TextDisplayType: resolveDisplayType(),
        IconSize: scale.value,
        //optional prop
        SocialData: {
          DisablePadding: disabledPadding.value,
          SocialList: socialList.value,
          ShowDisplay: showDisplay.value,
          FontFamily: fontFamily.value,
          FontSize: fontSize.value,
          FontWeight: fontWeight.value,
          FontItalic: fontItalic.value,
          FontStyle: fontStyle.value,
          FontColor: fontColor.value,
          IconStyle: iconStyle.value,
          Padding: {
            Top: spacingTop.value,
            Bottom: spacingBottom.value,
            Left: indentLeft.value,
            Right: indentRight.value,
          },
        },
      };
    } else {
      currentNodeSelected.Properties.Content.Type = 'SocialBlank';
      currentNodeSelected.Properties.Content.Properties = {};
      currentNodeSelected.Properties.Style = {};
      currentNodeSelected.Properties.HTMLUI = addSocialUI;
    }
    EDMRender('cdp-edm-editor');
    vm?.$emit('on-build-obj');
    vm?.$emit('on-get-edm-config-json', EDMRenderSave('cdp-edm-editor'));
    vm?.$emit('on-get-edm-content', EDMRenderResult('cdp-edm-editor'));
    nextTick(() => {
      vm?.$emit('set-is-selected');
      vm?.$emit('handle-selected-element');
    });
  };

  const handleDisabledPadding = () => {
    disabledPadding.value = !disabledPadding.value;
    if (disabledPadding.value == false) {
      spacingTop.value = 0;
      spacingBottom.value = 0;
      indentLeft.value = 0;
      indentRight.value = 0;
    }
  };

  const onSelectShowDisplay = (display: string) => {
    showDisplay.value = display;
  };

  const onSelectTextPosition = (position: string) => {
    textPosition.value = position;
  };

  const onSelectFontFamily = (family: string) => {
    fontFamily.value = family;
  };

  const onSelectFontSize = (size: number) => {
    fontSize.value = size;
  };

  const onSelectFontStyle = (fStyle: string) => {
    if (fontStyle.value.includes(fStyle)) {
      fontStyle.value = fontStyle.value.filter((style: string) => style !== fStyle);
      if (fStyle == 'underline') {
        textDecoration.value = 'none';
      } else if (fStyle == 'bold') {
        fontWeight.value = 'normal';
      } else if (fStyle == 'italic') {
        fontItalic.value = 'normal;';
      }
    } else {
      if (fStyle == 'underline') {
        textDecoration.value = 'underline';
      } else if (fStyle == 'bold') {
        fontWeight.value = 'bold';
      } else if (fStyle == 'italic') {
        fontItalic.value = 'italic;';
      }
      fontStyle.value.push(fStyle);
    }
  };

  const onToggleColorPicker = () => {
    isColorPickerOpen.value = !isColorPickerOpen.value;
  };

  const onChangeFontColor = (color: string) => {
    fontColor.value = color;
  };

  const onSelectIconStyle = (style: number) => {
    iconStyle.value = style;
  };

  const onSelectAlignMent = (align: string) => {
    alignMent.value = align;
  };

  const onSelectJustifyMent = (justify: string) => {
    justifyMent.value = justify;
  };

  ///Create Social
  const addSocial = () => {
    if (!selectedSocial.value) {
      return;
    }
    editMode.value = false;
    const socialContent = {
      key: keyChange.value++,
      label: selectedSocial.value?.label,
      selectedSocial: selectedSocial.value,
      iconUrl: uploadCustomIcon.url,
      link: link.value,
      replaceText: replaceText.value,
    };
    socialList.value.push(socialContent);
    selectedSocial.value = null!;
    link.value = '';
    replaceText.value = '';
    uploadCustomIcon.file = null;
    uploadCustomIcon.url = null;
  };

  const updateSocial = () => {
    editMode.value = false;
    isEdit.value = false;
    const socialContent = {
      key: keyChange.value++,
      label: selectedSocial.value?.label,
      selectedSocial: selectedSocial.value,
      iconUrl: uploadCustomIcon.url,
      link: link.value,
      replaceText: replaceText.value,
    };
    socialList.value[socialIndex.value] = socialContent;
    selectedSocial.value = null!;
    link.value = '';
    replaceText.value = '';
  };

  const onApplySocial = () => {
    if (!isEdit.value) {
      const keyList: number[] = [];
      socialList.value.forEach((social: Social) => {
        keyList.push(social.key);
        const max = Math.max(...keyList);
        keyChange.value = max;
        keyChange.value++;
      });
      addSocial();
    } else {
      updateSocial();
    }
  };

  const toggleEditMode = (index: number, socialItem: any, mode: 'create' | 'edit') => {
    editMode.value = !editMode.value;
    if (mode == 'edit') {
      isEdit.value = true;
      socialIndex.value = index;
      keyChange.value = socialItem.key;
      selectedSocial.value = socialItem.selectedSocial;
      uploadCustomIcon.url = socialItem.iconUrl;
      link.value = socialItem.link;
      replaceText.value = socialItem.replaceText;
    }
  };

  const onDeleteSocial = (removeItem: any) => {
    socialList.value = socialList.value.filter((social: any) => social.key !== removeItem?.key);
  };

  const sortableSocial: Directive = {
    beforeMount(el: HTMLElement) {
      const container = el;

      const option: Sortable.SortableOptions = {
        ghostClass: 'sortable-ghost',
        dragClass: 'sortable-drag',
        handle: '.item-social',
        onEnd: onReorderSocial,
        animation: 300,
        easing: 'cubic-bezier(0.22, 1, 0.36, 1)',
      };

      Sortable.create(container, option);
    },
  };

  const onReorderSocial = (event: Sortable.SortableEvent) => {
    socialList.value.splice(event.newDraggableIndex as number, 0, socialList.value.splice(event.oldDraggableIndex as number, 1)[0]);
  };

  const handleUploadCustomIconChange = (e: any) => {
    const filesOBJ: File = e.target?.files[0];
    if (filesOBJ) {
      if (isFileImage(filesOBJ)) {
        if (isMaxFileSize_10_MB(filesOBJ)) {
          isLoading(true, 'กำลังอัพโหลดรูปภาพ');
          vm?.$emit('uploading', true);
          uploadFileModel.payload.Path = props.templateMode ? PATH.SYSTEM.COMMU.EDM : PATH.COMMU.EDM;
          uploadFileModel.payload.File = filesOBJ;
          uploadFileModel.payload.Is_Temp = true;
          apiService
            .apiFormRequest(uploadFileModel)
            .then((response) => {
              const res = response.data;

              uploadCustomIcon.url = res.public_url as Blob;
              uploadCustomIcon.sref = res.sref;
              vm?.$emit('on-add-sref-list', uploadCustomIcon.sref);
              buildSocialObjProperties();
            })
            .catch((err) => {
              openDefaultErrorModal(err);
            })
            .finally(() => {
              isLoading(false);
              vm?.$emit('uploading', false);
            });
        } else {
          openErrorModal('เกิดข้อผิดพลาด', 'ขนาดไฟล์ใหญ่เกินไป', '');
          clearFile(e as Event);
        }
      } else {
        openErrorModal('เกิดข้อผิดพลาด', 'ไฟล์ผิดประเภท', '');
        clearFile(e as Event);
      }
    }
  };

  const isFileImage = (file: any) => file && file.type.split('/')[0] === 'image';

  const isMaxFileSize_10_MB = (file: File) => file && file.size <= 10485760;

  const clearFile = (e: Event) => {
    if (uploadCustomIcon.sref) {
      vm?.$emit('on-delete-file', uploadCustomIcon.sref);
    }
    uploadCustomIcon.file = null;
    uploadCustomIcon.url = null;
    (e.target as HTMLInputElement).value = '';
  };

  const checkInputPadding = () => {
    const min = 0;
    const max = 40;
    if (spacingTop.value < min) {
      spacingTop.value = min;
    }
    if (spacingBottom.value < min) {
      spacingBottom.value = min;
    }
    if (indentLeft.value > max) {
      indentLeft.value = max;
    } else if (indentLeft.value < min) {
      indentLeft.value = min;
    }
    if (indentRight.value > max) {
      indentRight.value = max;
    } else if (indentRight.value < min) {
      indentRight.value = min;
    }
  };

  function setMapperValue(link: string, listSocial: SocialPayload[], key: number): Campaign.TrackingMapper | null {
    if (link) {
      const mapper = {
        trackingUrl: {
          mapperType: 10,
          subType: 2,
          linkValue: '',
          tag: '',
        },
      };
      const social = listSocial.find((social: SocialPayload) => social.Key === key);
      if (!social?.Value) {
        mapper.trackingUrl.tag = `{{mp:${props.tagMp as number}}}`;
        vm?.$emit('update-tag-number');
      } else {
        mapper.trackingUrl.tag = social.Value.tag as string;
      }
      mapper.trackingUrl.linkValue = link;

      return mapper.trackingUrl;
    }

    return null;
  }

  onMounted(() => {
    const currentNodeSelected = EDMGetObjList('cdp-edm-editor')[props.selectedElement?.id as string];
    if (currentNodeSelected.Properties.Content.Type == 'Social') {
      socialList.value = currentNodeSelected.Properties.Content.Properties.SocialData.SocialList;
      const keyList: number[] = [];
      socialList.value.forEach((social: Social, index: number) => {
        if (!currentNodeSelected.Properties.Content.Properties.SocialList[index]?.Key) {
          social.key = keyChange.value;
          keyChange.value++;
        } else {
          social.key = currentNodeSelected.Properties.Content.Properties.SocialList[index].Key;
          keyList.push(social.key);
          const max = Math.max(...keyList);
          keyChange.value = max;
          keyChange.value++;
        }
      });
      disabledPadding.value = currentNodeSelected.Properties.Content.Properties.SocialData.DisablePadding;
      showDisplay.value = currentNodeSelected.Properties.Content.Properties.SocialData.ShowDisplay;
      if (showDisplay.value == 'text-only' || showDisplay.value == 'icon-only') {
        textPosition.value = 'Down';
      } else {
        textPosition.value = currentNodeSelected.Properties.Content.Properties.TextDisplayType;
      }
      fontFamily.value = currentNodeSelected.Properties.Content.Properties.SocialData.FontFamily;
      fontSize.value = currentNodeSelected.Properties.Content.Properties.SocialData.FontSize;
      fontWeight.value = currentNodeSelected.Properties.Content.Properties.SocialData.FontWeight;
      fontItalic.value = currentNodeSelected.Properties.Content.Properties.SocialData.FontItalic;
      fontStyle.value = currentNodeSelected.Properties.Content.Properties.SocialData.FontStyle;
      fontColor.value = currentNodeSelected.Properties.Content.Properties.SocialData.FontColor;
      iconStyle.value = currentNodeSelected.Properties.Content.Properties.SocialData.IconStyle;
      justifyMent.value = currentNodeSelected.Properties.Style['vertical-align'];
      alignMent.value = currentNodeSelected.Properties.Style['text-align'];
      spacingTop.value = currentNodeSelected.Properties.Content.Properties.SocialData.Padding.Top ?? 0;
      spacingBottom.value = currentNodeSelected.Properties.Content.Properties.SocialData.Padding.Bottom ?? 0;
      indentLeft.value = currentNodeSelected.Properties.Content.Properties.SocialData.Padding.Left ?? 0;
      indentRight.value = currentNodeSelected.Properties.Content.Properties.SocialData.Padding.Right ?? 0;
      textDecoration.value = currentNodeSelected.Properties.Content.Properties.LinkTextDecoration;
      scale.value = currentNodeSelected.Properties.Content.Properties.IconSize ?? 0;
      margin.value = currentNodeSelected.Properties.Content.Properties.InnerPadding ?? 0;
    }
  });

  watch(
    [
      disabledPadding,
      socialList,
      showDisplay,
      textPosition,
      fontFamily,
      fontSize,
      fontStyle,
      fontColor,
      textDecoration,
      iconStyle,
      alignMent,
      justifyMent,
      spacingTop,
      spacingBottom,
      indentLeft,
      indentRight,
      scale,
      margin,
      uploadCustomIcon,
    ],
    () => {
      buildSocialObjProperties();
    },
    { deep: true },
  );

  return {
    sortableSocial,
    disabledPadding,
    showDisplay,
    textPosition,
    fontFamily,
    fontSize,
    fontStyle,
    isColorPickerOpen,
    fontColor,
    iconStyle,
    alignMent,
    justifyMent,
    scale,
    margin,
    spacingTop,
    spacingBottom,
    indentLeft,
    indentRight,
    uploadCustomIcon,
    selectedSocial,
    socialList,
    link,
    replaceText,
    editMode,
    isEdit,
    addSocial,
    updateSocial,
    onApplySocial,
    toggleEditMode,
    onDeleteSocial,
    onToggleColorPicker,
    handleDisabledPadding,
    checkInputPadding,
    onSelectShowDisplay,
    onSelectTextPosition,
    onSelectFontFamily,
    onSelectFontSize,
    onSelectFontStyle,
    onChangeFontColor,
    onSelectIconStyle,
    onSelectAlignMent,
    onSelectJustifyMent,
    handleUploadCustomIconChange,
    clearFile,
  };
}
