import { onMounted, ref, Ref, watch, reactive } from 'vue';
import { useRouter } from 'vue-router';

import { useI18n } from 'vue-i18n';
import variable from './variable';

import { JOURNEY_ID } from '@/constants/modules/mka/mka';

export default function useListPausedTab() {
  const { t } = useI18n();
  const router = useRouter();
  const { Module, Channel, Schedule, dropDownPagination, column_main, column_expand } = variable();

  function setStatus(status: number, module: string) {
    if (module == Module.Commu) {
      // if (status == 7) {
      //   return { label: t('communication.pause'), color: 'red' };
      // } else if (status == 5) {
      //   return { label: t('communication.dispath'), color: 'yellow' };
      // } else if (status == 4) {
      //   return { label: t('communication.scheduled'), color: 'green' };
      // } else if (status == 2) {
      //   return { label: t('communication.preparing'), color: 'blue' };
      // }
      if (status == 7) {
        return { label: t('communication.pause'), color: ' #0871ff' };
      } else if (status == 5) {
        return { label: t('communication.dispath'), color: '#00ceff' };
      } else if (status == 4) {
        return { label: t('communication.scheduled'), color: '#ffc400' };
      } else if (status == 2) {
        return { label: t('communication.preparing'), color: '#ffc400' };
      }
    } else if (module == Module.Mka) {
      if (status == JOURNEY_ID.Draft) {
        return { label: t('mka.status_draft'), color: 'yellow' };
      } else if (status == JOURNEY_ID.Preparing) {
        return { label: t('mka.status_preparing'), color: 'blue' };
      } else if (status == JOURNEY_ID.Publish) {
        return { label: t('mka.status_active'), color: 'green' };
      } else if (status == JOURNEY_ID.Pause) {
        return { label: t('mka.status_pause'), color: 'red' };
      } else if (status == JOURNEY_ID.Archived) {
        return { label: t('mka.status_complete'), color: 'gray' };
      }
    }
  }

  function onClickRouterPush(data: Record<string, any>) {
    const id: string = data.id != undefined ? data.id : '0';
    if (data.module == Module.Commu) {
      const goTo: Ref<string> = ref('');
      if (data.channel == Channel.SMS) {
        goTo.value = `/communication/sms/dashboard-by-campaign/${id}`;
      } else if (data.channel == Channel.Line) {
        goTo.value = `/communication/line-oa/dashboard-by-campaign/${id}`;
      } else if (data.channel == Channel.Email) {
        goTo.value = `/communication/email/dashboard-by-campaign/${id}`;
      }
      router.push({
        path: goTo.value,
        query: {
          ...router.currentRoute.value.query,
        },
      });
    } else if (data.module == Module.Mka) {
      router.push({
        path: `/mka/detail/${id}`,
        query: {
          ...router.currentRoute.value.query,
        },
      });
    } else {
      router.push({
        path: `/communication/email/dashboard-by-campaign/${id}`,
        query: {
          ...router.currentRoute.value.query,
        },
      });
    }
  }

  return {
    setStatus,
    onClickRouterPush,
  };
}
