<template>
  <svg id="Group_115767" data-name="Group 115767" xmlns="http://www.w3.org/2000/svg" width="59.857" height="46.266" viewBox="0 0 59.857 46.266">
    <path
      id="Path_311130"
      data-name="Path 311130"
      d="M50.176,6.012H25.814a4.5,4.5,0,0,0-4.5,4.5V33.473a4.5,4.5,0,0,0,4.5,4.5,2.554,2.554,0,0,1,2,.984c1.535,1.952,3.779,4.347,6.467,5.093a.236.236,0,0,0,.267-.357,8.65,8.65,0,0,1-1.225-2.557,2.525,2.525,0,0,1,2.447-3.163H50.183a4.493,4.493,0,0,0,4.492-4.5V10.511a4.5,4.5,0,0,0-4.5-4.5M39.2,31.057a2,2,0,0,1-1.367.527,2.069,2.069,0,0,1-1.4-.519,1.821,1.821,0,0,1-.6-1.452,1.873,1.873,0,0,1,.578-1.394,1.953,1.953,0,0,1,1.42-.565A1.93,1.93,0,0,1,39.8,29.612a1.833,1.833,0,0,1-.591,1.445m4.91-12.172a6.013,6.013,0,0,1-1.078,1.452q-.625.612-2.242,2.058a9.141,9.141,0,0,0-.717.717,2.9,2.9,0,0,0-.4.565,2.682,2.682,0,0,0-.2.512q-.073.257-.217.9a1.463,1.463,0,0,1-1.564,1.367,1.6,1.6,0,0,1-1.15-.447,1.757,1.757,0,0,1-.467-1.328,4.662,4.662,0,0,1,1.249-3.332q.566-.611,1.525-1.452.841-.736,1.216-1.111a3.751,3.751,0,0,0,.631-.834,2.024,2.024,0,0,0,.256-1,2.322,2.322,0,0,0-.782-1.775,2.857,2.857,0,0,0-2.018-.723,2.792,2.792,0,0,0-2.129.729,5.823,5.823,0,0,0-1.157,2.15q-.447,1.486-1.7,1.486a1.666,1.666,0,0,1-1.242-.519,1.589,1.589,0,0,1-.506-1.124,4.758,4.758,0,0,1,.8-2.531,6.181,6.181,0,0,1,2.34-2.123,7.363,7.363,0,0,1,3.588-.841,7.656,7.656,0,0,1,3.365.7A5.459,5.459,0,0,1,43.773,14.3a4.694,4.694,0,0,1,.8,2.629,4.066,4.066,0,0,1-.454,1.958"
      transform="translate(-7.568 2.207)"
      fill="#007fff"
    />
    <path
      id="Subtraction_333"
      data-name="Subtraction 333"
      d="M28.867,31.96H25.955V7.423H21.739A5.9,5.9,0,0,0,20.2,6.373a7.693,7.693,0,0,0-3.365-.7,7.4,7.4,0,0,0-3.588.84,7.1,7.1,0,0,0-1.311.913H0V4.5A4.5,4.5,0,0,1,4.5,0H28.86a4.5,4.5,0,0,1,4.5,4.5V27.461A4.5,4.5,0,0,1,28.867,31.96Z"
      transform="translate(18.494 4.003)"
      fill="#007fff"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
      opacity="0.338"
    />
    <path
      id="Path_311131"
      data-name="Path 311131"
      d="M6.38,14.04a3.425,3.425,0,1,0,3.425-3.425A3.425,3.425,0,0,0,6.38,14.04"
      transform="translate(-2.275 -3.613)"
      fill="#007fff"
    />
    <path
      id="Path_311132"
      data-name="Path 311132"
      d="M0,4.906A2.053,2.053,0,1,0,2.053,2.853,2.053,2.053,0,0,0,0,4.906"
      transform="translate(0 0.881)"
      fill="#007fff"
    />
    <path
      id="Path_311135"
      data-name="Path 311135"
      d="M0,4.906A2.053,2.053,0,1,0,2.053,2.853,2.053,2.053,0,0,0,0,4.906"
      transform="translate(6.85 31.058)"
      fill="#007fff"
    />
    <path
      id="Path_311133"
      data-name="Path 311133"
      d="M64.489,2.575A2.575,2.575,0,1,0,67.064,0a2.574,2.574,0,0,0-2.575,2.575"
      transform="translate(-9.781)"
      fill="#007fff"
    />
    <path
      id="Path_311134"
      data-name="Path 311134"
      d="M64.489,48.124a2.227,2.227,0,1,1,2.227,2.227,2.227,2.227,0,0,1-2.227-2.227"
      transform="translate(-14.588 -9.882)"
      fill="#007fff"
    />
  </svg>
</template>
