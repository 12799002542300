<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.76953 8.84766H21.0915C21.4626 8.84766 21.83 8.92076 22.1728 9.06279C22.5157 9.20482 22.8271 9.413 23.0895 9.67543C23.3518 9.93787 23.5599 10.2494 23.7018 10.5923C23.8437 10.9351 23.9167 11.3026 23.9165 11.6737V21.1737C23.9165 21.5446 23.8435 21.912 23.7015 22.2547C23.5595 22.5975 23.3514 22.9089 23.0891 23.1712C22.8268 23.4336 22.5154 23.6416 22.1726 23.7836C21.8299 23.9256 21.4625 23.9987 21.0915 23.9987H11.5915C10.8428 23.9979 10.125 23.6999 9.59589 23.1702C9.06675 22.6405 8.76953 21.9224 8.76953 21.1737V8.84766Z"
      fill="#029EFF"
    />
    <path
      d="M16.0617 24.2388C15.2427 24.2377 14.4576 23.9119 13.8786 23.3329C13.2995 22.7538 12.9737 21.9687 12.9727 21.1498V13.7748C12.9729 13.5828 13.0493 13.3988 13.185 13.2631C13.3207 13.1274 13.5047 13.051 13.6967 13.0508H21.0717C21.8906 13.0518 22.6757 13.3776 23.2547 13.9567C23.8338 14.5358 24.1596 15.3208 24.1607 16.1398V21.1498C24.1596 21.9687 23.8338 22.7538 23.2547 23.3329C22.6757 23.9119 21.8906 24.2377 21.0717 24.2388H16.0617ZM15.2847 15.2388C15.2684 15.2388 15.2522 15.242 15.2372 15.2482C15.2222 15.2545 15.2085 15.2636 15.197 15.2751C15.1855 15.2866 15.1763 15.3003 15.1701 15.3153C15.1639 15.3304 15.1607 15.3465 15.1607 15.3628V21.1498C15.1607 21.3885 15.2555 21.6174 15.4243 21.7862C15.593 21.955 15.822 22.0498 16.0607 22.0498H21.0707C21.3094 22.0498 21.5383 21.955 21.7071 21.7862C21.8758 21.6174 21.9707 21.3885 21.9707 21.1498V16.1398C21.9707 15.9011 21.8758 15.6722 21.7071 15.5034C21.5383 15.3346 21.3094 15.2398 21.0707 15.2398L15.2847 15.2388Z"
      fill="black"
    />
    <path
      d="M3.63794 11.8032C2.92837 11.8024 2.24808 11.5203 1.74624 11.0186C1.24441 10.517 0.961996 9.8368 0.960938 9.12723V3.29123C0.961996 2.58167 1.24441 1.90148 1.74624 1.39983C2.24808 0.898186 2.92837 0.616028 3.63794 0.615234L9.47294 0.615234C10.1825 0.616028 10.8628 0.898186 11.3646 1.39983C11.8665 1.90148 12.1489 2.58167 12.1499 3.29123V6.14923C12.1499 6.43951 12.0346 6.7179 11.8294 6.92316C11.6241 7.12842 11.3457 7.24373 11.0554 7.24373C10.7652 7.24373 10.4868 7.12842 10.2815 6.92316C10.0763 6.7179 9.96094 6.43951 9.96094 6.14923V3.29123C9.96067 3.16162 9.90907 3.0374 9.81742 2.94575C9.72577 2.8541 9.60155 2.8025 9.47194 2.80223H3.63794C3.50833 2.8025 3.3841 2.8541 3.29245 2.94575C3.20081 3.0374 3.1492 3.16162 3.14894 3.29123V9.12723C3.14947 9.25667 3.20119 9.38064 3.29281 9.47207C3.38443 9.56351 3.5085 9.61497 3.63794 9.61523H10.4679C10.7581 9.61523 11.0363 9.73049 11.2415 9.93566C11.4467 10.1408 11.5619 10.4191 11.5619 10.7092C11.5619 10.9994 11.4467 11.2776 11.2415 11.4828C11.0363 11.688 10.7581 11.8032 10.4679 11.8032H3.63794Z"
      fill="black"
    />
    <path
      d="M14.6547 11.8032C14.5061 11.8029 14.3592 11.7726 14.2228 11.714C14.0863 11.6554 13.9631 11.5697 13.8607 11.4622C13.7577 11.3547 13.6779 11.2271 13.6263 11.0874C13.5747 10.9477 13.5524 10.7989 13.5607 10.6502C13.5821 10.3646 13.7123 10.098 13.9243 9.90549C14.1364 9.71294 14.4143 9.60906 14.7007 9.61523H21.4847C21.6143 9.61523 21.7387 9.56371 21.8304 9.47201C21.9221 9.3803 21.9737 9.25593 21.9737 9.12623V3.29123C21.9734 3.16162 21.9218 3.0374 21.8301 2.94575C21.7385 2.8541 21.6143 2.8025 21.4847 2.80223H15.6497C15.52 2.80223 15.3956 2.85375 15.3039 2.94546C15.2122 3.03716 15.1607 3.16154 15.1607 3.29123V6.85323C15.1607 7.14338 15.0454 7.42164 14.8402 7.62681C14.6351 7.83197 14.3568 7.94723 14.0667 7.94723C13.7765 7.94723 13.4982 7.83197 13.2931 7.62681C13.0879 7.42164 12.9727 7.14338 12.9727 6.85323V2.85323C12.9734 2.25992 13.2095 1.69114 13.629 1.27161C14.0486 0.852072 14.6173 0.616028 15.2107 0.615234H21.9217C22.5151 0.615764 23.0842 0.85169 23.5039 1.27125C23.9237 1.69082 24.1599 2.25975 24.1607 2.85323V9.12723C24.1599 9.83698 23.8776 10.5174 23.3757 11.0193C22.8738 11.5211 22.1934 11.8034 21.4837 11.8042L14.6547 11.8032Z"
      fill="black"
    />
    <path
      d="M4.04994 24.2388C3.64414 24.2393 3.24222 24.1598 2.86721 24.0047C2.4922 23.8497 2.15146 23.6222 1.86451 23.3352C1.57757 23.0483 1.35005 22.7075 1.195 22.3325C1.03995 21.9575 0.960414 21.5556 0.96094 21.1498V16.0918C0.961998 15.2856 1.28273 14.5127 1.8528 13.9426C2.42287 13.3726 3.19574 13.0518 4.00194 13.0508H9.42394C9.70145 13.0508 9.96761 13.161 10.1639 13.3571C10.3602 13.5532 10.4707 13.8193 10.4709 14.0968V14.1968C10.4707 14.4741 10.3604 14.74 10.1643 14.9361C9.96818 15.1322 9.70228 15.2425 9.42494 15.2428H4.00294C3.77653 15.243 3.55946 15.3331 3.39936 15.4932C3.23926 15.6533 3.1492 15.8704 3.14894 16.0968V21.2018C3.1492 21.4282 3.23926 21.6453 3.39936 21.8054C3.55946 21.9655 3.77653 22.0555 4.00294 22.0558H10.0089C10.2864 22.0535 10.5533 22.1616 10.7511 22.3561C10.9488 22.5507 11.0612 22.8159 11.0634 23.0933C11.0657 23.3707 10.9577 23.6376 10.7631 23.8354C10.5685 24.0332 10.3034 24.1455 10.0259 24.1478L4.05194 24.2478L4.04994 24.2388Z"
      fill="black"
    />
  </svg>
</template>
