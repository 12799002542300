import { Ref, ref, reactive, onBeforeMount, watch } from 'vue';
import { saveAs } from 'file-saver';
import apiService from '@/services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import campaignModel from '@/models/communication/campaign';

// permission
import ability from '@/libs/acl/ability';

import router from '@/router';

export default function useLineOACampaignList() {
  const { fetchCampaignListModel, duplicateCampaignModel, deleteCampaignModel, fetchCampaignReport, cancelCampaignModel } = campaignModel();
  const { openDefaultErrorModal, openSuccessModal, openWarningModal, openErrorModal } = useValidationModal();

  const campaignList: Ref<Communication.Campaign.Campaign[]> = ref([]);
  const isLoading: Ref<boolean> = ref(false);

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });

  const filterForm: {
    status: number | null;
    search: string | null;
    order: {
      Seq: number;
      Key: string;
      Direction: string;
    }[];
  } = reactive({
    status: null,
    search: '',
    order: [],
  });

  const field: BaseTable.TableColumn[] = [
    { key: 'name', label: 'Campaign Name', sortable: true },
    { key: 'updated_dt', label: 'Last Update', sortable: true },
    { key: 'updated_by', label: 'Update by', sortable: true },
    { key: 'status', label: 'Status', sortable: true },
    { key: 'action', label: '' },
  ];

  const statusOption = [
    {
      label: 'Draft',
      value: 1,
    },
    {
      label: 'Preparing',
      value: 2,
    },
    {
      label: 'Scheduled',
      value: 4,
    },
    {
      label: 'Sending',
      value: 5,
    },
    {
      label: 'Pause',
      value: 7,
    },
    {
      label: 'Complete',
      value: 9,
    },
    {
      label: 'Cancel',
      value: 11,
    },
  ];

  let searchTimeout: ReturnType<typeof setTimeout> = null!;

  const fetchCampaignList = () => {
    fetchCampaignListModel.payload.Channel = 3;
    fetchCampaignListModel.payload.Page = pagination.currentPage;
    fetchCampaignListModel.payload.Limit = pagination.perPage;
    fetchCampaignListModel.payload.Query = filterForm.search;
    fetchCampaignListModel.payload.Ordering = filterForm.order;
    fetchCampaignListModel.payload.Status = filterForm.status;
    isLoading.value = true;

    apiService
      .apiRequest(fetchCampaignListModel)
      .then((response) => {
        const campaignListDetail = response.data as Communication.Campaign.CampaignList;

        campaignList.value = campaignListDetail.records;
        pagination.totalRows = campaignListDetail.filtered_records;
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => (isLoading.value = false));
  };

  const onSearch = () => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      if (pagination.currentPage == 1) return fetchCampaignList();
      pagination.currentPage = 1;
    }, 800);
  };

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    if (sortedColumn.direction) {
      const sortModel = [
        {
          Seq: 1,
          Key: sortedColumn.key,
          Direction: sortedColumn.direction.toLocaleLowerCase(),
        },
      ];

      filterForm.order = sortModel;
    } else {
      filterForm.order = [];
    }

    if (pagination.currentPage == 1) return fetchCampaignList();
    pagination.currentPage = 1;
  };

  const resolveStatusClass = (status: number) => {
    switch (status) {
      case 1:
        return 'status-draft';
      case 2:
        return 'status-preparing';
      case 4:
        return 'status-scheduled';
      case 5:
        return 'status-sending';
      case 7:
        return 'status-pause';
      case 9:
        return 'status-complete';
      case 11:
        return 'status-cancel';
      default:
        return 'status-unknown';
    }
  };

  const resolveAction = (action: ActionList.Action, campaign: Communication.Campaign.Campaign) => {
    switch (action.key) {
      case 'download': {
        fetchDownloadReport(campaign.campaignid);
        return;
      }
      case 'dashboard':
        return router.push({
          path: `/communication/line-oa/dashboard-by-campaign/${campaign.campaignid}`,
          query: {
            ...router.currentRoute.value.query,
          },
        });
      case 'edit':
        return campaign.status == 1 && router.push({ name: 'line-oa-campaign-info', query: { campaign: campaign.campaignid } });
      case 'duplicate':
        return openWarningModal('Duplicate Campaign', `ต้องการ Duplicate Campaign ${campaign.name} หรือไม่`, () => duplicateCampaign(campaign));
      case 'delete':
        return openWarningModal('ลบ Campaign', `ต้องการลบ ${campaign.name} หรือไม่`, () => deleteCampaign(campaign.campaignid));
      case 'cancel':
        return openWarningModal(`ยกเลิก Campaign`, `ต้องการยกเลิก ${campaign.name} หรือไม่`, () => cancelCampaign(campaign.campaignid));
    }
  };

  const fetchDownloadReport = (campaignId: number) => {
    fetchCampaignReport.payload.CampaignId = campaignId;
    apiService
      .apiRequest(fetchCampaignReport)
      .then((response) => {
        const datesTime = new Date();
        saveAs(
          response as Blob | string,
          `Report-Campaign-${campaignId}-${datesTime.getFullYear()}${datesTime.getMonth()}${datesTime.getDate()}_${datesTime.getHours()}${datesTime.getMinutes()}${datesTime.getSeconds()}.xlsx`,
        );
      })
      .catch((err) => {
        try {
          openDefaultErrorModal(err);
        } catch {
          openErrorModal('เกิดข้อผิดพลาด', 'ไม่สามารถดาวน์โหลด Report ได้');
        }
      });
  };

  function duplicateCampaign(campaign: Communication.Campaign.Campaign) {
    duplicateCampaignModel.payload.CampaignId = campaign.campaignid;

    apiService
      .apiRequest(duplicateCampaignModel)
      .then(() => openSuccessModal('Duplicate สำเร็จ', '', '', undefined, fetchCampaignList))
      .catch((err) => openDefaultErrorModal(err));
  }

  function deleteCampaign(campaignId: number) {
    deleteCampaignModel.payload.CampaignId = campaignId;

    apiService
      .apiRequest(deleteCampaignModel)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        fetchCampaignList();
      });
  }

  function cancelCampaign(campaignId: number) {
    cancelCampaignModel.payload.CampaignId = campaignId;

    apiService
      .apiRequest(cancelCampaignModel)
      .then(() => openSuccessModal('Cancel สำเร็จ', '', '', undefined, fetchCampaignList))
      .catch((err) => openDefaultErrorModal(err));
  }

  const onClickRow = (campaign: Communication.Campaign.Campaign) => {
    if (campaign.status === 1) {
      if (ability.can('portal-cdp', 'cdp-portal-commu-createcampaign')) {
        return router.push({ name: 'line-oa-campaign-info', query: { campaign: campaign.campaignid } });
      }
    } else {
      return router.push({
        path: `/communication/line-oa/dashboard-by-campaign/${campaign.campaignid}`,
        query: {
          ...router.currentRoute.value.query,
        },
      });
    }
  };

  watch(
    () => pagination.currentPage,
    () => {
      fetchCampaignList();
    },
  );

  watch(
    () => filterForm.status,
    () => {
      if (pagination.currentPage == 1) return fetchCampaignList();
      pagination.currentPage = 1;
    },
  );

  onBeforeMount(() => {
    fetchCampaignList();
  });

  return {
    field,
    campaignList,
    pagination,
    filterForm,
    isLoading,
    statusOption,
    onSearch,
    resolveStatusClass,
    onSort,
    resolveAction,
    onClickRow,
  };
}
