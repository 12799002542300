<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14.041" height="14.105" viewBox="0 0 14.041 14.105">
    <g id="Group_37522" data-name="Group 37522">
      <path
        id="Path_24357"
        data-name="Path 24357"
        d="M274.65,130.5h0a.651.651,0,0,0-.651-.651h-2.3V126.5a.651.651,0,0,0-1.3,0v3.842a.642.642,0,0,0,0,.077.65.65,0,0,0,.647.729H274a.651.651,0,0,0,.651-.651"
        transform="translate(-264.247 -122.988)"
        fill="#c3c3c3"
      />
      <path
        id="Path_24358"
        data-name="Path 24358"
        d="M13.9,5.611A6.978,6.978,0,0,0,8.339.128,7.074,7.074,0,0,0,.008,5.887a.58.58,0,1,0,1.145.19A5.919,5.919,0,0,1,3.686,2.156a5.644,5.644,0,0,1,2.187-.9,5.918,5.918,0,0,1,6.04,2.558,5.6,5.6,0,0,1,.852,2.061A5.913,5.913,0,0,1,7.5,12.948.58.58,0,1,0,7.61,14.1,7.074,7.074,0,0,0,13.9,5.611"
        transform="translate(0 0)"
        fill="#c3c3c3"
      />
    </g>
  </svg>
</template>
