import { reactive } from 'vue';

interface SegmentViewmode {
  selectedOption: {
    mode: number;
    title: string;
    type: number;
  };
}

export default function useSelectViewMode() {
  const selectViewMode: SegmentViewmode = reactive({
    selectedOption: {
      mode: 0,
      title: '',
      type: 1,
    },
  });

  const selectObj = (selected: SegmentViewmode) => {
    selectViewMode.selectedOption.mode = selected.selectedOption.mode;
    selectViewMode.selectedOption.title = selected.selectedOption.title;
    selectViewMode.selectedOption.type = selected.selectedOption.type;
  };

  return {
    selectViewMode,
    selectObj,
  };
}
