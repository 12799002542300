import { DateTime, Zone } from 'luxon';
import { computed, defineComponent, h, Prop, ref } from 'vue';
import { SelectableFunctionType } from '../../composed/calendar';
import { getZone, useDateTimeFormat } from '../../composed/date-time';
import { useInputable } from '../../composed/inputable';
import { ChocoCalendar } from '../calendar';
import { ChocoInputPopup, ChocoInputPopupMethods } from '../input-popup';

export default defineComponent({
  name: 'ChocoDatePicker',
  props: {
    timezone: <Prop<Zone | string | number | null>>{
      validate: (val: any) => val === null || getZone(val).isValid,
      default: () => null,
    },
    modelValue: <Prop<Date | null>>{
      type: Object,
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd',
    },
    placeholder: {
      type: String,
      default: 'Select Date',
    },
    selectableCallback: <Prop<SelectableFunctionType>>{},
  },
  emits: ['update:modelValue'],
  setup(props, { slots, emit }) {
    // use date-time formatter
    const format = useDateTimeFormat(
      computed(() => props.modelValue ?? null),
      computed(() => props.timezone ?? null),
    );

    // use inputable for lazy-update
    const onInput = (value: string | null, reset: () => void) => {
      // validate input value, parse to format
      if (value !== null) {
        // parse to format
        const parsed = DateTime.fromFormat(value, props.format).setZone(format.timezone.value);
        // if parsed value is valid, update model value
        if (parsed.isValid) {
          let dt: DateTime;
          // date is not selected, create new Date
          if (!props.modelValue) {
            dt = DateTime.now().setZone(format.timezone.value);
          }
          // else, update from model value
          else {
            dt = DateTime.fromJSDate(props.modelValue).setZone(format.timezone.value);
          }

          // set date
          dt = dt.set({ year: parsed.year, month: parsed.month, day: parsed.day });
          // emit event, and return
          emit('update:modelValue', dt.toJSDate());
          return;
        }
      }

      // update failed, reset input value
      reset();
    };
    const { value, updateValue, onInputFocus, onInputBlur, onInputEnter } = useInputable(
      computed(() => (format.hasDate.value ? format.toFormat(props.format) : null)),
      onInput,
    );

    // element state
    const popupRef = ref<(InstanceType<typeof ChocoInputPopup> & ChocoInputPopupMethods) | null>(null);

    return () =>
      // ChocoInputPopup.choco-date-picker
      h(
        ChocoInputPopup,
        {
          ref: popupRef,
          class: ['choco-date-picker'],
          onEnter: onInputEnter,
          onFocus: onInputFocus,
          onBlur: onInputBlur,
          modelValue: value,
          'onUpdate:modelValue': updateValue,
          placeholder: props.placeholder,
        },
        {
          // v-slot:input > use slot:input or default native input
          input: (props: object) => (slots['input'] ? slots['input'](props) : h('input', props)),
          // v-slot:default > div.choco-date-picker__calendar-wrapper
          default: () => [
            // div.choco-date-picker__popup-container
            h('div', { class: ['choco-date-picker__popup-calendar'] }, [
              // div.choco-date-picker__calendar-wrapper
              h('div', { class: ['choco-date-picker__calendar-wrapper'] }, [
                // ChocoCalendar
                h(ChocoCalendar, {
                  timezone: props.timezone,
                  selectable: true,
                  select: props.modelValue,
                  selectableCallback: props.selectableCallback,
                  ['onUpdate:select']: (value) => emit('update:modelValue', value),
                }),
              ]),
            ]),
          ],
        },
      );
  },
});
