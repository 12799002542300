<template>
  <svg
    id="Group_37511"
    data-name="Group 37511"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="17.887"
    height="21.21"
    viewBox="0 0 17.887 21.21"
  >
    <g id="Group_37485" data-name="Group 37485">
      <path
        id="Path_24351"
        data-name="Path 24351"
        d="M8.944,21.209A13.758,13.758,0,0,1,2.8,16.327,15.8,15.8,0,0,1,.333,10.1,22.96,22.96,0,0,1,.271,2.7l.281,0h0A14.115,14.115,0,0,0,7.1,1.257,8.546,8.546,0,0,0,8.944,0a8.541,8.541,0,0,0,1.847,1.258A14.112,14.112,0,0,0,17.334,2.7l.282,0a22.96,22.96,0,0,1-.063,7.4,15.8,15.8,0,0,1-2.466,6.223,13.758,13.758,0,0,1-6.145,4.883M8.785,2.266c-.285.181-.586.355-.9.516A13.931,13.931,0,0,1,4.839,3.919a17,17,0,0,1-2.791.444l-.254.02-.018.254a21.054,21.054,0,0,0,.27,5.279A14.008,14.008,0,0,0,4.25,15.405a11.879,11.879,0,0,0,4.563,3.858l.131.065.131-.065a11.879,11.879,0,0,0,4.563-3.858,14.008,14.008,0,0,0,2.205-5.489,21.052,21.052,0,0,0,.27-5.278l-.018-.254-.254-.02a16.993,16.993,0,0,1-2.792-.444A13.929,13.929,0,0,1,10,2.782c-.31-.161-.612-.335-.9-.516l-.159-.1Z"
        transform="translate(0 0.001)"
      />
      <path
        id="Path_24352"
        data-name="Path 24352"
        d="M173.663,231.541a.12.12,0,0,1-.085-.035l-2.788-2.788a.121.121,0,0,1,0-.171l.738-.738a.12.12,0,0,1,.171,0l1.964,1.964,5.081-5.081a.121.121,0,0,1,.171,0l.738.738a.121.121,0,0,1,0,.171l-5.9,5.9a.12.12,0,0,1-.085.035"
        transform="translate(-165.948 -217.748)"
      />
    </g>
  </svg>
</template>
