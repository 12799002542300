<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.5 283.5"
    xml:space="preserve"
  >
    <g transform="translate(5 32)">
      <g>
        <path d="M122.1,124.2H80.5V94.9h41.6V53.4H151v41.6h41.9v29.2H151v41.9h-28.9V124.2z" />
      </g>
    </g>
  </svg>
</template>
