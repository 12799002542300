<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15">
    <g id="Group_8" data-name="Group 8" transform="translate(-1868.065 -30)">
      <rect
        id="Rectangle_15"
        data-name="Rectangle 15"
        width="18"
        height="3"
        rx="1.5"
        transform="translate(1868.065 30)"
      />
      <rect
        id="Rectangle_16"
        data-name="Rectangle 16"
        width="18"
        height="3"
        rx="1.5"
        transform="translate(1868.065 36)"
      />
      <rect
        id="Rectangle_17"
        data-name="Rectangle 17"
        width="18"
        height="3"
        rx="1.5"
        transform="translate(1868.065 42)"
      />
    </g>
  </svg>
</template>
