<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40.854" height="40.853" viewBox="0 0 40.854 40.853">
    <g transform="translate(0 0)">
      <path
        d="M20.427,40.852A20.426,20.426,0,0,1,5.983,5.983,20.426,20.426,0,1,1,34.871,34.87,20.294,20.294,0,0,1,20.427,40.852Zm0-36.767a16.341,16.341,0,0,0-11.555,27.9A16.341,16.341,0,0,0,31.981,8.871,16.233,16.233,0,0,0,20.427,4.085Z"
        fill="#75a3dd"
      />
      <g transform="translate(13.618 12.255)">
        <rect width="4.085" height="16.341" rx="2" transform="translate(0 0)" fill="#75a3dd" />
        <rect width="4.085" height="16.341" rx="2" transform="translate(9.532 0)" fill="#75a3dd" />
      </g>
    </g>
  </svg>
</template>
