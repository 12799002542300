<template>
  <svg id="Group_31745" data-name="Group 31745" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
    <path
      d="M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0m0,95.743A45.744,45.744,0,1,1,95.743,50,45.8,45.8,0,0,1,50,95.744"
      transform="translate(0 -0.001)"
    />
    <path
      d="M51.739,62.382H48.515a1.26,1.26,0,0,1-1.259-1.214L46.069,28.647a1.26,1.26,0,0,1,1.259-1.306h5.343a1.26,1.26,0,0,1,1.26,1.3L53,61.158a1.26,1.26,0,0,1-1.26,1.224"
      transform="translate(0 -0.001)"
    />
    <path
      d="M50.117,66.424a3.183,3.183,0,0,1,1.276.257A3.234,3.234,0,0,1,53.12,68.41a3.265,3.265,0,0,1,0,2.549,3.509,3.509,0,0,1-.694,1.051,3.248,3.248,0,0,1-3.584.709,3.326,3.326,0,0,1-1.759-1.76,3.277,3.277,0,0,1,0-2.549,3.282,3.282,0,0,1,3.034-1.986"
      transform="translate(0 -0.001)"
    />
  </svg>
</template>
