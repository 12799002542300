<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 148.16 148.16" style="enable-background:new 0 0 148.16 148.16;" xml:space="preserve">

<g id="Group_28375" transform="translate(-611.5 -885.5)">
	<g id="Group_28376">
		<path id="Union_1" class="st5" d="M620.58,1003.13l43.34-43.34l-43.33-43.33l21.66-21.66l43.33,43.33l43.33-43.33l21.66,21.66
			l-43.33,43.33l43.34,43.34l-21.66,21.66l-43.34-43.34l-43.34,43.34L620.58,1003.13z"/>
	</g>
</g>
</svg>
</template>

<style type="text/css" scoped>
	.st0{fill:#F0F2F9;stroke:#D4DBE8;stroke-miterlimit:10;}
	.st1{fill:#F7F8FB;stroke:#D4DBE8;stroke-miterlimit:10;}
	.st2{fill:#A7B1C6;stroke:#A7B1C6;stroke-miterlimit:10;}
	.st3{fill:#FFFFFF;stroke:#A7B1C6;stroke-miterlimit:10;}
	.st4{fill:#A7B1C6;}
	.st5{fill:#FFFFFF;}
	.st6{fill:#F0F2F9;}
	.st7{fill:none;}
	.st8{fill:#D4DBE8;stroke:#A7B1C6;stroke-miterlimit:10;}
	.st9{fill:#D5DCE9;}
	.st10{fill:#D5DCE9;stroke:#A7B1C6;stroke-miterlimit:10;}
	.st11{fill:#F0F1F5;stroke:#A7B1C6;stroke-miterlimit:10;}
	.st12{fill:#7F8AA1;}
	.st13{fill:#F1F3FA;}
	.st14{clip-path:url(#SVGID_00000129200676273551137540000004463007546241108648_);}
	.st15{fill:#FFFFFF;stroke:#D4DBE8;stroke-miterlimit:10;}
	.st16{fill:#F0F2F9;stroke:#D4DBE8;stroke-linecap:round;stroke-miterlimit:10;}
	.st17{fill:#D4DDE9;stroke:#91A8C7;stroke-miterlimit:10;}
	.st18{fill:#FFFFFF;stroke:#91A8C7;stroke-miterlimit:10;}
</style>