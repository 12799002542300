<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="36.688" height="15.056" viewBox="0 0 36.688 15.056">
    <g id="Group_44797" data-name="Group 44797" transform="translate(-3992.116 -3080)">
      <g id="Group_44735" data-name="Group 44735" transform="translate(9.116)">
        <path id="Path_28730" data-name="Path 28730" d="M4.5,0,9,5H0Z" transform="translate(4019.688 3090.5) rotate(180)" fill="#0f2a4e" />
        <g id="Group_44733" data-name="Group 44733" transform="translate(119.616)">
          <g id="Group_44729" data-name="Group 44729">
            <path
              id="Path_28725"
              data-name="Path 28725"
              d="M5107.956-141.545h-9.507"
              transform="translate(-1225.949 3222.545)"
              fill="none"
              stroke="#0f2a4e"
              stroke-linecap="round"
              stroke-width="2"
            />
            <path
              id="Path_28726"
              data-name="Path 28726"
              d="M5103.543-141.545h-5.094"
              transform="translate(-1225.95 3226.897)"
              fill="none"
              stroke="#0f2a4e"
              stroke-linecap="round"
              stroke-width="2"
            />
          </g>
          <g id="Group_44728" data-name="Group 44728">
            <path
              id="Path_28727"
              data-name="Path 28727"
              d="M5108.637-141.545h-10.187"
              transform="translate(-1225.949 3231.249)"
              fill="none"
              stroke="#0f2a4e"
              stroke-linecap="round"
              stroke-width="2"
            />
            <path
              id="Path_28728"
              data-name="Path 28728"
              d="M5103.543-141.545h-5.094"
              transform="translate(-1225.95 3235.601)"
              fill="none"
              stroke="#0f2a4e"
              stroke-linecap="round"
              stroke-width="2"
            />
          </g>
        </g>
        <g id="Group_44734" data-name="Group 44734" transform="translate(-1)">
          <path id="Polygon_42" data-name="Polygon 42" d="M3,0,6,3H0Z" transform="translate(3984 3080)" fill="#0080ff" />
          <path id="Polygon_43" data-name="Polygon 43" d="M3,0,6,3H0Z" transform="translate(3990 3095) rotate(180)" fill="#0080ff" />
          <line id="Line_98" data-name="Line 98" y2="12" transform="translate(3987 3080.5)" fill="none" stroke="#0080ff" stroke-width="1" />
        </g>
      </g>
    </g>
  </svg>
</template>
