<template>
  <svg
    id="Group_33303"
    data-name="Group 33303"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="17.27"
    height="14.388"
    viewBox="0 0 17.27 14.388"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_14395" data-name="Rectangle 14395" width="17.27" height="14.388" />
      </clipPath>
    </defs>
    <g id="Group_33302" data-name="Group 33302" clip-path="url(#clip-path)">
      <path
        id="Path_22416"
        data-name="Path 22416"
        d="M10.894,0a3.577,3.577,0,1,0,3.577,3.577A3.578,3.578,0,0,0,10.894,0m0,5.652a2.075,2.075,0,1,1,2.075-2.075,2.077,2.077,0,0,1-2.075,2.075"
        transform="translate(-3.651 0)"
      />
      <path
        id="Path_22417"
        data-name="Path 22417"
        d="M7.091,21.339a.749.749,0,0,0-.739-.694H2.6a1.062,1.062,0,0,1-1.038-.775.992.992,0,0,1,.389-1.164,9.278,9.278,0,0,1,5.3-1.655,8.989,8.989,0,0,1,1.134.074.762.762,0,0,0,.775-.386.751.751,0,0,0-.554-1.1,10.623,10.623,0,0,0-1.355-.087A10.784,10.784,0,0,0,1.092,17.47a2.579,2.579,0,0,0,1.5,4.677H6.353a.734.734,0,0,0,.739-.794l0-.015"
        transform="translate(0 -7.76)"
      />
      <path
        id="Path_22418"
        data-name="Path 22418"
        d="M25.455,18.819l-3.5-6.5a.968.968,0,0,0-1.7,0l-3.5,6.5a.968.968,0,0,0,.852,1.427h7a.968.968,0,0,0,.852-1.427m-7.258.105,2.907-5.4,2.907,5.4Z"
        transform="translate(-8.303 -5.897)"
      />
      <path
        id="Path_22419"
        data-name="Path 22419"
        d="M25.164,20.73a.288.288,0,0,1-.287-.27l-.113-1.838a.4.4,0,0,1,.107-.3.4.4,0,0,1,.686.3l-.105,1.838a.288.288,0,0,1-.288.272"
        transform="translate(-12.359 -9.083)"
      />
      <path id="Path_22420" data-name="Path 22420" d="M25.261,24.138a.3.3,0,1,1,.3-.3.3.3,0,0,1-.3.3" transform="translate(-12.459 -11.751)" />
    </g>
  </svg>
</template>
