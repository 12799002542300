import { ref, reactive, computed, getCurrentInstance, watch, onBeforeMount } from 'vue';

interface Props {
  label: string;
  modelValue: any;
  options?: any;
  disabled?: any;
  searchable?: boolean;
}

export default function useNativeCustomSelector(props: Props) {
  interface DropDownForm {
    searchQuery: string;
    selectedOption: Record<string, any>;
  }
  const vm = getCurrentInstance()?.proxy;

  const isVisible = ref(false);

  const dropDownForm: DropDownForm = reactive({
    searchQuery: '',
    selectedOption: {
      title: '',
    },
  });

  const onOpenDropdown = () => {
    isVisible.value = !isVisible.value;
    vm?.$emit('check-dropdown-expanded', isVisible.value);
  };

  const onChangeSelectedOption = (option: Record<string, any>) => {
    vm?.$emit('update:modelValue', option);
    dropDownForm.selectedOption = option;
    dropDownForm.selectedOption.title = option[option.key];
    isVisible.value = false;
    vm?.$emit('check-dropdown-expanded', isVisible.value);
    vm?.$emit('on-change', option);
  };

  const clearOptionSelected = () => {
    vm?.$emit('update:modelValue', null);
    vm?.$emit('on-change', null);
    dropDownForm.selectedOption = {};
    isVisible.value = false;
  };

  const filteredOption = computed(() => {
    const query = dropDownForm.searchQuery.toLowerCase();
    if (dropDownForm.searchQuery === '') {
      return mappingKey(props.options);
    }
    return mappingKey(props.options).filter((option: Record<string, any>) => {
      return option[option.key].toLowerCase().includes(query);
    });
  });

  const mappingKey = (payload: any) => {
    const tempArr: Array<any> = [];
    payload.forEach((o: any) => {
      return tempArr.push({
        key: props.label,
        ...o,
      });
    });
    return tempArr;
  };

  onBeforeMount(() => {
    if (props.label != null || props.label != undefined) {
      if (props.label == 'table_label') {
        dropDownForm.selectedOption = props.modelValue;
        dropDownForm.selectedOption.title = props.modelValue.table_label;
      }
    }
    if (props.modelValue) {
      dropDownForm.selectedOption = props.modelValue;
      dropDownForm.selectedOption.title = props.modelValue[props.label];
    }
  });

  return {
    isVisible,
    filteredOption,
    dropDownForm,
    onChangeSelectedOption,
    clearOptionSelected,
    onOpenDropdown,
  };
}
