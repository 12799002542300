export interface ActivityKey {
  COMM_SMS: string;
  COMM_LINE: string;
  COMM_EMAIL: string;
  PURCHASED_CRMPOS: string;
  PURCHASED_CSV: string;
  POINT_BURN: string;
  POINT_EARN: string;
  Ticket_CLOSE: string;
  Ticket_OPEN: string;
  Ticket_OTHERS: string;
}

// declare namespace INS{
export const ActivityKey: ActivityKey = {
  COMM_SMS: 'Activities_Comm_SMS',
  COMM_LINE: 'Activities_Comm_Line',
  COMM_EMAIL: 'Activities_Comm_Email',
  PURCHASED_CRMPOS: 'Activities_Purchased_CRMPOS',
  PURCHASED_CSV: 'Activities_Purchased_Csv',
  POINT_BURN: 'Activities_Point_Burn',
  POINT_EARN: 'Activities_Point_Earn',
  Ticket_CLOSE: 'Activities_Ticket_Close',
  Ticket_OPEN: 'Activities_Ticket_Open',
  Ticket_OTHERS: 'Activities_Ticket_Others',
};
// }

interface PropertiesGroupName {
  CustomerInfographic: string;
  Profile: string;
  Address: string;
  PDPA: string;
}

interface PropertiesName {
  Image: string;
  FirstName: string;
  LastName: string;
  Tier: string;
  SMSLineMailSubscribe: string;
  NotificationSubscribe: string;
  LastVisit: string;
  CurrentPoint: string;
  TotalPoint: string;
  MergeSubEl: string;
  MergePointEl: string;
  NickName: string;
  CustomerMembershipSince: string;
  Birthday: string;
  Age: string;
  Gender: string;
  Status: string;
  Phone: string;
  LineUID: string;
  Email: string;
  IDCard: string;
  CustomerGroup: string;
  FullAddress: string;
  Address: string;
  Province: string;
  District: string;
  Subdistrict: string;
  PostalCode: string;
  Version: string;
  Channel: string;
  LatestUpdate: string;
}

interface PropertiesColType {
  Image: number;
  FirstName: number;
  LastName: number;
  Tier: number;
  SMSLineMailSubscribe: number;
  NotificationSubscribe: number;
  LastVisit: number;
  CurrentPoint: number;
  TotalPoint: number;
  MergePointEl: number;
  MergeSubEl: number;
  NickName: number;
  CustomerMembershipSince: number;
  Birthday: number;
  Age: number;
  Gender: number;
  MartialStatus: number;
  Status: number;
  Phone: number;
  LineUID: number;
  Email: number;
  IDCard: number;
  CustomerGroup: number;
  FullAddress: number;
  Address: number;
  Province: number;
  District: number;
  Subdistrict: number;
  PostalCode: number;
  Version: number;
  Channel: number;
  LatestUpdate: number;
}

interface ColumnName {
  PDPA_SMS_Flag: string;
  PDPA_Email_Flag: string;
  PDPA_LineOA_Flag: string;
}

export const InfoGroupPropName: PropertiesGroupName = {
  CustomerInfographic: 'Customer Infographic',
  Profile: 'Profile',
  Address: 'Address',
  PDPA: 'PDPA',
};

export const InfoPropName: PropertiesName = {
  Image: 'Image',
  FirstName: 'FirstName',
  LastName: 'LastName',
  Tier: 'Tier',
  SMSLineMailSubscribe: 'SMS,Line,Mail Subscribe',
  NotificationSubscribe: 'Notification Subscribe',
  LastVisit: 'Last visit',
  CurrentPoint: 'Current Point',
  TotalPoint: 'Total Point',
  MergeSubEl: 'Merge Sub El',
  MergePointEl: 'Merge Point El',
  NickName: 'Nick name',
  CustomerMembershipSince: 'Customer/Membership Since',
  Birthday: 'Birthday',
  Age: 'Age',
  Gender: 'Gender',
  Status: 'Status',
  Phone: 'Phone',
  LineUID: 'Line UID',
  Email: 'Email',
  IDCard: ' ID Card',
  CustomerGroup: 'Customer Group',
  FullAddress: 'Full Address',
  Address: 'Address',
  Province: 'Province',
  District: 'District',
  Subdistrict: 'Subdistrict',
  PostalCode: 'Postal code',
  Version: 'Version',
  Channel: 'Channel',
  LatestUpdate: 'Latest update',
};

export const ColumnName: ColumnName = {
  PDPA_SMS_Flag: 'SMS Subscribe',
  PDPA_Email_Flag: 'Email Subscribe',
  PDPA_LineOA_Flag: 'Line Subscribe',
};

export const CustomColType = {
  MergePointEl: 990001,
  MergeSubEl: 990002,
};

export const InfoPropColType: PropertiesColType = {
  Image: 100007,
  FirstName: 100003,
  LastName: 100005,
  Tier: 100045,
  SMSLineMailSubscribe: 400001,
  NotificationSubscribe: 400002,
  LastVisit: 100017,
  CurrentPoint: 100014,
  TotalPoint: 100016,
  MergePointEl: CustomColType.MergePointEl,
  MergeSubEl: CustomColType.MergeSubEl,
  NickName: 100006,
  CustomerMembershipSince: 100053,
  Birthday: 100010,
  Age: 100011,
  Gender: 100009,
  MartialStatus: 100012,
  Phone: 100008,
  LineUID: 100028,
  Email: 100013,
  IDCard: 100020,
  CustomerGroup: 100050,
  FullAddress: 100029,
  Address: 100030,
  Province: 100038,
  District: 100037,
  Subdistrict: 100036,
  PostalCode: 100040,
  Version: 400004,
  Status: 400005,
  Channel: 0,
  LatestUpdate: 400006,
};
