<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
    <g id="Group_44761" data-name="Group 44761" transform="translate(-3362 -3276)">
      <g id="Rectangle_17106" data-name="Rectangle 17106" transform="translate(3362 3276)" fill="none" stroke="#ff4848" stroke-width="1">
        <rect width="29" height="29" rx="4" stroke="none" />
        <rect x="0.5" y="0.5" width="28" height="28" rx="3.5" fill="none" />
      </g>
      <g id="Group_44762" data-name="Group 44762">
        <path
          id="Path_28750"
          data-name="Path 28750"
          d="M0,13.065,13.16,1.1"
          transform="translate(3369.92 3283.418)"
          fill="none"
          stroke="#ff4848"
          stroke-width="1"
        />
        <path
          id="Path_28751"
          data-name="Path 28751"
          d="M13.16,13.065,0,1.1"
          transform="translate(3369.92 3283.418)"
          fill="none"
          stroke="#ff4848"
          stroke-width="1"
        />
      </g>
    </g>
  </svg>
</template>
