import { Tooltip } from 'bootstrap';
import { Directive, Ref, ref } from 'vue';
import { BtTooltipsOption } from './option';

// generate HTML template for tooltips
// see: https://getbootstrap.com/docs/5.1/components/tooltips/#options
function generateTemplateForTooltips(symId: symbol): string {
  // return the default template with wrapper for color variables
  return `<div class="tooltip" role="tooltip"><div id="${symId.description!}" style="--tooltip-bg-color: black; --tooltip-color: white"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div></div>`;
}

// apply tooltips wrapper css (e.g. update css color)
function applyTooltipsWrapperCss(baseEl: Element & ElementCSSInlineStyle, wrapperEl: Element & ElementCSSInlineStyle, option?: BtTooltipsOption) {
  // if colors is not passed via option, try to retrieve via its binding element
  // background color
  let backgroundColor: string;
  if (!!option?.bgColor) {
    backgroundColor = option.bgColor;
  } else {
    backgroundColor = baseEl.getAttribute('tooltip-bg-color') ?? 'black';
  }

  // color
  let color: string;
  if (!!option?.color) {
    color = option.color;
  } else {
    color = baseEl.getAttribute('tooltip-color') ?? 'white';
  }

  // apply css variable to wrapper
  wrapperEl.style.setProperty('--tooltip-bg-color', backgroundColor);
  wrapperEl.style.setProperty('--tooltip-color', color);
}

// increment id for wrapper
let wrapperIncrement = 1;

// directive object
export default <Directive>{
  mounted: (el: Element & ElementCSSInlineStyle, binding, vnode) => {
    const option = binding.value as BtTooltipsOption | undefined;

    // prepare wrapper id
    const wrapperId = Symbol(`bt-tooltip-${wrapperIncrement++}`);

    // prepare tooltips template
    const template = generateTemplateForTooltips(wrapperId);

    // prepare tooltip option: override default allow list (html sanitizer)
    const allowList = Tooltip.Default.allowList;
    allowList.div.push('style');

    // create and bound tooltips option, id and instance to its directive vnode that apply to
    // NOTE: any, because we don't care the instance of vnode (@meranote)
    const refOption = ((vnode as any).btTooltipOption = ref(option)); // reactive
    (vnode as any).btTooltipId = wrapperId;
    (vnode as any).btTooltipInstance = new Tooltip(el, { allowList, template });

    // add tooltips listener for override colors
    // inserted: after template DOM has been created and inserted into container
    el.addEventListener('inserted.bs.tooltip', () => {
      // get wrapper element
      const wrapperEl = document.getElementById(wrapperId.description!)!;

      // apply wrapper css
      applyTooltipsWrapperCss(el, wrapperEl, refOption.value);
    });

    el.addEventListener('click', () => {
      (vnode as any).btTooltipInstance.hide();
    });
  },
  updated: (el: Element & ElementCSSInlineStyle, binding, vnode, prevVNode) => {
    // transfer vnode properties
    (vnode as any).btTooltipOption = (prevVNode as any).btTooltipOption;
    (vnode as any).btTooltipId = (prevVNode as any).btTooltipId;
    (vnode as any).btTooltipInstance = (prevVNode as any).btTooltipInstance;

    // update option
    const option = binding.value as BtTooltipsOption | undefined;
    const refOption = (vnode as any).btTooltipOption as Ref<BtTooltipsOption | undefined>;
    refOption.value = option;

    // immediate update the wrapper css, if tooltip is currently showing
    const wrapperId = (vnode as any).btTooltipId as symbol;
    const wrapperEl = document.getElementById(wrapperId.description!);
    if (!!wrapperEl) {
      // apply wrapper css
      applyTooltipsWrapperCss(el, wrapperEl, refOption.value);
    }
  },
  unmounted: (_el: Element & ElementCSSInlineStyle, _binding, vnode) => {
    const wrapperId = (vnode as any).btTooltipId as symbol;
    const wrapperEl = document.getElementById(wrapperId.description!);

    if (wrapperEl) {
      wrapperEl.remove();
    }
  },
};
