import { DateTime, Zone } from 'luxon';
import { computed, isRef, ref, Ref, unref, watch, watchEffect } from 'vue';
import { CHOCO_DATETIME_FORMAT_OBJECT } from '../identifier';
import { getZone, isUseDateTimeObject, UseDateTimeObject } from './index';
import { checkTimezone, createDateTime } from './utility';

/**
 * DateTime formatter composition
 *
 * @param date Date to be used for formatting, accept UseDateTimeObject for auto timezone
 * @param timezone Timezone to be working on, IANA identifier or offset in minutes
 */
function this_useDateTimeFormat(
  date: UseDateTimeObject | Ref<Date | null> | Date | null,
  timezone: Ref<Zone | string | number | null> | Zone | string | number | null = null,
) {
  // region initialize models & tracking

  // determine timezone to use
  // if value is useDateTime() object, use timezone from the composed
  let targetTimezone: Ref<Zone | string | number | null> | Zone | string | number | null;
  if (isUseDateTimeObject(date)) {
    targetTimezone = date.timezone;
  }
  // else, use given timezone
  else {
    targetTimezone = timezone;
  }

  // check provided timezone
  if (targetTimezone) {
    if (!checkTimezone(unref(targetTimezone))) {
      throw new Error(`Invalid timezone provided`);
    }
  }

  // initialize timezone model
  const modelTimezone = ref<Zone>(getZone(unref(targetTimezone)));

  // initialize model (date instance)
  const unrefValue = unref(date);
  const model = ref<DateTime | null>(
    unrefValue ? createDateTime(isUseDateTimeObject(unrefValue) ? unrefValue.date.value : unrefValue, unref(targetTimezone)) : null,
  );
  // if value is useDateTime() object, watch its date effect and update model
  if (isUseDateTimeObject(date)) {
    watchEffect(() => {
      model.value = createDateTime(date.date.value, date.timezone.value);
    });
  }

  // if timezone is also a ref, watch to validate and update timezone
  if (isRef(targetTimezone)) {
    watch(targetTimezone, (value) => {
      if (!checkTimezone(value)) {
        throw new Error(`Invalid timezone provided`);
      }
      modelTimezone.value = getZone(value);
      model.value = model.value?.setZone(modelTimezone.value) ?? null;
    });
  }

  // if init value is also a ref, watch to update model
  if (isRef(date)) {
    watch(date, (value) => {
      if (value) {
        model.value = createDateTime(value, modelTimezone.value);
      } else {
        model.value = null;
      }
    });
  }

  // endregion

  // region functions

  const hasDate = computed(() => model.value !== null);

  const toFormat = (format: string, toUtc: boolean = false) => {
    if (model.value) {
      return (toUtc ? model.value.toUTC() : model.value).toFormat(format);
    } else {
      return null;
    }
  };
  const toISOFormat = () => toFormat("yyyy-MM-dd'T'HH:mm:ssZZ");
  const toISODateFormat = () => toFormat('yyyy-MM-dd');
  const toUTCISOFormat = () => toFormat("yyyy-MM-dd'T'HH:mm:ssZZ", true);
  const toUTCISODateFormat = () => toFormat('yyyy-MM-dd', true);

  // endregion

  return {
    [CHOCO_DATETIME_FORMAT_OBJECT]: 1,
    timezone: computed(() => modelTimezone.value),
    hasDate,
    toFormat,
    toISOFormat,
    toISODateFormat,
    toUTCISOFormat,
    toUTCISODateFormat,
  };
}

/**
 * DateTime composition object
 */
export type UseDateTimeFormatObject = Omit<ReturnType<typeof this_useDateTimeFormat>, typeof CHOCO_DATETIME_FORMAT_OBJECT>;

/**
 * DateTime composition
 *
 * @param init Initial date, null for default
 * @param timezone Timezone to convert to, IANA identifier or offset in minutes
 * @param options Use date-time option
 */
export const useDateTimeFormat: (...args: Parameters<typeof this_useDateTimeFormat>) => UseDateTimeFormatObject = this_useDateTimeFormat;

/**
 * Type guard for check if given value is a useDateTime() composition object
 * @param value
 */
export function isUseDateTimeFormatObject(value: unknown): value is UseDateTimeFormatObject {
  return typeof value === 'object' && value !== null && CHOCO_DATETIME_FORMAT_OBJECT in (value as object);
}
