<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="39.176" height="25.919" viewBox="0 0 39.176 25.919">
    <g id="Group_33409" data-name="Group 33409">
      <path
        id="Path_22465"
        data-name="Path 22465"
        d="M36.779,0H10.615a2.4,2.4,0,0,0-2.4,2.4V5.482H2.4A2.4,2.4,0,0,0,0,7.879V23.522a2.4,2.4,0,0,0,2.4,2.4H28.561a2.4,2.4,0,0,0,2.4-2.4V20.436h5.822a2.4,2.4,0,0,0,2.4-2.4V2.4a2.4,2.4,0,0,0-2.4-2.4M27.85,22.812H3.107V15.7H27.85Zm0-10.516H3.107V8.589H27.85Zm8.219,5.033H30.957V10.218h5.112Zm0-10.516H30.7a2.388,2.388,0,0,0-2.136-1.332H11.326V3.107H36.069Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
</template>
