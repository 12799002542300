<template>
  <svg width="81" height="65" viewBox="0 0 81 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M78.8936 16.793H1.37109V19.2549H78.8936V16.793Z" fill="white" />
    <path d="M78.8936 46.582H1.37109V48.8113H78.8936V46.582Z" fill="white" />
    <path
      d="M61.8027 52.2871H18.4652C17.9602 52.2871 17.5508 52.6965 17.5508 53.2015C17.5508 53.7066 17.9602 54.116 18.4652 54.116H61.8027C62.3077 54.116 62.7171 53.7066 62.7171 53.2015C62.7171 52.6965 62.3077 52.2871 61.8027 52.2871Z"
      fill="white"
    />
    <path
      d="M21.8242 54.7453V51.6584C21.8242 51.1567 21.4175 50.75 20.9158 50.75C20.4141 50.75 20.0074 51.1567 20.0074 51.6584V54.7453C20.0074 55.247 20.4141 55.6537 20.9158 55.6537C21.4175 55.6537 21.8242 55.247 21.8242 54.7453Z"
      fill="white"
    />
    <path
      d="M12.5911 13.8951C14.0674 13.8951 15.2642 12.6983 15.2642 11.222C15.2642 9.74563 14.0674 8.54883 12.5911 8.54883C11.1148 8.54883 9.91797 9.74563 9.91797 11.222C9.91797 12.6983 11.1148 13.8951 12.5911 13.8951Z"
      fill="white"
    />
    <path
      d="M20.8802 13.8951C22.3565 13.8951 23.5533 12.6983 23.5533 11.222C23.5533 9.74563 22.3565 8.54883 20.8802 8.54883C19.4038 8.54883 18.207 9.74563 18.207 11.222C18.207 12.6983 19.4038 13.8951 20.8802 13.8951Z"
      fill="white"
    />
    <path
      d="M29.1692 13.8951C30.6455 13.8951 31.8423 12.6983 31.8423 11.222C31.8423 9.74563 30.6455 8.54883 29.1692 8.54883C27.6929 8.54883 26.4961 9.74563 26.4961 11.222C26.4961 12.6983 27.6929 13.8951 29.1692 13.8951Z"
      fill="white"
    />
    <path
      d="M36.7539 36.1714V28.8185C36.7535 28.7106 36.7825 28.6047 36.8378 28.512C36.8931 28.4194 36.9724 28.3436 37.0676 28.2928C37.1627 28.2419 37.2699 28.2179 37.3776 28.2234C37.4854 28.2289 37.5894 28.2636 37.6789 28.3239L43.113 32.0003C43.1938 32.0551 43.2598 32.1288 43.3055 32.215C43.3513 32.3012 43.3751 32.3974 43.3751 32.495C43.3751 32.5926 43.3513 32.6887 43.3055 32.7749C43.2598 32.8612 43.1938 32.9349 43.113 32.9896L37.6789 36.6661C37.5894 36.7263 37.4854 36.761 37.3776 36.7665C37.2699 36.772 37.1627 36.748 37.0676 36.6972C36.9724 36.6463 36.8931 36.5705 36.8378 36.4778C36.7825 36.3852 36.7535 36.2793 36.7539 36.1714Z"
      fill="white"
    />
    <path
      d="M39.3807 44.6424C36.9783 44.6424 34.63 43.93 32.6325 42.5953C30.6351 41.2607 29.0784 39.3637 28.159 37.1442C27.2397 34.9248 26.9991 32.4825 27.4677 30.1263C27.9364 27.7702 29.0933 25.6059 30.792 23.9072C32.4907 22.2085 34.6549 21.0517 37.0111 20.583C39.3672 20.1143 41.8093 20.3549 44.0288 21.2742C46.2483 22.1935 48.1452 23.7503 49.4798 25.7478C50.8145 27.7453 51.527 30.0937 51.527 32.496C51.5234 35.7163 50.2425 38.8038 47.9654 41.0809C45.6883 43.358 42.601 44.6388 39.3807 44.6424ZM39.3807 23.4693C37.5959 23.4693 35.8513 23.9985 34.3673 24.9901C32.8833 25.9817 31.7267 27.391 31.0437 29.0399C30.3607 30.6889 30.1817 32.5033 30.5299 34.2538C30.8781 36.0043 31.7377 37.6122 32.9997 38.8742C34.2618 40.1363 35.8696 40.9958 37.6201 41.344C39.3706 41.6922 41.185 41.5135 42.8339 40.8305C44.4828 40.1475 45.8922 38.9908 46.8837 37.5068C47.8753 36.0228 48.4047 34.2781 48.4047 32.4933C48.4021 30.1006 47.4507 27.8065 45.759 26.1143C44.0673 24.4222 41.7734 23.4699 39.3807 23.4666V23.4693Z"
      fill="white"
    />
    <path
      d="M75.3746 4.63034e-08H4.88574C4.24412 -8.77569e-05 3.60865 0.126261 3.01585 0.371759C2.42305 0.617256 1.88459 0.977119 1.4309 1.43081C0.977201 1.88451 0.617256 2.42313 0.371759 3.01593C0.126261 3.60873 -8.77575e-05 4.24403 4.57312e-08 4.88566V59.5948C-8.77575e-05 60.2364 0.126261 60.8717 0.371759 61.4645C0.617256 62.0573 0.977201 62.596 1.4309 63.0497C1.88459 63.5034 2.42305 63.8632 3.01585 64.1087C3.60865 64.3542 4.24412 64.4806 4.88574 64.4805H75.3746C76.0163 64.4806 76.652 64.3544 77.2449 64.109C77.8377 63.8635 78.3764 63.5036 78.8301 63.0499C79.2839 62.5962 79.6437 62.0575 79.8893 61.4647C80.1348 60.8719 80.2611 60.2365 80.261 59.5948V4.8797C80.2594 3.58486 79.7441 2.34357 78.8279 1.42861C77.9116 0.513649 76.6695 -0.000178092 75.3746 4.63034e-08ZM73.6722 57.8923H6.58819V6.58819H73.6722V57.8923Z"
      fill="white"
    />
  </svg>
</template>
