import { ref, Ref, getCurrentInstance, watch } from 'vue';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import api from '@services/api';
// models
import templateModel from '@/models/communication/template';

// constants
import { NODE_ACTION_LINE_SUB_TYPE } from '@/constants/modules/mka/mkaDetail';
import { RICH_MESSAGE_TEMPLATE } from '@/views/modules/communication/pages/line-oa-campaign/constants/richMessage';
import { ContentTypeEnum } from '@/views/modules/communication/pages/line-oa-campaign/constants/contentType.enum';

interface Props {
  //setup props

  isOpen: boolean;
  nodeProps: MKA_Nodes.NodesProps;
  dataInfo: MKA_Nodes.NodeDataInfo.DataInfo;
  templateList: number[];
}

export default function useDataInfoPreview(props: Props) {
  // constants
  const defaultTemplate: Communication.Template.Template = {
    brand_id: 0,
    category_id: 0,
    category_name: '',
    channel: 0,
    channel_desc: '',
    contents: [],
    created_by: 0,
    created_by_req: 0,
    created_dt: '',
    description: '',
    is_deleted: false,
    name: '',
    status: 0,
    status_desc: '',
    subtype: 0,
    subtype_desc: '',
    template_id: 0,
    thumbnail_sref: '',
    thumbnail_url: '',
    type: 0,
    type_desc: '',
    updated_by: '',
    updated_by_req: '',
    updated_dt: '',
  };
  const vm = getCurrentInstance()?.proxy;
  const { openErrorModal } = useValidationModal();

  const { fetchTemplateDetailModel } = templateModel();

  const edmTemplatePreview = ref<Communication.Template.Template>({ ...defaultTemplate });

  // loadings
  const loading = ref<boolean>(false);
  const loadingTemplate = ref<boolean>(false);

  const currentChannel = ref<Communication.Campaign.ChannelType>(0);

  //messages
  const messageList = ref<Communication.LineOA.Component.LineMessage[]>([]);
  const cardMessageList = ref<Communication.LineOA.Component.CardMessge[]>([]);
  const richMessageList: Ref<Communication.LineOA.Component.RichMessage> = ref(null!);

  const previewMixMessageList: Ref<Communication.LineOA.Component.LineMessage[]> = ref([]);

  const keyChange = ref<number>(1);
  const dataInfo = ref<MKA_Nodes.NodeDataInfo.DataInfo>(props.dataInfo);

  const subTypeLine = ref<number>(0);

  const fetchTemplateSubType = () => {
    loadingTemplate.value = true;

    subTypeLine.value = 0;

    fetchTemplateDetailModel.payload.TemplateId = props.templateList[0];
    return api
      .apiRequest(fetchTemplateDetailModel)
      .then((response) => {
        const responseData = response.data as Communication.Template.Template;

        // lineTemplatePreview.value = responseData;
        subTypeLine.value = responseData.subtype;
        Promise.resolve();
      })
      .catch((err) => {
        openErrorModal(err as string);
        Promise.reject();
      })
      .finally(() => {
        loadingTemplate.value = false;
      });
  };

  const resolveDisplayMessageSms = () => {
    const contents: Communication.Template.TemplateContent[] = props.nodeProps.payload.contents;
    const results: any = [];

    if (contents && contents.length) {
      contents.forEach((content) => {
        const result = {
          key: keyChange.value,
          sender: dataInfo.value.sender_name || '',
          message: content.content_editor ? formatTextSMS(content.content_editor) : '',
        };
        results.push(result);
        keyChange.value++;
      });
    } else {
      results.push({ key: keyChange.value, sender: dataInfo.value.sender_name || '', message: '' });
      keyChange.value++;
    }
    return results;
  };

  function buildObjectDisplayMessage(resolvedData: Communication.LineOA.Component.LineMessage) {
    const defaultLineMessage: Communication.LineOA.Component.LineMessage = {
      key: 0,
      sender: '',
      message: '',
      imageUrl: '',
      videoUrl: '',
      audioUrl: '',
      richMessage: undefined,
      cardMessageList: [],
      previewImageUrl: '',
      duration: 0,
      sref: '',
      thumbnail: {
        url: '',
        sref: '',
      },
    };
    const combinedObject = { ...defaultLineMessage, ...resolvedData };

    return combinedObject;
  }

  const resolveDisplayMessageLine = (contents: Communication.Template.TemplateContent[], isMixType = false) => {
    const resolvedList: Communication.LineOA.Component.LineMessage[] = [];

    if (contents && contents.length) {
      contents.forEach((content) => {
        if (content.content) {
          const contentOBJ = JSON.parse(content.content);
          let displayContent: Communication.LineOA.Component.LineMessage = { key: keyChange.value };
          if (contentOBJ.type == 'text') {
            displayContent = {
              key: keyChange.value,
              sender: dataInfo.value.sender_name || '',
              message: content.content_editor ? formatTextSMS(content.content_editor) : '',
            };
          } else if (contentOBJ.type == 'image') {
            displayContent = {
              key: keyChange.value,
              sender: dataInfo.value.sender_name || '',
              imageUrl: contentOBJ.previewImageUrl || '',
              previewImageUrl: contentOBJ.previewImageUrl,
              sref: contentOBJ.sref,
            };
          } else if (contentOBJ.type == 'video') {
            displayContent = {
              key: keyChange.value,
              sender: dataInfo.value.sender_name || '',
              videoUrl: contentOBJ.originalContentUrl || '',
              previewImageUrl: contentOBJ.previewImageUrl,
              sref: contentOBJ.sref,
            };
          } else if (contentOBJ.type == 'audio') {
            displayContent = {
              key: keyChange.value,
              sender: dataInfo.value.sender_name || '',
              audioUrl: contentOBJ.originalContentUrl || '',
              duration: contentOBJ.duration,
              sref: contentOBJ.sref,
            };
          } else {
            displayContent = { key: keyChange.value, sender: dataInfo.value.sender_name || '' };
          }
          resolvedList.push(displayContent);
          keyChange.value++;
        }
      });
    } else {
      resolvedList.push({ key: keyChange.value, sender: dataInfo.value.sender_name || '', message: '' });
      keyChange.value++;
    }

    if (!isMixType) {
      for (let i = 0; i < resolvedList.length; i++) {
        const objData = buildObjectDisplayMessage(resolvedList[i]);
        previewMixMessageList.value.push(objData);
      }
    } else {
      const objData = buildObjectDisplayMessage(resolvedList[0]);
      previewMixMessageList.value.push(objData);
    }

    return resolvedList;
  };

  function resolveDisplayRichMessage(contents: Communication.LineOA.CreateCampaign.Content[]) {
    let resolvedContent: Communication.LineOA.Component.RichMessage = null!;

    if (contents && contents.length) {
      contents.forEach((content) => {
        let contentEditor: any;
        if (isJsonString(content.content_editor) && content.content_editor) {
          contentEditor = JSON.parse(content.content_editor || '');
        } else {
          contentEditor = content.content_editor || '';
        }
        if (content.content_editor !== '{}') {
          resolvedContent = {
            ...contentEditor,
            gridType: RICH_MESSAGE_TEMPLATE.find((message) => message.key == contentEditor.gridType),
            sender: dataInfo.value.sender_name || '',
          };
        } else {
          resolvedContent = null!;
        }
        keyChange.value++;
      });
    } else {
      keyChange.value++;
      return resolvedContent;
    }

    // add data for send <preview-message>
    const defaultLineMessage: Communication.LineOA.Component.LineMessage = {
      key: keyChange.value - 1,
      sender: '',
      message: undefined,
      imageUrl: '',
      videoUrl: '',
      audioUrl: '',
      richMessage: resolvedContent,
      cardMessageList: [],
      previewImageUrl: '',
      duration: 0,
      sref: '',
      thumbnail: {
        url: '',
        sref: '',
      },
    };

    previewMixMessageList.value.push(defaultLineMessage);

    return (richMessageList.value = resolvedContent);
  }

  function resolveDisplayCard(contents: Communication.LineOA.CreateCampaign.Content[]) {
    let resolvedList: Communication.LineOA.Component.CardMessge[] = [];
    let url = '';
    let sref = '';

    if (contents && contents.length) {
      contents.forEach((content) => {
        let contentEditor: any;
        if (isJsonString(content.content_editor) && content.content_editor) {
          contentEditor = JSON.parse(content.content_editor || '');
        } else {
          contentEditor = content.content_editor || '';
        }
        if (content.content_editor !== '{}') {
          contentEditor.list_card.forEach((card: Communication.LineOA.Component.CardMessge) => {
            resolvedList.push({
              ...card,
              sender: dataInfo.value.sender_name || '',
            });
          });

          url = contentEditor.thumbnail?.url;
          sref = contentEditor.thumbnail?.sref;
        } else {
          resolvedList = [];
        }

        keyChange.value++;
      });
    } else {
      keyChange.value++;
      return resolvedList;
    }

    // add data for send <preview-message>
    const defaultLineMessage: Communication.LineOA.Component.LineMessage = {
      key: keyChange.value - 1,
      sender: '',
      message: undefined,
      imageUrl: '',
      videoUrl: '',
      audioUrl: '',
      richMessage: undefined,
      cardMessageList: resolvedList,
      previewImageUrl: '',
      duration: 0,
      sref: '',
      thumbnail: {
        url: url,
        sref: sref,
      },
    };
    previewMixMessageList.value.push(defaultLineMessage);

    return (cardMessageList.value = resolvedList);
  }

  function resolveRichVideoMessage(content: Communication.Template.TemplateContent[], isMixType = false) {
    let resolvedContent: Communication.LineOA.Component.RichVideoMessage = null!;

    if (content && content.length) {
      content.forEach((content) => {
        let contentEditor: any;
        if (isJsonString(content.content_editor as string) && content.content_editor) {
          contentEditor = JSON.parse(content.content_editor || '');
        } else {
          contentEditor = content.content_editor || '';
        }
        resolvedContent = { ...contentEditor };
        keyChange.value++;
      });
    } else {
      keyChange.value++;
      return resolvedContent;
    }

    // add data for send <preview-message>
    const defaultLineMessage: Communication.LineOA.Component.LineMessage = {
      key: keyChange.value - 1,
      sender: '',
      message: undefined,
      imageUrl: '',
      videoUrl: '',
      audioUrl: '',
      richMessage: null!,
      richVideoMessage: resolvedContent,
      cardMessageList: [],
      previewImageUrl: '',
      duration: 0,
      sref: '',
      thumbnail: {
        url: '',
        sref: '',
      },
    };
    previewMixMessageList.value.push(defaultLineMessage);
  }

  function resolveDisplayMultiMessage(contents: Communication.LineOA.CreateCampaign.Content[]) {
    const resolvedContent: Communication.LineOA.Component.RichMessage = null!;

    if (contents && contents.length) {
      contents.forEach((content) => {
        let contentEditor: any;
        if (isJsonString(content.content_editor) && content.content_editor) {
          contentEditor = JSON.parse(content.content_editor || '');
        } else {
          contentEditor = content.content_editor || '';
        }

        if (contentEditor.type == ContentTypeEnum.TEXT) {
          resolveDisplayMessageLine([content], true);
        } else if (contentEditor.type == ContentTypeEnum.IMAGE) {
          resolveDisplayMessageLine([content], true);
        } else if (contentEditor.type == ContentTypeEnum.VIDEO) {
          resolveDisplayMessageLine([content], true);
        } else if (contentEditor.type == ContentTypeEnum.AUDIO) {
          resolveDisplayMessageLine([content], true);
        } else if (contentEditor.type == ContentTypeEnum.RICH_MESSAGE) {
          resolveDisplayRichMessage([content]);
        } else if (contentEditor.type == ContentTypeEnum.CARD_MESSAGE) {
          resolveDisplayCard([content]);
        } else if (contentEditor.type == ContentTypeEnum.RICH_VIDEO_MESSAGE) {
          resolveRichVideoMessage([content]);
        }
      });
    } else {
      keyChange.value++;
      return resolvedContent;
    }
  }

  const onLoadDataInfoPreview = async () => {
    loading.value = true;

    if (props.nodeProps.type == 301) {
      messageList.value = resolveDisplayMessageSms();
    } else if (props.nodeProps.type == 302) {
      setTimeout(() => {
        edmTemplatePreview.value = { ...defaultTemplate, ...{ contents: props.nodeProps.payload.contents } };
      }, 100);
    } else if (props.nodeProps.type == 303) {
      await fetchTemplateSubType();

      const templateContents: Communication.Template.TemplateContent[] = props.nodeProps.payload.contents;
      previewMixMessageList.value = [];

      if (subTypeLine.value == NODE_ACTION_LINE_SUB_TYPE.MESSAGE) {
        resolveDisplayMessageLine(templateContents);
      } else if (subTypeLine.value == NODE_ACTION_LINE_SUB_TYPE.RICH_MESSAGE) {
        resolveDisplayRichMessage(templateContents as Communication.LineOA.CreateCampaign.Content[]);
      } else if (subTypeLine.value == NODE_ACTION_LINE_SUB_TYPE.CARD_MESSAGE) {
        resolveDisplayCard(templateContents as Communication.LineOA.CreateCampaign.Content[]);
      } else if (subTypeLine.value == NODE_ACTION_LINE_SUB_TYPE.RICH_VIDEO_MESSAGE) {
        resolveRichVideoMessage(templateContents as Communication.LineOA.CreateCampaign.Content[]);
      } else if (subTypeLine.value == NODE_ACTION_LINE_SUB_TYPE.MULTI_MESSAGE) {
        resolveDisplayMultiMessage(templateContents as Communication.LineOA.CreateCampaign.Content[]);
      }
    }
    loading.value = false;
  };
  function isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function formatTextSMS(smsText: string) {
    if (isJsonString(smsText)) {
      const parseMessage = JSON.parse(smsText);
      return decodeURI((parseMessage.text as string) || ''); // SMS Personalize
    } else {
      return smsText; // SMS Normal Text
    }
  }

  // modal action
  function onClickClose() {
    vm?.$emit('close-modal');
  }

  watch(
    [() => props.isOpen],
    () => {
      onLoadDataInfoPreview();
    },
    { immediate: true },
  );

  return {
    onLoadDataInfoPreview,
    onClickClose,
    edmTemplatePreview,
    messageList,
    loading,
    loadingTemplate,
    currentChannel,
    cardMessageList,
    richMessageList,
    previewMixMessageList,
  };
}
