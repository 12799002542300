import { Ability } from '@casl/ability';
import { initialAbility } from './config';
import authService from '@/services/authentication';

const existingAbility =
  JSON.parse(authService.getLocalBrandData()!)?.filter((o: Record<string, any>) => {
    return (
      o.brandRef == new URLSearchParams(window.location.search).get('brandRef') ||
      o.brandRef == sessionStorage.getItem('brandSessionData')
    );
  })?.length > 0
    ? [{ action: 'access', subject: 'cdp' }]
    : null;

export default new Ability(existingAbility || initialAbility);
