import { reactive } from 'vue';
import router from '@/router';
import apiService from '@/services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import segmentModel from '@/models/segment/segment';

export default function useEditRuleModal(dataSource: Segment.Datasource) {
  const { fetchDisplayColumnModel, fetchSegmentDetailModel } = segmentModel();
  const { openDefaultErrorModal } = useValidationModal();

  const editRuleModal: { isOpen: boolean; isLoading: boolean; columnList: DynamicTable.Column[]; segment: Segment.Segment } = reactive({
    isOpen: false,
    isLoading: false,
    columnList: [],
    segment: null!,
  });

  const fetchDisplayColumn = (): Promise<DynamicTable.Column[]> => {
    return new Promise((resolve, reject) => {
      fetchDisplayColumnModel.payload.TableId = dataSource.tableid;

      apiService
        .apiRequest(fetchDisplayColumnModel)
        .then((response) => {
          const columnList = response.data.payload.columns as DynamicTable.Column[];
          resolve(columnList);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const fetchSegmentDetail = (): Promise<Segment.Segment> => {
    return new Promise((resolve, reject) => {
      fetchSegmentDetailModel.payload.segmentId =
        Number(router.currentRoute.value.query.applyDestSegmentId) || Number(router.currentRoute.value.query.segmentId);

      apiService
        .apiRequest(fetchSegmentDetailModel)
        .then((response) => {
          resolve(response.data as Segment.Segment);
        })
        .catch((err) => reject(err));
    });
  };

  const onOpenEditRuleModal = async () => {
    try {
      editRuleModal.isOpen = true;
      editRuleModal.isLoading = true;

      editRuleModal.columnList = await fetchDisplayColumn();
      editRuleModal.segment = await fetchSegmentDetail();
    } catch (err) {
      editRuleModal.isOpen = false;
      openDefaultErrorModal(err);
    } finally {
      editRuleModal.isLoading = false;
    }
  };

  const onCloseEditRuleModal = () => {
    editRuleModal.isOpen = false;
  };

  return { editRuleModal, onOpenEditRuleModal, onCloseEditRuleModal };
}
