<template>
  <svg id="Group_48123" data-name="Group 48123" xmlns="http://www.w3.org/2000/svg" width="20.125" height="20.125" viewBox="0 0 20.125 20.125">
    <path
      id="Path_21306"
      data-name="Path 21306"
      d="M408,1011.546V994.979a1.779,1.779,0,0,1,1.779-1.779h16.566a1.779,1.779,0,0,1,1.779,1.779v16.566a1.779,1.779,0,0,1-1.779,1.779H409.779a1.779,1.779,0,0,1-1.779-1.779"
      transform="translate(-408 -993.2)"
      fill="#fff"
    />
    <g id="Group_32737" data-name="Group 32737" transform="translate(4.171 2.776)">
      <g id="Group_32736" data-name="Group 32736" transform="translate(1.346)">
        <path
          id="Path_21307"
          data-name="Path 21307"
          d="M425.519,1003.867a.145.145,0,0,1-.066.135c-.184.129-.364.262-.562.405v-4.416a.451.451,0,0,0-.5-.494h-7.307a.453.453,0,0,0-.506.505q0,1.029,0,2.058,0,1.119,0,2.239v.108c-.21-.152-.4-.292-.6-.436a.115.115,0,0,1-.029-.083c0-1.325,0-2.65,0-3.974A1.057,1.057,0,0,1,417,998.871c.87-.006,1.741,0,2.611,0h4.822a1.07,1.07,0,0,1,1.082,1.081"
          transform="translate(-415.951 -997.71)"
          fill="#2aa700"
        />
        <path
          id="Path_21308"
          data-name="Path 21308"
          d="M421.462,1001.221h2.678c.073,0,.146,0,.219.005a.312.312,0,0,1-.039.62c-.108,0-.216,0-.324,0h-5.328c-.035,0-.07,0-.1,0a.312.312,0,0,1,0-.625c.1-.006.2,0,.3,0h2.593"
          transform="translate(-416.658 -998.43)"
          fill="#2aa700"
        />
        <path
          id="Path_21309"
          data-name="Path 21309"
          d="M421.444,1003.335h2.83a.316.316,0,0,1,.349.278.31.31,0,0,1-.325.349c-.362,0-.724,0-1.086,0h-3.3c-.448,0-.895,0-1.342,0a.311.311,0,0,1-.292-.439.294.294,0,0,1,.285-.187c.308,0,.616,0,.924,0h1.954"
          transform="translate(-416.656 -999.077)"
          fill="#2aa700"
        />
        <path
          id="Path_21310"
          data-name="Path 21310"
          d="M421.44,1005.452q1.429,0,2.858,0a.314.314,0,1,1,0,.628q-1.282,0-2.564,0h-3.125a.323.323,0,0,1-.327-.2.308.308,0,0,1,.283-.43c.7,0,1.4,0,2.1,0h.781Z"
          transform="translate(-416.656 -999.725)"
          fill="#2aa700"
        />
        <path
          id="Path_21311"
          data-name="Path 21311"
          d="M420.993,998.076c.357-.268.713-.538,1.074-.8a.33.33,0,0,1,.393-.016c.378.266.743.549,1.137.843h-.738a.4.4,0,0,0-.058,0,.505.505,0,0,1-.464-.158.072.072,0,0,0-.12,0,.566.566,0,0,1-.474.161c-.249-.013-.5,0-.749,0l0-.024"
          transform="translate(-417.494 -997.2)"
          fill="#2aa700"
        />
        <path
          id="Path_21312"
          data-name="Path 21312"
          d="M429.783,1012.4v-7l-4.728,3.421,4.728,3.58"
          transform="translate(-418.737 -999.711)"
          fill="#2aa700"
        />
      </g>
      <path
        id="Path_21313"
        data-name="Path 21313"
        d="M414.011,1005.4v7l4.73-3.579-4.73-3.421"
        transform="translate(-414.011 -999.711)"
        fill="#2aa700"
      />
      <path
        id="Path_21314"
        data-name="Path 21314"
        d="M420.356,1010c-1.89,1.432-5.648,4.293-5.645,4.3h11.322l-5.677-4.3"
        transform="translate(-414.225 -1001.119)"
        fill="#2aa700"
      />
    </g>
  </svg>
</template>
