import { onBeforeMount, Ref, ref, watch, getCurrentInstance } from 'vue';

interface Props {
  channels: Communication.Channel.Component.AllChannelTab[];
  modelValue?: number;
  smsCreditRemaining?: number;
  emailCreditRemaining?: number;
  lineCreditRemaining?: number;
}

export default function useAllChannelTab(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const activeChannel: Ref<number> = ref(0);

  const setChannelActive = (index: number) => {
    activeChannel.value = index;
    vm?.$emit('update:modelValue', activeChannel.value);
  };

  watch(
    () => props.modelValue,
    () => {
      if (typeof props.modelValue == 'number' && props.modelValue >= 0) {
        activeChannel.value = props.modelValue;
      }
    },
  );

  onBeforeMount(() => {
    if (typeof props.modelValue == 'number' && props.modelValue >= 0) {
      activeChannel.value = props.modelValue;
    }
  });
  return { activeChannel, setChannelActive };
}
