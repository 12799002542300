<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20.239" height="19.795" viewBox="0 0 20.239 19.795">
    <g id="Group_115378" data-name="Group 115378" transform="translate(0.15 0.15)">
      <path
        id="Path_310064"
        data-name="Path 310064"
        d="M16.773,14.248a1.993,1.993,0,1,0,1.993,1.993,1.995,1.995,0,0,0-1.993-1.993m0,2.772a.779.779,0,1,1,.779-.779.78.78,0,0,1-.779.779"
        transform="translate(-2.504 -2.416)"
        fill="#606a6c"
        stroke="#606a6c"
        stroke-width="0.3"
      />
      <g id="Group_110560" data-name="Group 110560">
        <path
          id="Path_310065"
          data-name="Path 310065"
          d="M20.657,13.984h-.792c-.019-.048-.039-.1-.06-.144l.561-.561a1.036,1.036,0,0,0,0-1.464l-.667-.666a1.035,1.035,0,0,0-1.464,0l-.561.561c-.048-.022-.1-.041-.145-.06v-.793a1.036,1.036,0,0,0-1.035-1.035h-.943a1.037,1.037,0,0,0-1.036,1.035v.793c-.048.019-.1.038-.144.06l-.561-.561a1.036,1.036,0,0,0-1.464,0l-.666.666a1.034,1.034,0,0,0,0,1.464l.561.561c-.022.047-.042.1-.06.144h-.793a1.036,1.036,0,0,0-1.035,1.036v.943A1.036,1.036,0,0,0,11.389,17h.793q.027.072.06.145l-.561.56a1.037,1.037,0,0,0,0,1.464l.666.667a1.036,1.036,0,0,0,1.464,0l.561-.561c.047.021.1.041.144.06v.792a1.037,1.037,0,0,0,1.036,1.036h.943a1.036,1.036,0,0,0,1.035-1.036v-.792l.145-.06.56.561a1.038,1.038,0,0,0,1.464,0l.667-.667a1.036,1.036,0,0,0,0-1.464l-.561-.561c.021-.047.041-.1.06-.144h.792a1.036,1.036,0,0,0,1.036-1.035V15.02a1.037,1.037,0,0,0-1.036-1.036M19.47,12.518h0v0Zm-3.111-1.567Zm-.712.042v0Zm-2.6,1.052Zm-.473.532Zm-1.052,3.291Zm1.052,2.6h0v0ZM16.4,19.989h0ZM19,18.938Zm1.483-3.153h-.973a.715.715,0,0,0-.684.515,2.927,2.927,0,0,1-.248.6.717.717,0,0,0,.119.849l.689.689-.415.415-.689-.689a.721.721,0,0,0-.505-.208.709.709,0,0,0-.344.089,2.89,2.89,0,0,1-.6.248.717.717,0,0,0-.516.685v.973H15.73v-.973a.715.715,0,0,0-.516-.684,2.92,2.92,0,0,1-.6-.248.712.712,0,0,0-.345-.089.721.721,0,0,0-.5.208l-.689.689-.415-.415.689-.689a.714.714,0,0,0,.119-.849,2.936,2.936,0,0,1-.248-.6.715.715,0,0,0-.684-.516h-.973V15.2h.973a.715.715,0,0,0,.684-.516,2.917,2.917,0,0,1,.248-.6.714.714,0,0,0-.119-.849l-.689-.689.415-.415.689.689a.715.715,0,0,0,.849.119,2.929,2.929,0,0,1,.6-.248.716.716,0,0,0,.517-.684v-.973h.587v.973a.715.715,0,0,0,.515.684,2.917,2.917,0,0,1,.6.248.716.716,0,0,0,.85-.119l.689-.689.415.415-.689.689a.717.717,0,0,0-.119.849,2.883,2.883,0,0,1,.248.6.717.717,0,0,0,.685.517h.973Zm.042-.67h0Z"
          transform="translate(-1.754 -1.665)"
          fill="#606a6c"
          stroke="#606a6c"
          stroke-width="0.3"
        />
        <path
          id="Path_310066"
          data-name="Path 310066"
          d="M1,15.117a1,1,0,0,1-1-1v-.332A6.3,6.3,0,0,1,3.846,7.975l.488-.207-.4-.349A4.235,4.235,0,0,1,6.728,0,4.5,4.5,0,0,1,7.752.12a4.144,4.144,0,0,1,2.81,2.3A4.24,4.24,0,0,1,9.235,7.651l-.413.3.444.254a.708.708,0,0,1,.385.543.7.7,0,0,1-.225.607.726.726,0,0,1-.494.2.684.684,0,0,1-.306-.072,5.056,5.056,0,0,0-2.312-.6,4.887,4.887,0,0,0-4.882,4.455L1.4,13.7H6.955a.706.706,0,1,1,0,1.412Zm5.731-13.7a2.814,2.814,0,1,0,.511.045,2.991,2.991,0,0,0-.511-.045"
          transform="translate(0)"
          fill="#606a6c"
          stroke="#606a6c"
          stroke-width="0.3"
        />
      </g>
    </g>
  </svg>
</template>
