import { Edge, GraphNode, ElementData, useVueFlow, FlowExportObject } from '@braks/vue-flow';
import mkaConstant from '@/constants/components/mka/index';

const mkaModalConfig = mkaConstant().useMkaModalConfig;

// segment mechanic

export const nodeEntrySegmentMechanic = (
  flowData: FlowExportObject,
  nodeProps: MKA_Nodes.NodesProps,
  removeEdgeCallback: CallableFunction,
): boolean => {
  if (nodeProps.localId) {
    let nodeMappingTargetId = nodeProps.localId + '_ip-1';
    const allEdges: Edge[] = flowData.edges;
    const removeEdge = removeEdgeCallback;
    const currentMappingEdge: Edge[] = allEdges.filter((edge: Edge) => {
      return edge.id.indexOf(nodeMappingTargetId) != -1;
    });
    if (currentMappingEdge.length > 0) {
      nodeProps.false_link = true;
      nodeProps.nodeOut = 2;
    } else {
      if (nodeProps.false_link == true) {
        nodeMappingTargetId = nodeProps.localId + '_op-' + nodeProps.nodeOut;
        allEdges.map((edge: Edge) => {
          if (edge.id.indexOf(nodeMappingTargetId) != -1) {
            removeEdge(edge.id);
          }
        });
        nodeProps.false_link = false;
        nodeProps.nodeOut = 1;
      }
    }
    return true;
  }
  return false;
};

// distribute split

export const nodeFlowDistributionSplitMechanic = (
  flowData: FlowExportObject,
  nodeProps: MKA_Nodes.NodesProps,
  removeEdgeCallback: CallableFunction,
): boolean => {
  if (nodeProps.nodeOut && nodeProps.localId) {
    const removeEdge = removeEdgeCallback;
    const allEdges: Edge[] = flowData.edges;
    let nodeMappingTargetId = '';

    for (let F = mkaModalConfig.nodeFlowDistributionSplit.maxNodeOut; F > nodeProps.nodeOut; F--) {
      nodeMappingTargetId = nodeProps.localId + '_op-' + F;
      allEdges.map((edge: Edge) => {
        if (edge.id.indexOf(nodeMappingTargetId) != -1) {
          removeEdge(edge.id);
        }
      });
    }
    return true;
  }

  return false;
};

// fork split

export const nodeFlowForkSplitMechanic = (
  flowData: FlowExportObject,
  nodeProps: MKA_Nodes.NodesProps,
  removeEdgeCallback: CallableFunction,
): boolean => {
  if (nodeProps.nodeOut && nodeProps.localId) {
    let nodeMappingTargetId = '';
    const allEdges: Edge[] = flowData.edges;
    const removeEdge = removeEdgeCallback;

    for (let F = mkaModalConfig.nodeFlowForkSplit.maxNodeOut; F > nodeProps.nodeOut; F--) {
      nodeMappingTargetId = nodeProps.localId + '_op-' + F;
      allEdges.map((edge: Edge) => {
        if (edge.id.indexOf(nodeMappingTargetId) != -1) {
          removeEdge(edge.id);
        }
      });
    }
    return true;
  }

  return false;
};

// limit

export const nodeFlowLimitMechanic = (flowData: FlowExportObject, nodeProps: MKA_Nodes.NodesProps, removeEdgeCallback: CallableFunction): boolean => {
  if (nodeProps.localId) {
    const nodeMappingTargetId = nodeProps.localId + '_op-' + nodeProps.nodeOut;
    const allEdges: Edge[] = flowData.edges;
    const removeEdge = removeEdgeCallback;
    const currentMappingEdge: Edge[] = allEdges.filter((edge: Edge) => {
      return edge.id.indexOf(nodeMappingTargetId) != -1;
    });
    if (currentMappingEdge.length > 0) {
      nodeProps.payload.false_mode = 2;
    } else {
      nodeProps.payload.false_mode = 1;
    }
    return true;
  }

  return false;
};

// random split normal

export const nodeFlowRandomSplitNormalMechanic = (
  flowData: FlowExportObject,
  nodeProps: MKA_Nodes.NodesProps,
  removeEdgeCallback: CallableFunction,
  getEdge: any,
): boolean => {
  let nodeMappingTargetId = '';
  const allEdges: Edge[] = flowData.edges;
  const removeEdge = removeEdgeCallback;

  if (nodeProps.nodeOut && nodeProps.localId) {
    for (let F = 1; F <= nodeProps.nodeOut; F++) {
      nodeMappingTargetId = nodeProps.localId + '_op-' + F;
      allEdges.map((edge: Edge) => {
        if (edge.id.indexOf(nodeMappingTargetId) != -1) {
          const currentEdge: any = getEdge.value(edge.id);

          currentEdge.data.payload.split_prob = nodeProps.payload.connectList[F - 1].autoPercent;
        }
      });
    }
    for (let F = mkaModalConfig.nodeFlowRandomSplitNormal.maxNodeOut; F > nodeProps.nodeOut; F--) {
      nodeMappingTargetId = nodeProps.localId + '_op-' + F;
      allEdges.map((edge: Edge) => {
        if (edge.id.indexOf(nodeMappingTargetId) != -1) {
          removeEdge(edge.id);
        }
      });
    }
    return true;
  }

  return false;
};

// random split true

export const nodeFlowRandomSplitTrueMechanic = (
  flowData: FlowExportObject,
  nodeProps: MKA_Nodes.NodesProps,
  removeEdgeCallback: CallableFunction,
  getEdge: any,
): boolean => {
  if (nodeProps.nodeOut && nodeProps.localId) {
    let nodeMappingTargetId = '';
    const allEdges: Edge[] = flowData.edges;
    const removeEdge = removeEdgeCallback;

    for (let F = 1; F <= nodeProps.nodeOut; F++) {
      nodeMappingTargetId = nodeProps.localId + '_op-' + F;
      allEdges.map((edge: Edge) => {
        if (edge.id.indexOf(nodeMappingTargetId) != -1) {
          const currentEdge: any = getEdge.value(edge.id);
          currentEdge.data.payload.split_prob = nodeProps.payload.connectList[F - 1].autoPercent;
        }
      });
    }
    // remove edge
    for (let F = mkaModalConfig.nodeFlowRandomSplitTrue.maxNodeOut; F > nodeProps.nodeOut; F--) {
      nodeMappingTargetId = nodeProps.localId + '_op-' + F;
      allEdges.map((edge: Edge) => {
        if (edge.id.indexOf(nodeMappingTargetId) != -1) {
          removeEdge(edge.id);
        }
      });
    }
    return true;
  }

  return false;
};
