export default function staticLayoutDashboard() {
  const layout = [
    {
      x: 0,
      y: 0,
      w: 6,
      h: 8,
      i: 1,
      minW: 3,
      minH: 2,
      static: true,
      chartLayoutId: 1,
    },
    {
      x: 6,
      y: 0,
      w: 6,
      h: 4,
      i: 2,
      minW: 3,
      minH: 2,
      static: true,
      chartLayoutId: 2,
    },
    {
      x: 6,
      y: 4,
      w: 6,
      h: 4,
      i: 3,
      minW: 4,
      minH: 5,
      static: true,
      chartLayoutId: 3,
    },
    {
      x: 0,
      y: 8,
      w: 6,
      h: 4,
      i: 4,
      minW: 3,
      minH: 2,
      static: true,
      chartLayoutId: 4,
    },
    {
      x: 6,
      y: 8,
      w: 6,
      h: 4,
      i: 5,
      minW: 4,
      minH: 5,
      static: true,
      chartLayoutId: 5,
    },
  ];

  const GIRD_LAYOUT: { COL_NUMBERS: number; ROW_HEIGHT: number } = {
    COL_NUMBERS: 12,
    ROW_HEIGHT: 100,
  };

  return {
    layout,
    GIRD_LAYOUT,
  };
}
