import { getCurrentInstance, Ref, ref, watch } from 'vue';
import { DateTime } from 'luxon';
import { EChartsOption } from 'echarts';

import api from '@services/api';
import customerInsightModel from '@models/customer-insight';
import useValidationModal from '@views/components/modal/hooks/useValidationModal';

// constant
import { COLOR_INDEX, DATA_INDEX } from '@/constants/components/chart/chart-summarybox';
import { COLOR_INDEX as COLOR_INDEX_OVERVIEW } from '@/constants/components/chart/chart-overview';
import { HEATMAP, HORIZONTAL_BAR, RADAR } from '@/constants/components/chart/base-chart';
import { DEFUALT_TOTAL_SUFFIX_FROM_ITEM_INDEX } from '@/constants/components/chart/chart-infographic';

// components
import ChartsInfoGraphic from '@views/components/chart/ChartInfoGraphic.vue';
import ChartsTable from '@views/components/chart/ChartTable.vue';
import ChartsCustomerBanner from '@views/components/chart/ChartCustomerBanner.vue';
import ChartUnresolved from '@views/components/chart/ChartUnresolved.vue';
import CustomChart from '@views/components/chart/CustomChart.vue';
import ChartSummaryBox from '@views/components/chart/ChartSummaryBox.vue';
import ChartOverview from '@views/components/chart/ChartOverview.vue';

import ExtraEmpty from '@views/modules/analytics/components/bi-chart/ExtraEmpty.vue';
import ExtraTopLegend from '@views/modules/analytics/components/bi-chart/ExtraTopLegend.vue';
import ExtraRightLegend from '@views/modules/analytics/components/bi-chart/ExtraRightLegend.vue';

import { chartTypes } from '@/constants/components/chart/chart-types';

import {
  chartsPropsToEChartsOptionsMapper,
  rawDataToCustomerBannerMapper,
  rawDataToChartTableColumn,
  rawDataToChartTableData,
  rawDataToChartsInfoGraphic,
} from '../../../utils/ins-mapper';

interface ExtraTopLegendItem {
  label: string;
  value: number;
  color: string;
}

interface ExtraRightLegendItem {
  label: string;
  value: number;
  color: string;
}

interface Props {
  cId: number;
  chartLayoutId: number;
  width: number;
  height: number;
  index: string;
  mainSourceList: { label: string; value: string }[];
  mainStartDate: Date | null;
  mainEndDate: Date | null;
  mainRelativeModel: string | null;
  isMainFilterChange: boolean;
}

// TODO: refactor to constant file
const DEFAULT_TEXT_TITLE_COLOR = 'black';
const DEFAULT_ICON_COLOR = '#93adc1';
const DEFAULT_BACKGROUND_COLOR = 'white';
const DEFAULT_DATE_PICKER_COLOR = 'grey';

export default function (props: Props, componentWidth: Ref<number>, componentHeight: Ref<number>) {
  const vm = getCurrentInstance()?.proxy;
  const chartProps: Ref<Charts.ChartsProps> = ref({
    chartId: null,
    chartSetId: null,
    title: '',
    tooltip: '',
    chartsType: 'none',
    options: {},
    graphRatioWidth: 100,
    graphRatioHeight: 100,
    implType: null,
    implKeyRef: '',
    extra: {
      left: {
        show: false,
        type: '',
      },
      top: {
        show: false,
        type: '',
      },
      bottom: {
        show: false,
        type: '',
      },
      right: {
        show: false,
        type: '',
      },
    },
    colorList: [],
    rawData: {
      title: '',
      columns: [],
      data: [],
      filter: {
        dateTime: false,
        source: false,
        store: false,
      },
    },
  });
  const loading: Ref<boolean> = ref(false);
  const isValidRawData: Ref<boolean> = ref(false);
  const textTitleColor: Ref<string> = ref(DEFAULT_TEXT_TITLE_COLOR);
  const iconColor: Ref<string> = ref(DEFAULT_ICON_COLOR);
  const backgroundColor: Ref<string> = ref(DEFAULT_BACKGROUND_COLOR);
  const datePickerColor: Ref<string> = ref(DEFAULT_DATE_PICKER_COLOR);
  const isClickElem: Ref<boolean> = ref(false);
  const startDate = ref<Date | null>(props.mainStartDate);
  const endDate = ref<Date | null>(props.mainEndDate);
  const relativeModel = ref<string | null>(props.mainRelativeModel);

  const { fetchCustomerInsightChartDataModel } = customerInsightModel();

  // TODO: move this function to utils mapper
  function chartPropsMapper(input: Charts.ChartsProps.Request.Response): Charts.ChartsProps {
    return {
      chartId: input.chartid,
      chartSetId: input.chartsetid,
      chartsType: input.type_name,
      title: input.chart_name || 'Not found, chart name',
      tooltip: input.chart_desc || 'Not found, tooltip',
      options: <EChartsOption>input.options,
      graphRatioHeight: input.ratio_height,
      graphRatioWidth: input.ratio_width,
      implType: input.impl_type,
      implKeyRef: input.impl_key_ref,
      extra: {
        top: {
          show: !!input.extra.extra_top,
          type: input.extra.extra_top,
        },
        left: {
          show: !!input.extra.extra_left,
          type: input.extra.extra_left,
        },
        right: {
          show: !!input.extra.extra_right,
          type: input.extra.extra_right,
        },
        bottom: {
          show: !!input.extra.extra_bottom,
          type: input.extra.extra_bottom,
        },
      },
      colorList: input.color_list.map((el) => ({
        seq: el.seq,
        colorId: el.colorid,
        hexColor: el.hex_color,
      })),
      rawData: {
        title: input.rawdata.title,
        data: input.rawdata.data,
        filter: {
          dateTime: input.rawdata.filter.date_time,
          source: input.rawdata.filter.source,
          store: input.rawdata.filter.store,
        },
      },
    };
  }

  function isRawDataValid(rawData: Charts.ChartsProps.RawData, chartType: string): boolean {
    switch (chartType) {
      case chartTypes.HEATMAP: {
        return rawData.data[HEATMAP.RAW_DATA_INDEX].datalist.length !== 0;
      }
      case chartTypes.HORIZONTAL_BAR: {
        return rawData.data[HORIZONTAL_BAR.RAW_DATA_INDEX].yaxis.length !== 0;
      }
      case chartTypes.BOX: {
        return true;
      }
      default: {
        if (!rawData.data) {
          return false;
        }

        if (Array.isArray(rawData.data) && rawData.data.length === 0) {
          return false;
        }

        return !(rawData.data && Object.keys(rawData.data).length === 0 && Object.getPrototypeOf(rawData.data) === Object.prototype);
      }
    }
  }

  function textTitleColorMapper(colorList: Charts.ChartsProps.ColorItem[], chartType: string): string {
    switch (chartType) {
      case chartTypes.BOX: {
        return colorList[COLOR_INDEX.TEXT_COLOR_INDEX].hexColor;
      }
      case chartTypes.OVERVIEW: {
        return colorList[COLOR_INDEX_OVERVIEW.TEXT_COLOR_INDEX].hexColor;
      }
      default: {
        return DEFAULT_TEXT_TITLE_COLOR;
      }
    }
  }

  function iconColorMapper(colorList: Charts.ChartsProps.ColorItem[], chartType: string): string {
    switch (chartType) {
      case chartTypes.BOX: {
        return colorList[COLOR_INDEX.TEXT_COLOR_INDEX].hexColor;
      }
      case chartTypes.OVERVIEW: {
        return colorList[COLOR_INDEX_OVERVIEW.TEXT_COLOR_INDEX].hexColor;
      }
      default: {
        return DEFAULT_ICON_COLOR;
      }
    }
  }

  function datePickerColorMapper(colorList: Charts.ChartsProps.ColorItem[], chartType: string): string {
    switch (chartType) {
      case chartTypes.BOX: {
        return colorList[COLOR_INDEX.TEXT_COLOR_INDEX].hexColor;
      }
      case chartTypes.OVERVIEW: {
        return colorList[COLOR_INDEX_OVERVIEW.TEXT_COLOR_INDEX].hexColor;
      }
      default: {
        return DEFAULT_DATE_PICKER_COLOR;
      }
    }
  }

  function backgroundColorMapper(colorList: Charts.ChartsProps.ColorItem[], chartType: string): string {
    switch (chartType) {
      case chartTypes.BOX: {
        return colorList[COLOR_INDEX.BACKGROUND_COLOR_INDEX].hexColor;
      }
      case chartTypes.OVERVIEW: {
        return colorList[COLOR_INDEX_OVERVIEW.BACKGROUND_COLOR_INDEX].hexColor;
      }
      default: {
        return DEFAULT_BACKGROUND_COLOR;
      }
    }
  }

  async function fetchOptionGraphById(chartId: number): Promise<void> {
    fetchCustomerInsightChartDataModel.payload.CId = props.cId;
    fetchCustomerInsightChartDataModel.payload.ChartLayoutId = chartId;
    fetchCustomerInsightChartDataModel.payload.CustomerView = props.cId !== 0;
    fetchCustomerInsightChartDataModel.payload.Filters = [
      {
        Key: 'startDate',
        Value: startDate.value ? DateTime.fromISO(startDate.value.toISOString()).toFormat('yyyy-MM-dd HH:mm') : '',
      },
      {
        Key: 'endDate',
        Value: endDate.value ? DateTime.fromISO(endDate.value.toISOString()).toFormat('yyyy-MM-dd HH:mm') : '',
      },
      {
        Key: 'source',
        Value: props.mainSourceList.map((el) => el.value),
      },
      {
        Key: 'hasDateFilter',
        Value: chartProps.value.rawData.filter.dateTime ? 'true' : 'false',
      },
    ];
    loading.value = true;
    return api
      .apiRequest(fetchCustomerInsightChartDataModel)
      .then((response) => {
        chartProps.value = chartPropsMapper(response.data.result as Charts.ChartsProps.Request.Response);
        isValidRawData.value = isRawDataValid(chartProps.value.rawData, chartProps.value.chartsType);
        textTitleColor.value = textTitleColorMapper(chartProps.value.colorList, chartProps.value.chartsType);
        iconColor.value = iconColorMapper(chartProps.value.colorList, chartProps.value.chartsType);
        datePickerColor.value = datePickerColorMapper(chartProps.value.colorList, chartProps.value.chartsType);
        backgroundColor.value = backgroundColorMapper(chartProps.value.colorList, chartProps.value.chartsType);
        return Promise.resolve();
      })
      .catch(() => {
        //TODO: change error modal to alert toast
        return Promise.reject();
      })
      .finally(() => {
        vm?.$emit('on-fetched', props.chartLayoutId);
        loading.value = false;
      });
  }

  function resolveByChartType() {
    switch (chartProps.value.chartsType) {
      case chartTypes.LINE:
      case chartTypes.STRAIGHT_LINE:
      case chartTypes.PIE:
      case chartTypes.BAR:
      case chartTypes.HORIZONTAL_BAR:
      case chartTypes.VERTICAL_BAR:
      case chartTypes.VERTICAL_STACK_BAR:
      case chartTypes.RADAR:
      case chartTypes.HEATMAP:
      case chartTypes.GAUGE:
        return CustomChart;
      case chartTypes.BOX:
        return ChartSummaryBox;
      case chartTypes.PROFILE:
        return ChartsCustomerBanner;
      case chartTypes.TABLE:
        return ChartsTable;
      case chartTypes.INFOGRAPHIC_AGE:
      case chartTypes.INFOGRAPHIC_GENDER:
        return ChartsInfoGraphic;
      case chartTypes.OVERVIEW:
        return ChartOverview;
      case chartTypes.NONE:
        return ChartUnresolved;
      default:
        return ChartUnresolved;
    }
  }

  function getLength(number: number) {
    return number.toString().length;
  }

  //Double chart to show Data Info
  const onDoubleClickChart = () => {
    vm?.$emit('on-click-data-info', props.chartLayoutId, chartProps.value, startDate.value, endDate.value);
  };

  function resolveProps(): object {
    switch (chartProps.value.chartsType) {
      case chartTypes.LINE:
      case chartTypes.STRAIGHT_LINE:
      case chartTypes.PIE:
      case chartTypes.BAR:
      case chartTypes.HORIZONTAL_BAR:
      case chartTypes.VERTICAL_BAR:
      case chartTypes.VERTICAL_STACK_BAR:
      case chartTypes.RADAR:
      case chartTypes.HEATMAP:
      case chartTypes.GAUGE:
        return {
          options: chartsPropsToEChartsOptionsMapper(
            chartProps.value.chartsType,
            chartProps.value.rawData,
            chartProps.value.colorList,
            <EChartsOption>chartProps.value.options,
          ),
          height: Number(props.height) * Number(chartProps.value.graphRatioHeight),
          responsiveWidth: true,
          eventType: 'dblclick',
          function: () => onDoubleClickChart(),
        };
      case chartTypes.BOX:
        return {
          number: (() => {
            try {
              return chartProps.value.rawData.data[DATA_INDEX.VALUE].value;
            } catch (e) {
              return 0;
            }
          })(),
          subtitle: (() => {
            try {
              return chartProps.value.rawData.data[DATA_INDEX.VALUE].title;
            } catch (e) {
              return '-';
            }
          })(),
          color: (() => {
            try {
              return chartProps.value.colorList[COLOR_INDEX.TEXT_COLOR_INDEX].hexColor;
            } catch (e) {
              return 'white';
            }
          })(),
          /* fontSize: `calc(${String(componentWidth.value / 100) + 'rem'} - ${
            getLength(<number>chartProps.value.rawData.data[DATA_INDEX.VALUE].value) * 1.5
          }px)`, don't use */
          isShowInfoAble: true,
        };
      case chartTypes.PROFILE:
        return {
          customers: rawDataToCustomerBannerMapper(chartProps.value.rawData),
          loading: false,
          autoRatio: true,
          isShowInfoAble: true,
        };
      case chartTypes.TABLE:
        return {
          columns: rawDataToChartTableColumn(chartProps.value.rawData),
          data: rawDataToChartTableData(chartProps.value.rawData),
          // TODO: declare type for chart "table" and mapping, not use "is_pagination" directly
          isPagination: chartProps.value.rawData.data[0].is_pagination,
          isShowInfoAble: true,
        };
      case chartTypes.INFOGRAPHIC_AGE:
      case chartTypes.INFOGRAPHIC_GENDER:
        return {
          items: rawDataToChartsInfoGraphic(chartProps.value.rawData, chartProps.value.colorList),
          suffix: chartProps.value.rawData.data[DEFUALT_TOTAL_SUFFIX_FROM_ITEM_INDEX].suffix,
          autoRatio: true,
          isShowInfoAble: true,
        };
      case chartTypes.OVERVIEW:
        return {
          items: (() => {
            try {
              return chartProps.value.rawData.data;
            } catch (e) {
              return [];
            }
          })(),
          color: (() => {
            try {
              return chartProps.value.colorList[COLOR_INDEX_OVERVIEW.TEXT_COLOR_INDEX].hexColor;
            } catch (e) {
              return 'white';
            }
          })(),
          descriptionColor: (() => {
            try {
              return chartProps.value.colorList[COLOR_INDEX_OVERVIEW.DESCRIPTION_COLOR_INDEX].hexColor;
            } catch (e) {
              return 'white';
            }
          })(),
          isShowInfoAble: true,
        };
      case chartTypes.NONE:
        return {};
      default:
        return {};
    }
  }

  const resolveEmits = (): object => {
    let itemEmits = {} as object;
    switch (chartProps.value.chartsType) {
      default:
        itemEmits = {
          ['on-click-data-info']: () => vm?.$emit('on-click-data-info', chartProps.value.chartId, chartProps.value, startDate.value, endDate.value),
        };
    }
    return itemEmits;
  };

  function resolveExtraTopComponent() {
    if (chartProps.value.extra.top.show) {
      switch (chartProps.value.chartsType) {
        case chartTypes.PIE: {
          // TODO: "extra-top-legend" should be "legend" need to be consult backend
          if (chartProps.value.extra.top.type === 'extra-top-legend') {
            return ExtraTopLegend;
          } else {
            return ExtraEmpty;
          }
        }
        default: {
          return ExtraEmpty;
        }
      }
    } else {
      return ExtraEmpty;
    }
  }

  function resolveExtraTopProps() {
    if (chartProps.value.extra.top.show) {
      switch (chartProps.value.chartsType) {
        case chartTypes.PIE: {
          // TODO: "extra-top-legend" should be "legend" need to be consult backend
          if (chartProps.value.extra.top.type === 'extra-top-legend') {
            return {
              items: <ExtraTopLegendItem[]>chartProps.value.rawData.data.map((el: any, index: number) => ({
                label: el.data,
                value: el.value,
                color: chartProps.value.colorList[index].hexColor,
              })),
            };
          } else {
            return;
          }
        }
        default: {
          return;
        }
      }
    } else {
      return;
    }
  }

  function resolveExtraRightComponent() {
    if (chartProps.value.extra.right.show) {
      switch (chartProps.value.chartsType) {
        case chartTypes.RADAR:
        case chartTypes.PIE: {
          // TODO: "extra-right-legend" should be "legend" need to be consult backend
          if (chartProps.value.extra.right.type === 'extra-right-legend') {
            return ExtraRightLegend;
          } else {
            return ExtraEmpty;
          }
        }
        default: {
          return ExtraEmpty;
        }
      }
    } else {
      return ExtraEmpty;
    }
  }

  function resolveExtraRightProps() {
    if (chartProps.value.extra.right.show) {
      switch (chartProps.value.chartsType) {
        // TODO: constant
        case chartTypes.RADAR: {
          // TODO: "extra-right-legend" should be "legend" need to be consult backend
          if (chartProps.value.extra.right.type === 'extra-right-legend') {
            return {
              items: <ExtraRightLegendItem[]>chartProps.value.rawData.data[RADAR.RAW_DATA_INDEX].data.map((el: any, index: number) => ({
                label: el.name,
                value: el.value.reduce((a: number, b: number) => a + b, 0),
                color: chartProps.value.colorList[index].hexColor,
              })),
            };
          } else {
            return;
          }
        }
        case chartTypes.PIE: {
          // TODO: "extra-right-legend" should be "legend" need to be consult backend
          if (chartProps.value.extra.right.type === 'extra-right-legend') {
            return {
              items: <ExtraRightLegendItem[]>chartProps.value.rawData.data.map((el: any, index: number) => ({
                label: el.data,
                value: el.value,
                color: chartProps.value.colorList[index].hexColor,
              })),
            };
          } else {
            return;
          }
        }
        default: {
          return;
        }
      }
    } else {
      return;
    }
  }

  const onClickElemInChart = () => {
    const elem = document.getElementById('grid-' + props.index) as HTMLElement;
    elem.style.zIndex = String(1);
    isClickElem.value = true;
  };

  const onOutClickElemInChart = async () => {
    if (isClickElem.value) {
      const elem = document.getElementById('grid-' + props.index) as HTMLElement;
      elem.style.zIndex = 'unset';
      isClickElem.value = false;

      await fetchOptionGraphById(props.chartLayoutId);
    }
  };

  watch(
    [() => props.isMainFilterChange],
    async () => {
      if (props.mainStartDate == null) {
        startDate.value = null;
      } else {
        startDate.value = new Date(props.mainStartDate);
      }

      if (props.mainEndDate == null) {
        endDate.value = null;
      } else {
        endDate.value = new Date(props.mainEndDate);
      }

      relativeModel.value = props.mainRelativeModel;
      await fetchOptionGraphById(props.chartLayoutId);
    },
    { immediate: true, deep: true },
  );

  return {
    DEFAULT_TEXT_TITLE_COLOR,
    DEFAULT_ICON_COLOR,
    DEFAULT_BACKGROUND_COLOR,
    DEFAULT_DATE_PICKER_COLOR,
    textTitleColor,
    iconColor,
    datePickerColor,
    backgroundColor,
    isValidRawData,
    chartProps,
    startDate,
    endDate,
    relativeModel,
    loading,
    fetchOptionGraphById,
    onClickElemInChart,
    onOutClickElemInChart,
    resolveByChartType,
    resolveProps,
    resolveExtraTopComponent,
    resolveExtraTopProps,
    resolveExtraRightComponent,
    resolveExtraRightProps,
    resolveEmits,
    chartTypes,
  };
}
