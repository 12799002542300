<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="6.673" height="10.904" viewBox="0 0 6.673 10.904">
    <path
      id="Path_416"
      data-name="Path 416"
      d="M6229.95-5221.722l-5.452,5.452,5.452,5.452,1.221-1.222-4.23-4.23,4.23-4.23Z"
      transform="translate(-6224.498 5221.722)"
    />
  </svg>
</template>
