<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 77.11 69.44">
    <defs></defs>
    <path
      id="Union_139"
      class="cls-1"
      d="M4.4,69.44C1.97,69.44,0,67.47,0,65.04H0c0-2.43,1.97-4.4,4.4-4.4H72.71c2.43,0,4.4,1.97,4.4,4.4h0c0,2.43-1.97,4.4-4.4,4.4H4.4Zm0-15.87C1.97,53.57,0,51.6,0,49.17H0c0-2.43,1.97-4.4,4.4-4.4H72.71c2.43,0,4.4,1.97,4.4,4.4h0c0,2.43-1.97,4.4-4.4,4.4H4.4Zm18.02-15.38c-.93-.01-1.67-.78-1.66-1.71-.01-.9,.68-1.64,1.57-1.71,.78,0,1.56-.11,2.32-.33,.06-.03,.16-.25,.16-.74-.04-.53-.16-1.05-.35-1.55-.15-.46-.37-1.04-.65-1.72l-1-2.45-10.01-.09c-.73,1.74-1.11,2.84-1.3,3.38-.33,.83-.53,1.71-.61,2.6,0,.11,0,.39,.8,.67,.56,.15,1.13,.23,1.71,.24,.9,.05,1.59,.8,1.58,1.7,.01,.93-.73,1.69-1.65,1.7H4.25c-.93-.01-1.67-.78-1.65-1.7-.03-.82,.52-1.55,1.33-1.73,.75-.15,1.4-.6,1.82-1.24,.79-1.32,1.44-2.73,1.95-4.19L18.72,1.33c.3-.8,1.06-1.32,1.91-1.33,.83,0,1.58,.52,1.9,1.29l11.08,26.87c.74,2.01,1.66,3.94,2.75,5.78,.31,.48,.83,.79,1.4,.85,.85,.11,1.49,.84,1.48,1.7,.01,.93-.73,1.7-1.66,1.71h-15.16Zm-8.18-14.41h6.96l-3.59-8.9-3.37,8.9Zm30.46,7.83c-2.43,0-4.4-1.97-4.4-4.4h0c0-2.43,1.97-4.4,4.4-4.4h28.02c2.43,0,4.4,1.97,4.4,4.4h0c0,2.43-1.97,4.4-4.4,4.4h-28.02Zm0-15.48c-2.43,0-4.4-1.97-4.4-4.4h0c0-2.43,1.97-4.4,4.4-4.4h28.02c2.43,0,4.4,1.97,4.4,4.4h0c0,2.43-1.97,4.4-4.4,4.4h-28.02Z"
    />
  </svg>
</template>
