import { ref, Ref, onBeforeMount, getCurrentInstance, watch } from 'vue';
import apiService from '@/services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import campaignModel from '@models/communication/campaign';

interface Props {
  campaign: Campaign.ResponseDetail;
  smsMessage: string;
}

export default function useCreateTab(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const { fetchProviderListModel } = campaignModel();

  const { openDefaultErrorModal } = useValidationModal();

  const loading = ref(false);

  const selectedProvider: Ref<Communication.Provider.Provider> = ref(null!);
  const message: Ref<string> = ref('');

  const smsDisplay: Ref<Campaign.SmsValue> = ref({
    maxChars: 0,
    maxDisplay: null,
    maxCredit: 0,
    creditUsed: 0,
    extraChars: 0,
    msgCount: 0,
  });
  const providerList: Ref<Communication.Provider.Provider[]> = ref([]);

  const fetchProviderList = () => {
    loading.value = true;
    fetchProviderListModel.payload.Channel = 1;
    fetchProviderListModel.payload.Page = 1;
    fetchProviderListModel.payload.Limit = 100;

    apiService
      .apiRequest(fetchProviderListModel)
      .then((response) => {
        providerList.value = response.data.records as Communication.Provider.Provider[];
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const onSelectSender = (provider: Communication.Provider.Provider) => {
    selectedProvider.value = provider;
    vm?.$emit('select-provider', JSON.parse(JSON.stringify(selectedProvider.value)));
  };

  const onGetSmsDisplay = (value: Campaign.SmsValue) => {
    smsDisplay.value = value;
    vm?.$emit('on-get-credit-display', value);
  };

  const initData = () => {
    selectedProvider.value = (props.campaign.info.provider as Communication.Provider.Provider) || null!;
  };

  const initMessage = () => {
    message.value = props.smsMessage || '';
  };

  onBeforeMount(() => {
    fetchProviderList();
    initData();
    initMessage();
  });

  watch(
    () => props.campaign.info.provider,
    () => {
      initData();
    },
  );

  watch(
    () => props.smsMessage,
    () => {
      initMessage();
    },
  );

  return {
    selectedProvider,
    smsDisplay,
    providerList,
    message,
    onSelectSender,
    onGetSmsDisplay,
  };
}
