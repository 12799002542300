import axios from '@/libs/axios';

const authService = {
  setLocalBrandData(brand: string) {
    return localStorage.setItem('brandData', brand);
  },
  getLocalBrandData() {
    return localStorage.getItem('brandData');
  },
  fetchBrandRef() {
    const searchParams = new URLSearchParams(window.location.search);
    const brandRef: string = searchParams.get('brandRef')!;
    const brand_ref: string = searchParams.get('brand_ref')!;
    if (brandRef || brand_ref) {
      sessionStorage.setItem('brandSessionData', brandRef || brand_ref);
    }
    return brandRef || brand_ref || sessionStorage.getItem('brandSessionData');
  },
  fetchTokenByBrand() {
    return JSON.parse(this.getLocalBrandData()!)?.find((o: Record<string, any>) => {
      return o.brandRef == new URLSearchParams(window.location.search).get('brandRef') || o.brandRef == sessionStorage.getItem('brandSessionData');
    })?.accessToken;
  },
  fetchBrandData() {
    let brand: Record<string, any> = {};
    if (JSON.parse(authService.getLocalBrandData()!)) {
      brand = JSON.parse(authService.getLocalBrandData()!)[0];
    } else {
      brand = JSON.parse(authService.getLocalBrandData()!)?.find((o: Record<string, any>) => {
        return o.brandRef == this.fetchBrandRef();
      });
    }
    return {
      access_token: brand.accessToken,
      refresh_token: brand.refreshToken,
      brandData: brand.brandData,
    };
  },
  isUserLoggedIn() {
    const searchParams = new URLSearchParams(window.location.search);
    const brandRef = searchParams.get('brandRef');
    let brandObj: Record<string, any> = {};

    if (brandRef) {
      const brands = JSON.parse(this.getLocalBrandData()!);
      sessionStorage.setItem('brandSessionData', brandRef);
      brandObj = brands?.find((o: Record<string, any>) => {
        return o.brandRef == brandRef;
      });
    } else {
      const brands = JSON.parse(this.getLocalBrandData()!);
      brandObj = brands?.at(0);
    }

    return brandObj?.brandRef || false;
  },
  isClearLocalStorage() {
    return localStorage.clear();
  },
};

export default authService;
