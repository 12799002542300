import useLoading from '@/views/components/loading/hooks/useLoading';

import { ref, Ref } from 'vue';
export default function usePreviewLoading() {
  const { isLoading } = useLoading();

  const isModalOpen: Ref<boolean> = ref(false);
  const isModalOpenPreview: Ref<boolean> = ref(false);

  const onLoadingWithText = () => {
    isLoading(true, 'รอสักครู่...');
    setTimeout(() => {
      isLoading(false);
    }, 5000);
  };

  const onLoadingWithOutText = () => {
    isLoading(true);
    setTimeout(() => {
      isLoading(false);
    }, 3000);
  };

  const onClickOpenModal = () => {
    isModalOpen.value = true;
  };

  const onLoadingWithModal = () => {
    isLoading(true);
    setTimeout(() => {
      isLoading(false, '');
    }, 3000);
  };

  const onLoadingWithModal2 = () => {
    isModalOpenPreview.value = true;
    setTimeout(() => {
      isModalOpenPreview.value = false;
    }, 3000);
  };

  const onClosePreviewModal = () => {
    isModalOpen.value = false;
    isModalOpenPreview.value = false;
  };

  return {
    isModalOpenPreview,
    isModalOpen,
    onClickOpenModal,
    onLoadingWithText,
    onLoadingWithOutText,
    onLoadingWithModal,
    onLoadingWithModal2,
    onClosePreviewModal,
  };
}
