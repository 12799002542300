import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';

interface RawData {
  category: string[];
  data: number[];
}

export default function (rawData: RawData): EChartsOption {
  const options: EChartsOption = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
    },
    legend: {
      data: ['Spending'],
    },
    xAxis: {
      type: 'category',
      data: rawData.category,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Spending',
        data: rawData.data,
        type: 'line',
        color: '#0099EA',
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#0099EA',
            },
            {
              offset: 1,
              color: 'white',
            },
          ]),
        },
      },
    ],
  };

  return options;
}
