export default function segmentModel() {
  const fetchSegmentListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/list`,
    method: 'POST',
    payload: {},
  };

  const deleteSegmentModel: API.RequestModel<{ ref_type: number; referenceId: number }> = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Delete`,
    method: 'POST',
    payload: {
      ref_type: null!,
      referenceId: null!,
    },
  };

  const fetchDataSourceModel: API.RequestModel<{ tableId: number; Flag_Count: boolean }> = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Datasource`,
    method: 'POST',
    payload: {
      tableId: null!,
      Flag_Count: false,
    },
  };

  const fetchDisplayColumnModel: API.RequestModel<{ TableId: number }> = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Display/Get`,
    method: 'GET',
    payload: {
      TableId: null!,
    },
  };

  const applyWithConditionModel: API.RequestModel<Segment.Request.ApplyWithCondition> = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Apply_With_Condition`,
    method: 'POST',
    payload: {
      Apply_SegmentId: null,
      root_TableId: null!,
      name: null!,
      description: null,
      set_Op: null!,
      set_Logical_Equation: null!,
      RuleSets: null!,
      listGroupBy: null,
    },
  };

  const fetchSegmentDetailModel: API.RequestModel<Segment.Request.SegmentDetail> = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Detail`,
    method: 'GET',
    payload: {
      segmentId: null!,
    },
  };
  const fetchSegmentTabForModal: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Datasource_List`,
    method: 'GET',
    payload: {},
  };

  const fetchCountRealtimeModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Refresh_Record_Count`,
    method: 'POST',
    payload: {
      SegmentIdList: [],
    },
  };

  return {
    fetchSegmentListModel,
    deleteSegmentModel,
    fetchDataSourceModel,
    fetchDisplayColumnModel,
    applyWithConditionModel,
    fetchSegmentDetailModel,
    fetchSegmentTabForModal,
    fetchCountRealtimeModel,
  };
}
