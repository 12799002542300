<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="26.013" height="22.076" viewBox="0 0 29.013 25.076">
    <g id="Group_34649" data-name="Group 34649" transform="translate(-127.892 -11.462)">
      <path
        id="Path_22734"
        data-name="Path 22734"
        d="M132.74,11.462H128.8a.913.913,0,0,0-.913.913V16.31a.913.913,0,0,0,.913.913h3.935a.913.913,0,0,0,.913-.913V12.375a.913.913,0,0,0-.913-.913m-.606,4.241h-2.723V12.981h2.723Z"
      />
      <path
        id="Path_22735"
        data-name="Path 22735"
        d="M153.992,11.462H136.966a.913.913,0,0,0-.913.913V16.31a.913.913,0,0,0,.913.913h17.026a.913.913,0,0,0,.913-.913V12.375a.913.913,0,0,0-.913-.913m-.606,4.241H137.573V12.981h15.813Z"
      />
      <path
        id="Path_22736"
        data-name="Path 22736"
        d="M132.74,19.62H128.8a.913.913,0,0,0-.913.913v3.934a.913.913,0,0,0,.913.913h3.935a.913.913,0,0,0,.913-.913V20.533a.913.913,0,0,0-.913-.913m-.606,4.241h-2.723V21.138h2.723Z"
      />
      <path
        id="Path_22737"
        data-name="Path 22737"
        d="M153.992,19.62H136.966a.913.913,0,0,0-.913.913v3.934a.913.913,0,0,0,.913.913h17.026a.912.912,0,0,0,.913-.913V20.533a.912.912,0,0,0-.913-.913m-.606,4.241H137.573V21.138h15.813Z"
      />
      <path
        id="Path_22738"
        data-name="Path 22738"
        d="M132.74,27.778H128.8a.912.912,0,0,0-.913.912v3.935a.913.913,0,0,0,.913.913h3.935a.913.913,0,0,0,.913-.913V28.69a.912.912,0,0,0-.913-.912m-.606,4.241h-2.723V29.3h2.723Z"
      />
      <path
        id="Path_22739"
        data-name="Path 22739"
        d="M153.992,27.778H136.966a.912.912,0,0,0-.913.912v3.935a.913.913,0,0,0,.913.913h17.026a.913.913,0,0,0,.913-.913V28.69a.912.912,0,0,0-.913-.912m-.606,4.241H137.573V29.3h15.813Z"
      />
    </g>
  </svg>
</template>
