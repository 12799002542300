<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 170.42 63.12">
    <defs></defs>
    <g id="Group_45701">
      <g id="Rectangle_17057">
        <rect class="cls-1" x="138.86" width="31.56" height="63.12" rx="5.31" ry="5.31" />
        <rect class="cls-2" x="139.53" y=".66" width="30.23" height="61.79" rx="4.65" ry="4.65" />
      </g>
      <g id="Rectangle_17056">
        <rect class="cls-1" width="129.4" height="63.12" rx="5.31" ry="5.31" />
        <rect class="cls-2" x=".66" y=".66" width="128.07" height="61.79" rx="4.65" ry="4.65" />
      </g>
    </g>
  </svg>
</template>
