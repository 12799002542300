<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <rect width="24" height="24" stroke="none" fill="currentColor" opacity="0" />
    <g transform="matrix(1.43 0 0 1.43 12 12)">
      <path
        style="
          stroke: none;
          stroke-width: 1;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-dashoffset: 0;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          fill-rule: nonzero;
          opacity: 1;
        "
        transform=" translate(-9, -9)"
        d="M 2 2 L 2 12 C 2 13.097656 2.902344 14 4 14 L 7.761719 14 C 8.570313 15.203125 9.945313 16 11.5 16 C 13.980469 16 16 13.980469 16 11.5 C 16 9.945313 15.203125 8.570313 14 7.761719 L 14 5 L 11 5 L 11 2 Z M 3 3 L 10 3 L 10 7.273438 C 9.648438 7.402344 9.308594 7.554688 9 7.761719 L 9 7 L 7 7 L 7 8 L 8.707031 8 C 8.347656 8.289063 8.019531 8.617188 7.761719 9 L 7 9 L 7 10 L 7.273438 10 C 7.105469 10.472656 7 10.972656 7 11.5 C 7 12.027344 7.105469 12.527344 7.273438 13 L 4 13 C 3.441406 13 3 12.558594 3 12 Z M 4 5 L 4 6 L 9 6 L 9 5 Z M 11 6 L 13 6 L 13 7.273438 C 12.527344 7.105469 12.027344 7 11.5 7 C 11.328125 7 11.167969 7.03125 11 7.050781 Z M 4 7 L 4 8 L 6 8 L 6 7 Z M 11.5 8 C 12.289063 8 13.007813 8.265625 13.589844 8.703125 L 8.703125 13.589844 C 8.265625 13.007813 8 12.289063 8 11.5 C 8 9.5625 9.5625 8 11.5 8 Z M 4 9 L 4 10 L 6 10 L 6 9 Z M 14.296875 9.410156 C 14.734375 9.992188 15 10.710938 15 11.5 C 15 13.4375 13.4375 15 11.5 15 C 10.710938 15 9.992188 14.734375 9.410156 14.296875 Z M 4 11 L 4 12 L 6 12 L 6 11 Z"
        stroke-linecap="round"
      />
    </g>
  </svg>
</template>
