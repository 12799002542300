<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40.924" height="40.922" viewBox="0 0 40.924 40.922">
    <path
      d="M20.462,40.922A20.461,20.461,0,0,1,5.993,5.993,20.461,20.461,0,1,1,34.931,34.929,20.328,20.328,0,0,1,20.462,40.922Zm0-36.83A16.369,16.369,0,0,0,8.887,32.036,16.369,16.369,0,0,0,32.036,8.886,16.261,16.261,0,0,0,20.462,4.092Z"
      transform="translate(0 0)"
      fill="#75a3dd"
    />
    <g transform="translate(11.576 11.594)">
      <rect width="4.092" height="17.752" rx="2" transform="translate(17.752 0) rotate(90)" fill="#75a3dd" />
      <rect width="4.092" height="17.752" rx="2" transform="translate(17.752 6.82) rotate(90)" fill="#75a3dd" />
      <rect width="4.092" height="17.752" rx="2" transform="translate(17.752 13.641) rotate(90)" fill="#75a3dd" />
    </g>
  </svg>
</template>
