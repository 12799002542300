<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17.253" height="17.329" viewBox="0 0 13.253 17.329">
    <defs>
      <clipPath id="clip-pathbb">
        <rect id="Rectangle_15569" data-name="Rectangle 15569" class="cls-1-data-info" width="13.253" height="17.329" transform="translate(0 0)" />
      </clipPath>
    </defs>
    <g id="Group_36119" data-name="Group 36119" transform="translate(-956.699 -693.049)">
      <g id="Group_36118" data-name="Group 36118" transform="translate(956.699 693.049)">
        <g id="Group_36117" data-name="Group 36117" class="cls-2-data-info" transform="translate(0 0)">
          <path
            id="Path_23443"
            data-name="Path 23443"
            class="cls-3-data-info"
            d="M18.6,18.64a.909.909,0,0,0,0,1.819h4.513a.909.909,0,0,0,0-1.819Z"
            transform="translate(-14.528 -15.305)"
          />
          <path
            id="Path_23444"
            data-name="Path 23444"
            class="cls-3-data-info"
            d="M48.563,46.652a.208.208,0,0,0-.074-.014.211.211,0,0,0-.2.133c-.356.9-1.162,2.33-1.81,2.365h-.025c-.077,0-.184,0-.184-.24a2.031,2.031,0,0,1,.132-.595l1.922-5.443a4.369,4.369,0,0,0,.3-1.456,1.236,1.236,0,0,0-1.4-1.343,2.418,2.418,0,0,0-1.386.45,3.008,3.008,0,0,0-1.041,1.54l-.467,1.312a.21.21,0,0,0,.127.269.2.2,0,0,0,.071.013.211.211,0,0,0,.2-.138c.333-.912,1.116-2.359,1.809-2.359.118,0,.19.021.19.239a2.043,2.043,0,0,1-.132.595l-1.922,5.444a4.65,4.65,0,0,0-.336,1.491c0,.6.255,1.307,1.469,1.307a2.3,2.3,0,0,0,1.392-.472,3.184,3.184,0,0,0,1.017-1.519l.467-1.312a.211.211,0,0,0-.124-.268"
            transform="translate(-36.394 -32.894)"
          />
          <path
            id="Path_23445"
            data-name="Path 23445"
            class="cls-3"
            d="M61.518,22.364a1.074,1.074,0,0,0-.819-.336,1.456,1.456,0,0,0-1.078.487,1.563,1.563,0,0,0-.463,1.107,1.111,1.111,0,0,0,.311.8,1.063,1.063,0,0,0,.8.325,1.5,1.5,0,0,0,1.083-.475,1.518,1.518,0,0,0,.475-1.1,1.147,1.147,0,0,0-.308-.809"
            transform="translate(-48.574 -18.086)"
          />
          <path
            id="Path_23446"
            data-name="Path 23446"
            class="cls-3-data-info"
            d="M21.517,69.369H18.643a.936.936,0,0,0-.944.82.91.91,0,0,0,.905,1h2.914a.909.909,0,0,0,0-1.819"
            transform="translate(-14.528 -56.957)"
          />
          <path
            id="Path_23447"
            data-name="Path 23447"
            class="cls-3-data-info"
            d="M21.784,35.2H18.643a.936.936,0,0,0-.944.82.91.91,0,0,0,.905,1h3.181a.909.909,0,0,0,0-1.819"
            transform="translate(-14.528 -28.905)"
          />
          <path
            id="Path_23448"
            data-name="Path 23448"
            class="cls-3-data-info"
            d="M18.6,53.4h2.113a.909.909,0,0,0,0-1.819H18.642a.936.936,0,0,0-.944.82.909.909,0,0,0,.905,1"
            transform="translate(-14.528 -42.351)"
          />
          <path
            id="Path_23449"
            data-name="Path 23449"
            class="cls-3-data-info"
            d="M6.133,15.7H1.61V1.61h9.515V2.16a.805.805,0,0,0,1.61,0V1.022A1.022,1.022,0,0,0,11.714,0H1.022A1.022,1.022,0,0,0,0,1.022V16.291a1.022,1.022,0,0,0,1.022,1.022H6.133a.805.805,0,1,0,0-1.61"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1-data-info {
  fill: none;
}

.cls-2-data-info {
  clip-path: url(#clip-pathbb);
}

.cls-3-data-info {
  fill: #626a6c;
}
</style>
