import { CHARTS_KEY, CHARTS_TYPE } from './constants/allChannelChart';

export default function staticLayoutDashboard() {
  const layout = [
    {
      x: 0,
      y: 0,
      w: 12,
      h: 2.2,
      i: 0,
      minW: 6,
      minH: 2,
      static: true,
      chartLayoutId: 0,
      chartHeight: 155,
      key: CHARTS_KEY.TOTAL_STATUS,
      chartType: CHARTS_TYPE.OVERVIEW,
    },
    {
      x: 0,
      y: 2.2,
      w: 12,
      h: 5,
      i: 1,
      minW: 6,
      minH: 2,
      static: true,
      chartLayoutId: 1,
      chartHeight: 400,
      key: CHARTS_KEY.DELIVERY_RANGE,
      chartType: CHARTS_TYPE.HEATMAP,
    },
    {
      x: 0,
      y: 7.2,
      w: 3,
      h: 4.5,
      i: 2,
      minW: 3,
      minH: 2,
      static: true,
      chartLayoutId: 2,
      chartHeight: 332,
      key: CHARTS_KEY.DELIVERY,
      chartType: CHARTS_TYPE.PIE,
    },
    {
      x: 3,
      y: 7.2,
      w: 6,
      h: 4.5,
      i: 3,
      minW: 3,
      minH: 2,
      static: true,
      chartLayoutId: 3,
      chartHeight: 332,
      key: CHARTS_KEY.DELIVERY_BY_CAMPAIGN,
      chartType: CHARTS_TYPE.VERTICAL_STACK_BAR,
    },
    {
      x: 9,
      y: 7.2,
      w: 3,
      h: 4.5,
      i: 4,
      minW: 3,
      minH: 2,
      static: true,
      chartLayoutId: 4,
      chartHeight: 332,
      key: CHARTS_KEY.PDPA_CONSENT,
      chartType: CHARTS_TYPE.PIE,
    },
    // SMS New Subscribe i: 5 - 7
    {
      x: 0,
      y: 11.7,
      w: 6,
      h: 4.5,
      i: 5,
      minW: 4,
      minH: 4,
      static: true,
      chartLayoutId: 5,
      chartHeight: 332,
      key: CHARTS_KEY.SMS_NEW_SUBSCRIBE,
      chartType: CHARTS_TYPE.LINE,
    },
    {
      x: 6,
      y: 11.7,
      w: 3,
      h: 4.5,
      i: 15,
      minW: 4,
      minH: 4,
      static: true,
      chartLayoutId: 15,
      chartHeight: 332,
      key: CHARTS_KEY.SMS_Subscribe,
      chartType: CHARTS_TYPE.PIE,
    },
    {
      x: 9,
      y: 11.7,
      w: 3,
      h: 4.5,
      i: 152,
      minW: 4,
      minH: 4,
      static: true,
      chartLayoutId: 152,
      chartHeight: 332,
      key: CHARTS_KEY.SMS_Credit,
      chartType: CHARTS_TYPE.VERTICAL_OVERVIEW,
    },
    // Line OA New Subscribe i: 8 - 10
    {
      x: 0,
      y: 16.2,
      w: 6,
      h: 4.5,
      i: 16,
      minW: 4,
      minH: 4,
      static: true,
      chartLayoutId: 16,
      chartHeight: 332,
      key: CHARTS_KEY.Line_New_Subscriber,
      chartType: CHARTS_TYPE.LINE,
    },
    {
      x: 6,
      y: 16.2,
      w: 3,
      h: 4.5,
      i: 20,
      minW: 4,
      minH: 4,
      static: true,
      chartLayoutId: 20,
      chartHeight: 332,
      key: CHARTS_KEY.Line_Subscribe,
      chartType: CHARTS_TYPE.PIE,
    },

    // Email New Subscribe i: 11 - 13
    {
      x: 0,
      y: 20.7,
      w: 6,
      h: 4.5,
      i: 11,
      minW: 4,
      minH: 4,
      static: true,
      chartLayoutId: 11,
      chartHeight: 332,
      key: CHARTS_KEY.EMAIL_NEW_SUBSCRIBE,
      chartType: CHARTS_TYPE.LINE,
    },
    {
      x: 6,
      y: 20.7,
      w: 3,
      h: 4.5,
      i: 12,
      minW: 3,
      minH: 4,
      static: true,
      chartLayoutId: 12,
      chartHeight: 332,
      key: CHARTS_KEY.EMAIL_SUBSCRIBE,
      chartType: CHARTS_TYPE.PIE,
    },
    {
      x: 9,
      y: 20.7,
      w: 3,
      h: 4.5,
      i: 13,
      minW: 3,
      minH: 4,
      static: true,
      chartLayoutId: 13,
      chartHeight: 332,
      key: CHARTS_KEY.EMAIL_CREDIT,
      chartType: CHARTS_TYPE.VERTICAL_OVERVIEW,
    },
  ];

  const GIRD_LAYOUT: { COL_NUMBERS: number; ROW_HEIGHT: number } = {
    COL_NUMBERS: 12,
    ROW_HEIGHT: 100,
  };

  return {
    layout,
    GIRD_LAYOUT,
  };
}
