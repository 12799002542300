<template>
  <svg id="Group_37151" data-name="Group 37151" xmlns="http://www.w3.org/2000/svg" width="72.548" height="127.221" viewBox="0 0 72.548 127.221">
    <path
      id="Path_24062"
      data-name="Path 24062"
      class="cls-1"
      d="M115.608,13.81A13.809,13.809,0,1,1,101.8,0,13.809,13.809,0,0,1,115.608,13.81"
      transform="translate(-66.002 -0.001)"
    />
    <path
      id="Path_24063"
      data-name="Path 24063"
      class="cls-1"
      d="M140.124,58.581,125.186,29.529a5.7,5.7,0,0,0-4.705-3.059,2.422,2.422,0,0,0-.34-.035h-5.2a1.489,1.489,0,0,0-1.448,1.834l1.523,6.4a.031.031,0,0,1-.048.033l-7.634-5.1a5.081,5.081,0,0,0-5.661.011l-7.558,5.093a.032.032,0,0,1-.049-.032l1.18-6.485a1.49,1.49,0,0,0-1.466-1.756H88.858a2.493,2.493,0,0,0-.367.037,5.7,5.7,0,0,0-4.59,3.057L68.961,58.581A5.89,5.89,0,0,0,70.3,65.845a5.724,5.724,0,0,0,8.692-1.811l7.376-14.347a.031.031,0,0,1,.059.014v68.512a5.889,5.889,0,0,0,4.514,5.847,5.723,5.723,0,0,0,6.9-5.585V88.987a6.858,6.858,0,0,1,6.478-6.96,6.654,6.654,0,0,1,6.829,6.652v29.535a5.888,5.888,0,0,0,4.514,5.847,5.723,5.723,0,0,0,6.9-5.585V49.533a.032.032,0,0,1,.06-.014l7.463,14.515a5.723,5.723,0,0,0,8.691,1.811,5.89,5.89,0,0,0,1.341-7.264"
      transform="translate(-68.269 3.038)"
    />
  </svg>
</template>
