export default function campaignModel() {
  const confirmPasswordModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_CDP_CORE!}/account/CheckLogin`,
    method: 'POST',
    payload: {
      password: '',
    },
  };
  const fetchSelectedUnConsentPaginationModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/segment_provider/Count_Unconsent_Record`,
    method: 'POST',
    payload: {
      CampaignId: null!,
      Search_Value: null!,
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 10,
    },
  };

  const fetchSelectedUnConsentSegmentModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/segment_provider/List_Unconsent`,
    method: 'POST',
    payload: {
      CampaignId: null!,
      Search_Value: null!,
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 10,
    },
  };

  const fetchCampaignListModel: API.RequestModel<Communication.Campaign.CampaignListRequest> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/List`,
    method: 'POST',
    payload: {
      Channel: 0,
      Query: null,
      Status: 0,
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 10,
    },
  };

  const createCampaignModel: API.RequestModel<Campaign.Create> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/Create`,
    method: 'POST',
    payload: {
      Channel: 0,
      Name: null,
      Description: null,
      TemplateId: null,
      SubType: null,
    },
  };

  const deleteCampaignModel: API.RequestModel<{ CampaignId: number }> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/Delete`,
    method: 'POST',
    payload: {
      CampaignId: null!,
    },
  };

  const duplicateCampaignModel: API.RequestModel<{ CampaignId: number }> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/Duplicate`,
    method: 'POST',
    payload: {
      CampaignId: null!,
    },
  };

  const cancelCampaignModel: API.RequestModel<{ CampaignId: number }> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/Cancel`,
    method: 'POST',
    payload: {
      CampaignId: null!,
    },
  };

  const saveCampaignModel: API.RequestModel<Campaign.Save | Communication.LineOA.CreateCampaign.Request.SaveCampaign> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/Save`,
    method: 'POST',
    payload: {
      CampaignId: null,
      Info: {
        Name: '',
        Description: '',
        ProviderId: null,
      },
      Contents: [],
      Deleted_File_Sref: [],
      Recipients: {
        Segments: [
          {
            Action: null,
            SegmentId: null!,
            SelectId: null,
            Type: null,
            Selection_Mode: null,
            CIDs: [],
          },
        ],
      },
      Recipients_Unconsent: { Action: null, Selection_Mode: null, CIDs: [], Filter_Search: null, Filtered_Record: null },
      Schedule: {
        Mode: 1,
        Schedule_DT: '',
        Repeat_Every: null,
        Repeat_Mode: 0,
        Repeat_On: null,
        End_Mode: null,
        End_DT: '',
        End_Occurrence: null,
      },
    },
  };

  const detailCampaignModel: API.RequestModel<{ CampaignId: number }> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/Detail`,
    method: 'POST',
    payload: {
      CampaignId: null!,
    },
  };

  const previewCampaignModel: API.RequestModel<{ CampaignId: number }> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/Preview`,
    method: 'POST',
    payload: {
      CampaignId: null!,
    },
  };

  const scheduleInfoPreviewModel: API.RequestModel<{ CampaignId: number }> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/Schedule_Info`,
    method: 'POST',
    payload: {
      CampaignId: null!,
    },
  };

  const publishCampaignModel: API.RequestModel<{ CampaignId: number }> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/Publish`,
    method: 'POST',
    payload: {
      CampaignId: null!,
    },
  };

  const fetchProviderListModel: API.RequestModel<Campaign.Provider> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Provider/List`,
    method: 'POST',
    payload: {
      Channel: 0,
      Query: '',
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 100,
    },
  };

  const fetchCampaignSummaryStatsModel: API.RequestModel<{ Channel: number }> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/SummaryStats`,
    method: 'POST',
    payload: {
      Channel: null!,
    },
  };

  const fetchPersonalizeListModel: API.RequestModel<{ CampaignId: number }> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/PersonalizeList`,
    method: 'POST',
    payload: {
      CampaignId: null!,
    },
  };

  const fetchCouponSetList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/CouponSetList`,
    method: 'POST',
    payload: {},
  };

  const fetchCouponColumnList: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/CouponColumnList`,
    method: 'POST',
    payload: {},
  };

  const getChannelCreditBalanceModel: API.RequestModel<{ Channel: number | null }> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Provider/GetChannelCreditBalance`,
    method: 'GET',
    payload: {
      Channel: null!,
    },
  };

  const fetchSelectedSegmentModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/Selected_Segment`,
    method: 'POST',
    payload: {
      CampaignId: null!,
      SegmentId: null!,
      Search_Value: null!,
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 10,
    },
  };

  const fetchCampaignReport: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignReport/Campaign`,
    method: 'GET',
    payload: {
      CampaignId: null!,
    },
    responseType: 'blob',
  };

  const saveAsTemplateModel: API.RequestModel<Campaign.Request.SaveAsTemplate> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/SaveAsTemplate`,
    method: 'POST',
    payload: {
      Channel: null!,
      Type: 2,
      SubType: null!,
      CategoryId: null,
      Name: null!,
      Description: null,
      Contents: [],
      Deleted_File_Sref: [],
      Thumbnail_Url: null!,
      Thumbnail_Sref: null!,
    },
  };

  const outOfCreditCampaignStatModel: API.RequestModel<{ Channel: number | null }> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Campaign/OutOfCreditCampaignStat`,
    method: 'POST',
    payload: {
      Channel: null,
    },
  };

  return {
    fetchCampaignListModel,
    createCampaignModel,
    deleteCampaignModel,
    saveCampaignModel,
    detailCampaignModel,
    previewCampaignModel,
    publishCampaignModel,
    fetchProviderListModel,
    fetchCampaignSummaryStatsModel,
    scheduleInfoPreviewModel,
    getChannelCreditBalanceModel,
    fetchPersonalizeListModel,
    fetchCouponSetList,
    fetchCouponColumnList,
    duplicateCampaignModel,
    fetchSelectedSegmentModel,
    fetchCampaignReport,
    saveAsTemplateModel,
    fetchSelectedUnConsentSegmentModel,
    confirmPasswordModel,
    fetchSelectedUnConsentPaginationModel,
    outOfCreditCampaignStatModel,
    cancelCampaignModel,
  };
}
