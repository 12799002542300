import { onMounted, ref, Ref, watch } from 'vue'
import * as echarts from 'echarts';
import { uid, calculateWidth, calculateFontSizeFromHeight, formatterLabel } from '../utils'

interface Props {
    min: number,
    max: number,
    value: number,
    height: number,
    startLabel?: string,
    endLabel?: string,
    detailColor?: string,
    gradient?: Array<{
        offset: number;
        color: string;
    }>
}

export default function (props: Props) {
    const chart = ref()
    const fontOffset = 10
    const id = ref()
    id.value = uid()

    const option: Ref<GaugeOption.Pointer> = ref({
        series: [
            {
                type: 'gauge',
                center: ['50%', '75%'],
                radius: '140%',
                startAngle: 180,
                endAngle: 0,
                min: props.min,
                max: props.max,
                splitNumber: 1,
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 1, 1, 1, props.gradient),
                },
                progress: {
                    show: true,
                    roundCap: false,
                    width: 18
                },
                pointer: {
                    itemStyle: {
                        color: '#3C3C3C'
                    },
                    icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
                    length: '75%',
                    width: 10,
                    offsetCenter: [0, '5%']
                },
                axisLine: {
                    roundCap: false,
                    lineStyle: {
                        width: 18
                    }
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLabel: {
                    show: true,
                    distance: -30,
                    color: '#999',
                    fontSize: calculateFontSizeFromHeight(Number(props.height), 10),
                    formatter: (val: number) => formatterLabel(Number(props.min), Number(props.max), val, String(props.startLabel), props.endLabel)
                },
                title: {
                    show: false
                },
                detail: {
                    width: '30%',
                    lineHeight: 20,
                    height: 20,
                    offsetCenter: [0, '25%'],
                    valueAnimation: true,
                    formatter: function (value: number) {
                        return '{value|' + value.toFixed(0) + '}{unit|%}';
                    },
                    rich: {
                        value: {
                            fontSize: calculateFontSizeFromHeight(Number(props.height), fontOffset),
                            fontWeight: 'bolder',
                            color: props.detailColor,
                        },
                        unit: {
                            fontSize: calculateFontSizeFromHeight(Number(props.height), fontOffset),
                            color: props.detailColor,
                            padding: [0, 0, 0, 5]
                        }
                    }
                },
                data: [
                    {
                        value: props.value
                    }
                ]
            }
        ]
    })

    onMounted(() => {
        const dom = document.getElementById(id.value) as HTMLInputElement;
        chart.value = echarts.init(dom);
        option.value && chart.value.setOption(option.value);
    })

    watch([() => props.min, () => props.max, () => props.value], () => {
        chart.value.setOption({
            series: [{
                min: props.min,
                max: props.max,
                data: [{ value: props.value }]
            }]
        })
    })

    watch([() => props.startLabel, () => props.endLabel], () => {
        chart.value.setOption({
            series: [{
                axisLabel: {
                    formatter: (val: number) => formatterLabel(Number(props.min), Number(props.max), val, String(props.startLabel), props.endLabel)
                },
            }]
        })
    })

    watch(() => props.detailColor, (val) => {
        chart.value.setOption({
            series: [{
                detail: {
                    rich: {
                        value: {
                            color: val,
                        },
                        unit: {
                            color: val,
                        }
                    }
                }
            }]
        })
    })

    watch(() => props.gradient, (val) => {
        chart.value.setOption({
            series: [{
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 1, 1, 1, val),
                },
            }]
        })
    }, { deep: true })

    watch([() => props.height], (height) => {
        chart.value.resize({
            height: height,
            width: calculateWidth(Number(height)),
        })
        chart.value.setOption({
            series: [{
                axisLabel: {
                    fontSize: calculateFontSizeFromHeight(Number(height), 10),
                },
                detail: {
                    rich: {
                        value: {
                            fontSize: calculateFontSizeFromHeight(Number(height), fontOffset),
                        },
                        unit: {
                            fontSize: calculateFontSizeFromHeight(Number(height), fontOffset),
                        }
                    }
                },
            }]
        })
    })

    return { id, uid, calculateFontSizeFromHeight, formatterLabel, calculateWidth }
}
