import PersonIcon from '@/assets/icons/modules/customer-insight/PersonIcon.vue';
import EllipsisIcon from '@/assets/icons/modules/customer-insight/EllipsisIcon.vue';
import HomeIcon from '@/assets/icons/modules/customer-insight/HomeIcon.vue';
import PDPAIcon from '@/assets/icons/modules/customer-insight/PDPAIcon.vue';

import ChannelLineIcon from '@/assets/icons/modules/customer-insight/ChannelLineIcon.vue';
import ChannelEDMIcon from '@/assets/icons/modules/customer-insight/ChannelEDMIcon.vue';
import ChannelSmsIcon from '@/assets/icons/modules/customer-insight/ChannelSmsIcon.vue';
import { h } from 'vue';

export const adjustDefaultLayout = () => {
  const contentWrapper = document.getElementsByClassName('content-wrapper')[0] as HTMLElement;
  const contentBody = document.getElementsByClassName('content-body')[0] as HTMLElement;
  if (contentWrapper) contentWrapper.style.cssText = 'display:initial !important;height: 100% !important;margin: 0 !important;padding: 0 !important;';
  if (contentBody) contentBody.style.cssText = 'display: initial !important;flex-direction: initial !important;flex-grow: initial !important;';
};

export const unMountedDefaultLayout = () => {
  const contentWrapper = document.getElementsByClassName('content-wrapper')[0] as HTMLElement;
  const contentBody = document.getElementsByClassName('content-body')[0] as HTMLElement;
  if (contentWrapper) contentWrapper.style.cssText = '';
  if (contentBody) contentBody.style.cssText = '';
};

export const resolveConfigTypeIcon = (typeName: string) => {
  const headerName = typeName.toLowerCase();
  switch (headerName) {
    case 'address':
      return h(HomeIcon);
    case 'profile':
      return h(PersonIcon);
    case 'pdpa_info':
      return h(PDPAIcon);
    default:
      return h(EllipsisIcon);
  }
};

export const resolveSocialChannelIcon = (typeName: string) => {
  const channelName = typeName.toLowerCase();
  switch (channelName) {
    case 'email':
      return h(ChannelEDMIcon);
    case 'line':
      return h(ChannelLineIcon);
    case 'sms':
      return h(ChannelSmsIcon);
  }
};

export const resolveKeyMapping = (keyMapping?: { [key: string]: string }, targetKey?: string) => {
  let label = targetKey || '';
  if (keyMapping) {
    if (keyMapping[label]) {
      label = keyMapping[label];
    } else label = targetKey || '';
  }
  return label;
};

// Recursive function to deep clone an object or array
export function deepClone<T>(obj: T): T {
  if (typeof obj !== 'object' || obj === null) {
    return obj; // If it's not an object or is null, return as is
  }

  // Create an empty object or array to hold the cloned properties
  const clone: any = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // Recursively clone each property
      clone[key] = deepClone(obj[key]);
    }
  }

  return clone as T;
}

export const includeTimeFn = (date: Date | null, time: string | null): Date => {
  const calDate = date == null ? new Date() : date;
  const calTime = time == null ? '00:00:00' : time;
  const [inputHours, inputMinutes, inputSeconds] = calTime.split(':').map(Number);
  // Add time
  calDate.setHours(inputHours || 0);
  calDate.setMinutes(inputMinutes || 0);
  calDate.setSeconds(inputSeconds || 0);

  return calDate;
};
