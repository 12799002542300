import { ref, Ref, reactive } from 'vue';

export default function useImageTabPanel() {
  const isChangedRadiusMode: Ref<boolean> = ref(false);
  const disabledPadding: Ref<boolean> = ref(true);
  const uploadImage: any = reactive({ file: null, url: null });
  const imgWidth: Ref<number> = ref(0);
  const imgHeight: Ref<number> = ref(0);
  const spacingTop: Ref<number> = ref(10);
  const spacingBottom: Ref<number> = ref(10);
  const indentLeft: Ref<number> = ref(10);
  const indentRight: Ref<number> = ref(10);
  const alignMent: Ref<string> = ref('center');
  const justifyMent: Ref<string> = ref('center');
  const borderRadius: Ref<Record<string, any>> = ref({
    all: 0,
    topLeft: 0,
    bottomLeft: 0,
    topRight: 0,
    bottomRight: 0,
  });
  const borderType: Ref<string> = ref('solid');
  const borderPx: Ref<string> = ref('1');
  const isColorPickerOpen: Ref<boolean> = ref(false);
  const borderColor: Ref<string> = ref('');
  const altText: Ref<string> = ref('');
  const imgLink: Ref<string> = ref('');
  const openLink: Ref<any> = ref(1);

  const onChangeRadiusMode = () => {
    isChangedRadiusMode.value = !isChangedRadiusMode.value;
  };

  const handleDisablePadding = () => {
    disabledPadding.value = !disabledPadding.value;
  };

  const onSelectAlignMent = (align: string) => {
    alignMent.value = align;
  };

  const onSelectJustifyMent = (justify: string) => {
    justifyMent.value = justify;
  };

  const onSelectBorder = (border: string) => {
    borderType.value = border;
  };

  const onSelectBorderSize = (px: string) => {
    borderPx.value = px;
  };

  const onToggleColorPicker = () => {
    isColorPickerOpen.value = !isColorPickerOpen.value;
  };

  const onChangeBorderColor = (color: string) => {
    borderColor.value = color;
  };

  const onSelectOpenLink = (type: number) => {
    openLink.value = type;
  };

  const handleUploadImageChange = (e: any) => {
    const filesOBJ: File = e.target?.files[0];
    try {
      if (!isFileImage(filesOBJ)) {
        console.log('invalid format');
        return;
      }
      const newObjUrl = URL.createObjectURL(filesOBJ as Blob);

      uploadImage.file = filesOBJ;
      uploadImage.url = newObjUrl;
      onGetImageSize(uploadImage.url as string);
    } catch (err) {}
  };

  const onGetImageSize = (url: string) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      imgWidth.value = img.width;
      imgHeight.value = img.height;
    };
  };

  const isFileImage = (file: any) => file && file.type.split('/')[0] === 'image';

  const clearFile = (e: any) => {
    uploadImage.file = null;
    uploadImage.url = null;
    imgWidth.value = 0;
    imgHeight.value = 0;
    e.value = null;
  };

  return {
    isChangedRadiusMode,
    disabledPadding,
    uploadImage,
    imgWidth,
    imgHeight,
    spacingTop,
    spacingBottom,
    indentLeft,
    indentRight,
    alignMent,
    justifyMent,
    borderType,
    borderRadius,
    borderPx,
    borderColor,
    altText,
    imgLink,
    openLink,
    isColorPickerOpen,
    onChangeRadiusMode,
    handleDisablePadding,
    onSelectAlignMent,
    onSelectJustifyMent,
    onSelectBorder,
    onSelectBorderSize,
    onChangeBorderColor,
    onToggleColorPicker,
    onSelectOpenLink,
    handleUploadImageChange,
    clearFile,
  };
}
