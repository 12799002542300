import { ref, Ref } from 'vue';
import notificationModel from '@/models/notification/notification';
import apiService from '@/services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

export default function checkOutOfCredit() {
  const { fetchListOverview } = notificationModel();
  const { openDefaultErrorModal } = useValidationModal();
  const channelCredit: Ref<any> = ref(null!);
  const smsCreditRemaining: Ref<number> = ref(0);
  const lineCreditRemaining: Ref<number> = ref(0);
  const emailCreditRemaining: Ref<number> = ref(0);

  function onlyUnique(value: any, index: any, array: string | any[]) {
    return array.indexOf(value) === index;
  }

  const getChannelCreditBalance = async () => {
    fetchListOverview.payload.List_Credit_Status = [];

    await apiService
      .apiRequest(fetchListOverview)
      .then((response) => {
        const resp = response.data;
        channelCredit.value = resp;
        resp.forEach((x: any) => {
          if (x.channel === 1) {
            smsCreditRemaining.value = x.credit_balance;
          } else if (x.channel === 2) {
            emailCreditRemaining.value = x.credit_balance;
          } else if (x.channel === 3) {
            lineCreditRemaining.value = x.credit_balance;
          }
        });
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      });
  };
  async function resolveTextNormalCredit(mkaNodeAction: any) {
    await getChannelCreditBalance();
    let NormalCredit = '';
    let newMkaNodeAction: any[] = [];

    if (smsCreditRemaining.value <= 0) {
      mkaNodeAction = mkaNodeAction.filter((x: any) => x.data.type != 301);
    }
    if (emailCreditRemaining.value <= 0) {
      mkaNodeAction = mkaNodeAction.filter((x: any) => x.data.type != 302);
    }
    if (lineCreditRemaining.value <= 0) {
      mkaNodeAction = mkaNodeAction.filter((x: any) => x.data.type != 303);
    }

    mkaNodeAction.forEach((x: any) => {
      newMkaNodeAction.push(x.data.type);
    });

    newMkaNodeAction = newMkaNodeAction.filter(onlyUnique);

    newMkaNodeAction.forEach((actionNormal: any, index: number) => {
      if (index + 1 < newMkaNodeAction.length) {
        switch (actionNormal) {
          case 301:
            NormalCredit = NormalCredit + 'SMS, ';
            break;
          case 302:
            NormalCredit = NormalCredit + 'Email, ';
            break;
          case 303:
            NormalCredit = NormalCredit + 'Line, ';
            break;
        }
      } else {
        switch (actionNormal) {
          case 301:
            NormalCredit = NormalCredit + 'SMS';
            break;
          case 302:
            NormalCredit = NormalCredit + 'Email';
            break;
          case 303:
            NormalCredit = NormalCredit + 'Line';
            break;
        }
      }
    });
    return NormalCredit;
  }
  async function resolveTextOutOfCredit(mkaNodeAction: any) {
    await getChannelCreditBalance();
    let OutOfCredit = '';
    let newMkaNodeAction: any[] = [];

    if (smsCreditRemaining.value > 0) {
      mkaNodeAction = mkaNodeAction.filter((x: any) => x.data.type != 301);
    }
    if (emailCreditRemaining.value > 0) {
      mkaNodeAction = mkaNodeAction.filter((x: any) => x.data.type != 302);
    }
    if (lineCreditRemaining.value > 0) {
      mkaNodeAction = mkaNodeAction.filter((x: any) => x.data.type != 303);
    }

    mkaNodeAction.forEach((x: any) => {
      newMkaNodeAction.push(x.data.type);
    });

    newMkaNodeAction = newMkaNodeAction.filter(onlyUnique);

    newMkaNodeAction.forEach((actionOutOfCredit: any, index: number) => {
      if (index + 1 < newMkaNodeAction.length) {
        switch (actionOutOfCredit) {
          case 301:
            OutOfCredit = OutOfCredit + 'SMS, ';
            break;
          case 302:
            OutOfCredit = OutOfCredit + 'Email, ';
            break;
          case 303:
            OutOfCredit = OutOfCredit + 'Line, ';
            break;
        }
      } else {
        switch (actionOutOfCredit) {
          case 301:
            OutOfCredit = OutOfCredit + 'SMS';
            break;
          case 302:
            OutOfCredit = OutOfCredit + 'Email';
            break;
          case 303:
            OutOfCredit = OutOfCredit + 'Line';
            break;
        }
      }
    });

    return OutOfCredit;
  }
  async function haveOutOfCredit(mkaNodeAction: any) {
    await getChannelCreditBalance();
    let isOutOfCredit = false;
    mkaNodeAction.forEach((x: any) => {
      switch (x.data.type) {
        case 301:
          if (smsCreditRemaining.value <= 0) {
            isOutOfCredit = true;
          }
          break;
        case 302:
          if (emailCreditRemaining.value <= 0) {
            isOutOfCredit = true;
          }
          break;
        case 303:
          if (lineCreditRemaining.value <= 0) {
            isOutOfCredit = true;
          }
          break;
      }
    });
    return isOutOfCredit;
  }

  async function haveOutOfCreditByChannal(Channal: any) {
    await getChannelCreditBalance();
    let isOutOfCredit = false;
    switch (Channal) {
      case 301:
        if (smsCreditRemaining.value <= 0) {
          isOutOfCredit = true;
        }
        break;
      case 302:
        if (emailCreditRemaining.value <= 0) {
          isOutOfCredit = true;
        }
        break;
      case 303:
        if (lineCreditRemaining.value <= 0) {
          isOutOfCredit = true;
        }
        break;
    }

    return isOutOfCredit;
  }

  return { resolveTextNormalCredit, resolveTextOutOfCredit, haveOutOfCredit, haveOutOfCreditByChannal };
}
