<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="82.562" height="50.023" viewBox="0 0 82.562 50.023">
    <g id="Group_116485" data-name="Group 116485" transform="translate(-58.356 -94.474)">
      <g id="Group_43863" data-name="Group 43863" transform="translate(58.356 94.474)">
        <path
          id="Path_28353"
          data-name="Path 28353"
          d="M215.018,112.919a2.11,2.11,0,0,0-1.957-1.338l-6.3-.84a4.817,4.817,0,0,1-3.209-2.337l-2.751-5.728c-.608-1.259-1.435-1.451-1.88-1.451a2.117,2.117,0,0,0-1.873,1.451L194.3,108.4a3.97,3.97,0,0,1-.957,1.194c2.574,3.049,7.767,8.2,15.065,10.646,1.207-.941,2.388-1.907,3.506-2.882l2.313-2.208A2.117,2.117,0,0,0,215.018,112.919Z"
          transform="translate(-132.536 -98.184)"
          fill="#221f1f"
        />
        <path
          id="Path_28354"
          data-name="Path 28354"
          d="M132.908,117.541A37.082,37.082,0,0,1,118,106.663a3.675,3.675,0,0,1-1.1.368l-6.3.84a1.943,1.943,0,0,0-1.165,3.573l4.6,4.389a4.8,4.8,0,0,1,1.227,3.768l-1.138,6.257a3.823,3.823,0,0,0-.055.442,15.348,15.348,0,0,1-5.155.878,14.947,14.947,0,1,1,8.257-27.4l.27.178.824-1.654.164-.327.274-.55a17.773,17.773,0,0,0-27.571,14.426l-.017.022.006.4a15.005,15.005,0,1,1-6.727-12.494l.415.275,1.264-2.535-.017-.012a17.79,17.79,0,1,0,6.47,21.7l0-.009a17.815,17.815,0,0,0,23.669,9.228c.017-.008.369-.168.629-.3a17.7,17.7,0,0,0,2.532-1.539A159.62,159.62,0,0,0,132.908,117.541Z"
          transform="translate(-58.356 -94.474)"
          fill="#221f1f"
        />
        <path
          id="Path_28355"
          data-name="Path 28355"
          d="M216.068,148.97a3.447,3.447,0,0,1-.013-1.054q-2.983,2.268-6.6,4.691c-.741.495-1.465.971-2.164,1.422a3.967,3.967,0,0,1,1.286.38l5.6,3.022a2.833,2.833,0,0,0,1.346.385,1.673,1.673,0,0,0,1.31-.588,2.331,2.331,0,0,0,.38-2Z"
          transform="translate(-140.205 -123.843)"
          fill="#221f1f"
        />
      </g>
      <g id="Group_43865" data-name="Group 43865" transform="translate(61.819 134.871)">
        <path
          id="Path_28356"
          data-name="Path 28356"
          d="M74.445,192.41a4.866,4.866,0,0,1-3.508,1.372,4.538,4.538,0,0,1-4.892-4.76,4.646,4.646,0,0,1,4.892-4.866,4.763,4.763,0,0,1,3.389,1.411l-1.16,1.121a3.171,3.171,0,0,0-2.229-.87,3.026,3.026,0,0,0-3.177,3.2,2.959,2.959,0,0,0,3.177,3.125,3.4,3.4,0,0,0,2.321-.936Z"
          transform="translate(-66.045 -184.156)"
          fill="#221f1f"
        />
        <path
          id="Path_28357"
          data-name="Path 28357"
          d="M93.167,193.826v-3.732H88.631v3.732H86.89V184.6h1.741v3.89h4.536V184.6h1.727v9.231Z"
          transform="translate(-77.5 -184.397)"
          fill="#221f1f"
        />
        <path
          id="Path_28358"
          data-name="Path 28358"
          d="M116.866,189.009a4.741,4.741,0,1,1-9.481.013,4.742,4.742,0,1,1,9.481-.013Zm-7.767.039a3.029,3.029,0,1,0,6.053-.012,3.038,3.038,0,1,0-6.053.012Z"
          transform="translate(-88.763 -184.156)"
          fill="#221f1f"
        />
        <path
          id="Path_28359"
          data-name="Path 28359"
          d="M139.089,192.41a4.864,4.864,0,0,1-3.507,1.372,4.537,4.537,0,0,1-4.892-4.76,4.646,4.646,0,0,1,4.892-4.866,4.763,4.763,0,0,1,3.389,1.411l-1.16,1.121a3.172,3.172,0,0,0-2.228-.87,3.027,3.027,0,0,0-3.178,3.2,2.959,2.959,0,0,0,3.178,3.125,3.4,3.4,0,0,0,2.32-.936Z"
          transform="translate(-101.571 -184.156)"
          fill="#221f1f"
        />
        <path
          id="Path_28360"
          data-name="Path 28360"
          d="M160.08,189.009a4.741,4.741,0,1,1-9.481.013,4.742,4.742,0,1,1,9.481-.013Zm-7.767.039a3.028,3.028,0,1,0,6.052-.012,3.038,3.038,0,1,0-6.052.012Z"
          transform="translate(-112.512 -184.156)"
          fill="#221f1f"
        />
        <g id="Group_43864" data-name="Group 43864" transform="translate(48.558 0.078)">
          <path
            id="Path_28361"
            data-name="Path 28361"
            d="M181.954,192.387a4.752,4.752,0,0,1-3.363,1.384,4.721,4.721,0,1,1,0-9.441,4.755,4.755,0,0,1,3.363,1.385l-.488.422a4.041,4.041,0,0,0-2.875-1.174,4.075,4.075,0,0,0,0,8.149,4.126,4.126,0,0,0,2.888-1.173Z"
            transform="translate(-173.844 -184.33)"
            fill="#221f1f"
          />
          <path
            id="Path_28362"
            data-name="Path 28362"
            d="M202.915,193.8h-.936l-3.191-3.494h-2.518V193.8h-.712v-9.229h4.061a2.852,2.852,0,0,1,3.032,2.874,2.724,2.724,0,0,1-2.993,2.822Zm-6.645-4.166h3.27a2.064,2.064,0,0,0,2.334-2.2,2.127,2.127,0,0,0-2.254-2.2h-3.349Z"
            transform="translate(-185.777 -184.46)"
            fill="#221f1f"
          />
          <path
            id="Path_28363"
            data-name="Path 28363"
            d="M222.592,185.594,219.058,191h-.237l-3.56-5.406v8.2h-.712v-9.229h.843l3.534,5.379,3.494-5.379h.87V193.8h-.7Z"
            transform="translate(-196.214 -184.46)"
            fill="#221f1f"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
