<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10.137" height="15.119" viewBox="0 0 10.137 15.119">
    <g id="Group_52279" data-name="Group 52279" transform="translate(-432.964 -308.002)">
      <line
        id="Line_540"
        data-name="Line 540"
        x2="9.137"
        transform="translate(433.464 320.337)"
        fill="none"
        stroke-linecap="round"
        stroke-width="1"
      />
      <line
        id="Line_541"
        data-name="Line 541"
        x2="4.569"
        transform="translate(433.464 322.621)"
        fill="none"
        stroke-linecap="round"
        stroke-width="1"
      />
      <g id="Rectangle_18576" data-name="Rectangle 18576" transform="translate(433.007 308.002)" fill="none" stroke-width="1">
        <rect width="10" height="10" rx="1" stroke="none" />
        <rect x="0.5" y="0.5" width="9" height="9" rx="0.5" fill="none" />
      </g>
    </g>
  </svg>
</template>
