<template>
  <!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    xml:space="preserve"
  >
    <g id="Group_34367_00000041283861045533500980000005034976924522088072_" transform="translate(-479 -176)">
      <path
        id="Subtraction_269_00000058576656344219908650000016941104277586589628_"
        style="fill: #212529"
        d="M515.71,258.24
		c-0.9,0-1.74-0.47-2.19-1.25l-9.29-15.78h-7.05c-2.81,0-5.09-2.28-5.09-5.09v-37.26c0-2.81,2.28-5.09,5.09-5.09h63.64
		c2.81,0,5.09,2.28,5.09,5.09v37.24c0,2.81-2.28,5.09-5.09,5.09h-33.63l-9.29,15.78C517.46,257.75,516.62,258.24,515.71,258.24z
		 M500.54,199.34c-1.41,0-2.55,1.14-2.55,2.55v31.18c0,1.41,1.14,2.55,2.55,2.55h6.97l8.2,13.95l8.2-13.95h33.55
		c1.41,0,2.55-1.14,2.55-2.55v-31.18c0-1.41-1.14-2.55-2.55-2.55L500.54,199.34z"
      />

      <ellipse id="Ellipse_500_00000107551809747878921220000017796733144268555659_" style="fill: #212529" cx="529" cy="216.47" rx="5.2" ry="5.2" />

      <ellipse id="Ellipse_501_00000064332234891969668210000005912928935190813093_" style="fill: #212529" cx="511.18" cy="216.47" rx="5.2" ry="5.2" />

      <ellipse id="Ellipse_502_00000155105773684265513220000010854970209502168452_" style="fill: #212529" cx="546.82" cy="216.47" rx="5.2" ry="5.2" />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
@import '@assets/styles/base/button.scss';
</style>
