<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="25" viewBox="0 0 12 25" fill="currentColor">
    <path
      d="M2.71288 4.916C3.93233 4.916 4.92088 3.92744 4.92088 2.708C4.92088 1.48856 3.93233 0.5 2.71288 0.5C1.49344 0.5 0.504883 1.48856 0.504883 2.708C0.504883 3.92744 1.49344 4.916 2.71288 4.916Z"
      fill="#currentColor"
    />
    <path
      d="M2.71288 11.54C3.93233 11.54 4.92088 10.5515 4.92088 9.33202C4.92088 8.11258 3.93233 7.12402 2.71288 7.12402C1.49344 7.12402 0.504883 8.11258 0.504883 9.33202C0.504883 10.5515 1.49344 11.54 2.71288 11.54Z"
      fill="#currentColor"
    />
    <path
      d="M2.71288 18.165C3.93233 18.165 4.92088 17.1765 4.92088 15.957C4.92088 14.7376 3.93233 13.749 2.71288 13.749C1.49344 13.749 0.504883 14.7376 0.504883 15.957C0.504883 17.1765 1.49344 18.165 2.71288 18.165Z"
      fill="#currentColor"
    />
    <path
      d="M2.71288 24.789C3.93233 24.789 4.92088 23.8005 4.92088 22.581C4.92088 21.3616 3.93233 20.373 2.71288 20.373C1.49344 20.373 0.504883 21.3616 0.504883 22.581C0.504883 23.8005 1.49344 24.789 2.71288 24.789Z"
      fill="#currentColor"
    />
    <path
      d="M9.33691 4.916C10.5564 4.916 11.5449 3.92744 11.5449 2.708C11.5449 1.48856 10.5564 0.5 9.33691 0.5C8.11746 0.5 7.12891 1.48856 7.12891 2.708C7.12891 3.92744 8.11746 4.916 9.33691 4.916Z"
      fill="#currentColor"
    />
    <path
      d="M9.33691 11.54C10.5564 11.54 11.5449 10.5515 11.5449 9.33202C11.5449 8.11258 10.5564 7.12402 9.33691 7.12402C8.11746 7.12402 7.12891 8.11258 7.12891 9.33202C7.12891 10.5515 8.11746 11.54 9.33691 11.54Z"
      fill="#currentColor"
    />
    <path
      d="M9.33691 18.165C10.5564 18.165 11.5449 17.1765 11.5449 15.957C11.5449 14.7376 10.5564 13.749 9.33691 13.749C8.11746 13.749 7.12891 14.7376 7.12891 15.957C7.12891 17.1765 8.11746 18.165 9.33691 18.165Z"
      fill="#currentColor"
    />
    <path
      d="M9.33691 24.789C10.5564 24.789 11.5449 23.8005 11.5449 22.581C11.5449 21.3616 10.5564 20.373 9.33691 20.373C8.11746 20.373 7.12891 21.3616 7.12891 22.581C7.12891 23.8005 8.11746 24.789 9.33691 24.789Z"
      fill="#currentColor"
    />
  </svg>
</template>
