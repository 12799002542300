<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="27.193" height="19.125" viewBox="0 0 27.193 19.125">
    <path
      id="Path_31596"
      data-name="Path 31596"
      d="M310.215,10.449a5.9,5.9,0,0,0-1.082-2.7A3.889,3.889,0,0,0,306.409,6.6c-3.8-.275-9.511-.275-9.511-.275h-.012s-5.707,0-9.511.275A3.893,3.893,0,0,0,284.65,7.75a5.892,5.892,0,0,0-1.08,2.7,41.5,41.5,0,0,0-.271,4.4v2.062a41.524,41.524,0,0,0,.271,4.4,5.884,5.884,0,0,0,1.08,2.7,4.617,4.617,0,0,0,3,1.163c2.174.209,9.245.273,9.245.273s5.707-.009,9.519-.284a3.89,3.89,0,0,0,2.724-1.152,5.891,5.891,0,0,0,1.082-2.7,41.506,41.506,0,0,0,.272-4.4V14.85a41.236,41.236,0,0,0-.277-4.4M294.08,19.413v-7.64l7.349,3.833Z"
      transform="translate(-283.299 -6.324)"
      fill="red"
      fill-rule="evenodd"
    />
  </svg>
</template>
