<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g>
      <path
        class="st8"
        d="M242.75,189.54c-0.33-0.91-0.67-1.84-0.97-2.67c-0.3-0.83-0.61-1.67-0.91-2.51
		c-16.02-44.05-17.38-48.04-17.49-48.33c-0.03-0.09-0.07-0.19-0.1-0.27l-5.7-15.65c-1.8-4.97-5.45-9.05-10.19-11.4
		c-4.77-2.38-10.29-2.78-15.36-1.1l-0.59,0.21c-1.3,0.47-2.55,1.07-3.73,1.8c-4.81-8.75-15.19-12.8-24.65-9.62l-0.29,0.11
		c-1.87,0.68-3.63,1.62-5.23,2.81c-5.48-5.9-13.91-8.06-21.55-5.51l-0.37,0.14c-1.15,0.42-2.26,0.94-3.32,1.55l-7.67-21.06
		c-4.15-11.44-16.73-17.41-28.21-13.39l-0.12,0.04c-11.44,4.17-17.34,16.83-13.18,28.27l36.41,100.04L85.8,181.12
		c-12.53-4.25-26.16,2.22-30.8,14.61c-4.74,12.67,1.69,26.78,14.35,31.51l84.33,33.61l16.64,6.69c0.45,0.18,0.91,0.31,1.39,0.38
		c11.21,2.41,22.88,1.61,33.66-2.32C236.16,254.32,252.63,220.81,242.75,189.54z M224.33,226.63c-7.52,16.29-24.19,26.36-42.1,25.43
		c-2.09-0.1-4.17-0.36-6.23-0.76L75.41,211.17l-0.23-0.08c-2.09-0.73-3.73-2.37-4.48-4.45c-1.38-3.83,0.6-8.05,4.43-9.43
		c1.6-0.59,3.36-0.6,4.98-0.03l47.49,16.79c0.87,0.31,1.78,0.49,2.7,0.53c5.24,0.26,9.69-3.78,9.95-9.02l0.05-0.62
		c0.15-2.32-0.18-4.64-0.97-6.82L99.02,87.33c-0.99-2.73,0.41-5.76,3.15-6.75c2.73-1,5.76,0.41,6.75,3.15l24.71,67.88
		c0.07,0.23,0.15,0.46,0.25,0.68l3.72,10.23c1.59,4.36,6.4,6.6,10.76,5.02c4.36-1.59,6.6-6.4,5.02-10.76L139.3,118.1
		c-0.68-1.8,0.14-3.81,1.88-4.63l0.15-0.05c1.87-0.68,3.94,0.28,4.62,2.16L159.93,154c1.65,4.53,6.65,6.86,11.18,5.21
		c4.53-1.65,6.86-6.65,5.21-11.18l-10-27.48c-0.06-0.14-0.11-0.28-0.15-0.43c-0.56-1.91,0.53-3.91,2.44-4.48s3.91,0.53,4.48,2.44
		l10.07,27.67c1.61,4.41,6.49,6.69,10.91,5.09c4.42-1.61,6.7-6.49,5.1-10.91l-4.19-11.51c-0.68-1.8,0.14-3.81,1.88-4.63l0.24-0.09
		c1.87-0.68,3.94,0.28,4.62,2.15l5.72,15.71c0.06,0.17,0.81,2.21,10.94,30.05c1.9,5.22,4.08,11.61,5.95,17.18
		c0.06,0.19,0.13,0.39,0.21,0.57c0.22,0.5,0.4,1.02,0.56,1.55c0.2,0.55,0.36,0.99,0.66,1.8c0.3,0.81,0.53,1.66,0.83,2.49
		C229.71,205.62,228.91,216.8,224.33,226.63z"
      />
      <path
        class="st8"
        d="M58.15,110.06c1.74-0.99,3.1-2.53,3.86-4.38l0.08-0.2c1.05-2.57,0.87-5.48-0.5-7.9l-0.02-0.05
		c-11.9-21.29-4.28-48.2,17.01-60.09c21.29-11.9,48.19-4.28,60.09,17.01c2.31,4.13,3.94,8.61,4.83,13.27
		c0.5,2.73,2.23,5.08,4.69,6.37l0.19,0.1c4.4,2.29,9.83,0.58,12.12-3.82c0.94-1.81,1.24-3.88,0.85-5.88
		c-0.96-5.07-2.54-10.01-4.7-14.7C143.03,22.74,112.47,8.75,83.1,16.13C50,25.57,30.82,60.06,40.26,93.16
		c1.35,4.72,3.25,9.27,5.66,13.54C48.37,111.01,53.85,112.51,58.15,110.06z"
      />
    </g>
  </svg>
</template>
