import { ref, Ref, reactive, onBeforeMount, watch } from 'vue';
import { useRouter } from 'vue-router';
import { saveAs } from 'file-saver';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useConvertDate from '@/utils/hooks/useConvertDate';
import apiService from '@/services/api';
import campaignModel from '@/models/communication/campaign';

import useRedirectBangchak from '@/views/modules/custom-brands/bangchak/analytics/hooks/useRedirectBangchak';

// permission
import ability from '@/libs/acl/ability';
interface Props {
  channelInfo: Communication.Channel.Component.AllChannelTab | null;
}

export default function useCampaignTab(props: Props) {
  const router = useRouter();
  const { openDefaultErrorModal, openWarningModal, openSuccessModal, openErrorModal } = useValidationModal();
  const { convertDate } = useConvertDate();

  const { fetchCampaignListModel, duplicateCampaignModel, deleteCampaignModel, fetchCampaignReport, cancelCampaignModel } = campaignModel();
  const { redirectBangchak, brandBangChak, actionListBangChak } = useRedirectBangchak();

  const campaignList: Ref<Communication.Campaign.Campaign[]> = ref([]);
  const loading = ref(false);
  let searchTimeOut: ReturnType<typeof setTimeout> = null!;
  const filterForm: {
    status: number | null;
    search: string | null;
    order: {
      Seq: number;
      Key: string;
      Direction: string;
    }[];
  } = reactive({
    status: null,
    search: '',
    order: [],
  });

  const field: BaseTable.TableColumn[] = [
    { key: 'name', label: 'Campaign Name', sortable: true },
    { key: 'category', label: 'Category', sortable: true },
    { key: 'updated_dt', label: 'Last Update', sortable: true },
    { key: 'updated_by', label: 'Update by ', sortable: true },
    { key: 'status', label: 'Status ', sortable: true },
    { key: 'action', label: '' },
  ];

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });

  const statusOption = reactive([
    {
      label: 'Draft',
      value: 1,
    },
    {
      label: 'Preparing',
      value: 2,
    },
    {
      label: 'Scheduled',
      value: 4,
    },
    {
      label: 'Sending',
      value: 5,
    },
    {
      label: 'Pause',
      value: 7,
    },
    {
      label: 'Complete',
      value: 9,
    },
    {
      label: 'Cancel',
      value: 11,
    },
  ]);

  const resolveStatusClass = (status: number) => {
    switch (status) {
      case 1:
        return 'draft';
      case 2:
        return 'preparing';
      case 4:
        return 'scheduled';
      case 5:
        return 'sending';
      case 7:
        return 'pause';
      case 9:
        return 'complete';
      case 11:
        return 'cancel';
      default:
        return 'unknown';
    }
  };

  const onSelectAction = (action: ActionList.Action, campaign: Communication.Campaign.Campaign) => {
    switch (action.key) {
      case 'download': {
        fetchDownloadReport(campaign.campaignid);
        return;
      }
      case 'dashboard':
        return (
          // campaign.status == 9 &&
          router.push({
            path: `communication/sms/dashboard-by-campaign/${campaign.campaignid}`,
            query: {
              ...router.currentRoute.value.query,
            },
          })
        );
      case 'edit':
        return router.push({
          path: `communication/sms/edit-campaign/${campaign.campaignid}`,
          query: {
            ...router.currentRoute.value.query,
          },
        });
      case 'duplicate':
        return openWarningModal('Duplicate Campaign', `ต้องการ Duplicate Campaign ${campaign.name} หรือไม่`, () => duplicateCampaign(campaign));
      case 'delete':
        return openWarningModal(`ลบ Campaign`, `ต้องการลบ ${campaign.name} หรือไม่`, () => deleteCampaign(campaign.campaignid));
      case 'cancel':
        return openWarningModal(`ยกเลิก Campaign`, `ต้องการยกเลิก ${campaign.name} หรือไม่`, () => cancelCampaign(campaign.campaignid));
      default:
        return;
    }
  };

  const fetchDownloadReport = (campaignId: number) => {
    fetchCampaignReport.payload.CampaignId = campaignId;
    apiService
      .apiRequest(fetchCampaignReport)
      .then((response) => {
        const datesTime = new Date();
        saveAs(
          response as Blob | string,
          `Report-Campaign-${campaignId}-${datesTime.getFullYear()}${datesTime.getMonth()}${datesTime.getDate()}_${datesTime.getHours()}${datesTime.getMinutes()}${datesTime.getSeconds()}.xlsx`,
        );
      })
      .catch((err) => {
        try {
          openDefaultErrorModal(err);
        } catch {
          openErrorModal('เกิดข้อผิดพลาด', 'ไม่สามารถดาวน์โหลด Report ได้');
        }
      });
  };

  const fetchCampaignList = () => {
    loading.value = true;
    fetchCampaignListModel.payload.Channel = props.channelInfo?.value as Communication.Campaign.ChannelType;
    fetchCampaignListModel.payload.Query = filterForm.search;
    fetchCampaignListModel.payload.Status = filterForm.status;
    fetchCampaignListModel.payload.Page = pagination.currentPage;
    fetchCampaignListModel.payload.Limit = pagination.perPage;
    fetchCampaignListModel.payload.Ordering = filterForm.order;

    apiService
      .apiRequest(fetchCampaignListModel)
      .then((response) => {
        campaignList.value = response.data.records;
        pagination.totalRows = response.data.filtered_records;
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    if (sortedColumn.direction) {
      const sortModel = [
        {
          Seq: 1,
          Key: sortedColumn.key,
          Direction: sortedColumn.direction.toLocaleLowerCase(),
        },
      ];

      filterForm.order = sortModel;
    } else {
      filterForm.order = [];
    }

    if (pagination.currentPage == 1) return fetchCampaignList();
    pagination.currentPage = 1;
  };

  const onChangeSearch = () => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    searchTimeOut = setTimeout(() => {
      if (pagination.currentPage == 1) return fetchCampaignList();
      pagination.currentPage = 1;
    }, 800);
  };

  const onClickRow = (campaign: Communication.Campaign.Campaign) => {
    if (campaign.status === 1) {
      if (ability.can('portal-cdp', 'cdp-portal-commu-createcampaign')) {
        return router.push({
          path: `communication/sms/edit-campaign/${campaign.campaignid}`,
          query: {
            ...router.currentRoute.value.query,
          },
        });
      }
    } else {
      return router.push({
        path: `communication/sms/dashboard-by-campaign/${campaign.campaignid}`,
        query: {
          ...router.currentRoute.value.query,
        },
      });
    }
  };

  function duplicateCampaign(campaign: Communication.Campaign.Campaign) {
    duplicateCampaignModel.payload.CampaignId = campaign.campaignid;

    apiService
      .apiRequest(duplicateCampaignModel)
      .then(() => openSuccessModal('Duplicate สำเร็จ', '', '', undefined, fetchCampaignList))
      .catch((err) => openDefaultErrorModal(err));
  }

  function deleteCampaign(campaignId: number) {
    deleteCampaignModel.payload.CampaignId = campaignId;

    apiService
      .apiRequest(deleteCampaignModel)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        fetchCampaignList();
      });
  }

  function cancelCampaign(campaignId: number) {
    cancelCampaignModel.payload.CampaignId = campaignId;

    apiService
      .apiRequest(cancelCampaignModel)
      .then(() => openSuccessModal('Cancel สำเร็จ', '', '', undefined, fetchCampaignList))
      .catch((err) => openDefaultErrorModal(err));
  }

  watch(
    () => pagination.currentPage,
    () => {
      fetchCampaignList();
    },
  );

  watch(
    () => filterForm.status,
    () => {
      if (pagination.currentPage == 1) return fetchCampaignList();
      pagination.currentPage = 1;
    },
  );

  onBeforeMount(() => {
    fetchCampaignList();
  });

  return {
    loading,
    field,
    campaignList,
    pagination,
    actionListBangChak,
    brandBangChak,
    statusOption,
    filterForm,
    onClickRow,
    onSort,
    onSelectAction,
    onChangeSearch,
    resolveStatusClass,
    convertDate,
  };
}
