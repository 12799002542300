import { Directive, getCurrentInstance } from 'vue';

import Sortable from 'sortablejs';

export default function useCreateMessageTab() {
  const vm = getCurrentInstance()?.proxy;

  const sortableMessage: Directive = {
    beforeMount(el: HTMLElement) {
      const container = el;

      const option: Sortable.SortableOptions = {
        chosenClass: 'is-sorting',
        ghostClass: 'sortable-ghost',
        dragClass: 'sortable-drag',
        draggable: '.line-editor-container',
        handle: '.reorder-button',
        onEnd: onReordered,
        disabled: false,
        animation: 300,
        easing: 'cubic-bezier(0.22, 1, 0.36, 1)',
      };

      Sortable.create(container, option);
    },
  };

  const onReordered = (event: Sortable.SortableEvent) => {
    vm?.$emit('reorder-message', event);
  };

  return { sortableMessage };
}
