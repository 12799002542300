export default function datasourceModel() {
  const fetchSegmentResultModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/result`,
    method: 'POST',
    payload: {
      SegmentId: null,
      Search_Value: null, //028017669, 17/06/2022 13:53:15
      Page: null,
      Limit: null,
      Filters: [],
      Flag_Count: false,
    },
  };

  const fetchSegmentFetchModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/fetch`,
    method: 'POST',
    payload: {
      SegmentId: null,
    },
  };

  const fetchSegmentSaveModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Save`,
    method: 'POST',
    payload: {
      Save_Type: null,
      Type: null,
      Name: '',
      SegmentId: null,
      Apply_SegmentId: null,
      Dest_SegmentId: null,
      Root_TableId: null,
      Root_FolderId: null,
      Dest_FolderId: null,
      saveColumns: [],
      select_flag: null,
      Datas: [],
    },
  };

  const fetchCountRecord: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Count_Record`,
    method: 'POST',
    payload: {},
  };

  const fetchCancelSegmentModel: API.RequestModel<{ segmentId: number }> = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Cancel_Apply`,
    method: 'POST',
    payload: {
      segmentId: null!,
    },
  };

  return { fetchSegmentResultModel, fetchSegmentSaveModel, fetchCancelSegmentModel, fetchSegmentFetchModel, fetchCountRecord };
}
