export default function filterTable() {
  const fetchFilterTableAndOrdering: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Filter/Preview`,
    method: 'POST',
    payload: {
      TableId: null,
      ColumnId: null,
      Page: null,
      Limit: null,
    },
  };

  return { fetchFilterTableAndOrdering };
}
