<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22.437" height="22.437" viewBox="0 0 22.437 22.437">
    <path
      id="add-icon"
      data-name="Path 29"
      d="M-323.932,150.739h0a11.218,11.218,0,0,0-11.218,11.218h0a11.219,11.219,0,0,0,11.218,11.219h0a11.219,11.219,0,0,0,11.218-11.219h0A11.218,11.218,0,0,0-323.932,150.739Zm5.983,11.416a1.059,1.059,0,0,1-1.069.928h-3.895v3.346a1.059,1.059,0,0,1-.928,1.069,1.031,1.031,0,0,1-1.131-1.025v-3.39h-3.992a1.059,1.059,0,0,1-1.069-.928,1.029,1.029,0,0,1,1.024-1.131h4.037v-3.691a1.059,1.059,0,0,1,.929-1.069,1.028,1.028,0,0,1,1.13,1.024v3.736h3.939A1.031,1.031,0,0,1-317.949,162.155Z"
      transform="translate(335.15 -150.739)"
    />
  </svg>
</template>

<style lang="scss" scoped>
@import '@assets/styles/base/button.scss';
</style>
