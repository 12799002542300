<template>
  <svg id="Group_116012" data-name="Group 116012" xmlns="http://www.w3.org/2000/svg" width="38.688" height="32.25" viewBox="0 0 38.688 32.25">
    <path
      id="Path_309681"
      data-name="Path 309681"
      d="M44.285,29.85h-.236l-10.9-11.926a1.843,1.843,0,0,0-1.284-.6l-.086,0a1.837,1.837,0,0,0-1.248.485c-.042.038-.082.077-.121.119l-8.675,9.492a.307.307,0,0,1-.443,0l-3.565-3.9A1.859,1.859,0,0,0,15.1,23.4c-.041.038-.079.077-.116.118L9.459,29.562a.882.882,0,0,1-.257-.609V24.861l7.745-8.033a.391.391,0,0,1,.289-.115.268.268,0,0,1,.254.122l3.977,4.35a1.252,1.252,0,0,0,.873.406,1.212,1.212,0,0,0,.9-.328c.027-.026.053-.053.079-.079L33.725,9.8V15.43H35.67V6.787H26.154V8.734h5.909L22.389,19.315l-3.47-3.793a2.33,2.33,0,0,0-1.687-.754,2.581,2.581,0,0,0-1.693.71L9.2,22.057V1.2a1.2,1.2,0,0,0-2.4,0V30A2.252,2.252,0,0,0,9.045,32.25h35.24a1.2,1.2,0,0,0,0-2.4"
      transform="translate(-6.796)"
    />
  </svg>
</template>
