export default function segmentModal() {
  const fetchFolderForModal: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Folder_list`,
    method: 'POST',
    payload: {
      root_tableId: null,
    },
  };

  const fetchSegmentForModal: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/list`,
    method: 'POST',
    payload: {
      ref_type: 0, // List By Folder = 1 , Datasource = 2 , Brand = 3
      reference: 0, // folderId , RootTableId (Datasource) , BrandI
    },
  };

  const editSegmentForEditCustomModal: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Save`,
    method: 'POST',
    payload: {},
  };

  const fetchDisplayForModal: API.RequestModel<{ TableId: number }> = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Display/Get`,
    method: 'GET',
    payload: {
      TableId: null!,
    },
  };

  return { fetchFolderForModal, fetchSegmentForModal, editSegmentForEditCustomModal, fetchDisplayForModal };
}
