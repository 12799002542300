import { getCurrentInstance, onMounted, ref, Ref, watch } from 'vue';

import Quill from 'quill';
interface Props {
  selectedElement?: any;
}

const FONT_SIZE = ['9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px'];

const Size = Quill.import('attributors/style/size');
Size.whitelist = FONT_SIZE;
Quill.register(Size as string, true);

const options = {
  theme: 'snow',
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ size: FONT_SIZE }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      ['clean'],
    ],
  },
};

export default function useTextTabPanel(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const htmlContent: Ref<string> = ref('');
  const enabledPadding: Ref<boolean> = ref(null!);
  const paddingTop: Ref<number> = ref(0);
  const paddingBottom: Ref<number> = ref(0);
  const paddingLeft: Ref<number> = ref(0);
  const paddingRight: Ref<number> = ref(0);

  watch(enabledPadding, () => {
    buildLayoutObjProperties(enabledPadding.value, paddingTop.value, paddingBottom.value, paddingLeft.value, paddingRight.value);
  });

  watch(paddingTop, () => {
    buildLayoutObjProperties(enabledPadding.value, paddingTop.value, paddingBottom.value, paddingLeft.value, paddingRight.value);
  });

  watch(paddingBottom, () => {
    buildLayoutObjProperties(enabledPadding.value, paddingTop.value, paddingBottom.value, paddingLeft.value, paddingRight.value);
  });

  watch(paddingLeft, () => {
    buildLayoutObjProperties(enabledPadding.value, paddingTop.value, paddingBottom.value, paddingLeft.value, paddingRight.value);
  });

  watch(paddingRight, () => {
    buildLayoutObjProperties(enabledPadding.value, paddingTop.value, paddingBottom.value, paddingLeft.value, paddingRight.value);
  });

  const handleDisablePadding = () => {
    enabledPadding.value = !enabledPadding.value;
    buildLayoutObjProperties(enabledPadding.value, paddingTop.value, paddingBottom.value, paddingLeft.value, paddingRight.value);
  };

  const buildLayoutObjProperties = (
    enabledPadding: boolean,
    paddingTop: number,
    paddingBottom: number,
    paddingLeft: number,
    paddingRight: number,
  ) => {
    EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.EnablePadding = enabledPadding;
    EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Style = {
      'padding-top': `${paddingTop}px`,
      'padding-bottom': `${paddingBottom}px`,
      'padding-left': `${paddingLeft}px`,
      'padding-right': `${paddingRight}px`,
    };
    EDMRender('cdp-edm-editor');
    vm?.$emit('set-is-selected');
    vm?.$emit('handle-selected-element');
  };

  const quillDefineEvent = (quillInstance: any) => {
    quillInstance.on('text-change', function (delta: any, oldDelta: any, source: any) {
      if (source == 'user') {
        vm?.$emit('on-html-content-change', quillInstance.container.firstChild.innerHTML);
      }
    });
  };

  const quillPasteHTML = (quillInstance: any, html: string) => {
    quillInstance.container.firstChild.innerHTML = html;
  };

  onMounted(() => {
    htmlContent.value = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Content.Properties.Text;
    enabledPadding.value = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.EnablePadding;
    paddingTop.value = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Padding.Top;
    paddingBottom.value = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Padding.Bottom;
    paddingLeft.value = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Padding.Left;
    paddingRight.value = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Padding.Right;
    const quill: Quill = new Quill('#quill-editor', options);
    quillDefineEvent(quill);
    quillPasteHTML(quill, htmlContent.value);
  });

  return {
    enabledPadding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    handleDisablePadding,
  };
}
