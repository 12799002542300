<template>
  <svg fill="#007fff" xmlns="http://www.w3.org/2000/svg" width="23.741" height="13.752" viewBox="0 0 23.741 13.752">
    <g id="Group_109653" data-name="Group 109653" transform="translate(0 0)">
      <g id="Group_33788" data-name="Group 33788" transform="translate(0 0)">
        <g id="Group_109653-2" data-name="Group 109653" transform="translate(0 0)">
          <path
            class="stroke"
            id="Path_22591"
            data-name="Path 22591"
            d="M4.617,13.753A4.589,4.589,0,0,1,0,9.378a4.491,4.491,0,0,1,3.144-4.41,6.879,6.879,0,0,1,12.745-1.2,5.023,5.023,0,0,1,5.05,2.213A3.944,3.944,0,0,1,23.734,10a4.029,4.029,0,0,1-4.074,3.749ZM4.49,6.661A2.6,2.6,0,0,0,1.9,9.3a2.66,2.66,0,0,0,2.7,2.554H19.685a2.125,2.125,0,0,0,2.149-1.908A2.071,2.071,0,0,0,19.87,7.713a.3.3,0,0,1-.257-.186A3.12,3.12,0,0,0,15.07,6.081a.3.3,0,0,1-.154.044.3.3,0,0,1-.285-.234A4.976,4.976,0,0,0,4.8,6.406a.282.282,0,0,1-.281.256H4.49"
            transform="translate(0.001 -0.001)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<style lang="scss" scoped>
.stroke {
  stroke: none !important;
}
</style>
