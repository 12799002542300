<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g>
      <path
        class="st8"
        d="M129.83,38.57h-0.91l0,0c-63.37,0-114.75,51.37-114.75,114.75c0,63.37,51.37,114.75,114.75,114.75
		c63.37,0,114.75-51.37,114.75-114.75H128.92L129.83,38.57z"
      />
      <path
        class="st8"
        d="M269.29,132.35c0-64.21-51.76-116.4-115.96-116.94l-0.93,116.94H269.29z M250.13,114.73h-80.02l0.6-80.02
		C211.15,42.46,242.68,74.23,250.13,114.73z"
      />
      <path class="st8" d="M153.27,15.4c0.02,0,0.04,0,0.06,0v0H153.27z" />
    </g>
  </svg>
</template>
