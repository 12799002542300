<template>
  <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.50193 10.5394L4.3815 13.0414L1.43947 13.5193L1.50193 10.5394ZM1.50193 10.5394L8.00879 3.05054L10.8884 5.55252L4.3815 13.0414L1.50193 10.5394Z"
      fill="#707070"
    />
    <path
      d="M12.0492 2.75691L10.615 1.51074C10.216 1.16408 9.61156 1.20649 9.2649 1.60546C8.91824 2.00443 8.96065 2.60889 9.35963 2.95554L10.7939 4.20172C11.1928 4.54838 11.7973 4.50597 12.1439 4.10699C12.4906 3.70802 12.4482 3.10357 12.0492 2.75691Z"
      fill="#707070"
    />
    <path d="M12.1437 4.10676L9.26465 1.60522L8.63697 2.32763L11.516 4.82916L12.1437 4.10676Z" fill="#707070" />
    <path
      d="M13.375 14.99H0.787C0.352352 14.99 0 15.3423 0 15.777C0 16.2116 0.352352 16.564 0.787 16.564H13.375C13.8096 16.564 14.162 16.2116 14.162 15.777C14.162 15.3423 13.8096 14.99 13.375 14.99Z"
      fill="#707070"
    />
  </svg>
</template>
