<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25.354" height="25.186" viewBox="0 0 25.354 25.186">
    <g id="Group_36004" data-name="Group 36004" transform="translate(0 0)">
      <g id="Group_36005" data-name="Group 36005" transform="translate(0 0)">
        <path
          id="Path_23381"
          data-name="Path 23381"
          d="M52.024,35.255a1.465,1.465,0,0,1-.632-.147,3.36,3.36,0,0,1-1.214-1.047,9.2,9.2,0,0,1-1.288-2.374,13.791,13.791,0,0,1-.943-3.974,6.092,6.092,0,0,1,.173-2.236,2.255,2.255,0,0,1,.474-.9,1.3,1.3,0,0,1,.977-.443,1.348,1.348,0,0,1,.556.121c.051.023.1.047.153.073l.076.037a3.7,3.7,0,0,1,1.062.981,9.309,9.309,0,0,1,1.318,2.457,13.927,13.927,0,0,1,.905,3.9,6.1,6.1,0,0,1-.159,2.16,2.278,2.278,0,0,1-.49.95,1.292,1.292,0,0,1-.969.434M49.679,25.8a1.03,1.03,0,0,0-.14.2l-.01.021c-.012.023-.026.05-.037.078a1.541,1.541,0,0,0-.053.154,4.3,4.3,0,0,0-.1,1.5,11.4,11.4,0,0,0,.843,3.47A7.1,7.1,0,0,0,51.236,33.1a1.539,1.539,0,0,0,.631.488.152.152,0,0,0,.05.011c.006,0,.01,0,.021-.019.021-.039.043-.078.065-.116a1.6,1.6,0,0,0,.143-.293,3.76,3.76,0,0,0,.114-1.4,11.372,11.372,0,0,0-.832-3.55,7.727,7.727,0,0,0-.964-1.789,1.767,1.767,0,0,0-.573-.54l-.022-.011a.815.815,0,0,0-.189-.081v0Z"
          transform="translate(-36.589 -18.431)"
        />
        <path
          id="Path_23382"
          data-name="Path 23382"
          d="M30.3,40.182a.119.119,0,0,1-.057-.015l-.208-.117c-.146-.083-.292-.165-.442-.242a4.528,4.528,0,0,0-2.073-.506,5.2,5.2,0,0,0-1.375.194l-.231.063c-.373.1-.758.206-1.131.327a1.375,1.375,0,0,1-.422.069,1.428,1.428,0,0,1-.9-.348,3.407,3.407,0,0,1-1.127-1.769,3.578,3.578,0,0,1-.089-1.965,2.605,2.605,0,0,1,.269-.632,1.179,1.179,0,0,1,.748-.545l.315-.089c.483-.136.982-.277,1.459-.448a4.386,4.386,0,0,0,2.3-1.888c.108-.175.208-.361.3-.541q.067-.125.135-.249a.118.118,0,0,1,.1-.061.116.116,0,0,1,.046.009c.051.021.118.091.108.145l-.029.167a6.315,6.315,0,0,0-.065,1.508.44.44,0,0,1-.095.312,5.329,5.329,0,0,1-1.661,1.461.108.108,0,0,0-.039.052,2.709,2.709,0,0,0,.01,1.441,3.2,3.2,0,0,0,.635,1.331c.031.038.066.073.1.108s.057.058.085.089a.1.1,0,0,0,.084.032c.109-.006.2-.008.29-.008a5.414,5.414,0,0,1,1.943.379.317.317,0,0,1,.161.132,6.521,6.521,0,0,0,.951,1.375.118.118,0,0,1,.015.139l-.018.031a.119.119,0,0,1-.1.06"
          transform="translate(-16.913 -23.991)"
        />
        <path
          id="Path_23383"
          data-name="Path 23383"
          d="M35.823,70.277a1.023,1.023,0,0,1-.734-.331,3.75,3.75,0,0,1-.663-1.012c-.061-.121-.12-.242-.18-.364-.1-.21-.206-.421-.316-.628-.074-.138-.158-.268-.247-.406-.04-.062-.081-.125-.12-.188a.118.118,0,0,1,.064-.175l.1-.031.376-.105c.273-.076.554-.154.827-.241a1.066,1.066,0,0,1,.324-.054.916.916,0,0,1,.722.376c.125.149.253.3.381.444.105.121.21.242.314.365a.515.515,0,0,0,.292.166l.15.033c.11.024.22.048.328.08a1.087,1.087,0,0,1,.725.828.48.48,0,0,1-.256.56,1.886,1.886,0,0,1-.321.143q-.277.1-.555.188c-.274.092-.548.184-.82.281A1.149,1.149,0,0,1,35.823,70.277Z"
          transform="translate(-25.614 -50.961)"
        />
        <path
          id="Path_23384"
          data-name="Path 23384"
          d="M74.1,40.082c-.039,0-.077,0-.109-.006l-.043,0a.116.116,0,0,1-.058-.016.524.524,0,0,1-.3-.5.492.492,0,0,1,.348-.456c.2-.071.4-.141.6-.21l.437-.152c.209-.074.419-.147.629-.218a.63.63,0,0,1,.2-.035.511.511,0,0,1,.483.379.572.572,0,0,1-.494.863c-.315.06-.635.134-.943.2-.211.048-.422.1-.633.142a.577.577,0,0,1-.117.011"
          transform="translate(-56.194 -29.384)"
        />
        <path
          id="Path_23385"
          data-name="Path 23385"
          d="M67.837,26.548a.5.5,0,0,1-.285-.093.456.456,0,0,1-.2-.51.581.581,0,0,1,.184-.3c.41-.327.853-.671,1.433-1.115a.492.492,0,0,1,.711.106,1.979,1.979,0,0,1,.15.237.505.505,0,0,1,.009.47.116.116,0,0,1-.026.037c-.013.013-.026.027-.038.04a.578.578,0,0,1-.123.111q-.774.474-1.553.941a.5.5,0,0,1-.259.075"
          transform="translate(-51.414 -18.65)"
        />
        <path
          id="Path_23386"
          data-name="Path 23386"
          d="M76.923,55.523a.452.452,0,0,1-.05,0l-.12-.012q-.843-.082-1.685-.167a.5.5,0,0,1-.462-.522.51.51,0,0,1,.508-.482c.338-.009.676-.013,1.015-.019l.521-.009.209,0h.04a.492.492,0,0,1,.517.52c0,.094-.009.188-.016.27l-.007.086a.118.118,0,0,1-.021.06A.553.553,0,0,1,76.923,55.523Z"
          transform="translate(-56.967 -41.467)"
        />
        <path
          id="Path_23387"
          data-name="Path 23387"
          d="M56.679,44.6l-.025,0a.119.119,0,0,1-.075-.052,5.15,5.15,0,0,1-.6-1.458,5.586,5.586,0,0,1-.18-1.279.118.118,0,0,1,.118-.119h0a1.6,1.6,0,0,1,.824,2.892.119.119,0,0,1-.065.019"
          transform="translate(-42.608 -31.83)"
        />
        <path
          id="Path_23388"
          data-name="Path 23388"
          d="M2.251,67.373A2.251,2.251,0,1,1,4.5,65.121a2.254,2.254,0,0,1-2.251,2.251"
          transform="translate(0 -48.006)"
        />
        <path
          id="Path_23389"
          data-name="Path 23389"
          d="M62.778,96.471a1.179,1.179,0,0,1-.081-2.354c.028,0,.056,0,.084,0a1.182,1.182,0,0,1,1.175,1.1,1.178,1.178,0,0,1-1.093,1.258c-.028,0-.056,0-.084,0"
          transform="translate(-47.036 -71.285)"
        />
        <path
          id="Path_23390"
          data-name="Path 23390"
          d="M82.37,14.288a2.148,2.148,0,1,1,2.148-2.148,2.151,2.151,0,0,1-2.148,2.148"
          transform="translate(-61.255 -7.63)"
        />
        <path
          id="Path_23391"
          data-name="Path 23391"
          d="M2.2,14.164a.76.76,0,0,1-.754-.691c-.027-.329-.041-.652-.041-.96a12.489,12.489,0,0,1,18.4-11.022.759.759,0,0,1,.224,1.16.75.75,0,0,1-.938.188,10.813,10.813,0,0,0-5.179-1.31A11,11,0,0,0,2.932,12.512c0,.27.011.548.034.829a.761.761,0,0,1-.687.819c-.035,0-.057,0-.08,0"
          transform="translate(-1.071 -0.001)"
        />
        <path
          id="Path_23392"
          data-name="Path 23392"
          d="M91.87,41.392a.74.74,0,0,1-.616-.323l-.014-.02a.746.746,0,0,1,.008-.869,10.932,10.932,0,0,0,1-11.358.748.748,0,0,1,.2-.917.779.779,0,0,1,.487-.175.737.737,0,0,1,.664.407A12.421,12.421,0,0,1,92.485,41.08.758.758,0,0,1,91.87,41.392Z"
          transform="translate(-69.564 -21.174)"
        />
        <path
          id="Path_23393"
          data-name="Path 23393"
          d="M36,98.753a12.386,12.386,0,0,1-4.14-.718.76.76,0,0,1,.249-1.477.769.769,0,0,1,.255.044,10.885,10.885,0,0,0,3.624.624c.252,0,.506-.009.758-.027.155-.011.308-.026.46-.046a.78.78,0,0,1,.1-.006.764.764,0,0,1,.089,1.521c-.19.025-.366.043-.537.055C36.575,98.743,36.288,98.753,36,98.753Z"
          transform="translate(-23.942 -73.729)"
        />
      </g>
    </g>
  </svg>
</template>
