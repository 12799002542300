<template>
  <svg id="Group_116016" data-name="Group 116016" xmlns="http://www.w3.org/2000/svg" width="36.161" height="29.313" viewBox="0 0 36.161 29.313">
    <path
      id="Path_309679"
      data-name="Path 309679"
      d="M21.709,71.739a4.963,4.963,0,1,0,3.51,1.454,4.969,4.969,0,0,0-3.51-1.454m0,8.027A3.064,3.064,0,1,1,24.773,76.7a3.067,3.067,0,0,1-3.064,3.064"
      transform="translate(1.384 -64.06)"
    />
    <path
      id="Path_309680"
      data-name="Path 309680"
      d="M37.047,72.045a13.127,13.127,0,0,0-9.39-3.972l-.256.01a13.1,13.1,0,0,0-8.97,3.826,6.582,6.582,0,0,0-5.518-2.986l-2.053.043L8.805,66.912a1.136,1.136,0,0,0-1.6,0l-2.3,2.3a1.14,1.14,0,0,0,0,1.608l2.3,2.3a1.136,1.136,0,0,0,1.6,0l1.858-1.92h2.249a4.373,4.373,0,0,1,3.762,2.172l.222.386a12.984,12.984,0,0,0-2.248,6.356H10.763v-.585a1.138,1.138,0,0,0-1.136-1.136H6.379a1.136,1.136,0,0,0-1.134,1.134v3.248A1.138,1.138,0,0,0,6.38,83.906H9.63a1.139,1.139,0,0,0,1.136-1.137V82.14h3.879a12.96,12.96,0,0,0,2.309,6.477l-.283.49a4.374,4.374,0,0,1-3.765,2.173l-2.142.043L8.8,89.362a1.132,1.132,0,0,0-1.6,0l-2.3,2.3a1.136,1.136,0,0,0,0,1.6l2.3,2.3a1.136,1.136,0,0,0,1.6,0l1.949-2.01h2.158a6.664,6.664,0,0,0,5.6-3.1l.052.057a13.663,13.663,0,0,0,1.276,1.078,12.993,12.993,0,0,0,15.648,0,13.386,13.386,0,0,0,1.276-1.079,13.056,13.056,0,0,0,.29-18.465M27.666,86.7a8.408,8.408,0,0,1,6.7,3.345,11.071,11.071,0,0,1-13.409,0A8.427,8.427,0,0,1,27.666,86.7m-.018-1.895a10.284,10.284,0,0,0-8.117,3.979,11.164,11.164,0,1,1,16.251,0,10.205,10.205,0,0,0-8.134-3.979"
      transform="translate(-4.567 -66.58)"
    />
  </svg>
</template>
