import { ref, Ref, watch, getCurrentInstance, onMounted } from 'vue';

interface Props {
  defaultTags: any;
  options: any;
  inputPlaceholder?: string;
  isTagCount?: boolean;
  maxTag?: number;
}

export default function useInputTagGenerator(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const tags: Ref<Record<string, any>> = ref(props.defaultTags);
  const listGroup: Ref<Record<string, any>> = ref([]);
  const isActive: Ref<boolean> = ref(false);
  const isLoadingList: Ref<boolean> = ref(false);
  const focus: Ref<number> = ref(null!);
  const currentScroll: Ref<number> = ref(0);

  const addTag = (event: any) => {
    event.preventDefault();
    if (event.target.value.length != 0) {
      const val = event.target.value.trim();
      if (val.length > 0) {
        if (tags.value.length >= 1) {
          for (let i = 0; i < tags.value.length; i++) {
            if (tags.value[i] === val) {
              return false;
            }
          }
        }
        tags.value.push(val);
        event.target.value = '';
      }
    } else {
      const HLList = document.querySelectorAll('.list-tag.selected');
      HLList.forEach((item) => {
        const val = (<HTMLInputElement>item).getAttribute('data-value');
        tags.value.push(val);
      });
      onFilterSelected();
    }
  };

  const onPasteAddTags = (event: any) => {
    const defaultTags = tags.value;
    const importValue = event.clipboardData.getData('text');
    const convertArray = importValue.split(',');
    const setFormetValue: any = [];
    for (let index = 0; index < convertArray.length; index++) {
      if (convertArray[index].length != 0) {
        const reset = convertArray[index].trim();
        setFormetValue.push(reset);
      }
    }
    const Result = defaultTags.concat(setFormetValue);
    tags.value = Result;

    const input = document.querySelectorAll('input#input-target-tag');
    input.forEach((elem) => {
      (<HTMLInputElement>elem).value = '';
    });
  };

  const removeLastTag = (event: any) => {
    if (event.target.value.length === 0) {
      removeTag(tags.value.length - 1);
    }
  };

  const removeTag = (index: any) => {
    tags.value.splice(index, 1);
    onFilterSelected();
  };

  const onKeyTarget = (value: any) => {
    vm?.$emit('on-search-target', value.target.value);
  };

  const onSelectItem = (item: any) => {
    tags.value.push(item.value);
    onFilterSelected();
  };

  const onScrolled = (e: Event | any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const result = {
      scrollTop: scrollTop,
      clientHeight: clientHeight,
      scrollHeight: scrollHeight,
    };

    vm?.$emit('on-infinite-scroll', result);
  };

  const onFilterSelected = () => {
    listGroup.value = props.options.filter((item: any) => {
      return props.defaultTags.indexOf(item.value) == -1;
    });
    isLoadingList.value = false;
  };

  const onChangeActive = (active: boolean) => {
    isActive.value = active;
    vm?.$emit('open', isActive.value);
  };

  const onArrowUpAndDown = (event: any) => {
    const elem = document.querySelector('.display-area');
    switch (event.keyCode) {
      case 38:
        if (focus.value === null) {
          focus.value = 0;
        } else if (focus.value > 0) {
          focus.value--;
          currentScroll.value = currentScroll.value - 32;
          elem?.scrollTo(0, currentScroll.value);
        }
        break;
      case 40:
        if (focus.value === null) {
          focus.value = 0;
        } else if (focus.value < listGroup.value.length - 1) {
          currentScroll.value = 32 * focus.value;
          elem?.scrollTo(0, currentScroll.value);
          focus.value++;
        }
        break;
    }
  };

  onMounted(() => {
    onFilterSelected();
  });

  watch(
    () => [props.options, props.options.length],
    () => {
      isLoadingList.value = true;
      onFilterSelected();
    },
    { deep: true },
  );

  watch(
    () => tags.value,
    () => {
      vm?.$emit('on-export-tags', tags.value);

      if (tags.value.length === props.maxTag) {
        onChangeActive(false);
      }
    },
    { deep: true },
  );

  return {
    focus,
    tags,
    listGroup,
    isActive,
    isLoadingList,
    addTag,
    removeTag,
    removeLastTag,
    onPasteAddTags,
    onKeyTarget,
    onSelectItem,
    onScrolled,
    onChangeActive,
    onArrowUpAndDown,
  };
}
