import router from '@/router';
import PreviewButton from '../preview-button/PreviewButton.vue';
import PreviewCard from '../preview-card/PreviewCard.vue';
import PreviewTab from '../preview-tab/PreviewTab.vue';
import PreviewDrawer from '../preview-drawer/PreviewDrawer.vue';
import PreviewDynamicTable from '../preview-dynamic-table/PreviewDynamicTable.vue';
import PreviewChart from '../preview-chart/PreviewChart.vue';
import PreviewDateTimePicker from '../preview-datetime-picker/PreviewDateTimePicker.vue';
import PreviewGrid from '../preview-grid/PreviewGrid.vue';
import PreviewScrollbar from '../preview-scrollbar/PreviewScrollbar.vue';
import PreviewTable from '../preview-table/PreviewTable.vue';
import PreviewBaseRightSideModal from '../preview-base-right-side-modal/PreviewBaseRightSideModal.vue';
import PreviewCustomVueSelect from '../preview-custom-v-select/PreviewCustomVueSelect.vue';
import PreviewLoading from '../preview-loading/PreviewLoading.vue';
import PreviewDialog from '../preview-dialog/PreviewDialog.vue';
import PreviewPDFMake from '../preview-pdf-make/PreviewPDFMake.vue';
import PreviewEdm from '../preview-edm/PreviewEdm.vue';
import PreviewColorPicker from '../preview-color-picker/PreviewColorPicker.vue';
import PreviewQuilljs from '../preview-text-editor/PreviewQuilljs.vue';
import PreviewFullCalendar from '../preview-full-calendar/PreviewFullCalendar.vue';
import PreviewPopperjs from '../preview-popperjs/PreviewPopperjs.vue';
import PreviewDownloadReport from '../preview-download-report/PreviewDownloadReport.vue';
import PreviewJsBarcode from '../preview-jsbarcode/PreviewJsBarcode.vue';
import PreviewChartMap from '../preview-chart-map/PreviewChartMap.vue';
import PreviewSignalR from '../preview-signalr/PreviewSignalR.vue';

export default function usePreview() {
  const resolveComponent = () => {
    const componentParam = router.currentRoute.value.params.component;

    switch (componentParam) {
      case 'base-table':
        return PreviewTable;
      case 'chart':
        return PreviewChart;
      case 'card':
        return PreviewCard;
      case 'base-tab':
        return PreviewTab;
      case 'drawer':
        return PreviewDrawer;
      case 'base-button':
        return PreviewButton;
      case 'base-tab':
        return PreviewTab;
      case 'dynamic-table':
        return PreviewDynamicTable;
      case 'datetime':
        return PreviewDateTimePicker;
      case 'grid':
        return PreviewGrid;
      case 'scrollbar':
        return PreviewScrollbar;
      case 'base-right-side-modal':
        return PreviewBaseRightSideModal;
      case 'custom-v-select':
        return PreviewCustomVueSelect;
      case 'preview-loading':
        return PreviewLoading;
      case 'preview-dialog':
        return PreviewDialog;
      case 'pdf-make':
        return PreviewPDFMake;
      case 'preview-edm':
        return PreviewEdm;
      case 'color-picker':
        return PreviewColorPicker;
      case 'preview-quill':
        return PreviewQuilljs;
      case 'full-calendar':
        return PreviewFullCalendar;
      case 'preview-popperjs':
        return PreviewPopperjs;
      case 'preview-download-report':
        return PreviewDownloadReport;
      case 'jsbarcode':
        return PreviewJsBarcode;
      case 'preview-chart-map':
        return PreviewChartMap;
      case 'signalr':
        return PreviewSignalR;
      default:
        return '';
    }
  };

  return { resolveComponent };
}
