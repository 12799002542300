import { onBeforeMount, getCurrentInstance } from 'vue';
interface Props {
  isOpen: boolean;
  isLoading?: boolean;
}
export default function useTemplateInfoModal(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const onCloseModal = () => {
    vm?.$emit('close-modal');
  };
  onBeforeMount(() => {});

  return { onCloseModal };
}
