import { i18n } from '@/libs/i18n';
import { toRaw } from 'vue';

export default function useConvertTextOparetor() {
  const convertOperator = (options: CreateSegmentModal.RuleOperator[]) => {
    if (options != undefined) {
      if (i18n.global.locale === 'th') {
        const saveColumns: CreateSegmentModal.RuleOperator[] = [];
        options.forEach((Object: CreateSegmentModal.RuleOperator) => {
          const result: CreateSegmentModal.RuleOperator = {
            description: mapDescription(String(Object.description)),
            name: Object.name,
            tootips_en: Object.tootips_en,
            tootips_th: Object.tootips_th,
            data_list: toRaw(Object.data_list),
            unit_list: toRaw(Object.unit_list),
            value: Object.value,
          };
          saveColumns.push(result);
        });
        return saveColumns;
      } else {
        return options;
      }
    } else {
      return [];
    }
  };

  const convertUnit = (item: CreateSegmentModal.RuleUnit[], id: CreateSegmentModal.RuleOperator) => {
    if (item != undefined) {
      if (i18n.global.locale === 'th') {
        const saveUnit: CreateSegmentModal.RuleUnit[] = [];
        item.forEach((unitObj: CreateSegmentModal.RuleUnit) => {
          const result: CreateSegmentModal.RuleUnit = {
            description: mapDescription(String(unitObj.description), id.value),
            name: unitObj.name,
            value: unitObj.value,
          };
          saveUnit.push(result);
        });
        return saveUnit;
      } else {
        return item;
      }
    } else {
      return [];
    }
  };

  const convertDataUnit = (item: CreateSegmentModal.RuleUnit[], id: CreateSegmentModal.RuleOperator) => {
    if (item != undefined) {
      if (i18n.global.locale === 'th') {
        const saveUnit: CreateSegmentModal.RuleUnit[] = [];
        item.forEach((unitObj: CreateSegmentModal.RuleUnit) => {
          const result: CreateSegmentModal.RuleUnit = {
            description: mapDescriptionDataUnit(String(unitObj.description), id.value),
            name: unitObj.name,
            value: unitObj.value,
          };
          saveUnit.push(result);
        });
        return saveUnit;
      } else {
        return item;
      }
    } else {
      return [];
    }
  };

  const convertFunction = (list: CreateSegmentModal.RuleAggregate[]) => {
    if (list != undefined) {
      if (i18n.global.locale === 'th') {
        const saveUnit: CreateSegmentModal.RuleAggregate[] = [];
        list.forEach((funcObj: CreateSegmentModal.RuleAggregate) => {
          const result: CreateSegmentModal.RuleAggregate = {
            description: mapDescription(String(funcObj.description)),
            name: funcObj.name,
            tootips_en: funcObj.tootips_en,
            tootips_th: funcObj.tootips_th,
            operator_list: toRaw(funcObj.operator_list),
            value: funcObj.value,
          };
          saveUnit.push(result);
        });
        return saveUnit;
      } else {
        return list;
      }
    } else {
      return [];
    }
  };

  const mapDescription = (item: string, id?: number) => {
    const txtOparetor = item.toLowerCase().trim();
    switch (txtOparetor) {
      case 'date is':
        return 'วันที่ ที่ระบุ';
      case 'date is after':
        return 'หลังวันที่ ที่ระบุ';
      case 'date is before':
        return 'ก่อนวันที่ ที่ระบุ';
      case 'date is between':
        return 'ระหว่างวันที่ ที่ระบุ';
      case 'date is not':
        return 'ไม่ใช่วันที่ ที่ระบุ';
      case 'is empty':
        return 'ไม่มีข้อมูล';
      case 'is not empty':
        return 'มีข้อมูล';
      case 'text equals':
        return 'เท่ากับข้อความ ที่ระบุ';
      case 'text contains':
        return 'ประกอบด้วยข้อความ ที่ระบุ';
      case 'text starts with':
        return 'ขึ้นต้นด้วย ข้อความที่ระบุ';
      case 'text ends with':
        return 'ลงท้ายด้วย ข้อความที่ระบุ';
      case 'text does not equal':
        return 'ไม่ใช่ข้อความ ที่ระบุ';
      case 'text does not contain':
        return 'ไม่ประกอบด้วย ข้อความที่ระบุ';
      case 'text ends with':
        return 'ลงท้ายด้วย ข้อความที่ระบุ';
      case 'equal':
        return 'เท่ากับตัวเลข ที่ระบุ';
      case 'greater than':
        return 'มากกว่า ตัวเลขที่ระบุ';
      case 'greater than or equal to':
        return 'มากกว่าหรือเท่ากับ ตัวเลขที่ระบุ';
      case 'less than':
        return 'น้อยกว่า ตัวเลขที่ระบุ';
      case 'less than or equal to':
        return 'น้อยกว่าหรือเท่ากับ ตัวเลขที่ระบุ';
      case 'contains':
        return 'ประกอบด้วย ตัวเลขที่ระบุ';
      case 'start with':
        return 'เริ่มต้นด้วย ตัวเลขที่ระบุ';
      case 'end with':
        return 'ลงท้ายด้วย ตัวเลขที่ระบุ';
      case 'between':
        return 'อยู่ระหว่าง ตัวเลขที่ระบุ';
      case 'not between':
        return 'ไม่อยู่ระหว่าง ตัวเลขที่ระบุ';
      case 'date is not between':
        return 'ไม่อยู่ระหว่างวันที่ ที่ระบุ';
      case 'not between':
        return 'ไม่อยู่ระหว่าง ตัวเลขที่ระบุ';
      case 'does not equal':
        return 'ไม่ใช่ค่า ที่ระบุ';
      case 'not contain':
        return 'ไม่ประกอบด้วยค่า ที่ระบุ';
      case 'not end with':
        return 'ไม่ลงท้ายด้วย ค่าที่ระบุ';
      case 'not start with':
        return 'ไม่เริ่มต้นด้วย ค่าที่ระบุ';
      case 'in':
        return 'เท่ากับข้อความ ที่ระบุ (หลายค่า)';
      case 'not in':
        return 'ไม่เท่ากับข้อความ ที่ระบุ (หลายค่า)';
      case 'day':
        if (id == 52) return 'วันนี้';
        else return 'วัน';
      case 'month':
        if (id == 52) return 'เดือนนี้';
        else return 'เดือน';
      case 'year':
        if (id == 52) return 'ปีนี้';
        else return 'ปี';
      case 'quarter':
        if (id == 52) return 'ควอเตอร์นี้';
        else return 'ควอเตอร์';
      case 'week':
        return 'สัปดาห์';
      case 'hour':
        return 'ชั่วโมง';
      // case 'count':
      //   return 'นับจำนวน';
      // case 'max':
      //   return 'ค่าสูงสุด';
      // case 'min':
      //   return 'ค่าต่ำสุด';
      // case 'sum':
      //   return 'ผลรวม';
      // case 'avg':
      //   return 'ค่าเฉลี่ย';
      case 'text does not ends with':
        return 'ไม่ลงท้ายด้วย';
      case 'text does not starts with':
        return 'ไม่เริ่มต้นด้วย';
      case 'last':
        return 'ภายใน_ก่อน';
      case 'not last':
        return 'นอกเหนือจาก_ก่อน';
      case 'this':
        return 'ภายใน';
      case 'today':
        return 'ภายในวันนี้';
      case 'not start with':
        return 'ไม่เริ่มต้นด้วย';
      case 'with in':
        return 'ภายในอีก_';
      case 'day of week':
        return 'ทุกๆ วันที่ระบุ';
      case 'monthly':
        return 'ทุกๆ เดือนที่ระบุ';
      case 'weekly':
        return 'ภายในสัปดาห์นี้';
      case 'past':
        return 'ย้อนหลัง_';
      default:
        return item;
    }
  };

  const mapDescriptionDataUnit = (item: string, id?: number) => {
    const txtOparetor = item.toLowerCase().trim();
    switch (txtOparetor) {
      case 'sunday':
        return 'วันอาทิตย์';
      case 'monday':
        return 'วันจันทร์';
      case 'tuesday':
        return 'วันอังคาร';
      case 'wednesday':
        return 'วันพุธ';
      case 'thursday':
        return 'วันพฤหัสบดี';
      case 'friday':
        return 'วันศุกร์';
      case 'saturday':
        return 'วันเสาร์';
      case 'january':
        return 'มกราคม';
      case 'february':
        return 'กุมภาพันธ์';
      case 'march':
        return 'มีนาคม';
      case 'april':
        return 'เมษายน';
      case 'may':
        return 'พฤษภาคม';
      case 'june':
        return 'มิถุนายน';
      case 'july':
        return 'กรกฏาคม';
      case 'august':
        return 'สิงหาคม';
      case 'september':
        return 'กันยายน';
      case 'october':
        return 'ตุลาคม';
      case 'november':
        return 'พฤศจิกายน';
      case 'december':
        return 'ธันวาคม';
      default:
        return item;
    }
  };
  return {
    convertFunction,
    convertOperator,
    convertUnit,
    convertDataUnit,
  };
}
