import { useLoadingStore } from '@/store/loadingStore';

export default function useValidationModal() {
  const store = useLoadingStore();

  const closeLoading = (callback: Function) => {
    callback();
    return store.CLOSE_LOADING();
  };

  const isLoading = (isOpen = false, title = '') => {
    const payload = {
      isOpen,
      title,
    };
    store.OPEN_LOADING(payload);
  };

  return {
    isLoading,
    closeLoading,
  };
}
