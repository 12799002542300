<template>
  <svg id="Group_116013" data-name="Group 116013" xmlns="http://www.w3.org/2000/svg" width="38.688" height="38.401" viewBox="0 0 38.688 38.401">
    <g id="Group_109600" data-name="Group 109600" transform="translate(0 0)">
      <g id="Group_37426" data-name="Group 37426" transform="translate(0 0)">
        <path
          id="Path_24328"
          data-name="Path 24328"
          d="M10.945,43.295a5.388,5.388,0,1,0,5.388,5.388,5.394,5.394,0,0,0-5.388-5.388m0,8.46a3.072,3.072,0,0,1,0-6.145l0-.155v.155a3.072,3.072,0,0,1,0,6.145"
          transform="translate(3.039 -31.136)"
        />
        <path
          id="Path_24329"
          data-name="Path 24329"
          d="M32.728,47.353a5.96,5.96,0,0,0,4.82-9.463A5.96,5.96,0,0,0,27.3,43.851l-2.846,1.991A13.923,13.923,0,1,0,4.283,65a14.5,14.5,0,0,0,1.36,1.148,13.973,13.973,0,0,0,17.889-1l2.692,2.194a4.557,4.557,0,0,0,2.061,6,4.547,4.547,0,0,0,6.115-2,4.551,4.551,0,0,0-6.624-5.817L25.112,63.35a13.872,13.872,0,0,0,.75-15.58L28.7,45.784a5.932,5.932,0,0,0,4.028,1.569m-3.535-6.51a3.58,3.58,0,0,1,3.53-3.02,3.577,3.577,0,0,1,2.105,6.468,3.543,3.543,0,0,1-2.1.685A3.588,3.588,0,0,1,29.833,43.5a3.548,3.548,0,0,1-.64-2.661m-.787,26.747.116.1a2.411,2.411,0,1,1-.606,1.748,2.418,2.418,0,0,1,.606-1.748ZM20.688,64.48a11.527,11.527,0,0,1-13.4,0l-.142-.1.113-.138a8.687,8.687,0,0,1,12.22-1.245A8.794,8.794,0,0,1,20.72,64.24l.11.136Zm2-1.791-.127.144-.124-.147a11,11,0,0,0-16.9,0l-.124.147-.125-.144a11.613,11.613,0,1,1,17.4,0"
          transform="translate(0 -35.435)"
        />
      </g>
    </g>
  </svg>
</template>
