import { watch, Ref, ref, onMounted, nextTick, toRaw, computed, toRefs, onUpdated, shallowRef } from 'vue';
import * as echarts from 'echarts';
import type { EChartsOption } from 'echarts';

interface Props {
  options: EChartsOption;
  height?: number | string;
  width?: number | string;
  responsiveWidth?: boolean;
  responsiveHeight?: boolean;
  eventType?: string;
  function?: Function;
}

export default function (props: Props) {
  const chart = shallowRef();
  const id = ref();

  const uid = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };

  id.value = uid();

  const resolveHeight = computed(() => {
    return props.responsiveHeight ? '100%' : typeof props.height === 'number' ? `${props.height}px` : props.height;
  });

  const resolveWidth = computed(() => {
    return props.responsiveWidth ? '100%' : typeof props.width === 'number' ? `${props.width}px` : props.width;
  });

  const init = () => {
    const dom = document.getElementById(String(id.value)) as HTMLElement;
    try {
      chart.value = echarts.init(dom);
      chart.value.setOption(toRaw(props.options));
      let initResize = false;

      setTimeout(() => {
        initResize = true;
        chart.value.resize();
      }, 1000);

      new ResizeObserver(() => {
        if (initResize) chart.value.resize();
      }).observe(dom);

      if (props.eventType) {
        chart.value.on(props.eventType, props.function);
      }
    } catch (e) {
      //  TODO: this exception will happen when using with vue <transition>
    }
  };

  onMounted(() => {
    init();
  });

  watch(
    () => props.options,
    () => {
      if (chart.value) chart.value.setOption(toRaw(props.options));
    },
    {
      deep: true,
      immediate: true,
    },
  );

  watch([() => props.height, () => props.width], () => {
    setTimeout(() => {
      chart.value.resize();
    }, 100);
  });

  return { id, resolveHeight, resolveWidth };
}
