import { ref, Ref, getCurrentInstance, nextTick, watch, onBeforeMount } from 'vue';

import { COLOR_MODE, ColorMode } from '@/constants/components/color-picker/color-spectrum';

// Use HSL color system
import { colord, extend } from 'colord';
import hwbPlugin from 'colord/plugins/hwb';

extend([hwbPlugin]);

interface Props {
  currentColor?: string;
  wrapperId: string;
}

export default function useColorSpectrum(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const spectrumCanvas: Ref<HTMLCanvasElement | null> = ref(null);
  const spectrumCtx: Ref<CanvasRenderingContext2D | null> = ref(null);
  const spectrumCursor: Ref<HTMLElement | null> = ref(null);
  // TODO: declare type
  const spectrumRect: Ref<any | null> = ref(null);

  const hueCanvas: Ref<HTMLCanvasElement | null> = ref(null);
  const hueCtx: Ref<CanvasRenderingContext2D | null> = ref(null);
  const hueCursor: Ref<HTMLElement | null> = ref(null);
  // TODO: declare type
  const hueRect: Ref<any | null> = ref(null);

  const defaultColor = '#ff0000' as string;
  const currentColor: Ref<string> = ref(props.currentColor ? colord(props.currentColor).toHslString() : colord(defaultColor).toHslString());
  const styleRangeColor: Ref<string> = ref(props.currentColor ? colord(props.currentColor).toHslString() : colord(defaultColor).toHslString());

  const alpha: Ref<number> = ref(1);
  const hexWithAlpha: Ref<string> = ref(props.currentColor ? props.currentColor : defaultColor);

  // HSL
  const hue: Ref<number> = ref(0);
  const saturation: Ref<number> = ref(100);
  const lightness: Ref<number> = ref(50);

  const black: Ref<number> = ref(0);
  const white: Ref<number> = ref(0);

  const rgbColor: Ref<Array<number>> = ref([0, 0, 0]);
  const hslColor: Ref<Array<number>> = ref([0, 0, 0]);
  const hexColor: Ref<string> = ref('#000000');

  const colorMode: ColorMode[] = COLOR_MODE;
  const currentMode: Ref<ColorMode | null> = ref(null);

  function ColorPicker() {
    createShadeSpectrum();
    createHueSpectrum();
    setDefaultColor();
    setDefaultMode();
  }

  function setDefaultColor() {
    const color = colord(currentColor.value).toHslString();
    colorToPos(color);
    setColorValues(color);
    refreshElementRects();
  }

  function setDefaultMode() {
    currentMode.value = colorMode.find((m) => m.seq === 1) || null;
  }

  function refreshElementRects() {
    if (spectrumCanvas.value && hueCanvas.value) {
      spectrumRect.value = spectrumCanvas.value.getBoundingClientRect();
      hueRect.value = hueCanvas.value.getBoundingClientRect();
    }
  }

  function updateColor() {
    let numAlpha = '';
    numAlpha = Math.round(alpha.value * 255)
      .toString(16)
      .padStart(2, '0');
    if (numAlpha == 'ff') {
      hexWithAlpha.value = hexColor.value.substring(0, 7) + '';
    } else {
      hexWithAlpha.value = hexColor.value.substring(0, 7) + numAlpha.toUpperCase();
    }
    setCurrentColor();
  }

  function createShadeSpectrum(color?: string) {
    if (spectrumCanvas.value && spectrumCtx.value) {
      const canvas: HTMLCanvasElement = spectrumCanvas.value;
      const ctx: CanvasRenderingContext2D = spectrumCtx.value;

      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (!color) {
          color = defaultColor;
        }
        ctx.fillStyle = color;

        ctx.fillRect(0, 0, canvas.width, canvas.height);
        const whiteGradient: CanvasGradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
        whiteGradient.addColorStop(0, '#fff');
        whiteGradient.addColorStop(1, 'transparent');
        ctx.fillStyle = whiteGradient;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const blackGradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
        blackGradient.addColorStop(0, 'transparent');
        blackGradient.addColorStop(1, '#000');
        ctx.fillStyle = blackGradient;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        canvas.addEventListener('mousedown', (event: MouseEvent) => {
          startGetSpectrumColor(event);
        });
      }
    }
  }

  function createHueSpectrum() {
    if (hueCanvas.value && hueCtx.value) {
      const canvas: HTMLCanvasElement = hueCanvas.value;
      const ctx: CanvasRenderingContext2D = hueCtx.value;

      if (ctx) {
        const hueGradient: CanvasGradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
        hueGradient.addColorStop(0.0, 'hsl(0,100%,50%)');
        hueGradient.addColorStop(0.17, 'hsl(298.8,100%,50%)');
        hueGradient.addColorStop(0.33, 'hsl(241.2,100%,50%)');
        hueGradient.addColorStop(0.5, 'hsl(180,100%,50%)');
        hueGradient.addColorStop(0.67, 'hsl(118.8,100%,50%)');
        hueGradient.addColorStop(0.83, 'hsl(61.2,100%,50%)');
        hueGradient.addColorStop(1, 'hsl(360,100%,50%)');

        ctx.fillStyle = hueGradient;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        canvas.addEventListener('mousedown', (event: MouseEvent) => {
          startGetHueColor(event);
        });
      }
    }
  }

  function colorToHue() {
    const hueString: string = colord(`hsl(${hue.value}, 100%, 50%)`).toHslString();
    return hueString;
  }

  function colorToPos(colorInput: string) {
    if (spectrumCanvas.value) {
      const color = colord(colorInput);
      const hsl = color.toHsl();
      hue.value = hsl.h;
      const hsv = color.toHsv();

      const x = spectrumRect.value.width * (hsv.s / 100);
      const y = spectrumRect.value.height * (1 - hsv.v / 100);

      const hueY = hueRect.value.height - (hue.value / 360) * hueRect.value.height;
      updateSpectrumCursor(x, y);
      // HUE workable
      updateHueCursor(hueY);
      setCurrentColor(colorInput);
      createShadeSpectrum(colorToHue());
    }
  }

  function setColorValues(colorInput: string) {
    const color = colord(colorInput);
    const rgbValue = color.toRgb();
    const hexValue = color.toHex();
    const hslValue = color.toHsl();

    //Set Input
    // RGB
    rgbColor.value[0] = rgbValue.r;
    rgbColor.value[1] = rgbValue.g;
    rgbColor.value[2] = rgbValue.b;
    // HEX
    hexColor.value = hexValue;
    // HSL
    hslColor.value[0] = hue.value;
    hslColor.value[1] = hslValue.s;
    hslColor.value[2] = hslValue.l;
  }

  function setCurrentColor(colorInput?: string) {
    if (!colorInput) {
      if (spectrumCursor.value && hueCursor.value) {
        const color = colord(hexWithAlpha.value).toHslString();
        currentColor.value = color;
        spectrumCursor.value.style.backgroundColor = color;
        hueCursor.value.style.backgroundColor = color;
        vm?.$emit('on-change-current-color', hexWithAlpha.value);
      }
    } else {
      if (spectrumCursor.value && hueCursor.value) {
        // const color = colord(colorInput).hue(hue.value).toHslString();
        // currentColor.value = color;
        // spectrumCursor.value.style.backgroundColor = color;
        // hueCursor.value.style.backgroundColor = color;
        // vm?.$emit('on-change-current-color', colord(colorInput).toHex());
      }
    }
  }

  function updateHueCursor(y: number) {
    if (hueCursor.value) {
      hueCursor.value.style.top = y.toString() + 'px';
    }
  }

  function updateSpectrumCursor(x: number, y: number) {
    if (spectrumCursor.value) {
      spectrumCursor.value.style.top = y.toString() + 'px';
      spectrumCursor.value.style.left = x.toString() + 'px';
    }
  }

  function endGetSpectrumColor(event: MouseEvent) {
    if (spectrumCursor.value) {
      spectrumCursor.value.classList.remove('dragging');
      window.removeEventListener('mousemove', getSpectrumColor);
    }
  }

  const startGetSpectrumColor = (event: MouseEvent) => {
    if (spectrumCursor.value) {
      getSpectrumColor(event);
      spectrumCursor.value.classList.add('dragging');
      window.addEventListener('mousemove', getSpectrumColor);
      window.addEventListener('mouseup', endGetSpectrumColor);
    }
  };

  function getSpectrumColor(event: MouseEvent) {
    event.preventDefault();

    //get x, y coordinates
    let x = event.pageX - spectrumRect.value.left;
    let y = event.pageY - spectrumRect.value.top;

    //contain x max
    if (x > spectrumRect.value.width) {
      x = spectrumRect.value.width;
    }
    if (x < 0) {
      x = 0;
    }
    if (y > spectrumRect.value.height) {
      y = spectrumRect.value.height;
    }
    if (y < 0) {
      y = 0;
    }

    //Convert between hsv and hsl
    const xRatio = (x / spectrumRect.value.width) * 100;
    const yRatio = (y / spectrumRect.value.height) * 100;

    const hsvValue = 1 - yRatio / 100;
    const hsvSaturation = xRatio / 100;

    white.value = (1 - hsvSaturation) * hsvValue * 100;
    black.value = (1 - hsvValue) * 100;

    // Change Formula (Before [HSV -> HSL]) to be [HSV -> HWB]
    // HWB -> HUE, White, Black
    const color = colord(`hwb(${hue.value} ${white.value}% ${black.value}%)`).toHwbString();

    saturation.value = colord(color).toHsl().s;
    lightness.value = colord(color).toHsl().l;

    setCurrentColor(color);
    setColorValues(color);
    updateSpectrumCursor(x, y);
  }

  function endGetHueColor(event: MouseEvent) {
    if (hueCursor.value) {
      hueCursor.value.classList.remove('dragging');
      window.removeEventListener('mousemove', getHueColor);
    }
  }

  function startGetHueColor(event: MouseEvent) {
    if (hueCursor.value) {
      getHueColor(event);
      hueCursor.value.classList.add('dragging');
      window.addEventListener('mousemove', getHueColor);
      window.addEventListener('mouseup', endGetHueColor);
    }
  }

  function getHueColor(event: MouseEvent) {
    event.preventDefault();
    let y = event.pageY - hueRect.value.top;
    if (y > hueRect.value.height) {
      y = hueRect.value.height;
    }
    if (y < 0) {
      y = 0;
    }

    const percent: number = y / hueRect.value.height;
    hue.value = Math.round(360 - 360 * percent);
    const color = colord(`hsl(${hue.value}, ${saturation.value}%, ${lightness.value}%)`).toHslString();

    createShadeSpectrum(colorToHue());
    updateHueCursor(y);
    setCurrentColor(color);
    setColorValues(color);
  }

  const modeToggleHandler = () => {
    if (currentMode.value) {
      const mode = currentMode.value;
      if (mode.seq + 1 > colorMode.length) {
        currentMode.value = colorMode.find((m) => m.seq === 1) || null;
      } else {
        currentMode.value = colorMode.find((m) => m.seq === mode.seq + 1) || null;
      }
    }
  };

  const onChangeColorInputHandler = (mode: string) => {
    let color = '';
    switch (mode) {
      case 'rgb':
        color = colord(`rgb(${rgbColor.value[0]},${rgbColor.value[1]},${rgbColor.value[2]})`).toHslString();
        colorToPos(color);
        setColorValues(color);
        break;

      case 'hex':
        hexColor.value = hexColor.value.search('#') === -1 ? `#${hexColor.value}` : hexColor.value;
        color = colord(`${hexColor.value}`).toHslString();
        colorToPos(color);
        setColorValues(color);
        break;

      case 'hsl':
        color = colord(`hsl(${hslColor.value[0]},${hslColor.value[1]}%,${hslColor.value[2]}%)`).toHslString();
        colorToPos(color);
        setColorValues(color);
        hue.value = hslColor.value[0];
        break;
    }
  };
  watch(
    () => alpha.value,
    () => {
      updateColor();
    },
  );
  watch(
    () => hexColor.value,
    () => {
      updateColor();
    },
  );
  nextTick(() => {
    // SPECTRUM CANVAS
    spectrumCanvas.value = document.querySelector(`#spectrum-canvas-${props.wrapperId}`) as HTMLCanvasElement;
    spectrumCtx.value = spectrumCanvas.value.getContext('2d') as CanvasRenderingContext2D;
    spectrumCursor.value = document.querySelector(`#spectrum-cursor-${props.wrapperId}`) as HTMLElement;
    // TODO: declare type
    spectrumRect.value = spectrumCanvas.value.getBoundingClientRect();

    // HUE CANVAS
    hueCanvas.value = document.querySelector(`#hue-canvas-${props.wrapperId}`) as HTMLCanvasElement;
    hueCtx.value = hueCanvas.value.getContext('2d') as CanvasRenderingContext2D;
    hueCursor.value = document.querySelector(`#hue-cursor-${props.wrapperId}`) as HTMLElement;
    // TODO: declare type
    hueRect.value = hueCanvas.value.getBoundingClientRect();

    ColorPicker();

    window.addEventListener('resize', () => {
      refreshElementRects();
    });
  });

  onBeforeMount(() => {
    let defaultTransparent = '';
    let numDefault = 1;
    defaultTransparent = hexWithAlpha.value.substring(7, 9);
    styleRangeColor.value = hexWithAlpha.value.substring(0, 7);
    if (defaultTransparent) {
      numDefault = parseInt(parseInt(defaultTransparent, 16).toString(10));
      alpha.value = numDefault / 255;
    } else {
      alpha.value = 1;
    }
  });

  return {
    rgbColor,
    hexColor,
    hslColor,
    styleRangeColor,
    currentMode,
    onChangeColorInputHandler,
    modeToggleHandler,
    startGetSpectrumColor,
    endGetSpectrumColor,
    startGetHueColor,
    endGetHueColor,
    alpha,
    hexWithAlpha,
  };
}
