<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="256.283"
    height="174.278"
    viewBox="0 0 256.283 174.278"
  >
    <g id="Group_1339" data-name="Group 1339" transform="translate(0 25)">
      <g id="Group_1338" data-name="Group 1338" transform="translate(0 -25)">
        <path
          id="Path_511"
          data-name="Path 511"
          d="M235.13,113.843c0,9.406-50.631,17.03-113.09,17.03s-113.09-7.624-113.09-17.03,50.633-17.03,113.09-17.03,113.09,7.624,113.09,17.03"
          transform="translate(4.013 43.406)"
          fill="#e2e2e2"
        />
        <path
          id="Path_512"
          data-name="Path 512"
          d="M200.183,133.109H28.214v-92.8a8.392,8.392,0,0,1,8.392-8.392H191.792a8.392,8.392,0,0,1,8.392,8.392Z"
          transform="translate(12.65 14.309)"
          fill="#fff"
        />
        <path
          id="Path_513"
          data-name="Path 513"
          d="M131.67,100.466h0a1.813,1.813,0,0,1-1.813,1.813H106.312a1.813,1.813,0,0,1-1.813-1.813,1.813,1.813,0,0,0-1.813-1.813H21.358a1.813,1.813,0,0,0-1.813,1.813V101a11.609,11.609,0,0,0,11.608,11.608H205.016A11.609,11.609,0,0,0,216.624,101v-.533a1.813,1.813,0,0,0-1.813-1.813H133.483a1.813,1.813,0,0,0-1.813,1.813"
          transform="translate(8.763 44.231)"
          fill="#f4f4f4"
        />
        <line
          id="Line_28"
          data-name="Line 28"
          y2="46.835"
          transform="translate(212.833 96.048)"
          fill="none"
          stroke="#dadada"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_514"
          data-name="Path 514"
          d="M77.662,31.916h90.556a9.8,9.8,0,0,1,9.8,9.794"
          transform="translate(34.819 14.309)"
          fill="none"
          stroke="#dadada"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_515"
          data-name="Path 515"
          d="M28.214,128.574V41.711a9.794,9.794,0,0,1,9.794-9.794H50.889"
          transform="translate(12.65 14.31)"
          fill="none"
          stroke="#dadada"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_516"
          data-name="Path 516"
          d="M170.786,98.653h-37.3a1.814,1.814,0,0,0-1.815,1.813,1.814,1.814,0,0,1-1.813,1.815H106.314a1.815,1.815,0,0,1-1.815-1.815,1.813,1.813,0,0,0-1.813-1.813H21.36a1.814,1.814,0,0,0-1.815,1.813V101a11.609,11.609,0,0,0,11.608,11.608H89.52"
          transform="translate(8.763 44.231)"
          fill="none"
          stroke="#dadada"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_517"
          data-name="Path 517"
          d="M82.689,112.608h94.018A11.607,11.607,0,0,0,188.314,101v-.533a1.813,1.813,0,0,0-1.813-1.813H157.667"
          transform="translate(37.073 44.231)"
          fill="none"
          stroke="#dadada"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_518"
          data-name="Path 518"
          d="M24.622,18.92S42,40.645,65.176,39.2L40.554,56.577S20.277,60.922,0,31.955A62.129,62.129,0,0,0,24.622,18.92M37.563,55.36h0a17.176,17.176,0,0,0,2.423-.155L60.616,40.642C42.093,39.5,28.18,25.19,24.458,20.964A64.472,64.472,0,0,1,2.394,32.81C17.292,53.118,31.878,55.36,37.563,55.36"
          transform="translate(0 8.483)"
          fill="#dadada"
        />
        <path
          id="Path_519"
          data-name="Path 519"
          d="M36.822,54.728a17.175,17.175,0,0,0,2.423-.155L59.875,40.01C41.353,38.871,27.438,24.557,23.716,20.331A64.472,64.472,0,0,1,1.652,32.177c14.9,20.309,29.484,22.551,35.169,22.551Z"
          transform="translate(0.741 9.115)"
          fill="#fff"
        />
        <path
          id="Path_520"
          data-name="Path 520"
          d="M16.134,121.952c3.8-6.9,13.917-23.042,29.378-31.445-1.919-3.082-5.818-11.1-5.255-24.709-6.239,2.94-24.586,12.895-31.645,31.081Z"
          transform="translate(3.862 29.5)"
          fill="#fff"
        />
        <path
          id="Path_521"
          data-name="Path 521"
          d="M42.317,64.22c-1.448,18.828,5.793,27.519,5.793,27.519C27.834,101.877,16.247,126.5,16.247,126.5L7.557,97.532c8.69-23.174,34.76-33.312,34.76-33.312m-25.71,58.439c3.8-6.9,13.917-23.042,29.378-31.445-1.919-3.082-5.818-11.1-5.255-24.709C34.492,69.446,16.146,79.4,9.086,97.587Z"
          transform="translate(3.388 28.793)"
          fill="#dadada"
        />
        <path
          id="Path_522"
          data-name="Path 522"
          d="M151.538,54.869S170.974,67.028,195.3,65.911c0,0-6.4,9.721-11.314,12.267s-12.16,6.891-26.644,3.995-23.52-9.31-23.52-9.31,9.036-3.725,17.721-17.994"
          transform="translate(59.997 24.6)"
          fill="#fff"
        />
        <path
          id="Path_523"
          data-name="Path 523"
          d="M132.949,72.89s10.138-2.9,18.829-18.828c0,0,15.932,11.587,44.9,11.587a35.41,35.41,0,0,1-31.864,18.828,75.442,75.442,0,0,1-8.34-1.169h-.051l.042,0c-7.227-1.393-17.445-4.346-23.515-10.416m61.287-5.821c-23.252-.513-37.641-8.254-41.956-10.941-6.3,10.928-13.189,15.487-16.666,17.215,5.292,4.318,12.934,6.759,19.086,8.112,6.167.747,14.847-4.549,16.8-6.52l.559-.361.555.369c2.048,1.364,5.252,1.7,7.883,1.7a31.6,31.6,0,0,0,5.872-.817,34.477,34.477,0,0,0,7.863-8.755M164.865,83.029a34.614,34.614,0,0,0,17.315-4.4c-.5.023-1.046.039-1.621.039-2.71,0-6.051-.335-8.493-1.692a57.162,57.162,0,0,1-11.442,5.556c2.361.343,3.954.472,4.241.494"
          transform="translate(59.607 24.238)"
          fill="#dedede"
        />
        <path
          id="Path_524"
          data-name="Path 524"
          d="M149.141,74.258c-.054,0-.025,0,.033,0h-.033"
          transform="translate(66.854 33.293)"
          fill="#dedede"
        />
        <rect
          id="Rectangle_668"
          data-name="Rectangle 668"
          width="4.093"
          height="15.308"
          transform="translate(235.649 49.045)"
          fill="#cbcbcb"
        />
        <rect
          id="Rectangle_669"
          data-name="Rectangle 669"
          width="15.308"
          height="4.093"
          transform="translate(230.041 54.652)"
          fill="#cbcbcb"
        />
        <rect
          id="Rectangle_670"
          data-name="Rectangle 670"
          width="4.093"
          height="15.308"
          transform="translate(120.059 9.025)"
          fill="#cbcbcb"
        />
        <rect
          id="Rectangle_671"
          data-name="Rectangle 671"
          width="15.308"
          height="4.093"
          transform="translate(114.451 14.631)"
          fill="#cbcbcb"
        />
        <rect
          id="Rectangle_672"
          data-name="Rectangle 672"
          width="15.307"
          height="4.094"
          transform="translate(204.561 12.793) rotate(-45)"
          fill="#cbcbcb"
        />
        <rect
          id="Rectangle_673"
          data-name="Rectangle 673"
          width="4.094"
          height="15.307"
          transform="translate(204.561 4.864) rotate(-45)"
          fill="#cbcbcb"
        />
        <rect
          id="Rectangle_674"
          data-name="Rectangle 674"
          width="15.307"
          height="4.094"
          transform="translate(30.557 10.824) rotate(-45)"
          fill="#cbcbcb"
        />
        <rect
          id="Rectangle_675"
          data-name="Rectangle 675"
          width="4.094"
          height="15.307"
          transform="translate(30.557 2.895) rotate(-45)"
          fill="#cbcbcb"
        />
        <path
          id="Path_525"
          data-name="Path 525"
          d="M58.448,15.088a3.325,3.325,0,1,1-3.325-3.325,3.325,3.325,0,0,1,3.325,3.325"
          transform="translate(23.223 5.274)"
          fill="#cbcbcb"
        />
        <path
          id="Path_526"
          data-name="Path 526"
          d="M112.918,5.088a3.325,3.325,0,1,1-3.325-3.325,3.325,3.325,0,0,1,3.325,3.325"
          transform="translate(47.644 0.79)"
          fill="#cbcbcb"
        />
        <path
          id="Path_527"
          data-name="Path 527"
          d="M178.05,21.842a3.325,3.325,0,1,1-3.325-3.325,3.326,3.326,0,0,1,3.325,3.325"
          transform="translate(76.846 8.302)"
          fill="#cbcbcb"
        />
        <path
          id="Path_528"
          data-name="Path 528"
          d="M12.162,58.607a3.325,3.325,0,1,1-3.325-3.325,3.325,3.325,0,0,1,3.325,3.325"
          transform="translate(2.471 24.785)"
          fill="#cbcbcb"
        />
        <path
          id="Path_529"
          data-name="Path 529"
          d="M67.05,88.158a36.822,36.822,0,0,1,72.94,0h19.292a55.993,55.993,0,0,0-111.523,0Z"
          transform="translate(21.413 16.703)"
          fill="#ebeaea"
        />
        <path
          id="Path_530"
          data-name="Path 530"
          d="M125.31,53.362h0a5.979,5.979,0,0,0-2.128-9.327A52.4,52.4,0,0,0,50.25,87.046H62.31a40.393,40.393,0,0,1,56.135-32,5.924,5.924,0,0,0,6.865-1.68"
          transform="translate(22.529 17.815)"
          fill="#cbcbcb"
        />
        <rect
          id="Rectangle_676"
          data-name="Rectangle 676"
          width="45.251"
          height="6.962"
          transform="translate(85.429 112.057)"
          fill="#cbcbcb"
        />
        <rect
          id="Rectangle_677"
          data-name="Rectangle 677"
          width="45.251"
          height="6.962"
          transform="translate(135.443 112.057)"
          fill="#cbcbcb"
        />
        <rect
          id="Rectangle_678"
          data-name="Rectangle 678"
          width="8.335"
          height="6.962"
          transform="translate(71.14 112.057)"
          fill="#cbcbcb"
        />
        <rect
          id="Rectangle_679"
          data-name="Rectangle 679"
          width="45.251"
          height="6.962"
          transform="translate(85.429 123.852)"
          fill="#e5e5e4"
        />
        <rect
          id="Rectangle_680"
          data-name="Rectangle 680"
          width="45.251"
          height="6.962"
          transform="translate(135.443 123.852)"
          fill="#e5e5e4"
        />
        <rect
          id="Rectangle_681"
          data-name="Rectangle 681"
          width="8.335"
          height="6.962"
          transform="translate(71.14 123.852)"
          fill="#e5e5e4"
        />
        <path
          id="Path_531"
          data-name="Path 531"
          d="M88.726,61.033a3.667,3.667,0,0,1-.3,1.486,3.805,3.805,0,0,1-2.042,2.032,3.832,3.832,0,0,1-2.992,0,3.805,3.805,0,0,1-2.042-2.032,3.69,3.69,0,0,1-.3-1.486,3.745,3.745,0,0,1,.3-1.5,3.912,3.912,0,0,1,.818-1.215,3.741,3.741,0,0,1,1.224-.808,3.972,3.972,0,0,1,2.992,0,3.726,3.726,0,0,1,1.224.808,3.887,3.887,0,0,1,.818,1.215,3.721,3.721,0,0,1,.3,1.5m-2.265,0a1.577,1.577,0,0,0-3.154,0,1.486,1.486,0,0,0,.465,1.1,1.588,1.588,0,0,0,2.225,0,1.486,1.486,0,0,0,.465-1.1m-.02,11.425-1.8-.891,8.35-14.759,1.88.91Zm11.991-4.267a3.667,3.667,0,0,1-.3,1.486,3.818,3.818,0,0,1-2.042,2.032,3.849,3.849,0,0,1-2.992,0,3.818,3.818,0,0,1-2.042-2.032,3.8,3.8,0,0,1,0-2.972,3.818,3.818,0,0,1,2.042-2.032,3.849,3.849,0,0,1,2.992,0A3.818,3.818,0,0,1,98.128,66.7a3.676,3.676,0,0,1,.3,1.486m-2.265,0a1.481,1.481,0,0,0-.132-.617,1.677,1.677,0,0,0-.343-.505,1.546,1.546,0,0,0-.5-.333,1.583,1.583,0,0,0-2.184,1.456,1.486,1.486,0,0,0,.465,1.1,1.537,1.537,0,0,0,1.112.455,1.519,1.519,0,0,0,.607-.122,1.57,1.57,0,0,0,.5-.333,1.7,1.7,0,0,0,.343-.5,1.412,1.412,0,0,0,.132-.607"
          transform="translate(36.335 25.47)"
          fill="#ebeaea"
        />
      </g>
    </g>
  </svg>
</template>
