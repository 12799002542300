<template>
  <svg
    id="Group_31469"
    data-name="Group 31469"
    xmlns="http://www.w3.org/2000/svg"
    width="21.849"
    height="20.282"
    viewBox="0 0 21.849 20.282"
  >
    <path
      id="Subtraction_249"
      data-name="Subtraction 249"
      d="M7.955,15.925a7.963,7.963,0,1,1,6.013-2.739A7.955,7.955,0,0,1,7.955,15.925ZM7.961,2.654a5.308,5.308,0,1,0,3.48,1.3A5.31,5.31,0,0,0,7.961,2.654Z"
      fill="#606a6c"
    />
    <rect
      id="Rectangle_395"
      data-name="Rectangle 395"
      width="2.654"
      height="10.616"
      rx="1"
      transform="translate(12.096 13.317) rotate(-49)"
      fill="#606a6c"
    />
  </svg>
</template>
