import { ref, Ref, getCurrentInstance, onBeforeMount } from 'vue';

import mockProfile from './mock/profile';
import mockActivity from './mock/activity';
import mockSegment from './mock/segment';
import mockSpendingOverview from './mock/spending-overview';
import mockAverageSpending from './mock/average-spending';
import mockTotalSpending from './mock/total-spending';
import mockEngagementScore from './mock/engagement-score';
import mockTopCategory from './mock/top-category';
import mockTopFiveProduct from './mock/top-five-product';

import averageSpendingChartOptionMapper from './chart-options-mapper/averageSpending';
import totalSpendingChartOptionMapper from './chart-options-mapper/totalSpending';
import engagementScoreMapper from './chart-options-mapper/engagementScore';
import topCategoryMapper from './chart-options-mapper/topCategory';

interface Loading {
  profile: boolean;
  activity: boolean;
  segment: boolean;
  spendingOverview: boolean;
  averageSpending: boolean;
  customerTotalSpending: boolean;
  engagementScore: boolean;
  topCategory: boolean;
  topFiveProduct: boolean;
}

export default function () {
  const vm = getCurrentInstance()?.proxy;

  const loading: Ref<Loading> = ref({
    profile: false,
    activity: false,
    segment: false,
    spendingOverview: false,
    averageSpending: false,
    customerTotalSpending: false,
    engagementScore: false,
    topCategory: false,
    topFiveProduct: false,
  });

  const customerInsightsData: Ref<CustomerInsights.Data> = ref({
    profile: {
      image: '',
      tierId: 0,
      tierLabel: '',
      averageLifetimeValue: 0,
      point: 0,
      firstname: '',
      lastname: '',
      mobileNo: '',
      birthdate: '',
      genderLabel: '',
      email: '',
      isPDPAConcent: false,
      occupation: '',
      address: '',
      lastVisit: '',
    },
    activity: {
      summaryCount: {
        sms: {
          amount: 0,
          notify: 0,
        },
        line: {
          amount: 0,
          notify: 0,
        },
        email: {
          amount: 0,
          notify: 0,
        },
      },
      timeline: {
        filters: {
          date: '',
        },
        items: [],
      },
    },
    segment: [],
    spendingOverview: {
      total: 0,
      average: 0,
      max: 0,
      customerLifetimeValue: 0,
      CLTV: 0,
    },
    averageSpending: {
      filters: {
        startDate: new Date('2022-08-16T17:00:00.000Z'),
        endDate: new Date('2022-08-17T17:00:00.000Z'),
      },
      chart: {
        rawData: {
          category: [],
          data: [],
        },
        options: {},
      },
    },
    totalSpending: {
      filters: {
        startDate: new Date('2022-08-16T17:00:00.000Z'),
        endDate: new Date('2022-08-17T17:00:00.000Z'),
      },
      chart: {
        rawData: {
          category: [],
          data: [],
        },
        options: {},
      },
    },
    engagementScore: {
      filters: {
        startDate: new Date('2022-08-16T17:00:00.000Z'),
        endDate: new Date('2022-08-17T17:00:00.000Z'),
      },
      chart: {
        rawData: {
          value: 0,
        },
        options: {},
      },
    },
    topCategory: {
      filters: {
        startDate: new Date('2022-08-16T17:00:00.000Z'),
        endDate: new Date('2022-08-17T17:00:00.000Z'),
      },
      chart: {
        rawData: {
          category: [],
          data: [],
        },
        options: {},
      },
    },
    topFiveProduct: {
      filters: {
        startDate: new Date('2022-08-16T17:00:00.000Z'),
        endDate: new Date('2022-08-17T17:00:00.000Z'),
      },
      items: [],
    },
  });

  function mapProfile(input: CustomerInsights.Response.Profile): CustomerInsights.Profile {
    return input as CustomerInsights.Profile;
  }

  function mapActivity(input: CustomerInsights.Response.Activity): CustomerInsights.Activity {
    return input as CustomerInsights.Activity;
  }

  function mapSegment(input: CustomerInsights.Response.Segment): CustomerInsights.Segment {
    return input as CustomerInsights.Segment;
  }

  function mapSpendingOverview(input: CustomerInsights.Response.SpendingOverview): CustomerInsights.SpendingOverview {
    return input as CustomerInsights.SpendingOverview;
  }

  function mapAverageSpendingChart(input: CustomerInsights.Response.AverageSpending): CustomerInsights.AverageSpendingChart {
    return <CustomerInsights.AverageSpendingChart>{
      rawData: input.rawData,
      options: averageSpendingChartOptionMapper(input.rawData),
    };
  }

  function mapTotalSpending(input: CustomerInsights.Response.TotalSpending): CustomerInsights.TotalSpendingChart {
    return <CustomerInsights.TotalSpendingChart>{
      rawData: input.rawData,
      options: totalSpendingChartOptionMapper(input.rawData),
    };
  }

  function mapEngagementScore(input: CustomerInsights.Response.EngagementScore): CustomerInsights.EngagementScoreChart {
    return <CustomerInsights.EngagementScoreChart>{
      rawData: input.rawData,
      options: engagementScoreMapper(input.rawData),
    };
  }

  function mapTopCategory(input: CustomerInsights.Response.TopCategory): CustomerInsights.TopCategoryChart {
    return <CustomerInsights.TopCategoryChart>{
      rawData: input.rawData,
      options: topCategoryMapper(input.rawData),
    };
  }

  function mapTopFiveProduct(input: CustomerInsights.Response.TopFiveProduct): CustomerInsights.TopFiveProductItem[] {
    return <CustomerInsights.TopFiveProductItem[]>input.items;
  }

  function fetchProfile(): void {
    loading.value.profile = true;
    setTimeout(() => {
      loading.value.profile = false;
    }, 1000);
    customerInsightsData.value.profile = mapProfile(mockProfile as CustomerInsights.Response.Profile);
  }

  function fetchActivity(): void {
    loading.value.activity = true;
    setTimeout(() => {
      loading.value.activity = false;
    }, 1200);
    customerInsightsData.value.activity = mapActivity(mockActivity as CustomerInsights.Response.Activity);
  }

  function fetchSegment(): void {
    loading.value.segment = true;
    setTimeout(() => {
      loading.value.segment = false;
    }, 1400);
    customerInsightsData.value.segment = mapSegment(mockSegment as CustomerInsights.Response.Segment);
  }

  function fetchSpendingOverview(): void {
    loading.value.spendingOverview = true;
    setTimeout(() => {
      loading.value.spendingOverview = false;
    }, 1600);
    customerInsightsData.value.spendingOverview = mapSpendingOverview(mockSpendingOverview as CustomerInsights.Response.SpendingOverview);
  }

  function fetchAverageSpending(): void {
    loading.value.averageSpending = true;
    setTimeout(() => {
      loading.value.averageSpending = false;
    }, 1800);
    customerInsightsData.value.averageSpending.chart = mapAverageSpendingChart(mockAverageSpending as CustomerInsights.Response.AverageSpending);
  }

  function fetchTotalSpending(): void {
    loading.value.customerTotalSpending = true;
    setTimeout(() => {
      loading.value.customerTotalSpending = false;
    }, 2000);
    customerInsightsData.value.totalSpending.chart = mapTotalSpending(mockTotalSpending as CustomerInsights.Response.TotalSpending);
  }

  function fetchEngagementScore(): void {
    loading.value.engagementScore = true;
    setTimeout(() => {
      loading.value.engagementScore = false;
    }, 2200);
    customerInsightsData.value.engagementScore.chart = mapEngagementScore(mockEngagementScore as CustomerInsights.Response.EngagementScore);
  }

  function fetchTopCategory(): void {
    loading.value.topCategory = true;
    setTimeout(() => {
      loading.value.topCategory = false;
    }, 2400);
    customerInsightsData.value.topCategory.chart = mapTopCategory(mockTopCategory as CustomerInsights.Response.TopCategory);
  }

  function fetchTopFiveProduct(): void {
    loading.value.topFiveProduct = true;
    setTimeout(() => {
      loading.value.topFiveProduct = false;
    }, 2600);
    customerInsightsData.value.topFiveProduct.items = mapTopFiveProduct(mockTopFiveProduct as CustomerInsights.Response.TopFiveProduct);
  }

  function onUpdateAverageSpendingFilter() {
    fetchAverageSpending();
  }

  function onUpdateTotalSpendingFilter() {
    fetchTotalSpending();
  }

  function onUpdateEngagementScoreFilter() {
    fetchEngagementScore();
  }

  function onUpdateTopCategoryFilter() {
    fetchTopCategory();
  }

  function onUpdateTopFiveProductFilter() {
    fetchTopFiveProduct();
  }

  const onCloseModal = () => {
    vm?.$emit('close-modal');
  };

  onBeforeMount(() => {
    fetchProfile();
    fetchActivity();
    fetchSegment();
    fetchSpendingOverview();
    fetchAverageSpending();
    fetchTotalSpending();
    fetchEngagementScore();
    fetchTopCategory();
    fetchTopFiveProduct();
  });

  return {
    loading,
    customerInsightsData,
    onCloseModal,
    onUpdateAverageSpendingFilter,
    onUpdateTotalSpendingFilter,
    onUpdateEngagementScoreFilter,
    onUpdateTopCategoryFilter,
    onUpdateTopFiveProductFilter,
  };
}
