import { defineComponent, h, mergeProps, onBeforeMount, onBeforeUnmount, Prop, ref, unref } from 'vue';

export default defineComponent({
  name: 'ChocoClickObserver',
  props: {
    as: <Prop<any>>{},
  },
  emits: ['clickInside', 'clickOutside'],
  setup(props, { emit, attrs, slots }) {
    const elRoot = ref<HTMLDivElement>();

    // to prevent immediate event trigger on mounted (because click from parent)
    // - because vue will mount component before trigger event, so this will immediately trigger after element is mounted
    let immediateFlag = false;
    const globalPickerHandler = (e: MouseEvent) => {
      if (!immediateFlag) {
        immediateFlag = true;
        return;
      }

      // observer root element
      const el = unref(elRoot);
      // event target paths (from target element to root)
      const composedPath = e.composedPath();

      // click inside-outside
      if (!el || e.target === el || composedPath.includes(el)) {
        emit('clickInside', e);
      } else {
        emit('clickOutside', e);
      }
    };

    onBeforeMount(() => {
      window.addEventListener('click', globalPickerHandler);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('click', globalPickerHandler);
    });

    return () => h(props.as || 'div', mergeProps({ ref: elRoot }, attrs), slots);
  },
});
