export const CHARTS_KEY = {
  TOTAL_STATUS: 'Comm_006_OverviewTotalStatus',
  DELIVERY_RANGE: 'Comm_007_HeatmapCommuCampaignDeliveryRange',
  DELIVERY: 'Comm_008_PieDelivery',
  DELIVERY_BY_CAMPAIGN: 'Comm_009_VerticalStackBarDeliveryByCampaign',
  PDPA_CONSENT: 'Comm_001_PiePdpaConsent',
  SMS_NEW_SUBSCRIBE: 'Comm_010_LineSmsNewSubscribe',
  SMS_Subscribe: 'Comm_011_PieSmsSubscribe',
  SMS_Credit: 'Comm_012_VerticalOverviewSmsCredit',
  Line_New_Subscriber: 'Comm_013_LineLineNewSubscribe',
  Line_Subscribe: 'Comm_014_PieLineSubscribe',
  EMAIL_NEW_SUBSCRIBE: 'Comm_003_LineEmailNewSubscribe',
  EMAIL_SUBSCRIBE: 'Comm_004_PieEmailSubscribe',
  EMAIL_CREDIT: 'Comm_005_VerticalOverviewEmailCredit',
};

export const CHARTS_TYPE = {
  OVERVIEW: 'overview',
  HEATMAP: 'heatmap-commu',
  PIE: 'pie',
  VERTICAL_STACK_BAR: 'vertical-stack-bar',
  LINE: 'line',
  VERTICAL_OVERVIEW: 'vertical-overview',
};

export const INDEX_SUB_DATE_PICKER = {
  DELIVERY_RANGE: 0,
  EMAIL_NEW_SUBSCRIBE: 1,
  SMS_NEW_SUBSCRIBE: 2,
  Line_New_Subscriber: 3,
};
