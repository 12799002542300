<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="202.821" height="100.075" viewBox="0 0 202.821 100.075">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_19779" data-name="Rectangle 19779" width="64.106" height="92.386" fill="none" />
      </clipPath>
    </defs>
    <g id="Group_109860" data-name="Group 109860" transform="translate(15883.689 13223.237)">
      <g id="Group_90972" data-name="Group 90972" transform="translate(-15764.235 -13184.843)">
        <g id="Group_37450" data-name="Group 37450" transform="translate(0 0)">
          <g id="Group_37336" data-name="Group 37336">
            <path
              id="Path_24208"
              data-name="Path 24208"
              d="M610.028,33.677h-12.14a3.536,3.536,0,0,1,0-7.072h3.693a3.536,3.536,0,0,0,0-7.072H568.107a3.536,3.536,0,0,0,0,7.072h15.755a3.536,3.536,0,0,1,0,7.072h1.965a3.536,3.536,0,0,0,0,7.072h24.2a3.536,3.536,0,0,0,0-7.072"
              transform="translate(-557.965 -19.534)"
              fill="#ededed"
            />
            <path
              id="Path_24352"
              data-name="Path 24352"
              d="M406.513,54.779a3.2,3.2,0,1,0-3.2,3.2h0a3.2,3.2,0,0,0,3.2-3.2"
              transform="translate(-347.716 -51.369)"
              fill="#ededed"
            />
            <path
              id="Path_24217"
              data-name="Path 24217"
              d="M603.9,99.587a6.608,6.608,0,1,1-6.608-6.608,6.608,6.608,0,0,1,6.608,6.608"
              transform="translate(-590.683 -78.371)"
              fill="#ededed"
            />
          </g>
        </g>
      </g>
      <g id="Group_90973" data-name="Group 90973" transform="translate(-15854.628 -13181.539)">
        <path
          id="Path_30237"
          data-name="Path 30237"
          d="M384.265,140.864h15.761a3.536,3.536,0,0,1,0,7.072h-1.331a3.536,3.536,0,1,0,0,7.072h33.474a3.536,3.536,0,0,0,0-7.072H415.9a3.536,3.536,0,1,1,0-7.072h5.031a3.536,3.536,0,0,0,0-7.072H384.267a3.536,3.536,0,0,0,0,7.072"
          transform="translate(-380.732 -133.792)"
          fill="#ededed"
        />
        <path
          id="Path_30238"
          data-name="Path 30238"
          d="M400.115,55.062a3.483,3.483,0,1,0,3.484-3.483h0a3.483,3.483,0,0,0-3.483,3.483"
          transform="translate(-340.156 -37.33)"
          fill="#ededed"
        />
      </g>
      <g id="Group_90974" data-name="Group 90974" transform="translate(-15861.222 -13149.999)">
        <path
          id="Path_30237-2"
          data-name="Path 30237"
          d="M384.265,140.864h15.761a3.536,3.536,0,0,1,0,7.072h-1.331a3.536,3.536,0,1,0,0,7.072h33.474a3.536,3.536,0,0,0,0-7.072H415.9a3.536,3.536,0,1,1,0-7.072h5.031a3.536,3.536,0,0,0,0-7.072H384.267a3.536,3.536,0,0,0,0,7.072"
          transform="translate(-380.732 -133.792)"
          fill="#ededed"
        />
        <path
          id="Path_30238-2"
          data-name="Path 30238"
          d="M400.115,55.062a3.483,3.483,0,1,0,3.484-3.483h0a3.483,3.483,0,0,0-3.483,3.483"
          transform="translate(-340.156 -37.33)"
          fill="#ededed"
        />
      </g>
      <g id="Group_90975" data-name="Group 90975" transform="translate(-15770.263 -13149.999)">
        <path
          id="Path_30237-3"
          data-name="Path 30237"
          d="M384.265,140.864h15.761a3.536,3.536,0,0,1,0,7.072h-1.331a3.536,3.536,0,1,0,0,7.072h33.474a3.536,3.536,0,0,0,0-7.072H415.9a3.536,3.536,0,1,1,0-7.072h5.031a3.536,3.536,0,0,0,0-7.072H384.267a3.536,3.536,0,0,0,0,7.072"
          transform="translate(-380.732 -133.792)"
          fill="#ededed"
        />
        <path
          id="Path_30238-3"
          data-name="Path 30238"
          d="M400.115,55.062a3.483,3.483,0,1,0,3.484-3.483h0a3.483,3.483,0,0,0-3.483,3.483"
          transform="translate(-340.156 -37.33)"
          fill="#ededed"
        />
      </g>
      <line
        id="Line_1219"
        data-name="Line 1219"
        x2="199.821"
        transform="translate(-15882.189 -13125.269)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="3"
      />
      <g id="Group_90976" data-name="Group 90976" transform="translate(-15808.449 -13216.362)">
        <g id="Mask_Group_50423" data-name="Mask Group 50423" transform="translate(0 0)" clip-path="url(#clip-path)">
          <path
            id="Path_30239"
            data-name="Path 30239"
            d="M30.684,1.053C30.328,1.036,29.978,1,29.619,1A21.556,21.556,0,0,0,8.062,22.557v16.1H19.135v-16.1A10.5,10.5,0,0,1,29.617,12.073a10.67,10.67,0,0,1,1.066.053A10.5,10.5,0,0,1,40.1,22.557v16.1H51.175v-16.1a21.538,21.538,0,0,0-20.491-21.5"
            transform="translate(2.435 0.303)"
            fill="#fff"
          />
          <g id="Group_90960" data-name="Group 90960" transform="translate(9.196)">
            <path
              id="Path_30240"
              data-name="Path 30240"
              d="M42.536,37.928H40.4a1.3,1.3,0,0,1-1.3-1.3v-16.1a9.182,9.182,0,0,0-18.364,0v16.1a1.3,1.3,0,0,1-1.3,1.3H8.364a1.3,1.3,0,0,1-1.3-1.3v-16.1A22.806,22.806,0,0,1,10.574,8.342a1.3,1.3,0,0,1,2.2,1.389A20.2,20.2,0,0,0,9.666,20.522v14.8h8.468v-14.8A11.8,11.8,0,0,1,29.921,8.736c.4,0,.8.021,1.2.06A11.749,11.749,0,0,1,41.707,20.522v14.8h.829a1.3,1.3,0,0,1,0,2.6"
              transform="translate(-7.062 2.337)"
            />
            <path
              id="Path_30241"
              data-name="Path 30241"
              d="M50.012,24.161a1.3,1.3,0,0,1-1.3-1.3,20.216,20.216,0,0,0-19.253-20.2l-.383-.023c-.2-.014-.41-.029-.62-.029A20.125,20.125,0,0,0,14.133,8.536,1.3,1.3,0,0,1,12.292,6.7,22.709,22.709,0,0,1,28.455,0c.268,0,.534.017.8.035l.335.021a22.817,22.817,0,0,1,21.728,22.8,1.3,1.3,0,0,1-1.3,1.3"
              transform="translate(-5.597)"
            />
          </g>
          <path
            id="Path_30242"
            data-name="Path 30242"
            d="M51.01,22.719H12.492A11.661,11.661,0,0,0,7.614,23.8,11.426,11.426,0,0,0,1,34.211V72.729A11.492,11.492,0,0,0,12.492,84.221H51.01A11.491,11.491,0,0,0,62.5,72.729V34.211A11.492,11.492,0,0,0,51.01,22.719"
            transform="translate(0.302 6.862)"
            fill="#459bff"
          />
          <path
            id="Path_30244"
            data-name="Path 30244"
            d="M30.253,79.263H12.794A12.808,12.808,0,0,1,0,66.47V44.749a1.3,1.3,0,0,1,2.6,0V66.47a10.2,10.2,0,0,0,10.19,10.19H30.253a1.3,1.3,0,1,1,0,2.6"
            transform="translate(0 13.123)"
          />
          <path
            id="Union_123"
            data-name="Union 123"
            d="M2.125,18.48A2,2,0,0,1,.241,15.807L3.166,7.619A4.62,4.62,0,1,1,9.5,8.276l2.69,7.53a2,2,0,0,1-1.884,2.673Z"
            transform="translate(25.83 51.474)"
            fill="#fff"
          />
          <g id="Group_90961" data-name="Group 90961" transform="translate(0 28.279)">
            <path
              id="Path_30243"
              data-name="Path 30243"
              d="M42.328,79.438H31.045a1.3,1.3,0,1,1,0-2.6H42.328a10.2,10.2,0,0,0,10.19-10.19V44.172a1.3,1.3,0,0,1,2.6,0V66.644A12.808,12.808,0,0,1,42.328,79.438"
              transform="translate(8.983 -15.331)"
            />
            <path
              id="Path_30245"
              data-name="Path 30245"
              d="M62.8,45.8a1.3,1.3,0,0,1-1.3-1.3V34.514a10.2,10.2,0,0,0-10.19-10.191H12.794A10.2,10.2,0,0,0,2.6,34.514v5.267a1.3,1.3,0,1,1-2.6,0V34.514a12.81,12.81,0,0,1,12.794-12.8H51.312a12.81,12.81,0,0,1,12.794,12.8V44.5a1.3,1.3,0,0,1-1.3,1.3"
              transform="translate(0 -21.719)"
            />
            <path
              id="Path_30246"
              data-name="Path 30246"
              d="M30.813,59.367H21.727a2.583,2.583,0,0,1-2.5-3.241l2.138-8.1a6.093,6.093,0,1,1,9.66-.012l1.068,3.8a1.3,1.3,0,0,1-2.506.706l-1.259-4.474a1.3,1.3,0,0,1,.333-1.273,3.49,3.49,0,1,0-4.939,0,1.3,1.3,0,0,1,.337,1.254l-2.314,8.767,9.063-.029A1.281,1.281,0,0,1,31.7,55.17a1.3,1.3,0,0,1,1.6.915,2.582,2.582,0,0,1-2.486,3.281"
              transform="translate(5.783 -16.736)"
            />
          </g>
        </g>
      </g>
      <g id="Group_90977" data-name="Group 90977" transform="matrix(0.799, 0.602, -0.602, 0.799, -15812.071, -13223.237)">
        <path
          id="Path_30354"
          data-name="Path 30354"
          d="M48.977,78.3,34.119,36.743a20.788,20.788,0,1,0-11.782,4.777l2.81,2.7L24.2,51.61l4.92,3.717-1.387,6.164,4.928,3.739-1.4,6.142,4.615,2.864-1.126,6.892,11.568,5.739Z"
          transform="translate(0 0)"
          fill="#e2e2e2"
        />
        <circle id="Ellipse_1370" data-name="Ellipse 1370" cx="5.175" cy="5.175" r="5.175" transform="translate(11.094 11.922)" fill="#fff" />
        <g id="Group_90978" data-name="Group 90978" transform="translate(0 0.001)">
          <path
            id="Path_30356"
            data-name="Path 30356"
            d="M34.579,80.229l.6-1.931L20.319,36.743A20.782,20.782,0,0,0,0,1.22"
            transform="translate(13.801 0)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
          />
          <path
            id="Path_30357"
            data-name="Path 30357"
            d="M6.728,0A20.785,20.785,0,0,0,22.336,36.036l2.81,2.7L24.2,46.125l4.92,3.717-1.387,6.164,4.928,3.74-1.394,6.142,4.615,2.864-1.127,6.892,11.568,5.738.826-2.665"
            transform="translate(0 5.483)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
          />
          <line
            id="Line_2"
            data-name="Line 2"
            x2="14.311"
            y2="40.025"
            transform="translate(27.958 37.087)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_30358"
            data-name="Path 30358"
            d="M.353,8.053A6.024,6.024,0,1,0,4,.353,6.024,6.024,0,0,0,.353,8.053Z"
            transform="translate(9.808 11.38)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
