<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="52.641" height="52.641" viewBox="0 0 52.641 52.641">
    <path
      id="Union_65"
      data-name="Union 65"
      d="M21.736,52.641a2,2,0,0,1-2-2V32.9H2a2,2,0,0,1-2-2V21.74a2,2,0,0,1,2-2H19.736V2a2,2,0,0,1,2-2H30.9a2,2,0,0,1,2,2V19.741H50.637a2,2,0,0,1,2,2V30.9a2,2,0,0,1-2,2H32.9V50.641a2,2,0,0,1-2,2Z"
      transform="translate(0.005)"
    />
  </svg>
</template>
