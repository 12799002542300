import { ref, Ref, watch, nextTick, toRaw } from 'vue';

interface Props {
  dataSource: Segment.Datasource;
  modelValue: any;
  isModalOpen?: boolean;
  activeTab?: number;
}

export default function useSelectRow(props: Props, list: Ref<Record<string, any>[]>, uniqueKey: Ref<string>, selectAll?: Ref<boolean>) {
  const isSelectedAllForModal: Ref<boolean> = ref(selectAll || true);

  const includeOrExcludeListForModal: Ref<Record<string, any>[]> = ref([]);

  function forceCheckedAll(value: boolean) {
    const tds = document.querySelectorAll('tbody tr td:first-child input[type=checkbox]');
    let eleFirstElement: string;
    nextTick(() => {
      tds.forEach((element: Element) => {
        (<HTMLInputElement>element).checked = value;
        eleFirstElement = element.classList[1];
        const selectTableRow = document.querySelectorAll(`tbody tr.selected-${eleFirstElement}`);
        selectTableRow.forEach((el: Element) => {
          if ((<HTMLInputElement>element).checked == true) {
            (<HTMLInputElement>el).style.backgroundColor = '#EFF5FA';
          } else {
            (<HTMLInputElement>el).removeAttribute('style');
          }
        });
      });
    });
  }

  function onSelectedAllForModal(e: Event) {
    const inputElement = e.target as HTMLInputElement;
    isSelectedAllForModal.value = inputElement.checked;
    includeOrExcludeListForModal.value = [];
    forceCheckedAll(inputElement.checked);
  }

  function highlightTable() {
    const checkUp = document.querySelectorAll('tbody tr td:first-child input[type=checkbox]');
    let firstElement: string;
    checkUp.forEach((element: Element) => {
      firstElement = element.classList[1];
      const selectTableRow = document.querySelectorAll(`tbody tr.selected-${firstElement}`);
      selectTableRow.forEach((el: Element) => {
        if ((<HTMLInputElement>element).checked == true) {
          (<HTMLInputElement>el).style.backgroundColor = '#EFF5FA';
        } else {
          (<HTMLInputElement>el).removeAttribute('style');
        }
      });
    });
  }

  function onSelectedRowForModal(e: Event, item: Record<string, any>) {
    const itemTmp = toRaw(item);
    const inputElement = e.target as HTMLInputElement;

    highlightTable();

    if (isSelectedAllForModal.value && inputElement.checked) {
      includeOrExcludeListForModal.value = includeOrExcludeListForModal.value.filter(
        (el: Record<string, any>) => el[uniqueKey.value] !== itemTmp[uniqueKey.value],
      );
    } else if (isSelectedAllForModal.value && !inputElement.checked) {
      includeOrExcludeListForModal.value.push(itemTmp);
    } else if (!isSelectedAllForModal.value && inputElement.checked) {
      includeOrExcludeListForModal.value.push(itemTmp);
    } else if (!isSelectedAllForModal.value && !inputElement.checked) {
      includeOrExcludeListForModal.value = includeOrExcludeListForModal.value.filter(
        (el: Record<string, any>) => el[uniqueKey.value] !== itemTmp[uniqueKey.value],
      );
    }
  }

  watch(
    () => props.dataSource,
    () => {
      includeOrExcludeListForModal.value = [];
      isSelectedAllForModal.value = false;
    },
  );

  watch(list, () => {
    const includeOrExcludeListTmp = toRaw(includeOrExcludeListForModal.value.map((el: Record<string, any>) => toRaw(el)));
    setTimeout(() => {
      const table_row = document.getElementsByClassName('dragable-row');
      for (let i = 0; i < table_row.length; i++) {
        const row = table_row[i];
        row.classList.add(`selected-${i}`);
      }

      let gq = -1;
      setTimeout(() => {
        const RowCheckUp = document.querySelectorAll('tbody tr td:first-child input[type=checkbox]');
        RowCheckUp.forEach((element: Element) => {
          (<HTMLInputElement>element).classList.add(`${++gq}`);
        });
      });

      setTimeout(() => {
        highlightTable();
      }, 0);

      const tds = document.querySelectorAll('tbody tr td:first-child input[type=checkbox]');
      tds.forEach((element: Element) => {
        includeOrExcludeListTmp.forEach((o: Record<string, any>) => {
          setTimeout(() => {
            let eleFirstElement: string;
            if (o[uniqueKey.value] === parseInt((<HTMLInputElement>element).value)) {
              if (isSelectedAllForModal.value) {
                (<HTMLInputElement>element).checked = false;
                eleFirstElement = element.classList[1];
                const selectTableRow = document.querySelectorAll(`tbody tr.selected-${eleFirstElement}`);
                selectTableRow.forEach((el: Element) => {
                  (<HTMLInputElement>el).removeAttribute('style');
                });
              } else {
                (<HTMLInputElement>element).checked = true;
                eleFirstElement = element.classList[1];
                const selectTableRow = document.querySelectorAll(`tbody tr.selected-${eleFirstElement}`);
                selectTableRow.forEach((el: Element) => {
                  (<HTMLInputElement>el).style.backgroundColor = '#EFF5FA';
                });
              }
            }
          }, 10);
        });
      });
    });
  });

  watch(
    () => props.activeTab,
    () => {
      includeOrExcludeListForModal.value = [];
      isSelectedAllForModal.value = false;
    },
  );

  watch(
    () => props.isModalOpen,
    () => {
      if (props.isModalOpen == false) {
        includeOrExcludeListForModal.value = [];
        isSelectedAllForModal.value = false;
      }
    },
  );

  return { isSelectedAllForModal, includeOrExcludeListForModal, onSelectedAllForModal, onSelectedRowForModal };
}
