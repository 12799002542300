export default function dashboardByCampaignModel() {
  // Dashboard
  const getHtmlClickMapModel: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.HtmlClickMap> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetHtmlClickMap`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null!,
      Filters: [],
    },
  };

  const getTop5ClickLinkModel: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.ClickLink> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetClickLink`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null!,
      Filters: [],
    },
  };

  const getTop5EngagedContactModel: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Top5EngagedContact> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetTop5EngagedContact`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null!,
      Filters: [],
    },
  };

  const getDashboardCompleteModel: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.DashboardComplete> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetDashboardComplete`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null!,
      Filters: [],
      Ordering: [],
      Page: null!,
      Limit: null!,
    },
  };

  const getDashboardFailModel: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.DashboardFail> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetDashboardFail`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null!,
      Filters: [],
      Ordering: [],
      Page: null!,
      Limit: null!,
    },
  };

  // csv dashboard
  const getReportDashboardClickLink: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Report> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/ExportCsv_ClickLink`,
    method: 'GET',
    payload: {
      channel: null!,
      campaignId: null!,
    },
    responseType: 'blob',
  };
  const getReportDashboardTop5Engaged: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Report> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/ExportCsv_EngagedContact`,
    method: 'GET',
    payload: {
      channel: null!,
      campaignId: null!,
    },
    responseType: 'blob',
  };
  const getReportDashboardComplete: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Report> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/ExportCsv_DashboardComplete`,
    method: 'GET',
    payload: {
      channel: null!,
      campaignId: null!,
    },
    responseType: 'blob',
  };
  const getReportDashboardFail: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Report> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/ExportCsv_DashboardFail`,
    method: 'GET',
    payload: {
      channel: null!,
      campaignId: null!,
    },
    responseType: 'blob',
  };

  // Recipient
  const getRecipientSummaryModel: API.RequestModel<Communication.DashboardByCampaign.RecipientTab.Request.Summary> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetRecipientSummary`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null,
      Filters: [],
    },
  };

  const getRecipientOpenModel: API.RequestModel<Communication.DashboardByCampaign.RecipientTab.Request.Opened> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetRecipientOpen`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null,
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 10,
    },
  };

  const getRecipientClickModel: API.RequestModel<Communication.DashboardByCampaign.RecipientTab.Request.Click> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetRecipientClick`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null,
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 10,
    },
  };

  const getRecipientSentModel: API.RequestModel<Communication.DashboardByCampaign.RecipientTab.Request.Sent> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetRecipientSent`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null,
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 10,
    },
  };

  const getRecipientSent2Model: API.RequestModel<Communication.DashboardByCampaign.RecipientTab.Request.Sent> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetRecipientSent2`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null,
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 10,
    },
  };

  const getRecipientCompleteModel: API.RequestModel<Communication.DashboardByCampaign.RecipientTab.Request.Completed> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetRecipientCompleted`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null,
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 10,
    },
  };

  const getRecipientComplete2Model: API.RequestModel<Communication.DashboardByCampaign.RecipientTab.Request.Completed> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetRecipientCompleted2`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null,
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 10,
    },
  };
  const getRecipientFailModel: API.RequestModel<Communication.DashboardByCampaign.RecipientTab.Request.Fail> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetRecipientFail`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null,
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 10,
    },
  };

  const getRecipientBounceRateModel: API.RequestModel<Communication.DashboardByCampaign.RecipientTab.Request.BounceRate> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetRecipientBounceRate`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null,
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 10,
    },
  };

  const getRecipientSpamReportModel: API.RequestModel<Communication.DashboardByCampaign.RecipientTab.Request.BounceRate> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetRecipientSpamReport`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null,
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 10,
    },
  };

  const getRecipientOutOfCreditReportModel: API.RequestModel<Communication.DashboardByCampaign.RecipientTab.Request.BounceRate> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetRecipientOutOfCredit`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null,
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 10,
    },
  };

  const getRecipientLimitationReportModel: API.RequestModel<Communication.DashboardByCampaign.RecipientTab.Request.BounceRate> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/GetRecipientLimitation`,
    method: 'POST',
    payload: {
      Channel: null!,
      CampaignId: null,
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 10,
    },
  };

  // csv recipient
  const getReportRecipientOpen: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Report> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/ExportCsv_RecipientOpen`,
    method: 'GET',
    payload: {
      channel: null!,
      campaignId: null!,
    },
    responseType: 'blob',
  };
  const getReportRecipientClicked: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Report> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/ExportCsv_RecipientClicked`,
    method: 'GET',
    payload: {
      channel: null!,
      campaignId: null!,
    },
    responseType: 'blob',
  };
  const getReportRecipientSent: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Report> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/ExportCsv_RecipientSent`,
    method: 'GET',
    payload: {
      channel: null!,
      campaignId: null!,
    },
    responseType: 'blob',
  };

  const getReportRecipientSent2: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Report> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/ExportCsv_RecipientSent2`,
    method: 'GET',
    payload: {
      channel: null!,
      campaignId: null!,
    },
    responseType: 'blob',
  };

  const getReportRecipientCompleted: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Report> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/ExportCsv_RecipientCompleted`,
    method: 'GET',
    payload: {
      channel: null!,
      campaignId: null!,
    },
    responseType: 'blob',
  };

  const getReportRecipientCompleted2: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Report> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/ExportCsv_RecipientCompleted2`,
    method: 'GET',
    payload: {
      channel: null!,
      campaignId: null!,
    },
    responseType: 'blob',
  };

  const getReportRecipientFail: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Report> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/ExportCsv_RecipientFail`,
    method: 'GET',
    payload: {
      channel: null!,
      campaignId: null!,
    },
    responseType: 'blob',
  };
  const getReportRecipientBounceRate: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Report> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/ExportCsv_RecipientBounceRate`,
    method: 'GET',
    payload: {
      channel: null!,
      campaignId: null!,
    },
    responseType: 'blob',
  };
  const getReportRecipientSpamReport: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Report> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/ExportCsv_RecipientSpamReport`,
    method: 'GET',
    payload: {
      channel: null!,
      campaignId: null!,
    },
    responseType: 'blob',
  };
  const getReportRecipientOutOfCreditReport: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Report> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/ExportCsv_RecipientOutOfCredit`,
    method: 'GET',
    payload: {
      channel: null!,
      campaignId: null!,
    },
    responseType: 'blob',
  };
  const getReportRecipientLimitationReport: API.RequestModel<Communication.DashboardByCampaign.DashboardTab.Request.Report> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignDashboard/ExportCsv_RecipientLimitation`,
    method: 'GET',
    payload: {
      channel: null!,
      campaignId: null!,
    },
    responseType: 'blob',
  };

  return {
    // Dashboard
    getHtmlClickMapModel,
    getTop5ClickLinkModel,
    getTop5EngagedContactModel,
    getDashboardCompleteModel,
    getDashboardFailModel,
    // Dashboard Report
    getReportDashboardClickLink,
    getReportDashboardTop5Engaged,
    getReportDashboardComplete,
    getReportDashboardFail,

    // Recipient
    getRecipientSummaryModel,
    getRecipientOpenModel,
    getRecipientClickModel,
    getRecipientSentModel,
    getRecipientCompleteModel,
    getRecipientFailModel,
    getRecipientBounceRateModel,
    getRecipientSpamReportModel,
    getRecipientOutOfCreditReportModel,
    getRecipientLimitationReportModel,
    // Recipient Report
    getReportRecipientOpen,
    getReportRecipientClicked,
    getReportRecipientSent,
    getReportRecipientCompleted,
    getReportRecipientFail,
    getReportRecipientBounceRate,
    getReportRecipientSpamReport,
    getReportRecipientOutOfCreditReport,
    getReportRecipientLimitationReport,
    getRecipientSent2Model,
    getRecipientComplete2Model,
    getReportRecipientSent2,
    getReportRecipientCompleted2,
  };
}
