import { CustomRouteRecord } from '@/types/router/router';

import ExpireResetPassword from '@/views/modules/authentication/components/ExpireResetPassword.vue';

const permissionRoute: Array<CustomRouteRecord> = [
  {
    path: '/permission/profile/:idetitysref?',
    name: 'permission-main',
    component: () => import('@views/modules/permission/pages/permission-main/PermissionMain.vue'),
    breadcrumb: [
      {
        text: 'สิทธิ์การใช้งาน',
        to: '/permission/profile',
      },
      {
        text: 'โปรไฟล์',
        active: true,
      },
    ],
  },
  {
    path: '/permission/manage/:submenu?',
    name: 'permission-manage',
    component: () => import('@views/modules/permission/pages/permission-main/PermissionMain.vue'),
    breadcrumb: [
      {
        text: 'สิทธิ์การใช้งาน',
        to: '/permission/profile/:submenu?',
      },
      {
        text: 'จัดการสิทธิ์การใช้งาน',
        active: true,
      },
    ],
  },
  {
    path: '/permission/history/:idetitysref?',
    name: 'permission-history',
    component: () => import('@views/modules/permission/pages/permission-main/PermissionActivity.vue'),
    breadcrumb: [
      {
        text: 'สิทธิ์การใช้งาน',
        to: '/permission/profile',
      },
      {
        text: 'โปรไฟล์',
        to: '/permission/profile',
      },
      {
        text: 'ประวัติการใช้งาน',
        active: true,
      },
    ],
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@views/modules/permission/pages/authentication/components/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/created-password',
    name: 'created-password',
    component: () => import('@views/modules/permission/pages/authentication/components/CreatePassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/check-old-password',
    name: 'check-old-password',
    component: () => import('@views/modules/permission/pages/authentication/components/CheckOldPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/expire-reset-password',
    name: 'expire-reset-password',
    component: ExpireResetPassword,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/invite-profile',
    name: 'invite-profile',
    component: () => import('@views/modules/permission/pages/authentication/components/InviteProfile.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
];

export default permissionRoute;
