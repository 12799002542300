<template>
  <svg id="Group_34735" data-name="Group 34735" xmlns="http://www.w3.org/2000/svg" width="19.825" height="14.766" viewBox="0 0 19.825 14.766">
    <path
      id="Path_22790"
      data-name="Path 22790"
      d="M774.959,8.425h-17.4a1.21,1.21,0,0,0-1.21,1.21V21.982a1.21,1.21,0,0,0,1.21,1.21h17.4a1.21,1.21,0,0,0,1.21-1.21V9.635a1.21,1.21,0,0,0-1.21-1.21m-2.8,1.682a.253.253,0,0,1,.253-.253h1.678a.253.253,0,0,1,.253.253v1.361a.253.253,0,0,1-.253.253h-1.678a.253.253,0,0,1-.253-.253Zm-3.508,0a.253.253,0,0,1,.253-.253h1.678a.253.253,0,0,1,.253.253v1.361a.253.253,0,0,1-.253.253h-1.678a.253.253,0,0,1-.253-.253Zm-3.508,0a.253.253,0,0,1,.253-.253h1.678a.253.253,0,0,1,.253.253v1.361a.253.253,0,0,1-.253.253H765.4a.253.253,0,0,1-.253-.253Zm-3.508,0a.253.253,0,0,1,.253-.253h1.678a.253.253,0,0,1,.253.253v1.361a.253.253,0,0,1-.253.253h-1.678a.253.253,0,0,1-.253-.253Zm-3.508,0a.253.253,0,0,1,.253-.253h1.678a.253.253,0,0,1,.253.253v1.361a.253.253,0,0,1-.253.253h-1.678a.253.253,0,0,1-.253-.253ZM760.316,21.8a.253.253,0,0,1-.253.253h-1.678a.253.253,0,0,1-.253-.253V20.437a.253.253,0,0,1,.253-.253h1.678a.253.253,0,0,1,.253.253Zm3.508,0a.253.253,0,0,1-.253.253h-1.678a.253.253,0,0,1-.253-.253V20.437a.253.253,0,0,1,.253-.253h1.678a.253.253,0,0,1,.253.253Zm3.508,0a.253.253,0,0,1-.253.253H765.4a.253.253,0,0,1-.253-.253V20.437a.253.253,0,0,1,.253-.253h1.678a.253.253,0,0,1,.253.253Zm3.508,0a.253.253,0,0,1-.253.253h-1.678a.253.253,0,0,1-.253-.253V20.437a.253.253,0,0,1,.253-.253h1.678a.253.253,0,0,1,.253.253Zm3.508,0a.253.253,0,0,1-.253.253h-1.678a.253.253,0,0,1-.253-.253V20.437a.253.253,0,0,1,.253-.253h1.678a.253.253,0,0,1,.253.253Zm.03-2.618H758.135V12.714h16.242Z"
      transform="translate(-756.344 -8.425)"
    />
    <path
      id="Path_22791"
      data-name="Path 22791"
      d="M807.655,40.2l-2.075-1.537a.352.352,0,0,0-.561.278l-.04,3.256a.352.352,0,0,0,.574.277l2.114-1.719a.352.352,0,0,0-.012-.556"
      transform="translate(-796.268 -33.19)"
    />
  </svg>
</template>
