<!-- <template>
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
    <path
      opacity="0.1"
      d="M34.086 0.25H10C4.47715 0.25 0 4.72715 0 10.25V34.336C0 39.8588 4.47715 44.336 10 44.336H34.086C39.6088 44.336 44.086 39.8588 44.086 34.336V10.25C44.086 4.72715 39.6088 0.25 34.086 0.25Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M27.5654 20.688H16.6414C16.2349 20.689 15.8453 20.851 15.5579 21.1385C15.2704 21.4259 15.1085 21.8155 15.1074 22.222V26.694C15.1085 27.1005 15.2704 27.4901 15.5579 27.7775C15.8453 28.065 16.2349 28.2269 16.6414 28.228H27.5654C27.9719 28.2269 28.3615 28.065 28.649 27.7775C28.9364 27.4901 29.0984 27.1005 29.0994 26.694V22.222C29.0984 21.8155 28.9364 21.4259 28.649 21.1385C28.3615 20.851 27.9719 20.689 27.5654 20.688Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M28.8 16.5728H20.766C20.4743 16.5728 20.1945 16.6886 19.9882 16.8949C19.7819 17.1012 19.666 17.381 19.666 17.6728V20.9888C19.666 21.2805 19.7819 21.5603 19.9882 21.7666C20.1945 21.9729 20.4743 22.0888 20.766 22.0888H28.8C29.0918 22.0888 29.3715 21.9729 29.5778 21.7666C29.7841 21.5603 29.9 21.2805 29.9 20.9888V17.6738C29.9 17.382 29.7841 17.1022 29.5778 16.8959C29.3715 16.6896 29.0918 16.5738 28.8 16.5738"
      fill="black"
    />
    <path
      d="M33.23 17.519H33.905V16.427C33.905 15.6843 33.61 14.9722 33.0849 14.4471C32.5598 13.922 31.8476 13.627 31.105 13.627H17.841C17.0984 13.627 16.3862 13.922 15.8611 14.4471C15.336 14.9722 15.041 15.6843 15.041 16.427V17.527H15.762C16.0513 17.5276 16.3358 17.6012 16.5891 17.7411C16.8424 17.8809 17.0563 18.0824 17.211 18.327C17.2762 18.4334 17.3672 18.5217 17.4756 18.5835C17.584 18.6454 17.7062 18.6789 17.831 18.681C17.9592 18.6807 18.0851 18.6462 18.1955 18.581C18.3059 18.5158 18.3969 18.4223 18.4591 18.3101C18.5213 18.198 18.5524 18.0713 18.5492 17.9431C18.5461 17.8149 18.5087 17.6899 18.441 17.581C18.0022 16.8795 17.3068 16.3772 16.503 16.181C16.5622 15.8719 16.7271 15.5931 16.9694 15.3924C17.2118 15.1917 17.5164 15.0816 17.831 15.081H31.105C31.4197 15.0816 31.7243 15.1917 31.9666 15.3924C32.2089 15.5931 32.3738 15.8719 32.433 16.181C31.7434 16.3485 31.1301 16.7429 30.6915 17.3008C30.2529 17.8587 30.0145 18.5478 30.0145 19.2575C30.0145 19.9671 30.2529 20.6562 30.6915 21.2141C31.1301 21.772 31.7434 22.1664 32.433 22.334C32.3738 22.643 32.2089 22.9218 31.9666 23.1225C31.7243 23.3232 31.4197 23.4333 31.105 23.434C31.0131 23.434 30.9221 23.4521 30.8371 23.4872C30.7522 23.5224 30.675 23.574 30.61 23.639C30.545 23.704 30.4935 23.7811 30.4583 23.8661C30.4231 23.951 30.405 24.042 30.405 24.134V24.167C30.405 24.2589 30.4231 24.3499 30.4583 24.4348C30.4935 24.5198 30.545 24.5969 30.61 24.6619C30.675 24.7269 30.7522 24.7785 30.8371 24.8137C30.9221 24.8488 31.0131 24.867 31.105 24.867C31.8476 24.867 32.5598 24.572 33.0849 24.0469C33.61 23.5218 33.905 22.8096 33.905 22.067V20.967H33.184C32.9438 20.9668 32.7063 20.9164 32.4866 20.8191C32.267 20.7218 32.0702 20.5797 31.9087 20.4018C31.7472 20.2239 31.6247 20.0143 31.549 19.7863C31.4733 19.5584 31.446 19.3171 31.469 19.078C31.5183 18.646 31.726 18.2476 32.0521 17.96C32.3781 17.6724 32.7993 17.5159 33.234 17.521"
      fill="black"
    />
    <path
      d="M22.5058 30.243V30.236C22.5094 30.1395 22.4935 30.0433 22.4591 29.9531C22.4247 29.8629 22.3724 29.7806 22.3054 29.7111C22.2384 29.6415 22.1581 29.5862 22.0693 29.5485C21.9804 29.5107 21.8849 29.4912 21.7883 29.4912C21.6918 29.4912 21.5962 29.5107 21.5074 29.5485C21.4185 29.5862 21.3382 29.6415 21.2712 29.7111C21.2042 29.7806 21.152 29.8629 21.1175 29.9531C21.0831 30.0433 21.0672 30.1395 21.0708 30.236V30.243C21.0672 30.3395 21.0831 30.4357 21.1175 30.5259C21.152 30.6161 21.2042 30.6984 21.2712 30.7679C21.3382 30.8375 21.4185 30.8928 21.5074 30.9305C21.5962 30.9683 21.6918 30.9878 21.7883 30.9878C21.8849 30.9878 21.9804 30.9683 22.0693 30.9305C22.1581 30.8928 22.2384 30.8375 22.3054 30.7679C22.3724 30.6984 22.4247 30.6161 22.4591 30.5259C22.4935 30.4357 22.5094 30.3395 22.5058 30.243Z"
      fill="black"
    />
    <path
      d="M19.5587 29.518H12.9837C12.6707 29.5176 12.3676 29.4089 12.1257 29.2104C11.8838 29.0119 11.7181 28.7358 11.6567 28.429C11.6564 28.427 11.6568 28.425 11.6579 28.4233C11.659 28.4216 11.6607 28.4204 11.6627 28.42C12.3504 28.2509 12.9616 27.8562 13.3986 27.2988C13.8356 26.7415 14.073 26.0537 14.073 25.3455C14.073 24.6372 13.8356 23.9495 13.3986 23.3921C12.9616 22.8347 12.3504 22.44 11.6627 22.271L11.6598 22.27L11.6575 22.2679L11.6564 22.265L11.6567 22.262C11.7183 21.9553 11.8841 21.6794 12.126 21.4811C12.3679 21.2828 12.6709 21.1743 12.9837 21.174H14.5337C14.725 21.174 14.9085 21.0979 15.0438 20.9626C15.1792 20.8273 15.2552 20.6438 15.2552 20.4525C15.2552 20.2611 15.1792 20.0776 15.0438 19.9423C14.9085 19.807 14.725 19.731 14.5337 19.731H12.9837C12.2411 19.731 11.5289 20.026 11.0038 20.5511C10.4787 21.0762 10.1837 21.7884 10.1837 22.531V23.613L10.1857 23.6179L10.1907 23.62H10.8477C11.2842 23.6145 11.7073 23.7713 12.0347 24.0602C12.3621 24.349 12.5706 24.7491 12.6197 25.183C12.6419 25.4217 12.6141 25.6625 12.5381 25.8899C12.462 26.1174 12.3394 26.3264 12.178 26.5038C12.0167 26.6812 11.8201 26.823 11.6008 26.9201C11.3816 27.0172 11.1445 27.0676 10.9047 27.068H10.1907L10.1877 27.0686L10.1853 27.0703L10.1839 27.073L10.1837 27.076V28.155C10.1837 28.8976 10.4787 29.6098 11.0038 30.1349C11.5289 30.66 12.2411 30.955 12.9837 30.955H19.5937C19.6939 30.9551 19.7931 30.9343 19.8848 30.894C19.9766 30.8536 20.0589 30.7946 20.1266 30.7206C20.1943 30.6466 20.2457 30.5594 20.2778 30.4644C20.3098 30.3694 20.3217 30.2688 20.3127 30.169C20.2904 29.9853 20.2008 29.8164 20.0611 29.695C19.9214 29.5736 19.7417 29.5084 19.5567 29.512"
      fill="black"
    />
    <path
      d="M15.9434 20.449V20.455C15.9434 20.6454 16.019 20.828 16.1537 20.9627C16.2883 21.0973 16.4709 21.173 16.6614 21.173C16.8518 21.173 17.0344 21.0973 17.1691 20.9627C17.3037 20.828 17.3794 20.6454 17.3794 20.455V20.449C17.3794 20.2585 17.3037 20.0759 17.1691 19.9413C17.0344 19.8066 16.8518 19.731 16.6614 19.731C16.4709 19.731 16.2883 19.8066 16.1537 19.9413C16.019 20.0759 15.9434 20.2585 15.9434 20.449Z"
      fill="black"
    />
    <path
      d="M15.9434 23.1189V23.1249C15.9434 23.3153 16.019 23.4979 16.1537 23.6326C16.2883 23.7672 16.4709 23.8429 16.6614 23.8429C16.8518 23.8429 17.0344 23.7672 17.1691 23.6326C17.3037 23.4979 17.3794 23.3153 17.3794 23.1249V23.1189C17.3794 22.9285 17.3037 22.7458 17.1691 22.6112C17.0344 22.4765 16.8518 22.4009 16.6614 22.4009C16.4709 22.4009 16.2883 22.4765 16.1537 22.6112C16.019 22.7458 15.9434 22.9285 15.9434 23.1189Z"
      fill="black"
    />
    <path
      d="M15.9434 25.5749V25.5809C15.9434 25.7714 16.019 25.954 16.1537 26.0886C16.2883 26.2233 16.4709 26.2989 16.6614 26.2989C16.8518 26.2989 17.0344 26.2233 17.1691 26.0886C17.3037 25.954 17.3794 25.7714 17.3794 25.5809V25.5749C17.3794 25.3845 17.3037 25.2019 17.1691 25.0672C17.0344 24.9326 16.8518 24.8569 16.6614 24.8569C16.4709 24.8569 16.2883 24.9326 16.1537 25.0672C16.019 25.2019 15.9434 25.3845 15.9434 25.5749Z"
      fill="black"
    />
    <path
      d="M15.9434 27.988V27.994C15.9434 28.1845 16.019 28.3671 16.1537 28.5017C16.2883 28.6364 16.4709 28.712 16.6614 28.712C16.8518 28.712 17.0344 28.6364 17.1691 28.5017C17.3037 28.3671 17.3794 28.1845 17.3794 27.994V27.988C17.3794 27.7976 17.3037 27.615 17.1691 27.4803C17.0344 27.3457 16.8518 27.27 16.6614 27.27C16.4709 27.27 16.2883 27.3457 16.1537 27.4803C16.019 27.615 15.9434 27.7976 15.9434 27.988Z"
      fill="black"
    />
    <path
      d="M28.3663 23.6231H29.0373V22.5231C29.0373 21.7805 28.7423 21.0683 28.2172 20.5432C27.6921 20.0181 26.9799 19.7231 26.2373 19.7231H18.8953C18.704 19.7231 18.5205 19.7992 18.3851 19.9345C18.2498 20.0698 18.1738 20.2533 18.1738 20.4446C18.1738 20.636 18.2498 20.8195 18.3851 20.9548C18.5205 21.0901 18.704 21.1661 18.8953 21.1661H26.2373C26.552 21.1668 26.8566 21.2769 27.0989 21.4776C27.3412 21.6783 27.5061 21.9571 27.5653 22.2661C26.8757 22.4337 26.2624 22.8281 25.8238 23.386C25.3852 23.9439 25.1468 24.633 25.1468 25.3426C25.1468 26.0523 25.3852 26.7414 25.8238 27.2993C26.2624 27.8572 26.8757 28.2516 27.5653 28.4191C27.5061 28.7282 27.3412 29.0069 27.0989 29.2077C26.8566 29.4084 26.552 29.5185 26.2373 29.5191H23.9653C23.7823 29.516 23.6045 29.5806 23.4663 29.7006C23.328 29.8206 23.239 29.9875 23.2163 30.1691C23.2064 30.2694 23.2176 30.3706 23.2492 30.4662C23.2807 30.5618 23.332 30.6498 23.3997 30.7244C23.4673 30.799 23.5498 30.8586 23.6419 30.8994C23.734 30.9402 23.8336 30.9612 23.9343 30.9611H26.2423C26.9849 30.9611 27.6971 30.6661 28.2222 30.141C28.7473 29.6159 29.0423 28.9038 29.0423 28.1611V27.0611H28.3213C28.0811 27.061 27.8436 27.0106 27.6239 26.9133C27.4043 26.816 27.2075 26.6738 27.046 26.496C26.8845 26.3181 26.762 26.1085 26.6863 25.8805C26.6106 25.6525 26.5833 25.4113 26.6063 25.1721C26.6556 24.7402 26.8633 24.3418 27.1894 24.0542C27.5154 23.7666 27.9366 23.6101 28.3713 23.6151"
      fill="black"
    />
  </svg>
</template> -->
<template>
<svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.2" d="M17.5657 7.68799H6.64167C6.23515 7.68904 5.84558 7.851 5.55813 8.13845C5.27068 8.4259 5.10872 8.81547 5.10767 9.22199V13.694C5.10872 14.1005 5.27068 14.4901 5.55813 14.7775C5.84558 15.065 6.23515 15.2269 6.64167 15.228H17.5657C17.9722 15.2269 18.3617 15.065 18.6492 14.7775C18.9367 14.4901 19.0986 14.1005 19.0997 13.694V9.22199C19.0986 8.81547 18.9367 8.4259 18.6492 8.13845C18.3617 7.851 17.9722 7.68904 17.5657 7.68799Z" fill="black"/>
<path opacity="0.2" d="M18.8 3.57275H10.766C10.4743 3.57275 10.1945 3.68865 9.9882 3.89494C9.78191 4.10123 9.66602 4.38102 9.66602 4.67275V7.98875C9.66602 8.28049 9.78191 8.56028 9.9882 8.76657C10.1945 8.97286 10.4743 9.08875 10.766 9.08875H18.8C19.0918 9.08875 19.3715 8.97286 19.5778 8.76657C19.7841 8.56028 19.9 8.28049 19.9 7.98875V4.67375C19.9 4.38202 19.7841 4.10223 19.5778 3.89594C19.3715 3.68965 19.0918 3.57376 18.8 3.57376" fill="black"/>
<path d="M23.23 4.51895H23.905V3.42695C23.905 2.68435 23.61 1.97216 23.0849 1.44705C22.5598 0.921952 21.8476 0.626953 21.105 0.626953H7.84102C7.09841 0.626953 6.38622 0.921952 5.86112 1.44705C5.33601 1.97216 5.04102 2.68435 5.04102 3.42695V4.52695H5.76201C6.05135 4.52758 6.33585 4.60123 6.58914 4.74107C6.84243 4.88092 7.05633 5.08244 7.21102 5.32695C7.27618 5.4334 7.36718 5.52166 7.47557 5.58355C7.58396 5.64543 7.70622 5.67894 7.83101 5.68095C7.95925 5.68068 8.08508 5.64616 8.1955 5.58097C8.30593 5.51577 8.39694 5.42227 8.45912 5.31013C8.52131 5.19799 8.55242 5.07127 8.54924 4.94308C8.54605 4.81489 8.50869 4.68987 8.44102 4.58095C8.00218 3.87953 7.3068 3.37719 6.50302 3.18095C6.56223 2.87193 6.72714 2.59315 6.96945 2.39244C7.21176 2.19173 7.51637 2.0816 7.83101 2.08095H21.105C21.4197 2.0816 21.7243 2.19173 21.9666 2.39244C22.2089 2.59315 22.3738 2.87193 22.433 3.18095C21.7434 3.3485 21.1301 3.74288 20.6915 4.30079C20.2529 4.8587 20.0145 5.54779 20.0145 6.25745C20.0145 6.96712 20.2529 7.65621 20.6915 8.21412C21.1301 8.77203 21.7434 9.16641 22.433 9.33395C22.3738 9.64297 22.2089 9.92176 21.9666 10.1225C21.7243 10.3232 21.4197 10.4333 21.105 10.434C21.0131 10.434 20.9221 10.4521 20.8371 10.4872C20.7522 10.5224 20.675 10.574 20.61 10.639C20.545 10.704 20.4935 10.7811 20.4583 10.8661C20.4231 10.951 20.405 11.042 20.405 11.134V11.167C20.405 11.2589 20.4231 11.3499 20.4583 11.4348C20.4935 11.5198 20.545 11.5969 20.61 11.6619C20.675 11.7269 20.7522 11.7785 20.8371 11.8137C20.9221 11.8488 21.0131 11.867 21.105 11.867C21.8476 11.867 22.5598 11.572 23.0849 11.0469C23.61 10.5218 23.905 9.80956 23.905 9.06695V7.96695H23.184C22.9438 7.9668 22.7063 7.91645 22.4866 7.81913C22.267 7.7218 22.0702 7.57966 21.9087 7.4018C21.7472 7.22395 21.6247 7.01431 21.549 6.78633C21.4733 6.55835 21.446 6.31707 21.469 6.07795C21.5183 5.64597 21.726 5.24761 22.0521 4.95999C22.3781 4.67237 22.7993 4.51593 23.234 4.52095" fill="black"/>
<path d="M12.5058 17.243V17.236C12.5094 17.1395 12.4935 17.0433 12.4591 16.9531C12.4247 16.8629 12.3724 16.7806 12.3054 16.7111C12.2384 16.6415 12.1581 16.5862 12.0693 16.5485C11.9804 16.5107 11.8849 16.4912 11.7883 16.4912C11.6918 16.4912 11.5962 16.5107 11.5074 16.5485C11.4185 16.5862 11.3382 16.6415 11.2712 16.7111C11.2042 16.7806 11.152 16.8629 11.1175 16.9531C11.0831 17.0433 11.0672 17.1395 11.0708 17.236V17.243C11.0672 17.3395 11.0831 17.4357 11.1175 17.5259C11.152 17.6161 11.2042 17.6984 11.2712 17.7679C11.3382 17.8375 11.4185 17.8928 11.5074 17.9305C11.5962 17.9683 11.6918 17.9878 11.7883 17.9878C11.8849 17.9878 11.9804 17.9683 12.0693 17.9305C12.1581 17.8928 12.2384 17.8375 12.3054 17.7679C12.3724 17.6984 12.4247 17.6161 12.4591 17.5259C12.4935 17.4357 12.5094 17.3395 12.5058 17.243Z" fill="black"/>
<path d="M9.55867 16.518H2.98367C2.67075 16.5176 2.36761 16.4089 2.12572 16.2104C1.88382 16.0119 1.71809 15.7358 1.65667 15.429C1.65636 15.427 1.6568 15.425 1.65791 15.4233C1.65902 15.4216 1.66072 15.4204 1.66267 15.42C2.35042 15.2509 2.96164 14.8562 3.3986 14.2988C3.83555 13.7415 4.07302 13.0537 4.07302 12.3455C4.07302 11.6372 3.83555 10.9495 3.3986 10.3921C2.96164 9.83475 2.35042 9.43999 1.66267 9.27096L1.65976 9.26999L1.65754 9.26788L1.65644 9.26501L1.65667 9.26196C1.7183 8.95531 1.88412 8.67943 2.12599 8.48112C2.36786 8.28282 2.67089 8.17429 2.98367 8.17396H4.53367C4.72502 8.17396 4.90854 8.09794 5.04384 7.96264C5.17915 7.82733 5.25517 7.64381 5.25517 7.45246C5.25517 7.2611 5.17915 7.07759 5.04384 6.94228C4.90854 6.80697 4.72502 6.73096 4.53367 6.73096H2.98367C2.24106 6.73096 1.52887 7.02596 1.00377 7.55106C0.478665 8.07616 0.183666 8.78835 0.183666 9.53096V10.613L0.185716 10.6179L0.190666 10.62H0.847666C1.28424 10.6145 1.70728 10.7713 2.03471 11.0602C2.36214 11.349 2.57061 11.7491 2.61967 12.183C2.64191 12.4217 2.61412 12.6625 2.53808 12.8899C2.46204 13.1174 2.33942 13.3264 2.17804 13.5038C2.01666 13.6812 1.82007 13.823 1.60082 13.9201C1.38157 14.0172 1.14447 14.0676 0.904666 14.068H0.190666L0.187734 14.0686L0.185326 14.0703L0.183881 14.073L0.183666 14.076V15.155C0.183666 15.8976 0.478665 16.6098 1.00377 17.1349C1.52887 17.66 2.24106 17.955 2.98367 17.955H9.59367C9.69391 17.9551 9.79307 17.9343 9.88484 17.894C9.9766 17.8536 10.0589 17.7946 10.1266 17.7206C10.1943 17.6466 10.2457 17.5594 10.2778 17.4644C10.3098 17.3694 10.3217 17.2688 10.3127 17.169C10.2904 16.9853 10.2008 16.8164 10.0611 16.695C9.92142 16.5736 9.74167 16.5084 9.55667 16.512" fill="black"/>
<path d="M5.94336 7.44896V7.45496C5.94336 7.64538 6.019 7.82801 6.15366 7.96266C6.28831 8.09731 6.47093 8.17296 6.66136 8.17296C6.85178 8.17296 7.03441 8.09731 7.16906 7.96266C7.30371 7.82801 7.37936 7.64538 7.37936 7.45496V7.44896C7.37936 7.25853 7.30371 7.0759 7.16906 6.94125C7.03441 6.8066 6.85178 6.73096 6.66136 6.73096C6.47093 6.73096 6.28831 6.8066 6.15366 6.94125C6.019 7.0759 5.94336 7.25853 5.94336 7.44896Z" fill="black"/>
<path d="M5.94336 10.1189V10.1249C5.94336 10.3153 6.019 10.4979 6.15366 10.6326C6.28831 10.7672 6.47093 10.8429 6.66136 10.8429C6.85178 10.8429 7.03441 10.7672 7.16906 10.6326C7.30371 10.4979 7.37936 10.3153 7.37936 10.1249V10.1189C7.37936 9.92845 7.30371 9.74583 7.16906 9.61118C7.03441 9.47652 6.85178 9.40088 6.66136 9.40088C6.47093 9.40088 6.28831 9.47652 6.15366 9.61118C6.019 9.74583 5.94336 9.92845 5.94336 10.1189Z" fill="black"/>
<path d="M5.94336 12.5749V12.5809C5.94336 12.7714 6.019 12.954 6.15366 13.0886C6.28831 13.2233 6.47093 13.2989 6.66136 13.2989C6.85178 13.2989 7.03441 13.2233 7.16906 13.0886C7.30371 12.954 7.37936 12.7714 7.37936 12.5809V12.5749C7.37936 12.3845 7.30371 12.2019 7.16906 12.0672C7.03441 11.9326 6.85178 11.8569 6.66136 11.8569C6.47093 11.8569 6.28831 11.9326 6.15366 12.0672C6.019 12.2019 5.94336 12.3845 5.94336 12.5749Z" fill="black"/>
<path d="M5.94336 14.988V14.994C5.94336 15.1845 6.019 15.3671 6.15366 15.5017C6.28831 15.6364 6.47093 15.712 6.66136 15.712C6.85178 15.712 7.03441 15.6364 7.16906 15.5017C7.30371 15.3671 7.37936 15.1845 7.37936 14.994V14.988C7.37936 14.7976 7.30371 14.615 7.16906 14.4803C7.03441 14.3457 6.85178 14.27 6.66136 14.27C6.47093 14.27 6.28831 14.3457 6.15366 14.4803C6.019 14.615 5.94336 14.7976 5.94336 14.988Z" fill="black"/>
<path d="M18.3663 10.6231H19.0373V9.52314C19.0373 8.78054 18.7423 8.06835 18.2172 7.54324C17.6921 7.01814 16.9799 6.72314 16.2373 6.72314H8.89532C8.70397 6.72314 8.52045 6.79916 8.38515 6.93447C8.24984 7.06977 8.17383 7.25329 8.17383 7.44464C8.17383 7.636 8.24984 7.81951 8.38515 7.95482C8.52045 8.09013 8.70397 8.16615 8.89532 8.16615H16.2373C16.552 8.16679 16.8566 8.27692 17.0989 8.47763C17.3412 8.67834 17.5061 8.95712 17.5653 9.26614C16.8757 9.43369 16.2624 9.82807 15.8238 10.386C15.3852 10.9439 15.1468 11.633 15.1468 12.3426C15.1468 13.0523 15.3852 13.7414 15.8238 14.2993C16.2624 14.8572 16.8757 15.2516 17.5653 15.4191C17.5061 15.7282 17.3412 16.0069 17.0989 16.2077C16.8566 16.4084 16.552 16.5185 16.2373 16.5191H13.9653C13.7823 16.516 13.6045 16.5806 13.4663 16.7006C13.328 16.8206 13.239 16.9875 13.2163 17.1691C13.2064 17.2694 13.2176 17.3706 13.2492 17.4662C13.2807 17.5618 13.332 17.6498 13.3997 17.7244C13.4673 17.799 13.5498 17.8586 13.6419 17.8994C13.734 17.9402 13.8336 17.9612 13.9343 17.9611H16.2423C16.9849 17.9611 17.6971 17.6661 18.2222 17.141C18.7473 16.6159 19.0423 15.9038 19.0423 15.1611V14.0611H18.3213C18.0811 14.061 17.8436 14.0106 17.6239 13.9133C17.4043 13.816 17.2075 13.6738 17.046 13.496C16.8845 13.3181 16.762 13.1085 16.6863 12.8805C16.6106 12.6525 16.5833 12.4113 16.6063 12.1721C16.6556 11.7402 16.8633 11.3418 17.1894 11.0542C17.5154 10.7666 17.9366 10.6101 18.3713 10.6151" fill="black"/>
</svg>
</template>
