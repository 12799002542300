<template>
  <svg id="Group_37106" data-name="Group 37106" xmlns="http://www.w3.org/2000/svg" width="54.928" height="94.432" viewBox="0 0 54.928 94.432">
    <path
      id="Path_24104"
      data-name="Path 24104"
      d="M285.321,17.106a8.972,8.972,0,1,1-8.972-8.972,8.972,8.972,0,0,1,8.972,8.972"
      transform="translate(-248.514 -8.134)"
    />
    <path
      id="Path_24105"
      data-name="Path 24105"
      d="M295.474,57.108l-11.594-17.6a4.491,4.491,0,0,0-3.224-1.992V37.48H256.8V37.5a4.492,4.492,0,0,0-3.462,2.012L242.2,56.413a5.9,5.9,0,0,0,.722,7.093h0a3.607,3.607,0,0,0,5.782-.589L256.8,50.63v55.33a4.63,4.63,0,0,0,4.366,4.691,4.5,4.5,0,0,0,4.644-4.5V85.938a2.917,2.917,0,1,1,5.834,0v20.021a4.63,4.63,0,0,0,4.366,4.692,4.5,4.5,0,0,0,4.644-4.5V50.991l7.4,11.231a4.505,4.505,0,0,0,6.355,1.206,4.63,4.63,0,0,0,1.065-6.32m-26.759,7.709-2.845-2.33a.591.591,0,0,1-.222-.521l2.23-20.107a.942.942,0,0,0-.314-.8l-2.012-1.831a.25.25,0,0,1,.2-.43h5.959a.251.251,0,0,1,.2.425L269.9,41.164a.938.938,0,0,0-.293.78l2.181,19.862a.589.589,0,0,1-.205.506Z"
      transform="translate(-241.339 -16.221)"
    />
  </svg>
</template>
