<template>
  <svg
    id="Group_33301"
    data-name="Group 33301"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="20.983"
    height="15.806"
    viewBox="0 0 20.983 15.806"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_14393" data-name="Rectangle 14393" width="17.981" height="14.002" />
      </clipPath>
      <clipPath id="clip-path-2">
        <rect id="Rectangle_14394" data-name="Rectangle 14394" width="8.046" height="6.361" />
      </clipPath>
    </defs>
    <g id="Group_33298" data-name="Group 33298" transform="translate(3.003 1.804)">
      <g id="Group_33297" data-name="Group 33297" transform="translate(0 0)" clip-path="url(#clip-path)">
        <path
          id="Path_22408"
          data-name="Path 22408"
          d="M10.8,0A3.482,3.482,0,1,0,14.28,3.482,3.482,3.482,0,0,0,10.8,0m0,5.5a2.019,2.019,0,1,1,2.019-2.019A2.022,2.022,0,0,1,10.8,5.5"
          transform="translate(-3.75 0)"
        />
        <path
          id="Path_22409"
          data-name="Path 22409"
          d="M22.55,18.144a2.5,2.5,0,0,1,1.4-4.206,2.567,2.567,0,0,1,.4-.031,2.5,2.5,0,0,1,1.164.287,2.491,2.491,0,0,1,1.163-.287,2.559,2.559,0,0,1,.4.031,2.5,2.5,0,0,1,1.416,4.19l-2.978,3Zm1.8-3.013a1.326,1.326,0,0,0-.209.017,1.276,1.276,0,0,0-.717,2.138l2.09,2.106,2.094-2.11a1.276,1.276,0,0,0-.722-2.134,1.322,1.322,0,0,0-.209-.017,1.274,1.274,0,0,0-.976.456l-.187.222-.187-.221a1.274,1.274,0,0,0-.977-.456"
          transform="translate(-11.198 -7.127)"
        />
        <path
          id="Path_22410"
          data-name="Path 22410"
          d="M2.526,20.509a1.033,1.033,0,0,1-1.011-.754.965.965,0,0,1,.379-1.133A9.027,9.027,0,0,1,7.049,17.01a8.839,8.839,0,0,1,1.552.139.729.729,0,1,0,.26-1.435,10.142,10.142,0,0,0-1.812-.166,10.5,10.5,0,0,0-5.986,1.871,2.509,2.509,0,0,0,1.463,4.552h8.153a.731.731,0,1,0,0-1.462Z"
          transform="translate(0 -7.969)"
        />
      </g>
    </g>
    <g id="Group_33300" data-name="Group 33300">
      <g id="Group_33299" data-name="Group 33299" clip-path="url(#clip-path-2)">
        <path
          id="Path_22411"
          data-name="Path 22411"
          d="M16.441,1.9l-.267,1.051a2.035,2.035,0,0,1-1.485,1.475l-1.027.253.948.216a2.035,2.035,0,0,1,1.505,1.425l.326,1.139.222-1.035a2.035,2.035,0,0,1,1.629-1.576l.928-.167L18.185,4.41a2.035,2.035,0,0,1-1.465-1.455Z"
          transform="translate(-13.662 -1.55)"
        />
        <path
          id="Path_22413"
          data-name="Path 22413"
          d="M14.647,24.11l-.095.373a.722.722,0,0,1-.527.523l-.364.09.336.076a.721.721,0,0,1,.534.505l.116.4.079-.367a.722.722,0,0,1,.578-.559l.329-.059L15.266,25a.721.721,0,0,1-.52-.516Z"
          transform="translate(-10.075 -19.72)"
        />
      </g>
    </g>
    <path
      id="Path_22414"
      data-name="Path 22414"
      d="M1.2,0,1.087.455a.88.88,0,0,1-.642.638L0,1.2.41,1.3a.88.88,0,0,1,.651.616L1.2,2.4l.1-.448A.88.88,0,0,1,2,1.274L2.4,1.2l-.448-.114A.88.88,0,0,1,1.323.459Z"
      transform="translate(15.214 3.559)"
    />
  </svg>
</template>
