<template>
  <svg
    id="Group_32170"
    data-name="Group 32170"
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    viewBox="0 0 13 12"
  >
    <path
      id="Subtraction_248"
      data-name="Subtraction 248"
      d="M13,4H0V1A.942.942,0,0,1,.867,0h5.2C6.37,0,7.337,1.056,8,2h4a1,1,0,0,1,1,1V4Z"
      transform="translate(0 0)"
    />
    <rect
      id="Rectangle_14076"
      data-name="Rectangle 14076"
      width="13"
      height="7"
      rx="1"
      transform="translate(0 5)"
    />
  </svg>
</template>
