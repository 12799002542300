<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="125.949" height="114.193" viewBox="0 0 125.949 114.193">
    <g id="Group_53825" data-name="Group 53825" transform="translate(15954.527 17842)">
      <path
        id="Union_162"
        data-name="Union 162"
        d="M2,104.194a2,2,0,0,1-2-2v-17H97.526V29.7h16.422a2,2,0,0,1,2,2v70.493a2,2,0,0,1-2,2ZM37.692.193H36.511a1.006,1.006,0,0,1,1.182,0l0,0Z"
        transform="translate(-15838.578 -17737.807) rotate(180)"
        fill="#3a83f6"
      />
      <g id="Group_53826" data-name="Group 53826" transform="translate(-15944.525 -17832)">
        <g id="Group_37623" data-name="Group 37623" transform="translate(0 0)">
          <path
            id="Subtraction_11"
            data-name="Subtraction 11"
            d="M37.1,104.193a.991.991,0,0,1-.862-.492L19.066,74.493H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H113.948a2,2,0,0,1,2,2V72.493a2,2,0,0,1-2,2H55.14L37.966,103.7A.991.991,0,0,1,37.1,104.193ZM10.275,8.764a1,1,0,0,0-1,1V64.729a1,1,0,0,0,1,1H24.22L37.1,87.639,49.986,65.73h55.686a1,1,0,0,0,1-1V9.764a1,1,0,0,0-1-1Z"
            transform="translate(0 0)"
            fill="#16294b"
          />
          <ellipse
            id="Ellipse_35"
            data-name="Ellipse 35"
            cx="6.387"
            cy="6.387"
            rx="6.387"
            ry="6.387"
            transform="translate(25.546 30.028)"
            fill="#3a83f6"
          />
          <ellipse
            id="Ellipse_36"
            data-name="Ellipse 36"
            cx="6.387"
            cy="6.387"
            rx="6.387"
            ry="6.387"
            transform="translate(51.092 30.028)"
            fill="#3a83f6"
          />
          <ellipse
            id="Ellipse_37"
            data-name="Ellipse 37"
            cx="6.387"
            cy="6.387"
            rx="6.387"
            ry="6.387"
            transform="translate(76.638 30.028)"
            fill="#3a83f6"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
