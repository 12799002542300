<template>
  <svg id="Group_116017" data-name="Group 116017" xmlns="http://www.w3.org/2000/svg" width="27.51" height="33.012" viewBox="0 0 27.51 33.012">
    <g id="Group_109725" data-name="Group 109725" transform="translate(0)">
      <g id="Group_102139" data-name="Group 102139">
        <path
          id="Path_306579"
          data-name="Path 306579"
          d="M749.383,920.587v-1.959a9.628,9.628,0,1,0-19.257,0v1.959A6.874,6.874,0,0,0,726,926.881v8.253a6.884,6.884,0,0,0,6.877,6.878h13.755a6.885,6.885,0,0,0,6.878-6.878v-8.253a6.881,6.881,0,0,0-4.127-6.294Zm-16.506-1.959a6.877,6.877,0,1,1,13.755,0V920H732.877Zm17.881,16.506a4.128,4.128,0,0,1-4.127,4.126H732.877a4.128,4.128,0,0,1-4.126-4.126v-8.253a4.128,4.128,0,0,1,4.126-4.127h13.755a4.128,4.128,0,0,1,4.127,4.127Z"
          transform="translate(-726 -909)"
        />
        <path
          id="Path_306580"
          data-name="Path 306580"
          d="M736.375,923A1.376,1.376,0,0,0,735,924.376v2.751a1.375,1.375,0,0,0,2.751,0v-2.751A1.377,1.377,0,0,0,736.375,923Z"
          transform="translate(-722.621 -903.743)"
        />
      </g>
    </g>
  </svg>
</template>
