<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="11.22" height="14.23" viewBox="0 0 11.22 14.23">
    <path
      id="Path_24336"
      data-name="Path 24336"
      d="M49.08,567.066c.053.084.1.127.2.065a3.336,3.336,0,0,1,3.315-.119.2.2,0,0,0,.28-.048c.57-.579,1.147-1.151,1.722-1.726.025-.025.048-.052.1-.1-.067,0-.106,0-.146,0-.588.023-1.176.047-1.764.067-.244.009-.306-.05-.316-.294-.007-.16-.015-.32-.019-.48,0-.184.073-.265.256-.271l2.04-.072,1.512-.056c.22-.008.3.073.288.294q-.065,1.764-.13,3.528c-.008.214-.083.284-.3.275-.164-.007-.328-.009-.492-.017-.182-.009-.258-.093-.252-.276q.031-.888.064-1.776c0-.047,0-.094,0-.13l-1.831,1.844c.105.148.24.32.357.5a3.409,3.409,0,0,1-1.221,4.739,3.568,3.568,0,0,1-1.043.384c-.061.012-.1.025-.1.1,0,.5,0,1,0,1.5a.417.417,0,0,0,.012.062h1.822c.226,0,.293.067.293.293,0,.16,0,.32,0,.48,0,.194-.074.269-.268.269-.56,0-1.121,0-1.682,0h-.172c0,.057-.007.107-.007.157q0,.847,0,1.694c0,.236-.063.3-.3.3h-.456c-.22,0-.289-.068-.289-.285v-1.859c-.061,0-.111-.008-.161-.008H48.689c-.21,0-.282-.071-.283-.28,0-.156,0-.312,0-.468,0-.227.068-.3.292-.3h1.848c0-.042.01-.076.01-.11,0-.473,0-.945,0-1.417,0-.087-.028-.115-.11-.131a3.413,3.413,0,0,1-2.128-5.409c.035-.049.13-.1-.025-.191-.064.077-.13.168-.208.247-.206.213-.417.421-.627.629-.151.15-.247.148-.4,0-.12-.118-.239-.236-.356-.357a.219.219,0,0,1,0-.363c.256-.259.515-.515.772-.774.036-.036.07-.074.1-.1l-1.145-1.151c0,.055,0,.1,0,.145.012.472.026.945.037,1.417,0,.188-.045.248-.231.287-.153.032-.305.066-.46.091a.219.219,0,0,1-.284-.235q-.082-1.649-.162-3.3c-.009-.194.078-.278.275-.27l3.216.134a1.42,1.42,0,0,1,.155.013.2.2,0,0,1,.188.245c-.031.189-.069.378-.114.564-.03.124-.129.162-.25.159q-.738-.014-1.477-.025H47.2l1.172,1.164c.08-.089.172-.2.272-.3.2-.2.395-.4.593-.6a.229.229,0,0,1,.373,0l.339.34c.165.165.165.263,0,.424q-.4.4-.8.8C49.127,567.012,49.1,567.039,49.08,567.066Zm4.341,2.984a2.374,2.374,0,1,0-2.375,2.364A2.374,2.374,0,0,0,53.421,570.05Z"
      transform="translate(-45.328 -564.026)"
      fill="#c3c3c3"
    />
  </svg>
</template>
