export const PATH = {
  SYSTEM: {
    COMMU: { SMS: '_system/commu/sms/template', LINE: '_system/commu/line/template', EDM: '_system/commu/edm/template' },
  },
  COMMU: {
    SMS: 'commu/sms/',
    LINE: 'commu/line/',
    EDM: 'commu/edm/',
  },
};
