<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.911" height="17.233" viewBox="0 0 17.911 17.233">
    <path
      id="Path_22780"
      data-name="Path 22780"
      d="M33.695,5.849a8.616,8.616,0,0,0-7.57,10.878,6.227,6.227,0,0,1,.109,3.116,5.023,5.023,0,0,1-1.022,2.091.3.3,0,0,0,.265.5,13.52,13.52,0,0,0,3.5-.971.553.553,0,0,1,.534.052,8.574,8.574,0,0,0,4.715,1.538,8.617,8.617,0,1,0-.534-17.2m.733,15.532h0l-.16,0a6.892,6.892,0,0,1-4-1.383l-.432-.324a.765.765,0,0,0-.779-.083l-.49.226q-.275.126-.543.232a.079.079,0,0,1-.106-.09,7.983,7.983,0,0,0-.186-3.683,6.946,6.946,0,0,1,6.1-8.76c.2-.017.4-.025.6-.025a6.947,6.947,0,0,1,6.946,7.052,6.976,6.976,0,0,1-6.95,6.84"
      transform="translate(-25.137 -5.818)"
    />
  </svg>
</template>
