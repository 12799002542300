import { reactive, getCurrentInstance } from 'vue';
import router from '@/router';

interface ModalPermission {
  isOpenModal: boolean;
  isOpenResendModal: boolean;
  isOpenCancelModal: boolean;
  isOpenDeleteModal: boolean;
  role: any[] | null;
  roleKey: string | null;
}

export default function usePermissionModal() {
  //modal state
  const modalOpen: ModalPermission = reactive({
    isOpenModal: false,
    isOpenResendModal: false,
    isOpenCancelModal: false,
    isOpenDeleteModal: false,
    role: [],
    roleKey: '',
  });

  const openRoleModal = (role: any[] | null, rolekey: string | null) => {
    modalOpen.isOpenModal = true;
    modalOpen.role = role;
    modalOpen.roleKey = rolekey;
  };

  const vm = getCurrentInstance()?.proxy;

  const onCloseModal = () => {
    vm?.$emit('close-modal');
  };

  const onClickToHome = () => {
    sessionStorage.removeItem('newPassword');
    router.push('/analytics/dashboard');
  };

  return {
    modalOpen,
    onCloseModal,
    onClickToHome,
    openRoleModal,
  };
}
