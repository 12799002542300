export default [
  {
    title: 'main_menu.communication',
    customIcon: 'CommunicationIcon',
    // path: '/communication',
    children: [
      {
        title: 'main_menu.communication_all_channel',
        route: 'All Channel',
        path: '/communication',
      },
      {
        title: 'main_menu.communication_limitation',
        route: 'Limitation',
        path: '/communication/limitation',
      },
    ],
  },
];
