import customerInsightTab from '@models/customer-insight/customer-insight-tab';
import customerInsightLayout from '@models/customer-insight/customer-insight-layout';
import customerInsightChart from '@models/customer-insight/customer-insight-chart';
import customerInsightConfig from '@models/customer-insight/customer-insight-config';
import customerInsightActivities from './customer-insight-activities';
import customerInsightCustomerInfo from './customer-insight-info';

export default function () {
  const {
    fetchCustomerInsightTabsModel,
    fetchCustomerInsightTabsReorderModel,
    fetchCustomerInsightTabsDeleteModel,
    fetchCustomerInsightTabsCreateModel,
    fetchCustomerInsightTabsEditModel,
  } = customerInsightTab();
  const { fetchCustomerInsightLayoutModel, fetchCustomerInsightDeleteLayoutModel, fetchCustomerInsightAdjustLayoutModel } = customerInsightLayout();
  const {
    fetchCustomerInsightChartGlobalFilterListModel,
    fetchChartSetListModel,
    fetchCustomerInsightChartSettingEditModel,
    fetchCustomerInsightChartDataInfoModel,
    fetchChartAddModel,
    fetchCustomerInsightChartSettingInfoModel,
    fetchCustomerInsightChartDataModel,
    fetchCustomerInsightChartExportDataInfoModel,
  } = customerInsightChart();

  const { fetchCustomerInsightConfigCreate, fetchCustomerInsightConfigSave, fetchCustomerInsightConfigCancel } = customerInsightConfig();

  const {
    fetchCustomerInsightActivityFiltersModel,
    fetchCustomerInsightTransactionsListModel,
    fetchCustomerInsightTransactionsInfoModel,
    fetchCustomerInsightTransactionsExportModel,
  } = customerInsightActivities();

  const {
    fetchCustomerInsightCInfoListPreview,
    fetchCustomerInsightCInfoById,
    fetchCustomerInsightInfoInitialColumn,
    fetchCustomerInsightInfoListProperties,
    fetchCustomerInsightInfoPDPAHistory,
    fetchCustomerInsightInfoSavePropertiesListAll,
    fetchCustomerInsightInfoCancelSettingProperties,
    fetchCustomerInsightInfoGetListPropertiesByGroup,
    fetchCustomerInsightInfoSavePropertiesByGroup,
    fetchCustomerInsightInfoAddSection,
    fetchCustomerInsightInfoCopySection,
    fetchCustomerInsightInfoDeleteSection,
    fetchCustomerInsightInfoEditSection,
    fetchCustomerInsightInfoDuplicationPropertiesListAll,
    fetchCustomerInsightInfoSavePropertiesListSortByGroup,
  } = customerInsightCustomerInfo();

  return {
    fetchCustomerInsightTabsModel,
    fetchCustomerInsightTabsReorderModel,
    fetchCustomerInsightTabsCreateModel,
    fetchCustomerInsightTabsEditModel,
    fetchCustomerInsightTabsDeleteModel,
    fetchCustomerInsightLayoutModel,
    fetchCustomerInsightAdjustLayoutModel,
    fetchCustomerInsightDeleteLayoutModel,
    fetchChartSetListModel,
    fetchChartAddModel,
    fetchCustomerInsightChartDataModel,
    fetchCustomerInsightChartSettingInfoModel,
    fetchCustomerInsightChartSettingEditModel,
    fetchCustomerInsightChartDataInfoModel,
    fetchCustomerInsightChartGlobalFilterListModel,
    fetchCustomerInsightChartExportDataInfoModel,
    fetchCustomerInsightConfigCreate,
    fetchCustomerInsightConfigSave,
    fetchCustomerInsightConfigCancel,
    fetchCustomerInsightActivityFiltersModel,
    fetchCustomerInsightTransactionsListModel,
    fetchCustomerInsightTransactionsExportModel,
    fetchCustomerInsightTransactionsInfoModel,
    fetchCustomerInsightCInfoListPreview,
    fetchCustomerInsightCInfoById,
    fetchCustomerInsightInfoInitialColumn,
    fetchCustomerInsightInfoListProperties,
    fetchCustomerInsightInfoPDPAHistory,
    fetchCustomerInsightInfoSavePropertiesListAll,
    fetchCustomerInsightInfoCancelSettingProperties,
    fetchCustomerInsightInfoGetListPropertiesByGroup,
    fetchCustomerInsightInfoSavePropertiesByGroup,
    fetchCustomerInsightInfoAddSection,
    fetchCustomerInsightInfoCopySection,
    fetchCustomerInsightInfoDeleteSection,
    fetchCustomerInsightInfoEditSection,
    fetchCustomerInsightInfoDuplicationPropertiesListAll,
    fetchCustomerInsightInfoSavePropertiesListSortByGroup,
  };
}
