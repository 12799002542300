<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24.745" height="24.744" viewBox="0 0 24.745 24.744">
    <g transform="translate(2541.314 3886.666)">
      <rect width="2.474" height="9.898" rx="1.237" transform="translate(-2533.065 -3879.243)" />
      <rect width="2.474" height="9.898" rx="1.237" transform="translate(-2527.292 -3879.243)" />
      <path
        d="M12.372,24.744A12.372,12.372,0,0,1,3.624,3.624a12.372,12.372,0,1,1,17.5,17.5A12.292,12.292,0,0,1,12.372,24.744Zm0-22.27a9.9,9.9,0,0,0-7,16.9,9.9,9.9,0,0,0,14-14A9.833,9.833,0,0,0,12.372,2.474Z"
        transform="translate(-2541.314 -3886.666)"
      />
    </g>
  </svg>
</template>
