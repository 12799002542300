<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16.671" height="14.388" viewBox="0 0 16.671 14.388">
    <path
      id="Path_29329"
      data-name="Path 29329"
      d="M6377.116-14771.905l3.058,3.264,4.441-4.739,6.345-6.772"
      transform="translate(-6375.703 14781.566)"
      fill="none"
      stroke="#5aaf1a"
      stroke-linecap="round"
      stroke-width="2"
    />
  </svg>
</template>
