<template>
  <div class="landing-container">
    <div class="font-welcome">Welcome to CDP Platform !</div>
    <div class="content-img-welcome">
      <img
        class="img-welcome"
        src="https://cdn.discordapp.com/attachments/831384214887989278/893418388729233428/Vector-Welcome_2.png"
        alt=""
        draggable="false"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@assets/styles/modules/landing/landing-page.scss';
</style>
