import { ref, Ref } from 'vue';

import router from '@/router';

export default function () {
  const modal: Ref<boolean> = ref(false);

  function onClose() {
    modal.value = false;
  }

  function onClick(row: any) {
    if (row.hasOwnProperty('cid') && (row.cid !== null || row.cid !== undefined)) {
      router.push({ name: 'customerProfileDashboard', params: { cId: row.cid, pageId: 1 } });
    } else if (row.hasOwnProperty('cdp_cid') && (row.cdp_cid !== null || row.cdp_cid !== undefined)) {
      router.push({ name: 'customerProfileDashboard', params: { cId: row.cdp_cid, pageId: 1 } });
    }
  }

  return { modal, onClick, onClose };
}
