<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 22 22">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_42388" data-name="Rectangle 42388" width="22" height="22" transform="translate(0.342 0)" fill="#007fff" />
      </clipPath>
    </defs>
    <g id="Group_91664" data-name="Group 91664" transform="translate(-0.342 0.255)">
      <g id="Group_91663" data-name="Group 91663" transform="translate(0 -0.255)" clip-path="url(#clip-path)">
        <path
          id="Path_296970"
          data-name="Path 296970"
          d="M20.978,10.68a1.145,1.145,0,0,0-1.144,1.144v6.268a1.741,1.741,0,0,1-1.742,1.742H4.031a1.742,1.742,0,0,1-1.742-1.742V4.031A1.742,1.742,0,0,1,4.031,2.289h6.506a1.144,1.144,0,0,0,0-2.289H4.031A4.031,4.031,0,0,0,0,4.031V18.093a4.03,4.03,0,0,0,4.03,4.03H18.092a4.031,4.031,0,0,0,4.031-4.031V11.824a1.145,1.145,0,0,0-1.144-1.144"
          transform="translate(0 0.219)"
          fill="fillColor"
        />
        <path
          id="Path_296971"
          data-name="Path 296971"
          d="M22.608,0H18.629a1.144,1.144,0,1,0,0,2.289h3.125l-7.2,7.2a1.144,1.144,0,0,0,1.618,1.619l7.035-7.035V6.41a1.144,1.144,0,1,0,2.289,0V2.887A2.886,2.886,0,0,0,22.608,0"
          transform="translate(-3.372 0.219)"
          fill="fillColor"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: '#007fff',
    },
  },
};
</script>
