<template>
  <svg width="7" height="29" viewBox="0 0 7 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 7C5.433 7 7 5.433 7 3.5C7 1.567 5.433 0 3.5 0C1.567 0 0 1.567 0 3.5C0 5.433 1.567 7 3.5 7Z" fill="white" />
    <path
      d="M3.5 5.5C4.60457 5.5 5.5 4.60457 5.5 3.5C5.5 2.39543 4.60457 1.5 3.5 1.5C2.39543 1.5 1.5 2.39543 1.5 3.5C1.5 4.60457 2.39543 5.5 3.5 5.5Z"
      stroke="#707070"
      stroke-width="3"
    />
    <path d="M3.5 18C5.433 18 7 16.433 7 14.5C7 12.567 5.433 11 3.5 11C1.567 11 0 12.567 0 14.5C0 16.433 1.567 18 3.5 18Z" fill="white" />
    <path
      d="M3.5 16.5C4.60457 16.5 5.5 15.6046 5.5 14.5C5.5 13.3954 4.60457 12.5 3.5 12.5C2.39543 12.5 1.5 13.3954 1.5 14.5C1.5 15.6046 2.39543 16.5 3.5 16.5Z"
      stroke="#707070"
      stroke-width="3"
    />
    <path d="M3.5 29C5.433 29 7 27.433 7 25.5C7 23.567 5.433 22 3.5 22C1.567 22 0 23.567 0 25.5C0 27.433 1.567 29 3.5 29Z" fill="white" />
    <path
      d="M3.5 27.5C4.60457 27.5 5.5 26.6046 5.5 25.5C5.5 24.3954 4.60457 23.5 3.5 23.5C2.39543 23.5 1.5 24.3954 1.5 25.5C1.5 26.6046 2.39543 27.5 3.5 27.5Z"
      stroke="#707070"
      stroke-width="3"
    />
  </svg>
</template>
