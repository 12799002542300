import { onBeforeMount, ref, watch, reactive, Ref, onMounted } from 'vue';

import router from '@/router';
import api from '@services/api';

import dataConnectModel from '@models/dataConnect/dataConnect';

export default function useAddDataSourcePage() {
  const { fetchAddDataSourceListModel } = dataConnectModel();

  const newDataConnect: any = () => {
    router.push({ path: `/dataConnector/addDataSource` });
  };
  const newCommuConnect: any = () => {};

  const search: any = ref('');
  const dataSourcePanelList: any = ref([]);

  const detailId: any = ref(0);
  const detailTitle: any = ref('');
  const detailIcon: any = ref('');
  const detailIconBGColor: any = ref('');
  const detailWebsite: any = ref('');
  const detailWebsiteUrl: any = ref('');
  const detailContent: any = ref('');
  const detailGoto: any = ref('');
  const detailIsEnable: Ref<boolean> = ref(false);
  const detailFrontendKey: Ref<string> = ref('');

  const dataSourceDetail: any = ref(false);

  const dataConnectFh5: Ref<boolean> = ref(false);
  const dataSourceFh5ContactUs: any = ref({
    phone: '063-901-1531',
    mail: 'info@chococrm.com',
    address: '2150, 4 ถ. สุขุมวิท แขวงบางจาก<br />เขตพระโขนง กรุงเทพมหานคร 10260',
  });

  const linkSocial: any = ref({
    web: 'https://www.chococrm.com/',
    facebook: 'https://www.facebook.com/chococrmofficial',
    ig: 'https://www.instagram.com/chococard/',
    youtube: 'https://www.youtube.com/@chocoofficial1848',
    tiktok: 'https://www.tiktok.com/@chococardcrm',
    line: 'https://page.line.me/chococrm?openQrModal=true',
  });

  function Fh5ContactUsAddline() {
    window.open(linkSocial.value.line, '_blank');
  }

  const dataSourceConnect = (dataSource: any) => {
    detailId.value = dataSource.id ? dataSource.id : 0;
    detailTitle.value = dataSource.title ? dataSource.title : '';
    detailIcon.value = dataSource.icon ? dataSource.icon : '';
    detailIconBGColor.value = dataSource.iconBGColor ? dataSource.iconBGColor : '';
    detailWebsite.value = dataSource.website ? dataSource.website : '';
    detailWebsiteUrl.value = dataSource.websiteUrl ? dataSource.websiteUrl : '';
    detailContent.value = dataSource.fullDetail ? dataSource.fullDetail : '';
    detailGoto.value = dataSource.goto ? dataSource.goto : '';
    detailIsEnable.value = dataSource.isenable ? dataSource.isenable : '';
    detailFrontendKey.value = dataSource.frontend_key ? dataSource.frontend_key : '';
    dataSourceDetail.value = true;
  };

  const dataSourceDetailOpen = () => {
    dataSourceDetail.value = true;
  };

  const dataSourceDetailClose = () => {
    dataSourceDetail.value = false;
  };

  const searchInput = (e: any) => {
    search.value = e.currentTarget.value;
  };

  const dataSourceRender = () => {
    return JSON.parse(JSON.stringify(dataSourcePanelList.value))
      .map((dataSourcePanel: any) => {
        dataSourcePanel.dataSourceList = dataSourcePanel.dataSourceList.filter((dataSource: any) => {
          if (search.value == null || search.value == '') {
            return true;
          } else {
            return (dataSource.title.toLowerCase() + dataSource.detail.toLowerCase()).indexOf(search.value.toLowerCase()) != -1;
          }
        });
        return dataSourcePanel;
      })
      .filter((dataSourcePanel: any) => {
        return dataSourcePanel.dataSourceList.length > 0;
      });
  };

  const fetchAddDataSourceListMapping = (Data: any) => {
    let DataReturn = Data.payload.map((dataSourceCategory: any) => {
      let dataSourceCategoryReturn = {
        title: dataSourceCategory.title,
        detail: dataSourceCategory.detail,
        dataSourceList: [],
      };

      dataSourceCategoryReturn.dataSourceList = dataSourceCategory.list_datasource.map((dataSource: any) => {
        let dataSourceReturn = {
          id: dataSource.datasource_id,
          icon: dataSource.icon,
          iconBGColor: { backgroundColor: dataSource.background_color },
          title: dataSource.title,
          website: dataSource.website_Name,
          websiteUrl: dataSource.website_Url,
          detail: dataSource.detail,
          fullDetail: dataSource.detail,
          goto: dataSource.goto,
          isenable: dataSource.isenable,
          frontend_key: dataSource.frontend_key,
        };
        return dataSourceReturn;
      });
      return dataSourceCategoryReturn;
    });
    return DataReturn;
  };

  const fetchDataSourceList = () => {
    api.apiRequest(fetchAddDataSourceListModel).then((response) => {
      dataSourcePanelList.value = fetchAddDataSourceListMapping(response.data);
    });
  };

  const detailStepNext = () => {
    dataSourceDetail.value = false;
    if (detailGoto.value.indexOf('http') != -1) {
      window.open(detailGoto.value, '_blank');
    } else if (detailGoto.value != null && detailGoto.value != '') {
      localStorage.setItem('DataSourceId', detailId.value);
      router.push({ path: detailGoto.value });
    }
  };

  function detailStepContactUs() {
    dataConnectFh5.value = true;
  }

  function dataConnectFh5StepNext() {
    dataSourceDetail.value = false;
    dataConnectFh5.value = false;
  }

  function detailStepGoToSetting() {
    detailStepNext();
  }

  onMounted(() => {
    fetchDataSourceList();
  });

  return {
    dataSourcePanelList,
    dataSourceRender,
    searchInput,
    search,
    dataSourceDetail,
    dataSourceDetailClose,
    dataSourceDetailOpen,
    dataSourceConnect,
    detailId,
    detailTitle,
    detailIcon,
    detailIconBGColor,
    detailWebsite,
    detailWebsiteUrl,
    detailContent,
    detailGoto,
    detailStepNext,
    dataConnectFh5,
    dataConnectFh5StepNext,
    detailIsEnable,
    detailFrontendKey,
    detailStepContactUs,
    detailStepGoToSetting,
    dataSourceFh5ContactUs,
    Fh5ContactUsAddline,
    linkSocial,
  };
}
