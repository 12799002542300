<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="23.307" height="24.217" viewBox="0 0 23.307 24.217">
    <g id="Group_74" data-name="Group 74" transform="matrix(0.978, 0.208, -0.208, 0.978, 4.288, 0)">
      <g id="Group_74-2" data-name="Group 74">
        <path
          id="Path_28"
          data-name="Path 28"
          d="M7.036,20.626A1.228,1.228,0,0,1,5.808,19.4v-4a.344.344,0,0,0-.2-.314l-4.457-2A1.947,1.947,0,0,1,.943,9.638L16.491.284a1.919,1.919,0,0,1,1-.284,1.969,1.969,0,0,1,1.47.669,1.909,1.909,0,0,1,.466,1.516l-1.872,15.5A1.949,1.949,0,0,1,15.623,19.4h0a1.937,1.937,0,0,1-.8-.173l-3.378-1.519a.344.344,0,0,0-.354.043l-3.31,2.605a1.2,1.2,0,0,1-.748.264m.631-4.58a.344.344,0,0,0-.344.344v2.428a.344.344,0,0,0,.557.27l1.963-1.545a.344.344,0,0,0-.072-.584l-1.963-.883a.344.344,0,0,0-.141-.03M17.2,4.788a.344.344,0,0,0-.243.1L7.9,13.946A.344.344,0,0,0,8,14.5l7.444,3.347a.424.424,0,0,0,.177.04.434.434,0,0,0,.43-.383L17.542,5.174a.344.344,0,0,0-.342-.385m-.52-2.794a.342.342,0,0,0-.177.049L1.723,10.936a.432.432,0,0,0,.046.765l3.973,1.787a.344.344,0,0,0,.384-.07L16.617,2.926l.014-.014.306-.344a.344.344,0,0,0-.257-.573"
          transform="translate(0.001 0)"
        />
      </g>
    </g>
  </svg>
</template>
