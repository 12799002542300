<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="23.354" height="15.726" viewBox="0 0 23.354 15.726">
    <defs>
      <clipPath id="clip-path">
        <rect width="23.354" height="15.726" />
      </clipPath>
    </defs>
    <g transform="translate(0 0)">
      <g transform="translate(0 0)" clip-path="url(#clip-path)">
        <path
          d="M22.583,6.765C22.389,6.489,17.76,0,11.678,0S.966,6.489.772,6.765L0,7.864l.772,1.1c.194.277,4.823,6.765,10.906,6.765S22.389,9.239,22.583,8.962l.771-1.1ZM11.678,13.816c-5.159,0-9.341-5.953-9.341-5.953s4.182-5.953,9.341-5.953,9.341,5.953,9.341,5.953-4.182,5.953-9.341,5.953"
          transform="translate(0 0)"
        />
        <path
          d="M10.163,3.347a3.729,3.729,0,1,0,3.729,3.729,3.728,3.728,0,0,0-3.729-3.729M11.58,7.864A1.566,1.566,0,1,1,13.145,6.3,1.565,1.565,0,0,1,11.58,7.864"
          transform="translate(1.515 0.788)"
        />
      </g>
    </g>
  </svg>
</template>
