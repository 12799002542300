<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 79.56 45.59">
    <defs></defs>
    <path
      id="Path_29221"
      class="cls-1"
      d="M77.18,9.49l-15.44,3.37v-3.26C61.75,4.3,57.45,0,52.15,0H9.6C4.3,0,0,4.3,0,9.6v26.38c0,5.3,4.3,9.6,9.6,9.6H52.14c5.3,0,9.6-4.3,9.6-9.6v-3.13l15.45,3.25c1.06,.22,2.09-.46,2.32-1.51,.03-.13,.04-.27,.04-.4V11.4c0-1.08-.87-1.95-1.95-1.96-.14,0-.28,.01-.42,.04Zm-35.74,15.1l-14.54,8.76c-.99,.6-2.27,.28-2.86-.71-.2-.33-.3-.7-.3-1.08V14.04c0-1.15,.93-2.09,2.08-2.09,.38,0,.75,.1,1.08,.3l14.54,8.76c.99,.59,1.31,1.87,.72,2.86-.18,.29-.42,.54-.72,.72Z"
    />
  </svg>
</template>
