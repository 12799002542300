export type GenderAndAge = '1-male' | '2-female' | '3-not-specified' | '1-child' | '2-teen' | '3-late-teen' | '4-working' | '5-old';

export const GENDER_AND_AGE = {
  MALE: '1-male',
  FEMALE: '2-female',
  NOT_SPECIFIED: '3-not-specified',
  CHILD: '1-child',
  TEEN: '2-teen',
  LATE_TEEN: '3-late-teen',
  WORKING: '4-working',
  OLD: '5-old',
  NULL_DATA: 'null',
};

export function resolveGenderTypeTh(type: string, options?: any): string {
  if (options && Object.keys(options as object).length !== 0) {
    return options[type];
  } else {
    switch (type) {
      case GENDER_AND_AGE.FEMALE:
        return 'หญิง';
      case GENDER_AND_AGE.MALE:
        return 'ชาย';
      case GENDER_AND_AGE.NOT_SPECIFIED:
        return 'ไม่ระบุ';
      case GENDER_AND_AGE.CHILD:
        return 'เด็ก (0-10ปี)';
      case GENDER_AND_AGE.TEEN:
        return 'วัยรุ่น (11-20ปี)';
      case GENDER_AND_AGE.LATE_TEEN:
        return 'วัยรุ่นตอนปลาย (21-30ปี)';
      case GENDER_AND_AGE.WORKING:
        return 'วัยทำงาน (31-60ปี)';
      case GENDER_AND_AGE.OLD:
        return 'วัยชรา (61ปีขึ้นไป)';
      default:
        return 'ไม่พบ';
    }
  }
}

export const DEFUALT_TOTAL_SUFFIX_FROM_ITEM_INDEX = 0;
