import { computed, defineComponent, h, ref, unref, withKeys } from 'vue';
import { ChocoClickObserver } from '../click-observer';
import { ChocoTransitionContainer } from '../transition-container';

export interface ExposeMethods {
  show(): void;
  hide(): void;
}

// FIXME: popup toggle show/hide when click on input wrapper (should not be hide if we click on input wrapper, in case of user try to input via typing on input)

/**
 * Input popup
 *
 * Component for wrapping input that will show popup
 */
export default defineComponent({
  name: 'ChocoInputPopup',
  props: ['modelValue', 'placeholder'],
  emits: ['blur', 'enter', 'focus', 'hide', 'show', 'update:modelValue'],
  setup(props, { emit, slots, expose }) {
    // element references
    const $ = ref<HTMLElement>(null!);
    const input$ = ref<HTMLElement>(null!);
    const wrapper$ = ref<HTMLElement>(null!);

    // states
    const isShow = ref(false);

    // region popup positioning
    const topGap = 8; // TODO: top gap via props
    const topPosition = ref(0);
    const leftPosition = ref(0);

    const calculateTopPosition = () => {
      // get input height & viewport bounding top
      const input$Height = input$.value.clientHeight;
      const input$VwBoundingTop = input$.value.getBoundingClientRect().top;
      // get popup wrapper height
      const wrapper$Height = wrapper$.value.clientHeight;

      // if input+wrapper shown height exceed viewport height, and its height will not overflow
      // at the top of screen when show at the top of input$; show at the top of input$
      if (input$VwBoundingTop + input$Height + wrapper$Height > window.innerHeight && wrapper$Height + topGap < input$VwBoundingTop) {
        topPosition.value = -(wrapper$Height + topGap);
      }
      // else, show at the bottom of input$
      else {
        topPosition.value = input$Height + topGap;
      }
    };
    const calculateLeftPosition = () => {
      // get input width & viewport bounding left
      const input$Width = input$.value.clientWidth;
      const input$VwBoundingLeft = input$.value.getBoundingClientRect().left;
      // get popup wrapper width
      const wrapper$Width = wrapper$.value.clientWidth;

      // if input+wrapper shown width exceed viewport width, and its width will not overflow
      // at the left of screen when show at the right of input$; show at the right of input$
      if (input$VwBoundingLeft + wrapper$Width > window.innerWidth && wrapper$Width < input$VwBoundingLeft) {
        leftPosition.value = -(wrapper$Width - input$Width);
      }
      // else, show at the left of input$
      else {
        leftPosition.value = 0;
      }
    };
    // endregion

    // methods
    const show = () => {
      isShow.value = true;
      emit('show');
    };
    const hide = () => {
      isShow.value = false;
      emit('hide');
    };

    // events
    const onClickInput = () => show();
    const onClickOutside = (e: MouseEvent) => {
      // if click on input wrapper, do not hide
      // otherwise hide the popup
      // this workaround when user try to manually input the value
      if (!e.composedPath().includes(unref(input$))) {
        hide();
      }
    };

    // transition events
    const onBeforeAnimated = () => {
      calculateTopPosition();
      calculateLeftPosition();
    };

    // exposed
    expose(<ExposeMethods>{ show, hide });

    // slot props
    const inputProps = computed(() => ({
      // input props
      value: unref(props.modelValue),
      placeholder: unref(props.placeholder),
      // FIXME: remove events -> migrate to use-inputable
      onFocus: () => emit('focus'),
      onBlur: () => emit('blur'),
      onInput: (e: InputEvent) => emit('update:modelValue', (e.target as HTMLInputElement).value),
      onKeyup: withKeys((e: KeyboardEvent) => emit('enter', (e.target as HTMLInputElement).value), ['enter']),
    }));

    // render function
    return () =>
      // div.choco-input-popup
      h('div', { ref: $, class: ['choco-input-popup', isShow.value ? 'popup-show' : 'popup-hide'] }, [
        // TODO: add title
        // div.choco-input-popup__input-wrapper
        h(
          'div',
          { ref: input$, class: ['choco-input-popup__input-wrapper'], onClick: onClickInput },
          [
            // slot:input{ value } -- for customize input display (on UI)
            slots['input'] ? slots['input'](unref(inputProps)) : h('input', unref(inputProps)),
          ],
        ),
        // div.choco-input-popup__wrapper
        h(
          'div',
          {
            ref: wrapper$,
            class: ['choco-input-popup__wrapper'],
            style: { top: unref(topPosition) + 'px', left: unref(leftPosition) + 'px' },
          },
          [
            // ChocoTransitionContainer as div
            h(ChocoTransitionContainer, { name: 'choco-fade', onEnter: onBeforeAnimated }, () => [
              // v-if = show > ChocoClickObserver > slot:default -- for customize popup (on UI)
              isShow.value ? h(ChocoClickObserver, { onClickOutside }, () => [slots['default'] ? slots['default']({ hide }) : null]) : null,
            ]),
          ],
        ),
      ]);
  },
});
