<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0728 13.0501C20.0726 14.4778 19.649 15.8734 18.8557 17.0604C18.0624 18.2474 16.935 19.1725 15.6159 19.7188C14.2968 20.265 12.8454 20.4079 11.4452 20.1293C10.0449 19.8507 8.7587 19.1632 7.74917 18.1537C6.73963 17.1441 6.05211 15.8579 5.77353 14.4577C5.49495 13.0574 5.63783 11.606 6.18409 10.2869C6.73036 8.96789 7.65548 7.84043 8.84248 7.04712C10.0295 6.25381 11.4251 5.83028 12.8528 5.83008C14.7676 5.83008 16.6041 6.59075 17.9581 7.94477C19.3121 9.29878 20.0728 11.1352 20.0728 13.0501Z"
      fill="#029EFF"
    />
    <path
      d="M15.7969 10.0744V4.6394C15.7963 4.57654 15.8085 4.51422 15.8329 4.45626C15.8572 4.39831 15.8932 4.34594 15.9385 4.30239C15.9838 4.25884 16.0376 4.22502 16.0965 4.20301C16.1553 4.181 16.2181 4.17126 16.2809 4.1744C17.8005 4.28956 19.2287 4.94487 20.3071 6.02175C21.3854 7.09863 22.0427 8.52594 22.1599 10.0454C22.163 10.1081 22.1533 10.1708 22.1314 10.2296C22.1094 10.2884 22.0757 10.3421 22.0323 10.3874C21.9888 10.4328 21.9366 10.4687 21.8787 10.4931C21.8209 10.5175 21.7587 10.5299 21.6959 10.5294L16.2599 10.5374C16.1371 10.5374 16.0193 10.4886 15.9325 10.4018C15.8457 10.315 15.7969 10.1972 15.7969 10.0744Z"
      fill="#029EFF"
    />
    <path
      d="M13.3389 25.0095C11.0552 24.9916 8.81264 24.4005 6.81687 23.2905C5.03787 22.3221 3.57607 20.861 2.60687 19.0825C1.55444 17.2579 0.967426 15.2022 0.897664 13.097C0.827903 10.9918 1.27754 8.90174 2.20687 7.01146C3.11579 5.20347 4.45008 3.64318 6.09508 2.46465C7.74008 1.28612 9.64661 0.524577 11.6509 0.245461C11.9799 0.20104 12.3146 0.227781 12.6324 0.323875C12.9502 0.419969 13.2436 0.583176 13.4929 0.802461C13.7503 1.02656 13.9568 1.30311 14.0985 1.61356C14.2403 1.924 14.3141 2.26118 14.3149 2.60246L14.3209 11.1915C14.3209 11.2918 14.3607 11.3881 14.4315 11.4591C14.5024 11.5301 14.5985 11.5702 14.6989 11.5705H23.2869C23.6294 11.5705 23.968 11.6439 24.2799 11.7857C24.5917 11.9274 24.8696 12.1343 25.0949 12.3925C25.3141 12.6424 25.4773 12.9364 25.5735 13.2546C25.6697 13.5728 25.6968 13.9079 25.6529 14.2375C25.3753 16.2431 24.6145 18.1513 23.4358 19.7977C22.2572 21.444 20.6961 22.7793 18.8869 23.6885C17.1672 24.559 15.2664 25.0116 13.3389 25.0095ZM11.9639 2.22446C11.9499 2.22348 11.9359 2.22348 11.9219 2.22446C10.2384 2.45906 8.63707 3.09912 7.25571 4.08959C5.87435 5.08006 4.75429 6.39128 3.99187 7.91046C3.19838 9.50919 2.81663 11.2807 2.88124 13.0644C2.94585 14.848 3.45479 16.5873 4.36187 18.1245C5.14792 19.5654 6.33251 20.7493 7.77387 21.5345C9.31054 22.4409 11.0492 22.9493 12.8321 23.0138C14.615 23.0782 16.3858 22.6966 17.9839 21.9035C19.5031 21.1409 20.8144 20.0206 21.8048 18.6391C22.7953 17.2576 23.4353 15.6561 23.6699 13.9725C23.6753 13.9257 23.6707 13.8783 23.6564 13.8335C23.6421 13.7887 23.6184 13.7474 23.5869 13.7125C23.5496 13.6694 23.5035 13.6349 23.4518 13.6111C23.4 13.5873 23.3438 13.5748 23.2869 13.5745H14.6989C14.0686 13.5737 13.4644 13.323 13.0186 12.8774C12.5728 12.4319 12.3219 11.8277 12.3209 11.1975L12.3149 2.60746C12.3148 2.55067 12.3026 2.49454 12.2792 2.44281C12.2557 2.39109 12.2215 2.34494 12.1789 2.30746C12.1197 2.25446 12.0433 2.22494 11.9639 2.22446Z"
      fill="black"
    />
    <path
      d="M17.1463 10.2619C16.8387 10.2611 16.544 10.1385 16.3266 9.92091C16.1092 9.70332 15.9869 9.40848 15.9863 9.1009V2.2009C15.9868 2.0451 16.0182 1.89094 16.0788 1.74741C16.1394 1.60388 16.228 1.47385 16.3393 1.3649C16.4496 1.25522 16.5813 1.16934 16.7261 1.11258C16.8709 1.05582 17.0259 1.02938 17.1813 1.0349C19.2947 1.08484 21.3078 1.94595 22.8034 3.43978C24.2991 4.93361 25.1628 6.94563 25.2153 9.0589C25.219 9.21329 25.1917 9.36685 25.1352 9.51058C25.0787 9.6543 24.9941 9.78531 24.8863 9.8959C24.7787 10.0067 24.6502 10.0952 24.5083 10.1563C24.3664 10.2174 24.2138 10.2499 24.0593 10.2519L17.1463 10.2619ZM17.9833 3.1119V8.2609L23.1333 8.25289C22.9215 6.96504 22.3109 5.77613 21.3875 4.85378C20.4641 3.93143 19.2744 3.32217 17.9863 3.1119"
      fill="black"
    />
  </svg>
</template>
