<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="19.334"
    height="13.02"
    viewBox="0 0 19.334 13.02"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_15218"
          data-name="Rectangle 15218"
          width="19.334"
          height="13.02"
          transform="translate(0)"
        />
      </clipPath>
    </defs>
    <g id="Group_35456" data-name="Group 35456" transform="translate(-29.415 0)">
      <g id="Group_35396" data-name="Group 35396" transform="translate(29.415 0)" clip-path="url(#clip-path)">
        <path
          id="Path_22806"
          data-name="Path 22806"
          d="M18.7,5.6C18.536,5.372,14.7,0,9.668,0S.8,5.372.639,5.6L0,6.51l.639.909c.161.229,3.993,5.6,9.028,5.6s8.868-5.372,9.028-5.6l.638-.909ZM9.668,11.438C5.4,11.438,1.934,6.51,1.934,6.51S5.4,1.582,9.668,1.582,17.4,6.51,17.4,6.51s-3.462,4.928-7.734,4.928"
          transform="translate(0 0)"
        />
        <path
          id="Path_22807"
          data-name="Path 22807"
          d="M9.521,3.347a3.087,3.087,0,1,0,3.087,3.087A3.087,3.087,0,0,0,9.521,3.347m1.173,3.739a1.3,1.3,0,1,1,1.3-1.3,1.3,1.3,0,0,1-1.3,1.3"
          transform="translate(0.147 0.076)"
        />
      </g>
    </g>
  </svg>
</template>
