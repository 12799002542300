<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g id="Group_32742_00000120518911283488243530000002712021982219167906_" transform="translate(3.779 5.957)">
      <path
        id="Path_21379_00000056392226276907765440000008079284529046984852_"
        class="st8"
        d="M263.13,35.41H12.77
		c-1.31,0-2.38,1.07-2.38,2.38l0,0v195.97c0,1.31,1.07,2.38,2.38,2.38c0,0,0,0,0,0h250.36c1.31,0,2.38-1.07,2.38-2.38l0,0V37.79
		C265.51,36.47,264.45,35.41,263.13,35.41C263.13,35.41,263.13,35.41,263.13,35.41 M253.98,222.65c0,1.31-1.07,2.38-2.38,2.38
		c0,0,0,0,0,0H24.17c-1.31,0-2.38-1.07-2.38-2.38l0,0V92.63c0-1.31,1.07-2.38,2.38-2.38c0,0,0,0,0,0h227.43
		c1.31,0,2.38,1.07,2.38,2.38l0,0L253.98,222.65z M254.22,75.35c0,1.31-1.07,2.38-2.38,2.38c0,0,0,0,0,0H24.08
		c-1.31,0-2.38-1.07-2.38-2.38l0,0V49.33c-0.01-1.31,1.05-2.39,2.37-2.39c0,0,0.01,0,0.01,0h227.75c1.31,0,2.38,1.07,2.38,2.38
		c0,0,0,0.01,0,0.01V75.35z"
      />
      <path
        id="Path_21380_00000070836596004674526020000005659771612458517633_"
        class="st8"
        d="M117.61,120.63
		c-6.74-0.8-13.57-0.59-20.25,0.62c-14.25,3.03-25.2,14.47-27.6,28.84c-6.8,0-13.34,0-19.89,0c-4.18,0-8.65,0.15-8.82,5.57
		c-0.17,5.43,4.25,5.81,8.49,5.72c6.55-0.06,13.09,0,20.04,0c8.73,28.84,27.01,31.15,46.92,30.35c4.13-0.17,6.78-2.15,6.8-6.44
		c0.09-19.46,0.25-38.91-0.18-58.37c-0.63-2.92-2.73-5.3-5.54-6.31 M111.66,179.98c-10.66,2.38-21.65-2.28-27.33-11.61
		c-5.13-7.93-4.89-18.18,0.61-25.85c5.78-8.81,16.47-13.05,26.72-10.6V179.98z"
      />
      <path
        id="Path_21381_00000000193826989370574900000010764282586106096782_"
        class="st8"
        d="M36.28,53.05
		c-5.34,0.16-9.53,4.63-9.37,9.96c0.16,5.34,4.63,9.53,9.96,9.37c5.22-0.16,9.36-4.43,9.37-9.65
		C46.08,57.34,41.67,53.06,36.28,53.05"
      />
      <path
        id="Path_21382_00000102512202109127287740000001223854594993154219_"
        class="st8"
        d="M65.39,53.06
		c-5.09,0.48-9,4.72-9.08,9.83c0.14,5.29,4.55,9.47,9.84,9.33c5.3-0.14,9.47-4.55,9.33-9.84s-4.55-9.47-9.84-9.33
		C65.56,53.05,65.47,53.05,65.39,53.06"
      />
      <path
        id="Path_21383_00000075848587827109174370000017884421773782462349_"
        class="st8"
        d="M94.78,53.03
		c-5.35,0.06-9.64,4.44-9.58,9.79c0.06,5.35,4.44,9.64,9.79,9.58c5.09-0.27,9.13-4.36,9.35-9.45c0.12-5.35-4.12-9.79-9.47-9.91
		C94.83,53.04,94.81,53.03,94.78,53.03"
      />
      <path
        id="Path_21384_00000100351169350347445690000010100205276703411130_"
        class="st8"
        d="M222.07,150.09c-6.55,0-13.08,0-19.88,0
		c-2.41-14.37-13.35-25.81-27.6-28.84c-6.68-1.22-13.51-1.43-20.26-0.63c-2.81,1.01-4.9,3.4-5.53,6.32
		c-0.06,2.68-0.08,5.35-0.11,8.01h-6.72c-4.17,0-7.92,1.14-7.81,5.99c0.1,4.46,3.72,5.42,7.61,5.37c2.21,0,4.45,0,6.79,0
		c-0.05,6.55-0.05,13.09,0,19.64c-2.08,0-4.33,0-6.57,0c-4.17,0-7.92,1.14-7.81,6c0.1,4.46,3.72,5.41,7.61,5.36
		c2.22,0,4.47,0,6.81,0v8.08c0,4.3,2.67,6.27,6.8,6.44c19.95,0.8,38.24-1.5,46.92-30.35c6.92,0,13.48,0,20.04,0
		c4.23,0,8.65-0.21,8.49-5.72c-0.16-5.52-4.65-5.6-8.84-5.59 M187.54,168.47c-5.68,9.33-16.67,13.99-27.33,11.61V132
		c10.25-2.45,20.94,1.79,26.72,10.6C192.42,150.28,192.66,160.54,187.54,168.47"
      />
    </g>
  </svg>
</template>
