<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="56.171" height="32.196" viewBox="0 0 56.171 32.196">
    <path
      id="Path_29240"
      data-name="Path 29240"
      d="M295.085,95.944l-10.9,2.378v-2.3a6.779,6.779,0,0,0-6.778-6.78H247.369a6.78,6.78,0,0,0-6.78,6.78V114.66a6.78,6.78,0,0,0,6.78,6.78h30.038a6.779,6.779,0,0,0,6.778-6.78v-2.212l10.911,2.295a1.381,1.381,0,0,0,1.664-1.351v-16.1A1.38,1.38,0,0,0,295.085,95.944ZM269.85,106.6l-10.267,6.187a1.473,1.473,0,0,1-2.233-1.263V99.156a1.473,1.473,0,0,1,2.233-1.263l10.267,6.187A1.473,1.473,0,0,1,269.85,106.6Z"
      transform="translate(-240.589 -89.244)"
    />
  </svg>
</template>
