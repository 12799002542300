import { DateTime, DurationLike, Zone } from 'luxon';
import { ref, Ref, unref } from 'vue';
import { DateRange } from '../../interfaces';
import { getZone, parseISODuration } from '../date-time';

/**
 * Composition for manipulate date range using relative & duration
 * FIXME: convert to utility functions instead
 */
export function useDateRangeRelativePicker(timezone: Ref<Zone | string | number | null> | Zone | string | number | null) {
  // models
  const workingTimezone = ref(getZone(unref(timezone)));

  // functions
  const selectToday = (withTime = false): DateRange => {
    const todayWithZoned = DateTime.now().setZone(workingTimezone.value);
    const endTime = withTime ? { h: 23, mi: 59, s: 59 } : { h: 0, mi: 0, s: 0 };

    return {
      start: todayWithZoned.set({ hour: 0, minute: 0, second: 0 }).toJSDate(),
      end: todayWithZoned.set({ hour: endTime.h, minute: endTime.mi, second: endTime.s }).toJSDate(),
    };
  };

  const selectLastsDuration = (duration: DurationLike | string, baseOrWithTime: Date | boolean = false): DateRange => {
    const baseWithZoned = (baseOrWithTime instanceof Date ? DateTime.fromJSDate(baseOrWithTime) : DateTime.now()).setZone(workingTimezone.value);
    const endTime = baseOrWithTime === true ? { h: 23, mi: 59, s: 59 } : { h: baseWithZoned.hour, mi: baseWithZoned.minute, s: baseWithZoned.second };

    return {
      start: baseWithZoned.minus(typeof duration === 'string' ? parseISODuration(duration) : duration).toJSDate(),
      end: baseWithZoned.set({ hour: endTime.h, minute: endTime.mi, second: endTime.s }).toJSDate(),
    };
  };

  return {
    // function
    selectToday,
    selectLastsDuration,
  };
}
