import { onBeforeMount, ref, watch, reactive, Ref, onMounted } from 'vue';

import router from '@/router';
import api from '@services/api';

import dataConnectModel from '@models/dataConnect/dataConnect';
import BaseModal from '@/views/components/modal/BaseModal.vue';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

export default function useAddDataSourceImportExcelPage() {
  const { fetchAddDataSourceExcelDataSetModel, downloadDataSourceExcelDataSetModel, deleteDataSourceExcelCustomDataTemplateModel } =
    dataConnectModel();

  const { openSuccessModal, openWarningModal, openErrorModal } = useValidationModal();

  const DrawerType = {
    createTemplate: 1,
    uploadFile: 2,
  };

  const loading: any = ref(false);
  const isCreateTemplateDrawerOpen: any = ref(false);
  const isUploadFileDrawerOpen: any = ref(false);
  const isEditTemplateDrawerOpen: any = ref(false);
  const templateOption: any = ref([]);
  const selectedTemplate: any = ref(null);
  const selectedCustomTemplate: any = ref(null);
  const dataSetList: any = ref([]);
  const downloadLink: any = ref(null);
  const isSuccuessModalOpen: any = ref(false);
  const isRemoveModalOpen: any = ref(false);
  const removeModalDetail: any = ref(null);
  const successModalDetail: any = ref(null);
  const uploadCustomTemplateHeader: any = ref([]);
  const dataSetListCustomTemplate: any = ref([]);

  const resolveDateTime = (dateInput: any) => {
    const date = new Date(dateInput);
    let dateStr = '';

    try {
      dateStr = `${date.toLocaleString('default', {
        day: '2-digit',
      })}-${date.toLocaleString('default', {
        month: '2-digit',
      })}-${date.getFullYear()} ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
      }`;
    } catch {
      //return i18n.t('date.invalid');
    }

    return dateStr;
  };

  const fetchAddDataSourceExcelDataSetMapping = (Data: any) => {
    let dataResult = {
      default: [],
      custom: [],
    };

    let dataSetList = Data.data.payload
      .map((dataSet: any) => {
        let dataSetResult = {
          id: dataSet.dataset_id,
          dataSetId: dataSet.parent_dataset_id !== 0 ? dataSet.parent_dataset_id : dataSet.dataset_id,
          dataSetType: 1,
          templateType: dataSet.is_default_template ? 1 : 2,
          dataset_name: dataSet.dataset_name,
          description: dataSet.description,
          seq: dataSet.seq,
          icon_url: dataSet.icon_url,
          created_by: dataSet.created_by,
          created_date: resolveDateTime(dataSet.created_date),
          created_date_raw: dataSet.created_date,
          updated_by: dataSet.updated_by,
          updated_date: resolveDateTime(dataSet.updated_date),
          updated_date_raw: dataSet.updated_date,
          category_id: dataSet.category_id,
          category_name: dataSet.category_name,
          mapping: [],
          customData: {
            mappingColumns: [],
            column: [],
          },
        };

        if (!dataSet.is_default_template) {
          // TODO: sample data in front end UI
          // const sampleData = JSON.parse(dataSet.sample_data);

          dataSetResult.customData.mappingColumns = dataSet.custommappings.map((mappedColumn: any, index: number) => {
            let obj = {
              column: mappedColumn.src_field_name,
              rename: mappedColumn.src_display_name,
              mapping: {
                label: mappedColumn.dest_display_name,
                value: mappedColumn.dest_field_name,
              },
              // TODO: fix bug sample out of array index
              // preview: sampleData ? sampleData[index].List : [],
            };
            return obj;
          });
        }

        dataSetResult.mapping = dataSet.mappings.map((mapping: any) => {
          let mappingResult = {
            label: mapping.dest_display_dropdown_name,
            value: mapping.dest_field_name,
            display: mapping.dest_display_name,
            require: mapping.is_require
          };
          return mappingResult;
        });

        dataSetResult.customData.column = dataSet.custommappings.map((list: any) => {
          return list;
        });
        return dataSetResult;
      })
      .sort((a: any, b: any) => {
        return new Date(b.created_date_raw).getTime() - new Date(a.created_date_raw).getTime();
      });

    dataResult.default = dataSetList.filter((dataSet: any) => {
      return dataSet.templateType == 1;
    });

    dataResult.custom = dataSetList.filter((dataSet: any) => {
      return dataSet.templateType == 2;
    });

    return dataResult;
  };

  const fetchAddDataSourceExcelDataSetRequest = () => {
    let model: any = fetchAddDataSourceExcelDataSetModel;
    model.payload.Datasource_Id = localStorage.getItem('DataSourceId');
    api.apiRequest(model).then((response) => {
      let dataSetListResult = fetchAddDataSourceExcelDataSetMapping(response);
      dataSetList.value = dataSetListResult.default;
      dataSetListCustomTemplate.value = dataSetListResult.custom;
    });
  };

  const openDrawer = (type: any, template?: any) => {
    switch (type) {
      case DrawerType.createTemplate:
        return (isCreateTemplateDrawerOpen.value = true);
      case DrawerType.uploadFile:
        selectedTemplate.value = template;

        let templateHeader: any = [];
        let templateCustomData: any = template.customData?.column || [];
        templateCustomData.map((column: any, idx: any) => {
          templateHeader.push({
            key: idx,
            column: column.dest_display_name,
            rename: column.src_display_name || column.dest_display_name,
          });
        });
        uploadCustomTemplateHeader.value = templateHeader;
        return (isUploadFileDrawerOpen.value = true);
    }
  };

  const closeDrawer = () => {
    isCreateTemplateDrawerOpen.value = false;
    isUploadFileDrawerOpen.value = false;
  };

  const setLoading = (value: any) => {
    loading.value = value;
  };

  const onConfirmRemoveCustomTemplate = (id: any) => {
    let model: any = deleteDataSourceExcelCustomDataTemplateModel;
    model.payload.Dataset_Id = id;
    api.apiRequest(model).then((response) => {
      fetchAddDataSourceExcelDataSetRequest();
      if (response.status == 'success') {
        openSuccessModal('ทำรายการสำเร็จ!');
      } else {
        openErrorModal('เกิดข้อผิดพลาด!', 'ทำรายการไม่สำเร็จ');
      }
    });
  };

  const onDeleteCustomTemplate = (template: any) => {
    openWarningModal(
      'ลบ Custom Template',
      `ต้องการลบ ${template.dataset_name} หรือไม่ ?`,
      () => {
        onConfirmRemoveCustomTemplate(template.id);
      },
      () => {},
    );
  };

  const onEditCustomTemplate = (index: any) => {
    selectedCustomTemplate.value = {
      index: index,
      template: dataSetListCustomTemplate.value[index],
    };
    isEditTemplateDrawerOpen.value = true;
  };

  const onCloseEditDrawer = () => {
    isEditTemplateDrawerOpen.value = false;
    selectedCustomTemplate.value = null;
  };

  const onEditSuccess = () => {
    openSuccessModal('ทำรายการสำเร็จ!');
    fetchAddDataSourceExcelDataSetRequest();
  };

  onMounted(() => {
    fetchAddDataSourceExcelDataSetRequest();
  });

  return {
    dataSetList,
    dataSetListCustomTemplate,
    isCreateTemplateDrawerOpen,
    openDrawer,
    closeDrawer,
    loading,
    setLoading,
    fetchAddDataSourceExcelDataSetRequest,
    DrawerType,
    onDeleteCustomTemplate,
    isEditTemplateDrawerOpen,
    selectedCustomTemplate,
    onCloseEditDrawer,
    onEditSuccess,
    onEditCustomTemplate,
    isUploadFileDrawerOpen,
    selectedTemplate,
    uploadCustomTemplateHeader,
    downloadLink,
  };
}
