<template>
  <svg
    id="Group_37520"
    data-name="Group 37520"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="10.414"
    height="15.201"
    viewBox="0 0 10.414 15.201"
  >
    <g id="Group_37519" data-name="Group 37519">
      <path
        id="Path_24355"
        data-name="Path 24355"
        d="M5.207,0A5.207,5.207,0,0,0,0,5.208c0,2.139,2.882,7.112,4.357,9.519a1,1,0,0,0,1.7,0c1.476-2.407,4.357-7.379,4.357-9.519A5.207,5.207,0,0,0,5.207,0m.12,13.812a.14.14,0,0,1-.24,0c-.447-.736-.989-1.656-1.528-2.633A38.5,38.5,0,0,1,1.823,7.687,7.15,7.15,0,0,1,1.1,5.208a4.109,4.109,0,0,1,8.217,0c0,.455-.24,1.947-2.461,5.972-.539.977-1.081,1.9-1.528,2.633"
        transform="translate(0 -0.001)"
        fill="#c3c3c3"
      />
      <path
        id="Path_24356"
        data-name="Path 24356"
        d="M139.223,141.97a2.257,2.257,0,1,0,2.257,2.257,2.257,2.257,0,0,0-2.257-2.257"
        transform="translate(-134.016 -138.912)"
        fill="#c3c3c3"
      />
    </g>
  </svg>
</template>
