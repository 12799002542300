<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g id="Group_32739_00000061429737608214313630000015367364266702705583_" transform="translate(8.223 5.174)">
      <path
        id="Path_21316_00000113314037755412415470000014214937243941216156_"
        class="st8"
        d="M138.32,197.69
		c-0.78-2.97-1.42-6-2.37-8.92c-6.27-19.4-12.52-38.8-19-58.1c-2.47-7.35-2.59-14.01,5.88-16.7s12.12,3.18,14.42,10.35
		c3.25,10.15,6.8,20.21,10.24,30.43c11.96-11.29,17.08-10.62,23.43,1.84c4.78-2.03,9.81-6.14,14.01-5.42s7.95,6.18,11.11,8.91
		c3.63-1.76,8.38-6.08,12.31-5.43c4.56,1.75,8.12,5.4,9.78,10c6.04,16.03,11.46,32.28,16.27,48.77c1.74,5.83,1.93,12.01,0.53,17.94
		c-4.74,16.36-18.64,28.4-35.5,30.78c-15.21,4.92-31.89,0.62-42.83-11.04c-15.52-16.94-31.5-33.49-47.23-50.24
		c-3.53-3.76-8.32-7.84-3.05-13.15c5.26-5.32,10.6-3.18,15.52,1.05c4.45,3.81,8.91,7.62,13.37,11.41l3.06-2.44"
      />
      <path
        id="Path_21317_00000107562638197152628820000017272504940180829623_"
        class="st8"
        d="M109.56,156.56
		c-17.91-6.39-27.25-26.08-20.86-43.99c0.42-1.19,0.91-2.35,1.46-3.49c8.39-18.94,30.11-28.02,49.48-20.69
		c18.14,6.41,27.66,26.31,21.25,44.45c-0.23,0.65-0.48,1.29-0.74,1.93c-2.49-4.58-4.55-9.37-6.16-14.33
		c-2.22-12.1-11.61-21.61-23.68-23.99c-15.63-2.5-30.32,8.15-32.82,23.77c-1.33,8.33,1.07,16.81,6.57,23.21
		c2.36,4.14,4.22,8.55,5.55,13.13"
      />
      <path
        id="Path_21318_00000111149236301081943740000014568761197019712153_"
        class="st8"
        d="M181.86,26.22
		c-28.24-5.6-56.53-11-84.86-16.19c-11.58-3.66-23.93,2.75-27.59,14.32c-0.48,1.51-0.79,3.06-0.93,4.63
		c-12.36,62.52-24.46,125.1-36.3,187.73c-3.64,11.64,2.84,24.03,14.48,27.68c1.79,0.56,3.65,0.89,5.52,0.98
		c28.24,5.55,56.52,10.97,84.83,16.24c4.53,0.5,9.09,0.74,13.64,0.71c-14.34-15.17-26.41-28.55-39.25-41.15
		c-4.32-4.12-9.63-7.05-15.42-8.51c-15.7-3.8-31.66-6.47-49.14-9.85c10.16-52.41,20.11-103.7,30.16-155.55
		c29.74,5.75,58.19,11.2,86.77,16.81c23.52,4.66,23.42,4.75,18.67,28.59c-3.06,15.36-5.83,30.78-8.86,46.83l12.08-1.95
		c5.34-27.79,10.77-55.08,15.79-82.43c3.64-11.83-3-24.37-14.83-28.01c-1.57-0.48-3.19-0.79-4.82-0.92 M95.4,226.06
		c4.83,0.01,8.74,3.93,8.73,8.75c-0.01,4.83-3.93,8.74-8.75,8.73c-4.82-0.01-8.72-3.91-8.73-8.73
		C86.64,229.98,90.56,226.06,95.4,226.06L95.4,226.06 M146.46,43.72l-22.88-4.54c0.25-1.29,0.49-2.47,0.74-3.7l22.7,4.33
		c-0.17,1.29-0.36,2.59-0.54,3.88"
      />
    </g>
  </svg>
</template>
