<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.86 20">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          id="Path_501"
          data-name="Path 501"
          d="M9.38,0A9.38,9.38,0,0,0,2.47,15.72C3,16.28,2,18,1,19.69c-.35.61,2.13.34,4.53-1.06.95-.56.86.13,3.81.13A9.38,9.38,0,0,0,9.58,0h-.2M9.13,16a1.45,1.45,0,1,1,1.44-1.45h0A1.44,1.44,0,0,1,9.13,16M11.6,9.71c-.65.37-1.27.73-1.27,1.38v.16a.24.24,0,0,1-.24.24H8.16a.24.24,0,0,1-.24-.24V11c0-1.38,1-2,1.91-2.45l.07,0c.77-.43,1.5-.84,1.5-1.7a1.55,1.55,0,0,0-.78-1.28A2.71,2.71,0,0,0,9.19,5.1a3,3,0,0,0-2.5,1.45.24.24,0,0,1-.34,0L5.2,5.73a.25.25,0,0,1,0-.34A4.76,4.76,0,0,1,9.39,3.14a4.76,4.76,0,0,1,2.93,1.08,3.42,3.42,0,0,1,1.34,2.6c0,1.69-1.1,2.32-2.06,2.88"
        />
      </g>
    </g>
  </svg>
</template>
