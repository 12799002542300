<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="44" height="44" viewBox="0 0 44 44">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_17599"
          data-name="Rectangle 17599"
          width="44"
          height="44"
          rx="5"
          transform="translate(-5027.428 -7912.161)"
          fill="none"
          stroke="#ededed"
          stroke-width="1"
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <rect id="Rectangle_17597" data-name="Rectangle 17597" width="45.097" height="44.673" fill="none" />
      </clipPath>
    </defs>
    <g id="Mask_Group_128" data-name="Mask Group 128" transform="translate(5027.428 7912.161)" clip-path="url(#clip-path)">
      <g id="Group_45602" data-name="Group 45602" transform="translate(29.797 161.494)">
        <g id="Group_45590" data-name="Group 45590" transform="translate(-5057.773 -8073.991)" clip-path="url(#clip-path-2)">
          <rect id="Rectangle_17437" data-name="Rectangle 17437" width="5.563" height="5.563" transform="translate(6.154 0.167)" fill="#ddd" />
          <rect id="Rectangle_17438" data-name="Rectangle 17438" width="5.563" height="5.563" transform="translate(0.591 5.73)" fill="#ddd" />
          <rect id="Rectangle_17439" data-name="Rectangle 17439" width="5.563" height="5.563" transform="translate(17.709 0.167)" fill="#ddd" />
          <rect id="Rectangle_17440" data-name="Rectangle 17440" width="5.991" height="5.563" transform="translate(11.718 5.73)" fill="#ddd" />
          <rect id="Rectangle_17441" data-name="Rectangle 17441" width="5.563" height="5.563" transform="translate(28.835 0.167)" fill="#ddd" />
          <rect id="Rectangle_17442" data-name="Rectangle 17442" width="5.563" height="5.563" transform="translate(23.272 5.73)" fill="#ddd" />
          <rect id="Rectangle_17443" data-name="Rectangle 17443" width="5.563" height="5.563" transform="translate(39.962 0.167)" fill="#ddd" />
          <rect id="Rectangle_17444" data-name="Rectangle 17444" width="5.563" height="5.563" transform="translate(34.398 5.73)" fill="#ddd" />
          <rect id="Rectangle_17445" data-name="Rectangle 17445" width="5.563" height="5.563" transform="translate(51.516 0.167)" fill="#ddd" />
          <rect id="Rectangle_17446" data-name="Rectangle 17446" width="5.991" height="5.563" transform="translate(45.525 5.73)" fill="#ddd" />
          <rect id="Rectangle_17447" data-name="Rectangle 17447" width="5.563" height="5.563" transform="translate(62.643 0.167)" fill="#ddd" />
          <rect id="Rectangle_17448" data-name="Rectangle 17448" width="5.563" height="5.563" transform="translate(57.08 5.73)" fill="#ddd" />
          <rect id="Rectangle_17449" data-name="Rectangle 17449" width="5.991" height="5.563" transform="translate(73.769 0.167)" fill="#ddd" />
          <rect id="Rectangle_17450" data-name="Rectangle 17450" width="5.563" height="5.563" transform="translate(68.206 5.73)" fill="#ddd" />
          <rect id="Rectangle_17451" data-name="Rectangle 17451" width="5.563" height="5.563" transform="translate(85.324 0.167)" fill="#ddd" />
          <rect id="Rectangle_17452" data-name="Rectangle 17452" width="5.563" height="5.563" transform="translate(79.761 5.73)" fill="#ddd" />
          <rect id="Rectangle_17453" data-name="Rectangle 17453" width="5.563" height="5.563" transform="translate(96.45 0.167)" fill="#ddd" />
          <rect id="Rectangle_17454" data-name="Rectangle 17454" width="5.563" height="5.563" transform="translate(90.887 5.73)" fill="#ddd" />
          <rect id="Rectangle_17455" data-name="Rectangle 17455" width="5.991" height="5.563" transform="translate(107.577 0.167)" fill="#ddd" />
          <rect id="Rectangle_17456" data-name="Rectangle 17456" width="5.563" height="5.563" transform="translate(102.014 5.73)" fill="#ddd" />
          <rect id="Rectangle_17457" data-name="Rectangle 17457" width="5.563" height="5.563" transform="translate(0.591 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17458" data-name="Rectangle 17458" width="5.563" height="5.563" transform="translate(6.154 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17459" data-name="Rectangle 17459" width="5.991" height="5.563" transform="translate(11.718 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17460" data-name="Rectangle 17460" width="5.563" height="5.563" transform="translate(17.709 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17461" data-name="Rectangle 17461" width="5.563" height="5.563" transform="translate(23.272 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17462" data-name="Rectangle 17462" width="5.563" height="5.563" transform="translate(28.835 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17463" data-name="Rectangle 17463" width="5.563" height="5.563" transform="translate(34.398 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17464" data-name="Rectangle 17464" width="5.563" height="5.563" transform="translate(39.962 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17465" data-name="Rectangle 17465" width="5.991" height="5.563" transform="translate(45.525 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17466" data-name="Rectangle 17466" width="5.563" height="5.563" transform="translate(51.516 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17467" data-name="Rectangle 17467" width="5.563" height="5.563" transform="translate(57.08 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17468" data-name="Rectangle 17468" width="5.563" height="5.563" transform="translate(62.643 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17469" data-name="Rectangle 17469" width="5.563" height="5.563" transform="translate(68.206 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17470" data-name="Rectangle 17470" width="5.991" height="5.563" transform="translate(73.769 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17471" data-name="Rectangle 17471" width="5.563" height="5.563" transform="translate(79.761 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17472" data-name="Rectangle 17472" width="5.563" height="5.563" transform="translate(85.324 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17473" data-name="Rectangle 17473" width="5.563" height="5.563" transform="translate(90.887 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17474" data-name="Rectangle 17474" width="5.563" height="5.563" transform="translate(96.45 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17475" data-name="Rectangle 17475" width="5.563" height="5.563" transform="translate(102.014 0.167)" fill="#fcfcfc" />
          <rect id="Rectangle_17476" data-name="Rectangle 17476" width="5.991" height="5.563" transform="translate(107.577 5.73)" fill="#fcfcfc" />
          <rect id="Rectangle_17477" data-name="Rectangle 17477" width="5.563" height="5.563" transform="translate(6.154 22.42)" fill="#ddd" />
          <rect id="Rectangle_17478" data-name="Rectangle 17478" width="5.563" height="5.563" transform="translate(0.591 27.983)" fill="#ddd" />
          <rect id="Rectangle_17479" data-name="Rectangle 17479" width="5.563" height="5.563" transform="translate(17.709 22.42)" fill="#ddd" />
          <rect id="Rectangle_17480" data-name="Rectangle 17480" width="5.991" height="5.563" transform="translate(11.718 27.983)" fill="#ddd" />
          <rect id="Rectangle_17481" data-name="Rectangle 17481" width="5.563" height="5.563" transform="translate(28.835 22.42)" fill="#ddd" />
          <rect id="Rectangle_17482" data-name="Rectangle 17482" width="5.563" height="5.563" transform="translate(23.272 27.983)" fill="#ddd" />
          <rect id="Rectangle_17483" data-name="Rectangle 17483" width="5.563" height="5.563" transform="translate(39.962 22.42)" fill="#ddd" />
          <rect id="Rectangle_17484" data-name="Rectangle 17484" width="5.563" height="5.563" transform="translate(34.398 27.983)" fill="#ddd" />
          <rect id="Rectangle_17485" data-name="Rectangle 17485" width="5.563" height="5.563" transform="translate(51.516 22.42)" fill="#ddd" />
          <rect id="Rectangle_17486" data-name="Rectangle 17486" width="5.991" height="5.563" transform="translate(45.525 27.983)" fill="#ddd" />
          <rect id="Rectangle_17487" data-name="Rectangle 17487" width="5.563" height="5.563" transform="translate(62.643 22.42)" fill="#ddd" />
          <rect id="Rectangle_17488" data-name="Rectangle 17488" width="5.563" height="5.563" transform="translate(57.08 27.983)" fill="#ddd" />
          <rect id="Rectangle_17489" data-name="Rectangle 17489" width="5.991" height="5.563" transform="translate(73.769 22.42)" fill="#ddd" />
          <rect id="Rectangle_17490" data-name="Rectangle 17490" width="5.563" height="5.563" transform="translate(68.206 27.983)" fill="#ddd" />
          <rect id="Rectangle_17491" data-name="Rectangle 17491" width="5.563" height="5.563" transform="translate(85.324 22.42)" fill="#ddd" />
          <rect id="Rectangle_17492" data-name="Rectangle 17492" width="5.563" height="5.563" transform="translate(79.761 27.983)" fill="#ddd" />
          <rect id="Rectangle_17493" data-name="Rectangle 17493" width="5.563" height="5.563" transform="translate(96.45 22.42)" fill="#ddd" />
          <rect id="Rectangle_17494" data-name="Rectangle 17494" width="5.563" height="5.563" transform="translate(90.887 27.983)" fill="#ddd" />
          <rect id="Rectangle_17495" data-name="Rectangle 17495" width="5.991" height="5.563" transform="translate(107.577 22.42)" fill="#ddd" />
          <rect id="Rectangle_17496" data-name="Rectangle 17496" width="5.563" height="5.563" transform="translate(102.014 27.983)" fill="#ddd" />
          <rect id="Rectangle_17497" data-name="Rectangle 17497" width="5.563" height="5.563" transform="translate(0.591 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17498" data-name="Rectangle 17498" width="5.563" height="5.563" transform="translate(6.154 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17499" data-name="Rectangle 17499" width="5.991" height="5.563" transform="translate(11.718 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17500" data-name="Rectangle 17500" width="5.563" height="5.563" transform="translate(17.709 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17501" data-name="Rectangle 17501" width="5.563" height="5.563" transform="translate(23.272 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17502" data-name="Rectangle 17502" width="5.563" height="5.563" transform="translate(28.835 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17503" data-name="Rectangle 17503" width="5.563" height="5.563" transform="translate(34.398 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17504" data-name="Rectangle 17504" width="5.563" height="5.563" transform="translate(39.962 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17505" data-name="Rectangle 17505" width="5.991" height="5.563" transform="translate(45.525 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17506" data-name="Rectangle 17506" width="5.563" height="5.563" transform="translate(51.516 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17507" data-name="Rectangle 17507" width="5.563" height="5.563" transform="translate(57.08 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17508" data-name="Rectangle 17508" width="5.563" height="5.563" transform="translate(62.643 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17509" data-name="Rectangle 17509" width="5.563" height="5.563" transform="translate(68.206 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17510" data-name="Rectangle 17510" width="5.991" height="5.563" transform="translate(73.769 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17511" data-name="Rectangle 17511" width="5.563" height="5.563" transform="translate(79.761 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17512" data-name="Rectangle 17512" width="5.563" height="5.563" transform="translate(85.324 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17513" data-name="Rectangle 17513" width="5.563" height="5.563" transform="translate(90.887 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17514" data-name="Rectangle 17514" width="5.563" height="5.563" transform="translate(96.45 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17515" data-name="Rectangle 17515" width="5.563" height="5.563" transform="translate(102.014 22.42)" fill="#fcfcfc" />
          <rect id="Rectangle_17516" data-name="Rectangle 17516" width="5.991" height="5.563" transform="translate(107.577 27.983)" fill="#fcfcfc" />
          <rect id="Rectangle_17517" data-name="Rectangle 17517" width="5.563" height="5.563" transform="translate(6.154 11.293)" fill="#ddd" />
          <rect id="Rectangle_17518" data-name="Rectangle 17518" width="5.563" height="5.563" transform="translate(0.591 16.857)" fill="#ddd" />
          <rect id="Rectangle_17519" data-name="Rectangle 17519" width="5.563" height="5.563" transform="translate(17.709 11.293)" fill="#ddd" />
          <rect id="Rectangle_17520" data-name="Rectangle 17520" width="5.991" height="5.563" transform="translate(11.718 16.857)" fill="#ddd" />
          <rect id="Rectangle_17521" data-name="Rectangle 17521" width="5.563" height="5.563" transform="translate(28.835 11.293)" fill="#ddd" />
          <rect id="Rectangle_17522" data-name="Rectangle 17522" width="5.563" height="5.563" transform="translate(23.272 16.857)" fill="#ddd" />
          <rect id="Rectangle_17523" data-name="Rectangle 17523" width="5.563" height="5.563" transform="translate(39.962 11.293)" fill="#ddd" />
          <rect id="Rectangle_17524" data-name="Rectangle 17524" width="5.563" height="5.563" transform="translate(34.398 16.857)" fill="#ddd" />
          <rect id="Rectangle_17525" data-name="Rectangle 17525" width="5.563" height="5.563" transform="translate(51.516 11.293)" fill="#ddd" />
          <rect id="Rectangle_17526" data-name="Rectangle 17526" width="5.991" height="5.563" transform="translate(45.525 16.857)" fill="#ddd" />
          <rect id="Rectangle_17527" data-name="Rectangle 17527" width="5.563" height="5.563" transform="translate(62.643 11.293)" fill="#ddd" />
          <rect id="Rectangle_17528" data-name="Rectangle 17528" width="5.563" height="5.563" transform="translate(57.08 16.857)" fill="#ddd" />
          <rect id="Rectangle_17529" data-name="Rectangle 17529" width="5.991" height="5.563" transform="translate(73.769 11.293)" fill="#ddd" />
          <rect id="Rectangle_17530" data-name="Rectangle 17530" width="5.563" height="5.563" transform="translate(68.206 16.857)" fill="#ddd" />
          <rect id="Rectangle_17531" data-name="Rectangle 17531" width="5.563" height="5.563" transform="translate(85.324 11.293)" fill="#ddd" />
          <rect id="Rectangle_17532" data-name="Rectangle 17532" width="5.563" height="5.563" transform="translate(79.761 16.857)" fill="#ddd" />
          <rect id="Rectangle_17533" data-name="Rectangle 17533" width="5.563" height="5.563" transform="translate(96.45 11.293)" fill="#ddd" />
          <rect id="Rectangle_17534" data-name="Rectangle 17534" width="5.563" height="5.563" transform="translate(90.887 16.857)" fill="#ddd" />
          <rect id="Rectangle_17535" data-name="Rectangle 17535" width="5.991" height="5.563" transform="translate(107.577 11.293)" fill="#ddd" />
          <rect id="Rectangle_17536" data-name="Rectangle 17536" width="5.563" height="5.563" transform="translate(102.014 16.857)" fill="#ddd" />
          <rect id="Rectangle_17537" data-name="Rectangle 17537" width="5.563" height="5.563" transform="translate(0.591 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17538" data-name="Rectangle 17538" width="5.563" height="5.563" transform="translate(6.154 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17539" data-name="Rectangle 17539" width="5.991" height="5.563" transform="translate(11.718 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17540" data-name="Rectangle 17540" width="5.563" height="5.563" transform="translate(17.709 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17541" data-name="Rectangle 17541" width="5.563" height="5.563" transform="translate(23.272 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17542" data-name="Rectangle 17542" width="5.563" height="5.563" transform="translate(28.835 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17543" data-name="Rectangle 17543" width="5.563" height="5.563" transform="translate(34.398 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17544" data-name="Rectangle 17544" width="5.563" height="5.563" transform="translate(39.962 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17545" data-name="Rectangle 17545" width="5.991" height="5.563" transform="translate(45.525 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17546" data-name="Rectangle 17546" width="5.563" height="5.563" transform="translate(51.516 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17547" data-name="Rectangle 17547" width="5.563" height="5.563" transform="translate(57.08 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17548" data-name="Rectangle 17548" width="5.563" height="5.563" transform="translate(62.643 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17549" data-name="Rectangle 17549" width="5.563" height="5.563" transform="translate(68.206 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17550" data-name="Rectangle 17550" width="5.991" height="5.563" transform="translate(73.769 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17551" data-name="Rectangle 17551" width="5.563" height="5.563" transform="translate(79.761 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17552" data-name="Rectangle 17552" width="5.563" height="5.563" transform="translate(85.324 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17553" data-name="Rectangle 17553" width="5.563" height="5.563" transform="translate(90.887 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17554" data-name="Rectangle 17554" width="5.563" height="5.563" transform="translate(96.45 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17555" data-name="Rectangle 17555" width="5.563" height="5.563" transform="translate(102.014 11.293)" fill="#fcfcfc" />
          <rect id="Rectangle_17556" data-name="Rectangle 17556" width="5.991" height="5.563" transform="translate(107.577 16.857)" fill="#fcfcfc" />
          <rect id="Rectangle_17557" data-name="Rectangle 17557" width="5.563" height="5.563" transform="translate(6.154 33.547)" fill="#ddd" />
          <rect id="Rectangle_17558" data-name="Rectangle 17558" width="5.563" height="5.563" transform="translate(0.591 39.11)" fill="#ddd" />
          <rect id="Rectangle_17559" data-name="Rectangle 17559" width="5.563" height="5.563" transform="translate(17.709 33.547)" fill="#ddd" />
          <rect id="Rectangle_17560" data-name="Rectangle 17560" width="5.991" height="5.563" transform="translate(11.718 39.11)" fill="#ddd" />
          <rect id="Rectangle_17561" data-name="Rectangle 17561" width="5.563" height="5.563" transform="translate(28.835 33.547)" fill="#ddd" />
          <rect id="Rectangle_17562" data-name="Rectangle 17562" width="5.563" height="5.563" transform="translate(23.272 39.11)" fill="#ddd" />
          <rect id="Rectangle_17563" data-name="Rectangle 17563" width="5.563" height="5.563" transform="translate(39.962 33.547)" fill="#ddd" />
          <rect id="Rectangle_17564" data-name="Rectangle 17564" width="5.563" height="5.563" transform="translate(34.398 39.11)" fill="#ddd" />
          <rect id="Rectangle_17565" data-name="Rectangle 17565" width="5.563" height="5.563" transform="translate(51.516 33.547)" fill="#ddd" />
          <rect id="Rectangle_17566" data-name="Rectangle 17566" width="5.991" height="5.563" transform="translate(45.525 39.11)" fill="#ddd" />
          <rect id="Rectangle_17567" data-name="Rectangle 17567" width="5.563" height="5.563" transform="translate(62.643 33.547)" fill="#ddd" />
          <rect id="Rectangle_17568" data-name="Rectangle 17568" width="5.563" height="5.563" transform="translate(57.08 39.11)" fill="#ddd" />
          <rect id="Rectangle_17569" data-name="Rectangle 17569" width="5.991" height="5.563" transform="translate(73.769 33.547)" fill="#ddd" />
          <rect id="Rectangle_17570" data-name="Rectangle 17570" width="5.563" height="5.563" transform="translate(68.206 39.11)" fill="#ddd" />
          <rect id="Rectangle_17571" data-name="Rectangle 17571" width="5.563" height="5.563" transform="translate(85.324 33.547)" fill="#ddd" />
          <rect id="Rectangle_17572" data-name="Rectangle 17572" width="5.563" height="5.563" transform="translate(79.761 39.11)" fill="#ddd" />
          <rect id="Rectangle_17573" data-name="Rectangle 17573" width="5.563" height="5.563" transform="translate(96.45 33.547)" fill="#ddd" />
          <rect id="Rectangle_17574" data-name="Rectangle 17574" width="5.563" height="5.563" transform="translate(90.887 39.11)" fill="#ddd" />
          <rect id="Rectangle_17575" data-name="Rectangle 17575" width="5.991" height="5.563" transform="translate(107.577 33.547)" fill="#ddd" />
          <rect id="Rectangle_17576" data-name="Rectangle 17576" width="5.563" height="5.563" transform="translate(102.014 39.11)" fill="#ddd" />
          <rect id="Rectangle_17577" data-name="Rectangle 17577" width="5.563" height="5.563" transform="translate(0.591 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17578" data-name="Rectangle 17578" width="5.563" height="5.563" transform="translate(6.154 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17579" data-name="Rectangle 17579" width="5.991" height="5.563" transform="translate(11.718 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17580" data-name="Rectangle 17580" width="5.563" height="5.563" transform="translate(17.709 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17581" data-name="Rectangle 17581" width="5.563" height="5.563" transform="translate(23.272 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17582" data-name="Rectangle 17582" width="5.563" height="5.563" transform="translate(28.835 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17583" data-name="Rectangle 17583" width="5.563" height="5.563" transform="translate(34.398 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17584" data-name="Rectangle 17584" width="5.563" height="5.563" transform="translate(39.962 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17585" data-name="Rectangle 17585" width="5.991" height="5.563" transform="translate(45.525 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17586" data-name="Rectangle 17586" width="5.563" height="5.563" transform="translate(51.516 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17587" data-name="Rectangle 17587" width="5.563" height="5.563" transform="translate(57.08 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17588" data-name="Rectangle 17588" width="5.563" height="5.563" transform="translate(62.643 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17589" data-name="Rectangle 17589" width="5.563" height="5.563" transform="translate(68.206 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17590" data-name="Rectangle 17590" width="5.991" height="5.563" transform="translate(73.769 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17591" data-name="Rectangle 17591" width="5.563" height="5.563" transform="translate(79.761 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17592" data-name="Rectangle 17592" width="5.563" height="5.563" transform="translate(85.324 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17593" data-name="Rectangle 17593" width="5.563" height="5.563" transform="translate(90.887 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17594" data-name="Rectangle 17594" width="5.563" height="5.563" transform="translate(96.45 39.11)" fill="#fcfcfc" />
          <rect id="Rectangle_17595" data-name="Rectangle 17595" width="5.563" height="5.563" transform="translate(102.014 33.547)" fill="#fcfcfc" />
          <rect id="Rectangle_17596" data-name="Rectangle 17596" width="5.991" height="5.563" transform="translate(107.577 39.11)" fill="#fcfcfc" />
        </g>
        <path
          id="Path_29182"
          data-name="Path 29182"
          d="M569.047,68.12h-.721a.557.557,0,0,0-.557.557v7.1a.557.557,0,0,0,.557.557h.721a.557.557,0,0,0,.557-.557v-7.1a.557.557,0,0,0-.557-.557"
          transform="translate(-5617.232 -8123.879)"
          fill="#fff"
        />
        <path
          id="Path_29183"
          data-name="Path 29183"
          d="M586.175,73.916a.557.557,0,0,0-.244-.355l-.561-.361a.557.557,0,0,0-.763.158,1.647,1.647,0,0,1-.691.606,2.205,2.205,0,0,1-.932.2,2.38,2.38,0,0,1-1-.2,2.208,2.208,0,0,1-.744-.54,2.469,2.469,0,0,1-.478-.8,2.85,2.85,0,0,1-.172-.987,2.927,2.927,0,0,1,.168-.991,2.4,2.4,0,0,1,.472-.8,2.215,2.215,0,0,1,.744-.54,2.412,2.412,0,0,1,1.006-.2,1.883,1.883,0,0,1,.49.061,2.213,2.213,0,0,1,.436.167,1.471,1.471,0,0,1,.316.217,1.27,1.27,0,0,1,.205.233.557.557,0,0,0,.8.146l.6-.441a.557.557,0,0,0,.129-.766,2.638,2.638,0,0,0-.571-.593,3.584,3.584,0,0,0-.713-.421,4.2,4.2,0,0,0-1.7-.359,4.373,4.373,0,0,0-1.682.323,4.079,4.079,0,0,0-2.251,2.257,4.454,4.454,0,0,0-.323,1.705,4.513,4.513,0,0,0,.316,1.7,4.021,4.021,0,0,0,2.244,2.261,4.434,4.434,0,0,0,1.7.322A4.058,4.058,0,0,0,584,75.794a3.872,3.872,0,0,0,.866-.34,3.413,3.413,0,0,0,.707-.508,3.527,3.527,0,0,0,.517-.608.557.557,0,0,0,.082-.423"
          transform="translate(-5625.626 -8123.288)"
          fill="#fff"
        />
        <path
          id="Path_29184"
          data-name="Path 29184"
          d="M618.726,69.927a4.078,4.078,0,0,0-2.251-2.257,4.537,4.537,0,0,0-3.364,0,4.078,4.078,0,0,0-2.251,2.257,4.669,4.669,0,0,0,0,3.411,4.076,4.076,0,0,0,2.251,2.258,4.54,4.54,0,0,0,3.364,0,4.082,4.082,0,0,0,2.251-2.257,4.668,4.668,0,0,0,0-3.411m-3.933,4.236a2.375,2.375,0,0,1-1-.2,2.245,2.245,0,0,1-.747-.538,2.352,2.352,0,0,1-.469-.795,3.024,3.024,0,0,1,0-1.987,2.413,2.413,0,0,1,.472-.8,2.208,2.208,0,0,1,.744-.541,2.583,2.583,0,0,1,1.992,0,2.217,2.217,0,0,1,.744.541,2.413,2.413,0,0,1,.472.8,3.025,3.025,0,0,1,0,1.987,2.35,2.35,0,0,1-.47.794,2.275,2.275,0,0,1-.746.539,2.38,2.38,0,0,1-1,.2"
          transform="translate(-5650.018 -8123.287)"
          fill="#fff"
        />
        <path
          id="Path_29185"
          data-name="Path 29185"
          d="M656.417,68.12h-.722a.557.557,0,0,0-.557.557v4.272L652,68.363a.557.557,0,0,0-.46-.243h-.9a.557.557,0,0,0-.557.557v7.1a.557.557,0,0,0,.557.557h.721a.557.557,0,0,0,.557-.557V71.457l3.132,4.629a.557.557,0,0,0,.461.245h.9a.557.557,0,0,0,.557-.557v-7.1a.557.557,0,0,0-.557-.557"
          transform="translate(-5680.334 -8123.879)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>
