<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="145.967" height="124.48" viewBox="0 0 145.967 124.48">
    <g id="Group_53787" data-name="Group 53787" transform="translate(-266.317 -425.5)">
      <g id="Group_45160" data-name="Group 45160" transform="translate(266.317 425.5)">
        <g id="Group_45161" data-name="Group 45161">
          <rect
            id="Rectangle_17076"
            data-name="Rectangle 17076"
            width="14.889"
            height="2.326"
            transform="translate(8.607) rotate(90)"
            fill="#3a83f6"
          />
          <rect
            id="Rectangle_17077"
            data-name="Rectangle 17077"
            width="14.889"
            height="2.326"
            transform="translate(14.889 8.608) rotate(180)"
            fill="#3a83f6"
          />
        </g>
      </g>
      <g id="Group_45162" data-name="Group 45162" transform="translate(401.863 533.365) rotate(45)">
        <g id="Group_45161-2" data-name="Group 45161">
          <rect
            id="Rectangle_17076-2"
            data-name="Rectangle 17076"
            width="14.889"
            height="2.326"
            transform="translate(8.607) rotate(90)"
            fill="#3a83f6"
          />
          <rect
            id="Rectangle_17077-2"
            data-name="Rectangle 17077"
            width="14.889"
            height="2.326"
            transform="translate(14.889 8.608) rotate(180)"
            fill="#3a83f6"
          />
        </g>
      </g>
      <circle id="Ellipse_1010" data-name="Ellipse 1010" cx="3.524" cy="3.524" r="3.524" transform="translate(405.236 478.354)" fill="#16294b" />
      <g id="Group_45174" data-name="Group 45174" transform="translate(269.931 496.677)">
        <circle id="Ellipse_1011" data-name="Ellipse 1011" cx="5.638" cy="5.638" r="5.638" transform="translate(0 7.047)" fill="#16294b" />
        <circle id="Ellipse_1012" data-name="Ellipse 1012" cx="2.114" cy="2.114" r="2.114" fill="#16294b" />
      </g>
      <g id="Group_45173" data-name="Group 45173" transform="translate(295.123 432.945)">
        <circle id="Ellipse_1016" data-name="Ellipse 1016" cx="6.135" cy="6.135" r="6.135" transform="translate(61.087 16.126)" fill="#3a83f6" />
        <path
          id="Path_28710"
          data-name="Path 28710"
          d="M4142,956.737a1.714,1.714,0,0,0-2.453-.067L4127.238,968.7a1.348,1.348,0,0,1-1.844.035l-23.437-21.181a1.715,1.715,0,0,0-2.449.154l-16.227,18.824-6.873-5.856,21.4-24.917a2.983,2.983,0,0,1,4.264-.269l22.319,20.171a2.442,2.442,0,0,0,3.345-.064l11.283-11.02a2.983,2.983,0,0,1,4.281.116l18.527,20.185-6.69,6.066Z"
          transform="translate(-4073.204 -909.826)"
          fill="#3a83f6"
        />
        <path
          id="Path_28711"
          data-name="Path 28711"
          d="M4156.057,887.817h-79.525a6.162,6.162,0,0,0-6.162,6.162v91.271a6.162,6.162,0,0,0,6.162,6.162h79.525a6.162,6.162,0,0,0,6.163-6.162V893.979A6.162,6.162,0,0,0,4156.057,887.817Zm-4.53,61.459a2.228,2.228,0,0,1-2.229,2.228H4083.29a2.228,2.228,0,0,1-2.229-2.228V900.549a2.228,2.228,0,0,1,2.229-2.228H4149.3a2.228,2.228,0,0,1,2.229,2.228Z"
          transform="translate(-4070.37 -887.817)"
          fill="#16294b"
        />
        <rect
          id="Rectangle_17082"
          data-name="Rectangle 17082"
          width="52.206"
          height="9.115"
          rx="4.557"
          transform="translate(10.517 70.216)"
          fill="#fff"
        />
        <rect
          id="Rectangle_17083"
          data-name="Rectangle 17083"
          width="70.465"
          height="9.115"
          rx="4.557"
          transform="translate(10.517 84.721)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>
