export default function () {
  const fetchCustomerInsightConfigCreate: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSConfig/CreateConfig`,
    method: 'POST',
    payload: {},
  };

  const fetchCustomerInsightConfigSave: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSConfig/SaveConfig`,
    method: 'POST',
    payload: {},
  };

  const fetchCustomerInsightConfigCancel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSConfig/CancelConfig`,
    method: 'POST',
    payload: {},
  };

  return {
    fetchCustomerInsightConfigCreate,
    fetchCustomerInsightConfigSave,
    fetchCustomerInsightConfigCancel,
  };
}
