<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18.615" height="23.812" viewBox="0 0 16.615 11.812">
    <g id="Group_44804" data-name="Group 44804" transform="translate(-1816.602 1560.615)">
      <path
        id="Path_28721"
        data-name="Path 28721"
        d="M1824.9-1552.785l1.866-1.866-2.214-5.964h-2.66L1816.6-1546.8h2.55q.187-.5.356-.981t.335-.96q.16-.477.335-.957t.355-.978h2.263l2.106-2.107Zm-3.519-.016,1.783-5.072,1.731,5.072Z"
        fill="#0f2a4e"
      />
      <path
        id="Path_28722"
        data-name="Path 28722"
        d="M1849.568-1540.8a1.086,1.086,0,0,0,0-1.536l-2.806-2.806a1.086,1.086,0,0,0-1.536,0l-4.845,4.845a1.087,1.087,0,0,0,0,1.536l2.417,2.417a1.045,1.045,0,0,0,.74.307h6.189v-1.158h-3.765Zm-5.983,3.606-2.335-2.335,2.967-2.967,2.7,2.7-2.6,2.6Z"
        transform="translate(-16.669 -10.771)"
        fill="#0080ff"
      />
    </g>
  </svg>
</template>
