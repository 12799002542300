<template>
  <svg id="Group_37809" data-name="Group 37809" xmlns="http://www.w3.org/2000/svg" width="47.899" height="67.799" viewBox="0 0 47.899 67.799">
    <path
      id="Path_24102"
      data-name="Path 24102"
      d="M38.619,54.376A8.972,8.972,0,1,1,29.647,45.4a8.972,8.972,0,0,1,8.972,8.972"
      transform="translate(-5.697 -45.404)"
    />
    <path
      id="Path_24103"
      data-name="Path 24103"
      d="M47.037,63.51a4.63,4.63,0,0,0-6.336-.967L29.63,70.084a10.092,10.092,0,0,1-11.363,0L7.2,62.543a4.631,4.631,0,0,0-6.081.647,4.506,4.506,0,0,0,.853,6.693l11.073,7.543V90.9a4.487,4.487,0,0,0-.12,1.017V113a4.63,4.63,0,0,0,4.367,4.691,4.505,4.505,0,0,0,4.644-4.5V102.832a2.342,2.342,0,1,1,4.683,0v10.354a4.5,4.5,0,1,0,9.01,0V76.9l10.3-7.019a4.505,4.505,0,0,0,1.107-6.373"
      transform="translate(0 -49.893)"
    />
  </svg>
</template>
