<template>
  <svg id="Group_974" data-name="Group 974" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" width="16.986" height="17" viewBox="0 0 16.986 17">
    <g id="Group_974-2" data-name="Group 974">
      <path id="Path_419" data-name="Path 419"
        d="M104.331,80.447h-.646a.3.3,0,0,1-.3-.3V79.5a.3.3,0,0,1,.3-.3h0a.3.3,0,0,1,.3.3v.345h.345a.3.3,0,1,1,0,.6"
        transform="translate(-94.472 -72.372)" fill="#676767" />
      <path id="Path_420" data-name="Path 420"
        d="M129.242,87.3h-1.236a.3.3,0,1,1,0-.6h1.236a.3.3,0,0,1,0,.6m-2.472,0h-1.236a.3.3,0,0,1,0-.6h1.236a.3.3,0,0,1,0,.6"
        transform="translate(-114.44 -79.225)" fill="#676767" />
      <path id="Path_421" data-name="Path 421"
        d="M183.54,80.452h-.646a.3.3,0,0,1,0-.6h.339c0-.107-.011-.213-.02-.319a.3.3,0,1,1,.6-.051c.019.222.029.448.029.672a.3.3,0,0,1-.3.3"
        transform="translate(-166.856 -72.376)" fill="#676767" />
      <path id="Path_422" data-name="Path 422"
        d="M129.821,8.544a.3.3,0,0,1-.293-.23,7.155,7.155,0,0,0-.35-1.057.3.3,0,1,1,.556-.234,7.742,7.742,0,0,1,.38,1.147.3.3,0,0,1-.293.374m-.9-2.134a.3.3,0,0,1-.257-.143,7.219,7.219,0,0,0-.658-.9.3.3,0,1,1,.458-.393,7.822,7.822,0,0,1,.713.975.3.3,0,0,1-.256.46m-1.507-1.757a.3.3,0,0,1-.2-.072,7.23,7.23,0,0,0-.9-.656.3.3,0,1,1,.316-.514,7.813,7.813,0,0,1,.976.711.3.3,0,0,1-.2.531m-1.97-1.214a.3.3,0,0,1-.116-.023,7.137,7.137,0,0,0-1.058-.348.3.3,0,1,1,.143-.586,7.739,7.739,0,0,1,1.147.378.3.3,0,0,1-.117.58"
        transform="translate(-113.353 -2.261)" fill="#676767" />
      <path id="Path_423" data-name="Path 423"
        d="M103.548,1.25a.3.3,0,0,1-.3-.3V.3a.3.3,0,0,1,.3-.3h.013c.219,0,.441.009.658.027a.3.3,0,1,1-.051.6c-.105-.009-.212-.015-.319-.02V.948a.3.3,0,0,1-.3.3Z"
        transform="translate(-94.347)" fill="#676767" />
      <path id="Path_424" data-name="Path 424"
        d="M103.592,26.152a.3.3,0,0,1-.3-.3l0-1.236a.3.3,0,0,1,.3-.3h0a.3.3,0,0,1,.3.3l0,1.236a.3.3,0,0,1-.3.3Zm0-2.472a.3.3,0,0,1-.3-.3l0-1.236a.3.3,0,0,1,.3-.3h0a.3.3,0,0,1,.3.3l0,1.236a.3.3,0,0,1-.3.3Z"
        transform="translate(-94.383 -19.959)" fill="#676767" />
      <path id="Path_425" data-name="Path 425" d="M7.472,31.331h7.472a7.472,7.472,0,1,1-7.485-7.472Z"
        transform="translate(0 -21.803)" fill="#676767" />
    </g>
  </svg>
</template>
