import { ref, Ref, computed } from 'vue';
import { DateTime } from 'luxon';

interface Props {
  campaignForm: Communication.LineOA.CreateCampaign.CampaignDetail;
  validation?: Communication.LineOA.Component.ValidateResult | null;
  messageList?: Communication.LineOA.Component.LineMessage[];
}

export default function usePreviewCampaign(props: Props) {
  const resolveSchedule = (mode: number): string[] => {
    const resolveString: any = [];
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null');
    const timezone = userInfo.timezone || DateTime.fromISO(new Date().toISOString()).zoneName;
    const scheduleDateTime = props.campaignForm.schedule.scheduleDt
      ? DateTime.fromISO(props.campaignForm.schedule.scheduleDt, { zone: timezone })
      : null;

    if (mode == 1) {
      resolveString.push(props.campaignForm.schedule.startOnLabel);
    } else if (mode == 2) {
      resolveString.push(
        `${
          scheduleDateTime
            ? `${scheduleDateTime.setLocale('th').toFormat('cccc')}ที่ ${scheduleDateTime
                .setLocale('th')
                .toFormat('dd LLLL yyyy')} เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.`
            : ''
        }`,
      );
    } else if (mode == 3) {
      if (props.campaignForm.schedule.repeatMode != 2) {
        resolveString.push(
          `${
            scheduleDateTime
              ? `${scheduleDateTime.setLocale('th').toFormat('cccc')}ที่ ${scheduleDateTime
                  .setLocale('th')
                  .toFormat('dd LLLL yyyy')} เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.`
              : ''
          }`,
        );
        resolveString.push(props.campaignForm.schedule.repeatEveryLabel, props.campaignForm.schedule.startOnLabel);
      } else {
        resolveString.push(
          scheduleDateTime
            ? `${scheduleDateTime.setLocale('th').toFormat('cccc')}ที่ ${scheduleDateTime
                .setLocale('th')
                .toFormat('dd LLLL yyyy')} เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.`
            : '',
        );
        resolveString.push(props.campaignForm.schedule.repeatEveryLabel, props.campaignForm.schedule.startOnLabel);
        props.campaignForm.schedule.repeatOnVal?.forEach((value) => {
          switch (value) {
            case 1:
              resolveString.push(`ส่งทุกวันอาทิตย์${scheduleDateTime ? ` เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.` : ''}`);
              break;
            case 2:
              resolveString.push(`ส่งทุกวันจันทร์${scheduleDateTime ? ` เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.` : ''}`);
              break;
            case 4:
              resolveString.push(`ส่งทุกวันอังคาร${scheduleDateTime ? ` เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.` : ''}`);
              break;
            case 8:
              resolveString.push(`ส่งทุกวันพุธ${scheduleDateTime ? ` เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.` : ''}`);
              break;
            case 16:
              resolveString.push(`ส่งทุกวันพฤหัสบดี${scheduleDateTime ? ` เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.` : ''}`);
              break;
            case 32:
              resolveString.push(`ส่งทุกวันศุกร์${scheduleDateTime ? ` เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.` : ''}`);
              break;
            case 64:
              resolveString.push(`ส่งทุกวันเสาร์${scheduleDateTime ? ` เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.` : ''}`);
              break;
          }
        });
      }
    }

    return resolveString;
  };

  const resolveEndDate = (dateString: string) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null');
    const timezone = userInfo.timezone || DateTime.fromISO(new Date().toISOString()).zoneName;
    const endDate = dateString
      ? `${DateTime.fromISO(dateString, { zone: timezone }).setLocale('th').toFormat('cccc')}ที่ ${DateTime.fromISO(dateString, { zone: timezone })
          .setLocale('th')
          .toFormat('dd LLLL yyyy')} เวลา ${DateTime.fromISO(dateString, {
          zone: timezone,
        }).toFormat('HH:mm:ss')} น. `
      : '-';
    return endDate;
  };

  return { resolveSchedule, resolveEndDate };
}
