<template>
  <svg id="Group_34736" data-name="Group 34736" xmlns="http://www.w3.org/2000/svg" width="19.514" height="17.739" viewBox="0 0 19.514 17.739">
    <path
      id="Path_22786"
      data-name="Path 22786"
      d="M631.967,6.035H614.962a1.254,1.254,0,0,0-1.254,1.254V19.207a1.254,1.254,0,0,0,1.254,1.254h13.267a.192.192,0,0,1,.111.035l4.577,3.242a.193.193,0,0,0,.3-.157V7.289a1.254,1.254,0,0,0-1.254-1.254m-.561,14.409-2.017-1.428a2,2,0,0,0-1.161-.369H615.522V7.85h15.884Z"
      transform="translate(-613.707 -6.035)"
    />
    <path
      id="Path_22787"
      data-name="Path 22787"
      d="M638.345,39.706a1.453,1.453,0,1,1-1.453-1.453,1.453,1.453,0,0,1,1.453,1.453"
      transform="translate(-631.546 -32.483)"
    />
    <path
      id="Path_22788"
      data-name="Path 22788"
      d="M662.974,39.706a1.454,1.454,0,1,1-1.453-1.453,1.453,1.453,0,0,1,1.453,1.453"
      transform="translate(-651.763 -32.483)"
    />
    <path
      id="Path_22789"
      data-name="Path 22789"
      d="M687.6,39.706a1.454,1.454,0,1,1-1.453-1.453,1.453,1.453,0,0,1,1.453,1.453"
      transform="translate(-671.982 -32.483)"
    />
  </svg>
</template>
