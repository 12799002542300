import { createPopper, preventOverflow } from '@popperjs/core';
import { Directive } from 'vue';
import { PopperOptions } from './popper';

let popperInstance: ReturnType<typeof createPopper> = null!;

const showPopper = (el: HTMLElement, mode: 'click' | 'hover', showedCallback?: (...params: any[]) => void) => {
  el.classList.add('show');

  if (mode == 'hover') {
    el.focus();
  }

  if (mode == 'click') {
    el.focus();
  }

  if (showedCallback) showedCallback();

  popperInstance.setOptions((options) => ({
    ...options,
    modifiers: [...(options.modifiers?.filter((option) => option.name !== 'eventListeners') || []), { name: 'eventListeners', enabled: true }],
  }));

  popperInstance.update();
};

const hidePopper = (el: Element) => {
  popperInstance.setOptions((options) => ({
    ...options,
    modifiers: [...(options.modifiers?.filter((option) => option.name !== 'eventListeners') || []), { name: 'eventListeners', enabled: false }],
  }));
  el.classList.remove('show');
};

const popper: Directive = {
  mounted: (el: Element & ElementCSSInlineStyle, binding) => {
    const popperOptions = binding.value as PopperOptions;
    const target = document.querySelector(`#${popperOptions.target}`);
    const toggle = popperOptions.toggle ? document.querySelector(`#${popperOptions.toggle}`) : null;

    if (target) {
      const parent = el.parentElement;
      const popperContainer = document.createElement('div');
      const popperContent = document.createElement('div');
      const popperContentWrapper = document.createElement('div');

      popperContainer.className = 'popper-container';
      popperContent.className = 'popper-content';
      popperContentWrapper.className = 'popper-content-wrapper';
      popperContainer.setAttribute('tabindex', '1');
      popperContent.appendChild(el);
      popperContentWrapper.appendChild(popperContent);
      popperContainer.appendChild(popperContentWrapper);
      parent?.appendChild(popperContainer);

      if (toggle) {
        (toggle as HTMLElement).tabIndex = 1;

        if (popperOptions.event == 'hover') {
          toggle.addEventListener('mouseover', () => showPopper(popperContainer, 'hover', popperOptions.onShowed));
          popperContainer.addEventListener('blur', () => hidePopper(popperContainer));
        } else {
          toggle.addEventListener('click', () => showPopper(popperContainer, 'click', popperOptions.onShowed));
          popperContainer.addEventListener('blur', () => hidePopper(popperContainer));
        }
      }

      popperInstance = createPopper(target as HTMLElement, popperContainer, {
        placement: popperOptions.placement || 'top',
        modifiers: [preventOverflow, ...(popperOptions.modifiers || []), { name: 'eventListeners', enabled: false }],
      });
    }
  },
  unmounted: () => {
    popperInstance.destroy();
  },
};

export default popper;
