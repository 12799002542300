<template>
  <svg id="Group_37102" data-name="Group 37102" xmlns="http://www.w3.org/2000/svg" width="58.084" height="83.663" viewBox="0 0 58.084 83.663">
    <g id="Group_37084" data-name="Group 37084" transform="translate(0 0)">
      <path
        id="Path_24097"
        data-name="Path 24097"
        d="M364.575,42.829a8.972,8.972,0,1,0-8.972-8.972,8.972,8.972,0,0,0,8.972,8.972"
        transform="translate(-338.738 -24.884)"
      />
      <path
        id="Path_24098"
        data-name="Path 24098"
        d="M390.079,71.753a7.426,7.426,0,0,0-.929-2.087,7.561,7.561,0,0,0-1.723-1.868,7.336,7.336,0,0,0-2.225-1.163,7.913,7.913,0,0,0-1.059-.257l-11.278-17.12a4.494,4.494,0,0,0-3.224-1.992V47.23a4.614,4.614,0,0,0-3.224,1.287,11.809,11.809,0,0,1-16.517,0,4.613,4.613,0,0,0-3.224-1.287h-.89v.045a4.5,4.5,0,0,0-3.182,2.037l-9.575,15a4.5,4.5,0,0,0,1.374,6.221,4.383,4.383,0,0,0,.5.272,4.533,4.533,0,0,0,.4.247l10.478,5.65v33.245a1.69,1.69,0,0,0,.528,1.213c3.876,3.724,8.482,1.04,8.482-2.719V92.189a2.917,2.917,0,1,1,5.834,0v17.761a1.692,1.692,0,0,0,.528,1.213c3.877,3.724,8.482,1.04,8.482-2.719v-47.7l6.293,9.554c-.01.023-.024.043-.034.067a7.805,7.805,0,0,0-.642,3.264v38.188c1.929,2.2,4.376.858,4.376-1.052V73.84a4.492,4.492,0,0,0,5.306-2.571,2.861,2.861,0,0,1,.483.545,3.548,3.548,0,0,1,.474,1.081l.044.347c.233,1.835,2.711,2.893,4.465.558-.122-.653-.142-1.374-.328-2.046m-44.291-5.285-2.561-1.381,2.561-4.014Z"
        transform="translate(-332.322 -29.286)"
      />
    </g>
  </svg>
</template>
