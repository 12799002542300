import OneGrid from '@assets/icons/modules/communication/line-oa/rich-message-template/OneGrid.vue';
import TwoGridVertical from '@assets/icons/modules/communication/line-oa/rich-message-template/TwoGridVertical.vue';
import TwoGridHorizontal from '@assets/icons/modules/communication/line-oa/rich-message-template/TwoGridHorizontal.vue';
import ThreeGridHorizontal from '@assets/icons/modules/communication/line-oa/rich-message-template/ThreeGridHorizontal.vue';
import ThreeGridOneToTwo from '@assets/icons/modules/communication/line-oa/rich-message-template/ThreeGridOneToTwo.vue';
import ThreeGridTwoToOne from '@assets/icons/modules/communication/line-oa/rich-message-template/ThreeGridTwoToOne.vue';
import FourGrid from '@assets/icons/modules/communication/line-oa/rich-message-template/FourGrid.vue';
import SixGrid from '@assets/icons/modules/communication/line-oa/rich-message-template/SixGrid.vue';

export const RICH_MESSAGE_TEMPLATE = [
  {
    key: 'one-grid',
    icon: OneGrid,
  },
  {
    key: 'two-grid-vertical',
    icon: TwoGridVertical,
  },
  {
    key: 'two-grid-horizontal',
    icon: TwoGridHorizontal,
  },
  {
    key: 'three-grid-horizontal',
    icon: ThreeGridHorizontal,
  },
  {
    key: 'three-grid-one-to-two',
    icon: ThreeGridOneToTwo,
  },
  {
    key: 'three-grid-two-to-one',
    icon: ThreeGridTwoToOne,
  },
  {
    key: 'four-grid',
    icon: FourGrid,
  },
  {
    key: 'six-grid',
    icon: SixGrid,
  },
];
