import { defineComponent, h, mergeProps, Prop, Transition } from 'vue';

// https://vuejs.org/guide/built-ins/transition.html#javascript-hooks
type TransitionEvent = 'beforeEnter' | 'enter' | 'afterEnter' | 'beforeLeave' | 'leave' | 'afterLeave';

export default defineComponent({
  name: 'ChocoTransitionContainer',
  props: {
    as: <Prop<any>>{},
    name: <Prop<string>>{ type: String, required: true },
    onEnter: {},
  },
  emits: ['beforeEnter', 'enter', 'afterEnter', 'beforeLeave', 'leave', 'afterLeave'],
  setup(props, { attrs, emit, slots }) {
    const delegateTransitionEvent = (name: TransitionEvent) => (...args: any[]) => emit(name, ...args);

    // render function
    return () =>
      h(props.as || 'div', mergeProps({ class: ['choco-transition-container'] }, attrs), [
        h(
          Transition,
          {
            name: props.name,
            // TODO: delegate all transition events
            onBeforeEnter: delegateTransitionEvent('beforeEnter'),
            onEnter: delegateTransitionEvent('enter'),
            onAfterAppear: delegateTransitionEvent('afterEnter'),
            onBeforeLeave: delegateTransitionEvent('beforeLeave'),
            onLeave: delegateTransitionEvent('enter'),
            onAfterLeave: delegateTransitionEvent('afterLeave'),
          },
          slots,
        ),
      ]);
  },
});
