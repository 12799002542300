<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18.761" height="20" viewBox="0 0 18.761 20">
    <path
      id="Path_501"
      data-name="Path 501"
      d="M470.934,461.552a9.381,9.381,0,0,0-6.912,15.723c.511.557-.447,2.261-1.432,3.972-.35.608,2.133.337,4.535-1.068.95-.555.855.135,3.809.135a9.381,9.381,0,1,0,0-18.761M470.68,477.5a1.447,1.447,0,1,1,1.447-1.447,1.448,1.448,0,0,1-1.447,1.447m2.469-6.24c-.649.374-1.262.728-1.262,1.384v.153a.246.246,0,0,1-.246.246h-1.923a.246.246,0,0,1-.245-.246v-.26c0-1.372,1.018-1.941,1.915-2.444l.068-.038c.771-.432,1.5-.841,1.5-1.7a1.542,1.542,0,0,0-.785-1.283,2.735,2.735,0,0,0-1.432-.425,2.967,2.967,0,0,0-2.5,1.459.246.246,0,0,1-.34.042l-1.144-.868a.246.246,0,0,1-.053-.337,4.757,4.757,0,0,1,4.245-2.247,4.679,4.679,0,0,1,2.923,1.079,3.4,3.4,0,0,1,1.341,2.6c0,1.688-1.1,2.32-2.062,2.878"
      transform="translate(-461.553 -461.552)"
    />
  </svg>
</template>
