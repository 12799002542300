import { onBeforeMount, ref, watch, reactive, Ref, onMounted } from 'vue';

import router from '@/router';
import api from '@services/api';

import dataConnectModel from '@models/dataConnect/dataConnect';
import BaseModal from '@/views/components/modal/BaseModal.vue';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import uploadIcon from '@assets/images/modules/data-connect/upload.vue';
import tableIcon from '@assets/images/modules/data-connect/table.vue';
import keyIcon from '@assets/images/modules/data-connect/key.vue';
import mappingIcon from '@assets/images/modules/data-connect/mapping.vue';
import checkIcon from '@assets/images/modules/data-connect/check.vue';
import { useI18n } from 'vue-i18n';
import XLSX from '@libs/xlsx/xlsx.full.min.js';

export default function useUploadFileDrawer(props: any) {
  const {
    confirmUploadExcelDataModel,
    summaryUploadDataModel,
    uploadDataSourceDataModel,
    updateProgressDataSourceDataModel,
    processDataSourceDataModel,
    getProcessDataSourceDataModel,
    confirmUploadDataSourceDataModel,
    downloadErrorReportFileHistoryModel,
    downloadDataSourceExcelDataSetModel,
  } = dataConnectModel();

  const { openSuccessModal, openWarningModal, openDefaultErrorModal } = useValidationModal();

  const uploadFileStep: any = [
    { title: 'data_connector.upload_file_progress_upload', icon: uploadIcon },
    { title: 'data_connector.upload_file_progress_confirm_data', icon: checkIcon },
  ];

  const pageStep: any = ref(0);
  const fileExcelName: any = ref('');
  const fileExcelData: any = ref({});
  const drawerLoading: any = ref(false);
  const isUploading: any = ref(false);
  const isUploadingLabel: any = ref('In Progress');
  const isUploadingPercent: any = ref(0);
  const isUploadingStatus: any = ref('active');
  const isLoading: any = ref(false);
  const isPreviewing: any = ref(false);
  const summaryData: any = ref([]);
  const errorSummaryData: any = ref([]);
  const canNext: any = ref(false);
  const isComplete: any = ref(false);
  const showModalCancel: any = ref(false);
  const selectReplaceMode: any = ref(1);
  const selectBackupMode: any = ref(true);
  const uploadProgressMode: any = ref(false);
  const previewTableColumn: any = ref([]);
  const previewTableData: any = ref([]);
  const downloadLink: any = ref(null);
  const csvHistoryId: any = ref(null);
  const dataSetName: any = ref('');
  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });

  let uploadingEvent: any = null;

  const { t } = useI18n();

  const cleanData = () => {
    fileExcelName.value = '';
    fileExcelData.value = {};
    selectReplaceMode.value = 1;
    canNext.value = false;
    pageStep.value = 0;
    isUploading.value = false;
    isUploadingLabel.value = 'In Progress';
    isUploadingPercent.value = 0;
    isUploadingStatus.value = 'active';
    isLoading.value = false;
    isComplete.value = false;
    uploadProgressMode.value = false;
    drawerLoading.value = false;
    isPreviewing.value = false;
    csvHistoryId.value = null;
    if (uploadingEvent != null) {
      clearInterval(uploadingEvent);
      uploadingEvent = null;
    }
    window.GlobalPreventClear('UploadCsvEvent');
    return props.onClose ? props.onClose() : () => {};
  };

  const onConfirmCancelUploadFile = async () => {
    props.setPageLoading(true);
    let params = {
      Custom_Csv_History_Id: csvHistoryId.value,
      Is_Confirm: false,
      Name: dataSetName.value,
    };

    let model: any = confirmUploadExcelDataModel;
    model.payload = params;
    api
      .apiRequest(model)
      .then(() => {
        props.setPageLoading(false);
        isPreviewing.value = false;
        showModalCancel.value = false;
        cleanData();
      })
      .catch((error) => {
        openDefaultErrorModal(error);
      });
  };

  const setShowModalCancel = (value: any) => {
    showModalCancel.value = value;
  };

  const summaryDataMapping = (respData: any) => {
    let resp = respData.data;
    let returnResult: Record<any, any> = {
      summary: [],
      errors: [],
    };

    returnResult.errors = resp.error_rows.map((data: any, idx: any) => {
      let dataResult = {
        key: idx,
        error: data,
      };
      return dataResult;
    });
    returnResult.summary = [
      {
        key: '1',
        status: t('data_connector.summary_upload_column_row_success'),
        quantity: String(resp.success_record),
      },
      {
        key: '2',
        status: t('data_connector.summary_upload_column_row_error'),
        quantity: String(resp.fail_record),
      },
      {
        key: '3',
        status: t('data_connector.summary_upload_column_row_total'),
        quantity: String(resp.total_record),
      },
    ];
    return returnResult;
  };

  const summaryUploadData = () => {
    props.setPageLoading(true);
    let params = {
      Custom_Csv_History_Id: csvHistoryId.value,
    };

    let model: any = summaryUploadDataModel;
    model.payload = params;
    api
      .apiRequest(model)
      .then((response) => {
        props.setPageLoading(false);
        pageStep.value = 1;
        let finalDataResult = summaryDataMapping(response);
        // [solved] TODO: use file-name (from response) instead dataset_name
        dataSetName.value = response.data.name;
        errorSummaryData.value = finalDataResult.errors;
        summaryData.value = finalDataResult.summary;
      })
      .catch((error) => {
        openDefaultErrorModal(error);
      });
  };

  const showModelCancel = () => {
    showModalCancel.value = true;
    if (pageStep.value == 1) {
      openWarningModal(
        'หากยกเลิกแล้วจะไม่สามารถกลับมาแก้ไขข้อมูลได้อีก',
        'ต้องการยืนยันใช่หรือไม่ ?',
        () => {
          cleanData();
        },
        () => {},
      );
    } else {
      openWarningModal(
        'ต้องการยกเลิกการนำเข้าข้อมูล',
        'ต้องการยกเลิกใช่หรือไม่ ?',
        () => {
          cleanData();
        },
        () => {},
      );
    }
  };

  const processDataSourceMapping = (response: any) => {
    const resp = response.data;
    const returnResult = {
      header: [],
      column: [],
      totalPage: resp.total_page,
      totalRow: resp.total_record,
    };

    returnResult.header = resp.headers.map((header: any) => ({
      label: header.disp_name,
      key: header.field_name,
    }));

    returnResult.column = resp.payload.map((content: any) => content);

    return returnResult;
  };

  const onSuccessProcessData = async (historyId: any) => {
    props.setPageLoading(true);

    const params: any = {};
    params.Custom_Csv_History_Id = historyId;
    params.Page = pagination.currentPage;
    params.Limit = pagination.perPage;

    const model: any = getProcessDataSourceDataModel;
    model.payload = params;
    api.apiRequest(model).then((response) => {
      props.setPageLoading(false);

      const tableDataResult = processDataSourceMapping(response);
      previewTableColumn.value = tableDataResult.header;
      previewTableData.value = tableDataResult.column;
      pagination.totalRows = tableDataResult.totalRow;
      canNext.value = true;
      isLoading.value = false;
      isComplete.value = true;
      isPreviewing.value = true;
    });
  };

  const renderLoadingData = async (historyId: any) => {
    canNext.value = false;
    isLoading.value = true;
    isPreviewing.value = false;
    isUploadingLabel.value = 'In Progress';
    isUploadingPercent.value = 0;
    isUploadingStatus.value = 'active';

    window.GlobalPreventSet('UploadCsvEvent', 1, showModelCancel);

    uploadingEvent = setInterval(async () => {
      let params: any = {};
      params.Custom_Csv_History_Id = historyId;

      let model: any = updateProgressDataSourceDataModel;
      model.payload = params;
      api
        .apiRequest(model)
        .then((response) => {
          if (response.data.progress == 100) {
            // Clear interval
            if (uploadingEvent != null) {
              clearInterval(uploadingEvent);
              uploadingEvent = null;
            }
            // Update progress success
            isUploadingLabel.value = 'Complete';
            isUploadingPercent.value = 100;
            isUploadingStatus.value = 'success';

            onSuccessProcessData(historyId);
          } else {
            // Update progress
            isUploadingPercent.value = response.data.progress;
          }
        })
        .catch((error) => {
          openDefaultErrorModal(error);
        });
    }, 5000);
  };

  const uploadFile = async () => {
    let params: any = {};
    params.Datasource_Id = localStorage.getItem('DataSourceId');
    params.Dataset_Id = props.template.id;
    params.Config_Upsert = selectReplaceMode.value;
    params.Config_Backup = selectBackupMode.value;
    params.File = fileExcelData.value;

    params = window.GlobalRequest.CalculateFormDataObject(params);

    let configs = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: any) => {
        let progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
        isUploadingPercent.value = progress;
      },
    };

    drawerLoading.value = true;
    isUploading.value = true;
    uploadProgressMode.value = true;
    window.GlobalPreventSet('UploadCsvEvent', 1, showModelCancel);

    let model: any = uploadDataSourceDataModel;
    model.payload = params;
    api.apiRequest(model).then((response) => {
      drawerLoading.value = false;
      isUploadingLabel.value = 'Complete';
      isUploadingPercent.value = 100;
      isUploadingStatus.value = 'success';
      csvHistoryId.value = response.data.custom_csv_history_id;
      renderLoadingData(response.data.custom_csv_history_id);
    });
  };

  const onConfirmUploadFile = async () => {
    props.setPageLoading(true);
    let params = {
      Custom_Csv_History_Id: csvHistoryId.value,
      Is_Confirm: true,
      Name: dataSetName.value,
    };

    let model: any = confirmUploadDataSourceDataModel;
    model.payload = params;
    api
      .apiRequest(model)
      .then((response) => {
        props.setPageLoading(false);
        isPreviewing.value = false;
        showModalCancel.value = false;
        cleanData();
        router.push({ path: '/dataConnector/excelFileHistory' });
      })
      .catch((error) => {
        openDefaultErrorModal(error);
      });
  };

  const onNextStep = () => {
    let conditionSwitch: Record<any, any> = {
      0: () => {
        return isPreviewing.value ? summaryUploadData() : uploadFile();
      },
      1: () => {
        return onConfirmUploadFile();
      },
    };

    let stepCondition = conditionSwitch[pageStep.value]();

    if (stepCondition == true) pageStep.value = Math.min(pageStep + 1, 3);
  };

  const onCloseDrawer = () => {
    if (props.pageLoading) return false;
    if (isLoading.value || isUploading.value || isComplete.value) {
      showModelCancel();
    } else {
      return cleanData();
    }
  };

  const onPreviousStep = () => {
    if (pageStep > 0) {
      return (pageStep.value = pageStep - 1);
    }

    return onCloseDrawer();
  };

  const onGetFileExcel = (fileData: any) => {
    fileExcelName.value = fileData.name;
    fileExcelData.value = fileData;
    canNext.value = true;
    pageStep.value = 0;
  };

  const onSelectReplaceMode = (value: any) => {
    selectReplaceMode.value = value;
  };

  const onSelectBackupMode = (value: any) => {
    selectBackupMode.value = value.target.checked;
  };

  const onPageSizeChange = async (value: any) => {
    props.setPageLoading(true);
    let params = {
      Page: 1,
      Limit: pagination.perPage,
      Custom_Csv_History_Id: csvHistoryId.value,
    };

    let model: any = getProcessDataSourceDataModel;
    model.payload = params;
    api.apiRequest(model).then((response) => {
      props.setPageLoading(false);

      let tableDataResult = processDataSourceMapping(response);
      previewTableColumn.value = tableDataResult.header;
      previewTableData.value = tableDataResult.column;
      pagination.totalRows = response.data.total_record;
      canNext.value = true;
      isLoading.value = false;
      isComplete.value = true;
      isPreviewing.value = true;
    });
  };

  const onJumpToPage = async (value: any) => {
    let params = {
      Page: Number(value),
      Limit: pagination.perPage,
      Custom_Csv_History_Id: csvHistoryId.value,
    };

    let model: any = getProcessDataSourceDataModel;
    model.payload = params;
    api.apiRequest(model).then((response) => {
      props.setPageLoading(false);

      let tableDataResult = processDataSourceMapping(response);
      previewTableColumn.value = tableDataResult.header;
      previewTableData.value = tableDataResult.column;
      pagination.totalRows = response.data.total_record;
      canNext.value = true;
      isLoading.value = false;
      isComplete.value = true;
      isPreviewing.value = true;
    });
  };

  const onChangePagination = async (page: any, pageSize: any) => {
    let params = {
      Page: page,
      Limit: pagination.perPage,
      Custom_Csv_History_Id: csvHistoryId.value,
    };

    let model: any = getProcessDataSourceDataModel;
    model.payload = params;
    api.apiRequest(model).then((response) => {
      props.setPageLoading(false);

      let tableDataResult = processDataSourceMapping(response);
      previewTableColumn.value = tableDataResult.header;
      previewTableData.value = tableDataResult.column;
      pagination.totalRows = response.data.total_record;
      canNext.value = true;
      isLoading.value = false;
      isComplete.value = true;
      isPreviewing.value = true;
    });
  };

  const onDownloadError = async () => {
    let params = {
      Custom_Csv_History_Id: csvHistoryId.value,
    };

    let model: any = downloadErrorReportFileHistoryModel;
    model.payload = params;
    api.apiRequest(model).then((response) => {
      if (response.data.has_error_report == true) {
        window.open(response.data.url);
      }
    });
  };

  const onDownloadTemplate = async () => {
    let params = {
      Dataset_Id: props.template.id,
    };

    let model: any = downloadDataSourceExcelDataSetModel;
    model.payload = params;
    api.apiRequest(model).then((response) => {
      window.open(response.data.url);
    });
  };

  const onChangeDataSetName = (name: any) => {
    dataSetName.value = name;
  };

  onMounted(() => {});

  return {
    uploadFileStep,
    pagination,
    isLoading,
    isUploading,
    previewTableColumn,
    previewTableData,
    isPreviewing,
    pageStep,
    summaryData,
    errorSummaryData,
    fileExcelName,
    canNext,
    showModalCancel,
    isComplete,
    onConfirmCancelUploadFile,
    setShowModalCancel,
    onNextStep,
    onPreviousStep,
    onCloseDrawer,
    onGetFileExcel,
    onConfirmUploadFile,
    selectReplaceMode,
    selectBackupMode,
    onSelectReplaceMode,
    onSelectBackupMode,
    onPageSizeChange,
    onJumpToPage,
    onChangePagination,
    uploadProgressMode,
    isUploadingLabel,
    isUploadingPercent,
    isUploadingStatus,
    drawerLoading,
    dataSetName,
    onDownloadError,
    onChangeDataSetName,
    onDownloadTemplate,
    t,
  };
}
