export default function resumeCampaignModel() {
  const ListPauseCampaignStatModel: API.RequestModel<{ Module: string }> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignResume/ListPauseCampaignStat`,
    method: 'POST',
    payload: {
      Module: '',
    },
  };

  //modal
  const GetCampaignToResumeDetailModel: API.RequestModel<{ CampaignId: number }> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignResume/GetCampaignToResumeDetail`,
    method: 'POST',
    payload: {
      CampaignId: 0,
    },
  };

  //list table
  const CampaignPausedTableModel: API.RequestModel<Resume.CampaignPausedTableModel> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignResume/ListPauseCampaign`,
    method: 'POST',
    payload: {
      Module: '',
      Filters: [],
      Ordering: [],
      Page: 1,
      Limit: 5,
    },
  };

  //confirmResumeCampaignModel
  const ResumeCampaignModel: API.RequestModel<Resume.ResumeCampaignModel> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignResume/ResumeCampaign`,
    method: 'POST',
    payload: {
      schedule_mode: 0,
      schedule_dt: new Date(),
      resend_mode: 0,
      send_to_previous_mode: 0,
      list_specify_round: [],
      module: '',
      list_Channel: [],
      list_campaign: [],
      selection_Mode: 0,
      filters: [],
    },
  };
  //GetResumeCreditToBeUsed
  const GetResumeCreditToBeUsedModel: API.RequestModel<Resume.GetResumeCreditToBeUsedModel> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/CampaignResume/GetResumeCreditToBeUsed`,
    method: 'POST',
    payload: { Module: '', List_Channel: [0], List_Campaign: [{ module: '', id: 0 }], Selection_Mode: 0, Filters: [] },
  };

  return {
    ListPauseCampaignStatModel,
    GetCampaignToResumeDetailModel,
    CampaignPausedTableModel,
    ResumeCampaignModel,
    GetResumeCreditToBeUsedModel,
  };
}
