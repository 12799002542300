<template>
  <svg
    id="Group_34508"
    data-name="Group 34508"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="27.659"
    height="15.564"
    viewBox="0 0 27.659 15.564"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_14565" data-name="Rectangle 14565" width="27.659" height="15.564" />
      </clipPath>
    </defs>
    <g id="Group_33558" data-name="Group 33558" clip-path="url(#clip-path)">
      <path
        id="Path_22503"
        data-name="Path 22503"
        d="M177.084,96.235a.228.228,0,0,0,.209-.226v-2.25a1.431,1.431,0,0,0-1.43-1.43H157.288a1.431,1.431,0,0,0-1.43,1.43v2.476l.253-.006h.028a2.04,2.04,0,0,1,0,4.08h-.029l-.252-.007v2.476a1.431,1.431,0,0,0,1.43,1.43h18.575a1.431,1.431,0,0,0,1.43-1.43v-2.46l-.209-.016a2.039,2.039,0,0,1,0-4.066m-17.207,2.033a3.765,3.765,0,0,0-2.321-3.459v-.782h18.039v.887a3.739,3.739,0,0,0,0,6.708v.887H157.556v-.782a3.765,3.765,0,0,0,2.321-3.459"
        transform="translate(-149.634 -88.642)"
      />
      <path
        id="Path_22504"
        data-name="Path 22504"
        d="M321.677,161.543a1.636,1.636,0,1,0-1.636-1.636,1.638,1.638,0,0,0,1.636,1.636m0-2.033a.4.4,0,1,1-.4.4.4.4,0,0,1,.4-.4"
        transform="translate(-307.261 -151.952)"
      />
      <path
        id="Path_22505"
        data-name="Path 22505"
        d="M447.665,240.363a1.636,1.636,0,1,0,1.636-1.636,1.638,1.638,0,0,0-1.636,1.636m2.033,0a.4.4,0,1,1-.4-.4.4.4,0,0,1,.4.4"
        transform="translate(-429.789 -229.194)"
      />
      <path
        id="Path_22506"
        data-name="Path 22506"
        d="M342.461,168.33a.733.733,0,0,0,1.252.518l4.876-4.876a.733.733,0,1,0-1.037-1.037l-4.876,4.876a.728.728,0,0,0-.215.518"
        transform="translate(-328.786 -156.222)"
      />
      <path
        id="Path_22507"
        data-name="Path 22507"
        d="M4.741,10.179H1.7V9.4a3.738,3.738,0,0,0,0-6.918V1.7H19.737v.5a.849.849,0,0,0,1.7,0V1.43A1.431,1.431,0,0,0,20.005,0H1.43A1.431,1.431,0,0,0,0,1.43V3.906L.253,3.9H.281a2.04,2.04,0,1,1,0,4.08H.252L0,7.971v2.476a1.431,1.431,0,0,0,1.43,1.43H4.741a.849.849,0,0,0,0-1.7"
      />
    </g>
  </svg>
</template>
