import { ref, Ref, getCurrentInstance } from 'vue';

interface Props {
  tabItemProps: Bi.BiTab.Item;
  isOpen: boolean;
}

export default function useDeleteModal(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const tabName: Ref<string> = ref(props.tabItemProps.name);

  const onClose = () => {
    vm?.$emit('on-close');
  };

  const onSubmit = () => {
    vm?.$emit('on-submit', tabName.value);
  };

  return { tabName, onClose, onSubmit };
}
