<template>
  <svg
    id="Group_37517"
    data-name="Group 37517"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="15.734"
    height="13.796"
    viewBox="0 0 15.734 13.796"
  >
    <g id="Group_37517-2" data-name="Group 37517">
      <path
        id="Path_24354"
        data-name="Path 24354"
        d="M15.127,2.862H10.739V.621A.583.583,0,0,0,10.2,0H5.623a.583.583,0,0,0-.536.621V2.862H.607a.661.661,0,0,0-.607.7v9.528a.661.661,0,0,0,.607.7h14.52a.661.661,0,0,0,.607-.7V3.565a.661.661,0,0,0-.607-.7M6.167,1.251H9.659V2.862H6.167Zm8.486,11.294H1.08V8.18H4.632v.531a1.175,1.175,0,0,0,1.08,1.251h4.309A1.175,1.175,0,0,0,11.1,8.711V8.18h3.552ZM10.021,6.929V8.711H5.713V6.929H1.08V4.113H14.654V6.929Z"
        transform="translate(0)"
        fill="#c3c3c3"
      />
    </g>
  </svg>
</template>
