<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="11.347" height="10.638" viewBox="0 0 11.347 10.638">
    <g id="Group_966" data-name="Group 966" transform="translate(0 5.428) rotate(-49)">
      <path
        id="Subtraction_10"
        data-name="Subtraction 10"
        d="M3.6,7.192a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,3.6,7.192ZM3.6,1.2a2.4,2.4,0,1,0,2.4,2.4A2.4,2.4,0,0,0,3.6,1.2Z"
        transform="translate(0 0)"
        fill="#6087ad"
      />
      <rect
        id="Rectangle_395"
        data-name="Rectangle 395"
        width="1.199"
        height="4.795"
        rx="0.599"
        transform="translate(2.997 6.593)"
        fill="#6087ad"
      />
    </g>
  </svg>
</template>
