import { onBeforeMount, getCurrentInstance, Ref, ref } from 'vue';
interface Props {
  templateCategoryList: Segment.TemplateSegmentListCard[];
}
export default function useSetCardList(props: Props) {
  const idTemplateSelect: Ref<number> = ref(0);
  const templateCategoryListIsOpen: Ref<any[]> = ref([]);
  const vm = getCurrentInstance()?.proxy;

  const onClickArrow = (index: number) => {
    templateCategoryListIsOpen.value[index].isOpen = !templateCategoryListIsOpen.value[index].isOpen;
    setTimeout(() => {
      onClickCard(idTemplateSelect.value);
    });
  };

  const onClickCard = (id: number) => {
    idTemplateSelect.value = id;
    const containerActive = document.querySelectorAll('.card-template-active');
    containerActive.forEach((eleActive: any) => {
      (<HTMLInputElement>eleActive).classList.remove('card-template-active');
      (<HTMLInputElement>eleActive).classList.add('card-template');
    });
    const container = document.querySelectorAll(`.card-template-${id}`);
    container.forEach((ele: any) => {
      (<HTMLInputElement>ele).classList.remove('card-template');
      (<HTMLInputElement>ele).classList.add('card-template-active');
    });
    vm?.$emit('on-click-card', id);
  };
  const onClickPreviewTemplate = (id: number) => {
    vm?.$emit('on-click-preview-template', id);
  };

  const addIsOpen = () => {
    if (props.templateCategoryList.length != 0) {
      const hasKey = props.templateCategoryList[0].hasOwnProperty('isOpen');
      if (!hasKey) {
        props.templateCategoryList.forEach((Category) => {
          templateCategoryListIsOpen.value.push({
            ...Category,
            isOpen: true,
          });
        });
      }
    }
  };

  onBeforeMount(() => {
    addIsOpen();
  });

  return { onClickCard, onClickPreviewTemplate, onClickArrow, templateCategoryListIsOpen };
}
