import { ref, Ref, markRaw, reactive, onMounted, computed, onBeforeUnmount, watch, getCurrentInstance, nextTick, onUpdated } from 'vue';
import {
  actionIconList,
  addContentUI,
  addImageUI,
  addSocialUI,
  addVideoUI,
  addScanUI,
  defaultSocialPropertiesDev,
  defaultSocialPropertiesProd,
  defaultSocialStyle,
  defaultHtmlContent,
} from '../../../../constants/constant-edm';
//content
import ImageIcon from '@assets/icons/modules/communication/edm-editor/content/ImageIcon.vue';
import TextIcon from '@assets/icons/modules/communication/edm-editor/content/TextIcon.vue';
import ButtonIcon from '@assets/icons/modules/communication/edm-editor/content/ButtonIcon.vue';
import VideoIcon from '@assets/icons/modules/communication/edm-editor/content/VideoIcon.vue';
import SocialIcon from '@assets/icons/modules/communication/edm-editor/content/SocialIcon.vue';
import DividerIcon from '@assets/icons/modules/communication/edm-editor/content/DividerIcon.vue';
import BarcodeIcon from '@assets/icons/modules/communication/edm-editor/content/BarcodeIcon.vue';
//layout
import OneLayoutIcon from '@assets/icons/modules/communication/edm-editor/layout/OneLayoutIcon.vue';
import TwoLayoutIcon from '@assets/icons/modules/communication/edm-editor/layout/TwoLayoutIcon.vue';
import ThreeLayoutIcon from '@assets/icons/modules/communication/edm-editor/layout/ThreeLayoutIcon.vue';
import FourLayoutIcon from '@assets/icons/modules/communication/edm-editor/layout/FourLayoutIcon.vue';
import OneToTwoLayoutIcon from '@assets/icons/modules/communication/edm-editor/layout/OneToTwoLayoutIcon.vue';
import TwoToOneLayoutIcon from '@assets/icons/modules/communication/edm-editor/layout/TwoToOneLayoutIcon.vue';

import mailTrackerImg from '@assets/images/modules/communication/mail-tracker.png';

import useQuillTextEditor from '../../../../hooks/useQuillTextEditor';

interface Props {
  isSaved: boolean;
  edmConfigJson: string;
  isClearUi?: boolean;
  isPersonalizeFromTemplate?: boolean;
  templateMode?: string;
  readOnly?: boolean;
}

export default function useEdmEditor(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const toolbarRef: Ref = ref(null);

  const designNodeConstantList: Ref<Communication.EdmEditor.DesignNode[]> = ref([
    {
      id: 0,
      category: 'content',
      type: 'image',
      title: 'Image',
      subTitle: '',
      icon: markRaw(ImageIcon),
      info: '',
      editable: true,
      payload: {},
    },
    {
      id: 0,
      category: 'content',
      type: 'text',
      title: 'Text',
      subTitle: '',
      icon: markRaw(TextIcon),
      info: '',
      editable: true,
      payload: {},
    },
    {
      id: 0,
      category: 'content',
      type: 'video',
      title: 'Video',
      subTitle: '',
      icon: markRaw(VideoIcon),
      info: '',
      editable: true,
      payload: {},
    },
    {
      id: 0,
      category: 'content',
      type: 'social',
      title: 'Social',
      subTitle: '',
      icon: markRaw(SocialIcon),
      info: '',
      editable: true,
      payload: {},
    },
    {
      id: 0,
      category: 'content',
      type: 'scan-obj',
      title: 'Barcode/QR',
      subTitle: '',
      icon: markRaw(BarcodeIcon),
      info: '',
      editable: true,
      payload: {},
    },
    {
      id: 0,
      category: 'layout',
      type: 'layout-one',
      title: '1',
      subTitle: '',
      icon: markRaw(OneLayoutIcon),
      info: '',
      editable: true,
      payload: {},
    },
    {
      id: 0,
      category: 'layout',
      type: 'layout-two',
      title: '2',
      subTitle: '',
      icon: markRaw(TwoLayoutIcon),
      info: '',
      editable: true,
      payload: {},
    },
    {
      id: 0,
      category: 'layout',
      type: 'layout-three',
      title: '3',
      subTitle: '',
      icon: markRaw(ThreeLayoutIcon),
      info: '',
      editable: true,
      payload: {},
    },
    {
      id: 0,
      category: 'layout',
      type: 'layout-four',
      title: '4',
      subTitle: '',
      icon: markRaw(FourLayoutIcon),
      info: '',
      editable: true,
      payload: {},
    },
    {
      id: 0,
      category: 'layout',
      type: 'layout-one-to-two',
      title: '1/3 : 2/3',
      subTitle: '',
      icon: markRaw(OneToTwoLayoutIcon),
      info: '',
      editable: true,
      payload: {},
    },
    {
      id: 0,
      category: 'layout',
      type: 'layout-two-to-one',
      title: '2/3 : 1/3',
      subTitle: '',
      icon: markRaw(TwoToOneLayoutIcon),
      info: '',
      editable: true,
      payload: {},
    },
  ]);

  let interVal: any = null;
  let currentQuillText = '';
  const editorId = 'cdp-edm-editor';
  const selectedNode: Ref<Communication.EdmEditor.DesignNode> = ref(null!);

  const hoverElement: Communication.EdmEditor.EdmElement = reactive({
    id: null!,
    rowSide: null,
    colSide: null,
    element: null,
    type: null,
    tdElement: null,
  });

  const selectedElement: Communication.EdmEditor.EdmElement = reactive({
    id: null!,
    rowSide: null,
    colSide: null,
    element: null,
    type: null,
    tdElement: null,
    tdList: null,
  });

  const freezeElement: Communication.EdmEditor.EdmElement = reactive({
    id: null!,
    rowSide: null,
    colSide: null,
    element: null,
    type: null,
    tdElement: null,
  });

  const adjustObj: any = reactive({
    col1: null,
    col2: null,
  });

  const mapper: any = reactive({
    trackingUrl: {
      mapperType: 10,
      subType: 2,
      linkValue: null,
      tag: null,
    },
    trackingOpenMail: {
      mapperType: 12,
      subType: 1,
      tag: null,
    },
  });

  const mapperList: Ref<Campaign.Personalize[]> = ref([]);
  const tagNumber: Ref<number> = ref(-1);
  const selectedButton: Ref<any> = ref(null!);
  //personalize
  const isEditPersonalize: Ref<boolean> = ref(false);
  const personalizeObjColumn: Ref<Campaign.Personalize> = ref(null!);
  const isMovingPersonalize: Ref<boolean> = ref(false);
  //coupon
  const isEditCoupon: Ref<boolean> = ref(false);
  const couponObjColumn: Ref<Campaign.CouponColumn> = ref(null!);
  const isMovingCoupon: Ref<boolean> = ref(false);
  const isCouponScanMode: Ref<boolean> = ref(false);
  const selectedCoupon: Ref<Campaign.CouponSet> = ref(null!);
  const listCouponColumn: Ref<Campaign.CouponColumn> = ref(null!);

  const srefList: Ref<string[]> = ref([]);
  let dragElement: Communication.EdmEditor.CoreEdmElement = null!;
  let duplicateElement: Communication.EdmEditor.CoreEdmElement = null!;
  let isDuplicating = false;
  let isDragging = false;
  let isSelected = false;
  let isAdjustSize = false;
  let mouseDownObjType = '';
  const backupSelected: any = reactive({
    element: null,
    event: null,
  });

  /* quill state management */
  const saveQuillTextToEDM = () => {
    let str: string = QuillGetHtml(quillInstance) as string;
    const htmlElement = new DOMParser().parseFromString(str, 'text/html');
    htmlElement.querySelectorAll('a').forEach((element: HTMLElement) => {
      mapper.trackingUrl.linkValue = element.getAttribute('href');
      const trackingMapper: Campaign.TrackingMapper = JSON.parse(element.getAttribute('value') as string);
      if (trackingMapper?.tag) {
        mapper.trackingUrl.tag = trackingMapper.tag;
      } else {
        mapper.trackingUrl.tag = `{{mp:${tagNumber.value}}}`;
        tagNumber.value--;
      }
      element.setAttribute('value', JSON.stringify(mapper.trackingUrl));
    });
    str = htmlElement.body.innerHTML;

    EDMGetObjList(editorId)[selectedElement.id].Properties.Content.Properties.Text = encodeURI(`<span class='edm-rendered-html'>${str}</span>`);
    EDMGetObjList(editorId)[selectedElement.id].Properties.Content.Properties.RawText = encodeURI(str);
    EDMGetProperties(editorId).mapper = filterMapper();
    vm?.$emit('on-get-edm-config-json', EDMRenderSave(editorId));
    vm?.$emit('on-get-edm-content', EDMRenderResult(editorId));
  };

  let quillInstance: any = null!;
  const quillSelectionRange: Ref<{ index: number; length: number }> = ref({
    index: 0,
    length: 0,
  });
  const quillInstanceWrapper: object = {
    saveQuillTextToEDM,
    quillInstance,
  };
  const { QuillInitInstance, QuillSetHtml, QuillGetHtml } = useQuillTextEditor();
  /* end */

  const removeEdmUi = (uiElement: any) => {
    if (uiElement.element != null) {
      uiElement.element.parentNode.removeChild(uiElement.element);
      uiElement.element = null;
      uiElement.id = null!;
      uiElement.rowSide = null;
      uiElement.colSide = null;
      uiElement.type = null;
      if (uiElement.tdElement) {
        uiElement.tdElement.classList.remove('edm-content-hover');
        uiElement.tdElement = null;
      }
      if (uiElement.tdList) {
        for (const td of uiElement.tdList) {
          td.classList.remove('edm-content-selector');
          td.childNodes[0].remove();
        }
        uiElement.tdList = null;
      }
    }
  };

  const renderSelectedUI = () => {
    const selectedUI = document.createElement('div');
    //class
    selectedUI.classList.add('edm-selected');
    //append
    return {
      selectedUI,
    };
  };

  const renderSelectedWithAction = () => {
    const element = renderSelectedUI();
    //left
    const widthContent = document.createElement('div');
    const rowManageActionGroupLeft = document.createElement('div');
    const addRowButtonLeft = document.createElement('div');
    const deleteRowButtonLeft = document.createElement('div');
    //right
    const rowManageActionGroupRight = document.createElement('div');
    const addRowButtonRight = document.createElement('div');
    const deleteRowButtonRight = document.createElement('div');
    //class
    widthContent.classList.add('width-content');
    //left
    rowManageActionGroupLeft.classList.add('row-action-group', 'left');
    addRowButtonLeft.classList.add('add-remove-row-button', 'left');
    deleteRowButtonLeft.classList.add('add-remove-row-button', 'left');
    //right
    rowManageActionGroupRight.classList.add('row-action-group', 'right');
    addRowButtonRight.classList.add('add-remove-row-button', 'right');
    deleteRowButtonRight.classList.add('add-remove-row-button', 'right');
    //append
    //left
    rowManageActionGroupLeft.append(addRowButtonLeft, deleteRowButtonLeft);
    addRowButtonLeft.innerHTML = '+';
    deleteRowButtonLeft.innerHTML = '-';
    //right
    rowManageActionGroupRight.append(addRowButtonRight, deleteRowButtonRight);
    addRowButtonRight.innerHTML = '+';
    deleteRowButtonRight.innerHTML = '-';
    element.selectedUI.append(rowManageActionGroupLeft, widthContent, rowManageActionGroupRight);
    return {
      ...element,
      rowManageActionGroupLeft,
      rowManageActionGroupRight,
      addRowButtonLeft,
      addRowButtonRight,
      deleteRowButtonLeft,
      deleteRowButtonRight,
    };
  };

  const renderHoverUI = () => {
    const hoverUI = document.createElement('div');
    const selectOverlay = document.createElement('div');
    //class
    hoverUI.classList.add('edm-selected');
    selectOverlay.classList.add('selected-overlay');
    //append
    hoverUI.appendChild(selectOverlay);
    return {
      hoverUI,
      selectOverlay,
    };
  };

  const renderHoverWithDraggableHandle = () => {
    const element = renderHoverUI();
    const draggableHandle = document.createElement('div');
    const draggableCursor = document.createElement('div');
    element.hoverUI.appendChild(draggableHandle);
    draggableHandle.appendChild(draggableCursor);
    draggableHandle.classList.add('draggable-handle');
    draggableCursor.classList.add('draggable-cursor');
    draggableCursor.innerHTML += '.. .. .. ..';
    //event
    draggableHandle.addEventListener('mousedown', () => {
      mouseDownObjType = 'drag-handle';
    });
    return { ...element, ...{ draggableHandle, draggableCursor } };
  };

  const renderHoverWithActionGroup = () => {
    const element = renderHoverWithDraggableHandle();
    const actionGroup = document.createElement('div');
    const editButton = document.createElement('div');
    const duplicateButton = document.createElement('div');
    const deleteButton = document.createElement('div');
    //class
    actionGroup.classList.add('action-group-list');
    editButton.classList.add('action-button');
    duplicateButton.classList.add('action-button');
    deleteButton.classList.add('action-button');
    //append
    element.hoverUI.appendChild(actionGroup);
    actionGroup.append(editButton, duplicateButton, deleteButton);
    editButton.innerHTML = actionIconList.editIcon;
    duplicateButton.innerHTML = actionIconList.duplicateIcon;
    deleteButton.innerHTML = actionIconList.deleteIcon;
    return { ...element, ...{ actionGroup, editButton, duplicateButton, deleteButton } };
  };

  const renderDragLineRow = () => {
    const rowLine = document.createElement('div');
    const circleLeft = document.createElement('div');
    const circleRight = document.createElement('div');
    circleLeft.classList.add('circle-left');
    circleRight.classList.add('circle-right');
    rowLine?.append(circleLeft, circleRight);
    return { rowLine };
  };

  const renderDragLineCol = () => {
    const colLine = document.createElement('div');
    const circleTop = document.createElement('div');
    const circleBottom = document.createElement('div');
    circleTop.classList.add('circle-top');
    circleBottom.classList.add('circle-bottom');
    colLine?.append(circleTop, circleBottom);
    return { colLine };
  };

  const calculateWidthCol = (col: any) => {
    const contentData = EDMGetContentFromCol(editorId, col.Id as string);
    const tableDataLength = EDMGetEditorRunTime(editorId).Config.TableDataLength;
    if (
      contentData.Properties.Content.Type == 'Image' ||
      contentData.Properties.Content.Type == 'Video' ||
      contentData.Properties.Content.Type == 'Scanner'
    ) {
      const removePadding = contentData.Properties.Content.Properties.SummaryPadding;
      const colWidthMem: number = contentData.Properties.Content.Properties.WidthMemory;
      contentData.Properties.Content.Properties.Width = Math.min(col.Properties.Length * tableDataLength - removePadding, colWidthMem);
    }
  };

  const removeSrefFromRow = (col: any) => {
    const contentData = EDMGetContentFromCol(editorId, col.Id as string);
    if (contentData.Properties.Content.Type == 'Image' && contentData.Properties.Content.Properties.ImageData.UploadImage_Sref) {
      vm?.$emit('on-delete-file', contentData.Properties.Content.Properties.ImageData.UploadImage_Sref);
    }
  };

  const handleHoverElement = (element: Communication.EdmEditor.CoreEdmElement, e: any) => {
    if (!isDragging && !isSelected) {
      const colUI = document.querySelector(`#${element.Id}`);
      const rowUI = document.querySelector(`#${element.RowId}>.edm-editor-row-selector-ui-overlay`);
      const id = element.ColId || element.RowId;
      if (hoverElement.id != id || hoverElement.type != element.Type) {
        hoverElement.id = id;
        hoverElement.type = element.Type;
        removeEdmUi(hoverElement);
        if (hoverElement.id != null) {
          const uiElement = renderHoverWithActionGroup();
          ///add class
          hoverElement.element = uiElement.hoverUI;
          if (element.Type == 'Row' || element.Type == 'Col' || element.Obj.Properties.Content.Type == 'LayoutBlank') {
            rowUI?.appendChild(hoverElement.element);
            uiElement.editButton.setAttribute('style', 'border-top-left-radius: 0.2rem;border-bottom-left-radius: 0.2rem;');
            uiElement.duplicateButton.setAttribute('style', 'border-top-left-radius: 0.2rem;border-bottom-left-radius: 0.2rem;');
            uiElement.deleteButton.setAttribute('style', 'border-top-left-radius: 0.2rem;border-bottom-left-radius: 0.2rem;');
            ///add eventlistener
            uiElement.editButton.addEventListener('mousedown', () => {
              removeEdmUi(hoverElement);
              backupSelected.element = element;
              backupSelected.event = e;
              handleSelectedElement(element, e as Event);
            });
            uiElement.duplicateButton.addEventListener('mousedown', () => {
              EDMDuplicateRow(editorId, hoverElement.id, { TargetObjId: hoverElement.id, TargetAddType: 'After' });
              EDMRender(editorId);
              removeEdmUi(hoverElement);
            });
            uiElement.deleteButton.addEventListener('mousedown', () => {
              const colList = EDMGetRowMapping(editorId, element.RowId).Col;
              for (let i = 0; i < colList.length; i++) {
                const colProp = EDMGetObjList(editorId)[colList[i].Id];
                removeSrefFromRow(colProp);
              }
              EDMRemoveRow(editorId, hoverElement.id);
              if (EDMGetObjMappingList(editorId).length <= 0) {
                const rowId = EDMAddRow(editorId, {
                  Properties: {
                    EnableBackground: true,
                    BackgroundImage: null,
                    Padding: {
                      Top: 0,
                      Bottom: 0,
                    },
                    Style: {
                      width: '600px',
                      'background-color': '#FFFFFF',
                      'background-image': null,
                    },
                  },
                });
                const colId = EDMAddCol(editorId, rowId);
                EDMAddContent(editorId, colId, { Type: 'LayoutBlank', Properties: {} }, { Properties: { HTMLUI: addContentUI } });
              }
              EDMRender(editorId);
              removeEdmUi(hoverElement);
              EDMGetProperties(editorId).mapper = filterMapper();
            });
          } else if (element.Type !== 'Content' || element.Obj.Properties.Content.Type !== 'LayoutBlank') {
            colUI?.appendChild(hoverElement.element);
            uiElement.editButton.setAttribute('style', 'border-top-right-radius: 0.2rem;border-top-left-radius: 0.2rem;');
            uiElement.duplicateButton.setAttribute('style', 'border-top-right-radius: 0.2rem;border-top-left-radius: 0.2rem;');
            uiElement.deleteButton.setAttribute('style', 'border-top-right-radius: 0.2rem;border-top-left-radius: 0.2rem;');
            uiElement.actionGroup.setAttribute('style', 'flex-direction:row;transform:translateY(-27px);');
            //addEventlistener
            uiElement.editButton.addEventListener('mousedown', () => {
              removeEdmUi(hoverElement);
              backupSelected.element = element;
              backupSelected.event = e;
              handleSelectedElement(element, e as Event);
            });
            uiElement.duplicateButton.addEventListener('mousedown', () => {
              removeEdmUi(hoverElement);
              isDuplicating = true;
              duplicateElement = element;
            });
            uiElement.deleteButton.addEventListener('mousedown', () => {
              const colList = EDMGetRowMapping(editorId, element.RowId).Col;
              removeSrefFromRow(element.ColObj);
              EDMRemoveCol(editorId, element.RowId, hoverElement.id);
              //cal new width
              for (let i = 0; i < colList.length; i++) {
                const colProp = EDMGetObjList(editorId)[colList[i].Id];
                calculateWidthCol(colProp);
              }
              //if no column
              if (colList.length <= 0) {
                EDMRemoveRow(editorId, element.RowId);
                for (let i = 0; i < colList.length; i++) {
                  const colProp = EDMGetObjList(editorId)[colList[i].Id];
                  removeSrefFromRow(colProp);
                }
              }
              if (EDMGetObjMappingList(editorId).length <= 0) {
                const rowId = EDMAddRow(editorId, {
                  Properties: {
                    EnableBackground: true,
                    BackgroundImage: null,
                    Padding: {
                      Top: 0,
                      Bottom: 0,
                    },
                    Style: {
                      width: '600px',
                      'background-color': '#FFFFFF',
                      'background-image': null,
                    },
                  },
                });
                const colId = EDMAddCol(editorId, rowId);
                EDMAddContent(editorId, colId, { Type: 'LayoutBlank', Properties: {} }, { Properties: { HTMLUI: addContentUI } });
              }
              EDMRender(editorId);
              removeEdmUi(hoverElement);
              EDMGetProperties(editorId).mapper = filterMapper();
            });
          }
        }
      }
    } else if (mouseDownObjType == 'drag-handle') {
      if (e.y <= 200) {
        if (interVal == null) {
          const mainContent = document.querySelector('.main-content');
          interVal = setInterval(() => {
            scrollContent(mainContent, -10);
          }, 10);
        }
      } else if (e.y >= window.innerHeight - 200) {
        if (interVal == null) {
          const mainContent = document.querySelector('.main-content');
          interVal = setInterval(() => {
            scrollContent(mainContent, 10);
          }, 10);
        }
      } else if (interVal != null) {
        clearInterval(interVal as number);
        interVal = null;
      }
      const colUI = document.querySelector(`#${element.ColId}`);
      const rowUI = document.querySelector(`#${element.RowId}>.edm-editor-row-selector-ui-overlay`);
      if (hoverElement.id != element.RowId || hoverElement.rowSide != element.Option.RowSide || hoverElement.colSide != element.Option.ColSide) {
        hoverElement.id = element.RowId || element.Id;
        hoverElement.rowSide = element.Option.RowSide;
        hoverElement.colSide = element.Option.ColSide;
        removeEdmUi(hoverElement);

        if (hoverElement.id != null) {
          if (element.Type == 'Row' || element.Type == 'Col') {
            const lineElement = renderDragLineRow();
            hoverElement.element = lineElement.rowLine;
            rowUI?.appendChild(hoverElement.element);

            if (hoverElement.rowSide == 'Before') {
              lineElement.rowLine.classList.add('row-line-before');
            } else {
              lineElement.rowLine.classList.add('row-line-after');
            }
          } else if (element.Type == dragElement.Type && element.Obj.Properties.Content.Type !== 'LayoutBlank') {
            const lineElement = renderDragLineCol();
            hoverElement.element = lineElement.colLine;
            hoverElement.tdElement = colUI?.closest('td');
            hoverElement.tdElement.classList.add('edm-content-hover');
            colUI?.closest('td')?.querySelector('.edm-editor-col-selector-ui-overlay')?.appendChild(hoverElement.element);

            if (hoverElement.colSide == 'Before') {
              lineElement.colLine.classList.add('col-line-left');
            } else {
              lineElement.colLine.classList.add('col-line-right');
            }
          }
        }
      }
    } else if (isAdjustSize && element.ColId) {
      if (adjustObj.col1 == element.ColId || adjustObj.col2 == element.ColId) {
        const resizeObj = EDMCalculateColResizeDrag(editorId, 3);
        if (resizeObj.Col2Id !== null) {
          if (
            (adjustObj.col1 == resizeObj.Col1Id || adjustObj.col1 == resizeObj.Col2Id) &&
            (adjustObj.col2 == resizeObj.Col1Id || adjustObj.col2 == resizeObj.Col2Id)
          ) {
            const edmObj = EDMGetObjList(editorId);
            if (
              edmObj[resizeObj.Col1Id].Properties.Length !== resizeObj.Col1Length ||
              edmObj[resizeObj.Col2Id].Properties.Length !== resizeObj.Col2Length
            ) {
              edmObj[resizeObj.Col1Id].Properties.Length = resizeObj.Col1Length;
              edmObj[resizeObj.Col2Id].Properties.Length = resizeObj.Col2Length;
              const col1 = EDMGetContentFromCol(editorId, resizeObj.Col1Id as string);
              const col2 = EDMGetContentFromCol(editorId, resizeObj.Col2Id as string);
              const tableDataLength = EDMGetEditorRunTime(editorId).Config.TableDataLength;
              if (col1.Properties.Content.Type == 'Image' || col1.Properties.Content.Type == 'Video') {
                const removePadding = col1.Properties.Content.Properties.SummaryPadding;
                const col1WidthMem: number = col1.Properties.Content.Properties.WidthMemory - removePadding;
                col1.Properties.Content.Properties.Width = Math.min(resizeObj.Col1Length * tableDataLength - removePadding, col1WidthMem);
              }
              if (col2.Properties.Content.Type == 'Image' || col2.Properties.Content.Type == 'Video') {
                const removePadding = col2.Properties.Content.Properties.SummaryPadding;
                const col2WidthMem: number = col2.Properties.Content.Properties.WidthMemory - removePadding;
                col2.Properties.Content.Properties.Width = Math.min(resizeObj.Col2Length * tableDataLength - removePadding, col2WidthMem);
              }
              removeEdmUi(hoverElement);
              EDMRender(editorId);
              setIsSelected();
              handleSelectedElement(backupSelected.element as Communication.EdmEditor.CoreEdmElement, backupSelected.event as Event);
            }
          }
        }
      }
    }
  };

  const handleSelectedElement = (element: Communication.EdmEditor.CoreEdmElement, e: Event) => {
    removeEdmUi(hoverElement);
    backupSelected.element = element;
    backupSelected.event = e;
    const colUI = document.querySelector(`#${element.Id}`);
    const rowUI = document.querySelector(`#${element.RowId}>.edm-editor-row-selector-ui-overlay`);
    const id = element.Id || element.RowId;

    if (!isSelected) {
      isSelected = true;
      selectedElement.id = id;
      // removeEdmUi(selectedElement);
      if (selectedElement.id != null) {
        const uiElement = renderSelectedWithAction();
        selectedElement.element = uiElement.selectedUI;

        if (element.Type == 'Row' || element.Type == 'Col' || element.Obj.Properties.Content.Type == 'LayoutBlank') {
          selectedElement.id = element.RowId;
          const rowSelector = document.querySelector(`#${element.RowId}`);
          const tdList: any = rowSelector?.querySelectorAll('.edm-editor-col-selector-ui');
          selectedElement.tdList = tdList;
          for (const td of tdList) {
            td.classList.add('edm-content-selector');

            const adjustContainer = document.createElement('div');
            const adjustSizeButton = document.createElement('div');
            adjustSizeButton.classList.add('adjust-size-button');
            adjustContainer.classList.add('adjust-container');
            adjustSizeButton.innerHTML = '|||';
            adjustSizeButton.addEventListener('mousedown', () => {
              isAdjustSize = true;
            });
            adjustContainer.appendChild(adjustSizeButton);
            td.appendChild(adjustContainer);
          }
          const numberOfCol = EDMGetRowMapping(editorId, element.RowId).Col.length;
          const firstIndexCol = EDMGetRowMapping(editorId, element.RowId).Col[0].Id;
          const lastIndexCol = EDMGetRowMapping(editorId, element.RowId).Col[EDMGetRowMapping(editorId, element.RowId).Col.length - 1].Id;

          rowUI?.appendChild(selectedElement.element);

          if (numberOfCol <= 1) {
            uiElement.rowManageActionGroupLeft.removeChild(uiElement.deleteRowButtonLeft);
            uiElement.rowManageActionGroupRight.removeChild(uiElement.deleteRowButtonRight);
          } else if (numberOfCol == 4) {
            uiElement.rowManageActionGroupLeft.removeChild(uiElement.addRowButtonLeft);
            uiElement.rowManageActionGroupRight.removeChild(uiElement.addRowButtonRight);
          }

          ///add event add col listener
          uiElement.addRowButtonLeft.addEventListener('mousedown', () => {
            isSelected = false;
            const newCol = EDMAddCol(editorId, element.RowId, { TargetAddType: 'Before' });
            EDMAddContent(editorId, newCol, { Type: 'LayoutBlank', Properties: {} }, { Properties: { HTMLUI: addContentUI } });
            const colList = EDMGetRowMapping(editorId, element.RowId).Col;
            for (let i = 0; i < colList.length; i++) {
              const colProp = EDMGetObjList(editorId)[colList[i].Id];
              calculateWidthCol(colProp);
            }
            EDMRender(editorId);
            removeEdmUi(selectedElement);
            handleHoverElement(element, e);
          });
          uiElement.addRowButtonRight.addEventListener('mousedown', () => {
            isSelected = false;
            const newCol = EDMAddCol(editorId, element.RowId, { TargetAddType: 'After' });
            EDMAddContent(editorId, newCol, { Type: 'LayoutBlank', Properties: {} }, { Properties: { HTMLUI: addContentUI } });
            const colList = EDMGetRowMapping(editorId, element.RowId).Col;
            for (let i = 0; i < colList.length; i++) {
              const colProp = EDMGetObjList(editorId)[colList[i].Id];
              calculateWidthCol(colProp);
            }
            EDMRender(editorId);
            removeEdmUi(selectedElement);
            handleHoverElement(element, e);
          });
          ///add event add col listener
          uiElement.deleteRowButtonLeft.addEventListener('mousedown', () => {
            isSelected = false;
            EDMRemoveCol(editorId, element.RowId, firstIndexCol as string);
            const colList = EDMGetRowMapping(editorId, element.RowId).Col;
            for (let i = 0; i < colList.length; i++) {
              const colProp = EDMGetObjList(editorId)[colList[i].Id];
              calculateWidthCol(colProp);
            }
            EDMRender(editorId);
            removeEdmUi(selectedElement);
            handleHoverElement(element, e);
          });
          uiElement.deleteRowButtonRight.addEventListener('mousedown', () => {
            isSelected = false;
            EDMRemoveCol(editorId, element.RowId, lastIndexCol as string);
            const colList = EDMGetRowMapping(editorId, element.RowId).Col;
            for (let i = 0; i < colList.length; i++) {
              const colProp = EDMGetObjList(editorId)[colList[i].Id];
              calculateWidthCol(colProp);
            }
            EDMRender(editorId);
            removeEdmUi(selectedElement);
            handleHoverElement(element, e);
          });
          //set type
          selectedElement.type = 'Layout';
        } else if (element.Type !== 'Content' || element.Obj.Properties.Content.Type !== 'LayoutBlank') {
          const activeElement = colUI?.querySelector('.add-content-title');
          const uiElement = renderSelectedUI();
          activeElement?.classList.add('active');
          selectedElement.element = uiElement.selectedUI;
          colUI?.appendChild(selectedElement.element);
          selectedElement.type = element.Obj.Properties.Content.Type;

          /* text content handler */
          if (selectedElement.type === 'Text') {
            const elementUi: any = document.querySelector(`#${selectedElement.id}`);
            elementUi.classList.add('edm-position-relative');
            // elementUi.innerHTML = '';
            const el: any = elementUi?.closest('td');
            el.querySelector('.edm-rendered-html').innerHTML = '';
            elementUi.insertAdjacentHTML('beforeend', '<div id="quill-editor" />');

            nextTick(() => {
              quillInstance = QuillInitInstance('quill-editor');

              quillInstance.on('text-change', () => {
                currentQuillText = QuillGetHtml(quillInstance) as string;
                const htmlElement = new DOMParser().parseFromString(currentQuillText, 'text/html');
                htmlElement.querySelectorAll('a').forEach((element: HTMLElement) => {
                  mapper.trackingUrl.linkValue = element.getAttribute('href');
                  const trackingMapper: Campaign.TrackingMapper = JSON.parse(element.getAttribute('value') as string);
                  if (trackingMapper?.tag) {
                    mapper.trackingUrl.tag = trackingMapper.tag;
                  } else {
                    mapper.trackingUrl.tag = `{{mp:${tagNumber.value}}}`;
                    tagNumber.value--;
                  }
                  element.setAttribute('value', JSON.stringify(mapper.trackingUrl));
                });
                currentQuillText = htmlElement.body.innerHTML;
                vm?.$emit('on-get-quill-instance', quillInstance, currentQuillText);
              });

              quillInstance.on('selection-change', () => {
                const range = quillInstance.getSelection();
                if (range) {
                  quillSelectionRange.value = quillInstance.getSelection();
                  if (range.length === 0) {
                    // do nothing
                  } else {
                    const content = quillInstance.getContents(range.index, range.length);
                    const color = content.ops[0].attributes?.color;
                    toolbarRef.value.setColor(color || '');
                  }
                } else {
                  // do nothing
                }
              });

              quillInstance.root.addEventListener('blur', function () {
                // do nothing
              });

              QuillSetHtml(
                quillInstance,
                decodeURI(EDMGetObjList('cdp-edm-editor')[selectedElement.id].Properties.Content.Properties.RawText as string),
              );
              vm?.$emit('on-get-func-save-text', saveQuillTextToEDM);
            });
          }
          /* end */
        }
      }
    } else if (isSelected) {
      /* text content handler */
      const isClickNearbyEditor = () => {
        return element.Type === 'None' || element.Type === 'Row' || element.Type === 'Col';
      };

      if (element.Type === 'Content' && selectedElement.type === 'Text') {
        if (
          EDMGetObjList(editorId)[element.Id].Properties.Content.Type === 'Text' &&
          EDMGetObjList(editorId)[element.Id].Properties.Content.Type === EDMGetObjList(editorId)[selectedElement.id].Properties.Content.Type &&
          EDMGetObjList(editorId)[element.Id].Id === EDMGetObjList(editorId)[selectedElement.id].Id
        ) {
          return;
        } else {
          currentQuillText = QuillGetHtml(quillInstance) as string;
          QuillSetHtml(quillInstance, currentQuillText);
          saveQuillTextToEDM();
          EDMRender(editorId);
          vm?.$emit('on-get-edm-config-json', EDMRenderSave(editorId));
          vm?.$emit('on-get-edm-content', EDMRenderResult(editorId));
          vm?.$emit('on-get-func-save-text', null);
        }
      }
      if (isClickNearbyEditor() && selectedElement.type === 'Text') {
        currentQuillText = QuillGetHtml(quillInstance) as string;
        QuillSetHtml(quillInstance, currentQuillText);
        saveQuillTextToEDM();
        EDMRender(editorId);
        vm?.$emit('on-get-edm-config-json', EDMRenderSave(editorId));
        vm?.$emit('on-get-edm-content', EDMRenderResult(editorId));
        vm?.$emit('on-get-func-save-text', null);
      }
      /* end */

      const blankContentElem = document?.querySelectorAll('.add-content-title');
      isSelected = false;
      backupSelected.element = null;
      backupSelected.event = null;
      removeEdmUi(selectedElement);
      for (const blank of blankContentElem) {
        blank.classList.remove('active');
      }
      handleHoverElement(element, e);
    }
  };

  const setIsSelected = () => {
    isSelected = !isSelected;
  };

  const onSaveText = () => {
    QuillSetHtml(quillInstance, currentQuillText);
    saveQuillTextToEDM();
    removeEdmUi(selectedElement);
  };

  const scrollContent = (el: any, scroll: number) => {
    el.scrollTop += scroll;
  };

  const initEdmEditor = () => {
    EDMInitEditor(editorId, '.edm-content-container', {
      ContentPlugin: EDMBlockPlugin(),
      GlobalClass: {
        table: [],
        td: ['edm-td-relative'],
      },
      EventFunction: {
        Click: {
          onSelected: (element: Communication.EdmEditor.CoreEdmElement, e: Event) => {
            if (!props.readOnly) {
              vm?.$emit('is-editing-edm');
              handleSelectedElement(element, e);
              vm?.$emit('on-get-edm-config-json', EDMRenderSave(editorId));
              vm?.$emit('on-get-edm-content', EDMRenderResult(editorId));
              nextTick(() => {
                addActionEditToPersonalizeButton();
              });
            }
          },
        },
        Hover: {
          HoverElement: (element: Communication.EdmEditor.CoreEdmElement, e: Event) => {
            if (!props.readOnly) {
              handleHoverElement(element, e);
            }
          },
        },
        Up: {
          onMouseUp: (element: Communication.EdmEditor.CoreEdmElement) => {
            if (!props.readOnly) {
              if (interVal != null) {
                clearInterval(interVal as number);
                interVal = null;
              }
              vm?.$emit('is-editing-edm');
              isAdjustSize = false;
              isDragging = false;
              removeEdmUi(freezeElement);
              if (mouseDownObjType == 'drag-handle') {
                const numberOfCol = EDMGetRowMapping(editorId, element.RowId).Col.length;
                if (dragElement.Type == 'Row') {
                  EDMMoveRow(editorId, dragElement.RowId, element.RowId, { TargetAddType: element.Option.RowSide });
                  EDMRender(editorId);
                  removeEdmUi(hoverElement);
                } else if (
                  element.Type !== 'Row' &&
                  element.Type !== 'Col' &&
                  element.Obj.Properties.Content.Type !== 'LayoutBlank' &&
                  numberOfCol < 4
                ) {
                  const newCol = EDMAddCol(editorId, element.RowId, { TargetObjId: element.ColId, TargetAddType: element.Option.ColSide });
                  const newContent = EDMAddContent(
                    editorId,
                    newCol,
                    { Type: 'LayoutBlank', Properties: {} },
                    { Properties: { HTMLUI: addContentUI } },
                  );
                  EDMSwapContent(editorId, dragElement.Id, newContent);
                  const colList = EDMGetRowMapping(editorId, element.RowId).Col;
                  for (let i = 0; i < colList.length; i++) {
                    const colProp = EDMGetObjList(editorId)[colList[i].Id];
                    calculateWidthCol(colProp);
                  }
                  EDMRender(editorId);
                  removeEdmUi(hoverElement);
                } else if (element.Type == 'Row' || element.Type == 'Col') {
                  const newRow = EDMAddRow(editorId, {
                    TargetObjId: element.RowId,
                    TargetAddType: element.Option.RowSide,
                    Properties: {
                      EnableBackground: true,
                      BackgroundImage: null,
                      Padding: {
                        Top: 0,
                        Bottom: 0,
                      },
                      Style: {
                        width: '600px',
                        'background-color': '#FFFFFF',
                        'background-image': null,
                      },
                    },
                  });
                  const newCol = EDMAddCol(editorId, newRow);
                  const newContent = EDMAddContent(
                    editorId,
                    newCol,
                    { Type: 'LayoutBlank', Properties: {} },
                    { Properties: { HTMLUI: addContentUI } },
                  );
                  EDMSwapContent(editorId, dragElement.Id, newContent);
                  EDMRender(editorId);
                  removeEdmUi(hoverElement);
                } else {
                  EDMSwapContent(editorId, dragElement.Id, element.Id);
                  const colList = EDMGetRowMapping(editorId, element.RowId).Col;
                  for (let i = 0; i < colList.length; i++) {
                    const colProp = EDMGetObjList(editorId)[colList[i].Id];
                    calculateWidthCol(colProp);
                  }
                  EDMRender(editorId);
                  removeEdmUi(hoverElement);
                }
                if (isDuplicating) {
                  EDMDuplicateContentAndReplace(editorId, duplicateElement.Id, element.Id);
                  EDMRender(editorId);
                  removeEdmUi(hoverElement);
                  isDuplicating = false;
                  duplicateElement = null!;
                }
              }
              mouseDownObjType = '';
              vm?.$emit('on-get-edm-config-json', EDMRenderSave(editorId));
              vm?.$emit('on-get-edm-content', EDMRenderResult(editorId));
            }
          },
        },
        Down: {
          onMouseDown: (element: Communication.EdmEditor.CoreEdmElement, e: Event) => {
            if (!props.readOnly) {
              vm?.$emit('is-editing-edm');
              if (!isSelected) {
                isDragging = true;
                dragElement = element;
                const colUI = document.querySelector(`#${element.Id}`);
                const rowUI = document.querySelector(`#${element.RowId}>.edm-editor-row-selector-ui-overlay`);
                const id = element.Id || element.RowId;
                if (freezeElement.id != id) {
                  freezeElement.id = id;
                  removeEdmUi(freezeElement);
                  if (freezeElement.id != null) {
                    const uiElement = renderHoverWithDraggableHandle();
                    if (element.Type !== 'Content' || element.Obj.Properties.Content.Type !== 'LayoutBlank') {
                      uiElement.hoverUI.setAttribute('style', 'opacity:0.25');
                      uiElement.draggableHandle.removeEventListener('mousedown', () => {
                        mouseDownObjType = 'drag-handle';
                      });
                      freezeElement.element = uiElement.hoverUI;
                    }
                    if (element.Type == 'Row' || element.Type == 'Col') {
                      rowUI?.appendChild(freezeElement.element);
                    } else if (element.Type !== 'Content' || element.Obj.Properties.Content.Type !== 'LayoutBlank') {
                      colUI?.appendChild(freezeElement.element);
                    }
                  }
                }
              } else if (isAdjustSize && element.ColId) {
                const numberOfCol = EDMGetRowMapping(editorId, element.RowId).Col;
                adjustObj.col1 = element.ColId;
                let colIndex = -1;
                for (let i = 0; i < numberOfCol.length; i++) {
                  if (element.ColId == numberOfCol[i].Id) {
                    colIndex = i;
                  }
                }
                if (colIndex !== -1) {
                  if (element.Option.ColSide == 'Before') {
                    if (colIndex > 0) {
                      colIndex -= 1;
                      adjustObj.col2 = numberOfCol[colIndex].Id;
                    } else {
                      adjustObj.col2 = null;
                    }
                  } else if (element.Option.ColSide == 'After') {
                    if (colIndex < numberOfCol.length - 1) {
                      colIndex += 1;
                      adjustObj.col2 = numberOfCol[colIndex].Id;
                    } else {
                      adjustObj.col2 = null;
                    }
                  }
                } else {
                  adjustObj.col2 = null;
                }
              }
              vm?.$emit('on-get-edm-config-json', EDMRenderSave(editorId));
              vm?.$emit('on-get-edm-content', EDMRenderResult(editorId));
            }
          },
        },
        Drag: {},
        DragStart: {},
        DragEnd: {
          onDragEnd: () => {
            if (!props.readOnly) {
              if (interVal != null) {
                clearInterval(interVal as number);
                interVal = null;
              }
            }
          },
        },
        DragOver: {
          onDragLayout: (element: Communication.EdmEditor.CoreEdmElement, e: any) => {
            if (!props.readOnly) {
              if (isMovingPersonalize.value || isMovingCoupon.value) {
                return;
              }
              if (selectedNode.value) {
                if (e.y <= 200) {
                  if (interVal == null) {
                    const mainContent = document.querySelector('.main-content');
                    interVal = setInterval(() => {
                      scrollContent(mainContent, -10);
                    }, 10);
                  }
                } else if (e.y >= window.innerHeight - 200) {
                  if (interVal == null) {
                    const mainContent = document.querySelector('.main-content');
                    interVal = setInterval(() => {
                      scrollContent(mainContent, 10);
                    }, 10);
                  }
                } else if (interVal != null) {
                  clearInterval(interVal as number);
                  interVal = null;
                }
              }
              vm?.$emit('is-editing-edm');
              const colUI = document.querySelector(`#${element.ColId}`);
              const rowUI = document.querySelector(`#${element.RowId}>.edm-editor-row-selector-ui-overlay`);
              if (
                hoverElement.id != element.RowId ||
                hoverElement.rowSide != element.Option.RowSide ||
                hoverElement.colSide != element.Option.ColSide
              ) {
                hoverElement.id = element.RowId || element.Id;
                hoverElement.rowSide = element.Option.RowSide;
                hoverElement.colSide = element.Option.ColSide;

                removeEdmUi(hoverElement);
                if (hoverElement.id != null) {
                  if (element.Type == 'Row' || element.Type == 'Col' || selectedNode.value.category == 'layout') {
                    const lineElement = renderDragLineRow();
                    hoverElement.element = lineElement.rowLine;
                    rowUI?.appendChild(hoverElement.element);

                    if (hoverElement.rowSide == 'Before') {
                      lineElement.rowLine.classList.add('row-line-before');
                    } else {
                      lineElement.rowLine.classList.add('row-line-after');
                    }
                  } else if (element.Obj.Properties.Content.Type !== 'LayoutBlank') {
                    const lineElement = renderDragLineCol();
                    hoverElement.element = lineElement.colLine;
                    hoverElement.tdElement = colUI?.closest('td');
                    hoverElement.tdElement.classList.add('edm-content-hover');
                    colUI?.closest('td')?.querySelector('.edm-editor-col-selector-ui-overlay')?.appendChild(hoverElement.element);

                    if (hoverElement.colSide == 'Before') {
                      lineElement.colLine.classList.add('col-line-left');
                    } else {
                      lineElement.colLine.classList.add('col-line-right');
                    }
                  }
                }
              }
            }
          },
        },
        DragEnter: {},
        DragLeave: {},
        Drop: {
          DropItem: (element: Communication.EdmEditor.CoreEdmElement, e: Event) => {
            if (!props.readOnly) {
              if (interVal != null) {
                clearInterval(interVal as number);
                interVal = null;
              }
              vm?.$emit('is-editing-edm');
              removeEdmUi(selectedElement);
              removeEdmUi(hoverElement);
              let rowId = null;
              let colId1 = null;
              let colId2 = null;
              switch (selectedNode.value?.type) {
                case 'image':
                  if (element.Type == 'Content' && element.Obj.Properties.Content.Type == 'LayoutBlank') {
                    EDMAddContent(editorId, element.ColId, { Type: 'ImageBlank', Properties: {} }, { Properties: { HTMLUI: addImageUI } });
                    EDMRender(editorId);
                  } else if (element.Type == 'Content' && element.Obj.Properties.Content.Type !== 'LayoutBlank') {
                    const numberOfCol = EDMGetRowMapping(editorId, element.RowId).Col.length;
                    if (numberOfCol < 4) {
                      colId1 = EDMAddCol(editorId, element.RowId, { TargetObjId: element.ColId, TargetAddType: element.Option.ColSide });
                      EDMAddContent(editorId, colId1, { Type: 'ImageBlank', Properties: {} }, { Properties: { HTMLUI: addImageUI } });
                      const colList = EDMGetRowMapping(editorId, element.RowId).Col;
                      for (let i = 0; i < colList.length; i++) {
                        const colProp = EDMGetObjList(editorId)[colList[i].Id];
                        calculateWidthCol(colProp);
                      }
                      EDMRender(editorId);
                    }
                  } else if (element.Type !== 'Content') {
                    rowId = EDMAddRow(editorId, {
                      TargetObjId: element.RowId,
                      TargetAddType: element.Option.RowSide,
                      Properties: {
                        EnableBackground: true,
                        BackgroundImage: null,
                        Padding: {
                          Top: 0,
                          Bottom: 0,
                        },
                        Style: {
                          width: '600px',
                          'background-color': '#FFFFFF',
                          'background-image': null,
                        },
                      },
                    });
                    colId1 = EDMAddCol(editorId, rowId);
                    EDMAddContent(editorId, colId1, { Type: 'ImageBlank', Properties: {} }, { Properties: { HTMLUI: addImageUI } });
                    EDMRender(editorId);
                  }
                  break;
                case 'text':
                  const defaultSetting = {
                    enablePadding: true,
                    top: 10,
                    bottom: 10,
                    left: 10,
                    right: 10,
                  };

                  if (element.Type == 'Content' && element.Obj.Properties.Content.Type == 'LayoutBlank') {
                    EDMAddContent(
                      editorId,
                      element.ColId,
                      {
                        Type: 'Text',
                        Properties: {
                          Text: encodeURI(`<span class='edm-rendered-html'>${defaultHtmlContent}</span>`),
                          RawText: encodeURI(`${defaultHtmlContent}`),
                        },
                      },
                      {
                        Properties: {
                          EnablePadding: defaultSetting.enablePadding,
                          Padding: {
                            Top: defaultSetting.top,
                            Bottom: defaultSetting.bottom,
                            Left: defaultSetting.left,
                            Right: defaultSetting.right,
                          },
                          Style: {
                            'padding-top': `${defaultSetting.top}px`,
                            'padding-bottom': `${defaultSetting.bottom}px`,
                            'padding-left': `${defaultSetting.left}px`,
                            'padding-right': `${defaultSetting.right}px`,
                          },
                        },
                      },
                    );
                    EDMRender(editorId);
                  } else if (element.Type == 'Content' && element.Obj.Properties.Content.Type !== 'LayoutBlank') {
                    const numberOfCol = EDMGetRowMapping(editorId, element.RowId).Col.length;
                    if (numberOfCol < 4) {
                      colId1 = EDMAddCol(editorId, element.RowId, { TargetObjId: element.ColId, TargetAddType: element.Option.ColSide });
                      EDMAddContent(
                        editorId,
                        colId1,
                        {
                          Type: 'Text',
                          Properties: {
                            Text: encodeURI(`<span class='edm-rendered-html'>${defaultHtmlContent}</span>`),
                            RawText: encodeURI(`${defaultHtmlContent}`),
                          },
                        },
                        {
                          Properties: {
                            EnablePadding: defaultSetting.enablePadding,
                            Padding: {
                              Top: defaultSetting.top,
                              Bottom: defaultSetting.bottom,
                              Left: defaultSetting.left,
                              Right: defaultSetting.right,
                            },
                            Style: {
                              'padding-top': `${defaultSetting.top}px`,
                              'padding-bottom': `${defaultSetting.bottom}px`,
                              'padding-left': `${defaultSetting.left}px`,
                              'padding-right': `${defaultSetting.right}px`,
                            },
                          },
                        },
                      );
                      const colList = EDMGetRowMapping(editorId, element.RowId).Col;
                      for (let i = 0; i < colList.length; i++) {
                        const colProp = EDMGetObjList(editorId)[colList[i].Id];
                        calculateWidthCol(colProp);
                      }
                      EDMRender(editorId);
                    }
                  } else if (element.Type !== 'Content') {
                    rowId = EDMAddRow(editorId, {
                      TargetObjId: element.RowId,
                      TargetAddType: element.Option.RowSide,
                      Properties: {
                        EnableBackground: true,
                        BackgroundImage: null,
                        Padding: {
                          Top: 0,
                          Bottom: 0,
                        },
                        Style: {
                          width: '600px',
                          'background-color': '#FFFFFF',
                          'background-image': null,
                        },
                      },
                    });
                    colId1 = EDMAddCol(editorId, rowId);
                    EDMAddContent(
                      editorId,
                      colId1,
                      {
                        Type: 'Text',
                        Properties: {
                          Text: encodeURI(`<span class='edm-rendered-html'>${defaultHtmlContent}</span>`),
                          RawText: encodeURI(`${defaultHtmlContent}`),
                        },
                      },
                      {
                        Properties: {
                          EnablePadding: defaultSetting.enablePadding,
                          Padding: {
                            Top: defaultSetting.top,
                            Bottom: defaultSetting.bottom,
                            Left: defaultSetting.left,
                            Right: defaultSetting.right,
                          },
                          Style: {
                            'padding-top': `${defaultSetting.top}px`,
                            'padding-bottom': `${defaultSetting.bottom}px`,
                            'padding-left': `${defaultSetting.left}px`,
                            'padding-right': `${defaultSetting.right}px`,
                          },
                        },
                      },
                    );
                    EDMRender(editorId);
                  }
                  break;
                case 'video':
                  if (element.Type == 'Content' && element.Obj.Properties.Content.Type == 'LayoutBlank') {
                    EDMAddContent(editorId, element.ColId, { Type: 'VideoBlank', Properties: {} }, { Properties: { HTMLUI: addVideoUI } });
                    EDMRender(editorId);
                  } else if (element.Type == 'Content' && element.Obj.Properties.Content.Type !== 'LayoutBlank') {
                    const numberOfCol = EDMGetRowMapping(editorId, element.RowId).Col.length;
                    if (numberOfCol < 4) {
                      colId1 = EDMAddCol(editorId, element.RowId, { TargetObjId: element.ColId, TargetAddType: element.Option.ColSide });
                      EDMAddContent(editorId, colId1, { Type: 'VideoBlank', Properties: {} }, { Properties: { HTMLUI: addVideoUI } });
                      const colList = EDMGetRowMapping(editorId, element.RowId).Col;
                      for (let i = 0; i < colList.length; i++) {
                        const colProp = EDMGetObjList(editorId)[colList[i].Id];
                        calculateWidthCol(colProp);
                      }
                      EDMRender(editorId);
                    }
                  } else if (element.Type !== 'Content') {
                    rowId = EDMAddRow(editorId, {
                      TargetObjId: element.RowId,
                      TargetAddType: element.Option.RowSide,
                      Properties: {
                        EnableBackground: true,
                        BackgroundImage: null,
                        Padding: {
                          Top: 0,
                          Bottom: 0,
                        },
                        Style: {
                          width: '600px',
                          'background-color': '#FFFFFF',
                          'background-image': null,
                        },
                      },
                    });
                    colId1 = EDMAddCol(editorId, rowId);
                    EDMAddContent(editorId, colId1, { Type: 'VideoBlank', Properties: {} }, { Properties: { HTMLUI: addVideoUI } });
                    EDMRender(editorId);
                  }
                  break;
                case 'social':
                  const socialProp = process.env.NODE_ENV == 'development' ? defaultSocialPropertiesDev : defaultSocialPropertiesProd;
                  if (element.Type == 'Content' && element.Obj.Properties.Content.Type == 'LayoutBlank') {
                    EDMAddContent(editorId, element.ColId, { Type: 'Social', Properties: socialProp }, { Properties: { Style: defaultSocialStyle } });
                    EDMRender(editorId);
                  } else if (element.Type == 'Content' && element.Obj.Properties.Content.Type !== 'LayoutBlank') {
                    const numberOfCol = EDMGetRowMapping(editorId, element.RowId).Col.length;
                    if (numberOfCol < 4) {
                      colId1 = EDMAddCol(editorId, element.RowId, { TargetObjId: element.ColId, TargetAddType: element.Option.ColSide });
                      EDMAddContent(editorId, colId1, { Type: 'Social', Properties: socialProp }, { Properties: { Style: defaultSocialStyle } });
                      const colList = EDMGetRowMapping(editorId, element.RowId).Col;
                      for (let i = 0; i < colList.length; i++) {
                        const colProp = EDMGetObjList(editorId)[colList[i].Id];
                        calculateWidthCol(colProp);
                      }
                      EDMRender(editorId);
                    }
                  } else if (element.Type !== 'Content') {
                    rowId = EDMAddRow(editorId, {
                      TargetObjId: element.RowId,
                      TargetAddType: element.Option.RowSide,
                      Properties: {
                        EnableBackground: true,
                        BackgroundImage: null,
                        Padding: {
                          Top: 0,
                          Bottom: 0,
                        },
                        Style: {
                          width: '600px',
                          'background-color': '#FFFFFF',
                          'background-image': null,
                        },
                      },
                    });
                    colId1 = EDMAddCol(editorId, rowId);
                    EDMAddContent(editorId, colId1, { Type: 'Social', Properties: socialProp }, { Properties: { Style: defaultSocialStyle } });
                    EDMRender(editorId);
                  }
                  break;
                case 'scan-obj':
                  if (element.Type == 'Content' && element.Obj.Properties.Content.Type == 'LayoutBlank') {
                    EDMAddContent(editorId, element.ColId, { Type: 'ScannerBlank', Properties: {} }, { Properties: { HTMLUI: addScanUI } });
                    EDMRender(editorId);
                  } else if (element.Type == 'Content' && element.Obj.Properties.Content.Type !== 'LayoutBlank') {
                    const numberOfCol = EDMGetRowMapping(editorId, element.RowId).Col.length;
                    if (numberOfCol < 4) {
                      colId1 = EDMAddCol(editorId, element.RowId, { TargetObjId: element.ColId, TargetAddType: element.Option.ColSide });
                      EDMAddContent(editorId, colId1, { Type: 'ScannerBlank', Properties: {} }, { Properties: { HTMLUI: addScanUI } });
                      const colList = EDMGetRowMapping(editorId, element.RowId).Col;
                      for (let i = 0; i < colList.length; i++) {
                        const colProp = EDMGetObjList(editorId)[colList[i].Id];
                        calculateWidthCol(colProp);
                      }
                      EDMRender(editorId);
                    }
                  } else if (element.Type !== 'Content') {
                    rowId = EDMAddRow(editorId, {
                      TargetObjId: element.RowId,
                      TargetAddType: element.Option.RowSide,
                      Properties: {
                        EnableBackground: true,
                        BackgroundImage: null,
                        Padding: {
                          Top: 0,
                          Bottom: 0,
                        },
                        Style: {
                          width: '600px',
                          'background-color': '#FFFFFF',
                          'background-image': null,
                        },
                      },
                    });
                    colId1 = EDMAddCol(editorId, rowId);
                    EDMAddContent(editorId, colId1, { Type: 'ScannerBlank', Properties: {} }, { Properties: { HTMLUI: addScanUI } });
                    EDMRender(editorId);
                  }
                  break;
                case 'layout-one':
                  rowId = EDMAddRow(editorId, {
                    TargetObjId: element.RowId,
                    TargetAddType: element.Option.RowSide,
                    Properties: {
                      EnableBackground: true,
                      BackgroundImage: null,
                      Padding: {
                        Top: 0,
                        Bottom: 0,
                      },
                      Style: {
                        width: '600px',
                        'background-color': '#FFFFFF',
                        'background-image': null,
                      },
                    },
                  });
                  colId1 = EDMAddCol(editorId, rowId);
                  EDMAddContent(editorId, colId1, { Type: 'LayoutBlank', Properties: {} }, { Properties: { HTMLUI: addContentUI } });
                  EDMRender(editorId);
                  break;
                case 'layout-two':
                  rowId = EDMAddRow(editorId, {
                    TargetObjId: element.RowId,
                    TargetAddType: element.Option.RowSide,
                    Properties: {
                      EnableBackground: true,
                      BackgroundImage: null,
                      Padding: {
                        Top: 0,
                        Bottom: 0,
                      },
                      Style: {
                        width: '600px',
                        'background-color': '#FFFFFF',
                        'background-image': null,
                      },
                    },
                  });
                  colId1 = EDMAddCol(editorId, rowId);
                  colId2 = EDMAddCol(editorId, rowId);
                  EDMAddContent(editorId, colId1, { Type: 'LayoutBlank', Properties: {} }, { Properties: { HTMLUI: addContentUI } });
                  EDMAddContent(editorId, colId2, { Type: 'LayoutBlank', Properties: {} }, { Properties: { HTMLUI: addContentUI } });
                  EDMRender(editorId);
                  break;
                case 'layout-three':
                  rowId = EDMAddRow(editorId, {
                    TargetObjId: element.RowId,
                    TargetAddType: element.Option.RowSide,
                    Properties: {
                      EnableBackground: true,
                      BackgroundImage: null,
                      Padding: {
                        Top: 0,
                        Bottom: 0,
                      },
                      Style: {
                        width: '600px',
                        'background-color': '#FFFFFF',
                        'background-image': null,
                      },
                    },
                  });
                  for (let i = 1; i <= 3; i++) {
                    colId1 = EDMAddCol(editorId, rowId);
                    EDMAddContent(editorId, colId1, { Type: 'LayoutBlank', Properties: {} }, { Properties: { HTMLUI: addContentUI } });
                  }
                  EDMRender(editorId);
                  break;
                case 'layout-four':
                  rowId = EDMAddRow(editorId, {
                    TargetObjId: element.RowId,
                    TargetAddType: element.Option.RowSide,
                    Properties: {
                      EnableBackground: true,
                      BackgroundImage: null,
                      Padding: {
                        Top: 0,
                        Bottom: 0,
                      },
                      Style: {
                        width: '600px',
                        'background-color': '#FFFFFF',
                        'background-image': null,
                      },
                    },
                  });
                  for (let i = 1; i <= 4; i++) {
                    colId1 = EDMAddCol(editorId, rowId);
                    EDMAddContent(editorId, colId1, { Type: 'LayoutBlank', Properties: {} }, { Properties: { HTMLUI: addContentUI } });
                  }
                  EDMRender(editorId);
                  break;
                case 'layout-one-to-two':
                  rowId = EDMAddRow(editorId, {
                    TargetObjId: element.RowId,
                    TargetAddType: element.Option.RowSide,
                    Properties: {
                      EnableBackground: true,
                      BackgroundImage: null,
                      Padding: {
                        Top: 0,
                        Bottom: 0,
                      },
                      Style: {
                        width: '600px',
                        'background-color': '#FFFFFF',
                        'background-image': null,
                      },
                    },
                  });
                  colId1 = EDMAddCol(editorId, rowId);
                  colId2 = EDMAddCol(editorId, rowId);
                  EDMGetObjList(editorId)[colId1].Properties.Length = 3;
                  EDMGetObjList(editorId)[colId2].Properties.Length = 9;
                  EDMAddContent(editorId, colId1, { Type: 'LayoutBlank', Properties: {} }, { Properties: { HTMLUI: addContentUI } });
                  EDMAddContent(editorId, colId2, { Type: 'LayoutBlank', Properties: {} }, { Properties: { HTMLUI: addContentUI } });
                  EDMRender(editorId);
                  break;
                case 'layout-two-to-one':
                  rowId = EDMAddRow(editorId, {
                    TargetObjId: element.RowId,
                    TargetAddType: element.Option.RowSide,
                    Properties: {
                      EnableBackground: true,
                      BackgroundImage: null,
                      Padding: {
                        Top: 0,
                        Bottom: 0,
                      },
                      Style: {
                        width: '600px',
                        'background-color': '#FFFFFF',
                        'background-image': null,
                      },
                    },
                  });
                  colId1 = EDMAddCol(editorId, rowId);
                  colId2 = EDMAddCol(editorId, rowId);
                  EDMGetObjList(editorId)[colId1].Properties.Length = 9;
                  EDMGetObjList(editorId)[colId2].Properties.Length = 3;
                  EDMAddContent(editorId, colId1, { Type: 'LayoutBlank', Properties: {} }, { Properties: { HTMLUI: addContentUI } });
                  EDMAddContent(editorId, colId2, { Type: 'LayoutBlank', Properties: {} }, { Properties: { HTMLUI: addContentUI } });
                  EDMRender(editorId);
                  break;
              }
              selectedNode.value = null!;
              vm?.$emit('on-get-edm-config-json', EDMRenderSave(editorId));
              vm?.$emit('on-get-edm-content', EDMRenderResult(editorId));
            }
          },
        },
      },
    });
  };

  const onAddSrefList = (sref: string) => {
    srefList.value.push(sref);
  };

  const onSelectedEmoji = (emoji: string) => {
    quillInstance.focus();
    const cursorPosition = quillInstance.getSelection()?.index;
    quillInstance.insertText(cursorPosition as number, emoji);
  };

  //Personalize
  function onAddEditPersonalize(personalize: Campaign.Personalize) {
    quillInstance.focus();
    const cursorPosition = quillInstance.getSelection()?.index;
    //add new personalize
    if (!isEditPersonalize.value) {
      if (props.isPersonalizeFromTemplate) {
        const tagNumList: number[] = [];
        filterMapper().forEach((mapper: any) => {
          tagNumList.push(mapper.tag.split(/(-?\d+)/)[1] as number);
          const min = Math.min(...tagNumList);
          tagNumber.value = min;
          tagNumber.value--;
        });
      }
      const personalizeObj = Object.assign(personalize, {
        tag: `{{mp:${tagNumber.value}}}`,
        backgroundColor: personalize.backgroundColor,
        fontColor: personalize.fontColor,
      });
      quillInstance.insertText(cursorPosition, ' ');
      quillInstance.insertEmbed(cursorPosition, 'personalize', personalizeObj);
      tagNumber.value--;
    } else {
      editPersonalize(selectedButton.value, personalize);
    }
    addActionEditToPersonalizeButton();
    closePersonalizeModal();
  }

  function editPersonalize(button: any, personalize: Campaign.Personalize) {
    const edmEditor = document.getElementById('cdp-edm-editor') as HTMLDivElement;
    const personalizeButton: any = edmEditor.getElementsByClassName('personalize-button');

    for (let i = 0; i < personalizeButton.length; i++) {
      const mapper: Campaign.Personalize = JSON.parse(personalizeButton[i].getAttribute('value') as string);
      if (mapper.columnAlias === personalize.columnAlias) {
        mapper.defaultValue = personalize.defaultValue;
        personalizeButton[i].setAttribute('value', `${JSON.stringify(mapper)}`);
      }
    }
    button.innerHTML = personalize.columnAlias as string;
    button.setAttribute('value', `${JSON.stringify(personalize)}`);
    currentQuillText = QuillGetHtml(quillInstance) as string;
    QuillSetHtml(quillInstance, currentQuillText);
  }

  const closePersonalizeModal = () => {
    personalizeObjColumn.value = null!;
    isEditPersonalize.value = false;
    vm?.$emit('on-close-personalize-modal');
  };

  //Coupon
  function onSelectCouponFromScan() {
    isCouponScanMode.value = true;
  }

  function onAddCouponFromScan(couponSet: Campaign.CouponSet) {
    selectedCoupon.value = couponSet;
  }

  function onAddedCouponFromScan() {
    selectedCoupon.value = null!;
    listCouponColumn.value = null!;
    EDMGetProperties(editorId).mapper = filterMapper();
  }

  function onChangeCoupon(couponObj: Campaign.CouponColumn) {
    isEditCoupon.value = true;
    couponObjColumn.value = couponObj;
  }

  function changeCoupon(couponObj: Campaign.CouponColumn) {
    listCouponColumn.value = couponObj;
  }

  function onAddEditCoupon(coupon: Campaign.CouponColumn[] | Campaign.CouponColumn) {
    if (isEditCoupon.value) {
      if (!isCouponScanMode.value) {
        editCoupon(selectedButton.value, coupon as Campaign.CouponColumn);
      } else if (isCouponScanMode.value) {
        changeCoupon(coupon as Campaign.CouponColumn);
      }
    } else {
      addCoupon(coupon as Campaign.CouponColumn[]);
    }
    addActionEditToPersonalizeButton();
    closeCouponModal();
  }

  function addCoupon(couponList: Campaign.CouponColumn[]) {
    if (props.isPersonalizeFromTemplate) {
      const tagNumList: number[] = [];
      filterMapper().forEach((mapper: any) => {
        tagNumList.push(mapper.tag.split(/(-?\d+)/)[1] as number);
        const min = Math.min(...tagNumList);
        tagNumber.value = min;
        tagNumber.value--;
      });
    }
    if (!isCouponScanMode.value) {
      quillInstance.focus();
      const cursorPosition = quillInstance.getSelection()?.index;
      couponList.forEach((item: Campaign.CouponColumn) => {
        const couponObj = Object.assign(item, {
          tag: `{{mp:${tagNumber.value}}}`,
          backgroundColor: item.backgroundColor,
          fontColor: item.fontColor,
        });
        quillInstance.insertText(cursorPosition, ' ');
        quillInstance.insertEmbed(cursorPosition, 'coupon', couponObj);
        tagNumber.value--;
      });
    } else if (isCouponScanMode.value) {
      const couponObj = Object.assign(couponList[0], {
        tag: `{{mp:${tagNumber.value}}}`,
        backgroundColor: couponList[0].backgroundColor,
        fontColor: couponList[0].fontColor,
      });
      listCouponColumn.value = couponObj;
      tagNumber.value--;
    }
  }

  function editCoupon(button: any, coupon: Campaign.CouponColumn) {
    const edmEditor = document.getElementById('cdp-edm-editor') as HTMLDivElement;
    const couponButton: any = edmEditor.getElementsByClassName('coupon-button');
    for (let i = 0; i < couponButton.length; i++) {
      const mapper: Campaign.CouponColumn = JSON.parse(couponButton[i].getAttribute('value') as string);
      if (
        mapper.couponColumnAlias === coupon.couponColumnAlias &&
        mapper.couponFreeTextRef === coupon.couponFreeTextRef &&
        mapper.couponId === coupon.couponId
      ) {
        mapper.defaultValue = coupon.defaultValue as string;
        couponButton[i].setAttribute('value', `${JSON.stringify(mapper)}`);
      }
    }
    button.innerHTML = coupon.couponColumnAlias as string;
    button.setAttribute('value', `${JSON.stringify(coupon)}`);
    button.setAttribute(
      'style',
      `border-radius:2px;padding:0 5px;font-size:16px;border:none;background-color:${coupon.backgroundColor as string};color:${
        coupon.fontColor as string
      }`,
    );
    currentQuillText = QuillGetHtml(quillInstance) as string;
    QuillSetHtml(quillInstance, currentQuillText);
  }

  const closeCouponModal = () => {
    couponObjColumn.value = null!;
    isEditCoupon.value = false;
    isCouponScanMode.value = false;
    vm?.$emit('on-close-coupon-modal');
  };

  function updateTagNumber() {
    tagNumber.value--;
  }

  function onBuildObj() {
    EDMGetProperties(editorId).mapper = filterMapper();
  }

  function buildTrackingOpenMailEle() {
    decreaseTagNumberFromLatest();

    mapper.trackingOpenMail.tag = `{{mp:${tagNumber.value}}}`;
    const ElementImage = document.createElement('img');
    ElementImage.setAttribute('src', mailTrackerImg);
    ElementImage.setAttribute('class', 'tracking-open-mail');
    ElementImage.setAttribute('value', JSON.stringify(mapper.trackingOpenMail));
    tagNumber.value--;

    return ElementImage.outerHTML.replaceAll(/"/, "'");
  }

  //util
  function addActionEditToPersonalizeButton() {
    const edmEditor = document.getElementById('cdp-edm-editor') as HTMLDivElement;
    //Personalize Action
    const personalizeButton: any = edmEditor.getElementsByClassName('personalize-button');

    for (let i = 0; i < personalizeButton.length; i++) {
      const personalize: Campaign.Personalize = JSON.parse(personalizeButton[i].getAttribute('value') as string);
      personalizeButton[i].onclick = () => {
        isEditPersonalize.value = true;
        personalizeObjColumn.value = personalize;
        vm?.$emit('on-select-personalize');
        selectedButton.value = personalizeButton[i];
      };
      personalizeButton[i].ondragstart = (e: any) => {
        isMovingPersonalize.value = true;
        selectedButton.value = personalizeButton[i];
        e.dataTransfer.setData('personalize', JSON.stringify(personalize));
      };

      personalizeButton[i].ondragend = (e: any) => {
        e.dataTransfer.clearData();
      };
    }
    //Coupon Action
    const couponButtonList: any = document?.getElementsByClassName('coupon-button');

    for (let i = 0; i < couponButtonList.length; i++) {
      const couponObj = JSON.parse(couponButtonList[i].getAttribute('value') as string);

      couponButtonList[i].onclick = () => {
        isEditCoupon.value = true;
        couponObjColumn.value = couponObj;
        vm?.$emit('on-select-coupon');
        selectedButton.value = couponButtonList[i];
      };
      couponButtonList[i].ondragstart = (e: any) => {
        isMovingCoupon.value = true;
        selectedButton.value = couponButtonList[i];
        e.dataTransfer.setData('coupon', JSON.stringify(couponObj));
      };

      couponButtonList[i].ondragend = (e: any) => {
        e.dataTransfer.clearData();
      };
    }

    function onDragOver(e: any) {
      e.preventDefault();
      if (document.caretRangeFromPoint) {
        const selection = document.getSelection();
        const range = document.caretRangeFromPoint(e.clientX as number, e.clientY as number);
        if (selection && range) {
          selection.setBaseAndExtent(range.startContainer, range.startOffset, range.startContainer, range.startOffset);
        }
      }
    }
    function onDrop(e: any) {
      e.preventDefault();
      quillInstance.focus();
      const cursorPosition = quillInstance.getSelection()?.index;
      if (isMovingPersonalize.value) {
        isMovingPersonalize.value = false;
        quillInstance.insertEmbed(cursorPosition, 'personalize', JSON.parse(e.dataTransfer.getData('personalize') as string));
      } else if (isMovingCoupon.value) {
        isMovingCoupon.value = false;
        quillInstance.insertEmbed(cursorPosition, 'coupon', JSON.parse(e.dataTransfer.getData('coupon') as string));
      }
      saveQuillTextToEDM();
      const text = decodeURI(EDMGetObjList('cdp-edm-editor')[selectedElement.id].Properties.Content.Properties.RawText as string);
      const removeItem = text.replace(selectedButton.value.outerHTML as string, ' ');
      QuillSetHtml(quillInstance, removeItem);
      saveQuillTextToEDM();
      vm?.$emit('on-get-func-save-text', null);
    }
    if (quillInstance) {
      quillInstance.root.addEventListener('drop', onDrop, false);
      quillInstance.root.addEventListener('dragover', onDragOver, false);
    }
  }

  function filterMapper() {
    const currentMapper = [];
    const edmEditor = document.getElementById('cdp-edm-editor') as HTMLDivElement;
    const personalizeButton: any = edmEditor.getElementsByClassName('personalize-button');
    const couponButtonList: any = edmEditor.getElementsByClassName('coupon-button');
    const listScanCoupon: any = edmEditor.getElementsByClassName('scan-coupon-code');
    //personalize
    for (let i = 0; i < personalizeButton.length; i++) {
      const mapper: Campaign.Personalize = JSON.parse(personalizeButton[i].getAttribute('value') as string);
      currentMapper.push(mapper);
    }
    //coupon
    for (let i = 0; i < couponButtonList.length; i++) {
      const mapper: Campaign.Personalize = JSON.parse(couponButtonList[i].getAttribute('value') as string);
      currentMapper.push(mapper);
    }
    //scan coupon
    if (listScanCoupon) {
      for (let i = 0; i < listScanCoupon.length; i++) {
        const mapper: Campaign.Personalize = JSON.parse(listScanCoupon[i].getAttribute('value') as string).CouponColumnDetail;
        currentMapper.push(mapper);
      }
    }
    ///tracking url
    edmEditor.querySelectorAll('a').forEach((element: HTMLElement) => {
      if (isJsonString(element.getAttribute('value') as string)) {
        const mapper: Campaign.TrackingMapper = JSON.parse(element.getAttribute('value') as string);
        if (mapper?.tag) {
          currentMapper.push(mapper);
        }
      }
    });
    ///tracking open mail
    const trackingOpenMailString = EDMGetEditor(editorId).Properties.AfterContentHtml;
    if (trackingOpenMailString && trackingOpenMailString !== '') {
      const trackingOpenMailHtml = new DOMParser().parseFromString(trackingOpenMailString as string, 'text/html');
      const trackingOpenMail = JSON.parse(trackingOpenMailHtml.querySelector('.tracking-open-mail')?.getAttribute('value') as string);
      currentMapper.push(trackingOpenMail);
    }

    return currentMapper.filter(filterUniqueMapper);
  }

  function filterUniqueMapper(value: any, index: any, array: any) {
    return array.findIndex((item: any) => item.tag === value.tag) === index;
  }

  function isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const onApplyColor = (color: string) => {
    // quillInstance.focus();
    // const range = quillInstance.getSelection();
    if (quillSelectionRange.value) {
      if (quillSelectionRange.value.length === 0) {
        // User cursor is at index, do nothing
      } else {
        quillInstance.formatText(quillSelectionRange.value.index, quillSelectionRange.value.length, {
          color: color,
        });
        toolbarRef.value.setColor(color || '');
      }
    } else {
      // User cursor is not in editor, do nothing
    }
  };

  function addMapperToEdmContent() {
    const edmRowList = EDMGetObjMappingList(editorId);

    for (let i = 0; i < edmRowList.length; i++) {
      const columnList = edmRowList[i].Col;
      for (let i = 0; i < columnList.length; i++) {
        const content = EDMGetContentFromCol(editorId, columnList[i].Id as string);

        if (content.Properties.Content.Type == 'Text') {
          let str = decodeURI(EDMGetObjList(editorId)[content.Id].Properties.Content.Properties.RawText as string);
          const htmlElement = new DOMParser().parseFromString(str, 'text/html');
          htmlElement.querySelectorAll('a').forEach((element: HTMLElement) => {
            mapper.trackingUrl.linkValue = element.getAttribute('href');
            const trackingMapper: Campaign.TrackingMapper = JSON.parse(element.getAttribute('value') as string);
            if (trackingMapper?.tag) {
              mapper.trackingUrl.tag = trackingMapper.tag;
            } else {
              mapper.trackingUrl.tag = `{{mp:${tagNumber.value}}}`;
              tagNumber.value--;
            }
            element.setAttribute('value', JSON.stringify(mapper.trackingUrl));
          });
          str = htmlElement.body.innerHTML;

          EDMGetObjList(editorId)[content.Id].Properties.Content.Properties.Text = encodeURI(`<span class='edm-rendered-html'>${str}</span>`);
          EDMGetObjList(editorId)[content.Id].Properties.Content.Properties.RawText = encodeURI(str);
        } else if (content.Properties.Content.Type == 'Image') {
          const trackingMapper: Campaign.TrackingMapper = {
            mapperType: 10,
            subType: 2,
            linkValue: null,
            tag: null,
          };
          function setMapperImage(): Campaign.TrackingMapper | null {
            const imgLink = EDMGetObjList(editorId)[content.Id].Properties.Content.Properties.Link;
            if (imgLink) {
              const valueAttribute = EDMGetObjList('cdp-edm-editor')[content.Id].Properties.Content.Properties.Value;
              if (!valueAttribute) {
                trackingMapper.tag = `{{mp:${tagNumber.value}}}`;
                tagNumber.value--;
              }
              trackingMapper.linkValue = imgLink;
              return trackingMapper;
            }
            return null;
          }
          EDMGetObjList(editorId)[content.Id].Properties.Content.Properties.Value = setMapperImage();
        } else if (content.Properties.Content.Type == 'Social') {
          const socialList = EDMGetObjList(editorId)[content.Id].Properties.Content.Properties.SocialList;
          let keyChange = 1;
          const keyList: number[] = [];
          socialList.forEach((social: Communication.EdmEditor.MappingSocialPayload, index: number) => {
            if (!EDMGetObjList(editorId)[content.Id].Properties.Content.Properties.SocialList[index]?.Key) {
              social.Key = keyChange;
              keyChange++;
            } else {
              social.Key = EDMGetObjList(editorId)[content.Id].Properties.Content.Properties.SocialList[index].Key;
              keyList.push(social.Key);
              const max = Math.max(...keyList);
              keyChange = max;
              keyChange++;
            }
          });
          function setMapperSocial(
            link: string,
            listSocial: Communication.EdmEditor.MappingSocialPayload[],
            key: number,
          ): Campaign.TrackingMapper | null {
            if (link) {
              const mapper = {
                trackingUrl: {
                  mapperType: 10,
                  subType: 2,
                  linkValue: '',
                  tag: '',
                },
              };
              const social = listSocial.find((social: Communication.EdmEditor.MappingSocialPayload) => social.Key === key);
              if (!social?.Value) {
                mapper.trackingUrl.tag = `{{mp:${tagNumber.value}}}`;
                tagNumber.value--;
              } else {
                mapper.trackingUrl.tag = social.Value.tag as string;
              }
              mapper.trackingUrl.linkValue = link;

              return mapper.trackingUrl;
            }
            return null;
          }
          const socialListPayload = socialList.map((social: Communication.EdmEditor.MappingSocialPayload, index: number) => ({
            ...social,
            Value: setMapperSocial(
              social.Link,
              EDMGetObjList(editorId)[content.Id].Properties.Content.Properties.SocialList as Communication.EdmEditor.MappingSocialPayload[],
              social.Key,
            ),
          }));
          EDMGetObjList(editorId)[content.Id].Properties.Content.Properties.SocialList = socialListPayload;
        }
      }
    }
    EDMRender(editorId);
    EDMGetProperties(editorId).mapper = filterMapper();
    vm?.$emit('on-get-edm-config-json', EDMRenderSave(editorId));
    vm?.$emit('on-get-edm-content', EDMRenderResult(editorId));
  }

  function createEdmDefaultTemplate() {
    const defaultSetting = {
      enablePadding: true,
      top: 10,
      bottom: 10,
      left: 10,
      right: 10,
    };
    //row1
    const row1 = EDMAddRow(editorId, {
      Properties: {
        EnableBackground: true,
        BackgroundImage: null,
        Padding: {
          Top: 0,
          Bottom: 0,
        },
        Style: {
          width: '600px',
          'background-color': '#FFFFFF',
          'background-image': null,
        },
      },
    });
    const col1 = EDMAddCol(editorId, row1);
    EDMAddContent(
      editorId,
      col1,
      {
        Type: 'Text',
        Properties: {
          Text: "%3Cspan%20class='edm-rendered-html'%3E%3Cp%20class=%22no-margin-no-padding%22%20style=%22text-align:%20center;%22%3E%3Cspan%20style=%22font-size:%2048px;%20color:%20rgb(255,%200,%20166);%22%3ECompany%20Logo%3C/span%3E%3C/p%3E%3C/span%3E",
          RawText:
            '%3Cp%20class=%22no-margin-no-padding%22%20style=%22text-align:%20center;%22%3E%3Cspan%20style=%22font-size:%2048px;%20color:%20rgb(255,%200,%20166);%22%3ECompany%20Logo%3C/span%3E%3C/p%3E',
        },
      },
      {
        Properties: {
          EnablePadding: defaultSetting.enablePadding,
          Padding: {
            Top: defaultSetting.top,
            Bottom: defaultSetting.bottom,
            Left: defaultSetting.left,
            Right: defaultSetting.right,
          },
          Style: {
            'padding-top': `${defaultSetting.top}px`,
            'padding-bottom': `${defaultSetting.bottom}px`,
            'padding-left': `${defaultSetting.left}px`,
            'padding-right': `${defaultSetting.right}px`,
          },
        },
      },
    );
    //row2
    const row2 = EDMAddRow(editorId, {
      Properties: {
        EnableBackground: true,
        BackgroundImage: null,
        Padding: {
          Top: 0,
          Bottom: 0,
        },
        Style: {
          width: '600px',
          'background-color': '#FFFFFF',
          'background-image': null,
        },
      },
    });
    const col2 = EDMAddCol(editorId, row2);
    EDMAddContent(editorId, col2, { Type: 'ImageBlank', Properties: {} }, { Properties: { HTMLUI: addImageUI } });

    //row3
    const row3 = EDMAddRow(editorId, {
      Properties: {
        EnableBackground: true,
        BackgroundImage: null,
        Padding: {
          Top: 0,
          Bottom: 0,
        },
        Style: {
          width: '600px',
          'background-color': '#FFFFFF',
          'background-image': null,
        },
      },
    });
    const col3 = EDMAddCol(editorId, row3);
    EDMAddContent(
      editorId,
      col3,
      {
        Type: 'Text',
        Properties: {
          Text: encodeURI(`<span class='edm-rendered-html'>${defaultHtmlContent}</span>`),
          RawText: encodeURI(`${defaultHtmlContent}`),
        },
      },
      {
        Properties: {
          EnablePadding: defaultSetting.enablePadding,
          Padding: {
            Top: defaultSetting.top,
            Bottom: defaultSetting.bottom,
            Left: defaultSetting.left,
            Right: defaultSetting.right,
          },
          Style: {
            'padding-top': `${defaultSetting.top}px`,
            'padding-bottom': `${defaultSetting.bottom}px`,
            'padding-left': `${defaultSetting.left}px`,
            'padding-right': `${defaultSetting.right}px`,
          },
        },
      },
    );

    //row4
    const row4 = EDMAddRow(editorId, {
      Properties: {
        EnableBackground: true,
        BackgroundImage: null,
        Padding: {
          Top: 0,
          Bottom: 0,
        },
        Style: {
          width: '600px',
          'background-color': '#FFFFFF',
          'background-image': null,
        },
      },
    });
    const col1Row4 = EDMAddCol(editorId, row4);
    const col2Row4 = EDMAddCol(editorId, row4);
    EDMAddContent(editorId, col1Row4, { Type: 'ImageBlank', Properties: {} }, { Properties: { HTMLUI: addImageUI } });
    EDMAddContent(editorId, col2Row4, { Type: 'ImageBlank', Properties: {} }, { Properties: { HTMLUI: addImageUI } });

    //row5
    const row5 = EDMAddRow(editorId, {
      Properties: {
        EnableBackground: true,
        BackgroundImage: null,
        Padding: {
          Top: 0,
          Bottom: 0,
        },
        Style: {
          width: '600px',
          'background-color': '#FFFFFF',
          'background-image': null,
        },
      },
    });
    const col5 = EDMAddCol(editorId, row5);
    EDMAddContent(
      editorId,
      col5,
      {
        Type: 'Text',
        Properties: {
          Text: encodeURI(`<span class='edm-rendered-html'>${defaultHtmlContent}</span>`),
          RawText: encodeURI(`${defaultHtmlContent}`),
        },
      },
      {
        Properties: {
          EnablePadding: defaultSetting.enablePadding,
          Padding: {
            Top: defaultSetting.top,
            Bottom: defaultSetting.bottom,
            Left: defaultSetting.left,
            Right: defaultSetting.right,
          },
          Style: {
            'padding-top': `${defaultSetting.top}px`,
            'padding-bottom': `${defaultSetting.bottom}px`,
            'padding-left': `${defaultSetting.left}px`,
            'padding-right': `${defaultSetting.right}px`,
          },
        },
      },
    );

    //row6
    const socialProp = process.env.NODE_ENV == 'development' ? defaultSocialPropertiesDev : defaultSocialPropertiesProd;
    const row6 = EDMAddRow(editorId, {
      Properties: {
        EnableBackground: true,
        BackgroundImage: null,
        Padding: {
          Top: 0,
          Bottom: 0,
        },
        Style: {
          width: '600px',
          'background-color': '#FFFFFF',
          'background-image': null,
        },
      },
    });
    const col6 = EDMAddCol(editorId, row6);
    EDMAddContent(editorId, col6, { Type: 'Social', Properties: socialProp }, { Properties: { Style: defaultSocialStyle } });

    //row7
    const row7 = EDMAddRow(editorId, {
      Properties: {
        EnableBackground: true,
        BackgroundImage: null,
        Padding: {
          Top: 0,
          Bottom: 0,
        },
        Style: {
          width: '600px',
          'background-color': '',
          'background-image': null,
        },
      },
    });
    const col7 = EDMAddCol(editorId, row7);
    EDMAddContent(
      editorId,
      col7,
      {
        Type: 'Text',
        Properties: {
          Text: "%3Cspan%20class='edm-rendered-html'%3E%3Cp%20class=%22no-margin-no-padding%22%20style=%22text-align:%20center;%22%3E%3Cspan%20class=%22ql-font-arial%22%3EChoco%20CRM%20123/4%20Bangkok,%20Thailand%3C/span%3E%3C/p%3E%3Cp%20class=%22no-margin-no-padding%22%20style=%22text-align:%20center;%22%3E%3Ca%20href=%22Manage%20Reference%22%20target=%22_blank%22%20class=%22ql-font-arial%22%3EManage%20Reference%3C/a%3E%3C/p%3E%3C/span%3E",
          RawText:
            '%3Cp%20class=%22no-margin-no-padding%22%20style=%22text-align:%20center;%22%3E%3Cspan%20class=%22ql-font-arial%22%3EChoco%20CRM%20123/4%20Bangkok,%20Thailand%3C/span%3E%3C/p%3E%3Cp%20class=%22no-margin-no-padding%22%20style=%22text-align:%20center;%22%3E%3Ca%20href=%22Manage%20Reference%22%20target=%22_blank%22%20class=%22ql-font-arial%22%3EManage%20Reference%3C/a%3E%3C/p%3E',
        },
      },
      {
        Properties: {
          EnablePadding: defaultSetting.enablePadding,
          Padding: {
            Top: 20,
            Bottom: defaultSetting.bottom,
            Left: defaultSetting.left,
            Right: defaultSetting.right,
          },
          Style: {
            'padding-top': `20px`,
            'padding-bottom': `${defaultSetting.bottom}px`,
            'padding-left': `${defaultSetting.left}px`,
            'padding-right': `${defaultSetting.right}px`,
          },
        },
      },
    );
  }

  function decreaseTagNumberFromLatest() {
    const tagNumList: number[] = [];
    filterMapper().forEach((mapper: any) => {
      tagNumList.push(mapper.tag.split(/(-?\d+)/)[1] as number);
      const min = Math.min(...tagNumList);
      if (min > 0) tagNumber.value = 0;
      else if (min < 0) tagNumber.value = min;
      tagNumber.value--;
    });
  }

  onMounted(() => {
    initEdmEditor();
    EDMGetProperties(editorId).BaseLayout.Style['background-color'] = 'unset';
    EDMGetProperties(editorId).SrefList = srefList.value;
    if (props.edmConfigJson != '') {
      if (JSON.parse(props.edmConfigJson).Properties.mapper) {
        mapperList.value = JSON.parse(props.edmConfigJson).Properties.mapper;
      }
      EDMRenderLoad(editorId, props.edmConfigJson);
      EDMRender(editorId);
      if (!EDMGetEditor(editorId).Properties.AfterContentHtml) {
        EDMGetEditor(editorId).Properties.AfterContentHtml = buildTrackingOpenMailEle();
        EDMGetProperties(editorId).mapper = filterMapper();
        addMapperToEdmContent();
      }
    } else {
      createEdmDefaultTemplate();
      EDMGetEditor(editorId).Properties.AfterContentHtml = buildTrackingOpenMailEle();
      EDMGetProperties(editorId).mapper = filterMapper();
    }
    EDMRender(editorId);
    vm?.$emit('on-get-edm-config-json', EDMRenderSave(editorId));
    vm?.$emit('on-get-edm-content', EDMRenderResult(editorId));
  });

  onBeforeUnmount(() => {
    EDMUnInitEditor(editorId);
  });

  // this watch consume when
  // 1. Template -> Edit
  // 2. Campaign -> Detail
  watch(
    () => props.edmConfigJson,
    () => {
      if (props.edmConfigJson != '') {
        if (JSON.parse(props.edmConfigJson).Properties.mapper) {
          mapperList.value = JSON.parse(props.edmConfigJson).Properties.mapper;
        }
        EDMRenderLoad(editorId, props.edmConfigJson);
        EDMRender(editorId);
        if (!EDMGetEditor(editorId).Properties.AfterContentHtml) {
          EDMGetEditor(editorId).Properties.AfterContentHtml = buildTrackingOpenMailEle();
          EDMGetProperties(editorId).mapper = filterMapper();
          addMapperToEdmContent();
        } else {
          decreaseTagNumberFromLatest();
          addMapperToEdmContent();
        }
        vm?.$emit('is-editing-edm');
      }
    },
    { deep: true },
  );

  watch(
    srefList,
    () => {
      EDMGetProperties(editorId).SrefList = srefList.value;
      vm?.$emit('on-get-edm-config-json', EDMRenderSave(editorId));
      vm?.$emit('on-get-edm-content', EDMRenderResult(editorId));
    },
    { deep: true },
  );

  watch(
    () => props.isSaved,
    () => {
      if (props.templateMode) {
        srefList.value = [];
        decreaseTagNumberFromLatest();
      } else {
        if (props.isSaved) {
          srefList.value = [];
          tagNumber.value = -1;
        } else {
          decreaseTagNumberFromLatest();
        }
      }
    },
  );

  watch(
    () => props.isClearUi,
    () => {
      if (props.isClearUi) {
        removeEdmUi(selectedElement);
        removeEdmUi(hoverElement);
      }
    },
  );

  const handleDragStart = (e: any, item: Communication.EdmEditor.DesignNode) => {
    if (item) {
      selectedNode.value = item;
      e.dataTransfer.effectAllowed = 'move';
    }
  };

  return {
    quillInstanceWrapper,
    toolbarRef,
    designNodeConstantList,
    selectedElement,
    backupSelected,
    mapperList,
    personalizeObjColumn,
    isEditPersonalize,
    isEditCoupon,
    couponObjColumn,
    isCouponScanMode,
    selectedCoupon,
    listCouponColumn,
    tagNumber,
    onAddSrefList,
    handleDragStart,
    removeEdmUi,
    handleSelectedElement,
    setIsSelected,
    onSelectedEmoji,
    onApplyColor,
    onAddEditPersonalize,
    onAddEditCoupon,
    closePersonalizeModal,
    closeCouponModal,
    onSelectCouponFromScan,
    onAddCouponFromScan,
    onAddedCouponFromScan,
    onChangeCoupon,
    updateTagNumber,
    onBuildObj,
    onSaveText,
  };
}
