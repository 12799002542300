<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="7.945" height="9.509" viewBox="0 0 7.945 9.509">
    <path
      d="M23.466,30.008h0a.707.707,0,0,0,.994-.106l6.531-8.095a.707.707,0,1,0-1.1-.888l-6.53,8.095a.706.706,0,0,0,.106.994"
      transform="translate(-23.203 -20.656)"
    />
    <path
      d="M30.885,30.008h0a.707.707,0,0,1-.994-.106L23.36,21.807a.707.707,0,1,1,1.1-.888l6.53,8.095a.706.706,0,0,1-.106.994"
      transform="translate(-23.203 -20.656)"
    />
  </svg>
</template>
