import { ref, Ref, reactive } from 'vue';

export default function useVideoTabPanel() {
  const disabledPadding: Ref<boolean> = ref(true);
  const uploadVideo: any = reactive({ file: null, url: null });
  const uploadThumbnail: any = reactive({ file: null, url: null });
  const selectedVideoType: Ref<any> = ref(1);
  const videoLink: Ref<any> = ref(null);
  const isCorrectedUrlLink: Ref<boolean> = ref(true);
  const thumbnailWidth: Ref<number> = ref(0);
  const thumbnailHeight: Ref<number> = ref(0);
  const spacingTop: Ref<number> = ref(10);
  const spacingBottom: Ref<number> = ref(10);
  const indentLeft: Ref<number> = ref(10);
  const indentRight: Ref<number> = ref(10);
  const alignMent: Ref<string> = ref('center');
  const justifyMent: Ref<string> = ref('center');

  const handleDisablePadding = () => {
    disabledPadding.value = !disabledPadding.value;
  };

  const onSelectAlignMent = (align: string) => {
    alignMent.value = align;
  };

  const onSelectJustifyMent = (justify: string) => {
    justifyMent.value = justify;
  };

  const matchYoutubeUrl = (url: string) => {
    const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const matches = url.match(p);
    if (matches) {
      return matches[1];
    } else {
      isCorrectedUrlLink.value = false;
      return false;
    }
  };

  const onChangeVideoLink = (link: string) => {
    if (matchYoutubeUrl(link)) {
      const regex: any = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
      videoLink.value = `https://www.youtube.com/embed/${regex.exec(link)[3]}`;
      isCorrectedUrlLink.value = true;
    } else {
      videoLink.value = '';
    }
  };

  const onSelectVideoType = (type: number) => {
    selectedVideoType.value = type;
    videoLink.value = null;
    uploadVideo.file = null;
    uploadVideo.url = null;
    uploadThumbnail.file = null;
    uploadThumbnail.url = null;
    isCorrectedUrlLink.value = true;
  };

  const handleUploadFileChange = (e: any, type: string) => {
    const filesOBJ: File = e.target?.files[0];
    try {
      if (!onValidateFile(filesOBJ, type)) {
        console.log('invalid format');
        return;
      }
      const newObjUrl = URL.createObjectURL(filesOBJ as Blob);

      if (type == 'image') {
        uploadThumbnail.file = filesOBJ;
        uploadThumbnail.url = newObjUrl;
        onGetImageSize(uploadThumbnail.url as string);
      } else if (type == 'video') {
        uploadVideo.file = filesOBJ;
        uploadVideo.url = newObjUrl;
      }
    } catch (err) {}
  };

  const onGetImageSize = (url: string) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      thumbnailWidth.value = img.width;
      thumbnailHeight.value = img.height;
    };
  };

  const onValidateFile = (file: File, type: string) => {
    const fileType = file.type.split('/');
    const extensionList = {
      image: ['png', 'jpeg', 'jpg'],
      video: ['mp4'],
    };
    if (fileType[0] === 'image' && type === 'image') {
      return extensionList.image.includes(fileType[1]);
    } else if (fileType[0] === 'video' && type === 'video') {
      return extensionList.video.includes(fileType[1]);
    } else return false;
  };

  const clearFile = (e: any, type: string) => {
    if (type == 'image') {
      uploadThumbnail.file = null;
      uploadThumbnail.url = null;
      thumbnailWidth.value = 0;
      thumbnailHeight.value = 0;
    } else {
      uploadVideo.file = null;
      uploadVideo.url = null;
    }
    e.value = null;
  };

  return {
    uploadVideo,
    uploadThumbnail,
    selectedVideoType,
    videoLink,
    isCorrectedUrlLink,
    disabledPadding,
    spacingTop,
    spacingBottom,
    indentLeft,
    indentRight,
    thumbnailWidth,
    thumbnailHeight,
    alignMent,
    justifyMent,
    handleDisablePadding,
    onSelectAlignMent,
    onSelectJustifyMent,
    onChangeVideoLink,
    onSelectVideoType,
    handleUploadFileChange,
    clearFile,
  };
}
