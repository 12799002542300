export default function dashboardAllChannelModel() {
  const getChartDataModel: API.RequestModel<Communication.DashboardAllChannel.Request.ChartData> = {
    endpoint: `${process.env.BASE_URL_BI!}/Chart/Data`,
    method: 'POST',
    payload: {
      Impl_Key: '',
      Filters: [],
    },
    acceptLanguage: 'en-US',
  };

  const getChartDataInfoModel: API.RequestModel<Communication.DashboardAllChannel.Request.ChartDataInfo> = {
    endpoint: `${process.env.BASE_URL_BI!}/Chart/DataInfo`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      ChartSetId: null!,
      ChartId: null!,
      Impl_Type: null!,
      Impl_Key_Ref: '',
      Filters: [],
    },
    acceptLanguage: 'en-US',
  };

  const getExportChartDataInfoModel: API.RequestModel<Communication.DashboardAllChannel.Request.ChartDataInfo> = {
    endpoint: `${process.env.BASE_URL_BI!}/Chart/ExportDataInfo`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      ChartSetId: null!,
      ChartId: null!,
      Impl_Type: null!,
      Impl_Key_Ref: '',
      Filters: [],
    },
    responseType: 'blob',
  };

  return {
    getChartDataModel,
    getChartDataInfoModel,
    getExportChartDataInfoModel,
  };
}
