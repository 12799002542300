import { EChartsOption } from 'echarts';

const ITEM_COLOR = '#D7E4F0';
const MAX_ITEM_COLOR = '#0086FF';

interface RawData {
  category: string[];
  data: number[];
}

interface Item {
  value: number;
  itemStyle: {
    color: string;
  };
}

function mapper(data: number[]): Item[] {
  const newArr: Item[] = data.map((el) => ({
    value: el,
    itemStyle: {
      color: ITEM_COLOR,
    },
  }));

  let max = -Infinity;
  let maxIndices: number[] = [];

  for (let i = 0; i < newArr.length; i++) {
    if (newArr[i].value === max) {
      maxIndices.push(i);
    } else if (newArr[i].value > max) {
      maxIndices = [i];
      max = newArr[i].value;
    }
  }

  maxIndices.forEach((index: number) => {
    newArr[index].itemStyle.color = MAX_ITEM_COLOR;
  });

  return newArr;
}

export default function (rawData: RawData): EChartsOption {
  const options: EChartsOption = {
    tooltip: {
      trigger: 'item',
    },
    xAxis: {
      type: 'category',
      data: rawData.category,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: mapper(rawData.data),
        type: 'bar',
      },
    ],
  };

  return options;
}
