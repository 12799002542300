/**
  @enum {number} {
  *
  *  video = จะมี object เสมอ,
  *
  *  linkUrl = จะไม่เจอ object กรณี rich-video ตั้งค่าไว้ว่าซ่อนปุ่ม
  *
 }

 */
export enum richVideoMapperTagEnum {
  video = 0,
  linkUrl = 1,
}
