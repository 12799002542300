import { ref, Ref, onBeforeMount, watch } from 'vue';
import { useRouter } from 'vue-router';

import api from '@services/api';
import segmentTabsModel from '@/models/segment/segmentTabs';

export default function () {
  const router = useRouter();

  const selectedSource: Ref<Segment.Datasource | null> = ref(null);
  const { fetchSegmentTabsModel } = segmentTabsModel();
  const segmentConfig: Ref<Segment.Config> = ref(null!);
  const tabs: Ref<Segment.Datasource[]> = ref([]);
  const activeTab: Ref<number> = ref(Number(router.currentRoute.value.query.tab) || 0);

  const fetchSegmentTabs = async () => {
    return api.apiRequest(fetchSegmentTabsModel).then((response: any) => {
      return (tabs.value = response.data.payload), (segmentConfig.value = response.data.config.segment_rule_flag);
    });
  };

  watch(activeTab, (v) => {
    selectedSource.value = tabs.value[v];
  });

  watch(
    () => router.currentRoute.value.query.tab,
    (currentTabs) => {
      activeTab.value = Number(currentTabs);
    },
  );

  watch(
    () => router.currentRoute.value.query,
    (routerQueryNew, routerQueryOld) => {
      if (!routerQueryNew.tab) {
        router.replace({ path: router.currentRoute.value.fullPath, query: { ...router.currentRoute.value.query, tab: routerQueryOld.tab } });
      }
    },
  );

  onBeforeMount(() => {
    fetchSegmentTabs().then(() => {
      selectedSource.value = tabs.value[activeTab.value];
      router.replace({ path: router.currentRoute.value.fullPath, query: { ...router.currentRoute.value.query, tab: activeTab.value } });
    });
  });

  return { tabs, activeTab, selectedSource, segmentConfig };
}
