import { ref, Ref, watch, onBeforeMount, getCurrentInstance } from 'vue';

import CustomerIcon from '@/assets/icons/base/tabs/CustomerIcon.vue';
import BoxIcon from '@/assets/icons/base/tabs/BoxIcon.vue';
import MoneyTurnOverIcon from '@/assets/icons/base/tabs/MoneyTurnOverIcon.vue';
import IntersectionBoxesIcon from '@/assets/icons/base/tabs/IntersectionBoxesIcon.vue';

interface Props {
  items: Segment.Datasource[];
  showArrow?: boolean;
  showExpanded?: boolean;
  modelValue?: number;
}

export const useSegmentTabs = (props: Props) => {
  const vm = getCurrentInstance()?.proxy;

  const navRef = ref<Element>(null!);

  const tabs: Ref<Segment.Datasource[]> = ref([]);

  const isStart: Ref<boolean> = ref(true);
  const isEnd: Ref<boolean> = ref(false);
  const expanded: Ref<boolean> = ref(false);

  const showMenu: Ref<boolean> = ref(false);

  const resolveIcon = (datasource: Segment.Datasource) => {
    switch (datasource.type) {
      case 100:
        return CustomerIcon;
      case 200:
        return BoxIcon;
      case 300:
        return MoneyTurnOverIcon;
      case 500:
        return IntersectionBoxesIcon;
      default:
        return IntersectionBoxesIcon;
    }
  };

  const onClickArrows = (facing: -1 | 1) => {
    const containerElement: HTMLElement = navRef.value as HTMLElement;
    containerElement.classList.add('snap-align-start');
    const elementList = navRef.value?.querySelectorAll('li');
    let closestPosition: number | null = null;
    let correctIndex = -1;
    if (elementList) {
      elementList.forEach((el: Element, index: number) => {
        const elementConvert: HTMLElement = el as HTMLElement;
        const distanceRange = Math.abs(window.globalOffset(elementConvert).left - window.globalOffset(containerElement).left);
        if (distanceRange <= (closestPosition || distanceRange)) {
          closestPosition = distanceRange;
          correctIndex = index + 1;
        }
      });
    }
    if (correctIndex != -1) {
      if (facing > 0) {
        navRef.value.scrollLeft = navRef.value.scrollLeft + (navRef.value.querySelector('li:nth-of-type(' + correctIndex + ')')?.clientWidth || 0);
      } else {
        navRef.value.scrollLeft =
          navRef.value.scrollLeft - (navRef.value.querySelector('li:nth-of-type(' + (correctIndex - 1 || 1) + ')')?.clientWidth || 0);
      }
    }
    containerElement.classList.remove('snap-align-start');
  };

  const onClickExpand = () => {
    expanded.value = !expanded.value;
    scrollToTabActive();
  };

  const arrowsStateWatcher = () => {
    setTimeout(() => {
      isStart.value = navRef.value.scrollLeft == 0;
      isEnd.value = Math.ceil(navRef.value.scrollLeft + navRef.value.clientWidth) >= navRef.value.scrollWidth;
    }, 50);
  };

  const scrollToTabActive = () => {
    setTimeout(() => {
      const element = document.getElementById(`segment-tab-${props.modelValue}`) as HTMLElement;
      if (element) {
        element.scrollIntoView({ block: 'end' });
      }
    }, 50);
  };

  const onSelectDropdownItem = (selectedTab: Segment.Datasource) => {
    const tabIndex = props.items.findIndex((tab) => tab.table_label == selectedTab.table_label);

    vm?.$emit('update:modelValue', tabIndex);
  };

  const onClickOutsideMenu = () => {
    showMenu.value = !showMenu.value;
  };

  watch(
    () => props.items,
    () => {
      if (props.items && props.items.length) {
        tabs.value = props.items;
      }
    },
  );

  watch(
    () => props.modelValue,
    () => scrollToTabActive(),
  );

  onBeforeMount(() => {
    if (props.items && props.items.length) {
      tabs.value = props.items;
    }
  });

  return {
    navRef,
    tabs,
    isStart,
    isEnd,
    expanded,
    showMenu,
    scrollToTabActive,
    arrowsStateWatcher,
    onClickExpand,
    onClickArrows,
    onClickOutsideMenu,
    resolveIcon,
    onSelectDropdownItem,
  };
};
