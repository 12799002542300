<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21.531" height="24.384" viewBox="0 0 21.531 24.384">
    <g id="Group_54151" data-name="Group 54151" transform="translate(-1487.537 -1131.087)">
      <path
        id="Path_31603"
        data-name="Path 31603"
        d="M1507,1136.82a.166.166,0,0,0,.03.021,5.508,5.508,0,0,0,1.322,2.7,2.178,2.178,0,0,0,.707.64l0,0c-.018.047.013.052.047.053l0,0c-.007.036.015.047.044.053l.184.185c.011.029.02.059.061.053l-.005,0a2.3,2.3,0,0,0,.531.6,5.555,5.555,0,0,0,2.863,1.451l.015.024c0,.685,0,1.37-.007,2.055,0,.227.106.532-.048.661s-.442.015-.671,0a9.575,9.575,0,0,1-4.954-1.813c-.059-.043-.1-.125-.207-.106,0,.089,0,.177,0,.264q0,4.016.011,8.032a7.454,7.454,0,0,1-.121,1.473,7.288,7.288,0,0,1-10.511,5.038c-.31-.157-.605-.342-.907-.513l0,0c0-.043-.029-.054-.063-.06l-.222-.209a.025.025,0,0,0-.02-.032l-.015-.015-.033-.04-.012-.011c-.009-.024-.022-.043-.052-.033l.007-.012a1.421,1.421,0,0,0-.081-.142,7.093,7.093,0,0,1-1.34-3.449,7.211,7.211,0,0,1,3.87-7.153,7.6,7.6,0,0,1,3.306-.818.169.169,0,0,0,.019.022c0,.868-.009,1.737,0,2.6,0,.159-.031.2-.194.158a3.185,3.185,0,0,0-1.323-.1,3.347,3.347,0,0,0-2.831,2.195,3.27,3.27,0,0,0,.644,3.495,1.772,1.772,0,0,0,.734.586l0,0c-.01.037.014.044.041.048l.1.1c.006.028.012.057.052.049h0c-.012.046.019.073.042.1a3.515,3.515,0,0,0,3.564,1.3,3.455,3.455,0,0,0,2.707-3.449q0-7.872,0-15.744c0-.195.04-.251.243-.248C1505.346,1136.832,1506.174,1136.822,1507,1136.82Z"
        transform="translate(-4.828 -4.634)"
        fill="#010101"
      />
      <path
        id="Path_31604"
        data-name="Path 31604"
        d="M1503.276,1182c.3.172.6.356.907.513a7.288,7.288,0,0,0,10.511-5.038,7.444,7.444,0,0,0,.121-1.473q-.009-4.016-.011-8.032c0-.087,0-.175,0-.264.1-.019.148.064.207.106a9.574,9.574,0,0,0,4.954,1.813c.229.018.523.127.671,0s.045-.434.048-.661c.009-.685.006-1.37.008-2.056a6.236,6.236,0,0,0,.926.087c.136,0,.172.047.172.18,0,1.139-.007,2.279,0,3.418,0,.175-.082.168-.2.164a9.668,9.668,0,0,1-5.478-1.847c-.052-.037-.1-.073-.156-.108,0,0-.014,0-.049.008,0,.077,0,.16,0,.244q.035,4.039.071,8.079a7.3,7.3,0,0,1-12.587,5.031C1503.342,1182.121,1503.264,1182.088,1503.276,1182Z"
        transform="translate(-12.721 -28.959)"
        fill="#ee0245"
      />
      <path
        id="Path_31605"
        data-name="Path 31605"
        d="M1525.776,1132.186c-.828,0-1.656.012-2.484,0-.2,0-.243.053-.243.248q.008,7.872,0,15.744a3.455,3.455,0,0,1-2.707,3.449,3.516,3.516,0,0,1-3.564-1.3c-.023-.032-.054-.059-.042-.1.276.094.546.209.828.28a3.463,3.463,0,0,0,4.336-3.379c.009-2.963,0-5.926,0-8.89q0-3.453,0-6.906c0-.181.038-.241.232-.239q1.661.015,3.322,0c.174,0,.229.039.233.221C1525.7,1131.6,1525.745,1131.893,1525.776,1132.186Z"
        transform="translate(-23.602)"
        fill="#09fdf8"
      />
      <path
        id="Path_31606"
        data-name="Path 31606"
        d="M1495.9,1178.591a7.6,7.6,0,0,0-3.306.818,7.21,7.21,0,0,0-3.869,7.152,7.091,7.091,0,0,0,1.34,3.45,1.47,1.47,0,0,1,.082.142c-.1.058-.14-.045-.189-.09a7.04,7.04,0,0,1-2.4-5.933,7.232,7.232,0,0,1,6.054-6.531,8.183,8.183,0,0,1,2.118-.046c.142.015.192.059.185.2C1495.9,1178.034,1495.9,1178.313,1495.9,1178.591Z"
        transform="translate(0 -37.523)"
        fill="#0afdf7"
      />
      <path
        id="Path_31607"
        data-name="Path 31607"
        d="M1508.9,1192.188a1.772,1.772,0,0,1-.734-.586,3.27,3.27,0,0,1-.644-3.495,3.347,3.347,0,0,1,2.831-2.195,3.18,3.18,0,0,1,1.323.1c.163.046.195,0,.194-.158,0-.868,0-1.736,0-2.6a5.653,5.653,0,0,1,1,.046c.094.009.142.035.142.142,0,1.234,0,2.469,0,3.722a4.122,4.122,0,0,0-1.425-.147,3.436,3.436,0,0,0-2.781,4.964C1508.839,1192.042,1508.9,1192.1,1508.9,1192.188Z"
        transform="translate(-15.95 -42.156)"
        fill="#ee0245"
      />
      <path
        id="Path_31608"
        data-name="Path 31608"
        d="M1566.1,1140.2a2.174,2.174,0,0,1-.707-.64,5.5,5.5,0,0,1-1.322-2.7c.294,0,.589-.008.883-.016.088,0,.13.025.132.121a5.893,5.893,0,0,0,1.015,3.185C1566.11,1140.161,1566.1,1140.181,1566.1,1140.2Z"
        transform="translate(-61.869 -4.655)"
        fill="#ec0446"
      />
      <path
        id="Path_31609"
        data-name="Path 31609"
        d="M1579.76,1158.141a5.558,5.558,0,0,1-2.863-1.451.477.477,0,1,1-.02-.311,5.839,5.839,0,0,0,2.677.748c.18.006.231.053.22.229C1579.756,1157.616,1579.763,1157.879,1579.76,1158.141Z"
        transform="translate(-71.805 -20.212)"
        fill="#0cfbf5"
      />
      <path
        id="Path_31610"
        data-name="Path 31610"
        d="M1574.676,1154.369c-.035,0-.065-.006-.047-.053C1574.672,1154.309,1574.678,1154.335,1574.676,1154.369Z"
        transform="translate(-70.397 -18.776)"
        fill="#ec0446"
      />
      <path
        id="Path_31611"
        data-name="Path 31611"
        d="M1574.924,1154.631c-.029-.006-.051-.018-.044-.053C1574.914,1154.58,1574.927,1154.6,1574.924,1154.631Z"
        transform="translate(-70.603 -18.989)"
        fill="#ec0446"
      />
      <path
        id="Path_31612"
        data-name="Path 31612"
        d="M1576.138,1155.875c-.041.006-.05-.024-.061-.053C1576.111,1155.824,1576.137,1155.835,1576.138,1155.875Z"
        transform="translate(-71.572 -19.995)"
        fill="#0cfbf5"
      />
      <path
        id="Path_31613"
        data-name="Path 31613"
        d="M1515.782,1229.978c-.028,0-.052-.011-.041-.048C1515.775,1229.929,1515.786,1229.947,1515.782,1229.978Z"
        transform="translate(-22.797 -79.901)"
        fill="#ee0245"
      />
      <path
        id="Path_31614"
        data-name="Path 31614"
        d="M1516.54,1230.735c-.04.007-.045-.021-.051-.049C1516.52,1230.688,1516.55,1230.692,1516.54,1230.735Z"
        transform="translate(-23.403 -80.512)"
        fill="#09fdf8"
      />
      <path
        id="Path_31615"
        data-name="Path 31615"
        d="M1501.115,1243.553c.03-.009.043.009.052.033l-.053.006Z"
        transform="translate(-10.975 -90.911)"
        fill="#0afdf7"
      />
      <path id="Path_31616" data-name="Path 31616" d="M1501.4,1243.8l.033.04-.047-.009Z" transform="translate(-11.191 -91.11)" fill="#0afdf7" />
      <path
        id="Path_31617"
        data-name="Path 31617"
        d="M1501.66,1244.086a.025.025,0,0,1,.02.033c-.007,0-.015.006-.02,0a.022.022,0,0,1-.01-.018C1501.65,1244.1,1501.656,1244.092,1501.66,1244.086Z"
        transform="translate(-11.408 -91.344)"
        fill="#0afdf7"
      />
      <path
        id="Path_31618"
        data-name="Path 31618"
        d="M1502.965,1245.346c.034.006.064.017.063.06C1502.982,1245.412,1502.97,1245.383,1502.965,1245.346Z"
        transform="translate(-12.471 -92.362)"
        fill="#ee0245"
      />
    </g>
  </svg>
</template>
