<template>
  <svg
    id="Group_1315"
    data-name="Group 1315"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="15.549"
    height="17.952"
    viewBox="0 0 15.549 17.952"
  >
    <g id="Group_641" data-name="Group 641">
      <path
        id="Path_316"
        data-name="Path 316"
        d="M5.341,1.936c0-.2-.006-.346,0-.494A1.479,1.479,0,0,1,6.82.006C7.43,0,8.04,0,8.65,0A1.478,1.478,0,0,1,10.23,1.578c0,.1.01.2.017.341.133.006.255.016.377.016,1.105,0,2.21,0,3.315,0a1.48,1.48,0,0,1,1.61,1.611,2.911,2.911,0,0,1-.073.784,1.3,1.3,0,0,1-1.053.983c-.305.055-.376.182-.4.466q-.383,5.182-.791,10.363a1.769,1.769,0,0,1-1.961,1.808q-3.505,0-7.01,0a1.765,1.765,0,0,1-1.921-1.773q-.4-5.233-.809-10.466c-.008-.1-.021-.2-.027-.262A5.375,5.375,0,0,1,.656,5.1,1.853,1.853,0,0,1,.1,2.973,1.443,1.443,0,0,1,1.6,1.937q1.658,0,3.315,0h.427M2.56,5.393c0,.145-.007.248,0,.351q.393,5.181.791,10.361c.047.6.3.827.917.827q3.487,0,6.974,0c.717,0,.933-.21.989-.935q.337-4.406.678-8.811C12.955,6.6,13,6,13.04,5.393ZM7.773,2.957c-2.036,0-4.072-.006-6.108.011a.74.74,0,0,0-.55.24.717.717,0,0,0,.648,1.148H13.773a1.155,1.155,0,0,0,.343-.011c.124-.04.318-.117.33-.2a2.569,2.569,0,0,0,.019-.938c-.064-.273-.372-.25-.618-.249q-3.037,0-6.074,0m1.4-1.034c.109-.74-.027-.9-.735-.9-.459,0-.919.026-1.376-.007-.6-.043-.826.283-.649.907Z"
        transform="translate(0 0)"
      />
      <path
        id="Path_317"
        data-name="Path 317"
        d="M69.137,109.456c0-1.1,0-2.209,0-3.313a.543.543,0,0,1,.315-.562.488.488,0,0,1,.675.338,1.43,1.43,0,0,1,.028.342q0,3.21,0,6.419c0,.069,0,.139,0,.207a.507.507,0,0,1-.514.5.494.494,0,0,1-.5-.512c-.012-.552,0-1.1,0-1.657q0-.88,0-1.76Z"
        transform="translate(-64.357 -98.246)"
      />
      <path
        id="Path_318"
        data-name="Path 318"
        d="M105.137,109.361c0-1.1,0-2.209,0-3.313a.543.543,0,0,1,.315-.562.488.488,0,0,1,.676.338,1.432,1.432,0,0,1,.027.342q0,3.21,0,6.419c0,.069,0,.139,0,.207a.507.507,0,0,1-.514.5.494.494,0,0,1-.5-.512c-.012-.552,0-1.1,0-1.657q0-.88,0-1.76Z"
        transform="translate(-97.87 -98.158)"
      />
      <path
        id="Path_319"
        data-name="Path 319"
        d="M142.535,109.532q0,1.587,0,3.175a1.608,1.608,0,0,1-.016.344.5.5,0,0,1-.971.022,1.311,1.311,0,0,1-.025-.342q0-3.226,0-6.453c0-.046,0-.092,0-.138a.512.512,0,0,1,.514-.534.5.5,0,0,1,.5.546c.01.391,0,.782,0,1.173q0,1.1,0,2.208"
        transform="translate(-131.742 -98.309)"
      />
    </g>
  </svg>
</template>
