<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="36.292" height="36.292" viewBox="0 0 36.292 36.292">
    <g id="Group_45598" data-name="Group 45598" transform="translate(5182 8069.8)">
      <path
        id="Path_29158"
        data-name="Path 29158"
        d="M36.292,26.117A18.146,18.146,0,1,1,18.146,7.971,18.146,18.146,0,0,1,36.292,26.117"
        transform="translate(-5182 -8077.771)"
      />
      <path
        id="Path_29159"
        data-name="Path 29159"
        d="M23.1,68.12h-.722a.557.557,0,0,0-.557.557v7.1a.557.557,0,0,0,.557.557H23.1a.557.557,0,0,0,.557-.557v-7.1a.557.557,0,0,0-.557-.557"
        transform="translate(-5198.73 -8123.879)"
        fill="#fff"
      />
      <path
        id="Path_29160"
        data-name="Path 29160"
        d="M40.231,73.916a.556.556,0,0,0-.244-.355l-.561-.361a.557.557,0,0,0-.763.158,1.648,1.648,0,0,1-.691.606,2.207,2.207,0,0,1-.932.2,2.38,2.38,0,0,1-1-.2,2.208,2.208,0,0,1-.744-.54,2.47,2.47,0,0,1-.478-.8,2.85,2.85,0,0,1-.172-.987,2.928,2.928,0,0,1,.168-.991,2.406,2.406,0,0,1,.472-.8,2.214,2.214,0,0,1,.744-.54,2.412,2.412,0,0,1,1.006-.2,1.881,1.881,0,0,1,.49.061,2.214,2.214,0,0,1,.436.167,1.469,1.469,0,0,1,.316.217,1.27,1.27,0,0,1,.205.233.557.557,0,0,0,.8.146l.6-.441a.557.557,0,0,0,.129-.766,2.638,2.638,0,0,0-.571-.593,3.587,3.587,0,0,0-.713-.421,4.2,4.2,0,0,0-1.7-.359,4.373,4.373,0,0,0-1.682.323A4.078,4.078,0,0,0,33.1,69.928a4.453,4.453,0,0,0-.323,1.705,4.519,4.519,0,0,0,.316,1.7A4.021,4.021,0,0,0,35.334,75.6a4.433,4.433,0,0,0,1.7.322,4.057,4.057,0,0,0,1.029-.125,3.872,3.872,0,0,0,.867-.34,3.413,3.413,0,0,0,.707-.508,3.528,3.528,0,0,0,.517-.608.557.557,0,0,0,.082-.423"
        transform="translate(-5207.124 -8123.288)"
        fill="#fff"
      />
      <path
        id="Path_29161"
        data-name="Path 29161"
        d="M72.783,69.927a4.08,4.08,0,0,0-2.251-2.257,4.538,4.538,0,0,0-3.364,0,4.077,4.077,0,0,0-2.251,2.257,4.669,4.669,0,0,0,0,3.411A4.077,4.077,0,0,0,67.168,75.6a4.539,4.539,0,0,0,3.364,0,4.081,4.081,0,0,0,2.251-2.257,4.671,4.671,0,0,0,0-3.411M68.85,74.163a2.378,2.378,0,0,1-1-.2,2.246,2.246,0,0,1-.747-.538,2.348,2.348,0,0,1-.47-.795,3.023,3.023,0,0,1,0-1.987,2.413,2.413,0,0,1,.472-.8,2.209,2.209,0,0,1,.744-.541,2.582,2.582,0,0,1,1.992,0,2.214,2.214,0,0,1,.744.541,2.412,2.412,0,0,1,.472.8,3.024,3.024,0,0,1,0,1.987,2.349,2.349,0,0,1-.47.794,2.274,2.274,0,0,1-.746.539,2.379,2.379,0,0,1-1,.2"
        transform="translate(-5231.516 -8123.287)"
        fill="#fff"
      />
      <path
        id="Path_29162"
        data-name="Path 29162"
        d="M110.473,68.12h-.722a.557.557,0,0,0-.557.557v4.272l-3.133-4.586a.557.557,0,0,0-.46-.243h-.9a.557.557,0,0,0-.557.557v7.1a.557.557,0,0,0,.557.557h.722a.557.557,0,0,0,.557-.557V71.457l3.132,4.629a.557.557,0,0,0,.461.245h.9a.557.557,0,0,0,.557-.557v-7.1a.557.557,0,0,0-.557-.557"
        transform="translate(-5261.832 -8123.879)"
        fill="#fff"
      />
    </g>
  </svg>
</template>
