import { watch, ref, Ref, onMounted, shallowRef, toRaw } from 'vue';
import * as echarts from 'echarts';
import type { EChartsOption } from 'echarts';
import { calculateWidth, uid } from '../../chart/utils';
import GeoJsTh from './geoTh.json';

interface Props {
  options: EChartsOption;
  height?: number | string;
  width?: number | string;
  rawData?: Charts.ChartsProps.RawData;
  colorList?: Charts.ChartsProps.ColorItem[];
  responsiveWidth?: boolean;
  responsiveHeight?: boolean;
  eventType?: string;
  function?: Function;
}

export default function (props: Props) {
  const chart = ref<HTMLDivElement | null>(null);
  const chartSize = shallowRef();
  const id = ref();
  id.value = uid();

  const init = () => {
    const dom = document.getElementById(String(id.value)) as HTMLElement;
    try {
      chartSize.value = echarts.init(dom);
      let initResize = false;

      if (chart.value) {
        const myChart = echarts.init(chart.value);
        chartSize.value = echarts.init(chart.value);
        myChart.showLoading();
        try {
          myChart.hideLoading();
          echarts.registerMap('Thailand', GeoJsTh as any, {});
          myChart.setOption(props.options);
        } catch (error) {
          console.error('Error loading Thailand geoJSON:', error);
          myChart.hideLoading();
        }
      }

      setTimeout(() => {
        initResize = true;
        chartSize.value.resize();
      }, 1000);

      new ResizeObserver(() => {
        if (initResize) chartSize.value.resize();
      }).observe(dom);

      if (props.eventType) {
        chartSize.value.on(props.eventType, props.function);
      }
    } catch (e) {
      //  TODO: this exception will happen when using with vue <transition>
    }
  };

  onMounted(() => {
    init();
  });

  watch(
    () => props.options,
    () => {
      if (chart.value) {
        const myChart = echarts.init(chart.value);
        myChart.setOption(props.options);
      }
    },
    {
      deep: true,
      immediate: true,
    },
  );

  watch([() => props.height, () => props.width], () => {
    setTimeout(() => {
      chartSize.value.resize();
    }, 100);
  });

  return { id, chart, calculateWidth };
}
