<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="56" viewBox="0 0 14 56">
    <g id="Group_37017" data-name="Group 37017" transform="translate(-346 -537)">
      <g id="Group_35336" data-name="Group 35336" transform="translate(5 6)">
        <g id="Group_35334" data-name="Group 35334" transform="translate(-4)">
          <g id="Group_35332" data-name="Group 35332" transform="translate(345 531)">
            <circle id="Ellipse_525" data-name="Ellipse 525" cx="2.5" cy="2.5" r="2.5" />
            <circle id="Ellipse_526" data-name="Ellipse 526" cx="2.5" cy="2.5" r="2.5" transform="translate(0 10)" />
            <circle id="Ellipse_527" data-name="Ellipse 527" cx="2.5" cy="2.5" r="2.5" transform="translate(0 20)" />
          </g>
        </g>
        <g id="Group_35335" data-name="Group 35335" transform="translate(-4 31)">
          <g id="Group_35332-2" data-name="Group 35332" transform="translate(345 531)">
            <circle id="Ellipse_525-2" data-name="Ellipse 525" cx="2.5" cy="2.5" r="2.5" />
            <circle id="Ellipse_526-2" data-name="Ellipse 526" cx="2.5" cy="2.5" r="2.5" transform="translate(0 10)" />
            <circle id="Ellipse_527-2" data-name="Ellipse 527" cx="2.5" cy="2.5" r="2.5" transform="translate(0 20)" />
          </g>
        </g>
      </g>
      <g id="Group_37016" data-name="Group 37016" transform="translate(14 6)">
        <g id="Group_35334-2" data-name="Group 35334" transform="translate(-4)">
          <g id="Group_35332-3" data-name="Group 35332" transform="translate(345 531)">
            <circle id="Ellipse_525-3" data-name="Ellipse 525" cx="2.5" cy="2.5" r="2.5" />
            <circle id="Ellipse_526-3" data-name="Ellipse 526" cx="2.5" cy="2.5" r="2.5" transform="translate(0 10)" />
            <circle id="Ellipse_527-3" data-name="Ellipse 527" cx="2.5" cy="2.5" r="2.5" transform="translate(0 20)" />
          </g>
        </g>
        <g id="Group_35335-2" data-name="Group 35335" transform="translate(-4 31)">
          <g id="Group_35332-4" data-name="Group 35332" transform="translate(345 531)">
            <circle id="Ellipse_525-4" data-name="Ellipse 525" cx="2.5" cy="2.5" r="2.5" />
            <circle id="Ellipse_526-4" data-name="Ellipse 526" cx="2.5" cy="2.5" r="2.5" transform="translate(0 10)" />
            <circle id="Ellipse_527-4" data-name="Ellipse 527" cx="2.5" cy="2.5" r="2.5" transform="translate(0 20)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
