<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13.486" height="14.471" viewBox="0 0 13.486 14.471">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_17398" data-name="Rectangle 17398" width="13.486" height="14.471" fill="#007fff" />
      </clipPath>
    </defs>
    <g id="Group_45999" data-name="Group 45999" clip-path="url(#clip-path)">
      <path
        id="Path_29253"
        data-name="Path 29253"
        d="M2.485,1.218h9.55V0H2.023A2.023,2.023,0,0,0,0,2.023V12.448a2.023,2.023,0,0,0,2.023,2.023h9.241a.771.771,0,0,0,.771-.771V4.425a.771.771,0,0,0-.771-.771H2.433A1.219,1.219,0,0,1,1.22,2.317a1.254,1.254,0,0,1,1.265-1.1M1.827,6.123h8.381V7.974H1.827Zm0,3.736h8.381V11.71H1.827Z"
        transform="translate(0 0)"
        fill="#007fff"
      />
      <path
        id="Path_29254"
        data-name="Path 29254"
        d="M49.783,14.814h-.335v2.374h.335a.636.636,0,0,0,.636-.636v-1.1a.636.636,0,0,0-.636-.636"
        transform="translate(-36.933 -11.065)"
        fill="#007fff"
      />
      <path
        id="Path_29255"
        data-name="Path 29255"
        d="M49.783,47.8h-.335v2.374h.335a.636.636,0,0,0,.636-.636v-1.1a.636.636,0,0,0-.636-.636"
        transform="translate(-36.933 -35.7)"
        fill="#007fff"
      />
      <path
        id="Path_29256"
        data-name="Path 29256"
        d="M49.783,36.8h-.335v2.374h.335a.636.636,0,0,0,.636-.636v-1.1a.636.636,0,0,0-.636-.636"
        transform="translate(-36.933 -27.489)"
        fill="#007fff"
      />
      <path
        id="Path_29257"
        data-name="Path 29257"
        d="M49.783,25.809h-.335v2.374h.335a.636.636,0,0,0,.636-.636v-1.1a.636.636,0,0,0-.636-.636"
        transform="translate(-36.933 -19.277)"
        fill="#007fff"
      />
    </g>
  </svg>
</template>
