<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g>
      <path
        class="st8"
        d="M27.88,92.16v-0.07h54.31c6.32,0,11.44-5.12,11.44-11.44V64.62h32.01V44.15H93.63V26.41
		c0-6.32-5.12-11.44-11.44-11.44H27.88c-6.32,0-11.44,5.12-11.44,11.44v54.31C16.44,87.04,21.56,92.16,27.88,92.16z M36.91,35.44
		h36.25v36.25H36.91V35.44z"
      />
      <path
        class="st8"
        d="M256.34,191.31C256.34,191.31,256.34,191.31,256.34,191.31L256.34,191.31h-54.31
		c-6.32,0-11.44,5.12-11.44,11.44c0,0,0,0,0,0v17.65h-29.81v20.47h29.81v16.17c0,6.32,5.12,11.44,11.44,11.44c0,0,0,0,0,0h54.31
		c6.32,0,11.44-5.12,11.44-11.44c0,0,0,0,0,0v-54.29C267.78,196.44,262.66,191.31,256.34,191.31z M247.31,248.03h-36.21V211.8h36.23
		L247.31,248.03z"
      />
      <path
        class="st8"
        d="M213.81,153.29c30.15,0.48,54.99-23.57,55.47-53.72c0.48-30.15-23.57-54.99-53.72-55.47
		c-0.58-0.01-1.17-0.01-1.75,0v0.06h-17.89v20.47h17.89c18.85,0,34.12,15.28,34.12,34.12s-15.28,34.12-34.12,34.12h-22.07
		L155.37,96.5c-4.47-4.47-11.71-4.47-16.18,0l-35.2,35.2H67.9c-30.15,0.48-54.2,25.32-53.72,55.47
		c0.47,29.47,24.25,53.25,53.72,53.72h22.52v-20.47H67.9c-18.85,0-34.12-15.28-34.12-34.12s15.28-34.12,34.12-34.12h33.98
		l37.31,37.31c4.47,4.47,11.71,4.47,16.18,0l0,0l36.14-36.19H213.81z M147.28,168.61l-25.62-25.62l25.62-25.62l25.62,25.62
		L147.28,168.61z"
      />
      <rect x="144.46" y="44.15" class="st8" width="32.63" height="20.47" />
      <rect x="109.32" y="220.42" class="st8" width="32.63" height="20.47" />
    </g>
  </svg>
</template>
