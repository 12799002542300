<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 78.73 74.97">
    <defs></defs>
    <path
      id="Union_137"
      class="cls-1"
      d="M29.75,49.98c-.17-13.62,10.73-24.8,24.35-24.98,13.62,.18,24.52,11.36,24.36,24.98,.01,4.92-1.41,9.74-4.1,13.86l4.22,7.85c.32,.57,.12,1.29-.45,1.61-.23,.13-.5,.18-.77,.14l-10.88-1.96c-3.73,2.28-8.01,3.49-12.38,3.49-13.62-.18-24.52-11.37-24.35-24.99Zm13.8-5.72v14.41c0,.67,.53,1.23,1.21,1.23h2.04c.67,0,1.21-.56,1.2-1.23h0v-14.41c0-.67-.53-1.22-1.2-1.23h-2.04c-.67,0-1.22,.56-1.21,1.23h0s0,0,0,0Zm10.78-5.23c-.88,4.62-2.62,4.82-3.75,5.48-.54,.32-.86,.91-.86,1.53v10.14c-.01,.97,.76,1.76,1.73,1.78,0,0,0,0,0,0h9.05c1.51-.01,2.8-1.1,3.07-2.59l1.28-6.64c.36-1.72-.74-3.41-2.46-3.77-.2-.04-.4-.06-.61-.07h-3.92c2.15-4.45-.35-6.11-2.04-6.72-.12-.04-.25-.07-.38-.07-.54,0-1.01,.39-1.11,.92h0Zm-42.08,7.46l-10.88,1.96c-.64,.1-1.25-.34-1.35-.98-.04-.26,0-.53,.14-.77l4.22-7.85C1.69,34.73,.26,29.91,.27,24.99,.11,11.37,11.01,.18,24.63,0c11.84,.12,21.9,8.68,23.92,20.35-1.67,.34-3.32,.82-4.91,1.45-1.4-9.45-9.46-16.49-19.02-16.6-10.79,.15-19.42,9-19.29,19.79,0,3.9,1.12,7.71,3.24,10.98l1.71,2.63-1.77,3.29,4.69-.85,1.61,.98c2.95,1.8,6.34,2.76,9.8,2.77,.18,0,.35-.02,.53-.03-.31,1.72-.47,3.47-.48,5.22h-.06c-4.37,0-8.65-1.21-12.38-3.49h0Zm12.73-8.78c-4.99-2.17-8.95-6.19-11.04-11.22h.03c-.86-1.61-.99-3.5-.37-5.22,.72-2.09,2.46-3.66,4.61-4.17,2.62-.57,5.31,.59,6.68,2.89,.04,.06,.12,.09,.18,.05,.02-.01,.04-.03,.05-.05,1.38-2.3,4.07-3.46,6.68-2.89,2.15,.51,3.89,2.08,4.61,4.17,.62,1.71,.49,3.61-.37,5.22h.03c-2.09,5.03-6.04,9.05-11.04,11.22-.01,0-.02,0-.03,0h-.02Z"
    />
  </svg>
</template>
