<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="35.427" height="28" viewBox="0 0 35.427 28">
    <g id="Group_44796" data-name="Group 44796" transform="translate(-3932 -3073)">
      <path id="Path_28729" data-name="Path 28729" d="M4.5,0,9,5H0Z" transform="translate(3967.427 3090.5) rotate(180)" fill="#0f2a4e" />
      <g id="Group_44732" data-name="Group 44732">
        <g id="Group_44731" data-name="Group 44731" transform="translate(67.356)">
          <g id="Group_44729" data-name="Group 44729">
            <path
              id="Path_28725"
              data-name="Path 28725"
              d="M5107.956-141.545h-9.507"
              transform="translate(-1225.949 3222.545)"
              fill="none"
              stroke="#0f2a4e"
              stroke-linecap="round"
              stroke-width="2"
            />
            <path
              id="Path_28726"
              data-name="Path 28726"
              d="M5103.543-141.545h-5.094"
              transform="translate(-1225.95 3226.897)"
              fill="none"
              stroke="#0f2a4e"
              stroke-linecap="round"
              stroke-width="2"
            />
          </g>
          <g id="Group_44728" data-name="Group 44728">
            <path
              id="Path_28727"
              data-name="Path 28727"
              d="M5108.637-141.545h-10.187"
              transform="translate(-1225.949 3231.249)"
              fill="none"
              stroke="#0f2a4e"
              stroke-linecap="round"
              stroke-width="2"
            />
            <path
              id="Path_28728"
              data-name="Path 28728"
              d="M5103.543-141.545h-5.094"
              transform="translate(-1225.95 3235.601)"
              fill="none"
              stroke="#0f2a4e"
              stroke-linecap="round"
              stroke-width="2"
            />
          </g>
        </g>
        <text
          id="_1"
          data-name="1"
          transform="translate(3932 3086)"
          fill="#0080ff"
          font-size="12"
          font-family="SegoeUI-Bold, Segoe UI"
          font-weight="700"
        >
          <tspan x="0" y="0">1</tspan>
        </text>
        <text
          id="_2"
          data-name="2"
          transform="translate(3932 3095)"
          fill="#0080ff"
          font-size="12"
          font-family="SegoeUI-Bold, Segoe UI"
          font-weight="700"
        >
          <tspan x="0" y="0">2</tspan>
        </text>
      </g>
    </g>
  </svg>
</template>
