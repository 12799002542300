<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="127.374" height="109.158" viewBox="0 0 127.374 109.158">
    <g id="Group_53831" data-name="Group 53831" transform="translate(15548 17856)">
      <path
        id="Subtraction_292"
        data-name="Subtraction 292"
        d="M97,50H13A13,13,0,0,1,0,37V31.225H94.192L93.318,0,95.28-32H97a12.915,12.915,0,0,1,9.192,3.808A12.914,12.914,0,0,1,110-19V37A13,13,0,0,1,97,50Z"
        transform="translate(-15438 -17806) rotate(180)"
        fill="#3a83f6"
      />
      <g id="Group_53830" data-name="Group 53830">
        <path
          id="Path_297034"
          data-name="Path 297034"
          d="M8.184,19.428v1.479q0,23.9,0,47.8c0,3.392,1.52,4.919,4.9,4.919q19.879,0,39.758,0c3.2,0,5.255,2.8,4.147,5.593A4.144,4.144,0,0,1,52.947,81.8c-4.3.013-8.608,0-12.911,0q-13.552,0-27.1,0C5.256,81.8.006,76.561,0,68.893Q0,40.9,0,12.912C.006,5.286,5.314,0,12.962,0Q53.168,0,93.374,0c7.777,0,13,5.207,13,12.969q.009,11.822,0,23.645a4.077,4.077,0,0,1-2.621,4,3.589,3.589,0,0,1-4.308-.984A5.735,5.735,0,0,1,98.25,36.42c-.122-5.194-.054-10.394-.055-15.591V19.394c-1.87,1.086-3.552,2.061-5.231,3.04C82.044,28.8,71.106,35.142,60.213,41.556a12.824,12.824,0,0,1-14,.037Q27.944,30.849,9.607,20.216c-.4-.232-.807-.447-1.423-.787m.66-9.142c.378.224.631.376.887.525q20.351,11.876,40.692,23.77a4.92,4.92,0,0,0,5.494.015Q76.241,22.675,96.609,10.829c.312-.182.6-.4.922-.621a4.44,4.44,0,0,0-4.244-2.04q-40.083.026-80.165.012a8.876,8.876,0,0,0-1.15.022,3.814,3.814,0,0,0-3.128,2.083"
          transform="translate(-15538.437 -17845)"
        />
        <path
          id="Path_297035"
          data-name="Path 297035"
          d="M134.4,96.393a3.607,3.607,0,0,0-4.567,1.127,18.23,18.23,0,0,1-10.056,6.061,31.112,31.112,0,0,1-7.423.89,39.15,39.15,0,0,1-11.274-1.767A15.337,15.337,0,0,1,90.806,83.749,22.541,22.541,0,0,1,112.164,67.9a20.607,20.607,0,0,1,3.556.309A13.354,13.354,0,0,1,126.6,77.558c1.866,5.325,1.031,10.132-2.482,14.289a4.483,4.483,0,0,1-3.612,1.674,6.754,6.754,0,0,1-.816-.052c-.047-.006-.088-.013-.121-.02.005-.038.014-.083.026-.137.147-.631.3-1.259.458-1.888.262-1.056.533-2.149.761-3.247.871-4.189,1.708-8.4,2.482-12.332a3.932,3.932,0,0,0-.541-2.985,3.613,3.613,0,0,0-2.35-1.513,3.719,3.719,0,0,0-.689-.066,3.51,3.51,0,0,0-3.217,2.16,11.475,11.475,0,0,0-7.067-2.475,10.319,10.319,0,0,0-5.052,1.3c-6.717,3.777-10.244,9.771-10.2,17.333.039,6.548,4.717,11.3,11.124,11.3a13.638,13.638,0,0,0,3.113-.369,14.964,14.964,0,0,0,5.421-2.522,7.725,7.725,0,0,0,5.888,2.782q.338.015.672.016a11.577,11.577,0,0,0,9.42-4.451c5.584-6.914,6.563-14.917,2.833-23.143-3.646-8.041-11.03-12.653-20.258-12.653a30.07,30.07,0,0,0-4.283.316C98.2,62.307,90.638,67.828,85.653,77.285A23.118,23.118,0,0,0,84.359,96.2,21.759,21.759,0,0,0,97.128,109a41.29,41.29,0,0,0,14.772,2.752,42.747,42.747,0,0,0,7.8-.73,26.057,26.057,0,0,0,15.53-8.644,4.751,4.751,0,0,0,1.064-1.855,3.428,3.428,0,0,0-1.895-4.132m-29.123-2.778a3.347,3.347,0,0,1-2.507-.912c-.887-.894-1.354-2.5-1.352-4.662a12.149,12.149,0,0,1,6.192-9.274,3.8,3.8,0,0,1,1.915-.52,3.973,3.973,0,0,1,3.1,1.554,16.614,16.614,0,0,1,1.408,2.251,3.558,3.558,0,0,1,.243,2.24c-.285,2.751-1.6,5.015-4.394,7.57a6.652,6.652,0,0,1-4.529,1.752Z"
          transform="translate(-15557.052 -17858.596)"
          fill="#3a83f6"
        />
      </g>
    </g>
  </svg>
</template>
