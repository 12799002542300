<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 177.47 64.08">
    <defs></defs>
    <g id="Group_45698">
      <g id="Group_44675">
        <g id="Rectangle_17039">
          <rect class="cls-1" y="0" width="49.3" height="64.08" rx="5.31" ry="5.31" />
          <rect class="cls-2" x=".66" y=".66" width="47.97" height="62.76" rx="4.65" ry="4.65" />
        </g>
        <g id="Rectangle_17040">
          <rect class="cls-1" x="64.08" width="49.3" height="64.08" rx="5.31" ry="5.31" />
          <rect class="cls-2" x="64.75" y=".66" width="47.97" height="62.76" rx="4.65" ry="4.65" />
        </g>
        <g id="Rectangle_17041">
          <rect class="cls-1" x="128.17" y="0" width="49.3" height="64.08" rx="5.31" ry="5.31" />
          <rect class="cls-2" x="128.84" y=".66" width="47.97" height="62.76" rx="4.65" ry="4.65" />
        </g>
      </g>
    </g>
  </svg>
</template>
