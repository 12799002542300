import { ref, Ref, reactive, onBeforeMount, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import router from '@/router';
import apiService from '@/services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import segmentModel from '@/models/segment/segment';
import useConvertDate from '@/utils/hooks/useConvertDate';

import Edit from '@/assets/icons/modules/segment/all-segment/Edit.vue';
import Copy from '@/assets/icons/modules/segment/all-segment/Copy.vue';
import Delete from '@/assets/icons/modules/segment/all-segment/Delete.vue';

import ability from '@/libs/acl/ability';

interface Props {
  selectedSource: Segment.Datasource;
  segmentConfig: Segment.Config;
}

export default function useAllSegmentTab(props: Props) {
  const { t } = useI18n();

  const { fetchSegmentListModel, deleteSegmentModel, fetchDisplayColumnModel, fetchCountRealtimeModel } = segmentModel();
  const { openDefaultErrorModal, openWarningModal, openSuccessModal } = useValidationModal();
  const { convertUTCToDeviceTimeZone } = useConvertDate();

  const fields: Ref<BaseTable.TableColumn[]> = ref([
    { key: 'name', label: t('segment.all_segment.table.segment_name') },
    { key: 'folder_name', label: t('segment.all_segment.table.folder'), sortable: true },
    { key: 'segment_size', label: t('segment.all_segment.table.segment_size'), sortable: true },
    { key: 'campaign_count', label: t('segment.all_segment.table.campaign'), sortable: true },
    { key: 'updated_by_name', label: t('segment.all_segment.table.update_by'), sortable: true },
    { key: 'updated_dt', label: t('segment.all_segment.table.fast_update'), sortable: true },
    { key: 'type', label: t('segment.all_segment.table.segment_type') },
    { key: 'action', label: '' },
  ]);

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });

  const isDialogSegmentOpen: Ref<boolean> = ref(false);

  const allSegmentList: Ref<Segment.Segment[]> = ref([]);
  const allSegmentListFilter: Ref<Segment.Segment[]> = ref([]);
  const segmentListPerPage: Ref<number[]> = ref([]);
  const isLoading: Ref<boolean> = ref(false);
  const showOverlay: Ref<boolean> = ref(false);
  const textSearch: Ref<string> = ref('');
  const segmentSelectForModal = reactive({
    segmentid: 0,
    root_tableid: 0,
    segment_size: 0,
    name: '',
    ref_segmentid: 0,
    folder_id: 0,
    result_table: '',
    result_type: 0,
    dest_ds_tableid: 0,
    result_ref_tableid: 0,
  });

  const dataSourceDisplaySave: Ref<AllDataSegment.DatasourceDisplay> = ref(null!);

  const selectSegmentRow: Ref<Segment.Segment> = ref(null!);

  const createSegmentModal = reactive({
    isOpen: false,
    isLoading: false,
    columnList: [] as DynamicTable.Column[],
  });

  const actionList: ActionList.Action[] = [];

  const fetchSegmentList = async (showLoading?: boolean) => {
    fetchSegmentListModel.payload.ref_type = 2;
    fetchSegmentListModel.payload.reference = props.selectedSource.tableid;
    if (showLoading) isLoading.value = true;

    await apiService
      .apiRequest(fetchSegmentListModel)
      .then((response) => {
        const segmentList = response.data.segment_list as Segment.Segment[];
        allSegmentList.value = segmentList;
        allSegmentListFilter.value = allSegmentList.value;
        const totalPages = Math.ceil(allSegmentList.value.length / pagination.perPage);

        if (pagination.currentPage > 1 && pagination.currentPage - totalPages == 1) {
          pagination.currentPage--;
        }
        pagination.totalRows = allSegmentList.value.length;
        if (router.currentRoute.value.query.page != undefined) {
          pagination.currentPage = Number(router.currentRoute.value.query.page);
          router.replace({
            path: router.currentRoute.value.fullPath,
            query: { ...router.currentRoute.value.query, page: undefined },
          });
        }
        const startIndex = pagination.perPage * (pagination.currentPage - 1);
        const endIndex = pagination.perPage * pagination.currentPage;

        segmentListPerPage.value = allSegmentListFilter.value?.slice(startIndex, endIndex).map((segment) => segment.segmentid) || [];
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => (isLoading.value = false));
  };

  const fetchCountRealtime = async () => {
    fetchCountRealtimeModel.payload.SegmentIdList = segmentListPerPage.value;
    await apiService
      .apiRequest(fetchCountRealtimeModel)
      .then(() => {})
      .catch((err) => openDefaultErrorModal(err));
  };

  const deleteSegment = (segment: Segment.Segment) => {
    deleteSegmentModel.payload.ref_type = 2;
    deleteSegmentModel.payload.referenceId = segment.segmentid;
    showOverlay.value = true;

    apiService
      .apiRequest(deleteSegmentModel)
      .then(() => openSuccessModal('ดำเนินการสำเร็จ', `ลบ ${segment.name} เรียบร้อย`, '', undefined, () => fetchSegmentList()))
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => (showOverlay.value = false));
  };

  const onCloseDialogSegment = () => {
    selectSegmentRow.value = null!;
    dataSourceDisplaySave.value = null!;
    segmentSelectForModal.segmentid = 0;
    segmentSelectForModal.root_tableid = 0;
    segmentSelectForModal.segment_size = 0;
    segmentSelectForModal.name = '';
    segmentSelectForModal.ref_segmentid = 0;
    segmentSelectForModal.folder_id = 0;
    segmentSelectForModal.result_table = '';
    segmentSelectForModal.result_type = 0;
    segmentSelectForModal.dest_ds_tableid = 0;
    segmentSelectForModal.result_ref_tableid = 0;
    isDialogSegmentOpen.value = false;
  };

  const fetchDisplayColumn = () => {
    fetchDisplayColumnModel.payload.TableId = props.selectedSource.tableid;
    createSegmentModal.isLoading = true;

    apiService
      .apiRequest(fetchDisplayColumnModel)
      .then((response) => {
        const columnList = response.data.payload.columns;
        createSegmentModal.columnList = columnList;
      })
      .catch((err) => {
        openDefaultErrorModal(
          err,
          () => (createSegmentModal.isOpen = false),
          () => (createSegmentModal.isOpen = false),
        );
      })
      .finally(() => (createSegmentModal.isLoading = false));
  };

  const resolveSegmentType = (type: Segment.SegmentType) => {
    switch (type) {
      case 1:
        return 'Batch';
      case 2:
        return 'Realtime';
      case 3:
        return 'Custom';
    }
  };

  const toSegmentDetail = (segment: Segment.Segment) => {
    return router.push({
      path: '/segment/data-segment/segment-detail',
      query: {
        ...router.currentRoute.value.query,
        segmentId: segment.segmentid,
        folderId: segment.folder_id,
        folderName: segment.folder_name,
        segmentName: segment.name,
        type: segment.type,
        page: pagination.currentPage,
      },
    });
  };

  const onCloneAction = (segment: Segment.Segment) => {
    fetchDisplayColumnModel.payload.TableId = segment.result_ref_tableid;
    createSegmentModal.isLoading = true;

    apiService
      .apiRequest(fetchDisplayColumnModel)
      .then((response) => {
        dataSourceDisplaySave.value = response.data.payload;
      })
      .catch((err) => {
        openDefaultErrorModal(
          err,
          () => (createSegmentModal.isOpen = false),
          () => (createSegmentModal.isOpen = false),
        );
      })
      .finally(() => {
        selectSegmentRow.value = segment;
        segmentSelectForModal.segmentid = segment.segmentid;
        isDialogSegmentOpen.value = true;
      });
  };

  const onSelectAction = (action: ActionList.Action, segment: Segment.Segment) => {
    switch (action.key) {
      case 'edit':
        return router.push({
          path: '/segment/data-segment/segment-detail',
          query: {
            ...router.currentRoute.value.query,
            segmentId: segment.segmentid,
            folderId: segment.folder_id,
            folderName: segment.folder_name,
            segmentName: segment.name,
            type: segment.type,
            page: pagination.currentPage,
          },
        });
      case 'clone':
        return onCloneAction(segment);
      case 'delete':
        return openWarningModal('ลบ Segment', `ต้องการลบ Segment ${segment.name} หรือไม่`, () => deleteSegment(segment));

      default:
        return;
    }
  };

  const onSubmitModal = () => {};

  const onOpenCreateSegmentModal = () => {
    createSegmentModal.isOpen = true;
    fetchDisplayColumn();
  };

  const onCloseCreateSegmentModal = () => {
    createSegmentModal.isOpen = false;
  };

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    const sortBy = sortedColumn.key;
    const sortDesc = sortedColumn.direction;

    allSegmentList.value.sort((a: any, b: any) => {
      if (sortDesc == 'ASC') {
        document.getElementById('sorting-' + sortedColumn.key)?.classList.add('asc');
        isLoading.value = true;
        setTimeout(() => (isLoading.value = false), 1000);
        return a[sortBy] <= b[sortBy] ? -1 : 1;
      } else if (sortDesc == 'DESC') {
        document.getElementById('sorting-' + sortedColumn.key)?.classList.add('desc');
        isLoading.value = true;
        setTimeout(() => (isLoading.value = false), 1000);
        return a[sortBy] >= b[sortBy] ? -1 : 1;
      } else {
        fetchSegmentList(true);
        return a[sortBy] == b[sortBy] ? -1 : 1;
      }
    });
    allSegmentListFilter.value = allSegmentList.value;
    return allSegmentListFilter.value;
  };

  const convertDateTable = (date: string) => {
    return convertUTCToDeviceTimeZone(date);
  };

  const onChangeSearch = () => {
    const filterList: any = [];
    if (textSearch.value == '') {
      allSegmentListFilter.value = allSegmentList.value;
      pagination.totalRows = allSegmentList.value.length;
    } else {
      if (allSegmentList.value.length > 0) {
        allSegmentList.value.map((column) => {
          if (
            column.name.toLowerCase().indexOf(textSearch.value.toLowerCase()) > -1 ||
            column.folder_name.toLowerCase().indexOf(textSearch.value.toLowerCase()) > -1 ||
            column.segment_size.toString().toLowerCase().indexOf(textSearch.value) > -1 ||
            column.campaign_count.toString().toLowerCase().indexOf(textSearch.value) > -1 ||
            column.updated_by_name.toLowerCase().indexOf(textSearch.value) > -1
          ) {
            filterList.push({
              name: column.name,
              folderid: column.folder_id,
              folder_name: column.folder_name,
              segmentid: column.segmentid,
              segment_size: column.segment_size,
              campaign_count: column.campaign_count,
              updated_by_name: column.updated_by_name,
              updated_dt: column.updated_dt,
              type: column.type,
            });
          }
        });
      }
      pagination.totalRows = filterList.length;
      allSegmentListFilter.value = filterList;
    }
  };

  function checkPermissionSegment() {
    if (ability.can('portal-cdp', 'cdp-portal-segment-duplicate')) {
      actionList.push({
        key: 'clone',
        tooltip: t('segment.all_segment.action.duplicate'),
        icon: Copy,
        tooltipColor: '#007FFF',
        tooltipBackgroundColor: '#BBDBFF',
      });
    }
    if (ability.can('portal-cdp', 'cdp-portal-segment-edit')) {
      actionList.push({
        key: 'edit',
        tooltip: t('segment.all_segment.action.edit'),
        icon: Edit,
        tooltipColor: '#007FFF',
        tooltipBackgroundColor: '#BBDBFF',
      });
    }
    if (ability.can('portal-cdp', 'cdp-portal-segment-delete')) {
      actionList.push({
        key: 'delete',
        tooltip: t('segment.all_segment.action.delete'),
        icon: Delete,
        tooltipColor: '#007FFF',
        tooltipBackgroundColor: '#BBDBFF',
      });
    }
  }

  watch(
    () => props.selectedSource,
    () => {
      pagination.currentPage = 1;
      setTimeout(() => {
        fetchSegmentList(true);
      }, 10);
    },
  );

  watch(
    () => pagination.currentPage,
    async () => {
      if (textSearch.value.length == 0) {
        await fetchSegmentList(true);
        await fetchCountRealtime();
        await fetchSegmentList(false);
      } else return onChangeSearch();
    },
  );

  watch(
    () => textSearch.value,
    () => {
      pagination.currentPage = 1;
      onChangeSearch();
    },
  );

  onBeforeMount(async () => {
    //check permission
    checkPermissionSegment();
    if (props.selectedSource) {
      pagination.currentPage = 1;
      await fetchSegmentList(true);
      await fetchCountRealtime();
      await fetchSegmentList(false);
    }
  });

  return {
    fields,
    allSegmentListFilter,
    pagination,
    actionList,
    isLoading,
    showOverlay,
    createSegmentModal,
    resolveSegmentType,
    onSubmitModal,
    onSelectAction,
    onOpenCreateSegmentModal,
    onCloseCreateSegmentModal,
    toSegmentDetail,
    onSort,
    convertDateTable,
    textSearch,
    isDialogSegmentOpen,
    onCloseDialogSegment,
    dataSourceDisplaySave,
    selectSegmentRow,
  };
}
