export interface ColorMode {
  seq: number;
  label: string;
}

export const COLOR_MODE: ColorMode[] = [
  {
    seq: 1,
    label: 'rgb',
  },
  {
    seq: 2,
    label: 'hex',
  },
  // {
  //   seq: 3,
  //   label: 'hsl',
  // },
];
