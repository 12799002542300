<template>
  <svg
    id="Group_31960"
    data-name="Group 31960"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="19.092"
    height="16.85"
    viewBox="0 0 19.092 16.85"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_14209" data-name="Rectangle 14209" width="19.092" height="16.85" fill="#005ab5" />
      </clipPath>
    </defs>
    <g id="Group_31960-2" data-name="Group 31960" clip-path="url(#clip-path)">
      <path
        id="Path_21308"
        data-name="Path 21308"
        d="M14.713,48.248a.35.35,0,1,0,0,.7h.531v2.974a.35.35,0,0,0,.35.35h.041a.35.35,0,0,0,.35-.35V48.948h.531a.35.35,0,0,0,0-.7Z"
        transform="translate(-12.268 -41.212)"
      />
      <path
        id="Path_21309"
        data-name="Path 21309"
        d="M108.29,33.769a.714.714,0,1,0-.714-.714.714.714,0,0,0,.714.714"
        transform="translate(-91.888 -27.625)"
      />
      <path
        id="Path_21310"
        data-name="Path 21310"
        d="M58.032,105.754a.714.714,0,1,0,.714.714.714.714,0,0,0-.714-.714"
        transform="translate(-48.959 -90.331)"
      />
      <path
        id="Path_21311"
        data-name="Path 21311"
        d="M18.275,9.933a.818.818,0,0,0,.818-.818V3.669a.818.818,0,0,0-.818-.818H15.438V1.309A1.308,1.308,0,0,0,14.13,0H4.345A1.308,1.308,0,0,0,3.037,1.309V4.676H.818A.818.818,0,0,0,0,5.494v6.847a.818.818,0,0,0,.818.818H3.037v2.275a1.308,1.308,0,0,0,1.308,1.308H6.5a.62.62,0,1,0,0-1.24H4.345a.068.068,0,0,1-.068-.068V13.159H6.265a.818.818,0,0,0,.818-.818V5.494a.818.818,0,0,0-.818-.818H4.277V1.309a.068.068,0,0,1,.068-.068H14.13a.068.068,0,0,1,.068.068V2.851h-2.77a.818.818,0,0,0-.818.818V9.115a.818.818,0,0,0,.818.818H14.2v.8h-3.04a.438.438,0,0,0-.438.438v2.418a.438.438,0,0,0,.438.438H14.2v1.41a.068.068,0,0,1-.068.068H11.806a.62.62,0,1,0,0,1.24H14.13a1.308,1.308,0,0,0,1.308-1.308v-1.41H18.6a.438.438,0,0,0,.438-.438V11.167a.438.438,0,0,0-.438-.438H15.438v-.8ZM5.645,5.917a.2.2,0,0,1,.2.2v5.607a.2.2,0,0,1-.2.2H1.438a.2.2,0,0,1-.2-.2V6.114a.2.2,0,0,1,.2-.2H5.645ZM11.85,4.288a.2.2,0,0,1,.2-.2h5.607a.2.2,0,0,1,.2.2V8.5a.2.2,0,0,1-.151.191l-4.13-2.807a1.086,1.086,0,0,0-1.339.092l-.383.346ZM17.8,11.97v.814H11.96V11.97H17.8ZM14.2,8.693h-2.15a.2.2,0,0,1-.2-.2V7.433l.678-.612a.7.7,0,0,1,.868-.06l2.8,1.932h-2Z"
        transform="translate(0 -0.001)"
      />
    </g>
  </svg>
</template>
