// console.log('EDM Block Plugin Loaded');

var EDMBlockTextType = function (Config) {
  /*
	Config = {
		Text:string
		Link:string(optional){link to external website}
	}
	*/
  let Build = decodeURI(Config.Text) || '';
  if (Config.Link != null) {
    Build = '<a target="_blank" href="' + Config.Link + '">' + Build + '</a>';
  }
  return Build;
};

var EDMBlockImageType = function (Config) {
  /*
	Config = {
		Width:number
		Url:sttring{image url}
		Alt:string(optional)
		Link:string(optional){link to external website}
	}
	*/
  let Build = '';
  let AlignFromParent = 'margin:auto;';
  if (Config.ParentStyle['text-align'] != null) {
    if (Config.ParentStyle['text-align'] == 'left') {
      AlignFromParent = 'margin:auto auto auto 0;';
    } else if (Config.ParentStyle['text-align'] == 'right') {
      AlignFromParent = 'margin:auto 0 auto auto;';
    }
  }
  let ElementObject = document.createElement('img');
  ElementObject.setAttribute('width', Config.Width || '0');
  ElementObject.setAttribute('alt', Config.Alt || '');
  ElementObject.setAttribute('src', Config.Url || '');
  ElementObject.setAttribute('style', Config.Style + AlignFromParent + 'vertical-align:top;');
  Build = ElementObject.outerHTML;
  if (Config.Link != null) {
    Build =
      '<a target="_blank" href="' +
      Config.Link +
      '" style="' +
      AlignFromParent +
      'display:block; font-size:0px; margin-block-start:0px; margin-block-end:0px; margin-inline-start:0px; margin-inline-end:0px;"' +
      ' value=' +
      JSON.stringify(Config.Value) +
      '>' +
      Build +
      '</a>';
  }
  return Build;
};

var EDMBlockVideoType = function (Config) {
  /*
	Config = {
		Width:number
		Url:sttring{thumbnail url}
		Alt:string(optional)
		Link:string(optional){link to external video site}

		VideoLink:string{raw video url}
		VideoType:string(optional){for video format, defalut is 'video/mp4'}
	}
	*/
  if (Config.ViewMode != 'legacy') {
    let Build = '';
    let AlignFromParent = 'margin:auto;';
    if (Config.ParentStyle['text-align'] != null) {
      if (Config.ParentStyle['text-align'] == 'left') {
        AlignFromParent = 'margin:auto auto auto 0;';
      } else if (Config.ParentStyle['text-align'] == 'right') {
        AlignFromParent = 'margin:auto 0 auto auto;';
      }
    }
    let ElementTable = document.createElement('table');
    ElementTable.setAttribute(
      'style',
      Config.Style + AlignFromParent + 'width:' + (Config.Width || '0') + 'px; border:0px; border-spacing:0px; border-collapse:collapse;',
    );
    let ElementTableBody = document.createElement('tbody');
    ElementTable.appendChild(ElementTableBody);
    let ElementTableRow = document.createElement('tr');
    ElementTableBody.appendChild(ElementTableRow);
    let ElementTableData = document.createElement('td');
    ElementTableRow.appendChild(ElementTableData);
    ElementTableData.setAttribute('style', 'overflow:hidden; padding:0px;');
    let ElementModernOverflow = document.createElement('div');
    ElementModernOverflow.setAttribute('style', 'mso-hide:all; height:0; overflow:visible;');
    ElementTableData.appendChild(ElementModernOverflow);
    let ElementModernRelative = document.createElement('div');
    ElementModernRelative.setAttribute('style', 'mso-hide:all; position:relative;');
    ElementModernOverflow.appendChild(ElementModernRelative);
    let ElementModernImage = document.createElement('img');
    ElementModernImage.setAttribute('width', Config.Width || '0');
    ElementModernImage.setAttribute('alt', Config.Alt || 'image');
    ElementModernImage.setAttribute('src', Config.Url || '');
    ElementModernImage.setAttribute('style', 'mso-hide:all; display:block; vertical-align:top;');
    let ElementModernImageBuild = ElementModernImage.outerHTML;
    if (Config.Link != null) {
      ElementModernImageBuild =
        '<a target="_blank" href="' +
        Config.Link +
        '" style="mso-hide:all; display:block; font-size:0px; margin-block-start:0px; margin-block-end:0px; margin-inline-start:0px; margin-inline-end:0px;">' +
        ElementModernImageBuild +
        '</a>';
    }
    let ElementModernVideoBuild =
      Config.VideoLink != null
        ? '<video controls poster="' +
          Config.Url +
          '" class="non-support-video" style="mso-hide:all; position:absolute; display:block; top:0; left:0; width:100%; height:100%;"><source src="' +
          Config.VideoLink +
          '" type="' +
          (Config.VideoType || 'video/mp4') +
          '"></video>'
        : '';
    let ElementLegacyImage = document.createElement('img');
    ElementLegacyImage.setAttribute('width', Config.Width || '0');
    ElementLegacyImage.setAttribute('alt', Config.Alt || 'image');
    ElementLegacyImage.setAttribute('src', Config.Url || '');
    ElementLegacyImage.setAttribute('style', 'display:block; vertical-align:top;');
    let ElementLegacyImageBuild = ElementLegacyImage.outerHTML;
    if (Config.Link != null) {
      ElementLegacyImageBuild =
        '<a target="_blank" href="' +
        Config.Link +
        '" style="display:block; font-size:0px; margin-block-start:0px; margin-block-end:0px; margin-inline-start:0px; margin-inline-end:0px;">' +
        ElementLegacyImageBuild +
        '</a>';
    }
    ElementModernRelative.insertAdjacentHTML('beforeend', ElementModernImageBuild);
    ElementModernRelative.insertAdjacentHTML('beforeend', ElementModernVideoBuild);
    ElementTableData.insertAdjacentHTML('beforeend', ElementLegacyImageBuild);
    Build = ElementTable.outerHTML;
    return Build;
  } else {
    return EDMBlockImageType(Config);
  }
};

var EDMBlockSocialType = function (Config) {
  /*
	Config = {
		SocialList:array
		[
			{
				Text:string(optional)
				Url:string{icon url}
				Alt:string(optional)
				Link:string(optional){link to external website}
			}
		]
		IconSize:number
		InnerPadding:number(optional){padding for each social icon}
		InnerPaddingTop:number(optional){padding for each social icon}
		InnerPaddingBottom:number(optional){padding for each social icon}
		InnerPaddingLeft:number(optional){padding for each social icon}
		InnerPaddingRight:number(optional){padding for each social icon}
		TextSize:number(optional)
		FixedWidthArea:number(optional){width fixed per social slot}
		LinkTextDecoration:string(optional){css for custom text docaration}
	}
	*/
  if (Config.SocialList != null && Config.SocialList.length > 0) {
    let Build = '';
    let AlignFromParent = 'margin:auto;';
    if (Config.ParentStyle['text-align'] != null) {
      if (Config.ParentStyle['text-align'] == 'left') {
        AlignFromParent = 'margin:auto auto auto 0;';
      } else if (Config.ParentStyle['text-align'] == 'right') {
        AlignFromParent = 'margin:auto 0 auto auto;';
      }
    }
    let ElementTable = document.createElement('table');
    ElementTable.setAttribute('style', Config.Style + AlignFromParent + 'border:0px; border-spacing:0px; border-collapse:collapse;');
    let ElementTableBody = document.createElement('tbody');
    ElementTable.appendChild(ElementTableBody);

    let TableDataStyle = 'vertical-align:middle;text-align:center;';
    if (Config.InnerPadding != null) {
      TableDataStyle += 'padding:' + Config.InnerPadding + 'px;';
    }
    if (Config.InnerPaddingTop != null) {
      TableDataStyle += 'padding-top:' + Config.InnerPaddingTop + 'px;';
    }
    if (Config.InnerPaddingBottom != null) {
      TableDataStyle += 'padding-bottom:' + Config.InnerPaddingBottom + 'px;';
    }
    if (Config.InnerPaddingLeft != null) {
      TableDataStyle += 'padding-left:' + Config.InnerPaddingLeft + 'px;';
    }
    if (Config.InnerPaddingRight != null) {
      TableDataStyle += 'padding-right:' + Config.InnerPaddingRight + 'px;';
    }
    if (Config.TextSize != null) {
      TableDataStyle += 'font-size:' + Config.TextSize + 'px;';
    }
    if (Config.FixedWidthArea != null) {
      TableDataStyle += 'width:' + Config.FixedWidthArea + 'px;';
    }

    let AStyle = '';
    if (Config.LinkTextDecoration != null && Config.ViewMode != 'legacy') {
      AStyle += 'text-decoration:' + Config.LinkTextDecoration + ';';
    }

    if (Config.TextDisplayType == 'Up') {
      let ElementTableRow = document.createElement('tr');
      ElementTableBody.appendChild(ElementTableRow);
      Config.SocialList.map((Social) => {
        let ElementTableData = document.createElement('td');
        ElementTableData.setAttribute('style', TableDataStyle);
        ElementTableRow.appendChild(ElementTableData);
        let ElementTextBuild = decodeURI(Social.Text) || '';
        if (Social.Link != null) {
          ElementTextBuild =
            '<a target="_blank" href="' +
            Social.Link +
            '" style="' +
            AStyle +
            '" value=' +
            JSON.stringify(Social.Value) +
            '>' +
            ElementTextBuild +
            '</a>';
        }
        ElementTableData.insertAdjacentHTML('beforeend', ElementTextBuild);
      });
      ElementTableRow = document.createElement('tr');
      ElementTableBody.appendChild(ElementTableRow);
      Config.SocialList.map((Social) => {
        let ElementTableData = document.createElement('td');
        ElementTableData.setAttribute('style', TableDataStyle);
        ElementTableRow.appendChild(ElementTableData);
        let ElementImage = document.createElement('img');
        ElementImage.setAttribute('width', Config.IconSize || '0');
        ElementImage.setAttribute('alt', Social.Alt || 'image');
        ElementImage.setAttribute('src', Social.Url || '');
        ElementImage.setAttribute('style', 'vertical-align:top;');
        let ElementImageBuild = ElementImage.outerHTML;
        if (Social.Link != null) {
          ElementImageBuild =
            '<a target="_blank" href="' +
            Social.Link +
            '" style="font-size:0px; margin-block-start:0px; margin-block-end:0px; margin-inline-start:0px; margin-inline-end:0px;"' +
            ' value=' +
            JSON.stringify(Social.Value) +
            '>' +
            ElementImageBuild +
            '</a>';
        }
        ElementTableData.insertAdjacentHTML('beforeend', ElementImageBuild);
      });
    } else if (Config.TextDisplayType == 'Down') {
      let ElementTableRow = document.createElement('tr');
      ElementTableBody.appendChild(ElementTableRow);
      Config.SocialList.map((Social) => {
        let ElementTableData = document.createElement('td');
        ElementTableData.setAttribute('style', TableDataStyle);
        ElementTableRow.appendChild(ElementTableData);
        let ElementImage = document.createElement('img');
        ElementImage.setAttribute('width', Config.IconSize || '0');
        ElementImage.setAttribute('alt', Social.Alt || 'image');
        ElementImage.setAttribute('src', Social.Url || '');
        ElementImage.setAttribute('style', 'vertical-align:top;');
        let ElementImageBuild = ElementImage.outerHTML;
        if (Social.Link != null) {
          ElementImageBuild =
            '<a target="_blank" href="' +
            Social.Link +
            '" style="font-size:0px; margin-block-start:0px; margin-block-end:0px; margin-inline-start:0px; margin-inline-end:0px;"' +
            ' value=' +
            JSON.stringify(Social.Value) +
            '>' +
            ElementImageBuild +
            '</a>';
        }
        ElementTableData.insertAdjacentHTML('beforeend', ElementImageBuild);
      });
      ElementTableRow = document.createElement('tr');
      ElementTableBody.appendChild(ElementTableRow);
      Config.SocialList.map((Social) => {
        let ElementTableData = document.createElement('td');
        ElementTableData.setAttribute('style', TableDataStyle);
        ElementTableRow.appendChild(ElementTableData);
        let ElementTextBuild = decodeURI(Social.Text) || '';
        if (Social.Link != null) {
          ElementTextBuild =
            '<a target="_blank" href="' +
            Social.Link +
            '" style="' +
            AStyle +
            '" value=' +
            JSON.stringify(Social.Value) +
            '>' +
            ElementTextBuild +
            '</a>';
        }
        ElementTableData.insertAdjacentHTML('beforeend', ElementTextBuild);
      });
    } else if (Config.TextDisplayType == 'Left') {
      let ElementTableRow = document.createElement('tr');
      ElementTableBody.appendChild(ElementTableRow);
      Config.SocialList.map((Social) => {
        let ElementTableData = document.createElement('td');
        ElementTableData.setAttribute('style', TableDataStyle);
        ElementTableRow.appendChild(ElementTableData);
        let ElementTextBuild = decodeURI(Social.Text) || '';
        if (Social.Link != null) {
          ElementTextBuild =
            '<a target="_blank" href="' +
            Social.Link +
            '" style="' +
            AStyle +
            '" value=' +
            JSON.stringify(Social.Value) +
            '>' +
            ElementTextBuild +
            '</a>';
        }
        ElementTableData.insertAdjacentHTML('beforeend', ElementTextBuild);
        ElementTableData = document.createElement('td');
        ElementTableData.setAttribute('style', TableDataStyle);
        ElementTableRow.appendChild(ElementTableData);
        let ElementImage = document.createElement('img');
        ElementImage.setAttribute('width', Config.IconSize || '0');
        ElementImage.setAttribute('alt', Social.Alt || 'image');
        ElementImage.setAttribute('src', Social.Url || '');
        ElementImage.setAttribute('style', 'vertical-align:top;');
        let ElementImageBuild = ElementImage.outerHTML;
        if (Social.Link != null) {
          ElementImageBuild =
            '<a target="_blank" href="' +
            Social.Link +
            '" style="font-size:0px; margin-block-start:0px; margin-block-end:0px; margin-inline-start:0px; margin-inline-end:0px;"' +
            ' value=' +
            JSON.stringify(Social.Value) +
            '>' +
            ElementImageBuild +
            '</a>';
        }
        ElementTableData.insertAdjacentHTML('beforeend', ElementImageBuild);
      });
    } else if (Config.TextDisplayType == 'Right') {
      let ElementTableRow = document.createElement('tr');
      ElementTableBody.appendChild(ElementTableRow);
      Config.SocialList.map((Social) => {
        let ElementTableData = document.createElement('td');
        ElementTableData.setAttribute('style', TableDataStyle);
        ElementTableRow.appendChild(ElementTableData);
        let ElementImage = document.createElement('img');
        ElementImage.setAttribute('width', Config.IconSize || '0');
        ElementImage.setAttribute('alt', Social.Alt || 'image');
        ElementImage.setAttribute('src', Social.Url || '');
        ElementImage.setAttribute('style', 'vertical-align:top;');
        let ElementImageBuild = ElementImage.outerHTML;
        if (Social.Link != null) {
          ElementImageBuild =
            '<a target="_blank" href="' +
            Social.Link +
            '" style="font-size:0px; margin-block-start:0px; margin-block-end:0px; margin-inline-start:0px; margin-inline-end:0px;"' +
            ' value=' +
            JSON.stringify(Social.Value) +
            '>' +
            ElementImageBuild +
            '</a>';
        }
        ElementTableData.insertAdjacentHTML('beforeend', ElementImageBuild);
        ElementTableData = document.createElement('td');
        ElementTableData.setAttribute('style', TableDataStyle);
        ElementTableRow.appendChild(ElementTableData);
        let ElementTextBuild = decodeURI(Social.Text) || '';
        if (Social.Link != null) {
          ElementTextBuild =
            '<a target="_blank" href="' +
            Social.Link +
            '" style="' +
            AStyle +
            '" value=' +
            JSON.stringify(Social.Value) +
            '>' +
            ElementTextBuild +
            '</a>';
        }
        ElementTableData.insertAdjacentHTML('beforeend', ElementTextBuild);
      });
    } else if (Config.TextDisplayType == 'TextOnly') {
      let ElementTableRow = document.createElement('tr');
      ElementTableBody.appendChild(ElementTableRow);
      Config.SocialList.map((Social) => {
        let ElementTableData = document.createElement('td');
        ElementTableData.setAttribute('style', TableDataStyle);
        ElementTableRow.appendChild(ElementTableData);
        let ElementTextBuild = decodeURI(Social.Text) || '';
        if (Social.Link != null) {
          ElementTextBuild =
            '<a target="_blank" href="' +
            Social.Link +
            '" style="' +
            AStyle +
            '" value=' +
            JSON.stringify(Social.Value) +
            '>' +
            ElementTextBuild +
            '</a>';
        }
        ElementTableData.insertAdjacentHTML('beforeend', ElementTextBuild);
      });
    } else {
      let ElementTableRow = document.createElement('tr');
      ElementTableBody.appendChild(ElementTableRow);
      Config.SocialList.map((Social) => {
        let ElementTableData = document.createElement('td');
        ElementTableData.setAttribute('style', TableDataStyle);
        ElementTableRow.appendChild(ElementTableData);
        let ElementImage = document.createElement('img');
        ElementImage.setAttribute('width', Config.IconSize || '0');
        ElementImage.setAttribute('alt', Social.Alt || 'image');
        ElementImage.setAttribute('src', Social.Url || '');
        ElementImage.setAttribute('style', 'vertical-align:top;');
        let ElementImageBuild = ElementImage.outerHTML;
        if (Social.Link != null) {
          ElementImageBuild =
            '<a target="_blank" href="' +
            Social.Link +
            '" style="font-size:0px; margin-block-start:0px; margin-block-end:0px; margin-inline-start:0px; margin-inline-end:0px;"' +
            ' value=' +
            JSON.stringify(Social.Value) +
            '>' +
            ElementImageBuild +
            '</a>';
        }
        ElementTableData.insertAdjacentHTML('beforeend', ElementImageBuild);
      });
    }
    Build = ElementTable.outerHTML;
    return Build;
  } else {
    return '';
  }
};

var EDMBlockButtonType = function (Config) {
  /*
	Config = {
		Text:string{optional}
		Width:number(optional)
		Height:number(optional)
		Link:string(optional){link to external site}
	}
	*/
  let Build = '';
  let AlignFromParent = 'margin:auto;';
  if (Config.ParentStyle['text-align'] != null) {
    if (Config.ParentStyle['text-align'] == 'left') {
      AlignFromParent = 'margin:auto auto auto 0;';
    } else if (Config.ParentStyle['text-align'] == 'right') {
      AlignFromParent = 'margin:auto 0 auto auto;';
    }
  }
  let TableStyle = '';
  if (Config.Width != null) {
    TableStyle += 'width:' + Config.Width + 'px;';
  }
  if (Config.Height != null) {
    TableStyle += 'height:' + Config.Height + 'px;';
  }
  let ElementTable = document.createElement('table');
  ElementTable.setAttribute('style', Config.Style + AlignFromParent + TableStyle + ' border:0px; border-spacing:0px; border-collapse:collapse;');
  let ElementTableBody = document.createElement('tbody');
  ElementTable.appendChild(ElementTableBody);
  let ElementTableRow = document.createElement('tr');
  ElementTableBody.appendChild(ElementTableRow);
  let ElementTableData = document.createElement('td');
  ElementTableData.setAttribute('style', 'text-align:center; vertical-align:middle;');
  ElementTableRow.appendChild(ElementTableData);
  ElementTableData.insertAdjacentHTML('beforeend', decodeURI(Config.Text) || '');
  Build = ElementTable.outerHTML;
  if (Config.Link != null) {
    Build =
      '<a target="_blank" href="' +
      Config.Link +
      '" style="' +
      AlignFromParent +
      'display:block; font-size:0px; margin-block-start:0px; margin-block-end:0px; margin-inline-start:0px; margin-inline-end:0px;">' +
      Build +
      '</a>';
  }
  return Build;
};

var EDMBlockScannerType = function (Config) {
  /*
	Config = {
		Width:number
		Url:string{image url}
		Alt:string(optional)
		Link:string(optional){link to external website}
	}
	*/
  let Build = '';
  let AlignFromParent = 'margin:auto;';
  if (Config.ParentStyle['text-align'] != null) {
    if (Config.ParentStyle['text-align'] == 'left') {
      AlignFromParent = 'margin:auto auto auto 0;';
    } else if (Config.ParentStyle['text-align'] == 'right') {
      AlignFromParent = 'margin:auto 0 auto auto;';
    }
  }
  let ElementObject = document.createElement('img');
  ElementObject.setAttribute('class', Config.ClassName || '');
  ElementObject.setAttribute('width', Config.Width || '0');
  ElementObject.setAttribute('alt', Config.Alt || 'image');
  ElementObject.setAttribute('src', Config.Url || '');
  ElementObject.setAttribute('style', Config.Style + AlignFromParent + 'vertical-align:top;');
  ElementObject.setAttribute('value', `${JSON.stringify(Config.ScanData)}`);
  Build = ElementObject.outerHTML;
  return Build;
};

var EDMBlockLayoutBlankType = function () {
  return '<div class="add-content-area"></div>';
};

var EDMBlockImageBlankType = function () {
  return '<div class="blank-image-area"></div>';
};

var EDMBlockVideoBlankType = function () {
  return '<div class="blank-video-area"></div>';
};

var EDMBlockSocialBlankType = function () {
  return '<div class="blank-social-area"></div>';
};

var EDMBlockScanObjBlankType = function () {
  return '<div class="blank-scan-object-area"></div>';
};

window.EDMBlockPlugin = function () {
  return {
    ImageBlank: EDMBlockImageBlankType,
    VideoBlank: EDMBlockVideoBlankType,
    SocialBlank: EDMBlockSocialBlankType,
    LayoutBlank: EDMBlockLayoutBlankType,
    ScannerBlank: EDMBlockScanObjBlankType,
    Text: EDMBlockTextType,
    Image: EDMBlockImageType,
    Video: EDMBlockVideoType,
    Social: EDMBlockSocialType,
    Scanner: EDMBlockScannerType,
  };
};
