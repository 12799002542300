<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20.573" height="25" viewBox="0 0 20.573 25">
    <g id="Group_45155" data-name="Group 45155" transform="translate(20.573) rotate(90)">
      <rect id="Rectangle_17333" data-name="Rectangle 17333" width="25" height="3" rx="1" transform="translate(0 8.787)" />
      <g id="Group_45154" data-name="Group 45154" transform="translate(6 0)">
        <rect id="Rectangle_17334" data-name="Rectangle 17334" width="5" height="20.573" rx="1" transform="translate(0 0)" />
        <rect id="Rectangle_17335" data-name="Rectangle 17335" width="5" height="15.573" rx="1" transform="translate(9 2.5)" />
      </g>
    </g>
  </svg>
</template>
