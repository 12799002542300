import { Ref, ref, nextTick, getCurrentInstance, onMounted } from 'vue';

interface Option {
  label: string;
  value: string | number;
}

interface Props {
  options: Option[];
  dropdownSelect: Array<string>;
  name: string;
}

export default function (props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const select: Ref<Array<string>> = ref(props.dropdownSelect);
  const isSelectAll: Ref<boolean> = ref(false);

  const isActive: Ref<boolean> = ref(false);

  const onChangeActive = (active: boolean) => {
    isActive.value = active;
  };

  const changeInputElement = (index: number, bool: boolean) => {
    const checkbox = document.getElementById(
      props.name + '-' + String(props.options[index].label) + '-' + String(props.options[index].value),
    ) as HTMLInputElement;
    checkbox.checked = bool;
  };

  const onChangeOption = (e: any) => {
    const valueCheckbox = e.target.value as string;
    const isSelected: number = select.value.indexOf(valueCheckbox);
    if (valueCheckbox == 'All') {
      select.value.length = 0;
      if (isSelected != -1) {
        isSelectAll.value = false;
        for (let index = 0; index < props.options.length; index++) {
          changeInputElement(index, false);
        }
      } else {
        isSelectAll.value = true;
        for (let index = 0; index < props.options.length; index++) {
          changeInputElement(index, true);
          nextTick(() => {
            select.value.push(String(props.options[index].value));
          });
        }
      }
    } else {
      if (isSelected != -1) {
        if (isSelectAll.value) {
          const all: number = select.value.indexOf('All');
          changeInputElement(0, false);
          select.value.splice(all, 1);
          nextTick(() => {
            isSelectAll.value = false;
            const isSelected: number = select.value.indexOf(valueCheckbox);
            select.value.splice(isSelected, 1);
          });
        } else {
          select.value.splice(isSelected, 1);
        }
      } else {
        select.value.push(valueCheckbox);
      }
    }
    nextTick(() => {
      vm?.$emit('on-select');
    });
  };

  onMounted(() => {
    const all: number = select.value.indexOf('All');
    if (all != -1) {
      select.value.length = 0;
      isSelectAll.value = true;
      for (let index = 0; index < props.options.length; index++) {
        changeInputElement(index, true);
        nextTick(() => {
          select.value.push(String(props.options[index].value));
        });
      }
    }
  });

  return { select, isSelectAll, isActive, onChangeActive, onChangeOption };
}
