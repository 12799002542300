export const charts_key = {
  TOTAL_STATUS: 'Comm_006_OverviewTotalStatus',
  DELIVERY_RANGE: 'Comm_007_HeatmapCommuCampaignDeliveryRange',
  OPEN_RANGE: 'Comm_002_HeatmapCommuOpenRange',
  DELIVERY: 'Comm_008_PieDelivery',
  DELIVERY_BY_CAMPAIGN: 'Comm_009_VerticalStackBarDeliveryByCampaign',
  EMAIL_NEW_SUBSCRIBE: 'Comm_003_LineEmailNewSubscribe',
  EMAIL_SUBSCRIBE: 'Comm_004_PieEmailSubscribe',
  EMAIL_CREDIT: 'Comm_005_VerticalOverviewEmailCredit',
};

export const charts_type = {
  OVERVIEW: 'overview',
  HEATMAP: 'heatmap-commu',
  PIE: 'pie',
  VERTICAL_STACK_BAR: 'vertical-stack-bar',
  LINE: 'line',
  VERTICAL_OVERVIEW: 'vertical-overview',
};
