<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8">
    <g id="Group_778" data-name="Group 778">
      <circle id="Ellipse_54" data-name="Ellipse 54" cx="1" cy="1" r="1" />
      <rect id="Rectangle_519" data-name="Rectangle 519" width="9" height="2" rx="1" transform="translate(3)" />
    </g>
    <g id="Group_779" data-name="Group 779" transform="translate(0 3)">
      <circle id="Ellipse_54-2" data-name="Ellipse 54" cx="1" cy="1" r="1" />
      <rect id="Rectangle_519-2" data-name="Rectangle 519" width="9" height="2" rx="1" transform="translate(3)" />
    </g>
    <g id="Group_780" data-name="Group 780" transform="translate(0 6)">
      <circle id="Ellipse_54-3" data-name="Ellipse 54" cx="1" cy="1" r="1" />
      <rect id="Rectangle_519-3" data-name="Rectangle 519" width="9" height="2" rx="1" transform="translate(3)" />
    </g>
  </svg>
</template>
