import { ref, Ref, toRaw, watch } from 'vue';

interface SegmentViewmode {
  selectedOption: {
    mode: number;
    title: string;
    type: number;
  };
}

export default function useFilterColumn(viewmode: SegmentViewmode) {
  const resultMapData: Ref<Record<string, any>> = ref([]);
  const resultMapOrdering: Ref<Record<string, any>> = ref([]);

  const onSelectedSorting = (sort: any) => {
    resultMapOrdering.value = sort.sorting;
  };

  const onSelectedFilter = (selected: any) => {
    const selectedItem = toRaw(selected.preview);
    const valueArr = resultMapData.value.find((item: any) => {
      return item.label == selectedItem.label;
    });

    if (valueArr == undefined) {
      resultMapData.value.push(selectedItem);
    } else {
      const arr = Object.values(resultMapData.value);
      const foundIndex = arr.findIndex((item: any) => {
        return item.label == selectedItem.label;
      });

      resultMapData.value[foundIndex] = selectedItem;
    }
  };

  const onRemoveItemFilter = (item: any) => {
    const itemRemove = toRaw(item);

    if (resultMapData.value.length === 1) {
      resultMapData.value.pop();
    } else {
      const data = resultMapData.value.filter((x: any) => {
        return x.label != itemRemove.label;
      });
      removeKey(data, itemRemove.label);
      resultMapData.value = data;
    }
  };

  const removeKey = (data: any, key: any) => {
    const index = data.findIndex((x: any) => x.Key == key);
    if (index > -1) {
      data.splice(index, 1);
      return true;
    } else {
      return data.some((x: any) => {
        if (x.filters) {
          return removeKey(x.filters, key);
        } else {
          return false;
        }
      });
    }
  };

  const onRemoveAllItemFilter = () => {
    if (resultMapData.value.length > 0) {
      resultMapData.value = [];
      resultMapOrdering.value = [];
    }
  };

  watch(
    () => viewmode,
    () => {
      if (resultMapOrdering.value.length > 0) {
        resultMapOrdering.value = [];
      }
      if (resultMapData.value.length > 0) {
        resultMapData.value = [];
      }
    },
    { deep: true },
  );

  return {
    resultMapData,
    resultMapOrdering,
    onSelectedFilter,
    onRemoveItemFilter,
    onRemoveAllItemFilter,
    onSelectedSorting,
  };
}
