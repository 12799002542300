<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.55 16.02">
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M14.76,11v2a1.54,1.54,0,0,1-1.56,1.53H3.09A1.54,1.54,0,0,1,1.53,13V3A1.54,1.54,0,0,1,3.09,1.51H13.2l.24,0L14.65.37A3.06,3.06,0,0,0,13.2,0H3.09A3.07,3.07,0,0,0,0,3V13a3.07,3.07,0,0,0,3.09,3H13.2a3.06,3.06,0,0,0,3.09-3V9.49Z"
        />
        <path
          class="cls-1"
          d="M10.26,7.35,17.34.53A1.91,1.91,0,0,1,20,.56a1.82,1.82,0,0,1,0,2.62L12.91,10l-3.09.37Zm1.25,1.39.74-.09L19,2.17a.44.44,0,0,0,.13-.31A.4.4,0,0,0,19,1.55a.43.43,0,0,0-.32-.14.48.48,0,0,0-.32.13L11.62,8Z"
        />
      </g>
    </g>
  </svg>
</template>
