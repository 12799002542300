<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21" height="23" viewBox="0 0 21 23">
    <defs>
      <clipPath id="a"><rect width="21" height="23"/></clipPath>
    </defs>
    <g clip-path="url(#a)">
      <path
        d="M20.724,5.129,15.924.581A2.12,2.12,0,0,0,14.466,0H1.257A1.258,1.258,0,0,0,0,1.258V21.41a1.258,1.258,0,0,0,1.257,1.257H20.184a1.257,1.257,0,0,0,1.258-1.257V6.8a2.3,2.3,0,0,0-.717-1.667M5.36,4.892a.612.612,0,0,1,.619-.6h9.189a.612.612,0,0,1,.619.6V9.2a.611.611,0,0,1-.619.6H5.98a.611.611,0,0,1-.619-.6ZM18.724,19.636a.581.581,0,0,1-.581.581H3.3a.58.58,0,0,1-.58-.581v-5.98a.581.581,0,0,1,.58-.581H18.143a.581.581,0,0,1,.581.581Z"
      />
      <rect width="1.838" height="4.288" transform="translate(12.725 4.901)"/>
    </g>
  </svg>
</template>
