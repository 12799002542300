import { Directive } from 'vue';

import sortable from 'sortablejs';

export const sortableRow: Directive = {
  beforeMount(el, binding) {
    const table = el;
    table._sortable = sortable.create(<HTMLElement>table.querySelector('tbody'), binding.value);
  },
};

export const sortableCol: Directive = {
  beforeMount(el, binding) {
    const table = el;
    const tableHead = <HTMLElement>table.querySelector('thead');

    table._sortable = sortable.create(<HTMLElement>tableHead.querySelector('tr'), binding.value);
  },
};
