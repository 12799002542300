<template>
  <svg
    id="Group_37458"
    data-name="Group 37458"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="12.534"
    height="14.773"
    viewBox="0 0 12.534 14.773"
  >
    <g id="Group_37463" data-name="Group 37463">
      <path
        id="Path_24342"
        data-name="Path 24342"
        d="M12.183,10.977a2,2,0,0,0-.316-.361L10.121,9.054l-.138-.113,0,0a2.037,2.037,0,0,0-1.905-.28h0L6.865,9.2c-.176-.206-.75-.884-1.078-1.342L5.5,7.437c-.185-.279-.363-.567-.528-.855l-.1-.185.855-.989a1.985,1.985,0,0,0,.329-2.07L5.1,1.228A2.03,2.03,0,0,0,2.183.3h0L1.656.634a3.8,3.8,0,0,0-1.5,4.23,17.576,17.576,0,0,0,2.5,5.105,17.832,17.832,0,0,0,4.019,4.056,3.893,3.893,0,0,0,2.3.747h0a3.9,3.9,0,0,0,2.214-.686l.471-.324a2,2,0,0,0,.523-2.785M7.373,13.067a16.646,16.646,0,0,1-3.75-3.784A16.391,16.391,0,0,1,1.3,4.523a2.617,2.617,0,0,1,1.017-2.9l.492-.315a.851.851,0,0,1,.1-.051A.843.843,0,0,1,4.015,1.7l0,.009.945,2.1a.806.806,0,0,1-.137.828L3.435,6.246l.5.918,0,.008c.179.311.37.62.568.919l.005.008.3.434.007.01c.419.585,1.166,1.452,1.2,1.488l.523.605,1.964-.875a.851.851,0,0,1,.748.114l.094.077L11.076,11.5a.818.818,0,0,1-.085,1.284l-.473.325a2.73,2.73,0,0,1-3.145-.043"
        transform="translate(0 0)"
        fill="#c3c3c3"
      />
    </g>
  </svg>
</template>
