<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="36.292" height="36.292" viewBox="0 0 36.292 36.292">
    <g id="Group_45599" data-name="Group 45599" transform="translate(4964.972 8022.925)">
      <g id="Group_45598" data-name="Group 45598">
        <path
          id="Path_29165"
          data-name="Path 29165"
          d="M961.347,208.778H934.392a4.669,4.669,0,0,0-4.669,4.669V240.4a4.669,4.669,0,0,0,4.669,4.669h26.955a4.669,4.669,0,0,0,4.669-4.669V213.447a4.669,4.669,0,0,0-4.669-4.669"
          transform="translate(-5894.695 -8231.703)"
        />
        <path
          id="Path_29166"
          data-name="Path 29166"
          d="M952.826,268.927H952.1a.557.557,0,0,0-.557.557v7.1a.557.557,0,0,0,.557.557h.722a.557.557,0,0,0,.557-.557v-7.1a.557.557,0,0,0-.557-.557"
          transform="translate(-5911.425 -8277.812)"
          fill="#fff"
        />
        <path
          id="Path_29167"
          data-name="Path 29167"
          d="M969.954,274.722a.556.556,0,0,0-.244-.354l-.561-.361a.557.557,0,0,0-.763.158,1.647,1.647,0,0,1-.691.606,2.207,2.207,0,0,1-.932.2,2.378,2.378,0,0,1-1-.2,2.21,2.21,0,0,1-.744-.539,2.474,2.474,0,0,1-.478-.8,2.851,2.851,0,0,1-.172-.987,2.929,2.929,0,0,1,.168-.991,2.4,2.4,0,0,1,.472-.8,2.214,2.214,0,0,1,.744-.54,2.415,2.415,0,0,1,1.006-.2,1.881,1.881,0,0,1,.49.06,2.217,2.217,0,0,1,.436.167,1.476,1.476,0,0,1,.316.217,1.269,1.269,0,0,1,.205.233.557.557,0,0,0,.367.245.558.558,0,0,0,.43-.1l.6-.441a.557.557,0,0,0,.129-.766,2.639,2.639,0,0,0-.571-.593,3.6,3.6,0,0,0-.713-.421,4.2,4.2,0,0,0-1.7-.359,4.372,4.372,0,0,0-1.682.323,4.078,4.078,0,0,0-2.251,2.257,4.453,4.453,0,0,0-.323,1.705,4.52,4.52,0,0,0,.316,1.7,4.021,4.021,0,0,0,2.244,2.261,4.433,4.433,0,0,0,1.7.322,4.058,4.058,0,0,0,1.029-.125,3.874,3.874,0,0,0,.867-.34,3.415,3.415,0,0,0,.707-.508,3.518,3.518,0,0,0,.517-.608.557.557,0,0,0,.082-.423"
          transform="translate(-5919.818 -8277.219)"
          fill="#fff"
        />
        <path
          id="Path_29168"
          data-name="Path 29168"
          d="M1002.5,270.734a4.08,4.08,0,0,0-2.251-2.257,4.538,4.538,0,0,0-3.364,0,4.077,4.077,0,0,0-2.251,2.257,4.669,4.669,0,0,0,0,3.411,4.077,4.077,0,0,0,2.251,2.258,4.54,4.54,0,0,0,3.364,0,4.08,4.08,0,0,0,2.251-2.257,4.67,4.67,0,0,0,0-3.411m-3.933,4.236a2.378,2.378,0,0,1-1-.2,2.246,2.246,0,0,1-.747-.538,2.357,2.357,0,0,1-.47-.795,3.028,3.028,0,0,1,0-1.987,2.414,2.414,0,0,1,.472-.8,2.211,2.211,0,0,1,.744-.541,2.583,2.583,0,0,1,1.992,0,2.217,2.217,0,0,1,.744.541,2.412,2.412,0,0,1,.472.8,3.024,3.024,0,0,1,0,1.987,2.349,2.349,0,0,1-.47.794,2.274,2.274,0,0,1-.746.539,2.38,2.38,0,0,1-1,.2"
          transform="translate(-5944.21 -8277.219)"
          fill="#fff"
        />
        <path
          id="Path_29169"
          data-name="Path 29169"
          d="M1040.2,268.927h-.722a.557.557,0,0,0-.557.557v4.272l-3.133-4.586a.557.557,0,0,0-.46-.243h-.9a.557.557,0,0,0-.557.557v7.1a.557.557,0,0,0,.557.557h.722a.557.557,0,0,0,.557-.557v-4.317l3.132,4.629a.557.557,0,0,0,.461.245h.9a.557.557,0,0,0,.557-.557v-7.1a.557.557,0,0,0-.557-.557"
          transform="translate(-5974.527 -8277.812)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>
