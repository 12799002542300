<template>
  <svg
    id="Group_33881"
    data-name="Group 33881"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="19.172"
    height="19.172"
    viewBox="0 0 19.172 19.172"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_14692" data-name="Rectangle 14692" width="19.172" height="19.172" />
      </clipPath>
    </defs>
    <g id="Group_33877" data-name="Group 33877" transform="translate(0 0)" clip-path="url(#clip-path)">
      <path
        id="Path_22597"
        data-name="Path 22597"
        d="M9.586,0a9.586,9.586,0,1,0,9.586,9.586A9.586,9.586,0,0,0,9.586,0m0,17.711a8.125,8.125,0,1,1,8.125-8.125,8.134,8.134,0,0,1-8.125,8.125"
        transform="translate(0 0)"
      />
      <path
        id="Path_22598"
        data-name="Path 22598"
        d="M23.01,41.155A1.258,1.258,0,1,1,21.752,39.9a1.258,1.258,0,0,1,1.258,1.258"
        transform="translate(-16.216 -31.569)"
      />
      <path
        id="Path_22599"
        data-name="Path 22599"
        d="M42.413,41.155A1.258,1.258,0,1,1,41.155,39.9a1.258,1.258,0,0,1,1.258,1.258"
        transform="translate(-31.569 -31.569)"
      />
      <path
        id="Path_22600"
        data-name="Path 22600"
        d="M61.817,41.155A1.258,1.258,0,1,1,60.559,39.9a1.258,1.258,0,0,1,1.258,1.258"
        transform="translate(-46.923 -31.569)"
      />
    </g>
  </svg>
</template>
