<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
    <g id="Group_30914" data-name="Group 30914" transform="translate(0 0.091)">
      <path
        id="Subtraction_246"
        data-name="Subtraction 246"
        d="M13,14H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H13a1,1,0,0,1,1,1V13A1,1,0,0,1,13,14ZM1.616,4.847v7.538H12.385V4.847Z"
        transform="translate(0 1.909)"
        fill="#555"
      />
      <g id="Group_30913" data-name="Group 30913" transform="translate(2.882 7.834)">
        <rect id="Rectangle_13967" data-name="Rectangle 13967" width="2" height="2" transform="translate(0.118 0.074)" fill="#555" />
        <rect id="Rectangle_13970" data-name="Rectangle 13970" width="2" height="2" transform="translate(3.118 0.074)" fill="#555" />
        <rect id="Rectangle_13972" data-name="Rectangle 13972" width="2" height="2" transform="translate(6.118 0.074)" fill="#555" />
        <rect id="Rectangle_13968" data-name="Rectangle 13968" width="2" height="2" transform="translate(0.118 3.074)" fill="#555" />
        <rect id="Rectangle_13969" data-name="Rectangle 13969" width="2" height="2" transform="translate(3.118 3.074)" fill="#555" />
        <rect id="Rectangle_13971" data-name="Rectangle 13971" width="2" height="2" transform="translate(6.118 3.074)" fill="#555" />
      </g>
      <rect id="Rectangle_13973" data-name="Rectangle 13973" width="2" height="4" rx="1" transform="translate(4 -0.091)" fill="#555" />
      <rect id="Rectangle_13974" data-name="Rectangle 13974" width="2" height="4" rx="1" transform="translate(9 -0.091)" fill="#555" />
    </g>
  </svg>
</template>
