<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="27.74" height="28.011" viewBox="0 0 27.74 28.011">
    <g id="Group_36787" data-name="Group 36787" transform="translate(0 5.188) rotate(-13)">
      <g id="Group_36115" data-name="Group 36115" transform="translate(0 0)">
        <path
          id="Path_23441"
          data-name="Path 23441"
          d="M19.1,13.309l.466-.678a1.033,1.033,0,0,0-.266-1.436L3.3.2a1.063,1.063,0,0,0-1.411.16,1.033,1.033,0,0,0,.2,1.517L6.609,4.978.327,14.115A1.856,1.856,0,0,0,.8,16.7l9.31,6.4a1.856,1.856,0,0,0,2.58-.478l6.4-9.31h0M16.445,12.5,3,14.8a.363.363,0,0,1-.361-.564L8.238,6.1l8.351,5.742a.363.363,0,0,1-.145.657"
          transform="translate(0 0)"
        />
        <path
          id="Path_23442"
          data-name="Path 23442"
          d="M2.108.225C2.226,3.963.194,2.977.012,4.738A2.184,2.184,0,0,0,1.935,7.165a2.114,2.114,0,0,0,2.282-2.1c.021-2.4-2.145-6-2.109-4.838"
          transform="translate(18.844 14.184)"
        />
      </g>
    </g>
  </svg>
</template>
