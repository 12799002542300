import { ref, Ref, markRaw, onBeforeMount, watch } from 'vue';
import router from '@/router';
import { Channel, Channel as ChannelConstant } from '@views/modules/communication/pages/main/constants/channel';
import apiService from '@/services/api';
import campaignModel from '@/models/communication/campaign';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import SMSIcon from '@assets/icons/modules/communication/all-channel-tab/SMSIcon.vue';
import EmailIcon from '@assets/icons/modules/communication/all-channel-tab/EmailIcon.vue';
import LineOaIcon from '@assets/icons/modules/communication/all-channel-tab/LineOaIcon.vue';
import AllChannelIcon from '@/assets/icons/modules/communication/all-channel-tab/AllChannelIcon.vue';

export default function useLimitationPage() {
  const { getChannelCreditBalanceModel } = campaignModel();
  const { openDefaultErrorModal } = useValidationModal();

  const activeChannel: Ref<number> = ref(null!);
  const activeTab: Ref<number> = ref(1);
  const selectedChannel: Ref<Communication.Channel.Component.AllChannelTab | null> = ref(null);
  const channelCredit: Ref<Communication.Provider.channelCredit> = ref(null!);
  const smsCreditRemaining: Ref<number> = ref(null!);
  const lineCreditRemaining: Ref<number> = ref(null!);
  const emailCreditRemaining: Ref<number> = ref(null!);

  const channels: Ref<Communication.Channel.Component.AllChannelTab[]> = ref([
    {
      label: 'All Channel',
      icon: markRaw(AllChannelIcon),
      path: 'all-channel',
      value: 0,
      disable: true,
    },
    {
      label: 'SMS',
      icon: markRaw(SMSIcon),
      path: 'sms',
      value: 1,
    },
    {
      label: 'Line OA',
      icon: markRaw(LineOaIcon),
      path: 'line-oa',
      value: 3,
    },
    {
      label: 'Email',
      icon: markRaw(EmailIcon),
      path: 'email',
      value: 2,
    },
  ]);

  let isInit = false;

  const resolveChannelQuery = (): string => {
    switch (activeChannel.value) {
      case ChannelConstant.allChannel:
        return 'all-channel';
      case ChannelConstant.sms:
        return 'sms';
      case ChannelConstant.lineOA:
        return 'line-oa';
      case ChannelConstant.email:
        return 'email';
      default:
        return 'sms';
    }
  };

  const getChannelCreditBalance = (selectedChannel: number) => {
    getChannelCreditBalanceModel.payload.Channel = selectedChannel;

    apiService
      .apiRequest(getChannelCreditBalanceModel)
      .then((response) => {
        const resp = response.data;

        channelCredit.value = resp[0];
        if (channelCredit.value.channel === 1) {
          smsCreditRemaining.value = channelCredit.value.remaining;
        } else if (channelCredit.value.channel === 2) {
          emailCreditRemaining.value = channelCredit.value.remaining;
        } else if (channelCredit.value.channel === 3) {
          lineCreditRemaining.value = channelCredit.value.remaining;
        }
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      });
  };

  function getActiveTab(tab: number) {
    activeTab.value = tab;
  }

  watch(
    activeChannel,
    () => {
      const resolvedChannel = resolveChannelQuery();

      if (isInit) {
        router.replace({ path: router.currentRoute.value.path, query: { ...router.currentRoute.value.query, channel: resolvedChannel } });
        isInit = false;
      } else {
        router.replace({ path: router.currentRoute.value.path, query: { channel: resolvedChannel } });
      }
      if (activeChannel.value) {
        selectedChannel.value = channels.value[activeChannel.value];
      } else {
        selectedChannel.value = channels.value[0];
      }

      if (smsCreditRemaining.value === null || emailCreditRemaining.value === null || lineCreditRemaining.value === null) {
        if (selectedChannel.value.value !== 0) getChannelCreditBalance(selectedChannel.value.value);
      }
    },
    { immediate: true },
  );

  onBeforeMount(() => {
    isInit = true;
    activeChannel.value = Channel.sms;
    if (router.currentRoute.value.query.channel) {
      const channel = router.currentRoute.value.query.channel;

      switch (channel) {
        case 'all-channel':
          activeChannel.value = ChannelConstant.allChannel;
          break;
        case 'sms':
          activeChannel.value = ChannelConstant.sms;
          break;
        case 'line-oa':
          activeChannel.value = ChannelConstant.lineOA;
          break;
        case 'email':
          activeChannel.value = ChannelConstant.email;
          break;
        default:
          activeChannel.value = ChannelConstant.sms;
      }
    }

    selectedChannel.value = channels.value[activeChannel.value];
  });

  return { channels, activeChannel, activeTab, selectedChannel, smsCreditRemaining, emailCreditRemaining, lineCreditRemaining, getActiveTab };
}
