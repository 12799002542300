<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32.785" height="28.763" viewBox="0 0 32.785 28.763">
    <g id="Group_37644" data-name="Group 37644" transform="translate(0)">
      <path
        id="Subtraction_11"
        data-name="Subtraction 11"
        d="M10.491,28.763a.991.991,0,0,1-.862-.492L5.391,21.064H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H30.785a2,2,0,0,1,2,2V19.064a2,2,0,0,1-2,2H15.592l-4.238,7.207A.991.991,0,0,1,10.491,28.763ZM3.623,2.478a1,1,0,0,0-1,1V17.585a1,1,0,0,0,1,1H6.848l3.643,6.2,3.642-6.2H29.162a1,1,0,0,0,1-1V3.478a1,1,0,0,0-1-1Z"
        transform="translate(0 0)"
        fill="#fff"
      />
      <ellipse id="Ellipse_35" data-name="Ellipse 35" cx="1.806" cy="1.806" rx="1.806" ry="1.806" transform="translate(7.223 8.491)" fill="#fff" />
      <ellipse id="Ellipse_36" data-name="Ellipse 36" cx="1.806" cy="1.806" rx="1.806" ry="1.806" transform="translate(14.447 8.491)" fill="#fff" />
      <ellipse id="Ellipse_37" data-name="Ellipse 37" cx="1.806" cy="1.806" rx="1.806" ry="1.806" transform="translate(21.67 8.491)" fill="#fff" />
    </g>
  </svg>
</template>
