<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g>
      <path
        class="st8"
        d="M25.82,69.76c0.34,0.01,0.69,0.01,1.03,0h114.3c5.09,15.75,19.87,27.15,37.32,27.15
		c17.45,0,32.22-11.4,37.32-27.15h40.77c6.71,0.28,12.38-4.92,12.66-11.63c0.28-6.71-4.92-12.38-11.63-12.66
		c-0.34-0.01-0.69-0.01-1.03,0h-40.82c-5.14-15.67-19.88-27-37.27-27c-17.39,0-32.13,11.32-37.27,27H26.85
		c-6.71-0.28-12.38,4.92-12.66,11.63C13.9,63.8,19.11,69.47,25.82,69.76z M178.47,40.67c9.36,0,16.95,7.59,16.95,16.95
		c-0.01,9.36-7.59,16.94-16.95,16.95c-9.36,0-16.95-7.59-16.95-16.95C161.52,48.26,169.1,40.67,178.47,40.67z"
      />
      <path
        class="st8"
        d="M257.58,213.58c-0.34-0.01-0.69-0.01-1.03,0h-40.81c-5.13-15.68-19.87-27.02-37.27-27.02
		c-17.4,0-32.14,11.33-37.27,27.02H26.85c-6.71-0.28-12.38,4.92-12.66,11.63s4.92,12.38,11.63,12.66c0.34,0.01,0.69,0.01,1.03,0
		h114.31c5.1,15.74,19.87,27.13,37.31,27.13c17.44,0,32.21-11.39,37.31-27.13h40.78c6.71,0.28,12.38-4.92,12.66-11.63
		C269.5,219.53,264.29,213.86,257.58,213.58z M178.47,242.66c-9.36,0-16.95-7.59-16.95-16.95c0-9.36,7.59-16.95,16.95-16.95
		c9.36,0,16.95,7.59,16.95,16.95C195.41,235.07,187.82,242.65,178.47,242.66z"
      />
      <path
        class="st8"
        d="M256.62,130.68H142.2c-5.14-15.67-19.87-27-37.26-27c-17.39,0-32.13,11.32-37.27,27H26.85
		c-6.31,0.27-11.36,5.32-11.63,11.63c-0.28,6.71,4.92,12.38,11.63,12.66h40.77c5.09,15.75,19.87,27.15,37.32,27.15
		c17.45,0,32.22-11.4,37.32-27.15h114.37c0.34,0.01,0.69,0.01,1.03,0c6.71-0.28,11.92-5.95,11.63-12.66
		C269,135.6,263.33,130.39,256.62,130.68z M104.93,159.78c-9.36-0.01-16.94-7.59-16.95-16.95c0-9.36,7.59-16.95,16.95-16.95
		s16.95,7.59,16.95,16.95S114.29,159.78,104.93,159.78z"
      />
    </g>
  </svg>
</template>
