<template>
  <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.17969 8.18896H13.2217C14.813 8.18896 16.3391 8.82111 17.4643 9.94632C18.5895 11.0715 19.2217 12.5977 19.2217 14.189V18.231C19.2217 19.8223 18.5895 21.3484 17.4643 22.4736C16.3391 23.5988 14.813 24.231 13.2217 24.231H9.17969C7.58839 24.231 6.06226 23.5988 4.93705 22.4736C3.81183 21.3484 3.17969 19.8223 3.17969 18.231L3.17969 8.18896Z"
      fill="#029EFF"
    />
    <path
      d="M2.24649 23.774C2.73714 23.774 3.20768 23.5791 3.55463 23.2322C3.90157 22.8852 4.09647 22.4147 4.09647 21.924V13.536C4.09647 13.0454 3.90157 12.5748 3.55463 12.2279C3.20768 11.8809 2.73714 11.686 2.24649 11.686C1.75584 11.686 1.28527 11.8809 0.938324 12.2279C0.591382 12.5748 0.396484 13.0454 0.396484 13.536V21.924C0.396484 22.167 0.444321 22.4075 0.537292 22.632C0.630264 22.8564 0.766536 23.0604 0.938324 23.2322C1.11011 23.404 1.31406 23.5402 1.53851 23.6332C1.76297 23.7262 2.00355 23.774 2.24649 23.774Z"
      fill="black"
    />
    <path
      d="M9.52579 23.774C10.0164 23.774 10.487 23.5792 10.834 23.2322C11.1809 22.8853 11.3758 22.4147 11.3758 21.9241V8.90005C11.3758 8.4094 11.1809 7.93886 10.834 7.59192C10.487 7.24498 10.0164 7.05005 9.52579 7.05005C9.03514 7.05005 8.56459 7.24498 8.21765 7.59192C7.87071 7.93886 7.67578 8.4094 7.67578 8.90005V21.9241C7.67578 22.4147 7.87071 22.8853 8.21765 23.2322C8.56459 23.5792 9.03514 23.774 9.52579 23.774Z"
      fill="black"
    />
    <path
      d="M17.1547 23.7741C17.6453 23.7741 18.1159 23.5792 18.4628 23.2322C18.8098 22.8853 19.0047 22.4147 19.0047 21.9241V2.38809C19.0047 1.89744 18.8098 1.42687 18.4628 1.07993C18.1159 0.732983 17.6453 0.538086 17.1547 0.538086C16.664 0.538086 16.1935 0.732983 15.8465 1.07993C15.4996 1.42687 15.3047 1.89744 15.3047 2.38809V21.9241C15.3047 22.4147 15.4996 22.8853 15.8465 23.2322C16.1935 23.5792 16.664 23.7741 17.1547 23.7741Z"
      fill="black"
    />
  </svg>
</template>
