export const imageColumn = {
  img_size_width: 'unset',
  img_size_height: '50px',
  img_shape: 'Square', // Square , Circle
  img_size_unit: 'px',
};

export const textColumn = {
  font_color: '#737373',
  badge_color: 'unset',
  font_size: 20,
  font_size_unit: 'px',
};

export const dateColumn = {
  date_format: 'dd/MM/yyyy',
  edit_date_type: 2,
  edit_date_type_desc: 'date', // date , time , datetime
  time_zone: 'Asia/Bangkok',
};

export const optionColumn = {
  display_type: 1, // 1 : word | 2 : word + icon | 3 : icon
  value: {
    item_id: 0,
    name_th: '',
    name_en: '',
    color_font: '#737373',
    color_badge: 'unset',
    icon: '',
    icon_color: '',
    image: '',
    value: '',
    font_size: '20px',
  }, // itemId , nameTh , nameEn , color-font , color-badge , icon , image , value
};

export const actionColumn = {
  action_list: ['detail'], // ['detail , 'edit']
};
