<template>
  <svg id="Group_38665" data-name="Group 38665" xmlns="http://www.w3.org/2000/svg" width="24.848" height="24.847" viewBox="0 0 24.848 24.847">
    <path
      id="Subtraction_264"
      data-name="Subtraction 264"
      d="M12.424,24.847A12.424,12.424,0,0,1,3.639,3.639a12.424,12.424,0,1,1,17.57,17.57A12.343,12.343,0,0,1,12.424,24.847Zm0-22.363A9.939,9.939,0,0,0,5.4,19.452,9.939,9.939,0,0,0,19.452,5.4,9.874,9.874,0,0,0,12.424,2.485Z"
      transform="translate(0)"
      fill="#fff"
    />
    <g id="Group_38666" data-name="Group 38666" transform="translate(0 0)">
      <g id="Group_38665-2" data-name="Group 38665" transform="translate(11.424 6.626)">
        <path
          id="Polygon_43"
          data-name="Polygon 43"
          d="M4.979,1.17a1,1,0,0,1,1.638,0l3.877,5.539a1,1,0,0,1-.819,1.573H1.921A1,1,0,0,1,1.1,6.709Z"
          transform="translate(8.283 0) rotate(90)"
          fill="#fff"
        />
      </g>
      <rect id="Rectangle_16844" data-name="Rectangle 16844" width="2.474" height="9.898" rx="1.237" transform="translate(7.065 7.334)" fill="#fff" />
    </g>
  </svg>
</template>
