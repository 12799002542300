<template>
  <svg id="Group_37636" data-name="Group 37636" xmlns="http://www.w3.org/2000/svg" width="12.633" height="13.17" viewBox="0 0 12.633 13.17">
    <g id="Group_32403" data-name="Group 32403" transform="translate(0 11.767)">
      <rect id="Rectangle_440" data-name="Rectangle 440" width="12.633" height="1.404" rx="0.702" />
    </g>
    <rect id="Rectangle_14341" data-name="Rectangle 14341" width="1.428" height="5.402" rx="0.714" transform="translate(1.268 5.401)" />
    <rect id="Rectangle_14342" data-name="Rectangle 14342" width="1.428" height="8.643" rx="0.714" transform="translate(5.478 2.161)" />
    <rect id="Rectangle_14343" data-name="Rectangle 14343" width="1.428" height="10.803" rx="0.714" transform="translate(9.688)" />
  </svg>
</template>
