import { ref, watch, Ref, onMounted } from 'vue';

import router from '@/router';
import api from '@services/api';

import couponManagementModel from '@models/dataConnect/couponManagement';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useLoading from '@/views/components/loading/hooks/useLoading';

import XLSX from '@libs/xlsx/xlsx.full.min.js';

enum CurrentStep {
  is_Error = 0,
  is_Ready = 1,
  in_progress_upload = 2,
  upload_success = 3,
  preview = 4,
  final_step = 5,
}

enum progress {
  is_Ready = 0,
  in_progress = 1,
  is_success = 2,
  is_Error = 9,
}

enum SelectValid {
  Duplicate = 2,
  No_Duplicate = 1,
}

export default function useBaseRightModal() {
  const { downloadTemplateCouponModel, uploadCouponCodeModel, cancelCouponSet, validateCouponCode, confirmCouponCodeModel } = couponManagementModel();

  const { isLoading } = useLoading();
  const { openDangerModal, openErrorModal } = useValidationModal();

  const fileImportName: any = ref('');
  const fileImportData: any = ref({});

  const uploadResultRef: Ref<HTMLDivElement> = ref(null!);
  const chooseValid: Ref<number> = ref(SelectValid.No_Duplicate);
  const resCouponId: Ref<number> = ref(null!);
  const resReference: Ref<string> = ref('');
  const resCouponCodePreview: Ref<Record<string, any>[]> = ref([]);

  const isProgressSuccess: Ref<boolean> = ref(false);
  const isPreviewTableData: Ref<boolean> = ref(false);
  const isOpenSideModal: Ref<boolean> = ref(false);
  const isActiveSubmit: Ref<boolean> = ref(true);
  const isValidActive: Ref<boolean> = ref(true);
  const currentStep: Ref<number> = ref(CurrentStep.is_Ready);
  const isLoadingPreviewSuccess: Ref<boolean> = ref(false);
  const reportFileUpload: Ref<Coupon.ValidateCouponResponse> = ref(null!);
  const isProgressError: Ref<boolean> = ref(false);
  const isHaveCouponCode: Ref<boolean> = ref(false);

  const onOpenSideModal = () => {
    isOpenSideModal.value = true;
  };

  const onCloseSideModal = () => {
    switch (currentStep.value) {
      case CurrentStep.is_Ready:
        isOpenSideModal.value = false;
        break;
      case CurrentStep.in_progress_upload:
        openDangerModal('ต้องการยกเลิกการนำเข้าข้อมูล', 'ต้องการยกเลิกใช่หรือไม่ ?', () => onConfirmCancel('closeAll'));
        break;
      case CurrentStep.upload_success:
        openDangerModal('ต้องการยกเลิกการนำเข้าข้อมูล', 'ต้องการยกเลิกใช่หรือไม่ ?', () => onConfirmCancel('closeAll'));
        break;
      case CurrentStep.preview:
        openDangerModal('ต้องการยกเลิกการนำเข้าข้อมูล', 'ต้องการยกเลิกใช่หรือไม่ ?', () => onConfirmCancel('closeAll'));
        break;
      case CurrentStep.final_step:
        openDangerModal('หากยกเลิกแล้วจะไม่สามารถกลับมาแก้ไขข้อมูลได้อีก', 'ต้องการยืนยันใช่หรือไม่ ?', () => onConfirmCancel('closeAll'));
        break;
    }
  };

  const onUpdateProgress = (record: Record<string, any>) => {
    if (record.current_progress == progress.in_progress) {
      currentStep.value = CurrentStep.in_progress_upload;
    } else if (record.current_progress == progress.is_success) {
      currentStep.value = CurrentStep.upload_success;
      isPreviewTableData.value = true;
    } else if (record.current_progress == progress.is_Error) {
      currentStep.value = CurrentStep.is_Ready;
    }
  };

  const onNextStepProgress = async (page: number) => {
    if (page == CurrentStep.upload_success) {
      await onValidateCoupon(resCouponId.value);
      currentStep.value = CurrentStep.preview;
    } else if (page == CurrentStep.preview) {
      currentStep.value = CurrentStep.final_step;
    }
  };

  const onPrevStepProgress = (page: number) => {
    switch (page) {
      case CurrentStep.preview:
        openDangerModal('ต้องการยกเลิกการนำเข้าข้อมูล', 'ต้องการยกเลิกใช่หรือไม่ ?', () => onConfirmCancel('backToReady'));
        break;
      case CurrentStep.final_step:
        openDangerModal('หากยกเลิกแล้วจะไม่สามารถกลับมาแก้ไขข้อมูลได้อีก', 'ต้องการยืนยันใช่หรือไม่ ?', () => onConfirmCancel('backToReady'));
        break;
    }
  };

  const onConfirmCancel = (exit: string) => {
    isLoading(true, 'กำลังลบข้อมูลไฟล์');
    cancelCouponSet.payload.coupon_id = resCouponId.value;

    api
      .apiRequest(cancelCouponSet)
      .then((response) => {
        if (response.status == 'success') {
          if (exit == 'closeAll') {
            isOpenSideModal.value = false;
          }
          currentStep.value = CurrentStep.is_Ready;
        }
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
      })
      .finally(() => {
        isLoading(false);
      });
  };

  const onImportFileUpload = (fileData: any) => {
    fileImportName.value = fileData.name;
    fileImportData.value = fileData;
    onUploadFile(fileData);
  };

  const onUploadFile = async (fileData: any) => {
    let params: any = {};
    params.File = fileData;
    params.Records_Preview = 10;
    params = window.GlobalRequest.CalculateFormDataObject(params);

    const model = uploadCouponCodeModel;
    model.payload = params;

    api
      .apiRequest(model)
      .then((response) => {
        if (response.status == 'success') {
          resCouponId.value = response.data.couponid;
          resReference.value = response.data.reference;
          resCouponCodePreview.value = response.data.couponcode_preview;
          isProgressSuccess.value = true;
          router.push({
            path: '/dataConnector/couponManagement',
            query: {
              ...router.currentRoute.value.query,
              fileId: resCouponId.value,
            },
          });
        }
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
        currentStep.value = CurrentStep.is_Error;
        isProgressError.value = true;
      });
  };

  const onValidateCoupon = async (couponId: number) => {
    validateCouponCode.payload.CouponId = couponId;
    validateCouponCode.payload.Condition = chooseValid.value;

    api
      .apiRequest(validateCouponCode)
      .then((response) => {
        if (response.status == 'success') {
          reportFileUpload.value = response.data;
          if (response.data.success_records != 0) {
            isHaveCouponCode.value = true;
          } else {
            isHaveCouponCode.value = false;
          }
          isValidActive.value = false;
        }
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
      })
      .finally(() => {
        onCheckCurrentStep();
      });
  };

  const onDownloadTemplateForm = () => {
    api.apiRequest(downloadTemplateCouponModel).then((response) => {
      window.open(response.data.url);
    });
  };

  const onSelectValidate = (valid: any) => {
    chooseValid.value = valid.value;
  };

  const onGetSubmitResult = () => {
    const form = (uploadResultRef as any).value.confirmFormData();
    let payloadObj: Coupon.PayloadConfirmResult = null!;

    payloadObj = {
      CouponId: resCouponId.value,
      Image_Name: form.Image_Name,
      Image: form.Image,
      Name: form.Name,
      Description: form.Description,
      Value_Reference: form.Value_Reference,
      Status: form.Status,
    };

    onSubmitDataConfirm(payloadObj);
  };

  const onSubmitDataConfirm = async (payload: Coupon.PayloadConfirmResult) => {
    isLoading(true);
    const confirm = confirmCouponCodeModel;
    confirm.payload = window.GlobalRequest.CalculateFormDataObject(payload);

    api
      .apiRequest(confirm)
      .then((response) => {
        if (response.status == 'success') {
          router.push({
            path: '/dataConnector/couponManagement',
            query: {
              brandRef: router.currentRoute.value.query.brandRef,
              ref: resReference.value,
            },
          });
          isOpenSideModal.value = false;
        }
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
      })
      .finally(() => {
        isLoading(false);
      });
  };

  const onSubmitStatus = (status: boolean) => {
    isActiveSubmit.value = status;
  };

  const onCheckCurrentStep = () => {
    if (currentStep.value == CurrentStep.preview) {
      const btn = document.querySelectorAll('#nextBtn');
      btn.forEach((elm: any) => {
        if (isValidActive.value) {
          isLoadingPreviewSuccess.value = true;
          (<HTMLInputElement>elm).disabled = true;
        } else {
          isLoadingPreviewSuccess.value = false;
          (<HTMLInputElement>elm).disabled = false;
        }
      });
    }
  };

  const onExportErrorReport = () => {
    const d = new Date(),
      dformat = [d.getFullYear() + 1, d.getMonth(), d.getDate()].join('') + '_' + [d.getHours(), d.getMinutes(), d.getSeconds()].join('');
    const fileName = 'CouponCode_ErrorReport_' + dformat + '_' + resReference.value + '.xlsx';
    const dataWS = XLSX.utils.json_to_sheet(reportFileUpload.value.error_report);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, dataWS);
    XLSX.writeFile(wb, fileName);
  };

  watch(
    () => [isOpenSideModal.value, currentStep.value],
    () => {
      if (!isOpenSideModal.value || currentStep.value == CurrentStep.is_Ready) {
        isProgressSuccess.value = false;
        resCouponCodePreview.value = [];
        resReference.value = '';
        resCouponId.value = null!;
        isActiveSubmit.value = true;
        isValidActive.value = true;
        isProgressError.value = false;
        currentStep.value = CurrentStep.is_Ready;
      }

      if (currentStep.value == CurrentStep.preview) {
        onCheckCurrentStep();
      }
    },
    { deep: true },
  );

  return {
    resCouponId,
    resReference,
    resCouponCodePreview,
    currentStep,
    isOpenSideModal,
    isPreviewTableData,
    isProgressSuccess,
    reportFileUpload,
    uploadResultRef,
    isActiveSubmit,
    isValidActive,
    isLoadingPreviewSuccess,
    isProgressError,
    isHaveCouponCode,
    onOpenSideModal,
    onCloseSideModal,
    onUpdateProgress,
    onImportFileUpload,
    onDownloadTemplateForm,
    onNextStepProgress,
    onPrevStepProgress,
    onSelectValidate,
    onGetSubmitResult,
    onSubmitStatus,
    onExportErrorReport,
  };
}
