export enum TemplateCategoryEnum {
  ALL = 0,
  POPULAR = 1,
  PROMOTIONS = 2,
  SEASONAL = 3,
  AWARENESS = 4,
  WIN_BACK = 5,
  ENGAGEMENT = 6,
  NOTIFICATION = 7,
  OTHER = 8,
  NEWS = 9,
  FESTIVAL = 10,
  SALES_AND_PROMOTION = 11,
  OTHER_2 = 12,
}
