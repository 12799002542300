<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g>
      <g id="Group_32796_00000178908542786757172930000006031014170962193046_">
        <path
          id="Path_21922_00000010309556018125535980000002198144219919364488_"
          class="st8"
          d="M265.28,56.78L265.28,56.78v-0.13l0,0
			c-0.01-1.13-0.15-2.26-0.41-3.37l0,0v-0.23c-0.32-1.24-0.76-2.43-1.34-3.57l-0.2-0.34l-0.35-0.61l-0.19-0.35l-0.39-0.64
			l-0.23-0.36l-0.42-0.61l-0.32-0.34l-0.45-0.6l-0.32-0.39l-0.5-0.58l-0.35-0.39l-0.52-0.57l-0.36-0.38l-0.55-0.55l-0.41-0.39
			l-0.6-0.55l-0.44-0.39l-0.63-0.54l-0.47-0.48l-0.66-0.54l-0.5-0.39l-0.7-0.52l-0.51-0.38l-0.73-0.52l-0.54-0.36l-0.77-0.52
			l-0.55-0.36l-0.82-0.52l-0.57-0.35l-0.86-0.52l-0.55-0.32l-0.95-0.5l-0.52-0.29l-1.02-0.55l-0.38-0.19
			c-1.3-0.69-2.66-1.36-4.08-2.03l-0.44-0.2l-1.02-0.52l-0.66-0.29l-1.06-0.45l-0.76-0.32l-1.05-0.44l-0.85-0.34l-1.08-0.42
			l-0.86-0.32l-1.06-0.41l-0.9-0.34l-1.08-0.38l-0.93-0.34l-1.09-0.36l-0.96-0.34l-1.12-0.36l-0.99-0.31l-1.12-0.35l-1.03-0.32
			l-1.14-0.34l-1.06-0.32l-1.15-0.32l-1.09-0.31l-1.17-0.32l-1.09-0.29l-1.21-0.31l-1.15-0.29l-1.19-0.29l-1.18-0.28l-1.19-0.29
			l-1.24-0.28l-1.19-0.26l-1.28-0.28l-1.18-0.25L204.15,20l-1.12-0.22l-1.66-0.32l-0.89-0.17l-2.54-0.47l-1.05-0.17l-1.57-0.26
			l-1.17-0.19l-1.46-0.23l-1.25-0.19l-1.46-0.22l-1.33-0.19l-1.46-0.2L186,16.99l-1.46-0.19l-1.31-0.16l-1.46-0.17l-1.31-0.15
			L179,16.15l-1.33-0.15l-1.46-0.16l-1.47-0.13l-1.53-0.09l-1.36-0.17l-1.54-0.13l-1.37-0.12l-1.56-0.12L166.02,15l-1.59-0.12
			l-1.37-0.07l-1.6-0.1l-1.38-0.07l-1.65-0.07h-1.36h-1.7h-1.33h-1.75H151h-1.85l-1.28-0.39h-1.98h-7.84h-2.67h-1.12h-2.03h-1.37
			h-1.75h-1.57h-1.46h-1.46l-1.69,0.5l-1.46,0.09l-1.46,0.09l-1.81,0.1l-1.36,0.09l-1.69,0.13l-1.31,0.1l-1.62,0.13l-1.36,0.12
			l-1.69,0.12l-1.25,0.16l-1.7,0.17l-1.21,0.12l-1.75,0.2l-1.12,0.12l-1.75,0.22l-1.11,0.13l-1.82,0.23l-0.99,0.1l-1.87,0.29
			l-0.92,0.13l-1.85,0.28l-0.9,0.15l-1.84,0.29l-0.87,0.15l-1.92,0.32l-0.76,0.13l-2.01,0.36l-0.63,0.12l-2.08,0.39l-0.52,0.1
			l-2.01,0.41l-0.55,0.12l-2.05,0.44l-0.47,0.1l-2.03,0.47l-0.45,0.13l-2.03,0.45l-0.42,0.1l-2.07,0.52l-0.32,0.07l-2.08,0.55h-0.26
			l-2.08,0.58h-0.22l-2.1,0.73h-0.16l-2.03,0.67h-0.17l-2.05,0.68h-0.15l-2,0.76l0,0l-2.13,0.7l0,0
			c-21.19,7.66-34.2,17.98-34.3,29.36l0,0v174.09h0.55c5.92,21.64,58.72,38.55,122.99,38.55s117.08-16.93,122.99-38.55h0.55v-4.04
			l0,0V56.78z M240.51,223.58c-8.54,6.13-18.26,10.41-28.54,12.57c-22.95,5.78-46.54,8.58-70.2,8.33
			c-23.66,0.24-47.26-2.56-70.2-8.33c-10.31-2.15-20.05-6.43-28.6-12.57v-52.15c31.45,10.51,64.47,15.54,97.62,14.86
			c15.2,0,29.8-0.89,54.16-2.52c7.36-0.33,13.06-6.55,12.73-13.91c-0.33-7.36-6.55-13.06-13.91-12.73
			c-0.22,0.01-0.45,0.03-0.67,0.05c-23.39,1.7-37.74,2.59-52.31,2.59c-25.31,0.26-50.54-2.74-75.08-8.92
			c-7.77-2-15.33-4.78-22.54-8.31V82.38c31.46,12.21,65.04,17.99,98.77,17c14.57,0,28.68-0.87,48.58-2.49
			c6.88-0.11,12.37-5.77,12.26-12.65c-0.11-6.88-5.77-12.37-12.65-12.26c-0.57,0.01-1.13,0.06-1.7,0.14
			c-18.94,1.65-32.61,2.52-46.5,2.52c-23.66,0.24-47.26-2.56-70.2-8.33c-8.45-2.1-16.59-5.31-24.2-9.53l1.28-0.74l0.17-0.09
			l0.63-0.34l0,0l1.37-0.71l0.23-0.12l0.68-0.35l0.34-0.16c0.72-0.35,1.49-0.7,2.3-1.05l0.47-0.2l0.71-0.31l0.55-0.22l1.17-0.48
			l0.67-0.26l0.71-0.28l0.71-0.26l0.71-0.26l1.18-0.42l0.8-0.28l0.93-0.32l0.74-0.25l1.02-0.34l0.68-0.2l1.63-0.51l0.68-0.23
			l1.19-0.35l0.71-0.2l1.65-0.47h0.25c21.38-5.47,43.34-8.3,65.41-8.44h4.79h4.79c22.06,0.09,44.02,2.88,65.41,8.31l1.56,0.44h0.12
			l1.34,0.39l0.26,0.07l1.18,0.36l0.26,0.09l1.21,0.38h0.16l2.64,0.87l0.22,0.07l0.98,0.34l0.39,0.15l0.76,0.28l0.44,0.16l0.66,0.25
			l0.48,0.17l0.58,0.22l1.27,0.51h0.15l0.66,0.26l0.31,0.13l0.64,0.26l0.28,0.12l0.63,0.28c4.1,1.64,7.93,3.87,11.38,6.63
			L240.51,223.58z"
        />
        <path
          id="Path_21923_00000066480101302320232040000004249659625227162520_"
          class="st8"
          d="M185.47,112.06
			c-8.18,0-14.82,6.63-14.82,14.82s6.63,14.82,14.82,14.82s14.82-6.63,14.82-14.82l0,0C200.29,118.69,193.66,112.06,185.47,112.06"
        />
        <path
          id="Path_21924_00000020357080570016255280000010617656016828847232_"
          class="st8"
          d="M185.47,196.02
			c-8.18,0-14.82,6.63-14.82,14.82c0,8.18,6.63,14.82,14.82,14.82s14.82-6.63,14.82-14.82l0,0
			C200.29,202.65,193.66,196.02,185.47,196.02"
        />
      </g>
    </g>
  </svg>
</template>
