<template>
  <svg id="Group_28409" data-name="Group 28409" xmlns="http://www.w3.org/2000/svg" width="17.91" height="13.777" viewBox="0 0 17.91 13.777">
    <path
      id="Subtraction_89"
      data-name="Subtraction 89"
      d="M17.91,6.2H0V0H2.171V3.855H15.74V0h2.17V6.2Z"
      transform="translate(0 7.578)"
      fill="#41b726"
    />
    <rect id="Rectangle_12755" data-name="Rectangle 12755" width="2.316" height="7.412" transform="translate(7.533 1.733)" fill="#41b726" />
    <path id="Polygon_6" data-name="Polygon 6" d="M3.1,0,6.2,3.444H0Z" transform="translate(5.467)" fill="#41b726" />
  </svg>
</template>
