<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="303" height="412" viewBox="0 0 303 412">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_23250" data-name="Rectangle 23250" width="303" height="412" transform="translate(299.449 224.145)" fill="#fff" />
      </clipPath>
    </defs>
    <g id="Mask_Group_47446" data-name="Mask Group 47446" transform="translate(-299.449 -224.145)" clip-path="url(#clip-path)">
      <g id="Group_51029" data-name="Group 51029" transform="translate(299.116 261.773)">
        <path
          id="Path_31592"
          data-name="Path 31592"
          d="M1962.75,953.3a96.528,96.528,0,0,1-28.218-1.659,6.7,6.7,0,0,1-4-1.881c-1.525-1.8-1.153-4.9.4-6.65a7.34,7.34,0,0,1,6.18-2.136c1.426.118,2.895.5,4.257.036,1.7-.585,2.8-2.336,3.912-3.865s2.636-3.05,4.415-2.857c1.332.145,2.406,1.218,3.6,1.879s2.917.692,3.565-.591c1.386-2.747-.827-10.136,6.643-10.313,10.783-.256,9.2,7.272,10.535,8.829,2.025,2.361,5.515,1.748,8.346.865s6.207-1.737,8.457.364c1.951,1.822,2.229,5.241,4.3,6.9,2.2,1.765,5.244.723,7.84-.155s5.96-1.258,7.536,1.185c1.322,2.048.445,5.089-1.329,6.654s-4.151,1.995-6.422,2.2C1988.026,953.443,1974.579,952.233,1962.75,953.3Z"
          transform="translate(-1720.443 -827.845)"
          fill="#f0f0f0"
        />
        <path
          id="Path_31593"
          data-name="Path 31593"
          d="M693.295,482.915c5.867.013,17.9,11.154.664,12.9s-43.042.864-53.485.878c-17.047.023-21.274-.732-21.121-8.169.088-4.3,2.336-13.3,11.325-14.406,9.351-1.153,3.228,7.33,14.639,7.692,9.719.308,11.417-9.795,20.915-9.942,11.236-.175,9.15,10.759,15.65,12.554C689.024,486.391,687.43,482.9,693.295,482.915Z"
          transform="translate(-619.349 -471.861)"
          fill="#f0f0f0"
          opacity="0.94"
        />
      </g>
      <g id="Group_51028" data-name="Group 51028" transform="translate(-0.781 239.64)">
        <path
          id="Path_31546"
          data-name="Path 31546"
          d="M512.62,1799.54c0,2.3,103.706,4.977,223.2,4.977s216.359-1.867,216.359-4.17-96.867-4.17-216.359-4.17S512.62,1797.237,512.62,1799.54Z"
          transform="translate(-358.338 -1428.746)"
          fill="#32323f"
          opacity="0.11"
        />
        <g id="Group_51014" data-name="Group 51014" transform="translate(64.596 52.668)">
          <path
            id="Path_31547"
            data-name="Path 31547"
            d="M-292.395,1153.12c-88.38,54.175-39.279,129.954,40.114,133.715,41.947,1.987,100.7,2.507,116.859,2.213,17.08-.311,47.84-4.575,49.044-17.656a12.439,12.439,0,0,0-11.862-13.624c-5.851-.189-13.778,2.454-14.155,12.079-.318,8.117,9.674,16.084,22.654,18.64,13.2,2.6,36.231-.9,36.231-14.866,0-8.87-6.383-12.6-11.5-12.6-6.1,0-11.922,4.089-12.612,11.14-.992,10.141,8.95,16.5,26.274,16.605,25.963.156,23.456-37.451,27.969-69.743"
            transform="translate(339.476 -974.518)"
            fill="none"
            stroke="#3e3e3e"
            stroke-width="7"
          />
          <g id="Group_51013" data-name="Group 51013" transform="translate(280.128)">
            <path
              id="Path_31548"
              data-name="Path 31548"
              d="M1139.38,1411.417c-1.736,10.6-1.847,17.906,3.659,18.961,6.713,1.286,9.145-8.022,8.144-12.86S1139.38,1411.417,1139.38,1411.417Z"
              transform="translate(-1108.959 -1178.721)"
            />
            <path
              id="Path_31549"
              data-name="Path 31549"
              d="M1074.419,464.653c-.371,2.022-2.045,5.259-4.451,9.32-8.06,13.607-24.322,36.452-27.2,53.753-3.737,22.464,3.652,64.241,3.789,76.826.042,3.97-3.446,6.717-8.225,8.381-10.366,3.608-16.406,19.715-20.789,14.285-10.48-12.983-24.665-60.22-17.483-101.178s30.212-71.03,37.77-93.5C1037.834,432.545,1076.036,455.855,1074.419,464.653Z"
              transform="translate(-998.112 -404.852)"
              fill="#383838"
            />
            <path
              id="Path_31550"
              data-name="Path 31550"
              d="M1133.592,710.969c.042,3.97-3.446,6.717-8.225,8.382-1.778-3.235-9.773-6.533-14.281-26.949-7.182-32.5-2.768-82.068,34.06-125.25L1157,580.389c-8.06,13.607-24.322,36.452-27.2,53.753C1126.066,656.607,1133.455,698.383,1133.592,710.969Z"
              transform="translate(-1085.144 -511.267)"
              fill="#575757"
            />
            <g id="Group_51012" data-name="Group 51012" transform="translate(14.12 162.246)">
              <path
                id="Path_31551"
                data-name="Path 31551"
                d="M1126.058,1152.777a59.787,59.787,0,0,1-17.617,3.722,42.619,42.619,0,0,1-14.613-1.754,37.31,37.31,0,0,1-12.41-6.572,40.664,40.664,0,0,1-10.571-13.026s0,0,0,0a46.57,46.57,0,0,1-2.713-6.3c-5-14.41-3.121-27.393,8.506-38.437,4.852-4.609,11.4-8.883,19.861-12.779l.235-.108q1.362-.625,2.793-1.236c5.353-2.29,11.369-1.344,17.126,1.982a37.055,37.055,0,0,1,7.271,5.61,50.232,50.232,0,0,1,6.185,7.391,61.265,61.265,0,0,1,7.4,14.695q.577,1.664,1.05,3.32C1144.264,1129.214,1138.921,1148.1,1126.058,1152.777Z"
                transform="translate(-1065.532 -1075.022)"
                fill="#383838"
              />
              <path
                id="Path_31552"
                data-name="Path 31552"
                d="M1126.058,1152.777a59.787,59.787,0,0,1-17.617,3.722,42.619,42.619,0,0,1-14.613-1.754,37.31,37.31,0,0,1-12.41-6.572,40.664,40.664,0,0,1-10.571-13.026s0,0,0,0a46.57,46.57,0,0,1-2.713-6.3c-5-14.41-3.121-27.393,8.506-38.437,4.852-4.609,11.4-8.883,19.861-12.779l.235-.108q1.362-.625,2.793-1.236c5.353-2.29,11.369-1.344,17.126,1.982a37.055,37.055,0,0,1,7.271,5.61,50.232,50.232,0,0,1,6.185,7.391,61.265,61.265,0,0,1,7.4,14.695q.577,1.664,1.05,3.32C1144.264,1129.214,1138.921,1148.1,1126.058,1152.777Z"
                transform="translate(-1065.532 -1075.022)"
                fill="#383838"
              />
              <path
                id="Path_31553"
                data-name="Path 31553"
                d="M1177.756,1152.777a59.788,59.788,0,0,1-17.617,3.722c-11.572-4.407-21.644-14.6-26.429-28.4-.008-.021-.013-.04-.021-.061a48.638,48.638,0,0,1-1.974-24.814c2.021-10.755,7.835-20.074,16.487-25.559.076-.051.153-.1.231-.148q1.362-.625,2.793-1.236c5.353-2.29,11.369-1.344,17.125,1.982a37.051,37.051,0,0,1,7.271,5.61,50.2,50.2,0,0,1,6.185,7.391,61.257,61.257,0,0,1,7.4,14.695q.577,1.664,1.05,3.32C1195.962,1129.214,1190.619,1148.1,1177.756,1152.777Z"
                transform="translate(-1117.23 -1075.022)"
                fill="#575757"
                opacity="0.999"
              />
              <ellipse
                id="Ellipse_1533"
                data-name="Ellipse 1533"
                cx="20.318"
                cy="31.445"
                rx="20.318"
                ry="31.445"
                transform="translate(17.76 16.454) rotate(-19.128)"
                fill="#383838"
              />
            </g>
            <path
              id="Path_31554"
              data-name="Path 31554"
              d="M1251.954,348.11c-5.826,21.586-21.8,35.826-35.8,32.27a59.778,59.778,0,0,1-16.659-6.833c-17.3-10.623-22.359-28.531-17.767-45.542,5.648-20.929,21.156-31.661,52.52-26.345q1.477.25,3,.549C1251.425,304.982,1257.779,326.524,1251.954,348.11Z"
              transform="translate(-1142.002 -300.312)"
              fill="#0267a4"
            />
            <path
              id="Path_31555"
              data-name="Path 31555"
              d="M1251.954,348.11c-5.826,21.586-21.8,35.826-35.8,32.27a59.778,59.778,0,0,1-16.659-6.833c-17.3-10.623-22.359-28.531-17.767-45.542,5.648-20.929,21.156-31.661,52.52-26.345q1.477.25,3,.549C1251.425,304.982,1257.779,326.524,1251.954,348.11Z"
              transform="translate(-1142.002 -300.312)"
              fill="#383838"
            />
            <path
              id="Path_31556"
              data-name="Path 31556"
              d="M1296.293,353.1c-5.826,21.586-21.8,35.826-35.8,32.27a59.777,59.777,0,0,1-16.659-6.833c-7.09-10.152-9.679-24.248-5.875-38.349,5.546-20.55,22.824-34.258,40.627-33.537q1.477.25,3,.549C1295.765,309.971,1302.119,331.512,1296.293,353.1Z"
              transform="translate(-1186.342 -305.3)"
              fill="#575757"
              opacity="0.999"
            />
            <ellipse
              id="Ellipse_1534"
              data-name="Ellipse 1534"
              cx="31.445"
              cy="20.318"
              rx="31.445"
              ry="20.318"
              transform="translate(56.887 66.047) rotate(-74.897)"
              fill="#383838"
            />
            <path
              id="Path_31557"
              data-name="Path 31557"
              d="M1384.979,441.034c-.355,2.075.458,3.945,1.816,4.177s2.746-1.261,3.1-3.336-.458-3.945-1.816-4.177S1385.334,438.96,1384.979,441.034Z"
              transform="translate(-1303.893 -408.907)"
            />
            <ellipse
              id="Ellipse_1535"
              data-name="Ellipse 1535"
              cx="3.811"
              cy="2.494"
              rx="3.811"
              ry="2.494"
              transform="translate(87.955 38.606) rotate(-80.293)"
            />
            <ellipse
              id="Ellipse_1536"
              data-name="Ellipse 1536"
              cx="5.059"
              cy="3.311"
              rx="5.059"
              ry="3.311"
              transform="matrix(0.169, -0.986, 0.986, 0.169, 79.189, 51.095)"
            />
            <path
              id="Path_31558"
              data-name="Path 31558"
              d="M1251.242,1243.08c.885,1.91,2.616,2.988,3.866,2.409s1.546-2.6.66-4.507-2.616-2.988-3.866-2.409S1250.357,1241.17,1251.242,1243.08Z"
              transform="translate(-1197.826 -1041.951)"
              opacity="0.85"
            />
            <ellipse
              id="Ellipse_1537"
              data-name="Ellipse 1537"
              cx="2.494"
              cy="3.811"
              rx="2.494"
              ry="3.811"
              transform="translate(59.542 195.649) rotate(-24.871)"
              opacity="0.85"
            />
            <ellipse
              id="Ellipse_1538"
              data-name="Ellipse 1538"
              cx="2.494"
              cy="3.811"
              rx="2.494"
              ry="3.811"
              transform="translate(58.858 205.4) rotate(-24.871)"
              opacity="0.85"
            />
          </g>
        </g>
        <g id="Group_51024" data-name="Group 51024" transform="translate(427.121 195.966)">
          <g id="Group_51015" data-name="Group 51015" transform="translate(0 0)">
            <path
              id="Path_31559"
              data-name="Path 31559"
              d="M1468.785,1107.805a.92.92,0,0,0,1.81-.249c-.064-2.348-.636-4.408-1.784-4.315C1467.846,1103.32,1468.2,1105.6,1468.785,1107.805Z"
              transform="translate(-1454.139 -1103.239)"
              fill="#fff"
            />
            <path
              id="Path_31560"
              data-name="Path 31560"
              d="M1418.554,1157.169a.921.921,0,0,0-.338-1.78c-3.876.016-7.928,1.023-7.651,2.868C1410.8,1159.821,1415.27,1158.422,1418.554,1157.169Z"
              transform="translate(-1410.552 -1142.661)"
              fill="#fff"
            />
            <path
              id="Path_31561"
              data-name="Path 31561"
              d="M1436.465,1110.421a.92.92,0,0,0,1.5-1.009c-1.534-3.557-4.051-6.891-5.64-5.91C1430.98,1104.335,1434.023,1107.9,1436.465,1110.421Z"
              transform="translate(-1426.763 -1103.307)"
              fill="#fff"
            />
            <path
              id="Path_31562"
              data-name="Path 31562"
              d="M1431.565,1136.718a.92.92,0,0,0,.963-1.566c-1.251-.82-2.4-1.177-2.879-.533C1429.243,1135.167,1430.234,1135.962,1431.565,1136.718Z"
              transform="translate(-1424.918 -1126.71)"
              fill="#fff"
            />
          </g>
          <g id="Group_51023" data-name="Group 51023" transform="translate(4.893 10.435)">
            <g id="Group_51017" data-name="Group 51017" transform="translate(31.776 50.669)">
              <path
                id="Path_31563"
                data-name="Path 31563"
                d="M1610.293,1755.268c-.56-2.659-1.118-9.489-1.118-9.489l7.181-1.03.743,10.266-4.406,3.76Z"
                transform="translate(-1597.368 -1649.282)"
                fill="#fff"
              />
              <path
                id="Path_31564"
                data-name="Path 31564"
                d="M1573.263,1783.7c-.775-.1-.387.578-.552,1.735-.378,2.648-9.882,3.651-11.293,4.025-.728.193-1.536,4.111,2.146,3.734,3.184-.325,13.389-1.33,16.871-1.708a1.6,1.6,0,0,0,1.42-1.348c.375-2.559-.191-6.418-1.172-6.4C1579.166,1783.41,1576.49,1784.134,1573.263,1783.7Z"
                transform="translate(-1560.797 -1678.686)"
              />
              <g id="Group_51016" data-name="Group 51016" transform="translate(98.925 39.339)">
                <path
                  id="Path_31565"
                  data-name="Path 31565"
                  d="M1977.813,1531.474c-2.507,1.109-9.135,3.1-9.135,3.1l-2.559-6.716,9.983-2.908,4.661,3.453Z"
                  transform="translate(-1966.12 -1522.47)"
                  fill="#fff"
                />
                <path
                  id="Path_31566"
                  data-name="Path 31566"
                  d="M2004.3,1524.675c.1.766.666.217,1.838.071,2.684-.334,6.169,8.468,6.905,9.716.38.644,4.417.386,3.078-3.028-1.157-2.952-4.829-12.422-6.116-15.645a1.613,1.613,0,0,0-1.69-1c-2.6.315-6.212,1.867-5.94,2.8C2002.465,1519.12,2003.877,1521.482,2004.3,1524.675Z"
                  transform="translate(-1993.518 -1514.777)"
                />
              </g>
              <path
                id="Path_31567"
                data-name="Path 31567"
                d="M1583.614,1454.834c.305,1.169,11.892,1.68,11.713-.265s-5.812-35.811-4.868-39.766c1.092-4.575,6.377-14.081,10.859-22.3,4.124-7.566,7.83-13.974,7.949-14.179,0,0,0-.005,0-.005s17.163,23.8,26.383,31.974c5.834,5.173,43.036-3.507,44.721-3.842s-1.786-11.314-3.337-10.931-32.13,5.224-35.349,2.638c-2.886-2.319-11.519-23.037-17.733-35.545l-3.389-6.924c-.414-.872-.656-1.413-.687-1.539-.389-1.595-22.639.782-22.639.782l-5.476,3.394c-1.188,12.424-13.768,50.131-14.055,56.126S1583.309,1453.665,1583.614,1454.834Z"
                transform="translate(-1573.576 -1353.596)"
              />
              <path
                id="Path_31568"
                data-name="Path 31568"
                d="M1715.463,1366.854s4.486,5.678,14.175,4.613l-4.932-9.734c-2.353-.492-2.413.466-5.9.848Z"
                transform="translate(-1677.714 -1359.644)"
                fill="#1f1f39"
                opacity="0.44"
              />
              <path
                id="Path_31569"
                data-name="Path 31569"
                d="M1674.469,1455.7c4.124-7.567,7.83-13.974,7.949-14.179,0,0,0-.005,0-.005l-2.919-4.317S1674.423,1449.567,1674.469,1455.7Z"
                transform="translate(-1646.725 -1416.797)"
                fill="#1f1f39"
                opacity="0.57"
              />
            </g>
            <path
              id="Path_31570"
              data-name="Path 31570"
              d="M1434.99,1208.7a28.431,28.431,0,0,1-.875-5.518c-.09-2.442-.238-4.048-1.3-5.8-.943-1.555-2.789-5.454-2.039-7.013,1.059-2.2,7.77-3.374,7.684.093-.23,9.286,3.831,3.653,4.3,4.781,1.153,2.772-3.438,1.732-2.334,6.029.63,2.451,1.4,5.009,1.193,6.091S1434.99,1208.7,1434.99,1208.7Z"
              transform="translate(-1430.6 -1177.952)"
              fill="#eeada5"
            />
            <path
              id="Path_31571"
              data-name="Path 31571"
              d="M1516.412,1195.522l3.984-4.946c-1.552-2.247-7.327-5.146-9.117-6.869-1.963-1.891-5.462.035-5.462.035l.933,3.826.319.256.063.051a28,28,0,0,1,3,4.139,7.14,7.14,0,0,1,.7,1.641c.324,1.279,1.622,2.58,1.637,4.887C1512.484,1200.269,1516.412,1195.522,1516.412,1195.522Z"
              transform="translate(-1487.46 -1173.874)"
              fill="#eeada5"
            />
            <path
              id="Path_31572"
              data-name="Path 31572"
              d="M1511.011,1198.252a27.99,27.99,0,0,1,3,4.139c3.61-1.067,2.786-5.368,1.445-5.645a27.723,27.723,0,0,0-3.538-.137l-.971,1.593Z"
              transform="translate(-1491.337 -1184.252)"
              fill="#1f1f39"
              opacity="0.14"
            />
            <path
              id="Path_31573"
              data-name="Path 31573"
              d="M1528.388,1194.825c-2.779.954-.319,6.042-2.119,7.491-1.092.879-1.573.344-2.361-.144-.425-.263-1.931-1.453-2.087-.542-3.183,18.535,28.329,40.427,28.3,43.331-.028,2.374,11.5-.341,16.279-5.664,6.552-7.3.2-15.6-1.151-17.088s-25.492-26.031-25.492-26.031S1534.044,1195.588,1528.388,1194.825Z"
              transform="translate(-1499.387 -1182.907)"
              fill="#fff"
            />
            <g id="Group_51019" data-name="Group 51019" transform="translate(71.625 53.014)">
              <path
                id="Path_31574"
                data-name="Path 31574"
                d="M1766.517,1384.064l-5.185,3.927c-.8.606-.77,1.985.065,3.081l2.318,3.04a.034.034,0,0,0,.048.006l.965-.731a.034.034,0,0,0,.007-.047l-1.634-2.142a1.668,1.668,0,0,1-.05-2.3l3.876-2.936a1.681,1.681,0,0,1,2.214.665l1.634,2.142a.034.034,0,0,0,.048.006l.965-.731a.034.034,0,0,0,.007-.047l-2.318-3.04C1768.643,1383.857,1767.317,1383.458,1766.517,1384.064Z"
                transform="translate(-1751.798 -1378.759)"
                fill="#201b2c"
              />
              <path
                id="Path_31575"
                data-name="Path 31575"
                d="M1752.057,1366.223a.034.034,0,0,0-.006.039c.646,1.267.109,1.188.738,3s.782,4.864,1.758,4.532.96-1.684,1.226-2.146-.011,1.063.984,1.46,3.326-.608,3.846-1.833a4.706,4.706,0,0,0-.676-4.388,20.423,20.423,0,0,0-3.844-3.622C1755.489,1362.731,1752.317,1365.957,1752.057,1366.223Z"
                transform="translate(-1745.218 -1363.204)"
                fill="#eeada5"
              />
              <path
                id="Path_31576"
                data-name="Path 31576"
                d="M1761.3,1398.541c-.8.606-.77,1.986.066,3.081l2.318,3.039a.034.034,0,0,0,.048.006l.964-.73a.034.034,0,0,0,.007-.047l-1.634-2.142a1.669,1.669,0,0,1-.049-2.3h0l-.614-1.669a.034.034,0,0,0-.052-.014Z"
                transform="translate(-1751.771 -1389.321)"
                fill="#201b2c"
              />
              <g id="Group_51018" data-name="Group 51018" transform="translate(0 0.889)">
                <path
                  id="Path_31577"
                  data-name="Path 31577"
                  d="M1724.848,1391.708l10.676,14.169a1.98,1.98,0,0,0,2.772.349l.02-.015,26.414-19.276c2.953-2.212,1.415-4.213.782-5.053l-9.676-13.7a4.327,4.327,0,0,0-5.692-.468l-23.3,17.426-.875.653A4.2,4.2,0,0,0,1724.848,1391.708Z"
                  transform="translate(-1724.066 -1366.845)"
                  fill="#54353a"
                />
                <path
                  id="Path_31578"
                  data-name="Path 31578"
                  d="M1724.848,1448.436l10.676,14.169a1.981,1.981,0,0,0,2.772.349h0a.035.035,0,0,0,.013-.038,13.516,13.516,0,0,0-2.853-4.5c-4.153-4.793-6.97-11.637-8.595-16.5a.033.033,0,0,0-.052-.015l-.837.625A4.2,4.2,0,0,0,1724.848,1448.436Z"
                  transform="translate(-1724.066 -1423.573)"
                  fill="#775155"
                />
              </g>
            </g>
            <path
              id="Path_31579"
              data-name="Path 31579"
              d="M1438,1228.85c.3,1.577,6.693,30.095,14.36,28.314,3.894-.9,8.575-14.621,10.041-14.848,2.623-.406,4.7,3.489,6.1,6.384.89,1.849,2.021,3.2,2.7,4.495,1.757,3.319,8.123,18,14.6,19.386,4.116.879,5.932-.731,6.463-3.034.913-3.953-8.57-12.579-14.5-23.312-4.585-8.3-10.965-10.546-14.531-17.556-2.7-5.3-4.584-6.881-4.584-6.881-3.438,6.318-5.416,20.5-6.646,20.928-1.317.454-3.666-10.947-4.722-15.375C1446.885,1225.69,1437.632,1226.892,1438,1228.85Z"
              transform="translate(-1436.186 -1203.297)"
              fill="#bebebe"
            />
            <path
              id="Path_31580"
              data-name="Path 31580"
              d="M1598.113,1231.434c-1.962,4.515-6.5,7.7-11.716,6.545-4.328-.957-4.141-6.142-10.239-11.1-3.385-2.751-9.2-7.851-12.471-11.917a244.507,244.507,0,0,1-16.391-23.874,25.653,25.653,0,0,1,14.059-1.017c3.239.7,8.335,5.625,11.392,9.681,1,1.332,1.794,2.411,2.449,3.333,1.339,1.884,2.106,3.106,2.934,4.452.7,1.14,1.448,2.372,2.624,4.171C1584,1216.676,1599.645,1227.91,1598.113,1231.434Z"
              transform="translate(-1518.815 -1178.904)"
              fill="#bebebe"
            />
            <path
              id="Path_31581"
              data-name="Path 31581"
              d="M1577.534,1201.981c25.663,11.738,25.549,10,28.074,13.059s8.552,22.05,9,22.957,8.258-2.624,8.225-3.858-9.87-24.674-11.155-26.638c-3.375-5.156-29.161-15.192-31.9-15.937s-9.862,2.979-7.614,6.128A12.714,12.714,0,0,0,1577.534,1201.981Z"
              transform="translate(-1537.287 -1180.369)"
              fill="#bebebe"
            />
            <g id="Group_51021" data-name="Group 51021" transform="translate(25.337 2.398)">
              <g id="Group_51020" data-name="Group 51020" transform="translate(0.724 10.683)">
                <path
                  id="Path_31582"
                  data-name="Path 31582"
                  d="M1538.417,1206.568c.159-.235,4.037,2.985,7.546,3.079a7.653,7.653,0,0,0,2.885-.436c2.73-1.012,14.082-8.01,14.082-8.01l-4.254-1.611s-9.145,6.417-10.6,6.873a11.16,11.16,0,0,1-3.232.49,17.845,17.845,0,0,1-5.355-.868c-.752-.231-1.128-.916-1.811-.65C1536.771,1205.786,1538.211,1206.869,1538.417,1206.568Z"
                  transform="translate(-1537.38 -1199.59)"
                  fill="#1f1f3b"
                  opacity="0.24"
                />
              </g>
              <path
                id="Path_31583"
                data-name="Path 31583"
                d="M1534.475,1173.214a1.224,1.224,0,0,1,.792-1.567c.682-.265.845-.023,1.6.208a14.721,14.721,0,0,0,8.587.379c3.106-.974,8.355-7.72,13.139-8.7s7.687,2.286,14.447.517,12.538-7.344,14.542-7.843,3.3-.591,3.549.152a4.039,4.039,0,0,1-1.312,3.427c-1.11,1.083-12.41,6.879-15.639,7.806-5.271,1.513-11.36-1.018-14.7.1s-9.394,5.854-13.257,7.286c-4.308,1.6-9.68-1.693-9.885-1.392a1.3,1.3,0,0,1-.985.69C1534.945,1174.349,1534.615,1173.636,1534.475,1173.214Z"
                transform="translate(-1534.412 -1155.819)"
                fill="#f4ad00"
              />
            </g>
            <g id="Group_51022" data-name="Group 51022" transform="translate(10.108)">
              <path
                id="Path_31584"
                data-name="Path 31584"
                d="M1484.992,1169.311c.373,2.262-1,7.128.528,6.729s.783,4.78,2.421,4.412c3.967-.891,7.875-4.5,7.913-6.493s-1.555-7.12-1.555-7.12Z"
                transform="translate(-1481.666 -1161.752)"
                fill="#efaea6"
              />
              <path
                id="Path_31585"
                data-name="Path 31585"
                d="M1479.936,1154.63c-1.2.463-1.17.76-2.144.574s.232-1.75-1.456-1.594-3.493.52-4.122-1.254c-.912-2.567,1.571-2.772,3.119-2.848,1-.049.719-2.123,2.632-2.526,1.5-.316,1.542.577,2.892.354.816-.135,2.048-1.826,3.531-1.207,2.751,1.148,1.368,3.792,3.761,5.158,2.791,1.593-1.224,7.572-1.968,7.167-.868-.472-.4-2.992-1.247-3.336-1.273-.52-1.18.41-1.868,1.471-.735,1.133-2.492,1.645-1.788-.359S1481.025,1154.21,1479.936,1154.63Z"
                transform="translate(-1472.017 -1145.993)"
                fill="#1f1f39"
              />
            </g>
          </g>
        </g>
        <path
          id="Path_31586"
          data-name="Path 31586"
          d="M1450.931,435.784c.289-3.387,21.427,3.748,22.79-4.74,1.12-6.975,10.388-36.123,39.542-29.672,22.339,4.943,29.25,18.551,25.322,41.23-3.365,19.431-15.258,34.616-42.509,29.192-28.9-5.752-19.374-28.073-24.324-27.374C1461.644,445.848,1450.79,437.436,1450.931,435.784Z"
          transform="translate(-1023.809 -321.397)"
          fill="#fff"
          stroke="#e8e8ea"
          stroke-width="2"
        />
        <circle id="Ellipse_2000" data-name="Ellipse 2000" cx="5" cy="5" r="5" transform="translate(461.229 118.505)" />
        <circle id="Ellipse_2001" data-name="Ellipse 2001" cx="5" cy="5" r="5" transform="translate(475.229 118.505)" />
        <circle id="Ellipse_2002" data-name="Ellipse 2002" cx="5" cy="5" r="5" transform="translate(489.229 118.505)" />
      </g>
    </g>
  </svg>
</template>
