export default function AllCustomerModel() {
  const fetchAllCustomerListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Segment/Datasource`,
    method: 'POST',
    payload: {
      Flag_Count: false,
    },
  };

  const fetchDisplayColumnModel: API.RequestModel<{ TableId: number }> = {
    endpoint: `${process.env.BASE_URL_SEGMENT!}/Display/Get`,
    method: 'GET',
    payload: {
      TableId: null!,
    },
  };

  return { fetchAllCustomerListModel, fetchDisplayColumnModel };
}
