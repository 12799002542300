import { ref, Ref, reactive, getCurrentInstance } from 'vue';

interface Props {
  groupBucket: CreateSegmentModal.GroupBucket;
  toggleId: string;
}

export default function useButtonToggleOperator(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const selectedSetOperator: CreateSegmentModal.AdvanecOperator = reactive({
    ruleSetIndex: Number(props.groupBucket.OperatorOfRuleSetIndex),
    logicRuleSetOperatorId: Number(props.groupBucket.logicRuleSetOperatorId),
    status: false,
  });

  const btnToggle = (e: Event) => {
    const target = e.target as HTMLInputElement;

    if (target.checked) {
      selectedSetOperator.logicRuleSetOperatorId = 1;
    } else {
      selectedSetOperator.logicRuleSetOperatorId = 2;
    }

    return vm?.$emit('operator-property', JSON.parse(JSON.stringify(selectedSetOperator)));
  };

  return {
    btnToggle,
    selectedSetOperator,
  };
}
