<template>
	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px" y="0px" viewBox="0 0 110 110" style="enable-background:new 0 0 110 110;" xml:space="preserve">
		<g id="Group_31568" transform="translate(-1563 -508.199)">
			<g id="Group_31564">
				<g id="Group_31559" transform="translate(12552.222 15060.07)">
					<g id="Group_804" transform="translate(-10977.865 -14478.842)">
						<g>
							<g id="Group_803">
								<path id="Path_381" style="fill:#0064BA;" d="M27.59-13.97l-4.27,3.84v-29.91h28.32c1.22,0,2.21-0.99,2.21-2.21
							s-0.99-2.21-2.21-2.21H18.9v34.53c0,0,0,0.02,0,0.04l-4.55-4.08c-0.97-0.87-2.46-0.79-3.33,0.18
							c-0.87,0.97-0.79,2.46,0.18,3.33l8.11,7.27c0.45,0.41,1.05,0.62,1.66,0.6c0.61,0.02,1.21-0.19,1.66-0.6l8.11-7.27
							c0.97-0.87,1.05-2.37,0.17-3.34C30.06-14.77,28.56-14.85,27.59-13.97L27.59-13.97" />
								<path id="Path_382" style="fill:#0064BA;" d="M76.08-26.26l-8.11-7.27c-0.96-0.79-2.35-0.79-3.32,0l-8.11,7.27
							c-0.97,0.87-1.05,2.36-0.18,3.33c0.87,0.97,2.36,1.05,3.33,0.18l4.48-4.02c0,0,0,0.03,0,0.04V3.98H35.05
							c-1.22,0-2.21,0.99-2.21,2.21c0,1.22,0.99,2.21,2.21,2.21h33.55v-35.05l4.34,3.89c0.97,0.87,2.46,0.79,3.33-0.18
							C77.13-23.91,77.05-25.4,76.08-26.26" />
							</g>
						</g>
					</g>
					<path id="Path_383" style="fill:#0064BA;" d="M-10927.72-14491.62c0.01-0.88-0.17-1.75-0.53-2.55c-0.45-0.92-1.06-1.74-1.8-2.45
				c-0.34-0.35-1.3-1.19-2.89-2.51c-1.09-0.86-2.11-1.8-3.06-2.81c-0.24-0.3-0.37-0.66-0.37-1.04c0.01-0.39,0.2-0.76,0.52-0.99
				c0.38-0.3,0.86-0.46,1.34-0.44c0.8,0.04,1.57,0.37,2.14,0.94c0.84,0.73,2.11,0.64,2.84-0.19c0.73-0.84,0.64-2.11-0.19-2.84l0,0
				c-0.49-0.44-1.02-0.81-1.6-1.12c-0.53-0.27-1.1-0.47-1.68-0.6v-2.02c0.04-0.89-0.65-1.65-1.55-1.69
				c-0.89-0.04-1.65,0.65-1.69,1.55c0,0.05,0,0.09,0,0.14v2.04c0,0.02,0,0.04,0,0.06c-0.93,0.24-1.79,0.72-2.5,1.38
				c-1.12,0.99-1.75,2.42-1.74,3.91c0.03,1.13,0.39,2.24,1.04,3.16c1.34,1.7,2.89,3.21,4.62,4.5c0.89,0.67,1.7,1.44,2.43,2.28
				c0.34,0.42,0.54,0.95,0.55,1.49c-0.01,0.57-0.28,1.1-0.73,1.44c-0.5,0.42-1.15,0.65-1.8,0.62c-1.07-0.05-2.05-0.58-2.67-1.46
				c-0.76-0.86-2.08-0.94-2.95-0.18c-0.81,0.72-0.94,1.93-0.3,2.8c0.49,0.64,1.08,1.19,1.75,1.64c0.7,0.45,1.48,0.77,2.3,0.94
				c0,0.03-0.02,0.06-0.02,0.09v2.06c0.04,0.89,0.79,1.59,1.69,1.55c0.84-0.04,1.51-0.71,1.55-1.55v-2.05
				c1.29-0.18,2.48-0.76,3.43-1.64c1.23-1.14,1.92-2.77,1.87-4.45" />
				</g>
			</g>
		</g>
	</svg>

</template>
