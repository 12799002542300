<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="23.875" height="23.875" viewBox="0 0 23.875 23.875">
    <g id="Group_45295" data-name="Group 45295" transform="translate(-1046.538 -657.167)">
      <g id="Group_45295-2" data-name="Group 45295" transform="translate(1046.538 657.167)">
        <path
          id="Path_29106"
          data-name="Path 29106"
          d="M1048.9,665.476V660.35a.825.825,0,0,1,.824-.824h5.126v-2.359h-5.126a3.183,3.183,0,0,0-3.183,3.183v5.126Z"
          transform="translate(-1046.538 -657.167)"
        />
      </g>
      <path
        id="Path_29107"
        data-name="Path 29107"
        d="M1102.653,659.526h5.126a.825.825,0,0,1,.824.824v5.126h2.359V660.35a3.183,3.183,0,0,0-3.183-3.183h-5.126Z"
        transform="translate(-40.549)"
      />
      <path
        id="Path_29108"
        data-name="Path 29108"
        d="M1108.6,713.282v5.126a.825.825,0,0,1-.824.825h-5.126v2.359h5.126a3.183,3.183,0,0,0,3.183-3.183v-5.126Z"
        transform="translate(-40.549 -40.549)"
      />
      <path
        id="Path_29109"
        data-name="Path 29109"
        d="M1054.847,719.232h-5.126a.825.825,0,0,1-.824-.825v-5.126h-2.359v5.126a3.183,3.183,0,0,0,3.183,3.183h5.126Z"
        transform="translate(0 -40.549)"
      />
    </g>
  </svg>
</template>
