<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="36.071" height="15.056" viewBox="0 0 36.071 15.056">
    <g id="Group_44795" data-name="Group 44795" transform="translate(-3871.24 -3080)">
      <path id="Path_28724" data-name="Path 28724" d="M4.5,0,9,5H0Z" transform="translate(3907.311 3090.5) rotate(180)" fill="#0f2a4e" />
      <g id="Group_44730" data-name="Group 44730" transform="translate(7.24)">
        <g id="Group_44729" data-name="Group 44729">
          <path
            id="Path_28725"
            data-name="Path 28725"
            d="M5107.956-141.545h-9.507"
            transform="translate(-1225.949 3222.545)"
            fill="none"
            stroke="#0f2a4e"
            stroke-linecap="round"
            stroke-width="2"
          />
          <path
            id="Path_28726"
            data-name="Path 28726"
            d="M5103.543-141.545h-5.094"
            transform="translate(-1225.95 3226.897)"
            fill="none"
            stroke="#0f2a4e"
            stroke-linecap="round"
            stroke-width="2"
          />
          <circle id="Ellipse_940" data-name="Ellipse 940" cx="3" cy="3" r="3" transform="translate(3864 3080)" fill="#0080ff" />
        </g>
        <g id="Group_44728" data-name="Group 44728">
          <path
            id="Path_28727"
            data-name="Path 28727"
            d="M5108.637-141.545h-10.187"
            transform="translate(-1225.949 3231.249)"
            fill="none"
            stroke="#0f2a4e"
            stroke-linecap="round"
            stroke-width="2"
          />
          <path
            id="Path_28728"
            data-name="Path 28728"
            d="M5103.543-141.545h-5.094"
            transform="translate(-1225.95 3235.601)"
            fill="none"
            stroke="#0f2a4e"
            stroke-linecap="round"
            stroke-width="2"
          />
          <circle id="Ellipse_941" data-name="Ellipse 941" cx="3" cy="3" r="3" transform="translate(3864 3089)" fill="#0080ff" />
        </g>
      </g>
    </g>
  </svg>
</template>
