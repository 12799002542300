<template>
  <svg
    id="Group_33565"
    data-name="Group 33565"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="20.076"
    height="20.076"
    viewBox="0 0 20.076 20.076"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_14569" data-name="Rectangle 14569" width="20.076" height="20.076" />
      </clipPath>
    </defs>
    <g id="Group_33564" data-name="Group 33564" clip-path="url(#clip-path)">
      <path
        id="Path_22517"
        data-name="Path 22517"
        d="M151.983,4.544a4.882,4.882,0,0,0-8.322-3.115l-2.889,2.889A.838.838,0,0,0,141.958,5.5l2.86-2.86a3.205,3.205,0,1,1,4.562,4.5l-3.973,3.973-.6.6a3.208,3.208,0,0,1-4.533,0,7.644,7.644,0,0,1-.543-.638.858.858,0,0,0-1.246-.115.849.849,0,0,0-.071,1.186c.338.389.5.576.675.753a4.878,4.878,0,0,0,6.9,0l4.572-4.572a4.87,4.87,0,0,0,1.418-3.789"
        transform="translate(-131.919 0)"
      />
      <path
        id="Path_22518"
        data-name="Path 22518"
        d="M9.135,152.683l-1.958,1.958a3.205,3.205,0,1,1-4.562-4.5l3.174-3.174.6-.6a3.208,3.208,0,0,1,4.533,0,4.351,4.351,0,0,1,.506.625.848.848,0,1,0,1.368-1,8.131,8.131,0,0,0-.688-.809h0a4.878,4.878,0,0,0-6.9,0L1.43,148.952a4.882,4.882,0,0,0,0,6.9h0a4.882,4.882,0,0,0,6.9,0l1.987-1.987a.838.838,0,0,0-1.186-1.186"
        transform="translate(0 -137.209)"
      />
    </g>
  </svg>
</template>
