import { ref, Ref, getCurrentInstance, computed } from 'vue';

import authService from '@/services/authentication';
import { STATIC_COLOR_LIST } from '@/constants/components/color-picker/base-color-picker';

export default function useToolbar() {
  const vm = getCurrentInstance()?.proxy;

  const colorList = STATIC_COLOR_LIST.map((color) => color.colorHex);
  const fontColor: Ref<string> = ref('');
  const isColorPickerOpen: Ref<boolean> = ref(false);

  const onToggleColorPicker = () => {
    isColorPickerOpen.value = !isColorPickerOpen.value;
  };

  const setColor = (color: string) => {
    fontColor.value = color;
  };

  const onApplyColor = (color: string) => {
    vm?.$emit('on-apply-color', color);
  };

  const onSelectedEmoji = (emoji: string) => {
    vm?.$emit('on-selected-emoji', emoji);
  };

  const isBrandKerry = computed(() => {
    const brand_ref = authService.fetchBrandData().brandData.brand_ref;
    if (brand_ref == 'BCUZ063IB4LC') {
      return true;
    } else {
      return false;
    }
  });

  return {
    colorList,
    fontColor,
    isColorPickerOpen,
    isBrandKerry,
    setColor,
    onToggleColorPicker,
    onApplyColor,
    onSelectedEmoji,
  };
}
