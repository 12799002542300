import { ref, Ref, reactive, onBeforeMount, onMounted, watch, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import apiService from '@/services/api';
import campaignModel from '@/models/communication/campaign';

interface Props {
  selectedChannel: Communication.Channel.Component.AllChannelTab | null;
}

export default function useEDMTab(props: Props) {
  const router = useRouter();
  const vm = getCurrentInstance()?.proxy;
  const { openDefaultErrorModal } = useValidationModal();
  const { createCampaignModel } = campaignModel();

  const isLoading = ref(false);
  const campaignId: Ref<number> = ref(0);
  const activeTab: Ref<number> = ref(0);
  const createButtonOptions: Communication.Channel.Component.CreateButtonDropDown[] = [
    {
      label: 'Campaign',
      key: 'campaign',
    },
    {
      label: 'Template',
      key: 'template',
    },
  ];

  const selectTemplateModal: { isOpen: boolean } = reactive({
    isOpen: false,
  });

  const createCampaign = (templateId?: number) => {
    isLoading.value = true;
    createCampaignModel.payload.Channel = props.selectedChannel?.value as Communication.Campaign.ChannelType;
    createCampaignModel.payload.TemplateId = templateId || null;
    createCampaignModel.payload.Name = null;
    createCampaignModel.payload.Description = null;

    return apiService
      .apiRequest(createCampaignModel)
      .then((response) => {
        campaignId.value = response.data.campaignid;
        router.push({
          path: `/communication/email/create-campaign/${campaignId.value}`,
          query: {
            ...router.currentRoute.value.query,
          },
        });
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const onSelectTemplate = (template: Communication.Template.Template) => {
    createCampaign(template.template_id);
  };

  function onClickCreateButton() {
    const dropdownContent = document.getElementById('dropdown-content') as HTMLDivElement;
    dropdownContent.classList.toggle('show');
  }

  window.onclick = function (event: any) {
    if (!event.target.matches('.create-campaign-button') && !event.target.matches('.button-icon') && !event.target.matches('.button-text')) {
      const dropdowns = document.getElementsByClassName('dropdown-content');
      for (let i = 0; i < dropdowns.length; i++) {
        const openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  };

  function createTemplate() {
    router.push({
      name: 'email-template-create',
      query: { category: router.currentRoute.value.query.category || 0 },
    });
  }

  function resolveOptionAction(key: string) {
    switch (key) {
      case 'campaign':
        return createCampaign();
      case 'template':
        return createTemplate();
    }
  }

  watch(activeTab, () => {
    router.replace({ path: router.currentRoute.value.path, query: { ...router.currentRoute.value.query, tab: activeTab.value } });
    vm?.$emit('active-tab', activeTab.value);
  });

  onBeforeMount(() => {
    setTimeout(() => {
      activeTab.value = Number(router.currentRoute.value.query.tab) || 0;
      router.replace({ path: router.currentRoute.value.path, query: { ...router.currentRoute.value.query, tab: activeTab.value } });
      vm?.$emit('active-tab', activeTab.value);
    }, 0);
  });

  return {
    isLoading,
    activeTab,
    createButtonOptions,
    onSelectTemplate,
    onClickCreateButton,
    resolveOptionAction,
  };
}
