<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M0 4035 l0 -365 365 0 365 0 0 365 0 365 -365 0 -365 0 0 -365z" />
      <path
        d="M1110 4035 l0 -365 2005 0 2005 0 0 365 0 365 -2005 0 -2005 0 0
-365z"
      />
      <path d="M0 2545 l0 -365 365 0 365 0 0 365 0 365 -365 0 -365 0 0 -365z" />
      <path
        d="M1110 2545 l0 -365 2005 0 2005 0 0 365 0 365 -2005 0 -2005 0 0
-365z"
      />
      <path
        d="M1110 1105 l0 -365 2005 0 2005 0 0 365 0 365 -2005 0 -2005 0 0
-365z"
      />
      <path d="M0 1085 l0 -365 365 0 365 0 0 365 0 365 -365 0 -365 0 0 -365z" />
    </g>
  </svg>
</template>
