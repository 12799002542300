import { useI18n } from 'vue-i18n';

export default function useConvertModule() {
  const { t } = useI18n();
  const convertModuleName = (moduleString: string) => {
    let resolveValue = '';
    switch (moduleString) {
      case 'BI':
        resolveValue = t('communication.bi');
        break;
      case 'INS':
        resolveValue = t('communication.ins');
        break;
      case 'DC':
        resolveValue = t('communication.dc');
        break;
      case 'DI':
        resolveValue = t('communication.di');
        break;
      case 'SEG':
        resolveValue = t('communication.seg');
        break;
      case 'COMM':
        resolveValue = t('communication.communication');
        break;
      case 'MKA':
        resolveValue = t('communication.mka');
        break;
      case 'PERM':
        resolveValue = t('communication.perm');
        break;
      default:
        break;
    }
    return resolveValue;
  };

  return {
    convertModuleName,
  };
}
