import { getCurrentInstance } from 'vue';
import router from '@/router';

import authService from '@/services/authentication';
import apiService from '@/services/api';
import loginModel from '@/models/authentication/login';

import { initialAbility } from '@/libs/acl/config';
import { signalRService } from '@/services/signalR';

export default function useAuthentication() {
  const vm = getCurrentInstance()?.proxy;

  const { fetchLogoutModel } = loginModel();

  const logout = () => {
    fetchLogoutModel.payload.access_token = authService.fetchTokenByBrand();

    apiService
      .apiRequest(fetchLogoutModel)
      .then(() => {})
      .catch(() => {});

    const brands: Array<Record<string, any>> = JSON.parse(authService.getLocalBrandData()!).filter((o: Record<string, any>) => {
      return o.brandRef != sessionStorage.getItem('brandSessionData');
    });

    if (brands.length === 0) {
      localStorage.removeItem('userInfo');
    }
    sessionStorage.removeItem('brandSessionData');
    authService.setLocalBrandData(JSON.stringify(brands));
    // Disconnect SignalR
    // signalRService.disconnect();
    vm?.$ability.update(initialAbility);
    window.location.href = '/login';
  };

  return {
    logout,
  };
}
