<template>
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="49.911" viewBox="0 0 50 49.911">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_12903" data-name="Rectangle 12903" width="50" height="49.911" fill="#006cff"/>
    </clipPath>
  </defs>
  <g id="Group_28385" data-name="Group 28385">
    <path id="Path_17159" data-name="Path 17159" d="M132.6,122.563c.942.2,1.729.36,2.513.532q3.66.8,7.319,1.609l2.789.616a1.094,1.094,0,0,1,.849.762q1.7,4.744,3.421,9.482c.334.923.661,1.849,1,2.768a1.169,1.169,0,0,1-.251,1.277q-3.076,3.5-6.142,7.018c-.989,1.132-1.984,2.258-2.955,3.4a1.269,1.269,0,0,1-1.37.478q-5.41-1.159-10.829-2.273a1.342,1.342,0,0,1-1.058-.9q-2.628-6.469-5.281-12.928a1.108,1.108,0,0,1,.215-1.273q2.369-2.641,4.729-5.29c1.438-1.607,2.87-3.219,4.326-4.809a3.094,3.094,0,0,1,.724-.475m-.261,2.909c-.574.644-1.076,1.21-1.581,1.773q-2.915,3.257-5.835,6.51a.6.6,0,0,0-.137.744c1.224,2.953,2.425,5.916,3.634,8.875.244.6.494,1.191.775,1.867l1.2-1.55c1.9-2.443,3.786-4.891,5.694-7.325a.693.693,0,0,0,.09-.772q-1.327-3.479-2.643-6.963c-.389-1.026-.78-2.051-1.2-3.159m15.379,13.988c-.154-.052-.247-.094-.345-.115-3.049-.657-6.1-1.307-9.145-1.975a.584.584,0,0,0-.661.3q-3.272,4.235-6.552,8.464c-.1.123-.174.26-.28.419.154.05.249.09.349.111,2.735.575,5.473,1.137,8.2,1.734a.842.842,0,0,0,.946-.335c1.865-2.16,3.752-4.3,5.629-6.452.612-.7,1.217-1.408,1.856-2.147m.352-2.008c-.267-.732-.485-1.33-.7-1.929-.977-2.7-1.95-5.393-2.941-8.084a.633.633,0,0,0-.4-.333c-2.521-.57-5.047-1.121-7.571-1.676-.691-.152-1.383-.3-2.187-.477.136.356.235.611.331.867q1.73,4.588,3.453,9.178a.6.6,0,0,0,.5.424c2.471.521,4.939,1.056,7.407,1.585.663.142,1.326.279,2.106.443" transform="translate(-100.588 -100.64)" fill="#006cff"/>
    <path id="Path_17160" data-name="Path 17160" d="M21.564,87.207c-.641,1.1-1.234,2.113-1.82,3.128-1.152,2-2.289,4-3.462,5.987a.915.915,0,0,0-.012,1.015q2.568,4.974,5.1,9.965a1.05,1.05,0,0,1-.139,1.351,1.514,1.514,0,0,1-.916.33c-1.947-.057-3.893-.176-5.84-.248-2.467-.092-4.936-.157-7.4-.237a3.529,3.529,0,0,1-1.016-.083,1.324,1.324,0,0,1-.7-.556c-1.074-2.038-2.106-4.1-3.163-6.147C1.555,100.457.9,99.205.215,97.969a1.478,1.478,0,0,1,.013-1.557c1.137-2.074,2.257-4.157,3.384-6.236L6.59,84.686c.1-.183.192-.37.293-.552a1.094,1.094,0,0,1,1.1-.611c2.468.114,4.936.211,7.4.321,2.036.091,4.072.2,6.109.285a1.2,1.2,0,0,1,1.124.725q1.755,3.463,3.535,6.914a1.033,1.033,0,0,1-.018,1.1.919.919,0,0,1-.923.425,1.112,1.112,0,0,1-.862-.7q-1.259-2.438-2.509-4.882c-.073-.142-.153-.281-.275-.5m-1.641-1.149c-.193-.023-.294-.042-.4-.047-3.612-.162-7.224-.326-10.836-.469a.623.623,0,0,0-.457.289q-2.384,4.34-4.738,8.7c-.273.5-.539,1.007-.849,1.586.231,0,.362,0,.492,0,3.525-.1,7.05-.213,10.575-.291a.956.956,0,0,0,.923-.578c1.2-2.121,2.438-4.225,3.658-6.337.534-.924,1.06-1.852,1.629-2.848m-1.1,20.726a1.15,1.15,0,0,0-.046-.187c-1.424-2.8-2.854-5.588-4.266-8.389a.577.577,0,0,0-.646-.326Q9,98.049,4.14,98.2c-.47.016-.939.045-1.439.069a1.214,1.214,0,0,0,.057.206q1.992,3.866,3.994,7.728a.451.451,0,0,0,.3.2c.382.049.769.078,1.154.089,1.9.054,3.8.1,5.706.149,1.619.044,3.238.094,4.907.142" transform="translate(0 -68.581)" fill="#006cff"/>
    <path id="Path_17161" data-name="Path 17161" d="M116.238,9.6c-.47.3-.828.525-1.185.754-2.6,1.671-5.2,3.352-7.81,5.005a.958.958,0,0,0-.516.961c.032.921.009,1.845,0,2.767-.008.658-.355,1.047-.9,1.041-.611-.007-.939-.354-.948-1.044-.011-.982-.015-1.965,0-2.946a.546.546,0,0,0-.363-.579q-3.618-1.742-7.226-3.5c-1.014-.492-2.03-.98-3.142-1.517,0,.714.01,1.344,0,1.973a.939.939,0,0,1-.752.971.906.906,0,0,1-1.11-.867c-.01-1.22-.009-2.442.064-3.659a1.271,1.271,0,0,1,.532-.813c2.876-1.985,5.773-3.94,8.663-5.9C102.464,1.615,103.4,1,104.3.353a1.364,1.364,0,0,1,1.674-.12c2.576,1.514,5.178,2.984,7.773,4.467,1.212.693,2.422,1.388,3.643,2.065a1.356,1.356,0,0,1,.814,1.3c-.021,4.166-.014,8.332-.009,12.5a1.363,1.363,0,0,1-.475,1.149.852.852,0,0,1-1.405-.386,2.159,2.159,0,0,1-.1-.7q0-5.177.018-10.355c0-.176,0-.352,0-.674m-20.9-.729c.208.119.332.2.463.263q4.8,2.322,9.593,4.648a.628.628,0,0,0,.719-.032c2.674-1.72,5.359-3.423,8.04-5.133.366-.233.722-.482,1.117-.747-.331-.206-.59-.379-.859-.533-2.825-1.617-5.656-3.222-8.472-4.856a.974.974,0,0,0-1.228-.015c-2.059,1.439-4.149,2.834-6.225,4.247-1.029.7-2.054,1.407-3.149,2.157" transform="translate(-75.771 0.001)" fill="#006cff"/>
  </g>
</svg>
</template>
