import { ref, Ref } from 'vue';

import { mockDataSource } from '../mock/mock-data-source';

export default function usePreviewDialog() {
  const isDialogOpen: Ref<boolean> = ref(false);
  const segmentDataSource: Ref<Segment.Datasource[]> = ref(mockDataSource);

  const onOpenDialog = () => {
    isDialogOpen.value = true;
  };

  const onCloseDialog = () => {
    isDialogOpen.value = false;
  };

  const onSubmitDialog = (e: any) => {
    console.log('save >>', e);
  };

  return {
    segmentDataSource,
    isDialogOpen,
    onOpenDialog,
    onCloseDialog,
    onSubmitDialog,
  };
}
