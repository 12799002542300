<template>
  <svg
    id="Group_115582"
    data-name="Group 115582"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="31.071"
    height="28.811"
    viewBox="0 0 31.071 28.811"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_50542" data-name="Rectangle 50542" width="31.071" height="28.811" fill="#007fff" />
      </clipPath>
    </defs>
    <g id="Group_115408" data-name="Group 115408" clip-path="url(#clip-path)">
      <path
        id="Path_311047"
        data-name="Path 311047"
        d="M13.652,6.989h0a1,1,0,0,1-1,1h-4.5a1,1,0,0,1,0-2h4.5a1,1,0,0,1,1,1"
        transform="translate(4.43 3.707)"
        fill="#007fff"
      />
      <path
        id="Path_311048"
        data-name="Path 311048"
        d="M11.924,6.989h0a1,1,0,0,1-1,1H6.43a1,1,0,0,1,0-2h4.5a1,1,0,0,1,1,1"
        transform="translate(3.361 3.707)"
        fill="#007fff"
      />
      <path id="Path_311049" data-name="Path 311049" d="M7.429,6.989a1,1,0,1,1-1-1,1,1,0,0,1,1,1" transform="translate(3.361 3.707)" fill="#007fff" />
      <path id="Path_311050" data-name="Path 311050" d="M7.429,9.9a1,1,0,1,1-1-1,1,1,0,0,1,1,1" transform="translate(3.361 5.507)" fill="#007fff" />
      <path
        id="Path_311051"
        data-name="Path 311051"
        d="M10.656,8.9h-2.5a1,1,0,0,0,0,2h2.5a1,1,0,1,0,0-2"
        transform="translate(4.43 5.507)"
        fill="#007fff"
      />
      <path
        id="Path_311052"
        data-name="Path 311052"
        d="M26.042,12.078A12.967,12.967,0,0,0,13.095,0q-.437,0-.879.029a12.969,12.969,0,0,0-9.106,21.2c-.536.295-1.148.618-1.823.96A1.87,1.87,0,0,0,.045,24.348C.381,25.757,1.68,26.1,6.665,26.1c2.653,0,5.481-.125,6.44-.167a12.925,12.925,0,0,0,3.754-.57l.583-.175-.3-.531a7.142,7.142,0,0,1-.741-1.834l-.12-.489-.486.136a9.886,9.886,0,0,1-2.687.389H12.91c-.8.039-3.629.167-6.245.167l-.484,0,.2-.121a1.54,1.54,0,0,0,.223-2.48A9.891,9.891,0,0,1,12.426,3.1c.236-.018.471-.024.7-.024a9.894,9.894,0,0,1,9.842,9.214,10.014,10.014,0,0,1,.008,1.123l-.021.5.5.006a7.166,7.166,0,0,1,1.951.319l.584.173.037-.609a12.826,12.826,0,0,0,.008-1.724"
        transform="translate(0 0.001)"
        fill="#007fff"
      />
      <path
        id="Path_311053"
        data-name="Path 311053"
        d="M17.382,8.292a7.694,7.694,0,1,0,7.694,7.694,7.694,7.694,0,0,0-7.694-7.694m0,12.157a.959.959,0,1,1,.962-.958.959.959,0,0,1-.962.958m.962-3.647a.96.96,0,1,1-1.92,0V12.483a.96.96,0,1,1,1.92,0Z"
        transform="translate(5.995 5.132)"
        fill="#007fff"
      />
    </g>
  </svg>
</template>
