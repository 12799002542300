<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="13.684" height="16.421" viewBox="0 0 13.684 16.421">
    <g id="Group_37458" data-name="Group 37458" transform="translate(0 16.421) rotate(-90)">
      <path
        id="Subtraction_262"
        data-name="Subtraction 262"
        d="M16.421,0H13.137L8.21,4.106,3.284,0H0L8.21,6.842,16.42,0Z"
        transform="translate(0 6.842)"
      />
      <path id="Subtraction_263" data-name="Subtraction 263" d="M16.421,0H13.137L8.21,4.106,3.284,0H0L8.21,6.842,16.42,0Z" />
    </g>
  </svg>
</template>
