<template>
  <svg id="Group_34738" data-name="Group 34738" xmlns="http://www.w3.org/2000/svg" width="21.952" height="16.525" viewBox="0 0 21.952 16.525">
    <path
      id="Path_22784"
      data-name="Path 22784"
      d="M353.345,44.213a1.138,1.138,0,1,0-1.138-1.138,1.138,1.138,0,0,0,1.138,1.138"
      transform="translate(-339.994 -35.276)"
    />
    <path
      id="Path_22785"
      data-name="Path 22785"
      d="M304.817,6.947l-15-2.188a1.349,1.349,0,0,0-1.53,1.141l-.409,2.8h-2.509a1.35,1.35,0,0,0-1.35,1.35v9.87a1.349,1.349,0,0,0,1.35,1.349h15.159a1.349,1.349,0,0,0,1.349-1.349v-.7l1.125.164a1.35,1.35,0,0,0,1.53-1.141l1.424-9.767a1.349,1.349,0,0,0-1.141-1.53M285.8,19.493v-.909l3.783-3.183,3.371,2.956a1.279,1.279,0,0,0,1.708-.017l1.74-1.588,3.186,2.74Zm14.3-1.918L297.223,15.1a1.281,1.281,0,0,0-1.7.025l-1.736,1.585-3.358-2.944a1.274,1.274,0,0,0-1.669-.017L285.8,16.244V10.478h14.3Zm2.753.071-.977-.142V10.05a1.35,1.35,0,0,0-1.349-1.35H289.673l.309-2.121,14.155,2.064Z"
      transform="translate(-284.019 -4.745)"
    />
  </svg>
</template>
