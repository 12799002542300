<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18.583" height="21.375" viewBox="0 0 18.583 21.375">
    <g id="Group_1315" data-name="Group 1315" transform="translate(0.249 0.25)">
      <g id="Group_110170" data-name="Group 110170">
        <g id="Group_110171" data-name="Group 110171">
          <path
            id="Union_247"
            data-name="Union 247"
            d="M-6296.463-19054.687a2.482,2.482,0,0,1-1.743-.6,2.453,2.453,0,0,1-.737-1.689l-.44-5.678q-.251-3.246-.5-6.492c0-.047-.009-.1-.014-.143-.062-.029-.129-.049-.191-.072a3.154,3.154,0,0,1-.7-.307,2.371,2.371,0,0,1-.756-2.744,1.924,1.924,0,0,1,1.987-1.393h4.1c0-.125,0-.23,0-.34a1.984,1.984,0,0,1,1.963-1.91c.3,0,.613,0,.972,0,.393,0,.785,0,1.163,0a1.965,1.965,0,0,1,2.087,2.078c0,.053,0,.105,0,.162.072.006.139.006.2.006.278,0,.56,0,.838,0l1.733,0c.426,0,.857,0,1.283,0a2.136,2.136,0,0,1,1.561.574,2.146,2.146,0,0,1,.565,1.547,3.84,3.84,0,0,1-.1.98,1.76,1.76,0,0,1-1.422,1.322c-.211.039-.235.066-.254.316-.3,4.041-.608,8.1-.919,12.049a2.312,2.312,0,0,1-2.533,2.332Zm-1.729-14.463.063.818c.277,3.682.565,7.488.857,11.227.043.57.225.732.813.732h8.11c.694,0,.847-.143.9-.857q.23-2.965.459-5.926l.331-4.318c.038-.469.072-.934.1-1.422.01-.129.02-.264.029-.393H-6298.2A1.28,1.28,0,0,0-6298.192-19069.15Zm-1.292-2.963a.666.666,0,0,0-.426.162.639.639,0,0,0-.067.65.664.664,0,0,0,.608.3h13.965c.043,0,.086,0,.129,0s.067.006.1.006a.417.417,0,0,0,.091-.01,1.059,1.059,0,0,0,.225-.1,2.569,2.569,0,0,0,.015-.934c-.01-.043-.024-.1-.34-.1h-.072l-.062.006h-10.106C-6296.622-19072.123-6298.034-19072.123-6299.484-19072.113Zm5.773-2.145a.568.568,0,0,0-.048.43h2.791a.705.705,0,0,0-.048-.436c-.091-.109-.464-.109-.589-.109h-.014c-.158,0-.321,0-.483,0s-.326.01-.488.01c-.244,0-.445-.01-.632-.023h-.1A.479.479,0,0,0-6293.711-19074.258Z"
            transform="translate(6301.421 19075.813)"
            fill="#e05240"
          />
          <path
            id="Union_248"
            data-name="Union 248"
            d="M-6300.839-19066.432a.824.824,0,0,1-.823-.842c-.01-.459-.01-.93,0-1.383v-3.621c0-.928,0-1.881,0-2.824a.873.873,0,0,1,.512-.881.849.849,0,0,1,.335-.076.822.822,0,0,1,.795.637,1.918,1.918,0,0,1,.038.455q.007,3.439,0,6.869v.652a1.65,1.65,0,0,1-.009.211.831.831,0,0,1-.838.8Zm5.822,0a.786.786,0,0,1-.795-.613,1.65,1.65,0,0,1-.038-.422v-.047q0-3.275,0-6.559v-.977c0-.043,0-.1,0-.148a.838.838,0,0,1,.842-.848h0a.833.833,0,0,1,.828.875c0,.326,0,.652,0,.969,0,.133,0,.268,0,.4v6.338a1.427,1.427,0,0,1-.024.373.784.784,0,0,1-.766.656Zm-2.93-.006a.819.819,0,0,1-.823-.838c-.01-.463-.01-.934,0-1.383v-3.619c0-.93,0-1.887,0-2.83a.873.873,0,0,1,.512-.881.843.843,0,0,1,.335-.07.817.817,0,0,1,.795.631,1.72,1.72,0,0,1,.043.459v7.518c0,.066,0,.139,0,.215a.834.834,0,0,1-.842.8Z"
            transform="translate(6306.974 19084.285)"
            fill="#e05240"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
