import { ref, Ref, watch, reactive, getCurrentInstance, toRaw, nextTick } from 'vue';
import api from '@services/api';

import filterTable from '@models/segment/filterTable';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import { DateTime } from 'luxon';
import { dateColumn } from '@/views/components/table/dynamic-table/config/column-config';

import useConvertTextOperator from '@/views/components/table/base-filter-table/hooks/useConvertTextOperator';

interface Props {
  isOpenFilter: boolean;
  tableColumn: Array<BaseTable.TableColumn>;
  selectColumn: FilterTable.DisplayColumns;
  selectColumnFilter: any;
  selectColumnSorting: any;
  tableId?: number;
}

enum selectItem {
  SELECT_ALL = 2,
  SELECT_BY_ITEM = 1,
}

export default function useBaseFilterTable(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const { fetchFilterTableAndOrdering } = filterTable();
  const { openDefaultErrorModal } = useValidationModal();
  const { convertOperator, convertUnit } = useConvertTextOperator();

  const controllers: AbortController[] = [];
  const listGroupRef: Ref<HTMLElement | null> = ref(null);
  const AllGroupRef: Ref<HTMLElement | any> = ref(null);
  const optionOperator: Ref<Record<string, any>> = ref([]);
  const selectedColumn: Record<string, any> = ref();
  const selectedOperator: Record<string, any> = ref([]);
  const selectedUnit: Record<string, any> = ref([]);
  const timezone = DateTime.fromISO((localStorage.getItem('userInfo') as any)?.timezone || new Date().toISOString()).zoneName || 'UTC+7';
  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 20,
    totalRows: 0,
  });
  const dataColumnResult: Ref<Record<string, any>> = ref([]);
  const totalRecord: Ref<number> = ref(0);
  const filteredRecord: Ref<number> = ref(0);
  const totalPage: Ref<number> = ref(0);
  const isLoading: Ref<boolean> = ref(false);
  const isLoadingInResult: Ref<boolean> = ref(false);

  const dataSortColumn: Record<string, any> = reactive({
    Seq: 1,
    Key: '',
    Direction: '',
  });

  const isSelectedAll: Ref<boolean> = ref(true);
  const includeOrExcludeList: Ref<Record<string, any>[]> = ref([]);

  const dataFilterColumn: Ref<Record<string, any>> = ref([]);
  const ruleValue01: Ref<any> = ref('');
  const ruleValue02: Ref<any> = ref('');
  const selectedColumnKey: Ref<string> = ref('');
  const Exp_Comp_Label_Unit_Id: Ref<number> = ref(null!);
  const displayListData: Ref<Record<string, any>> = ref([]);

  const fetchColumnData = (column: Record<string, any>[]) => {
    optionOperator.value = column.filter((el: any) => {
      return el.type_id != 2;
    });
    pagination.currentPage = 1;
  };

  const selectedOperatorAndLoadOperator = (selected: Record<string, any>) => {
    dataColumnResult.value = [];
    selectedColumn.value = selected;
    selectedColumnKey.value = selected.key;
    const findKey = props.selectColumnFilter.find((el: any) => el.Key == selected.key);
    if (findKey != undefined && props.selectColumnFilter.length > 0) {
      if (findKey.operator_selected.value != undefined) {
        selectedOperator.value = props.selectColumn.operation_list.find((item: any) => item.value == findKey.operator_selected.value);
        const filterKey = findKey.filters.find((e: any) => e.Key == selected.key);
        ruleValue01.value = filterKey.R_Value;
        ruleValue02.value = filterKey.R2_Value;
        onFilterTable();
      } else {
        selectedOperator.value = [];
        ruleValue01.value = '';
        ruleValue02.value = '';
        onFilterTable();
      }
    } else {
      selectedOperator.value = [];
      ruleValue01.value = '';
      ruleValue02.value = '';

      onFilterTable();
    }
  };

  const onSelectOperetorList = (list: FilterTable.OperationList) => {
    ruleValue01.value = null;
    ruleValue02.value = null;
    selectedOperator.value = list;
    selectedUnit.value = convertUnit(list.unit_list, selectedColumn.value.operation_list);
  };

  const onSelectOperatorUnit = (unit: FilterTable.RuleUnit) => {
    Exp_Comp_Label_Unit_Id.value = unit.value;
  };

  const showContent = (tracking: string, operatorId: number) => {
    const typeDescription = String(selectedColumn.value.type_desc);
    const editType = selectedColumn.value.column_property.date_edittype;

    switch (tracking) {
      case 'rule-value-1-input-1':
        if (operatorId && operatorId !== 10 && operatorId !== 110 && operatorId !== 112 && typeDescription !== 'Date') {
          return true;
        }
        break;
      case 'rule-value-1-input-2':
        if ((operatorId == 51 || operatorId == 54 || operatorId == 57 || operatorId == 151) && typeDescription == 'Date') {
          return true;
        }
        break;
      case 'rule-value-1-datetime':
        if (
          operatorId &&
          operatorId !== 10 &&
          operatorId !== 110 &&
          operatorId !== 112 &&
          operatorId !== 51 &&
          operatorId !== 52 &&
          operatorId !== 53 &&
          operatorId !== 54 &&
          operatorId !== 55 &&
          operatorId !== 56 &&
          operatorId !== 151 &&
          editType == 1 &&
          typeDescription == 'Date'
        ) {
          return true;
        }
        break;
      case 'rule-value-1-date':
        if (
          operatorId &&
          operatorId !== 51 &&
          operatorId !== 52 &&
          operatorId !== 53 &&
          operatorId !== 54 &&
          operatorId !== 55 &&
          operatorId !== 56 &&
          operatorId !== 151 &&
          editType == 2 &&
          typeDescription == 'Date'
        ) {
          return true;
        }
        break;
      case 'rule-value-1-time':
        if (
          operatorId &&
          operatorId !== 51 &&
          operatorId !== 52 &&
          operatorId !== 53 &&
          operatorId !== 54 &&
          operatorId !== 151 &&
          editType == 3 &&
          typeDescription == 'Date'
        ) {
          return true;
        }
        break;
      case 'rule-value-2-input-1':
        if ((operatorId == 9 || operatorId == 109) && typeDescription !== 'Date') {
          return true;
        }
        break;
      case 'rule-value-2-datetime':
        if ((operatorId == 9 || operatorId == 109) && editType == 1 && typeDescription == 'Date') {
          return true;
        }
        break;
      case 'rule-value-2-date':
        if ((operatorId == 9 || operatorId == 109) && editType == 2 && typeDescription == 'Date') {
          return true;
        }
        break;
      case 'rule-data-unit':
        if ((operatorId == 55 || operatorId == 56) && typeDescription == 'Date') {
          return true;
        }
        break;
      case 'rule-unit':
        if ((operatorId == 51 || operatorId == 52 || operatorId == 151 || operatorId == 54 || operatorId == 57) && typeDescription == 'Date') {
          return true;
        }
        break;

      default: {
        return false;
      }
    }
  };

  const onSelectOrdering = (sort: Record<string, any>) => {
    dataSortColumn.Direction = sort.key;
    pagination.currentPage = 1;

    const mapDataSorting = {
      Seq: 1,
      Key: selectedColumn.value.key,
      Direction: dataSortColumn.Direction,
      status: sort.status,
    };

    vm?.$emit('on-selected-sorting', { sorting: toRaw(mapDataSorting) });
  };

  const resolveSelectedAll = (value: boolean) => {
    switch (value) {
      case true:
        return selectItem.SELECT_ALL;
      default:
        return selectItem.SELECT_BY_ITEM;
    }
  };

  const onScrolled = (e: Event | any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (dataColumnResult.value.length != filteredRecord.value) {
      if (!isLoadingInResult.value && scrollTop + clientHeight >= scrollHeight) {
        isLoadingInResult.value = true;
        pagination.currentPage++;
        onFilterTable(true);
      }
    }
  };

  const onSelectAllRow = (e: any) => {
    const inputElement = e.target as HTMLInputElement;
    isSelectedAll.value = inputElement.checked;
    includeOrExcludeList.value = [];
    forceCheckedAll(inputElement.checked);

    const nodeList = listGroupRef?.value?.childNodes as unknown as NodeListOf<HTMLElement>;
    const tableBody = nodeList[1] || null;
    const checked = AllGroupRef.value?.checked || false;

    if (tableBody) {
      tableBody.childNodes.forEach((node) => {
        if (node.nodeName == 'DIV') {
          const columnList = node.childNodes as NodeListOf<HTMLElement>;
          columnList.forEach((col) => {
            if (col.className == 'checkbox-form') {
              const checkbox = col as HTMLInputElement;
              checkbox.checked = checked;
            }
          });
        }
      });
    }
  };

  const forceCheckedAll = (value: boolean) => {
    const tds = document.querySelectorAll('.checked-list-container .form-group-checkbox input[type=checkbox].checkbox-form');
    nextTick(() => {
      tds.forEach((element: Element) => {
        (<HTMLInputElement>element).checked = value;
      });
    });
  };

  const onSelectRow = (e: Event, item: Record<string, any>) => {
    const itemTmp = toRaw(item[selectedColumn.value.key]);
    const inputElement = e.target as HTMLInputElement;

    if (isSelectedAll.value && inputElement.checked) {
      includeOrExcludeList.value = includeOrExcludeList.value.filter((el: Record<string, any>) => el !== itemTmp);
    } else if (isSelectedAll.value && !inputElement.checked) {
      includeOrExcludeList.value.push(itemTmp);
    } else if (!isSelectedAll.value && inputElement.checked) {
      includeOrExcludeList.value.push(itemTmp);
    } else if (!isSelectedAll.value && !inputElement.checked) {
      includeOrExcludeList.value = includeOrExcludeList.value.filter((el: Record<string, any>) => el !== itemTmp);
    }
  };

  const convertDateTime = (DateTime: any) => {
    const date = new Date(String(DateTime));
    const now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1);
    const fullDate = String(new Date(now_utc).toISOString().split('T')[0] + 'T00:00:00');
    return fullDate;
  };

  const onSetValue = (value: any) => {
    if (value) {
      const operatorId: number = selectedOperator.value.value;
      const typeDescription: string = selectedColumn.value.type_desc;

      if (typeDescription == 'Date') {
        if (operatorId == 51 || operatorId == 52 || operatorId == 151) {
          return value;
        } else {
          return convertDateTime(value);
        }
      } else {
        return value;
      }
    } else {
      return null;
    }
  };

  const onSetFilterTable = () => {
    const valueArr = dataFilterColumn.value.find((item: any) => {
      return item.Key == selectedColumn.value.key;
    });
    if (!selectedOperator.value.value) {
      ruleValue01.value = '';
      ruleValue02.value = '';
    }
    const seqCount = Number(props.selectColumnFilter.length);
    if (includeOrExcludeList.value.length == 0 && isSelectedAll.value == false) {
      isSelectedAll.value = true;
    }
    if (valueArr == undefined) {
      const resolveFilter = {
        Seq: seqCount,
        Key: selectedColumn.value.key,
        Exp_Comp_Op: selectedOperator.value.value,
        Exp_Comp_Label_Unit_Id: Exp_Comp_Label_Unit_Id.value,
        R_Value: onSetValue(ruleValue01.value),
        R2_Value: onSetValue(ruleValue02.value),
        Select_Flag: resolveSelectedAll(isSelectedAll.value),
        Datas: toRaw(includeOrExcludeList.value),
      };
      dataFilterColumn.value.push(resolveFilter);
    } else {
      const arr = Object.values(dataFilterColumn.value);
      const foundIndex = arr.findIndex((item: any) => {
        return item.Key == selectedColumn.value.key;
      });

      const resolveFilter = {
        Seq: foundIndex + 1,
        Key: selectedColumn.value.key,
        Exp_Comp_Op: selectedOperator.value.value,
        Exp_Comp_Label_Unit_Id: Exp_Comp_Label_Unit_Id.value,
        R_Value: onSetValue(ruleValue01.value),
        R2_Value: onSetValue(ruleValue02.value),
        Select_Flag: resolveSelectedAll(isSelectedAll.value),
        Datas: toRaw(includeOrExcludeList.value),
      };
      dataFilterColumn.value[foundIndex] = resolveFilter;
      onFilterTable();
    }
  };

  const onApplyFilter = () => {
    const preview_payload: any = {
      label: selectedColumn.value.key,
      column_alias: selectedColumn.value.column_alias,
      Key: selectedColumn.value.key,
      column_selected: {
        column_id: toRaw(selectedColumn.value.column_id),
        date_edittype: toRaw(selectedColumn.value.column_property.date_edittype),
        date_format: toRaw(selectedColumn.value.column_property.date_format),
        key: toRaw(selectedColumn.value.key),
        type_desc: toRaw(selectedColumn.value.type_desc),
      },
      filters: dataFilterColumn.value,
      operator_selected: toRaw(selectedOperator.value),
      Select_Flag: isSelectedAll.value,
      Datas: toRaw(includeOrExcludeList.value),
    };
    displayListData.value = preview_payload;
    vm?.$emit('on-data-filter', { preview: toRaw(displayListData.value) });
    vm?.$emit('force-close-filter', true);
    onSetFilterTable();
  };

  const onClearFilter = () => {
    vm?.$emit('on-clear-data-filter', { key: 'clear' });
  };

  const onConvertItem = (item: string) => {
    const userData = JSON.parse(localStorage.getItem('userInfo') || '{}');
    if (item == 'null' || item == '' || !item.replace(/\s/g, '').length) {
      return 'blank';
    } else {
      if (selectedColumn.value.type_desc == 'Date') {
        return DateTime.fromISO(item, { zone: userData.time_zone || dateColumn.time_zone }).toFormat(
          selectedColumn.value.column_property.date_format || dateColumn.date_format,
        );
      } else {
        return item;
      }
    }
  };

  const convertClassInList = (name: string) => {
    const value = String(name).trim();
    let item = '';
    if (selectedColumn.value.type_desc != 'Date' && value == null) {
      return 'selected-null';
    } else if ((selectedColumn.value.type_desc != 'Date' && value == undefined) || value == '') {
      return 'selected-undefined';
    } else if (selectedColumn.value.type_desc == 'Date') {
      if (value == null) {
        return 'selected-null';
      } else {
        const data = onConvertItem(value);
        return `selected-${String(data.replace(/[^0-9\.]+/g, '').trim())}`;
      }
    } else {
      item = value.split(' ').join('').trim();
      return `selected-${item}`;
    }
  };

  const onFilterTable = (staus?: boolean) => {
    if (!staus) {
      isLoading.value = true;
    }

    // controllers.push(new AbortController());
    // for (let i = 0; i < controllers.length - 1; i++) {
    //   controllers[i].abort();
    // }

    fetchFilterTableAndOrdering.payload.TableId = props.tableId;
    fetchFilterTableAndOrdering.payload.ColumnId = selectedColumn.value.column_id;
    fetchFilterTableAndOrdering.payload.Key_Column = selectedColumn.value.key;
    fetchFilterTableAndOrdering.payload.Page = staus ? pagination.currentPage : 1;
    fetchFilterTableAndOrdering.payload.Limit = pagination.perPage;
    // fetchFilterTableAndOrdering.signal = controllers[controllers.length - 1].signal;
    fetchFilterTableAndOrdering.payload.Filters = dataFilterColumn.value;

    api
      .apiRequest(fetchFilterTableAndOrdering)
      .then((response) => {
        if (!staus) {
          dataColumnResult.value = response.data.datalist;
        } else {
          dataColumnResult.value.push(...response.data.datalist);
        }

        totalRecord.value = response.data.total_record;
        filteredRecord.value = response.data.filtered_record;
        totalPage.value = response.data.total_page;

        isLoading.value = false;
        isLoadingInResult.value = false;
      })
      .catch((error) => {
        openDefaultErrorModal(error);
        isLoading.value = false;
        // if (!(error instanceof TypeError)) {
        //   openDefaultErrorModal(error);
        //   isLoading.value = false;
        // }
      });
  };

  watch(
    () => props.isOpenFilter,
    () => {
      if (props.isOpenFilter) {
        isSelectedAll.value = true;
        includeOrExcludeList.value = [];
        fetchColumnData(props.tableColumn);

        if (props.selectColumnFilter.length > 0) {
          const filterStorageLength = props.selectColumnFilter.length;
          const filterStorage = props.selectColumnFilter[filterStorageLength - 1];
          dataFilterColumn.value = filterStorage.filters;
          selectedOperatorAndLoadOperator(props.selectColumn);
        } else {
          dataFilterColumn.value = [];
          ruleValue01.value = '';
          ruleValue02.value = '';
          selectedOperator.value = [];
          isSelectedAll.value = true;
          includeOrExcludeList.value = [];
          selectedOperatorAndLoadOperator(props.selectColumn);
        }
      }
    },
  );

  watch(
    () => props.selectColumnFilter,
    (newObj, oldObj) => {
      // clear
      if (newObj.length == 0 && oldObj.length != '') {
        ruleValue01.value = '';
        ruleValue02.value = '';
        selectedOperator.value = [];
        isSelectedAll.value = true;
        includeOrExcludeList.value = [];
        dataFilterColumn.value = [];
        onFilterTable();
      }
      if (newObj.length < oldObj.length) {
        onFilterTable();
      }
    },
    { deep: true },
  );

  watch(
    () => isLoadingInResult.value,
    () => {
      setTimeout(() => {
        const btnApply = document.querySelectorAll('.btn-action-container .btn.btn-apply');
        if (!isSelectedAll.value && includeOrExcludeList.value.length == 0) {
          btnApply.forEach((element: Element) => {
            (<HTMLInputElement>element).disabled = true;
          });
        } else {
          btnApply.forEach((element: Element) => {
            (<HTMLInputElement>element).disabled = false;
          });
        }
      }, 10);
    },
    { deep: true },
  );

  watch(
    () => [isSelectedAll.value, includeOrExcludeList.value],
    () => {
      setTimeout(() => {
        const btnApply = document.querySelectorAll('.btn-action-container .btn.btn-apply');

        if (!isSelectedAll.value && includeOrExcludeList.value.length == 0) {
          btnApply.forEach((element: Element) => {
            (<HTMLInputElement>element).disabled = true;
          });
        } else {
          btnApply.forEach((element: Element) => {
            (<HTMLInputElement>element).disabled = false;
          });
        }
      }, 0);
    },
    { deep: true },
  );

  return {
    optionOperator,
    selectedOperator,
    selectedColumn,
    timezone,
    dataColumnResult,
    isLoading,
    ruleValue01,
    ruleValue02,
    listGroupRef,
    AllGroupRef,
    displayListData,
    isLoadingInResult,
    isSelectedAll,
    selectedUnit,
    onSelectOperatorUnit,
    onClearFilter,
    convertClassInList,
    onConvertItem,
    onScrolled,
    onSelectAllRow,
    onSelectRow,
    onApplyFilter,
    onSetFilterTable,
    onSelectOrdering,
    onFilterTable,
    selectedOperatorAndLoadOperator,
    onSelectOperetorList,
    showContent,
  };
}
