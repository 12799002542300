<template>
  <svg
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    aria-labelledby="okIconTitle"
    stroke="#ffffff"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="miter"
    fill="none"
    color="#ffffff"
  >
    <polyline points="4 13 9 18 20 7" />
  </svg>
</template>
