<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="106.844" height="106.845" viewBox="0 0 106.844 106.845">
    <g id="Group_31375" data-name="Group 31375" transform="translate(-5359.58 -2893.594)">
      <circle
        id="Ellipse_420"
        data-name="Ellipse 420"
        cx="51.922"
        cy="51.922"
        r="51.922"
        transform="translate(5361.08 2895.094)"
        fill="#fff"
        stroke="#b7b7b7"
        stroke-width="3"
      />
      <g id="Group_2695" data-name="Group 2695" transform="translate(5401.672 2919.86)">
        <g id="Group_2694" data-name="Group 2694">
          <g id="Group_2693" data-name="Group 2693" transform="translate(5.947)">
            <path
              id="Path_918"
              data-name="Path 918"
              d="M5419.834,2923.651a5.1,5.1,0,0,1,3.6,1.387,4.5,4.5,0,0,1,0,6.682,5.1,5.1,0,0,1-3.6,1.386,5.157,5.157,0,0,1-3.583-1.366,4.33,4.33,0,0,1-1.509-3.341,4.4,4.4,0,0,1,1.509-3.362A5.119,5.119,0,0,1,5419.834,2923.651Z"
              transform="translate(-5414.742 -2923.651)"
            />
          </g>
          <path
            id="Path_919"
            data-name="Path 919"
            d="M5422.752,2939.712l.856.546q-.494,5.5-.5,13.992v9.5q0,.925.18,5.946a50.877,50.877,0,0,0,.384,5.862,2.419,2.419,0,0,0,.608,1.219,1.916,1.916,0,0,0,.992.484q.585.105,4.865.357l.4.336v1.765l-.359.377q-5.632-.335-10.859-.336-5.178,0-10.812.336l-.405-.377v-1.765l.405-.336q4.369-.253,4.934-.378a2.1,2.1,0,0,0,.991-.526,2.176,2.176,0,0,0,.586-1.219q.158-.818.361-5.421t.2-6.954v-8.278q0-1.68-.182-4.58t-.291-3.573a1.31,1.31,0,0,0-.7-.986,5.689,5.689,0,0,0-2.344-.316l-3.783-.042-.406-.337V2943.2l.361-.336A62.255,62.255,0,0,0,5422.752,2939.712Z"
            transform="translate(-5407.885 -2925.783)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
