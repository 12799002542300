<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21.524" height="17.234" viewBox="0 0 21.524 17.234">
    <path
      id="Path_28636"
      data-name="Path 28636"
      d="M-6185.672-5577.393a11.364,11.364,0,0,1,2.768-3.089,14.741,14.741,0,0,1,3.011-1.8,22.074,22.074,0,0,1,5.111-1.546v-2.543a.863.863,0,0,1,1.378-.693l7.391,5.5a.863.863,0,0,1,0,1.387l-7.391,5.5a.863.863,0,0,1-1.378-.693v-2.108a16.578,16.578,0,0,0-3.808-.211,10.384,10.384,0,0,0-2.712.536,8.14,8.14,0,0,0-2.456,1.352,9.5,9.5,0,0,0-3.259,5.8A10.76,10.76,0,0,1-6185.672-5577.393Z"
      transform="translate(6187.188 5587.233)"
    />
  </svg>
</template>
