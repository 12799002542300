import { computed, isRef, Ref, ref, unref, watch } from 'vue';

// TODO: add support for validator states (e.g. vee-validate ?)
// TODO: implement more input states (e.g. touch, valid, etc; reverse these from vee-validate)

/**
 * Inputable composed function, for lazy-update use with the input element
 * (e.g. pre-check the input value before real update occur)
 * @param value
 * @param onInput
 */
export function useInputable(value: Ref<string | null> | string | null, onInput: (value: string | null, reset: () => void) => void) {
  // internal input model tracking
  const inputValue = ref<string | null>(unref(value));
  // input states
  const inputDirty = ref(false); // input value is changed from original
  const inputFocusing = ref(false);

  // watch for dirty state, on input value changes
  watch(inputValue, (newValue) => (inputDirty.value = newValue !== unref(value)));

  // if value is reactive, watch on date model changes
  if (isRef(value)) {
    watch(value, () => {
      // update input value if input is not dirty
      if (!inputDirty.value) {
        resetInputValue();
      }
    });
  }

  // reset input value to original date value
  const resetInputValue = () => (inputValue.value = unref(value));

  // update input value (e.g. submit or blur the input)
  const updateInputValue = () => {
    onInput(inputValue.value, resetInputValue);
    // clear states
    inputDirty.value = false;
  };

  // event listeners
  const onInputFocus = () => (inputFocusing.value = true);
  const onInputBlur = () => {
    inputFocusing.value = false;
    resetInputValue();
  };
  const onInputEnter = () => updateInputValue();

  // return
  return {
    value: computed(() => inputValue.value),
    updateValue: (value: string | null) => (inputValue.value = value),
    resetInputValue,
    onInputFocus,
    onInputBlur,
    onInputEnter,
  };
}
