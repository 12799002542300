import { ref, Ref, getCurrentInstance, onBeforeMount, reactive } from 'vue';

import api from '@services/api';

// models
import customerInsightModel from '@models/customer-insight';

import useValidationModal from '@views/components/modal/hooks/useValidationModal';

interface DropdownSelect {
  chartDataType: string[];
  sourceTypeDescription: string[];
  chartTypeName: string[];
}

export default function useAddBiModal() {
  const vm = getCurrentInstance()?.proxy;

  const loading: Ref<boolean> = ref(false);

  const { fetchChartSetListModel } = customerInsightModel();

  const { openErrorModal } = useValidationModal();

  const chartSet: Ref<INS.INSChartSet.Item[]> = ref([]);
  const chartSetFiltered: Ref<INS.INSChartSet.Item[]> = ref([]);

  const chartSetSelectedList: Ref<INS.INSChartSet.Item[]> = ref([]);
  // TODO: implement i18n text 'All' (optional)
  const dropdownSelect: DropdownSelect = reactive({
    chartDataType: ['All'],
    sourceTypeDescription: ['All'],
    chartTypeName: ['All'],
  });

  const searchKeyword: Ref<string> = ref('');

  function mapChartSetList(input: INS.INSChartSet.Request.Response.Result): INS.INSChartSet.Item[] {
    return <INS.INSChartSet.Item[]>input.data_list.map((el) => ({
      chartSetId: el.chartsetid,
      chartId: el.chartid,
      chartName: el.chart_name,
      tooltip: el.chart_desc,
      sourceType: el.chart_source_type,
      sourceTypeDescription: el.chart_source_type_desc,
      disabled: !el.is_available,
      chartTypeId: el.chart_type,
      chartTypeName: el.chart_type_name,
      chartDataType: el.chart_data_type,
      chartImageTypeName: el.chart_image_type_name,
    }));
  }

  function fetchChartSetList(): Promise<void> {
    loading.value = true;
    return api
      .apiRequest(fetchChartSetListModel)
      .then((response) => {
        chartSet.value = chartSetFiltered.value = mapChartSetList(response.data.result as INS.INSChartSet.Request.Response.Result);
        return Promise.resolve();
      })
      .catch((error) => {
        openErrorModal(error.data.error.locale['th-th'].title as string, error.data.error.locale['th-th'].message as string);
        return Promise.reject();
      })
      .finally(() => {
        loading.value = false;
      });
  }

  const onSelectedChartSetItem = (selectedChart: INS.INSChartSet.Item) => {
    const isSelected: number = chartSetSelectedList.value.indexOf(selectedChart);
    if (isSelected != -1) {
      chartSetSelectedList.value.splice(isSelected, 1);
    } else {
      chartSetSelectedList.value.push(selectedChart);
    }
  };

  const onFilterChange = () => {
    chartSetFiltered.value = chartSet.value;

    if (!dropdownSelect.chartDataType.includes('All')) {
      chartSetFiltered.value = chartSetFiltered.value.filter((item) => dropdownSelect.chartDataType.includes(item.chartDataType[0]));
    }

    if (!dropdownSelect.sourceTypeDescription.includes('All')) {
      chartSetFiltered.value = chartSetFiltered.value.filter((item) => dropdownSelect.sourceTypeDescription.includes(item.sourceTypeDescription));
    }

    if (!dropdownSelect.chartTypeName.includes('All')) {
      chartSetFiltered.value = chartSetFiltered.value.filter((item) => dropdownSelect.chartTypeName.includes(item.chartTypeName));
    }

    if (searchKeyword.value) {
      chartSetFiltered.value = chartSetFiltered.value.filter((item) => item.chartName.indexOf(searchKeyword.value) > -1);
    }

    chartSetSelectedList.value = [];
  };

  const onAddNewChart = () => {
    vm?.$emit('add-new-chart', chartSetSelectedList.value);
    loading.value = true;
  };

  const onCloseModal = () => {
    vm?.$emit('close-modal');
  };

  onBeforeMount(async () => {
    await fetchChartSetList();
  });

  return {
    loading,
    chartSetFiltered,
    chartSetSelectedList,
    dropdownSelect,
    onCloseModal,
    onSelectedChartSetItem,
    onAddNewChart,
    onFilterChange,
    searchKeyword,
  };
}
