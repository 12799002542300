interface payload {
  // Seq: number;
  // Key: string;
  // Exp_Comp_Op: number;
  // R_Value: string;
}
export default function () {
  function filterDisplayObj(dataSourceDisplay: Array<BaseTable.TableColumn | DynamicTable.Column>, value: string): payload[] {
    const isInclude = dataSourceDisplay.filter(
      (element) => (<DynamicTable.Column>element).is_filter == true || (<BaseTable.TableColumn>element).isFilter == true,
    );
    const payloads: payload[] = [];

    // isInclude.forEach((element, index) => {
    //   const obj: payload = {
    // Seq: index + 1,
    // Key: element.key,
    // Exp_Comp_Op: 6,
    // R_Value: value,
    // };
    //   payloads.push(obj);
    // });
    return payloads;
  }
  return { filterDisplayObj };
}
