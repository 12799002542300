<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19.547" height="20.736" viewBox="0 0 19.547 20.736">
    <path id="Path_28" data-name="Path 28"
      d="M7.073,20.736a1.235,1.235,0,0,1-1.234-1.23V15.482a.346.346,0,0,0-.2-.316L1.154,13.151A1.957,1.957,0,0,1,.948,9.69L16.579.285A1.929,1.929,0,0,1,17.585,0a1.979,1.979,0,0,1,1.478.672A1.919,1.919,0,0,1,19.531,2.2L17.65,17.784a1.96,1.96,0,0,1-1.943,1.724h0a1.947,1.947,0,0,1-.8-.174l-3.4-1.527a.346.346,0,0,0-.356.044L7.825,20.47a1.207,1.207,0,0,1-.752.266m.635-4.6a.346.346,0,0,0-.346.346v2.441a.346.346,0,0,0,.56.272L9.9,17.637a.346.346,0,0,0-.072-.587L7.85,16.162a.346.346,0,0,0-.142-.03M17.292,4.814a.345.345,0,0,0-.245.1L7.942,14.021a.346.346,0,0,0,.1.56l7.484,3.365a.426.426,0,0,0,.178.04.437.437,0,0,0,.432-.385l1.5-12.4a.346.346,0,0,0-.343-.387m-.523-2.809a.344.344,0,0,0-.178.05L1.732,10.994a.435.435,0,0,0,.046.769l3.995,1.8a.346.346,0,0,0,.387-.071L16.706,2.942l.014-.014.308-.346a.346.346,0,0,0-.259-.576"
      transform="translate(0.001)"  />
  </svg>
</template>

<style lang="scss" scoped>
@import '@assets/styles/base/button.scss';
</style>