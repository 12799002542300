import { DateTime, Zone } from 'luxon';
import { getZone } from './utilities';

export function checkTimezone(timezone: Zone | string | number | null) {
  return getZone(timezone).isValid;
}

export function createDateTime(init: Date | string | null, timezone: Zone | string | number | null) {
  // prepare luxon object,
  // if init is Date (native object), parse to DateTime (luxon object),
  // or if init is string, parse to DateTime using ISO format,
  // or if init value is not provided, use current time
  let luxon;
  if (!init) {
    luxon = DateTime.now();
  } else if (typeof init === 'string') {
    luxon = DateTime.fromISO(init);
  } else {
    luxon = DateTime.fromJSDate(init);
  }

  // set timezone and return object instance
  return luxon.setZone(getZone(timezone));
}
