import { ref, Ref, watch, onBeforeMount, getCurrentInstance } from 'vue';
import apiService from '@/services/api';
import router from '@/router';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import campaignModel from '@/models/communication/campaign';

export default function useLineOATab() {
  const vm = getCurrentInstance()?.proxy;
  const { createCampaignModel } = campaignModel();
  const { openDefaultErrorModal } = useValidationModal();

  const isLoading: Ref<boolean> = ref(false);
  const activeTab: Ref<number> = ref(0);
  const isSelectMessageTypeModalOpen: Ref<boolean> = ref(false);
  const createType: Ref<string> = ref('');
  const createButtonOptions: Communication.Channel.Component.CreateButtonDropDown[] = [
    {
      label: 'Campaign',
      key: 'campaign',
    },
    {
      label: 'Template',
      key: 'template',
    },
  ];

  const createCampaign = (templateId?: number) => {
    createCampaignModel.payload.Channel = 3;
    createCampaignModel.payload.TemplateId = templateId || null;
    createCampaignModel.payload.SubType = null;
    isLoading.value = true;

    apiService
      .apiRequest(createCampaignModel)
      .then((response) => {
        const campaignId = response.data.campaignid;

        return router.push({ name: 'line-oa-campaign-create', query: { campaign: campaignId } });
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => (isLoading.value = false));
  };

  const onSelectTemplate = (template: Communication.Template.Template) => {
    createCampaign(template.template_id);
  };

  function onClickCreateButton() {
    const dropdownContent = document.getElementById('dropdown-content') as HTMLDivElement;
    dropdownContent.classList.toggle('show');
  }

  window.onclick = function (event: any) {
    if (!event.target.matches('.create-campaign-button') && !event.target.matches('.button-icon') && !event.target.matches('.button-text')) {
      const dropdowns = document.getElementsByClassName('dropdown-content');
      for (let i = 0; i < dropdowns.length; i++) {
        const openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  };

  function createTemplate(messageType?: number) {
    router.push({
      name: 'line-oa-template-create',
      query: { category: router.currentRoute.value.query.category || 0, messageType: messageType },
    });
  }

  function resolveOptionAction(key: string) {
    createType.value = key;
    if (createType.value == 'template') {
      isSelectMessageTypeModalOpen.value = true;
    } else {
      createCampaign();
    }
  }

  function resolveActionCreate(messageType: number) {
    createTemplate(messageType);
  }

  function closeSelectMessageTypeModal() {
    isSelectMessageTypeModalOpen.value = false;
  }

  watch(activeTab, () => {
    router.replace({ path: router.currentRoute.value.path, query: { ...router.currentRoute.value.query, tab: activeTab.value } });
    vm?.$emit('active-tab', activeTab.value);
  });

  onBeforeMount(() => {
    setTimeout(() => {
      activeTab.value = Number(router.currentRoute.value.query.tab) || 0;
      router.replace({ path: router.currentRoute.value.path, query: { ...router.currentRoute.value.query, tab: activeTab.value } });
      vm?.$emit('active-tab', activeTab.value);
    }, 0);
  });

  return {
    isLoading,
    activeTab,
    createButtonOptions,
    isSelectMessageTypeModalOpen,
    resolveActionCreate,
    closeSelectMessageTypeModal,
    onSelectTemplate,
    onClickCreateButton,
    resolveOptionAction,
  };
}
