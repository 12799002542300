import { MKA } from '@/types/modules/mka/mka';

export interface MKA_STATUS {
  ALL: { id: 0; label: 'mka.status_all' };
  DRAFT: { id: 1; label: 'mka.status_draft' };
  PREPARING: { id: 2; label: 'mka.status_preparing' };
  ACTIVE: { id: 3; label: 'mka.status_active' };
  PAUSE: { id: 4; label: 'mka.status_pause' };
  COMPLETE: { id: 9; label: 'mka.status_complete' };
}

export const MKA_CAMPAIGN_HEADER: { label: string; key: string; sortable: boolean }[] = [
  {
    label: 'mka.campaign_name',
    key: 'name',
    sortable: true,
  },
  {
    label: 'mka.start_date',
    key: 'start_dt',
    sortable: true,
  },
  {
    label: 'mka.end_date',
    key: 'end_dt',
    sortable: true,
  },
  {
    label: 'mka.last_update',
    key: 'last_update',
    sortable: true,
  },
  {
    label: 'mka.update_by',
    key: 'update_by',
    sortable: false,
  },
  {
    label: 'mka.status',
    key: 'status',
    sortable: true,
  },
  {
    label: '',
    key: '_Action_',
    sortable: false,
  },
];

export const STATUS_RENDER_CONFIG: { id: number; label: string }[] = [
  { id: 1, label: 'mka.status_draft' },
  { id: 2, label: 'mka.status_preparing' },
  { id: 3, label: 'mka.status_active' },
  { id: 4, label: 'mka.status_pause' },
  { id: 9, label: 'mka.status_complete' },
];

export const DROPDOWN_SELECT_STATUS: { id: number; label: string }[] = [
  { label: 'mka.status_all', id: 0 },
  { label: 'mka.status_draft', id: 1 },
  // { label: 'mka.status_preparing', id: 2 },
  { label: 'mka.status_active', id: 3 },
  { label: 'mka.status_pause', id: 4 },
  { label: 'mka.status_complete', id: 9 },
];

export const MKA_TABLE_CONFIG: any = {
  pageNumber: 1,
  pageSize: [10, 50, 100],
  rowsPerPage: 10,
  totalPages: null,
  totalRows: null,
};

export const MKA_ID = {
  Merge: { Wait_Mode: { Wait_All: 1, Wait_Count: 2 }, Flow_Mode: { None: 1, High_Priority: 11 }, Limit_Mode: { Limit: 1, Unlimited: 2 } },
};

export const JOURNEY_ID: MKA.Journey.Status.JourneyId = {
  All: 0,
  Draft: 1,
  Preparing: 2,
  Publish: 3,
  Pause: 4,
  Archived: 9,
};

export const FIRST_PAGE = 1;

export const CATEGORY_NODE = {
  SOURCE: 1,
  FLOW: 2,
  ACTIONS: 3,
};

export const CHANEL_COMMU_CREATE = {
  SMS: 'sms',
  LINE_OA: 'line-oa',
  EMAIL: 'email',
};
