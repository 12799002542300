import { onBeforeMount, ref, watch, reactive, Ref, onMounted, onBeforeUpdate } from 'vue';

import router from '@/router';
import api from '@services/api';

import dataConnectModel from '@models/dataConnect/dataConnect';
import uploadIcon from "@assets/images/modules/data-connect/upload.vue";
import tableIcon from "@assets/images/modules/data-connect/table.vue";
import keyIcon from "@assets/images/modules/data-connect/key.vue";
import mappingIcon from "@assets/images/modules/data-connect/mapping.vue";
import checkIcon from "@assets/images/modules/data-connect/check.vue";

import XLSX from '@libs/xlsx/xlsx.full.min.js';

export default function useEditTemplateDrawer(props:any) {
  const { editDataSourceExcelCustomDataTemplateModel } = dataConnectModel();

  const selectionType:any = ref('checkbox')
  const primaryKeyDropdownOption:any = ref([])
  const primaryKeyList:any = ref([])
  const templateType:any = ref(null)
  const templateName:any = ref(null)
  const templateDescription:any = ref(null)
  const columnPreviewList:any = ref(null)
  const columnList:any = ref([])

  const newDataConnect:any = () => {router.push({ path: `/dataConnector/addDataSource` })}


  const resetDrawer = () => {
    templateType.value = null;
    templateName.value = null;
    templateDescription.value = null;
  };

  const onCloseDrawer = () => {
    resetDrawer();
    return props.onClose ? props.onClose() : () => {};
  };

  const setTemplateName = (value:any) => {
    templateName.value = value
  }

  const setTemplateDescription = (value:any) => {
    templateDescription.value = value
  }

  const excelHeaderPlaceHolderReference = (headerNum:any) => {
    let dataFillTableHeader = 'abcdefghijklmnopqrstuvwxyz';
    return dataFillTableHeader.substring(
      Math.min(headerNum, dataFillTableHeader.length),
      Math.min(headerNum + 1, dataFillTableHeader.length + 1)
    );
  };

  const onHoverTableIcon = (index:any) => {
    const displayList = [...columnList.value[index].preview];
    displayList.splice(0, 1);

    let titleDisplay:any =columnList.value[index].preview[0] != ''?columnList.value[index].preview[0]:'Column ' + (excelHeaderPlaceHolderReference(index)).toUpperCase()

    columnPreviewList.value = {
      title: titleDisplay,
      list: displayList,
    };
  };

  const onStopPreview = () => {
    columnPreviewList.value = (null);
  };

  const onRenameColumn = (event:any,index:any) => {
    const temptColumnList = [...columnList.value];

    temptColumnList[index].rename = event.target.value;
    columnList.value = temptColumnList;
  };

  const onEditCustomTemplate = async () => {

    const titleObj:any = document.querySelector('.cdp-excel-template-title.dc-edit')
    const descriptionObj:any = document.querySelector('.cdp-excel-template-description.dc-edit')

    const params = {
      Dataset_Id: props.template.template?.id,
      Dataset_Name: titleObj.value || '',
      Description: descriptionObj.value || '',
      List_Column: [],
    };

    params.List_Column = columnList.value.map((mappedColumn:any) => ({
      Source_Name: mappedColumn.column,
      Source_Display_Name: mappedColumn.rename || '',
      Dest_Name: mappedColumn.mapping.value,
    }));

    let model:any = editDataSourceExcelCustomDataTemplateModel
    model.payload = params
    api.apiRequest(model).then((response) => {
      props.fatchDataSet();
      onCloseDrawer();
    });
  };

  const resolveTemplateType = (typeId:any) => {
    const templateTypeData:any = props.templateTypeList.find(
      (template:any) => template.id == typeId
    );
    
    return templateTypeData ? templateTypeData : {};
  };

  onBeforeUpdate(
    () => {
      if(props.template != null){
        const template = props.template.template;
        if (template && props.templateTypeList) {
          templateName.value = template.dataset_name;
          templateType.value = resolveTemplateType(template.dataSetId);
          templateDescription.value = template.description;
          if (template.customData?.mappingColumns) {
            columnList.value = JSON.parse(JSON.stringify(template.customData?.mappingColumns))
          }
        }
      }
    }
  )
  
  onMounted(() => {
 
  });

  return {
    onCloseDrawer,
    templateType,
    templateName,
    templateDescription,
    setTemplateName,
    setTemplateDescription,
    onHoverTableIcon,
    onStopPreview,
    columnPreviewList,
    onRenameColumn,
    onEditCustomTemplate,
    columnList
  };
}
