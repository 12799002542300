import { nextTick, ref, Ref, watch } from 'vue';

interface Props {
  columns: Array<BaseTable.TableColumn>;
  data?: Array<Record<string, any>>;
  hover?: boolean;
  checkbox?: boolean;
  responsive?: boolean;
  stickyHeader?: boolean;
  sortableRow?: boolean;
  sortableCol?: boolean;
  currentPage?: number;
  perPages?: number;
  rowSelectVariant?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'dark';
  busy?: boolean;
  selectAll?: boolean;
  sortAndFilter?: boolean;
  tableId?: number;
  selectColumnFilter?: any;
  selectColumnSorting?: any;
}

export default function useDynamicFilter(props: Props) {
  const isOpenFilter: Ref<boolean> = ref(false);
  const selectTableColumn: Ref<FilterTable.DisplayColumns> = ref(null!);

  const keySorting: Ref<string> = ref('');
  const directionSorting: Ref<string> = ref('');

  const onTableColumnProperty = (e: Event, col: any) => {
    isOpenFilter.value = true;
    selectTableColumn.value = col;
  };

  const onCloseTableFilter = () => {
    isOpenFilter.value = false;
  };

  const onForceCloseTableFilter = (type: boolean) => {
    if (type) {
      isOpenFilter.value = false;
    }
  };

  const onForceCloseTableSort = (val: Record<string, any>) => {
    if (val.status) {
      isOpenFilter.value = false;
      keySorting.value = val.selected;
    }
  };

  watch(
    () => props.selectColumnSorting,
    (newSorting) => {
      if (newSorting.Key && newSorting.Direction != '' && newSorting.Direction != undefined) {
        keySorting.value = newSorting.Key;
      }
      if (newSorting.Key == keySorting.value && !newSorting.status) {
        keySorting.value = '';
      }
    },
  );

  return {
    selectTableColumn,
    isOpenFilter,
    keySorting,
    directionSorting,
    onTableColumnProperty,
    onCloseTableFilter,
    onForceCloseTableFilter,
    onForceCloseTableSort,
  };
}
