<template>
  <svg id="Group_51451" data-name="Group 51451" xmlns="http://www.w3.org/2000/svg" width="250.233" height="250" viewBox="0 0 250.233 250">
    <g id="Rectangle_17816" data-name="Rectangle 17816" transform="translate(0.233)" fill="rgba(96,106,108,0.02)" stroke="#606a6c" stroke-width="1">
      <rect width="250" height="250" rx="20" stroke="none" />
      <rect x="0.5" y="0.5" width="249" height="249" rx="19.5" fill="none" />
    </g>
    <g id="Group_51444" data-name="Group 51444" transform="translate(0 84.5)">
      <line id="Line_7" data-name="Line 7" x1="250" fill="none" stroke="#606a6c" stroke-width="1" />
      <line id="Line_8" data-name="Line 8" x1="250" transform="translate(0 83)" fill="none" stroke="#606a6c" stroke-width="1" />
    </g>
  </svg>
</template>
