import ImageColumn from '@views/components/chart/ColumnImage.vue';
import TextColumn from '@views/components/chart/ColumnText.vue';

enum ColumnType {
  None,
  Text,
  Image,
}

export function renderSlotName(key: string) {
  return `cell(${key})`;
}

export function resolveComponent(columnType: ChartTable.ColumnType) {
  switch (columnType) {
    case ColumnType.Text:
      return TextColumn;
    case ColumnType.Image:
      return ImageColumn;
    default:
      return TextColumn;
  }
}
