<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="263.512" height="222.771" viewBox="0 0 263.512 222.771">
    <g id="Group_37025" data-name="Group 37025" transform="translate(-820.559 -461.177)">
      <text
        id="No_item"
        data-name="No item"
        transform="translate(910 674.947)"
        fill="#cbcbcb"
        font-size="22"
        font-family="Prompt, sans-serif"
        letter-spacing="-0.009em"
      >
        <tspan x="0" y="0">No item</tspan>
      </text>
      <g id="Group_31245" data-name="Group 31245" transform="translate(820.559 461.177)">
        <g id="Group_1338" data-name="Group 1338" transform="translate(0 0)">
          <path
            id="Path_511"
            data-name="Path 511"
            d="M241.51,114.323c0,9.671-52.059,17.51-116.279,17.51S8.951,123.994,8.951,114.323s52.061-17.51,116.279-17.51,116.279,7.839,116.279,17.51"
            transform="translate(4.379 47.36)"
            fill="#e2e2e2"
          />
          <path
            id="Path_512"
            data-name="Path 512"
            d="M205.034,135.963H28.214V40.544a8.628,8.628,0,0,1,8.628-8.628H196.405a8.628,8.628,0,0,1,8.628,8.628Z"
            transform="translate(13.802 15.613)"
            fill="#fff"
          />
          <path
            id="Path_513"
            data-name="Path 513"
            d="M134.833,100.517h0a1.864,1.864,0,0,1-1.864,1.864H108.76a1.864,1.864,0,0,1-1.864-1.864,1.864,1.864,0,0,0-1.864-1.864H21.409a1.864,1.864,0,0,0-1.864,1.864v.548A11.936,11.936,0,0,0,31.481,113H210.247a11.936,11.936,0,0,0,11.936-11.936v-.548a1.864,1.864,0,0,0-1.864-1.864H136.7a1.864,1.864,0,0,0-1.864,1.864"
            transform="translate(9.561 48.261)"
            fill="#f4f4f4"
          />
          <line
            id="Line_28"
            data-name="Line 28"
            y2="48.156"
            transform="translate(218.836 98.757)"
            fill="none"
            stroke="#dadada"
            stroke-miterlimit="10"
            stroke-width="1"
          />
          <path
            id="Path_514"
            data-name="Path 514"
            d="M77.662,31.916h93.11a10.071,10.071,0,0,1,10.071,10.07"
            transform="translate(37.992 15.613)"
            fill="none"
            stroke="#dadada"
            stroke-miterlimit="10"
            stroke-width="1"
          />
          <path
            id="Path_515"
            data-name="Path 515"
            d="M28.214,131.3V41.987a10.07,10.07,0,0,1,10.07-10.07H51.529"
            transform="translate(13.802 15.614)"
            fill="none"
            stroke="#dadada"
            stroke-miterlimit="10"
            stroke-width="1"
          />
          <path
            id="Path_516"
            data-name="Path 516"
            d="M175.051,98.653H136.7a1.866,1.866,0,0,0-1.866,1.864,1.865,1.865,0,0,1-1.864,1.866H108.761a1.866,1.866,0,0,1-1.866-1.866,1.864,1.864,0,0,0-1.864-1.864H21.411a1.866,1.866,0,0,0-1.866,1.864v.548A11.936,11.936,0,0,0,31.481,113H91.494"
            transform="translate(9.561 48.261)"
            fill="none"
            stroke="#dadada"
            stroke-miterlimit="10"
            stroke-width="1"
          />
          <path
            id="Path_517"
            data-name="Path 517"
            d="M82.689,113h96.67a11.935,11.935,0,0,0,11.934-11.936v-.548a1.864,1.864,0,0,0-1.864-1.864H159.782"
            transform="translate(40.451 48.261)"
            fill="none"
            stroke="#dadada"
            stroke-miterlimit="10"
            stroke-width="1"
          />
          <path
            id="Path_518"
            data-name="Path 518"
            d="M25.316,18.92s17.87,22.338,41.7,20.849L41.7,57.639S20.849,62.107,0,32.323a63.881,63.881,0,0,0,25.316-13.4M38.622,56.388h0a17.661,17.661,0,0,0,2.491-.159L62.326,41.255C43.28,40.084,28.975,25.367,25.148,21.021A66.291,66.291,0,0,1,2.462,33.2C17.78,54.083,32.777,56.388,38.622,56.388"
            transform="translate(0 9.256)"
            fill="#dadada"
          />
          <path
            id="Path_519"
            data-name="Path 519"
            d="M37.814,55.7a17.66,17.66,0,0,0,2.491-.159L61.518,40.565C42.472,39.394,28.166,24.676,24.338,20.331A66.291,66.291,0,0,1,1.652,32.511C16.97,53.393,31.968,55.7,37.813,55.7Z"
            transform="translate(0.808 9.946)"
            fill="#fff"
          />
          <path
            id="Path_520"
            data-name="Path 520"
            d="M16.346,123.536c3.906-7.093,14.31-23.692,30.207-32.332-1.973-3.169-5.982-11.415-5.4-25.406C34.735,68.821,15.871,79.056,8.613,97.756Z"
            transform="translate(4.213 32.188)"
            fill="#fff"
          />
          <path
            id="Path_521"
            data-name="Path 521"
            d="M43.3,64.22c-1.489,19.36,5.957,28.295,5.957,28.295-20.849,10.424-32.762,35.741-32.762,35.741L7.557,98.472C16.492,74.644,43.3,64.22,43.3,64.22M16.863,124.308c3.906-7.093,14.31-23.692,30.207-32.332-1.973-3.169-5.982-11.415-5.4-25.406C35.252,69.593,16.388,79.828,9.13,98.528Z"
            transform="translate(3.697 31.416)"
            fill="#dadada"
          />
          <path
            id="Path_522"
            data-name="Path 522"
            d="M152.038,54.869s19.984,12.5,44.992,11.354c0,0-6.576,10-11.634,12.613s-12.5,7.086-27.4,4.107-24.183-9.573-24.183-9.573,9.291-3.83,18.22-18.5"
            transform="translate(65.463 26.842)"
            fill="#fff"
          />
          <path
            id="Path_523"
            data-name="Path 523"
            d="M132.949,73.422s10.424-2.978,19.36-19.36c0,0,16.381,11.914,46.165,11.914a36.409,36.409,0,0,1-32.762,19.36,77.57,77.57,0,0,1-8.575-1.2h-.052l.043,0c-7.431-1.433-17.937-4.469-24.179-10.71m63.015-5.985c-23.908-.527-38.7-8.487-43.139-11.249-6.481,11.236-13.561,15.924-17.136,17.7,5.441,4.439,13.3,6.95,19.625,8.341,6.341.768,15.266-4.678,17.278-6.7l.575-.371.57.38c2.106,1.4,5.4,1.745,8.106,1.745a32.494,32.494,0,0,0,6.037-.84,35.449,35.449,0,0,0,8.085-9m-30.2,16.409a35.591,35.591,0,0,0,17.8-4.521c-.518.024-1.075.04-1.666.04-2.786,0-6.222-.344-8.733-1.739A58.775,58.775,0,0,1,161.4,83.338c2.427.353,4.065.485,4.36.508"
            transform="translate(65.038 26.447)"
            fill="#dedede"
          />
          <path
            id="Path_524"
            data-name="Path 524"
            d="M149.142,74.258c-.055,0-.025,0,.034,0h-.034"
            transform="translate(72.945 36.326)"
            fill="#dedede"
          />
          <rect id="Rectangle_668" data-name="Rectangle 668" width="4.208" height="15.739" transform="translate(242.295 50.429)" fill="#cbcbcb" />
          <rect id="Rectangle_669" data-name="Rectangle 669" width="15.739" height="4.208" transform="translate(236.529 56.193)" fill="#cbcbcb" />
          <rect id="Rectangle_670" data-name="Rectangle 670" width="4.208" height="15.739" transform="translate(123.445 9.279)" fill="#cbcbcb" />
          <rect id="Rectangle_671" data-name="Rectangle 671" width="15.739" height="4.208" transform="translate(117.679 15.044)" fill="#cbcbcb" />
          <rect
            id="Rectangle_672"
            data-name="Rectangle 672"
            width="15.739"
            height="4.209"
            transform="translate(210.331 13.154) rotate(-45)"
            fill="#cbcbcb"
          />
          <rect
            id="Rectangle_673"
            data-name="Rectangle 673"
            width="4.209"
            height="15.739"
            transform="translate(210.331 5.001) rotate(-45)"
            fill="#cbcbcb"
          />
          <rect
            id="Rectangle_674"
            data-name="Rectangle 674"
            width="15.739"
            height="4.209"
            transform="translate(31.419 11.129) rotate(-45)"
            fill="#cbcbcb"
          />
          <rect
            id="Rectangle_675"
            data-name="Rectangle 675"
            width="4.209"
            height="15.739"
            transform="translate(31.419 2.976) rotate(-45)"
            fill="#cbcbcb"
          />
          <path
            id="Path_525"
            data-name="Path 525"
            d="M58.635,15.182a3.419,3.419,0,1,1-3.419-3.419,3.419,3.419,0,0,1,3.419,3.419"
            transform="translate(25.339 5.754)"
            fill="#cbcbcb"
          />
          <path
            id="Path_526"
            data-name="Path 526"
            d="M113.105,5.182a3.419,3.419,0,1,1-3.419-3.419,3.419,3.419,0,0,1,3.419,3.419"
            transform="translate(51.985 0.862)"
            fill="#cbcbcb"
          />
          <path
            id="Path_527"
            data-name="Path 527"
            d="M178.237,21.936a3.419,3.419,0,1,1-3.419-3.419,3.419,3.419,0,0,1,3.419,3.419"
            transform="translate(83.848 9.058)"
            fill="#cbcbcb"
          />
          <path
            id="Path_528"
            data-name="Path 528"
            d="M12.349,58.7A3.419,3.419,0,1,1,8.93,55.282,3.419,3.419,0,0,1,12.349,58.7"
            transform="translate(2.696 27.044)"
            fill="#cbcbcb"
          />
          <path
            id="Path_529"
            data-name="Path 529"
            d="M67.594,89.593a37.861,37.861,0,0,1,75,0h19.836a57.572,57.572,0,0,0-114.668,0Z"
            transform="translate(23.364 18.224)"
            fill="#ebeaea"
          />
          <path
            id="Path_530"
            data-name="Path 530"
            d="M127.428,53.746h0a6.148,6.148,0,0,0-2.188-9.59A53.874,53.874,0,0,0,50.25,88.381h12.4a41.533,41.533,0,0,1,57.718-32.907,6.091,6.091,0,0,0,7.059-1.727"
            transform="translate(24.582 19.439)"
            fill="#cbcbcb"
          />
          <rect id="Rectangle_676" data-name="Rectangle 676" width="46.527" height="7.159" transform="translate(87.839 115.218)" fill="#cbcbcb" />
          <rect id="Rectangle_677" data-name="Rectangle 677" width="46.527" height="7.159" transform="translate(139.264 115.218)" fill="#cbcbcb" />
          <rect id="Rectangle_678" data-name="Rectangle 678" width="8.57" height="7.159" transform="translate(73.146 115.218)" fill="#cbcbcb" />
          <rect id="Rectangle_679" data-name="Rectangle 679" width="46.527" height="7.159" transform="translate(87.839 127.346)" fill="#e5e5e4" />
          <rect id="Rectangle_680" data-name="Rectangle 680" width="46.527" height="7.159" transform="translate(139.264 127.346)" fill="#e5e5e4" />
          <rect id="Rectangle_681" data-name="Rectangle 681" width="8.57" height="7.159" transform="translate(73.146 127.346)" fill="#e5e5e4" />
          <path
            id="Path_531"
            data-name="Path 531"
            d="M88.943,61.152a3.77,3.77,0,0,1-.313,1.528,3.913,3.913,0,0,1-2.1,2.089,3.94,3.94,0,0,1-3.077,0,3.912,3.912,0,0,1-2.1-2.089,3.794,3.794,0,0,1-.311-1.528,3.85,3.85,0,0,1,.311-1.547,4.023,4.023,0,0,1,.841-1.249,3.846,3.846,0,0,1,1.258-.831,4.084,4.084,0,0,1,3.077,0,3.831,3.831,0,0,1,1.258.831A4,4,0,0,1,88.63,59.6a3.826,3.826,0,0,1,.313,1.547m-2.329,0a1.622,1.622,0,0,0-3.243,0,1.528,1.528,0,0,0,.478,1.133,1.632,1.632,0,0,0,2.287,0,1.528,1.528,0,0,0,.478-1.133M86.593,72.9l-1.85-.916,8.585-15.175,1.933.935Zm12.329-4.387a3.77,3.77,0,0,1-.313,1.528,3.926,3.926,0,0,1-2.1,2.089,3.957,3.957,0,0,1-3.077,0,3.926,3.926,0,0,1-2.1-2.089,3.906,3.906,0,0,1,0-3.056,3.926,3.926,0,0,1,2.1-2.089,3.957,3.957,0,0,1,3.077,0,3.926,3.926,0,0,1,2.1,2.089,3.78,3.78,0,0,1,.313,1.528m-2.329,0a1.523,1.523,0,0,0-.136-.634,1.724,1.724,0,0,0-.353-.52,1.59,1.59,0,0,0-.509-.343,1.627,1.627,0,0,0-2.246,1.5,1.528,1.528,0,0,0,.478,1.133,1.58,1.58,0,0,0,1.144.468,1.562,1.562,0,0,0,.624-.125,1.614,1.614,0,0,0,.509-.343,1.751,1.751,0,0,0,.353-.509,1.451,1.451,0,0,0,.136-.624"
            transform="translate(39.646 27.79)"
            fill="#ebeaea"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
