import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { canNavigate } from '@/libs/acl/routeProtection';
import authService from '@/services/authentication';
import apiService from '@/services/api';
import { throttle } from 'lodash';

import Preview from '@/views/modules/preview/pages/Preview.vue';
import Landing from '@/views/modules/landing/Landing.vue';
import authenicationRoute from './routes/authentication';
import segmentRoutes from './routes/segment';
import mkaRoutes from './routes/mka';
import dataConnectRoutes from './routes/data-connect';
import communicationRoutes from './routes/communication';
import analyticsRoutes from './routes/analytics';
import reportRoutes from './routes/report';
import settingRoutes from './routes/setting';
import customerInsightRoutes from './routes/customer-insight';
import permissionRoutes from './routes/permission';
import MyAccountRoutes from './routes/My-Account';
import MainNotificationCreditRoutes from './routes/notification-credit';

// fall back page
import NotFoundPage from '@/views/modules/unauthorized/404NotFound.vue';

// custom brands
import bangchakAnalyticsRoute from '@/router/routes/custom-brands/bangchak/bangchak';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import loginModel from '@/models/authentication/login';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: 'analytics-dashboard' },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: { name: 'analytics-dashboard' },
  },
  {
    path: '/landing',
    name: 'landing',
    component: Landing,
  },
  {
    path: '/not-found',
    name: 'not-found-page',
    component: NotFoundPage,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/preview/:component',
    name: 'preview-component',
    component: Preview,
  },
  ...authenicationRoute,
  ...dataConnectRoutes,
  ...mkaRoutes,
  ...segmentRoutes,
  ...analyticsRoutes,
  ...communicationRoutes,
  ...reportRoutes,
  ...settingRoutes,
  ...permissionRoutes,
  ...customerInsightRoutes,
  // custom brands
  ...bangchakAnalyticsRoute,
  ...customerInsightRoutes,
  ...MyAccountRoutes,
  ...MainNotificationCreditRoutes,

  // markdow preview
  {
    path: '/docs-markdown',
    name: 'docs-markdown',
    component: () => import('@/views/modules/docs-markdown/DocsMarkdown.vue'),
  },
  {
    path: '/docs-markdown-direct',
    name: 'docs-markdown-direct',
    component: () => import('@/views/modules/docs-markdown/test.md'),
  },
];
const { fetchRefreshTokenModel } = loginModel();

const fetchBrandRef = () => {
  return new URLSearchParams(window.location.search).get('brandRef') || sessionStorage.getItem('brandSessionData');
};
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const baseUrl: string = process.env.BASE_URL || '';
const brand = authService.getLocalBrandData() ? JSON.parse(authService.getLocalBrandData()!)[0] : null;

const throttledApiRequest = throttle(() => {
  const now = Math.floor(Date.now() / 1000);
  // const now = 1727666971; // เอาไว้ Test เอา time มาใส่

  if (authService.getLocalBrandData()) {
    const localData = JSON.parse(authService.getLocalBrandData()!)[0];

    if (!localData) {
      // กรณี Logout // ไม่มี ข้อมูลใน BrandData
      return;
    }
    const accessToken = String(localData.accessToken);
    const refreshToken = String(localData.refreshToken);

    if (accessToken) {
      const tokenDecode = jwtDecode<JwtPayload>(accessToken);
      const exp = tokenDecode.exp; // รับค่า exp จาก tokenDecode

      if (exp !== undefined) {
        if (now > exp) {
          // ถ้าเลยเวลาหมดอายุแล้ว ให้ไปหน้า Login ใหม่
          return;
        } else {
          // ถ้ายังไม่เลยเวลาให้ลบ 1 ชั่วโมง
          const expMinusOneHour = exp - 3600; // 3600 วินาที = 1 ชั่วโมง

          // เช็คเวลาที่ลบ 1 ชั่วโมงแล้วกับเวลาปัจจุบัน
          if (now > expMinusOneHour) {
            try {
              // เรียกใช้ API
              // ตรวจสอบการหมดอายุของ JWT และทำการ refresh token
              fetchRefreshTokenModel.payload.access_token = accessToken;
              fetchRefreshTokenModel.payload.refresh_token = refreshToken;

              // call API refresh token
              apiService
                .apiRequest(fetchRefreshTokenModel)
                .then((res: Record<string, any>) => {
                  let tempBrand: Array<any> = [];
                  if (authService.getLocalBrandData()) {
                    tempBrand = [
                      ...JSON.parse(authService.getLocalBrandData()!)?.filter((o: Record<string, any>) => {
                        return o.brandRef != fetchBrandRef();
                      }),
                    ];
                  }

                  tempBrand.push({
                    brandRef: fetchBrandRef(),
                    brandData: authService.fetchBrandData()?.brandData,
                    accessToken: res.data.access_token,
                    refreshToken: res.data.refresh_token,
                  });

                  // set item local new token
                  authService.setLocalBrandData(JSON.stringify(tempBrand));
                })
                .catch((error) => {
                  console.error('Failed to refresh token:', error);
                });
            } catch (error) {
              console.error('Error calling API:', error);
            }
          }
        }
      }
    }
  }
}, 10000); // ตั้งค่าให้เรียกใช้ครั้งเดียวทุก 10 วินาที 1000 = 1 sec

router.beforeEach((to, _, next) => {
  const isUserLoggedIn = authService.isUserLoggedIn();
  const currentRoute = window.location;

  throttledApiRequest();

  // bypass skip check login if path = verify
  if (to.name == 'verify-account') {
    return next();
  }

  if (!to.query.brandRef && (sessionStorage.getItem('brandSessionData') || brand)) {
    return next({ path: to.fullPath, query: { ...to.query, brandRef: sessionStorage.getItem('brandSessionData') || brand.brandRef } });
  }

  if (!canNavigate(to)) {
    if (!isUserLoggedIn && !to.meta.redirectIfLoggedIn) return next({ name: 'auth-login' });
  }

  if (to.name == 'auth-login' && isUserLoggedIn) {
    return next({ name: 'analytics-dashboard' }); // if logged in redirect to dashboard
  }

  if (to.name == 'landing' && currentRoute.pathname !== '/landing') {
    const redirect = currentRoute.pathname.replaceAll(baseUrl, '/') + currentRoute.search;
    return next(redirect);
  }

  return next();
});

export default router;
