import dataConnectModel from '@models/dataConnect/dataConnect';

import api from '@services/api';
import { onMounted, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default function useFileHistoryPage() {
  const {
    cancelUploadProgressDataSourceModel,
    fetchFileHistoryListModel,
    downloadErrorReportFileHistoryModel,
    downloadBackupReportFileHistoryModel,
  } = dataConnectModel();

  let searchTimeout: ReturnType<typeof setTimeout> | null = null;

  const { t } = useI18n();

  const loading: Ref<boolean> = ref(false);
  const modalCancel: Ref<boolean> = ref(false);
  const headerColumn: any = ref(null);
  const fileHistory: Ref<boolean> = ref(false);
  const searchValue: Ref<boolean> = ref(false);
  const statusValue: Ref<boolean> = ref(false);
  const ordering: Ref<boolean> = ref(false);
  const historyData: any = ref(null);
  const selectedCancel: any = ref(1);

  const paginationConfig: any = ref({
    PageNumber: 1,
    PageSize: [10, 50, 100],
    RowsPerPage: 10,
    TotalPages: null,
    TotalRows: null,
  });

  const cancelOptions = [
    { label: 'Clear Process Data', value: 1 },
    { label: 'Keep Process Data', value: 2 },
  ];

  const Header = [
    {
      columnId: 1,
      columnName: t('data_connector.file_history_column_no'),
      columnKey: 'no',
      width: '5%',
    },
    {
      columnId: 2,
      columnName: t('data_connector.file_history_column_name'),
      columnKey: 'name',
      width: '20%',
    },
    {
      columnId: 3,
      columnName: t('data_connector.file_history_column_type'),
      columnKey: 'type',
    },
    {
      columnId: 4,
      columnName: t('data_connector.file_history_column_ref_code'),
      columnKey: 'refCode',
    },
    {
      columnId: 5,
      columnName: t('data_connector.file_history_column_amount'),
      columnKey: 'uploadFile',
      width: '10%',
    },
    {
      columnId: 6,
      columnName: t('data_connector.file_history_column_last_update'),
      columnKey: 'modifyDate',
      width: '10%',
    },
    {
      columnId: 7,
      columnName: t('data_connector.file_history_column_status'),
      columnKey: 'status',
      width: '10%',
    },
  ];
  const isNotiCreditModalOpen = ref<boolean>(false);
  const isNotiCreditSuccessOpen = ref<boolean>(false);
  const onCloseNotiCreditModal = () => {
    isNotiCreditModalOpen.value = false;
  };
  const onOpenNotiCreditModal = () => {
    isNotiCreditModalOpen.value = true;
  };
  const onCloseNotiCreditSuccess = () => {
    isNotiCreditSuccessOpen.value = false;
  };
  const onOpenNotiCreditSuccess = () => {
    isNotiCreditSuccessOpen.value = true;
  };
  const setModalCancel = (value: any) => {
    modalCancel.value = value;
  };

  const fetchHeaderColumn = () => {
    const columns: Record<any, any> = Header.map((item: any) => {
      return {
        label: item.columnName,
        key: item.columnKey,
        className: 'file-history-column',
        width: item.width,
        ellipsis: {
          showTitle: false,
        },
        sorter: item.columnId == 4 || item.columnId == 7 || item.columnId == 8,
        sortDirections: ['descend', 'ascend'],
        rawData: item,
        columnId: item.columnId,
      };
    });

    columns.push({
      label: '',
      key: '_Action_',
      hideColumn: false,
      ellipsis: {
        showTitle: false,
      },
    });
    headerColumn.value = columns;
  };

  const resolveDate = (dateInput: string) => {
    if (dateInput) {
      const date = new Date(dateInput);
      let dateStr = '';

      try {
        dateStr = `${date.getFullYear()}-${date.toLocaleString('default', {
          month: '2-digit',
        })}-${date.toLocaleString('default', {
          day: '2-digit',
        })}`;
      } catch {
        return '';
      }

      return dateStr;
    }

    return '';
  };

  const subStringRefCode = (refCode: string) => {
    let resultRefCode = '-';
    if (refCode.length >= 10) {
      resultRefCode = `${refCode.substring(0, 10)}....`;
    } else {
      resultRefCode = refCode;
    }

    return resultRefCode;
  };

  const fetchDataTable = (data: any) => {
    return data.map((item: any) => ({
      // Table
      no: item.no,
      name: item.name,
      type: item.type,
      refCode: subStringRefCode(item.ref_code),
      uploadFile: item.amount_upload_file,
      modifyDate: resolveDate(item.last_update),
      status: item.status,
      status_label: item.status_label,
      //
      ref_code: item.ref_code,
      config_backup: item.config_backup,
      show_status: item.show_status,
      show_download_error: item.show_download_error,
      Id: item.dataset_id,
      key: item.dataset_id,
      _Action_: item.ref_code,
    }));
  };

  const fetchFileHistory = (value?: any) => {
    const params = value || {};
    loading.value = true;

    const model: any = fetchFileHistoryListModel;
    model.payload = params;
    api.apiRequest(model).then((response) => {
      modalCancel.value = false;
      loading.value = false;
      fetchHeaderColumn();
      fileHistory.value = fetchDataTable(response.data.payload);

      paginationConfig.value = {
        ...paginationConfig.value,
        TotalPages: response.data.total_page,
        TotalRows: response.data.filtered_record,
      };
    });
  };

  const cancelProgress = () => {
    const params = {
      Custom_Csv_History_Id: historyData.value.Id,
    };

    const model: any = cancelUploadProgressDataSourceModel;
    model.payload = params;
    api.apiRequest(model).then(() => {
      modalCancel.value = false;
      fetchFileHistory();
    });
  };

  const packAPIOption = (param: any) => {
    //Combine all condition for server side filter
    const filterCondition = [];
    const orderingSorted = [];

    let indexCondition = 0;
    if (param.status !== null && param.status !== undefined) {
      indexCondition++;
      filterCondition.push({
        Seq: indexCondition,
        Key: 'STATUS',
        Value: param.status,
      });
    }
    if (param.search) {
      indexCondition++;
      filterCondition.push({
        Seq: indexCondition,
        Key: 'QUERY',
        Value: param.search,
      });
    }
    if (param.ordering) {
      indexCondition++;
      orderingSorted.push(param.ordering);
    }
    return {
      Page: param.Page,
      Limit: param.Limit,
      Filters: filterCondition,
      Ordering: orderingSorted,
    };
  };

  const onFilterStatus = (Status: any) => {
    statusValue.value = Status;
    const filterParams = packAPIOption({
      Page: paginationConfig.value.PageNumber,
      Limit: paginationConfig.value.RowsPerPage,
      status: Status,
      search: searchValue.value,
      ordering: ordering.value,
    });
    fetchFileHistory(filterParams);
  };

  const onSearchFileHistory = (value: any, timeout = 500) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      searchValue.value = value;
      const filterParams = packAPIOption({
        Page: paginationConfig.value.PageNumber,
        Limit: paginationConfig.value.RowsPerPage,
        status: statusValue.value,
        search: value,
        ordering: ordering.value,
      });
      fetchFileHistory(filterParams);
    }, timeout);
  };

  const onPageSizeChange = (value: any) => {
    paginationConfig.value = {
      ...paginationConfig.value,
      RowsPerPage: Number(value),
    };
    const params = packAPIOption({
      Page: 1,
      Limit: Number(value),
      status: statusValue,
      search: searchValue,
      ordering: ordering,
    });
    fetchFileHistory(params);
  };

  const onJumpToPage = (value: any) => {
    paginationConfig.value = {
      ...paginationConfig.value,
      PageNumber: Number(value),
    };
    const params = packAPIOption({
      Page: Number(value),
      Limit: paginationConfig.value.RowsPerPage,
      status: statusValue.value,
      search: searchValue.value,
      ordering: ordering.value,
    });
    fetchFileHistory(params);
  };

  const onChangePagination = (page: any, pageSize: any, timeout = 500) => {
    paginationConfig.value = {
      ...paginationConfig.value,
      PageNumber: Number(page),
      RowsPerPage: Number(pageSize),
    };
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      const params = packAPIOption({
        Page: page,
        Limit: pageSize,
        status: statusValue,
        search: searchValue,
        ordering: ordering,
      });
      fetchFileHistory(params);
    }, timeout);
  };

  const onOrdering = (sorter: any) => {
    ordering.value = sorter;
    const params = packAPIOption({
      Page: paginationConfig.value.PageNumber,
      Limit: paginationConfig.value.RowsPerPage,
      status: statusValue.value,
      search: searchValue.value,
      ordering: sorter,
    });
    fetchFileHistory(params);
  };

  const onOpenCancelProgressModal = (item: any) => {
    historyData.value = item;
    modalCancel.value = true;
  };

  const onDownloadErrorFile = (ref: any) => {
    const params = {
      Sync_Transaction_Ref: ref,
    };

    const model: any = downloadErrorReportFileHistoryModel;
    model.payload = params;
    api.apiRequest(model).then((response) => {
      if (response.data.has_error_report == true) {
        window.open(response.data.url);
      }
    });
  };

  const onDownloadBackUpFile = (ref: any) => {
    const params = {
      Sync_Transaction_Ref: ref,
    };

    const model: any = downloadBackupReportFileHistoryModel;
    model.payload = params;
    api.apiRequest(model).then((response) => {
      window.open(response.data.url);
    });
  };

  onMounted(() => {
    fetchFileHistory();
  });

  return {
    loading,
    modalCancel,
    setModalCancel,
    headerColumn,
    fileHistory,
    paginationConfig,
    cancelOptions,
    selectedCancel,
    cancelProgress,
    onFilterStatus,
    onSearchFileHistory,
    onPageSizeChange,
    onJumpToPage,
    onChangePagination,
    onOrdering,
    onOpenCancelProgressModal,
    onDownloadErrorFile,
    onDownloadBackUpFile,
    isNotiCreditModalOpen,
    onCloseNotiCreditModal,
    onOpenNotiCreditModal,
    isNotiCreditSuccessOpen,
    onOpenNotiCreditSuccess,
    onCloseNotiCreditSuccess,
  };
}
