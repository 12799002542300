<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="41" viewBox="0 0 20 45">
    <g id="Group_44809" data-name="Group 44809" transform="translate(-3487 -3059.5)">
      <text id="A" transform="translate(3487 3092.5)" fill="#0f2a4e" font-size="31" font-family="SegoeUI, Segoe UI">
        <tspan x="0" y="0">A</tspan>
      </text>
      <path
        id="Path_28719"
        data-name="Path 28719"
        d="M3498.893,3083.27l2.792-2.261,2.927,2.261"
        transform="translate(0 -3.27)"
        fill="none"
        stroke="#0080ff"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>
