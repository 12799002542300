<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <g transform="translate(-1039 -364)">
      <rect width="14" height="2" transform="translate(1039 364)" fill="#606a6c" />
      <rect width="5" height="2" transform="translate(1044 376)" fill="#606a6c" />
      <rect width="11" height="2" transform="translate(1041 368)" fill="#606a6c" />
      <rect width="8" height="2" transform="translate(1042 372)" fill="#606a6c" />
    </g>
  </svg>
</template>
