import { ref } from 'vue';

export default function useInputFieldFastHelp5() {
  const inputTypes = ref({ type: 'password', class: 'input-value pass' });
  function toggleInputType() {
    if (inputTypes.value.type == 'text') {
      inputTypes.value.type = 'password';
      inputTypes.value.class = 'input-value pass';
    } else {
      inputTypes.value.type = 'text';
      inputTypes.value.class = 'input-value text';
    }
  }

  function copyToClipboard(value: string) {
    const el = document.createElement('textarea');
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  function addHoverClass(mouseEvent: any, id: string) {
    const element = document.getElementById(id);
    if (element) {
      if (mouseEvent == 'mouseover') {
        const event = new MouseEvent('mouseover', {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        element.dispatchEvent(event);
      } else if (mouseEvent == 'mouseout') {
        const event = new MouseEvent('mouseout', {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        element.dispatchEvent(event);
      }
    }
  }

  return {
    addHoverClass,
    copyToClipboard,
    toggleInputType,
    inputTypes,
  };
}
