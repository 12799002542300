export const HORIZONTAL_BAR = {
  Y_AXIS_INDEX: 1,
  SERIES_INDEX: 0,
  RAW_DATA_INDEX: 0,
};

export const HEATMAP = {
  X_AXIS_INDEX: 0,
  Y_AXIS_INDEX: 0,
  SERIES_INDEX: 0,
  COLOR_INDEX: 0,
  RAW_DATA_INDEX: 0,
  COLOR_THRESHOLD_TOP: 0.9,
  COLOR_THRESHOLD_BOTTOM: 0.3,
};

export const RADAR = {
  RAW_DATA_INDEX: 0,
};

export const GAUGE = {
  RAW_DATA_INDEX: 0,
};

export const GEOMAP = {
  RAW_DATA_INDEX: 0,
  COLOR_THRESHOLD_TOP: 0.9,
  COLOR_THRESHOLD_BOTTOM: 0.3,
};

export const INFOGRAPHIC = {
  RAW_DATA_NULL: 'null',
};
