export default function () {
  const config: any = {
    'no-margin-no-padding': {
      padding: '0',
      margin: '0',
    },
    'text-vertical-align-1': {
      'font-style': 'normal',
      'line-height': '1',
    },
    'text-vertical-align-1-15': {
      'font-style': 'normal',
      'line-height': '1.15',
    },
    'text-vertical-align-1-25': {
      'font-style': 'normal',
      'line-height': '1.25',
    },
    'text-vertical-align-1-5': {
      'font-style': 'normal',
      'line-height': '1.5',
    },
    'text-vertical-align-1-75': {
      'font-style': 'normal',
      'line-height': '1.75',
    },
    'text-vertical-align-2': {
      'font-style': 'normal',
      'line-height': '2',
    },
    'text-vertical-align-2-25': {
      'font-style': 'normal',
      'line-height': '2.25',
    },
    'text-vertical-align-2-5': {
      'font-style': 'normal',
      'line-height': '2.5',
    },
    'text-vertical-align-3': {
      'font-style': 'normal',
      'line-height': '3',
    },
    'text-vertical-align-4': {
      'font-style': 'normal',
      'line-height': '4',
    },
    'text-transform-none': {
      'font-style': 'normal',
      'text-transform': 'none',
    },
    'text-transform-uppercase': {
      'font-style': 'normal',
      'text-transform': 'uppercase',
    },
    'text-transform-lowercase': {
      'font-style': 'normal',
      'text-transform': 'lowercase',
    },
    'text-transform-capitalize': {
      'font-style': 'normal',
      'text-transform': 'capitalize',
    },
  };

  const generateStyle = (ElementObject: any, Config: any, StyleString: any) => {
    let StyleRender = '';
    const x: object = Config;
    const ConfigKeys: any = Object.keys(x);
    for (let i = 0; i < ConfigKeys.length; i++) {
      StyleRender += ConfigKeys[i] + ':' + Config[ConfigKeys[i]] + ';';
    }

    if (StyleString === null) {
      StyleString = '';
    } else if (StyleString !== '' && StyleString[StyleString.length - 1] !== ';') {
      StyleString += ';';
    }

    const finalStyle: string = StyleString + StyleRender;
    if (finalStyle !== '') {
      ElementObject.setAttribute('style', finalStyle);
    }
    return ElementObject;
  };

  const mapping = (elementObject: any) => {
    for (let i = 0; i < elementObject.children.length; i++) {
      mapping(elementObject.children[i]);
    }

    const classOfElement: any[] = [];

    if (elementObject.classList) {
      for (let i = 0; i < elementObject.classList.length; i++) {
        classOfElement.push(config[elementObject.classList[i]]);
      }

      /* transform array to object */
      let styleConfig = {};
      classOfElement.map((obj) => {
        styleConfig = { ...styleConfig, ...obj };
      });
      /* end */

      generateStyle(elementObject, styleConfig, elementObject.getAttribute('style'));
    }
  };

  const replacerClassByStyle = (htmlString: string) => {
    const element: any = new DOMParser().parseFromString(htmlString, 'text/html');
    mapping(element);
    return element.documentElement.outerHTML;
  };

  return { replacerClassByStyle };
}
