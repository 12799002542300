export const charts_key = {
  TOTAL_STATUS: 'Comm_006_OverviewTotalStatus',
  DELIVERY_RANGE: 'Comm_007_HeatmapCommuCampaignDeliveryRange',
  CLICK_RANGE: 'Comm_015_HeatmapCommuClickRange',
  DELIVERY: 'Comm_008_PieDelivery',
  DELIVERY_BY_CAMPAIGN: 'Comm_009_VerticalStackBarDeliveryByCampaign',
  LINE_NEW_SUBSCRIBE: 'Comm_013_LineLineNewSubscribe',
  LINE_SUBSCRIBE: 'Comm_014_PieLineSubscribe',
};

export const charts_type = {
  OVERVIEW: 'overview',
  HEATMAP: 'heatmap-commu',
  PIE: 'pie',
  VERTICAL_STACK_BAR: 'vertical-stack-bar',
  LINE: 'line',
  VERTICAL_OVERVIEW: 'vertical-overview',
};
