export default function noteModel() {
  const createNoteModel: API.RequestModel<Communication.Note.Request.Create> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Note/Create`,
    method: 'POST',
    payload: {
      Channel: null!,
      Name: '',
      Description: '',
      Schedule_Mode: null!,
      Schedule_DT: '',
      Repeat_Every: null!,
      Repeat_Mode: null!,
      Repeat_On: null!,
      End_Mode: null!,
      End_DT: null,
      End_Occurrence: null!,
    },
  };

  const updateNoteModel: API.RequestModel<Communication.Note.Request.Update> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Note/Update`,
    method: 'POST',
    payload: {
      NoteId: null!,
      Channel: null!,
      Status: null!,
      Name: '',
      Description: '',
    },
  };

  const getNoteDetailModel: API.RequestModel<Communication.Note.Request.Detail> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Note/Detail`,
    method: 'POST',
    payload: {
      NoteId: null!,
    },
  };

  const deleteNoteModel: API.RequestModel<Communication.Note.Request.Delete> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Note/Delete`,
    method: 'POST',
    payload: {
      NoteId: null!,
    },
  };

  return { createNoteModel, getNoteDetailModel, updateNoteModel, deleteNoteModel };
}
