import { ref, getCurrentInstance } from 'vue';
import { useVueFlow, FlowExportObject, Connection } from '@braks/vue-flow';

export default function useMkaFlow(props: any) {
  const vm = getCurrentInstance()?.proxy;
  const mkaFlowData = ref<FlowExportObject[]>([]);

  const { onConnect, addEdges, toObject, project, addNodes, removeNodes, applyEdgeChanges } = useVueFlow({
    defaultZoom: 0.75,
    minZoom: 0.2,
    maxZoom: 4,
  });

  const onFlowNodeDrop = (event: DragEvent) => {
    const nodeData: any = event.dataTransfer?.getData('application/vueflow');
    if (nodeData != null) {
      const nodeDataObject = JSON.parse(event.dataTransfer?.getData('application/vueflow') as string);
      const nodeDataSet = props.mkaNodeConstant[nodeDataObject.type + '_' + nodeDataObject.subType];
      let elementTransformScale: any = document.querySelector('.vue-flow__transformationpane');
      elementTransformScale = Number(elementTransformScale.style.transform.match(/(?:scale\()(.*)(\))/)[1]);
      const grid: any = [15, 15];
      const nodeSize: any = [208, 128];
      const placePositionX = Math.floor(event.offsetX - (nodeSize[0] / 2) * elementTransformScale);
      const placePositionY = Math.floor(event.offsetY - (nodeSize[1] / 2) * elementTransformScale);
      if (nodeDataSet != null) {
        const targetHtml: any = document.querySelector('.mka-flow-container .vue-flow');
        const positionTarget: any = project({
          x: placePositionX,
          y: placePositionY,
        });
        positionTarget.x = positionTarget.x - (positionTarget.x % 15);
        positionTarget.y = positionTarget.y - (positionTarget.y % 15);
        const nodeData: any = JSON.parse(JSON.stringify(nodeDataSet));
        delete nodeData.icon;
        const nodeId: any = 'n-' + props.nodeLocalIdIdx.toString();
        nodeData.localId = nodeId;
        const mkaNodeDataTemp: any = props.mkaNodeData;
        mkaNodeDataTemp[nodeId] = nodeData;
        props.setNodeData(mkaNodeDataTemp);
        const nodeIdData: any = { id: nodeId };
        const node: any = {
          id: nodeId,
          type: 'mkaCustom',
          data: nodeId,
          position: positionTarget,
          snapGrid: grid,
        };
        addNodes([node]);
        props.setNodeLocalIdIdx(props.nodeLocalIdIdx + 1);
      }
    }
  };

  const onFlowDragOver = (event: DragEvent) => {
    event.preventDefault();
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'move';
    }
  };

  const verifyOneTimeOutput = (source: any, sourceSlot: any) => {
    const currentId: any = source + '_' + sourceSlot;
    const mappingData: any = toObject().edges;
    for (let F = 0; F < mappingData.length; F++) {
      if (mappingData[F].id.indexOf(currentId) != -1) return true;
    }
    return false;
  };

  const verifyInfiniteLoop: any = (source: any, target: any, objectList?: Array<any>) => {
    let isLoop: any = false;
    const moveStack: Array<any> = [];
    let mappingData: any =
      objectList ||
      toObject().edges.map((edge: any) => {
        return [edge.source, edge.target];
      });
    if (objectList == null) {
      mappingData.push([source, target]);
    }

    if (mappingData.length > 0) {
      mappingData = mappingData.filter((edge: any) => {
        if (edge[0] === target) {
          if (edge[1] === source) {
            isLoop = true;
          } else {
            moveStack.push(edge[1]);
          }
          return false;
        } else {
          return true;
        }
      });
    }
    if (isLoop == true) {
      return true;
    }
    if (moveStack.length > 0) {
      for (let F = 0; F < moveStack.length; F++) {
        isLoop = verifyInfiniteLoop(source, moveStack[F], mappingData);
        if (isLoop == true) {
          break;
        }
      }
    }
    return isLoop;
  };

  const onCheckNodeConnect = (connection: any) => {
    let nodeSource: any = connection.source;
    let nodeSourceRender: any = nodeSource.split('-');
    let nodeSourceSlot: any = connection.sourceHandle;
    let nodeSourceSlotRender: any = nodeSourceSlot.split('-');
    let nodeTarget: any = connection.target;
    let nodeTargetRender: any = nodeTarget.split('-');
    let nodeTargetSlot: any = connection.targetHandle;
    let nodeTargetSlotRender: any = nodeTargetSlot.split('-');
    if (nodeSourceSlotRender[0] == 'ip' && nodeTargetSlotRender[0] == 'op') {
      connection.source = nodeTarget;
      connection.sourceHandle = nodeTargetSlot;
      connection.target = nodeSource;
      connection.targetHandle = nodeSourceSlot;
      nodeSource = connection.source;
      nodeSourceRender = nodeSource.split('-');
      nodeSourceSlot = connection.sourceHandle;
      nodeSourceSlotRender = nodeSourceSlot.split('-');
      nodeTarget = connection.target;
      nodeTargetRender = nodeTarget.split('-');
      nodeTargetSlot = connection.targetHandle;
      nodeTargetSlotRender = nodeTargetSlot.split('-');
    }
    if (nodeSource == nodeTarget) return false;
    if (nodeSourceSlotRender[0] == nodeTargetSlotRender[0]) return false;
    if (verifyOneTimeOutput(nodeSource, nodeSourceSlot)) return false;
    if (verifyInfiniteLoop(nodeSource, nodeTarget)) return false;
    return true;
  };

  onConnect((connection: Connection) => {
    const nodeSource: any = connection.source;
    const nodeSourceRender: any = nodeSource.split('-');
    const nodeSourceSlot: any = connection.sourceHandle;
    const nodeSourceSlotRender: any = nodeSourceSlot.split('-');
    const nodeTarget: any = connection.target;
    const nodeTargetRender: any = nodeTarget.split('-');
    const nodeTargetSlot: any = connection.targetHandle;
    const nodeTargetSlotRender: any = nodeTargetSlot.split('-');

    const currentEdgeId: string = nodeSource + '_' + nodeSourceSlot + '_' + nodeTarget + '_' + nodeTargetSlot;

    const modeDataSource: any = props.mkaNodeData[nodeSource];

    const edgeData: any = {
      id: currentEdgeId,
      source: nodeSource,
      sourceHandle: nodeSourceSlot,
      target: nodeTarget,
      targetHandle: nodeTargetSlot,
      type: 'mkaCustom',
      animated: false,
      data: {
        localId: currentEdgeId,
        source: Number(nodeSourceRender[1]),
        sourceSlot: Number(nodeSourceSlotRender[1]),
        target: Number(nodeTargetRender[1]),
        targetSlot: Number(nodeTargetSlotRender[1]),
        false_link: modeDataSource.false_link && nodeSourceSlotRender[1] == modeDataSource.nodeOut,
        payload: {},
      },
    };

    addEdges([edgeData]);
    vm?.$emit('on-edge-connect-update', edgeData.source, edgeData.target);
  });

  const miniMapStrokeColor = (node: any) => {
    return '#eee';
  };

  const miniMapBackgroundColor = (node: any) => {
    switch (props.mkaNodeData[node.id].category) {
      case 1:
        return '#1589ee';
      case 2:
        return '#ffa200';
      case 3:
        return '#2aa700';
      default:
    }
    return '#000';
  };

  return {
    mkaFlowData,
    onFlowNodeDrop,
    onFlowDragOver,
    onCheckNodeConnect,
    miniMapStrokeColor,
    miniMapBackgroundColor,
  };
}
