<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.976" height="15.386" viewBox="0 0 12.976 15.386">
    <g id="Group_37485" data-name="Group 37485">
      <path
        id="Path_24351"
        data-name="Path 24351"
        d="M6.488,15.385A9.98,9.98,0,0,1,2.03,11.843,11.461,11.461,0,0,1,.242,7.329,16.655,16.655,0,0,1,.2,1.959l.2,0h0A10.239,10.239,0,0,0,5.148.912,6.2,6.2,0,0,0,6.488,0a6.2,6.2,0,0,0,1.34.913A10.237,10.237,0,0,0,12.575,1.96l.2,0a16.656,16.656,0,0,1-.045,5.371,11.461,11.461,0,0,1-1.789,4.514,9.98,9.98,0,0,1-4.458,3.542M6.373,1.643c-.206.131-.425.257-.651.374a10.106,10.106,0,0,1-2.212.825,12.329,12.329,0,0,1-2.025.322L1.3,3.179l-.013.184a15.273,15.273,0,0,0,.2,3.829,10.162,10.162,0,0,0,1.6,3.982,8.617,8.617,0,0,0,3.31,2.8l.1.047.1-.047a8.617,8.617,0,0,0,3.31-2.8,10.162,10.162,0,0,0,1.6-3.982,15.272,15.272,0,0,0,.2-3.829l-.013-.184-.184-.014a12.327,12.327,0,0,1-2.025-.322,10.1,10.1,0,0,1-2.212-.825C7.029,1.9,6.81,1.775,6.6,1.643L6.488,1.57Z"
        transform="translate(0 0.001)"
        fill="#c3c3c3"
      />
      <path
        id="Path_24352"
        data-name="Path 24352"
        d="M172.864,229.65a.087.087,0,0,1-.062-.026L170.78,227.6a.087.087,0,0,1,0-.124l.536-.536a.087.087,0,0,1,.124,0l1.425,1.425,3.686-3.686a.088.088,0,0,1,.124,0l.536.536a.088.088,0,0,1,0,.124l-4.284,4.284a.087.087,0,0,1-.062.026"
        transform="translate(-167.268 -219.645)"
        fill="#c3c3c3"
      />
    </g>
  </svg>
</template>
