<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="26.949" height="26.515" viewBox="0 0 26.949 26.515">
    <g id="Group_75" data-name="Group 75">
      <path
        id="Path_29"
        data-name="Path 29"
        d="M40.822,84.46a4.366,4.366,0,0,0,4.352,4.34h0a4.345,4.345,0,0,0,4.292-3.608.6.6,0,0,0-1.19-.2,3.143,3.143,0,0,1-3.1,2.606h-.034a3.176,3.176,0,0,1-3.114-3.065,3.143,3.143,0,0,1,2.732-3.206.6.6,0,0,0,.407-.238.6.6,0,0,0-.485-.964.608.608,0,0,0-.08.005,4.364,4.364,0,0,0-3.782,4.336"
        transform="translate(-34.755 -68.212)"
      />
      <path
        id="Path_30"
        data-name="Path 30"
        d="M20.2,51.408l-1.226-.48a.634.634,0,0,0-.463,1.181l1.029.4L18.917,54.1,17.6,53.585a.971.971,0,0,0-1.169.376A7.231,7.231,0,0,1,14.851,55.7a.973.973,0,0,0-.285,1.152l.6,1.371L13.6,58.9l-.552-1.264a.971.971,0,0,0-1.1-.559,7.261,7.261,0,0,1-2.714.081.978.978,0,0,0-.153-.012.965.965,0,0,0-.9.617l-.452,1.152L6.137,58.3,6.6,57.111a.969.969,0,0,0-.345-1.152,7.233,7.233,0,0,1-1.745-1.724.971.971,0,0,0-1.179-.325l-1.314.574-.683-1.564,1.366-.6a.97.97,0,0,0,.571-1.053,7.276,7.276,0,0,1,0-2.466.976.976,0,0,0-.605-1.07l-1.289-.505L2,45.639l1.374.538A.971.971,0,0,0,4.52,45.84a7.233,7.233,0,0,1,1.763-1.731.975.975,0,0,0,.336-1.19l-.557-1.276,1.567-.684L8.24,42.3a.633.633,0,0,0,1.153-.523L8.7,40.256a1.056,1.056,0,0,0-.968-.632,1.044,1.044,0,0,0-.42.088l-1.953.853a1.059,1.059,0,0,0-.546,1.393l.546,1.251a8.49,8.49,0,0,0-1.733,1.7l-1.357-.532a1.05,1.05,0,0,0-.384-.073A1.067,1.067,0,0,0,.9,44.98L.123,46.961a1.059,1.059,0,0,0,.6,1.371l1.265.5a8.532,8.532,0,0,0,0,2.423l-1.351.59a1.06,1.06,0,0,0-.546,1.393l.852,1.95a1.06,1.06,0,0,0,.972.634,1.046,1.046,0,0,0,.421-.088l1.29-.564A8.487,8.487,0,0,0,5.338,56.86L4.879,58.03a1.06,1.06,0,0,0,.6,1.371l1.981.777a1.05,1.05,0,0,0,.384.073,1.067,1.067,0,0,0,.987-.672l.442-1.129a8.54,8.54,0,0,0,2.711-.081l.54,1.235a1.059,1.059,0,0,0,.972.634,1.045,1.045,0,0,0,.421-.088l1.95-.852a1.06,1.06,0,0,0,.546-1.393l-.594-1.36a8.5,8.5,0,0,0,1.541-1.692l1.291.506a1.05,1.05,0,0,0,.384.073,1.067,1.067,0,0,0,.987-.672l.777-1.981a1.06,1.06,0,0,0-.6-1.371"
        transform="translate(0 -33.735)"
      />
      <path
        id="Path_31"
        data-name="Path 31"
        d="M109.755,31.646a3.24,3.24,0,0,0-2.909,1.82,3.332,3.332,0,0,0,2.959,4.8,3.24,3.24,0,0,0,2.909-1.82,3.332,3.332,0,0,0-2.959-4.8m1.92,4.278A2.083,2.083,0,0,1,109.8,37.1a2.164,2.164,0,0,1-1.919-3.116,2.083,2.083,0,0,1,1.871-1.174,2.164,2.164,0,0,1,1.919,3.115"
        transform="translate(-90.677 -26.943)"
      />
      <path
        id="Path_32"
        data-name="Path 32"
        d="M90.607,6.648l-.961-.055c-.041-.164-.088-.327-.141-.487a6.475,6.475,0,0,0-.384-.913l.645-.588a.914.914,0,0,0,.062-1.28L88.8,2.169a.9.9,0,0,0-.675-.3.892.892,0,0,0-.6.233l-.68.62a6.26,6.26,0,0,0-1.346-.632l.049-1.044a.914.914,0,0,0-.855-.955L83.156,0h-.049a.9.9,0,0,0-.9.861l-.05,1.073c-.187.044-.372.1-.552.156a6.137,6.137,0,0,0-.632.25l-.654-.733a.905.905,0,0,0-.675-.3.892.892,0,0,0-.6.233L77.912,2.57a.914.914,0,0,0-.062,1.28l.588.659a6.27,6.27,0,0,0-.705,1.407L76.8,5.863h-.051a.9.9,0,0,0-.9.861L75.776,8.27a.914.914,0,0,0,.855.955l.865.049a6.436,6.436,0,0,0,.5,1.5l-.706.643a.914.914,0,0,0-.063,1.28l1.031,1.156a.905.905,0,0,0,.675.3.891.891,0,0,0,.6-.233l.663-.6a6.274,6.274,0,0,0,1.393.7l-.038.808a.913.913,0,0,0,.855.955l1.533.087h.049a.9.9,0,0,0,.9-.861l.036-.77a6.172,6.172,0,0,0,1.652-.606l.576.646a.905.905,0,0,0,.675.3.892.892,0,0,0,.6-.233l1.134-1.033a.914.914,0,0,0,.062-1.28l-.65-.729a6.293,6.293,0,0,0,.588-1.349l.879.05h.051a.9.9,0,0,0,.9-.861L91.462,7.6a.913.913,0,0,0-.855-.955M89.29,8.737a.837.837,0,0,0-.812.635,5.092,5.092,0,0,1-.656,1.513.855.855,0,0,0,.082,1.028l.632.708-.692.631-.568-.637a.845.845,0,0,0-.63-.283.837.837,0,0,0-.432.12,4.98,4.98,0,0,1-1.773.652.838.838,0,0,0-.7.793l-.035.751-.934-.053.037-.787a.851.851,0,0,0-.6-.856,5.078,5.078,0,0,1-1.538-.772.837.837,0,0,0-1.062.054l-.652.594-.634-.711.689-.628a.849.849,0,0,0,.175-1.027,5.226,5.226,0,0,1-.55-1.656.853.853,0,0,0-.789-.721l-.853-.049.045-.952.909.052h.048a.833.833,0,0,0,.264-.043.842.842,0,0,0,.537-.554,5.083,5.083,0,0,1,.778-1.55.855.855,0,0,0-.051-1.074l-.582-.652.692-.631.633.71a.846.846,0,0,0,.63.283.836.836,0,0,0,.383-.093,4.993,4.993,0,0,1,1.385-.474.839.839,0,0,0,.685-.79L83.4,1.223l.934.053L84.282,2.3a.848.848,0,0,0,.619.861,5.073,5.073,0,0,1,1.5.705.837.837,0,0,0,1.04-.07l.662-.6.634.711-.635.579a.855.855,0,0,0-.162,1.052A5.244,5.244,0,0,1,88.531,7.1a.85.85,0,0,0,.782.681l.936.053-.045.952-.866-.049H89.29"
        transform="translate(-64.514 0)"
      />
    </g>
  </svg>
</template>
