<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="23.135" height="23.261" viewBox="0 0 23.135 23.261">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_14691" data-name="Rectangle 14691" width="18.663" height="18.847" />
      </clipPath>
    </defs>
    <g id="Group_33875" data-name="Group 33875" transform="translate(0 5.144) rotate(-16)">
      <g id="Group_33874" data-name="Group 33874" transform="translate(0 0)" clip-path="url(#clip-path)">
        <path
          id="Path_22573"
          data-name="Path 22573"
          d="M7.446.817A.817.817,0,0,0,6.629,0C6.178,0,0,.817,0,.817s6.178.817,6.629.817A.817.817,0,0,0,7.446.817"
          transform="translate(9.621 9.815)"
        />
        <path
          id="Path_22574"
          data-name="Path 22574"
          d="M6.7,1.773A1.039,1.039,0,0,0,5.229.3C4.823.71,0,7,0,7S6.293,2.179,6.7,1.773"
          transform="translate(8.833 2.84)"
        />
        <path
          id="Path_22575"
          data-name="Path 22575"
          d="M1.249.624A.625.625,0,0,0,0,.624C0,.969.625,5.7.625,5.7S1.249.969,1.249.624"
          transform="translate(7.648 3.587)"
        />
        <path
          id="Path_22576"
          data-name="Path 22576"
          d="M.149.906A.24.24,0,0,0,.462.775L.644.331A.24.24,0,0,0,.2.149L.018.593A.239.239,0,0,0,.07.853.236.236,0,0,0,.149.906"
          transform="translate(9.038 7.245)"
        />
        <path
          id="Path_22577"
          data-name="Path 22577"
          d="M.149,1.174a.24.24,0,0,0,.313-.131L.754.331A.24.24,0,1,0,.31.149L.018.861a.239.239,0,0,0,.052.26.23.23,0,0,0,.079.052"
          transform="translate(9.511 5.82)"
        />
        <path
          id="Path_22578"
          data-name="Path 22578"
          d="M.149,1.174a.24.24,0,0,0,.313-.131L.754.331A.24.24,0,0,0,.31.149L.018.861a.239.239,0,0,0,.052.26.23.23,0,0,0,.079.052"
          transform="translate(10.094 4.396)"
        />
        <path
          id="Path_22579"
          data-name="Path 22579"
          d="M.149.906A.24.24,0,0,0,.462.775L.644.331A.24.24,0,0,0,.2.149L.018.593A.239.239,0,0,0,.07.853.23.23,0,0,0,.149.906"
          transform="translate(10.678 3.24)"
        />
        <path
          id="Path_22580"
          data-name="Path 22580"
          d="M.331.643.775.462A.24.24,0,0,0,.593.018L.149.2A.24.24,0,0,0,.018.512.23.23,0,0,0,.07.591a.239.239,0,0,0,.26.052"
          transform="translate(10.421 8.891)"
        />
        <path
          id="Path_22581"
          data-name="Path 22581"
          d="M.331.754,1.043.462A.24.24,0,0,0,.861.018L.149.31A.24.24,0,0,0,.018.622.23.23,0,0,0,.07.7a.239.239,0,0,0,.26.052"
          transform="translate(13.002 7.723)"
        />
        <path
          id="Path_22582"
          data-name="Path 22582"
          d="M.331.754,1.043.462A.24.24,0,1,0,.861.018L.149.31A.24.24,0,0,0,.018.622.23.23,0,0,0,.07.7a.239.239,0,0,0,.26.052"
          transform="translate(11.577 8.307)"
        />
        <path
          id="Path_22583"
          data-name="Path 22583"
          d="M.331.643.775.462A.24.24,0,0,0,.593.018L.149.2A.24.24,0,0,0,.018.512.23.23,0,0,0,.07.591a.239.239,0,0,0,.26.052"
          transform="translate(14.427 7.25)"
        />
        <path
          id="Path_22584"
          data-name="Path 22584"
          d="M.243.014A.176.176,0,1,0,.108.337L.432.473A.175.175,0,1,0,.567.149Z"
          transform="translate(10.381 11.143)"
        />
        <path
          id="Path_22585"
          data-name="Path 22585"
          d="M.763.231.243.014A.175.175,0,0,0,.014.108.173.173,0,0,0,0,.176.176.176,0,0,0,.108.337l.52.218A.176.176,0,0,0,.763.231"
          transform="translate(12.263 11.931)"
        />
        <path
          id="Path_22586"
          data-name="Path 22586"
          d="M.763.231.243.014A.175.175,0,0,0,.014.108.173.173,0,0,0,0,.176.176.176,0,0,0,.108.337l.52.218A.176.176,0,0,0,.763.231"
          transform="translate(11.224 11.496)"
        />
        <path
          id="Path_22587"
          data-name="Path 22587"
          d="M.567.149.243.014A.175.175,0,0,0,.013.108.173.173,0,0,0,0,.176.175.175,0,0,0,.108.337L.431.473A.176.176,0,0,0,.567.149"
          transform="translate(13.302 12.367)"
        />
        <path
          id="Path_22588"
          data-name="Path 22588"
          d="M.014.243.149.567A.175.175,0,0,0,.473.431L.337.107A.176.176,0,0,0,.014.243"
          transform="translate(7.218 7.791)"
        />
        <path
          id="Path_22589"
          data-name="Path 22589"
          d="M.108.014A.175.175,0,0,0,.014.243l.217.52A.176.176,0,1,0,.555.627L.337.107A.175.175,0,0,0,.108.014"
          transform="translate(6.347 5.713)"
        />
        <path
          id="Path_22590"
          data-name="Path 22590"
          d="M.014.243l.218.52A.175.175,0,0,0,.555.627L.337.108A.176.176,0,0,0,.014.243"
          transform="translate(6.782 6.752)"
        />
        <path
          id="Path_22591"
          data-name="Path 22591"
          d="M.473.431.337.107A.175.175,0,0,0,.014.243L.149.567A.176.176,0,0,0,.473.431"
          transform="translate(5.994 4.87)"
        />
        <path
          id="Path_22592"
          data-name="Path 22592"
          d="M2.42,1.106A.175.175,0,0,0,2.331.8l-.549-.1a.175.175,0,0,1-.129-.1L1.419.1A.175.175,0,0,0,1.1.094L.844.586A.177.177,0,0,1,.709.679L.155.744a.175.175,0,0,0-.105.3l.389.4A.175.175,0,0,1,.484,1.6l-.11.547a.176.176,0,0,0,.249.192l.5-.246a.174.174,0,0,1,.163,0l.486.273a.175.175,0,0,0,.26-.178l-.079-.552a.175.175,0,0,1,.055-.154Z"
          transform="translate(16.187 5.373)"
        />
        <path
          id="Path_22593"
          data-name="Path 22593"
          d="M2.008.906A.176.176,0,0,1,1.953.752L2.03.2A.176.176,0,0,0,1.77.023L1.285.3A.176.176,0,0,1,1.122.3L.62.059A.175.175,0,0,0,.371.251L.482.8A.174.174,0,0,1,.437.954l-.387.4a.176.176,0,0,0,.107.3l.554.063a.177.177,0,0,1,.135.092l.262.492a.175.175,0,0,0,.315-.01l.231-.507a.178.178,0,0,1,.129-.1l.549-.1a.176.176,0,0,0,.088-.3Z"
          transform="translate(14.763 12.313)"
        />
        <path
          id="Path_22594"
          data-name="Path 22594"
          d="M.093,1.369l.492.262a.175.175,0,0,1,.092.135L.74,2.32a.176.176,0,0,0,.3.107l.4-.387a.174.174,0,0,1,.157-.045l.546.111a.175.175,0,0,0,.193-.249l-.244-.5a.176.176,0,0,1,.005-.163L2.369.706a.176.176,0,0,0-.177-.26L1.64.523A.176.176,0,0,1,1.486.468L1.109.057a.176.176,0,0,0-.3.088L.71.694a.177.177,0,0,1-.1.129L.1,1.055a.175.175,0,0,0-.01.315"
          transform="translate(4.287 1.752)"
        />
        <path
          id="Path_22595"
          data-name="Path 22595"
          d="M.3,1.188a.176.176,0,0,1,0,.163l-.246.5A.175.175,0,0,0,.246,2.1l.547-.11a.176.176,0,0,1,.157.046l.4.389a.175.175,0,0,0,.3-.105l.065-.554A.175.175,0,0,1,1.8,1.633L2.3,1.372a.176.176,0,0,0-.009-.315L1.782.824a.175.175,0,0,1-.1-.129l-.1-.549a.176.176,0,0,0-.3-.089L.907.467A.176.176,0,0,1,.753.521L.2.442A.176.176,0,0,0,.023.7Z"
          transform="translate(10.9 0)"
        />
        <path
          id="Path_22596"
          data-name="Path 22596"
          d="M11.323,6.134,10.8,5.609l-1.06-1.06L8.666,3.477l-1.06-1.06L5.59.4A1.369,1.369,0,0,0,3.335.9l-.508,1.4L2.455,3.334,2.079,4.372,1.708,5.4,1.284,6.57.913,7.6.085,9.886a1.369,1.369,0,0,0,1.753,1.753L6.725,9.871,9.416,8.9l1.4-.508a1.354,1.354,0,0,0,.687-.549,1.385,1.385,0,0,0,.183-1.032,1.357,1.357,0,0,0-.368-.676m-6.81-4.8a.115.115,0,0,1,.19-.043L5.792,2.375,4.149,2.338ZM3.777,3.364l3.074.071L7.923,4.507,3.4,4.4Zm-2.366,7.1a.115.115,0,0,1-.148-.148l.2-.562,1.794.041Zm4.54-1.643L1.838,8.723l.4-1.1,6.588.151ZM2.607,6.6,3.03,5.429l5.952.137,1.209,1.208Z"
          transform="translate(0 7.123)"
        />
      </g>
    </g>
  </svg>
</template>
