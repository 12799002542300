import { onMounted, ref, Ref } from 'vue';
import type { EChartsOption } from 'echarts';

export default function useLineChart() {
  const option: Ref<EChartsOption> = ref({});

  onMounted(() => {
    option.value = {
      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: [150, 230, 224, 218, 135, 147, 260],
          type: 'line',
        },
      ],
    };
  });

  return { option };
}
