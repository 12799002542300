<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.832" height="14.737" viewBox="0 0 17.832 14.737">
    <g id="Group_33791" data-name="Group 33791" transform="translate(7426.102 20467.629)">
      <g id="Group_33756" data-name="Group 33756" transform="translate(-7426.102 -20467.629)">
        <path
          id="Path_22581"
          data-name="Path 22581"
          d="M397.015,62.569a4.111,4.111,0,1,0-4.111-4.111,4.111,4.111,0,0,0,4.111,4.111m0-6.806a2.7,2.7,0,1,1-2.7,2.7,2.7,2.7,0,0,1,2.7-2.7"
          transform="translate(-389.554 -54.348)"
        />
        <path
          id="Path_22582"
          data-name="Path 22582"
          d="M425.591,91.747h2.495a.222.222,0,0,0,.157-.38l-2.1-2.1a.222.222,0,0,0-.38.157v1.192h-5.254a.222.222,0,0,0-.222.222v.688a.222.222,0,0,0,.222.222Z"
          transform="translate(-410.476 -80.978)"
        />
        <path
          id="Path_22583"
          data-name="Path 22583"
          d="M423,106.015h-2.495a.222.222,0,0,0-.157.38l2.1,2.1a.223.223,0,0,0,.38-.157v-1.192h5.254a.222.222,0,0,0,.222-.222v-.688a.222.222,0,0,0-.222-.222Z"
          transform="translate(-410.476 -93.826)"
        />
        <path
          id="Path_22584"
          data-name="Path 22584"
          d="M387.8,96.321h-7.5c1-1.677,3.29-2.777,5.864-2.777a8.459,8.459,0,0,1,1.037.064.7.7,0,0,0,.775-.52l0-.019a.7.7,0,0,0-.591-.866,10.087,10.087,0,0,0-1.225-.075c-3.409,0-6.3,1.671-7.358,4a1.138,1.138,0,0,0,1.036,1.61h7.97a.7.7,0,0,0,.621-1.02l-.008-.015a.7.7,0,0,0-.621-.38"
          transform="translate(-378.703 -83.216)"
        />
      </g>
    </g>
  </svg>
</template>
