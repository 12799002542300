<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g>
      <path
        id="Path_21386_00000063624285905165881420000010959635677852832184_"
        class="st8"
        d="M107.55,127.2
		c-24.47-23.01-25.72-61.48-2.79-86.02c21.3-21.82,55.73-23.78,79.37-4.51c22.88,18.27,29.13,50.44,14.76,75.95l-15.24-8.75
		c6.41-12.89,5.82-28.17-1.57-40.52c-5.11-8.93-13.41-15.59-23.23-18.67c-18.61-6.06-38.81,2.78-46.98,20.56
		c-9.82,20.7-2.13,41.52,21.35,56.69c-5.21,8.57-10.36,17.18-15.63,25.75c-5.41,8.89-10.96,17.62-16.42,26.48
		c-5.35,5.95-7.06,14.32-4.48,21.89c3.27,12.19-3.96,24.73-16.15,28c-0.95,0.26-1.92,0.45-2.9,0.58
		c-13.67,2.1-26.45-7.28-28.55-20.95c-0.11-0.73-0.19-1.46-0.24-2.19c-1.17-12.91,8.35-24.33,21.26-25.51
		c0.63-0.06,1.27-0.09,1.9-0.09c3.86,0.35,7.53-1.78,9.13-5.31c8.62-14.46,17.5-28.77,26.45-43.39"
      />
      <path
        id="Path_21387_00000106856169540372363880000011396297982851783811_"
        class="st8"
        d="M132.14,209.18
		c-5.16,32.84-35.96,55.28-68.8,50.13c-0.27-0.04-0.54-0.09-0.82-0.13c-32.26-6.14-53.43-37.26-47.3-69.52
		c5.15-27.08,28.24-47.07,55.78-48.29v17.44c-22.79,2.86-39.24,23.22-37.26,46.1c1.61,20.33,18.81,35.87,39.2,35.42
		c24.48-0.43,38.36-16.81,40.99-49.09c2.06-0.15,4.25-0.43,6.45-0.46c19.9-0.25,39.78-0.53,59.68-0.6c3.64,0.34,7.16-1.35,9.19-4.38
		c6.53-8.94,18.37-12.17,28.53-7.78c9.65,4.12,15.66,13.88,14.97,24.36c-0.97,10.67-8.51,19.6-18.87,22.35
		c-10.69,2.75-21.89-2.06-27.24-11.72c-0.95-1.55-2.74-3.62-4.19-3.64c-16.84-0.25-33.7-0.15-50.35-0.15"
      />
      <path
        id="Path_21388_00000137114695093940125210000016025139901966041233_"
        class="st8"
        d="M157.67,230.33l15.24-8.76
		c20.75,20.62,42.84,24.28,61.13,10.37c15.9-11.84,20.38-33.73,10.4-50.86c-11.96-20.32-32.42-24.66-61.98-12.51
		c-3.21-5.89-6.51-11.86-9.76-17.85c-7.05-12.99-13.9-26.1-21.26-38.91c-1.62-2.5-4.15-4.25-7.06-4.88
		c-11.45-1.29-20.56-10.18-22.15-21.59c-1.1-9.92,3.99-19.49,12.83-24.13c9.72-5.12,21.65-3.33,29.43,4.42
		c8,7.64,9.64,19.8,3.94,29.28c-1.14,2.04-1.4,4.44-0.74,6.68c7.29,14.13,14.97,28.08,22.77,42.49c17.94-5.65,37.48-2.75,53,7.86
		c11.9,7.88,20.44,19.92,23.95,33.75c7.31,28.28-7.2,57.64-34.11,69c-27.74,11.85-59.96,1.47-75.58-24.33"
      />
    </g>
  </svg>
</template>
