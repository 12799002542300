<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="16.767" viewBox="0 0 17.5 16.767">
    <g id="Group_515" data-name="Group 515" transform="translate(0 0)">
      <g id="Group_514" data-name="Group 514" transform="translate(0 7.937)">
        <rect id="Rectangle_397" data-name="Rectangle 397" width="17.5" height="2.33" rx="1.165" transform="translate(0 6.5)" fill="" />
        <rect
          id="Rectangle_398"
          data-name="Rectangle 398"
          width="8.5"
          height="2.326"
          rx="1.163"
          transform="translate(2.326) rotate(90)"
          fill=""
        />
        <rect
          id="Rectangle_399"
          data-name="Rectangle 399"
          width="8.5"
          height="2.332"
          rx="1.166"
          transform="translate(17.5) rotate(90)"
          fill=""
        />
      </g>
      <g id="Group_513" data-name="Group 513" transform="translate(12.892 11.874) rotate(180)">
        <rect
          id="Rectangle_400"
          data-name="Rectangle 400"
          width="11.161"
          height="2"
          rx="1"
          transform="translate(3.499 11.874) rotate(-90)"
          fill=""
        />
        <rect
          id="Rectangle_403"
          data-name="Rectangle 403"
          width="6.844"
          height="2.129"
          rx="1.065"
          transform="matrix(0.616, -0.788, 0.788, 0.616, 0, 5.393)"
          fill=""
        />
        <rect
          id="Rectangle_404"
          data-name="Rectangle 404"
          width="6.844"
          height="2.129"
          rx="1.065"
          transform="matrix(-0.616, -0.788, 0.788, -0.616, 7.214, 6.704)"
          fill=""
        />
      </g>
    </g>
  </svg>
</template>
