import { ref } from 'vue';

export default function useMappingTemplate(props: any) {
    const previewList: any = ref(null);
    const isPreview: any = ref(false);
    const keyList: any = ref([]);
    const keyListCheckCount: any = ref(0);
    const isSelectAllKey: any = ref(false);

    const tableColumn = ref(['', 'Column', 'Mapping']);

    const onPreview = (preview: any) => {
      previewList.value = { title: preview.label || '', list: preview.example || [] };
      isPreview.value = true;
    };

    const onStopPreview = () => {
      isPreview.value = false;
      previewList.value = null;
    };

    const isCheckAll = (list: any) => {
      const filteredUncheckList = list.filter((item: any) => !item.checked && !item.isPrimaryKey);

      return filteredUncheckList.length <= 0;
    };

    // Set checked/unchecked
    const onCheck = (index: any) => (event: any) => {
      const temptKeyList = [...keyList];
      temptKeyList[index]['checked'] = event.target.checked;

      const isAll = isCheckAll(temptKeyList);

      isSelectAllKey.value = isAll;
      keyList.value = temptKeyList;
    };

    const onCheckAll = (event: any) => {
      const check = event.target.checked;
      const temptKeyList = [...keyList.value];

      temptKeyList.forEach((key) => (key.checked = check));
      keyList.value = temptKeyList;
      isSelectAllKey.value = check;
    };

    const selectSummary = (dataList: any) => {
      return dataList.filter((item: any) => item.checked || item.isPrimaryKey).length || 0;
    };

    return {
      selectSummary,
      tableColumn,
    };
}