<template>
  <svg id="Group_52301" data-name="Group 52301" xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250">
    <g id="Rectangle_17818" data-name="Rectangle 17818" transform="translate(0.058)" fill="rgba(96,106,108,0.02)" stroke="#606a6c" stroke-width="1">
      <rect width="250" height="250" rx="20" stroke="none"></rect>
      <rect x="0.5" y="0.5" width="249" height="249" rx="19.5" fill="none"></rect>
    </g>
    <line id="Line_11" data-name="Line 11" y2="250" transform="translate(124.5 0.5)" fill="none" stroke="#606a6c" stroke-width="1"></line>
    <line id="Line_10" data-name="Line 10" x1="250" transform="translate(0.058 125)" fill="none" stroke="#606a6c" stroke-width="1"></line>
  </svg>
</template>
