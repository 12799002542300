<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g>
      <path
        class="st8"
        d="M176.42,233.54L59.5,233.5V54.64h117.2v16.92h8.47c0-13.24,0.58-26.35-0.2-39.37
		c-0.52-8.89-7.34-16.13-16.19-17.18c-3.02-0.58-6.1-0.86-9.18-0.83c-18.39,0-36.79,0-55.2,0c-11.19,0.03-22.35-0.09-33.51,0.39
		c-9.26,0.07-17.21,6.58-19.11,15.64c-0.59,2.23-0.89,4.53-0.9,6.84c-0.11,23.89-0.16,47.8-0.16,71.7c0,34.78,0,69.57,0,104.37
		c0,11.84-0.06,23.71,0.34,35.54c-0.11,9.9,7.12,18.36,16.92,19.8c2.91,0.56,5.87,0.82,8.84,0.79c18.32,0.05,36.63,0.05,54.93,0
		c11.26-0.02,22.55,0.1,33.81-0.4c9.57-0.07,17.71-6.98,19.32-16.41c0.55-2.74,0.82-5.53,0.78-8.32c0-22.85,0-45.68,0-68.53v-4.62
		h-9.24V233.54z M90.18,33.81c0.29-2.82,2.81-4.88,5.63-4.6c4.62-0.06,9.27-0.06,13.89-0.06h28.62c0.77,0,1.53,0,2.31,0
		c0.12-0.01,0.25-0.01,0.37-0.01c2.92,0.06,5.24,2.48,5.18,5.4c0,0.07-0.01,0.14-0.02,0.21c-0.28,2.87-2.84,4.96-5.7,4.68
		c-7.41,0-14.83,0-22.25,0l-0.03,0.02c-7.51,0-14.97,0.08-22.55,0c-0.29,0.02-0.58,0.02-0.87-0.01
		C91.95,39.15,89.9,36.63,90.18,33.81z"
      />
      <path
        class="st8"
        d="M220.97,80.17c-0.6-0.04-1.21-0.03-1.81,0.02h-93.34c-6.93-0.73-13.15,4.3-13.88,11.23
		c-0.09,0.85-0.09,1.7-0.01,2.55c0,17.73,0,35.45,0,53.18c-0.76,6.93,4.24,13.17,11.17,13.93c0.65,0.07,1.31,0.09,1.97,0.06
		c1.34,0.19,2.56,0.88,3.41,1.93c3.32,4.38,6.4,8.93,9.58,13.39c2.16,3.02,3.61,3.06,5.72,0.1c3.19-4.46,6.44-8.84,9.48-13.46
		c0.84-1.41,2.42-2.21,4.05-2.06c6.16,0.09,12.34,0,18.5,0c14.44,0,28.9,0.08,43.34,0c6.82,0.61,12.84-4.42,13.46-11.24
		c0.06-0.68,0.07-1.37,0.01-2.05c0.1-18.12,0.1-36.22,0-54.29l0.09-0.07C233.13,86.5,227.87,80.58,220.97,80.17z M151.85,131.53
		l-0.15,0c-1.06,1.61-2.57,2.87-4.33,3.64c-1.93,0.86-4.03,1.29-6.15,1.26c-2.45,0.07-4.89-0.36-7.17-1.26
		c-1.92-0.8-3.56-2.16-4.71-3.9c-0.71-1.08-1.23-2.28-1.51-3.55c-0.19-0.81-0.01-1.66,0.48-2.34c0.48-0.67,1.23-1.09,2.05-1.16h0.37
		c1.29,0,2.42,0.87,2.75,2.12c0.17,0.66,0.44,1.28,0.81,1.86c0.64,0.87,1.52,1.55,2.53,1.96c1.32,0.55,2.74,0.83,4.18,0.81
		c1.24,0.02,2.47-0.19,3.64-0.62c0.84-0.28,1.58-0.8,2.13-1.49c0.42-0.57,0.65-1.26,0.66-1.97c0.01-0.67-0.2-1.32-0.61-1.86
		c-0.61-0.67-1.39-1.17-2.26-1.44c-0.76-0.29-2.52-0.77-5.19-1.41c-2.06-0.41-4.07-1.07-5.98-1.94c-1.44-0.71-2.66-1.78-3.55-3.12
		c-0.82-1.26-1.26-2.74-1.26-4.24c0-1.69,0.5-3.35,1.44-4.76c1.02-1.51,2.49-2.68,4.19-3.35c1.86-0.75,3.85-1.12,5.86-1.1
		c2.13-0.03,4.24,0.36,6.21,1.15c1.77,0.7,3.28,1.92,4.34,3.5c0.56,0.84,0.99,1.77,1.26,2.74c0.43,1.52-0.46,3.11-1.98,3.54
		c-0.18,0.05-0.36,0.08-0.55,0.1h-0.14c-1.28-0.01-2.39-0.87-2.74-2.1c-0.24-0.89-0.74-1.69-1.45-2.29
		c-1.34-0.93-2.95-1.37-4.57-1.26c-1.61-0.13-3.22,0.25-4.6,1.1c-0.78,0.55-1.25,1.44-1.26,2.39c-0.03,0.73,0.28,1.43,0.83,1.91
		c1.68,1.01,3.56,1.66,5.5,1.89c2.32,0.46,4.59,1.12,6.79,1.97c1.7,0.72,3.17,1.89,4.24,3.38c0.95,1.42,1.44,3.09,1.41,4.8
		C153.37,128.27,152.84,130.03,151.85,131.53z M185.21,135.95C185.21,135.95,185.21,135.95,185.21,135.95h-0.81
		c-1.39,0-2.52-1.13-2.52-2.52v-17.59l-6.15,17.74c-0.64,1.82-2.62,2.77-4.44,2.14c-1-0.35-1.79-1.14-2.14-2.14l-6.12-18.02v17.88
		c0,1.4-1.13,2.53-2.52,2.53h-0.81c-1.39,0-2.52-1.13-2.52-2.53v-24.89c0.01-2.45,2.02-4.43,4.47-4.42c1.89,0.01,3.57,1.22,4.18,3
		l6.31,18.75l0.49,1.5l0.64-1.88l6.41-18.63c0.72-2.13,3.04-3.28,5.17-2.56c1.67,0.56,2.79,2.14,2.77,3.9l0.11,25.21
		C187.73,134.82,186.6,135.95,185.21,135.95z M215.62,131.53c-1.06,1.61-2.57,2.87-4.34,3.64c-1.93,0.86-4.03,1.29-6.15,1.26
		c-2.45,0.07-4.89-0.36-7.17-1.26c-1.93-0.8-3.57-2.16-4.72-3.9c-0.71-1.08-1.23-2.28-1.51-3.55c-0.19-0.81-0.01-1.66,0.48-2.34
		c0.48-0.67,1.23-1.09,2.05-1.16h0.37c1.29,0,2.42,0.87,2.75,2.12c0.17,0.66,0.44,1.28,0.81,1.86c0.64,0.87,1.52,1.55,2.52,1.96
		c1.32,0.55,2.75,0.83,4.18,0.81c1.24,0.02,2.47-0.19,3.64-0.62c0.84-0.28,1.58-0.8,2.13-1.49c0.43-0.57,0.66-1.26,0.66-1.97
		c0.01-0.67-0.2-1.32-0.61-1.86c-0.61-0.67-1.39-1.17-2.26-1.44c-1.7-0.56-3.44-1.03-5.19-1.41c-2.06-0.42-4.07-1.07-5.98-1.94
		c-1.44-0.71-2.66-1.78-3.55-3.12c-0.82-1.26-1.26-2.74-1.26-4.24c0-1.7,0.5-3.35,1.45-4.76c1.01-1.52,2.47-2.69,4.18-3.35
		c1.86-0.75,3.84-1.12,5.85-1.1c2.14-0.04,4.26,0.36,6.24,1.15c1.76,0.7,3.26,1.92,4.32,3.5c0.56,0.84,0.99,1.77,1.26,2.74
		c0.23,0.83,0.08,1.72-0.42,2.42c-0.48,0.73-1.26,1.19-2.12,1.26h-0.34c-1.28-0.01-2.4-0.86-2.75-2.1c-0.23-0.9-0.74-1.7-1.45-2.29
		c-1.34-0.93-2.95-1.37-4.57-1.26c-1.61-0.13-3.22,0.25-4.6,1.1c-0.78,0.55-1.25,1.44-1.26,2.39c-0.02,0.73,0.28,1.43,0.83,1.91
		c1.68,1.01,3.56,1.66,5.5,1.89c2.32,0.46,4.59,1.12,6.79,1.97c1.7,0.72,3.17,1.89,4.25,3.38c0.94,1.42,1.43,3.09,1.4,4.8
		c0,1.8-0.52,3.55-1.51,5.05L215.62,131.53z"
      />
    </g>
  </svg>
</template>
