export default function () {
  const fetchRedirectBangchak: API.RequestModel<Custombrand.Bangchak> = {
    endpoint: `${process.env.BASE_URL_BANGCHAK}/CDP-Bangchak/api/v1.0/Dashboard/Redirect`,
    method: 'POST',
    payload: {
      campaignid: null,
    },
  };

  return {
    fetchRedirectBangchak,
  };
}
