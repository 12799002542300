<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.291" height="12.99" viewBox="0 0 19.291 12.99">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_42390" data-name="Rectangle 42390" width="19.291" height="12.99" />
      </clipPath>
    </defs>
    <g id="Group_91667" data-name="Group 91667" clip-path="url(#clip-path)">
      <path
        id="Path_296972"
        data-name="Path 296972"
        d="M18.654,5.588C18.494,5.36,14.67,0,9.646,0S.8,5.36.638,5.588L0,6.5.638,7.4c.16.229,3.984,5.588,9.008,5.588s8.848-5.36,9.008-5.588l.637-.907ZM9.646,11.412C5.384,11.412,1.93,6.5,1.93,6.5S5.384,1.579,9.646,1.579,17.362,6.5,17.362,6.5s-3.454,4.917-7.716,4.917"
        transform="translate(0 0)"
      />
      <path
        id="Path_296973"
        data-name="Path 296973"
        d="M9.514,3.347a3.08,3.08,0,1,0,3.08,3.08,3.08,3.08,0,0,0-3.08-3.08m1.171,3.731a1.293,1.293,0,1,1,1.293-1.293,1.293,1.293,0,0,1-1.293,1.293"
        transform="translate(0.132 0.068)"
      />
    </g>
  </svg>
</template>
