export default {
  myAccount: 'My account',
  my_account: {
    my_account_tabs: {
      business: 'Business Overview',
      credit: 'Credit',
      storage: 'Storage',
    },
    ask: 'Contact us',
  },

  business: {
    title: 'Business',
  },
  credit: {
    title: 'Credit',
    detail: 'Credit usage overview​',
  },

  storage: {
    title: 'storage',
    detail: '',
    storage_information: {
      title: 'Storage',
      current_package: 'Current package',
    },
    upgrade_storage: {
      title: 'upgrade Storage',
    },
  },
};
