<template>
  <svg
    id="Group_116239"
    data-name="Group 116239"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="30.416"
    height="23.548"
    viewBox="0 0 30.416 23.548"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_50731" data-name="Rectangle 50731" width="30.416" height="23.548" />
      </clipPath>
    </defs>
    <g id="Group_116237" data-name="Group 116237" clip-path="url(#clip-path)">
      <path
        id="Path_311286"
        data-name="Path 311286"
        d="M15.208,54.3c-3.139,0-6.277.008-9.416,0A5.751,5.751,0,0,1,.006,48.514C0,44.537,0,40.561,0,36.584A5.809,5.809,0,0,1,.541,34.09c.206-.442.281-.455.623-.113Q5.322,38.136,9.475,42.3a8.28,8.28,0,0,0,3.008,2.15,7.166,7.166,0,0,0,7.972-1.691c2.95-2.891,5.848-5.834,8.768-8.755.377-.377.439-.367.659.113a5.475,5.475,0,0,1,.524,2.3c0,4.087.019,8.173-.005,12.26A5.731,5.731,0,0,1,24.79,54.3l-.165,0H15.208"
        transform="translate(0 -30.759)"
      />
      <path
        id="Path_311287"
        data-name="Path 311287"
        d="M39.768,0c3.105,0,6.211.006,9.316,0a5.846,5.846,0,0,1,3.281.937c.3.2.315.279.064.53C49.4,4.5,46.387,7.541,43.34,10.553a5.086,5.086,0,0,1-7.127-.008C33.169,7.53,30.15,4.49,27.122,1.459c-.239-.239-.229-.323.053-.508a5.412,5.412,0,0,1,2.98-.939c3.2-.013,6.409,0,9.614,0Z"
        transform="translate(-24.573 0)"
      />
    </g>
  </svg>
</template>
