import { defineComponent, h, nextTick, onBeforeUnmount, onMounted, Prop, reactive, ref, unref, VNode, watch } from 'vue';
import { useDebounce } from '../../composed/debounce';
import { ChocoScrollbar, ChocoScrollBarInstance } from '../scrollbar';

// TODO: while drag scroll (mousedown), prevent snapping until mouseup
// TODO: Normalize select items height, set items' height to the highest height

export default defineComponent({
  name: 'ChocoScrollSelect',
  props: {
    modelValue: <Prop<object | string | number | null>>{
      type: [Object, String, Number],
    },
    options: <Prop<Array<object> | Array<string | number>>>{
      type: Array,
      default: () => [],
    },
    optionKey: <Prop<string>>{
      type: String,
      default: null,
    },
    height: {
      type: Number,
      default: 300,
    },
    // styles
    // TODO: selected position
    selectedPosition: <Prop<'top' | 'center' | 'bottom'>>{
      type: String,
      default: 'top',
    },
    selectedActiveMode: <Prop<'item' | 'area'>>{
      type: String,
      default: 'area',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit, slots }) {
    // element references
    const scrollbar$ = ref<ChocoScrollBarInstance>(null!);

    // set scrollbar scroll position
    const setScrollPosition = (offset: number) => {
      const unrefScrollbar = unref(scrollbar$);

      // if scrollbar scroll top is not match offset, snap to option
      if (unrefScrollbar.getScrollTop() !== offset) {
        unrefScrollbar.setScrollTop(offset);
      }
    };

    // get option value
    const getOptionValue = (option: object | string | number): string | number => {
      let opt: string | number;

      // for object, use value of option key
      if (typeof option === 'object') {
        // if no option key provided, use entire object
        if (!props.optionKey) {
          opt = JSON.stringify(option);
        }
        // else, get value of option key
        else {
          opt = (option as Record<string, string | number>)[props.optionKey];
        }
      }
      // otherwise, use as-is
      else {
        opt = option;
      }

      return opt;
    };

    // tracking options map
    const options$Map = reactive<Record<string | number, HTMLElement>>({});
    const optionsOffsetMap = reactive<Record<number, string | number>>({});
    // options$ binder
    const bindOption$ = (option: object | string | number, vnode: VNode) => {
      // get option value
      const opt = getOptionValue(option);

      // add to mappings
      options$Map[opt] = vnode.el as HTMLElement;
      optionsOffsetMap[options$Map[opt].offsetTop] = opt;
    };
    const unbindOption$ = (option: object | string | number) => {
      // get option value
      const opt = getOptionValue(option);

      // remove mappings
      const elOffset = options$Map[opt].offsetTop;
      delete options$Map[opt];
      delete optionsOffsetMap[elOffset];
    };

    // options pre/post height filling
    const fillHeightPost = ref(0);
    const calculateFillHeightPost = () => {
      // get last element in options
      const maxOffset = Math.max(...Object.keys(optionsOffsetMap).map(Number));
      const lastOpt$ = options$Map[optionsOffsetMap[maxOffset]];

      // calculate needed height to be fill on post option
      const wrapperHeight = unref(scrollbar$).getWrapper$().clientHeight;
      const lastOptionHeight = lastOpt$.clientHeight;
      fillHeightPost.value = wrapperHeight - lastOptionHeight;
    };

    // compare active/selected option
    const isActive = (option: object | string | number) => {
      // no value selected, no compare
      if (props.modelValue === null || props.modelValue === undefined) {
        return false;
      }

      // prepare option value
      const cOpt: string | number = getOptionValue(props.modelValue);
      const tOpt: string | number = getOptionValue(option);

      // compare
      return cOpt === tOpt;
    };

    // scroll to selected
    const scrollToSelected = () => {
      // no value selected, no scroll
      if (props.modelValue === null || props.modelValue === undefined) {
        return;
      }

      // get selected value for compare
      let selectedValue: string | number = getOptionValue(props.modelValue);

      // get selected option$
      const selected$ = options$Map[selectedValue];
      // selected$ not found, no scroll
      if (!selected$) return;

      // snap to selected$ position
      setScrollPosition(selected$.offsetTop);
    };
    const { debounce: debounceScrollToSelected, cancel: cancelDA } = useDebounce(10, scrollToSelected);

    // option scrolling select; snapping
    // debounce for snapping
    const { debounce: debounceSnap, cancel: cancelDB } = useDebounce(150, setScrollPosition);
    // update selection on scrolling
    const updateSelectOnScroll = () => {
      const unrefScrollbar = unref(scrollbar$);

      // get nearest option element
      const currentScrollTop = unrefScrollbar.getScrollTop();
      const nearestOffset = Object.keys(optionsOffsetMap)
        .map(Number)
        // nearest items offset from current scroll top
        .reduce((prev, curr) => (Math.abs(curr - currentScrollTop) < Math.abs(prev - currentScrollTop) ? curr : prev));
      const nearestOption = optionsOffsetMap[nearestOffset];

      // if option not match model value, emit for update
      if (props.modelValue !== nearestOption) {
        // emit value with option data
        emit('update:modelValue', nearestOption);
      }

      // debounce to snap (for continuously scrolling)
      debounceSnap(nearestOffset);
    };

    // option click; emit update model value
    const onClickOption = (opt: object | string | number) => emit('update:modelValue', opt);

    // on scrollbar resize
    const onResize = () => {
      calculateFillHeightPost();
      // TODO: re-calculate options$ offset
      // because wrapper resize, maybe de/increase the width that affect the items' height, that change its offsetTop
    };

    // on scrollbar scroll
    const onScroll = () => {
      updateSelectOnScroll();
    };

    // watch on model value changed; debounce scroll to selected value until user finalized the selection
    // (this prevent recursive with on scroll event)
    watch(
      () => props.modelValue,
      () => debounceScrollToSelected(),
    );

    // on mounted; trigger resize and scroll to selected value (on next tick)
    onMounted(() => {
      onResize();
      void nextTick(() => scrollToSelected());
    });

    // on before unmount; cancel any debouncing
    onBeforeUnmount(() => {
      cancelDA();
      cancelDB();
    });

    // render function
    return () =>
      // ChocoScrollbar.choco-scroll-select
      h(
        ChocoScrollbar,
        {
          ref: scrollbar$,
          class: ['choco-scroll-select'],
          height: props.height,
          scrollImmediate: true,
          onResize,
          onScroll,
        },
        () => [
          // div.choco-scroll-select__active-area
          props.selectedActiveMode === 'area' ? h('div', { class: ['choco-scroll-select__active-area'] }) : null,
          // div.choco-scroll-select__items-list
          h('div', { class: ['choco-scroll-select__items-list'] }, [
            // v-for = props.options > div.choco-scroll-select__item
            props.options?.map((option, key) =>
              h(
                'div',
                {
                  key,
                  class: [
                    'choco-scroll-select__item',
                    props.selectedActiveMode === 'item' && isActive(option) ? 'choco-scroll-select__item--active' : null,
                  ],
                  onClick: () => onClickOption(option),
                  onVnodeMounted: (vnode) => bindOption$(option, vnode),
                  onVnodeUnmounted: () => unbindOption$(option),
                },
                [
                  // slot:item{ option, active } or show default (option value)
                  slots['item'] ? slots['item']({ option: option, active: isActive(option) }) : getOptionValue(option),
                ],
              ),
            ) ?? null,
          ]),
          // v-if = fillHeightPost.value > 0 > div.choco-scroll-select__height-fill.choco-scroll-select__height-fill--post
          fillHeightPost.value > 0
            ? h('div', {
                class: ['choco-scroll-select__height-fill', 'choco-scroll-select__height-fill--post'],
                style: { height: fillHeightPost.value + 'px' },
              })
            : null,
        ],
      );
  },
});
