<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16.742" height="23.123" viewBox="0 0 16.742 23.123">
    <path
      id="Path_296967"
      data-name="Path 296967"
      d="M1503.894,880.221a4.489,4.489,0,0,1-.577-.483,47.056,47.056,0,0,1-5.183-7.183,20.6,20.6,0,0,1-2.079-4.712,8.15,8.15,0,0,1,1.076-7.032,8.363,8.363,0,0,1,12.24-1.814,8.184,8.184,0,0,1,2.979,5.229,9.282,9.282,0,0,1-.623,4.753,28.06,28.06,0,0,1-3.069,5.76,50.331,50.331,0,0,1-3.825,5,4.56,4.56,0,0,1-.577.483Zm.182-10.536a4.216,4.216,0,1,0-4.21-4.209A4.222,4.222,0,0,0,1504.077,869.685Z"
      transform="translate(-1495.701 -857.098)"
    />
  </svg>
</template>
