import { CustomRouteRecord } from '@/types/router/router';

import MkaJourneyDetail from '@views/modules/mka/pages/mka-journey-detail/MkaJourneyDetail.vue';
import MkaJourneyList from '@views/modules/mka/pages/mka-journey-list/MkaJourneyList.vue';

const dataConnectRoutes: Array<CustomRouteRecord> = [
  {
    path: '/mka/detail/:campaignId',
    name: 'mkaDetail',
    component: MkaJourneyDetail,
    breadcrumb: [
      {
        text: 'mka.automations',
        to: '/mka',
      },
      {
        text: 'mka.detail',
        active: true,
      },
    ],
  },
  {
    path: '/mka',
    name: 'mka',
    component: MkaJourneyList,
    breadcrumb: [
      {
        text: 'mka.automations',
        to: '/mka',
      },
    ],
  },
];

export default dataConnectRoutes;
