<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49.512" height="57.689" viewBox="0 0 49.512 57.689">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_82" data-name="Rectangle 82" width="49.512" height="57.689" />
      </clipPath>
    </defs>
    <g id="Group_34779" data-name="Group 34779" opacity="0.537">
      <g id="Group_114" data-name="Group 114" transform="translate(0 0)" clip-path="url(#clip-path)">
        <path
          id="Path_52"
          data-name="Path 52"
          d="M49.5,32.521c-.124-3.976-.025-7.96-.025-11.94q0-6.728,0-13.456c0-2.21-.513-2.961-2.594-3.691a67.485,67.485,0,0,0-20-3.389,72.343,72.343,0,0,0-12.96.736A54.226,54.226,0,0,0,2.2,3.592,2.98,2.98,0,0,0,0,6.77Q0,20.181.014,33.593a15.636,15.636,0,0,0,.269,2.827,18.716,18.716,0,0,0,5.136,9.5c5.133,5.406,11.474,8.954,18.34,11.628a3.653,3.653,0,0,0,2.32-.109A38.77,38.77,0,0,0,30.4,55.551c5.332-2.633,10.313-5.773,14.277-10.3A17.9,17.9,0,0,0,49.5,32.521m-8.644,9.39C37.851,45.345,33.931,48.149,28.151,51c-1.078.532-2.222,1.077-3.346,1.5C18.03,49.775,13.024,46.559,9.1,42.425a13.706,13.706,0,0,1-3.829-6.938,10.519,10.519,0,0,1-.183-1.905Q5.059,21.806,5.073,10.03V7.943A51.706,51.706,0,0,1,14.646,5.8a69.562,69.562,0,0,1,9.821-.73c.746,0,1.5.014,2.233.041A63.073,63.073,0,0,1,44.407,7.947V20.582c0,1.211-.009,2.422-.018,3.634-.021,2.759-.043,5.612.046,8.465a12.815,12.815,0,0,1-3.575,9.231"
          transform="translate(0 0)"
        />
        <path
          id="Path_53"
          data-name="Path 53"
          d="M50.524,47.974c-2.643-.009-5.287,0-7.931,0-1.167,0-1.169,0-1.169-1.179q0-7.888,0-15.775c0-1.032-.288-1.323-1.31-1.339-.862-.013-1.723-.011-2.584,0-1.075.012-1.377.309-1.378,1.375q-.007,5.214,0,10.427,0,5.124,0,10.249c0,1.287.255,1.537,1.572,1.541,1.218,0,2.435,0,3.653,0,3.06,0,6.119.006,9.178,0,1.068,0,1.365-.319,1.373-1.386.006-.832,0-1.664,0-2.5-.006-1.188-.211-1.406-1.4-1.41"
          transform="translate(-19.483 -15.992)"
        />
      </g>
    </g>
  </svg>
</template>
