import { ElScrollbar } from 'element-plus';
import 'element-plus/es/components/scrollbar/style/css';
import { defineComponent, h, onBeforeUnmount, onMounted, ref, unref } from 'vue';
import { useDebounce } from '../../composed/debounce';

export interface Exposed {
  getWrapper$(): HTMLElement;
  getScrollLeft(): number;
  getScrollTop(): number;
  setScrollLeft(value: number): void;
  setScrollTop(value: number): void;
}

export type ChocoScrollBarOnResizeCallback = (wrapper$: HTMLElement, entries: ResizeObserverEntry[], observer: ResizeObserver) => void;

export default defineComponent({
  name: 'ChocoScrollbar',
  props: {
    height: {
      type: Number,
      required: true,
    },
    scrollImmediate: {
      type: Boolean,
      default: () => false,
    },
    scrollFrequency: {
      type: Number,
      default: 50,
    },
  },
  emits: ['resize', 'scroll'],
  setup(props, { emit, expose, slots }) {
    // element references
    const wrapper$ = ref<InstanceType<typeof ElScrollbar>>(undefined!);
    const getWrapper$ = () => wrapper$.value.$el;

    // region scrolling

    const wrapper$ScrollLeft = ref(0);
    const wrapper$ScrollTop = ref(0);

    const getScrollLeft = () => unref(wrapper$ScrollLeft);
    const getScrollTop = () => unref(wrapper$ScrollTop);
    const setScrollLeft = (value: number) => wrapper$.value.setScrollLeft(value);
    const setScrollTop = (value: number) => wrapper$.value.setScrollTop(value);

    // on scroll
    // debounce scroll event to prevent too frequency emitting
    const { debounce: onScrollDebounce } = useDebounce(props.scrollImmediate ? 0 : props.scrollFrequency, () => emit('scroll'));
    const onScroll = ({ scrollLeft, scrollTop }: { scrollLeft: number; scrollTop: number }) => {
      wrapper$ScrollLeft.value = scrollLeft;
      wrapper$ScrollTop.value = scrollTop;

      onScrollDebounce();
    };

    // endregion

    // region events

    // on wrapper resize, use resize observer
    // debounce resize event to prevent too frequency emitting
    const { debounce: onResizeDebounce } = useDebounce(100, <ResizeObserverCallback>((entries, observer) => {
      emit('resize', getWrapper$(), entries, observer);
    }));
    const wrapperResizeObserver = new ResizeObserver(onResizeDebounce);
    const bindOnResize = () => wrapperResizeObserver.observe(getWrapper$());
    const unbindOnResize = () => wrapperResizeObserver.disconnect();

    // endregion

    // region hooks

    onMounted(() => {
      bindOnResize();
    });

    onBeforeUnmount(() => {
      unbindOnResize();
    });

    // endregion

    // exposed methods
    expose(<Exposed>{ getWrapper$, getScrollLeft, getScrollTop, setScrollLeft, setScrollTop });

    // render function
    return () =>
      // div.choco-scrollbar--wrapper
      h(ElScrollbar, { ref: wrapper$, class: ['choco-scrollbar'], style: { height: props.height + 'px' }, always: true, onScroll }, () => [
        // slot:default
        slots['default'] ? slots['default']() : null,
      ]);
  },
});
