<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 150 150" style="enable-background:new 0 0 150 150;" xml:space="preserve">
<g id="Group_28709" transform="translate(-527.221 -651.8)">
	<g id="Group_28463" transform="translate(650 811)">
		<g id="Group_28928" transform="translate(-734.762 -850.376)">
			<path id="Path_17467" class="st3" d="M709.62,802.25l-58.72-58.72c-2.01-2.01-5.27-2.01-7.29,0c-2.01,2.01-2.01,5.27,0,7.29
				c0,0,0,0,0,0l7.18,7.18l-7.82,7.82c-10.43,10.43-12.18,26.73-4.21,39.14l-20.01,20.01c-2.5,2.7-2.34,6.92,0.36,9.43
				c2.56,2.37,6.51,2.37,9.07,0l20.01-20.01c12.41,7.98,28.71,6.23,39.15-4.2l7.82-7.82l7.18,7.18c2.01,2.01,5.27,2.01,7.29,0
				C711.64,807.53,711.64,804.27,709.62,802.25C709.63,802.25,709.62,802.25,709.62,802.25"/>
			<path id="Path_17468" class="st3" d="M663.98,730.46l58.72,58.72c2.01,2.01,5.27,2.01,7.29,0c2.01-2.01,2.01-5.27,0-7.29
				c0,0,0,0,0,0l-7.18-7.18l7.82-7.82c10.43-10.43,12.18-26.73,4.21-39.14l20.01-20.01c2.7-2.5,2.86-6.72,0.36-9.43
				c-2.5-2.7-6.72-2.86-9.43-0.36c-0.12,0.11-0.24,0.23-0.36,0.36l-20.01,20.01c-12.41-7.98-28.7-6.22-39.14,4.21l-7.82,7.82
				l-7.18-7.18c-2.01-2.01-5.27-2.01-7.29,0C661.97,725.18,661.97,728.44,663.98,730.46C663.98,730.46,663.98,730.46,663.98,730.46"
				/>
		</g>
	</g>
</g>
</svg>
</template>

<style type="text/css" scoped>
	.st0{fill:#FFFFFF;stroke:#231F20;stroke-miterlimit:10;}
	.st1{fill:#343434;}
	.st2{fill:#484848;}
	.st3{fill:#FFFFFF;}
</style>
