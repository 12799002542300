import { CustomRouteRecord } from '@/types/router/router';

const reportRoute: Array<CustomRouteRecord> = [
  {
    path: '/report/main',
    name: 'report',
    component: () => import('@views/modules/unauthorized/Unauthorized.vue'),
    breadcrumb: [
      {
        text: 'Report',
      },
    ],
  },
];

export default reportRoute;
