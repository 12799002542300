<template>
  <svg id="Group_33223" data-name="Group 33223" xmlns="http://www.w3.org/2000/svg" width="40.839" height="40.838" viewBox="0 0 40.839 40.838">
    <path
      id="Subtraction_264"
      data-name="Subtraction 264"
      d="M20.419,40.838A20.419,20.419,0,0,1,5.981,5.981,20.419,20.419,0,1,1,34.858,34.857,20.286,20.286,0,0,1,20.419,40.838Zm0-36.754A16.335,16.335,0,0,0,8.869,31.969a16.335,16.335,0,0,0,23.1-23.1A16.228,16.228,0,0,0,20.419,4.083Z"
    />
    <path
      id="Path_21500"
      data-name="Path 21500"
      d="M-1103.572,372.823l5.85,5.85,13.381-13.381-2.3-2.3-11.08,11.08-3.506-3.525Z"
      transform="translate(1114.375 -350.414)"
    />
  </svg>
</template>
