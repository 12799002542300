import { ref, getCurrentInstance } from 'vue';
import router from '@/router';

export default function useVerticalNavMenuGroup(item: any, indexTab: any) {
  const vm = getCurrentInstance()?.proxy;
  const isOpen = ref(false);

  const updateGroupOpen = (val: any) => {
    isOpen.value = val;
  };

  const onClickMainMenu = (path: string) => {
    vm?.$emit('on-click-menu', indexTab);
    router.push(path).then(() => {});
  };

  const onSubSelect = (valSelect: any) => {
    vm?.$emit('on-click-menu', valSelect);
  };

  const resolveEmits = (type: string): object => {
    let itemEmits = {} as object;
    switch (type) {
      default:
        itemEmits = {
          ['on-click-sub-menu']: (valSelect: object) => onSubSelect(valSelect),
        };
    }
    return itemEmits;
  };

  const doesHaveSubChild = (title: any) => item.children.some((child: any) => child.title === title);

  return {
    doesHaveSubChild,
    updateGroupOpen,
    resolveEmits,
    onClickMainMenu,
    isOpen,
  };
}
