<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 176.74 63.12">
    <defs></defs>
    <g id="Group_45699">
      <g id="Rectangle_17042">
        <rect class="cls-1" width="34.72" height="63.12" rx="5.31" ry="5.31" />
        <rect class="cls-2" x=".66" y=".66" width="33.39" height="61.79" rx="4.65" ry="4.65" />
      </g>
      <g id="Rectangle_17048">
        <rect class="cls-1" x="142.02" width="34.72" height="63.12" rx="5.31" ry="5.31" />
        <rect class="cls-2" x="142.68" y=".66" width="33.39" height="61.79" rx="4.65" ry="4.65" />
      </g>
      <g id="Rectangle_17049">
        <rect class="cls-1" x="94.68" width="34.72" height="63.12" rx="5.31" ry="5.31" />
        <rect class="cls-2" x="95.34" y=".66" width="33.39" height="61.79" rx="4.65" ry="4.65" />
      </g>
      <g id="Rectangle_17050">
        <rect class="cls-1" x="50.5" width="31.56" height="63.12" rx="5.31" ry="5.31" />
        <rect class="cls-2" x="51.16" y=".66" width="30.23" height="61.79" rx="4.65" ry="4.65" />
      </g>
    </g>
  </svg>
</template>
