<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
    <g id="Group_45156" data-name="Group 45156" transform="translate(24) rotate(90)">
      <rect id="Rectangle_17333" data-name="Rectangle 17333" width="25" height="3" rx="1" />
      <g id="Group_45154" data-name="Group 45154" transform="translate(6 6)">
        <rect id="Rectangle_17334" data-name="Rectangle 17334" width="5" height="18" rx="1" transform="translate(0 0)" />
        <rect id="Rectangle_17335" data-name="Rectangle 17335" width="5" height="13" rx="1" transform="translate(9 0)" />
      </g>
    </g>
  </svg>
</template>
