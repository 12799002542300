export default {
  items: [
    {
      image: 'https://icon-library.com/images/twitter-small-icon/twitter-small-icon-17.jpg',
      title: 'Mango Frappe',
      subtitle: 'price 89 Baht',
    },
    {
      image: 'https://icon-library.com/images/twitter-small-icon/twitter-small-icon-17.jpg',
      title: 'Burberry Frappe',
      subtitle: 'price 129 Baht',
    },
    {
      image: 'https://icon-library.com/images/twitter-small-icon/twitter-small-icon-17.jpg',
      title: 'Chocolate Frappe',
      subtitle: 'price 89 Baht',
    },
    {
      image: 'https://icon-library.com/images/twitter-small-icon/twitter-small-icon-17.jpg',
      title: 'Kiwi Frappe',
      subtitle: 'price 89 Baht',
    },
    {
      image: 'https://icon-library.com/images/twitter-small-icon/twitter-small-icon-17.jpg',
      title: 'Strawberry  Frappe',
      subtitle: 'price 89 Baht',
    },
  ],
};
