<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.79 15.14">
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="Group_36017" data-name="Group 36017">
          <g id="Group_34728" data-name="Group 34728">
            <g id="Group_778" data-name="Group 778">
              <circle id="Ellipse_54" data-name="Ellipse 54" cx="1.89" cy="13.25" r="1.89" />
            </g>
            <g id="Group_779" data-name="Group 779">
              <circle id="Ellipse_54-2" data-name="Ellipse 54-2" cx="1.89" cy="7.57" r="1.89" />
            </g>
            <g id="Group_780" data-name="Group 780">
              <circle id="Ellipse_54-3" data-name="Ellipse 54-3" cx="1.89" cy="1.89" r="1.89" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
