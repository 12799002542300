<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21.856" height="20.916" viewBox="0 0 21.856 20.916">
    <g id="Group_51010" data-name="Group 51010" transform="translate(0)">
      <path
        id="Path_31545"
        data-name="Path 31545"
        d="M1599.189,491.884a11.735,11.735,0,0,1,7.09,2.571,8.362,8.362,0,0,1,3.033,4.545,7.182,7.182,0,0,1-1.1,6.01,16.544,16.544,0,0,1-3.433,3.544,40.5,40.5,0,0,1-5.531,3.781,4.964,4.964,0,0,1-.92.41c-.517.166-.746-.05-.667-.59.058-.395.125-.788.179-1.184a3.466,3.466,0,0,0,.022-.478.687.687,0,0,0-.485-.719,12.132,12.132,0,0,0-1.235-.3,11.557,11.557,0,0,1-5.475-2.566,8.082,8.082,0,0,1-2.9-5.17,7.33,7.33,0,0,1,1.325-5.27,10.556,10.556,0,0,1,5.978-4.1A13.843,13.843,0,0,1,1599.189,491.884Zm-.634,8.578c.1.132.155.2.206.27q.957,1.311,1.914,2.623a.638.638,0,0,0,.723.316.622.622,0,0,0,.4-.675q0-1.3,0-2.609c0-.515.006-1.03,0-1.544a.579.579,0,0,0-.578-.6.571.571,0,0,0-.568.583c-.006.071,0,.142,0,.213q0,1.065,0,2.13c0,.082-.01.163-.02.3-.092-.12-.146-.187-.2-.257q-1-1.363-2-2.728a.546.546,0,0,0-.623-.245.512.512,0,0,0-.4.51c-.007,1.482,0,2.964,0,4.446a.435.435,0,0,0,.018.1.563.563,0,0,0,.522.42.532.532,0,0,0,.555-.367,1.137,1.137,0,0,0,.042-.34C1598.557,502.187,1598.556,501.363,1598.556,500.462Zm5.173,1.057h1.493a.6.6,0,0,0,.627-.357.579.579,0,0,0-.576-.8c-.512-.009-1.023,0-1.541,0v-1.03h.3c.408,0,.817.007,1.224,0a.557.557,0,0,0,.6-.57.568.568,0,0,0-.6-.577q-1.064-.012-2.129,0a.543.543,0,0,0-.586.583q-.008,2.17,0,4.339a.54.54,0,0,0,.56.584q1.1.023,2.208,0a.533.533,0,0,0,.545-.567.566.566,0,0,0-.577-.572c-.435-.012-.87,0-1.3,0h-.25Zm-12.155-.573c0,.71,0,1.419,0,2.129a.586.586,0,0,0,.644.645q1.011.006,2.023,0a.574.574,0,0,0,.651-.576.565.565,0,0,0-.636-.563c-.417-.006-.834-.015-1.25,0-.236.011-.3-.061-.3-.3.012-1.145.008-2.289,0-3.434a.563.563,0,0,0-.562-.636c-.351,0-.574.245-.576.65C1591.571,499.561,1591.573,500.253,1591.573,500.945Zm5.015,0c0-.7,0-1.4,0-2.1a.589.589,0,0,0-.573-.66.566.566,0,0,0-.563.635q-.006,2.116,0,4.231a.577.577,0,0,0,.585.644c.34-.009.55-.251.552-.646C1596.59,502.347,1596.588,501.646,1596.588,500.945Z"
        transform="translate(-1587.695 -491.884)"
        fill="#fff"
      />
    </g>
  </svg>
</template>
