import { getCurrentInstance, onMounted, Ref, ref, watch } from 'vue';
import api from '@services/api';
import dynamicTableModel from '@/models/component/table/dynamicTable';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

interface Props {
  tableId: number;
  columns: DynamicTable.Column[];
  selectedViewMode?: number;
}

export default function useDisableColumn(props: Props) {
  const columnsFilter: Ref<DynamicTable.Column[]> = ref([]);
  const tableIdForUpdate: Ref<number> = ref(props.tableId);
  const { openDefaultErrorModal } = useValidationModal();
  const { updateDisplayColumnModel } = dynamicTableModel();
  const vm = getCurrentInstance()?.proxy;

  const onChange = () => {
    vm?.$emit('on-change', {
      columnsFilter: columnsFilter.value,
    });
  };

  const updateDisableColumn = (column: DynamicTable.Column) => {
    const disableColumnList: DynamicTable.UpdateDisplay[] = [];

    disableColumnList.push({
      ColumnId: column.column_id,
      Key_Column: column.key,
      Is_Display: column.is_display,
    });

    updateDisplayColumnModel.payload.TableId = tableIdForUpdate.value;
    updateDisplayColumnModel.payload.displayTables = disableColumnList;

    api.apiRequest(updateDisplayColumnModel).catch((err) => {
      openDefaultErrorModal(err);
    });
  };

  const selectRow = (columnId: number) => {
    const index = columnsFilter.value.map((e) => e.column_id).indexOf(columnId);
    if (columnsFilter.value[index].is_display == true) {
      columnsFilter.value[index].is_display = false;
    } else if (columnsFilter.value[index].is_display == false) {
      columnsFilter.value[index].is_display = true;
    }
    if (props.selectedViewMode == 0 || props.selectedViewMode == 9) {
      updateDisableColumn(columnsFilter.value[index]);
    }
    onChange();
  };

  onMounted(() => {
    columnsFilter.value = props.columns;
  }),
    watch(
      () => props.columns,
      () => {
        setTimeout(() => {
          columnsFilter.value = props.columns;
        });
      },
    );

  watch(
    () => props.tableId,
    () => {
      setTimeout(() => {
        tableIdForUpdate.value = props.tableId;
      });
    },
  );

  return { selectRow };
}
