<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25.034" height="23.14" viewBox="0 0 29.034 26.14">
    <g id="Group_34650" data-name="Group 34650" transform="translate(-8.983 -10.93)">
      <path
        id="Path_22741"
        data-name="Path 22741"
        d="M14.981,10.93H9.81a.826.826,0,0,0-.827.826v5.172a.827.827,0,0,0,.827.827h5.171a.827.827,0,0,0,.827-.827V11.756a.826.826,0,0,0-.827-.826m-.9,5.1H10.712V12.658H14.08Z"
      />
      <path
        id="Path_22742"
        data-name="Path 22742"
        d="M14.981,19.088H9.81a.826.826,0,0,0-.827.826v5.172a.826.826,0,0,0,.827.826h5.171a.826.826,0,0,0,.827-.826V19.914a.826.826,0,0,0-.827-.826m-.9,5.1H10.712V20.816H14.08Z"
      />
      <path
        id="Path_22743"
        data-name="Path 22743"
        d="M14.981,27.245H9.81a.827.827,0,0,0-.827.827v5.172a.826.826,0,0,0,.827.826h5.171a.826.826,0,0,0,.827-.826V28.072a.827.827,0,0,0-.827-.827m-.9,5.1H10.712V28.974H14.08Z"
      />
      <path
        id="Path_22744"
        data-name="Path 22744"
        d="M35.19,10.93H30.019a.826.826,0,0,0-.827.826v5.172a.827.827,0,0,0,.827.827H35.19a.827.827,0,0,0,.827-.827V11.756a.826.826,0,0,0-.827-.826m-.9,5.1H30.92V12.658h3.368Z"
      />
      <path
        id="Path_22745"
        data-name="Path 22745"
        d="M35.19,19.088H30.019a.826.826,0,0,0-.827.826v5.172a.826.826,0,0,0,.827.826H35.19a.826.826,0,0,0,.827-.826V19.914a.826.826,0,0,0-.827-.826m-.9,5.1H30.92V20.816h3.368Z"
      />
      <path
        id="Path_22746"
        data-name="Path 22746"
        d="M35.19,27.245H30.019a.827.827,0,0,0-.827.827v5.172a.826.826,0,0,0,.827.826H35.19a.826.826,0,0,0,.827-.826V28.072a.827.827,0,0,0-.827-.827m-.9,5.1H30.92V28.974h3.368Z"
      />
      <path
        id="Path_22747"
        data-name="Path 22747"
        d="M25.086,10.93H19.914a.826.826,0,0,0-.826.826v5.172a.827.827,0,0,0,.826.827h5.172a.827.827,0,0,0,.826-.827V11.756a.826.826,0,0,0-.826-.826m-.9,5.1H20.816V12.658h3.368Z"
      />
      <path
        id="Path_22748"
        data-name="Path 22748"
        d="M25.086,19.088H19.914a.826.826,0,0,0-.826.826v5.172a.826.826,0,0,0,.826.826h5.172a.826.826,0,0,0,.826-.826V19.914a.826.826,0,0,0-.826-.826m-.9,5.1H20.816V20.816h3.368Z"
      />
      <path
        id="Path_22749"
        data-name="Path 22749"
        d="M25.086,27.245H19.914a.827.827,0,0,0-.826.827v5.172a.826.826,0,0,0,.826.826h5.172a.826.826,0,0,0,.826-.826V28.072a.827.827,0,0,0-.826-.827m-.9,5.1H20.816V28.974h3.368Z"
      />
    </g>
  </svg>
</template>
