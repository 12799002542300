<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="90.163" height="91.359" viewBox="0 0 90.163 91.359">
    <g id="Group_45082" data-name="Group 45082" transform="translate(381.573 -436.277)">
      <path
        id="Path_24363"
        data-name="Path 24363"
        d="M-226.513,439.589a3.312,3.312,0,0,0-3.312-3.312H-243.4a3.313,3.313,0,0,0-3.313,3.312A3.313,3.313,0,0,0-243.4,442.9h5.971l-8.783,8.783a22.656,22.656,0,0,1,5.822,3.548l7.25-7.25v5.153a3.313,3.313,0,0,0,3.312,3.313,3.312,3.312,0,0,0,3.312-3.313V439.642c0-.009,0-.018,0-.026S-226.513,439.6-226.513,439.589Z"
        transform="translate(-64.897)"
      />
      <path
        id="Path_24364"
        data-name="Path 24364"
        d="M-307.342,472.041a22.654,22.654,0,0,0-5.822-3.548,24.975,24.975,0,0,0-5.409-1.568,24.075,24.075,0,0,0-21.066,6.7,3.96,3.96,0,0,0-.307.4c-.148-.132-.232-.2-.308-.276a24,24,0,0,0-12.525-6.728,23.042,23.042,0,0,0-6.072-.4,23.644,23.644,0,0,0-15.08,6.536,23.528,23.528,0,0,0-7.2,13.367c-.17.966-.24,1.951-.361,2.926a3.277,3.277,0,0,1-.084.356v.383c0,.726.083,1.449.135,2.173v.008a23.908,23.908,0,0,0,2.545,9.214,73.642,73.642,0,0,0,6.055,6.877,17.281,17.281,0,0,1,10.481-3.5,17.44,17.44,0,0,1,7.071,1.492l.534-.442c0-.015,0-.029,0-.044a9.024,9.024,0,0,1,9.024-9.024,9,9,0,0,1,3.432.68h0L-354.073,509.4l-.662.661a14.492,14.492,0,0,0-17.847,2.069,14.486,14.486,0,0,0,0,20.463,14.487,14.487,0,0,0,20.463,0,14.493,14.493,0,0,0,2.068-17.847l.576-.576,11.948-11.948a8.976,8.976,0,0,1,.821,3.745,9.024,9.024,0,0,1-9.024,9.024c-.186,0-.366-.019-.549-.031l-.16.333a17.313,17.313,0,0,1-2.383,18.011c3.231,3.282,6.187,6.265,8.526,8.608.093.094.175.2.263.3h.074a4.459,4.459,0,0,1,.294-.36c11.17-11.18,36.383-36.608,37.5-38.407a22.963,22.963,0,0,0,3.376-9.248,23.69,23.69,0,0,0-7.277-21.013Q-306.689,472.586-307.342,472.041Zm-49.537,55.8a7.747,7.747,0,0,1-10.943,0,7.748,7.748,0,0,1,0-10.944,7.747,7.747,0,0,1,10.944,0A7.747,7.747,0,0,1-356.878,527.837Z"
        transform="translate(0 -14.58)"
      />
    </g>
  </svg>
</template>
