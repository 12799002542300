import { getCurrentInstance } from 'vue';

interface Props {
  isOpen: boolean;
}

export default function useAddTabModal(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const onCloseModal = () => {
    vm?.$emit('close-modal');
  };
  return { onCloseModal };
}
