<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24.619" height="24.619" viewBox="0 0 24.619 24.619">
    <path
      id="Path_31598"
      data-name="Path 31598"
      d="M24.619,15.886A12.309,12.309,0,1,1,12.309,3.577a12.311,12.311,0,0,1,12.31,12.309M2.572,17.872H6.431c-.05-.65-.085-1.306-.085-1.986s.035-1.335.085-1.985H2.572a10.054,10.054,0,0,0,0,3.971M8.288,6.815a9.953,9.953,0,0,0-4.887,4.7H6.739a16.486,16.486,0,0,1,1.549-4.7m0,18.142a16.486,16.486,0,0,1-1.549-4.7H3.4a9.953,9.953,0,0,0,4.887,4.7m7.6-9.071c0-.695-.039-1.349-.094-1.985H8.824c-.055.635-.1,1.289-.1,1.985s.04,1.352.1,1.986h6.962c.054-.634.1-1.291.1-1.986M9.167,11.517h6.276C14.718,8,13.229,5.955,12.3,5.955S9.891,8,9.167,11.517m6.281,8.739H9.167c.724,3.516,2.214,5.561,3.137,5.561s2.414-2.046,3.144-5.561m.874-13.441a16.5,16.5,0,0,1,1.549,4.7h3.337a9.953,9.953,0,0,0-4.884-4.7Zm4.886,13.441H17.871a16.642,16.642,0,0,1-1.549,4.7,9.955,9.955,0,0,0,4.886-4.7m.829-2.384a10,10,0,0,0,0-3.971H18.179c.05.65.085,1.305.085,1.985s-.035,1.337-.085,1.986Z"
      transform="translate(0 -3.577)"
      fill="#3187ff"
    />
  </svg>
</template>
