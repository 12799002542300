<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19.518" height="19.139" viewBox="0 0 19.518 19.139">
    <g id="Group_116339" data-name="Group 116339" transform="translate(-1657.859 -252.611)">
      <g id="Group_116338" data-name="Group 116338" transform="translate(1662.526 258)">
        <g id="Group_109785" data-name="Group 109785" transform="translate(0 3.522)">
          <path
            id="Subtraction_326"
            data-name="Subtraction 326"
            d="M6.429,3.662H.754A.755.755,0,0,1,0,2.907,3.22,3.22,0,0,1,.486,1.2,3.4,3.4,0,0,1,1.789,0,2.3,2.3,0,0,0,2.28.556,2.548,2.548,0,0,0,.718,2.907a.036.036,0,0,0,.036.036H6.429a.036.036,0,0,0,.036-.036A2.553,2.553,0,0,0,5.082.64,2.308,2.308,0,0,0,5.611.107,3.14,3.14,0,0,1,6.74,1.252a3.309,3.309,0,0,1,.444,1.655A.755.755,0,0,1,6.429,3.662Z"
          />
          <path
            id="Subtraction_326_-_Outline"
            data-name="Subtraction 326 - Outline"
            d="M6.429,3.912H.754a1,1,0,0,1-1-1A3.47,3.47,0,0,1,.273,1.07a3.655,3.655,0,0,1,1.4-1.291l.2-.108L2-.133a2.056,2.056,0,0,0,.438.5L2.76.625,2.378.787a2.3,2.3,0,0,0-1.4,1.907H6.205a2.289,2.289,0,0,0-.336-1,2.315,2.315,0,0,0-.9-.833L4.607.676,4.935.438a2.059,2.059,0,0,0,.472-.476l.132-.186.2.115a3.391,3.391,0,0,1,1.22,1.237,3.56,3.56,0,0,1,.477,1.78A1,1,0,0,1,6.429,3.912ZM1.715.33a3.131,3.131,0,0,0-1.017,1A2.97,2.97,0,0,0,.25,2.907a.5.5,0,0,0,.5.5H6.429a.5.5,0,0,0,.5-.5,3.059,3.059,0,0,0-.41-1.53A2.922,2.922,0,0,0,5.668.439Q5.593.526,5.51.606a2.8,2.8,0,0,1,1.205,2.3.286.286,0,0,1-.286.286H.754a.286.286,0,0,1-.286-.286A2.8,2.8,0,0,1,1.851.493Q1.78.414,1.715.33Z"
          />
        </g>
        <path
          id="Ellipse_4083"
          data-name="Ellipse 4083"
          d="M2.3.718A1.58,1.58,0,1,0,3.879,2.3,1.582,1.582,0,0,0,2.3.718M2.3,0A2.3,2.3,0,1,1,0,2.3,2.3,2.3,0,0,1,2.3,0Z"
          transform="translate(1.437)"
        />
        <path
          id="Ellipse_4083_-_Outline"
          data-name="Ellipse 4083 - Outline"
          d="M2.3-.25A2.549,2.549,0,1,1-.25,2.3,2.552,2.552,0,0,1,2.3-.25Zm0,4.6A2.049,2.049,0,1,0,.25,2.3,2.051,2.051,0,0,0,2.3,4.348ZM2.3.468A1.83,1.83,0,1,1,.468,2.3,1.832,1.832,0,0,1,2.3.468Zm0,3.161A1.33,1.33,0,1,0,.968,2.3,1.332,1.332,0,0,0,2.3,3.629Z"
          transform="translate(1.437)"
        />
      </g>
      <path
        id="Path_311300"
        data-name="Path 311300"
        d="M7.509,18.389a1.6,1.6,0,0,1-.907-.28A16.162,16.162,0,0,1,.58,10.189,22.157,22.157,0,0,1-.749,3.629,1.609,1.609,0,0,1,.784,1.955C4.07,1.8,5.571.732,6.252-.14a1.6,1.6,0,0,1,2.514,0c.681.872,2.182,1.943,5.468,2.094a1.609,1.609,0,0,1,1.532,1.674,22.157,22.157,0,0,1-1.329,6.559,16.162,16.162,0,0,1-6.022,7.92A1.6,1.6,0,0,1,7.509,18.389Zm0-17.639a.089.089,0,0,0-.074.034C5.85,2.813,2.981,3.355.853,3.453a.105.105,0,0,0-.1.109,20.614,20.614,0,0,0,1.236,6.1A14.692,14.692,0,0,0,7.448,16.87a.108.108,0,0,0,.121,0,14.692,14.692,0,0,0,5.462-7.205,20.614,20.614,0,0,0,1.236-6.1.105.105,0,0,0-.1-.109c-2.128-.1-5-.641-6.581-2.67A.09.09,0,0,0,7.509.749Z"
        transform="translate(1658.609 253.361)"
      />
    </g>
  </svg>
</template>
