export default function mkaModalConfig() {
  interface MODAL_PAGE {
    PREVIEW: string;
    SELECT_PROVIDER: string;
    SELECT_TEMPLATE: string;
    MAX: string;
  }

  const nodeFlowForkSplit = {
    maxNodeOut: 10,
    nodeFlowForkSplit_Var_BranchList: [
      { label: '2', id: 2 },
      { label: '3', id: 3 },
      { label: '4', id: 4 },
      { label: '5', id: 5 },
      { label: '6', id: 6 },
      { label: '7', id: 7 },
      { label: '8', id: 8 },
      { label: '9', id: 9 },
      { label: '10', id: 10 },
    ],
    nodeFlowForkSplit_Var_Branch_Default: { label: '2', id: 2 },
  };
  const nodeFlowRandomSplitNormal = { maxNodeOut: 10 };
  const nodeFlowRandomSplitTrue = { maxNodeOut: 10 };
  const nodeFlowDistributionSplit = { maxNodeOut: 10 };
  const nodeEntrySegment = { nodeEntrySegment_Var_Max_Priority: 10 };
  const nodeActionSms = {
    modalPage: <MODAL_PAGE>{ PREVIEW: 'preview', SELECT_PROVIDER: 'selectProvider', SELECT_TEMPLATE: 'selectTemplate', MAX: 'max' },
  };
  const nodeActionLine = {
    modalPage: <MODAL_PAGE>{ PREVIEW: 'preview', SELECT_PROVIDER: 'selectProvider', SELECT_TEMPLATE: 'selectTemplate', MAX: 'max' },
  };

  const nodeActionEdm = {
    modalPage: <MODAL_PAGE>{ PREVIEW: 'preview', SELECT_PROVIDER: 'selectProvider', SELECT_TEMPLATE: 'selectTemplate', MAX: 'max' },
  };

  return {
    nodeFlowForkSplit,
    nodeFlowRandomSplitNormal,
    nodeFlowRandomSplitTrue,
    nodeFlowDistributionSplit,
    nodeEntrySegment,
    nodeActionSms,
    nodeActionLine,
    nodeActionEdm,
  };
}
