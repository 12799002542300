<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="45" height="45" viewBox="0 0 45 45">
    <defs>
      <clipPath id="clip-path-12">
        <rect id="Rectangle_51048" data-name="Rectangle 51048" width="45" height="45" transform="translate(-2.301 -7.656)" fill="none" />
      </clipPath>
      <filter id="Union_239-16" x="-22.097" y="-23.525" width="97.666" height="97.695" filterUnits="userSpaceOnUse">
        <feOffset dx="-5" dy="5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="12.5" result="blur" />
        <feFlood flood-opacity="0.102" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_239-17" x="-22.097" y="-23.525" width="97.666" height="97.695" filterUnits="userSpaceOnUse">
        <feOffset dy="5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="2.5" result="blur-2" />
        <feFlood flood-color="#ffa193" result="color" />
        <feComposite operator="out" in="SourceGraphic" in2="blur-2" />
        <feComposite operator="in" in="color" />
        <feComposite operator="in" in2="SourceGraphic" />
      </filter>
      <filter id="Rectangle_48635" x="-6.897" y="-8.563" width="77.638" height="83.573" filterUnits="userSpaceOnUse">
        <feOffset dy="15" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="12.5" result="blur-3" />
        <feFlood flood-color="#fff" flood-opacity="0.545" />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Ellipse_4105" x="-6.897" y="1.065" width="77.638" height="77.638" filterUnits="userSpaceOnUse">
        <feOffset dy="15" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="12.5" result="blur-4" />
        <feFlood flood-color="#fff" flood-opacity="0.545" />
        <feComposite operator="in" in2="blur-4" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_239-18" x="-17.359" y="4.857" width="108.462" height="108.462" filterUnits="userSpaceOnUse">
        <feOffset dy="25" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="17.5" result="blur-5" />
        <feFlood flood-opacity="0.051" />
        <feComposite operator="in" in2="blur-5" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_239-19" x="-50.199" y="-1.685" width="107.103" height="107.103" filterUnits="userSpaceOnUse">
        <feOffset dy="25" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="17.5" result="blur-6" />
        <feFlood flood-opacity="0.051" />
        <feComposite operator="in" in2="blur-6" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Union_239-20" x="-35.296" y="-19.844" width="109.614" height="109.614" filterUnits="userSpaceOnUse">
        <feOffset dy="25" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="17.5" result="blur-7" />
        <feFlood flood-opacity="0.051" />
        <feComposite operator="in" in2="blur-7" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_115434" data-name="Group 115434" transform="translate(2.301 7.656)">
      <g id="Mask_Group_98836" data-name="Mask Group 98836" clip-path="url(#clip-path-12)">
        <g id="Group_37618" data-name="Group 37618">
          <g id="Group_115487" data-name="Group 115487">
            <g id="Group_110110" data-name="Group 110110" transform="translate(6.654 1.319)">
              <g id="Group_110106" data-name="Group 110106" transform="translate(0 0)">
                <g id="Group_110104" data-name="Group 110104" transform="translate(0 7.913)">
                  <path
                    id="Union_239-21"
                    data-name="Union 239"
                    d="M.4.9C1.815,1.62,2.925,2.25,3.676,2.7a10.357,10.357,0,1,0,6.8-2.549h-.109C9.78.123,7.122,0,4.645,0,1.67,0-1.044.177.4.9Z"
                    transform="translate(20.838 20.865) rotate(180)"
                    fill="#fff"
                  />
                </g>
                <g id="Group_110105" data-name="Group 110105" transform="translate(11.448 0)">
                  <g data-type="innerShadowGroup">
                    <g transform="matrix(1, 0, 0, 1, -20.4, -8.97)" filter="url(#Union_239-16)">
                      <path
                        id="Union_239-22"
                        data-name="Union 239"
                        d="M.434,21.711c1.54-.777,2.747-1.463,3.565-1.952a11.266,11.266,0,1,1,7.4,2.772h-.119c-.643.031-3.534.165-6.229.165C1.816,22.7-1.135,22.5.434,21.711Z"
                        transform="translate(20.4 8.97)"
                        fill="#e36352"
                      />
                    </g>
                    <g transform="matrix(1, 0, 0, 1, -20.4, -8.97)" filter="url(#Union_239-17)">
                      <path
                        id="Union_239-23"
                        data-name="Union 239"
                        d="M.434,21.711c1.54-.777,2.747-1.463,3.565-1.952a11.266,11.266,0,1,1,7.4,2.772h-.119c-.643.031-3.534.165-6.229.165C1.816,22.7-1.135,22.5.434,21.711Z"
                        transform="translate(20.4 8.97)"
                        fill="#e36352"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_110109" data-name="Group 110109" transform="translate(21.648 4.963)">
                <g transform="matrix(1, 0, 0, 1, -30.6, -13.94)" filter="url(#Rectangle_48635)">
                  <rect
                    id="Rectangle_48635-2"
                    data-name="Rectangle 48635"
                    width="2.638"
                    height="8.573"
                    rx="1.319"
                    transform="translate(30.6 13.94)"
                    fill="#fff"
                  />
                </g>
                <g transform="matrix(1, 0, 0, 1, -30.6, -13.94)" filter="url(#Ellipse_4105)">
                  <ellipse
                    id="Ellipse_4105-2"
                    data-name="Ellipse 4105"
                    cx="1.319"
                    cy="1.319"
                    rx="1.319"
                    ry="1.319"
                    transform="translate(30.6 23.57)"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_115389" data-name="Group 115389" transform="translate(2.103 18.159) rotate(90)">
              <g transform="matrix(0, -1, 1, 0, -25.81, 4.4)" filter="url(#Union_239-19)">
                <path
                  id="Union_239-10"
                  data-name="Union 239"
                  d="M1.051,0A1.051,1.051,0,1,1,0,1.051,1.051,1.051,0,0,1,1.051,0Z"
                  transform="translate(2.3 27.92) rotate(-90)"
                  fill="#e46453"
                />
              </g>
            </g>
            <g id="Group_115390" data-name="Group 115390" transform="translate(14.903)">
              <g transform="matrix(1, 0, 0, 1, -17.2, -7.66)" filter="url(#Union_239-20)">
                <path
                  id="Union_239-11"
                  data-name="Union 239"
                  d="M2.307,0A2.307,2.307,0,1,1,0,2.307,2.307,2.307,0,0,1,2.307,0Z"
                  transform="translate(21.82 12.27) rotate(180)"
                  fill="#e46453"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
