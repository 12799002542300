<template>
  <svg id="Group_38686" data-name="Group 38686" xmlns="http://www.w3.org/2000/svg" width="202.198" height="112.282" viewBox="0 0 202.198 112.282">
    <g id="Group_38676" data-name="Group 38676" transform="translate(41.841 19.795)">
      <g id="Group_38673" data-name="Group 38673" transform="translate(0 7.429)">
        <g id="Group_38671" data-name="Group 38671" transform="translate(21.833)">
          <ellipse id="Ellipse_999" data-name="Ellipse 999" cx="1.547" cy="1.547" rx="1.547" ry="1.547" fill="#16294b" />
          <ellipse
            id="Ellipse_1000"
            data-name="Ellipse 1000"
            cx="1.547"
            cy="1.547"
            rx="1.547"
            ry="1.547"
            transform="translate(5.242)"
            fill="#16294b"
          />
          <ellipse
            id="Ellipse_1001"
            data-name="Ellipse 1001"
            cx="1.547"
            cy="1.547"
            rx="1.547"
            ry="1.547"
            transform="translate(10.483)"
            fill="#16294b"
          />
        </g>
        <g id="Group_38672" data-name="Group 38672" transform="translate(0 20.599)">
          <path
            id="Path_24714"
            data-name="Path 24714"
            d="M152.365,543.672H128.246a2.663,2.663,0,0,0-2.663,2.663v19.356a2.663,2.663,0,0,0,2.663,2.663h24.119a2.663,2.663,0,0,0,2.663-2.663V546.335A2.663,2.663,0,0,0,152.365,543.672Zm-2.254,19.765H130.5V548.589h19.612Z"
            transform="translate(-125.583 -543.672)"
            fill="#3a83f6"
          />
          <path
            id="Path_24715"
            data-name="Path 24715"
            d="M149.741,576.427l-14.4-10.707a.685.685,0,0,0-.922.1l-4.761,5.4-3.054-2.551,6.1-7.024h0a2.459,2.459,0,0,1,3.311-.376h0l16.071,11.754Z"
            transform="translate(-126.102 -552.375)"
            fill="#3a83f6"
          />
          <ellipse
            id="Ellipse_1002"
            data-name="Ellipse 1002"
            cx="1.793"
            cy="1.793"
            rx="1.793"
            ry="1.793"
            transform="translate(18.539 7.077)"
            fill="#3a83f6"
          />
        </g>
        <rect
          id="Rectangle_16847"
          data-name="Rectangle 16847"
          width="33.839"
          height="4.871"
          rx="2.436"
          transform="translate(35.45 19.693)"
          fill="#16294b"
        />
        <rect
          id="Rectangle_16848"
          data-name="Rectangle 16848"
          width="33.839"
          height="4.871"
          rx="2.436"
          transform="translate(35.45 29.53)"
          fill="#3a83f6"
        />
        <rect
          id="Rectangle_16849"
          data-name="Rectangle 16849"
          width="33.839"
          height="4.871"
          rx="2.436"
          transform="translate(35.45 39.368)"
          fill="#16294b"
        />
      </g>
      <g id="Group_38674" data-name="Group 38674" transform="translate(12.77 0)">
        <path
          id="Path_24716"
          data-name="Path 24716"
          d="M228.279,505.756a7.523,7.523,0,0,1,2.643.485v-6.935a2.643,2.643,0,0,0-2.643-2.643H164.2a2.643,2.643,0,0,0-2.644,2.643v6.935a7.523,7.523,0,0,1,2.643-.485Z"
          transform="translate(-156.64 -491.747)"
          fill="none"
        />
        <path
          id="Path_24717"
          data-name="Path 24717"
          d="M151.557,600.749a7.56,7.56,0,0,0,7.56,7.56H223.2a7.555,7.555,0,0,0,7.075-4.917H159.117a2.643,2.643,0,0,1-2.644-2.643v-.27h-4.917Z"
          transform="translate(-151.557 -544.521)"
          fill="#16294b"
        />
        <path
          id="Path_24718"
          data-name="Path 24718"
          d="M156.534,507.678a2.643,2.643,0,0,1,2.583-2.089h71.638V494.223a7.56,7.56,0,0,0-7.56-7.56H159.117a7.56,7.56,0,0,0-7.56,7.56v12.9c0,.187.015.371.028.555-.013.184-.028.367-.028.555v2.994h4.917v-2.994A2.643,2.643,0,0,1,156.534,507.678Zm-.06-13.455a2.643,2.643,0,0,1,2.644-2.643H223.2a2.643,2.643,0,0,1,2.643,2.643v6.935a7.522,7.522,0,0,0-2.643-.485H159.117a7.522,7.522,0,0,0-2.643.485Z"
          transform="translate(-151.557 -486.663)"
          fill="#16294b"
        />
      </g>
      <g id="Group_38675" data-name="Group 38675" transform="translate(77.197 22.829)">
        <path
          id="Path_24719"
          data-name="Path 24719"
          d="M304.5,533.1H286.05a3.456,3.456,0,0,0-3.452,3.452v25.235a3.456,3.456,0,0,0,3.452,3.452H304.5a3.456,3.456,0,0,0,3.452-3.452V536.549A3.456,3.456,0,0,0,304.5,533.1Zm-17.462,27.714-.01-23.28,16.489-.009.01,23.289Z"
          transform="translate(-282.598 -533.097)"
          fill="#16294b"
        />
        <path
          id="Path_24720"
          data-name="Path 24720"
          d="M306.782,551.681h-7.429a1.475,1.475,0,1,0,0,2.95h2.239v11.056a1.475,1.475,0,0,0,2.95,0V554.631h2.24a1.475,1.475,0,1,0,0-2.95Z"
          transform="translate(-290.366 -542.544)"
          fill="#16294b"
        />
      </g>
    </g>
    <g id="Group_53820" data-name="Group 53820">
      <g id="Group_38683" data-name="Group 38683" transform="translate(0 0.198)">
        <g id="Group_37210" data-name="Group 37210" transform="translate(2.015 0)">
          <path
            id="Path_24123"
            data-name="Path 24123"
            d="M97.185,454.989H83.135a4.092,4.092,0,0,0,0,8.185h4.274a4.092,4.092,0,1,1,0,8.184H48.669a4.092,4.092,0,1,1,0-8.184H66.9a4.092,4.092,0,0,0,0-8.185h2.273a4.092,4.092,0,0,1,0-8.185H97.185a4.092,4.092,0,1,1,0,8.185h0"
            transform="translate(-44.577 -446.804)"
            fill="#f2f2f2"
          />
        </g>
        <g id="Group_37208" data-name="Group 37208" transform="translate(129.389 79.404)">
          <path
            id="Path_24127"
            data-name="Path 24127"
            d="M363.184,616.492H344.945a4.092,4.092,0,0,0,0,8.185h1.54a4.092,4.092,0,1,1,0,8.184H307.744a4.092,4.092,0,1,1,0-8.184h18.83a4.092,4.092,0,0,0,0-8.185h-5.822a4.092,4.092,0,1,1,0-8.184h42.429a4.092,4.092,0,0,1,0,8.184"
            transform="translate(-303.652 -608.308)"
            fill="#f2f2f2"
          />
        </g>
        <g id="Group_37208-2" data-name="Group 37208" transform="translate(14.346 87.531)">
          <path
            id="Path_24127-2"
            data-name="Path 24127"
            d="M129.19,641.207H110.951a4.092,4.092,0,1,1,0-8.184h1.54a4.092,4.092,0,0,0,0-8.185H73.75a4.092,4.092,0,0,0,0,8.185H92.58a4.092,4.092,0,1,1,0,8.184H86.758a4.092,4.092,0,0,0,0,8.185h42.429a4.092,4.092,0,0,0,0-8.185"
            transform="translate(-69.658 -624.838)"
            fill="#f2f2f2"
          />
        </g>
        <path
          id="Path_24131"
          data-name="Path 24131"
          d="M393.475,549.535a5.521,5.521,0,1,0-5.522,5.52h0a5.521,5.521,0,0,0,5.521-5.521h0"
          transform="translate(-214.311 -496.22)"
          fill="#f2f2f2"
        />
        <path
          id="Path_24131-2"
          data-name="Path 24131"
          d="M49.572,628.577a4.547,4.547,0,1,0-4.547,4.546h0a4.546,4.546,0,0,0,4.547-4.546h0"
          transform="translate(-40.479 -536.897)"
          fill="#f2f2f2"
        />
        <path
          id="Path_24132"
          data-name="Path 24132"
          d="M78.512,542.974a7.647,7.647,0,1,1-7.647-7.647h0a7.648,7.648,0,0,1,7.647,7.647"
          transform="translate(-52.038 -491.805)"
          fill="#f2f2f2"
        />
      </g>
      <g id="Group_37208-3" data-name="Group 37208" transform="translate(124.23)">
        <path
          id="Path_24127-3"
          data-name="Path 24127"
          d="M297.249,454.585h18.24a4.092,4.092,0,1,1,0,8.184h-1.54a4.092,4.092,0,1,0,0,8.185h38.74a4.092,4.092,0,0,0,0-8.185h-18.83a4.092,4.092,0,1,1,0-8.184h5.823a4.092,4.092,0,0,0,0-8.185H297.252a4.092,4.092,0,0,0,0,8.185"
          transform="translate(-293.16 -446.4)"
          fill="#f2f2f2"
        />
      </g>
      <path
        id="Path_24131-3"
        data-name="Path 24131"
        d="M433.246,483.2a4.547,4.547,0,1,0,4.547-4.546h0a4.546,4.546,0,0,0-4.547,4.547h0"
        transform="translate(-240.141 -462.796)"
        fill="#f2f2f2"
      />
    </g>
  </svg>
</template>
