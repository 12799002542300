<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250">
    <g id="Group_51456" data-name="Group 51456" transform="translate(-0.175)">
      <g id="Rectangle_17815" data-name="Rectangle 17815" transform="translate(0.175)" fill="rgba(96,106,108,0.02)" stroke="#606a6c" stroke-width="1">
        <rect width="250" height="250" rx="20" stroke="none" />
        <rect x="0.5" y="0.5" width="249" height="249" rx="19.5" fill="none" />
      </g>
      <line id="Line_4" data-name="Line 4" x1="250" transform="translate(0.175 125)" fill="none" stroke="#606a6c" stroke-width="1" />
    </g>
  </svg>
</template>
