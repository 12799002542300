<template>
  <svg
    id="Group_33786"
    data-name="Group 33786"
    xmlns="http://www.w3.org/2000/svg"
    width="19.545"
    height="14.521"
    viewBox="0 0 19.545 14.521"
  >
    <path
      id="Path_22577"
      data-name="Path 22577"
      d="M439.355,211.867a1.982,1.982,0,0,0-.263,2.082,2.038,2.038,0,0,0,3.419.456,1.981,1.981,0,0,0,.263-2.082,2.038,2.038,0,0,0-3.419-.455m2.769.746a1.274,1.274,0,0,1-.168,1.343,1.324,1.324,0,0,1-2.219-.3,1.275,1.275,0,0,1,.168-1.341,1.323,1.323,0,0,1,2.218.3"
      transform="translate(-426.103 -203.435)"
    />
    <path
      id="Path_22578"
      data-name="Path 22578"
      d="M402.936,186.722a4.111,4.111,0,1,0-4.111-4.111,4.111,4.111,0,0,0,4.111,4.111m0-6.806a2.7,2.7,0,1,1-2.7,2.7,2.7,2.7,0,0,1,2.7-2.7"
      transform="translate(-395.475 -178.501)"
    />
    <path
      id="Path_22579"
      data-name="Path 22579"
      d="M394.1,220.475h-7.88c1-1.677,3.29-2.777,5.864-2.777a8.764,8.764,0,0,1,1.514.131.693.693,0,0,0,.808-.571l0-.014a.709.709,0,0,0-.579-.811,10.112,10.112,0,0,0-1.746-.15c-3.409,0-6.3,1.671-7.358,4a1.139,1.139,0,0,0,1.036,1.61h8.343a.7.7,0,0,0,.657-.938l0-.014a.7.7,0,0,0-.657-.463"
      transform="translate(-384.623 -207.369)"
    />
    <path
      id="Path_22580"
      data-name="Path 22580"
      d="M429.732,200.6l.237.539c-.078.068-.154.139-.226.212a4,4,0,0,0-.391.463l-.5-.188a.559.559,0,0,0-.715.322l-.344.881a.551.551,0,0,0,.024.451.545.545,0,0,0,.294.262l.527.2a3.816,3.816,0,0,0,.03.909l-.582.264a.559.559,0,0,0-.283.731l.377.863.014.027a.55.55,0,0,0,.719.251l.6-.271c.076.09.158.175.238.258a3.789,3.789,0,0,0,.312.275l-.218.56a.553.553,0,0,0,.024.451.545.545,0,0,0,.294.262l.876.331a.559.559,0,0,0,.715-.322l.2-.5a3.831,3.831,0,0,0,.962-.007l.23.523.014.028a.55.55,0,0,0,.719.251l.863-.39a.559.559,0,0,0,.283-.731l-.214-.485a3.935,3.935,0,0,0,.678-.689l.547.206a.559.559,0,0,0,.715-.321l.343-.883a.553.553,0,0,0-.024-.451.543.543,0,0,0-.293-.262l-.511-.195a3.837,3.837,0,0,0-.006-.954l.451-.2a.559.559,0,0,0,.283-.731l-.379-.86-.014-.027a.55.55,0,0,0-.719-.251l-.43.194a3.775,3.775,0,0,0-.789-.732l.192-.493a.553.553,0,0,0-.024-.451.546.546,0,0,0-.294-.262l-.878-.331a.559.559,0,0,0-.715.322l-.217.557a3.848,3.848,0,0,0-.9.054l-.217-.493-.014-.028a.551.551,0,0,0-.719-.251l-.861.39a.559.559,0,0,0-.283.731m1.506.136a.512.512,0,0,0,.572.266,3.12,3.12,0,0,1,1.007-.063.523.523,0,0,0,.537-.33l.21-.541.536.2-.189.486a.517.517,0,0,0,.021.422.511.511,0,0,0,.185.2,3.045,3.045,0,0,1,.848.785.512.512,0,0,0,.627.161l.419-.189.23.524-.439.2a.52.52,0,0,0-.3.565,3.1,3.1,0,0,1,.007,1.053.512.512,0,0,0,.324.564l.5.19-.211.543-.534-.2a.52.52,0,0,0-.608.19,3.189,3.189,0,0,1-.75.76.522.522,0,0,0-.174.63l.21.478-.531.24-.224-.51-.013-.026a.508.508,0,0,0-.1-.132.516.516,0,0,0-.451-.139,3.105,3.105,0,0,1-1.061.007.523.523,0,0,0-.571.326l-.193.5-.536-.2.211-.542a.518.518,0,0,0-.021-.422.51.51,0,0,0-.157-.183,3.055,3.055,0,0,1-.643-.623.513.513,0,0,0-.621-.154l-.583.263-.23-.524.572-.256a.518.518,0,0,0,.3-.576,3.11,3.11,0,0,1-.033-1.013.512.512,0,0,0-.327-.547l-.511-.194.211-.543.492.185a.523.523,0,0,0,.618-.2,3.209,3.209,0,0,1,.687-.756.52.52,0,0,0,.154-.615l-.231-.525.531-.24.214.485.013.026"
      transform="translate(-417.581 -194.38)"
    />
  </svg>
</template>
