<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19" height="17.128"
    viewBox="0 0 19 17.128">
    <g id="Group_976" data-name="Group 976" transform="translate(1 3)">
      <g id="Group_975" data-name="Group 975">
        <path id="Path_426" data-name="Path 426"
          d="M16.537,0H.464A.536.536,0,0,0,0,.587V4.958a.536.536,0,0,0,.464.587H16.537A.536.536,0,0,0,17,4.958V.587A.536.536,0,0,0,16.537,0M15.46,3.6H1.54V1.95H15.46Z"
          transform="translate(0 -1.164)" fill="#676767" stroke="#676767" stroke-width="0.5" />
        <path id="Path_427" data-name="Path 427"
          d="M35.651,33.192H24.237a.786.786,0,0,0-.786.786v8.715a.786.786,0,0,0,.786.786H35.651a.786.786,0,0,0,.786-.786V33.978a.786.786,0,0,0-.786-.786M34.9,41.939H24.991V34.732H34.9Z"
          transform="translate(-21.444 -30.352)" fill="#676767" stroke="#676767" stroke-width="0.5" />
        <path id="Path_428" data-name="Path 428" d="M81.113,62.714H78.89a.7.7,0,0,1,0-1.394h2.223a.7.7,0,1,1,0,1.394"
          transform="translate(-71.502 -56.073)" fill="#676767" stroke="#676767" stroke-width="0.5" />
      </g>
    </g>
  </svg>
</template>
