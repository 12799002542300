<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="6.674" height="10.904" viewBox="0 0 6.674 10.904">
    <path
      id="Path_416"
      data-name="Path 416"
      d="M6225.719-5221.722l5.452,5.452-5.452,5.452-1.222-1.222,4.23-4.23-4.23-4.23Z"
      transform="translate(-6224.497 5221.722)"
    />
  </svg>
</template>
