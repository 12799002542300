import { CustomRouteRecord } from '@/types/router/router';

const bangchakAnalyticsRoute: Array<CustomRouteRecord> = [
  {
    path: '/analytics/dashboard/bangchak',
    name: 'analytics-dashboard-brandCustom',
    component: () => import('@views/modules/custom-brands/bangchak/analytics/pages/bi-dashboard/BusinessIntelligence.vue'),
    breadcrumb: [
      {
        text: 'bi.dashboard',
      },
    ],
    meta: {
      layout: 'full',
    },
  },
];

export default bangchakAnalyticsRoute;
