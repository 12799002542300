<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 110 110" style="enable-background:new 0 0 110 110;" xml:space="preserve">
    <g id="Group_31567" transform="translate(-1563 -508.199)">
      <g id="Group_31563">
        <g>
          <g id="Group_31558" transform="translate(1576.933 519.127)">
            <path id="Path_21238" style="fill:#0064BA;" d="M58.62,35.39c0,1.27-1.03,2.3-2.3,2.3c-1.27,0-2.3-1.03-2.3-2.3
					c0-1.27,1.03-2.3,2.3-2.3S58.62,34.12,58.62,35.39L58.62,35.39" />
            <path id="Path_21239" style="fill:#0064BA;" d="M28.12,50.36c0,1.27-1.03,2.3-2.3,2.3c-1.27,0-2.3-1.03-2.3-2.3
					c0-1.27,1.03-2.3,2.3-2.3C27.09,48.06,28.12,49.09,28.12,50.36L28.12,50.36" />
            <path id="Path_21240" style="fill:#0064BA;" d="M57.29,19.19c-0.35,0-0.67,0.17-0.87,0.45L55,21.59l-1.74-2.64
					c-0.32-0.49-0.98-0.62-1.47-0.3c-0.12,0.08-0.23,0.19-0.31,0.32l-1.79,2.68l-2.21-2.83c-0.36-0.46-1.03-0.54-1.49-0.18
					c-0.09,0.07-0.18,0.16-0.24,0.27l-1.7,2.69h-0.1l-1.8-2.67c-0.33-0.48-0.99-0.61-1.47-0.28c-0.12,0.08-0.22,0.19-0.3,0.31
					l-1.79,2.69l-2.21-2.82c-0.36-0.46-1.02-0.54-1.48-0.18c-0.1,0.08-0.18,0.17-0.24,0.27l-1.8,2.69l-1.8-2.67
					c-0.33-0.48-0.99-0.61-1.47-0.28c-0.12,0.08-0.22,0.19-0.3,0.31l-1.79,2.68l-1.61-2.09c-0.36-0.46-1.02-0.55-1.48-0.2
					c-0.26,0.2-0.42,0.51-0.41,0.85v23.84c0,1.1,0.89,1.99,1.99,1.99s1.99-0.89,1.99-1.99c0,0,0,0,0,0V27.5l2.2-2.5l0.13-0.03
					l0.02-0.03l1.8,2.66c0.33,0.48,0.98,0.61,1.47,0.29c0.12-0.08,0.23-0.19,0.3-0.31l1.87-2.95h0.1l2.2,2.83
					c0.36,0.46,1.02,0.54,1.48,0.18c0.1-0.08,0.18-0.17,0.24-0.27l1.53-2.42h0.1l1.8,2.66c0.33,0.48,0.98,0.61,1.47,0.28
					c0.12-0.08,0.22-0.19,0.3-0.31l1.87-2.95h0.1l2.2,2.82c0.36,0.46,1.02,0.54,1.48,0.18c0.1-0.08,0.18-0.17,0.24-0.27L52.37,25
					h0.1c0.68,1.08,1.69,2.64,1.91,3.03v0.99c-0.02,1,0.68,1.87,1.66,2.07c1.08,0.19,2.12-0.54,2.3-1.63
					c0.02-0.11,0.03-0.22,0.03-0.34v-8.9c0-0.58-0.46-1.05-1.04-1.06C57.31,19.19,57.3,19.19,57.29,19.19" />
            <path id="Path_21241" style="fill:#0064BA;" d="M56.07,40.36c-1.01,0.19-1.73,1.09-1.7,2.12v17.74c0,0.01,0,0.02,0,0.03
					l-1.94,2.8c-0.02,0.03-0.06,0.03-0.08,0.02c-0.01,0-0.01-0.01-0.02-0.02l-1.43-2.27c-0.31-0.49-0.96-0.64-1.46-0.33
					c-0.1,0.07-0.19,0.15-0.27,0.24l-2.27,2.77c-0.02,0.03-0.06,0.03-0.08,0.01c0,0-0.01-0.01-0.01-0.01l-1.82-2.89
					c-0.31-0.49-0.96-0.64-1.46-0.33c-0.12,0.08-0.23,0.18-0.31,0.3l-1.8,2.66h-0.1l-1.53-2.43c-0.31-0.49-0.96-0.64-1.46-0.33
					c-0.1,0.07-0.19,0.15-0.27,0.24l-2.2,2.82h-0.1l-1.87-2.95c-0.31-0.49-0.97-0.64-1.46-0.33c-0.12,0.08-0.23,0.18-0.31,0.3
					l-1.85,2.69l-0.1-0.09l-2.18-2.5c-0.01-0.01-0.02-0.02-0.02-0.04v-3.37c0-1.1-0.89-1.99-1.99-1.99c-1.1,0-1.99,0.89-1.99,1.99
					v10.71c0,0.58,0.47,1.06,1.05,1.06c0.33,0,0.64-0.15,0.84-0.41l1.65-2.02c0.02-0.03,0.06-0.03,0.08-0.01
					c0,0,0.01,0.01,0.01,0.01l1.65,2.61c0.31,0.49,0.97,0.64,1.46,0.33c0.12-0.08,0.23-0.18,0.31-0.3l1.85-2.59
					c0.02-0.03,0.06-0.03,0.08-0.01c0,0,0.01,0.01,0.01,0.01l1.65,2.61c0.31,0.49,0.96,0.64,1.46,0.33c0.1-0.07,0.19-0.15,0.27-0.24
					l2.25-2.76c0.02-0.03,0.06-0.03,0.08-0.01c0,0,0.01,0.01,0.01,0.01l1.65,2.62c0.31,0.49,0.96,0.64,1.46,0.33
					c0.12-0.08,0.23-0.18,0.31-0.3l1.86-2.59c0.02-0.03,0.06-0.03,0.08-0.01c0,0,0.01,0.01,0.01,0.01l1.65,2.61
					c0.31,0.49,0.96,0.64,1.46,0.33c0.1-0.07,0.19-0.15,0.27-0.24l2.25-2.76c0.02-0.03,0.05-0.03,0.08-0.01
					c0.01,0,0.01,0.01,0.01,0.01l1.65,2.6c0.31,0.49,0.96,0.64,1.46,0.33c0.13-0.08,0.24-0.19,0.32-0.31L55,66.52h0.1l1.33,1.95
					c0.19,0.29,0.52,0.46,0.87,0.45c0.58,0.01,1.06-0.46,1.06-1.04c0-0.01,0-0.02,0-0.02V42.34c0-1.1-0.9-1.99-2-1.99
					c-0.09,0-0.18,0.01-0.27,0.02" />
            <path id="Path_21242" style="fill:#0064BA;"
              d="M46.53,48.07c0.01-0.74-0.15-1.48-0.45-2.15c-0.38-0.77-0.89-1.47-1.51-2.06
					c-0.28-0.29-1.1-1-2.44-2.12c-0.92-0.73-1.78-1.52-2.58-2.37c-0.2-0.25-0.31-0.56-0.31-0.88c0.01-0.33,0.17-0.64,0.44-0.83
					c0.32-0.26,0.73-0.39,1.14-0.37c0.68,0.03,1.32,0.31,1.8,0.78c0.69,0.64,1.77,0.59,2.4-0.1c0.64-0.69,0.59-1.77-0.1-2.4
					c-0.02-0.02-0.05-0.04-0.07-0.06c-0.41-0.37-0.86-0.69-1.35-0.95c-0.45-0.23-0.92-0.4-1.42-0.5v-1.7c0-0.75-0.61-1.36-1.36-1.36
					c-0.75,0-1.36,0.61-1.36,1.36v1.73c0,0.02,0,0.03,0,0.05c-0.79,0.21-1.51,0.6-2.11,1.16c-0.94,0.84-1.47,2.04-1.46,3.29
					c0.02,0.96,0.33,1.88,0.88,2.67c1.13,1.43,2.44,2.71,3.9,3.8c0.75,0.57,1.43,1.21,2.05,1.92c0.28,0.36,0.44,0.8,0.45,1.25
					c-0.01,0.48-0.24,0.93-0.62,1.22c-0.42,0.36-0.97,0.54-1.52,0.53c-0.9-0.04-1.73-0.49-2.25-1.22c-0.66-0.71-1.77-0.76-2.49-0.1
					c-0.65,0.6-0.75,1.59-0.24,2.31c0.41,0.54,0.91,1,1.47,1.38c0.59,0.38,1.25,0.65,1.94,0.8c0,0.02-0.01,0.05-0.01,0.07v1.73
					c0,0.75,0.61,1.36,1.36,1.36c0.75,0,1.36-0.61,1.36-1.36V53.2c1.08-0.16,2.08-0.64,2.88-1.38c1.04-0.97,1.61-2.33,1.57-3.75" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>