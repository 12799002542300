<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="26.923" height="27.793" viewBox="0 0 26.923 27.793">
    <g id="Group_46005" data-name="Group 46005" transform="translate(-370 -721)">
      <path
        id="Path_29275"
        data-name="Path 29275"
        d="M2.244,9.309A1.346,1.346,0,0,0,3.59,7.963V2.692H8.526A1.346,1.346,0,0,0,8.526,0H2.244A1.346,1.346,0,0,0,.9,1.346a1.312,1.312,0,0,0,.017.167A1.328,1.328,0,0,0,.9,1.681V7.963A1.346,1.346,0,0,0,2.244,9.309"
        transform="translate(370 721)"
        fill="#05203d"
      />
      <path
        id="Path_29276"
        data-name="Path 29276"
        d="M18.4,2.692h4.936V7.963a1.346,1.346,0,1,0,2.692,0V1.681a1.328,1.328,0,0,0-.017-.168,1.312,1.312,0,0,0,.017-.167A1.345,1.345,0,0,0,24.679,0H18.4a1.346,1.346,0,0,0,0,2.692"
        transform="translate(370 721)"
        fill="#05203d"
      />
      <path
        id="Path_29277"
        data-name="Path 29277"
        d="M8.526,25.1H3.59V19.83a1.346,1.346,0,1,0-2.692,0v6.282a1.328,1.328,0,0,0,.017.168,1.312,1.312,0,0,0-.017.167,1.345,1.345,0,0,0,1.346,1.346H8.526a1.346,1.346,0,1,0,0-2.692"
        transform="translate(370 721)"
        fill="#05203d"
      />
      <path
        id="Path_29278"
        data-name="Path 29278"
        d="M24.68,18.484a1.346,1.346,0,0,0-1.346,1.346V25.1H18.4a1.346,1.346,0,1,0,0,2.692H24.68a1.346,1.346,0,0,0,1.346-1.346,1.312,1.312,0,0,0-.017-.167,1.328,1.328,0,0,0,.017-.168V19.83a1.346,1.346,0,0,0-1.346-1.346"
        transform="translate(370 721)"
        fill="#05203d"
      />
      <path
        id="Path_29279"
        data-name="Path 29279"
        d="M10.769,17.025H6.282a.9.9,0,0,0-.9.9V22.41a.9.9,0,0,0,.9.9h4.487a.9.9,0,0,0,.9-.9V17.922a.9.9,0,0,0-.9-.9"
        transform="translate(370 721)"
        fill="#05203d"
      />
      <path
        id="Path_29280"
        data-name="Path 29280"
        d="M5.385,5.359V9.846a.9.9,0,1,0,1.795,0V5.359a.9.9,0,0,0-1.795,0"
        transform="translate(370 721)"
        fill="#05203d"
      />
      <path
        id="Path_29281"
        data-name="Path 29281"
        d="M19.744,5.359V9.846a.9.9,0,1,0,1.8,0V5.359a.9.9,0,0,0-1.8,0"
        transform="translate(370 721)"
        fill="#05203d"
      />
      <path
        id="Rectangle_17692"
        data-name="Rectangle 17692"
        d="M.9,0h.9a.9.9,0,0,1,.9.9V5.384a.9.9,0,0,1-.9.9H.9a.9.9,0,0,1-.9-.9V.9A.9.9,0,0,1,.9,0Z"
        transform="translate(378.077 725.462)"
        fill="#05203d"
      />
      <path
        id="Path_29282"
        data-name="Path 29282"
        d="M15.257,10.743h.9a.9.9,0,0,0,.9-.9V5.358a.9.9,0,0,0-.9-.9h-.9a.9.9,0,0,0-.9.9V9.846a.9.9,0,0,0,.9.9"
        transform="translate(370 721)"
        fill="#05203d"
      />
      <path
        id="Path_29283"
        data-name="Path 29283"
        d="M12.115,10.743a.449.449,0,0,0,.449-.449V4.91a.449.449,0,1,0-.9,0v5.384a.449.449,0,0,0,.449.449"
        transform="translate(370 721)"
        fill="#05203d"
      />
      <path
        id="Path_29284"
        data-name="Path 29284"
        d="M17.949,4.91V10.3a.449.449,0,0,0,.9,0V4.91a.449.449,0,1,0-.9,0"
        transform="translate(370 721)"
        fill="#05203d"
      />
      <path
        id="Path_29285"
        data-name="Path 29285"
        d="M21.538,22.41V17.923a.9.9,0,0,0-.9-.9H16.153a.9.9,0,0,0-.9.9V22.41a.9.9,0,0,0,.9.9h4.489a.9.9,0,0,0,.9-.9"
        transform="translate(370 721)"
        fill="#05203d"
      />
      <path
        id="Path_29286"
        data-name="Path 29286"
        d="M25.577,12.538H1.346a1.346,1.346,0,1,0,0,2.692H25.577a1.346,1.346,0,1,0,0-2.692"
        transform="translate(370 721)"
        fill="#05203d"
      />
    </g>
  </svg>
</template>
