export enum Channel {
  allChannel,
  sms,
  lineOA,
  email,
  facebook,
  Instagram,
  website,
  application,
}

export const CHANNEL_VALUE = {
  ALL_CHANNEL: 0,
  SMS: 1,
  EMAIL: 2,
  LINE: 3,
};
