import { DateTime } from 'luxon';

type RepeatMode = 0 | 1 | 2 | 3 | 4 | null; //none/day/week/month/year

export const resolveSchedule = (
  mode: number,
  scheduleDt: string | null,
  startOnLabel?: string | null,
  repeatMode?: RepeatMode,
  repeatEveryLabel?: string | null,
  repeatOnVal?: number[] | null,
): string[] => {
  const resolveString: any = [];
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null');
  const timezone = userInfo.timezone || DateTime.fromISO(new Date().toISOString()).zoneName;
  const scheduleDateTime = scheduleDt ? DateTime.fromISO(scheduleDt, { zone: timezone }) : null;

  if (mode == 1) {
    resolveString.push(startOnLabel);
  } else if (mode == 2) {
    resolveString.push(
      `${
        scheduleDateTime
          ? `${scheduleDateTime.setLocale('th').toFormat('cccc')}ที่ ${scheduleDateTime
              .setLocale('th')
              .toFormat('dd LLLL yyyy')} เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.`
          : ''
      }`,
    );
  } else if (mode == 3) {
    if (repeatMode != 2) {
      resolveString.push(
        `${
          scheduleDateTime
            ? `${scheduleDateTime.setLocale('th').toFormat('cccc')}ที่ ${scheduleDateTime
                .setLocale('th')
                .toFormat('dd LLLL yyyy')} เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.`
            : ''
        }`,
      );
      resolveString.push(repeatEveryLabel, startOnLabel);
    } else {
      resolveString.push(
        scheduleDateTime
          ? `${scheduleDateTime.setLocale('th').toFormat('cccc')}ที่ ${scheduleDateTime
              .setLocale('th')
              .toFormat('dd LLLL yyyy')} เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.`
          : '',
      );
      resolveString.push(repeatEveryLabel, startOnLabel);
      repeatOnVal?.forEach((value) => {
        switch (value) {
          case 1:
            resolveString.push(`ส่งทุกวันอาทิตย์${scheduleDateTime ? ` เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.` : ''}`);
            break;
          case 2:
            resolveString.push(`ส่งทุกวันจันทร์${scheduleDateTime ? ` เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.` : ''}`);
            break;
          case 4:
            resolveString.push(`ส่งทุกวันอังคาร${scheduleDateTime ? ` เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.` : ''}`);
            break;
          case 8:
            resolveString.push(`ส่งทุกวันพุธ${scheduleDateTime ? ` เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.` : ''}`);
            break;
          case 16:
            resolveString.push(`ส่งทุกวันพฤหัสบดี${scheduleDateTime ? ` เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.` : ''}`);
            break;
          case 32:
            resolveString.push(`ส่งทุกวันศุกร์${scheduleDateTime ? ` เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.` : ''}`);
            break;
          case 64:
            resolveString.push(`ส่งทุกวันเสาร์${scheduleDateTime ? ` เวลา ${scheduleDateTime.toFormat('HH:mm:ss')} น.` : ''}`);
            break;
        }
      });
    }
  }

  return resolveString;
};

export const resolveEndDate = (dateString: string) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null');
  const timezone = userInfo.timezone || DateTime.fromISO(new Date().toISOString()).zoneName;
  const endDate = dateString
    ? `${DateTime.fromISO(dateString, { zone: timezone }).setLocale('th').toFormat('cccc')}ที่ ${DateTime.fromISO(dateString, { zone: timezone })
        .setLocale('th')
        .toFormat('dd LLLL yyyy')} เวลา ${DateTime.fromISO(dateString, {
        zone: timezone,
      }).toFormat('HH:mm:ss')} น. `
    : '-';
  return endDate;
};
