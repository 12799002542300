export default function () {
  const fetchChartSetListModel: API.RequestModel<Record<string, never>> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSChart/SetList`,
    method: 'GET',
    payload: {},
  };

  const fetchChartAddModel: API.RequestModel<INS.INSChartAdd.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSChart/Add`,
    method: 'POST',
    payload: {
      PageId: null!,
      Charts: [],
    },
  };

  const fetchCustomerInsightChartDataModel: API.RequestModel<INS.INSChartData.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSChart/Data`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      CId: null!,
      Filters: [],
      CustomerView: false,
    },
  };

  const fetchCustomerInsightChartSettingInfoModel: API.RequestModel<INS.INSChartSetting.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSChart/SettingInfo`,
    method: 'GET',
    payload: {
      ChartLayoutId: null!,
      CId: null!,
      ChartSetId: null!,
      ChartId: null!,
      CustomerView: false,
    },
  };

  const fetchCustomerInsightChartSettingEditModel: API.RequestModel<INS.INSChartSetting.Edit.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSChart/SettingEdit`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      CId: null!,
      ChartId: null!, // From chart_type_list => is_default = true
      Chart_Color_List: [],
    },
  };

  const fetchCustomerInsightChartDataInfoModel: API.RequestModel<INS.INSChartDataInfo.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSChart/DataInfo`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      CId: null!,
      ChartSetId: null!,
      ChartId: null!,
      Impl_Type: null!,
      Impl_Key_Ref: '',
      Filters: [],
    },
  };

  const fetchCustomerInsightChartGlobalFilterListModel: API.RequestModel<Record<string, never>> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSChart/GlobalFilterList`,
    method: 'GET',
    payload: {},
  };

  const fetchCustomerInsightChartExportDataInfoModel: API.RequestModel<INS.INSChartDataInfo.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSChart/ExportDataInfo`,
    method: 'POST',
    payload: {
      ChartLayoutId: null!,
      CId: null!,
      ChartSetId: null!,
      ChartId: null!,
      Impl_Type: null!,
      Impl_Key_Ref: '',
      Filters: [],
    },
    responseType: 'blob',
  };

  return {
    fetchChartSetListModel,
    fetchChartAddModel,
    fetchCustomerInsightChartDataModel,
    fetchCustomerInsightChartSettingInfoModel,
    fetchCustomerInsightChartSettingEditModel,
    fetchCustomerInsightChartDataInfoModel,
    fetchCustomerInsightChartGlobalFilterListModel,
    fetchCustomerInsightChartExportDataInfoModel,
  };
}
