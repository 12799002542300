<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.071" height="30" viewBox="0 0 17.071 33">
    <g id="Group_44807" data-name="Group 44807" transform="translate(-3489 -3069)">
      <text id="A" transform="translate(3489 3096)" fill="#0f2a4e" font-size="25" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">A</tspan></text>
      <path
        id="Path_28720"
        data-name="Path 28720"
        d="M3498.893,3081.009l2.792,2.261,2.927-2.261"
        transform="translate(1 3.386)"
        fill="none"
        stroke="#0080ff"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>
