import { ref, reactive, Ref, watch, onMounted } from 'vue';
import router from '@/router';
import api from '@services/api';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import dynamicTableModel from '@/models/component/table/dynamicTable';

import segmentResultModel from '@models/segment/result';

import useFilters from '@/utils/hooks/useFilters';
import useConvertDate from '@/utils/hooks/useConvertDate';
import { useI18n } from 'vue-i18n';

interface DropdownListItem {
  title: string;
  value: number;
  icon: object;
  type: number;
}

interface SegmentViewmode {
  selectedOption: {
    mode: number;
    title: string;
    type: number;
  };
}

export default function usePreviewSegment() {
  const { openDefaultErrorModal } = useValidationModal();
  const { fetchSegmentResultModel } = segmentResultModel();
  const { updateDisplayColumnModel } = dynamicTableModel();

  const { filterDisplayObj } = useFilters();
  const { convertUTCToDeviceTimeZone } = useConvertDate();
  const { t } = useI18n();

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 20,
    totalRows: 0,
  });

  const columns: Ref<DynamicTable.Column[]> = ref([]);
  const rowSort: Ref<boolean> = ref(false);
  const columnSort: Ref<boolean> = ref(false);
  const loading: Ref<boolean> = ref(false);
  const DatasourceDisplay: Ref<Array<any>> = ref([]);
  const previewSegmentList: Ref<Record<string, any>[]> = ref([]);
  const uniqueKey: Ref<string> = ref('');
  const tableIdForSegment: Ref<number> = ref(0);
  const OrderingSeq: Ref<number> = ref(0);
  const OrderingKey: Ref<string> = ref('');
  const OrderingDirection: Ref<string> = ref('');
  const isModalSubmitOpen: Ref<boolean> = ref(false);
  const submitType: Ref<number> = ref(0);
  const textSearch: Ref<string> = ref('');
  const isOpenCheckbox: Ref<boolean> = ref(false);
  const lastUpDateTime: Ref<string> = ref('');
  const filtersObj: Ref<Array<any>> = ref([]);
  const payloadForUpdate: Ref<any> = ref();
  let interval: ReturnType<typeof setInterval> = null!;
  const showLastUpdate: Ref<string> = ref('');
  const totalRecord: Ref<number> = ref(0);
  const errorCount: Ref<number> = ref(0);
  const selectViewMode: SegmentViewmode = reactive({
    selectedOption: {
      mode: 0,
      title: '',
      type: 1,
    },
  });
  const retrySelectedViewMode: Ref<boolean> = ref(false);

  const fetchSegmentResultList = () => {
    loading.value = true;

    if (OrderingDirection.value) {
      fetchSegmentResultModel.payload.Ordering = [
        {
          Seq: OrderingSeq.value,
          Key: OrderingKey.value,
          Direction: OrderingDirection.value,
        },
      ];
    } else {
      fetchSegmentResultModel.payload.Ordering = [];
    }

    fetchSegmentResultModel.payload.SegmentId = router.currentRoute.value.query.applySegmentId;
    fetchSegmentResultModel.payload.Page = pagination.currentPage;
    fetchSegmentResultModel.payload.Limit = pagination.perPage;
    fetchSegmentResultModel.payload.Filters = filtersObj.value;
    fetchSegmentResultModel.payload.Search_Value = textSearch.value;
    fetchSegmentResultModel.payload.Flag_Count = true;

    fetchSegmentResultModel.payload.ResultType = selectViewMode.selectedOption.type;
    fetchSegmentResultModel.payload.View_Select_Main = selectViewMode.selectedOption.mode;

    // fetchSegmentResultModel.payload.Filters = filter.value.length != 0 ? filter.value[0].filters : [];
    // fetchSegmentResultModel.payload.Ordering = ordering.value.length != 0 ? [ordering.value] : [];

    clearTimeout(interval);

    api
      .apiRequest(fetchSegmentResultModel)
      .then((response) => {
        findUniqueKey(response.data.datasourcedisplay.columns);
        columns.value = response.data.datasourcedisplay.columns;
        tableIdForSegment.value = response.data.datasourcedisplay.tableid;
        previewSegmentList.value = response.data.segment_result;
        pagination.totalRows = response.data.filtered_record;
        totalRecord.value = response.data.segment_size;
        DatasourceDisplay.value = response.data.datasourcedisplay;
        lastUpDateTime.value = response.data.updated_dt;
        if (response.status == 'success') {
          retrySelectedViewMode.value = false;
          loading.value = false;
          onSetCheckBoxTable();
          onTimerPreview();
        }
      })
      .catch((error) => {
        ++errorCount.value;
        if (error.status != 422 && errorCount.value < 5) {
          retryPayload();
        } else {
          openDefaultErrorModal(error);
        }
      })
      .finally(() => {
        filtersObj.value = [];
      });
  };

  const retryPayload = () => {
    fetchSegmentResultList();
  };

  const onTimerPreview = () => {
    const CurrentDate = new Date().getTime();
    const apiDate = lastUpDateTime.value;
    const resultDate = new Date(apiDate).getTime() + (CurrentDate - new Date(apiDate).getTime());

    if (interval) {
      clearTimeout(interval);
    }

    interval = setInterval(() => {
      showLastUpdate.value = convertUTCToDeviceTimeZone(new Date(resultDate).toISOString());
    }, 1000);
  };

  const handleColumnChange = (col: Array<any>) => {
    columns.value = col;
    const displayList: DynamicTable.UpdateDisplay[] = [];
    col.map((column, index) => {
      displayList.push({
        ColumnId: column.column_id,
        Key_Column: column.key,
        Seq: index + 1,
      });
    });
    updateDisplayColumnModel.payload = displayList;
    payloadForUpdate.value = updateDisplayColumnModel.payload;
  };

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    OrderingSeq.value = 1;
    OrderingKey.value = sortedColumn.key;
    OrderingDirection.value = sortedColumn.direction;

    fetchSegmentResultList();
  };

  function findUniqueKey(_columns: PreviewSegment.DatasourceDisplayColumns[]): void {
    const found = _columns.find((el: PreviewSegment.DatasourceDisplayColumns) => el.is_primary_key === true);
    if (found) {
      uniqueKey.value = found.key;
    } else {
      throw 'uniqueKey not found';
    }
  }

  const onOpenEditname = () => {
    isModalSubmitOpen.value = true;
  };

  const onSelectTypeSubmit = (type: DropdownListItem) => {
    submitType.value = type.value;
    isModalSubmitOpen.value = true;
  };

  const closeModalSubmit = () => {
    isModalSubmitOpen.value = false;
  };

  const countTotal = (total: number, length: number, status: boolean) => {
    if (length > 0 && status == true)
      return (
        t('segment.preview_segment.table.statusbar_total_record_button_all') +
        (total - length) +
        t('segment.preview_segment.table.statusbar_total_record_button_inbox')
      );
    if (length == 0 && status == true) return t('segment.preview_segment.table.statusbar_total_record_button_clear');
    else
      return (
        t('segment.preview_segment.table.statusbar_total_record_button_all') +
        length +
        t('segment.preview_segment.table.statusbar_total_record_button_inbox')
      );
  };

  const onSetCheckBoxTable = () => {
    if (Number(router.currentRoute.value.query.type) > 2 || router.currentRoute.value.query.type) {
      isOpenCheckbox.value = false;
    } else {
      isOpenCheckbox.value = true;
    }
  };

  let searchTimeout: ReturnType<typeof setTimeout> = null!;
  const onChangeSearch = () => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      if (textSearch.value != '') {
        filtersObj.value = filterDisplayObj(columns.value, textSearch.value);
      }
      fetchSegmentResultList();
    }, 500);
  };

  const selectObj = (selected: SegmentViewmode) => {
    selectViewMode.selectedOption.mode = selected.selectedOption.mode;
    selectViewMode.selectedOption.title = selected.selectedOption.title;
    selectViewMode.selectedOption.type = selected.selectedOption.type;
    pagination.currentPage = 1;
    fetchSegmentResultList();
  };

  watch(
    () => router.currentRoute.value,
    (newValue, oldValue) => {
      if (router.currentRoute.value.query.segmentMode == '2' && newValue.query.countApply && oldValue?.name == 'data-segment-preview') {
        retrySelectedViewMode.value = true;
        selectViewMode.selectedOption.type = 1;
        selectViewMode.selectedOption.mode = 0;
        pagination.currentPage = 1;
        fetchSegmentResultList();
      }
    },
    { immediate: true },
  );

  watch(
    () => pagination.currentPage,
    () => {
      if (textSearch.value.length == 0) return fetchSegmentResultList();
      else return onChangeSearch();
    },
  );

  watch(
    () => textSearch.value,
    () => {
      pagination.currentPage = 1;
      onChangeSearch();
    },
  );

  onMounted(() => {
    if (router.currentRoute.value.name == 'data-segment-preview') fetchSegmentResultList();
  });

  return {
    columns,
    previewSegmentList,
    rowSort,
    columnSort,
    pagination,
    loading,
    uniqueKey,
    isModalSubmitOpen,
    DatasourceDisplay,
    submitType,
    textSearch,
    isOpenCheckbox,
    showLastUpdate,
    totalRecord,
    selectViewMode,
    filtersObj,
    onSort,
    onSelectTypeSubmit,
    closeModalSubmit,
    onChangeSearch,
    countTotal,
    onOpenEditname,
    selectObj,
    retrySelectedViewMode,
    handleColumnChange,
    payloadForUpdate,
  };
}
