<template>
  <div class="app-content">
    <div class="content-scroll">
      <div class="content-wrapper">
        <div class="content-body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@assets/styles/base/app-content.scss';
</style>
