<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 150 150" style="enable-background:new 0 0 150 150;" xml:space="preserve">
<g id="Group_28929_00000178903283739460252860000012137917325749715626_" transform="translate(13.863) rotate(45)">
	<g id="Group_28927_00000168080841021563651160000012570117143566318245_" transform="translate(0 0)">
		<path id="Path_17469_00000020398450634513630160000011770534447893163418_" class="st2" d="M139.97,30.8H115.1V6.08
			c0.06-3.66-2.79-6.72-6.45-6.93c-3.67-0.11-6.73,2.77-6.84,6.44c0,0.07,0,0.14,0,0.2v25H90.72V6.08c0.06-3.66-2.78-6.72-6.44-6.93
			c-3.67-0.11-6.73,2.77-6.84,6.44c0,0.07,0,0.14,0,0.2v25H52.56c-2.87-0.06-5.33,2.06-5.68,4.91c-0.3,2.99,1.88,5.66,4.87,5.96
			c0.19,0.02,0.38,0.03,0.57,0.03h10.75v11.7c0,15.61,10.88,29.11,26.14,32.44v5.91c0,3.9,3.16,7.05,7.05,7.06
			c3.9,0,7.05-3.16,7.06-7.05c0,0,0,0,0-0.01v-5.91c15.27-3.31,26.17-16.81,26.17-32.44V41.7h10.71c3.01,0.02,5.46-2.41,5.47-5.42
			c0-0.19-0.01-0.38-0.03-0.57C145.29,32.86,142.85,30.74,139.97,30.8"/>
		<path id="Path_17470_00000150795186962329571600000017383948422745289398_" class="st2" d="M52.32-11.24h87.87
			c3.01,0.02,5.46-2.41,5.47-5.42c0-0.19-0.01-0.38-0.03-0.57c-0.35-2.85-2.8-4.97-5.66-4.92h-10.49v-11.7
			c0-15.62-10.9-29.12-26.16-32.44v-5.85c0-3.9-3.16-7.05-7.05-7.06c-3.9,0-7.05,3.16-7.06,7.05c0,0,0,0,0,0.01v5.85
			c-15.26,3.32-26.14,16.82-26.14,32.44v11.71H52.56c-2.87-0.06-5.33,2.06-5.68,4.91c-0.3,2.99,1.88,5.66,4.87,5.96
			C51.94-11.25,52.13-11.24,52.32-11.24"/>
	</g>
</g>
</svg>
</template>

<style type="text/css" scoped>
	.st0{fill:#FFFFFF;stroke:#231F20;stroke-miterlimit:10;}
	.st1{fill:#343434;}
	.st2{fill:#484848;}
	.st3{fill:#FFFFFF;}
</style>
