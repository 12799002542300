export default {
  DEFAULT_ERROR_MESSAGES: {
    th: {
      title: 'เกิดข้อผิดพลาด',
      message: 'เกิดข้อผิดพลาด กรุณาติดต่อ ChocoCRM',
    },
    en: {
      title: 'Something Went Wrong',
      message: 'Please, Contact ChocoCRM',
    },
  },
};
