<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15.11" height="17.987" viewBox="0 0 15.11 17.987">
    <defs></defs>
    <g id="Group_36081" data-name="Group 36081" transform="translate(-340 -346.574)">
      <path
        id="Path_23432"
        data-name="Path 23432"
        class="cls-1"
        d="M15.108,13.494V4.1A1.228,1.228,0,0,0,13.88,2.874H11.316V1.167A1.154,1.154,0,0,0,10.263,0a1.129,1.129,0,0,0-1.2,1.126V2.874H6.049V1.167A1.154,1.154,0,0,0,5,0a1.129,1.129,0,0,0-1.2,1.126V2.874H1.228A1.228,1.228,0,0,0,0,4.1V16.754a1.228,1.228,0,0,0,1.228,1.228H10.62l0,0h.84a1.392,1.392,0,0,0,.985-.408l.539-.539,1.188-1.188.537-.537a1.392,1.392,0,0,0,.408-.985v-.84Zm-1.184.233H12.037a1.184,1.184,0,0,0-1.184,1.184V16.8c0,.033,0,.132-.006.242H1.228a.286.286,0,0,1-.286-.286V6.644a.617.617,0,0,1,.617-.617h11.99a.617.617,0,0,1,.617.617v7.081l-.242,0"
        transform="translate(340 346.574)"
      />
      <path
        id="Path_23433"
        data-name="Path 23433"
        class="cls-1"
        d="M31.683,52.841a3.892,3.892,0,1,0,3.892,3.892,3.892,3.892,0,0,0-3.892-3.892m0,1.48A2.4,2.4,0,0,1,33,54.712l-3.55,2.918a2.409,2.409,0,0,1,2.237-3.309m0,4.825A2.394,2.394,0,0,1,30.45,58.8l3.5-2.879a2.405,2.405,0,0,1-2.269,3.222"
        transform="translate(315.948 300.843)"
      />
    </g>
  </svg>
</template>
