<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="31.806" height="22.9" viewBox="0 0 31.806 22.9">
    <g id="Group_112" data-name="Group 112">
      <path
        id="Path_51"
        data-name="Path 51"
        d="M29.171,0H2.288A2.33,2.33,0,0,0,0,2.356V19.241A2.33,2.33,0,0,0,2.288,21.6H29.171a2.33,2.33,0,0,0,2.288-2.356V2.356A2.33,2.33,0,0,0,29.171,0M2.288,19.241V2.526l10.119,9.952-1.6-1.571L2.334,19.241Zm13.536-7.2L5.98,2.356H25.671Zm-3.083.77,1.493,1.468a2.25,2.25,0,0,0,3.18,0l1.493-1.468,6.545,6.435H6.2Zm8.1-1.9,8.33-8.191V19.1Z"
        transform="translate(0.231 0.628)"
        fill="#fff"
      />
    </g>
  </svg>
</template>
