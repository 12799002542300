<template>
  <svg id="Group_30076" data-name="Group 30076" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" width="17.659" height="20" viewBox="0 0 17.659 20">
    <g id="Group_30076-2" data-name="Group 30076">
      <path id="Path_21044" data-name="Path 21044"
        d="M17.028,4.538l-.649-.374L9.461.169A1.263,1.263,0,0,0,8.2.169L1.715,3.912.631,4.538A1.263,1.263,0,0,0,0,5.632v8.737a1.263,1.263,0,0,0,.631,1.094l5.895,3.4,1.672.965a1.265,1.265,0,0,0,1.263,0l.159-.092,2.048-1.183,5.359-3.094a1.263,1.263,0,0,0,.631-1.094V5.632a1.263,1.263,0,0,0-.631-1.094M8.829,2.17l5.5,3.176L9.365,8.213l-5.5-3.176ZM2.048,13.915V6.354L8.34,9.986h0v7.561h0Zm13.562,0-5.22,3.014h0V9.987h0l5.22-3.014Z"
        transform="translate(0 0)" />
    </g>
  </svg>
</template>
