<template>
  <svg fill="#E05240" viewBox="0 0 24 24" id="warning" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" class="icon flat-color">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <circle id="primary" cx="12" cy="12" r="10"></circle>
      <path class="path" id="secondary" d="M11,13V7a1,1,0,0,1,2,0v6a1,1,0,0,1-2,0Zm1,2.5A1.5,1.5,0,1,0,13.5,17,1.5,1.5,0,0,0,12,15.5Z"></path>
    </g>
  </svg>
</template>
<style lang="scss" scoped>
path {
  fill: #ffffff;
}
</style>
