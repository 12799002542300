<template>
  <svg
    id="Group_37459"
    data-name="Group 37459"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="14.919"
    height="15.699"
    viewBox="0 0 14.919 15.699"
  >
    <g id="Group_37464" data-name="Group 37464" transform="translate(0 0)">
      <path
        id="Path_24343"
        data-name="Path 24343"
        d="M236.586,4.672a4.526,4.526,0,0,0,4.527,4.39h0a4.531,4.531,0,0,0,1.763-8.7,4.529,4.529,0,0,0-6.293,4.174v.14Zm4.527,3.118a3.258,3.258,0,0,1-3.257-3.258V4.444a3.258,3.258,0,1,1,3.257,3.345"
        transform="translate(-233.661 -0.002)"
        fill="#c3c3c3"
      />
      <path
        id="Path_24344"
        data-name="Path 24344"
        d="M14.877,779.752a7.9,7.9,0,0,0-14.836,0v0a.709.709,0,0,0,.091.645h0a.726.726,0,0,0,.583.3H14.2a.724.724,0,0,0,.585-.3.7.7,0,0,0,.091-.647m-13.308-.326a6.63,6.63,0,0,1,11.779,0Z"
        transform="translate(0 -764.999)"
        fill="#c3c3c3"
      />
    </g>
  </svg>
</template>
