<template>
  <svg id="Group_45193" data-name="Group 45193" xmlns="http://www.w3.org/2000/svg" width="12.21" height="15.105" viewBox="0 0 12.21 15.105">
    <path id="Subtraction_285" data-name="Subtraction 285" d="M2.231,5.162H0L6.109,0l6.1,5.16H9.984L6.109,1.885,2.231,5.161Z" fill="#00b708" />
    <path
      id="Subtraction_286"
      data-name="Subtraction 286"
      d="M2.231,5.162H0L6.109,0l6.1,5.16H9.984L6.109,1.885,2.231,5.161Z"
      transform="translate(0 4.781)"
      fill="#00b708"
      opacity="0.6"
    />
    <path
      id="Subtraction_287"
      data-name="Subtraction 287"
      d="M2.231,5.162H0L6.109,0l6.1,5.16H9.984L6.109,1.885,2.231,5.161Z"
      transform="translate(0 9.943)"
      fill="#00b708"
      opacity="0.3"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg path {
  animation: 3s linear 1s infinite running fadeIn;
  &#Subtraction_285 {
    animation-delay: 1000ms;
  }
  &#Subtraction_286 {
    animation-delay: 500ms;
  }

  &#Subtraction_287 {
    animation-delay: 0ms;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0.6;
  }

  66% {
    opacity: 0.3;
  }

  100% {
    opacity: 0.1;
  }
}
</style>
