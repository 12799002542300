import { onMounted, ref, Ref, watch, reactive } from 'vue';

export default function useTooltipColumn() {
  //tooltip column
  const tooltipTextRef: Ref<HTMLElement | null> = ref(null);
  const tooltipStyle = reactive<{ top: string; left: string }>({ top: '', left: '' });
  const tooltipTextDetails: Ref<string> = ref('');
  const showTooltip = ref(false);
  const updateTooltipPosition = (e: MouseEvent, t: string) => {
    tooltipTextDetails.value = t;
    const targetElement = e.target as HTMLElement;
    if (targetElement) {
      const positionMouse = { x: e.clientX, y: e.clientY };
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (tooltipTextRef.value) {
        const widthTooltip = tooltipTextRef.value.offsetWidth;
        const heightTooltip = tooltipTextRef.value.offsetHeight;
        const position = positionTooltip([Number(positionMouse.x), Number(positionMouse.y)], {
          viewSize: [width, height],
          contentSize: [widthTooltip, heightTooltip],
        });
        tooltipStyle.top = position.top + 'px';
        tooltipStyle.left = position.left + 'px';
      }
    }
    showTooltip.value = true;
  };

  const hideTooltip = () => {
    showTooltip.value = false;
  };

  function positionTooltip(pos: Array<number>, size: Record<string, any>) {
    const obj: Ref<Record<string, number>> = ref({});
    const canvasX: number = pos[0];
    const canvasY: number = pos[1];
    const width_canvas: number = size.viewSize[0];
    const height_canvas: number = size.viewSize[1];
    const width_tooltip: number = size.contentSize[0];
    const height_tooltip: number = size.contentSize[1];
    //distance mouse X &  Tooltip X
    const distanceX = 10;

    //แบบที 1
    const percentHeight_canvas: number = (canvasY * 100) / height_canvas;
    const Y_tooltip: number = (height_tooltip * percentHeight_canvas) / 100;
    obj.value['top'] = canvasY - Y_tooltip;
    if (canvasX < width_canvas / 2) {
      obj.value['left'] = canvasX + distanceX;
    } else {
      obj.value['left'] = canvasX - (width_tooltip + distanceX);
    }

    //แบบที่ 2
    // var overSize = 0;
    // if (canvasY < height_canvas / 2) {
    //   if (canvasY + height_tooltip > height_canvas) {
    //     overSize = canvasY + height_tooltip - height_canvas;
    //     obj['top'] = canvasY - overSize;
    //   } else {
    //     obj['top'] = canvasY;
    //   }
    // } else {
    //   if (canvasY - height_tooltip < 0) {
    //     overSize = (canvasY - height_tooltip) * -1;
    //     obj['top'] = canvasY + overSize - height_tooltip;
    //   } else {
    //     obj['top'] = canvasY - height_tooltip;
    //   }
    // }
    // if (canvasX < width_canvas / 2) {
    //   obj['left'] = canvasX + distanceX;
    // } else {
    //   obj['left'] = canvasX - width_tooltip - distanceX;
    // }
    return obj.value;
  }

  //
  return {
    updateTooltipPosition,
    hideTooltip,
    showTooltip,
    tooltipStyle,
    tooltipTextRef,
    tooltipTextDetails,
  };
}
