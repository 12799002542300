<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21" fill="currentColor">
    <path
      d="M8.8 6.74219C8.04843 6.74219 7.31374 6.96505 6.68883 7.3826C6.06393 7.80015 5.57687 8.39362 5.28926 9.08798C5.00165 9.78234 4.92639 10.5464 5.07302 11.2835C5.21964 12.0207 5.58156 12.6977 6.113 13.2292C6.64444 13.7606 7.32153 14.1225 8.05866 14.2692C8.79579 14.4158 9.55984 14.3405 10.2542 14.0529C10.9486 13.7653 11.542 13.2783 11.9596 12.6534C12.3771 12.0284 12.6 11.2938 12.6 10.5422C12.6 9.53437 12.1996 8.56782 11.487 7.85518C10.7744 7.14254 9.80782 6.74219 8.8 6.74219ZM11.054 9.82419L8.334 12.5442C8.22431 12.6538 8.07557 12.7154 7.9205 12.7154C7.76542 12.7154 7.6167 12.6538 7.507 12.5442L6.467 11.5042C6.36228 11.3935 6.30487 11.2464 6.30697 11.094C6.30908 10.9417 6.37052 10.7962 6.47825 10.6884C6.58599 10.5807 6.73151 10.5193 6.88385 10.5172C7.03619 10.515 7.18334 10.5725 7.294 10.6772L7.92 11.3032L10.227 8.99619C10.3377 8.89147 10.4848 8.83405 10.6372 8.83615C10.7895 8.83825 10.935 8.89971 11.0427 9.00745C11.1505 9.11518 11.2119 9.26068 11.214 9.41302C11.2161 9.56537 11.1587 9.71252 11.054 9.82318V9.82419Z"
      fill="currentColor"
    />
    <path
      d="M17.2661 5.98091V5.96591C17.2571 5.77491 17.2521 5.57191 17.2481 5.34691C17.2389 4.81422 17.0287 4.30469 16.6597 3.92044C16.2906 3.53619 15.79 3.30561 15.2581 3.27491C13.1673 3.23185 11.1719 2.39156 9.68012 0.92591L9.66712 0.91391C9.43141 0.695539 9.12193 0.574219 8.80062 0.574219C8.4793 0.574219 8.16983 0.695539 7.93412 0.91391L7.92012 0.92591C6.42835 2.39156 4.43296 3.23185 2.34212 3.27491C1.81041 3.30585 1.31002 3.53654 0.941188 3.92076C0.572353 4.30499 0.362305 4.81438 0.353117 5.34691C0.350117 5.57491 0.344117 5.77491 0.335117 5.96591V6.00191C0.136943 8.66127 0.432933 11.3343 1.20812 13.8859C1.71901 15.3004 2.54127 16.582 3.61412 17.6359C4.96154 18.899 6.57017 19.8505 8.32612 20.4229C8.38916 20.445 8.45365 20.4627 8.51912 20.4759C8.70427 20.513 8.89496 20.513 9.08012 20.4759C9.14592 20.4627 9.21075 20.445 9.27412 20.4229C11.0284 19.8495 12.6352 18.8978 13.9811 17.6349C15.0544 16.5806 15.8773 15.2987 16.3891 13.8839C17.1673 11.3264 17.4646 8.6468 17.2661 5.98091ZM8.80012 15.5059C7.81774 15.5059 6.85742 15.2146 6.0406 14.6688C5.22378 14.123 4.58715 13.3473 4.21121 12.4397C3.83527 11.5321 3.7369 10.5334 3.92856 9.5699C4.12021 8.60639 4.59327 7.72136 5.28792 7.02671C5.98256 6.33206 6.8676 5.859 7.8311 5.66735C8.79461 5.4757 9.7933 5.57406 10.7009 5.95C11.6085 6.32594 12.3842 6.96257 12.93 7.77939C13.4758 8.59621 13.7671 9.55653 13.7671 10.5389C13.7658 11.8558 13.2421 13.1184 12.3109 14.0496C11.3796 14.9809 10.117 15.5046 8.80012 15.5059Z"
      fill="currentColor"
    />
  </svg>
</template>
