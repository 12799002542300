<template>
  <div class="loading-table">
    <table class="table">
      <tbody>
        <tr class="tr">
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
        </tr>
        <tr class="tr">
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
        </tr>
        <tr class="tr">
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
        </tr>
        <tr class="tr">
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
        </tr>
        <tr class="tr">
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
        </tr>
        <tr class="tr">
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
        </tr>
        <tr class="tr">
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
        </tr>
        <tr class="tr">
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
          <td class="td"><div class="loader"></div></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import '@/assets/styles/modules/data-connect/coupon-management/loading-table.scss';
</style>
