function isUrlValid(url: string) {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}

function resolveRatio(layout: string) {
  switch (layout) {
    case 'vertical':
      return '2:3.7';
    case 'horizontal':
      return '1:1';
    case 'small-square':
      return '1:1';
    case 'large-square':
      return '1:1.1';
  }
}

function blockContentImageCard(properties: any, mapper?: Communication.MapperModel.TrackingOpen) {
  let builtProp: any = [];
  let labelBlock: any = {};

  const imageBlock = {
    type: 'image',
    size: 'full',
    aspectRatio: resolveRatio(properties.layout.key as string),
    aspectMode: 'cover',
    url: mapper?.linkValue || properties.bannerUrl,
  };
  if (properties.label.width == 0 || properties.label.width == undefined) {
    labelBlock = {
      type: 'box',
      layout: 'vertical',
      contents: [
        {
          type: 'text',
          text: properties.label.text,
          color: properties.label.fontColor,
          align: properties.label.align,
          decoration: properties.label.textDecoration,
          style: properties.label.fontItalic,
          weight: properties.label.fontWeight,
          size: 'sm',
          offsetTop: '1.5px',
        },
      ],
      position: 'absolute',
      cornerRadius: '20px',
      offsetTop: '15px',
      backgroundColor: properties.label.bgColor,
      offsetStart: '15px',
      height: '25px',
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingEnd: '8px',
      paddingStart: '8px',
    };
  } else {
    labelBlock = {
      type: 'box',
      layout: 'vertical',
      contents: [
        {
          type: 'text',
          text: properties.label.text,
          color: properties.label.fontColor,
          align: properties.label.align,
          decoration: properties.label.textDecoration,
          style: properties.label.fontItalic,
          weight: properties.label.fontWeight,
          size: 'sm',
          offsetTop: '1.5px',
        },
      ],
      position: 'absolute',
      cornerRadius: '20px',
      offsetTop: '15px',
      backgroundColor: properties.label.bgColor,
      offsetStart: '15px',
      height: '25px',
      width: (properties.label.width as string) + 'px',
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingEnd: '8px',
      paddingStart: '8px',
    };
  }
  if (properties.actionLabel.width == 0 || properties.actionLabel.width == undefined) {
    builtProp = [
      {
        type: 'box',
        layout: 'vertical',
        contents: properties.enableAction
          ? [
              {
                type: 'box',
                layout: 'vertical',
                contents: [
                  {
                    type: 'filler',
                  },
                  {
                    type: 'box',
                    layout: 'baseline',
                    contents: [
                      {
                        type: 'filler',
                      },
                      {
                        type: 'text',
                        text: properties.actionLabel.text,
                        color: properties.actionLabel.fontColor,
                        decoration: properties.actionLabel.textDecoration,
                        style: properties.actionLabel.fontItalic,
                        weight: properties.actionLabel.fontWeight,
                        flex: 0,
                        offsetTop: '-2px',
                      },
                      {
                        type: 'filler',
                      },
                    ],
                    justifyContent: 'center',
                    margin: 'none',
                  },
                  {
                    type: 'filler',
                  },
                ],
                cornerRadius: `${properties.actionLabel.borderRadius as string}px`,
                height: '50px',
                backgroundColor: properties.actionLabel.bgColor,
                action:
                  properties.actionType == 1
                    ? {
                        type: 'uri',
                        label: 'action',
                        uri: isUrlValid(properties.linkUrl as string) ? properties.linkUrl : 'https://' + properties.linkUrl,
                      }
                    : {
                        type: 'message',
                        label: 'action',
                        text: properties.actionLabel.text,
                      },
                paddingTop: '12px',
                paddingStart: '24px',
                paddingEnd: '24px',
                paddingBottom: '12px',
              },
            ]
          : [],
        position: 'absolute',
        paddingStart: '30px',
        paddingEnd: '30px',
        paddingBottom: '10px',
        offsetBottom: '0px',
        offsetStart: '0px',
        offsetEnd: '0px',
        alignItems: 'center',
      },
    ];
  } else {
    builtProp = [
      {
        type: 'box',
        layout: 'vertical',
        contents: properties.enableAction
          ? [
              {
                type: 'box',
                layout: 'vertical',
                contents: [
                  {
                    type: 'filler',
                  },
                  {
                    type: 'box',
                    layout: 'baseline',
                    contents: [
                      {
                        type: 'filler',
                      },
                      {
                        type: 'text',
                        text: properties.actionLabel.text,
                        color: properties.actionLabel.fontColor,
                        decoration: properties.actionLabel.textDecoration,
                        style: properties.actionLabel.fontItalic,
                        weight: properties.actionLabel.fontWeight,
                        flex: 0,
                        offsetTop: '-2px',
                      },
                      {
                        type: 'filler',
                      },
                    ],
                    justifyContent: 'center',
                    margin: 'none',
                  },
                  {
                    type: 'filler',
                  },
                ],
                cornerRadius: `${properties.actionLabel.borderRadius as string}px`,
                height: '50px',
                backgroundColor: properties.actionLabel.bgColor,
                action:
                  properties.actionType == 1
                    ? {
                        type: 'uri',
                        label: 'action',
                        uri: isUrlValid(properties.linkUrl as string) ? properties.linkUrl : 'https://' + properties.linkUrl,
                      }
                    : {
                        type: 'message',
                        label: 'action',
                        text: properties.actionLabel.text,
                      },
                width: (properties.actionLabel.width as string) + 'px',
                paddingTop: '12px',
                paddingStart: '24px',
                paddingEnd: '24px',
                paddingBottom: '12px',
              },
            ]
          : [],
        position: 'absolute',
        paddingStart: '30px',
        paddingEnd: '30px',
        paddingBottom: '10px',
        offsetBottom: '0px',
        offsetStart: '0px',
        offsetEnd: '0px',
        alignItems: 'center',
      },
    ];
  }

  builtProp.unshift(imageBlock);

  if (properties.enableLabel) {
    builtProp.push(labelBlock);
  }
  return builtProp;
}

function blockContentClosingCard(properties: any) {
  let builtProp: any = [];

  const imageBlock = {
    type: 'image',
    size: 'full',
    aspectRatio: resolveRatio(properties.layout.key as string),
    aspectMode: 'cover',
    url: properties.bannerUrl,
  };

  builtProp = [
    {
      type: 'box',
      layout: 'vertical',
      contents: properties.enableAction
        ? [
            {
              type: 'box',
              layout: 'vertical',
              contents: [
                {
                  type: 'box',
                  layout: 'baseline',
                  contents: [
                    {
                      type: 'filler',
                    },
                    {
                      type: 'text',
                      text: properties.actionLabel.text,
                      flex: 0,
                      offsetTop: '-2px',
                      action:
                        properties.actionType == 1
                          ? {
                              type: 'uri',
                              label: 'action',
                              uri: isUrlValid(properties.linkUrl as string) ? properties.linkUrl : 'https://' + properties.linkUrl,
                            }
                          : {
                              type: 'message',
                              label: 'action',
                              text: properties.actionLabel.text,
                            },
                      color: properties.actionLabel.fontColor,
                    },
                    {
                      type: 'filler',
                    },
                  ],
                  spacing: 'none',
                  margin: 'xl',
                },
                {
                  type: 'filler',
                },
              ],
              spacing: 'sm',
              height: '50px',
            },
          ]
        : [],
      position: properties?.enableImage ? 'absolute' : 'relative',
      offsetBottom: '0px',
      offsetStart: '0px',
      offsetEnd: '0px',
      backgroundColor: properties.actionLabel.bgColor,
      justifyContent: 'center',
    },
  ];
  if (properties.enableImage) {
    builtProp.unshift(imageBlock);
  }
  return builtProp;
}
export function buildCardMessageImagePayload(cardMessageList: Communication.LineOA.Component.CardMessge[]) {
  let resolvedContent = {};
  resolvedContent = {
    type: 'carousel',
    contents: cardMessageList.map((card) => {
      if (card.key !== 'closing-card') {
        return {
          type: 'bubble',
          size: card.properties?.layout.size,
          body: {
            type: 'box',
            layout: 'horizontal',
            contents: blockContentImageCard(card.properties, card.mapperTrackingOpen),
            position: 'relative',
            paddingAll: 'none',
            height: `${card.properties?.layout.height as string}px`,
          },
        };
      } else {
        return {
          type: 'bubble',
          size: card.properties?.layout.size,
          body: {
            type: 'box',
            layout: 'horizontal',
            contents: blockContentClosingCard(card.properties),
            position: 'relative',
            paddingAll: 'none',
            height: `${card.properties?.layout.height as string}px`,
          },
        };
      }
    }),
  };
  return resolvedContent;
}
