<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15.804" height="14.201" viewBox="0 0 15.804 14.201">
    <path
      id="Path_22479"
      data-name="Path 22479"
      d="M5.118,0S5.007,9.449.9,12.122s7.1,1.06,9.709-4.517c1.375-2.947-.231-4.9-2.354-6.277A11.875,11.875,0,0,0,5.118,0Z"
      transform="matrix(0.259, 0.966, -0.966, 0.259, 12.926, 0)"
    />
  </svg>
</template>
