<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16.463" height="22.99" viewBox="0 0 16.463 22.99">
    <g id="Group_45329" data-name="Group 45329" transform="translate(-144.456 -1119.186)">
      <g id="Group_45287" data-name="Group 45287" transform="translate(-302.955 5)">
        <g id="Group_45285" data-name="Group 45285" transform="translate(444.41 1114.186)">
          <g id="Group_45282" data-name="Group 45282" transform="matrix(0.809, -0.588, 0.588, 0.809, 0, 8.865)">
            <path
              id="Path_29101"
              data-name="Path 29101"
              d="M10.38,3.063A4.2,4.2,0,0,0,2.954,1.707L1.09,4.235a5.264,5.264,0,0,1,2.141-.169l.042-.057L4.26,2.67A2.579,2.579,0,1,1,8.411,5.732L7.424,7.071l-.81,1.1-.13.177L5.5,9.684a2.574,2.574,0,0,1-4.445-.521L0,10.589a4.2,4.2,0,0,0,6.8.058L9.718,6.7A4.194,4.194,0,0,0,10.38,3.063Z"
              transform="translate(4.545)"
            />
            <path
              id="Path_29102"
              data-name="Path 29102"
              d="M8.1,8.329a5.355,5.355,0,0,1-.8-.041l-.042.057L6.277,9.684A2.579,2.579,0,0,1,2.126,6.622l.987-1.339.81-1.1.13-.176L5.04,2.67a2.574,2.574,0,0,1,4.446.521l1.052-1.426a4.2,4.2,0,0,0-6.8-.058L.819,5.659a4.2,4.2,0,0,0,6.764,4.988L9.449,8.118A5.313,5.313,0,0,1,8.1,8.329Z"
              transform="translate(0 5.105)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
