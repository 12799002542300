import { reactive } from 'vue';

export default function () {
  const data = reactive([
    {
      label: 'All',
      value: 'All',
    },
    {
      label: 'Customer',
      value: 'Customer',
    },
    {
      label: 'Product',
      value: 'Product',
    },
    {
      label: 'Transaction',
      value: 'Transaction',
    },
  ]);

  const source = reactive([
    {
      label: 'All',
      value: 'All',
    },
    {
      label: 'CRM/POS',
      value: 'CRM',
    },
    {
      label: 'Csv',
      value: 'Csv',
    },
    {
      label: 'BP',
      value: 'BP',
    },
    {
      label: 'FH5',
      value: 'FH5',
    },
  ]);

  const chartType = reactive([
    {
      label: 'All',
      value: 'All',
    },
    {
      label: 'Pie Chart',
      value: 'Pie Chart',
    },
    {
      label: 'Line Chart',
      value: 'Line Chart',
    },
    {
      label: 'Bar Chart',
      value: 'Bar Chart',
    },
    {
      label: 'Heatmap',
      value: 'Heatmap',
    },
    {
      label: 'Infographic',
      value: 'Infographic',
    },
    {
      label: 'Radar',
      value: 'Radar',
    },
    {
      label: 'Box',
      value: 'Box',
    },
    {
      label: 'Geo-Map',
      value: 'Geo-Map',
    },
    {
      label: 'Table',
      value: 'Table',
    },
    {
      label: 'Profile',
      value: 'Profile',
    },
    {
      label: 'TOverview',
      value: 'Overview',
    },
    {
      label: 'Gauge',
      value: 'Gauge',
    },
  ]);

  return { data, source, chartType };
}
