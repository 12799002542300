<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="558.63" height="172.585" viewBox="0 0 558.63 172.585">
    <g id="Group_37783" data-name="Group 37783" transform="translate(10815.049 5386.084)">
      <g id="Group_37782" data-name="Group 37782" transform="translate(-10815.05 -5386.084)">
        <g id="Group_37457" data-name="Group 37457" transform="translate(0 0)">
          <g id="Group_37450" data-name="Group 37450" transform="translate(0 0)">
            <g id="Group_37336" data-name="Group 37336" transform="translate(0 0)">
              <path
                id="Path_24208"
                data-name="Path 24208"
                d="M574.417,39.226h33.8a9.846,9.846,0,0,1,0,19.692H597.937a9.846,9.846,0,0,0,0,19.692h93.211a9.846,9.846,0,0,0,0-19.692h-43.87a9.846,9.846,0,0,1,0-19.692h-5.471a9.846,9.846,0,0,0,0-19.692h-67.39a9.846,9.846,0,0,0,0,19.692"
                transform="translate(-180.412 -1.133)"
                fill="#dfebf8"
              />
              <path
                id="Path_24343"
                data-name="Path 24343"
                d="M574.417,39.226h33.8a9.846,9.846,0,0,1,0,19.692H597.937a9.846,9.846,0,0,0,0,19.692h93.21a9.846,9.846,0,0,0,0-19.692h-43.87a9.846,9.846,0,0,1,0-19.692h-5.471a9.846,9.846,0,0,0,0-19.692H574.417a9.846,9.846,0,0,0,0,19.692"
                transform="translate(-530.896 -19.534)"
                fill="#dfebf8"
              />
              <path
                id="Path_24212"
                data-name="Path 24212"
                d="M390.57,153.484h43.887a9.846,9.846,0,0,1,0,19.692h-3.707a9.846,9.846,0,1,0,0,19.692H523.96a9.846,9.846,0,0,0,0-19.692H478.654a9.846,9.846,0,1,1,0-19.692h14.009a9.846,9.846,0,0,0,0-19.692H390.577a9.846,9.846,0,0,0,0,19.692"
                transform="translate(-380.732 -20.282)"
                fill="#dfebf8"
              />
              <g id="Group_37452" data-name="Group 37452" transform="translate(384.162 113.51)">
                <path
                  id="Path_24213"
                  data-name="Path 24213"
                  d="M538.726,165.018H546a9.846,9.846,0,1,1,0,19.692h-7.275a9.846,9.846,0,0,1,0-19.692"
                  transform="translate(-385.31 -125.637)"
                  fill="#dfebf8"
                />
                <path
                  id="Path_24215"
                  data-name="Path 24215"
                  d="M692.626,163.343h-52.39a7.489,7.489,0,0,1,2,.3c-4.6,1.25-7.448,6.538-6.358,11.812a9.274,9.274,0,0,0,6.358,7.286,7.5,7.5,0,0,1-2,.3h17.8c4.745,0,8.592,4.407,8.592,9.847s-3.847,9.845-8.592,9.845h-94.9c-4.745,0-8.592-4.407-8.592-9.845s3.847-9.847,8.592-9.847h34.323c4.745,0,8.59-4.407,8.59-9.845s-3.846-9.847-8.59-9.847H535.341c-4.745,0-8.592-4.407-8.592-9.845s3.847-9.847,8.592-9.847H692.626c4.745,0,8.591,4.407,8.591,9.847s-3.846,9.845-8.591,9.845"
                  transform="translate(-526.749 -143.653)"
                  fill="#dfebf8"
                />
              </g>
              <path
                id="Path_24216"
                data-name="Path 24216"
                d="M400.116,61.525a9.947,9.947,0,1,0,9.948-9.946h0a9.947,9.947,0,0,0-9.947,9.947"
                transform="translate(-370.666 -12.398)"
                fill="#dfebf8"
              />
              <path
                id="Path_24353"
                data-name="Path 24353"
                d="M400.115,61.277a9.7,9.7,0,1,0,9.7-9.7h0a9.7,9.7,0,0,0-9.7,9.7"
                transform="translate(-233.153 101.608)"
                fill="#dfebf8"
              />
              <path
                id="Path_24352"
                data-name="Path 24352"
                d="M400.115,60.486a8.908,8.908,0,1,0,8.909-8.907h0a8.908,8.908,0,0,0-8.908,8.908"
                transform="translate(-24.863 7.497)"
                fill="#dfebf8"
              />
              <path
                id="Path_24217"
                data-name="Path 24217"
                d="M590.683,111.379a18.4,18.4,0,1,1,18.4,18.4,18.4,18.4,0,0,1-18.4-18.4"
                transform="translate(-88.503 -92.978)"
                fill="#dfebf8"
              />
            </g>
          </g>
        </g>
        <g id="Group_37781" data-name="Group 37781" transform="translate(187.778 16.907)">
          <g id="Group_37778" data-name="Group 37778" transform="translate(48.551 57.939)">
            <rect id="Rectangle_16373" data-name="Rectangle 16373" width="65.049" height="12.985" rx="3.765" fill="#002d61" />
            <rect
              id="Rectangle_16374"
              data-name="Rectangle 16374"
              width="40.21"
              height="12.985"
              rx="3.765"
              transform="translate(0 29.217)"
              fill="#002d61"
            />
            <rect
              id="Rectangle_16375"
              data-name="Rectangle 16375"
              width="30.962"
              height="12.985"
              rx="3.765"
              transform="translate(0 57.785)"
              fill="#002d61"
            />
          </g>
          <path
            id="Path_24349"
            data-name="Path 24349"
            d="M504.2,515.559c-3.272-3.272-10.489,14.34-8.916-.162.031-.283-.571-.007-.86,0V450.716a9.949,9.949,0,0,0-9.937-9.937h-1.049a9.949,9.949,0,0,0-9.937,9.937v21.91a.235.235,0,0,1-.234.234H367.39A4.869,4.869,0,0,1,362.526,468V442.743A11.756,11.756,0,0,1,374.266,431H492.456a11.754,11.754,0,0,1,11.74,11.743Zm-122.38-74.78a9.526,9.526,0,0,0-9.515,9.515v12.521h91.417V440.779Z"
            transform="translate(-362.526 -431)"
            fill="#002d61"
          />
          <path
            id="Path_24350"
            data-name="Path 24350"
            d="M393.545,562.1a19.119,19.119,0,0,1-19.074-19.073V468.642a4.888,4.888,0,1,1,9.777,0V545.5a6.831,6.831,0,0,0,6.824,6.823h76.5c3.415,4.034,14.589,7.337,19.154,9.779Z"
            transform="translate(-353.87 -407.266)"
            fill="#002d61"
          />
          <g id="Group_37780" data-name="Group 37780" transform="translate(96.241 81.81)">
            <path
              id="Path_24351"
              data-name="Path 24351"
              d="M454.842,551.459a36.511,36.511,0,1,1,36.512-36.512A36.553,36.553,0,0,1,454.842,551.459Zm0-63.246a26.734,26.734,0,1,0,26.734,26.733A26.764,26.764,0,0,0,454.842,488.213Z"
              transform="translate(-418.33 -478.436)"
              fill="#0086ff"
            />
            <g id="Group_37779" data-name="Group 37779" transform="translate(21.689 20.54)">
              <rect
                id="Rectangle_16376"
                data-name="Rectangle 16376"
                width="32.431"
                height="7.448"
                transform="translate(19.939 0) rotate(90)"
                fill="#0086ff"
              />
              <rect
                id="Rectangle_16377"
                data-name="Rectangle 16377"
                width="32.431"
                height="7.448"
                transform="translate(32.431 19.939) rotate(180)"
                fill="#0086ff"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
