<template>
  <svg id="search-icon" xmlns="http://www.w3.org/2000/svg" width="25.123" height="23.552" viewBox="0 0 25.123 23.552">
    <g transform="translate(-2344.178 852.792) rotate(-49)">
      <path
        d="M7.963,15.924a7.962,7.962,0,1,1,7.961-7.961A7.971,7.971,0,0,1,7.963,15.924Zm0-13.27A5.308,5.308,0,1,0,13.27,7.963,5.315,5.315,0,0,0,7.963,2.654Z"
        transform="translate(2172 1214)"/>
      <rect width="2.654" height="10.616" rx="1" transform="translate(2178.635 1228.597)"/>
    </g>
  </svg>
</template>

<style lang="scss" scoped>
@import '@assets/styles/base/button.scss';
</style>