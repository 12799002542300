import { ContentTable } from 'pdfmake/interfaces';

export const pdfMakeContentHeader = ({
  title,
  subtitle,
  currentPage,
  totalPage,
  downloadDateString,
}: {
  title: string;
  subtitle: string;
  currentPage: number;
  totalPage: number;
  downloadDateString: string;
}) => {
  return {
    table: {
      widths: ['*', '50%'],
      body: [
        [
          {
            margin: [30, 20, 0, 0],
            text: title,
            fillColor: '#EFEFEF',
            style: 'title',
          },
          {
            margin: [30, 20, 30, 0],
            text: `${currentPage}/${totalPage}`,
            fillColor: '#EFEFEF',
            style: 'title',
            alignment: 'right',
          },
        ],
        [
          {
            margin: [30, 0, 0, 30],
            text: subtitle,
            fillColor: '#EFEFEF',
            style: 'subtitle',
          },
          {
            margin: [0, 0, 30, 30],
            text: `${downloadDateString}`,
            fillColor: '#EFEFEF',
            style: 'subtitle',
            alignment: 'right',
          },
        ],
      ],
    },
    layout: 'noBorders',
  };
};

export const pdfMakeContentSubHeader = (filters: string[]) => {
  const text: ({ text: string; style: string } | '\t')[] = [];

  filters.forEach((el) => {
    text.push({
      text: `\t${el}\t`,
      style: 'tag',
    });
    text.push('\t');
  });

  return <ContentTable>{
    table: {
      widths: ['*'],
      body: [
        [
          {
            margin: [30, 10],
            text: [
              'Filter',
              '\t',
              {
                text: text,
              },
            ],
            fillColor: '#F8F8F8',
            style: 'filter',
          },
        ],
      ],
    },
    layout: 'noBorders',
  };
};

export const pdfMakeContentMain = (imageBase64: unknown, paperWidth: number, isLastPage: boolean) => {
  return <ContentTable>{
    table: {
      body: [
        [
          {
            image: imageBase64,
            width: paperWidth,
          },
        ],
      ],
    },
    layout: 'noBorders',
    pageBreak: !isLastPage ? 'after' : '',
  };
};

export const styles = {
  title: {
    fontSize: 18,
  },
  subtitle: {
    fontSize: 10,
  },
  filter: {
    fontSize: 10,
  },
  tag: {
    fontSize: 10,
    background: '#0086FF',
    color: 'white',
  },
};
