<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
<g id="Group_33458_00000143594052557481870220000004546176590582676400_" transform="translate(-989 6045)">
	<path id="Path_21387_00000109030973178081582550000005965844600819232155_" style="fill:#0069C1;" d="M1232.72-6016.46
		c0-8.2-6.64-14.84-14.84-14.84h-207.76c-8.2,0-14.84,6.64-14.84,14.84v44.52h237.44V-6016.46z"/>
	<path id="Path_21388_00000155121845056302389040000005051167023325450655_" style="fill:#A2ABB7;" d="M995.28-5971.94v144.45
		c0,10.38,8.41,18.79,18.79,18.79c0,0,0,0,0,0h199.86c10.38,0,18.79-8.41,18.79-18.79l0,0v-144.45H995.28z M1069.48-5826.27
		c0.01,1.5-1.2,2.72-2.7,2.73c-0.01,0-0.01,0-0.02,0h-53.93c-1.5,0-2.72-1.22-2.72-2.72c0-0.01,0-0.01,0-0.01v-27.94
		c0-1.5,1.21-2.72,2.71-2.72h0h53.93c1.5,0,2.72,1.22,2.72,2.72l0,0V-5826.27z M1069.48-5876.36c0.01,1.5-1.2,2.72-2.7,2.73
		c-0.01,0-0.01,0-0.02,0h-53.93c-1.5,0-2.72-1.22-2.72-2.72c0-0.01,0-0.01,0-0.01v-27.94c0-1.5,1.21-2.72,2.71-2.72h0h53.93
		c1.5,0,2.72,1.22,2.72,2.72l0,0V-5876.36z M1069.48-5926.44c0.01,1.5-1.2,2.72-2.7,2.73c-0.01,0-0.01,0-0.02,0h-53.93
		c-1.5,0-2.72-1.22-2.72-2.72c0-0.01,0-0.01,0-0.01v-27.94c0-1.5,1.21-2.72,2.71-2.72h0h53.93c1.5,0,2.72,1.22,2.72,2.72l0,0
		V-5926.44z M1143.68-5826.27c0.01,1.5-1.2,2.72-2.7,2.73c-0.01,0-0.01,0-0.02,0h-53.93c-1.5,0-2.72-1.22-2.72-2.72
		c0-0.01,0-0.01,0-0.01v-27.94c0-1.5,1.21-2.72,2.71-2.72h0h53.93c1.5,0,2.72,1.22,2.72,2.72l0,0V-5826.27z M1143.68-5876.36
		c0.01,1.5-1.2,2.72-2.7,2.73c-0.01,0-0.01,0-0.02,0h-53.93c-1.5,0-2.72-1.22-2.72-2.72c0-0.01,0-0.01,0-0.01v-27.94
		c0-1.5,1.21-2.72,2.71-2.72h0h53.93c1.5,0,2.72,1.22,2.72,2.72l0,0V-5876.36z M1143.68-5926.44c0.01,1.5-1.2,2.72-2.7,2.73
		c-0.01,0-0.01,0-0.02,0h-53.93c-1.5,0-2.72-1.22-2.72-2.72c0-0.01,0-0.01,0-0.01v-27.94c0-1.5,1.21-2.72,2.71-2.72h0h53.93
		c1.5,0,2.72,1.22,2.72,2.72l0,0V-5926.44z M1217.88-5826.27c0.01,1.5-1.2,2.72-2.7,2.73c-0.01,0-0.01,0-0.02,0h-53.93
		c-1.5,0-2.72-1.22-2.72-2.72c0-0.01,0-0.01,0-0.01v-27.94c0-1.5,1.21-2.72,2.71-2.72h0h53.93c1.5,0,2.72,1.22,2.72,2.72l0,0
		V-5826.27z M1217.88-5876.36c0.01,1.5-1.2,2.72-2.7,2.73c-0.01,0-0.01,0-0.02,0h-53.93c-1.5,0-2.72-1.22-2.72-2.72
		c0-0.01,0-0.01,0-0.01v-27.94c0-1.5,1.21-2.72,2.71-2.72h0h53.93c1.5,0,2.72,1.22,2.72,2.72l0,0V-5876.36z M1217.88-5926.44
		c0.01,1.5-1.2,2.72-2.7,2.73c-0.01,0-0.01,0-0.02,0h-53.93c-1.5,0-2.72-1.22-2.72-2.72c0-0.01,0-0.01,0-0.01v-27.94
		c0-1.5,1.21-2.72,2.71-2.72h0h53.93c1.5,0,2.72,1.22,2.72,2.72l0,0V-5926.44z"/>
</g>
</svg>
</template>
