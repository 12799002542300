<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="13.858" height="15.344" viewBox="0 0 13.858 15.344">
    <g id="Group_36775" data-name="Group 36775" transform="translate(0 0)">
      <g id="Group_640" data-name="Group 640" transform="matrix(0.755, 0.656, -0.656, 0.755, 9.681, 0)">
        <path id="Union_33" data-name="Union 33" d="M0,9.707H3.732L1.867,11.947Zm0,0V0H3.732V9.707Z" transform="translate(0 2.809)" />
        <g id="Group_444" data-name="Group 444" transform="translate(0 0)">
          <rect id="Rectangle_280" data-name="Rectangle 280" width="3.732" height="1.873" rx="0.936" transform="translate(0)" />
          <rect id="Rectangle_281" data-name="Rectangle 281" width="3.732" height="0.936" transform="translate(0 0.936)" />
        </g>
      </g>
      <rect id="Rectangle_440" data-name="Rectangle 440" width="13.858" height="1.54" rx="0.77" transform="translate(0 13.804)" />
    </g>
  </svg>
</template>
