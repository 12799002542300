<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="36.292" height="36.292" viewBox="0 0 36.292 36.292">
    <g id="Group_45601" data-name="Group 45601" transform="translate(5009.689 8069.8)">
      <rect id="Rectangle_17435" data-name="Rectangle 17435" width="36.292" height="36.292" transform="translate(-5009.688 -8069.8)" fill="#fff" />
      <path
        id="Path_29178"
        data-name="Path 29178"
        d="M952.826,68.12H952.1a.557.557,0,0,0-.557.557v7.1a.557.557,0,0,0,.557.557h.722a.557.557,0,0,0,.557-.557v-7.1a.557.557,0,0,0-.557-.557"
        transform="translate(-5956.142 -8123.879)"
      />
      <path
        id="Path_29179"
        data-name="Path 29179"
        d="M969.954,73.916a.557.557,0,0,0-.244-.355l-.561-.361a.557.557,0,0,0-.763.158,1.648,1.648,0,0,1-.691.606,2.207,2.207,0,0,1-.932.2,2.378,2.378,0,0,1-1-.2,2.207,2.207,0,0,1-.744-.54,2.472,2.472,0,0,1-.478-.8,2.85,2.85,0,0,1-.172-.987,2.929,2.929,0,0,1,.168-.991,2.4,2.4,0,0,1,.472-.8,2.214,2.214,0,0,1,.744-.54,2.412,2.412,0,0,1,1.006-.2,1.882,1.882,0,0,1,.49.061,2.216,2.216,0,0,1,.436.167,1.467,1.467,0,0,1,.316.217,1.268,1.268,0,0,1,.205.233.557.557,0,0,0,.8.146l.6-.441a.557.557,0,0,0,.129-.766,2.639,2.639,0,0,0-.571-.593,3.588,3.588,0,0,0-.713-.421,4.2,4.2,0,0,0-1.7-.359,4.373,4.373,0,0,0-1.682.323,4.078,4.078,0,0,0-2.251,2.257,4.453,4.453,0,0,0-.323,1.705,4.519,4.519,0,0,0,.316,1.7,4.021,4.021,0,0,0,2.244,2.261,4.433,4.433,0,0,0,1.7.322,4.058,4.058,0,0,0,1.029-.125,3.873,3.873,0,0,0,.867-.34,3.414,3.414,0,0,0,.707-.508,3.527,3.527,0,0,0,.517-.608.557.557,0,0,0,.082-.423"
        transform="translate(-5964.535 -8123.288)"
      />
      <path
        id="Path_29180"
        data-name="Path 29180"
        d="M1002.5,69.927a4.08,4.08,0,0,0-2.251-2.257,4.538,4.538,0,0,0-3.364,0,4.077,4.077,0,0,0-2.251,2.257,4.669,4.669,0,0,0,0,3.411A4.076,4.076,0,0,0,996.89,75.6a4.54,4.54,0,0,0,3.364,0,4.08,4.08,0,0,0,2.251-2.257,4.67,4.67,0,0,0,0-3.411m-3.933,4.236a2.378,2.378,0,0,1-1-.2,2.245,2.245,0,0,1-.747-.538,2.358,2.358,0,0,1-.47-.795,3.028,3.028,0,0,1,0-1.987,2.414,2.414,0,0,1,.472-.8,2.21,2.21,0,0,1,.744-.541,2.583,2.583,0,0,1,1.992,0,2.216,2.216,0,0,1,.744.541,2.412,2.412,0,0,1,.472.8,3.024,3.024,0,0,1,0,1.987,2.35,2.35,0,0,1-.47.794,2.274,2.274,0,0,1-.746.539,2.379,2.379,0,0,1-1,.2"
        transform="translate(-5988.927 -8123.287)"
      />
      <path
        id="Path_29181"
        data-name="Path 29181"
        d="M1040.2,68.12h-.722a.557.557,0,0,0-.557.557v4.272l-3.133-4.586a.557.557,0,0,0-.46-.243h-.9a.557.557,0,0,0-.557.557v7.1a.557.557,0,0,0,.557.557h.722a.557.557,0,0,0,.557-.557V71.457l3.132,4.629a.557.557,0,0,0,.461.245h.9a.557.557,0,0,0,.557-.557v-7.1a.557.557,0,0,0-.557-.557"
        transform="translate(-6019.243 -8123.879)"
      />
    </g>
  </svg>
</template>
