<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_2" viewBox="0 0 40 26">
    <g id="Layer_1-2">
      <image
        width="40"
        height="26"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAaCAYAAADFTB7LAAABY0lEQVRYCd2XTU7EMAyFK1ZIbDgKN4CjcANYOGt6g+EGHcVizw3gBnCAWtmym7lBkTua4SnxEKq2aUUlS9ZLHH91fppWVeZ5bMK189I4lq6YeQnuJdxk0A7NjuW5GBgUgbzs/gbo2/clADXnfRMus5AOAInDXTZgZAcsBvmvq+xwqwDUyhDLk2VOF+xxbXhprD7DtfMzccrF0vUVJJYaxVI+edk4H24TOxbjBOhlVwpqaJ6+ghik1ZzX2i3my/kJYHbHjOygaz0Hhe2LAx42p27QH1sVoDUhvwISt29zmmP5QIDBgBhcwv+HgPrdndGI5RNnZnAFrYAptfiYscbGF1jBMZNuylUBIozl13V3UWGDVfIptXiKMXfsE7fbPjc2TAljjRUDnv/uw3VsSUDrJRINAZO7mXVfG6OxPGC+BMYSMKC0b/EkGv5zlAQk374mMJagP+ba2bHsCwHuNZ/mtXhi7RsSGBdS6Ho1zwAAAABJRU5ErkJggg=="
      />
    </g>
  </svg>
</template>
