<template>
  <svg
    id="Group_962"
    data-name="Group 962"
    xmlns="http://www.w3.org/2000/svg"
    width="11.767"
    height="10.904"
    viewBox="0 0 11.767 10.904"
  >
    <path
      id="Path_417"
      data-name="Path 417"
      d="M6229.95-5221.722l-5.452,5.452,5.452,5.452,1.221-1.222-4.23-4.23,4.23-4.23Z"
      transform="translate(-6224.498 5221.722)"
    />
    <path
      id="Path_418"
      data-name="Path 418"
      d="M6229.95-5221.722l-5.452,5.452,5.452,5.452,1.221-1.222-4.23-4.23,4.23-4.23Z"
      transform="translate(-6219.404 5221.722)"
    />
  </svg>
</template>
