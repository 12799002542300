export default function () {
  const fetchCustomerInsightActivityFiltersModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSActivity/ActivityFilterList`,
    method: 'GET',
    payload: {},
  };

  const fetchCustomerInsightTransactionsListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSActivity/Data`,
    method: 'POST',
    payload: {
      CID: null!,
      Activities_Key: [],
      Filters: [],
      Page: null!,
      Limit: null!,
    },
  };

  const fetchCustomerInsightTransactionsExportModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSActivity/ExportCsv`,
    method: 'POST',
    payload: {
      CID: null!,
      Activities_Key: [],
      Filters: [],
    },
    responseType: 'blob',
  };

  const fetchCustomerInsightTransactionsInfoModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_INS!}/INSActivity/DataInfo`,
    method: 'POST',
    payload: {
      CID: null!,
      Activity_Key: String,
      Activity_Id: null!, //commu ใช้ campaign id
    },
  };

  return {
    fetchCustomerInsightActivityFiltersModel,
    fetchCustomerInsightTransactionsListModel,
    fetchCustomerInsightTransactionsExportModel,
    fetchCustomerInsightTransactionsInfoModel,
  };
}
