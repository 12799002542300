import { ref, Ref, getCurrentInstance, onMounted } from 'vue';

interface Props {
  isOpen: boolean;
  name: string;
  typeSelected: string;
  typeList: string[];
  itemProperty: SettingChartModal.ItemProperty[];
}

export default function useSettingChartModal(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const loading: Ref<boolean> = ref(false);

  const selectedChartType: Ref<string | null> = ref(null);
  const itemsModified: Ref<SettingChartModal.ItemProperty[]> = ref([]);

  const onCloseModal = () => {
    vm?.$emit('close-modal');
  };

  const onCancel = () => {
    vm?.$emit('cancel');
    vm?.$emit('close-modal');
  };

  const onApply = () => {
    vm?.$emit('apply', selectedChartType.value, itemsModified.value);
    loading.value = true;
  };

  const onItemsChanged = (v: SettingChartModal.ItemProperty) => {
    const foundIndex: number = itemsModified.value.findIndex((o) => o.colorId == v.colorId);
    if (foundIndex == -1) {
      itemsModified.value.push(v);
    } else {
      itemsModified.value[foundIndex] = v;
    }
  };

  onMounted(() => {
    selectedChartType.value = props.typeSelected;
  });

  return { loading, selectedChartType, onCloseModal, onCancel, onApply, onItemsChanged };
}
