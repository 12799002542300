import Quill from 'quill';
import { fontSizeList, fontFamilyList } from '../constants/constant-edm';

const FONT_SIZE = fontSizeList.map((el) => el.label);
const FONT_LIST = fontFamilyList.map((el) => el.label);

// override icons
const icons = Quill.import('ui/icons');
icons['clean'] = `<svg xmlns="http://www.w3.org/2000/svg" width="16.615" height="13.813" viewBox="0 0 16.615 13.813">
<g id="Group_44849" data-name="Group 44849" transform="translate(-1816.602 1560.615)">
  <path
    id="Path_28721"
    data-name="Path 28721"
    d="M1824.9-1552.785l1.866-1.866-2.214-5.964h-2.66L1816.6-1546.8h2.55q.187-.5.356-.981t.335-.96q.166-.477.335-.957t.355-.978h2.263l2.106-2.107Zm-3.519-.016,1.783-5.072,1.731,5.072Z"
    fill="#0f2a4e"
  />
  <path
    id="Path_28722"
    data-name="Path 28722"
    d="M1849.568-1540.8a1.086,1.086,0,0,0,0-1.536l-2.806-2.806a1.086,1.086,0,0,0-1.536,0l-4.845,4.845a1.087,1.087,0,0,0,0,1.536l2.417,2.417a1.045,1.045,0,0,0,.74.307h6.189v-1.158h-3.765Zm-5.983,3.606-2.335-2.335,2.967-2.967,2.7,2.7-2.6,2.6Z"
    transform="translate(-16.669 -10.771)"
    fill="#0080ff"
  />
</g>
</svg>`;
// end

const AlignClass = Quill.import('attributors/style/align');
Quill.register(AlignClass as string, true);

const size = Quill.import('attributors/style/size');
size.whitelist = FONT_SIZE;
Quill.register(size as string, true);

function getFontName(font: string) {
  return font.toLowerCase().replace(/\s/g, '-');
}
const fontList = FONT_LIST;
const fontNames = fontList.map((font) => getFontName(font));
const fonts = Quill.import('formats/font');
fonts.whitelist = fontNames;
Quill.register(fonts as string, true);
let fontStyles = '';
fontList.forEach(function (font) {
  const fontName = getFontName(String(font));
  fontStyles +=
    '.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=' +
    fontName +
    ']::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=' +
    fontName +
    ']::before {' +
    "content: '" +
    font +
    "';" +
    "font-family: '" +
    font +
    "', sans-serif;" +
    '}' +
    '.ql-font-' +
    fontName +
    '{' +
    " font-family: '" +
    font +
    "', sans-serif;" +
    '}';
});
const node = document.createElement('style');
node.innerHTML = fontStyles;
document.body.appendChild(node);

const Inline = Quill.import('blots/inline');
const Parchment = Quill.import('parchment');
const BlockEmbed = Quill.import('blots/embed');

/* example. custom <button> */
class MarkBlot extends Inline {
  static create() {
    const node = super.create();
    node.setAttribute('class', 'mark-red');
    return node;
  }
}
MarkBlot.blotName = 'mark-red';
MarkBlot.tagName = 'i';
Quill.register(MarkBlot);
/* end */

/* example. custom <select> */
const boxAttributor: any = new Parchment.Attributor.Class('box', 'line', {
  scope: Parchment.Scope.INLINE,
  whitelist: ['solid', 'double', 'dotted'],
});
Quill.register(boxAttributor as string, true);
/* end */

/* custom class text-transform */
const textTransform: any = new Parchment.Attributor.Class('text-transform', 'text-transform', {
  scope: Parchment.Scope.INLINE,
  whitelist: ['none', 'capitalize', 'lowercase', 'uppercase'],
});
Quill.register(textTransform as string, true);
/* end */

/* custom class line-height */
const textVerticalAlign: any = new Parchment.Attributor.Class('text-vertical-align', 'text-vertical-align', {
  scope: Parchment.Scope.INLINE,
  whitelist: ['1', '1-15', '1-25', '1-5', '1-75', '2', '2-25', '2-5', '3', '4'],
});
Quill.register(textVerticalAlign as string, true);
/* end */

/* example. personalize button */
class Personalize extends BlockEmbed {
  static create(value: any) {
    const node = super.create(value);
    node.innerHTML = value.columnAlias;
    node.className = 'personalize-button';
    node.type = 'button';
    node.draggable = true;
    node.setAttribute(
      'style',
      `border-radius:2px;padding:0 5px;margin:0px 2px;font-size:16px;border:none;background-color:${value.backgroundColor as string};color:${
        value.fontColor as string
      }`,
    );
    node.setAttribute('contenteditable', false);
    node.setAttribute('value', JSON.stringify(value));
    return node;
  }
  static value(node: any) {
    return node;
  }
}
Personalize.blotName = 'personalize';
Personalize.tagName = 'button';
Quill.register(Personalize);
/* end */

/* example. coupon button */
class Coupon extends BlockEmbed {
  static create(value: any) {
    const node = super.create(value);
    node.innerHTML = value.couponColumnAlias;
    node.className = 'coupon-button';
    node.type = 'button';
    node.draggable = true;
    node.setAttribute(
      'style',
      `border-radius:2px;padding:0 5px;margin:0px 2px;font-size:16px;border:none;background-color:${value.backgroundColor as string};color:${
        value.fontColor as string
      }`,
    );
    node.setAttribute('contenteditable', false);
    node.setAttribute('value', JSON.stringify(value));
    return node;
  }
  static value(node: any) {
    return node;
  }
}
Coupon.blotName = 'coupon';
Coupon.tagName = 'button';
Quill.register(Coupon);
/* end */

export const options = {
  theme: 'snow',
  modules: {
    toolbar: '#toolbar',
    // toolbar: {
    //   container: [
    //     ['bold', 'italic', 'underline', 'strike'],
    //     [{ list: 'ordered' }, { list: 'bullet' }],
    //     [{ size: FONT_SIZE }],
    //     [{ color: [] }, { background: [] }],
    //     [{ font: fonts.whitelist }],
    //     ['clean'],
    //     [{ align: [] }],
    //   ],
    // },
  },
};

export default function () {
  const QuillInitInstance = (id: string) => {
    return new Quill(`#${id}`, options);
  };

  const QuillSetHtml = (quillInstance: any, html: string) => {
    quillInstance.container.firstChild.innerHTML = html;
  };

  const QuillGetHtml = (quillInstance: any) => {
    return quillInstance.container.firstChild.innerHTML;
  };

  return { QuillInitInstance, QuillSetHtml, QuillGetHtml };
}
