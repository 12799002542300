import { DateTime, Zone } from 'luxon';
import { computed, defineComponent, h, Prop, Ref, ref, watch } from 'vue';
import { getZone, useDateTimeFormat } from '../../composed/date-time';
import { useInputable } from '../../composed/inputable';
import { ChocoInputPopup, ChocoInputPopupMethods } from '../input-popup';
import { ChocoScrollSelect } from '../scroll-select';

export default defineComponent({
  name: 'ChocoTimePicker',
  props: {
    timezone: <Prop<Zone | string | number | null>>{
      validate: (val: any) => val === null || getZone(val).isValid,
      default: () => null,
    },
    dateMode: {
      type: Boolean,
      default: () => false,
    },
    modelValue: <Prop<Date | string | null>>{
      default: () => null,
    },
    format: {
      type: String,
      default: 'HH:mm:ss',
    },
    displayFormat: {
      type: String,
      default: 'HH:mm:ss',
    },
    placeholder: {
      type: String,
      default: 'Select Time',
    },
    showSecond: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['update:modelValue', 'hide'],
  setup(props, { emit, slots }) {
    // TODO: composable for time-picker ?

    // popup reference
    const popup$ = ref<(InstanceType<typeof ChocoInputPopup> & ChocoInputPopupMethods) | null>(null);

    // static options
    const options = {
      hour: [...Array(24).keys()],
      minute: [...Array(60).keys()],
      second: [...Array(60).keys()],
    };

    // ensure date mode
    const ensureDateMode = computed(() => props.modelValue instanceof Date || props.dateMode);

    // model timezone
    const timezone = computed(() => getZone(props.timezone ?? null));
    // h:m:s & changed states
    const hour = ref<number | null>(null);
    const minute = ref<number | null>(null);
    const second = ref<number | null>(null);

    // watch selection changes, emit update value
    watch([hour, minute, second], ([hour, minute, second]) => {
      if (hour !== null || minute !== null || second !== null) {
        // if date mode, emit with Date (native object)
        if (ensureDateMode.value) {
          const dt = (props.modelValue ? DateTime.fromJSDate(props.modelValue as Date) : DateTime.now()).setZone(timezone.value);
          emit('update:modelValue', dt.set({ hour: hour ?? 0, minute: minute ?? 0, second: second ?? 0 }).toJSDate());
        }
        // else, emit with configured format
        else {
          emit('update:modelValue', displayValue.value);
        }
      }
    });

    // watch model value changes, update h:m:s states
    watch(
      () => props.modelValue,
      (value) => {
        // if value is Date (native object), use its time
        if (value instanceof Date) {
          const dt = DateTime.fromJSDate(value).setZone(timezone.value);
          hour.value = dt.hour;
          minute.value = dt.minute;
          second.value = dt.second;
        }
        // else if value exists, try to parse with configured format
        else if (value) {
          const dt = DateTime.fromFormat(value, props.format).setZone(timezone.value);
          // if datetime is valid, set the time, otherwise ignore
          if (dt.isValid) {
            hour.value = dt.hour;
            minute.value = dt.minute;
            second.value = dt.second;
          }
        }
      },
      { immediate: true },
    );

    // prepare datetime formatter
    const format = useDateTimeFormat(
      computed(() => {
        if (hour.value !== null || minute.value !== null || second.value !== null) {
          return DateTime.now()
            .setZone(timezone.value)
            .set({ hour: hour.value ?? 0, minute: minute.value ?? 0, second: second.value ?? 0 })
            .toJSDate();
        } else {
          return null;
        }
      }),
      computed(() => props.timezone ?? null),
    );

    // use inputable for lazy-update
    const onInput = (value: string | null, reset: () => void) => {
      // validate input value, parse to format
      if (value !== null) {
        // parse to format
        const parsed = DateTime.fromFormat(value, props.format).setZone(format.timezone.value);
        // if parsed value is valid, update h:m:s states and return;
        if (parsed.isValid) {
          hour.value = parsed.hour;
          minute.value = parsed.minute;
          second.value = parsed.second;
          return;
        }
      }

      // update failed, reset input value
      reset();
    };
    const { value, updateValue, onInputFocus, onInputBlur, onInputEnter } = useInputable(
      computed(() => (format.hasDate.value ? format.toFormat(props.format) : null)),
      onInput,
    );

    // render utility
    const scrollSelectPropsBuilder = (t: 'hour' | 'minute' | 'second', m: Ref<number | null>) => ({
      class: [`choco-time-picker__scrollbar choco-time-picker__${t}-picker`],
      height: 252,
      options: options[t],
      modelValue: m.value,
      ['onUpdate:modelValue']: (opt: number) => (m.value = opt),
    });

    // display value
    const displayValue = computed(() => (format.hasDate.value ? format.toFormat(props.displayFormat) : null));

    // render functions
    return () =>
      // ChocoInputPopup.choco-time-picker
      h(
        ChocoInputPopup,
        {
          ref: popup$,
          class: ['choco-time-picker'],
          onEnter: onInputEnter,
          onFocus: onInputFocus,
          onBlur: onInputBlur,
          modelValue: value,
          'onUpdate:modelValue': updateValue,
          placeholder: props.placeholder,
          onHide: () => emit('hide'),
        },
        {
          // v-slot:input > use slot:input or default native input
          input: (props: object) => (slots['input'] ? slots['input'](props) : h('input', props)),
          // v-slot:default
          default: () => {
            const children = [];
            children.push(
              // ChocoScrollbar.choco-time-picker__scrollbar.choco-time-picker__hour-picker
              h(ChocoScrollSelect, scrollSelectPropsBuilder('hour', hour), () => null),
              // ChocoScrollbar.choco-time-picker__scrollbar.choco-time-picker__minute-picker
              h(ChocoScrollSelect, scrollSelectPropsBuilder('minute', minute), () => null),
            );

            if (props.showSecond) {
              children.push(
                // ChocoScrollbar.choco-time-picker__scrollbar.choco-time-picker__second-picker
                h(ChocoScrollSelect, scrollSelectPropsBuilder('second', second), () => null),
              );
            }
            return [
              // div.choco-time-picker__popup-container
              h('div', { class: ['choco-time-picker__popup-container'] }, children),
            ];
          },
        },
      );
  },
});
