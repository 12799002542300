import { DateTime, DurationLike, Zone } from 'luxon';
import { computed, isRef, Ref, ref, unref, watch } from 'vue';
import { CHOCO_DATETIME_OBJECT } from '../identifier';
import { getZone, parseISODuration } from './utilities';
import { checkTimezone, createDateTime } from './utility';

/**
 * DateTime composition
 *
 * @param init Initial date, null for default (now())
 * @param timezone Timezone to be working on, IANA identifier or offset in minutes
 */
function this_useDateTime(
  init: Ref<Date | string> | Date | string | null = null,
  timezone: Ref<Zone | string | number | null> | Zone | string | number | null = null,
) {
  // region initialize models & tracking

  // check provided timezone
  if (timezone) {
    if (!checkTimezone(unref(timezone))) {
      throw new Error(`Invalid timezone provided`);
    }
  }

  // initialize timezone model
  const modelTimezone = ref<Zone>(getZone(unref(timezone)));

  // initialize model (date instance)
  const model = ref<DateTime>(createDateTime(unref(init), unref(timezone)));

  // if timezone is also a ref, watch to validate and update timezone
  if (isRef(timezone)) {
    watch(timezone, (value) => {
      if (!checkTimezone(value)) {
        throw new Error(`Invalid timezone provided`);
      }
      modelTimezone.value = getZone(value);
      model.value = model.value.setZone(modelTimezone.value) ?? null;
    });
  }

  // if init value is also a ref, watch to update model
  if (isRef(init)) {
    watch(init, (value) => {
      model.value = createDateTime(value, modelTimezone.value);
    });
  }

  // endregion

  // region states & computes

  // utc & native conversion
  const modelNative = computed<Date>({
    get: () => model.value.toJSDate(),
    set: (value) => (model.value = DateTime.fromJSDate(value).setZone(modelTimezone.value)),
  });
  const modelUTC = computed(() => model.value.toUTC());

  // year
  const year = computed(() => model.value.year);
  const yearUTC = computed(() => modelUTC.value.year);

  // month
  const month = computed(() => model.value.month - 1 /* minus 1 for compatibility with Date.getMonth() */);
  const monthUTC = computed(() => modelUTC.value.month - 1 /* minus 1 for compatibility with Date.getMonth() */);

  // day
  const daysInMonth = computed(() => model.value.daysInMonth);
  const daysInMonthUTC = computed(() => modelUTC.value.daysInMonth);
  const firstDayWeekOfMonth = computed(
    () => model.value.set({ day: 1 }).weekday % 7 /* % 7 to made sunday as 0, for compatibility with Date.getDay() */,
  );
  const firstDayWeekOfMonthUTC = computed(
    () => modelUTC.value.set({ day: 1 }).weekday % 7 /* % 7 to made sunday as 0, for compatibility with Date.getDay() */,
  );

  const dayOfWeek = computed(() => model.value.weekday % 7 /* % 7 to made sunday as 0, for compatibility with Date.getDay() */);
  const dayOfWeekUTC = computed(() => modelUTC.value.weekday % 7 /* % 7 to made sunday as 0, for compatibility with Date.getDay() */);
  const day = computed(() => model.value.day);
  const dayUTC = computed(() => modelUTC.value.day);

  // time
  const hour = computed(() => model.value.hour);
  const hourUTC = computed(() => modelUTC.value.hour);
  const minute = computed(() => model.value.minute);
  const minuteUTC = computed(() => modelUTC.value.minute);
  const second = computed(() => model.value.second);
  const secondUTC = computed(() => modelUTC.value.second);

  // unix timestamp
  const timestamp = computed(() => model.value.toMillis());

  // endregion

  // region date manipulator functions

  /**
   * Set timezone
   *
   * @param timezone Timezone to set to
   */
  const setTimezone = (timezone: Ref<Zone> | Zone | string | number) => {
    model.value = model.value.setZone((modelTimezone.value = getZone(unref(timezone))));
  };

  /**
   * Set to specific date
   *
   * @param year
   * @param month JS Month (0 - 11)
   * @param day
   * @param hour
   * @param minute
   * @param second
   * @param millisecond
   */
  const setDate = (
    year?: number | null,
    month?: number | null,
    day?: number | null,
    hour?: number | null,
    minute?: number | null,
    second?: number | null,
    millisecond?: number | null,
  ) => {
    model.value = model.value.set({
      year: year ?? model.value.year,
      month: (month ?? model.value.month - 1) + 1,
      day: day ?? model.value.day,
      hour: hour ?? model.value.hour,
      minute: minute ?? model.value.minute,
      second: second ?? model.value.second,
      millisecond: millisecond ?? model.value.millisecond,
    });
  };

  /**
   * Set to specific time
   *
   * @param hour
   * @param minute
   * @param second
   * @param millisecond
   */
  const setTime = (hour?: number | null, minute?: number | null, second?: number | null, millisecond?: number | null) => {
    setDate(null, null, null, hour, minute, second, millisecond);
  };

  /**
   * Add date with duration
   *
   * @param duration
   */
  const addByDuration = (duration: DurationLike | string) => {
    model.value = model.value.plus(typeof duration === 'string' ? parseISODuration(duration) : duration);
  };

  /**
   * Subtract date with duration
   *
   * @param duration
   */
  const subtractByDuration = (duration: DurationLike | string) => {
    model.value = model.value.minus(typeof duration === 'string' ? parseISODuration(duration) : duration);
  };

  // endregion

  return {
    [CHOCO_DATETIME_OBJECT]: 1,
    /** current zone */
    timezone: computed(() => modelTimezone.value),
    /** current date (as Date (native object)) */
    date: modelNative,
    /** current year */
    year,
    /** current year in UTC */
    yearUTC,
    /** current month (0 - 11) */
    month,
    /** current month (0 - 11) in UTC */
    monthUTC,
    /** current days in month */
    daysInMonth,
    /** current days in month in UTC */
    daysInMonthUTC,
    /** current weekday of first week of month (0 - 6, start at Sunday) */
    firstDayWeekOfMonth,
    /** current weekday of first week of month in UTC (0 - 6, start at Sunday) */
    firstDayWeekOfMonthUTC,
    /** current weekday (0 - 6, start at Sunday) */
    dayOfWeek,
    /** current weekday in UTC (0 - 6, start at Sunday) */
    dayOfWeekUTC,
    /** current day of month */
    day,
    /** current day of month in UTC */
    dayUTC,
    /** current hour */
    hour,
    /** current hour in UTC */
    hourUTC,
    /** current minute */
    minute,
    /** current minute in UTC */
    minuteUTC,
    /** current second */
    second,
    /** current second in UTC */
    secondUTC,
    /** current unix timestamp, milliseconds from 1 Jan, 1970 */
    timestamp,
    setTimezone,
    setDate,
    setTime,
    addByDuration,
    subtractByDuration,
  };
}

/**
 * DateTime composition object
 */
export type UseDateTimeObject = Omit<ReturnType<typeof this_useDateTime>, typeof CHOCO_DATETIME_OBJECT>;

/**
 * DateTime composition
 *
 * @param init Initial date, null for default
 * @param timezone Timezone to convert to, IANA identifier or offset in minutes
 * @param options Use date-time option
 */
export const useDateTime: (...[init, timezone]: Parameters<typeof this_useDateTime>) => UseDateTimeObject = this_useDateTime;

/**
 * Type guard for check if given value is a useDateTime() composition object
 * @param value
 */
export function isUseDateTimeObject(value: unknown): value is UseDateTimeObject {
  return typeof value === 'object' && value !== null && CHOCO_DATETIME_OBJECT in (value as object);
}
