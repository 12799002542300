import { Ref, onBeforeMount, ref, getCurrentInstance } from 'vue';

export default function useTemplateCard() {
  const vm = getCurrentInstance()?.proxy;

  const idTemplateSelect: Ref<number> = ref(0);

  const onSelectedCard = (id: number) => {
    idTemplateSelect.value = id;
  };

  const onSelectedPreview = (id: number) => {
    vm?.$emit('on-click-preview-template', id);
  };

  onBeforeMount(() => {});

  return { idTemplateSelect, onSelectedCard, onSelectedPreview };
}
