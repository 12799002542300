<template>
  <svg id="Group_52311" data-name="Group 52311" xmlns="http://www.w3.org/2000/svg" width="18.329" height="18.33" viewBox="0 0 18.329 18.33">
    <path
      id="Path_30475"
      data-name="Path 30475"
      d="M5.523,0H2.761A2.762,2.762,0,0,0,0,2.761V5.523A2.762,2.762,0,0,0,2.761,8.284H5.523A2.762,2.762,0,0,0,8.284,5.523V2.761A2.762,2.762,0,0,0,5.523,0M6.562,5.523A1.04,1.04,0,0,1,5.523,6.562H2.761A1.04,1.04,0,0,1,1.722,5.523V2.76A1.04,1.04,0,0,1,2.761,1.721H5.523A1.04,1.04,0,0,1,6.562,2.76Z"
      fill="#5180AE"
    />
    <path
      id="Path_30476"
      data-name="Path 30476"
      d="M16.43,0H13.668a2.762,2.762,0,0,0-2.761,2.761V5.523a2.762,2.762,0,0,0,2.761,2.761H16.43a2.762,2.762,0,0,0,2.761-2.761V2.761A2.762,2.762,0,0,0,16.43,0m1.04,5.523a1.041,1.041,0,0,1-1.04,1.039H13.668a1.04,1.04,0,0,1-1.039-1.039V2.76a1.04,1.04,0,0,1,1.039-1.039H16.43A1.041,1.041,0,0,1,17.47,2.76Z"
      transform="translate(-0.862)"
      fill="#5180AE"
    />
    <path
      id="Path_30477"
      data-name="Path 30477"
      d="M5.523,10.907H2.761A2.762,2.762,0,0,0,0,13.668V16.43a2.762,2.762,0,0,0,2.761,2.761H5.523A2.762,2.762,0,0,0,8.284,16.43V13.668a2.762,2.762,0,0,0-2.761-2.761M6.562,16.43a1.04,1.04,0,0,1-1.039,1.039H2.761A1.04,1.04,0,0,1,1.722,16.43V13.668a1.041,1.041,0,0,1,1.039-1.04H5.523a1.041,1.041,0,0,1,1.039,1.04Z"
      transform="translate(0 -0.861)"
      fill="#5180AE"
    />
    <path
      id="Path_30478"
      data-name="Path 30478"
      d="M16.43,10.907H13.668a2.762,2.762,0,0,0-2.761,2.761V16.43a2.762,2.762,0,0,0,2.761,2.761H16.43a2.762,2.762,0,0,0,2.761-2.761V13.668a2.762,2.762,0,0,0-2.761-2.761m1.04,5.523a1.041,1.041,0,0,1-1.04,1.039H13.668a1.04,1.04,0,0,1-1.039-1.039V13.668a1.041,1.041,0,0,1,1.039-1.04H16.43a1.042,1.042,0,0,1,1.04,1.04Z"
      transform="translate(-0.862 -0.861)"
      fill="#5180AE"
    />
  </svg>
</template>
