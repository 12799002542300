<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16.524" height="16.51" viewBox="0 0 16.524 16.51">
    <g id="Group_34734" data-name="Group 34734" transform="translate(0.014)">
      <path
        id="Path_22798"
        data-name="Path 22798"
        d="M899.585,16.156H885.42a1.173,1.173,0,0,0-1.173,1.173V31.493a1.173,1.173,0,0,0,1.173,1.173h14.165a1.173,1.173,0,0,0,1.173-1.173V17.329a1.173,1.173,0,0,0-1.173-1.173m-.618,14.719H886.038V17.947h12.928Z"
        transform="translate(-884.247 -16.156)"
      />
      <path
        id="Path_22799"
        data-name="Path 22799"
        d="M950.8,30.813a.879.879,0,0,0,1.055-1.055.87.87,0,0,0-.654-.654.878.878,0,1,0-.4,1.709"
        transform="translate(-938.329 -26.767)"
      />
      <rect id="Rectangle_15135" data-name="Rectangle 15135" width="15.711" height="0.944" transform="translate(0.236 8.85)" stroke-width="0.5" />
      <path
        id="Path_22800"
        data-name="Path 22800"
        d="M906.626,39.28l-3.266-2.809-1.974,1.8a.922.922,0,0,1-1.23.012l-3.6-3.159L893.024,38.1v-1.4l2.937-2.471a.915.915,0,0,1,1.2.012l3.6,3.156,1.973-1.8a.923.923,0,0,1,1.224-.018L906.67,37.9V39.28Z"
        transform="translate(-891.452 -30.805)"
        stroke-width="0.5"
      />
      <path
        id="Path_22801"
        data-name="Path 22801"
        d="M907.63,76.134a.834.834,0,1,0-.692-.692.834.834,0,0,0,.692.692"
        transform="translate(-903.791 -63.032)"
      />
      <path
        id="Path_22802"
        data-name="Path 22802"
        d="M926.355,76.458H919.74a.684.684,0,0,1-.683-.683V75.7a.683.683,0,0,1,.683-.683h6.615a.683.683,0,0,1,.684.683v.076a.684.684,0,0,1-.684.683"
        transform="translate(-913.386 -63.459)"
      />
    </g>
  </svg>
</template>
