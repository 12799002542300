<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20.126" height="16.211" viewBox="0 0 20.126 16.211">
    <g id="Group_46124" data-name="Group 46124" transform="translate(32.563 -28.12) rotate(90)">
      <g id="Group_46121" data-name="Group 46121" transform="translate(12.532)">
        <g id="Group_46120" data-name="Group 46120" transform="translate(15.831 17.99)">
          <rect id="Rectangle_17698" data-name="Rectangle 17698" width="1.152" height="9.02" transform="translate(0 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17699" data-name="Rectangle 17699" width="1.151" height="9.02" transform="translate(13.741 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17700" data-name="Rectangle 17700" width="1.152" height="9.02" transform="translate(6.622 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17701" data-name="Rectangle 17701" width="2.459" height="9.02" transform="translate(3.14 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17702" data-name="Rectangle 17702" width="0.352" height="9.02" transform="translate(1.6 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17703" data-name="Rectangle 17703" width="0.352" height="9.02" transform="translate(7.997 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17704" data-name="Rectangle 17704" width="0.352" height="9.02" transform="translate(6.047 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17705" data-name="Rectangle 17705" width="0.352" height="9.02" transform="translate(15.116 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17706" data-name="Rectangle 17706" width="0.352" height="9.02" transform="translate(13.165 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17707" data-name="Rectangle 17707" width="2.119" height="9.02" transform="translate(9.634 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17708" data-name="Rectangle 17708" width="0.648" height="9.02" transform="translate(12.164 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17709" data-name="Rectangle 17709" width="0.648" height="9.02" transform="translate(8.573 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17710" data-name="Rectangle 17710" width="0.352" height="9.02" transform="translate(2.399 0)" fill="#0f2a4e" />
        </g>
        <path
          id="Path_29317"
          data-name="Path 29317"
          d="M8440.87,7109.953h-15.211a.5.5,0,0,1,0-1h13.853l-1.641-1.4a.5.5,0,1,1,.648-.762l2.675,2.278a.5.5,0,0,1-.324.881Z"
          transform="translate(-8409.571 -7094.238)"
          fill="#0f2a4e"
        />
        <path
          id="Path_29318"
          data-name="Path 29318"
          d="M8428.333,7109.953a.5.5,0,0,1-.324-.119l-2.675-2.278a.5.5,0,0,1,.324-.881h15.211a.5.5,0,0,1,0,1h-13.853l1.641,1.4a.5.5,0,0,1-.324.881Z"
          transform="translate(-8409.57 -7077.39)"
          fill="#0f2a4e"
        />
      </g>
    </g>
  </svg>

  <svg xmlns="http://www.w3.org/2000/svg" width="20.126" height="16.211" viewBox="0 0 20.126 16.211">
    <g id="Group_46124" data-name="Group 46124" transform="translate(32.563 -28.12) rotate(90)">
      <g id="Group_46121" data-name="Group 46121" transform="translate(12.532)">
        <g id="Group_46120" data-name="Group 46120" transform="translate(15.831 17.99)">
          <rect id="Rectangle_17698" data-name="Rectangle 17698" width="1.152" height="9.02" transform="translate(0 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17699" data-name="Rectangle 17699" width="1.151" height="9.02" transform="translate(13.741 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17700" data-name="Rectangle 17700" width="1.152" height="9.02" transform="translate(6.622 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17701" data-name="Rectangle 17701" width="2.459" height="9.02" transform="translate(3.14 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17702" data-name="Rectangle 17702" width="0.352" height="9.02" transform="translate(1.6 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17703" data-name="Rectangle 17703" width="0.352" height="9.02" transform="translate(7.997 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17704" data-name="Rectangle 17704" width="0.352" height="9.02" transform="translate(6.047 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17705" data-name="Rectangle 17705" width="0.352" height="9.02" transform="translate(15.116 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17706" data-name="Rectangle 17706" width="0.352" height="9.02" transform="translate(13.165 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17707" data-name="Rectangle 17707" width="2.119" height="9.02" transform="translate(9.634 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17708" data-name="Rectangle 17708" width="0.648" height="9.02" transform="translate(12.164 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17709" data-name="Rectangle 17709" width="0.648" height="9.02" transform="translate(8.573 0)" fill="#0f2a4e" />
          <rect id="Rectangle_17710" data-name="Rectangle 17710" width="0.352" height="9.02" transform="translate(2.399 0)" fill="#0f2a4e" />
        </g>
        <path
          id="Path_29317"
          data-name="Path 29317"
          d="M8440.87,7109.953h-15.211a.5.5,0,0,1,0-1h13.853l-1.641-1.4a.5.5,0,1,1,.648-.762l2.675,2.278a.5.5,0,0,1-.324.881Z"
          transform="translate(-8409.571 -7094.238)"
          fill="#0f2a4e"
        />
        <path
          id="Path_29318"
          data-name="Path 29318"
          d="M8428.333,7109.953a.5.5,0,0,1-.324-.119l-2.675-2.278a.5.5,0,0,1,.324-.881h15.211a.5.5,0,0,1,0,1h-13.853l1.641,1.4a.5.5,0,0,1-.324.881Z"
          transform="translate(-8409.57 -7077.39)"
          fill="#0f2a4e"
        />
      </g>
    </g>
  </svg>
</template>
