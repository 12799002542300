/**
 * ['Consent PDPA', 'Subscribe', 'Ready to send', 'Unconsent']
 */
export const mockCategoriesCustomer = ['Consent PDPA', 'Subscribe', 'Ready to send', 'Unconsent'];
export const mockSeriesCustomer = [
  {
    name: 'Consent PDPA',
    type: 'bar',
    stack: 'stack',
    barWidth: 40,
    data: [1795],
  },
  {
    name: 'Subscribe',
    type: 'bar',
    stack: 'stack',
    barWidth: 40,
    data: [, 1932],
  },
  {
    name: 'Ready to send',
    type: 'bar',
    stack: 'stack',
    barWidth: 40,
    data: [, , 1765],
  },
];
