import { ref, reactive, Ref, watch, markRaw, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import api from '@services/api';
import useFilters from '@/utils/hooks/useFilters';
import AllCustomerModel from '@/models/segment/taballcustomer';
import dynamicTableModel from '@/models/component/table/dynamicTable';
import datasourceModel from '@/models/segment/result';

import BoxIcon from '@assets/icons/base/button/BoxIcon.vue';
import SmsIcon from '@assets/icons/base/button/SmsIcon.vue';
import EmailIcon from '@assets/icons/base/button/EmailIcon.vue';
import LineIcon from '@assets/icons/base/button/LineIcon.vue';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

interface Props {
  selectedSource: Segment.Datasource;
  segmentConfig: Segment.Config;
}

export default function useAllCustomer(props: Props) {
  const { openDefaultErrorModal } = useValidationModal();

  const { fetchCountRecord } = datasourceModel();
  const { fetchAllCustomerListModel, fetchDisplayColumnModel } = AllCustomerModel();
  const { updateDisplayColumnModel } = dynamicTableModel();

  const { filterDisplayObj } = useFilters();
  const { t } = useI18n();
  const router = useRouter();

  const textSearch: Ref<string> = ref('');
  const orderingSeq: Ref<number> = ref(0);
  const orderingKey: Ref<string> = ref('');
  const orderingDirection: Ref<string> = ref('');
  const rowSort: Ref<boolean> = ref(false);
  const selectAll: Ref<boolean> = ref(false);
  const columnSort: Ref<boolean> = ref(true);
  const loading: Ref<boolean> = ref(false);
  const dataSourceDisplaySave: Ref<AllDataSegment.DatasourceDisplay> = ref(null!);
  const saveSegmentModal = ref({ isOpen: false });
  const listDataSourceList: Ref<Array<any>> = ref([]);
  const listCustomer: Ref<Record<string, any>[]> = ref([]);
  const dataSourceDisplay: Ref<Array<any>> = ref([]);
  const filtersObj: Ref<Array<any>> = ref([]);
  const previewDataList: Ref<Record<string, any>[]> = ref([]);
  const uniqueKey: Ref<string> = ref('');
  const fields: Ref<DynamicTable.Column[]> = ref([]);
  const filtedFields: Ref<DynamicTable.Column[]> = ref([]);

  const tableSelectAll: Ref<boolean> = ref(false);
  const isDialogSegmentOpen: Ref<boolean> = ref(false);
  const TotalRecord: Ref<number> = ref(0);
  const errorCountDatasource: Ref<number> = ref(0);
  let searchTimeout: ReturnType<typeof setTimeout> = null!;

  const controllers: AbortController[] = [];
  const itemDropdownSave = ref([{ title: 'การจัดกลุ่มเป้าหมายทีบันทึกเอง', icon: markRaw(BoxIcon) }]);
  const createButtonOptions: AllDataSegment.CreateButtonDropDown[] = [
    {
      label: 'New Segment',
      key: 'segment',
    },
    {
      label: 'Segment Template',
      key: 'template',
    },
  ];
  const itemDropdownShare = ref([
    { title: 'SMS', icon: markRaw(SmsIcon) },
    { title: 'Email', icon: markRaw(EmailIcon) },
    { title: 'Line', icon: markRaw(LineIcon) },
  ]);

  const createSegmentModal = reactive({
    isOpen: false,
    isLoading: false,
    columnList: [] as DynamicTable.Column[],
  });

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 20,
    totalRows: 0,
  });

  const fetchDatasourceDisplay = () => {
    if (props.selectedSource != null) {
      loading.value = true;
      TotalRecord.value = 0;
      controllers.push(new AbortController());
      for (let i = 0; i < controllers.length - 1; i++) {
        controllers[i].abort();
      }

      fetchAllCustomerListModel.payload.tableId = props.selectedSource.tableid;
      fetchAllCustomerListModel.payload.Filters = filtersObj.value;
      fetchAllCustomerListModel.payload.Page = pagination.currentPage;
      fetchAllCustomerListModel.payload.Limit = pagination.perPage;
      fetchAllCustomerListModel.signal = controllers[controllers.length - 1].signal;
      fetchAllCustomerListModel.payload.Search_Value = textSearch.value;
      fetchAllCustomerListModel.payload.Flag_Count = false;

      if (orderingDirection.value) {
        fetchAllCustomerListModel.payload.Ordering = [
          {
            Seq: orderingSeq.value,
            Key: orderingKey.value,
            Direction: orderingDirection.value,
          },
        ];
      } else {
        fetchAllCustomerListModel.payload.Ordering = [];
      }
      fetchCountData();
      api
        .apiRequest(fetchAllCustomerListModel)
        .then((response) => {
          dataSourceDisplaySave.value = response.data.datasourcedisplay;
          dataSourceDisplay.value = response.data.datasourcedisplay.columns;
          findUniqueKey(response.data.datasourcedisplay.columns);
          fields.value = response.data.datasourcedisplay.columns;
          filtedFields.value = response.data.datasourcedisplay.columns;
          listCustomer.value = response.data.structuretable;
          if (response.status == 'success') {
            loading.value = false;
          }
        })
        .catch((err) => {
          if (!(err instanceof TypeError)) {
            openDefaultErrorModal(err);
            ++errorCountDatasource.value;
            if (err.status != 422 && errorCountDatasource.value < 5) {
              retryPayloadDatasource();
            } else {
              loading.value = false;
              openDefaultErrorModal(err);
            }
          }
        })
        .finally(() => {
          filtersObj.value = [];
        });
    }
  };

  const fetchCountData = () => {
    fetchCountRecord.payload.tableId = props.selectedSource.tableid;
    fetchCountRecord.payload.Filters = filtersObj.value;
    fetchCountRecord.payload.Page = pagination.currentPage;
    fetchCountRecord.payload.Limit = pagination.perPage;
    fetchCountRecord.payload.Search_Value = textSearch.value;

    if (orderingDirection.value) {
      fetchCountRecord.payload.Ordering = [
        {
          Seq: orderingSeq.value,
          Key: orderingKey.value,
          Direction: orderingDirection.value,
        },
      ];
    } else {
      fetchCountRecord.payload.Ordering = [];
    }
    api
      .apiRequest(fetchCountRecord)
      .then((response) => {
        pagination.totalRows = response.data.filtered_record;
        TotalRecord.value = response.data.total_record;
      })
      .catch((err) => {
        if (!(err instanceof TypeError)) {
          openDefaultErrorModal(err);
          ++errorCountDatasource.value;
          if (err.status != 422 && errorCountDatasource.value < 5) {
            openDefaultErrorModal(err, () => retryPayloadDatasource());
          } else {
            openDefaultErrorModal(err);
          }
        }
      });
  };

  const retryPayloadDatasource = () => {
    fetchDatasourceDisplay();
  };

  const fetchDisplayColumn = () => {
    fetchDisplayColumnModel.payload.TableId = props.selectedSource.tableid;
    createSegmentModal.isLoading = true;

    api
      .apiRequest(fetchDisplayColumnModel)
      .then((response) => {
        const columnList = response.data.payload.columns;
        createSegmentModal.columnList = columnList;
      })
      .catch((err) => {
        openDefaultErrorModal(
          err,
          () => (createSegmentModal.isOpen = false),
          () => (createSegmentModal.isOpen = false),
        );
      })
      .finally(() => (createSegmentModal.isLoading = false));
  };

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    orderingSeq.value = 1;
    orderingKey.value = sortedColumn.key;
    orderingDirection.value = sortedColumn.direction;

    if (textSearch.value != '') {
      filtersObj.value = filterDisplayObj(filtedFields.value, textSearch.value);
    }
    if (pagination.currentPage == 1) {
      fetchDatasourceDisplay();
    } else {
      pagination.currentPage = 1;
    }
  };

  const onClickSaveOpensaveSegmentModal = () => {
    saveSegmentModal.value.isOpen = true;
  };

  const onCloseCreateSegmentModal = () => {
    createSegmentModal.isOpen = false;
  };

  const onCloseSaveSegmentModal = () => {
    saveSegmentModal.value.isOpen = false;
  };

  const onOpenCreateSegmentModal = () => {
    createSegmentModal.isOpen = true;
    fetchDisplayColumn();
  };

  const onClickShare = () => {
    console.log('onClickShare');
  };

  const onClickCopy = () => {
    isDialogSegmentOpen.value = true;
  };

  const onClickExport = () => {
    console.log('onClickExport');
  };

  const onCloseDialogSegment = () => {
    isDialogSegmentOpen.value = false;
  };

  const onDisableColumnChange = (value: { columnsFilter: DynamicTable.Column[] }) => {
    filtedFields.value = value.columnsFilter;
  };

  const handleColumnChange = (col: Array<any>) => {
    filtedFields.value = col;
    const displayList: DynamicTable.UpdateDisplay[] = [];
    col.map((column, index) => {
      displayList.push({
        ColumnId: column.column_id,
        Key_Column: column.key,
        Seq: index + 1,
      });
    });
    updateDisplayColumnModel.payload.TableId = props.selectedSource.tableid;
    updateDisplayColumnModel.payload.displayTables = displayList;

    api.apiRequest(updateDisplayColumnModel).catch((err) => {
      openDefaultErrorModal(
        err,
        () => (createSegmentModal.isOpen = false),
        () => (createSegmentModal.isOpen = false),
      );
    });
  };

  const onChangeSearch = () => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      if (textSearch.value != '') {
        filtersObj.value = filterDisplayObj(filtedFields.value, textSearch.value);
      }
      fetchDatasourceDisplay();
    }, 500);
  };

  function findUniqueKey(columns: PreviewSegment.DatasourceDisplayColumns[]): void {
    const found = columns.find((el: PreviewSegment.DatasourceDisplayColumns) => el.is_primary_key === true);

    if (found) {
      uniqueKey.value = found.key;
    } else {
      throw 'uniqueKey not found';
    }
  }
  function createSegment() {
    onOpenCreateSegmentModal();
  }
  const createTemplate = () => {
    return router.push({
      path: '/segment/data-segment/segment-template',
      query: {
        ...router.currentRoute.value.query,
      },
    });
  };
  function resolveOptionAction(key: string) {
    switch (key) {
      case 'segment':
        return createSegment();
      case 'template':
        return createTemplate();
    }
  }

  function onClickCreateButton() {
    const dropdownContent = document.getElementById('dropdown-content') as HTMLDivElement;
    dropdownContent.classList.toggle('show');
  }

  window.onclick = function (event: any) {
    if (!event.target.matches('.create-segment-button') && !event.target.matches('.icon-create') && !event.target.matches('.button-text')) {
      const dropdowns = document.getElementsByClassName('dropdown-content');
      for (let i = 0; i < dropdowns.length; i++) {
        const openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  };

  const countTotal = (total: number, length: number, status: boolean) => {
    if (length > 0 && status == true)
      return t('segment.all_customer.table.segments_inbox_before') + (total - length) + t('segment.all_customer.table.segments_inbox_after');
    if (length == 0 && status == true) return t('segment.all_customer.table.clear_select');
    else return t('segment.all_customer.table.segments_inbox_before') + length + t('segment.all_customer.table.segments_inbox_after');
  };

  const clickStatusBar = (length: number, status: boolean) => {
    if (length == 0 && status == true) {
      tableSelectAll.value = true;
    }
  };

  watch(
    () => props.selectedSource,
    () => {
      document.getElementById('sorting-' + orderingKey.value)?.classList.remove('asc');
      document.getElementById('sorting-' + orderingKey.value)?.classList.remove('desc');
      fetchAllCustomerListModel.payload.Ordering = [];
      fetchCountRecord.payload.Ordering = [];
      orderingSeq.value = 0;
      orderingKey.value = '';
      orderingDirection.value = '';
      textSearch.value = '';
      pagination.totalRows = 0;
      if (pagination.currentPage == 1) {
        fetchDatasourceDisplay();
      } else {
        pagination.currentPage = 1;
      }
    },
  );

  watch(
    () => pagination.currentPage,
    () => {
      if (textSearch.value.length == 0) {
        return fetchDatasourceDisplay();
      } else return onChangeSearch();
    },
  );

  watch(
    () => textSearch.value,
    () => {
      TotalRecord.value = 0;
      pagination.totalRows = 0;
      pagination.currentPage = 1;
      onChangeSearch();
    },
  );

  onMounted(() => {
    fetchDatasourceDisplay();
  });

  return {
    fields,
    filtedFields,
    listDataSourceList,
    listCustomer,
    rowSort,
    columnSort,
    pagination,
    loading,
    onSort,
    itemDropdownSave,
    itemDropdownShare,
    dataSourceDisplaySave,
    textSearch,
    onClickSaveOpensaveSegmentModal,
    onClickShare,
    onClickExport,
    previewDataList,
    uniqueKey,
    saveSegmentModal,
    onCloseCreateSegmentModal,
    onCloseSaveSegmentModal,
    createSegmentModal,
    onOpenCreateSegmentModal,
    selectAll,
    countTotal,
    clickStatusBar,
    tableSelectAll,
    TotalRecord,
    onClickCopy,
    isDialogSegmentOpen,
    onCloseDialogSegment,
    handleColumnChange,
    onDisableColumnChange,
    onClickCreateButton,
    createButtonOptions,
    resolveOptionAction,
  };
}
