<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.476" height="17.476" viewBox="0 0 17.476 17.476">
    <path d="M8.738,0A8.738,8.738,0,1,1,0,8.738,8.738,8.738,0,0,1,8.738,0Z" />
    <g transform="translate(4.287 5.445)">
      <path
        d="M342.859,245.092a1.123,1.123,0,0,1-.349.514q-2.544,2.539-5.084,5.082a.468.468,0,0,1-.752,0l-2.462-2.462a.686.686,0,0,1,0-1.06l.252-.252a.679.679,0,0,1,1.01,0q.754.75,1.5,1.5a.57.57,0,0,1,.056.078l.04.008a.455.455,0,0,1,.055-.089q2.1-2.1,4.2-4.2a.687.687,0,0,1,1.065,0c.037.037.072.075.111.11a1.075,1.075,0,0,1,.356.519Z"
        transform="translate(-333.963 -243.965)"
        fill="#fff"
      />
    </g>
  </svg>
</template>
