import { Ref, ref, onUpdated, reactive, watch, onBeforeMount, getCurrentInstance, computed } from 'vue';
import Sortable from 'sortablejs';

interface Props {
  columns: Array<BaseTable.TableColumn>;
  data?: Array<Record<string, any>>;
  hover?: boolean;
  checkbox?: boolean;
  responsive?: boolean;
  stickyHeader?: boolean;
  sortableRow?: boolean;
  sortableCol?: boolean;
  currentPage?: number;
  perPages?: number;
  rowSelectVariant?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'dark';
  busy?: boolean;
  selectAll?: boolean;
  sortAndFilter?: boolean;
  tableId?: number;
  selectColumnFilter?: any;
  selectColumnSorting?: any;
  tableViewMode?: boolean;
}

export default function useBaseTable(props: Props) {
  type direction = 'ASC' | 'DESC' | '';
  interface Column {
    key: string;
    direction: direction;
    target: HTMLElement | null;
  }

  const vm = getCurrentInstance()?.proxy;
  const tableRef: Ref<HTMLElement | null> = ref(null);
  const tableContainerRef: Ref<HTMLElement | null> = ref(null);
  const selectAllCheckbox: Ref<HTMLInputElement | null> = ref(null);
  const keyChange: Ref<number> = ref(0);
  const sortedColumn: Column = reactive({ key: '', direction: '', target: null });
  const displayedData: Ref<Record<string, any>> = ref([]);

  const onSelectAllRow = () => {
    const nodeList = tableRef?.value?.childNodes as unknown as NodeListOf<HTMLElement>;

    const tableBody = nodeList[1] || null;
    const checked = selectAllCheckbox.value?.checked || false;

    if (tableBody) {
      tableBody.childNodes.forEach((node) => {
        if (node.nodeName == 'TR') {
          const columnList = node.childNodes as NodeListOf<HTMLElement>;

          const row = node as HTMLElement;
          columnList.forEach((col) => {
            if (col.className == 'base-table-checkbox-container') {
              const checkbox = col.childNodes[1] as HTMLInputElement;
              checkbox.checked = checked;

              if (checked) {
                props.rowSelectVariant ? row.classList.add(`row-selected-${props.rowSelectVariant}`) : row.classList.add('row-selected');
              } else {
                props.rowSelectVariant ? row.classList.remove(`row-selected-${props.rowSelectVariant}`) : row.classList.remove('row-selected');
              }
            }
          });
        }
      });
    }

    if (checked) {
      vm?.$emit('select-all-row', JSON.parse(JSON.stringify(displayedData.value)));
    } else {
      vm?.$emit('unselect-all-row', JSON.parse(JSON.stringify([])));
    }
  };

  const onSelectRow = () => {
    const nodeList = tableRef?.value?.childNodes as unknown as NodeListOf<HTMLElement>;
    const tableBody = nodeList[1] || null;
    const tableHead = nodeList[0] || null;
    const selectedRow: Record<string, any>[] = [];
    let isCheckAll = true;

    if (tableBody) {
      tableBody.childNodes.forEach((node, index) => {
        if (node.nodeName == 'TR') {
          const row = node as HTMLElement;
          const columnList = node.childNodes as NodeListOf<HTMLElement>;

          columnList.forEach((col) => {
            if (col.className == 'base-table-checkbox-container') {
              const checkbox = col.childNodes[1] as HTMLInputElement;
              if (!checkbox.checked) {
                isCheckAll = false;
                props.rowSelectVariant ? row.classList.remove(`row-selected-${props.rowSelectVariant}`) : row.classList.remove('row-selected');
              } else {
                props.rowSelectVariant ? row.classList.add(`row-selected-${props.rowSelectVariant}`) : row.classList.add('row-selected');
                selectedRow.push(displayedData.value[index - 1]);
              }
            }
          });
        }
      });
    }

    if (tableHead) {
      const headNode = tableHead.childNodes[0] as HTMLElement;
      const columnList = headNode.childNodes as NodeListOf<HTMLElement>;

      columnList.forEach((node) => {
        if (node.nodeName == 'TH' && node.className == 'base-table-checkbox-container') {
          const checkbox = node.childNodes[1] as HTMLInputElement;
          checkbox.checked = isCheckAll;
        }
      });
    }

    vm?.$emit('row-select', JSON.parse(JSON.stringify(selectedRow)));
  };

  const onSort = (event: Event, col: { key: string; label: string }) => {
    const sortingCheckbox = event.target as HTMLElement;

    if (sortingCheckbox.nodeName !== 'INPUT') {
      sortedColumn.target?.classList.remove('asc');
      sortedColumn.target?.classList.remove('desc');
      sortedColumn.key = '';
      sortedColumn.direction = '';
      sortedColumn.target = null;
      return;
    }

    if (sortedColumn.key !== col.key) {
      sortedColumn.target?.classList.remove('asc');
      sortedColumn.target?.classList.remove('desc');
      sortingCheckbox.classList.add('asc');
      sortedColumn.direction = 'ASC';
      sortedColumn.key = col.key;
      sortedColumn.target = sortingCheckbox;
    } else {
      if (sortingCheckbox.classList.contains('asc')) {
        sortingCheckbox.classList.remove('asc');
        sortedColumn.direction = 'DESC';
        sortingCheckbox.classList.add('desc');
      } else if (sortingCheckbox.classList.contains('desc')) {
        sortingCheckbox.classList.remove('desc');
        sortedColumn.direction = '';
      } else {
        sortedColumn.direction = 'ASC';
        sortingCheckbox.classList.add('asc');
      }
    }

    return vm?.$emit('sorting-change', { key: sortedColumn.key, direction: sortedColumn.direction });
  };

  const initSortColumn = () => {
    if (!sortedColumn.key) {
      return;
    }

    const sortingCheckbox = tableRef.value?.querySelector(`#sorting-${sortedColumn.key}`);

    if (sortedColumn.direction == 'ASC') {
      return sortingCheckbox?.classList.add('asc');
    } else if (sortedColumn.direction == 'DESC') {
      return sortingCheckbox?.classList.add('desc');
    }
  };

  const onRowClick = (row: Record<string, any>) => {
    return vm?.$emit('row-click', JSON.parse(JSON.stringify(row)));
  };

  const handleRowChange = (event: Sortable.SortableEvent) => {
    if (props.data && props.data.length) {
      const reorderRow = [...(<Record<string, any>[]>displayedData.value)];

      reorderRow.splice(<number>event.newDraggableIndex, 0, reorderRow.splice(<number>event.oldDraggableIndex, 1)[0]);
      vm?.$emit('row-change', JSON.parse(JSON.stringify(reorderRow)));
    }
  };

  const handleColumnChange = (event: Sortable.SortableEvent) => {
    if (props.columns && props.columns.length) {
      const reorderCol = [...props.columns];

      reorderCol.splice(<number>event.newDraggableIndex, 0, reorderCol.splice(<number>event.oldDraggableIndex, 1)[0]);
      vm?.$emit('col-change', JSON.parse(JSON.stringify(reorderCol)));
    }
  };

  const setSortableRow = (sortable: boolean) => {
    sortableRowOptions.disabled = !sortable;
    return keyChange.value++;
  };

  const setSortableCol = (sortable: boolean) => {
    sortableColOptions.disabled = !sortable;
    return keyChange.value++;
  };

  const sortableRowOptions: Sortable.SortableOptions = reactive({
    chosenClass: 'is-selected',
    ghostClass: 'sortable-ghost',
    dragClass: 'sortable-drag',
    draggable: '.dragable-row',
    onEnd: handleRowChange,
    disabled: true,
    animation: 300,
    easing: 'cubic-bezier(0.22, 1, 0.36, 1)',
  });

  const sortableColOptions: Sortable.SortableOptions = reactive({
    chosenClass: 'is-selected',
    ghostClass: 'sortable-ghost',
    dragClass: 'sortable-drag',
    draggable: '.dragable-col',
    handle: '.handle-dragable-col',
    onEnd: handleColumnChange,
    disabled: true,
    animation: 300,
    easing: 'cubic-bezier(0.22, 1, 0.36, 1)',
  });

  const setPageContent = () => {
    const tableContainer = tableContainerRef.value;

    if (props.currentPage && props.perPages) {
      const startIndex = props.perPages * (props.currentPage - 1);
      const endIndex = props.perPages * props.currentPage;

      displayedData.value = props.data?.slice(startIndex, endIndex) || [];
    } else {
      displayedData.value = JSON.parse(JSON.stringify(props.data));
    }

    if (tableContainer) {
      tableContainer.scrollTop = 0;
    }

    return keyChange.value++;
  };

  const setSelectAll = () => {
    const checkbox = selectAllCheckbox.value as HTMLInputElement;

    if (checkbox) {
      checkbox.checked = props.selectAll || false;
    }
  };

  watch(
    () => props.sortableRow,
    () => {
      setSortableRow(props.sortableRow || false);
    },
  );

  watch(
    () => props.sortableCol,
    () => {
      setSortableCol(props.sortableCol || false);
    },
  );

  watch(
    [() => props.currentPage, () => props.perPages, () => props.data],
    () => {
      setPageContent();
    },
    { deep: true },
  );

  onUpdated(() => {
    const checkbox = selectAllCheckbox.value as HTMLInputElement;
    const table = tableRef.value;
    const tableBody = table?.querySelector('tbody');

    if (checkbox) {
      let isCheckAll = true;

      if (typeof props.selectAll == 'boolean') {
        checkbox.checked = props.selectAll;
      }

      if (tableBody) {
        tableBody.childNodes.forEach((node) => {
          if (node.nodeName == 'TR') {
            const columnList = node.childNodes as NodeListOf<HTMLElement>;

            columnList.forEach((col) => {
              if (col.className == 'base-table-checkbox-container') {
                const rowCheckbox = col.childNodes[0] as HTMLInputElement;

                rowCheckbox.checked = checkbox.checked;
                if (!rowCheckbox.checked) return (isCheckAll = false);
                return props.rowSelectVariant
                  ? (<HTMLElement>node).classList.add(`row-selected-${props.rowSelectVariant}`)
                  : (<HTMLElement>node).classList.add('row-selected');
              }
            });
          }
        });

        if (props.data && props.data.length) checkbox.checked = isCheckAll;
      }
    }

    initSortColumn();
  });

  onBeforeMount(() => {
    if (props.data) {
      setPageContent();
    }

    if (props.selectAll) {
      setSelectAll();
    }

    setSortableRow(props.sortableRow || false);
    setSortableCol(props.sortableCol || false);
  });

  return {
    tableRef,
    tableContainerRef,
    selectAllCheckbox,
    sortableRowOptions,
    sortableColOptions,
    keyChange,
    displayedData,
    onSelectAllRow,
    onSelectRow,
    onSort,
    onRowClick,
  };
}
