<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19.001" height="19.001" viewBox="0 0 19.001 19.001">
    <g id="Group_33005" data-name="Group 33005" transform="translate(-12 -12)">
      <path
        id="Subtraction_266"
        data-name="Subtraction 266"
        d="M9.5,19A9.5,9.5,0,1,1,19,9.5,9.511,9.511,0,0,1,9.5,19Zm0-17.286A7.786,7.786,0,1,0,17.286,9.5,7.795,7.795,0,0,0,9.5,1.715Z"
        transform="translate(12 12)"
      />
      <g id="Group_33005-2" data-name="Group 33005" transform="translate(20.152 16.529)">
        <rect id="Rectangle_14637" data-name="Rectangle 14637" width="2" height="6" rx="1" transform="translate(-0.151 0.471)" />
        <rect
          id="Rectangle_14638"
          data-name="Rectangle 14638"
          width="1.811"
          height="4.989"
          rx="0.906"
          transform="translate(4.989 4.982) rotate(90)"
        />
      </g>
    </g>
  </svg>
</template>
