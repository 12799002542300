<template>
  <svg class="svg-item" xmlns="http://www.w3.org/2000/svg" width="16.843" height="19.647" viewBox="0 0 16.843 19.647">
    <g id="Group_110497" data-name="Group 110497" transform="translate(0 0)">
      <path
        id="Path_310031"
        data-name="Path 310031"
        d="M14.534,20.111H2.309A2.3,2.3,0,0,1,.78,16.079a1,1,0,0,1,.194-.155,2.3,2.3,0,0,1,.22-.138c.449-.308.915-.787.915-1.238V11.3c0-.012,0-.024,0-.036v-.031a6.313,6.313,0,1,1,12.626,0v.032c0,.011,0,.023,0,.035v3.253c0,.445.455.919.9,1.226a2.31,2.31,0,0,1,.3.195l.049.039a2.3,2.3,0,0,1-1.446,4.1M2.15,17.572l-.014.011a.282.282,0,0,0-.112.219.285.285,0,0,0,.285.285H14.534a.285.285,0,0,0,.285-.285.281.281,0,0,0-.112-.219l-.012-.01a.292.292,0,0,0-.036-.022.967.967,0,0,1-.114-.066,3.68,3.68,0,0,1-1.836-2.936v-3.2q0-.033,0-.066v-.05a4.288,4.288,0,1,0-8.577-.005l0,.055c0,.022,0,.044,0,.066v3.2A3.816,3.816,0,0,1,2.278,17.5a1,1,0,0,1-.1.057l-.031.018"
        transform="translate(0 -3.672)"
      />
      <path
        id="Path_310033"
        data-name="Path 310033"
        d="M24.418,68.136a3.22,3.22,0,0,0,3.22-3.22H21.2a3.22,3.22,0,0,0,3.22,3.22"
        transform="translate(-15.834 -48.489)"
      />
      <path
        id="Path_310034"
        data-name="Path 310034"
        d="M27.658,0a1.9,1.9,0,0,0-1.9,1.9h3.81a1.9,1.9,0,0,0-1.9-1.9"
        transform="translate(-19.236 0)"
      />
    </g>
  </svg>
</template>
