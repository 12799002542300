export const maxLength = 400;

export function htmlToString(input: string) {
  return input
    ? input
        .replaceAll(/&nbsp;/gm, ' ')
        .replaceAll(/<br>/g, '')
        .replaceAll(/(?<!^|<div>)<div>/g, '\n')
        .replaceAll(/<[^>]*>/gm, '')
        .replaceAll(/[\u200B-\u200D\uFEFF]/g, '')
        .replaceAll(/&quot;/gm, '"')
        .replaceAll(/&euro;/gm, '€')
        .replaceAll(/&pound;/gm, '£')
        .replaceAll(/&amp;/gm, '&')
        .replaceAll(/&lt;/gm, '<')
        .replaceAll(/&gt;/gm, '>')
        .trim()
    : '';
}
