<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g>
      <path
        class="st8"
        d="M128.08,181.07c-0.07-0.06-0.15-0.12-0.22-0.17l-0.03-0.07c-16.49-12.21-36.56-18.63-57.07-18.26
		c-2.72,0.07-5.03-1.95-5.34-4.65l-0.88-8.13c-0.32-2.87-2.9-4.93-5.77-4.61c-1.07,0.12-2.09,0.57-2.89,1.29l-39.95,35.67
		c-2.15,1.92-2.35,5.22-0.43,7.37c0.3,0.34,0.65,0.64,1.03,0.89l47.21,31.12c2.41,1.58,5.65,0.92,7.23-1.49
		c0.67-1.01,0.96-2.22,0.83-3.43l-0.66-6.05c-0.3-2.88,1.78-5.45,4.66-5.76c0.27-0.03,0.55-0.04,0.82-0.02
		c10.44,0.5,20.49,4.18,28.78,10.54c2.28,1.77,5.57,1.36,7.35-0.92c0.26-0.34,0.48-0.71,0.66-1.1c1.55-3.51,3.28-6.93,5.19-10.24
		c2.98-5.15,6.4-10.04,10.22-14.61C130.64,186.19,130.31,182.9,128.08,181.07z"
      />
      <path
        class="st8"
        d="M268.43,184.97c-0.25-0.38-0.55-0.72-0.89-1.03l0,0.07l-39.95-35.67c-2.14-1.92-5.43-1.75-7.35,0.39
		c-0.74,0.82-1.19,1.85-1.31,2.95l-0.88,8.13c-0.31,2.7-2.63,4.72-5.35,4.65c-44.3-0.86-83.02,29.73-92.43,73.02
		c-0.61,2.82,1.19,5.6,4.01,6.2c0.36,0.08,0.73,0.12,1.1,0.12h30.8c2.21,0.02,4.2-1.36,4.97-3.44c1.04-2.89,2.33-5.69,3.87-8.35
		c8.7-15.06,24.47-24.65,41.85-25.44c2.89-0.14,5.35,2.08,5.49,4.97c0.01,0.27,0.01,0.55-0.02,0.82l-0.66,6.04
		c-0.31,2.87,1.77,5.44,4.63,5.75c1.2,0.13,2.42-0.16,3.43-0.83l47.21-31.13C269.35,190.61,270.02,187.37,268.43,184.97z"
      />
      <path class="st8" d="M89.06,152.18l-0.1,0.12c0.13-0.02,0.27-0.05,0.4-0.06C89.26,152.22,89.16,152.2,89.06,152.18z" />
      <path
        class="st8"
        d="M191.81,152.28h-0.39c-12.12-1.35-23.6-6.13-33.09-13.78c-1.76-2.58-2.44-5.74-1.91-8.82
		c0.44-3.26,1.91-6.3,4.18-8.67c4.81-5.78,8.23-12.58,9.99-19.89h-0.36c1.47-3.77,2.24-7.77,2.25-11.81V73.61
		c0.18-16.95-12.26-31.38-29.05-33.72c-18.19-2.17-34.7,10.82-36.86,29.02c-0.15,1.26-0.23,2.53-0.23,3.8v16.58
		c-0.02,4.65,0.96,9.24,2.85,13.48c0.86,1.88,1.77,3.75,2.54,5.71c1.75,4.52,4.26,8.71,7.42,12.38c2.55,2.7,4.11,6.19,4.42,9.89
		c0.3,2.76-0.41,5.53-2,7.8c-9.26,7.47-20.42,12.2-32.22,13.68c19,3.16,36.78,11.47,51.38,24.05
		C155.26,163.77,172.91,155.47,191.81,152.28z M134.49,78.16h-6.01c0-2.1,0.49-4.16,1.43-6.04c0.86-1.62,2.17-2.95,3.78-3.82
		c1.79-0.91,3.77-1.36,5.78-1.31c2.06-0.05,4.1,0.35,5.98,1.18c1.59,0.73,2.92,1.92,3.81,3.42c0.9,1.65,1.35,3.51,1.3,5.39
		c0.02,1.17-0.17,2.33-0.54,3.44c-0.32,0.93-0.77,1.82-1.33,2.64c-0.51,0.76-1.22,1.68-2.13,2.74c-0.85,0.97-1.64,1.98-2.38,3.04
		c-0.65,0.99-1.17,2.06-1.53,3.18c-0.19,0.6-0.33,1.22-0.41,1.84c-0.19,1.42-1.42,2.48-2.85,2.47h-0.2
		c-1.58,0.03-2.88-1.21-2.92-2.79c-0.01-0.22,0.02-0.45,0.06-0.66c0.1-0.54,0.24-1.08,0.41-1.61c0.4-1.32,0.97-2.58,1.7-3.75
		c0.82-1.22,1.7-2.39,2.64-3.52c0.78-0.97,1.43-1.78,1.84-2.41c0.46-0.66,0.83-1.37,1.11-2.13c0.3-0.8,0.45-1.65,0.44-2.5
		c0.08-1.34-0.4-2.65-1.33-3.62c-0.99-0.91-2.31-1.38-3.65-1.28c-1.41-0.08-2.77,0.51-3.68,1.58
		C134.88,74.95,134.41,76.55,134.49,78.16z M138.83,106.38c-1.58,0-2.85-1.28-2.85-2.85c0-1.58,1.28-2.85,2.85-2.85h0.56
		c1.58,0,2.85,1.28,2.85,2.85c0,1.58-1.28,2.85-2.85,2.85H138.83z"
      />
    </g>
  </svg>
</template>
