<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_318" data-name="Rectangle 318" width="15.396" height="11.532" fill="#cecece" />
      </clipPath>
    </defs>
    <g transform="translate(-22.002)">
      <g class="circle" transform="translate(22.002)" stroke="#cecece" stroke-width="1">
        <circle cx="16" cy="16" r="16" stroke="none" />
        <circle cx="16" cy="16" r="15.5" fill="none" />
      </g>
      <g transform="translate(30.303 10.234)">
        <g clip-path="url(#clip-path)" class="icon">
          <path
            d="M13.829.152a.519.519,0,0,0-.733,0l-7.3,7.3-3.5-3.5a.518.518,0,0,0-.733,0L.152,5.367a.519.519,0,0,0,0,.733l5.28,5.28a.519.519,0,0,0,.733,0L15.245,2.3a.518.518,0,0,0,0-.733Z"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
