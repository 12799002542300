import { reactive, ref, Ref, getCurrentInstance, onMounted } from 'vue';
import { renderSlotName, resolveComponent } from '@views/components/chart/utils/resolver';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

import { saveAs } from 'file-saver';

import api from '@services/api';
import router from '@/router';

import customerInsightModel from '@models/customer-insight';
import useValidationModal from '@views/components/modal/hooks/useValidationModal';
interface Props {
  cId: number;
  isOpen: boolean;
  chartName: string;
  description: string;
  filters: Record<string, any>[];
  dataInfo: DataInfoModal.DataInfoTable;
  chartDataInfoExportProps: Charts.ChartDataInfoExportProps;
}

export default function useDataInfoModal(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const loadingExport: Ref<boolean> = ref(false);
  const { fetchCustomerInsightChartExportDataInfoModel } = customerInsightModel();

  const { openSuccessModal, openErrorModal } = useValidationModal();

  const { t } = useI18n();

  const chartDataInfoExportProps: Ref<Charts.ChartDataInfoExportProps> = ref({
    chartLayoutId: props.chartDataInfoExportProps.chartLayoutId,
    chartId: props.chartDataInfoExportProps.chartId,
    chartSetId: props.chartDataInfoExportProps.chartSetId,
    implType: props.chartDataInfoExportProps.implType,
    implKeyRef: props.chartDataInfoExportProps.implKeyRef,
    filters: props.chartDataInfoExportProps.filters,
  });

  const columns: Ref<BaseTable.TableColumn[]> = ref([]);
  const data: Ref<Record<string, any>[]> = ref([]);
  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 5,
    totalRows: 0,
  });

  const onErrorSetting = () => {
    openErrorModal(
      'เกิดข้อผิดพลาด',
      'มีผู้ Setting Dashboard ก่อนหน้านี้ โปรดลองใหม่อีกครั้ง',
      undefined,
      () => {
        router.push({ path: '/segment/data-segment/main' });
      },
      () => {
        router.push({ path: '/segment/data-segment/main' });
      },
    );
  };

  const onCloseModal = () => {
    vm?.$emit('close-modal');
  };

  const resolveInfoFilterKey = (key: string) => {
    switch (key) {
      case 'startDate':
        return t('bi.startDate');
      case 'endDate':
        return t('bi.endDate');
      case 'source':
        return t('bi.source');
    }
  };

  const resolveInfoFilterValue = (value: string | string[]) => {
    if (value instanceof Array) {
      return value;
    } else {
      const date = new Date(value);
      if (date instanceof Date) {
        return DateTime.fromISO(date.toISOString()).toFormat('dd MMM yyyy hh:mm');
      }
    }
  };

  const onClickExportDataInfo = async (): Promise<void> => {
    loadingExport.value = true;
    fetchCustomerInsightChartExportDataInfoModel.payload.CId = props.cId!;
    fetchCustomerInsightChartExportDataInfoModel.payload.ChartLayoutId = chartDataInfoExportProps.value.chartLayoutId!;
    fetchCustomerInsightChartExportDataInfoModel.payload.ChartSetId = chartDataInfoExportProps.value.chartSetId!;
    fetchCustomerInsightChartExportDataInfoModel.payload.ChartId = chartDataInfoExportProps.value.chartId!;
    fetchCustomerInsightChartExportDataInfoModel.payload.Impl_Type = chartDataInfoExportProps.value.implType!;
    fetchCustomerInsightChartExportDataInfoModel.payload.Impl_Key_Ref = chartDataInfoExportProps.value.implKeyRef;
    fetchCustomerInsightChartExportDataInfoModel.payload.Filters = chartDataInfoExportProps.value.filters.map((el) => ({
      Key: el.key,
      Value: el.value,
    }));

    return api
      .apiRequest(fetchCustomerInsightChartExportDataInfoModel)
      .then((response) => {
        const datesTime = new Date();
        saveAs(
          response as Blob | string,
          `Report-${datesTime.getFullYear()}${datesTime.getMonth()}${datesTime.getDate()}_${datesTime.getHours()}${datesTime.getMinutes()}${datesTime.getSeconds()}.csv`,
        );
      })
      .catch((error) => {
        onErrorSetting();
      })
      .finally(() => {
        loadingExport.value = false;
        openSuccessModal('ทำรายการสำเร็จ');
      });
  };

  onMounted(() => {
    columns.value = props.dataInfo.columns;
    data.value = props.dataInfo.data;
    pagination.totalRows = data.value.length;
  });

  return {
    columns,
    data,
    pagination,
    onCloseModal,
    renderSlotName,
    resolveComponent,
    resolveInfoFilterKey,
    resolveInfoFilterValue,
    onClickExportDataInfo,
    loadingExport,
  };
}
