<template>
  <svg
    id="Group_37477"
    data-name="Group 37477"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="14.323"
    height="11.361"
    viewBox="0 0 14.323 11.361"
  >
    <g id="Group_37476" data-name="Group 37476">
      <path
        id="Path_24347"
        data-name="Path 24347"
        d="M130.055,192.622a.735.735,0,0,1-.259-.047l-.008,0-.1-.052-4.694-2.75c-.016-.009-.032-.02-.048-.031a.649.649,0,0,1-.157-.9h0a.711.711,0,0,1,.576-.3h.011a.725.725,0,0,1,.268.051l.007,0,.091.047,4.317,2.522,4.361-2.52a.728.728,0,0,1,.374-.1h.01a.711.711,0,0,1,.576.3l0,0a.647.647,0,0,1-.108.859l-.005,0-.089.065-4.743,2.754a.723.723,0,0,1-.373.1h-.006"
        transform="translate(-122.918 -185.896)"
        fill="#c3c3c3"
      />
      <path
        id="Path_24348"
        data-name="Path 24348"
        d="M11.939,11.361H2.384A2.385,2.385,0,0,1,0,9.057V2.3A2.385,2.385,0,0,1,2.384,0h9.555a2.385,2.385,0,0,1,2.383,2.3V9.057a2.385,2.385,0,0,1-2.383,2.3M11.9,10.168h.036a1.194,1.194,0,0,0,1.192-1.153V2.344a1.194,1.194,0,0,0-1.192-1.153H2.384A1.194,1.194,0,0,0,1.192,2.344V9.016a1.193,1.193,0,0,0,1.192,1.153H11.9Z"
        transform="translate(0 0)"
        fill="#c3c3c3"
      />
    </g>
  </svg>
</template>
