export default function dataConnectModel() {
  const fetchGetUploadMode: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Csv/GetUploadMode`,
    method: 'GET',
    payload: {},
  };
  const fetchGetFH5integrationSetting: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Fh5/GetFH5integrationSetting`,
    method: 'POST',
    payload: { brand_ref: null },
  };
  const fetchDataConnectListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/DataSource/GetConnectedDatasources`,
    method: 'GET',
    payload: {},
  };

  const fetchCommuListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/DataSource/GetConnectedCommunication`,
    method: 'GET',
    payload: {},
  };

  const fetchAddDataSourceListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/DataSource/GetDatasource`,
    method: 'POST',
    payload: {},
  };

  const fetchAddDataSourceExcelDataSetModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Dataset/GetListDatasetTemplate`,
    method: 'POST',
    payload: {},
  };

  const createDataSourceExcelDataTemplateModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Dataset/CreateCsvCustomTemplate`,
    method: 'POST',
    payload: {},
  };

  const downloadDataSourceExcelDataSetModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Csv/DownloadCsvTemplate`,
    method: 'POST',
    payload: {},
  };

  const deleteDataSourceExcelCustomDataTemplateModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Dataset/DeleteCsvCustomTemplate`,
    method: 'POST',
    payload: {},
  };

  const editDataSourceExcelCustomDataTemplateModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Dataset/EditCsvCustomTemplate`,
    method: 'POST',
    payload: {},
  };

  const confirmUploadExcelDataModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Csv/ConfirmUploadCsv`,
    method: 'POST',
    payload: {},
  };

  const summaryUploadDataModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Csv/SummaryUploadData`,
    method: 'POST',
    payload: {},
  };

  const uploadDataSourceDataModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Csv/UploadCsvFile`,
    method: 'POST',
    payload: {},
  };

  const updateProgressDataSourceDataModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Csv/GetProgressExecute`,
    method: 'POST',
    payload: {},
  };

  const processDataSourceDataModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Csv/ExecuteUploadCsvFile`,
    method: 'POST',
    payload: {},
  };

  const getProcessDataSourceDataModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Csv/GetDetailDataOfCsv`,
    method: 'POST',
    payload: {},
  };

  const confirmUploadDataSourceDataModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Csv/ConfirmUploadCsv`,
    method: 'POST',
    payload: {},
  };

  const cancelUploadProgressDataSourceModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/SyncMsgQueue/CancelSync`,
    method: 'POST',
    payload: {},
  };

  const fetchFileHistoryListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Csv/ListUploadFile`,
    method: 'POST',
    payload: {},
  };

  const downloadErrorReportFileHistoryModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Csv/DownloadErrorDataReport`,
    method: 'POST',
    payload: {},
  };

  const downloadBackupReportFileHistoryModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_DATACONNECT!}/Csv/DownloadCsvBackupFile`,
    method: 'POST',
    payload: {},
  };

  return {
    fetchGetUploadMode,
    fetchGetFH5integrationSetting,
    fetchDataConnectListModel,
    fetchCommuListModel,
    fetchAddDataSourceListModel,
    fetchAddDataSourceExcelDataSetModel,
    createDataSourceExcelDataTemplateModel,
    downloadDataSourceExcelDataSetModel,
    deleteDataSourceExcelCustomDataTemplateModel,
    editDataSourceExcelCustomDataTemplateModel,
    confirmUploadExcelDataModel,
    summaryUploadDataModel,
    uploadDataSourceDataModel,
    updateProgressDataSourceDataModel,
    processDataSourceDataModel,
    getProcessDataSourceDataModel,
    confirmUploadDataSourceDataModel,
    cancelUploadProgressDataSourceModel,
    fetchFileHistoryListModel,
    downloadErrorReportFileHistoryModel,
    downloadBackupReportFileHistoryModel,
  };
}
