import { onBeforeUnmount, ref, Ref, watch, getCurrentInstance, onBeforeMount } from 'vue';

interface Props {
  label: string;
  options: any;
  idOperator: string;
  selectValue: any;
  placeholder: string;
}

export default function useSelectOperator(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const refSelectOperator: Ref<HTMLElement | null> = ref(null);
  const selectedValue: Ref<string> = ref('');
  const isOpenOption: Ref<boolean> = ref(false);
  const optionList: Ref<Record<string, any>> = ref([]);
  const optionListNone: Ref<Record<string, any>> = ref([]);

  const onCreateOperatorActive = () => {
    optionList.value = props.options.filter((ops: Record<string, any>) => ops.is_filter === true);
  };

  const selectedItemOperator = (item: Record<string, any>) => {
    selectedValue.value = item[props.label];
    isOpenOption.value = false;
    vm?.$emit('on-selected', JSON.parse(JSON.stringify(item)));
  };

  const onClickOutsideMenu = (event: Event) => {
    const ListGroup = document.querySelectorAll(`#${props.idOperator}`);
    ListGroup.forEach((el: Element) => {
      const ListGroup = (<HTMLInputElement>el).contains(event.target as HTMLInputElement);

      if (!ListGroup) {
        isOpenOption.value = false;
      }
    });
  };

  onBeforeMount(() => {
    onCreateOperatorActive();
    selectedValue.value = props.selectValue[props.label];
    window.addEventListener('click', onClickOutsideMenu);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('click', onClickOutsideMenu);
  });

  watch(
    () => props.selectValue,
    () => {
      selectedValue.value = props.selectValue[props.label];
    },
    { deep: true },
  );

  watch(
    () => props.options,
    () => {
      onCreateOperatorActive();
    },
    { deep: true },
  );

  return {
    selectedValue,
    isOpenOption,
    refSelectOperator,
    optionList,
    optionListNone,
    selectedItemOperator,
  };
}
