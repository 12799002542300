<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="19" viewBox="0 0 25.612 21.35">
    <g id="Group_47871" data-name="Group 47871" transform="translate(1367.558 -10322.999)">
      <path
        id="Path_24330"
        data-name="Path 24330"
        d="M31.614,19.761h-.156l-7.217-7.895a1.22,1.22,0,0,0-.85-.4h-.057a1.216,1.216,0,0,0-.826.321c-.028.025-.054.051-.08.079L16.685,18.15a.2.2,0,0,1-.293,0l-2.36-2.581a1.23,1.23,0,0,0-1.736-.078c-.027.025-.052.051-.077.078l-3.66,4a.584.584,0,0,1-.17-.4V16.458l5.127-5.318a.259.259,0,0,1,.191-.076.178.178,0,0,1,.168.081l2.633,2.88a.829.829,0,0,0,.578.269.8.8,0,0,0,.6-.217c.018-.017.035-.035.052-.052L24.623,6.49v3.725h1.288V4.493h-6.3V5.782h3.912l-6.4,7-2.3-2.511a1.543,1.543,0,0,0-1.117-.5,1.709,1.709,0,0,0-1.121.47L8.385,14.6V.794A.795.795,0,0,0,6.8.794V19.861A1.491,1.491,0,0,0,8.285,21.35H31.614a.795.795,0,0,0,0-1.589"
        transform="translate(-1374.354 10322.999)"
      />
    </g>
  </svg>
</template>
