import { ref, Ref, watch, getCurrentInstance, onMounted, toRaw, reactive } from 'vue';
import api from '@services/api';

import filterTable from '@models/segment/filterTable';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import { DateTime } from 'luxon';
import { dateColumn } from '@/views/components/table/dynamic-table/config/column-config';

interface Props {
  previewTableId: number;
  dataPreviewTable: any;
}

export default function usePreviewSelectColumn(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const { fetchFilterTableAndOrdering } = filterTable();
  const { openDefaultErrorModal } = useValidationModal();

  const mapDataFilter: Ref<Record<string, any>> = ref([]);
  const previewData: Ref<Record<string, any>> = ref([]);
  const previewDataList: Ref<Record<string, any>> = ref([]);
  const isOpenPreviewData: Ref<boolean> = ref(false);
  const isLoadingDisplayModal: Ref<boolean> = ref(false);
  const isLoading: Ref<boolean> = ref(false);
  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });
  const totalRecord: Ref<number> = ref(0);
  const filteredRecord: Ref<number> = ref(0);
  const totalPage: Ref<number> = ref(0);

  const onSortDataFilter = (e: Record<string, any> | any) => {
    mapDataFilter.value = toRaw(e);
  };

  const onRemoveObject = (item: Record<string, any> | any) => {
    const removeItemFilter = item;
    vm?.$emit('on-remove-item', removeItemFilter);
  };

  const onLoadPreviewResult = (preview: Record<string, any> | any) => {
    isOpenPreviewData.value = true;
    pagination.currentPage = 1;
    previewData.value = preview;
    onFilterTable();
  };

  const closePreviewData = () => {
    isOpenPreviewData.value = false;
  };

  const onScrolled = (e: Event | any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (previewDataList.value.length != filteredRecord.value) {
      if (!isLoading.value && scrollTop + clientHeight >= scrollHeight) {
        isLoading.value = true;
        pagination.currentPage++;
        onFilterTable(true);
      }
    }
  };

  const onFilterTable = (staus?: boolean) => {
    if (!staus) {
      isLoadingDisplayModal.value = true;
    }

    fetchFilterTableAndOrdering.payload.TableId = props.previewTableId;
    fetchFilterTableAndOrdering.payload.ColumnId = previewData.value.column_selected.column_id;
    fetchFilterTableAndOrdering.payload.Key_Column = previewData.value.Key;
    fetchFilterTableAndOrdering.payload.Page = pagination.currentPage;
    fetchFilterTableAndOrdering.payload.Limit = pagination.perPage;
    fetchFilterTableAndOrdering.payload.Filters = previewData.value.filters;

    api
      .apiRequest(fetchFilterTableAndOrdering)
      .then((response) => {
        if (!staus) {
          previewDataList.value = response.data.datalist;
        } else {
          previewDataList.value.push(...response.data.datalist);
        }
        totalRecord.value = response.data.total_record;
        filteredRecord.value = response.data.filtered_record;
        totalPage.value = response.data.total_page;
      })
      .catch((error) => {
        isLoadingDisplayModal.value = false;
        isLoading.value = false;
        openDefaultErrorModal(error);
      })
      .finally(() => {
        isLoadingDisplayModal.value = false;
        isLoading.value = false;
      });
  };

  const onConvertItem = (item: any, type: any) => {
    const rusult2 = mapDataFilter.value.filter((el: any) => el.label == type);
    const resultType = String(rusult2[0].column_selected.type_desc);
    const resultFormetDate = String(rusult2[0].column_selected.date_format);
    const userData = JSON.parse(localStorage.getItem('userInfo') || '{}');
    if (item == null || item == '') {
      return 'blank';
    } else {
      if (resultType == 'Date') {
        const Obj = item[type];
        return DateTime.fromISO(Obj, { zone: userData.time_zone || dateColumn.time_zone }).toFormat(resultFormetDate);
      } else {
        return item[type];
      }
    }
  };

  const onConvertShowItem = (item: any, format: any, type: any) => {
    const userData = JSON.parse(localStorage.getItem('userInfo') || '{}');
    if (item == null || item == '') {
      return 'blank';
    } else {
      if (type == 'Date') {
        const Obj = item;
        const map: any = [];
        Obj.forEach((e: any) => {
          map.push(DateTime.fromISO(e, { zone: userData.time_zone || dateColumn.time_zone }).toFormat(format || dateColumn.date_format));
        });
        return map.join(', ');
      } else {
        const list = item;
        return list.length > 1 ? item.join(', ') : String(item);
      }
    }
  };

  onMounted(() => {
    if (props.dataPreviewTable) {
      onSortDataFilter(props.dataPreviewTable);
    }
  });

  watch(
    () => props.dataPreviewTable,
    () => {
      onSortDataFilter(props.dataPreviewTable);
    },
    // { deep: true },
  );

  return {
    mapDataFilter,
    isLoadingDisplayModal,
    isOpenPreviewData,
    previewData,
    previewDataList,
    isLoading,
    closePreviewData,
    onConvertItem,
    onScrolled,
    onRemoveObject,
    onLoadPreviewResult,
    onConvertShowItem,
  };
}
