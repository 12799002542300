import { ref, Ref, reactive, toRaw, watch } from 'vue';
import router from '@/router';
import { RouteLocationRaw } from 'vue-router';
import api from '@services/api';
import segmentResultModel from '@models/segment/result';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useLoading from '@/views/components/loading/hooks/useLoading';

type SaveType = 1 | 2 | 3;
type SelectFlag = 1 | 2;

interface SegmentSavePayload {
  Save_Type: SaveType;
  Type: number;
  Name: string;
  SegmentId: number;
  Apply_SegmentId: number;
  Dest_SegmentId: number;
  Root_TableId: number;
  Root_FolderId: number;
  Dest_FolderId: number;
  saveColumns: PreviewSegment.SaveColumns[];
  select_flag: SelectFlag;
  Datas: PreviewSegment.Datas[];
  Dest_Root_TableId: number;
  ResultType: number;
  View_Select_Main: number;
  displayTables?: any;
}

interface Props {
  selectedSource: Segment.Datasource;
}

interface SegmentDialogList {
  folderSelect: {
    folderId: number;
    label: string;
  };
  segmentSelect: {
    segmentId: number;
    label: string;
  };
  TableId: number;
  column_type_flags: number;
  segmentName: string;
}

interface SegmentPayloadSuccess {
  segment_id: number;
  segment_type: number;
  segment_type_desc: string;
  segment_size: number;
  is_displaycreate: boolean;
}

interface SegmentViewmode {
  selectedOption: {
    mode: number;
    title: string;
    type: number;
  };
}

export default function useSaveSegmentDetail(
  includeOrExcludeList: Ref<Record<string, any>[]>,
  dataSourceDisplay: Ref<PreviewSegment.DatasourceDisplay>,
  props: Props,
  isSelectedAll: Ref<boolean>,
  totalRecord: Ref<number>,
  selectViewMode: SegmentViewmode,
  payloadForUpdate: any,
) {
  const { isLoading } = useLoading();
  const { openSuccessModal, openDefaultErrorModal } = useValidationModal();
  const { fetchSegmentSaveModel } = segmentResultModel();
  const dataDialogList: Ref<any> = ref([]);
  const isOpenModalRename: Ref<boolean> = ref(false);
  const responseDetailSegment: SegmentPayloadSuccess = reactive({
    segment_id: 0,
    segment_type: 0,
    segment_type_desc: '',
    segment_size: 0,
    is_displaycreate: false,
  });
  const isLoadingActive: Ref<boolean> = ref(false);
  const nameDialog: Ref<string> = ref('');
  const typeModalAction: Ref<string> = ref('');
  const dataFolder: Ref<Record<string, any>> = ref(router.currentRoute.value.query);

  const urlRouter: Ref<RouteLocationRaw> = ref({
    path: '/segment/data-segment/folder-inside',
    query: {
      tab: router.currentRoute.value.query.tab,
      folderId: router.currentRoute.value.query.folderId,
      folderName: router.currentRoute.value.query.folderName,
      tableId: router.currentRoute.value.query.tableId,
      subtab: router.currentRoute.value.query.subtab,
      page: router.currentRoute.value.query.page,
    },
  });
  const urlRouterCurrent: Ref<RouteLocationRaw> = ref({
    path: '/segment/data-segment/segment-detail',
    query: {
      ...router.currentRoute.value.query,
    },
  });

  const breadcrumbList: Ref<BreadCrumbList.BreadCrumb[]> = ref([
    { key: 1, url: urlRouter.value, name: dataFolder.value.folderName },
    { key: 2, url: urlRouterCurrent.value, name: dataFolder.value.segmentName },
  ]);

  const onSubmitDialog = (data?: any) => {
    if (data == 'delete') {
      typeModalAction.value = data;
      onLoadDataSave();
    } else {
      dataDialogList.value = data;
      nameDialog.value = data.segmentName;
      onLoadDataSave();
    }
  };

  const isOpenModalDelete: Ref<boolean> = ref(false);
  const deleteName: Ref<string> = ref('');

  const onDeleteSegment = () => {
    if (isSelectedAll.value && includeOrExcludeList.value.length <= 0) {
      deleteName.value = String(totalRecord.value);
    } else if (includeOrExcludeList.value.length > 0 && isSelectedAll.value) {
      deleteName.value = String(totalRecord.value - includeOrExcludeList.value.length);
    } else {
      deleteName.value = String(includeOrExcludeList.value.length);
    }
    isOpenModalDelete.value = true;
  };

  const onCloseDeleteModal = () => {
    isOpenModalDelete.value = false;
  };

  const onLoadDataSave = () => {
    const mapColumn = (dataSourceDisplay: PreviewSegment.DatasourceDisplay): PreviewSegment.SaveColumns[] => {
      const saveColumns: PreviewSegment.SaveColumns[] = [];
      dataSourceDisplay.columns.forEach((element) => {
        const obj: PreviewSegment.SaveColumns = {
          ColumnId: element.column_id,
          Ref_columnId: element.ref_columnid,
          Col_Type: element.col_type,
        };
        saveColumns.push(obj);
      });
      return saveColumns;
    };

    const mapData = (includeOrExcludeList: Record<string, any>[]): PreviewSegment.Datas[] => {
      const datas: PreviewSegment.Datas[] = [];
      includeOrExcludeList.forEach((element) => {
        const dataMap: PreviewSegment.Data_Map[] = [];
        sourceDisplay.columns.forEach((el: PreviewSegment.DatasourceDisplayColumns) => {
          const obj: PreviewSegment.Data_Map = {
            ColumnId: el.column_id,
            Value: element[el.key],
          };
          dataMap.push(obj);
        });
        datas.push({ Data_Map: dataMap });
      });
      return datas;
    };

    const dataDialogOrder: SegmentDialogList = toRaw(dataDialogList.value);
    const ListIncludeOrExclude: Record<string, any>[] = toRaw(includeOrExcludeList.value);
    const sourceDisplay: PreviewSegment.DatasourceDisplay = toRaw(dataSourceDisplay.value);

    const payload: SegmentSavePayload = {
      Save_Type: !typeModalAction.value ? 1 : 2,
      Type: 3,
      Name: dataDialogOrder.segmentName,
      SegmentId: null!,
      Apply_SegmentId: Number(router.currentRoute.value.query.segmentId),
      Dest_SegmentId: !typeModalAction.value ? null! : Number(router.currentRoute.value.query.segmentId),
      Root_TableId: props.selectedSource.tableid,
      Root_FolderId: !typeModalAction.value ? null! : Number(router.currentRoute.value.query.folderId),
      Dest_FolderId: !typeModalAction.value ? dataDialogOrder.folderSelect.folderId : Number(router.currentRoute.value.query.folderId),
      saveColumns: mapColumn(sourceDisplay),
      select_flag: isSelectedAll.value ? 2 : 1,
      Datas: mapData(ListIncludeOrExclude),
      Dest_Root_TableId: selectViewMode.selectedOption.mode != 0 ? dataDialogOrder.TableId : null!,
      ResultType: selectViewMode.selectedOption.mode != 0 ? toRaw(selectViewMode.selectedOption.type) : null!,
      View_Select_Main: selectViewMode.selectedOption.mode != 0 ? toRaw(selectViewMode.selectedOption.mode) : null!,
      displayTables: payloadForUpdate.value != undefined ? (payloadForUpdate.value != null ? payloadForUpdate.value : null) : null,
    };

    onSaveDialogSelect(payload);
  };
  const onSaveDialogSelect = (payload: SegmentSavePayload) => {
    isOpenModalDelete.value = false;
    isLoading(true, 'กำลังบันทึก กรุณารอสักครู่...');
    isLoadingActive.value = true;
    fetchSegmentSaveModel.payload = payload;

    api
      .apiRequest(fetchSegmentSaveModel)
      .then((response) => {
        if (response.status === 'success') {
          responseDetailSegment.segment_id = response.data.segment_id;
          responseDetailSegment.segment_type = response.data.segment_type;
          openSuccessModal(
            'บันทึกรายการสำเร็จ',
            '',
            '',
            () => onDirectSegment(),
            () => onDirectSegment(),
          );
        }
      })
      .catch((error) => {
        if (error.data.error.message == 'Name of Segment is duplicate') {
          isLoadingActive.value = false;
          isOpenModalRename.value = true;
        } else {
          openDefaultErrorModal(error);
        }
      })
      .finally(() => {
        isLoadingActive.value = false;
        isLoading(false);
      });
  };

  const isModalSubmitOpen: Ref<boolean> = ref(false);
  const onOpenEditName = () => {
    isModalSubmitOpen.value = true;
    isOpenModalRename.value = false;
  };

  const refreshTable: Ref<boolean> = ref(false);

  const resolveTabs = (ColTypeFlag: number) => {
    switch (ColTypeFlag) {
      case 1:
        return 0;
      case 2:
        return 1;
      case 4:
        return 2;
      default:
        return router.currentRoute.value.query.tab;
    }
  };

  const onDirectSegment = () => {
    localStorage.setItem('listNewData', JSON.stringify(toRaw(includeOrExcludeList.value)));
    const dialogDataList: SegmentDialogList = toRaw(dataDialogList.value);

    if (typeModalAction.value) {
      refreshTable.value = true;
    } else {
      urlRouter.value = {
        path: '/segment/data-segment/folder-inside',
        query: {
          folderId: dataDialogList.value.folderSelect.folderId,
          folderName: dataDialogList.value.folderSelect.label,
          tab: router.currentRoute.value.query.tab,
          subtab: router.currentRoute.value.query.subtab,
          page: router.currentRoute.value.query.page,
        },
      };

      urlRouterCurrent.value = {
        path: '/segment/data-segment/folder-inside',
        query: {
          ...router.currentRoute.value.query,
          segmentId: responseDetailSegment.segment_id,
          segmentName: dataDialogList.value.segmentName,
        },
      };

      breadcrumbList.value = [
        { key: 1, url: urlRouter.value, name: dataDialogList.value.folderSelect.label },
        { key: 2, url: urlRouterCurrent.value, name: dataDialogList.value.segmentName },
      ];

      router.push({
        path: '/segment/data-segment/segment-detail',
        query: {
          tab: resolveTabs(dialogDataList.column_type_flags),
          folderId: dataDialogList.value.folderSelect.folderId,
          folderName: dataDialogList.value.folderSelect.label,
          segmentId: responseDetailSegment.segment_id,
          segmentName: dataDialogList.value.segmentName,
          type: responseDetailSegment.segment_type,
        },
      });
    }
  };

  watch(
    () => router.currentRoute.value.query.segmentName,
    (newSegmentName) => {
      if (newSegmentName) {
        if (dataFolder.value.folderName) {
          urlRouterCurrent.value = {
            path: '/segment/data-segment/folder-inside',
            query: {
              ...router.currentRoute.value.query,
              segmentName: newSegmentName,
            },
          };

          breadcrumbList.value = [
            { key: 1, url: urlRouter.value, name: dataFolder.value.folderName },
            { key: 2, url: urlRouterCurrent.value, name: newSegmentName },
          ];
        }
      }
    },
  );

  return {
    breadcrumbList,
    nameDialog,
    isOpenModalRename,
    isLoadingActive,
    isModalSubmitOpen,
    onSubmitDialog,
    onOpenEditName,
    onDeleteSegment,
    isOpenModalDelete,
    deleteName,
    onCloseDeleteModal,
    refreshTable,
  };
}
