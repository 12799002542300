import { reactive, ref, Ref, watch } from 'vue';
import { renderSlotName, resolveComponent } from '@views/components/chart/utils/resolver';
import { deepClone } from '@/views/modules/customer-insight/utils';

interface Props {
  columns: ChartTable.TableColumn[];
  data: Record<string, any>[];
  isPagination: boolean;
}

export default function (props: Props) {
  const columns: Ref<ChartTable.TableColumn[]> = ref(props.columns);
  const data: Ref<Record<string, any>[]> = ref(props.data);
  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 5,
    totalRows: 0,
  });

  // Capture the initial order when data is first loaded
  const initialData: Record<string, any>[] = data.value;

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    const sortBy = sortedColumn.key;
    const sortDesc = sortedColumn.direction;

    const isThai = (text: string) => {
      const thaiCharPattern = /[\u0E00-\u0E7F]/;
      return thaiCharPattern.test(text);
    };

    data.value.sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];

      let comparison;
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const aIsThai = isThai(aValue);
        const bIsThai = isThai(bValue);

        if (aIsThai && bIsThai) {
          comparison = aValue.localeCompare(bValue, 'th', { sensitivity: 'base' });
        } else {
          comparison = aValue.localeCompare(bValue, undefined, { sensitivity: 'base' });
        }
      } else if (typeof aValue === 'number' && typeof bValue === 'number') {
        comparison = aValue - bValue;
      } else {
        // Handle other types if needed
        comparison = 0;
      }

      return comparison;
    });

    if (sortDesc === 'DESC') {
      data.value.reverse();
      document.getElementById('sorting-' + sortedColumn.key)?.classList.add('desc');
    } else if (sortDesc === 'ASC') {
      document.getElementById('sorting-' + sortedColumn.key)?.classList.add('asc');
    } else {
      // Restore to initial order if sort direction is not specified or invalid
      data.value = deepClone(initialData); // คืนค่าเป็น deep copy ของ initialData
    }
  };

  watch(
    data,
    () => {
      if (!props.isPagination) pagination.perPage = data.value.length;
      pagination.totalRows = data.value.length;
    },
    { immediate: true },
  );

  return { columns, data, pagination, renderSlotName, resolveComponent, onSort };
}
