export const mockData = {
  request_id: '5827b7f4-78b3-4865-a889-06babc5e734a',
  status: 'success',
  data: {
    total_record: 60,
    page: 1,
    limit: 10,
    total_page: 6,
    structuretable: [
      {
        cid: 1,
        merge_cid: null,
        sourceid: 1,
        dataid: null,
        first_name: 'Mr. A',
        last_name: 'L',
        mobileno: '0812223333',
        homeno: null,
        faxno: null,
        gender: null,
        email: null,
        age: null,
        birthdate: null,
        nationality: null,
        religion: null,
        educational: null,
        occupation: null,
        maritalstatus: null,
        cdp_updated_dt: null,
        cdp_created_dt: '2022-06-17T00:00:00',
        cdp_isdelete: false,
        cdp_recordstate: 1,
        call_to_action: 1,
      },
      {
        cid: 2,
        merge_cid: null,
        sourceid: 1,
        dataid: null,
        first_name: 'Mr. B',
        last_name: 'LL',
        mobileno: '0817778888',
        homeno: null,
        faxno: null,
        gender: null,
        email: null,
        age: null,
        birthdate: null,
        nationality: null,
        religion: null,
        educational: null,
        occupation: null,
        maritalstatus: null,
        cdp_updated_dt: null,
        cdp_created_dt: '2022-06-17T00:00:00',
        cdp_isdelete: false,
        cdp_recordstate: 1,
        call_to_action: 2,
      },
      {
        cid: 3,
        merge_cid: null,
        sourceid: 1,
        dataid: null,
        first_name: 'Mr. C',
        last_name: 'LLL',
        mobileno: '0818889999',
        homeno: null,
        faxno: null,
        gender: null,
        email: null,
        age: null,
        birthdate: null,
        nationality: null,
        religion: null,
        educational: null,
        occupation: null,
        maritalstatus: null,
        cdp_updated_dt: null,
        cdp_created_dt: '2022-06-17T00:00:00',
        cdp_isdelete: false,
        cdp_recordstate: 1,
        call_to_action: 3,
      },
      {
        cid: 4,
        merge_cid: null,
        sourceid: 1,
        dataid: null,
        first_name: 'Mr. D',
        last_name: 'LLLL',
        mobileno: '0818112222',
        homeno: null,
        faxno: null,
        gender: null,
        email: null,
        age: null,
        birthdate: null,
        nationality: null,
        religion: null,
        educational: null,
        occupation: null,
        maritalstatus: null,
        cdp_updated_dt: null,
        cdp_created_dt: '2022-06-17T00:00:00',
        cdp_isdelete: false,
        cdp_recordstate: 1,
        call_to_action: 4,
      },
      {
        cid: 5,
        merge_cid: null,
        sourceid: 1,
        dataid: null,
        first_name: 'Mr. E',
        last_name: 'LLLLL',
        mobileno: null,
        homeno: null,
        faxno: null,
        gender: null,
        email: null,
        age: null,
        birthdate: null,
        nationality: null,
        religion: null,
        educational: null,
        occupation: null,
        maritalstatus: null,
        cdp_updated_dt: null,
        cdp_created_dt: '2022-06-17T00:00:00',
        cdp_isdelete: false,
        cdp_recordstate: 1,
        call_to_action: 5,
      },
      {
        cid: 6,
        merge_cid: null,
        sourceid: 1,
        dataid: null,
        first_name: 'Mr. F',
        last_name: 'LLLLLL',
        mobileno: null,
        homeno: null,
        faxno: null,
        gender: null,
        email: null,
        age: null,
        birthdate: null,
        nationality: null,
        religion: null,
        educational: null,
        occupation: null,
        maritalstatus: null,
        cdp_updated_dt: null,
        cdp_created_dt: '2022-06-17T00:00:00',
        cdp_isdelete: false,
        cdp_recordstate: 1,
        call_to_action: 2,
      },
      {
        cid: 7,
        merge_cid: null,
        sourceid: 1,
        dataid: null,
        first_name: 'Mr. G',
        last_name: 'LLLLLLL',
        mobileno: null,
        homeno: null,
        faxno: null,
        gender: null,
        email: null,
        age: null,
        birthdate: null,
        nationality: null,
        religion: null,
        educational: null,
        occupation: null,
        maritalstatus: null,
        cdp_updated_dt: null,
        cdp_created_dt: '2022-06-17T00:00:00',
        cdp_isdelete: false,
        cdp_recordstate: 1,
        call_to_action: 2,
      },
      {
        cid: 8,
        merge_cid: null,
        sourceid: 1,
        dataid: null,
        first_name: 'Mr. H',
        last_name: 'LLLLLLLL',
        mobileno: null,
        homeno: null,
        faxno: null,
        gender: null,
        email: null,
        age: null,
        birthdate: null,
        nationality: null,
        religion: null,
        educational: null,
        occupation: null,
        maritalstatus: null,
        cdp_updated_dt: null,
        cdp_created_dt: '2022-06-17T00:00:00',
        cdp_isdelete: false,
        cdp_recordstate: 1,
        call_to_action: 2,
      },
      {
        cid: 9,
        merge_cid: null,
        sourceid: 1,
        dataid: null,
        first_name: 'Mr. I',
        last_name: 'LLLLLLLLL',
        mobileno: null,
        homeno: null,
        faxno: null,
        gender: null,
        email: null,
        age: null,
        birthdate: null,
        nationality: null,
        religion: null,
        educational: null,
        occupation: null,
        maritalstatus: null,
        cdp_updated_dt: null,
        cdp_created_dt: '2022-06-17T00:00:00',
        cdp_isdelete: false,
        cdp_recordstate: 1,
        call_to_action: 2,
      },
      {
        cid: 10,
        merge_cid: null,
        sourceid: 1,
        dataid: null,
        first_name: 'Mr. J',
        last_name: 'LLLLLLLLLL',
        mobileno: null,
        homeno: null,
        faxno: null,
        gender: null,
        email: null,
        age: null,
        birthdate: null,
        nationality: null,
        religion: null,
        educational: null,
        occupation: null,
        maritalstatus: null,
        cdp_updated_dt: null,
        cdp_created_dt: '2022-06-17T00:00:00',
        cdp_isdelete: false,
        cdp_recordstate: 1,
        call_to_action: 2,
      },
    ],
  },
  error: null,
};

export const mockColumn = [
  {
    seq: 1,
    column_id: 2298,
    ref_columnid: null,
    ref_tableid: null,
    key: 'cid',
    column_alias: 'CID',
    label_en: 'CID',
    label_th: 'CID',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'GreaterThan', value: 2, description: '>' },
      { name: 'LessThan', value: 3, description: '<' },
      { name: 'GreaterOrEqual', value: 4, description: '>=' },
      { name: 'LessOrEqual', value: 5, description: '<=' },
      { name: 'Between', value: 9, description: 'BETWEEN' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: true,
    cdp_unique_index: 1,
    nullable: false,
    data_type: 15,
    data_type_desc: 'bigint',
    col_type: 101,
    col_type_desc: 'cid-m',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 2,
    column_id: 2299,
    ref_columnid: null,
    ref_tableid: null,
    key: 'merge_cid',
    column_alias: 'Merge_CID',
    label_en: 'Merge_CID',
    label_th: 'Merge_CID',
    sortable: true,
    type_id: 1,
    type_desc: 'Date',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'GreaterThan', value: 2, description: '>' },
      { name: 'LessThan', value: 3, description: '<' },
      { name: 'GreaterOrEqual', value: 4, description: '>=' },
      { name: 'LessOrEqual', value: 5, description: '<=' },
      { name: 'Between', value: 9, description: 'BETWEEN' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 15,
    data_type_desc: 'bigint',
    col_type: 110,
    col_type_desc: 'cid-merge-to',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 3,
    column_id: 2300,
    ref_columnid: null,
    ref_tableid: null,
    key: 'sourceid',
    column_alias: 'SourceId',
    label_en: 'SourceId',
    label_th: 'SourceId',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'GreaterThan', value: 2, description: '>' },
      { name: 'LessThan', value: 3, description: '<' },
      { name: 'GreaterOrEqual', value: 4, description: '>=' },
      { name: 'LessOrEqual', value: 5, description: '<=' },
      { name: 'Between', value: 9, description: 'BETWEEN' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: false,
    data_type: 14,
    data_type_desc: 'int',
    col_type: 103,
    col_type_desc: 'cid-sid',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 4,
    column_id: 2301,
    ref_columnid: null,
    ref_tableid: null,
    key: 'dataid',
    column_alias: 'DataId',
    label_en: 'DataId',
    label_th: 'DataId',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'GreaterThan', value: 2, description: '>' },
      { name: 'LessThan', value: 3, description: '<' },
      { name: 'GreaterOrEqual', value: 4, description: '>=' },
      { name: 'LessOrEqual', value: 5, description: '<=' },
      { name: 'Between', value: 9, description: 'BETWEEN' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 14,
    data_type_desc: 'int',
    col_type: 104,
    col_type_desc: 'cid-sdid',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 5,
    column_id: 2302,
    ref_columnid: null,
    ref_tableid: null,
    key: 'first_name',
    column_alias: 'First_Name',
    label_en: 'First_Name',
    label_th: 'First_Name',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'Like_Contain', value: 6, description: '%-%' },
      { name: 'Like_StartWith', value: 7, description: '-%' },
      { name: 'Like_EndWith', value: 8, description: '%-' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 52,
    data_type_desc: 'nvarchar',
    col_type: 1,
    col_type_desc: 'generic',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 6,
    column_id: 2303,
    ref_columnid: null,
    ref_tableid: null,
    key: 'last_name',
    column_alias: 'Last_Name',
    label_en: 'Last_Name',
    label_th: 'Last_Name',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'Like_Contain', value: 6, description: '%-%' },
      { name: 'Like_StartWith', value: 7, description: '-%' },
      { name: 'Like_EndWith', value: 8, description: '%-' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 52,
    data_type_desc: 'nvarchar',
    col_type: 1,
    col_type_desc: 'generic',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 7,
    column_id: 2304,
    ref_columnid: null,
    ref_tableid: null,
    key: 'mobileno',
    column_alias: 'MobileNo',
    label_en: 'MobileNo',
    label_th: 'MobileNo',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'Like_Contain', value: 6, description: '%-%' },
      { name: 'Like_StartWith', value: 7, description: '-%' },
      { name: 'Like_EndWith', value: 8, description: '%-' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 52,
    data_type_desc: 'nvarchar',
    col_type: 1,
    col_type_desc: 'generic',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 8,
    column_id: 4095,
    ref_columnid: null,
    ref_tableid: null,
    key: 'homeno',
    column_alias: 'HomeNo',
    label_en: 'HomeNo',
    label_th: 'HomeNo',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'Like_Contain', value: 6, description: '%-%' },
      { name: 'Like_StartWith', value: 7, description: '-%' },
      { name: 'Like_EndWith', value: 8, description: '%-' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 52,
    data_type_desc: 'nvarchar',
    col_type: 1,
    col_type_desc: 'generic',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 9,
    column_id: 4096,
    ref_columnid: null,
    ref_tableid: null,
    key: 'faxno',
    column_alias: 'FaxNo',
    label_en: 'FaxNo',
    label_th: 'FaxNo',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'Like_Contain', value: 6, description: '%-%' },
      { name: 'Like_StartWith', value: 7, description: '-%' },
      { name: 'Like_EndWith', value: 8, description: '%-' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 52,
    data_type_desc: 'nvarchar',
    col_type: 1,
    col_type_desc: 'generic',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 10,
    column_id: 4097,
    ref_columnid: null,
    ref_tableid: null,
    key: 'gender',
    column_alias: 'Gender',
    label_en: 'Gender',
    label_th: 'Gender',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'Like_Contain', value: 6, description: '%-%' },
      { name: 'Like_StartWith', value: 7, description: '-%' },
      { name: 'Like_EndWith', value: 8, description: '%-' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 52,
    data_type_desc: 'nvarchar',
    col_type: 1,
    col_type_desc: 'generic',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 11,
    column_id: 4098,
    ref_columnid: null,
    ref_tableid: null,
    key: 'email',
    column_alias: 'Email',
    label_en: 'Email',
    label_th: 'Email',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'Like_Contain', value: 6, description: '%-%' },
      { name: 'Like_StartWith', value: 7, description: '-%' },
      { name: 'Like_EndWith', value: 8, description: '%-' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 52,
    data_type_desc: 'nvarchar',
    col_type: 1,
    col_type_desc: 'generic',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 12,
    column_id: 4099,
    ref_columnid: null,
    ref_tableid: null,
    key: 'age',
    column_alias: 'Age',
    label_en: 'Age',
    label_th: 'Age',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'GreaterThan', value: 2, description: '>' },
      { name: 'LessThan', value: 3, description: '<' },
      { name: 'GreaterOrEqual', value: 4, description: '>=' },
      { name: 'LessOrEqual', value: 5, description: '<=' },
      { name: 'Between', value: 9, description: 'BETWEEN' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 14,
    data_type_desc: 'int',
    col_type: 1,
    col_type_desc: 'generic',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 13,
    column_id: 4100,
    ref_columnid: null,
    ref_tableid: null,
    key: 'birthdate',
    column_alias: 'Birthdate',
    label_en: 'Birthdate',
    label_th: 'Birthdate',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'GreaterThan', value: 2, description: '>' },
      { name: 'LessThan', value: 3, description: '<' },
      { name: 'GreaterOrEqual', value: 4, description: '>=' },
      { name: 'LessOrEqual', value: 5, description: '<=' },
      { name: 'Between', value: 9, description: 'BETWEEN' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 33,
    data_type_desc: 'datetime2',
    col_type: 1,
    col_type_desc: 'generic',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 14,
    column_id: 4101,
    ref_columnid: null,
    ref_tableid: null,
    key: 'nationality',
    column_alias: 'Nationality',
    label_en: 'Nationality',
    label_th: 'Nationality',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'Like_Contain', value: 6, description: '%-%' },
      { name: 'Like_StartWith', value: 7, description: '-%' },
      { name: 'Like_EndWith', value: 8, description: '%-' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 52,
    data_type_desc: 'nvarchar',
    col_type: 1,
    col_type_desc: 'generic',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 15,
    column_id: 4102,
    ref_columnid: null,
    ref_tableid: null,
    key: 'religion',
    column_alias: 'Religion',
    label_en: 'Religion',
    label_th: 'Religion',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'Like_Contain', value: 6, description: '%-%' },
      { name: 'Like_StartWith', value: 7, description: '-%' },
      { name: 'Like_EndWith', value: 8, description: '%-' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 52,
    data_type_desc: 'nvarchar',
    col_type: 1,
    col_type_desc: 'generic',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 16,
    column_id: 4103,
    ref_columnid: null,
    ref_tableid: null,
    key: 'educational',
    column_alias: 'Educational',
    label_en: 'Educational',
    label_th: 'Educational',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'Like_Contain', value: 6, description: '%-%' },
      { name: 'Like_StartWith', value: 7, description: '-%' },
      { name: 'Like_EndWith', value: 8, description: '%-' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 52,
    data_type_desc: 'nvarchar',
    col_type: 1,
    col_type_desc: 'generic',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 17,
    column_id: 4104,
    ref_columnid: null,
    ref_tableid: null,
    key: 'occupation',
    column_alias: 'Occupation',
    label_en: 'Occupation',
    label_th: 'Occupation',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'Like_Contain', value: 6, description: '%-%' },
      { name: 'Like_StartWith', value: 7, description: '-%' },
      { name: 'Like_EndWith', value: 8, description: '%-' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 52,
    data_type_desc: 'nvarchar',
    col_type: 1,
    col_type_desc: 'generic',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 18,
    column_id: 4105,
    ref_columnid: null,
    ref_tableid: null,
    key: 'maritalstatus',
    column_alias: 'MaritalStatus',
    label_en: 'MaritalStatus',
    label_th: 'MaritalStatus',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'Like_Contain', value: 6, description: '%-%' },
      { name: 'Like_StartWith', value: 7, description: '-%' },
      { name: 'Like_EndWith', value: 8, description: '%-' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 52,
    data_type_desc: 'nvarchar',
    col_type: 1,
    col_type_desc: 'generic',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 19,
    column_id: 2305,
    ref_columnid: null,
    ref_tableid: null,
    key: 'cdp_updated_dt',
    column_alias: 'CDP_Updated_DT',
    label_en: 'CDP_Updated_DT',
    label_th: 'CDP_Updated_DT',
    sortable: true,
    type_id: 1,
    type_desc: 'Date',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'GreaterThan', value: 2, description: '>' },
      { name: 'LessThan', value: 3, description: '<' },
      { name: 'GreaterOrEqual', value: 4, description: '>=' },
      { name: 'LessOrEqual', value: 5, description: '<=' },
      { name: 'Between', value: 9, description: 'BETWEEN' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: true,
    data_type: 33,
    data_type_desc: 'datetime2',
    col_type: 2002,
    col_type_desc: 'cdp-updated-dt',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 20,
    column_id: 2306,
    ref_columnid: null,
    ref_tableid: null,
    key: 'cdp_created_dt',
    column_alias: 'CDP_Created_DT',
    label_en: 'CDP_Created_DT',
    label_th: 'CDP_Created_DT',
    sortable: true,
    type_id: 1,
    type_desc: 'Date',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'GreaterThan', value: 2, description: '>' },
      { name: 'LessThan', value: 3, description: '<' },
      { name: 'GreaterOrEqual', value: 4, description: '>=' },
      { name: 'LessOrEqual', value: 5, description: '<=' },
      { name: 'Between', value: 9, description: 'BETWEEN' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: false,
    data_type: 33,
    data_type_desc: 'datetime2',
    col_type: 2001,
    col_type_desc: 'cdp-created-dt',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 21,
    column_id: 2307,
    ref_columnid: null,
    ref_tableid: null,
    key: 'cdp_isdelete',
    column_alias: 'CDP_IsDelete',
    label_en: 'CDP_IsDelete',
    label_th: 'CDP_IsDelete',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: false,
    data_type: 11,
    data_type_desc: 'bit',
    col_type: 2009,
    col_type_desc: 'cdp-is-delete',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 22,
    column_id: 2308,
    ref_columnid: null,
    ref_tableid: null,
    key: 'cdp_recordstate',
    column_alias: 'CDP_RecordState',
    label_en: 'CDP_RecordState',
    label_th: 'CDP_RecordState',
    sortable: true,
    type_id: 1,
    type_desc: 'Text',
    column_property: {
      font_color: null,
      badge_color: null,
      bg_color: null,
      font_size: null,
      font_size_unit: null,
    },
    operation_list: [
      { name: 'Equal', value: 1, description: '=' },
      { name: 'GreaterThan', value: 2, description: '>' },
      { name: 'LessThan', value: 3, description: '<' },
      { name: 'GreaterOrEqual', value: 4, description: '>=' },
      { name: 'LessOrEqual', value: 5, description: '<=' },
      { name: 'Between', value: 9, description: 'BETWEEN' },
      { name: 'IsNull', value: 10, description: 'IS NULL' },
      { name: 'In', value: 11, description: 'IN' },
    ],
    col_sqltype: 101,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 0,
    nullable: false,
    data_type: 14,
    data_type_desc: 'int',
    col_type: 2011,
    col_type_desc: 'cdp-record-state',
    is_groupby: false,
    is_display: true,
    is_filter: true,
    align: 1,
    align_desc: 'Left',
  },
  {
    seq: 23,
    key: 'call_to_action',
    label_en: 'Call To Action',
    label_th: 'Call To Action',
    sortable: false,
    type_id: 4,
    type_desc: 'Option',
    column_property: {
      display_type: 2,
      option_list: [
        {
          item_id: 9,
          name_th: 'None',
          name_en: 'None',
          color_font: '#F97794',
          color_badge: null,
          color_bg: '#F977941F',
          font_size: '25px',
          icon: null,
          icon_color: null,
          image: null,
          value: 1,
        },
        {
          item_id: 10,
          name_th: 'Full Image',
          name_en: 'Full Image',
          color_font: '#F97794',
          color_badge: null,
          color_bg: '#F977941F',
          font_size: '25px',
          icon: null,
          icon_color: null,
          image: null,
          value: 2,
        },
        {
          item_id: 11,
          name_th: 'Landing Page',
          name_en: 'Landing Page',
          color_font: '#F97794',
          color_badge: null,
          color_bg: '#F977941F',
          font_size: '25px',
          icon: null,
          icon_color: null,
          image: null,
          value: 3,
        },
        {
          item_id: 12,
          name_th: 'Content Detail',
          name_en: 'Content Detail',
          color_font: '#F97794',
          color_badge: null,
          color_bg: '#F977941F',
          font_size: '25px',
          icon: null,
          icon_color: null,
          image: null,
          value: 4,
        },
        {
          item_id: 13,
          name_th: 'Site URL',
          name_en: 'Site URL',
          color_font: '#F97794',
          color_badge: null,
          color_bg: '#F977941F',
          font_size: '25px',
          icon: null,
          icon_color: null,
          image: null,
          value: 5,
        },
      ],
    },
    is_display: true,
    is_filter: false,
    align: 2,
    align_desc: 'center',
  },
] as unknown as DynamicTable.Column[];
