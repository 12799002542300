import { ref, Ref, toRaw } from 'vue';
import router from '@/router';
import api from '@services/api';
import segmentResultModel from '@models/segment/result';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useLoading from '@/views/components/loading/hooks/useLoading';

type SaveType = 1 | 2 | 3;
type SelectFlag = 1 | 2;

interface SegmentSavePayload {
  Save_Type: SaveType;
  Type: number;
  Name: string;
  SegmentId: number;
  Apply_SegmentId: number;
  Dest_SegmentId: number;
  Root_TableId: number;
  Root_FolderId: number;
  Dest_FolderId: number;
  saveColumns: AllDataSegment.SaveColumns[];
  select_flag: SelectFlag;
  Datas: AllDataSegment.Datas[];
}

interface Props {
  selectedSource: Segment.Datasource;
}

export default function (
  segmentId: number,
  applySegmentId: number,
  applyDestSegmentId: number,
  folderId: number,
  props: Props,
  isSelectedAll: Ref<boolean>,
  includeOrExcludeList: Ref<Record<string, any>[]>,
  dataSourceDisplay: Ref<AllDataSegment.DatasourceDisplay>,
) {
  const { isLoading } = useLoading();
  const { openSuccessModal, openDefaultErrorModal } = useValidationModal();
  const { fetchSegmentSaveModel } = segmentResultModel();
  const type: Ref<number> = ref(0);
  const idFolder: Ref<number> = ref(0);
  const nameSegmentCopyTo: Ref<string> = ref('');
  const nameFolder: Ref<string> = ref('');
  const idSegmentSelectCopyTo: Ref<number> = ref(0);
  const nameSegmentSelectCopyTo: Ref<string> = ref('');
  const idSegment: Ref<number> = ref(segmentId);
  const isLoadingActiveCopyTo: Ref<boolean> = ref(false);
  const isCompleted: Ref<boolean> = ref(false);

  const onSubmitDialogSegment = (val: BaseModal.SaveSegmentModal) => {
    if (val.segmentSelect.segmentId != 0) {
      type.value = 3;
      nameSegmentCopyTo.value = val.segmentName;
      idFolder.value = val.folderSelect.folderId;
      nameFolder.value = val.folderSelect.label;
      idSegmentSelectCopyTo.value = val.segmentSelect.segmentId;
      nameSegmentSelectCopyTo.value = val.segmentSelect.label;
      onLoadDataSave();
    }
  };

  const onLoadDataSave = () => {
    const mapColumn = (dataSourceDisplay: AllDataSegment.DatasourceDisplay): AllDataSegment.SaveColumns[] => {
      const saveColumns: AllDataSegment.SaveColumns[] = [];
      dataSourceDisplay.columns.forEach((element) => {
        const obj: AllDataSegment.SaveColumns = {
          ColumnId: element.column_id,
          Ref_columnId: element.ref_columnid,
          Col_Type: element.col_type,
        };
        saveColumns.push(obj);
      });
      return saveColumns;
    };

    const mapData = (includeOrExcludeList: Record<string, any>[]): AllDataSegment.Datas[] => {
      const datas: AllDataSegment.Datas[] = [];
      includeOrExcludeList.forEach((element) => {
        const dataMap: AllDataSegment.Data_Map[] = [];
        sourceDisplay.columns.forEach((el: AllDataSegment.DatasourceDisplayColumns) => {
          const obj: AllDataSegment.Data_Map = {
            ColumnId: el.column_id,
            Value: element[el.key],
          };
          dataMap.push(obj);
        });
        datas.push({ Data_Map: dataMap });
      });
      return datas;
    };

    const listIncludeOrExclude: Record<string, any>[] = toRaw(includeOrExcludeList.value);
    const sourceDisplay: AllDataSegment.DatasourceDisplay = toRaw(dataSourceDisplay.value);

    const payload: SegmentSavePayload = {
      Save_Type: 3,
      Type: !router.currentRoute.value.query.type ? type.value : Number(router.currentRoute.value.query.type),
      Name: nameSegmentSelectCopyTo.value,
      SegmentId: !router.currentRoute.value.query.segmentOldId ? segmentId || applySegmentId : Number(router.currentRoute.value.query.segmentOldId),
      Apply_SegmentId: applySegmentId,
      Dest_SegmentId: idSegmentSelectCopyTo.value,
      Root_TableId: props.selectedSource.tableid,
      Root_FolderId: idFolder.value,
      Dest_FolderId: idFolder.value,
      saveColumns: type.value == 3 ? mapColumn(sourceDisplay) : [],
      select_flag: isSelectedAll.value ? 2 : 1,
      Datas: type.value == 3 ? mapData(listIncludeOrExclude) : [],
    };
    fetchSegmentResultList(payload);
  };

  const fetchSegmentResultList = (payload: SegmentSavePayload) => {
    isLoading(true, 'กำลังบันทึก กรุณารอสักครู่...');
    isLoadingActiveCopyTo.value = true;
    fetchSegmentSaveModel.payload = payload;

    api
      .apiRequest(fetchSegmentSaveModel)
      .then((response) => {
        if (response.status === 'success') {
          isCompleted.value = true;
          idSegment.value = response.data.segment_id;
        }
      })
      .catch((error) => {
        if (error.data.error.message == 'Name of Segment is duplicate') {
          isLoadingActiveCopyTo.value = false;
          openDefaultErrorModal(error.data.error.locale['th-th'].title, error.data.error.locale['th-th'].message);
        } else {
          openDefaultErrorModal(error.data.error.locale['th-th'].title, error.data.error.locale['th-th'].message);
        }
      })
      .finally(() => {
        isLoadingActiveCopyTo.value = false;
        isLoading(false);
        if (isCompleted.value) {
          openSuccessModal(
            'บันทึกรายการสำเร็จ',
            '',
            '',
            () => onDirectSegment(),
            () => onDirectSegment(),
          );
        }
      });
  };

  const onDirectSegment = () => {
    localStorage.setItem('listNewData', JSON.stringify(toRaw(includeOrExcludeList.value)));

    router.replace({
      path: '/segment/data-segment/segment-detail',
      query: {
        tab: router.currentRoute.value.query.tab,
        tableId: router.currentRoute.value.query.tableId,
        folderId: folderId || idFolder.value,
        folderName: router.currentRoute.value.query.folderName || nameFolder.value,
        segmentId: idSegment.value,
        segmentName: nameSegmentSelectCopyTo.value,
        type: !router.currentRoute.value.query.type ? type.value : Number(router.currentRoute.value.query.type),
      },
    });
  };

  return {
    isLoadingActiveCopyTo,
    nameSegmentCopyTo,
    onSubmitDialogSegment,
  };
}
