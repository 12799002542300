import { ref, Ref, reactive, Directive } from 'vue';

import Sortable from 'sortablejs';

export default function useSocialTabPanel() {
  //choose social
  const uploadCustomIcon: any = reactive({ file: null, url: null });
  const selectedSocial: Ref<Record<string, any>> = ref(null!);
  const socialList: Ref<any> = ref([]);
  const socialIndex: Ref<number> = ref(0);
  const editMode: Ref<boolean> = ref(false);
  const isEdit: Ref<boolean> = ref(false);
  const keyChange: Ref<number> = ref(1);
  const link: Ref<string> = ref('');
  const replaceText: Ref<string> = ref('');
  ///
  const disabledPadding: Ref<boolean> = ref(true);
  const showDisplay: Ref<string> = ref('icon-and-text');
  const textPosition: Ref<string> = ref('bottom');
  const fontFamily: Ref<string> = ref('Arial');
  const fontSize: Ref<number> = ref(14);
  const fontStyle: Ref<any> = ref([]);
  const fontColor: Ref<string> = ref('');
  const isColorPickerOpen: Ref<boolean> = ref(false);
  const iconStyle: Ref<string> = ref('fill:#000;');
  const alignMent: Ref<string> = ref('center');
  const justifyMent: Ref<string> = ref('center');
  const scale: Ref<number> = ref(25);
  const margin: Ref<number> = ref(5);
  const spacingTop: Ref<number> = ref(10);
  const spacingBottom: Ref<number> = ref(10);
  const indentLeft: Ref<number> = ref(10);
  const indentRight: Ref<number> = ref(10);

  const handleDisabledPadding = () => {
    disabledPadding.value = !disabledPadding.value;
  };

  const onSelectShowDisplay = (display: string) => {
    showDisplay.value = display;
  };

  const onSelectTextPosition = (position: string) => {
    textPosition.value = position;
  };

  const onSelectFontFamily = (family: string) => {
    fontFamily.value = family;
  };

  const onSelectFontSize = (size: number) => {
    fontSize.value = size;
  };

  const onSelectFontStyle = (fStyle: string) => {
    if (fontStyle.value.includes(fStyle)) {
      fontStyle.value = fontStyle.value.filter((style: string) => style !== fStyle);
    } else {
      fontStyle.value.push(fStyle);
    }
  };

  const mappingFontStyle = () => {
    let fontCssProp = '';
    fontCssProp = fontStyle.value.toString().replaceAll(',', ';');
    return fontCssProp;
  };

  const onToggleColorPicker = () => {
    isColorPickerOpen.value = !isColorPickerOpen.value;
  };

  const onChangeFontColor = (color: string) => {
    fontColor.value = color;
  };

  const onSelectIconStyle = (style: string) => {
    iconStyle.value = style;
  };

  const onSelectAlignMent = (align: string) => {
    alignMent.value = align;
  };

  const onSelectJustifyMent = (justify: string) => {
    justifyMent.value = justify;
  };

  ///Create Social
  const addSocial = () => {
    if (!selectedSocial.value) {
      return;
    }
    editMode.value = false;
    const socialContent = {
      key: keyChange.value++,
      label: selectedSocial.value?.label,
      selectedSocial: selectedSocial.value,
      iconUrl: uploadCustomIcon.url,
      link: link.value,
      replaceText: replaceText.value,
    };
    socialList.value.push(socialContent);
    selectedSocial.value = null!;
    link.value = '';
    replaceText.value = '';
    uploadCustomIcon.file = null;
    uploadCustomIcon.url = null;
  };

  const updateSocial = () => {
    editMode.value = false;
    isEdit.value = false;
    const socialContent = {
      key: keyChange.value++,
      label: selectedSocial.value?.label,
      selectedSocial: selectedSocial.value,
      iconUrl: uploadCustomIcon.url,
      link: link.value,
      replaceText: replaceText.value,
    };
    socialList.value[socialIndex.value] = socialContent;
    selectedSocial.value = null!;
    link.value = '';
    replaceText.value = '';
  };

  const onApplySocial = () => {
    if (!isEdit.value) {
      addSocial();
    } else {
      updateSocial();
    }
  };

  const toggleEditMode = (index: number, socialItem: any, mode: 'create' | 'edit') => {
    editMode.value = !editMode.value;
    if (mode == 'edit') {
      isEdit.value = true;
      socialIndex.value = index;
      keyChange.value = socialItem.key;
      selectedSocial.value = socialItem.selectedSocial;
      uploadCustomIcon.url = socialItem.iconUrl;
      link.value = socialItem.link;
      replaceText.value = socialItem.replaceText;
    }
  };

  const onDeleteSocial = (removeItem: any) => {
    socialList.value = socialList.value.filter((social: any) => social.key !== removeItem?.key);
  };

  const sortableSocial: Directive = {
    beforeMount(el: HTMLElement) {
      const container = el;

      const option: Sortable.SortableOptions = {
        ghostClass: 'sortable-ghost',
        dragClass: 'sortable-drag',
        handle: '.item-social',
        onEnd: onReorderSocial,
        animation: 300,
        easing: 'cubic-bezier(0.22, 1, 0.36, 1)',
      };

      Sortable.create(container, option);
    },
  };

  const onReorderSocial = (event: Sortable.SortableEvent) => {
    socialList.value.splice(event.newDraggableIndex as number, 0, socialList.value.splice(event.oldDraggableIndex as number, 1)[0]);
    socialList.value.forEach((social: any) => {
      social.key = keyChange.value;
      keyChange.value++;
    });
  };

  const handleUploadCustomIconChange = (e: any) => {
    const filesOBJ: File = e.target?.files[0];
    try {
      if (!isFileImage(filesOBJ)) {
        console.log('invalid format');
        return;
      }
      const newObjUrl = URL.createObjectURL(filesOBJ as Blob);

      uploadCustomIcon.file = filesOBJ;
      uploadCustomIcon.url = newObjUrl;
    } catch (err) {}
  };

  const isFileImage = (file: any) => file && file.type.split('/')[0] === 'image';

  const clearFile = (e: any) => {
    uploadCustomIcon.file = null;
    uploadCustomIcon.url = null;
    e.value = null;
  };

  return {
    sortableSocial,
    disabledPadding,
    showDisplay,
    textPosition,
    fontFamily,
    fontSize,
    fontStyle,
    isColorPickerOpen,
    fontColor,
    iconStyle,
    alignMent,
    justifyMent,
    scale,
    margin,
    spacingTop,
    spacingBottom,
    indentLeft,
    indentRight,
    uploadCustomIcon,
    selectedSocial,
    socialList,
    link,
    replaceText,
    editMode,
    isEdit,
    addSocial,
    updateSocial,
    onApplySocial,
    toggleEditMode,
    onDeleteSocial,
    onToggleColorPicker,
    handleDisabledPadding,
    onSelectShowDisplay,
    onSelectTextPosition,
    onSelectFontFamily,
    onSelectFontSize,
    onSelectFontStyle,
    onChangeFontColor,
    onSelectIconStyle,
    onSelectAlignMent,
    onSelectJustifyMent,
    handleUploadCustomIconChange,
    clearFile,
  };
}
