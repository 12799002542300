<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g id="Group_32737_00000113331658902768072610000005686497191128480432_" transform="translate(6.219 4.138)">
      <g id="Group_32736_00000140010964452899563900000004218433358075883925_" transform="translate(2.007)">
        <path
          id="Path_21307_00000112614694231226153710000000445502818736960152_"
          class="st8"
          d="M224.83,129.22
			c0.08,1.04-0.42,2.04-1.3,2.6c-3.56,2.5-7.05,5.07-10.88,7.79V54.17c0.49-4.8-3.01-9.08-7.81-9.57c-0.61-0.06-1.23-0.06-1.84,0.01
			H61.65c-4.81-0.59-9.19,2.82-9.78,7.63c-0.09,0.71-0.09,1.43,0,2.14c0,13.26,0,26.54,0,39.83c0,14.43,0,28.88,0,43.33v2.09
			c-4.05-2.95-7.79-5.65-11.55-8.44c-0.39-0.44-0.59-1.01-0.56-1.6c0-25.63-0.08-51.26,0-76.89c0.13-11.13,9.15-20.12,20.28-20.21
			c16.87-0.12,33.74,0,50.52,0h93.29c11.47-0.09,20.84,9.13,20.93,20.6c0,0.1,0,0.21,0,0.31"
        />
        <path
          id="Path_21308_00000111160761355073430310000015164091968288288439_"
          class="st8"
          d="M132.66,64.08h51.82
			c1.41,0,2.83,0,4.24,0.09c3.3,0.48,5.58,3.55,5.1,6.85c-0.43,2.92-2.9,5.1-5.85,5.16c-2.09,0.06-4.18,0-6.27,0H78.62
			c-0.67,0-1.3,0-2.02,0c-3.34-0.15-5.92-2.98-5.77-6.32c0.14-3.12,2.64-5.62,5.77-5.77c1.96-0.12,3.89,0,5.89,0h50.16"
        />
        <path
          id="Path_21309_00000067915580390767465660000003335650170841671599_"
          class="st8"
          d="M132.34,92.47h54.75
			c3.34-0.37,6.36,2.03,6.75,5.37c0.42,3.28-1.9,6.29-5.18,6.71c-0.36,0.05-0.73,0.06-1.1,0.04c-7.01,0-14.02,0-21.01,0h-63.77
			c-8.66,0-17.31,0-25.96,0c-3.32,0.09-6.09-2.53-6.19-5.85c-0.02-0.91,0.16-1.81,0.53-2.63c0.88-2.25,3.09-3.7,5.5-3.61
			c5.96,0,11.93,0,17.88,0h37.79"
        />
        <path
          id="Path_21310_00000160887228003855771370000005473683641373057204_"
          class="st8"
          d="M132.21,120.89
			c18.44,0,36.88,0,55.31,0c3.35-0.22,6.25,2.32,6.47,5.67c0.22,3.35-2.32,6.25-5.67,6.47c-0.27,0.02-0.53,0.02-0.8,0
			c-16.54,0-33.08,0-49.62,0H77.45c-2.73,0.23-5.29-1.35-6.32-3.89c-1.3-3.03,0.11-6.54,3.14-7.84c0.74-0.32,1.53-0.48,2.34-0.48
			c13.52,0,27.05,0,40.57,0c5.04,0,10.07,0,15.11,0L132.21,120.89z"
        />
        <path
          id="Path_21311_00000139284669293408205800000016214851525719142828_"
          class="st8"
          d="M107.4,27.03
			c6.92-5.19,13.81-10.38,20.76-15.57c2.19-1.78,5.29-1.9,7.62-0.31c7.31,5.19,14.38,10.62,22.06,16.3h-14.28
			c-0.37-0.03-0.74-0.03-1.12,0c-3.32,0.59-6.71-0.57-8.97-3.06c-0.43-0.64-1.3-0.81-1.95-0.38c-0.15,0.1-0.28,0.23-0.38,0.38
			c-2.41,2.41-5.8,3.56-9.18,3.11c-4.81-0.26-9.66,0-14.48,0v-0.47"
        />
        <path
          id="Path_21312_00000057128236790770829380000013643462494217229464_"
          class="st8"
          d="M253.37,255.69v-135.5l-91.48,66.19
			l91.48,69.28"
        />
      </g>
      <path
        id="Path_21313_00000149362293547845786280000004847648483787625091_"
        class="st8"
        d="M15.65,120.19v135.45l91.52-69.26
		L15.65,120.19"
      />
      <path
        id="Path_21314_00000011734943355136044030000004395839433431266981_"
        class="st8"
        d="M134.27,181.96
		C97.7,209.67,24.99,265.02,25.05,265.15h219.07L134.27,181.96"
      />
    </g>
  </svg>
</template>
