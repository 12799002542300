<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 85.04 85.04"
    style="enable-background: new 0 0 85.04 85.04"
    xml:space="preserve"
  >
    <path
      class="st11"
      d="M48.45,42.49l18.75-23.24c1.52-1.89,1.52-4.7-0.23-6.38c-1.97-1.9-5.1-1.66-6.78,0.42L42.56,35.15
	c-0.02,0.02-0.05,0.02-0.07,0L24.99,13.47c-1.52-1.89-4.27-2.48-6.29-1.12c-2.27,1.53-2.7,4.63-1.02,6.71l18.9,23.43
	c0.01,0.02,0.01,0.04,0,0.06L17.83,65.79c-1.52,1.89-1.52,4.7,0.23,6.38c1.97,1.9,5.1,1.66,6.78-0.42l17.64-21.86
	c0.02-0.02,0.05-0.02,0.07,0l17.49,21.68c1.52,1.89,4.27,2.48,6.29,1.12c2.27-1.53,2.7-4.63,1.02-6.71l-18.9-23.43
	C48.44,42.53,48.44,42.51,48.45,42.49z"
    />
  </svg>
</template>
