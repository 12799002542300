<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10.173" height="12.033" viewBox="0 0 10.173 12.033">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_15871" data-name="Rectangle 15871" class="cls-1" width="10.173" height="12.033" transform="translate(0 0)" />
      </clipPath>
    </defs>
    <g id="Group_36871" data-name="Group 36871" class="cls-2-reset">
      <path
        id="Path_23784"
        data-name="Path 23784"
        class="cls-1"
        d="M10.14.569A.2.2,0,0,0,9.8.433L8.665,1.594A5.134,5.134,0,0,0,.01,3.724a.245.245,0,0,0,.234.313h.984a.242.242,0,0,0,.228-.159,3.708,3.708,0,0,1,6.21-1.265l-1.1,1.122a.2.2,0,0,0,.143.335l3.269-.033a.2.2,0,0,0,.2-.2Z"
        transform="translate(0 1.264)"
      />
      <path
        id="Path_23785"
        data-name="Path 23785"
        class="cls-1"
        d="M.032,79.8a.2.2,0,0,0,.338.136l1.138-1.161a5.134,5.134,0,0,0,8.655-2.13.245.245,0,0,0-.234-.313H8.945a.242.242,0,0,0-.228.159,3.708,3.708,0,0,1-6.21,1.265l1.1-1.122a.2.2,0,0,0-.143-.335L.2,76.333a.2.2,0,0,0-.2.2Z"
        transform="translate(0 -68.338)"
      />
    </g>
  </svg>
</template>

<style scoped>
.cls-2-reset {
  clip-path: url(#clip-path);
}
</style>
