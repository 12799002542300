<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 34">
    <g id="Group_44814" data-name="Group 44814" transform="translate(-10 -1)">
      <g id="Group_29299" data-name="Group 29299">
        <text id="Aa" transform="translate(10 28)" font-size="25" font-family="SegoeUI-Bold, Segoe UI" font-weight="700">
          <tspan x="0" y="0">Aa</tspan>
        </text>
      </g>
    </g>
  </svg>
</template>
