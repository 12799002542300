<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    style="enable-background: new 0 0 100 100"
    xml:space="preserve"
  >
    <g id="Group_33347" transform="translate(9)">
      <g id="Group_33334" transform="translate(14.87 14)">
        <g>
          <g id="Group_114" transform="translate(0 0)">
            <path
              id="Path_52"
              d="M53.98,40.14c-0.14-4.48-0.03-8.96-0.03-13.44c0-5.05,0-10.1,0-15.15
					c0.37-1.91-0.88-3.76-2.8-4.13c-0.04-0.01-0.08-0.01-0.12-0.02C43.76,5,36.18,3.72,28.52,3.59c-4.88-0.16-9.76,0.12-14.59,0.83
					C9.43,4.98,5,6.04,0.73,7.58C-0.86,8-1.91,9.52-1.74,11.16c0,10.06,0.01,20.13,0.02,30.19c0.01,1.06,0.11,2.13,0.3,3.17
					c0.84,4.05,2.85,7.77,5.78,10.69c5.82,5.85,12.87,10.32,20.65,13.09c0.86,0.24,1.78,0.2,2.61-0.12
					c1.66-0.61,3.28-1.32,4.86-2.12c6.07-2.76,11.54-6.7,16.07-11.59C52.18,50.59,54.13,45.44,53.98,40.14 M44.26,50.7
					c-4.03,4.34-8.9,7.82-14.31,10.23c-1.21,0.6-2.5,1.21-3.77,1.69c-6.65-2.44-12.69-6.31-17.68-11.34
					c-2.17-2.12-3.67-4.84-4.31-7.81c-0.13-0.71-0.2-1.43-0.21-2.15C3.96,32.5,3.96,23.66,3.97,14.83v-2.34
					c3.51-1.14,7.11-1.95,10.77-2.41C18.4,9.54,22.1,9.26,25.8,9.25c0.84,0,1.69,0.02,2.51,0.05c6.76,0.11,13.47,1.18,19.93,3.19
					V26.7c0,1.36,0,2.73-0.02,4.09c-0.02,3.11-0.05,6.32,0.05,9.53C48.38,44.19,46.93,47.93,44.26,50.7"
            />
            <path
              id="Path_53"
              d="M33.2,39.54c-2.97,0-5.95,0-8.93,0c-1.31,0-1.32,0-1.32-1.33
					c0-5.92,0-11.84,0-17.76c0-1.17-0.32-1.49-1.47-1.51c-0.97-0.01-1.94-0.01-2.91,0c-1.21,0.01-1.55,0.35-1.55,1.55
					c0,3.91,0,7.83,0,11.74c0,3.85,0,7.69,0,11.54c0,1.45,0.29,1.73,1.77,1.73c1.37,0,2.74,0,4.11,0c3.45,0,6.89,0,10.33,0
					c1.2,0,1.54-0.36,1.55-1.56c0-0.94,0-1.87,0-2.81C34.78,39.79,34.55,39.55,33.2,39.54"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
