<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g id="Group_32744_00000083806881084297398050000007096334392377920444_" transform="translate(4.563 4.887)">
      <path
        id="Path_21376_00000168810863534444690350000010369133363949957279_"
        class="st8"
        d="M165.52,50.95
		c0.99,8.07,2.04,15.84,2.82,23.65c-0.09,1.62-0.52,3.21-1.26,4.66c-3.55,7.68-2.01,16.75,3.88,22.83
		c7.82,8.26,20.86,8.62,29.12,0.79c3.6-3.41,5.85-8,6.34-12.93c0.98-5.19-0.63-10.54-4.32-14.33c-4.14-4.25-6.32-10.02-6.04-15.94
		c-0.16-1.93,0-3.89,0-7.09c6.43,0.7,12.79,2,18.98,3.89c2.35,0.93,3.9,5.88,4.39,9.19c1.56,10.79,2.91,21.65,3.51,32.53
		c0.86,14.96,1.26,29.96,1.26,44.93c-0.32,3.26-1.78,6.29-4.12,8.58c-35.05,35.26-70.19,70.43-105.43,105.52
		c-5.99,7.85-17.2,9.35-25.04,3.37c-1.29-0.99-2.45-2.15-3.43-3.44c-23.06-23.02-46.11-46.04-69.16-69.08
		c-8.03-5.83-9.81-17.05-3.98-25.08c1.11-1.53,2.45-2.87,3.98-3.98c35.06-35.03,70.13-70.05,105.23-105.04
		c1.86-1.9,4.32-3.1,6.96-3.4c11.52-0.3,23.03-0.06,34.55,0c0.63,0.06,1.26,0.19,1.86,0.38"
      />
      <path
        id="Path_21377_00000150808711066806105630000014015763915266757285_"
        class="st8"
        d="M264.73,60.56
		c-1,21.63-6.48,42.81-16.11,62.2c-0.34,0.68-0.72,1.32-1.1,1.98c-2.88,5.04-7.15,6.64-11.23,4.27c-3.71-2.26-4.88-7.11-2.62-10.82
		c0.14-0.23,0.29-0.45,0.46-0.67c9.71-17.94,14.75-38.04,14.64-58.44c0.02-5.29-0.83-10.54-2.52-15.55
		c-4.79-13.84-19.31-21.77-33.55-18.32c-14.27,2.9-24.38,15.66-23.92,30.22c0.52,9.48,2.52,18.89,3.9,28.32
		c0.39,1.93,0.54,3.89,0.47,5.85c-0.23,3.36-2.94,6.01-6.3,6.17c-3.47,0.87-6.99-1.24-7.87-4.71c-4.07-13.51-5.87-27.6-5.31-41.69
		c2.69-21.98,20.81-38.82,42.93-39.9c21.47-0.89,40.55,13.6,45.44,34.53c1.17,5.46,2.04,10.98,2.61,16.53"
      />
    </g>
  </svg>
</template>
