<template>
  <svg
    id="Group_51503"
    data-name="Group 51503"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="21.2"
    height="23.472"
    viewBox="0 0 24.924 28.775"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_439"
          data-name="Rectangle 439"
          width="24.924"
          height="28.776"
          fill="#007fff"
          stroke="#007fff"
          stroke-linecap="square"
          stroke-linejoin="bevel"
          stroke-width="1"
        />
      </clipPath>
    </defs>
    <g id="Group_641" data-name="Group 641" clip-path="url(#clip-path)">
      <path
        id="Path_316"
        data-name="Path 316"
        d="M8.561,3.1c0-.317-.01-.555,0-.792a2.37,2.37,0,0,1,2.368-2.3c.978-.015,1.956-.009,2.934,0A2.369,2.369,0,0,1,16.4,2.53c0,.163.016.326.027.547.213.01.408.026.6.026,1.771,0,3.543,0,5.314,0a2.372,2.372,0,0,1,2.581,2.583,4.666,4.666,0,0,1-.117,1.257A2.091,2.091,0,0,1,23.12,8.52c-.489.089-.6.292-.636.747q-.614,8.307-1.269,16.61a2.836,2.836,0,0,1-3.142,2.9q-5.618,0-11.237,0a2.829,2.829,0,0,1-3.079-2.842q-.649-8.388-1.3-16.775c-.013-.163-.033-.325-.043-.419a8.616,8.616,0,0,1-1.366-.566C-.024,7.488-.176,6.126.158,4.765A2.312,2.312,0,0,1,2.563,3.1q2.657,0,5.314,0h.684M4.1,8.645c0,.232-.011.4,0,.562q.63,8.3,1.268,16.607c.075.968.487,1.326,1.47,1.326q5.589,0,11.179,0c1.149,0,1.5-.337,1.585-1.5q.54-7.062,1.087-14.123c.073-.948.138-1.9.209-2.874ZM12.459,4.74c-3.264,0-6.527-.01-9.791.017a1.187,1.187,0,0,0-.881.384,1.149,1.149,0,0,0,1.039,1.84h19.25a1.852,1.852,0,0,0,.549-.018c.2-.064.51-.188.529-.321a4.118,4.118,0,0,0,.03-1.5c-.1-.438-.6-.4-.991-.4q-4.868,0-9.735,0m2.252-1.657c.175-1.185-.044-1.442-1.178-1.443-.735,0-1.474.042-2.205-.011-.969-.07-1.324.453-1.04,1.454Z"
        transform="translate(0 0)"
        fill="#007fff"
      />
      <path
        id="Path_316_-_Outline"
        data-name="Path 316 - Outline"
        d="M12.264,0c.534,0,1.067,0,1.6.007A2.369,2.369,0,0,1,16.4,2.53c0,.163.016.326.027.547.213.01.408.026.6.026q1.77,0,3.54,0h1.774a2.372,2.372,0,0,1,2.581,2.583,4.667,4.667,0,0,1-.117,1.257A2.091,2.091,0,0,1,23.12,8.52c-.489.089-.6.292-.636.747q-.614,8.307-1.269,16.61a2.836,2.836,0,0,1-3.142,2.9q-5.618,0-11.237,0a2.829,2.829,0,0,1-3.079-2.842q-.649-8.388-1.3-16.775c-.013-.163-.033-.325-.043-.419a8.615,8.615,0,0,1-1.366-.566C-.024,7.488-.176,6.126.158,4.765A2.312,2.312,0,0,1,2.563,3.1q2.592,0,5.185,0h.813c0-.317-.01-.555,0-.792a2.37,2.37,0,0,1,2.368-2.3Q11.6,0,12.264,0Zm-.093,1.655c-.282,0-.563-.006-.844-.026-.969-.07-1.324.453-1.04,1.454h4.424c.175-1.185-.044-1.442-1.178-1.443C13.079,1.639,12.624,1.655,12.171,1.655ZM22.4,6.992a.744.744,0,0,0,.229-.03c.2-.064.51-.188.529-.321a4.119,4.119,0,0,0,.03-1.5c-.1-.438-.6-.4-.991-.4q-6.964.007-13.928,0-2.8,0-5.6.019a1.187,1.187,0,0,0-.881.384,1.149,1.149,0,0,0,1.039,1.84h19.25C22.182,6.981,22.291,6.992,22.4,6.992ZM12.431,27.141H18.02c1.149,0,1.5-.337,1.585-1.5q.54-7.062,1.087-14.123c.073-.948.138-1.9.209-2.874H4.1c0,.232-.011.4,0,.562q.63,8.3,1.268,16.607c.075.968.487,1.326,1.47,1.326Z"
        transform="translate(0 0)"
        fill="#007fff"
      />
      <path
        id="Path_319"
        data-name="Path 319"
        d="M143.147,111.9q0,2.544,0,5.088a2.58,2.58,0,0,1-.026.551.8.8,0,0,1-1.556.035,2.1,2.1,0,0,1-.041-.549q0-5.171,0-10.343c0-.074,0-.148,0-.221a.821.821,0,0,1,.824-.856.805.805,0,0,1,.8.875c.015.626,0,1.254,0,1.88q0,1.77,0,3.54"
        transform="translate(-125.53 -93.91)"
        fill="#007fff"
      />
      <path
        id="Path_319_-_Outline"
        data-name="Path 319 - Outline"
        d="M142.621,119.007a1.284,1.284,0,0,1-1.287-1,2.255,2.255,0,0,1-.061-.629v-.064q0-4.619,0-9.238v-1.151c0-.065,0-.138.005-.219a1.36,1.36,0,0,1,1.358-1.356h.012a1.342,1.342,0,0,1,1.335,1.4c.011.448.009.9.006,1.336,0,.186,0,.372,0,.558q0,1.77,0,3.54v1.653q0,1.718,0,3.436c0,.036,0,.071,0,.107a2.351,2.351,0,0,1-.04.561A1.283,1.283,0,0,1,142.7,119Zm.015-12.566c-.071,0-.25,0-.279.357,0,.033,0,.077,0,.124v1.159q0,4.619,0,9.237v.07a1.465,1.465,0,0,0,.021.335c.047.17.112.2.24.2h.029c.131-.006.2-.038.233-.206a1.521,1.521,0,0,0,.014-.309c0-.042,0-.083,0-.124q0-1.717,0-3.435v-1.653q0-1.77,0-3.54,0-.282,0-.564c0-.428,0-.872-.006-1.3C142.885,106.443,142.7,106.441,142.636,106.441Z"
        transform="translate(-125.823 -94.203)"
        fill="#007fff"
      />
      <path
        id="Path_534"
        data-name="Path 534"
        d="M143.147,111.9q0,2.544,0,5.088a2.58,2.58,0,0,1-.026.551.8.8,0,0,1-1.556.035,2.1,2.1,0,0,1-.041-.549q0-5.171,0-10.343c0-.074,0-.148,0-.221a.821.821,0,0,1,.824-.856.805.805,0,0,1,.8.875c.015.626,0,1.254,0,1.88q0,1.77,0,3.54"
        transform="translate(-129.874 -93.91)"
        fill="#007fff"
      />
      <path
        id="Path_534_-_Outline"
        data-name="Path 534 - Outline"
        d="M142.621,119.007a1.284,1.284,0,0,1-1.287-1,2.255,2.255,0,0,1-.061-.629v-.064q0-4.619,0-9.238v-1.151c0-.065,0-.138.005-.219a1.36,1.36,0,0,1,1.358-1.356h.012a1.342,1.342,0,0,1,1.335,1.4c.011.448.009.9.006,1.336,0,.186,0,.372,0,.558q0,1.77,0,3.54v1.653q0,1.718,0,3.436c0,.036,0,.071,0,.107a2.351,2.351,0,0,1-.04.561A1.283,1.283,0,0,1,142.7,119Zm.015-12.566c-.071,0-.25,0-.279.357,0,.033,0,.077,0,.124v1.159q0,4.619,0,9.237v.07a1.465,1.465,0,0,0,.021.335c.047.17.112.2.24.2h.029c.131-.006.2-.038.233-.206a1.521,1.521,0,0,0,.014-.309c0-.042,0-.083,0-.124q0-1.717,0-3.435v-1.653q0-1.77,0-3.54,0-.282,0-.564c0-.428,0-.872-.006-1.3C142.885,106.443,142.7,106.441,142.636,106.441Z"
        transform="translate(-130.167 -94.203)"
        fill="#007fff"
      />
      <path
        id="Path_535"
        data-name="Path 535"
        d="M143.147,111.9q0,2.544,0,5.088a2.58,2.58,0,0,1-.026.551.8.8,0,0,1-1.556.035,2.1,2.1,0,0,1-.041-.549q0-5.171,0-10.343c0-.074,0-.148,0-.221a.821.821,0,0,1,.824-.856.805.805,0,0,1,.8.875c.015.626,0,1.254,0,1.88q0,1.77,0,3.54"
        transform="translate(-134.217 -93.91)"
        fill="#007fff"
      />
      <path
        id="Path_535_-_Outline"
        data-name="Path 535 - Outline"
        d="M142.621,119.007a1.284,1.284,0,0,1-1.287-1,2.255,2.255,0,0,1-.061-.629v-.064q0-4.619,0-9.238v-1.151c0-.065,0-.138.005-.219a1.36,1.36,0,0,1,1.358-1.356h.012a1.342,1.342,0,0,1,1.335,1.4c.011.448.009.9.006,1.336,0,.186,0,.372,0,.558q0,1.77,0,3.54v1.653q0,1.718,0,3.436c0,.036,0,.071,0,.107a2.351,2.351,0,0,1-.04.561A1.283,1.283,0,0,1,142.7,119Zm.015-12.566c-.071,0-.25,0-.279.357,0,.033,0,.077,0,.124v1.159q0,4.619,0,9.237v.07a1.465,1.465,0,0,0,.021.335c.047.17.112.2.24.2h.029c.131-.006.2-.038.233-.206a1.521,1.521,0,0,0,.014-.309c0-.042,0-.083,0-.124q0-1.717,0-3.435v-1.653q0-1.77,0-3.54,0-.282,0-.564c0-.428,0-.872-.006-1.3C142.885,106.443,142.7,106.441,142.636,106.441Z"
        transform="translate(-134.51 -94.203)"
        fill="#007fff"
      />
    </g>
  </svg>
</template>
