import { getCurrentInstance, onMounted, ref, Ref, nextTick } from 'vue';

interface Props {
  quillInstanceWrapper?: {
    saveQuillTextToEDM: () => void;
    quillInstance: any;
  };
  selectedElement?: any;
}

export default function useTextTabPanel(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const enabledPadding: Ref<boolean> = ref(false);
  const paddingTop: Ref<number> = ref(0);
  const paddingBottom: Ref<number> = ref(0);
  const paddingLeft: Ref<number> = ref(0);
  const paddingRight: Ref<number> = ref(0);

  const handleDisablePadding = (e: any) => {
    enabledPadding.value = e.target.checked;
    if (enabledPadding.value === false) {
      paddingTop.value = 0;
      paddingBottom.value = 0;
      paddingLeft.value = 0;
      paddingRight.value = 0;
    }
    buildLayoutObjProperties();
  };

  const buildLayoutObjProperties = () => {
    const tmp: any = props.quillInstanceWrapper;

    if (tmp.quillInstance !== null) {
      tmp.saveQuillTextToEDM();
      tmp.quillInstance = null;
    }

    EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.EnablePadding = enabledPadding.value;
    EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Padding.Top = paddingTop.value;
    EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Padding.Bottom = paddingBottom.value;
    EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Padding.Left = paddingLeft.value;
    EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Padding.Right = paddingRight.value;
    EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Style = {
      'padding-top': `${paddingTop.value}px`,
      'padding-bottom': `${paddingBottom.value}px`,
      'padding-left': `${paddingLeft.value}px`,
      'padding-right': `${paddingRight.value}px`,
    };
    EDMRender('cdp-edm-editor');
    vm?.$emit('on-get-edm-config-json', EDMRenderSave('cdp-edm-editor'));
    vm?.$emit('on-get-edm-content', EDMRenderResult('cdp-edm-editor'));
    nextTick(() => {
      vm?.$emit('set-is-selected');
      vm?.$emit('handle-selected-element');
    });
  };

  const initStateValuesFromEDM = () => {
    enabledPadding.value = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.EnablePadding;
    paddingTop.value = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Padding.Top;
    paddingBottom.value = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Padding.Bottom;
    paddingLeft.value = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Padding.Left;
    paddingRight.value = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Padding.Right;
  };

  onMounted(() => {
    initStateValuesFromEDM();
  });

  return {
    enabledPadding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    handleDisablePadding,
    buildLayoutObjProperties,
  };
}
