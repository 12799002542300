export default function () {
  const fetchCustomerInsightTabsModel: API.RequestModel<INS.INSTab.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSPage/List`,
    method: 'GET',
    payload: {
      Page: null!,
      Limit: null!,
      CustomerView: false,
    },
  };

  const fetchCustomerInsightTabsReorderModel: API.RequestModel<INS.INSTab.Reorder.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSPage/Reorder`,
    method: 'POST',
    payload: {
      PageId: null!,
      From_Seq: null!,
      To_Seq: null!,
    },
  };

  const fetchCustomerInsightTabsCreateModel: API.RequestModel<INS.INSTab.Create.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSPage/Create`,
    method: 'POST',
    payload: {
      Tab_Name: '',
      Tab_label: '',
      Tab_Icon: '',
    },
  };

  const fetchCustomerInsightTabsEditModel: API.RequestModel<INS.INSTab.Edit.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSPage/Edit`,
    method: 'POST',
    payload: {
      PageId: null!,
      Tab_Name: '',
      Tab_label: '',
      Tab_Icon: '',
    },
  };

  const fetchCustomerInsightTabsDeleteModel: API.RequestModel<INS.INSTab.Delete.Request.Payload> = {
    endpoint: `${process.env.BASE_URL_INS!}/INSPage/Delete`,
    method: 'POST',
    payload: {
      PageId: null!,
    },
  };

  return {
    fetchCustomerInsightTabsModel,
    fetchCustomerInsightTabsReorderModel,
    fetchCustomerInsightTabsCreateModel,
    fetchCustomerInsightTabsEditModel,
    fetchCustomerInsightTabsDeleteModel,
  };
}
