<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="11.925" height="13.203" viewBox="0 0 11.925 13.203">
    <g id="Group_642" data-name="Group 642" transform="translate(0 0)">
      <g id="Group_640" data-name="Group 640" transform="matrix(0.755, 0.656, -0.656, 0.755, 8.33, 0)">
        <path
          id="Union_33"
          data-name="Union 33"
          d="M0,8.352H3.211L1.606,10.28Zm0,0V0H3.211V8.352Z"
          transform="translate(0 2.417)"
        />
        <g id="Group_444" data-name="Group 444" transform="translate(0 0)">
          <rect
            id="Rectangle_280"
            data-name="Rectangle 280"
            width="3.211"
            height="1.611"
            rx="0.806"
            transform="translate(0 0)"
          />
          <rect
            id="Rectangle_281"
            data-name="Rectangle 281"
            width="3.211"
            height="0.806"
            transform="translate(0 0.806)"
          />
        </g>
      </g>
      <rect
        id="Rectangle_440"
        data-name="Rectangle 440"
        width="11.924"
        height="1.325"
        rx="0.662"
        transform="translate(0 11.878)"
      />
    </g>
  </svg>
</template>
