<template>
  <svg id="Group_37104" data-name="Group 37104" xmlns="http://www.w3.org/2000/svg" width="54.577" height="83.92" viewBox="0 0 54.577 83.92">
    <path
      id="Path_24099"
      data-name="Path 24099"
      d="M135.493,39.5a4.631,4.631,0,0,0-6.352-.851l-6.308,4.468a37.374,37.374,0,0,1-10.224,5.1,2.413,2.413,0,0,0-1.146.749,8.945,8.945,0,0,1-12.32,1.509A2.434,2.434,0,0,0,97.678,50H93.219v.006a4.5,4.5,0,0,0-4.194,3.019l-6.931,19.9a4.63,4.63,0,0,0,2.58,5.867,4.505,4.505,0,0,0,5.866-2.725l2.68-7.694v37.661a4.629,4.629,0,0,0,4.365,4.691,4.5,4.5,0,0,0,4.644-4.5V91.183a3,3,0,0,1,2.63-3.03,2.919,2.919,0,0,1,3.2,2.9v14.98a4.629,4.629,0,0,0,4.366,4.691,4.5,4.5,0,0,0,4.643-4.5V58.236c.026-.017.053-.031.078-.049L134.5,45.894a4.5,4.5,0,0,0,.991-6.392"
      transform="translate(-81.826 -26.812)"
    />
    <path
      id="Path_24100"
      data-name="Path 24100"
      d="M117.057,37.154c-3.277,1.886-9.254,2.775-13.692,3.187-.683.064-1.37.1-2.044.1h0c-.223,0-.443-.016-.666-.022a8.968,8.968,0,1,0,17.418-4.09,4.255,4.255,0,0,1-1.016.829"
      transform="translate(-87.015 -26.416)"
    />
    <path
      id="Path_24101"
      data-name="Path 24101"
      d="M113.167,32.129a2.8,2.8,0,0,0,1.239-3.4l-.007-.018a9.279,9.279,0,0,0-17.338-.2c-4.727.687-7.9,1.96-7.9,3.417,0,.971,1.412,1.86,3.753,2.549a19.082,19.082,0,0,0,7.152.646c3.713-.346,9.93-1.174,13.1-3"
      transform="translate(-83.847 -22.644)"
    />
  </svg>
</template>
