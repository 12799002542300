import { ref, Ref, reactive, onBeforeMount, watch, getCurrentInstance, nextTick, toRaw, onMounted } from 'vue';
import useLoading from '@/views/components/loading/hooks/useLoading';
interface Props {
  lists: CreateSegmentModal.GroupBucket[];
  dataList: any;
  groupByList: CreateSegmentModal.GroupBy[];
  operatorRuleSet?: object;
  operatorRuleSetList: Array<object>;
  operatorRuleList: Array<object>;
  columns: CreateSegmentModal.RuleColumn[];
  aggregateColumns: CreateSegmentModal.RuleColumn[];
  groupByColumns: CreateSegmentModal.RuleColumn[];
  advanceMode?: boolean;
  miniModal?: boolean;
  modalType?: number;
  modalRuleSetName?: string;
  modalRuleSetIndex?: number;
  typeFlags: any;
  btnDeleteMap?: boolean;
}

export default function useAdvanceSegmentMapping(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const { isLoading } = useLoading();
  const condition: any = reactive({
    ruleSetList: props.lists,
  });
  const isVisibleCheckbox: Ref<boolean> = ref(false);

  const selectOparetor = (Oparetor: any) => {
    return vm?.$emit('operator-property', JSON.parse(JSON.stringify(Oparetor)));
  };

  onMounted(() => {
    condition.ruleSetList;
  });

  watch(
    () => props.lists,
    () => {
      isLoading(true, 'กำลังบันทึก กรุณารอสักครู่...'); // open
      condition.ruleSetList = [];
      setTimeout(() => {
        condition.ruleSetList = props.lists;
      }, 100);

      isLoading(false); // close
    },
  );

  return {
    condition,
    isVisibleCheckbox,
    selectOparetor,
  };
}
