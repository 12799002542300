<template>
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.0184 3.89414V2.66314C16.0186 2.44384 15.9755 2.22667 15.8916 2.02406C15.8077 1.82144 15.6847 1.63735 15.5295 1.48233C15.3744 1.32731 15.1903 1.2044 14.9876 1.12063C14.7849 1.03686 14.5677 0.993879 14.3484 0.994142H3.75344C3.31079 0.994142 2.88627 1.16998 2.57328 1.48298C2.26028 1.79598 2.08444 2.2205 2.08444 2.66314V3.86914C2.08456 4.24094 1.99901 4.60776 1.83444 4.94114L0.762438 7.11114C0.688341 7.26143 0.64936 7.42659 0.648438 7.59414V17.6631C0.648438 18.114 0.827544 18.5464 1.14636 18.8652C1.46517 19.184 1.89757 19.3631 2.34844 19.3631H15.7114C16.1623 19.3631 16.5947 19.184 16.9135 18.8652C17.2323 18.5464 17.4114 18.114 17.4114 17.6631V7.59414C17.4118 7.42785 17.3742 7.26368 17.3014 7.11414L16.2594 4.94514C16.1012 4.61736 16.0188 4.25813 16.0184 3.89414ZM15.9704 17.6661C15.9704 17.7346 15.9433 17.8002 15.8949 17.8486C15.8465 17.897 15.7809 17.9241 15.7124 17.9241H2.34844C2.28009 17.9239 2.21462 17.8966 2.16629 17.8483C2.11797 17.8 2.0907 17.7345 2.09044 17.6661V7.90914C2.08996 7.75807 2.12418 7.60891 2.19044 7.47314L3.30044 5.22614C3.44795 4.92727 3.5246 4.59843 3.52444 4.26514V2.96514C3.52444 2.82537 3.57996 2.69133 3.67879 2.5925C3.77762 2.49366 3.91167 2.43814 4.05144 2.43814H14.0484C14.1882 2.43814 14.3223 2.49366 14.4211 2.5925C14.5199 2.69133 14.5754 2.82537 14.5754 2.96514V4.27914C14.5756 4.60647 14.6498 4.92953 14.7924 5.22414L15.8744 7.46014C15.9396 7.59379 15.9738 7.74044 15.9744 7.88914L15.9704 17.6661Z"
      fill="currentColor"
    />
    <path
      d="M12.1751 8.83179H1.28909C1.21256 8.835 1.13618 8.8227 1.06452 8.79563C0.992868 8.76856 0.92743 8.72728 0.872144 8.67426C0.816857 8.62125 0.772864 8.5576 0.742811 8.48715C0.712758 8.41669 0.697266 8.34089 0.697266 8.26429C0.697266 8.18769 0.712758 8.11189 0.742811 8.04143C0.772864 7.97098 0.816857 7.90733 0.872144 7.85431C0.92743 7.8013 0.992868 7.76002 1.06452 7.73295C1.13618 7.70587 1.21256 7.69358 1.28909 7.69679H12.1751C12.3215 7.70294 12.4598 7.76541 12.5612 7.87117C12.6626 7.97692 12.7193 8.11777 12.7193 8.26429C12.7193 8.41081 12.6626 8.55166 12.5612 8.65741C12.4598 8.76317 12.3215 8.82564 12.1751 8.83179Z"
      fill="currentColor"
    />
    <path
      d="M14.7556 8.83179H13.9356C13.859 8.835 13.7827 8.8227 13.711 8.79563C13.6394 8.76856 13.5739 8.72728 13.5186 8.67426C13.4633 8.62125 13.4193 8.5576 13.3893 8.48715C13.3592 8.41669 13.3438 8.34089 13.3438 8.26429C13.3438 8.18769 13.3592 8.11189 13.3893 8.04143C13.4193 7.97098 13.4633 7.90733 13.5186 7.85431C13.5739 7.8013 13.6394 7.76002 13.711 7.73295C13.7827 7.70587 13.859 7.69358 13.9356 7.69679H14.7556C14.8321 7.69358 14.9085 7.70587 14.9802 7.73295C15.0518 7.76002 15.1172 7.8013 15.1725 7.85431C15.2278 7.90733 15.2718 7.97098 15.3019 8.04143C15.3319 8.11189 15.3474 8.18769 15.3474 8.26429C15.3474 8.34089 15.3319 8.41669 15.3019 8.48715C15.2718 8.5576 15.2278 8.62125 15.1725 8.67426C15.1172 8.72728 15.0518 8.76856 14.9802 8.79563C14.9085 8.8227 14.8321 8.835 14.7556 8.83179Z"
      fill="currentColor"
    />
    <path
      d="M11.0801 4.61778C11.0801 4.76842 11.1399 4.9129 11.2464 5.01942C11.353 5.12594 11.4974 5.18578 11.6481 5.18578H14.6041C14.7505 5.17963 14.8888 5.11716 14.9902 5.0114C15.0916 4.90565 15.1483 4.7648 15.1483 4.61828C15.1483 4.47176 15.0916 4.33091 14.9902 4.22516C14.8888 4.1194 14.7505 4.05693 14.6041 4.05078H11.6481C11.4974 4.05078 11.353 4.11062 11.2464 4.21714C11.1399 4.32367 11.0801 4.46714 11.0801 4.61778Z"
      fill="currentColor"
    />
    <path
      d="M6.41472 4.0503H2.97972C2.83333 4.05645 2.69498 4.11893 2.59357 4.22468C2.49217 4.33044 2.43555 4.47129 2.43555 4.6178C2.43555 4.76432 2.49217 4.90517 2.59357 5.01093C2.69498 5.11668 2.83333 5.17916 2.97972 5.1853H6.41472C6.49125 5.18852 6.56764 5.17622 6.63929 5.14915C6.71094 5.12208 6.77638 5.08079 6.83167 5.02778C6.88696 4.97477 6.93095 4.91112 6.961 4.84066C6.99105 4.77021 7.00655 4.6944 7.00655 4.6178C7.00655 4.54121 6.99105 4.4654 6.961 4.39495C6.93095 4.32449 6.88696 4.26084 6.83167 4.20783C6.77638 4.15482 6.71094 4.11353 6.63929 4.08646C6.56764 4.05939 6.49125 4.04709 6.41472 4.0503Z"
      fill="currentColor"
    />
    <path
      d="M7.3125 1.70177V6.08377C7.31246 6.12954 7.32585 6.17431 7.351 6.21255C7.37615 6.25079 7.41196 6.28081 7.45399 6.2989C7.49603 6.317 7.54245 6.32236 7.58751 6.31434C7.63257 6.30632 7.67429 6.28526 7.7075 6.25377L8.8715 5.15377C8.91497 5.11256 8.97259 5.08958 9.0325 5.08958C9.0924 5.08958 9.15003 5.11256 9.1935 5.15377L10.3615 6.25377C10.3947 6.28526 10.4364 6.30632 10.4815 6.31434C10.5265 6.32236 10.573 6.317 10.615 6.2989C10.657 6.28081 10.6929 6.25079 10.718 6.21255C10.7432 6.17431 10.7565 6.12954 10.7565 6.08377V1.70177C10.7565 1.63971 10.7318 1.58019 10.688 1.53631C10.6441 1.49243 10.5846 1.46777 10.5225 1.46777H7.5465C7.48444 1.46777 7.42492 1.49243 7.38104 1.53631C7.33715 1.58019 7.3125 1.63971 7.3125 1.70177Z"
      fill="currentColor"
    />
    <path
      d="M12.401 15.4102H6.01502C5.82307 15.4094 5.63923 15.3327 5.50359 15.1969C5.36796 15.0611 5.29154 14.8771 5.29102 14.6852V12.2162C5.29181 12.0244 5.36834 11.8407 5.50394 11.7051C5.63955 11.5695 5.82324 11.493 6.01502 11.4922H12.4C12.592 11.4927 12.7759 11.5691 12.9117 11.7048C13.0476 11.8404 13.1242 12.0242 13.125 12.2162V14.6852C13.1245 14.8773 13.0479 15.0614 12.9121 15.1973C12.7762 15.3331 12.5931 15.4097 12.401 15.4102Z"
      fill="currentColor"
    />
  </svg>
</template>
