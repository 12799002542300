<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 283.46 283.46"
    style="enable-background: new 0 0 283.46 283.46"
    xml:space="preserve"
  >
    <g>
      <path
        class="st8"
        d="M215.67,59.11h-28.16V28.92c0-8.14-6.59-14.74-14.73-14.74c-8.14,0-14.74,6.59-14.74,14.73c0,0,0,0.01,0,0.01
		v30.19h-36.1V28.92c0-8.14-6.6-14.75-14.75-14.75c-8.14,0-14.75,6.6-14.75,14.75v30.19H67.82C50.61,59.1,36.65,73.04,36.64,90.24
		c0,0.01,0,0.01,0,0.02v147.87c0,17.21,13.95,31.15,31.15,31.15h147.87c17.21,0,31.15-13.95,31.15-31.15V90.26
		C246.82,73.06,232.87,59.11,215.67,59.11z M222.67,238.14c0,3.87-3.13,7-7,7H67.8c-3.87,0-7-3.13-7-7V126.49h161.87V238.14z"
      />
      <rect x="83.29" y="149.56" class="st8" width="27.28" height="27.28" />
      <rect x="128.09" y="149.97" class="st8" width="27.28" height="27.28" />
      <rect x="172.9" y="149.97" class="st8" width="27.28" height="27.28" />
      <rect x="83.29" y="191.67" class="st8" width="27.28" height="27.28" />
      <rect x="128.09" y="192.08" class="st8" width="27.28" height="27.28" />
      <rect x="172.9" y="192.08" class="st8" width="27.28" height="27.28" />
    </g>
  </svg>
</template>
