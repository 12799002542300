import { ref, Ref, reactive, getCurrentInstance, onMounted, watch, nextTick } from 'vue';
import { PATH } from '@/constants/modules/file-service/path';
import apiService from '@/services/api';
import fileServiceModel from '@/models/file-service/file-service';
import useLoading from '@/views/components/loading/hooks/useLoading';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

interface Props {
  selectedElement?: Communication.EdmEditor.EdmElement;
  templateMode?: string;
}

export default function useLayoutTabPanel(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const { uploadFileModel } = fileServiceModel();
  const { openErrorModal, openDefaultErrorModal } = useValidationModal();
  const { isLoading } = useLoading();

  const selectedLayout: Ref<string> = ref('600px');
  const disabledBackground: Ref<boolean> = ref(true);
  const bodyColor: Ref<string> = ref('#FFFFFF');
  const spacingTop: Ref<number> = ref(0);
  const spacingBottom: Ref<number> = ref(0);
  const uploadBackground: any = reactive({ file: null, url: null, sref: null });
  const isColorPickerOpen: Ref<boolean> = ref(false);

  const buildLayoutObjProperties = () => {
    const currentNodeSelected = EDMGetObjList('cdp-edm-editor')[props.selectedElement?.id as string];
    currentNodeSelected.Properties.EnableBackground = disabledBackground.value;
    currentNodeSelected.Properties.BackgroundImage = uploadBackground.url;
    currentNodeSelected.Properties.Padding.Top = spacingTop.value;
    currentNodeSelected.Properties.Padding.Bottom = spacingBottom.value;
    currentNodeSelected.Properties.Style = {
      width: selectedLayout.value,
      'background-color': bodyColor.value,
      'background-image': uploadBackground.url ? `url(${uploadBackground.url as string})` : null,
    };
    const colList = EDMGetRowMapping('cdp-edm-editor', props.selectedElement?.id as string).Col;
    colList.forEach((col: any) => {
      EDMGetObjList('cdp-edm-editor')[col.Id].Properties.Style = {
        'padding-top': `${spacingTop.value}px`,
        'padding-bottom': `${spacingBottom.value}px`,
      };
      //Set color to blank content
      if (EDMGetContentFromCol('cdp-edm-editor', col.Id as string).Properties.Content.Type == 'LayoutBlank') {
        const contentElem = new DOMParser().parseFromString(
          EDMGetContentFromCol('cdp-edm-editor', col.Id as string).Properties.HTMLUI as string,
          'text/html',
        );
        const contentHTML = contentElem.getElementsByClassName('edm-content')[0];
        contentHTML.setAttribute('style', `background-color:${bodyColor.value};'background-image:${uploadBackground.url as string};'`);
      }
      //----------------------//
    });
    EDMRender('cdp-edm-editor');
    vm?.$emit('on-get-edm-config-json', EDMRenderSave('cdp-edm-editor'));
    vm?.$emit('on-get-edm-content', EDMRenderResult('cdp-edm-editor'));
    nextTick(() => {
      vm?.$emit('set-is-selected');
      vm?.$emit('handle-selected-element');
    });
  };

  const handleChangeLayoutType = (value: string) => {
    selectedLayout.value = value;
  };

  const handleDisableBackground = () => {
    disabledBackground.value = !disabledBackground.value;
    if (disabledBackground.value == false) {
      bodyColor.value = '#FFFFFF';
      uploadBackground.url = '';
    }
  };

  const onToggleColorPicker = () => {
    isColorPickerOpen.value = !isColorPickerOpen.value;
  };

  const onChangeBodyColor = (color: string) => {
    bodyColor.value = color;
  };

  const handleUploadBackgroundChange = (e: any) => {
    const filesOBJ: File = e.target?.files[0];
    if (filesOBJ) {
      if (isFileImage(filesOBJ)) {
        if (isMaxFileSize_10_MB(filesOBJ)) {
          isLoading(true, 'กำลังอัพโหลดรูปภาพ');
          vm?.$emit('uploading', true);
          uploadFileModel.payload.Path = props.templateMode ? PATH.SYSTEM.COMMU.EDM : PATH.COMMU.EDM;
          uploadFileModel.payload.File = filesOBJ;
          uploadFileModel.payload.Is_Temp = true;
          apiService
            .apiFormRequest(uploadFileModel)
            .then((response) => {
              const res = response.data;

              uploadBackground.url = res.public_url as Blob;
              uploadBackground.sref = res.sref;
              vm?.$emit('on-add-sref-list', uploadBackground.sref);
              buildLayoutObjProperties();
            })
            .catch((err) => {
              openDefaultErrorModal(err);
            })
            .finally(() => {
              isLoading(false);
              vm?.$emit('uploading', false);
            });
        } else {
          openErrorModal('เกิดข้อผิดพลาด', 'ขนาดไฟล์ใหญ่เกินไป', '');
          clearFile(e);
        }
      } else {
        openErrorModal('เกิดข้อผิดพลาด', 'ไฟล์ผิดประเภท', '');
        clearFile(e);
      }
    }
  };

  const isFileImage = (file: File) => file && file.type.split('/')[0] === 'image';

  const isMaxFileSize_10_MB = (file: File) => file && file.size <= 10485760;

  const clearFile = (e?: any) => {
    if (uploadBackground.sref) {
      vm?.$emit('on-delete-file', uploadBackground.sref);
    }
    uploadBackground.file = null;
    uploadBackground.url = null;
    e.value = null;
  };

  onMounted(() => {
    const currentNodeSelected = EDMGetObjList('cdp-edm-editor')[props.selectedElement?.id as string];
    if (currentNodeSelected.Type == 'Row') {
      disabledBackground.value = currentNodeSelected.Properties.EnableBackground;
      selectedLayout.value = currentNodeSelected.Properties.Style.width;
      bodyColor.value = currentNodeSelected.Properties.Style['background-color'];
      uploadBackground.url = currentNodeSelected.Properties.BackgroundImage;
      spacingTop.value = currentNodeSelected.Properties.Padding.Top;
      spacingBottom.value = currentNodeSelected.Properties.Padding.Bottom;
    }
  });

  watch([spacingTop, spacingBottom, uploadBackground, disabledBackground, selectedLayout, bodyColor], () => {
    buildLayoutObjProperties();
  });

  return {
    uploadBackground,
    selectedLayout,
    disabledBackground,
    bodyColor,
    spacingTop,
    spacingBottom,
    isColorPickerOpen,
    onChangeBodyColor,
    onToggleColorPicker,
    handleDisableBackground,
    handleUploadBackgroundChange,
    handleChangeLayoutType,
    clearFile,
  };
}
