<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40.614" height="40.608" viewBox="0 0 40.614 40.608">
    <defs>
      <clipPath id="clip-path654">
        <rect id="Rectangle_14701" data-name="Rectangle 14701" width="40.614" height="40.608" transform="translate(0 0)" />
      </clipPath>
    </defs>
    <g id="Group_37774" data-name="Group 37774" transform="translate(0 0)">
      <g id="Group_33425" data-name="Group 33425" clip-path="url(#clip-path654)">
        <path
          id="Path_22466"
          data-name="Path 22466"
          d="M20.306,40.608a20.3,20.3,0,1,1,14.361-5.947,20.3,20.3,0,0,1-14.361,5.947m0-36.547A16.214,16.214,0,1,0,31.8,8.818,16.244,16.244,0,0,0,20.306,4.061Z"
        />
        <path
          id="Path_22467"
          data-name="Path 22467"
          d="M25.738,8.732s-.056,3.277-6.413,4.475c-4.031.759-5.749,1.8-6.468,2.459l-.3-.68a1.294,1.294,0,0,0-2.365,1.05l6.681,15.042a1.294,1.294,0,0,0,2.365-1.05l-1.6-3.609c.72-.66,2.437-1.7,6.469-2.46,6.356-1.2,6.412-4.474,6.412-4.474Z"
        />
      </g>
    </g>
  </svg>
</template>
