import { ref, reactive, Ref, toRaw } from 'vue';
import router from '@/router';
import api from '@services/api';
import segmentResultModel from '@models/segment/result';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useLoading from '@/views/components/loading/hooks/useLoading';

type SaveType = 1 | 2 | 3;
type SelectFlag = 1 | 2;

interface SegmentSavePayload {
  Save_Type: SaveType;
  Type: number;
  Name: string;
  SegmentId: number;
  Apply_SegmentId: number;
  Dest_SegmentId: number;
  Root_TableId: number;
  Root_FolderId: number;
  Dest_FolderId: number;
  saveColumns: PreviewSegment.SaveColumns[];
  select_flag: SelectFlag;
  Datas: PreviewSegment.Datas[];
  Dest_Root_TableId?: number;
  ResultType?: number;
  View_Select_Main?: number;
  displayTables?: any;
}

interface Props {
  selectedSource: Segment.Datasource;
}

interface SegmentDialogItem {
  folderSelect: {
    folderId: number;
    label: string;
  };
  segmentSelect: {
    segmentId: number;
    label: string;
  };
  TableId: number;
  column_type_flags: number;
  segmentName: string;
}

export default function (
  saveType: SaveType,
  segmentId: number,
  applySegmentId: number,
  applyDestSegmentId: number,
  folderId: number,
  props: Props,
  isSelectedAll: Ref<boolean>,
  includeOrExcludeList: Ref<Record<string, any>[]>,
  dataSourceDisplay: Ref<PreviewSegment.DatasourceDisplay>,
  selectViewMode: SegmentViewMode.DataViewMode,
  payloadForUpdate: any,
) {
  const { isLoading } = useLoading();
  const { openSuccessModal, openDefaultErrorModal, openWarningModal } = useValidationModal();
  const { fetchSegmentSaveModel, fetchCancelSegmentModel } = segmentResultModel();
  const type: Ref<number> = ref(0);
  const idFolder: Ref<number> = ref(0);
  const nameSegment: Ref<string> = ref('');
  const nameFolder: Ref<string> = ref('');
  const idSegment: Ref<number> = ref(segmentId);
  const isLoadingActive: Ref<boolean> = ref(false);
  const isCompleted: Ref<boolean> = ref(false);
  const isOpenModalRename: Ref<boolean> = ref(false);
  const dataDialogTableId: Ref<number> = ref(0);
  const typeSegment: Ref<number> = ref(0);
  const dataDialogList: Ref<SegmentDialogItem> = ref({
    folderSelect: {
      folderId: null!,
      label: '',
    },
    segmentSelect: {
      segmentId: null!,
      label: '',
    },
    TableId: null!,
    column_type_flags: null!,
    segmentName: '',
  });

  const onClickSave = (folderList: SegmentDialogItem, modal_type: number, modal_nameSegment: string) => {
    if (folderList != null) {
      dataDialogList.value = folderList;
      dataDialogTableId.value = folderList.TableId;
      type.value = modal_type;
      nameSegment.value = folderList.segmentName;
      idFolder.value = folderList.folderSelect.folderId;
      nameFolder.value = folderList.folderSelect.label;
    } else {
      type.value = modal_type;
      nameSegment.value = modal_nameSegment;
      idFolder.value = folderId;
    }

    onLoadDataSave();
  };

  const onLoadDataSave = () => {
    const mapColumn = (dataSourceDisplay: PreviewSegment.DatasourceDisplay): PreviewSegment.SaveColumns[] => {
      const saveColumns: PreviewSegment.SaveColumns[] = [];
      dataSourceDisplay.columns.forEach((element) => {
        const obj: PreviewSegment.SaveColumns = {
          ColumnId: element.column_id,
          Ref_columnId: element.ref_columnid,
          Col_Type: element.col_type,
        };
        saveColumns.push(obj);
      });
      return saveColumns;
    };

    const mapData = (includeOrExcludeList: Record<string, any>[]): PreviewSegment.Datas[] => {
      const datas: PreviewSegment.Datas[] = [];
      includeOrExcludeList.forEach((element) => {
        const dataMap: PreviewSegment.Data_Map[] = [];
        sourceDisplay.columns.forEach((el: PreviewSegment.DatasourceDisplayColumns) => {
          const obj: PreviewSegment.Data_Map = {
            ColumnId: el.column_id,
            Value: element[el.key],
          };
          dataMap.push(obj);
        });
        datas.push({ Data_Map: dataMap });
      });
      return datas;
    };

    const ListIncludeOrExclude: Record<string, any>[] = toRaw(includeOrExcludeList.value);
    const sourceDisplay: PreviewSegment.DatasourceDisplay = toRaw(dataSourceDisplay.value);

    if (selectViewMode.selectedOption.mode == 0 || selectViewMode.selectedOption.mode == 9) {
      const payload: SegmentSavePayload = {
        Save_Type: type.value != 4 ? 1 : saveType,
        Type: type.value != 4 ? type.value : Number(router.currentRoute.value.query.type),
        Name: nameSegment.value,
        SegmentId: type.value != 4 ? applySegmentId : Number(router.currentRoute.value.query.segmentOldId),
        Apply_SegmentId: applySegmentId,
        Dest_SegmentId: type.value != 4 ? applySegmentId : Number(router.currentRoute.value.query.segmentOldId),
        Root_TableId: props.selectedSource.tableid,
        Root_FolderId: type.value != 4 ? idFolder.value : folderId,
        Dest_FolderId: type.value != 4 ? idFolder.value : folderId,
        saveColumns: type.value == 3 ? mapColumn(sourceDisplay) : [],
        select_flag: isSelectedAll.value ? 2 : 1,
        Datas: type.value == 3 ? mapData(ListIncludeOrExclude) : [],
        displayTables: payloadForUpdate.value != undefined ? (payloadForUpdate.value != null ? payloadForUpdate.value : null) : null,
      };
      fetchSegmentResultList(payload);
    } else {
      const payload: SegmentSavePayload = {
        Save_Type: type.value != 4 ? 1 : saveType,
        Type: 3,
        Name: nameSegment.value,
        SegmentId: null!,
        Apply_SegmentId: Number(router.currentRoute.value.query.applySegmentId),
        Dest_SegmentId: null!,
        Root_TableId: props.selectedSource.tableid,
        Root_FolderId: null!,
        Dest_FolderId: idFolder.value,
        saveColumns: mapColumn(sourceDisplay),
        select_flag: isSelectedAll.value ? 2 : 1,
        Datas: mapData(ListIncludeOrExclude),
        Dest_Root_TableId: dataDialogTableId.value,
        ResultType: toRaw(selectViewMode.selectedOption.type),
        View_Select_Main: toRaw(selectViewMode.selectedOption.mode),
        displayTables: payloadForUpdate.value != undefined ? (payloadForUpdate.value != null ? payloadForUpdate.value : null) : null,
      };
      fetchSegmentResultList(payload);
    }
  };

  const fetchSegmentResultList = (payload: SegmentSavePayload) => {
    isLoading(true, 'กำลังบันทึก กรุณารอสักครู่...');
    isLoadingActive.value = true;
    fetchSegmentSaveModel.payload = payload;

    api
      .apiRequest(fetchSegmentSaveModel)
      .then((response) => {
        if (response.status === 'success') {
          isCompleted.value = true;
          idSegment.value = response.data.segment_id;
          typeSegment.value = response.data.segment_type;
        }
      })
      .catch((error) => {
        if (error.data.error.message == 'Name of Segment is duplicate') {
          isLoadingActive.value = false;
          isOpenModalRename.value = true;
        } else {
          openDefaultErrorModal(error);
        }
      })
      .finally(() => {
        isLoadingActive.value = false;
        isLoading(false);
        if (isCompleted.value) {
          openSuccessModal(
            'บันทึกรายการสำเร็จ',
            '',
            '',
            () => onDirectSegment(),
            () => onDirectSegment(),
          );
        }
      });
  };

  const cancelSegmentPreview = () => {
    openWarningModal('ยกเลิก Preview Segment', `ต้องการลบ Preview Segment ${nameSegment.value} หรือไม่`, () => confirmSegment());

    function confirmSegment() {
      isLoading(true, 'กำลังยกเลิกรายการ กรุณารอสักครู่...');
      if (router.currentRoute.value.query.type) {
        fetchCancelSegmentModel.payload.segmentId = applyDestSegmentId;
      } else {
        fetchCancelSegmentModel.payload.segmentId = applySegmentId;
      }

      api
        .apiRequest(fetchCancelSegmentModel)
        .then((response) => {
          isLoading(false);
          if (response.status === 'success') {
            openSuccessModal(
              'ยกเลิกบันทึกรายการสำเร็จ',
              '',
              '',
              () => onDirectCancel(),
              () => onDirectCancel(),
            );
          }
          return response;
        })
        .catch((error) => {
          openDefaultErrorModal(error);
        });
    }
  };

  const resolveTabs = (ColTypeFlag: number) => {
    switch (ColTypeFlag) {
      case 1:
        return 0;
      case 2:
        return 1;
      case 4:
        return 2;
      default:
        return router.currentRoute.value.query.tab;
    }
  };

  const onDirectSegment = () => {
    const dialogDataList: SegmentDialogItem = toRaw(dataDialogList.value);
    localStorage.setItem('listNewData', JSON.stringify(toRaw(includeOrExcludeList.value)));
    if (selectViewMode.selectedOption.mode == 0 || selectViewMode.selectedOption.mode == 9) {
      router.push({
        path: '/segment/data-segment/segment-detail',
        query: {
          tab: router.currentRoute.value.query.tab,
          tableId: router.currentRoute.value.query.tableId,
          folderId: type.value != 4 ? idFolder.value : folderId,
          folderName: type.value != 4 ? nameFolder.value : router.currentRoute.value.query.folderName,
          segmentId: idSegment.value,
          segmentName: nameSegment.value,
          type: type.value != 4 ? typeSegment.value : Number(router.currentRoute.value.query.type),
        },
      });
    } else {
      router.push({
        path: '/segment/data-segment/segment-detail',
        query: {
          tab: resolveTabs(dialogDataList.column_type_flags),
          tableId: dialogDataList.TableId,
          folderId: dialogDataList.folderSelect.folderId,
          folderName: dialogDataList.folderSelect.label,
          segmentId: idSegment.value,
          segmentName: nameSegment.value,
          type: typeSegment.value,
        },
      });
    }
  };

  const onDirectCancel = () => {
    if (router.currentRoute.value.query.folderId) {
      router.replace({
        path: '/segment/data-segment/folder-inside',
        query: {
          tab: router.currentRoute.value.query.tab,
          folderId: folderId || idFolder.value,
          folderName: router.currentRoute.value.query.folderName || nameFolder.value,
          tableId: router.currentRoute.value.query.tableId,
        },
      });
    } else {
      router.replace({
        path: '/segment/data-segment/main',
        query: {
          tab: router.currentRoute.value.query.tab,
        },
      });
    }
  };

  return { isLoadingActive, isOpenModalRename, nameSegment, onClickSave, cancelSegmentPreview };
}
