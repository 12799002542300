<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10.165" height="15.376" viewBox="0 0 10.165 15.376">
    <path
      id="Union_68"
      data-name="Union 68"
      d="M.431,9.967C-.2,9.511-.128,8.412.6,7.514L5.878,1.006A2.678,2.678,0,0,1,7.372.039,2.27,2.27,0,0,1,9.5,1l5.276,6.509c.729.9.81,1.992.183,2.443s-1.727.087-2.456-.812L7.709,3.236,2.9,9.166a2.494,2.494,0,0,1-1.807,1A1.118,1.118,0,0,1,.431,9.967Z"
      transform="translate(0 15.376) rotate(-90)"
      fill="#606a6c"
    />
  </svg>
</template>
