<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="69.358" height="127.221" viewBox="0 0 69.358 127.221">
    <g id="Group_37149" data-name="Group 37149" transform="translate(-1115.421 -15497.724)">
      <g id="Group_37007" data-name="Group 37007" transform="translate(1110 15497.724)">
        <g id="Group_37006" data-name="Group 37006" transform="translate(5.421 0)">
          <path
            id="Path_24064"
            data-name="Path 24064"
            class="cls-1"
            d="M30.732,27.5a13.813,13.813,0,1,0-11.9-11.9,13.819,13.819,0,0,0,11.9,11.9"
            transform="translate(2.152 0)"
          />
          <path
            id="Path_24065"
            data-name="Path 24065"
            class="cls-1"
            d="M58.644,64.033a5.723,5.723,0,0,0,8.194,2.172,5.893,5.893,0,0,0,1.835-7.631L53.738,29.529a5.7,5.7,0,0,0-4.705-3.061,2.424,2.424,0,0,0-.34-.033H45.272a13.8,13.8,0,0,1-21.188,0H20.928a2.449,2.449,0,0,0-.259.026,5.7,5.7,0,0,0-5.051,3.068L.684,58.574A5.892,5.892,0,0,0,2.52,66.205a5.722,5.722,0,0,0,8.193-2.172L18.5,48.889V49.4A52.551,52.551,0,0,1,9.516,78.787a6.327,6.327,0,0,0,5.246,9.865H22.45v29.554a5.893,5.893,0,0,0,5.122,5.947,5.722,5.722,0,0,0,6.293-5.678V88.652h1.892v29.554a5.893,5.893,0,0,0,5.122,5.947,5.723,5.723,0,0,0,6.293-5.678V88.652h7.688A6.327,6.327,0,0,0,60.1,78.787,52.551,52.551,0,0,1,51.12,49.4Z"
            transform="translate(0.001 3.038)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
