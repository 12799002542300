<template>
  <svg
    id="Group_33288"
    data-name="Group 33288"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="18.691"
    height="18.208"
    viewBox="0 0 18.691 18.208"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_14388" data-name="Rectangle 14388" width="18.691" height="18.208" />
      </clipPath>
    </defs>
    <g id="Group_33288-2" data-name="Group 33288" clip-path="url(#clip-path)">
      <path
        id="Path_22390"
        data-name="Path 22390"
        d="M8.737.039A9.152,9.152,0,0,0,.489,8.745,9.043,9.043,0,0,0,2.27,14.523a12.118,12.118,0,0,1-1.1.8l-.86.554A.674.674,0,0,0,.3,17l.856.561a4.58,4.58,0,0,0,2.424.642A5.742,5.742,0,0,0,6.2,17.551a8.971,8.971,0,0,0,3.169.655A9.1,9.1,0,1,0,8.737.039m1.782,16.774A7.715,7.715,0,0,1,6.13,16.05a4.229,4.229,0,0,1-3.665.646.328.328,0,0,1-.083-.58,10.53,10.53,0,0,0,1.738-1.5,7.764,7.764,0,1,1,6.4,2.2"
        transform="translate(0 0)"
      />
      <path
        id="Path_22391"
        data-name="Path 22391"
        d="M16.476,11.894l.687,1.393a.769.769,0,0,0,.579.421l1.537.223a.769.769,0,0,1,.426,1.311l-1.112,1.084a.768.768,0,0,0-.221.68l.262,1.531a.768.768,0,0,1-1.115.81l-1.375-.723a.771.771,0,0,0-.716,0l-1.375.723a.769.769,0,0,1-1.116-.81l.263-1.531a.769.769,0,0,0-.221-.68l-1.112-1.084a.769.769,0,0,1,.426-1.311l1.537-.223a.77.77,0,0,0,.579-.421l.687-1.393a.769.769,0,0,1,1.379,0"
        transform="translate(-6.441 -6.347)"
      />
    </g>
  </svg>
</template>
