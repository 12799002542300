import { FixedOffsetZone, IANAZone, SystemZone, Zone } from 'luxon';

/**
 * Get luxon zone object
 * @param timezone If not given will return local timezone (SystemZone)
 */
export function getZone(timezone: Zone | string | number | null): Zone {
  // if timezone is already an object of Zone (luxon zone object), return
  if (timezone instanceof Zone) {
    return timezone;
  }
  // else, converting timezone, timezone is string
  else if (typeof timezone === 'string') {
    // if prefix with "UTC" or "+,-", parse with specifier
    if (timezone.match(/^((UTC)[+-]?)|([+-])\d{0,2}(:\d{2})?/g)) {
      return FixedOffsetZone.parseSpecifier(timezone);
    }
    // otherwise, try to create using IANA identifier
    else {
      return IANAZone.create(timezone);
    }
  }
  // else, timezone is number, use fixed offset
  else if (typeof timezone === 'number') {
    return FixedOffsetZone.instance(timezone);
  }
  // otherwise, return system zone (local timezone)
  else {
    return SystemZone.instance;
  }
}
