<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.161" height="20" viewBox="0 0 17.161 20">
    <g id="Group_30074" data-name="Group 30074" transform="translate(-346.513 -118.522)">
      <path id="Path_21042" data-name="Path 21042"
        d="M39.945,0a5.473,5.473,0,1,0,5.474,5.473A5.474,5.474,0,0,0,39.945,0m0,9.172a3.7,3.7,0,1,1,3.7-3.7,3.7,3.7,0,0,1-3.7,3.7"
        transform="translate(314.779 118.522)" />
      <path id="Path_21043" data-name="Path 21043"
        d="M37.917,41h-.625a9.248,9.248,0,0,0-2.737.467,2.8,2.8,0,0,1-1.656,0A9.248,9.248,0,0,0,30.162,41h-.477a4.464,4.464,0,0,0-4.464,4.464v3.524H42.382V45.464A4.464,4.464,0,0,0,37.917,41m2.689,6.213H27V45.464a2.689,2.689,0,0,1,2.689-2.689h.139a7.743,7.743,0,0,1,2.454.358,4.608,4.608,0,0,0,2.9,0,7.743,7.743,0,0,1,2.454-.358h.287a2.689,2.689,0,0,1,2.689,2.689Z"
        transform="translate(321.292 89.534)" />
    </g>
  </svg>
</template>
