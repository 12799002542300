<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="178.471" height="120.676" viewBox="0 0 178.471 120.676">
    <g id="Group_53785" data-name="Group 53785" transform="translate(-245.412 -424.398)">
      <g id="Group_45167" data-name="Group 45167" transform="translate(245.412 424.398)">
        <g id="Group_45160" data-name="Group 45160" transform="translate(164.416 57.5)">
          <g id="Group_45161" data-name="Group 45161" transform="translate(0)">
            <rect
              id="Rectangle_17076"
              data-name="Rectangle 17076"
              width="14.055"
              height="2.196"
              transform="translate(5.929 14.055) rotate(-90)"
              fill="#3a83f6"
            />
            <rect id="Rectangle_17077" data-name="Rectangle 17077" width="14.055" height="2.196" transform="translate(0 5.93)" fill="#3a83f6" />
          </g>
        </g>
        <g id="Group_45162" data-name="Group 45162" transform="translate(19.957 105.398) rotate(-45)">
          <g id="Group_45161-2" data-name="Group 45161" transform="translate(0 0)">
            <rect
              id="Rectangle_17076-2"
              data-name="Rectangle 17076"
              width="14.055"
              height="2.196"
              transform="translate(5.929 14.055) rotate(-90)"
              fill="#3a83f6"
            />
            <rect
              id="Rectangle_17077-2"
              data-name="Rectangle 17077"
              width="14.055"
              height="2.196"
              transform="translate(14.055 8.125) rotate(180)"
              fill="#3a83f6"
            />
          </g>
        </g>
        <ellipse
          id="Ellipse_1010"
          data-name="Ellipse 1010"
          cx="3.326"
          cy="3.326"
          rx="3.326"
          ry="3.326"
          transform="translate(0 17.296)"
          fill="#16294b"
        />
        <ellipse
          id="Ellipse_1011"
          data-name="Ellipse 1011"
          cx="5.322"
          cy="5.322"
          rx="5.322"
          ry="5.322"
          transform="translate(148.449 0)"
          fill="#16294b"
        />
        <ellipse
          id="Ellipse_1012"
          data-name="Ellipse 1012"
          cx="1.996"
          cy="1.996"
          rx="1.996"
          ry="1.996"
          transform="translate(155.102 13.305)"
          fill="#16294b"
        />
      </g>
      <g id="Group_45168" data-name="Group 45168" transform="translate(259.652 436.52)">
        <path
          id="Path_28703"
          data-name="Path 28703"
          d="M3916.338,910.331H3798.449a5.765,5.765,0,0,0-3.857,10.051l19.854,17.868a11.724,11.724,0,0,1,3.882,8.716V989.34a5.146,5.146,0,0,0,5.146,5.145h92.866a5.146,5.146,0,0,0,5.146-5.145V915.476A5.146,5.146,0,0,0,3916.338,910.331Zm-10.5,73.313h-70.946a3.625,3.625,0,0,1-3.625-3.625v-.654l20.422-22.682,21.425,20.6,1.253,1.206a4.931,4.931,0,0,0,7-.165l1.2-1.263,10.7-11.308,14.229,16.213a4.942,4.942,0,0,0,.792.721A3.607,3.607,0,0,1,3905.837,983.644Zm3.625-19.815-10.678-12.361-1.242-1.518a4.93,4.93,0,0,0-7.4-.269l-1.348,1.425-7.875,8.32a4.931,4.931,0,0,1-7,.166l-17.964-17.273-1.286-1.236a4.931,4.931,0,0,0-7.083.255l-1.192,1.325-15.13,16.8V924.8a3.625,3.625,0,0,1,3.625-3.625h70.946a3.625,3.625,0,0,1,3.625,3.625Z"
          transform="translate(-3792.672 -910.331)"
          fill="#16294b"
        />
        <path
          id="Path_28704"
          data-name="Path 28704"
          d="M3958.637,940.882a7.11,7.11,0,1,0,7.109,7.11A7.109,7.109,0,0,0,3958.637,940.882Z"
          transform="translate(-3856.933 -924.276)"
          fill="#3a83f6"
        />
        <path
          id="Path_28705"
          data-name="Path 28705"
          d="M3923.873,1065.505h-33.739a8.725,8.725,0,0,0-8.725,8.725h0a8.725,8.725,0,0,0,8.725,8.726h33.739a8.726,8.726,0,0,0,8.726-8.726h0A8.726,8.726,0,0,0,3923.873,1065.505Z"
          transform="translate(-3829.312 -974.403)"
          fill="#3a83f6"
        />
      </g>
    </g>
  </svg>
</template>
