<template>
  <svg
    id="Group_31761"
    data-name="Group 31761"
    xmlns="http://www.w3.org/2000/svg"
    width="20.295"
    height="20.295"
    viewBox="0 0 24.295 24.295"
  >
    <path
      id="Path_21177"
      data-name="Path 21177"
      d="M2.025,0H22.271A2.025,2.025,0,0,1,24.3,2.025V22.271A2.025,2.025,0,0,1,22.271,24.3H2.025A2.025,2.025,0,0,1,0,22.271V2.025A2.025,2.025,0,0,1,2.025,0Z"
    />
    <path
      id="Path_21178"
      data-name="Path 21178"
      d="M4.5,0,9,5H0Z"
      transform="translate(16.648 16.14) rotate(180)"
      fill="#fff"
    />
  </svg>
</template>
