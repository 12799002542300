import { DurationLikeObject } from 'luxon';
import { parseISODuration } from '../../composed/date-time';

const statics = ['today'];

// TODO: exposed translator via choco global translator injection
export function toReadableDuration(duration: DurationLikeObject | string): string {
  if (typeof duration === 'string' && duration === 'custom') {
    return 'Custom';
  } else if (typeof duration === 'string' && statics.includes(duration)) {
    return duration.substring(0, 1).toUpperCase() + duration.substring(1);
  } else {
    const pd = typeof duration === 'string' ? parseISODuration(duration) : duration;
    let str = 'Last';
    if (pd.years) {
      str += ` ${pd.years} Years`;
    }
    if (pd.months) {
      str += ` ${pd.months} Months`;
    }
    if (pd.days) {
      str += ` ${pd.days} Days`;
    }
    if (pd.hours) {
      str += ` ${pd.hours} Hours`;
    }
    if (pd.minutes) {
      str += ` ${pd.minutes} Minutes`;
    }
    if (pd.seconds) {
      str += ` ${pd.seconds} Seconds`;
    }
    return str;
  }
}
