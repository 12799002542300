<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250.5" viewBox="0 0 250 250.5">
    <g id="Group_51449" data-name="Group 51449" transform="translate(-0.117)">
      <g id="Rectangle_17814" data-name="Rectangle 17814" transform="translate(0.117)" fill="rgba(96,106,108,0.02)" stroke="#606a6c" stroke-width="1">
        <rect width="250" height="250" rx="20" stroke="none" />
        <rect x="0.5" y="0.5" width="249" height="249" rx="19.5" fill="none" />
      </g>
      <line id="Line_2" data-name="Line 2" y2="250" transform="translate(124.5 0.5)" fill="none" stroke="#606a6c" stroke-width="1" />
    </g>
  </svg>
</template>
