<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20.632" height="16.008" viewBox="0 0 20.632 16.008">
    <g id="Group_53804" data-name="Group 53804" transform="translate(0 0)">
      <path
        id="Union_162"
        data-name="Union 162"
        d="M-14.184.4l1.62-1.5h5.307A1.474,1.474,0,0,0-5.784-2.573V-9.61a1.474,1.474,0,0,0-1.474-1.474H-21.111A1.474,1.474,0,0,0-22.584-9.61v7.038A1.474,1.474,0,0,0-21.111-1.1H-15.8l1.62,1.5m0,2.609L-16.554.817h-4.557a3.393,3.393,0,0,1-3.39-3.39V-9.61A3.393,3.393,0,0,1-21.111-13H-7.258a3.393,3.393,0,0,1,3.39,3.39v7.038A3.393,3.393,0,0,1-7.258.817h-4.557Z"
        transform="translate(24.5 13)"
      />
      <path
        id="Path_303962"
        data-name="Path 303962"
        d="M1.21.389a.77.77,0,0,1,1.338,0L3.656,2.337a.77.77,0,0,1-.669,1.15H.771A.77.77,0,0,1,.1,2.337Z"
        transform="translate(12.403 3.353) rotate(90)"
      />
      <path
        id="Subtraction_288"
        data-name="Subtraction 288"
        d="M13.19,1.769H4.481A1.84,1.84,0,0,0,4.5,0H13.19a.884.884,0,1,1,0,1.769Zm-11.923,0H.884A.884.884,0,1,1,.884,0H1.25a1.84,1.84,0,0,0,.017,1.768Zm1.607-.531A.368.368,0,1,1,3.242.869.369.369,0,0,1,2.874,1.237Z"
        transform="translate(3.279 8.342)"
      />
      <g id="Group_53803" data-name="Group 53803" transform="translate(4.311 7.369)">
        <path
          id="Ellipse_1277_-_Outline"
          data-name="Ellipse 1277 - Outline"
          d="M-3.158-5A1.844,1.844,0,0,1-1.316-3.158,1.844,1.844,0,0,1-3.158-1.316,1.844,1.844,0,0,1-5-3.158,1.844,1.844,0,0,1-3.158-5Zm0,2.211a.369.369,0,0,0,.368-.368.369.369,0,0,0-.368-.368.369.369,0,0,0-.368.368A.369.369,0,0,0-3.158-2.789Z"
          transform="translate(5 5)"
        />
      </g>
    </g>
  </svg>
</template>
