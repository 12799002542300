<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="7.399" height="10.573" viewBox="0 0 7.399 10.573">
    <path
      id="Union_1"
      data-name="Union 1"
      d="M8.665,7.655,6.094,4.542,3.523,7.655A1.577,1.577,0,0,1,1.391,7.9,1.384,1.384,0,0,1,1.13,5.886L4.869,1.359A1.552,1.552,0,0,1,6.094.808a1.551,1.551,0,0,1,1.225.551l3.74,4.527A1.385,1.385,0,0,1,10.8,7.9a1.57,1.57,0,0,1-.934.3A1.549,1.549,0,0,1,8.665,7.655Z"
      transform="translate(-0.807 11.381) rotate(-90)"
    />
  </svg>
</template>
