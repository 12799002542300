<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 51.61 45.14">
    <g id="Group_44663">
      <path id="Path_28679" d="M41.3,0V3.49h4.61l-12.49,10.47,2.23,2.65,12.48-10.45v4.36h3.48V0h-10.31Z" />
      <rect id="Rectangle_17034" x="32.23" y="20.83" width="6.98" height="3.48" />
      <path
        id="Path_28680"
        d="M48.09,20.83h-6.93v3.47h6.94l.04,17.25-.08,.08H23.77V24.31h6.52v-3.48h-6.52V3.52l15.07-.03V0H3.56C1.59,0,0,1.59,0,3.56H0V41.58c0,1.97,1.59,3.56,3.56,3.56H48.05c1.96,0,3.56-1.59,3.56-3.55h0V12.63h-3.55l.02,8.21Zm-27.79,20.83H3.57l-.04-17.35H20.31v17.35Zm-.01-20.83H3.52l-.04-17.25,16.82-.03V20.83Z"
      />
    </g>
  </svg>
</template>
