import { CustomRouteRecord } from '@/types/router/router';

const analyticsRoute: Array<CustomRouteRecord> = [
  {
    path: '/analytics/dashboard',
    name: 'analytics-dashboard',
    component: () => import('@views/modules/analytics/pages/bi-dashboard/BusinessIntelligence.vue'),
    breadcrumb: [
      {
        text: 'bi.dashboard',
      },
    ],
  },
  {
    path: '/analytics/export-preview',
    component: () => import('@views/modules/analytics/pages/bi-export-preview/ExportPreview.vue'),
    meta: {
      layout: 'full',
    },
    breadcrumb: [
      {
        text: 'bi.dashboard',
      },
    ],
  },
];

export default analyticsRoute;
