export enum statusLine {
  hide,
  dotLine,
  solidLine,
  solidBlueLine,
}

export enum directionLine {
  up,
  down,
}

export enum datasourceConnect {
  datasource,
  commu,
}
