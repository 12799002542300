<template>
  <svg
    id="Group_33784"
    data-name="Group 33784"
    xmlns="http://www.w3.org/2000/svg"
    width="19.129"
    height="15.115"
    viewBox="0 0 19.129 15.115"
  >
    <path
      id="Path_22567"
      data-name="Path 22567"
      d="M19.915,275.145h-1.11a.223.223,0,0,0-.222.222v1.107a.223.223,0,0,0,.222.222h1.108l0,0a.223.223,0,0,0,.222-.223v-1.11a.222.222,0,0,0-.222-.222"
      transform="translate(-15.852 -273.022)"
    />
    <path
      id="Path_22568"
      data-name="Path 22568"
      d="M19.915,288.3h-1.11a.223.223,0,0,0-.222.222v1.107a.222.222,0,0,0,.222.222h1.108l0,0a.223.223,0,0,0,.222-.222v-1.11a.222.222,0,0,0-.222-.222"
      transform="translate(-15.852 -283.072)"
    />
    <path
      id="Path_22569"
      data-name="Path 22569"
      d="M31.183,275.145h-1.11a.223.223,0,0,0-.222.222v1.107a.223.223,0,0,0,.222.222h1.108l0,0a.223.223,0,0,0,.222-.223v-1.11a.222.222,0,0,0-.222-.222"
      transform="translate(-24.462 -273.022)"
    />
    <path
      id="Path_22570"
      data-name="Path 22570"
      d="M31.183,288.3h-1.11a.223.223,0,0,0-.222.222v1.107a.222.222,0,0,0,.222.222h1.108l0,0a.223.223,0,0,0,.222-.222v-1.11a.222.222,0,0,0-.222-.222"
      transform="translate(-24.462 -283.072)"
    />
    <path
      id="Path_22571"
      data-name="Path 22571"
      d="M19.915,301.456h-1.11a.223.223,0,0,0-.222.222v1.107a.223.223,0,0,0,.222.222h1.108l0,0a.223.223,0,0,0,.222-.222v-1.11a.222.222,0,0,0-.222-.222"
      transform="translate(-15.852 -293.125)"
    />
    <path
      id="Path_22572"
      data-name="Path 22572"
      d="M31.183,301.456h-1.11a.223.223,0,0,0-.222.222v1.107a.223.223,0,0,0,.222.222h1.108l0,0a.223.223,0,0,0,.222-.222v-1.11a.222.222,0,0,0-.222-.222"
      transform="translate(-24.462 -293.125)"
      fill="#606a6c"
    />
    <path
      id="Path_22573"
      data-name="Path 22573"
      d="M61.585,285.373,59.4,283.935a.245.245,0,0,0-.38.2V285H53.79a.245.245,0,0,0-.245.245v.674a.245.245,0,0,0,.245.245h5.234v.857a.245.245,0,0,0,.38.2l2.181-1.439a.245.245,0,0,0,0-.409"
      transform="translate(-42.566 -279.706)"
    />
    <path
      id="Path_22574"
      data-name="Path 22574"
      d="M53.655,299.652l2.181-1.439a.245.245,0,0,1,.38.2v.857H61.45a.245.245,0,0,1,.245.245v.674a.245.245,0,0,1-.245.245H56.216v.857a.245.245,0,0,1-.38.2l-2.181-1.438a.245.245,0,0,1,0-.409"
      transform="translate(-42.566 -290.616)"
    />
    <path
      id="Path_22575"
      data-name="Path 22575"
      d="M16.772,266.142a.121.121,0,0,0-.023,0H7.132a.127.127,0,0,0-.127.127v14.86a.127.127,0,0,0,.127.127h4.019v-2.844h1.382v2.844h4.238Zm-1.311,6.323v7.372H13.949v-2.844H9.736v2.844H8.42V267.552h7.041Z"
      transform="translate(-7.005 -266.137)"
    />
  </svg>
</template>
