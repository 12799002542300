import { ref, reactive, markRaw, getCurrentInstance, Ref, watch, onMounted } from 'vue';

import CustomerIcon from '@/assets/icons/base/tabs/CustomerIcon.vue';
import BoxIcon from '@/assets/icons/base/tabs/BoxIcon.vue';
import MoneyTurnOverIcon from '@/assets/icons/base/tabs/MoneyTurnOverIcon.vue';
import HoneycombIcon from '@/assets/icons/base/button/HoneycombIcon.vue';
import { useI18n } from 'vue-i18n';

interface Props {
  dataSource: Segment.Datasource;
  column: any;
  viewModeId?: string;
  tooltipTitle?: string;
  disabled?: boolean;
  fetchRetrySelected?: boolean;
}

interface DropDownForm {
  selectedOption: Record<string, any>;
}

interface DropdownListItem {
  seq: number;
  title: string;
  icon: object;
  mode: viewmode_type;
}

enum viewmode_type {
  Generic = 0,
  Customer = 1,
  Product = 2,
  Transaction = 3,
  Group_By = 9,
}

enum Column_Type_Flags {
  None = 0,
  Customer = 1,
  Product = 2,
  Customer_Product = 3,
  Transaction = 4,
  Customer_Transaction = 5,
  Transaction_Product = 6,
  Customer_Product_Transaction = 7,
}

export default function useSelectViewMode(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const isVisibleOption: Ref<boolean> = ref(false);
  const dataDisplay: Ref<string> = ref('');
  const iconDisplay: Ref<number> = ref(0);
  const { t } = useI18n();

  const dropDownForm: DropDownForm = reactive({
    selectedOption: {
      mode: viewmode_type.Generic,
      type: 1,
      title: '',
    },
  });

  const viewModeListItem: DropdownListItem[] = [
    { seq: 1, title: t('segment.components.view_mode.customer'), icon: markRaw(CustomerIcon), mode: viewmode_type.Customer },
    { seq: 2, title: t('segment.components.view_mode.product'), icon: markRaw(BoxIcon), mode: viewmode_type.Product },
    { seq: 3, title: t('segment.components.view_mode.transaction'), icon: markRaw(MoneyTurnOverIcon), mode: viewmode_type.Transaction },
    { seq: 4, title: t('segment.components.view_mode.group_by'), icon: markRaw(HoneycombIcon), mode: viewmode_type.Group_By },
  ];

  const optionViewMode: Ref<DropdownListItem[]> = ref(viewModeListItem);

  const onSetDisplaySelected = () => {
    if (dataDisplay.value.length == 0) {
      dataDisplay.value = props.dataSource.table_label;
    } else {
      dataDisplay.value = dropDownForm.selectedOption.title;
    }
  };

  const onSelectedItem = (selected?: any) => {
    if (selected == undefined) {
      iconDisplay.value = 5;
      dropDownForm.selectedOption.mode = viewmode_type.Generic;
      dropDownForm.selectedOption.type = 1;
      dropDownForm.selectedOption.title = props.dataSource.table_label;
    } else if (selected.mode == viewmode_type.Group_By) {
      iconDisplay.value = selected.seq;
      dropDownForm.selectedOption.mode = viewmode_type.Group_By;
      dropDownForm.selectedOption.type = 3;
      dropDownForm.selectedOption.title = selected.title;
    } else {
      iconDisplay.value = selected.seq;
      dropDownForm.selectedOption.mode = selected.mode;
      dropDownForm.selectedOption.type = 2;
      dropDownForm.selectedOption.title = selected.title;
    }

    onSetDisplaySelected();
    isVisibleOption.value = false;
    vm?.$emit('option-selected', JSON.parse(JSON.stringify(dropDownForm)));
  };

  const resolveIcon = (type: number) => {
    switch (type) {
      case 101 && 1:
        return CustomerIcon;
      case 201 && 2:
        return BoxIcon;
      case 4:
        return HoneycombIcon;
      default:
        return MoneyTurnOverIcon;
    }
  };

  const onSetOptions = () => {
    const isGroupByStatus: boolean = props.column.is_groupby;
    optionViewMode.value = viewModeListItem.filter((item: Record<string, any>) => {
      switch (props.column.groupby_type_flags) {
        case Column_Type_Flags.Customer:
          return item.mode == viewmode_type.Customer || item.mode == viewmode_type.Group_By;
        case Column_Type_Flags.Product:
          return item.mode == viewmode_type.Product || item.mode == viewmode_type.Group_By;
        case Column_Type_Flags.Customer_Product:
          return item.mode != viewmode_type.Transaction;
        case Column_Type_Flags.Transaction:
          if (props.column.column_type_flags == Column_Type_Flags.Transaction) {
            return item.mode == viewmode_type.Group_By;
          } else {
            return item.mode == viewmode_type.Transaction || item.mode == viewmode_type.Group_By;
          }
        case Column_Type_Flags.Transaction_Product:
          return item.mode == viewmode_type.Transaction || item.mode == Column_Type_Flags.Product || item.mode == viewmode_type.Group_By;
        case Column_Type_Flags.Customer_Transaction:
          return item.mode == viewmode_type.Transaction || item.mode == Column_Type_Flags.Customer || item.mode == viewmode_type.Group_By;
        case Column_Type_Flags.Customer_Product_Transaction:
          if (props.column.column_type_flags == Column_Type_Flags.Transaction) {
            return item.mode != viewmode_type.Transaction;
          } else {
            return item.mode > Column_Type_Flags.None;
          }

        default:
          if (props.column.column_type_flags == Column_Type_Flags.Transaction) {
            if (isGroupByStatus) {
              return item.mode == viewmode_type.Group_By;
            } else {
              return item.mode != viewmode_type.Group_By && item.mode != viewmode_type.Transaction;
            }
          } else if (props.column.column_type_flags == Column_Type_Flags.Customer_Transaction) {
            if (isGroupByStatus) {
              return item.mode == viewmode_type.Group_By;
            } else {
              return item.mode != viewmode_type.Group_By && item.mode != viewmode_type.Product;
            }
          } else if (props.column.column_type_flags == Column_Type_Flags.Transaction_Product) {
            if (isGroupByStatus) {
              return item.mode == viewmode_type.Group_By;
            } else {
              return item.mode != viewmode_type.Group_By && item.mode != viewmode_type.Customer;
            }
          } else if (props.column.column_type_flags == Column_Type_Flags.Customer_Product_Transaction) {
            if (isGroupByStatus) {
              return item.mode == viewmode_type.Group_By;
            } else {
              return item.mode != viewmode_type.Group_By;
            }
          }
      }
    });
  };

  watch(
    () => isVisibleOption.value,
    () => {
      if (isVisibleOption.value) {
        onSetOptions();
      }
    },
    { deep: true },
  );

  watch(
    () => props.fetchRetrySelected,
    () => {
      if (props.fetchRetrySelected == true) {
        dataDisplay.value = props.dataSource.table_label;
        iconDisplay.value = 5;
        dropDownForm.selectedOption.mode = viewmode_type.Generic;
        dropDownForm.selectedOption.type = 1;
        dropDownForm.selectedOption.title = props.dataSource.table_label;
      }
    },
    { deep: true },
  );

  onMounted(() => {
    if (props.dataSource) {
      onSetDisplaySelected();
    }
  });

  return {
    optionViewMode,
    isVisibleOption,
    dropDownForm,
    dataDisplay,
    iconDisplay,
    onSelectedItem,
    resolveIcon,
  };
}
