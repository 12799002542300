<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="216.567" height="147.27"
    viewBox="0 0 216.567 147.27">
    <g id="Group_31128" data-name="Group 31128" transform="translate(0 25)">
      <g id="Group_1338" data-name="Group 1338" transform="translate(0 -25)">
        <path id="Path_511" data-name="Path 511"
          d="M200.08,111.2c0,7.948-42.785,14.391-95.564,14.391S8.951,119.152,8.951,111.2s42.786-14.391,95.564-14.391,95.564,6.443,95.564,14.391"
          transform="translate(2.004 21.676)" fill="#e2e2e2" />
        <path id="Path_512" data-name="Path 512"
          d="M173.533,117.427H28.214V39.007a7.091,7.091,0,0,1,7.091-7.091H166.442a7.091,7.091,0,0,1,7.091,7.091Z"
          transform="translate(6.317 7.146)" fill="#fff" />
        <path id="Path_513" data-name="Path 513"
          d="M114.294,100.185h0a1.532,1.532,0,0,1-1.532,1.532h-19.9a1.532,1.532,0,0,1-1.532-1.532A1.532,1.532,0,0,0,89.8,98.653H21.077a1.532,1.532,0,0,0-1.532,1.532v.45a9.81,9.81,0,0,0,9.81,9.81H176.274a9.81,9.81,0,0,0,9.81-9.81v-.45a1.532,1.532,0,0,0-1.532-1.532H115.827a1.532,1.532,0,0,0-1.532,1.532"
          transform="translate(4.376 22.088)" fill="#f4f4f4" />
        <line id="Line_28" data-name="Line 28" y2="39.577" transform="translate(179.85 81.163)" fill="none"
          stroke="#dadada" stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_514" data-name="Path 514" d="M77.662,31.916h76.523a8.277,8.277,0,0,1,8.277,8.276"
          transform="translate(17.388 7.146)" fill="none" stroke="#dadada" stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_515" data-name="Path 515" d="M28.214,113.595v-73.4a8.276,8.276,0,0,1,8.276-8.276H47.375"
          transform="translate(6.317 7.146)" fill="none" stroke="#dadada" stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_516" data-name="Path 516"
          d="M147.348,98.653h-31.52a1.533,1.533,0,0,0-1.534,1.532,1.533,1.533,0,0,1-1.532,1.534H92.867a1.534,1.534,0,0,1-1.534-1.534A1.532,1.532,0,0,0,89.8,98.653H21.079a1.533,1.533,0,0,0-1.534,1.532v.45a9.81,9.81,0,0,0,9.81,9.81H78.676"
          transform="translate(4.376 22.088)" fill="none" stroke="#dadada" stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_517" data-name="Path 517"
          d="M82.689,110.445h79.448a9.809,9.809,0,0,0,9.808-9.81v-.45a1.532,1.532,0,0,0-1.532-1.532H146.048"
          transform="translate(18.514 22.088)" fill="none" stroke="#dadada" stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_518" data-name="Path 518"
          d="M20.806,18.92S35.493,37.278,55.075,36.055L34.269,50.741S17.135,54.413,0,29.935A52.5,52.5,0,0,0,20.806,18.92M31.742,49.713h0a14.514,14.514,0,0,0,2.048-.131L51.223,37.276C35.57,36.314,23.813,24.218,20.668,20.647A54.481,54.481,0,0,1,2.023,30.657C14.612,47.819,26.938,49.713,31.742,49.713"
          transform="translate(0 4.236)" fill="#dadada" />
        <path id="Path_519" data-name="Path 519"
          d="M31.372,49.4a14.514,14.514,0,0,0,2.048-.131L50.853,36.96C35.2,36,23.442,23.9,20.3,20.331A54.481,54.481,0,0,1,1.652,30.341C14.241,47.5,26.567,49.4,31.371,49.4Z"
          transform="translate(0.37 4.552)" fill="#fff" />
        <path id="Path_520" data-name="Path 520"
          d="M14.969,113.25c3.21-5.829,11.76-19.471,24.826-26.572-1.622-2.6-4.916-9.381-4.44-20.88-5.273,2.485-20.776,10.9-26.741,26.265Z"
          transform="translate(1.928 14.732)" fill="#fff" />
        <path id="Path_521" data-name="Path 521"
          d="M36.931,64.22c-1.224,15.911,4.9,23.254,4.9,23.254C24.692,96.041,14.9,116.848,14.9,116.848L7.557,92.37C14.9,72.787,36.931,64.22,36.931,64.22M15.205,113.6c3.21-5.829,11.76-19.471,24.826-26.572-1.622-2.6-4.916-9.381-4.44-20.88-5.273,2.485-20.776,10.9-26.741,26.265Z"
          transform="translate(1.692 14.379)" fill="#dadada" />
        <path id="Path_522" data-name="Path 522"
          d="M148.792,54.869s16.423,10.275,36.976,9.331c0,0-5.4,8.215-9.561,10.366s-10.276,5.823-22.515,3.376a56.257,56.257,0,0,1-19.875-7.867s7.636-3.148,14.974-15.206"
          transform="translate(29.961 12.285)" fill="#fff" />
        <path id="Path_523" data-name="Path 523"
          d="M132.949,69.973s8.567-2.448,15.911-15.911c0,0,13.463,9.791,37.941,9.791a29.923,29.923,0,0,1-26.926,15.911,63.752,63.752,0,0,1-7.047-.988h-.043l.035,0c-6.107-1.177-14.742-3.673-19.871-8.8m51.789-4.919c-19.648-.433-31.808-6.975-35.454-9.245C143.958,65.043,138.14,68.9,135.2,70.356c4.472,3.648,10.929,5.712,16.129,6.855,5.211.632,12.546-3.844,14.2-5.51l.472-.3.469.312c1.731,1.153,4.438,1.434,6.662,1.434a26.7,26.7,0,0,0,4.962-.69,29.134,29.134,0,0,0,6.645-7.4M159.919,78.54a29.25,29.25,0,0,0,14.632-3.716c-.426.02-.884.033-1.37.033A15.49,15.49,0,0,1,166,73.428a48.3,48.3,0,0,1-9.669,4.695c1.995.29,3.341.4,3.584.417"
          transform="translate(29.767 12.104)" fill="#dedede" />
        <path id="Path_524" data-name="Path 524" d="M149.136,74.258c-.045,0-.021,0,.028,0h-.028"
          transform="translate(33.386 16.626)" fill="#dedede" />
        <rect id="Rectangle_668" data-name="Rectangle 668" width="3.459" height="12.935"
          transform="translate(199.131 41.445)" fill="#cbcbcb" />
        <rect id="Rectangle_669" data-name="Rectangle 669" width="12.935" height="3.459"
          transform="translate(194.392 46.183)" fill="#cbcbcb" />
        <rect id="Rectangle_670" data-name="Rectangle 670" width="3.459" height="12.935"
          transform="translate(101.454 7.626)" fill="#cbcbcb" />
        <rect id="Rectangle_671" data-name="Rectangle 671" width="12.935" height="3.459"
          transform="translate(96.715 12.364)" fill="#cbcbcb" />
        <rect id="Rectangle_672" data-name="Rectangle 672" width="12.935" height="3.46"
          transform="translate(172.86 10.811) rotate(-45)" fill="#cbcbcb" />
        <rect id="Rectangle_673" data-name="Rectangle 673" width="3.46" height="12.935"
          transform="translate(172.861 4.11) rotate(-45)" fill="#cbcbcb" />
        <rect id="Rectangle_674" data-name="Rectangle 674" width="12.935" height="3.46"
          transform="translate(25.822 9.146) rotate(-45)" fill="#cbcbcb" />
        <rect id="Rectangle_675" data-name="Rectangle 675" width="3.46" height="12.935"
          transform="translate(25.822 2.446) rotate(-45)" fill="#cbcbcb" />
        <path id="Path_525" data-name="Path 525" d="M57.417,14.573a2.81,2.81,0,1,1-2.81-2.81,2.81,2.81,0,0,1,2.81,2.81"
          transform="translate(11.597 2.634)" fill="#cbcbcb" />
        <path id="Path_526" data-name="Path 526" d="M111.887,4.573a2.81,2.81,0,1,1-2.81-2.81,2.81,2.81,0,0,1,2.81,2.81"
          transform="translate(23.793 0.395)" fill="#cbcbcb" />
        <path id="Path_527" data-name="Path 527" d="M177.019,21.327a2.81,2.81,0,1,1-2.81-2.81,2.81,2.81,0,0,1,2.81,2.81"
          transform="translate(38.376 4.146)" fill="#cbcbcb" />
        <path id="Path_528" data-name="Path 528" d="M11.131,58.092a2.81,2.81,0,1,1-2.81-2.81,2.81,2.81,0,0,1,2.81,2.81"
          transform="translate(1.234 12.377)" fill="#cbcbcb" />
        <path id="Path_529" data-name="Path 529"
          d="M64.06,80.269a31.116,31.116,0,0,1,61.637,0H142a47.316,47.316,0,0,0-94.24,0Z"
          transform="translate(10.693 8.341)" fill="#ebeaea" />
        <path id="Path_530" data-name="Path 530"
          d="M113.678,51.25h0a5.052,5.052,0,0,0-1.8-7.882A44.276,44.276,0,0,0,50.25,79.715H60.441A34.134,34.134,0,0,1,107.877,52.67a5.006,5.006,0,0,0,5.8-1.42"
          transform="translate(11.251 8.897)" fill="#cbcbcb" />
        <rect id="Rectangle_676" data-name="Rectangle 676" width="38.238" height="5.883"
          transform="translate(72.19 94.692)" fill="#cbcbcb" />
        <rect id="Rectangle_677" data-name="Rectangle 677" width="38.238" height="5.883"
          transform="translate(114.454 94.692)" fill="#cbcbcb" />
        <rect id="Rectangle_678" data-name="Rectangle 678" width="7.044" height="5.883"
          transform="translate(60.115 94.692)" fill="#cbcbcb" />
        <rect id="Rectangle_679" data-name="Rectangle 679" width="38.238" height="5.883"
          transform="translate(72.19 104.659)" fill="#e5e5e4" />
        <rect id="Rectangle_680" data-name="Rectangle 680" width="38.238" height="5.883"
          transform="translate(114.454 104.659)" fill="#e5e5e4" />
        <rect id="Rectangle_681" data-name="Rectangle 681" width="7.044" height="5.883"
          transform="translate(60.115 104.659)" fill="#e5e5e4" />
        <path id="Path_531" data-name="Path 531"
          d="M87.536,60.378a3.1,3.1,0,0,1-.257,1.256,3.216,3.216,0,0,1-1.726,1.717,3.238,3.238,0,0,1-2.529,0A3.216,3.216,0,0,1,81.3,61.634a3.118,3.118,0,0,1-.256-1.256,3.164,3.164,0,0,1,.256-1.272,3.306,3.306,0,0,1,.692-1.027,3.161,3.161,0,0,1,1.034-.683,3.356,3.356,0,0,1,2.529,0,3.148,3.148,0,0,1,1.034.683,3.285,3.285,0,0,1,.692,1.027,3.145,3.145,0,0,1,.257,1.272m-1.914,0a1.333,1.333,0,0,0-2.666,0,1.256,1.256,0,0,0,.393.931,1.342,1.342,0,0,0,1.88,0,1.256,1.256,0,0,0,.393-.931M85.6,70.032l-1.52-.753L91.14,56.808l1.589.769Zm10.133-3.606a3.1,3.1,0,0,1-.257,1.256A3.226,3.226,0,0,1,93.754,69.4a3.252,3.252,0,0,1-2.529,0A3.226,3.226,0,0,1,89.5,67.682a3.21,3.21,0,0,1,0-2.511,3.226,3.226,0,0,1,1.726-1.717,3.252,3.252,0,0,1,2.529,0,3.226,3.226,0,0,1,1.726,1.717,3.107,3.107,0,0,1,.257,1.256m-1.914,0a1.251,1.251,0,0,0-.111-.521,1.417,1.417,0,0,0-.29-.427A1.307,1.307,0,0,0,93,65.2a1.337,1.337,0,0,0-1.846,1.23,1.256,1.256,0,0,0,.393.931,1.3,1.3,0,0,0,.94.384,1.284,1.284,0,0,0,.513-.1,1.327,1.327,0,0,0,.419-.281,1.439,1.439,0,0,0,.29-.419,1.193,1.193,0,0,0,.111-.513"
          transform="translate(18.145 12.719)" fill="#ebeaea" />
      </g>
    </g>
  </svg>
</template>
