import { charts_key, charts_type } from '../constants/commuChart';

export default function staticLayoutDashboardByChannel() {
  const layout = [
    {
      x: 0,
      y: 0,
      w: 12,
      h: 2.2,
      i: 0,
      minW: 12,
      minH: 2,
      static: true,
      chartLayoutId: 0,
      chartHeight: 155,
      key: charts_key.TOTAL_STATUS,
      chartType: charts_type.OVERVIEW,
    },
    {
      x: 0,
      y: 2.2,
      w: 12,
      h: 5,
      i: 1,
      minW: 12,
      minH: 4,
      static: true,
      chartLayoutId: 1,
      chartHeight: 442, ///332
      key: charts_key.DELIVERY_RANGE,
      chartType: charts_type.HEATMAP,
    },
    {
      x: 0,
      y: 7.2,
      w: 12,
      h: 5,
      i: 2,
      minW: 12,
      minH: 4,
      static: true,
      chartLayoutId: 2,
      chartHeight: 442,
      key: charts_key.OPEN_RANGE,
      chartType: charts_type.HEATMAP,
    },
    {
      x: 0,
      y: 12.2,
      w: 3,
      h: 5,
      i: 3,
      minW: 3,
      minH: 4,
      static: true,
      chartLayoutId: 3,
      chartHeight: 385,
      key: charts_key.DELIVERY,
      chartType: charts_type.PIE,
    },
    {
      x: 3,
      y: 12.2,
      w: 9,
      h: 5,
      i: 4,
      minW: 9,
      minH: 4,
      static: true,
      chartLayoutId: 4,
      chartHeight: 442,
      key: charts_key.DELIVERY_BY_CAMPAIGN,
      chartType: charts_type.VERTICAL_STACK_BAR,
    },
    {
      x: 0,
      y: 17.2,
      w: 6,
      h: 5,
      i: 5,
      minW: 6,
      minH: 4,
      static: true,
      chartLayoutId: 5,
      chartHeight: 442,
      key: charts_key.EMAIL_NEW_SUBSCRIBE,
      chartType: charts_type.LINE,
    },
    {
      x: 6,
      y: 17.2,
      w: 3,
      h: 5,
      i: 6,
      minW: 3,
      minH: 4,
      static: true,
      chartLayoutId: 6,
      chartHeight: 385,
      key: charts_key.EMAIL_SUBSCRIBE,
      chartType: charts_type.PIE,
    },
    {
      x: 9,
      y: 17.2,
      w: 3,
      h: 5,
      i: 7,
      minW: 3,
      minH: 4,
      static: true,
      chartLayoutId: 7,
      chartHeight: 442,
      key: charts_key.EMAIL_CREDIT,
      chartType: charts_type.VERTICAL_OVERVIEW,
    },
  ];

  const GIRD_LAYOUT: { COL_NUMBERS: number; ROW_HEIGHT: number } = {
    COL_NUMBERS: 12,
    ROW_HEIGHT: 100,
  };

  return {
    layout,
    GIRD_LAYOUT,
  };
}
