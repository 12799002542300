<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25.348" height="28.108" viewBox="0 0 25.348 28.108">
    <g id="Group_37768" data-name="Group 37768" transform="translate(-331.049 -19.711)">
      <path
        id="Path_2"
        data-name="Path 2"
        d="M197.478,13.969l-1.655,1.7c-.188.192-.386.375-.567.572a.263.263,0,0,1-.3.078,22.247,22.247,0,0,1-5.739-2.35.378.378,0,0,0-.444,0,22.089,22.089,0,0,1-8.095,2.805c-.27.037-.27.039-.27.308.009,1.383,0,2.768.03,4.151a16.636,16.636,0,0,0,4.337,10.949,16.46,16.46,0,0,0,4.073,3.3.249.249,0,0,0,.293,0,16.8,16.8,0,0,0,7.743-9.838.562.562,0,0,1,.119-.22q1.616-1.624,3.238-3.241c.019-.019.041-.037.091-.082-.013.214-.019.4-.037.58a19.823,19.823,0,0,1-11.064,15.938.482.482,0,0,1-.459-.007,19.7,19.7,0,0,1-7.873-6.882,20.965,20.965,0,0,1-1.3-2.277,19.213,19.213,0,0,1-1.75-5.639,23.193,23.193,0,0,1-.224-3.541q-.008-2.787-.011-5.575c0-.477,0-.486.483-.505a19.861,19.861,0,0,0,6.089-1.159,19.418,19.418,0,0,0,4.354-2.222c.162-.112.3-.283.526-.239a.435.435,0,0,1,.171.058,18.518,18.518,0,0,0,4.96,2.527,18.791,18.791,0,0,0,3.284.8"
        transform="translate(153.438 9.136)"
        fill="#009d00"
      />
      <path
        id="Path_3"
        data-name="Path 3"
        d="M185.815,24.963l-3.767-4.727a.59.59,0,0,1,.088-.824l1.057-.865a.589.589,0,0,1,.833.086l2.244,2.787a.588.588,0,0,0,.831.086l10.3-8.421a.59.59,0,0,1,.837.091l.872,1.1a.589.589,0,0,1-.093.826l-12.371,9.951a.59.59,0,0,1-.831-.091"
        transform="translate(157.159 11.189)"
        fill="#009d00"
      />
    </g>
  </svg>
</template>
