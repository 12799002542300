<template>
  <svg id="Group_52302" data-name="Group 52302" xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250">
    <g id="Rectangle_17819" data-name="Rectangle 17819" fill="rgba(96,106,108,0.02)" stroke="#606a6c" stroke-width="1">
      <rect width="250" height="250" rx="20" stroke="none" />
      <rect x="0.5" y="0.5" width="249" height="249" rx="19.5" fill="none" />
    </g>
    <line id="Line_9" data-name="Line 9" y1="124.5" transform="translate(124.383)" fill="none" stroke="#606a6c" stroke-width="1" />
    <g id="Group_51448" data-name="Group 51448" transform="translate(0 125)">
      <line id="Line_8" data-name="Line 8" x1="250" transform="translate(0 0)" fill="none" stroke="#606a6c" stroke-width="1" />
    </g>
  </svg>
</template>
