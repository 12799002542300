import { Directive, DirectiveBinding } from 'vue';

const observerOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0,
};

let observer: IntersectionObserver | null = null;

function observerWrapper(binding: DirectiveBinding) {
  observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        binding.value();
      }
    });
  }, observerOptions);
}

const vIntersectionObserver: Directive = {
  mounted: (el: Element, binding) => {
    if (!el) {
      return;
    }
    observerWrapper(binding);
    observer?.observe(el);
  },

  unmounted: (el: Element) => {
    observer?.unobserve(el);
  },
};

export default vIntersectionObserver;
