<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18.472" height="14.337" viewBox="0 0 18.472 14.337">
    <g transform="translate(13.01 0)">
      <g id="Group_31611" data-name="Group 31611">
        <rect width="1.745" height="12.721" transform="translate(1.75)" />
        <path d="M2.731,0,5.462,2.731H0Z" transform="translate(5.462 14.337) rotate(180)" />
      </g>
    </g>
    <g>
      <rect width="1.745" height="11.562" transform="translate(11.562) rotate(90)" />
      <rect width="1.745" height="8.526" transform="translate(11.562 3.691) rotate(90)" />
      <rect width="1.745" height="5.767" transform="translate(11.562 7.382) rotate(90)" />
      <rect width="1.745" height="3.449" transform="translate(11.562 10.859) rotate(90)" />
    </g>
  </svg>
</template>
