import { Ref, ref, onBeforeMount, reactive, getCurrentInstance, onMounted, watch, onBeforeUnmount } from 'vue';
import html2canvas from 'html2canvas';
import router from '@/router';
import apiService from '@/services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useReplaceClassByStyle from '@/views/modules/communication/pages/edm-campaign/hooks/useReplaceClassByStyle';
import templateModel from '@/models/communication/template';
import fileServiceModel from '@/models/file-service/file-service';
import { PATH } from '@/constants/modules/file-service/path';
import { Channel as ChannelConstant } from '@views/modules/communication/pages/main/constants/channel';

interface Props {
  mode?: 'create' | 'edit';
}

export default function useEdmTemplateForm(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const { createTemplateModel, fetchTemplateDetailModel, editTemplateModel } = templateModel();
  const { uploadFileModel, editFileModel } = fileServiceModel();
  const { replacerClassByStyle } = useReplaceClassByStyle();
  const keyChange: Ref<number> = ref(1);
  const isLoading: Ref<boolean> = ref(false);
  const isSaved: Ref<boolean> = ref(false);
  const isClearUi: Ref<boolean> = ref(false);
  const isPersonalizeFromTemplate: Ref<boolean> = ref(true);
  const srefList: Ref<string[]> = ref([]);
  const isUploading: Ref<boolean> = ref(false);
  const isUpdated: Ref<boolean> = ref(true);
  const isEdmEditing = ref(false);
  const interval: Ref<ReturnType<typeof setInterval>> = ref(null!);
  let onSaveTextEdm: any;

  const { openSuccessModal, openDefaultErrorModal } = useValidationModal();

  //personalize
  const isPersonalizeModalOpen: Ref<boolean> = ref(false);
  //Coupon
  const isCouponCodeModalOpen: Ref<boolean> = ref(false);
  const isCategoryModalOpen: Ref<boolean> = ref(false);
  const edmConfigJson: Ref<string> = ref('');
  const edmHTMLContent: Ref<string> = ref('');
  const title: Ref<string> = ref('');
  const thumbnail: Record<string, string> = reactive({ url: '', sref: '' });
  const templateInfo = reactive({
    templateName: '',
    channel: 2,
    categoryId: 0,
    templateId: 0,
    updatedTime: '',
  });

  const createTemplate = async () => {
    if (onSaveTextEdm) {
      await onSaveTextEdm();
    }
    await convertHtmlToBase64();
    createTemplateModel.payload.CategoryId = templateInfo.categoryId;
    createTemplateModel.payload.Channel = templateInfo.channel as Communication.Template.Request.ChannelType;
    createTemplateModel.payload.Name = templateInfo.templateName;
    createTemplateModel.payload.Type = 2;
    createTemplateModel.payload.Contents = resolveEdmContent() as Communication.Template.Request.SaveContent[];
    createTemplateModel.payload.Thumbnail_Url = thumbnail.url;
    createTemplateModel.payload.Thumbnail_Sref = thumbnail.sref;
    createTemplateModel.payload.Deleted_File_Sref = srefList.value;
    isLoading.value = true;

    apiService
      .apiRequest(createTemplateModel)
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        openSuccessModal('ดำเนินการสำเร็จ', '', '', undefined, () =>
          router.push({
            path: '/communication/template/list',
            query: { channel: templateInfo.channel, category: templateInfo.categoryId, mode: ChannelConstant.email, type: 2 },
          }),
        );
        isSaved.value = false;
        onSaveTextEdm = null;
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => (isLoading.value = false));
  };

  const editTemplate = async () => {
    if (onSaveTextEdm) {
      await onSaveTextEdm();
    }
    await convertHtmlToBase64();
    editTemplateModel.payload.TemplateId = templateInfo.templateId;
    editTemplateModel.payload.CategoryId = templateInfo.categoryId;
    editTemplateModel.payload.Channel = templateInfo.channel as Communication.Template.Request.ChannelType;
    editTemplateModel.payload.Type = 2;
    editTemplateModel.payload.Name = templateInfo.templateName;
    editTemplateModel.payload.Contents = resolveEdmContent() as Communication.Template.Request.SaveContent[];
    editTemplateModel.payload.Thumbnail_Url = thumbnail.url;
    editTemplateModel.payload.Thumbnail_Sref = thumbnail.sref;
    editTemplateModel.payload.Deleted_File_Sref = srefList.value;
    isLoading.value = true;

    apiService
      .apiRequest(editTemplateModel)
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        openSuccessModal('ดำเนินการสำเร็จ', '', '', undefined, () =>
          router.push({
            path: '/communication/template/list',
            query: { channel: templateInfo.channel, category: templateInfo.categoryId, mode: ChannelConstant.email, type: 2 },
          }),
        );
        isSaved.value = false;
        onSaveTextEdm = null;
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => (isLoading.value = false));
  };

  const saveTemplate = async () => {
    if (onSaveTextEdm) {
      await onSaveTextEdm();
    }
    await convertHtmlToBase64();
    editTemplateModel.payload.TemplateId = templateInfo.templateId;
    editTemplateModel.payload.CategoryId = templateInfo.categoryId;
    editTemplateModel.payload.Channel = templateInfo.channel as Communication.Template.Request.ChannelType;
    editTemplateModel.payload.Type = 2;
    editTemplateModel.payload.Name = templateInfo.templateName;
    editTemplateModel.payload.Contents = resolveEdmContent() as Communication.Template.Request.SaveContent[];
    editTemplateModel.payload.Thumbnail_Url = thumbnail.url;
    editTemplateModel.payload.Deleted_File_Sref = srefList.value;
    isLoading.value = true;

    apiService
      .apiRequest(editTemplateModel)
      .then(() => {
        fetchTemplateDetail();
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => {
        isUpdated.value = true;
        isLoading.value = false;
        onSaveTextEdm = null;
      });
  };

  const fetchTemplateDetail = () => {
    fetchTemplateDetailModel.payload.TemplateId = templateInfo.templateId;
    srefList.value = [];
    isLoading.value = true;
    isClearUi.value = false;

    apiService
      .apiRequest(fetchTemplateDetailModel)
      .then((response) => {
        const responseData = response.data as Communication.Template.Template;

        templateInfo.categoryId = responseData.category_id;
        templateInfo.channel = responseData.channel;
        templateInfo.templateId = responseData.template_id;
        templateInfo.templateName = responseData.name;
        templateInfo.updatedTime = responseData.updated_dt;
        thumbnail.url = responseData.thumbnail_url;
        thumbnail.sref = responseData.thumbnail_sref;
        edmConfigJson.value = responseData.contents[0].content_editor || '';
        title.value = responseData.contents[0].title || '';
        isSaved.value = true;
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => {
        isUpdated.value = true;
        isLoading.value = false;
      });
  };

  const resolveEdmContent = () => {
    if (edmConfigJson.value) {
      const edmContent = [
        {
          Content: replacePersonalizeTag(replacerClassByStyle(edmHTMLContent.value) as string) as unknown as string,
          Content_Editor: edmConfigJson.value,
          title: title.value || null,
        },
      ];
      return edmContent;
    }
  };

  const replacePersonalizeTag = (contentHtml: string) => {
    const htmlElement = new DOMParser().parseFromString(contentHtml, 'text/html');
    const personalizeButton = htmlElement.getElementsByTagName('button');
    const scanCouponCodeImg = htmlElement.getElementsByClassName('scan-coupon-code');

    for (let i = 0; i < personalizeButton.length; i++) {
      const tagMp = JSON.stringify(JSON.parse(personalizeButton[i].value).tag).replaceAll('"', '');
      contentHtml = contentHtml.replace(personalizeButton[i].outerHTML, tagMp);
    }
    ///scan coupon code
    for (let i = 0; i < scanCouponCodeImg.length; i++) {
      const tagMp = JSON.stringify(JSON.parse(scanCouponCodeImg[i].getAttribute('value') as string).CouponColumnDetail.tag).replaceAll('"', '');
      const width = JSON.stringify(JSON.parse(scanCouponCodeImg[i].getAttribute('width') as string));
      const height = JSON.stringify(JSON.parse(scanCouponCodeImg[i].getAttribute('value') as string).BarcodeLineHeight);
      const barcodeDisplayValue = JSON.stringify(JSON.parse(scanCouponCodeImg[i].getAttribute('value') as string).BarcodeDisplayValue);
      const barcodeColor = JSON.stringify(JSON.parse(scanCouponCodeImg[i].getAttribute('value') as string).BarcodeColor)
        .replaceAll('#', '')
        .replaceAll('"', '');
      const barcodeBgColor = resolveBarcodeBgColor(
        JSON.stringify(JSON.parse(scanCouponCodeImg[i].getAttribute('value') as string).BarcodeBgColor)
          .replaceAll('#', '')
          .replaceAll('"', ''),
      );
      const src = JSON.stringify(scanCouponCodeImg[i].getAttribute('src') as string).replaceAll('"', '');
      const apiBarcode = `https://${
        process.env.NODE_ENV === 'development' ? 'dev-' : ''
      }cdp-action-api.azurewebsites.net//CDP-Action/api/v1.0/Comm/ImageRender/GetBarcode?barcodeType=31&couponCode=${tagMp}&width=${width}&height=${height}&enableLabel=${barcodeDisplayValue}&barcodeColor=${barcodeColor}&backgroundColor=${barcodeBgColor}`;

      contentHtml = contentHtml.replace(src, apiBarcode);
    }
    ///url
    htmlElement.querySelectorAll('a').forEach((element: HTMLElement) => {
      if (isJsonString(element.getAttribute('value') as string)) {
        const trackingMapper: Campaign.TrackingMapper = JSON.parse(element.getAttribute('value') as string);
        if (trackingMapper?.tag) {
          //Replace '&' with '&amp;' because links containing '&' are escaped.
          const href = JSON.stringify(element.getAttribute('href') as string).replace(/&/g, '&amp;');
          contentHtml = contentHtml.replace(href, JSON.stringify(trackingMapper.tag));
        }
      }
    });
    ///tracking open mail
    const tracker = htmlElement.querySelector('.tracking-open-mail');
    if (tracker) {
      const trackerValue = JSON.parse(tracker?.getAttribute('value') as string);
      const trackerSrc = JSON.stringify(tracker?.getAttribute('src'));
      if (trackerValue?.tag) {
        contentHtml = contentHtml.replace(trackerSrc, trackerValue.tag as string);
      }
    }

    return contentHtml;
  };

  const validateForm = (): boolean => {
    let invalidCounter = 0;

    if (!templateInfo.templateName.trim()) {
      const nameContainer = vm?.$refs['template-name-container'] as HTMLDivElement;
      const nameInput = nameContainer.querySelector('.edm-template-name') as HTMLInputElement;
      invalidCounter++;

      nameContainer.classList.add('warning');
      nameInput.focus();
    }

    if (!title.value.trim()) {
      const titleContainer = vm?.$refs['edm-title-container'] as HTMLDivElement;
      const titleInput = titleContainer.querySelector('.title-input') as HTMLInputElement;
      invalidCounter++;

      titleInput.classList.add('warning');
      titleInput.focus();
    }

    return invalidCounter == 0;
  };

  const onClickSave = () => {
    const validateResult = validateForm();

    if (validateResult) {
      if (props.mode == 'create') {
        if (templateInfo.categoryId === 0) {
          return onSaveNoCategoryId();
        } else return createTemplate();
      }

      return editTemplate();
    }
  };

  function onSaveNoCategoryId() {
    isCategoryModalOpen.value = true;
  }

  function onCloseCategoryModal() {
    isCategoryModalOpen.value = false;
  }

  async function onSaveWithCategoryId(cat: Communication.TemplateCard.Response.CategoryList) {
    templateInfo.categoryId = cat.categoryId as number;
    if (templateInfo.categoryId !== 0) {
      isCategoryModalOpen.value = false;
      await createTemplate();
    }
  }

  const onInputTemplateName = () => {
    const nameContainer = vm?.$refs['template-name-container'] as HTMLDivElement;
    nameContainer.classList.remove('warning');
  };

  const onInputEmailTitle = () => {
    const titleContainer = vm?.$refs['edm-title-container'] as HTMLDivElement;
    const titleInput = titleContainer.querySelector('.title-input') as HTMLInputElement;
    titleInput.classList.remove('warning');
  };

  const onSelectEmoji = (emoji: string) => {
    const campaignNameInput = vm?.$refs['edm-template-name'] as HTMLInputElement;
    templateInfo.templateName += emoji;
    campaignNameInput.focus();
    if (templateInfo.templateName) {
      const campaignName = vm?.$refs['template-name-container'] as HTMLDivElement;
      campaignName.classList.remove('warning');
    }
  };

  const onGetEdmConfigJson = (json: string) => {
    edmConfigJson.value = json;
  };

  const onGetEdmContent = (contentHtml: string) => {
    edmHTMLContent.value = contentHtml;
  };

  const onDeleteFile = (sref: string) => {
    srefList.value.push(sref);
  };

  async function onGetFuncSaveText(saveTextFunc: string) {
    await new Promise((resolve) => {
      resolve((onSaveTextEdm = saveTextFunc));
    });
  }

  async function clearEdmUi() {
    await new Promise((resolve) => {
      resolve((isClearUi.value = true));
    });
  }

  const convertHtmlToBase64 = async () => {
    await clearEdmUi();
    const node = document.querySelector('.edm-content-container') as HTMLElement;

    //TODO : Convert into url link file service
    node.style.overflow = 'hidden';
    node.style.transform = 'scale(0.3)';
    node.style.width = '900px';
    node.style.height = '900px';

    return html2canvas(node, {
      allowTaint: true,
      useCORS: true,
    })
      .then(async function (canvas) {
        const png = canvas.toDataURL('png');
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = function () {
          img.width = 90;
          img.height = 90;
        };
        img.src = png;

        function urltoFile(url: string, filename: string, mimeType: string) {
          return fetch(url)
            .then(function (res) {
              return res.arrayBuffer();
            })
            .then(function (buf) {
              return new File([buf], filename, { type: mimeType });
            });
        }

        //Usage example:
        await urltoFile(png, `${templateInfo.templateName}.png`, 'image/png').then(function (file) {
          if (!thumbnail.sref || props.mode == 'create') {
            uploadFileModel.payload.Path = PATH.SYSTEM.COMMU.EDM;
            uploadFileModel.payload.File = file;
            uploadFileModel.payload.Is_Temp = true;
            return apiService
              .apiFormRequest(uploadFileModel)
              .then((response) => {
                const res = response.data as Communication.File.Request.Response.File;
                thumbnail.url = res.public_url;
                thumbnail.sref = res.sref;
              })
              .catch((err) => {
                openDefaultErrorModal(err);
              });
          } else {
            editFileModel.payload.Sref = thumbnail.sref;
            editFileModel.payload.Path = PATH.SYSTEM.COMMU.EDM;
            editFileModel.payload.File = file;
            editFileModel.payload.Is_Temp = true;
            return apiService
              .apiFormRequest(editFileModel)
              .then((response) => {
                const res = response.data as Communication.File.Request.Response.File;
                thumbnail.url = res.public_url;
              })
              .catch((err) => {
                openDefaultErrorModal(err);
              });
          }
        });
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      })
      .finally(() => {
        //TODO : Convert into url link file service
        node.style.transform = 'unset';
        node.style.width = 'unset';
        node.style.height = 'unset';
        node.style.overflow = 'unset';
      });
  };

  function onSelectPersonalize() {
    isPersonalizeModalOpen.value = true;
  }

  function onClosePersonalizeModal() {
    isPersonalizeModalOpen.value = false;
  }

  //coupon
  function onSelectCoupon() {
    isCouponCodeModalOpen.value = true;
  }

  function onCloseCouponModal() {
    isCouponCodeModalOpen.value = false;
  }

  function autoSave() {
    if (!isUpdated.value && !isUploading.value) return saveTemplate();
  }

  function onUploading(uploading: boolean) {
    isUploading.value = uploading;
  }

  const isEditingEdm = () => {
    isEdmEditing.value = !isEdmEditing.value;
  };

  function isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function resolveBarcodeBgColor(hex: string) {
    if (hex.length > 6) {
      hex = '00' + hex.substring(0, 6);
    }
    return hex;
  }

  onBeforeMount(() => {
    const query = router.currentRoute.value.query;

    if (props.mode == 'create') {
      templateInfo.categoryId = Number(query.category) || 0;
      return;
    }

    templateInfo.templateId = Number(router.currentRoute.value.params.id) || 0;
    fetchTemplateDetail();
  });

  onMounted(() => {
    if (props.mode == 'edit') interval.value = setInterval(autoSave, 3600000);
  });

  onBeforeUnmount(() => {
    if (interval.value) {
      clearInterval(interval.value);
    }
  });

  watch(
    () => isEdmEditing.value,
    () => {
      if (!isEdmEditing.value) isUpdated.value = false;
    },
  );

  watch(
    [templateInfo],
    () => {
      if (!isLoading.value) isUpdated.value = false;
    },
    {
      deep: true,
    },
  );

  return {
    title,
    isClearUi,
    isSaved,
    templateInfo,
    isLoading,
    edmConfigJson,
    isPersonalizeModalOpen,
    isCouponCodeModalOpen,
    isCategoryModalOpen,
    isPersonalizeFromTemplate,
    onCloseCategoryModal,
    onSaveWithCategoryId,
    onSelectPersonalize,
    onSelectCoupon,
    onClosePersonalizeModal,
    onCloseCouponModal,
    onClickSave,
    onInputTemplateName,
    onInputEmailTitle,
    onSelectEmoji,
    onDeleteFile,
    onGetEdmConfigJson,
    onGetEdmContent,
    onUploading,
    isEditingEdm,
    onGetFuncSaveText,
  };
}
