<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <path
      id="edit-icon"
      data-name="Subtraction 247"
      d="M7678,8433a9,9,0,1,1,9-9A9.011,9.011,0,0,1,7678,8433Zm1.8-12.887-5.46,5.461-.419,2.1,2.1-.421,5.46-5.458Zm1.259-1.015a.585.585,0,0,0-.419.175l-.421.419,1.681,1.683.419-.421a.6.6,0,0,0,0-.841l-.84-.84A.585.585,0,0,0,7681.059,8419.1Z"
      transform="translate(-7669 -8415)"
    />
  </svg>
</template>
