<template>
  <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.274 21.1734H3.5V10.7024C3.50013 9.73227 3.69137 8.77169 4.0628 7.8755C4.43423 6.97931 4.97856 6.16507 5.66472 5.47928C6.35088 4.79349 7.16542 4.2496 8.06181 3.87865C8.9582 3.50771 9.91889 3.31699 10.889 3.31738C12.8479 3.31738 14.7265 4.09555 16.1117 5.48069C17.4968 6.86584 18.275 8.7445 18.275 10.7034L18.274 21.1734Z"
      fill="currentColor"
    />
    <path
      d="M19.4639 21.7422H2.31389C2.05428 21.742 1.80031 21.6664 1.58289 21.5246C1.36547 21.3827 1.19398 21.1807 1.08929 20.9431C0.984596 20.7056 0.951224 20.4427 0.993233 20.1865C1.03524 19.9303 1.15082 19.6919 1.32589 19.5002L3.50289 17.1182H18.2729L20.4499 19.5002C20.6254 19.6917 20.7414 19.9302 20.7837 20.1865C20.8259 20.4429 20.7926 20.706 20.6879 20.9437C20.5831 21.1815 20.4114 21.3836 20.1937 21.5254C19.976 21.6671 19.7217 21.7425 19.4619 21.7422"
      fill="currentColor"
    />
    <path
      d="M10.8886 0.916992C10.4807 0.916992 10.0895 1.07903 9.80106 1.36746C9.51262 1.65589 9.35059 2.04709 9.35059 2.45499V5.97099C9.35059 6.37889 9.51262 6.77009 9.80106 7.05852C10.0895 7.34695 10.4807 7.50899 10.8886 7.50899C11.2965 7.50899 11.6877 7.34695 11.9761 7.05852C12.2646 6.77009 12.4266 6.37889 12.4266 5.97099V2.45499C12.4266 2.04709 12.2646 1.65589 11.9761 1.36746C11.6877 1.07903 11.2965 0.916992 10.8886 0.916992Z"
      fill="currentColor"
    />
    <path
      d="M8.83105 23.1172C8.96506 23.8306 9.34419 24.4748 9.90286 24.9383C10.4615 25.4018 11.1646 25.6555 11.8906 25.6555C12.6165 25.6555 13.3196 25.4018 13.8783 24.9383C14.4369 24.4748 14.8161 23.8306 14.9501 23.1172H8.83105Z"
      fill="currentColor"
    />
  </svg>
</template>
