export const uid = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const calculateWidth = (height: number) => {
    return height + 100
}

export const calculateFontSizeFromHeight = (height: number, offset: number) => {
    return height / offset
}

export const formatterLabel = (min: number, max: number, val: number, startLabel?: string, endLabel?: string) => {
    if (val < (min + max) / 2) {
        return `\n\n\n${startLabel}`
    } else {
        return `\n\n\n${endLabel}`
    }
}