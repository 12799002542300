<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="27" height="27" viewBox="0 0 350.664 412.106">
    <g>
      <path
        d="M233.776,58.443H38.963A38.963,38.963,0,0,0,0,97.406V370.144a38.963,38.963,0,0,0,38.963,38.962H233.776a38.963,38.963,0,0,0,38.963-38.962V97.406a38.963,38.963,0,0,0-38.963-38.963m12.994,304.22a19.481,19.481,0,0,1-19.481,19.481H45.47a19.481,19.481,0,0,1-19.482-19.481V104.886A19.481,19.481,0,0,1,45.47,85.4l181.819.02a19.481,19.481,0,0,1,19.481,19.481Z"
        transform="translate(11 3)"
      />
      <path d="M194.813,292.219H77.925a19.482,19.482,0,0,0,0,38.963H194.813a19.482,19.482,0,0,0,0-38.963" transform="translate(11 3)" />
      <path d="M194.813,214.294H77.925a19.481,19.481,0,0,0,0,38.963H194.813a19.481,19.481,0,0,0,0-38.963" transform="translate(11 3)" />
      <path d="M194.813,136.369H77.925a19.481,19.481,0,0,0,0,38.963H194.813a19.481,19.481,0,0,0,0-38.963" transform="translate(11 3)" />
      <path
        d="M311.7,0H116.888A38.96,38.96,0,0,0,77.929,38.826h27.532a19.486,19.486,0,0,1,17.934-11.864l181.819.02A19.481,19.481,0,0,1,324.7,46.463V304.22A19.472,19.472,0,0,1,313.2,321.976v28.65A38.958,38.958,0,0,0,350.664,311.7V38.963A38.963,38.963,0,0,0,311.7,0"
      />
    </g>
  </svg>
</template>
