import { onMounted, ref, Ref } from 'vue';
import type { EChartsOption } from 'echarts';

export default function useTestEChartsView() {
  const option: Ref<EChartsOption> = ref({});

  option.value = {
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [120, 200, 150, 80, 70, 110, 130],
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)',
        },
      },
    ],
  };

  onMounted(() => {
    setTimeout(() => {
      [option.value.series].push({
        data: [1200, 2000, 1500, 800, 700, 1100, 1300],
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)',
        },
      });
    }, 2000);
  });

  return { option };
}
