<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<path id="Path_17292_00000083810511711587092960000010373287431299355522_" class="st3" d="M34.4,10.63l37.84,37.84
	c0.85,0.84,0.85,2.21,0.01,3.06c0,0-0.01,0.01-0.01,0.01L34.4,89.37c-0.85,0.84-2.22,0.84-3.06,0l-3.58-3.58
	c-0.84-0.85-0.84-2.22,0-3.06L60.41,50L27.76,17.27c-0.84-0.85-0.84-2.22,0-3.06l3.58-3.58C32.18,9.79,33.55,9.79,34.4,10.63"/>
</svg>
</template>

<style type="text/css" scoped>
	.st1{stroke-miterlimit:10;}
	.st4{stroke-linecap:round;stroke-miterlimit:10;}
	.st6{stroke-miterlimit:10;}
	.st7{stroke-width:1.0002;stroke-miterlimit:10.0022;}
	.st9{stroke-miterlimit:10;}
	.st10{stroke-miterlimit:10;}
	.st11{stroke-miterlimit:10;}
	.st12{stroke-miterlimit:10;}
	.st13{stroke-miterlimit:10;}
	.st17{stroke-miterlimit:10;}
	.st18{stroke-miterlimit:10;}
	.st21{stroke-width:1;stroke-miterlimit:9.9997;}
	.st23{stroke-miterlimit:10;}
	.st26{stroke-miterlimit:10;}
	.st27{clip-path:url(#SVGID_00000089564196118647805590000013935658914668030853_);}
	.st30{clip-path:url(#SVGID_00000146466518659593879350000000182673210964081793_);}
	.st31{clip-path:url(#SVGID_00000130646424598493318790000018050273713600898748_);}
	.st32{clip-path:url(#SVGID_00000007404684084888955580000015079764952529848207_);}
	.st33{clip-path:url(#SVGID_00000028286123941914129630000017569614920256012451_);}
	.st34{clip-path:url(#SVGID_00000063628264219314695270000002611822316495807113_);}
	.st35{clip-path:url(#SVGID_00000005260684681775663650000006284715760334214056_);}
	.st36{clip-path:url(#SVGID_00000068662114443583656670000015084951746578051467_);}
	.st37{clip-path:url(#SVGID_00000087390167493850271520000005100682312425440954_);}
	.st38{clip-path:url(#SVGID_00000163057856059666813970000003317361773032016532_);}
</style>