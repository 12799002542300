<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24.451" height="24.567" viewBox="0 0 24.451 24.567">
    <g id="Group_117172" data-name="Group 117172" transform="translate(-1838.555 -1368.572)">
      <g id="Group_117170" data-name="Group 117170" transform="translate(1854.555 1369.572)">
        <g id="Group_117164" data-name="Group 117164">
          <path
            id="Path_311519"
            data-name="Path 311519"
            d="M-18690.273-13501.148h7.451v7.259"
            transform="translate(18690.273 13501.148)"
            fill="none"
            stroke="#707070"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </g>
      <g id="Group_117173" data-name="Group 117173">
        <g id="Group_117169" data-name="Group 117169" transform="translate(1.359 -0.84)">
          <g id="Group_117164-2" data-name="Group 117164" transform="translate(1838.195 1370.413)">
            <path
              id="Path_311519-2"
              data-name="Path 311519"
              d="M-18682.82-13501.148h-7.451v7.259"
              transform="translate(18690.271 13501.148)"
              fill="none"
              stroke="#707070"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
          <g id="Group_117165" data-name="Group 117165" transform="translate(1838.195 1385.72)">
            <path
              id="Path_311519-3"
              data-name="Path 311519"
              d="M-18682.82-13493.89h-7.451v-7.259"
              transform="translate(18690.271 13501.148)"
              fill="none"
              stroke="#707070"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
        </g>
        <g id="Group_117171" data-name="Group 117171" transform="matrix(0.695, -0.719, 0.719, 0.695, -425.59, 1755.975)">
          <line
            id="Line_2718"
            data-name="Line 2718"
            y1="6.894"
            transform="translate(1850.893 1385.213)"
            fill="none"
            stroke="#707070"
            stroke-linecap="round"
            stroke-width="2"
          />
          <g id="Ellipse_4374" data-name="Ellipse 4374" transform="translate(1844 1372)" fill="none" stroke="#707070" stroke-width="2">
            <circle cx="6.894" cy="6.894" r="6.894" stroke="none" />
            <circle cx="6.894" cy="6.894" r="5.894" fill="none" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
