import { Ref, ref, reactive, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import router from '@/router';
import { DateTime } from 'luxon';

import { useSettingStore } from '@/store/settingStore';
import { storeToRefs } from 'pinia';
import { i18n } from '@/libs/i18n';

import CreditIcon from '@assets/icons/base/notification/bell/credit.vue';
import StorageIcon from '@assets/icons/base/notification/bell/storage.vue';

import apiService from '@/services/api';
import datanotiModel from '@/models/notification/notification';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

export default function useNotiBell() {
  const { fetchNotiListBellModel, notiRead } = datanotiModel();
  const { t } = useI18n();
  const settingStore = useSettingStore();
  const { currentLocale } = storeToRefs(settingStore);
  const { openErrorModal } = useValidationModal();

  const isNoti: Ref<boolean> = ref(false);
  const loading: Ref<boolean> = ref(false);
  const dataNoti: Ref<Record<string, any>> = ref({});
  const loadingMore = ref(false);

  const scrollContainer: Ref<HTMLInputElement | null> = ref(null);
  const isLoadingMore = ref(false);
  const limitToPage: Ref<number> = ref(6);
  const page: Ref<number> = ref(1);
  const refDropdownNoti = ref<HTMLElement | null>(null);
  const refNotiBell = ref<HTMLElement | null>(null);

  const icons: Record<string, IconMapping> = {
    noti_commu_credit_warning: { icon: CreditIcon, color: 'icon-yellow' },
    noti_commu_credit_danger: { icon: CreditIcon, color: 'icon-red' },
    noti_storage_warning: { icon: StorageIcon, color: 'icon-yellow' },
    noti_storage_danger: { icon: StorageIcon, color: 'icon-red' },
    noti_danger: { color: 'icon-red' },
    noti_warning: { color: 'icon-yellow' },
    noti_success: { color: '' },
  };

  const fetchNotiListBell = () => {
    loading.value = true;

    fetchNotiListBellModel.payload.Filters = [];
    fetchNotiListBellModel.payload.Ordering = [
      {
        seq: 1,
        key: 'Created_DT',
        direction: 'asc',
      },
    ];
    fetchNotiListBellModel.payload.Page = 1;
    fetchNotiListBellModel.payload.Limit = limitToPage.value;

    apiService
      .apiRequest(fetchNotiListBellModel)
      .then((response) => {
        setTimeout(() => {
          dataNoti.value = response.data;
          loading.value = false;
        }, 400);
      })
      .catch((err) => {
        setTimeout(() => {
          loading.value = false;
        }, 400);
      })
      .finally(() => {});
  };
  const fetchNotiRead = (id: number) => {
    notiRead.payload.NotiId = id;
    apiService
      .apiRequest(notiRead)
      .then((response) => {})
      .catch((err) => {})
      .finally(() => {
        fetchNotiListBell();
      });
  };

  interface IconMapping {
    icon?: any;
    color: string;
  }

  function mapIcon(name: string): IconMapping {
    return icons[name] || { icon: CreditIcon, color: '' };
  }

  function onClickNoti(item: any) {
    if (!item.is_read) {
      fetchNotiRead(Number(item.notiid));
    }

    switch (item.action_goto) {
      case 'Settings_Business':
        router.push({ path: '/my-account', query: { tab: 'Business' } });
        break;
      case 'Settings_Credit':
        router.push({ path: '/my-account', query: { tab: 'Credit' } });
        break;
      case 'Settings_Storage':
        router.push({ path: '/my-account', query: { tab: 'Storage' } });
        break;
      default:
        break;
    }

    isNoti.value = false;
  }
  function onClickBell() {
    if (isNoti.value == false) {
      isNoti.value = true;
      fetchNotiListBell();
    } else {
      isNoti.value = false;
    }
  }

  const setDateTime = (dateString: string) => {
    let resolveValue = '';
    if (!dateString) {
      return '-';
    }
    const monthNamesThai = [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ];
    const monthNamesEnglish = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const monthNamesChinese = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
    const monthNamesJapanese = ['睦月', '如月', '弥生', '卯月', '皐月', '水無月', '文月', '葉月', '長月', '神無月', '霜月', '師走'];

    const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null');
    const timezone = userInfo.timezone || DateTime.fromISO(new Date().toISOString()).zoneName;
    const dateObject = DateTime.fromISO(dateString, { zone: timezone });
    const currentDateObject = DateTime.now().setZone(timezone as string);
    const duration = dateObject.diff(currentDateObject, ['hour', 'minute', 'second']);

    const monthIndex = dateObject.month - 1;
    const monthNameChinese = monthNamesChinese[monthIndex];
    const monthNameJapanese = monthNamesJapanese[monthIndex];
    const monthNameThai = monthNamesThai[monthIndex];
    const yearThai = dateObject.year + 543; // เพิ่ม 543 เพื่อแปลงเป็น พ.ศ.

    //currentLocale.value or i18n.global.locale
    const monthName = i18n.global.locale === 'th' ? monthNameThai : dateObject.monthLong;
    const year = i18n.global.locale === 'th' ? yearThai : dateObject.year;

    if (Math.abs(duration.hours) > 24) {
      // resolveValue = `${dateObject.day} ${monthName} ${year}`;
      resolveValue = `${dateObject.day} ${t(`notification.notification_bell.month_thai.${monthIndex}`)} ${year}`;
    } else if (Math.abs(duration.hours) <= 24 && Math.abs(duration.hours) >= 1) {
      resolveValue = `${Math.ceil(Math.abs(duration.hours))} ${t('notification.notification_bell.hours')}`;
    } else if (Math.abs(duration.minutes) < 60 && Math.abs(duration.minutes) >= 1) {
      resolveValue = `${Math.ceil(Math.abs(duration.minutes))} ` + t('notification.notification_bell.minutes'); // minutes ago
    } else {
      resolveValue = `${Math.ceil(Math.abs(duration.seconds))} ` + t('notification.notification_bell.seconds'); // seconds ago
    }
    return resolveValue;
  };

  const loadMore = () => {
    if (loadingMore.value) return;
    loadingMore.value = true;
    isLoadingMore.value = true;

    fetchNotiListBellModel.payload.Filters = [];
    fetchNotiListBellModel.payload.Ordering = [
      {
        seq: 1,
        key: 'Created_DT',
        direction: 'asc',
      },
    ];
    fetchNotiListBellModel.payload.Page = page.value + 1;
    fetchNotiListBellModel.payload.Limit = limitToPage.value;

    apiService
      .apiRequest(fetchNotiListBellModel)
      .then((response) => {
        setTimeout(() => {
          response.data.data_list.forEach((item: any) => {
            dataNoti.value.data_list.push(item);
          });
          page.value++;
          loadingMore.value = false;
          isLoadingMore.value = false;
        }, 400);
      })
      .catch((err) => {
        setTimeout(() => {
          loadingMore.value = false;
          isLoadingMore.value = false;
        }, 400);
      })
      .finally(() => {});
  };

  const handleScroll = () => {
    if (scrollContainer.value) {
      const { scrollTop, clientHeight, scrollHeight } = scrollContainer.value;

      if (scrollTop + clientHeight >= scrollHeight && scrollHeight > 0) {
        if (!isLoadingMore.value && dataNoti.value.total_page > page.value) {
          loadMore();
        }
      }
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    const targetElement = event.target as HTMLElement;

    // Check if the clicked element is outside the '.refDropdownNoti' div
    if (refDropdownNoti.value && !refDropdownNoti.value.contains(targetElement)) {
      if (refNotiBell.value && !refNotiBell.value.contains(targetElement)) {
        isNoti.value = false;
        if (scrollContainer.value) {
          scrollContainer.value.scrollTop = 0;
        }
      }
    }
  };

  watch(
    () => isNoti.value,
    (newVal, oldVal) => {
      if (isNoti.value == false) {
        page.value = 1;
        dataNoti.value.data_list = [];
      }
    },
  );

  onMounted(() => {
    document.addEventListener('click', handleClickOutside, true);
    if (localStorage.getItem('userInfo')) {
      fetchNotiListBell();
    }
  });

  return {
    isLoadingMore,
    scrollContainer,
    page,
    loadingMore,
    isNoti,
    loading,
    dataNoti,
    limitToPage,
    t,
    fetchNotiListBell,
    fetchNotiRead,
    mapIcon,
    onClickNoti,
    setDateTime,
    onClickBell,
    refNotiBell,
    refDropdownNoti,
    handleScroll,
  };
}
