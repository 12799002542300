import { Duration, DurationLikeObject } from 'luxon';

/**
 * Parse duration using ISO-8601 specification
 * @param dStr ISO-8601 Duration (see: https://en.wikipedia.org/wiki/ISO_8601#Durations)
 */
export function parseISODuration(dStr: string): DurationLikeObject {
  return Duration.fromISO(dStr).toObject();
}

export { DurationLikeObject };
