<template>
  <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 79.29 69.79">
    <g id="Group_45696">
      <path
        id="Path_28649"
        class="cls-1"
        d="M58.73,28.16c3.91,0,7.09-3.17,7.09-7.09s-3.17-7.09-7.09-7.09-7.08,3.17-7.09,7.08c0,3.91,3.17,7.09,7.09,7.09Z"
      />
      <path
        id="Path_28650"
        class="cls-1"
        d="M75.76,0H3.53C1.58,0,0,1.58,0,3.53H0v62.73c0,1.95,1.58,3.53,3.53,3.53H75.76c1.95,0,3.53-1.58,3.53-3.53h0V3.53C79.29,1.58,77.71,0,75.76,0h0Zm-4.13,7.66V44.24l-9.34-8.67c-1.19-1.19-3.12-1.19-4.31,0l-5.96,5.96c-1.2,1.2-3.11,1.31-4.44,.27l-16.39-12.78c-1.66-1.29-4.03-1.13-5.5,.37L7.66,47.73V7.64l63.96,.02Z"
      />
    </g>
  </svg>
</template>
